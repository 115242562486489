/* eslint-disable max-len */
//#region Imports
import { jobLists } from "character";
import NoCharacter from "components/no-character/NoCharacter";
import { NPC, RelicGroup, RelicPart, RelicStep, RelicType, View } from "enums";
import manager from "managers/app";
import { Component } from "react";
import { connect } from "react-redux";
import t from "translations/translator";
import { AppState, Requirement, ViewArmourElementalElemental2Props, ViewRelicInfo } from "types";
import "./ArmourElementalElemental2.css";
//#endregion Imports

const mapStateToProps = (state:AppState) => {
	return {
		lang: state.userInfo.options.lang,
		gameLang: state.userInfo.options.gameLang,
		userInfo: state.userInfo,
		hideCompletedSteps: state.userInfo.options.hideCompletedSteps,
		hideCompletedTasks: state.userInfo.options.hideCompletedTasks,
		showJob: state.userInfo.options.job,
	};
};

class ArmourElementalElemental2 extends Component<ViewArmourElementalElemental2Props> {
	private requirements:Requirement[] = [];
	private importantNPCs:NPC[] = [];
	private relicInfo:ViewRelicInfo = {
		jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC],
		type: RelicType.WEAPON,
		relic: RelicGroup.WEAPON_ZODIAC,
		part: RelicPart.WEAPON_ZENITH,
		steps: [RelicStep.MIST],
		stepPlaceholders: {},
		next: {
			part: RelicPart.WEAPON_ATMA,
			view: View.RELIC_WEAPONS_ZODIAC_ATMA,
		},
		previous: {
			part: RelicPart.WEAPON_BASE_WEAPON,
			view: View.RELIC_WEAPONS_ZODIAC_BASE_WEAPON,
		},
	};

	constructor(props:ViewArmourElementalElemental2Props){
		super(props);
		this.requirements = this.getRequirements();
		this.importantNPCs = this.getImportantNPCs();
	}

	render(){
		const character = manager.data.getActiveCharacter();
		if(character === null){ return <NoCharacter />; }

		return (
			<div id="armour-elemental-elemental2" className="relic">
				<h1 style={{ textAlign: "center" }}>{t.t("Coming Soon!")}</h1>
			</div>
		);
	}

	componentDidMount(){
		manager.view.changeComplete();
	}

	private getRequirements():Requirement[]{
		if(!this.relicInfo || this.relicInfo.previous === null){ return []; }
		return [
			{ type: "previous part", part: this.relicInfo.previous.part, partView: this.relicInfo.previous.view },
		];
	}

	private getImportantNPCs():NPC[]{
		return [];
	}
}
export default connect(mapStateToProps)(ArmourElementalElemental2);
