//#region Imports
import FormComponents from "components/forms/FormComponents";
import { View } from "enums";
import manager from "managers/app";
import { Component } from "react";
import { connect } from "react-redux";
import { AppState, NoCharacterProps } from "types";
import "./NoCharacter.css";
//#endregion Imports

const mapStateToProps = (state:AppState):NoCharacterProps => {
	return {
		lang: state.userInfo.options.lang,
		gameLang: state.userInfo.options.gameLang,
	};
};

class NoCharacter extends Component<NoCharacterProps> {
	render(){
		return (
			<div className="no-chars">
				<h2>
					<FormComponents.Button
						text="No characters found. Click here to go to your characters page to add a character"
						onClick={() => { manager.view.change(View.CHARACTERS); }}
						translate={true}
					/>
				</h2>
			</div>
		);
	}
}
export default connect(mapStateToProps)(NoCharacter);
