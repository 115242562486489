/* eslint-disable max-len, quote-props */

const siteTranslations = {
	//#region General
	"": { EN: "", FR: "", ES: "", DE: "" },
	"Unknown": { EN: "Unknown", FR: "Inconnu", ES: "Desconocido", DE: "Unbekannt" },
	"Coming Soon!": { EN: "Coming Soon!", FR: "Arrive bientôt!", ES: "Próximamente!", DE: null },
	"Home": { EN: "Home", FR: "Accueil", ES: "Hogar", DE: "Startseite" },
	"Characters": { EN: "Characters", FR: "Personnages", ES: "Personajes", DE: "Charaktere" },
	"Progress": { EN: "Progress", FR: "Progression", ES: "Progreso", DE: "Fortschritt" },
	"relic_weapon": { EN: "Relic Weapon", FR: "Armes Reliques", ES: "Arma Relica", DE: "Reliktwaffen" },
	"relic_tool": { EN: "Relic Tool", FR: "Outils Reliques", ES: "Herramienta Relica", DE: "Reliktwerkzeuge" },
	"relic_armour": { EN: "Relic Armour", FR: "Armures Reliques", ES: "Armadura Relica", DE: "Reliktrüstungen" },
	"Overall": { EN: "Overall", FR: "Global", ES: "En general", DE: "Gesamt" },
	"Items": { EN: "Items", FR: "Objets", ES: "Articulos", DE: "Gegenstände" },
	"Tips": { EN: "Tips", FR: "Conseils", ES: "Consejos", DE: "Tipps" },
	"Gallery": { EN: "Gallery", FR: "Galerie", ES: "Galeria", DE: "Gallerie" },
	"View Change Log": { EN: "View Change Log", FR: "Historique", ES: "Ver Lista de Cambios", DE: "Änderungen ansehen" },
	"Version": { EN: "Version", FR: "Version", ES: "Versión", DE: "Version" },
	"Dungeons": { EN: "Dungeons", FR: "Donjons", ES: "Mazmorras", DE: "Dungeons" },
	"Enemies": { EN: "Enemies", FR: "Ennemis", ES: "Enemigos", DE: "Gegner" },
	"Materia Extraction": { EN: "Materia Extraction", FR: "Extraction de matéria", ES: "Extracción de Materia", DE: "Materia-Extraktion" },
	"FATEs": { EN: "FATEs", FR: "ALEAs", ES: "FATEs", DE: "FATEs" },
	"Fishing": { EN: "Fishing", FR: "Pêche", ES: "Pesca", DE: "Fischen" },
	"Gathering": { EN: "Gathering", FR: "Récolte", ES: "Recolección", DE: "Sammeln" },
	"Leves": { EN: "Leves", FR: "Mandats", ES: "Niveles", DE: "Freibriefe" },
	"Locations": { EN: "Locations", FR: "Positions", ES: "Localizacion", DE: "Orte" },
	"Markets": { EN: "Markets", FR: "Marché", ES: "Mercados", DE: "Märkte" },
	"Details": { EN: "Details", FR: "Détails", ES: "Detalles", DE: "Details" },
	"Quest": { EN: "Quest", FR: "Quête", ES: "Misión", DE: null },
	"Quests": { EN: "Quests", FR: "Quêtes", ES: "Misiones", DE: "Aufträge" },
	"Raids": { EN: "Raids", FR: "Raids", ES: "Redadas", DE: "Raids" },
	"Recipes": { EN: "Recipes", FR: "Recettes", ES: "Recetas", DE: "Rezepte" },
	"Shops": { EN: "Shops", FR: "Boutiques", ES: "Tiendas", DE: null },
	"Trials": { EN: "Trials", FR: "Défis", ES: "Desafíos", DE: "Prüfungen" },
	"Never": { EN: "Never", FR: "Jamais", ES: "Nunca", DE: "Nie" },
	"year": { EN: "year", FR: "année", ES: "año", DE: "Jahr" },
	"years": { EN: "years", FR: "années", ES: "años", DE: "Jahre" },
	"month": { EN: "month", FR: "mois", ES: "mes", DE: "Monat" },
	"months": { EN: "months", FR: "mois", ES: "meses", DE: "Monate" },
	"day": { EN: "day", FR: "jour", ES: "día", DE: "Tag" },
	"days": { EN: "days", FR: "jours", ES: "días", DE: "Tage" },
	"hour": { EN: "hour", FR: "heure", ES: "hora", DE: "Stunde" },
	"hours": { EN: "hours", FR: "heures", ES: "horas", DE: "Stunden" },
	"minute": { EN: "minute", FR: "minute", ES: "minuto", DE: "Minute" },
	"minutes": { EN: "minutes", FR: "minutes", ES: "minutos", DE: "Minuten" },
	"second": { EN: "second", FR: "second", ES: "segundo", DE: "Sekunde" },
	"seconds": { EN: "seconds", FR: "secondes", ES: "segundos", DE: "Sekunden" },
	"Just Now": { EN: "Just Now", FR: "À l'instant", ES: "Justo Ahora", DE: "Jetzt" },
	"%COUNT% %INTERVAL% ago": { EN: "%COUNT% %INTERVAL% ago", FR: "Il y a %COUNT% %INTERVAL%", ES: "hace %COUNT% %INTERVAL%", DE: "vor %COUNT% %INTERVAL%" },
	"No characters found. Click here to go to your characters page to add a character": { EN: "No characters found. Click here to go to your characters page to add a character", FR: "Aucun personnage trouvé. Cliquez ici pour ajouter un personnage", ES: "No se encontró ningún personaje. Haz clic aquí para ir a tú página de personajes y añadir uno", DE: "Keine Charaktere gefunden. Hier klicken um auf deiner Charakterseite einen Charakter hinzuzufügen." },
	"Obtained": { EN: "Obtained", FR: "Obtenu", ES: "Obtenido", DE: "Erhalten" },
	"Item": { EN: "Item", FR: "Objet", ES: "Objeto", DE: "Gegenstand" },
	"Currency": { EN: "Currency", FR: "Devises", ES: "Divisa", DE: "Währung" },
	"Price": { EN: "Price", FR: "Prix", ES: "Precio", DE: "Preis" },
	"Total Cost": { EN: "Total Cost", FR: "Coût total", ES: "Costo Total", DE: "Gesamtkosten" },
	"Items Required": { EN: "Items Required", FR: "Nombre requis", ES: "Objetos Requeridos", DE: "Benötigte Gegenstände" },
	"Items Used": { EN: "Items Used", FR: "Nombre utilisés", ES: "Objetos Utilizados", DE: "Verbrauchte Gegenstände" },
	"Items Remaining": { EN: "Items Remaining", FR: "Nombre restants", ES: null, DE: "Verbleibende Gegenstände" },
	"Inventory": { EN: "Inventory", FR: "Inventaire", ES: "Inventario", DE: "Inventar" },
	"Remaining To Obtain": { EN: "Remaining To Obtain", FR: "Restant à obtenir", ES: "Faltan Obtener", DE: null },
	"Remaining Cost": { EN: "Remaining Cost", FR: "Coût restant", ES: "Costo Restante", DE: "Restkosten" },
	"Item Level": { EN: "Item Level", FR: "Niveau d'objet", ES: "Nivel de Objeto", DE: "Gegenstandsstufe" },
	"Relics": { EN: "Relics", FR: "Reliques", ES: "Reliquias", DE: "Relikte" },
	"To Obtain": { EN: "To Obtain", FR: "À obtenir", ES: "A Obtener", DE: null },
	"Total": { EN: "Total", FR: "Total", ES: "Total", DE: "Gesamt" },
	"Used": { EN: "Used", FR: "Utilisé", ES: "Utilizados", DE: "Verbraucht" },
	"Remaining": { EN: "Remaining", FR: "Restant", ES: "Restante", DE: "Verbleibend" },
	"Level": { EN: "Level", FR: "Niveau", ES: "Nivel", DE: "Stufe" },
	"N/A": { EN: "N/A", FR: "N/A", ES: "N/A", DE: "N/A" },
	"Market": { EN: "Market", FR: "Tableau de ventes", ES: "Mercado", DE: null },
	"Gather": { EN: "Gather", FR: "Récolte", ES: "Recolectar", DE: null },
	"Recipe": { EN: "Recipe", FR: "Recette", ES: "Receta", DE: "Rezept" },
	"Location": { EN: "Location", FR: "Position", ES: null, DE: null },
	"Desynthesis": { EN: "Desynthesis", FR: "Recyclage", ES: "Desíntesis", DE: "Materialverwertung" },
	"Other": { EN: "Other", FR: "Autres", ES: "Otro", DE: "Anderes" },
	"Show Maximum Required": { EN: "Show Maximum Required", FR: "Afficher le maximum requis", ES: "Mostrar Máximo Requerido", DE: null },
	"Tip": { EN: "Tip", FR: "Conseil", ES: "Consejo", DE: "Tipp" },
	"Universalis": { EN: "Universalis", FR: "Universalis", ES: null, DE: null },
	"Page Not Found": { EN: "Page Not Found", FR: "Page introuvable", ES: "Página No Encontrada", DE: "Seite nicht gefunden" },
	"No relics to be found this way!": { EN: "No relics to be found this way!", FR: "Aucune relique trouvée de cette façon", ES: "¡No hay reliquias a encontrar de este modo!", DE: null },
	"Task": { EN: "Task", FR: "Tâche", ES: "Tarea", DE: "Aufgabe" },
	"Check All": { EN: "Check All", FR: "Tout cocher", ES: null, DE: "Alles abhaken" },
	"Uncheck All": { EN: "Uncheck All", FR: "Tout décocher", ES: "Desmarcar Todo", DE: "Alles zurücksetzen" },
	"All Complete": { EN: "All Complete", FR: "Complet", ES: "Completo", DE: "Alles vollständig" },
	"Go to next part": { EN: "Go to next part", FR: "Aller à l'étape suivante", ES: "Ir a la siguiente parte", DE: "Zum nächsten Teil" },
	"Preferences Saved": { EN: "Preferences Saved", FR: "Préférences sauvegardées", ES: "Preferencias Guardadas", DE: "Einstellungen gespeichert" },
	"Preferences Reset": { EN: "Preferences Reset", FR: "Préférences réinitialisées", ES: "Preferencias Reiniciadas", DE: "Einstellungen zurückgesetzt" },
	"Error saving your preferences": { EN: "Error saving your preferences", FR: "Une erreur est survenue en sauvegardant vos préférences", ES: "Error guardando tus preferencias", DE: "Fehler beim Speichern der Einstellungen" },
	"Error resetting your preferences": { EN: "Error resetting your preferences", FR: "Une erreur est survenue en réinitialisant vos préférences", ES: "Error reiniciando tus preferencias", DE: "Fehler beim Zurücksetzen der Einstellungen" },
	"Data Saved": { EN: "Data Saved", FR: "Données sauvegardées", ES: "Datos Guardados", DE: "Daten gespeichert" },
	"Data Reset": { EN: "Data Reset", FR: "Données réinitialisées", ES: "Datos Reiniciados", DE: "Daten zurückgesetzt" },
	"Error saving your data": { EN: "Error saving your data", FR: "Une erreur est survenue en sauvegardant vos données", ES: "Error guardando tus datos", DE: "Fehler beim Speichern der Daten" },
	"Error resetting your data": { EN: "Error resetting your data", FR: "Une erreur est survenue en réinitialisant vos données", ES: "Error reiniciando tus datos", DE: "Fehler beim Zurücksetzen der Daten" },
	"Characters Reset - Error resetting Preferences": { EN: "Characters Reset - Error resetting Preferences", FR: "Réinitialisation des caractères - Erreur de réinitialisation des préférences", ES: "Personajes Reiniciados - Error reiniciando Preferencias", DE: "Charaktere zurückgesetzt - Fehler beim Zurücksetzen der Einstellungen" },
	"Preferences Reset - Error resetting Characters": { EN: "Preferences Reset - Error resetting Characters", FR: "Réinitialisation des préférences - Erreur de réinitialisation des caractères", ES: "Preferencias Reiniciados - Error reiniciando Personajes", DE: "Einstellungen zurückgesetzt - Fehler beim Zurücksetzen der Charaktere" },
	"Error preparing data": { EN: "Error preparing data", FR: "Erreur lors de la préparation des données", ES: "Error preparando datos", DE: "Fehler beim Bereitstellen der Daten" },
	"Refresh the page and try again, if the problem persists get in contact": { EN: "Refresh the page and try again, if the problem persists get in contact", FR: "Rafraîchissez la page et essayez de nouveau. Contactez-nous si le problème persiste.", ES: "Refresca la página e intenta de nuevo, si el problema persiste contáctate", DE: "Lade die Seite neu und versuche es erneut. Falls das Problem weiterhin besteht nimm Kontakt auf." },
	"There was an error logging in": { EN: "There was an error logging in", FR: "Une erreur est survenue lors de la connexion", ES: "Hubo un error iniciando sesión", DE: "Fehler beim Anmelden" },
	"An unexpected error occurred": { EN: "An unexpected error occurred", FR: "Une erreur inconnue s'est produite", ES: "Un error inesperado ha ocurrido", DE: "Unerwarteter Fehler" },
	"Tank": { EN: "Tank", FR: "Tank", ES: "Tanqueu", DE: "Verteidiger" },
	"Healer": { EN: "Healer", FR: "Soigneur", ES: "Curandero", DE: "Heiler" },
	"DPS": { EN: "DPS", FR: "DPS", ES: "DPS", DE: "Angreifer" },
	"Crafter": { EN: "Crafter", FR: "Artisan", ES: "Artesano", DE: "Handwerker" },
	"Gatherer": { EN: "Gatherer", FR: "Récolteur", ES: "Recolector", DE: "Sammler" },
	"None": { EN: "None", FR: "Aucun", ES: "Ninguno", DE: null },
	"There was an error fetching your account information - Switched to %DATASOURCE% data instead": { EN: "There was an error fetching your account information - Switched to %DATASOURCE% data instead", FR: "Une erreur s'est produite lors de l'extraction des informations relatives à votre compte - Les données ont été remplacées par celles de %DATASOURCE%.", ES: "Hubo un error obteniendo la información de tu cuenta - Se cambió a %DATASOURCE%", DE: null },
	"Error processing request": { EN: "Error processing request", FR: "Erreur dans le traitement de la demande", ES: "Error procesando consuy", DE: null },
	"There was an error processing the request": { EN: "There was an error processing the request", FR: "Une erreur s'est produite lors du traitement de la demande", ES: null, DE: null },
	"No images available for this relic": { EN: "No images available for this relic", FR: "Aucune image disponible pour cette relique", ES: "No hay imágenes disponibles para esta reliquia", DE: "Kein Bild für dieses Relikt vorhanden" },
	"Found": { EN: "Found", FR: "Trouvé", ES: null, DE: "Gefunden" },
	"Quantity": { EN: "Quantity", FR: "Quantité", ES: "Cantidad", DE: "Menge" },
	"Done": { EN: "Done", FR: "Terminé", ES: "Hecho", DE: "Erledigt" },
	"Target": { EN: "Target", FR: "Cible", ES: "Objetivo", DE: null },
	"Quantity / Job": { EN: "Quantity / Job", FR: "Quantité / Job", ES: "Cantidad / Trabajo", DE: null },
	"Job": { EN: "Job", FR: "Job", ES: null, DE: "Job" },
	"Jobs": { EN: "Jobs", FR: "Jobs", ES: "Trabajos", DE: null },
	"Submit Translations": { EN: "Submit Translations", FR: "Proposer des traductions", ES: "Enviar Traducciones", DE: "Übersetzungen einreichen" },
	"Dismiss": { EN: "Dismiss", FR: "Rejeter", ES: "Desechar", DE: null },
	"Yes": { EN: "Yes", FR: "Oui", ES: "Si", DE: null },
	"No": { EN: "No", FR: "Non", ES: "No", DE: null },
	"Old version data found": { EN: "Old version data found", FR: "Anciennes données trouvées", ES: "Se encontró una versión antigua de datos", DE: null },
	"Data from an old version has been found, would you like to import this data?": { EN: "Data from an old version has been found, would you like to import this data?", FR: "Des données d'une ancienne version ont été trouvées, souhaitez-vous importer ces données?", ES: "Se encontraron datos de una versión antigua, ¿quiere importarla?", DE: null },
	"Delete old data": { EN: "Delete old data", FR: "Supprimer les anciennes données", ES: "Borrar datos anteriores", DE: null },
	"Delete old version data? This is irreversable and you will lose the option to import later": { EN: "Delete old version data? This is irreversable and you will lose the option to import later", FR: "Supprimer les données de l'ancienne version ? C'est irréversible et vous perdrez la possibilité d'importer ultérieurement.", ES: "¿Borrar datos de la versión anterior? Esto es irreversible y perderás la opción para importarlos después", DE: null },
	"Data imported": { EN: "Data imported", FR: "Données importée", ES: "Datos importados", DE: null },
	"Data imported successfully - Anonymous character data can be merged with a specific character on the Characters page": { EN: "Data imported successfully - Anonymous character data can be merged with a specific character on the Characters page", FR: "Données importées avec succès - Les données relatives aux caractères anonymes (si elles sont disponibles) peuvent être fusionnées avec un caractère spécifique sur la page Caractères", ES: "Datos importados exitosamente - Los datos anónimos de personajes (de estar disponibles) pueden ser fusionados con un personaje específico en la página de Personajes", DE: null },
	"Data imported successfully": { EN: "Data imported successfully", FR: null, ES: null, DE: null },
	"Import error": { EN: "Import error", FR: "Erreur lors de l'import", ES: "Error de importe", DE: null },
	"There was an error importing data, no changes have been made, if the problem persists get in contact": { EN: "There was an error importing data, no changes have been made, if the problem persists get in contact", FR: "Une erreur est survenue en important les données. Aucun changement n'a eu lieu, contactez-nous si le problème persiste.", ES: "Hubo un error importando los datos, no se han realizado cambios, si el problema persiste ponte en contacto", DE: null },
	"Note": { EN: "Note", FR: null, ES: null, DE: null },
	//#endregion General

	//#region Error Messages
	"Error fetching server list": { EN: "Error fetching server list", FR: null, ES: null, DE: null },
	//#endregion Error Messages

	//#region Game Related, but not directly a Game Phrase
	"Company Seal": { EN: "Company Seal", FR: "Sceau de compagnie", ES: "Sello de Compañía", DE: "Staatstaler" },
	"Aetheric Density": { EN: "Aetheric Density", FR: "Densité éthérée", ES: "Densidad Etérica", DE: null },
	//#endregion Game Related, but not directly a Game Phrase

	//#region Components

	//#region Account Status
	"You are not logged in": { EN: "You are not logged in", FR: "Vous n'êtes pas connecté", ES: "No has iniciado sesión", DE: null },
	"Log in / Sign up": { EN: "Log in / Sign up", FR: "Connexion / Inscription", ES: "Iniciar sesión / Registrarse", DE: "Anmelden / Registrieren" },
	"Logged in as %USERNAME%": { EN: "Logged in as %USERNAME%", FR: "Connecté en tant que %USERNAME%", ES: "Iniciaste sesión como %USERNAME%", DE: "Angemeldet als %USERNAME%" },
	"Log out": { EN: "Log out", FR: "Se déconnecter", ES: "Cerrar sesión", DE: "Abmelden" },
	//#endregion Account Status

	//#region Character Selector
	"Character": { EN: "Character", FR: "Personnage", ES: "Personaje", DE: "Charakter" },
	"No Characters": { EN: "No Characters", FR: "Aucun personnage", ES: "No hay Personajes", DE: "Keine Charaktere" },
	//#endregion Character Selector

	//#region Language Switcher
	"Language": { EN: "Language", FR: "Langue", ES: "Idioma", DE: "Sprache" },
	"Game Language": { EN: "Game Language", FR: "Langue du jeu", ES: "Idioma del Juego", DE: "Spielsprache" },
	//#endregion Language Switcher

	//#region Theme Switcher
	"Change Theme": { EN: "Change Theme", FR: "Changer le thème", ES: null, DE: "Modus ändern" },
	//#endregion Theme Switcher

	//#endregion Components

	//#region Modal
	"Close": { EN: "Close", FR: "Fermer", ES: "Cerrar", DE: "Schließen" },
	"'Crafts' is the number of times you need to craft a recipe to obtain the required number of items": { EN: "'Crafts' is the number of times you need to craft a recipe to obtain the required number of items", FR: "'Fabrication' correspond au nombre de fois que vous allez devoir fabriquer une recette afin d'obtenir le nombre requis d'objets", ES: "'Artesanías' es el número de veces que necesitas fabricar una receta para obtener el número requerido de objetos", DE: "Herstellungen' ist die Zahl, wie oft du ein Rezept herstellen musst, um die nötige Anzahl an Gegenständen zu erhalten" },
	"Quest to unlock": { EN: "Quest to unlock", FR: "Quêtes prérequises", ES: null, DE: null },
	"Map": { EN: "Map", FR: "Carte", ES: "Mapà", DE: "Karte" },
	"Region": { EN: "Region", FR: "Région", ES: "Región", DE: "Region" },
	"Teleport": { EN: "Teleport", FR: "Téléportation", ES: "Teletransportarse", DE: "Teleport" }, // TODO: Move to game translations, rename to "Aetheryte"
	"Co-ordinates": { EN: "Co-ordinates", FR: "Coordonnées", ES: "Coordenadas", DE: "Koordinaten" },
	"Chained from %FATE%": { EN: "Chained from %FATE%", FR: "À la suite de %FATE%", ES: "Encadenada de %FATE%", DE: "In Kette nach %FATE%" },
	"Speak to %NPC% to start this FATE": { EN: "Speak to %NPC% to start this FATE", FR: "Parler à %NPC% pour démarrer cet ALEA", ES: "Habla con el %NPC% para comenzar esta FATE", DE: "Sprich mit %NPC% um das FATE zu beginnen" },
	"Any Skirmish": { EN: "Any Skirmish", FR: "N'importe quelle escarmouche", ES: null, DE: null },
	"Any FATEs": { EN: "Any FATEs", FR: "N'importe quel ALEA", ES: "Cualquier FATE", DE: "Jegliche FATEs" },
	"Any Trials": { EN: "Any Trials", FR: "N'importe quel Défi", ES: "Cualquier Desafío", DE: "Jegliche Prüfungen" },
	"Any Critical Engagement": { EN: "Any Critical Engagement", FR: "N'importe quel Affrontement Crucial", ES: "Cualquier Enfrentamiento Crítico", DE: "Jegliche Kritischen Gefechte" },
	"Bait": { EN: "Bait", FR: "Appât", ES: "Cebo", DE: "Köder" },
	"Collectable": { EN: "Collectable", FR: "Collectable", ES: null, DE: null }, // TODO: May be able to move this to game translations
	"General": { EN: "General", FR: "Général", ES: null, DE: null },
	"Makes": { EN: "Makes", FR: "Réaliser", ES: "Hace", DE: null },
	"Crafts": { EN: "Crafts", FR: "Fabriquer", ES: null, DE: "Herstellungen" },
	"Ingredients": { EN: "Ingredients", FR: "Ingrédients", ES: "Ingredientes", DE: null },
	"Are you sure?": { EN: "Are you sure?", FR: "Êtes-vous sûr?", ES: "¿Estás seguro?", DE: null },
	"Yes, Do it": { EN: "Yes, Do it", FR: "Oui, allons-y", ES: "Sí, hazlo", DE: null },
	"No, Go back": { EN: "No, Go back", FR: "Non, revenir en arrière", ES: "No, vuelve", DE: null },
	//#endregion Modal

	//#region Pages

	//#region Page General
	"The %PART% upgrade follows on from %PREVIOUSPART%": { EN: "The %PART% upgrade follows on from %PREVIOUSPART%", FR: "L'amélioration %PART% suit à partir de %PREVIOUSPART%", ES: "La mejora %PART% continúa de %PREVIOUSPART%", DE: null },
	"Complete": { EN: "Complete", FR: "Terminé", ES: null, DE: null },
	"All Steps Complete": { EN: "All Steps Complete", FR: "Toutes les étapes sont complétées", ES: "Todos los Pasos Completados", DE: null },
	"Select job to track": { EN: "Select job to track", FR: "Sélectionner un job à afficher", ES: "Escoje un trabajo para rastrear", DE: null },
	"Type": { EN: "Type", FR: "Type", ES: null, DE: null },
	"Points": { EN: "Points", FR: "Points", ES: "Puntos", DE: null },
	"Stage": { EN: "Stage", FR: "Étape", ES: null, DE: null },
	"Area": { EN: "Area", FR: "Zone", ES: "Zona", DE: null },
	"Any": { EN: "Any", FR: "N'importe", ES: "Cualquier", DE: null },
	"Duty": { EN: "Duty", FR: "Mission", ES: "Deber", DE: "Inhalt" },
	"Leve": { EN: "Leve", FR: "Mandat", ES: "Leve", DE: "Freibrief" },
	"Raid": { EN: "Raid", FR: "Raid", ES: "Banda", DE: "Raid" },
	"FATE": { EN: "FATE", FR: "ALEA", ES: "FATE", DE: "FATE" },
	"Ignore %PART% part": { EN: "Ignore %PART% part", FR: "Ignorer %PART% étape", ES: "Ignora la parte %PART%", DE: null },
	"Ignored": { EN: "Ignored", FR: "Ignoré", ES: "Ignroado", DE: null },
	"Import": { EN: "Import", FR: "Importer", ES: "Importar", DE: null },
	"Merge": { EN: "Merge", FR: "Fusionner", ES: null, DE: null },
	"Discard": { EN: "Discard", FR: "Abandonner", ES: "Descartar", DE: null },
	"Delete": { EN: "Delete", FR: "Effacer", ES: "Borrar", DE: null },
	//#endregion Page General

	//#region Home
	"A Relic Tracker Reborn": { EN: "A Relic Tracker Reborn", FR: "A Relic Tracker Reborn", ES: "Un Rastreador de Reliquias Renacido", DE: null },
	"Track your relic progress & Find out what you will need so you can spend those currencies effectively without sitting on cap!": { EN: "Track your relic progress & Find out what you will need so you can spend those currencies effectively without sitting on cap!", FR: "Vérifiez votre progression et trouvez ce dont vous avez besoin afin de dépenser vos devises sans en perdre à cause de la limite!", ES: "¡Rastrea tu progreso de reliquias y descubre que necesitarás para que puedas gastar esas divisas efectivamente sin estar todo el tiempo al límite de divisas!", DE: null },
	"Come mingle in the Discord": { EN: "Come mingle in the Discord", FR: "Venez dire bonjour sur le Discord", ES: "Ven a pasar un rato en Discord", DE: null },
	"Get some help, maybe find some relic farming buddies!": { EN: "Get some help, maybe find some relic farming buddies!", FR: "Obtenez de l'aide, ou faites-vous des amis collectionneurs de reliques!", ES: "¡Consigue ayuda, quizás encuentra amigos para farmear reliquias!", DE: null },
	"Found an error? or maybe a bug that needs squashing?": { EN: "Found an error? or maybe a bug that needs squashing?", FR: "Vous avez trouvé une erreur ? Ou un bug, qui demande à être réparé?", ES: "¿Encontraste un error? ¿O quizás un 'bicho' que necesita ser aplastado?", DE: null },
	"Fill out this form": { EN: "Fill out this form", FR: "Remplissez ce formulaire", ES: "Llena este fo", DE: null },
	"or": { EN: "or", FR: "ou", ES: "o", DE: null }, // TODO: Review the usage of this and try better way! - due to context / translations
	"Report on Discord": { EN: "Report on Discord", FR: "Signaler sur Discord", ES: "Reportar en Discord", DE: null },
	//#endregion Home

	//#region Change Log
	"Change Log": { EN: "Change Log", FR: "Journal des modifications", ES: "Historial de Cambios", DE: null },
	"Latest Changes": { EN: "Latest Changes", FR: "Dernières mises à jour", ES: "Cambios Recientes", DE: null },
	"Previous Changes": { EN: "Previous Changes", FR: "Précédentes mises à jours", ES: "Cambios Previos", DE: null },
	"NEW": { EN: "NEW", FR: "NOUVEAU", ES: "NUEVO", DE: null },
	"FIX": { EN: "FIX", FR: "CORRECTION", ES: "ARR", DE: null },
	"KNOWN ISSUE": { EN: "KNOWN ISSUE", FR: "BUG CONNU", ES: "ERROR CONOCIDO", DE: null },
	"UPDATED": { EN: "UPDATED", FR: "MIS À JOUR", ES: "ACTUALIZADO", DE: null },
	"UPCOMING": { EN: "UPCOMING", FR: "À VENIR", ES: "PRÓXIMAMENTE", DE: null },

	//#endregion Change Log

	//#region Characters
	"Upload backup from Version 2.1.1": { EN: "Upload backup from Version 2.1.1", FR: "Envoyer sauvegarde de la version 2.1.1", ES: "Subir copia de respaldo de la Versión 2.1.1", DE: null },
	"Are you sure you wish to merge anonymous into this character?": { EN: "Are you sure you wish to merge anonymous into this character?", FR: "Êtes-vous sûr de vouloir fusionner les données anonymes avec ce personnage?", ES: "¿Estás seguro que deseas combinar anónimo con este personaje?", DE: null },
	"Are you sure you wish to discard? - This is irreversable and you will lose the option to merge later": { EN: "Are you sure you wish to discard? - This is irreversable and you will lose the option to merge later", FR: "Êtes-vous sûr d'abandonner? Cette action est irréversible et il sera impossible de fusionner après", ES: "¿Estás seguro que quieres descartar? - Esto es irreversible y perderás la opción de combinar más tarde", DE: null },
	"Select Character": { EN: "Select Character", FR: "Sélectionner le personnage", ES: "Seleccionar Personaje", DE: null },
	"Anonymous Character found. You can merge this character into any existing character below by clicking": { EN: "Anonymous Character found. You can merge this character into any existing character below by clicking", FR: "Personnage anonyme trouvé. Vous pouvez fusionner ce personnage avec n'importe quel personnage existant ci-dessous en cliquant sur", ES: null, DE: null },
	"Merge Anonymous data into this character": { EN: "Merge Anonymous data into this character", FR: "Fusionner les données anonymes dans ce caractère", ES: null, DE: null },
	"Your Characters": { EN: "Your Characters", FR: "Vos personnages", ES: "Tus Personajes", DE: null },
	"No saved characters": { EN: "No saved characters", FR: "Aucun personnage sauvegardé", ES: "No hay personajes guardados", DE: null },
	"Find Character": { EN: "Find Character", FR: "Rechercher un personnage", ES: "Encontrar Personajes", DE: null },
	"Search Results": { EN: "Search Results", FR: "Résultats de recherche", ES: null, DE: null },
	"Data Management": { EN: "Data Management", FR: "Gestion des données", ES: null, DE: null },
	"Reset your preferences or reset/delete your characters - These actions are irreversible": { EN: "Reset your preferences or reset/delete your characters - These actions are irreversible", FR: "Réinitialiser vos préférences ou réinitialiser/supprimer vos personnages - Ces actions sont irréversibles", ES: null, DE: null },
	"Select Data Centre": { EN: "Select Data Centre", FR: "Sélectionner un centre de données", ES: null, DE: null },
	"No Data Center Selected": { EN: "No Data Center Selected", FR: "Aucun centre de données sélectionné", ES: null, DE: null },
	"Select a Server": { EN: "Select a Server", FR: "Sélectionner un monde", ES: null, DE: null },
	"Character Name": { EN: "Character Name", FR: "Nom du personnage", ES: "Nombre del Personaje", DE: null },
	"Search": { EN: "Search", FR: "Recherche", ES: null, DE: null },
	"Add Character": { EN: "Add Character", FR: "Ajouter personnage", ES: null, DE: null },
	"Add Characters": { EN: "Add Characters", FR: "Ajouter personnages", ES: null, DE: null },
	"Reset All Characters": { EN: "Reset All Characters", FR: "Remise à zéro de tous les personnages", ES: null, DE: null },
	"Reset Account Settings": { EN: "Reset Account Settings", FR: "Remise à zéro des paramètres du compte", ES: null, DE: null },
	"Reset All Data": { EN: "Reset All Data", FR: "Remise à zéro de toutes les données", ES: null, DE: null },
	"Delete All Characters": { EN: "Delete All Characters", FR: "Supprimer tous les personnages", ES: null, DE: null },
	"Fetching Achievements": { EN: "Fetching Achievements", FR: "Synchroniser les haut faits", ES: null, DE: null },
	"%CHARNAME% marked for removal": { EN: "%CHARNAME% marked for removal", FR: "%CHARNAME% est marqué pour suppression", ES: null, DE: null },
	"Undo": { EN: "Undo", FR: "Annuler", ES: null, DE: null },
	"Real Name": { EN: "Real Name", FR: "Véritable Nom", ES: null, DE: null },
	"Achievements Checked": { EN: "Achievements Checked", FR: "Haut faits synchronisés", ES: null, DE: null },
	"Last Update": { EN: "Last Update", FR: "Dernière mise à jour", ES: "Última Actualización", DE: null },
	"No Changes Made": { EN: "No Changes Made", FR: "Aucune modification n'a été apportée", ES: null, DE: null },
	"Check your Achievements": { EN: "Check your Achievements", FR: "Synchronisez vos Haut Faits", ES: null, DE: null },
	"Edit Character": { EN: "Edit Character", FR: "Éditer Personnage", ES: "Editar Personaje", DE: null },
	"Delete Character": { EN: "Delete Character", FR: "Effacer Personnage", ES: "Borrar Personaje", DE: null },
	"No results found": { EN: "No results found", FR: "Aucun résultat", ES: null, DE: null },
	"Characters updated but there was an error deleting your selected characters": { EN: "Characters updated but there was an error deleting your selected characters", FR: "Personnages mis à jour mais une erreur est survenue pendant la suppression des personnages sélectionnés", ES: null, DE: null },
	"Characters deleted, but there was an error updating your selected characters": { EN: "Characters deleted, but there was an error updating your selected characters", FR: "Personnages supprimés, mais une erreur est survenue pendant la mise à jour des personnages sélectionnés", ES: null, DE: null },
	"Save Changes": { EN: "Save Changes", FR: "Sauvegarder les modifications", ES: "Guardar Cambios", DE: null },
	"This will reset all your characters data - This action is irreversible": { EN: "This will reset all your characters data - This action is irreversible", FR: "Ceci va remettre à zéro les données de vos personnages - Cette action est définitive", ES: null, DE: null },
	"This will reset all your preferences - This action is irreversible": { EN: "This will reset all your preferences - This action is irreversible", FR: "Ceci va remettre à zéro vos préférences - Cette action est définitive", ES: null, DE: null },
	"This will reset all your data - This action is irreversible": { EN: "This will reset all your data - This action is irreversible", FR: "Ceci va remettre à zéro toutes vos données - Cette action est définitive", ES: null, DE: null },
	"This will delete all your characters - This action is irreversible": { EN: "This will delete all your characters - This action is irreversible", FR: "Ceci va supprimer tous vos personnages - Cette action est définitive", ES: null, DE: null },
	//#endregion Characters

	//#region Items
	"Showing %STARTENTRY% - %ENDENTRY% of %TOTALENTRIES%": { EN: "Showing %STARTENTRY% - %ENDENTRY% of %TOTALENTRIES%", FR: "Montrer %STARTENTRY% - %ENDENTRY% de %TOTALENTRIES%", ES: null, DE: null },
	"Hide 0 remaining items": { EN: "Hide 0 remaining items", FR: "Cacher les objets avec 0 restants à obtenir", ES: null, DE: null },
	"Show only buyable items": { EN: "Show only buyable items", FR: "Afficher uniquement les objets pouvant être achetés", ES: null, DE: null },
	"Select relics to filter": { EN: "Select relics to filter", FR: "Sélectionner la relique à afficher", ES: null, DE: null },
	"Select relic parts to filter": { EN: "Select relic parts to filter", FR: "Sélectionner l'étape à afficher", ES: null, DE: null },
	"Select currencies to filter": { EN: "Select currencies to filter", FR: "Sélectionner la monnaie à afficher", ES: null, DE: null },
	"Relic Parts": { EN: "Relic Parts", FR: "Étapes", ES: null, DE: null },
	"View": { EN: "View", FR: "Vue", ES: null, DE: null },
	"Total Required": { EN: "Total Required", FR: "Total requis", ES: null, DE: null },
	"Total Used": { EN: "Total Used", FR: "Total utilisé", ES: null, DE: null },
	"Total Remaining": { EN: "Total Remaining", FR: "Total restant", ES: null, DE: null },
	"Rows": { EN: "Rows", FR: "Colonnes", ES: "Lignes", DE: null },
	"These values only take into account the currencies when they are used to purchase something, and does not include when they are used as an item": { EN: "These values only take into account the currencies when they are used to purchase something, and does not include when they are used as an item", FR: "Ces valeurs ne prennent en compte les devises que lorsqu'elles sont utilisées pour acheter quelque chose, mais pas lorsqu'elles sont utilisées en tant qu'objet.", ES: null, DE: null },
	"For example: %CURRENCY% show the total to purchase all %ITEM% but not the crystals that are needed to hand in for %PART%": { EN: "For example: %CURRENCY% show the total to purchase all %ITEM% but not the crystals that are needed to hand in for %PART%", FR: "Par exemple: %CURRENCY% montre le total pour acheter tous les %ITEM% mais pas les cristaux qu'il est nécessaire d'avoir sur soi pour %PART%", ES: null, DE: null },
	"These values currently only take into account items when they are used directly in a relic step": { EN: "These values currently only take into account items when they are used directly in a relic step", FR: "Ces valeurs ne prennent pour le moment en compte que les objets qui sont directement utilisés dans une étape de relique", ES: null, DE: null },
	"For example: %CURRENCY% is excluded as it is not directly used to get the items required for %RELIC%": { EN: "For example: %CURRENCY% is excluded as it is not directly used to get the items required for %RELIC%", FR: "Par exemple, %CURRENCY% est exclus car n'est pas utilisé pour obtenir un objet directement requis pour %RELIC%", ES: null, DE: null },
	//#endregion Items

	//#region Submit Translations
	"Language Set": { EN: "Language Set", FR: "Langage enregistré", ES: null, DE: null },
	"site": { EN: "Site", FR: "Site", ES: "Sitio", DE: null },
	"game": { EN: "Game", FR: "Jeu", ES: "Juego", DE: null },
	"Show only my changes": { EN: "Show only my changes", FR: "Afficher uniquement mes changements", ES: null, DE: null },
	"Submit Changes": { EN: "Submit Changes", FR: "Soumettre des changements", ES: null, DE: null },
	"Translations": { EN: "Translations", FR: "Traduction", ES: "Traducciones", DE: null },
	"Show only missing translations": { EN: "Show only missing translations", FR: "Afficher uniquement les traductions manquantes", ES: "Ver solo traducciones faltantes", DE: null },
	"Translations Submited": { EN: "Translations Submited", FR: "Traductions soumises", ES: null, DE: null },
	"Error submitting translations": { EN: "Error submitting translations", FR: "Une erreur est survenue en soumettant des traductions", ES: null, DE: null },
	"Text between and including '%' are placeholders and should be preserved in the translation": { EN: "Text between and including '%' are placeholders and should be preserved in the translation", FR: "Les textes entres '%' sont des noms de variables et doivent être préservé lors de la traduction", ES: null, DE: null },
	"Select or Enter your language": { EN: "Select or Enter your language", FR: "Sélectionner ou entrer votre langue", ES: null, DE: null },
	"Existing Translation": { EN: "Existing Translation", FR: "Traduction existante", ES: "Traducción Existente", DE: null },
	"New Translation": { EN: "New Translation", FR: "Nouvelle traduction", ES: "Traducción Nueva", DE: null },
	"Submitted Translation": { EN: "Submitted Translation", FR: "Traduction soumise", ES: null, DE: null },
	"Use this page to submit translations": { EN: "Use this page to submit translations", FR: "Utilisez cette page pour soumettre vos traductions", ES: null, DE: null },
	"Either select the language you wish to submit translations for from the dropdown, or enter a new language code to add the new language": { EN: "Either select the language you wish to submit translations for from the dropdown, or enter a new language code to add the new language", FR: "Vous pouvez sélectionner un langage dans lequel vous voulez traduire dans la liste déroulante, ou entrer un nouveau code de langage", ES: null, DE: null },
	"Submissions are not automatically added to the site, and are sent for review": { EN: "Submissions are not automatically added to the site, and are sent for review", FR: "Les traductions sont envoyés pour revue, et ne sont pas automatiquement mises à jour sur le site", ES: null, DE: null },
	"Tracked submissions are cleared when leaving this page": { EN: "Tracked submissions are cleared when leaving this page", FR: "Les traductions soumises seront effacées quand vous quitterez cette page", ES: null, DE: null },
	//#endregion Submit Translations

	//#region Gallery
	"By Job": { EN: "By Job", FR: "Par Job", ES: null, DE: null },
	"By Relic": { EN: "By Relic", FR: "Par Relique", ES: null, DE: null },
	"Select jobs to filter": { EN: "Select jobs to filter", FR: "Sélectionnez un job à filtrer", ES: null, DE: null },
	"No images available for this job": { EN: "No images available for this job", FR: "Aucune image disponible pour ce job", ES: null, DE: null },
	//#endregion Gallery

	//#region Progress
	"Relic": { EN: "Relic", FR: "Relique", ES: "Reliquia", DE: null },
	"Relic Progress By Job": { EN: "Relic Progress By Job", FR: "Progrès de vos reliques par Job", ES: null, DE: null },
	"Finished Parts": { EN: "Finished Parts", FR: "Etapes accomplies", ES: "Partes Terminadas", DE: null },
	"Complete Relics": { EN: "Complete Relics", FR: "Reliques complétés", ES: "Reliquias Completas", DE: null },
	"Total Parts": { EN: "Total Parts", FR: "Étapes totales", ES: "Partes Totales", DE: null },
	"Parts Finished": { EN: "Parts Finished", FR: "Étapes complétées", ES: "Partes Terminadas", DE: null },
	"% Finished": { EN: "% Finished", FR: "% terminés", ES: null, DE: null },
	"% Complete": { EN: "% Complete", FR: "% complétés", ES: null, DE: null },
	"Relics Complete": { EN: "Relics Complete", FR: "Relique terminée", ES: "Reliquias Completas", DE: null },
	"Total Relics": { EN: "Total Relics", FR: "Reliques totales", ES: "Reliquias Totales", DE: null },
	"Relic Progress By Relic": { EN: "Relic Progress By Relic", FR: "Progrès par type de relique", ES: null, DE: null },
	"Parts Completed": { EN: "Parts Completed", FR: "Étapes complétées", ES: "Partes Completas", DE: null },
	"Jobs Finished": { EN: "Jobs Finished", FR: "Jobs terminés", ES: null, DE: null },
	"Jobs Complete": { EN: "Jobs Complete", FR: "Jobs complétés", ES: null, DE: null },
	"Total Jobs": { EN: "Total Jobs", FR: "Jobs totaux", ES: null, DE: null },
	"Parts": { EN: "Parts", FR: "Étapes", ES: "Partes", DE: null },
	"Compact View": { EN: "Compact View", FR: "Vue compacte", ES: null, DE: null },
	"Parts Progress By Job": { EN: "Parts Progress By Job", FR: "Progrès des étapes par Job", ES: null, DE: null },
	"Parts Progress By Relic": { EN: "Parts Progress By Relic", FR: "Progrès des étapes par Relique", ES: null, DE: null },
	"Job Progress By Relic": { EN: "Job Progress By Relic", FR: "Progrès des Jobs par Relique", ES: null, DE: null },
	//#endregion Progress

	//#region Weapon - Zodiac
	"Books can be purchased from G'jusana in Mor Dhona (x23, y7) for 100 Allagan Tomestones of Poetics": { EN: "Books can be purchased from G'jusana in Mor Dhona (x23, y7) for 100 Allagan Tomestones of Poetics", FR: "Les livres sont achetables auprès de G'jusana à Mor Dhona (x23, y7) pour 100 Mémoquartz allagois poétiques", ES: null, DE: null }, // TODO: Add placeholders
	"You can only carry 1 book at a time": { EN: "You can only carry 1 book at a time", FR: "Vous ne pouvez transporter qu'un seul livre à la fois", ES: null, DE: null },
	"is only required for the first job": { EN: "is only required for the first job", FR: "n'est requis que la première fois", ES: null, DE: null }, // TODO: Figure out better way of doing this line in case context is lost in translations
	"The quest follows the steps below": { EN: "The quest follows the steps below", FR: "La quête suit les étapes suivantes", ES: null, DE: null },
	"The Broken Weapon is obtained during the first parts of the quest": { EN: "The Broken Weapon is obtained during the first parts of the quest", FR: "L'arme cassée est obtenue lors des premières étapes de la quête", ES: null, DE: null },
	"Each job requires a specific weapon with 2 Grade III Materia melded": { EN: "Each job requires a specific weapon with 2 Grade III Materia melded", FR: "Chaque job demande un arme spécifique avec 2 materia de grade III serti", ES: null, DE: null },
	"While in Mor Dhona for this step, Purchase 1x %OIL% & 3x %MIST% for each job, you will need these later": { EN: "While in Mor Dhona for this step, Purchase 1x %OIL% & 3x %MIST% for each job, you will need these later", FR: "De passage à Mor Dhona, profitez en pour acheter 1x %OIL% et 3x %MIST%, vous en aurez besoin", ES: null, DE: null },
	"Each job has a specific set of enemies to hunt": { EN: "Each job has a specific set of enemies to hunt", FR: "Chaque job a sa propre liste d'ennemis", ES: null, DE: null },
	"Complete all Job Quests (level 50) for your chosen jobs": { EN: "Complete all Job Quests (level 50) for your chosen jobs", FR: "Complétez toutes les quetes de job jusqu'au niveau 50 pour les jobs désirés", ES: null, DE: null },
	"Dungeons can be run unsynced, and you only need your relic equipped for the final boss": { EN: "Dungeons can be run unsynced, and you only need your relic equipped for the final boss", FR: "Les donjons peuvent être fait désynchronisés (Sans restriction), et vous n'avez besoin d'avoir l'arme équipée que pour le boss final", ES: null, DE: null },
	"%TAMTARA% and %AURUM% are considered to be good dungeons to grind": { EN: "%TAMTARA% and %AURUM% are considered to be good dungeons to grind", FR: "%TAMTARA% et %AURUM% sont considérés comme des bons donjons à farmer", ES: null, DE: null },
	"No Activity": { EN: "No Activity", FR: null, ES: "Sin Actividad", DE: null },
	"Indistinct Activity": { EN: "Indistinct Activity", FR: null, ES: "Actividad Indistinta", DE: null },
	"Faint Activity": { EN: "Faint Activity", FR: null, ES: "Actividad Débil", DE: null },
	"Slight Activity": { EN: "Slight Activity", FR: null, ES: "Actividad Ligera", DE: null },
	"Modest Activity": { EN: "Modest Activity", FR: null, ES: "Actividad Modesta", DE: null },
	"Distinct Activity": { EN: "Distinct Activity", FR: null, ES: "Actividad Distinta", DE: null },
	"Robust Activity": { EN: "Robust Activity", FR: null, ES: "Actividad Robusta", DE: null },
	"Vigorous Activity": { EN: "Vigorous Activity", FR: null, ES: "Actividad Vigorosa", DE: null },
	"Intense Activity": { EN: "Intense Activity", FR: null, ES: "Actividad Intensa", DE: null },
	"Extreme Activity": { EN: "Extreme Activity", FR: null, ES: "Actividad Extrema", DE: null },
	"Bursting Activity": { EN: "Bursting Activity", FR: null, ES: null, DE: null },
	"Add Feeble Light": { EN: "Add Feeble Light", FR: null, ES: null, DE: null },
	"Add Gentle Light": { EN: "Add Gentle Light", FR: null, ES: null, DE: null },
	"Add Bright Light": { EN: "Add Bright Light", FR: null, ES: null, DE: null },
	"Add Brilliant Light": { EN: "Add Brilliant Light", FR: null, ES: null, DE: null },
	"Add Blinding Light": { EN: "Add Blinding Light", FR: null, ES: null, DE: null },
	"Add Newborn Star Light": { EN: "Add Newborn Star Light", FR: null, ES: null, DE: null },
	"This step requires 75 of various Grade I-IV materia along with the Alexandrite and Sphere Scroll from the previous steps": { EN: "This step requires 75 of various Grade I-IV materia along with the Alexandrite and Sphere Scroll from the previous steps", FR: "Cette étape requiert 75 materia de grade I-IV - Avec l'alexandrite et le parchemin des etapes précedentes", ES: null, DE: null },
	"The materia you choose will effect the stats on the created weapon": { EN: "The materia you choose will effect the stats on the created weapon", FR: "La materia utilisée va affetcter les caractéristiques de l'arme", ES: null, DE: null },
	"You can track your progress through the Sphere Scroll": { EN: "You can track your progress through the Sphere Scroll", FR: null, ES: null, DE: null },
	"Take the below items to the Furnace in Hyrstmill, North Shroud (x30, y20), to upgrade your Base Relic": { EN: "Take the below items to the Furnace in Hyrstmill, North Shroud (x30, y20), to upgrade your Base Relic", FR: "Utilisez les objets suivant sur le fourneau au Moulin des Bois, Forêt du Nord(x30, y20) afin d'améliorer votre arme", ES: null, DE: null }, // TODO: Placeholders
	"The Final step of the Zodiac Relic weapon - Achieve Full Soul Resonance!": { EN: "The Final step of the Zodiac Relic weapon - Achieve Full Soul Resonance!", FR: "La dernière étape des armes du Zodiaque - Atteindre la résonnance totale!", ES: null, DE: null },
	"Speak to Remon to affix a Mahatama to your weapon - You must then gain 'Soul Resonance' from various ARR activities": { EN: "Speak to Remon to affix a Mahatama to your weapon - You must then gain 'Soul Resonance' from various ARR activities", FR: "Achetez vos Mahâtma auprès de Remon, au Martinet (50 poetiques par Mahâtma) - Vous devez ensuite les faire résonner en participant à differentes activités de ARR", ES: null, DE: null },
	"%AURUM% and %SASTASHA% can fully resonate in 2 runs (1 if bonus is active)": { EN: "%AURUM% and %SASTASHA% can fully resonate in 2 runs (1 if bonus is active)", FR: "%AURUM% et %SASTASHA% peuvent remplir un Mahatma en 2 runs (1 si le bonus est actif)", ES: null, DE: null },
	"No Sense": { EN: "No Sense", FR: "Aucun sentiment", ES: null, DE: null },
	"Indisctinct Sense": { EN: "Indisctinct Sense", FR: "Vague sentiment", ES: null, DE: null },
	"Faint Sense": { EN: "Faint Sense", FR: "Faible sentiment", ES: null, DE: null },
	"Slight Sense": { EN: "Slight Sense", FR: "Léger sentiment", ES: null, DE: null },
	"Modest Sense": { EN: "Modest Sense", FR: "Modeste sentiment", ES: null, DE: null },
	"Distinct Sense": { EN: "Distinct Sense", FR: "Certain sentiment", ES: null, DE: null },
	"Robust Sense": { EN: "Robust Sense", FR: "Robuste sentiment", ES: null, DE: null },
	"Vigorous Sense": { EN: "Vigorous Sense", FR: "Vigoureux sentiment", ES: null, DE: null },
	"Intense Sense": { EN: "Intense Sense", FR: "Intense sentiment", ES: null, DE: null },
	"Extreme Sense": { EN: "Extreme Sense", FR: "Extreme sentiment", ES: null, DE: null },
	"Full Soul Resonance": { EN: "Full Soul Resonance", FR: "Débordant", ES: null, DE: null },
	"Feeble": { EN: "Feeble", FR: "Résonne faiblement", ES: null, DE: null },
	"Faint": { EN: "Faint", FR: "Résonne vaguement", ES: null, DE: null },
	"Gentle": { EN: "Gentle", FR: "Résonne doucement", ES: null, DE: null },
	"Steady": { EN: "Steady", FR: "Résonne clairement", ES: null, DE: null },
	"Forceful": { EN: "Forceful", FR: null, ES: null, DE: null },
	"Nigh Sings": { EN: "Nigh Sings", FR: null, ES: null, DE: null },
	"Primals EX": { EN: "Primals EX", FR: "Primordiaux (Extremes)", ES: null, DE: null },
	"The Porta Decumana": { EN: "The Porta Decumana", FR: "La Porte Decumana", ES: null, DE: null },
	"The Borderland Ruins (Secure)": { EN: "The Borderland Ruins (Secure)", FR: "Les Ruines frontalières (annexion)", ES: null, DE: null },
	//#endregion Weapon - Zodiac

	//#region Weapon - Anima
	"In order to obtain the %PART% you must complete the quest": { EN: "In order to obtain the %PART% you must complete the quest", FR: "Afin d'obtenir %PART%, vous devez finir cette quête", ES: "Para poder obtener %PART%, debes completar la misión", DE: null },
	"Obtain this item by collecting and exchanging the following with": { EN: "Obtain this item by collecting and exchanging the following with", FR: "Obtenez cet objet en collectant et echangeant l'objet suivant avec", ES: null, DE: null },
	"Obtain 1 of each of the following crystals OR you can trade in your %ZODIACZETA% Weapon to complete the quest": { EN: "Obtain 1 of each of the following crystals OR you can trade in your %ZODIACZETA% Weapon to complete the quest", FR: "Obtenez 1 de chacun des cristaux suivant OU vous pouvez donner votre arme %ZODIACZETA% pour completer cette quête", ES: "Obtén 1 de cada uno de los siguientes cristales, O puedes entregar tu arma %ZODIACZETA% para completar la misión", DE: null },
	"If you choose to trade in your weapon, beware it will be permanently lost! however you can obtain replicas from Drake if you have completed %VITALTITLE%": { EN: "If you choose to trade in your weapon, beware it will be permanently lost! however you can obtain replicas from Drake if you have completed %VITALTITLE%", FR: "Attention: Si vous faites le choix de donner votre arme, elle sera définitivement perdu! Mais vous pouvez en obtenir une replique pour en copier l'apparence auprès de Drake si vous avez fini %VITALTITLE%", ES: "Sí escoges entregar tu arma, ten en cuenta que la perderás permanentemente! Sin embargo, sí has completado %VITALTITLE% ,puedes obtener una réplica de Drake", DE: null },
	"The relic weapon is not required for these dungeons": { EN: "The relic weapon is not required for these dungeons", FR: "Il n'est pas obligatoire d'avoir la relique équipée pour ces donjons", ES: null, DE: null },
	"Complete the following dungeons": { EN: "Complete the following dungeons", FR: "Finissez les donjons suivants", ES: null, DE: null },
	"Rune": { EN: "Rune", FR: "Rune", ES: null, DE: null },
	"Runes": { EN: "Runes", FR: "Runes", ES: null, DE: null },
	"Add Vague condensation": { EN: "Add Vague condensation", FR: null, ES: null, DE: null },
	"Add Meager condensation": { EN: "Add Meager condensation", FR: null, ES: null, DE: null },
	"Add Vigorous condensation": { EN: "Add Vigorous condensation", FR: null, ES: null, DE: null },
	"Add Robust condensation": { EN: "Add Robust condensation", FR: null, ES: null, DE: null },
	"Add Sturdy condensation": { EN: "Add Sturdy condensation", FR: null, ES: null, DE: null },
	"Add Hardended condensation": { EN: "Add Hardended condensation", FR: null, ES: null, DE: null },
	"Add Stalwart condensation": { EN: "Add Stalwart condensation", FR: null, ES: null, DE: null },
	"You may receive bonus Treated Crystal Sand when trading in items and therefore may need to do fewer hand ins": { EN: "You may receive bonus Treated Crystal Sand when trading in items and therefore may need to do fewer hand ins", FR: null, ES: null, DE: null },
	//#endregion Weapon - Anima

	//#region Weapon - Eureka
	"Talk to Gerolt once you have obtained the relevant items to upgrade your weapon": { EN: "Talk to Gerolt once you have obtained the relevant items to upgrade your weapon", FR: "Parlez à Gerolt une fois tout les objets requis acquis pour améliorer votre arme", ES: "Habla con Gerolt una vez que hayas obtenido los objetos relevantes para mejorar tu arma", DE: null }, // TODO: Placeholders
	"Rewarded from level 70 job quest - If you have lost or discarded it, you can purchase a replacement for 1,000 Gil from any of the following": { EN: "Rewarded from level 70 job quest - If you have lost or discarded it, you can purchase a replacement for 1,000 Gil from any of the following", FR: "Récompense de quête de job niveau 70 - Si vous ne l'avez plus, vous pouvez le racheter pour 1,000 Gils auprès d'un des PNJ suivant", ES: null, DE: null }, // TODO: Placeholder for Gil
	"You will need to follow the storyline for Eureka & Reach %LEVEL% elemental level in order to unlock and access %AREA%": { EN: "You will need to follow the storyline for Eureka & Reach %LEVEL% elemental level in order to unlock and access %AREA%", FR: "Vous devez avoir suffisament avancer dans l'histoire d'Eureka et avoir un niveau élémentaire de %LEVEL% afin d'accéder à %AREA%", ES: null, DE: null },
	"Elemental Level %LEVEL%": { EN: "Elemental Level %LEVEL%", FR: "Niveau élémentaire %LEVEL%", ES: "Nivel Elemental %LEVEL%", DE: null },
	"Complete 'And We Shall Call It Pagos' from Rodney in Kugane (x9, y14)": { EN: "Complete 'And We Shall Call It Pagos' from Rodney in Kugane (x9, y14)", FR: "Finir 'Pagos, la terre de glace' auprès de Rodney à Kugane (x9, y14)", ES: null, DE: null }, // TODO: Replace mini-quest with placeholder
	"Complete 'And We Shall Call It Pyros' from Rodney in Kugane (x9, y14)": { EN: "Complete 'And We Shall Call It Pyros' from Rodney in Kugane (x9, y14)", FR: "Finir 'Pyros, la terre de glace et de feu' auprès de Rodney à Kugane (x9, y14)", ES: null, DE: null }, // TODO: Replace mini-quest with placeholder
	"Complete 'And We Shall Call It Hydatos' from Rodney in Kugane (x9, y14)": { EN: "Complete 'And We Shall Call It Hydatos' from Rodney in Kugane (x9, y14)", FR: "Finir 'Hydatos, la fin du voyage' auprès de Rodney à Kugane (x9, y14)", ES: null, DE: null }, // TODO: Replace mini-quest with placeholder
	"This upgrade does not change the appearance of the weapon, but it adds elemental stats for use in Eureka - So may be ignored if only getting the weapon for glamour": { EN: "This upgrade does not change the appearance of the weapon, but it adds elemental stats for use in Eureka - So may be ignored if only getting the weapon for glamour", FR: "Cette amélioration ne change pas l'apparence de l'arme mais lui ajoute des charactéristiques élémentaire pour utilisation à Eureka - Peut donc être ignoré si vous ne voulez l'arme que pour son apparence", ES: null, DE: null },
	"Show %PART% steps": { EN: "Show %PART% steps", FR: "Afficher les étapes %PART%", ES: null, DE: null },
	"Ignoring %PART% Step": { EN: "Ignoring %PART% Step", FR: "Ignore l'étape %PART%", ES: null, DE: null },
	"You have selected to ignore this step": { EN: "You have selected to ignore this step", FR: "Vous avez choisi d'ignorer cette étape", ES: null, DE: null },
	//#endregion Weapon - Eureka

	//#region Weapon - Resistance
	"Complete the following quest chain to begin this relic": { EN: "Complete the following quest chain to begin this relic", FR: "Finir la chaine de quête suivante pour commencer la relique", ES: "Completa la siguiente cadena de misiones para comenzar esta reliquia", DE: null },
	"Clear Castrum Lacus Litore": { EN: "Clear Castrum Lacus Litore", FR: "Finir Castrum Lacus Litore", ES: null, DE: null }, // TODO: Placeholder Castrum Lacus Litore / Game Translate it
	"This will reward your first relic, subsequent jobs must complete": { EN: "This will reward your first relic, subsequent jobs must complete", FR: "Cela va améliorer votre première relique, les jobs suivants doivent completer", ES: null, DE: null },
	"Complete Stormblood Alliance Raid questline finishing with": { EN: "Complete Stormblood Alliance Raid questline finishing with", FR: "Finir la suite de quête des Raids en alliance Stormblood, finissant avec", ES: null, DE: null },
	//#endregion Weapon - Resistance

	//#region Weapon - Manderville

	//#endregion Weapon - Manderville

	//#region Tool - Lucis
	"Complete level 50 job quest": { EN: "Complete level 50 job quest", FR: "Achevez la quête de job de niveau 50", ES: null, DE: null },
	"You require a %ITEM% for each job along with another crafted item": { EN: "You require a %ITEM% for each job along with another crafted item", FR: "Vous avez besoin de %ITEM% pour chaque job, avec également l'objet fabriqué", ES: null, DE: null },
	"Trade in the following items at %SHOP% to obtain your %RELIC% relic": { EN: "Trade in the following items at %SHOP% to obtain your %RELIC% relic", FR: "Echangez les items suivants auprès de %SHOP% pour obtenir votre relique %RELIC%", ES: null, DE: null },
	//#endregion Tool - Lucis

	//#region Tool - Skysteel
	"After completing %QUEST% - The rest of the base tools can be purchased from %SHOP% for 80,000 %CURRENCY% each (800,000 total for all tools)": { EN: "After completing %QUEST% - The rest of the base tools can be purchased from %SHOP% for 80,000 %CURRENCY% each (800,000 total for all tools)", FR: "Après avoir fini %QUEST% - Le reste des outils de base peuvent être achetés auprès de %SHOP% pour 80,000 %CURRENCY% chacun (800,000 pour tout les outils)", ES: null, DE: null },
	//#endregion Tool - Skysteel

	//#region Tool - Splendorous
	"Complete 'The Boutique Always Wins' from Mowen in Eulmore (x11, y11)": { EN: "Complete 'The Boutique Always Wins' from Mowen in Eulmore (x11, y11)", FR: null, ES: null, DE: null },
	"After completing %QUEST% - The rest of the base tools can be purchased from %SHOP% for 1,500 %CURRENCY1% or %CURRENCY2% each (12,00 %CURRENCY1% and 4,500 %CURRENCY2% in total)": { EN: "After completing %QUEST% - The rest of the base tools can be purchased from %SHOP% for 1,500 %CURRENCY1% or %CURRENCY2% each (12,00 %CURRENCY1% and 4,500 %CURRENCY2% in total)", FR: null, ES: null, DE: null },
	"Both fish provide the same component": { EN: "Both fish provide the same component", FR: null, ES: null, DE: null },
	"Quantity column shows the number of that single fish is required, where as the To Obtain column takes inventory from both fish into account": { EN: "Quantity column shows the number of that single fish is required, where as the To Obtain column takes inventory from both fish into account", FR: null, ES: null, DE: null },
	//#endregion Tool - Splendorous

	//#region Armour - Anemos

	//#endregion Armour - Anemos

	//#region Armour - Elemental

	//#endregion Armour - Elemental

	//#region Armour - Bozjan

	//#endregion Armour - Bozjan

	//#region Armour - Blade's

	//#endregion Armour - Blade's

	//#endregion Pages

	//#region Page Parts
	"%JOB% - %RELICNAME%": { EN: "%JOB% - %RELICNAME%", FR: "%JOB% - %RELICNAME%", ES: "%JOB% - %RELICNAME%", DE: null },
	"Hide Completed Steps": { EN: "Hide Completed Steps", FR: "Cacher les étapes complètes", ES: null, DE: null },
	"Hide Completed Tasks": { EN: "Hide Completed Tasks", FR: "Cacher les tâches complètes", ES: null, DE: null },
	"Click to expand": { EN: "Click to expand", FR: "Cliquez pour étendre", ES: null, DE: null },
	"Important NPCs": { EN: "Important NPCs", FR: "PNJ importants", ES: "NPCs Importantes", DE: null },
	"No NPCs of note": { EN: "No NPCs of note", FR: "Aucun PNJ à noter", ES: null, DE: null },
	"Overview": { EN: "Overview", FR: "Vue générale", ES: null, DE: null },
	"Jump to details": { EN: "Jump to details", FR: "Allez aux détails", ES: null, DE: null },
	"Requirements": { EN: "Requirements", FR: "Prérequis", ES: "Requerimientos", DE: null },
	"No additional requirements": { EN: "No additional requirements", FR: null, ES: null, DE: null },
	//#endregion Page Parts

	//#region Item Other Text
	"Mysterious Maps - These can be obtained from Auriana in Mor Dhona, or from the daily quest 'Morbid Motivation' also in Mor Dhona": { EN: "Mysterious Maps - These can be obtained from Auriana in Mor Dhona, or from the daily quest 'Morbid Motivation' also in Mor Dhona", FR: "Cartes mystèrieuses - Pouvant être obtenu auprès de Auriana à Mor Dhona, ou avec la quête répétable 'Echange de bons procédés' auprès de Brangwine, aussi à Mor Dhona", ES: null, DE: null }, // TODO: Placeholders
	"ARR FATEs (Must have Relic equipped)": { EN: "ARR FATEs (Must have Relic equipped)", FR: "ALEA ARR (Pensez à avoir votre arme relique equippée)", ES: null, DE: null },
	"Bought with Allied Seals": { EN: "Bought with Allied Seals", FR: "Achetable avec des Insignes Alliés", ES: null, DE: null }, // TODO: Replace "Allied Seals" with placeholder
	"Gather 'Light' from various ARR activites (ARR Dungeons, ARR Trials, ARR FATEs, Treasure Hunt, Alexandrite Maps, The Wolves' Den and Frontlines)": { EN: "Gather 'Light' from various ARR activites (ARR Dungeons, ARR Trials, ARR FATEs, Treasure Hunt, Alexandrite Maps, The Wolves' Den and Frontlines)", FR: "Accumulez de la 'lumière' en effectuant diverses activités ARR (Donjons, Défis, ALEAs, chasses aux trésors, Carte aux trésors d'alexandrite, PvP)", ES: null, DE: null },
	"These need to be upgraded similar to 'Light' from the Nexus upgrade - 40 Points are required - Points are obtained from ARR dungeons, trials, raids": { EN: "These need to be upgraded similar to 'Light' from the Nexus upgrade - 40 Points are required - Points are obtained from ARR dungeons, trials, raids", FR: null, ES: null, DE: null },
	"Hand in any of the following groups of items for 2x Crystal Sand": { EN: "Hand in any of the following groups of items for 2x Crystal Sand", FR: "Donnez n'importe lequel des groupes d'objets suivant pour 2 Sable de cristal", ES: "Intercambia cualquiera de los siguientes grupos de objetos para obtener 2x Crystal Sand", DE: null }, // TODO: Replace items with placeholders
	"5x Rowena's Token (Blue Crafters' Scrip)": { EN: "5x Rowena's Token (Blue Crafters' Scrip)", FR: "5x Avoir d'artisan (bleu)", ES: null, DE: null }, // TODO: Replace items with placeholders.,
	"5x Rowena's Token (Blue Gatherers' Scrip)": { EN: "5x Rowena's Token (Blue Gatherers' Scrip)", FR: "5x Avoir de récolteur (bleu)", ES: null, DE: null }, // TODO: Replace items with placeholders
	"1x Garlond Steel, 1x Celestine, 1x High Allagan Chimera Leather": { EN: "1x Garlond Steel, 1x Celestine, 1x High Allagan Chimera Leather", FR: null, ES: null, DE: null }, // TODO: Replace items with placeholders
	"1x Amber-encased Vilekin": { EN: "1x Amber-encased Vilekin", FR: "1x Morceau d'ambre à insecte", ES: null, DE: null }, // TODO: Replace items with placeholders
	"3x Battlecraft Demimateria I": { EN: "3x Battlecraft Demimateria I", FR: "3x Demi-matéria de l'art militaire I", ES: null, DE: null }, // TODO: Replace items with placeholders
	"2x Battlecraft Demimateria II": { EN: "2x Battlecraft Demimateria II", FR: "2x Demi-matéria de l'art militaire II", ES: null, DE: null }, // TODO: Replace items with placeholders
	"3x Fieldcraft Demimateria I": { EN: "3x Fieldcraft Demimateria I", FR: "3x Demi-matéria de l'artifice I", ES: null, DE: null }, // TODO: Replace items with placeholders
	"2x Fieldcraft Demimateria II": { EN: "2x Fieldcraft Demimateria II", FR: "2x Demi-matéria de l'artifice II", ES: null, DE: null }, // TODO: Replace items with placeholders
	"5x Moonstone": { EN: "5x Moonstone", FR: "5x Pierre de lune", ES: null, DE: null }, // TODO: Replace items with placeholders
	"1x Inferno Horn": { EN: "1x Inferno Horn", FR: "1x Corne du Seigneur des flammes", ES: null, DE: null }, // TODO: Replace items with placeholders
	"1x Crag Heart": { EN: "1x Crag Heart", FR: "1x Roc de l'Ébranleur des terres", ES: null, DE: null }, // TODO: Replace items with placeholders
	"1x Diamond Tear": { EN: "1x Diamond Tear", FR: "1x Larme de la Furie des neiges", ES: null, DE: null }, // TODO: Replace items with placeholders
	"1x Vortex Feather": { EN: "1x Vortex Feather", FR: "1x Plume de la Souffleuse de rafales", ES: null, DE: null }, // TODO: Replace items with placeholders
	"1x Barb of the Whorl": { EN: "1x Barb of the Whorl", FR: "1x Barbillon du Déchaîneur des marées", ES: null, DE: null }, // TODO: Replace items with placeholders
	"1x Large Levin Orb": { EN: "1x Large Levin Orb", FR: "1x Orbe du Patriarche fulgurant", ES: null, DE: null }, // TODO: Replace items with placeholders
	"5x Superior Enchanted Ink": { EN: "5x Superior Enchanted Ink", FR: "5x Encre enchantée de haute qualité", ES: null, DE: null }, // TODO: Replace items with placeholders
	"5x Thavnairian Mist": { EN: "5x Thavnairian Mist", FR: "5x Lymphe de Thavnair", ES: null, DE: null }, // TODO: Replace items with placeholders
	"2x Mhachi Farthing": { EN: "2x Mhachi Farthing", FR: "2x Pièce mhachoise", ES: null, DE: null }, // TODO: Replace items with placeholders
	"5x Allagan Catalyst": { EN: "5x Allagan Catalyst", FR: "5x Catalyseur allagois", ES: null, DE: null }, // TODO: Replace items with placeholders
	"1x Unidentifiable Bone": { EN: "1x Unidentifiable Bone", FR: "1x Fragment d'os mystérieux", ES: null, DE: null }, // TODO: Replace items with placeholders
	"1x Unidentifiable Shell": { EN: "1x Unidentifiable Shell", FR: "1x Coquillage mystérieux", ES: null, DE: null }, // TODO: Replace items with placeholders
	"1x Unidentifiable Ore": { EN: "1x Unidentifiable Ore", FR: "1x Gemme brute mystérieuse", ES: null, DE: null }, // TODO: Replace items with placeholders
	"1x Unidentifiable Seeds": { EN: "1x Unidentifiable Seeds", FR: "1x Graines mystérieuses", ES: null, DE: null }, // TODO: Replace items with placeholders
	"Aetheric Density points can be gained by the following": { EN: "Aetheric Density points can be gained by the following", FR: null, ES: null, DE: null },
	"HW Dungeons": { EN: "HW Dungeons", FR: "Donjons Heavensward", ES: null, DE: null },
	"HW Trials": { EN: "HW Trials", FR: "Défis Heavensward", ES: null, DE: null },
	"HW Raids": { EN: "HW Raids", FR: "Raids Heavensward", ES: null, DE: null },
	"HW FATEs": { EN: "HW FATEs", FR: "ALEA Heavensward", ES: null, DE: null },
	"Level 50 ARR Dungeons, except MSQ": { EN: "Level 50 ARR Dungeons, except MSQ", FR: "Donjons niveau 50 (ARR), sauf Praetorium et Castrum Meridianum", ES: null, DE: null }, // TODO: Change "MSQ" in english to Praetorium and Castrum, Use placeholders?
	"Drops from any mob in Eureka": { EN: "Drops from any mob in Eureka", FR: "Obtenu en tuant n'importe quel ennemi d'Eureka", ES: null, DE: null },
	"Trade in Anemos Crystals which are obtained from FATEs in Eureka": { EN: "Trade in Anemos Crystals which are obtained from FATEs in Eureka", FR: "Echangez vos Cristaux Anemos que vous obtenez lors des ALEAs d'Eurêka", ES: null, DE: null },
	"Fill Kettle with Vitiated Aether and each full bar can be converted at the Crystal Forge into Frosted Protean Crystals": { EN: "Fill Kettle with Vitiated Aether and each full bar can be converted at the Crystal Forge into Frosted Protean Crystals", FR: "Remplissez votre bouilloire d'aether. Chaque barre pleine peut être convertie en cristaux instable de glace à la Forge de Cristal", ES: null, DE: null },
	"FATEs in Eureka Pagos": { EN: "FATEs in Eureka Pagos", FR: "ALEA de Eureka Pagos", ES: null, DE: null }, // TODO: Placholders for Eureka map names
	"FATEs in Eureka Pyros": { EN: "FATEs in Eureka Pyros", FR: "ALEAs de Eureka Pyros", ES: null, DE: null }, // TODO: Placholders for Eureka map names
	"FATEs in Eureka Hydatos": { EN: "FATEs in Eureka Hydatos", FR: "ALEA de Eureka Hydatos", ES: null, DE: null }, // TODO: Placholders for Eureka map names
	"Obtained from Baldesion Arsenal": { EN: "Obtained from Baldesion Arsenal", FR: "Obtenu dans l'Arsenal de Baldesion", ES: null, DE: null }, // TOOD: Placeholder - Baldesion Arsenal is in game translations
	"Any level 60 dungeon": { EN: "Any level 60 dungeon", FR: "N'importe quel donjon niveau 60", ES: null, DE: null },
	"Leveling Roulette (Only once per day)": { EN: "Leveling Roulette (Only once per day)", FR: "Roulette Gain de niveau (seulement une fois par jour)", ES: null, DE: null },
	"Activities in the Bozjan Southern Front": { EN: "Activities in the Bozjan Southern Front", FR: "Activités du Front sud de Bozja", ES: null, DE: null },
	"Palace of the Dead": { EN: "Palace of the Dead", FR: "Palais des morts", ES: null, DE: null },
	"1 per Skirmish in Zadnor (Zone 1)": { EN: "1 per Skirmish in Zadnor (Zone 1)", FR: "1 par escarmouche dans Zadnor (Zone 1)", ES: null, DE: null },
	"2 per Critical Engagement in Zadnor (Zone 1)": { EN: "2 per Critical Engagement in Zadnor (Zone 1)", FR: "2 par affrontement crucial dans Zadnor (Zone 1)", ES: null, DE: null },
	"1 per Skirmish in Zadnor (Zone 2)": { EN: "1 per Skirmish in Zadnor (Zone 2)", FR: "1 par escarmouche dans Zadnor (Zone 2)", ES: null, DE: null },
	"2 per Critical Engagement in Zadnor (Zone 2)": { EN: "2 per Critical Engagement in Zadnor (Zone 2)", FR: "2 par affrontement crucial dans Zadnor (Zone 2)", ES: null, DE: null },
	"1 per Skirmish in Zadnor (Zone 3)": { EN: "1 per Skirmish in Zadnor (Zone 3)", FR: "1 par escarmouche dans Zadnor (Zone 3)", ES: null, DE: null },
	"2 per Critical Engagement in Zadnor (Zone 3)": { EN: "2 per Critical Engagement in Zadnor (Zone 3)", FR: "2 par affrontement crucial dans Zadnor (Zone 3)", ES: null, DE: null },
	"1 per Alexander Raid clear (A1, A2, A5, A6, A9, A10)": { EN: "1 per Alexander Raid clear (A1, A2, A5, A6, A9, A10)", FR: "1 par Raid Alexander (Poing du Père / Poignet du Père / Poing du Fils / Poignet du Fils / Yeux du Créateur / Souffle du Créateur)", ES: null, DE: null },
	"1 per Alexander Raid clear (A3, A4, A7, A8, A11, A12)": { EN: "1 per Alexander Raid clear (A3, A4, A7, A8, A11, A12)", FR: "1 par Raid Alexander (Bras du Père / Fardeau du Père / Bras du Fils / Fardeau du Fils / Cœur du Créateur / Âme du Créateur)", ES: null, DE: null },
	"1 per Omega Raid clear (O1, O2, O5, O6, O9, O10)": { EN: "1 per Omega Raid clear (O1, O2, O5, O6, O9, O10)", FR: "1 par Raid Oméga (Deltastice v1.0/v2.0 , Sigmastice v1.0/v2.0, Alphastice v1.0/v2.0)", ES: null, DE: null },
	"1 per Omega Raid clear (O3, O4, O7, O8, O11, O12)": { EN: "1 per Omega Raid clear (O3, O4, O7, O8, O11, O12)", FR: "1 par Raid Oméga (Deltastice v3.0/v4.0 , Sigmastice v3.0/v4.0, Alphastice v3.0/v4.0)", ES: null, DE: null },
	"1 per Eden Raid clear (E1, E2, E5, E6, E9, E10)": { EN: "1 per Eden Raid clear (E1, E2, E5, E6, E9, E10)", FR: "1 par Raid Eden (Résurrection / Descente / Fulmination / Fureur / Nuée / Litanie)", ES: null, DE: null },
	"1 per Eden Raid clear (E3, E4, E7, E8, E11, E12)": { EN: "1 per Eden Raid clear (E3, E4, E7, E8, E11, E12)", FR: "1 par Raid Eden (Déluge / Inhumation / Iconoclasme / Eclat / Anamorphose / Eternité)", ES: null, DE: null },
	"3 per The Dalriada": { EN: "3 per The Dalriada", FR: "3 par Dalriada", ES: null, DE: null },
	"2 per Delubrum Reginae": { EN: "2 per Delubrum Reginae", FR: "2 par Delubrum Reginae", ES: null, DE: null },
	"1 per level 70 Dungeon": { EN: "1 per level 70 Dungeon", FR: "1 par donjon niveau 70", ES: null, DE: null },
	"1 per 10 floors of Heaven-on-High (This is not 100% - Chances increases on higher floors)": { EN: "1 per 10 floors of Heaven-on-High (This is not 100% - Chances increases on higher floors)", FR: "1 tout les 10 niveau du Pilier des Cieux (Ce n'est pas 100% de chances - Le taux de chance augmente plus vous êtes haut)", ES: null, DE: null },
	"Shards can be obtained from many places, for example": { EN: "Shards can be obtained from many places, for example", FR: "Les éclats peuvent être obtenu de plein d'endroits, par exemple", ES: "Los fragmentos pueden ser obtenidos de muchos lugares, por ejemplo", DE: null },
	"Crystals can be obtained from many places, for example": { EN: "Crystals can be obtained from many places, for example", FR: "Les cristaux peuvent être obtenu de plein d'endroits, par exemple", ES: "Los cristales pueden ser obtenidos de muchos lugares, por ejemplo", DE: null },
	"Clusters can be obtained from many places, for example": { EN: "Clusters can be obtained from many places, for example", FR: "Les agrégats peuvent être obtenu de plein d'endroits, par exemple", ES: null, DE: null },
	"Level 1-50 gathering nodes": { EN: "Level 1-50 gathering nodes", FR: "Points de récolte niveau 1-50", ES: null, DE: null },
	"Level 25+ gathering nodes": { EN: "Level 25+ gathering nodes", FR: "Points de récolte niveau 25+", ES: null, DE: null },
	"Level 50+ gathering nodes": { EN: "Level 50+ gathering nodes", FR: "Points de récolte niveau 50+", ES: null, DE: null },
	"Sprites / Elementals of the relevant element": { EN: "Sprites / Elementals of the relevant element", FR: null, ES: null, DE: null },
	"Gardening": { EN: "Gardening", FR: "Jardinage", ES: null, DE: null },
	"Aetherial Reduction": { EN: "Aetherial Reduction", FR: null, ES: "Reducción Etérica", DE: null },
	//#endregion Item Other Text

	//#region Relic Steps
	"s_broken_weapon": { EN: "Broken Weapon", FR: "Arme brisée", ES: "Arma Rota", DE: null }, // Zodiac - Base Weapon
	"s_class_weapon": { EN: "Class Weapon", FR: "Arme de classe", ES: "Arma de Clase", DE: null },
	"s_chimera": { EN: "Complete %TRIAL%", FR: "Finir %TRIAL%", ES: "Completa %TRIAL%", DE: null },
	"s_amdapor": { EN: "Complete %DUNGEON%", FR: "Finir %DUNGEON%", ES: "Completa %DUNGEON%", DE: null },
	"s_beastman": { EN: "Beastman Hunt", FR: null, ES: null, DE: null },
	"s_hydra": { EN: "Complete %TRIAL%", FR: "Finir %TRIAL%", ES: "Completa %TRIAL%", DE: null },
	"s_ember": { EN: "Obtain %ITEM%", FR: "Obtenir %ITEM%", ES: "Obtén %ITEM%", DE: null },
	"s_gale": { EN: "Obtain %ITEM%", FR: "Obtenir %ITEM%", ES: "Obtén %ITEM%", DE: null },
	"s_ore": { EN: "Obtain %ITEM%", FR: "Obtenir %ITEM%", ES: "Obtén %ITEM%", DE: null },
	"s_oil": { EN: "Obtain %ITEM%", FR: "Obtenir %ITEM%", ES: "Obtén %ITEM%", DE: null },
	"s_mist": { EN: "Obtain %ITEM%", FR: "Obtenir %ITEM%", ES: "Obtén %ITEM%", DE: null }, // Zodiac - Zenith
	"s_arms": { EN: "Complete %QUEST%", FR: "Finir %QUEST%", ES: "Completa %QUEST%", DE: null }, // Zodiac - Atma
	"s_atmas": { EN: "Obtain %ITEM%", FR: "Obtenir %ITEM%", ES: "Obtén %ITEM%", DE: null },
	"s_trials": { EN: "Complete %BOOK%", FR: "Finir %BOOK%", ES: "Completa %BOOK%", DE: null }, // Zodiac - Animus
	"s_skyfire_1": { EN: "Complete %BOOK%", FR: "Finir %BOOK%", ES: "Completa %BOOK%", DE: null },
	"s_skyfire_2": { EN: "Complete %BOOK%", FR: "Finir %BOOK%", ES: "Completa %BOOK%", DE: null },
	"s_netherfire_1": { EN: "Complete %BOOK%", FR: "Finir %BOOK%", ES: "Completa %BOOK%", DE: null },
	"s_skyfall_1": { EN: "Complete %BOOK%", FR: "Finir %BOOK%", ES: "Completa %BOOK%", DE: null },
	"s_skyfall_2": { EN: "Complete %BOOK%", FR: "Finir %BOOK%", ES: "Completa %BOOK%", DE: null },
	"s_netherfall_1": { EN: "Complete %BOOK%", FR: "Finir %BOOK%", ES: "Completa %BOOK%", DE: null },
	"s_skywind_1": { EN: "Complete %BOOK%", FR: "Finir %BOOK%", ES: "Completa %BOOK%", DE: null },
	"s_skywind_2": { EN: "Complete %BOOK%", FR: "Finir %BOOK%", ES: "Completa %BOOK%", DE: null },
	"s_skyearth_1": { EN: "Complete %BOOK%", FR: "Finir %BOOK%", ES: "Completa %BOOK%", DE: null },
	"s_radiance": { EN: "Complete %QUEST%", FR: "Finir %QUEST%", ES: "Completa %QUEST%", DE: null }, // Zodiac - Novus
	"s_scroll": { EN: "Obtain %ITEM%", FR: "Obtenir %ITEM%", ES: "Obtén %ITEM%", DE: null },
	"s_star": { EN: "Complete %QUEST%", FR: "Finir %QUEST%", ES: "Completa %QUEST%", DE: null },
	"s_alexandrite": { EN: "Obtain %ITEM%", FR: "Obtenir %ITEM%", ES: "Obtén %ITEM%", DE: null },
	"s_binding": { EN: "Materia Binding", FR: null, ES: null, DE: null },
	"s_soulglaze": { EN: "Complete %QUEST%", FR: "Finir %QUEST%", ES: "Completa %QUEST%", DE: null }, // Zodiac - Nexus
	"s_light": { EN: "Obtain %ITEM%", FR: "Obtenir %ITEM%", ES: "Obtén %ITEM%", DE: null },
	"s_wherefore": { EN: "Complete %QUEST%", FR: "Finir %QUEST%", ES: "Completa %QUEST%", DE: null }, // Zodiac - Zodiac Braves
	"s_skylight": { EN: "Obtain %ITEM%", FR: "Obtenir %ITEM%", ES: "Obtén %ITEM%", DE: null },
	"s_zodium": { EN: "Obtain %ITEM%", FR: "Obtenir %ITEM%", ES: "Obtén %ITEM%", DE: null },
	"s_flawless_alexandrite": { EN: "Obtain %ITEM%", FR: "Obtenir %ITEM%", ES: "Obtén %ITEM%", DE: null },
	"s_braves_scroll": { EN: "Obtain %ITEM%", FR: "Obtenir %ITEM%", ES: "Obtén %ITEM%", DE: null },
	"s_materia": { EN: "Complete %QUEST%", FR: "Finir %QUEST%", ES: "Completa %QUEST%", DE: null },
	"s_shine": { EN: "Complete %QUEST%", FR: "Finir %QUEST%", ES: "Completa %QUEST%", DE: null }, // Zodiac - Zodiac Zeta
	"s_soul": { EN: "Soul Resonate %ITEM%", FR: null, ES: null, DE: null },
	"s_vital": { EN: "Complete %QUEST%", FR: "Finir %QUEST%", ES: "Completa %QUEST%", DE: null },
	"s_crystals": { EN: "Obtain Crystals", FR: "Obtenir Cristaux", ES: "Obtén Cristales", DE: null }, // Anima - Animated
	"s_snowcloak": { EN: "Complete %DUNGEON%", FR: "Finir %DUNGEON%", ES: "Completa %DUNGEON%", DE: null }, // Anima - Awoken
	"s_sastasha": { EN: "Complete %DUNGEON%", FR: "Finir %DUNGEON%", ES: "Completa %DUNGEON%", DE: null },
	"s_qarn": { EN: "Complete %DUNGEON%", FR: "Finir %DUNGEON%", ES: "Completa %DUNGEON%", DE: null },
	"s_keeper": { EN: "Complete %DUNGEON%", FR: "Finir %DUNGEON%", ES: "Completa %DUNGEON%", DE: null },
	"s_wanderer": { EN: "Complete %DUNGEON%", FR: "Finir %DUNGEON%", ES: "Completa %DUNGEON%", DE: null },
	"s_amdapor_awoken": { EN: "Complete %DUNGEON%", FR: "Finir %DUNGEON%", ES: "Completa %DUNGEON%", DE: null },
	"s_vigil": { EN: "Complete %DUNGEON%", FR: "Finir %DUNGEON%", ES: "Completa %DUNGEON%", DE: null },
	"s_sohm": { EN: "Complete %DUNGEON%", FR: "Finir %DUNGEON%", ES: "Completa %DUNGEON%", DE: null },
	"s_aery": { EN: "Complete %DUNGEON%", FR: "Finir %DUNGEON%", ES: "Completa %DUNGEON%", DE: null },
	"s_vault": { EN: "Complete %DUNGEON%", FR: "Finir %DUNGEON%", ES: "Completa %DUNGEON%", DE: null },
	"s_rubber": { EN: "Obtain %ITEM%", FR: "Obtenir %ITEM%", ES: "Obtén %ITEM%", DE: null }, // Anima - Anima
	"s_carboncoat": { EN: "Obtain %ITEM%", FR: "Obtenir %ITEM%", ES: "Obtén %ITEM%", DE: null },
	"s_water": { EN: "Obtain %ITEM%", FR: "Obtenir %ITEM%", ES: "Obtén %ITEM%", DE: null },
	"s_catalyst": { EN: "Obtain %ITEM%", FR: "Obtenir %ITEM%", ES: "Obtén %ITEM%", DE: null },
	"s_oil_hyper": { EN: "Obtain %ITEM%", FR: "Obtenir %ITEM%", ES: "Obtén %ITEM%", DE: null }, // Anima - Hyperconductive
	"s_sand": { EN: "Obtain Treated Crystal Sand", FR: null, ES: null, DE: null}, // Anima - Reconditioned
	"s_clusters": { EN: "Obtain %ITEM%", FR: "Obtenir %ITEM%", ES: "Obtén %ITEM%", DE: null }, // Anima - Sharpened
	"s_dungeons": { EN: "Complete Dungeons", FR: "Finir les donjons", ES: null, DE: null }, // Anima - Complete
	"s_pneumite": { EN: "Obtain %ITEM%", FR: "Obtenir %ITEM%", ES: "Obtén %ITEM%", DE: null },
	"s_density": { EN: "Obtain %ITEM%", FR: "Obtenir %ITEM%", ES: "Obtén %ITEM%", DE: null },
	"s_bff": { EN: "Complete %QUEST%", FR: "Finir %QUEST%", ES: "Completa %QUEST%", DE: null }, // Anima - Lux
	"s_antiquated": { EN: "Obtain Antiquated Weapon", FR: "Obtenir l'arme vieillie", ES: "Obtén Arma Anticuada", DE: null }, // Eureka - Antiquated
	"s_anemos_0": { EN: "Antiquated + 0", FR: "Vieilli + 0", ES: "Anticuado + 0", DE: null }, // Eureka - Anemos
	"s_anemos_1": { EN: "Antiquated + 1", FR: "Vieilli + 1", ES: "Anticuado + 1", DE: null },
	"s_anemos_2": { EN: "Antiquated + 2", FR: "Vieilli + 2", ES: "Anticuado + 2", DE: null },
	"s_anemos": { EN: "Obtain %ITEM%", FR: "Obtenir %ITEM%", ES: "Obtén %ITEM%", DE: null },
	"s_pagos": { EN: "Obtain %ITEM%", FR: "Obtenir %ITEM%", ES: "Obtén %ITEM%", DE: null }, // Eureka - Elemental
	"s_pagos_1": { EN: "Obtain %ITEM%", FR: "Obtenir %ITEM%", ES: "Obtén %ITEM%", DE: null },
	"s_elemental": { EN: "Obtain %ITEM%", FR: "Obtenir %ITEM%", ES: "Obtén %ITEM%", DE: null },
	"s_elemental_1": { EN: "Obtain %ITEM%", FR: "Obtenir %ITEM%", ES: "Obtén %ITEM%", DE: null }, // Eureka - Pyros
	"s_elemental_2": { EN: "Obtain %ITEM%", FR: "Obtenir %ITEM%", ES: "Obtén %ITEM%", DE: null },
	"s_pyros": { EN: "Obtain %ITEM%", FR: "Obtenir %ITEM%", ES: "Obtén %ITEM%", DE: null },
	"s_hydatos": { EN: "Obtain %ITEM%", FR: "Obtenir %ITEM%", ES: "Obtén %ITEM%", DE: null }, // Eureka - Eureka
	"s_hydatos_1": { EN: "Obtain %ITEM%", FR: "Obtenir %ITEM%", ES: "Obtén %ITEM%", DE: null },
	"s_base": { EN: "Obtain Base %ITEM%", FR: null, ES: "Obtén %ITEM%", DE: null },
	"s_eureka": { EN: "Obtain %ITEM%", FR: "Obtenir %ITEM%", ES: "Obtén %ITEM%", DE: null },
	"s_fragments": { EN: "Obtain %ITEM%", FR: "Obtenir %ITEM%", ES: "Obtén %ITEM%", DE: null }, // Eureka - Physeos
	"s_scalepowder": { EN: "Obtain %ITEM%", FR: "Obtenir %ITEM%", ES: "Obtén %ITEM%", DE: null }, // Resistance - Resistance
	"s_memory": { EN: "Complete %QUEST%", FR: "Finir %QUEST%", ES: "Completa %QUEST%", DE: null }, // Resistance - Augmented Resistance
	"s_resist": { EN: "Complete %QUEST%", FR: "Finir %QUEST%", ES: "Completa %QUEST%", DE: null }, // Resistance - Recollection
	"s_change_of_arms": { EN: "Complete %QUEST%", FR: "Finir %QUEST%", ES: "Completa %QUEST%", DE: null }, // Resistance - Laws Order
	"s_remembers": { EN: "Complete %QUEST%", FR: "Finir %QUEST%", ES: "Completa %QUEST%", DE: null }, // Resistance - Augmented Laws Order
	"s_path": { EN: "Complete %QUEST%", FR: "Finir %QUEST%", ES: "Completa %QUEST%", DE: null },
	"s_spare_parts": { EN: "Complete %QUEST%", FR: "Finir %QUEST%", ES: "Completa %QUEST%", DE: null }, // Resistance - Blade
	"s_story": { EN: "Complete %QUEST%", FR: "Finir %QUEST%", ES: "Completa %QUEST%", DE: null },
	"s_fond_memory": { EN: "Complete %QUEST%", FR: "Finir %QUEST%", ES: "Completa %QUEST%", DE: null },
	"s_deal": { EN: "Complete %QUEST%", FR: "Finir %QUEST%", ES: "Completa %QUEST%", DE: null },
	"s_irresistible": { EN: "Complete %QUEST%", FR: "Finir %QUEST%", ES: "Completa %QUEST%", DE: null },
	"s_manderville": { EN: "Obtain %ITEM%", FR: "Obtenir %ITEM%", ES: "Obtén %ITEM%", DE: null }, // Manderville - Manderville
	"s_amazing_manderville": { EN: "Obtain %ITEM%", FR: "Obtenir %ITEM%", ES: "Obtén %ITEM%", DE: null }, // Manderville - Amazing Manderville
	"s_majestic_manderville": { EN: "Obtain %ITEM%", FR: "Obtenir %ITEM%", ES: "Obtén %ITEM%", DE: null }, // Manderville - Majestic Manderville
	"s_mandervillous": { EN: "Obtain %ITEM%", FR: "Obtenir %ITEM%", ES: "Obtén %ITEM%", DE: null }, // Manderville - Mandervillous
	"s_class_quests": { EN: "Class Quests", FR: "Quêtes de classes", ES: "Misión de Clase", DE: null }, // Lucis - Masterwork
	"s_supra_items": { EN: "Hand in items", FR: "Donner les objets", ES: "Entrega objetos", DE: null }, // Lucis - Supra
	"s_lucis_items": { EN: "Hand in items", FR: "Donner les objets", ES: "Entrega objetos", DE: null }, // Lucis - Lucis
	"s_purchase": { EN: "Purchase Base Tool", FR: "Acheter l'outil de base", ES: "Compra Herramienta Base", DE: null }, // Skysteel - Skysteel
	"s_skysteel_items": { EN: "Hand in items", FR: "Donner les objets", ES: "Entrega objetos", DE: null }, // Skysteel - Skysteel + 1
	"s_dragonsung_items": { EN: "Hand in items", FR: "Donner les objets", ES: "Entrega objetos", DE: null }, // Skysteel - Dragonsung
	"s_basic_gobbiegoo": { EN: "Obtain %ITEM%", FR: "Obtenir %ITEM%", ES: "Obtén %ITEM%", DE: null }, // Skysteel - Augmented Dragonsung
	"s_viscous_gobbiegoo": { EN: "Obtain %ITEM%", FR: "Obtenir %ITEM%", ES: "Obtén %ITEM%", DE: null }, // Skysteel - Skysung
	"s_parts": { EN: "Obtain %ITEM%", FR: "Obtenir %ITEM%", ES: "Obtén %ITEM%", DE: null }, // Skysteel - Skybuilders
	"s_splendorous_purchase": { EN: "Purchase Base Tool", FR: "Acheter l'outil de base", ES: null, DE: null }, // Splendorous
	"s_splendorous_component": { EN: "Obtain %ITEM%", FR: "Obtenir %ITEM%", ES: "Obtén %ITEM%", DE: null }, // Splendorous - Augmented Splendorous
	"s_adaptive_component": { EN: "Obtain %ITEM%", FR: "Obtenir %ITEM%", ES: "Obtén %ITEM%", DE: null }, // Splendorous - Crystalline
	"s_customised_component": { EN: "Obtain %ITEM%", FR: "Obtenir %ITEM%", ES: "Obtén %ITEM%", DE: null }, // Splendorous - Chora Zoi's Crystalline
	"s_brilliant_component": { EN: "Obtain %ITEM%", FR: "Obtenir %ITEM%", ES: "Obtén %ITEM%", DE: null }, // Splendorous - Brilliant
	"s_inspirational_component": { EN: "Obtain %ITEM%", FR: "Obtenir %ITEM%", ES: "Obtén %ITEM%", DE: null }, // Splendorous - Vrandtic Visionary's
	"s_nightforged_component": { EN: "Obtain %ITEM%", FR: "Obtenir %ITEM%", ES: "Obtén %ITEM%", DE: null }, // Splendorous - Lodestar
	//#endregion Relic Steps
};
export default siteTranslations;
