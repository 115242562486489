/* eslint-disable max-len */
//#region Imports
import { jobLists } from "character";
import FormComponents from "components/forms/FormComponents";
import JobIcon from "components/job-icon/JobIcon";
import Link from "components/link/Link";
import NoCharacter from "components/no-character/NoCharacter";
import RelicCheckbox from "components/relic-checkbox/RelicCheckbox";
import { items } from "data-files/items";
import { quests } from "data-files/quests";
import { EorzeaDataType, InputType, Item, Job, LinkType, NPC, Quest, RelicGroup, RelicPart, RelicStep, RelicTask, RelicType, View } from "enums";
import manager from "managers/app";
import { Component } from "react";
import { connect } from "react-redux";
import t from "translations/translator";
import { AppState, Requirement, TaskInfo, ViewRelicInfo, ViewToolSkysteelDragonsungProps } from "types";
import utils from "utils";
import ContentHeader from "views/page-parts/content/content-header/ContentHeader";
import DetailsSection from "views/page-parts/content/details-section/DetailsSection";
import Obtained from "views/page-parts/content/obtained/Obtained";
import OverviewTable from "views/page-parts/content/overview-table/OverviewTable";
import "./ToolSkysteelDragonsung.css";
//#endregion Imports

const mapStateToProps = (state:AppState) => {
	return {
		lang: state.userInfo.options.lang,
		gameLang: state.userInfo.options.gameLang,
		userInfo: state.userInfo,
		hideCompletedSteps: state.userInfo.options.hideCompletedSteps,
		hideCompletedTasks: state.userInfo.options.hideCompletedTasks,
		showJob: state.userInfo.options.job,
	};
};

class ToolSkysteelDragonsung extends Component<ViewToolSkysteelDragonsungProps> {
	private requirements:Requirement[] = [];
	private importantNPCs:NPC[] = [];
	private relicInfo:ViewRelicInfo = {
		jobs: jobLists.relic[RelicGroup.TOOL_SKYSTEEL],
		type: RelicType.TOOL,
		relic: RelicGroup.TOOL_SKYSTEEL,
		part: RelicPart.TOOL_DRAGONSUNG,
		steps: [RelicStep.DRAGONSUNG_ITEMS],
		stepPlaceholders: {},
		next: {
			part: RelicPart.TOOL_AUGMENTED_DRAGONSUNG,
			view: View.RELIC_TOOLS_SKYSTEEL_AUGMENTED_DRAGONSUNG,
		},
		previous: {
			part: RelicPart.TOOL_SKYSTEEL_1,
			view: View.RELIC_TOOLS_SKYSTEEL_SKYSTEEL_1,
		},
	};

	constructor(props:ViewToolSkysteelDragonsungProps){
		super(props);
		this.requirements = this.getRequirements();
		this.importantNPCs = this.getImportantNPCs();
	}

	render(){
		const character = manager.data.getActiveCharacter();
		if(character === null){ return <NoCharacter />; }

		const completeSteps = manager.content.getCompleteSteps(character.progress, this.relicInfo);

		let row = "odd-row";

		return (
			<div id="tool-skysteel-dragonsung" className="relic">
				<ContentHeader requirements={this.requirements} importantNPCs={this.importantNPCs} relicInfo={this.relicInfo}>
					<p>{t.t("The %PART% upgrade follows on from %PREVIOUSPART%", { PART: this.relicInfo.part, PREVIOUSPART: this.relicInfo.previous ? this.relicInfo.previous.part : "Unknown"})}</p>
					<p>{t.t("Complete")}<Link type={LinkType.LODESTONE} lodestoneType={EorzeaDataType.BLUEQUEST} text={Quest.WORK_HARDR_MAKE_BETTER} lodestoneID={quests[Quest.WORK_HARDR_MAKE_BETTER].lodestoneID} /></p>
				</ContentHeader>
				<div id="relic-checklist">
					<OverviewTable character={character} completeSteps={completeSteps} relicInfo={this.relicInfo} />
				</div>
				<div id="relic-details">
					<h1>{t.t("Details")}</h1>
					{this.props.hideCompletedSteps && completeSteps.allComplete ? t.t("All Steps Complete") : null }
					<DetailsSection title={RelicStep.DRAGONSUNG_ITEMS} id={RelicStep.DRAGONSUNG_ITEMS} stepComplete={completeSteps[RelicStep.DRAGONSUNG_ITEMS]}>
						<div className="table-wrapper">
							<table className="table">
								<thead><tr><th>{t.t("Job")}</th><th>{t.t("Task")}</th><th>{t.t("Found")}</th><th>{t.t("Quantity")}</th><th>{t.t("Inventory")}</th><th>{t.t("To Obtain")}</th><th>{t.t("Done")}</th></tr></thead>
								<tbody>
									{
										this.relicInfo.jobs.map((job) => {
											if(this.props.showJob !== null && this.props.showJob !== job){ return null; }

											const stepComplete = character.progress.isComplete(this.relicInfo.type, this.relicInfo.relic, this.relicInfo.part, RelicStep.DRAGONSUNG_ITEMS, job);
											if(this.props.hideCompletedSteps && stepComplete){ return null; }

											const taskInfo:TaskInfo[] = [{ task: RelicTask.TASK_1, complete: character.progress.isComplete(this.relicInfo.type, this.relicInfo.relic, this.relicInfo.part, RelicStep.DRAGONSUNG_ITEMS, RelicTask.TASK_1, job), target: manager.relics.getTaskItem(RelicStep.DRAGONSUNG_ITEMS, RelicTask.TASK_1, job) }];

											if(jobLists.type.gatherer.includes(job) && job !== Job.FSH){
												taskInfo.push({ task: RelicTask.TASK_2, complete: character.progress.isComplete(this.relicInfo.type, this.relicInfo.relic, this.relicInfo.part, RelicStep.DRAGONSUNG_ITEMS, RelicTask.TASK_2, job), target: manager.relics.getTaskItem(RelicStep.DRAGONSUNG_ITEMS, RelicTask.TASK_2, job) });
											}

											const tableRows = taskInfo.map((thisTask, taskIndex) => {
												if(this.props.hideCompletedTasks && thisTask.complete){ return null; }

												let thisTarget = Item.UNKNOWN;
												if(utils.guards.isEnumValue(Item, thisTask.target)){ thisTarget = thisTask.target; }

												const inventory = manager.data.getInventoryStatus({
													item: thisTarget,
													relic: [this.relicInfo.type, this.relicInfo.relic, this.relicInfo.part, RelicStep.DRAGONSUNG_ITEMS, thisTask.task],
													job: job,
												});

												const showJobIcon = manager.content.shouldShowJobIcon(this.props.hideCompletedTasks, taskInfo, taskIndex);
												const rowSpan = manager.content.getRowSpan(this.props.hideCompletedTasks, taskInfo, taskIndex);

												return <tr key={`${job}-${RelicStep.DRAGONSUNG_ITEMS}-${thisTask.task}`} className={row}>
													{showJobIcon ? <td rowSpan={rowSpan}><JobIcon job={job} /></td> : null}
													<td>{t.t(thisTarget)}</td>
													<td><Obtained obtained={items[thisTarget].obtained} qtyNeeded={inventory.total} /></td>
													<td>{inventory.qtyPerJob.toLocaleString()}</td>
													<td><FormComponents.TextInput label={{ hide: true }} input={{ type: InputType.NUMBER, value: inventory.inventory, dataAttributes: { item: thisTarget }, events: { onChange: manager.content.inventoryUpdated }}} /></td>
													<td>{inventory.toObtain.toLocaleString()}</td>
													<td><RelicCheckbox character={character} job={job} relicInfo={this.relicInfo} step={RelicStep.DRAGONSUNG_ITEMS} task={thisTask.task} /></td>
												</tr>;
											});

											row = row === "odd-row" ? "even-row" : "odd-row";
											return tableRows;
										})
									}
									{
										completeSteps[RelicStep.DRAGONSUNG_ITEMS]
											? <tr className="all-complete"><td colSpan={7}><p>{t.t("All Complete")}</p></td></tr>
											: null
									}
								</tbody>
							</table>
						</div>
					</DetailsSection>
				</div>
			</div>
		);
	}

	componentDidMount(){
		manager.view.changeComplete();
	}

	private getRequirements():Requirement[]{
		if(!this.relicInfo || this.relicInfo.previous === null){ return []; }
		return [
			{ type: "previous part", part: this.relicInfo.previous.part, partView: this.relicInfo.previous.view },
		];
	}

	private getImportantNPCs():NPC[]{
		return [NPC.DENYS, NPC.NEILLEMARD];
	}
}
export default connect(mapStateToProps)(ToolSkysteelDragonsung);
