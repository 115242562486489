import { RelicGroup, RelicPart, RelicStep, RelicTask, RelicType } from "enums";

export const availableRelicIDs = [
	//#region Relic Types
	`${RelicType.WEAPON}`,
	`${RelicType.TOOL}`,
	`${RelicType.ARMOUR}`,
	//#endregion Relic Types

	//#region Relic Groups
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ANIMA}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_EUREKA}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_RESISTANCE}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_MANDERVILLE}`,
	`${RelicType.TOOL}-${RelicGroup.TOOL_LUCIS}`,
	`${RelicType.TOOL}-${RelicGroup.TOOL_SKYSTEEL}`,
	`${RelicType.TOOL}-${RelicGroup.TOOL_SPLENDOROUS}`,
	`${RelicType.ARMOUR}-${RelicGroup.ARMOUR_ANEMOS}`,
	`${RelicType.ARMOUR}-${RelicGroup.ARMOUR_ELEMENTAL}`,
	`${RelicType.ARMOUR}-${RelicGroup.ARMOUR_BOZJAN}`,
	`${RelicType.ARMOUR}-${RelicGroup.ARMOUR_LAWS_ORDER}`,
	`${RelicType.ARMOUR}-${RelicGroup.ARMOUR_BLADES}`,
	//#endregion Relic Groups

	//#region Relic Parts
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_BASE_WEAPON}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ZENITH}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ATMA}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_NOVUS}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_NEXUS}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_BRAVES}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ZETA}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ANIMA}-${RelicPart.WEAPON_ANIMATED}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ANIMA}-${RelicPart.WEAPON_AWOKEN}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ANIMA}-${RelicPart.WEAPON_ANIMA}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ANIMA}-${RelicPart.WEAPON_HYPERCONDUCTIVE}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ANIMA}-${RelicPart.WEAPON_RECONDITIONED}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ANIMA}-${RelicPart.WEAPON_SHARPENED}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ANIMA}-${RelicPart.WEAPON_COMPLETE}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ANIMA}-${RelicPart.WEAPON_LUX}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_EUREKA}-${RelicPart.WEAPON_ANTIQUATED}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_EUREKA}-${RelicPart.WEAPON_ANEMOS}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_EUREKA}-${RelicPart.WEAPON_ELEMENTAL}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_EUREKA}-${RelicPart.WEAPON_PYROS}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_EUREKA}-${RelicPart.WEAPON_EUREKA}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_EUREKA}-${RelicPart.WEAPON_PHYSEOS}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_RESISTANCE}-${RelicPart.WEAPON_RESISTANCE}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_RESISTANCE}-${RelicPart.WEAPON_AUGMENTED_RESISTANCE}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_RESISTANCE}-${RelicPart.WEAPON_RECOLLECTION}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_RESISTANCE}-${RelicPart.WEAPON_LAWS_ORDER}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_RESISTANCE}-${RelicPart.WEAPON_AUGMENTED_LAWS_ORDER}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_RESISTANCE}-${RelicPart.WEAPON_BLADE}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_MANDERVILLE}-${RelicPart.WEAPON_MANDERVILLE}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_MANDERVILLE}-${RelicPart.WEAPON_AMAZING_MANDERVILLE}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_MANDERVILLE}-${RelicPart.WEAPON_MAJESTIC_MANDERVILLE}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_MANDERVILLE}-${RelicPart.WEAPON_MANDERVILLOUS}`,
	`${RelicType.TOOL}-${RelicGroup.TOOL_LUCIS}-${RelicPart.TOOL_MASTERCRAFT}`,
	`${RelicType.TOOL}-${RelicGroup.TOOL_LUCIS}-${RelicPart.TOOL_SUPRA}`,
	`${RelicType.TOOL}-${RelicGroup.TOOL_LUCIS}-${RelicPart.TOOL_LUCIS}`,
	`${RelicType.TOOL}-${RelicGroup.TOOL_SKYSTEEL}-${RelicPart.TOOL_SKYSTEEL}`,
	`${RelicType.TOOL}-${RelicGroup.TOOL_SKYSTEEL}-${RelicPart.TOOL_SKYSTEEL_1}`,
	`${RelicType.TOOL}-${RelicGroup.TOOL_SKYSTEEL}-${RelicPart.TOOL_DRAGONSUNG}`,
	`${RelicType.TOOL}-${RelicGroup.TOOL_SKYSTEEL}-${RelicPart.TOOL_AUGMENTED_DRAGONSUNG}`,
	`${RelicType.TOOL}-${RelicGroup.TOOL_SKYSTEEL}-${RelicPart.TOOL_SKYSUNG}`,
	`${RelicType.TOOL}-${RelicGroup.TOOL_SKYSTEEL}-${RelicPart.TOOL_SKYBUILDERS}`,
	`${RelicType.TOOL}-${RelicGroup.TOOL_SPLENDOROUS}-${RelicPart.TOOL_SPLENDOROUS}`,
	`${RelicType.TOOL}-${RelicGroup.TOOL_SPLENDOROUS}-${RelicPart.TOOL_AUGMENTED_SPLENDOROUS}`,
	`${RelicType.TOOL}-${RelicGroup.TOOL_SPLENDOROUS}-${RelicPart.TOOL_CRYSTALLINE}`,
	`${RelicType.TOOL}-${RelicGroup.TOOL_SPLENDOROUS}-${RelicPart.TOOL_CHORA_ZOIS_CRYSTALLINE}`,
	`${RelicType.TOOL}-${RelicGroup.TOOL_SPLENDOROUS}-${RelicPart.TOOL_BRILLIANT}`,
	`${RelicType.TOOL}-${RelicGroup.TOOL_SPLENDOROUS}-${RelicPart.TOOL_VRANDTIC_VISIONARYS}`,
	`${RelicType.TOOL}-${RelicGroup.TOOL_SPLENDOROUS}-${RelicPart.TOOL_LODESTAR}`,
	`${RelicType.ARMOUR}-${RelicGroup.ARMOUR_ANEMOS}-${RelicPart.ARMOUR_ANTIQUATED}`,
	`${RelicType.ARMOUR}-${RelicGroup.ARMOUR_ANEMOS}-${RelicPart.ARMOUR_BASE_0}`,
	`${RelicType.ARMOUR}-${RelicGroup.ARMOUR_ANEMOS}-${RelicPart.ARMOUR_BASE_1}`,
	`${RelicType.ARMOUR}-${RelicGroup.ARMOUR_ANEMOS}-${RelicPart.ARMOUR_BASE_2}`,
	`${RelicType.ARMOUR}-${RelicGroup.ARMOUR_ANEMOS}-${RelicPart.ARMOUR_ANEMOS}`,
	`${RelicType.ARMOUR}-${RelicGroup.ARMOUR_ELEMENTAL}-${RelicPart.ARMOUR_ELEMENTAL}`,
	`${RelicType.ARMOUR}-${RelicGroup.ARMOUR_ELEMENTAL}-${RelicPart.ARMOUR_ELEMENTAL_1}`,
	`${RelicType.ARMOUR}-${RelicGroup.ARMOUR_ELEMENTAL}-${RelicPart.ARMOUR_ELEMENTAL_2}`,
	`${RelicType.ARMOUR}-${RelicGroup.ARMOUR_BOZJAN}-${RelicPart.ARMOUR_BOZJAN}`,
	`${RelicType.ARMOUR}-${RelicGroup.ARMOUR_BOZJAN}-${RelicPart.ARMOUR_AUGMENTED_BOZJAN}`,
	`${RelicType.ARMOUR}-${RelicGroup.ARMOUR_LAWS_ORDER}-${RelicPart.ARMOUR_LAWS_ORDER}`,
	`${RelicType.ARMOUR}-${RelicGroup.ARMOUR_LAWS_ORDER}-${RelicPart.ARMOUR_AUGMENTED_LAWS_ORDER}`,
	`${RelicType.ARMOUR}-${RelicGroup.ARMOUR_BLADES}-${RelicPart.ARMOUR_BLADES}`,
	//#endregion Relic Parts

	//#region Relic Steps
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_BASE_WEAPON}-${RelicStep.BROKEN_WEAPON}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_BASE_WEAPON}-${RelicStep.CLASS_WEAPON}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_BASE_WEAPON}-${RelicStep.CHIMERA}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_BASE_WEAPON}-${RelicStep.AMDAPOR}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_BASE_WEAPON}-${RelicStep.BEASTMAN}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_BASE_WEAPON}-${RelicStep.HYDRA}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_BASE_WEAPON}-${RelicStep.EMBER}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_BASE_WEAPON}-${RelicStep.GALE}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_BASE_WEAPON}-${RelicStep.ORE}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_BASE_WEAPON}-${RelicStep.OIL}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ZENITH}-${RelicStep.MIST}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ATMA}-${RelicStep.ARMS}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ATMA}-${RelicStep.ATMAS}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.TRIALS}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.SKYFIRE_1}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.SKYFIRE_2}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.NETHERFIRE_1}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.SKYFALL_1}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.SKYFALL_2}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.NETHERFALL_1}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.SKYWIND_1}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.SKYWIND_2}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.SKYEARTH_1}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_NOVUS}-${RelicStep.RADIANCE}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_NOVUS}-${RelicStep.SCROLL}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_NOVUS}-${RelicStep.STAR}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_NOVUS}-${RelicStep.ALEXANDRITE}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_NOVUS}-${RelicStep.BINDING}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_NEXUS}-${RelicStep.SOULGLAZE}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_NEXUS}-${RelicStep.LIGHT}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_BRAVES}-${RelicStep.WHEREFORE}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_BRAVES}-${RelicStep.SKYLIGHT}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_BRAVES}-${RelicStep.ZODIUM}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_BRAVES}-${RelicStep.FLAWLESS_ALEXANDRITE}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_BRAVES}-${RelicStep.BRAVES_SCROLL}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_BRAVES}-${RelicStep.MATERIA}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ZETA}-${RelicStep.SHINE}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ZETA}-${RelicStep.SOUL}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ZETA}-${RelicStep.VITAL}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ANIMA}-${RelicPart.WEAPON_ANIMATED}-${RelicStep.CRYSTALS}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ANIMA}-${RelicPart.WEAPON_AWOKEN}-${RelicStep.SNOWCLOAK}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ANIMA}-${RelicPart.WEAPON_AWOKEN}-${RelicStep.SASTASHA}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ANIMA}-${RelicPart.WEAPON_AWOKEN}-${RelicStep.QARN}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ANIMA}-${RelicPart.WEAPON_AWOKEN}-${RelicStep.KEEPER}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ANIMA}-${RelicPart.WEAPON_AWOKEN}-${RelicStep.WANDERER}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ANIMA}-${RelicPart.WEAPON_AWOKEN}-${RelicStep.AMDAPOR_AWOKEN}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ANIMA}-${RelicPart.WEAPON_AWOKEN}-${RelicStep.VIGIL}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ANIMA}-${RelicPart.WEAPON_AWOKEN}-${RelicStep.SOHM}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ANIMA}-${RelicPart.WEAPON_AWOKEN}-${RelicStep.AERY}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ANIMA}-${RelicPart.WEAPON_AWOKEN}-${RelicStep.VAULT}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ANIMA}-${RelicPart.WEAPON_ANIMA}-${RelicStep.RUBBER}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ANIMA}-${RelicPart.WEAPON_ANIMA}-${RelicStep.CARBONCOAT}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ANIMA}-${RelicPart.WEAPON_ANIMA}-${RelicStep.WATER}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ANIMA}-${RelicPart.WEAPON_ANIMA}-${RelicStep.CATALYST}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ANIMA}-${RelicPart.WEAPON_HYPERCONDUCTIVE}-${RelicStep.OIL_HYPER}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ANIMA}-${RelicPart.WEAPON_RECONDITIONED}-${RelicStep.SAND}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ANIMA}-${RelicPart.WEAPON_SHARPENED}-${RelicStep.CLUSTERS}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ANIMA}-${RelicPart.WEAPON_COMPLETE}-${RelicStep.DUNGEONS}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ANIMA}-${RelicPart.WEAPON_COMPLETE}-${RelicStep.PNEUMITE}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ANIMA}-${RelicPart.WEAPON_COMPLETE}-${RelicStep.DENSITY}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ANIMA}-${RelicPart.WEAPON_LUX}-${RelicStep.BFF}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_EUREKA}-${RelicPart.WEAPON_ANTIQUATED}-${RelicStep.ANTIQUATED}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_EUREKA}-${RelicPart.WEAPON_ANEMOS}-${RelicStep.ANEMOS_0}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_EUREKA}-${RelicPart.WEAPON_ANEMOS}-${RelicStep.ANEMOS_1}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_EUREKA}-${RelicPart.WEAPON_ANEMOS}-${RelicStep.ANEMOS_2}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_EUREKA}-${RelicPart.WEAPON_ANEMOS}-${RelicStep.ANEMOS}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_EUREKA}-${RelicPart.WEAPON_ELEMENTAL}-${RelicStep.PAGOS}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_EUREKA}-${RelicPart.WEAPON_ELEMENTAL}-${RelicStep.PAGOS_1}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_EUREKA}-${RelicPart.WEAPON_ELEMENTAL}-${RelicStep.ELEMENTAL}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_EUREKA}-${RelicPart.WEAPON_PYROS}-${RelicStep.ELEMENTAL_1}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_EUREKA}-${RelicPart.WEAPON_PYROS}-${RelicStep.ELEMENTAL_2}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_EUREKA}-${RelicPart.WEAPON_PYROS}-${RelicStep.PYROS}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_EUREKA}-${RelicPart.WEAPON_EUREKA}-${RelicStep.HYDATOS}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_EUREKA}-${RelicPart.WEAPON_EUREKA}-${RelicStep.HYDATOS_1}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_EUREKA}-${RelicPart.WEAPON_EUREKA}-${RelicStep.BASE}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_EUREKA}-${RelicPart.WEAPON_EUREKA}-${RelicStep.EUREKA}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_EUREKA}-${RelicPart.WEAPON_PHYSEOS}-${RelicStep.FRAGMENTS}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_RESISTANCE}-${RelicPart.WEAPON_RESISTANCE}-${RelicStep.SCALEPOWDER}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_RESISTANCE}-${RelicPart.WEAPON_AUGMENTED_RESISTANCE}-${RelicStep.MEMORY}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_RESISTANCE}-${RelicPart.WEAPON_RECOLLECTION}-${RelicStep.RESIST}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_RESISTANCE}-${RelicPart.WEAPON_LAWS_ORDER}-${RelicStep.CHANGE_OF_ARMS}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_RESISTANCE}-${RelicPart.WEAPON_AUGMENTED_LAWS_ORDER}-${RelicStep.REMEMBERS}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_RESISTANCE}-${RelicPart.WEAPON_AUGMENTED_LAWS_ORDER}-${RelicStep.PATH}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_RESISTANCE}-${RelicPart.WEAPON_BLADE}-${RelicStep.SPARE_PARTS}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_RESISTANCE}-${RelicPart.WEAPON_BLADE}-${RelicStep.STORY}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_RESISTANCE}-${RelicPart.WEAPON_BLADE}-${RelicStep.FOND_MEMORY}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_RESISTANCE}-${RelicPart.WEAPON_BLADE}-${RelicStep.DEAL}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_RESISTANCE}-${RelicPart.WEAPON_BLADE}-${RelicStep.IRRESISTIBLE}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_MANDERVILLE}-${RelicPart.WEAPON_MANDERVILLE}-${RelicStep.MANDERVILLE}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_MANDERVILLE}-${RelicPart.WEAPON_AMAZING_MANDERVILLE}-${RelicStep.AMAZING_MANDERVILLE}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_MANDERVILLE}-${RelicPart.WEAPON_MAJESTIC_MANDERVILLE}-${RelicStep.MAJESTIC_MANDERVILLE}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_MANDERVILLE}-${RelicPart.WEAPON_MANDERVILLOUS}-${RelicStep.MANDERVILLOUS}`,
	`${RelicType.TOOL}-${RelicGroup.TOOL_LUCIS}-${RelicPart.TOOL_MASTERCRAFT}-${RelicStep.CLASS_QUESTS}`,
	`${RelicType.TOOL}-${RelicGroup.TOOL_LUCIS}-${RelicPart.TOOL_SUPRA}-${RelicStep.SUPRA_ITEMS}`,
	`${RelicType.TOOL}-${RelicGroup.TOOL_LUCIS}-${RelicPart.TOOL_LUCIS}-${RelicStep.LUCIS_ITEMS}`,
	`${RelicType.TOOL}-${RelicGroup.TOOL_SKYSTEEL}-${RelicPart.TOOL_SKYSTEEL}-${RelicStep.PURCHASE}`,
	`${RelicType.TOOL}-${RelicGroup.TOOL_SKYSTEEL}-${RelicPart.TOOL_SKYSTEEL_1}-${RelicStep.SKYSTEEL_ITEMS}`,
	`${RelicType.TOOL}-${RelicGroup.TOOL_SKYSTEEL}-${RelicPart.TOOL_DRAGONSUNG}-${RelicStep.DRAGONSUNG_ITEMS}`,
	`${RelicType.TOOL}-${RelicGroup.TOOL_SKYSTEEL}-${RelicPart.TOOL_AUGMENTED_DRAGONSUNG}-${RelicStep.BASIC_GOBBIEGOO}`,
	`${RelicType.TOOL}-${RelicGroup.TOOL_SKYSTEEL}-${RelicPart.TOOL_SKYSUNG}-${RelicStep.VISCOUS_GOBBIEGOO}`,
	`${RelicType.TOOL}-${RelicGroup.TOOL_SKYSTEEL}-${RelicPart.TOOL_SKYBUILDERS}-${RelicStep.PARTS}`,
	`${RelicType.TOOL}-${RelicGroup.TOOL_SPLENDOROUS}-${RelicPart.TOOL_SPLENDOROUS}-${RelicStep.SPLENDOROUS_PURCHASE}`,
	`${RelicType.TOOL}-${RelicGroup.TOOL_SPLENDOROUS}-${RelicPart.TOOL_AUGMENTED_SPLENDOROUS}-${RelicStep.SPLENDOROUS_COMPONENT}`,
	`${RelicType.TOOL}-${RelicGroup.TOOL_SPLENDOROUS}-${RelicPart.TOOL_CRYSTALLINE}-${RelicStep.ADAPTIVE_COMPONENT}`,
	`${RelicType.TOOL}-${RelicGroup.TOOL_SPLENDOROUS}-${RelicPart.TOOL_CHORA_ZOIS_CRYSTALLINE}-${RelicStep.CUSTOMISED_COMPONENT}`,
	`${RelicType.TOOL}-${RelicGroup.TOOL_SPLENDOROUS}-${RelicPart.TOOL_BRILLIANT}-${RelicStep.BRILLIANT_COMPONENT}`,
	`${RelicType.TOOL}-${RelicGroup.TOOL_SPLENDOROUS}-${RelicPart.TOOL_VRANDTIC_VISIONARYS}-${RelicStep.INSPIRATIONAL_COMPONENT}`,
	`${RelicType.TOOL}-${RelicGroup.TOOL_SPLENDOROUS}-${RelicPart.TOOL_LODESTAR}-${RelicStep.NIGHTFORGED_COMPONENT}`,
	/* TODO: All Armour Steps
	`${RelicType.ARMOUR}-${RelicGroup.ARMOUR_ANEMOS}-${RelicPart.ARMOUR_ANTIQUATED}-${RelicStep.}`,
	`${RelicType.ARMOUR}-${RelicGroup.ARMOUR_ANEMOS}-${RelicPart.ARMOUR_UNANTIQUATED_0}-${RelicStep.}`,
	`${RelicType.ARMOUR}-${RelicGroup.ARMOUR_ANEMOS}-${RelicPart.ARMOUR_UNANTIQUATED_1}-${RelicStep.}`,
	`${RelicType.ARMOUR}-${RelicGroup.ARMOUR_ANEMOS}-${RelicPart.ARMOUR_UNANTIQUATED_2}-${RelicStep.}`,
	`${RelicType.ARMOUR}-${RelicGroup.ARMOUR_ANEMOS}-${RelicPart.ARMOUR_ANEMOS}-${RelicStep.}`,
	`${RelicType.ARMOUR}-${RelicGroup.ARMOUR_ELEMENTAL}-${RelicPart.ARMOUR_ELEMENTAL}-${RelicStep.}`,
	`${RelicType.ARMOUR}-${RelicGroup.ARMOUR_ELEMENTAL}-${RelicPart.ARMOUR_ELEMENTAL_1}-${RelicStep.}`,
	`${RelicType.ARMOUR}-${RelicGroup.ARMOUR_ELEMENTAL}-${RelicPart.ARMOUR_ELEMENTAL_2}-${RelicStep.}`,
	`${RelicType.ARMOUR}-${RelicGroup.ARMOUR_BOZJAN}-${RelicPart.ARMOUR_BOZJAN}-${RelicStep.}`,
	`${RelicType.ARMOUR}-${RelicGroup.ARMOUR_BOZJAN}-${RelicPart.ARMOUR_AUGMENTED_BOZJAN}-${RelicStep.}`,
	`${RelicType.ARMOUR}-${RelicGroup.ARMOUR_LAWS_ORDER}-${RelicPart.ARMOUR_LAWS_ORDER}-${RelicStep.}`,
	`${RelicType.ARMOUR}-${RelicGroup.ARMOUR_LAWS_ORDER}-${RelicPart.ARMOUR_AUGMENTED_LAWS_ORDER}-${RelicStep.}`,
	`${RelicType.ARMOUR}-${RelicGroup.ARMOUR_BLADES}-${RelicPart.ARMOUR_BLADES}-${RelicStep.}`,
	*/
	//#endregion Relic Steps

	//#region Relic Tasks
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_BASE_WEAPON}-${RelicStep.CLASS_WEAPON}-${RelicTask.TASK_1}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_BASE_WEAPON}-${RelicStep.CLASS_WEAPON}-${RelicTask.TASK_2}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_BASE_WEAPON}-${RelicStep.BEASTMAN}-${RelicTask.TASK_1}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_BASE_WEAPON}-${RelicStep.BEASTMAN}-${RelicTask.TASK_2}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_BASE_WEAPON}-${RelicStep.BEASTMAN}-${RelicTask.TASK_3}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_BASE_WEAPON}-${RelicStep.OIL}-${RelicTask.TASK_1}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ZENITH}-${RelicStep.MIST}-${RelicTask.TASK_1}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ATMA}-${RelicStep.ATMAS}-${RelicTask.TASK_1}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ATMA}-${RelicStep.ATMAS}-${RelicTask.TASK_2}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ATMA}-${RelicStep.ATMAS}-${RelicTask.TASK_3}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ATMA}-${RelicStep.ATMAS}-${RelicTask.TASK_4}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ATMA}-${RelicStep.ATMAS}-${RelicTask.TASK_5}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ATMA}-${RelicStep.ATMAS}-${RelicTask.TASK_6}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ATMA}-${RelicStep.ATMAS}-${RelicTask.TASK_7}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ATMA}-${RelicStep.ATMAS}-${RelicTask.TASK_8}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ATMA}-${RelicStep.ATMAS}-${RelicTask.TASK_9}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ATMA}-${RelicStep.ATMAS}-${RelicTask.TASK_10}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ATMA}-${RelicStep.ATMAS}-${RelicTask.TASK_11}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ATMA}-${RelicStep.ATMAS}-${RelicTask.TASK_12}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.SKYFIRE_1}-${RelicTask.TASK_1}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.SKYFIRE_1}-${RelicTask.TASK_2}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.SKYFIRE_1}-${RelicTask.TASK_3}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.SKYFIRE_1}-${RelicTask.TASK_4}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.SKYFIRE_1}-${RelicTask.TASK_5}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.SKYFIRE_1}-${RelicTask.TASK_6}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.SKYFIRE_1}-${RelicTask.TASK_7}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.SKYFIRE_1}-${RelicTask.TASK_8}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.SKYFIRE_1}-${RelicTask.TASK_9}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.SKYFIRE_1}-${RelicTask.TASK_10}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.SKYFIRE_1}-${RelicTask.TASK_11}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.SKYFIRE_1}-${RelicTask.TASK_12}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.SKYFIRE_1}-${RelicTask.TASK_13}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.SKYFIRE_1}-${RelicTask.TASK_14}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.SKYFIRE_1}-${RelicTask.TASK_15}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.SKYFIRE_1}-${RelicTask.TASK_16}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.SKYFIRE_1}-${RelicTask.TASK_17}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.SKYFIRE_1}-${RelicTask.TASK_18}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.SKYFIRE_1}-${RelicTask.TASK_19}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.SKYFIRE_2}-${RelicTask.TASK_1}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.SKYFIRE_2}-${RelicTask.TASK_2}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.SKYFIRE_2}-${RelicTask.TASK_3}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.SKYFIRE_2}-${RelicTask.TASK_4}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.SKYFIRE_2}-${RelicTask.TASK_5}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.SKYFIRE_2}-${RelicTask.TASK_6}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.SKYFIRE_2}-${RelicTask.TASK_7}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.SKYFIRE_2}-${RelicTask.TASK_8}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.SKYFIRE_2}-${RelicTask.TASK_9}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.SKYFIRE_2}-${RelicTask.TASK_10}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.SKYFIRE_2}-${RelicTask.TASK_11}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.SKYFIRE_2}-${RelicTask.TASK_12}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.SKYFIRE_2}-${RelicTask.TASK_13}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.SKYFIRE_2}-${RelicTask.TASK_14}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.SKYFIRE_2}-${RelicTask.TASK_15}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.SKYFIRE_2}-${RelicTask.TASK_16}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.SKYFIRE_2}-${RelicTask.TASK_17}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.SKYFIRE_2}-${RelicTask.TASK_18}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.SKYFIRE_2}-${RelicTask.TASK_19}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.NETHERFIRE_1}-${RelicTask.TASK_1}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.NETHERFIRE_1}-${RelicTask.TASK_2}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.NETHERFIRE_1}-${RelicTask.TASK_3}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.NETHERFIRE_1}-${RelicTask.TASK_4}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.NETHERFIRE_1}-${RelicTask.TASK_5}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.NETHERFIRE_1}-${RelicTask.TASK_6}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.NETHERFIRE_1}-${RelicTask.TASK_7}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.NETHERFIRE_1}-${RelicTask.TASK_8}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.NETHERFIRE_1}-${RelicTask.TASK_9}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.NETHERFIRE_1}-${RelicTask.TASK_10}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.NETHERFIRE_1}-${RelicTask.TASK_11}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.NETHERFIRE_1}-${RelicTask.TASK_12}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.NETHERFIRE_1}-${RelicTask.TASK_13}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.NETHERFIRE_1}-${RelicTask.TASK_14}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.NETHERFIRE_1}-${RelicTask.TASK_15}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.NETHERFIRE_1}-${RelicTask.TASK_16}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.NETHERFIRE_1}-${RelicTask.TASK_17}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.NETHERFIRE_1}-${RelicTask.TASK_18}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.NETHERFIRE_1}-${RelicTask.TASK_19}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.SKYFALL_1}-${RelicTask.TASK_1}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.SKYFALL_1}-${RelicTask.TASK_2}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.SKYFALL_1}-${RelicTask.TASK_3}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.SKYFALL_1}-${RelicTask.TASK_4}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.SKYFALL_1}-${RelicTask.TASK_5}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.SKYFALL_1}-${RelicTask.TASK_6}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.SKYFALL_1}-${RelicTask.TASK_7}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.SKYFALL_1}-${RelicTask.TASK_8}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.SKYFALL_1}-${RelicTask.TASK_9}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.SKYFALL_1}-${RelicTask.TASK_10}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.SKYFALL_1}-${RelicTask.TASK_11}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.SKYFALL_1}-${RelicTask.TASK_12}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.SKYFALL_1}-${RelicTask.TASK_13}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.SKYFALL_1}-${RelicTask.TASK_14}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.SKYFALL_1}-${RelicTask.TASK_15}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.SKYFALL_1}-${RelicTask.TASK_16}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.SKYFALL_1}-${RelicTask.TASK_17}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.SKYFALL_1}-${RelicTask.TASK_18}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.SKYFALL_1}-${RelicTask.TASK_19}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.SKYFALL_2}-${RelicTask.TASK_1}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.SKYFALL_2}-${RelicTask.TASK_2}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.SKYFALL_2}-${RelicTask.TASK_3}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.SKYFALL_2}-${RelicTask.TASK_4}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.SKYFALL_2}-${RelicTask.TASK_5}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.SKYFALL_2}-${RelicTask.TASK_6}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.SKYFALL_2}-${RelicTask.TASK_7}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.SKYFALL_2}-${RelicTask.TASK_8}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.SKYFALL_2}-${RelicTask.TASK_9}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.SKYFALL_2}-${RelicTask.TASK_10}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.SKYFALL_2}-${RelicTask.TASK_11}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.SKYFALL_2}-${RelicTask.TASK_12}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.SKYFALL_2}-${RelicTask.TASK_13}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.SKYFALL_2}-${RelicTask.TASK_14}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.SKYFALL_2}-${RelicTask.TASK_15}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.SKYFALL_2}-${RelicTask.TASK_16}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.SKYFALL_2}-${RelicTask.TASK_17}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.SKYFALL_2}-${RelicTask.TASK_18}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.SKYFALL_2}-${RelicTask.TASK_19}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.NETHERFALL_1}-${RelicTask.TASK_1}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.NETHERFALL_1}-${RelicTask.TASK_2}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.NETHERFALL_1}-${RelicTask.TASK_3}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.NETHERFALL_1}-${RelicTask.TASK_4}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.NETHERFALL_1}-${RelicTask.TASK_5}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.NETHERFALL_1}-${RelicTask.TASK_6}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.NETHERFALL_1}-${RelicTask.TASK_7}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.NETHERFALL_1}-${RelicTask.TASK_8}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.NETHERFALL_1}-${RelicTask.TASK_9}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.NETHERFALL_1}-${RelicTask.TASK_10}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.NETHERFALL_1}-${RelicTask.TASK_11}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.NETHERFALL_1}-${RelicTask.TASK_12}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.NETHERFALL_1}-${RelicTask.TASK_13}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.NETHERFALL_1}-${RelicTask.TASK_14}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.NETHERFALL_1}-${RelicTask.TASK_15}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.NETHERFALL_1}-${RelicTask.TASK_16}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.NETHERFALL_1}-${RelicTask.TASK_17}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.NETHERFALL_1}-${RelicTask.TASK_18}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.NETHERFALL_1}-${RelicTask.TASK_19}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.SKYWIND_1}-${RelicTask.TASK_1}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.SKYWIND_1}-${RelicTask.TASK_2}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.SKYWIND_1}-${RelicTask.TASK_3}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.SKYWIND_1}-${RelicTask.TASK_4}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.SKYWIND_1}-${RelicTask.TASK_5}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.SKYWIND_1}-${RelicTask.TASK_6}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.SKYWIND_1}-${RelicTask.TASK_7}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.SKYWIND_1}-${RelicTask.TASK_8}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.SKYWIND_1}-${RelicTask.TASK_9}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.SKYWIND_1}-${RelicTask.TASK_10}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.SKYWIND_1}-${RelicTask.TASK_11}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.SKYWIND_1}-${RelicTask.TASK_12}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.SKYWIND_1}-${RelicTask.TASK_13}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.SKYWIND_1}-${RelicTask.TASK_14}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.SKYWIND_1}-${RelicTask.TASK_15}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.SKYWIND_1}-${RelicTask.TASK_16}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.SKYWIND_1}-${RelicTask.TASK_17}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.SKYWIND_1}-${RelicTask.TASK_18}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.SKYWIND_1}-${RelicTask.TASK_19}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.SKYWIND_2}-${RelicTask.TASK_1}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.SKYWIND_2}-${RelicTask.TASK_2}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.SKYWIND_2}-${RelicTask.TASK_3}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.SKYWIND_2}-${RelicTask.TASK_4}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.SKYWIND_2}-${RelicTask.TASK_5}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.SKYWIND_2}-${RelicTask.TASK_6}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.SKYWIND_2}-${RelicTask.TASK_7}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.SKYWIND_2}-${RelicTask.TASK_8}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.SKYWIND_2}-${RelicTask.TASK_9}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.SKYWIND_2}-${RelicTask.TASK_10}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.SKYWIND_2}-${RelicTask.TASK_11}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.SKYWIND_2}-${RelicTask.TASK_12}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.SKYWIND_2}-${RelicTask.TASK_13}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.SKYWIND_2}-${RelicTask.TASK_14}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.SKYWIND_2}-${RelicTask.TASK_15}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.SKYWIND_2}-${RelicTask.TASK_16}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.SKYWIND_2}-${RelicTask.TASK_17}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.SKYWIND_2}-${RelicTask.TASK_18}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.SKYWIND_2}-${RelicTask.TASK_19}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.SKYEARTH_1}-${RelicTask.TASK_1}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.SKYEARTH_1}-${RelicTask.TASK_2}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.SKYEARTH_1}-${RelicTask.TASK_3}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.SKYEARTH_1}-${RelicTask.TASK_4}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.SKYEARTH_1}-${RelicTask.TASK_5}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.SKYEARTH_1}-${RelicTask.TASK_6}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.SKYEARTH_1}-${RelicTask.TASK_7}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.SKYEARTH_1}-${RelicTask.TASK_8}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.SKYEARTH_1}-${RelicTask.TASK_9}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.SKYEARTH_1}-${RelicTask.TASK_10}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.SKYEARTH_1}-${RelicTask.TASK_11}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.SKYEARTH_1}-${RelicTask.TASK_12}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.SKYEARTH_1}-${RelicTask.TASK_13}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.SKYEARTH_1}-${RelicTask.TASK_14}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.SKYEARTH_1}-${RelicTask.TASK_15}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.SKYEARTH_1}-${RelicTask.TASK_16}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.SKYEARTH_1}-${RelicTask.TASK_17}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.SKYEARTH_1}-${RelicTask.TASK_18}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ANIMUS}-${RelicStep.SKYEARTH_1}-${RelicTask.TASK_19}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_NOVUS}-${RelicStep.SCROLL}-${RelicTask.TASK_1}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_NOVUS}-${RelicStep.ALEXANDRITE}-${RelicTask.TASK_1}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_NEXUS}-${RelicStep.LIGHT}-${RelicTask.TASK_1}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_BRAVES}-${RelicStep.SKYLIGHT}-${RelicTask.TASK_1}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_BRAVES}-${RelicStep.SKYLIGHT}-${RelicTask.TASK_2}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_BRAVES}-${RelicStep.SKYLIGHT}-${RelicTask.TASK_3}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_BRAVES}-${RelicStep.SKYLIGHT}-${RelicTask.TASK_4}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_BRAVES}-${RelicStep.SKYLIGHT}-${RelicTask.TASK_5}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_BRAVES}-${RelicStep.SKYLIGHT}-${RelicTask.TASK_6}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_BRAVES}-${RelicStep.SKYLIGHT}-${RelicTask.TASK_7}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_BRAVES}-${RelicStep.SKYLIGHT}-${RelicTask.TASK_8}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_BRAVES}-${RelicStep.SKYLIGHT}-${RelicTask.TASK_9}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_BRAVES}-${RelicStep.ZODIUM}-${RelicTask.TASK_1}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_BRAVES}-${RelicStep.ZODIUM}-${RelicTask.TASK_2}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_BRAVES}-${RelicStep.ZODIUM}-${RelicTask.TASK_3}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_BRAVES}-${RelicStep.ZODIUM}-${RelicTask.TASK_4}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_BRAVES}-${RelicStep.ZODIUM}-${RelicTask.TASK_5}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_BRAVES}-${RelicStep.ZODIUM}-${RelicTask.TASK_6}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_BRAVES}-${RelicStep.ZODIUM}-${RelicTask.TASK_7}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_BRAVES}-${RelicStep.ZODIUM}-${RelicTask.TASK_8}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_BRAVES}-${RelicStep.ZODIUM}-${RelicTask.TASK_9}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_BRAVES}-${RelicStep.FLAWLESS_ALEXANDRITE}-${RelicTask.TASK_1}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_BRAVES}-${RelicStep.FLAWLESS_ALEXANDRITE}-${RelicTask.TASK_2}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_BRAVES}-${RelicStep.FLAWLESS_ALEXANDRITE}-${RelicTask.TASK_3}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_BRAVES}-${RelicStep.FLAWLESS_ALEXANDRITE}-${RelicTask.TASK_4}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_BRAVES}-${RelicStep.FLAWLESS_ALEXANDRITE}-${RelicTask.TASK_5}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_BRAVES}-${RelicStep.FLAWLESS_ALEXANDRITE}-${RelicTask.TASK_6}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_BRAVES}-${RelicStep.FLAWLESS_ALEXANDRITE}-${RelicTask.TASK_7}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_BRAVES}-${RelicStep.FLAWLESS_ALEXANDRITE}-${RelicTask.TASK_8}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_BRAVES}-${RelicStep.FLAWLESS_ALEXANDRITE}-${RelicTask.TASK_9}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_BRAVES}-${RelicStep.BRAVES_SCROLL}-${RelicTask.TASK_1}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_BRAVES}-${RelicStep.BRAVES_SCROLL}-${RelicTask.TASK_2}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_BRAVES}-${RelicStep.BRAVES_SCROLL}-${RelicTask.TASK_3}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_BRAVES}-${RelicStep.BRAVES_SCROLL}-${RelicTask.TASK_4}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_BRAVES}-${RelicStep.BRAVES_SCROLL}-${RelicTask.TASK_5}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_BRAVES}-${RelicStep.BRAVES_SCROLL}-${RelicTask.TASK_6}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_BRAVES}-${RelicStep.BRAVES_SCROLL}-${RelicTask.TASK_7}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_BRAVES}-${RelicStep.BRAVES_SCROLL}-${RelicTask.TASK_8}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_BRAVES}-${RelicStep.BRAVES_SCROLL}-${RelicTask.TASK_9}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ZETA}-${RelicStep.SOUL}-${RelicTask.TASK_1}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ZETA}-${RelicStep.SOUL}-${RelicTask.TASK_2}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ZETA}-${RelicStep.SOUL}-${RelicTask.TASK_3}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ZETA}-${RelicStep.SOUL}-${RelicTask.TASK_4}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ZETA}-${RelicStep.SOUL}-${RelicTask.TASK_5}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ZETA}-${RelicStep.SOUL}-${RelicTask.TASK_6}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ZETA}-${RelicStep.SOUL}-${RelicTask.TASK_7}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ZETA}-${RelicStep.SOUL}-${RelicTask.TASK_8}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ZETA}-${RelicStep.SOUL}-${RelicTask.TASK_9}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ZETA}-${RelicStep.SOUL}-${RelicTask.TASK_10}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ZETA}-${RelicStep.SOUL}-${RelicTask.TASK_11}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ZODIAC}-${RelicPart.WEAPON_ZETA}-${RelicStep.SOUL}-${RelicTask.TASK_12}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ANIMA}-${RelicPart.WEAPON_ANIMATED}-${RelicStep.CRYSTALS}-${RelicTask.TASK_1}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ANIMA}-${RelicPart.WEAPON_ANIMATED}-${RelicStep.CRYSTALS}-${RelicTask.TASK_2}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ANIMA}-${RelicPart.WEAPON_ANIMATED}-${RelicStep.CRYSTALS}-${RelicTask.TASK_3}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ANIMA}-${RelicPart.WEAPON_ANIMATED}-${RelicStep.CRYSTALS}-${RelicTask.TASK_4}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ANIMA}-${RelicPart.WEAPON_ANIMATED}-${RelicStep.CRYSTALS}-${RelicTask.TASK_5}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ANIMA}-${RelicPart.WEAPON_ANIMATED}-${RelicStep.CRYSTALS}-${RelicTask.TASK_6}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ANIMA}-${RelicPart.WEAPON_ANIMA}-${RelicStep.RUBBER}-${RelicTask.TASK_1}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ANIMA}-${RelicPart.WEAPON_ANIMA}-${RelicStep.RUBBER}-${RelicTask.TASK_2}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ANIMA}-${RelicPart.WEAPON_ANIMA}-${RelicStep.CARBONCOAT}-${RelicTask.TASK_1}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ANIMA}-${RelicPart.WEAPON_ANIMA}-${RelicStep.CARBONCOAT}-${RelicTask.TASK_2}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ANIMA}-${RelicPart.WEAPON_ANIMA}-${RelicStep.WATER}-${RelicTask.TASK_1}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ANIMA}-${RelicPart.WEAPON_ANIMA}-${RelicStep.WATER}-${RelicTask.TASK_2}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ANIMA}-${RelicPart.WEAPON_ANIMA}-${RelicStep.CATALYST}-${RelicTask.TASK_1}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ANIMA}-${RelicPart.WEAPON_ANIMA}-${RelicStep.CATALYST}-${RelicTask.TASK_2}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ANIMA}-${RelicPart.WEAPON_HYPERCONDUCTIVE}-${RelicStep.OIL_HYPER}-${RelicTask.TASK_1}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ANIMA}-${RelicPart.WEAPON_RECONDITIONED}-${RelicStep.SAND}-${RelicTask.TASK_1}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ANIMA}-${RelicPart.WEAPON_RECONDITIONED}-${RelicStep.SAND}-${RelicTask.TASK_2}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ANIMA}-${RelicPart.WEAPON_SHARPENED}-${RelicStep.CLUSTERS}-${RelicTask.TASK_1}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ANIMA}-${RelicPart.WEAPON_COMPLETE}-${RelicStep.DUNGEONS}-${RelicTask.TASK_1}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ANIMA}-${RelicPart.WEAPON_COMPLETE}-${RelicStep.DUNGEONS}-${RelicTask.TASK_2}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ANIMA}-${RelicPart.WEAPON_COMPLETE}-${RelicStep.DUNGEONS}-${RelicTask.TASK_3}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ANIMA}-${RelicPart.WEAPON_COMPLETE}-${RelicStep.PNEUMITE}-${RelicTask.TASK_1}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ANIMA}-${RelicPart.WEAPON_COMPLETE}-${RelicStep.DENSITY}-${RelicTask.TASK_1}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ANIMA}-${RelicPart.WEAPON_LUX}-${RelicStep.BFF}-${RelicTask.TASK_1}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ANIMA}-${RelicPart.WEAPON_LUX}-${RelicStep.BFF}-${RelicTask.TASK_2}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ANIMA}-${RelicPart.WEAPON_LUX}-${RelicStep.BFF}-${RelicTask.TASK_3}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ANIMA}-${RelicPart.WEAPON_LUX}-${RelicStep.BFF}-${RelicTask.TASK_4}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ANIMA}-${RelicPart.WEAPON_LUX}-${RelicStep.BFF}-${RelicTask.TASK_5}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ANIMA}-${RelicPart.WEAPON_LUX}-${RelicStep.BFF}-${RelicTask.TASK_6}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ANIMA}-${RelicPart.WEAPON_LUX}-${RelicStep.BFF}-${RelicTask.TASK_7}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ANIMA}-${RelicPart.WEAPON_LUX}-${RelicStep.BFF}-${RelicTask.TASK_8}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ANIMA}-${RelicPart.WEAPON_LUX}-${RelicStep.BFF}-${RelicTask.TASK_9}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ANIMA}-${RelicPart.WEAPON_LUX}-${RelicStep.BFF}-${RelicTask.TASK_10}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ANIMA}-${RelicPart.WEAPON_LUX}-${RelicStep.BFF}-${RelicTask.TASK_11}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ANIMA}-${RelicPart.WEAPON_LUX}-${RelicStep.BFF}-${RelicTask.TASK_12}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_ANIMA}-${RelicPart.WEAPON_LUX}-${RelicStep.BFF}-${RelicTask.TASK_13}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_EUREKA}-${RelicPart.WEAPON_ANEMOS}-${RelicStep.ANEMOS_0}-${RelicTask.TASK_1}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_EUREKA}-${RelicPart.WEAPON_ANEMOS}-${RelicStep.ANEMOS_1}-${RelicTask.TASK_1}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_EUREKA}-${RelicPart.WEAPON_ANEMOS}-${RelicStep.ANEMOS_2}-${RelicTask.TASK_1}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_EUREKA}-${RelicPart.WEAPON_ANEMOS}-${RelicStep.ANEMOS}-${RelicTask.TASK_1}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_EUREKA}-${RelicPart.WEAPON_ELEMENTAL}-${RelicStep.PAGOS}-${RelicTask.TASK_1}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_EUREKA}-${RelicPart.WEAPON_ELEMENTAL}-${RelicStep.PAGOS_1}-${RelicTask.TASK_1}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_EUREKA}-${RelicPart.WEAPON_ELEMENTAL}-${RelicStep.PAGOS_1}-${RelicTask.TASK_2}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_EUREKA}-${RelicPart.WEAPON_ELEMENTAL}-${RelicStep.ELEMENTAL}-${RelicTask.TASK_1}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_EUREKA}-${RelicPart.WEAPON_ELEMENTAL}-${RelicStep.ELEMENTAL}-${RelicTask.TASK_2}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_EUREKA}-${RelicPart.WEAPON_PYROS}-${RelicStep.ELEMENTAL_1}-${RelicTask.TASK_1}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_EUREKA}-${RelicPart.WEAPON_PYROS}-${RelicStep.ELEMENTAL_2}-${RelicTask.TASK_1}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_EUREKA}-${RelicPart.WEAPON_PYROS}-${RelicStep.PYROS}-${RelicTask.TASK_1}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_EUREKA}-${RelicPart.WEAPON_PYROS}-${RelicStep.PYROS}-${RelicTask.TASK_2}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_EUREKA}-${RelicPart.WEAPON_EUREKA}-${RelicStep.HYDATOS}-${RelicTask.TASK_1}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_EUREKA}-${RelicPart.WEAPON_EUREKA}-${RelicStep.HYDATOS_1}-${RelicTask.TASK_1}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_EUREKA}-${RelicPart.WEAPON_EUREKA}-${RelicStep.BASE}-${RelicTask.TASK_1}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_EUREKA}-${RelicPart.WEAPON_EUREKA}-${RelicStep.EUREKA}-${RelicTask.TASK_1}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_EUREKA}-${RelicPart.WEAPON_EUREKA}-${RelicStep.EUREKA}-${RelicTask.TASK_2}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_EUREKA}-${RelicPart.WEAPON_PHYSEOS}-${RelicStep.FRAGMENTS}-${RelicTask.TASK_1}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_RESISTANCE}-${RelicPart.WEAPON_RESISTANCE}-${RelicStep.SCALEPOWDER}-${RelicTask.TASK_1}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_RESISTANCE}-${RelicPart.WEAPON_AUGMENTED_RESISTANCE}-${RelicStep.MEMORY}-${RelicTask.TASK_1}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_RESISTANCE}-${RelicPart.WEAPON_AUGMENTED_RESISTANCE}-${RelicStep.MEMORY}-${RelicTask.TASK_2}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_RESISTANCE}-${RelicPart.WEAPON_AUGMENTED_RESISTANCE}-${RelicStep.MEMORY}-${RelicTask.TASK_3}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_RESISTANCE}-${RelicPart.WEAPON_RECOLLECTION}-${RelicStep.RESIST}-${RelicTask.TASK_1}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_RESISTANCE}-${RelicPart.WEAPON_LAWS_ORDER}-${RelicStep.CHANGE_OF_ARMS}-${RelicTask.TASK_1}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_RESISTANCE}-${RelicPart.WEAPON_AUGMENTED_LAWS_ORDER}-${RelicStep.REMEMBERS}-${RelicTask.TASK_1}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_RESISTANCE}-${RelicPart.WEAPON_AUGMENTED_LAWS_ORDER}-${RelicStep.REMEMBERS}-${RelicTask.TASK_2}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_RESISTANCE}-${RelicPart.WEAPON_AUGMENTED_LAWS_ORDER}-${RelicStep.PATH}-${RelicTask.TASK_1}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_RESISTANCE}-${RelicPart.WEAPON_BLADE}-${RelicStep.SPARE_PARTS}-${RelicTask.TASK_1}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_RESISTANCE}-${RelicPart.WEAPON_BLADE}-${RelicStep.SPARE_PARTS}-${RelicTask.TASK_2}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_RESISTANCE}-${RelicPart.WEAPON_BLADE}-${RelicStep.STORY}-${RelicTask.TASK_1}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_RESISTANCE}-${RelicPart.WEAPON_BLADE}-${RelicStep.STORY}-${RelicTask.TASK_2}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_RESISTANCE}-${RelicPart.WEAPON_BLADE}-${RelicStep.FOND_MEMORY}-${RelicTask.TASK_1}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_RESISTANCE}-${RelicPart.WEAPON_BLADE}-${RelicStep.FOND_MEMORY}-${RelicTask.TASK_2}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_RESISTANCE}-${RelicPart.WEAPON_BLADE}-${RelicStep.IRRESISTIBLE}-${RelicTask.TASK_1}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_MANDERVILLE}-${RelicPart.WEAPON_MANDERVILLE}-${RelicStep.MANDERVILLE}-${RelicTask.TASK_1}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_MANDERVILLE}-${RelicPart.WEAPON_AMAZING_MANDERVILLE}-${RelicStep.AMAZING_MANDERVILLE}-${RelicTask.TASK_1}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_MANDERVILLE}-${RelicPart.WEAPON_MAJESTIC_MANDERVILLE}-${RelicStep.MAJESTIC_MANDERVILLE}-${RelicTask.TASK_1}`,
	`${RelicType.WEAPON}-${RelicGroup.WEAPON_MANDERVILLE}-${RelicPart.WEAPON_MANDERVILLOUS}-${RelicStep.MANDERVILLOUS}-${RelicTask.TASK_1}`,
	`${RelicType.TOOL}-${RelicGroup.TOOL_LUCIS}-${RelicPart.TOOL_SUPRA}-${RelicStep.SUPRA_ITEMS}-${RelicTask.TASK_1}`,
	`${RelicType.TOOL}-${RelicGroup.TOOL_LUCIS}-${RelicPart.TOOL_SUPRA}-${RelicStep.SUPRA_ITEMS}-${RelicTask.TASK_2}`,
	`${RelicType.TOOL}-${RelicGroup.TOOL_LUCIS}-${RelicPart.TOOL_LUCIS}-${RelicStep.LUCIS_ITEMS}-${RelicTask.TASK_1}`,
	`${RelicType.TOOL}-${RelicGroup.TOOL_LUCIS}-${RelicPart.TOOL_LUCIS}-${RelicStep.LUCIS_ITEMS}-${RelicTask.TASK_2}`,
	`${RelicType.TOOL}-${RelicGroup.TOOL_SKYSTEEL}-${RelicPart.TOOL_SKYSTEEL_1}-${RelicStep.SKYSTEEL_ITEMS}-${RelicTask.TASK_1}`,
	`${RelicType.TOOL}-${RelicGroup.TOOL_SKYSTEEL}-${RelicPart.TOOL_SKYSTEEL_1}-${RelicStep.SKYSTEEL_ITEMS}-${RelicTask.TASK_2}`,
	`${RelicType.TOOL}-${RelicGroup.TOOL_SKYSTEEL}-${RelicPart.TOOL_DRAGONSUNG}-${RelicStep.DRAGONSUNG_ITEMS}-${RelicTask.TASK_1}`,
	`${RelicType.TOOL}-${RelicGroup.TOOL_SKYSTEEL}-${RelicPart.TOOL_DRAGONSUNG}-${RelicStep.DRAGONSUNG_ITEMS}-${RelicTask.TASK_2}`,
	`${RelicType.TOOL}-${RelicGroup.TOOL_SKYSTEEL}-${RelicPart.TOOL_AUGMENTED_DRAGONSUNG}-${RelicStep.BASIC_GOBBIEGOO}-${RelicTask.TASK_1}`,
	`${RelicType.TOOL}-${RelicGroup.TOOL_SKYSTEEL}-${RelicPart.TOOL_AUGMENTED_DRAGONSUNG}-${RelicStep.BASIC_GOBBIEGOO}-${RelicTask.TASK_2}`,
	`${RelicType.TOOL}-${RelicGroup.TOOL_SKYSTEEL}-${RelicPart.TOOL_SKYSUNG}-${RelicStep.VISCOUS_GOBBIEGOO}-${RelicTask.TASK_1}`,
	`${RelicType.TOOL}-${RelicGroup.TOOL_SKYSTEEL}-${RelicPart.TOOL_SKYSUNG}-${RelicStep.VISCOUS_GOBBIEGOO}-${RelicTask.TASK_2}`,
	`${RelicType.TOOL}-${RelicGroup.TOOL_SKYSTEEL}-${RelicPart.TOOL_SKYBUILDERS}-${RelicStep.PARTS}-${RelicTask.TASK_1}`,
	`${RelicType.TOOL}-${RelicGroup.TOOL_SKYSTEEL}-${RelicPart.TOOL_SKYBUILDERS}-${RelicStep.PARTS}-${RelicTask.TASK_2}`,
	`${RelicType.TOOL}-${RelicGroup.TOOL_SPLENDOROUS}-${RelicPart.TOOL_AUGMENTED_SPLENDOROUS}-${RelicStep.SPLENDOROUS_COMPONENT}-${RelicTask.TASK_1}`,
	`${RelicType.TOOL}-${RelicGroup.TOOL_SPLENDOROUS}-${RelicPart.TOOL_AUGMENTED_SPLENDOROUS}-${RelicStep.SPLENDOROUS_COMPONENT}-${RelicTask.TASK_2}`,
	`${RelicType.TOOL}-${RelicGroup.TOOL_SPLENDOROUS}-${RelicPart.TOOL_CRYSTALLINE}-${RelicStep.ADAPTIVE_COMPONENT}-${RelicTask.TASK_1}`,
	`${RelicType.TOOL}-${RelicGroup.TOOL_SPLENDOROUS}-${RelicPart.TOOL_CRYSTALLINE}-${RelicStep.ADAPTIVE_COMPONENT}-${RelicTask.TASK_2}`,
	`${RelicType.TOOL}-${RelicGroup.TOOL_SPLENDOROUS}-${RelicPart.TOOL_CHORA_ZOIS_CRYSTALLINE}-${RelicStep.CUSTOMISED_COMPONENT}-${RelicTask.TASK_1}`,
	`${RelicType.TOOL}-${RelicGroup.TOOL_SPLENDOROUS}-${RelicPart.TOOL_CHORA_ZOIS_CRYSTALLINE}-${RelicStep.CUSTOMISED_COMPONENT}-${RelicTask.TASK_2}`,
	`${RelicType.TOOL}-${RelicGroup.TOOL_SPLENDOROUS}-${RelicPart.TOOL_BRILLIANT}-${RelicStep.BRILLIANT_COMPONENT}-${RelicTask.TASK_1}`,
	`${RelicType.TOOL}-${RelicGroup.TOOL_SPLENDOROUS}-${RelicPart.TOOL_BRILLIANT}-${RelicStep.BRILLIANT_COMPONENT}-${RelicTask.TASK_2}`,
	`${RelicType.TOOL}-${RelicGroup.TOOL_SPLENDOROUS}-${RelicPart.TOOL_VRANDTIC_VISIONARYS}-${RelicStep.INSPIRATIONAL_COMPONENT}-${RelicTask.TASK_1}`,
	`${RelicType.TOOL}-${RelicGroup.TOOL_SPLENDOROUS}-${RelicPart.TOOL_VRANDTIC_VISIONARYS}-${RelicStep.INSPIRATIONAL_COMPONENT}-${RelicTask.TASK_2}`,
	`${RelicType.TOOL}-${RelicGroup.TOOL_SPLENDOROUS}-${RelicPart.TOOL_LODESTAR}-${RelicStep.NIGHTFORGED_COMPONENT}-${RelicTask.TASK_1}`,
	`${RelicType.TOOL}-${RelicGroup.TOOL_SPLENDOROUS}-${RelicPart.TOOL_LODESTAR}-${RelicStep.NIGHTFORGED_COMPONENT}-${RelicTask.TASK_2}`,
	/* TODO: All Armour Tasks */
	//#endregion Relic Tasks
] as const;
