//#region Imports
import manager from "managers/app";
import { Component } from "react";
import { connect } from "react-redux";
import t from "translations/translator";
import { AppState, ViewUnknownProps } from "types";
import "./Unknown.css";
//#endregion Imports

const mapStateToProps = (state:AppState) => {
	return {
		lang: state.userInfo.options.lang,
		gameLang: state.userInfo.options.gameLang,
		view: state.view,
	};
};

class Unknown extends Component<ViewUnknownProps> {
	render(){
		return (
			<div id="unknown">
				<h2>{t.t("No relics to be found this way!")}</h2>
			</div>
		);
	}

	componentDidMount = () => {
		manager.view.changeComplete();
	}

	componentDidUpdate = () => {
		const overlay = document.getElementById("loading-overlay");
		if(overlay && overlay.classList.contains("show")){
			manager.view.changeComplete();
		}
	}
}
export default connect(mapStateToProps)(Unknown);
