//#region Imports
import FormComponents from "components/forms/FormComponents";
import NoCharacter from "components/no-character/NoCharacter";
import { RelicGroup, RelicPart } from "enums";
import manager from "managers/app";
import { Component } from "react";
import { connect } from "react-redux";
import { MultiValue, SingleValue } from "react-select";
import t from "translations/translator";
import { AppState, OutputGameString, OutputString, RSOption, ViewProgressProps, ViewProgressState } from "types";
import utils from "utils";
import OverallTable from "views/page-parts/content/overall-table/OverallTable";
import "./Progress.css";
import ProgressStats from "./progress-stats";
//#endregion Imports

const mapStateToProps = (state:AppState) => {
	return {
		lang: state.userInfo.options.lang,
		gameLang: state.userInfo.options.gameLang,
		userInfo: state.userInfo,
		options: state.userInfo.options,
		ignorePhyseosPart: state.userInfo.options.progress.ignorePhyseosPart,
		compactProgressView: state.userInfo.options.progress.compactProgressView,
	};
};

class Progress extends Component<ViewProgressProps> {
	state:ViewProgressState = {
		showRelic: RelicGroup.WEAPON_ZODIAC,
		view: "Progress",
	}

	render(){
		const character = manager.data.getActiveCharacter();
		if(character === null){ return <NoCharacter />; }

		const selectOptions = this.getSelectOptions();
		const relicType = manager.relics.getRelicType(this.state.showRelic);

		return (
			<div id="progress">
				<div className="options">
					<FormComponents.Select
						label={{ hide: false, text: "View", position: "left", alignment: "center" }}
						input={{ options: selectOptions.view.options, value: selectOptions.view.selected, events: { onChange: selectOptions.view.event } }}
					/>
					{this.state.view === "Relic"
						? <FormComponents.Select
							label={ { hide: false, text: "Relic", position: "left", alignment: "center" } }
							input={ { options: selectOptions.relic.options, value: selectOptions.relic.selected, class: ["secondary"], events: { onChange: selectOptions.relic.event } } }
						/>
						: null
					}
					{/* {this.state.view === "Progress"
						? <FormComponents.Checkbox
							label={{ hide: false, text: "Compact View", position: "right", alignment: "center" }}
							input={{ checked: this.props.compactProgressView, events: { onClick: this.toggleCompactProgressView } }}
						/>
						: null
					} */}
					<FormComponents.Checkbox
						label={{ hide: false, text: "Ignore %PART% part", placeholders: { PART: RelicPart.WEAPON_PHYSEOS }, position: "right", alignment: "center" }}
						input={{ checked: this.props.ignorePhyseosPart, events: { onClick: this.toggleIgnorePhyseosPart } }}
					/>
				</div>
				{this.state.view === "Progress"
					? <ProgressStats />
					: null
				}
				{this.state.view === "Relic"
					? <OverallTable relicType={relicType} relic={this.state.showRelic} />
					: null
				}
			</div>
		);
	}

	componentDidMount(){
		manager.view.changeComplete();
	}

	toggleCompactProgressView = () => { // Event
		const options = this.props.options;
		options.progress.compactProgressView = !options.progress.compactProgressView;
		manager.data.saveOptions(options, true);
	}

	toggleIgnorePhyseosPart = () => { // Event
		const options = this.props.options;
		options.progress.ignorePhyseosPart = !options.progress.ignorePhyseosPart;
		manager.data.saveOptions(options, true);
	}

	getSelectOptions(){
		return {
			view: { options: this.generateOptions(["Progress", "Relic"]), selected: this.generateOptions([this.state.view]), event: this.switchView },
			relic: { options: manager.content.getRelicSelectOptions(), selected: this.generateOptions([this.state.showRelic]), event: this.switchOverallRelic },
		};
	}

	generateOptions(enumValues:(OutputString|OutputGameString)[]):RSOption[]{
		const options:RSOption[] = [];
		enumValues.forEach((thisValue) => {
			let text = t.t(thisValue);

			if(utils.guards.isEnumValue(RelicPart, thisValue)){
				text = `${text} (iLvl ${manager.relics.getPartILvl(thisValue)})`;
			}
			options.push({ label: text, value: thisValue});
		});
		return options;
	}

	switchView = (selectedOptions:MultiValue<RSOption>|SingleValue<RSOption>) => { // Event
		if((selectedOptions instanceof Array)){ return; }

		const selected = selectedOptions?.value;
		if(!selected || (selected !== "Progress" && selected !== "Relic")){ return; }

		this.setState((old:ViewProgressState):ViewProgressState => { return { ...old, view: selected }; });

	}

	switchOverallRelic = (selectedOptions:MultiValue<RSOption>|SingleValue<RSOption>) => { // Event
		if((selectedOptions instanceof Array)){ return; }

		const selected = selectedOptions?.value;
		if(!selected){ return; }

		if(!utils.guards.isEnumValue(RelicGroup, selected)){ return; }

		this.setState((old:ViewProgressState):ViewProgressState => { return { ...old, showRelic: selected }; });
	}
}
export default connect(mapStateToProps)(Progress);
