//#region Imports
import { Component } from "react";
import { FormFieldWrapperProps } from "types";
//#endregion Imports

export class FormFieldWrapper extends Component<FormFieldWrapperProps> {
	render(){
		const label = this.props.label;

		const classes:string[] = ["field"];

		if(this.props.minimalist){ classes.push("minimalist"); }
		if(!label.hide){
			classes.push(label.position ? `label-p-${label.position}` : "label-p-top");
			classes.push(label.alignment ? `label-a-${label.alignment}` : "label-a-left");
		}

		let style;
		if(this.props.width){ style = { width: this.props.width }; }

		return (
			<div className={classes.join(" ")} style={style}>
				{this.props.children}
			</div>
		);
	}
}
