/* eslint-disable max-len */
//#region Imports
import { jobLists } from "character";
import Link from "components/link/Link";
import NoCharacter from "components/no-character/NoCharacter";
import { dungeons } from "data-files/duties";
import { quests } from "data-files/quests";
import { Dungeon, EorzeaDataType, LinkType, NPC, Quest, RelicGroup, RelicPart, RelicStep, RelicType, View } from "enums";
import manager from "managers/app";
import { Component } from "react";
import { connect } from "react-redux";
import t from "translations/translator";
import { AppState, Requirement, ViewRelicInfo, ViewWeaponAnimaAwokenProps } from "types";
import ContentHeader from "views/page-parts/content/content-header/ContentHeader";
import DetailsSection from "views/page-parts/content/details-section/DetailsSection";
import OverviewTable from "views/page-parts/content/overview-table/OverviewTable";
import "./WeaponAnimaAwoken.css";
//#endregion Imports

const mapStateToProps = (state:AppState) => {
	return {
		lang: state.userInfo.options.lang,
		gameLang: state.userInfo.options.gameLang,
		userInfo: state.userInfo,
		hideCompletedSteps: state.userInfo.options.hideCompletedSteps,
		hideCompletedTasks: state.userInfo.options.hideCompletedTasks,
		showJob: state.userInfo.options.job,
	};
};

class WeaponAnimaAwoken extends Component<ViewWeaponAnimaAwokenProps> {
	private requirements:Requirement[] = [];
	private importantNPCs:NPC[] = [];
	private relicInfo:ViewRelicInfo = {
		jobs: jobLists.relic[RelicGroup.WEAPON_ANIMA],
		type: RelicType.WEAPON,
		relic: RelicGroup.WEAPON_ANIMA,
		part: RelicPart.WEAPON_AWOKEN,
		steps: [RelicStep.SNOWCLOAK, RelicStep.SASTASHA, RelicStep.QARN, RelicStep.KEEPER, RelicStep.WANDERER, RelicStep.AMDAPOR_AWOKEN, RelicStep.VIGIL, RelicStep.SOHM, RelicStep.AERY, RelicStep.VAULT],
		stepPlaceholders: {
			[RelicStep.SNOWCLOAK]: { DUNGEON: Dungeon.SNOWCLOAK },
			[RelicStep.SASTASHA]: { DUNGEON: Dungeon.SASTASHA_HARD },
			[RelicStep.QARN]: { DUNGEON: Dungeon.QARN_HARD },
			[RelicStep.KEEPER]: { DUNGEON: Dungeon.KEEPER_OF_THE_LAKE },
			[RelicStep.WANDERER]: { DUNGEON: Dungeon.WANDERERS_HARD },
			[RelicStep.AMDAPOR_AWOKEN]: { DUNGEON: Dungeon.AMDAPOR_KEEP_HARD },
			[RelicStep.VIGIL]: { DUNGEON: Dungeon.DUSK_VIGIL },
			[RelicStep.SOHM]: { DUNGEON: Dungeon.SOHM_AL },
			[RelicStep.AERY]: { DUNGEON: Dungeon.AERY },
			[RelicStep.VAULT]: { DUNGEON: Dungeon.VAULT },
		},
		next: {
			part: RelicPart.WEAPON_ANIMA,
			view: View.RELIC_WEAPONS_ANIMA_ANIMA,
		},
		previous: {
			part: RelicPart.WEAPON_ANIMATED,
			view: View.RELIC_WEAPONS_ANIMA_ANIMATED,
		},
	};

	constructor(props:ViewWeaponAnimaAwokenProps){
		super(props);
		this.requirements = this.getRequirements();
		this.importantNPCs = this.getImportantNPCs();
	}

	render(){
		const character = manager.data.getActiveCharacter();
		if(character === null){ return <NoCharacter />; }

		const completeSteps = manager.content.getCompleteSteps(character.progress, this.relicInfo);

		return (
			<div id="weapon-anima-awoken" className="relic">
				<ContentHeader requirements={this.requirements} importantNPCs={this.importantNPCs} relicInfo={this.relicInfo}>
					<p>{t.t("The %PART% upgrade follows on from %PREVIOUSPART%", { PART: this.relicInfo.part, PREVIOUSPART: this.relicInfo.previous ? this.relicInfo.previous.part : "Unknown"})}</p>
					{t.t("In order to obtain the %PART% you must complete the quest", { PART: RelicPart.WEAPON_AWOKEN })}
					<Link type={LinkType.LODESTONE} lodestoneType={EorzeaDataType.BLUEQUEST} text={Quest.TOUGHENING_UP} lodestoneID={quests[Quest.TOUGHENING_UP].lodestoneID} />
				</ContentHeader>
				<div id="relic-checklist">
					<OverviewTable character={character} completeSteps={completeSteps} relicInfo={this.relicInfo} />
				</div>
				<div id="relic-details">
					<h1>{t.t("Details")}</h1>
					{this.props.hideCompletedSteps && completeSteps.allComplete ? t.t("All Steps Complete") : null }
					<DetailsSection title={RelicStep.SNOWCLOAK} placeholders={this.relicInfo.stepPlaceholders[RelicStep.SNOWCLOAK]} id={RelicStep.SNOWCLOAK} stepComplete={completeSteps[RelicStep.SNOWCLOAK]}><p>{t.t("Complete")}<Link type={LinkType.LODESTONE} lodestoneType={dungeons[Dungeon.SNOWCLOAK].type} text={dungeons[Dungeon.SNOWCLOAK].name} lodestoneID={dungeons[Dungeon.SNOWCLOAK].lodestoneID} /></p></DetailsSection>
					<DetailsSection title={RelicStep.SASTASHA} placeholders={this.relicInfo.stepPlaceholders[RelicStep.SASTASHA]} id={RelicStep.SASTASHA} stepComplete={completeSteps[RelicStep.SASTASHA]}><p>{t.t("Complete")}<Link type={LinkType.LODESTONE} lodestoneType={dungeons[Dungeon.SASTASHA_HARD].type} text={dungeons[Dungeon.SASTASHA_HARD].name} lodestoneID={dungeons[Dungeon.SASTASHA_HARD].lodestoneID} /></p></DetailsSection>
					<DetailsSection title={RelicStep.QARN} placeholders={this.relicInfo.stepPlaceholders[RelicStep.QARN]} id={RelicStep.QARN} stepComplete={completeSteps[RelicStep.QARN]}><p>{t.t("Complete")}<Link type={LinkType.LODESTONE} lodestoneType={dungeons[Dungeon.QARN_HARD].type} text={dungeons[Dungeon.QARN_HARD].name} lodestoneID={dungeons[Dungeon.QARN_HARD].lodestoneID} /></p></DetailsSection>
					<DetailsSection title={RelicStep.KEEPER} placeholders={this.relicInfo.stepPlaceholders[RelicStep.KEEPER]} id={RelicStep.KEEPER} stepComplete={completeSteps[RelicStep.KEEPER]}><p>{t.t("Complete")}<Link type={LinkType.LODESTONE} lodestoneType={dungeons[Dungeon.KEEPER_OF_THE_LAKE].type} text={dungeons[Dungeon.KEEPER_OF_THE_LAKE].name} lodestoneID={dungeons[Dungeon.KEEPER_OF_THE_LAKE].lodestoneID} /></p></DetailsSection>
					<DetailsSection title={RelicStep.WANDERER} placeholders={this.relicInfo.stepPlaceholders[RelicStep.WANDERER]} id={RelicStep.WANDERER} stepComplete={completeSteps[RelicStep.WANDERER]}><p>{t.t("Complete")}<Link type={LinkType.LODESTONE} lodestoneType={dungeons[Dungeon.WANDERERS_HARD].type} text={dungeons[Dungeon.WANDERERS_HARD].name} lodestoneID={dungeons[Dungeon.WANDERERS_HARD].lodestoneID} /></p></DetailsSection>
					<DetailsSection title={RelicStep.VIGIL} placeholders={this.relicInfo.stepPlaceholders[RelicStep.VIGIL]} id={RelicStep.VIGIL} stepComplete={completeSteps[RelicStep.VIGIL]}><p>{t.t("Complete")}<Link type={LinkType.LODESTONE} lodestoneType={dungeons[Dungeon.DUSK_VIGIL].type} text={dungeons[Dungeon.DUSK_VIGIL].name} lodestoneID={dungeons[Dungeon.DUSK_VIGIL].lodestoneID} /></p></DetailsSection>
					<DetailsSection title={RelicStep.SOHM} placeholders={this.relicInfo.stepPlaceholders[RelicStep.SOHM]} id={RelicStep.SOHM} stepComplete={completeSteps[RelicStep.SOHM]}><p>{t.t("Complete")}<Link type={LinkType.LODESTONE} lodestoneType={dungeons[Dungeon.SOHM_AL].type} text={dungeons[Dungeon.SOHM_AL].name} lodestoneID={dungeons[Dungeon.SOHM_AL].lodestoneID} /></p></DetailsSection>
					<DetailsSection title={RelicStep.AERY} placeholders={this.relicInfo.stepPlaceholders[RelicStep.AERY]} id={RelicStep.AERY} stepComplete={completeSteps[RelicStep.AERY]}><p>{t.t("Complete")}<Link type={LinkType.LODESTONE} lodestoneType={dungeons[Dungeon.AERY].type} text={dungeons[Dungeon.AERY].name} lodestoneID={dungeons[Dungeon.AERY].lodestoneID} /></p></DetailsSection>
					<DetailsSection title={RelicStep.VAULT} placeholders={this.relicInfo.stepPlaceholders[RelicStep.VAULT]} id={RelicStep.VAULT} stepComplete={completeSteps[RelicStep.VAULT]}><p>{t.t("Complete")}<Link type={LinkType.LODESTONE} lodestoneType={dungeons[Dungeon.VAULT].type} text={dungeons[Dungeon.VAULT].name} lodestoneID={dungeons[Dungeon.VAULT].lodestoneID} /></p></DetailsSection>
				</div>
			</div>
		);
	}

	componentDidMount(){
		manager.view.changeComplete();
	}

	private getRequirements():Requirement[]{
		if(!this.relicInfo || this.relicInfo.previous === null){ return []; }
		return [
			{ type: "previous part", part: this.relicInfo.previous.part, partView: this.relicInfo.previous.view },
		];
	}

	private getImportantNPCs():NPC[]{
		return [NPC.ARDASHIR];
	}
}
export default connect(mapStateToProps)(WeaponAnimaAwoken);
