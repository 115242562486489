//#region Imports
import { jobLists } from "character";
import FormComponents from "components/forms/FormComponents";
import { GalleryLayout, Job, RelicGroup, RelicType } from "enums";
import manager from "managers/app";
import { Component } from "react";
import { connect } from "react-redux";
import { MultiValue, SingleValue } from "react-select";
import t from "translations/translator";
import { AppState, RSOption, RelicImage, ViewGalleryProps, ViewGalleryState } from "types";
import utils from "utils";
import Lightbox, { LightboxProps } from "yet-another-react-lightbox";
import Captions from "yet-another-react-lightbox/plugins/captions";
import Counter from "yet-another-react-lightbox/plugins/counter";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Inline from "yet-another-react-lightbox/plugins/inline";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "./Gallery.css";
//#endregion Imports

const mapStateToProps = (state:AppState) => {
	return {
		lang: state.userInfo.options.lang,
		gameLang: state.userInfo.options.gameLang,
	};
};

class Gallery extends Component<ViewGalleryProps> {
	state:ViewGalleryState = {
		jobsToShow: [],
		relicsToShow: [],
		layout: GalleryLayout.BYRELIC,
	};

	render(){
		const lightBoxSettings:Partial<LightboxProps> = {
			plugins: [Captions, Inline, Fullscreen, Thumbnails, Counter, Zoom],
			captions: { descriptionTextAlign: "center" },
			inline: { style: { maxHeight: "500px", aspectRatio: "16/9", margin: "0 auto" } },
			thumbnails: { imageFit: "cover", gap: 10, padding: 0, border: 0, vignette: true },
			carousel: { finite: true, padding: 0 } as any, // Typing is wacky on this object and not allowing properties as optional like the others
		};

		const jobOptions = manager.content.getJobSelectOptions(jobLists.all, false);
		const selectedJobs = this.getSelectedJobOptions();
		const relicOptions = manager.content.getRelicSelectOptions();
		const selectedRelics = this.getSelectedRelicOptions();
		const layoutOptions:RSOption[] = [
			{ label: t.t("By Relic"), value: GalleryLayout.BYRELIC },
			{ label: t.t("By Job"), value: GalleryLayout.BYJOB },
		];
		const selectedLayoutOption = this.state.layout === GalleryLayout.BYRELIC ? layoutOptions[0] : layoutOptions[1];
		const imagesToShow = this.getImagesToShow(this.state.jobsToShow, this.state.relicsToShow);

		return (
			<div id="gallery">
				<div className="gallery-options">
					<FormComponents.Select
						label={{ hide: false, text: "View", position: "top", alignment: "center" }}
						input={{ options: layoutOptions, value: [selectedLayoutOption], events: { onChange: this.layoutSelected } }}
					/>
					<FormComponents.Select
						label={{ hide: false, text: "Jobs", position: "top", alignment: "center" }}
						input={{ options: jobOptions, value: selectedJobs, multiple: true, placeholder: "Select jobs to filter", events: { onChange: this.jobSelected } }}
					/>
					<FormComponents.Select
						label={{ hide: false, text: "Relics", position: "top", alignment: "center" }}
						input={{ options: relicOptions, value: selectedRelics, multiple: true, placeholder: "Select relics to filter", events: { onChange: this.relicSelected } }}
					/>
				</div>
				<div className="gallery-wrapper">
					{this.state.layout === GalleryLayout.BYJOB
						? jobLists.all.map((job, jobIndex) => {
							if(this.shouldIgnoreJob(job)){ return null; }

							const theseImages = imagesToShow.filter((z) => { return z.job === job; });
							const slides = theseImages.map((image) => {
								return { src: image.image ? image.image : "", description: `${t.t(image.relic)} - ${t.t(image.relicName)}` };
							});

							return <div key={`jobground-${jobIndex}`}>
								<h2>{t.t(manager.relics.getJobName(job))}</h2>
								{!slides || slides.length === 0
									? <p>{t.t("No images available for this job")}</p>
									: <Lightbox
										plugins={lightBoxSettings.plugins}
										slides={slides}
										captions={lightBoxSettings.captions}
										inline={lightBoxSettings.inline}
										thumbnails={lightBoxSettings.thumbnails}
										carousel={lightBoxSettings.carousel}
									/>
								}
							</div>;
						})
						: Object.values(RelicGroup).map((relic, relicIndex) => {
							if(this.shouldIgnoreRelic(relic)){ return null; }

							const type = manager.relics.getRelicType(relic);
							const theseImages = imagesToShow.filter((z) => { return z.relic === relic; });
							const slides = theseImages.map((image) => {
								return { src: image.image ? image.image : "", description: `${t.t(manager.relics.getJobName(image.job))} - ${t.t(image.relicName)}` };
							});

							return <div key={`relicgroup-${relicIndex}`}>
								<h2>{t.t(type)} - {t.t(relic)}</h2>
								{!slides || slides.length === 0
									? <p>{t.t("No images available for this relic")}</p>
									: <Lightbox
										plugins={lightBoxSettings.plugins}
										slides={slides}
										captions={lightBoxSettings.captions}
										inline={lightBoxSettings.inline}
										thumbnails={lightBoxSettings.thumbnails}
										carousel={lightBoxSettings.carousel}
									/>
								}
							</div>;
						})
					}
				</div>
			</div>
		);
	}

	componentDidMount(){
		this.finishComponentSetup();
	}

	finishComponentSetup = () => { // Requires arrow function for correct "this" binding
		const images = manager.relics.getAllImages();
		if(images.length === 0){
			setTimeout(this.finishComponentSetup, 1000);
		}else{
			this.setState((old:ViewGalleryState):ViewGalleryState => { return { ...old, layout: this.state.layout}; });
			manager.view.changeComplete();
		}
	}

	getSelectedJobOptions():RSOption[]{
		const selectedOptions:RSOption[] = [];
		this.state.jobsToShow.forEach((job) => {
			selectedOptions.push({ label: t.t(manager.relics.getJobName(job)), value: job });
		});
		return selectedOptions;
	}

	getSelectedRelicOptions(){
		const selectedOptions:RSOption[] = [];
		this.state.relicsToShow.forEach((relic) => {
			selectedOptions.push({ label: t.t(relic), value: relic });
		});
		return selectedOptions;
	}

	getImagesToShow(selectedJobs:Job[], selectedRelics:RelicGroup[]): RelicImage[]{
		const imagesToShow:RelicImage[] = [];
		const images = manager.relics.getAllImages();

		images.forEach((image) => {
			if(selectedJobs.length !== 0 && !selectedJobs.includes(image.job)){ return; }
			if(selectedRelics.length !== 0 && !selectedRelics.includes(image.relic)){ return; }

			imagesToShow.push(image);
		});
		return imagesToShow;
	}

	jobSelected = (selectedOptions:MultiValue<RSOption>|SingleValue<RSOption>) => { // Event
		if(!(selectedOptions instanceof Array)){ return; }

		const selected:Job[] = [];
		selectedOptions.forEach((selectedOption) => { selected.push(selectedOption.value as Job); });

		this.setState((old:ViewGalleryState):ViewGalleryState => { return { ...old, jobsToShow: selected}; });
	}

	relicSelected = (selectedOptions:MultiValue<RSOption>|SingleValue<RSOption>) => { // Event
		if(!(selectedOptions instanceof Array)){ return; }

		const selected:RelicGroup[] = [];
		selectedOptions.forEach((selectedOption) => { selected.push(selectedOption.value as RelicGroup); });

		this.setState((old:ViewGalleryState):ViewGalleryState => { return { ...old, relicsToShow: selected}; });
	}

	layoutSelected = (selectedOptions:MultiValue<RSOption>|SingleValue<RSOption>) => { // Event
		if((selectedOptions instanceof Array)){ return; }

		const selected = selectedOptions;
		if(!selected){ return; }

		const value = selected.value;

		if(utils.guards.isEnumValue(GalleryLayout, value)){
			this.setState((old:ViewGalleryState):ViewGalleryState => { return { ...old, layout: value}; });
		}
	}

	shouldIgnoreJob(job:Job):boolean{
		const selectedJobs = this.state.jobsToShow;
		const selectedRelics = this.state.relicsToShow;

		if(selectedJobs.length > 0 && !selectedJobs.some((z) => { return z === job; })){ return true; }
		if(selectedRelics.length > 0 && selectedRelics.every((z) => { return !jobLists.relic[z].includes(job); })){ return true; }
		return false;
	}

	shouldIgnoreRelic(relic:RelicGroup):boolean{
		if(manager.relics.getRelicType(relic) === RelicType.ARMOUR){ return true; }// TODO: Add in relic armour


		const selectedJobs = this.state.jobsToShow;
		const selectedRelics = this.state.relicsToShow;

		if(selectedRelics.length > 0 && !selectedRelics.includes(relic)){ return true; }
		if(selectedJobs.length > 0 && selectedJobs.every((z) => { return !jobLists.relic[relic].includes(z); })){ return true; }
		return false;
	}
}
export default connect(mapStateToProps)(Gallery);
