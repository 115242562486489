//#region Imports
import { appEnv } from "managers/env";
import { Component } from "react";
import { connect } from "react-redux";
import { AppState } from "types";
import "./TestsiteMessage.css";
//#endregion Imports

interface TestSiteMessageProps {}

const mapStateToProps = (state:AppState):TestSiteMessageProps => {
	return {
		lang: state.userInfo.options.lang,
		gameLang: state.userInfo.options.gameLang,
	};
};

class TestSiteMessage extends Component<TestSiteMessageProps> {
	render(){
		if(appEnv.type === "production"){ return null; } // If production site, return nothing, this is a dev/test only message

		return (
			<div id="testsite-message">
				<h3>This version is still under development</h3>
				<h3>Some features and content may be unavailable or not work as intended</h3>
			</div>
		);
	}
}
export default connect(mapStateToProps)(TestSiteMessage);
