//#region Imports
import { Job } from "enums";
import manager from "managers/app";
import { Component } from "react";
import { connect } from "react-redux";
import t from "translations/translator";
import { AppState, ContentHeaderProps } from "types";
import { Lightbox, SlideImage } from "yet-another-react-lightbox";
import Captions from "yet-another-react-lightbox/plugins/captions";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Inline from "yet-another-react-lightbox/plugins/inline";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import ContentOptions from "../content-options/ContentOptions";
import ImportantNPCs from "../important-npcs/ImportantNPCs";
import RelicRequirements from "../relic-requirements/RelicRequirements";
import "./ContentHeader.css";
//#endregion Imports

const mapStateToProps = (state:AppState) => {
	return {
		lang: state.userInfo.options.lang,
		gameLang: state.userInfo.options.gameLang,
		showJob: state.userInfo.options.job,
	};
};

class ContentHeader extends Component<ContentHeaderProps> {
	render(){
		const selectedJob = this.props.showJob;
		const slides = this.getSlides(selectedJob);

		return (
			<div id="content-header">
				<div className="info">
					<div id="spotlight">
						{
							slides.length === 0
								? <div className="image-wrapper"><h3>{t.t("No images available for this relic")}</h3></div>
								: <Lightbox
									plugins={[Captions, Fullscreen, Zoom, Inline, Thumbnails]}
									slides={slides}
									captions={{ descriptionTextAlign: "center" }}
									carousel={{ imageFit: "cover", finite: true, padding: 0 }}
									inline={{
										style: {
											width: "100%", aspectRatio: "16/9", margin: 0, padding: 0, overflow: "hidden",
											borderRadius: "var(--border-radius-md)", boxShadow: "var(--shadow-lg) var(--shadow-dark)",
										},
									}}
									thumbnails={{ vignette: true }}
									render={{ buttonZoom: () => { return null; } }}
								/>
						}
					</div>
					<RelicRequirements requirements={this.props.requirements}/>
					<ImportantNPCs importantNPCs={this.props.importantNPCs}/>
				</div>
				<div className="options">
					<ContentOptions jobs={this.props.relicInfo.jobs} relic={this.props.relicInfo.relic} part={this.props.relicInfo.part} />
				</div>
				<div className="intro-text">
					{this.props.children}
				</div>
			</div>
		);
	}

	getSlides(selectedJob:Job|null):SlideImage[]{
		const selectedJobSlides:SlideImage[] = [];
		const relicSlides:SlideImage[] = [];
		const images = manager.relics.getAllImages();
		const relicInfo = this.props.relicInfo;

		images.forEach((image) => {
			if(image.part !== relicInfo.part){ return; }

			const slide:SlideImage = {
				src: image.image ? image.image : "",
				description: `${t.t(manager.relics.getJobName(image.job))} - ${t.t(image.relicName)}`,
			};

			if(selectedJob && selectedJob === image.job){ selectedJobSlides.push(slide); }
			relicSlides.push(slide);
		});

		if(selectedJobSlides.length > 0){ return selectedJobSlides; }
		return relicSlides;
	}
}
export default connect(mapStateToProps)(ContentHeader);
