//#region Imports
import { Component } from "react";
import { connect } from "react-redux";
import { AppState, ModalsProps } from "types";
import ConfirmationModal from "./content/confirmation/ConfirmationModal";
import DungeonsModal from "./content/dungeons/DungeonsModal";
import EnemiesModal from "./content/enemies/EnemiesModal";
import ExtractionsModal from "./content/extractions/ExtractionsModal";
import FatesModal from "./content/fates/FatesModal";
import FishingModal from "./content/fishing/FishingModal";
import GatheringModal from "./content/gathering/GatheringModal";
import LevesModal from "./content/leves/LevesModal";
import LocationsModal from "./content/locations/LocationsModal";
import MarketsModal from "./content/markets/MarketsModal";
import OtherModal from "./content/other/OtherModal";
import QuestsModal from "./content/quests/QuestsModal";
import RaidsModal from "./content/raids/RaidsModal";
import RecipesModal from "./content/recipes/RecipesModal";
import ShopsModal from "./content/shops/ShopsModal";
import TrialsModal from "./content/trials/TrialsModal";
import "./Modals.css";
//#endregion Imports

const mapStateToProps = (state:AppState) => {
	return {
		lang: state.userInfo.options.lang,
		gameLang: state.userInfo.options.gameLang,
	};
};

class Modals extends Component<ModalsProps> {
	render(){
		return (
			<div id="modals-container">
				<ConfirmationModal />
				<DungeonsModal />
				<EnemiesModal />
				<ExtractionsModal />
				<FatesModal />
				<FishingModal />
				<GatheringModal />
				<LevesModal />
				<LocationsModal />
				<MarketsModal />
				<OtherModal />
				<QuestsModal />
				<RaidsModal />
				<RecipesModal />
				<ShopsModal />
				<TrialsModal />
			</div>
		);
	}
}
export default connect(mapStateToProps)(Modals);
