//#region Imports
import { CharacterProgress, jobLists } from "character";
import { Item, Job, Modal, RelicGroup, RelicPart, RelicType } from "enums";
import log from "logger";
import { SyntheticEvent } from "react";
import t from "translations/translator";
import { CompleteParts, CompleteSteps, ConfirmationOptions, ModalAppState, ObtainedDungeon, ObtainedEnemy, ObtainedExtraction, ObtainedFate, ObtainedFishing, ObtainedGathering, ObtainedLeve, ObtainedLocation, ObtainedMarket, ObtainedOther, ObtainedQuest, ObtainedRaid, ObtainedShop, ObtainedTrial, RSOption, RSOptionGroup, RecipeModalData, TaskInfo, ViewRelicInfo } from "types"; // eslint-disable-line max-len
import manager from "./app";
import { actions, store } from "./state";
//#endregion Imports

export class ContentManager {
	private characterSaveDelay:number = -1;

	getCompleteSteps(completed:CharacterProgress, relicInfo:ViewRelicInfo):CompleteSteps{
		const completeSteps:CompleteSteps = { allComplete: true };

		relicInfo.steps.forEach((step) => {
			completeSteps[step] = completed.isComplete(relicInfo.type, relicInfo.relic, relicInfo.part, step);
			if(!completeSteps[step]){ completeSteps.allComplete = false; }
		});
		return completeSteps;
	}

	getCompleteParts(completed:CharacterProgress, type:RelicType, relic:RelicGroup, parts:RelicPart[]):CompleteParts{
		const completeParts:CompleteParts = { allComplete: true };

		parts.forEach((part) => {
			completeParts[part] = completed.isComplete(type, relic, part);
			if(!completeParts[part]){ completeParts.allComplete = false; }
		});
		return completeParts;
	}

	updateInventory(item:Item, value:number, skipDelay:boolean){
		const character = manager.data.getActiveCharacter();
		if(!character){ return; }

		manager.data.setInventory(item, value);

		if(skipDelay){
			manager.data.saveCharacters([character], true);
		}else{
			this.characterSaveDelay = window.setTimeout(() => {
				manager.data.saveCharacters([character], true);
			}, 500);
		}
	}

	inventoryUpdated = (evt:SyntheticEvent) => { // Event
		if(this.characterSaveDelay > -1){ clearTimeout(this.characterSaveDelay); }

		const ele = evt.currentTarget as HTMLInputElement;
		const item = ele.dataset.item as Item | undefined;
		const character = manager.data.getActiveCharacter();

		if(!item){ log.error("Missing Item from dataset"); return; }
		if(!character){ log.warn("No active character found"); return; }

		const value = ele.valueAsNumber;
		this.updateInventory(item, value, false);
	}

	openModal<T extends Modal>(modal: T, data: ModalAppState[T]){
		switch(modal){
			default: log.warn("Unknown Modal"); return;
			case Modal.CONFIRMATION: store.dispatch(actions.setModalConfirmation(data as ConfirmationOptions)); break;
			case Modal.ENEMIES: store.dispatch(actions.setModalEnemies(data as ObtainedEnemy[])); break;
			case Modal.RECIPES: store.dispatch(actions.setModalRecipes(data as RecipeModalData)); break;
			case Modal.MARKETS: store.dispatch(actions.setModalMarkets(data as ObtainedMarket)); break;
			case Modal.EXTRACTIONS: store.dispatch(actions.setModalExtractions(data as ObtainedExtraction)); break;
			case Modal.GATHERING: store.dispatch(actions.setModalGathering(data as ObtainedGathering[])); break;
			case Modal.FISHING: store.dispatch(actions.setModalFishing(data as ObtainedFishing[])); break;
			case Modal.SHOPS: store.dispatch(actions.setModalShops(data as ObtainedShop[])); break;
			case Modal.LOCATIONS: store.dispatch(actions.setModalLocations(data as ObtainedLocation[])); break;
			case Modal.TRIALS: store.dispatch(actions.setModalTrials(data as ObtainedTrial[])); break;
			case Modal.DUNGEONS: store.dispatch(actions.setModalDungeons(data as ObtainedDungeon[])); break;
			case Modal.RAIDS: store.dispatch(actions.setModalRaids(data as ObtainedRaid[])); break;
			case Modal.FATES: store.dispatch(actions.setModalFates(data as ObtainedFate[])); break;
			case Modal.LEVES: store.dispatch(actions.setModalLeves(data as ObtainedLeve[])); break;
			case Modal.QUESTS: store.dispatch(actions.setModalQuests(data as ObtainedQuest[])); break;
			case Modal.OTHER: store.dispatch(actions.setModalOther(data as ObtainedOther[])); break;
		}
		manager.modal.open(modal);
	}

	getRowSpan(hideCompletedTasks:boolean, taskInfo:TaskInfo[], currentTaskIndex:number){
		let rowSpan = 0;
		if(hideCompletedTasks){
			let totalCompleted = 0;
			taskInfo.forEach((task) => {
				totalCompleted += Number(task.complete);
			});
			rowSpan = taskInfo.length - totalCompleted;
		}else{
			rowSpan = currentTaskIndex === 0 ? taskInfo.length : 1;
		}
		return rowSpan;
	}

	shouldShowJobIcon(hideCompletedTasks:boolean, taskInfo:TaskInfo[], currentTaskIndex:number){
		let showJobIcon = true;

		if(hideCompletedTasks){
			const thisTask = taskInfo[currentTaskIndex];
			let taskBeforeIncomplete = false;
			taskInfo.some((task) => {
				if(task.task === thisTask.task){ return true; }
				if(!task.complete){ taskBeforeIncomplete = true; return true; }
				return false;
			});

			if(taskBeforeIncomplete){ showJobIcon = false; }
		}else if(taskInfo.length !== 1){
			const rowSpan = this.getRowSpan(hideCompletedTasks, taskInfo, currentTaskIndex);
			showJobIcon = rowSpan !== 1;
		}
		return showJobIcon;
	}

	getJobSelectOptions(filteredJobs:Job[], allowNone:boolean):(RSOption | RSOptionGroup)[]{
		const jobOptions:RSOptionGroup[] = [
			{ label: t.t("Tank"), options: [] },
			{ label: t.t("Healer"), options: [] },
			{ label: t.t("DPS"), options: [] },
			{ label: t.t("Crafter"), options: [] },
			{ label: t.t("Gatherer"), options: [] },
		];

		jobLists.type.tanks.forEach((job) => {
			if(filteredJobs.includes(job)){
				jobOptions[0].options.push({ label: t.t(manager.relics.getJobName(job)), value: job });
			}
		});
		jobLists.type.healers.forEach((job) => {
			if(filteredJobs.includes(job)){
				jobOptions[1].options.push({ label: t.t(manager.relics.getJobName(job)), value: job });
			}
		});
		jobLists.type.dps.forEach((job) => {
			if(filteredJobs.includes(job)){
				jobOptions[2].options.push({ label: t.t(manager.relics.getJobName(job)), value: job });
			}
		});
		jobLists.type.crafter.forEach((job) => {
			if(filteredJobs.includes(job)){
				jobOptions[3].options.push({ label: t.t(manager.relics.getJobName(job)), value: job });
			}
		});
		jobLists.type.gatherer.forEach((job) => {
			if(filteredJobs.includes(job)){
				jobOptions[4].options.push({ label: t.t(manager.relics.getJobName(job)), value: job });
			}
		});

		const filteredGroups = jobOptions.filter((group) => { return group.options.length > 0; });
		const finalOptions:(RSOption | RSOptionGroup)[] = filteredGroups;

		if(allowNone){ finalOptions.unshift({ label: t.t("None"), value: "none" }); }

		return finalOptions;
	}

	getRelicPartSelectOptions():RSOptionGroup[]{
		// const partOptions:RSOptionGroup[] = Object.values(RelicGroup).map((relic) => {
		const partOptions:RSOptionGroup[] = Object.values(RelicGroup) // TODO: Add in relic armour
			.filter((z) => { return manager.relics.getRelicType(z) !== RelicType.ARMOUR; })
			.map((relic) => {
				const relicParts = manager.relics.getRelicPartsForRelic(relic);
				const relicType = manager.relics.getRelicType(relic);

				const theseOptions:RSOption[] = relicParts.map((part) => {
					return { label: `${t.t(part)} (iLvl ${manager.relics.getPartILvl(part)})`, value: part };
				});
				return { label: `${t.t(relicType)} - ${t.t(relic)}`, options: theseOptions};
			});

		return partOptions;
	}

	getRelicSelectOptions():RSOptionGroup[]{
		const relicOptions:RSOptionGroup[] = [
			{ label: t.t(RelicType.WEAPON), options: [] },
			{ label: t.t(RelicType.TOOL), options: [] },
			// { label: t.t(RelicType.ARMOUR), options: [] }, // TODO: Add in relic armour
		];

		Object.values(RelicGroup).forEach((relic) => {
			const thisType = manager.relics.getRelicType(relic);
			const thisOption = { label: t.t(relic), value: relic };

			switch(thisType){
				default: log.warn("Unknown Relic Type"); break;
				case RelicType.WEAPON: relicOptions[0].options.push(thisOption); break;
				case RelicType.TOOL: relicOptions[1].options.push(thisOption); break;
				// case RelicType.ARMOUR: relicOptions[2].options.push(thisOption); break; // TODO: Add in relic armour
			}
		});

		return relicOptions;
	}
}

const content = new ContentManager();
export default content;
