//#region Imports
import { faLightbulb } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Component } from "react";
import { connect } from "react-redux";
import t from "translations/translator";
import { AppState, TipProps } from "types";
import "./Tip.css";
//#endregion Imports

const mapStateToProps = (state:AppState) => {
	return {
		lang: state.userInfo.options.lang,
		gameLang: state.userInfo.options.gameLang,
	};
};

class Tip extends Component<TipProps> {
	render(){
		return (
			<p className="tip"><FontAwesomeIcon icon={faLightbulb} />{t.t("Tip")}: {t.t(this.props.text, this.props.placeholders)}</p>
		);
	}
}
export default connect(mapStateToProps)(Tip);
