//#region Imports
import { EnvironmentType } from "enums";
import { Environment } from "types";
//#endregion Imports

const rawEnv = process.env; // eslint-disable-line no-undef, no-process-env

const builtEnv:Environment = {
	version: "3.3.1",
	dataVersion: "3.3.0",
	type: rawEnv.REACT_APP_ENV as EnvironmentType,
	auth0: {
		domain: rawEnv.REACT_APP_AUTH0_DOMAIN as string,
		clientId: rawEnv.REACT_APP_AUTH0_CLIENTID as string,
		audience: rawEnv.REACT_APP_AUTH0_AUDIENCE as string,
	},
	apiUrl: rawEnv.REACT_APP_API_URL as string,
	ga: { trackingCode: rawEnv.REACT_APP_GTM_CODE as string },
	saveDelay: Number(rawEnv.REACT_APP_SAVE_DELAY) ?? 30000,
};
Object.freeze(builtEnv);

export const appEnv = builtEnv;
