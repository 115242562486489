//#region Imports
import FormComponents from "components/forms/FormComponents";
import Link from "components/link/Link";
import { quests } from "data-files/quests";
import { Icon, LinkType, Modal } from "enums";
import manager from "managers/app";
import ModalContent from "modals/content/ModalContent";
import ModalFooter from "modals/footer/ModalFooter";
import ModalHeader from "modals/header/ModalHeader";
import { Component } from "react";
import { connect } from "react-redux";
import { AppState, QuestsModalProps } from "types";
import "./QuestsModal.css";
//#endregion Imports

const mapStateToProps = (state:AppState) => {
	return {
		lang: state.userInfo.options.lang,
		gameLang: state.userInfo.options.gameLang,
		quests: state.modal[Modal.QUESTS],
	};
};

class QuestsModal extends Component<QuestsModalProps> {
	render(){
		return (
			<div id={Modal.QUESTS} className="modal-wrapper">
				<ModalHeader text="Quests" icon={Icon.QUEST} />
				<ModalContent>
					{this.props.quests.map((thisQuest, index) => {
						const questInfo = quests[thisQuest];
						return <p key={`quest-${index}`}>
							<Link type={LinkType.LODESTONE} lodestoneType={questInfo.type} lodestoneID={questInfo.lodestoneID} text={questInfo.name} />
						</p>;
					})}
				</ModalContent>
				<ModalFooter>
					<FormComponents.Button text="Close" onClick={manager.modal.close} translate={true} />
				</ModalFooter>
			</div>
		);
	}
}
export default connect(mapStateToProps)(QuestsModal);
