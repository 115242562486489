//#region Imports
import { faSort, faSortAmountDown, faSortAmountUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Currency, CurrencyTableColumn, RelicGroup } from "enums";
import { Component } from "react";
import { connect } from "react-redux";
import t from "translations/translator";
import { AppState, CurrencyTableProps } from "types";
//#endregion Imports

const mapStateToProps = (state:AppState) => {
	return {
		lang: state.userInfo.options.lang,
		gameLang: state.userInfo.options.gameLang,
		breakpoint: state.breakpoint,
		options: state.userInfo.options,
		itemOptions: state.userInfo.options.items,
	};
};


class CurrencyTable extends Component<CurrencyTableProps> {
	render(){
		// const rows = this.props.rows;

		// const sortedRows =


		return (
			<table className="table sortable">
				<thead>
					<tr>
						<th colSpan={Object.values(CurrencyTableColumn).length}>
							<p>{t.t("These values currently only take into account items when they are used directly in a relic step")}</p>
							<p>{t.t("For example: %CURRENCY% is excluded as it is not directly used to get the items required for %RELIC%", { CURRENCY: Currency.WHITE_CRAFTERS_SCRIP, RELIC: RelicGroup.TOOL_SKYSTEEL })}</p>
						</th>
					</tr>
					<tr>
						<th onClick={this.props.sortTable} data-sortfield={CurrencyTableColumn.CURRENCY}>{t.t("Currency")}<FontAwesomeIcon icon={this.getSortIcon(CurrencyTableColumn.CURRENCY)}/></th>
						<th onClick={this.props.sortTable} data-sortfield={CurrencyTableColumn.REQUIRED}>{t.t("Total Required")}<FontAwesomeIcon icon={this.getSortIcon(CurrencyTableColumn.REQUIRED)}/></th>
						<th onClick={this.props.sortTable} data-sortfield={CurrencyTableColumn.USED}>{t.t("Total Used")}<FontAwesomeIcon icon={this.getSortIcon(CurrencyTableColumn.USED)}/></th>
						<th onClick={this.props.sortTable} data-sortfield={CurrencyTableColumn.REMAINING}>{t.t("Total Remaining")}<FontAwesomeIcon icon={this.getSortIcon(CurrencyTableColumn.REMAINING)}/></th>
					</tr>
				</thead>
				<tbody>
					{this.props.rows.map((row) => { // eslint-disable-line complexity
						if(row.required === 0){ return null; }

						return <tr key={`${row.currencyNumber}-${row.currency}`}>
							<td>{t.t(row.currency)}</td>
							<td>{row.required.toLocaleString()}</td>
							<td>{row.used.toLocaleString()}</td>
							<td>{row.remaining.toLocaleString()}</td>
						</tr>;
					})}
				</tbody>
			</table>
		);
	}

	getSortIcon(column:CurrencyTableColumn){
		const defaultSortIcon = faSort;
		const currentSortIcon = this.props.sortAsc ? faSortAmountUp : faSortAmountDown;
		return this.props.sortColumn === column ? currentSortIcon : defaultSortIcon;
	}
}
export default connect(mapStateToProps)(CurrencyTable);
