//#region Imports
import { Enemy, MapLocation } from "enums";
import { EnemyInfo } from "types";
//#endregion Imports

export const enemies:{ [key in Enemy]: EnemyInfo } = {
	[Enemy.UNKNOWN]: { name: Enemy.UNKNOWN, found: [{ location: MapLocation.UNKNOWN, x: -1, y: -1 }] },
	[Enemy.ZAHARAK_LANCER]: { name: Enemy.ZAHARAK_LANCER, found: [{ location: MapLocation.ZAHARAK, x: 29, y: 16 }] },
	[Enemy.ZAHARAK_PUGILIST]: { name: Enemy.ZAHARAK_PUGILIST, found: [{ location: MapLocation.ZAHARAK, x: 23, y: 21 }] },
	[Enemy.ZAHARAK_THAUMATURGE]: { name: Enemy.ZAHARAK_THAUMATURGE, found: [{ location: MapLocation.ZAHARAK, x: 22, y: 22 }] },
	[Enemy.UGHAMARO_QUARRYMAN]: { name: Enemy.UGHAMARO_QUARRYMAN, found: [{ location: MapLocation.UGHAMARO_MINES, x: 25, y: 9 }] },
	[Enemy.UGHAMARO_BEDESMAN]: { name: Enemy.UGHAMARO_BEDESMAN, found: [{ location: MapLocation.UGHAMARO_MINES, x: 25, y: 9 }] },
	[Enemy.UGHAMARO_PRIEST]: { name: Enemy.UGHAMARO_PRIEST, found: [{ location: MapLocation.UGHAMARO_MINES, x: 27, y: 7 }] },
	[Enemy.UGHAMARO_ROUNDSMAN]: { name: Enemy.UGHAMARO_ROUNDSMAN, found: [{ location: MapLocation.UGHAMARO_MINES, x: 23, y: 9 }] },
	[Enemy.NATALAN_BOLDWING]: { name: Enemy.NATALAN_BOLDWING, found: [{ location: MapLocation.NATALAN, x: 33, y: 18 }] },
	[Enemy.NATALAN_FOGCALLER]: { name: Enemy.NATALAN_FOGCALLER, found: [{ location: MapLocation.NATALAN, x: 32, y: 18 }] },
	[Enemy.NATALAN_WINDTALON]: { name: Enemy.NATALAN_WINDTALON, found: [{ location: MapLocation.NATALAN, x: 32, y: 22 }] },
	[Enemy.ZAHARAK_ARCHER]: { name: Enemy.ZAHARAK_ARCHER, found: [{ location: MapLocation.ZAHARAK, x: 25, y: 21 }] },
	[Enemy.SAPSA_SHELFSPINE]: { name: Enemy.SAPSA_SHELFSPINE, found: [{ location: MapLocation.SAPSA_SPAWNING_GROUNDS, x: 17, y: 15 }] },
	[Enemy.SAPSA_SHELFCLAW]: { name: Enemy.SAPSA_SHELFCLAW, found: [{ location: MapLocation.SAPSA_SPAWNING_GROUNDS, x: 14, y: 15 }] },
	[Enemy.SAPSA_SHELFTOOTH]: { name: Enemy.SAPSA_SHELFTOOTH, found: [{ location: MapLocation.SAPSA_SPAWNING_GROUNDS, x: 15, y: 15 }] },
	[Enemy.VIOLET_SIGH]: { name: Enemy.VIOLET_SIGH, found: [{ location: MapLocation.SYLPHLANDS, x: 24, y: 13 }] },
	[Enemy.VIOLET_SCREECH]: { name: Enemy.VIOLET_SCREECH, found: [{ location: MapLocation.SYLPHLANDS, x: 24, y: 14 }] },
	[Enemy.VIOLET_SNARL]: { name: Enemy.VIOLET_SNARL, found: [{ location: MapLocation.SYLPHLANDS, x: 24, y: 16 }] },
	[Enemy.DARING_HARRIER]: { name: Enemy.DARING_HARRIER, found: [{ location: MapLocation.FOGFENS, x: 17, y: 16 }] },
	[Enemy.COHORT_VANGUARD_5TH]: { name: Enemy.COHORT_VANGUARD_5TH, found: [{ location: MapLocation.CASTRUM_CENTRI, x: 11, y: 15 }] },
	[Enemy.COHORT_HOPLOMACHUS_4TH]: { name: Enemy.COHORT_HOPLOMACHUS_4TH, found: [{ location: MapLocation.CAPE_WESTWIND, x: 11, y: 6 }] },
	[Enemy.BASILISK]: { name: Enemy.BASILISK, found: [{ location: MapLocation.BLUEFOG, x: 23, y: 23 }] },
	[Enemy.ZANRAK_PUGILIST]: { name: Enemy.ZANRAK_PUGILIST, found: [{ location: MapLocation.ZANRAK, x: 19, y: 25 }] },
	[Enemy.MILKROOT_CLUSTER]: { name: Enemy.MILKROOT_CLUSTER, found: [{ location: MapLocation.SYLPHLANDS, x: 23, y: 16 }] },
	[Enemy.GIANT_LOGGER]: { name: Enemy.GIANT_LOGGER, found: [{ location: MapLocation.BOULDER_DOWNS, x: 13, y: 25 }] },
	[Enemy.SYNTHETIC_DOBLYN]: { name: Enemy.SYNTHETIC_DOBLYN, found: [{ location: MapLocation.UGHAMARO_MINES, x: 23, y: 8 }] },
	[Enemy.SHOALSPINE_SAHAGIN]: { name: Enemy.SHOALSPINE_SAHAGIN, found: [{ location: MapLocation.SAPSA_SPAWNING_GROUNDS, x: 17, y: 15 }] },
	[Enemy.COHORT_HOPLOMACHUS_2ND]: { name: Enemy.COHORT_HOPLOMACHUS_2ND, found: [{ location: MapLocation.AGELYSS_WISE, x: 25, y: 21 }] },
	[Enemy.RAGING_HARRIER]: { name: Enemy.RAGING_HARRIER, found: [{ location: MapLocation.FOGFENS, x: 16, y: 17 }] },
	[Enemy.BIAST]: { name: Enemy.BIAST, found: [{ location: MapLocation.BOULDER_DOWNS, x: 16, y: 30 }] },
	[Enemy.SHOALTOOTH_SAHAGIN]: { name: Enemy.SHOALTOOTH_SAHAGIN, found: [{ location: MapLocation.SAPSA_SPAWNING_GROUNDS, x: 17, y: 17 }] },
	[Enemy.SHELFSCALE_REAVER]: { name: Enemy.SHELFSCALE_REAVER, found: [{ location: MapLocation.SAPSA_SPAWNING_GROUNDS, x: 13, y: 17 }] },
	[Enemy.UGHAMARO_GOLEM]: { name: Enemy.UGHAMARO_GOLEM, found: [{ location: MapLocation.UGHAMARO_MINES, x: 28, y: 7 }] },
	[Enemy.DULLAHAN]: { name: Enemy.DULLAHAN, found: [{ location: MapLocation.PROUD_CREEK, x: 23, y: 20 }] },
	[Enemy.SYLPHEED_SIGH]: { name: Enemy.SYLPHEED_SIGH, found: [{ location: MapLocation.SYLPHLANDS, x: 29, y: 17 }] },
	[Enemy.TEMPERED_GLADIATOR]: { name: Enemy.TEMPERED_GLADIATOR, found: [{ location: MapLocation.ZANRAK, x: 22, y: 20 }] },
	[Enemy.HEXING_HARRIER]: { name: Enemy.HEXING_HARRIER, found: [{ location: MapLocation.FOGFENS, x: 16, y: 15 }] },
	[Enemy.GIGAS_BONZE]: { name: Enemy.GIGAS_BONZE, found: [{ location: MapLocation.NORTH_SILVERTEAR, x: 27, y: 9 }] },
	[Enemy.GIANT_LUGGER]: { name: Enemy.GIANT_LUGGER, found: [{ location: MapLocation.BOULDER_DOWNS, x: 13, y: 27 }] },
	[Enemy.WILD_HOG]: { name: Enemy.WILD_HOG, found: [{ location: MapLocation.URTHS_GIFT, x: 29, y: 24 }] },
	[Enemy.SYLPHEED_SCREECH]: { name: Enemy.SYLPHEED_SCREECH, found: [{ location: MapLocation.SYLPHLANDS, x: 28, y: 15 }] },
	[Enemy.SHELFCLAW_REAVER]: { name: Enemy.SHELFCLAW_REAVER, found: [{ location: MapLocation.SAPSA_SPAWNING_GROUNDS, x: 13, y: 17 }] },
	[Enemy.COHORT_LAQUEARIUS_2ND]: { name: Enemy.COHORT_LAQUEARIUS_2ND, found: [{ location: MapLocation.AGELYSS_WISE, x: 29, y: 20 }] },
	[Enemy.ZAHARAK_FORTUNETELLER]: { name: Enemy.ZAHARAK_FORTUNETELLER, found: [{ location: MapLocation.ZAHARAK, x: 29, y: 19 }] },
	[Enemy.TEMPERED_ORATOR]: { name: Enemy.TEMPERED_ORATOR, found: [{ location: MapLocation.ZANRAK, x: 21, y: 20 }] },
	[Enemy.MUDPUPPY]: { name: Enemy.MUDPUPPY, found: [{ location: MapLocation.FOGFENS, x: 14, y: 11 }] },
	[Enemy.LAKE_COBRA]: { name: Enemy.LAKE_COBRA, found: [{ location: MapLocation.NORTH_SILVERTEAR, x: 26, y: 12 }] },
	[Enemy.GIANT_READER]: { name: Enemy.GIANT_READER, found: [{ location: MapLocation.BOULDER_DOWNS, x: 12, y: 25 }] },
	[Enemy.SHELFSCALE_SAHAGIN]: { name: Enemy.SHELFSCALE_SAHAGIN, found: [{ location: MapLocation.HALFSTONE, x: 17, y: 19 }] },
	[Enemy.SEA_WASP]: { name: Enemy.SEA_WASP, found: [{ location: MapLocation.SAPSA_SPAWNING_GROUNDS, x: 14, y: 17 }] },
	[Enemy.COHORT_EQUES_2ND]: { name: Enemy.COHORT_EQUES_2ND, found: [{ location: MapLocation.AGELYSS_WISE, x: 29, y: 21 }] },
	[Enemy.MAGITEK_VANGUARD]: { name: Enemy.MAGITEK_VANGUARD, found: [{ location: MapLocation.RAUBAHNS_PUSH, x: 17, y: 17 }] },
	[Enemy.AMALJAA_LANCER]: { name: Enemy.AMALJAA_LANCER, found: [{ location: MapLocation.ZANRAK, x: 20, y: 20 }] },
	[Enemy.SYLPHLANDS_SENTINEL]: { name: Enemy.SYLPHLANDS_SENTINEL, found: [{ location: MapLocation.SYLPHLANDS, x: 20, y: 10 }] },
	[Enemy.GIGAS_BHIKKHU]: { name: Enemy.GIGAS_BHIKKHU, found: [{ location: MapLocation.NORTH_SILVERTEAR, x: 33, y: 14 }] },
	[Enemy.COHORT_HOPLOMACHUS_5TH]: { name: Enemy.COHORT_HOPLOMACHUS_5TH, found: [{ location: MapLocation.FOGFENS, x: 12, y: 12 }] },
	[Enemy.NATALAN_WATCHWOLF]: { name: Enemy.NATALAN_WATCHWOLF, found: [{ location: MapLocation.NATALAN, x: 31, y: 17 }] },
	[Enemy.SYLPH_BONNET]: { name: Enemy.SYLPH_BONNET, found: [{ location: MapLocation.SYLPHLANDS, x: 26, y: 13 }] },
	[Enemy.KED]: { name: Enemy.KED, found: [{ location: MapLocation.URTHS_GIFT, x: 31, y: 23 }] },
	[Enemy.COHORT_LAQUEARIUS_4TH]: { name: Enemy.COHORT_LAQUEARIUS_4TH, found: [{ location: MapLocation.CAPE_WESTWIND, x: 10, y: 6 }] },
	[Enemy.IRON_TORTOISE]: { name: Enemy.IRON_TORTOISE, found: [{ location: MapLocation.ZANRAK, x: 16, y: 24 }] },
	[Enemy.SHELFEYE_REAVER]: { name: Enemy.SHELFEYE_REAVER, found: [{ location: MapLocation.SAPSA_SPAWNING_GROUNDS, x: 13, y: 17 }] },
	[Enemy.SAPSA_SHELFSCALE]: { name: Enemy.SAPSA_SHELFSCALE, found: [{ location: MapLocation.SAPSA_SPAWNING_GROUNDS, x: 14, y: 14 }] },
	[Enemy.AMALJAA_BRIGAND]: { name: Enemy.AMALJAA_BRIGAND, found: [{ location: MapLocation.ZANRAK, x: 20, y: 21 }] },
	[Enemy.COHORT_SECUTOR_4TH]: { name: Enemy.COHORT_SECUTOR_4TH, found: [{ location: MapLocation.CAPE_WESTWIND, x: 9, y: 6 }] },
	[Enemy.SNOW_WOLF]: { name: Enemy.SNOW_WOLF, found: [{ location: MapLocation.BOULDER_DOWNS, x: 13, y: 30 }] },
	[Enemy.IXALI_WINDTALON]: { name: Enemy.IXALI_WINDTALON, found: [{ location: MapLocation.PROUD_CREEK, x: 19, y: 19 }] },
	[Enemy.LESSER_KALONG]: { name: Enemy.LESSER_KALONG, found: [{ location: MapLocation.SNAKEMOLT, x: 0, y: 0 }, { location: MapLocation.ALDER_SPRINGS, x: 24, y: 23 }, { location: MapLocation.URTHS_GIFT, x: 28, y: 22 }, { location: MapLocation.PROUD_CREEK, x: 21, y: 20 }] }, // eslint-disable-line max-len
	[Enemy.GIGAS_SOZU]: { name: Enemy.GIGAS_SOZU, found: [{ location: MapLocation.NORTH_SILVERTEAR, x: 29, y: 14 }] },
	[Enemy.COHORT_LAQUEARIUS_5TH]: { name: Enemy.COHORT_LAQUEARIUS_5TH, found: [{ location: MapLocation.FOGFENS, x: 12, y: 12 }] },
	[Enemy.HIPPOGRYPH]: { name: Enemy.HIPPOGRYPH, found: [{ location: MapLocation.NORTH_SILVERTEAR, x: 33, y: 11 }] },
	[Enemy.COHORT_EQUES_5TH]: { name: Enemy.COHORT_EQUES_5TH, found: [{ location: MapLocation.FOGFENS, x: 12, y: 12 }] },
	[Enemy.SAPSA_ELBST]: { name: Enemy.SAPSA_ELBST, found: [{ location: MapLocation.SAPSA_SPAWNING_GROUNDS, x: 17, y: 15 }] },
	[Enemy.TRENCHTOOTH_SAHAGIN]: { name: Enemy.TRENCHTOOTH_SAHAGIN, found: [{ location: MapLocation.HALFSTONE, x: 20, y: 19 }] },
	[Enemy.ELITE_ROUNDSMAN]: { name: Enemy.ELITE_ROUNDSMAN, found: [{ location: MapLocation.UGHAMARO_MINES, x: 25, y: 8 }] },
	[Enemy.COHORT_SECUTORES_2ND]: { name: Enemy.COHORT_SECUTORES_2ND, found: [{ location: MapLocation.AGELYSS_WISE, x: 25, y: 21 }] },
	[Enemy.AHRIMAN]: { name: Enemy.AHRIMAN, found: [{ location: MapLocation.BLUEFOG, x: 24, y: 21 }] },
	[Enemy.AMALJAA_THAUMATURGE]: { name: Enemy.AMALJAA_THAUMATURGE, found: [{ location: MapLocation.ZANRAK, x: 18, y: 19 }] },
	[Enemy.SYLPHEED_SNARL]: { name: Enemy.SYLPHEED_SNARL, found: [{ location: MapLocation.SYLPHLANDS, x: 28, y: 17 }] },
	[Enemy.GIGAS_SHRAMANA]: { name: Enemy.GIGAS_SHRAMANA, found: [{ location: MapLocation.NORTH_SILVERTEAR, x: 28, y: 13 }] },
	[Enemy.COHORT_SIGNIFER_5TH]: { name: Enemy.COHORT_SIGNIFER_5TH, found: [{ location: MapLocation.FOGFENS, x: 10, y: 13 }] },
	[Enemy.WATCHWOLF]: { name: Enemy.WATCHWOLF, found: [{ location: MapLocation.PROUD_CREEK, x: 19, y: 19 }] },
	[Enemy.DREAMTOAD]: { name: Enemy.DREAMTOAD, found: [{ location: MapLocation.SYLPHLANDS, x: 26, y: 18 }] },
	[Enemy.ZAHARAK_BATTLE_DRAKE]: { name: Enemy.ZAHARAK_BATTLE_DRAKE, found: [{ location: MapLocation.ZAHARAK, x: 30, y: 19 }] },
	[Enemy.AMALJAA_ARCHER]: { name: Enemy.AMALJAA_ARCHER, found: [{ location: MapLocation.ZANRAK, x: 20, y: 22 }] },
	[Enemy.COHORT_SIGNIFER_4TH]: { name: Enemy.COHORT_SIGNIFER_4TH, found: [{ location: MapLocation.CAPE_WESTWIND, x: 11, y: 7 }] },
	[Enemy.ELITE_PRIEST]: { name: Enemy.ELITE_PRIEST, found: [{ location: MapLocation.UGHAMARO_MINES, x: 24, y: 7 }] },
	[Enemy.IXALI_BOLDWING]: { name: Enemy.IXALI_BOLDWING, found: [{ location: MapLocation.PROUD_CREEK, x: 21, y: 20 }] },
	[Enemy.AMALJAA_SCAVENGER]: { name: Enemy.AMALJAA_SCAVENGER, found: [{ location: MapLocation.ZANRAK, x: 20, y: 21 }] },
	[Enemy.AXOLOTL]: { name: Enemy.AXOLOTL, found: [{ location: MapLocation.SAPSA_SPAWNING_GROUNDS, x: 14, y: 15 }] },
	[Enemy.ELITE_QUARRYMAN]: { name: Enemy.ELITE_QUARRYMAN, found: [{ location: MapLocation.UGHAMARO_MINES, x: 24, y: 7 }] },
	[Enemy.COHORT_SIGNIFER_2ND]: { name: Enemy.COHORT_SIGNIFER_2ND, found: [{ location: MapLocation.AGELYSS_WISE, x: 30, y: 20 }] },
	[Enemy.NATALAN_SWIFTBEAK]: { name: Enemy.NATALAN_SWIFTBEAK, found: [{ location: MapLocation.NATALAN, x: 31, y: 17 }] },
	[Enemy.COHORT_SECUTOR_5TH]: { name: Enemy.COHORT_SECUTOR_5TH, found: [{ location: MapLocation.FOGFENS, x: 10, y: 13 }] },
	[Enemy.HAPALIT]: { name: Enemy.HAPALIT, found: [{ location: MapLocation.NORTH_SILVERTEAR, x: 30, y: 5 }] },
	[Enemy.ATROCIRAPTOR]: { name: Enemy.ATROCIRAPTOR, found: [{ location: MapLocation.CITIA_SWAMPS, x: 12, y: 34 }] },
	[Enemy.VAMPIRE_VINE]: { name: Enemy.VAMPIRE_VINE, found: [{ location: MapLocation.CITIA_SWAMPS, x: 15, y: 32 }, { location: MapLocation.LAKE_TUSI_MEKTA, x: 10, y: 19 }] },
	[Enemy.VAMPIRE_CUP]: { name: Enemy.VAMPIRE_CUP, found: [{ location: MapLocation.LAKE_TUSI_MEKTA, x: 9, y: 23 }] },
	[Enemy.DIADEM_MELIA]: { name: Enemy.DIADEM_MELIA, found: [{ location: MapLocation.DIADEM, x: -1, y: -1 }] },
	[Enemy.DIADEM_ICETRAP]: { name: Enemy.DIADEM_ICETRAP, found: [{ location: MapLocation.DIADEM, x: -1, y: -1 }] },
	[Enemy.DIADEM_ICE_BOMB]: { name: Enemy.DIADEM_ICE_BOMB, found: [{ location: MapLocation.DIADEM, x: -1, y: -1 }] },
	[Enemy.DIADEM_ZOBLYN]: { name: Enemy.DIADEM_ZOBLYN, found: [{ location: MapLocation.DIADEM, x: -1, y: -1 }] },
	[Enemy.PROTO_NOCTILUCALE]: { name: Enemy.PROTO_NOCTILUCALE, found: [{ location: MapLocation.DIADEM, x: -1, y: -1 }] },
	[Enemy.DIADEM_BLOATED_BULB]: { name: Enemy.DIADEM_BLOATED_BULB, found: [{ location: MapLocation.DIADEM, x: -1, y: -1 }] },
	[Enemy.DIADEM_ICE_GOLEM]: { name: Enemy.DIADEM_ICE_GOLEM, found: [{ location: MapLocation.DIADEM, x: -1, y: -1 }] },
	[Enemy.DIADEM_BIAST]: { name: Enemy.DIADEM_BIAST, found: [{ location: MapLocation.DIADEM, x: -1, y: -1 }] },
	[Enemy.DIADEM_GOLEM]: { name: Enemy.DIADEM_GOLEM, found: [{ location: MapLocation.DIADEM, x: -1, y: -1 }] },
	[Enemy.DIADEM_WEREWOOD]: { name: Enemy.DIADEM_WEREWOOD, found: [{ location: MapLocation.DIADEM, x: -1, y: -1 }] },
	[Enemy.GRENADE]: { name: Enemy.GRENADE, found: [{ location: MapLocation.BLUEFOG, x: 26, y: 23 }, { location: MapLocation.IRON_LAKE, x: 21, y: 15 }] },
	[Enemy.WOOLY_YAK]: { name: Enemy.WOOLY_YAK, found: [{ location: MapLocation.TWINPOOLS, x: 16, y: 18 }] },
	[Enemy.OKEANIS]: { name: Enemy.OKEANIS, found: [{ location: MapLocation.RULING_QUARTER, x: 15, y: 32 }] },
	[Enemy.FLAME_ZONURE]: { name: Enemy.FLAME_ZONURE, found: [{ location: MapLocation.PRISTINE_PALACE_OF_AMH_MALIK, x: 27, y: 35 }] },
	[Enemy.TEMPEST_SWALLOW]: { name: Enemy.TEMPEST_SWALLOW, found: [{ location: MapLocation.NORVRANDT_SLOPE, x: 27, y: 14 }] },
	[Enemy.OCHU]: { name: Enemy.OCHU, found: [{ location: MapLocation.HONEY_YARD, x: 13, y: 25 }, { location: MapLocation.LARKSCALL, x: 28, y: 23 }, { location: MapLocation.SYLPHLANDS, x: 26, y: 20 }] },
	[Enemy.ROCKSKIN_PEISTE]: { name: Enemy.ROCKSKIN_PEISTE, found: [{ location: MapLocation.BROKEN_WATER, x: 15, y: 13 }] },
	[Enemy.SANDSKIN_PEISTE]: { name: Enemy.SANDSKIN_PEISTE, found: [{ location: MapLocation.BROKEN_WATER, x: 15, y: 13 }] },
	[Enemy.OVIM_BILLY]: { name: Enemy.OVIM_BILLY, found: [{ location: MapLocation.NORTHERN_HILLS_OF_AMBER, x: 16, y: 14 }] },
	[Enemy.OVIM_NANNY]: { name: Enemy.OVIM_NANNY, found: [{ location: MapLocation.NORTHERN_HILLS_OF_AMBER, x: 16, y: 14 }] },
	[Enemy.ALMASTY]: { name: Enemy.ALMASTY, found: [{ location: MapLocation.CERULEA_INGENS, x: 30, y: 30 }] },
	[Enemy.IANTHINE_PETALOUDA]: { name: Enemy.IANTHINE_PETALOUDA, found: [{ location: MapLocation.PHILOMYTHES_NOTOS, x: 25, y: 34 }] },
	[Enemy.KOKKINE_PETALOUDA]: { name: Enemy.KOKKINE_PETALOUDA, found: [{ location: MapLocation.PHILOMYTHES_NOTOS, x: 25, y: 34 }] },
	[Enemy.KYANE_PETALOUDA]: { name: Enemy.KYANE_PETALOUDA, found: [{ location: MapLocation.PHILOMYTHES_NOTOS, x: 25, y: 34 }] },
	[Enemy.OPHIOTAUROS]: { name: Enemy.OPHIOTAUROS, found: [{ location: MapLocation.ETHOSELETIKOS_BOREAS, x: 13, y: 9 }] },
	[Enemy.LUNATENDER]: { name: Enemy.LUNATENDER, found: [{ location: MapLocation.SOUTHERN_MARE_LAMENTORUM, x: 26, y: 31 }] },
	[Enemy.MOURNING_BILLY]: { name: Enemy.MOURNING_BILLY, found: [{ location: MapLocation.WELLWICK_WOOD, x: 26, y: 18 }] },
	[Enemy.MOURNING_NANNY]: { name: Enemy.MOURNING_NANNY, found: [{ location: MapLocation.WELLWICK_WOOD, x: 26, y: 18 }] },
	[Enemy.MYOTRAGUS_BILLY]: { name: Enemy.MYOTRAGUS_BILLY, found: [{ location: MapLocation.DRYBONE, x: 15, y: 23 }] },
	[Enemy.MYOTRAGUS_NANNY]: { name: Enemy.MYOTRAGUS_NANNY, found: [{ location: MapLocation.DRYBONE, x: 15, y: 23 }] },
	[Enemy.KUMBHIRA]: { name: Enemy.KUMBHIRA, found: [{ location: MapLocation.THAVNAIR, x: 32, y: 12 }] },
	[Enemy.HOPTRAP]: { name: Enemy.HOPTRAP, found: [{ location: MapLocation.LAKELAND, x: 29, y: 25 }] },
	[Enemy.APKALLU]: { name: Enemy.APKALLU, found: [{ location: MapLocation.EASTERN_LA_NOSCEA, x: 27, y: 36 }] },
	[Enemy.SAIGA]: { name: Enemy.SAIGA, found: [{ location: MapLocation.GARLEMALD, x: 31, y: 22 }] },
	[Enemy.OVIBOS]: { name: Enemy.OVIBOS, found: [{ location: MapLocation.GARLEMALD, x: 20, y: 29 }] },
	[Enemy.MOUSSE]: { name: Enemy.MOUSSE, found: [{ location: MapLocation.MARE_LAMENTORUM, x: 15, y: 24 }] },
	[Enemy.BIRD_OF_ELPIS]: { name: Enemy.BIRD_OF_ELPIS, found: [{ location: MapLocation.ELPIS, x: 28, y: 24 }] },
	[Enemy.BERKANAN]: { name: Enemy.BERKANAN, found: [{ location: MapLocation.LABYRINTHOS, x: 24, y: 28 }] },
	[Enemy.VAJRALANGULA]: { name: Enemy.VAJRALANGULA, found: [{ location: MapLocation.THAVNAIR, x: 11, y: 19 }] },
};
