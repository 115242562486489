//#region Imports
import { jobLists } from "character";
import FormComponents from "components/forms/FormComponents";
import { Job, RelicGroup, RelicPart, RelicTask } from "enums";
import manager from "managers/app";
import { Component, SyntheticEvent } from "react";
import { connect } from "react-redux";
import { AppState, RelicCheckboxProps, RelicID } from "types";
import utils from "utils";
import "./RelicCheckbox.css";
//#endregion Imports

const mapStateToProps = (state:AppState) => {
	return {
		lang: state.userInfo.options.lang,
		gameLang: state.userInfo.options.gameLang,
		userInfo: state.userInfo,
	};
};

class RelicCheckbox extends Component<RelicCheckboxProps> {
	render(){
		const character = this.props.character;
		const relicInfo = this.props.relicInfo;
		let isChecked = false;

		if(this.props.step){
			if(this.props.task){
				isChecked = character.progress.isComplete(relicInfo.type, relicInfo.relic, relicInfo.part, this.props.step, this.props.task, this.props.job);
			}else{
				isChecked = character.progress.isComplete(relicInfo.type, relicInfo.relic, relicInfo.part, this.props.step, this.props.job);
			}
		}else{
			isChecked = character.progress.isComplete(relicInfo.type, relicInfo.relic, relicInfo.part, this.props.job);
		}

		return <FormComponents.Checkbox
			label={{ hide: true }}
			input={{ checked: isChecked, contentAlign: "center", events: { onClick: this.toggleCheckbox } }}
		/>;
	}

	toggleCheckbox = (evt:SyntheticEvent) => {
		const ele = evt.currentTarget;
		const char = this.props.character;
		const relicInfo = this.props.relicInfo;
		const isChecked = !ele.classList.contains("checked");
		let relicId = `${relicInfo.type}-${relicInfo.relic}-${relicInfo.part}`;

		if(this.props.step){
			relicId += `-${this.props.step}`;
			relicId += this.props.task ? `-${this.props.task}` : "";
		}

		if(utils.guards.isRelicId(relicId)){
			char.progress.setComplete(relicId, this.props.job, isChecked);

			if(this.props.task){ this.setExtraTasks(this.props.task, relicId, isChecked); }

			manager.data.saveCharacters([char], false);
		}
	}

	setExtraTasks(thisTask:RelicTask, relicID:RelicID, isChecked:boolean){ // Partial ID in reality, but follows the structure
		/*
			This function is to set extra tasks that are not needed for some jobs for a step
			eg.
				For skysteel
					MIN and BTN have 2 tasks to complete
					FSH has 2 tasks only for Skybuilders
					All Crafters have 1 task

					The below should trigger marking task 2 complete for the cases where the job only has 1 task to do

				For Splendorous
					Similar situation as above except
					FSH Lodestar part is unique in that there are 2 fish to obtain the same item so both tasks should be treated as 1

		*/

		const relicInfo = this.props.relicInfo;
		const char = this.props.character;
		const altTask = thisTask === RelicTask.TASK_1 ? RelicTask.TASK_2 : RelicTask.TASK_1;

		if(relicInfo.relic === RelicGroup.TOOL_SKYSTEEL){ // Skysteel
			if(
				jobLists.type.crafter.includes(this.props.job)
				|| (this.props.job === Job.FSH && relicInfo.part !== RelicPart.TOOL_SKYBUILDERS)
			){
				const thisRelicID = relicID.replace(thisTask, altTask);
				if(utils.guards.isRelicId(thisRelicID)){
					char.progress.setComplete(thisRelicID, this.props.job, isChecked);
				}
			}
		}

		if(relicInfo.relic === RelicGroup.TOOL_SPLENDOROUS){
			if(
				jobLists.type.crafter.includes(this.props.job)
				|| (relicInfo.part === RelicPart.TOOL_LODESTAR && this.props.job === Job.FSH)
			){
				const thisRelicID = relicID.replace(thisTask, altTask);
				if(utils.guards.isRelicId(thisRelicID)){
					char.progress.setComplete(thisRelicID, this.props.job, isChecked);
				}
			}
		}
	}
}
export default connect(mapStateToProps)(RelicCheckbox);
