
import { EnvironmentType } from "enums";
import TagManager from "react-gtm-module";
import { appEnv } from "./env";

export class GAManager {
	init(){
		if(appEnv.type === EnvironmentType.LOCAL){ return; }
		TagManager.initialize({ gtmId: appEnv.ga.trackingCode });
	}

	achievementsChecked(result:boolean){
		if(appEnv.type === EnvironmentType.LOCAL){ return; }
		TagManager.dataLayer({ dataLayer: { event: "achievements_checked", achievementCheckResult: result } });
	}

	accountCreated(){
		if(appEnv.type === EnvironmentType.LOCAL){ return; }
		TagManager.dataLayer({ dataLayer: { event: "account_created" } });
	}

	loggedIn(result:boolean){
		if(appEnv.type === EnvironmentType.LOCAL){ return; }
		TagManager.dataLayer({ dataLayer: { event: "logged_in", loggedInResult: result } });
	}

	characterCreated(result:boolean){
		if(appEnv.type === EnvironmentType.LOCAL){ return; }
		TagManager.dataLayer({ dataLayer: { event: "characters_created", characterCreatedResult: result } });
	}

	characterDeleted(result:boolean){
		if(appEnv.type === EnvironmentType.LOCAL){ return; }
		TagManager.dataLayer({ dataLayer: { event: "characters_deleted", characterDeletedResult: result } });
	}
}

const ga = new GAManager();
export default ga;
