//#region Imports
import BasicIcon from "components/basic-icon/BasicIcon";
import FormComponents from "components/forms/FormComponents";
import { leves } from "data-files/duties";
import { locations } from "data-files/locations";
import { npcs } from "data-files/npc";
import { Icon, Modal } from "enums";
import manager from "managers/app";
import ModalContent from "modals/content/ModalContent";
import ModalFooter from "modals/footer/ModalFooter";
import ModalHeader from "modals/header/ModalHeader";
import { Component } from "react";
import { connect } from "react-redux";
import t from "translations/translator";
import { AppState, LevesModalProps } from "types";
import "./LevesModal.css";
//#endregion Imports

const mapStateToProps = (state:AppState) => {
	return {
		lang: state.userInfo.options.lang,
		gameLang: state.userInfo.options.gameLang,
		leves: state.modal[Modal.LEVES],
	};
};

class LevesModal extends Component<LevesModalProps> {
	render(){
		return (
			<div id={Modal.LEVES} className="modal-wrapper">
				<ModalHeader text="Leves" icon={Icon.LEVE} />
				<ModalContent>
					<div className="leve-wrapper">
						{this.props.leves.map((target) => {
							const leve = leves[target];
							const npc = npcs[leve.npc];
							const obtainedLocation = npc.found;
							const leveLocation = locations[obtainedLocation.location];

							return <div key={leve.name} className="leve-card">
								<h3 className="leve-name">{t.t(leve.name)}</h3>
								<p className="leve-grandcompany"><BasicIcon icon={Icon.GRAND_COMPANY} />{t.t(leve.grandCompany ? leve.grandCompany : "General")}</p>
								<p className="leve-npc"><BasicIcon icon={Icon.NPC} />{t.t(leve.npc)}</p>
								<div className="leve-location-wrapper">
									<div key={obtainedLocation.location} className="leve-location">
										<span><BasicIcon icon={Icon.MAP} tooltip="Map" /> {t.t(leveLocation.map)}</span>
										<span><BasicIcon icon={Icon.REGION} tooltip="Region" /> {leveLocation.region ? t.t(leveLocation.region) : t.t("Unknown")}</span>
										<span><BasicIcon icon={Icon.TELEPORT} tooltip="Teleport" /> {leveLocation.teleport ? t.t(leveLocation.teleport) : t.t("Unknown")}</span>
										<span><BasicIcon icon={Icon.LOCATION} tooltip="Co-ordinates" /> X: {obtainedLocation.x}, Y: {obtainedLocation.y}</span>
									</div>
								</div>
							</div>;
						})}
					</div>;
				</ModalContent>
				<ModalFooter>
					<FormComponents.Button text="Close" onClick={manager.modal.close} translate={true} />
				</ModalFooter>
			</div>
		);
	}
}
export default connect(mapStateToProps)(LevesModal);
