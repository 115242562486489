//#region Imports
import { Component } from "react";
import { FormInputWrapperProps } from "types";
//#endregion Imports

export class FormInputWrapper extends Component<FormInputWrapperProps> {
	render(){
		const classes:string[] = ["input-wrapper"];

		if(this.props.contentAlign === "center"){
			classes.push("center");
		}

		return (
			<div className={classes.join(" ")}>
				{this.props.children}
			</div>
		);
	}
}
