//#region Imports
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import BasicIcon from "components/basic-icon/BasicIcon";
import { Component } from "react";
import { FormCheckboxProps } from "types";
import { FormFieldWrapper } from "./FormFieldWrapper";
import { FormInputWrapper } from "./FormInputWrapper";
import { FormLabel } from "./FormLabel";
//#endregion Imports

export class FormCheckbox extends Component<FormCheckboxProps> {
	render(){
		const label = this.props.label;
		const input = this.props.input;

		const classes:string[] = ["rt-checkbox", "rt-icon-wrapper"];

		let icon = faTimes;
		if(input.checked){
			icon = faCheck;
			classes.push("checked");
		}

		let position:string;
		if(label.hide){
			position = "right";
		}else{
			position = label.position ? label.position : "right";
		}

		return (
			<FormFieldWrapper label={label} width={this.props.fieldWidth}>
				{position === "right" ? null : <FormLabel {...label} /> }
				<FormInputWrapper contentAlign={this.props.input.contentAlign}>
					<span
						className={classes.join(" ")}
						{...input.events}
					>
						<BasicIcon icon={icon} />
						{position === "right" ? <FormLabel {...label} /> : null }
					</span>
				</FormInputWrapper>
			</FormFieldWrapper>
		);
	}
}
