//#region Imports
import { APIEndpoint, APIStatus } from "enums";
import log from "logger";
import manager from "managers/app";
import { store } from "managers/state";
import sysMsg from "managers/sysMsg";
import { GetUserDataRequest, GetUserDataResponse, SaveUserDataRequest, SaveUserDataResponse, SaveUserOptionsRequest, SaveUserOptionsResponse, UserData, UserInfo, UserOptions } from "types"; // eslint-disable-line max-len
import sanitise from "./sanitation";
//#endregion Imports

class RemoteStorageManager {
	private DataLocation = log.DataLocation.REMOTE;
	private DataOperations = log.DataOperation;

	private logUnknownError(e:unknown){
		log.status("Caught Unknown Error");
		if(e instanceof Error){
			log.error(e);
		}else if(typeof e === "string"){
			log.error(new Error(e));
		}else{
			log.error("Unknown E: ", e as any);
		}
	}

	private storageError(resolve:any, resolveValue:any, e:unknown){ // resolve is the "resolve" part of the promise ChaoFengTing
		log.error("An error occured with the API Request");
		this.logUnknownError(e);

		sysMsg.error({
			title: "Error processing request",
			message: "There was an error processing the request",
		});

		return resolve(resolveValue);
	}

	private async shouldSendRequest():Promise<boolean>{
		if(!await manager.auth.isAuthenticated()){
			return false;
		}
		return true;
	}

	getUserInfo():Promise<UserInfo|null>{
		return new Promise(async(resolve) => {
			if(!await this.shouldSendRequest()){ return resolve(null); }

			try{
				const user = store.getState().userInfo;
				const storedOptions = sanitise.convertToStoredOptions(user.options);
				const response = await manager.request.send<GetUserDataRequest, GetUserDataResponse>(APIEndpoint.GET_USER_DATA, {
					username: user.username,
					options: storedOptions,
				});

				if(!response){ return resolve(null); }

				switch(response.status){
					default: log.error(new Error("Unknown API result status")); return resolve(null);
					case APIStatus.APPERROR: return this.storageError(resolve, null, response.message);
					case APIStatus.ERROR: return resolve(null);
					case APIStatus.SUCCESS: break; // Avoid the default case
				}

				const converted_options:UserOptions|null = sanitise.convertFromStoredOptions(response.data.options);
				if(!converted_options){ return resolve(null); }

				const converted_data:UserData|null = sanitise.convertFromStoredData(response.data.data);
				if(!converted_data){ return resolve(null); }

				const user_info:UserInfo = {
					isAuthenticated: user.isAuthenticated,
					username: user.username,
					options: converted_options,
					data: converted_data,
				};

				return resolve(user_info);
			}catch(e){
				return this.storageError(resolve, null, e);
			}
		});
	}

	saveOptions(options:UserOptions):Promise<boolean>{
		return new Promise(async(resolve) => {
			if(!await this.shouldSendRequest()){ return resolve(false); }

			try{
				const storedOptions = sanitise.convertToStoredOptions(options);
				if(!storedOptions){ return resolve(false); }

				const response = await manager.request.send<SaveUserOptionsRequest, SaveUserOptionsResponse>(APIEndpoint.SAVE_USER_OPTIONS, { options: storedOptions });
				if(!response){ return resolve(false); }
				switch(response.status){
					default: log.error(new Error("Unknown API result status")); return resolve(false);
					case APIStatus.APPERROR: return this.storageError(resolve, false, response.message);
					case APIStatus.ERROR: return resolve(false);
					case APIStatus.SUCCESS: return resolve(true);
				}
			}catch(e){
				return this.storageError(resolve, null, e);
			}
		});
	}

	saveUserData(userData:UserData):Promise<boolean>{
		return new Promise(async(resolve) => {
			if(!await this.shouldSendRequest()){ return resolve(false); }

			try{
				const storedUserData = sanitise.convertToStoredData(userData);
				if(!storedUserData){ return resolve(false); }

				const response = await manager.request.send<SaveUserDataRequest, SaveUserDataResponse>(APIEndpoint.SAVE_USER_DATA, { data: storedUserData });
				if(!response){ return resolve(false); }
				switch(response.status){
					default: log.error(new Error("Unknown API result status")); return resolve(false);
					case APIStatus.APPERROR: return this.storageError(resolve, false, response.message);
					case APIStatus.ERROR: return resolve(false);
					case APIStatus.SUCCESS: return resolve(true);
				}
			}catch(e){
				return this.storageError(resolve, null, e);
			}
		});
	}
}
const remote = new RemoteStorageManager();
export default remote;
