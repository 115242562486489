import { GameLanguage, Language } from "enums";

// Leaving this here to keep it self contained
enum Colour {
	DEFAULT = "padding: 4px 0; color: white;",
	WHITE = "padding: 4px 0; color: white;",
	RED = "padding: 4px 0; color: red;",
	YELLOW = "padding: 4px 0; color: yellow;",
	BLUE = "padding: 4px 0; color: skyblue;",
	PINK = "padding: 4px 0; color: lightpink;",
	MAGENTA = "padding: 4px 0; color: magenta;",
	GREEN = "padding: 4px 0; color: lightgreen",
}

enum Level {NONE, ALL, DEBUG, ERROR, WARN, INFO, STATUS, INIT, DATA, TRANSLATION}

enum InitStatus {
	START = "Start",
	COMPLETE = "Complete",
}

enum DataLocation {
	LOCAL = "Local",
	REMOTE = "Remote",
}

enum DataOperation {
	SAVE = "Save",
	FETCH = "Fetch",
	DELETE = "Delete",
	CREATE = "Create",
}

class Logger {
	InitStatus = InitStatus;
	DataLocation = DataLocation;
	DataOperation = DataOperation;

	private getConfig(args:any):string{
		let messageConfig = "%c%s";
		args.forEach((arg:any) => {
			const type = typeof arg;
			switch (type){
				case "bigint": case "number": case "boolean":
					messageConfig += "%d"; break;
				case "string":
					messageConfig += "%s"; break;
				case "object": case "undefined": default:
					messageConfig += "%o"; break;
			}
		});

		return messageConfig;
	}

	private getColour(type:Level){
		switch(type){
			default:
				console.log(`${Colour.RED}Unknown log type recieved${Colour.DEFAULT}`); // eslint-disable-line no-console
				return Colour.WHITE;
			case Level.DEBUG: return Colour.WHITE;
			case Level.ERROR: return Colour.RED;
			case Level.WARN: return Colour.YELLOW;
			case Level.INFO: return Colour.BLUE;
			case Level.STATUS: return Colour.MAGENTA;
			case Level.INIT: return Colour.PINK;
			case Level.DATA: return Colour.GREEN;
			case Level.TRANSLATION: return Colour.PINK;
		}
	}

	private doLog(type:Level, ...args:any){
		const config = this.getConfig(args);
		console.log(config, this.getColour(type), `[${Level[type]}] `, ...args); // eslint-disable-line no-console
	}

	/* Standard log messages */
	status(...args:any){ this.doLog(Level.STATUS, ...args); }
	info(...args:any){ this.doLog(Level.INFO, ...args); }
	warn(...args:any){ this.doLog(Level.WARN, ...args); }
	error(...args:any){ this.doLog(Level.ERROR, ...args); }
	debug(...args:any){ this.doLog(Level.DEBUG, ...args); }

	/* Special Types */
	init(startOrComplete:InitStatus, message:string){ this.doLog(Level.INIT, `${startOrComplete}: ${message}`); }
	data(loc:DataLocation, operation:DataOperation, message:string){ this.doLog(Level.DATA, `${loc} - ${operation} - ${message}`); }
	missingTranslation(language:Language|GameLanguage, text:string){ this.doLog(Level.TRANSLATION, `${language}: Missing Translation for "${text}"`); }
	missingPlaceholder(key:string, value?:string){ this.doLog(Level.TRANSLATION, value ? `Placeholder for ${key} is empty` : `Missing Placholder for ${key}`); }
}

const log = new Logger();
export default log;
