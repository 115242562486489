/* eslint-disable max-len */
//#region Imports
import { jobLists } from "character";
import FormComponents from "components/forms/FormComponents";
import Link from "components/link/Link";
import NoCharacter from "components/no-character/NoCharacter";
import { items } from "data-files/items";
import { quests } from "data-files/quests";
import { EorzeaDataType, InputType, Item, LinkType, NPC, Quest, RelicGroup, RelicPart, RelicStep, RelicTask, RelicType, View } from "enums";
import manager from "managers/app";
import { Component } from "react";
import { connect } from "react-redux";
import t from "translations/translator";
import { AppState, Requirement, TaskInfo, ViewRelicInfo, ViewWeaponResistanceRecollectionProps } from "types";
import ContentHeader from "views/page-parts/content/content-header/ContentHeader";
import DetailsSection from "views/page-parts/content/details-section/DetailsSection";
import Obtained from "views/page-parts/content/obtained/Obtained";
import OverviewTable from "views/page-parts/content/overview-table/OverviewTable";
import "./WeaponResistanceRecollection.css";
//#endregion Imports

const mapStateToProps = (state:AppState) => {
	return {
		lang: state.userInfo.options.lang,
		gameLang: state.userInfo.options.gameLang,
		userInfo: state.userInfo,
		hideCompletedSteps: state.userInfo.options.hideCompletedSteps,
		hideCompletedTasks: state.userInfo.options.hideCompletedTasks,
		showJob: state.userInfo.options.job,
	};
};

class WeaponResistanceRecollection extends Component<ViewWeaponResistanceRecollectionProps> {
	private requirements:Requirement[] = [];
	private importantNPCs:NPC[] = [];
	private relicInfo:ViewRelicInfo = {
		jobs: jobLists.relic[RelicGroup.WEAPON_RESISTANCE],
		type: RelicType.WEAPON,
		relic: RelicGroup.WEAPON_RESISTANCE,
		part: RelicPart.WEAPON_RECOLLECTION,
		steps: [RelicStep.RESIST],
		stepPlaceholders: { [RelicStep.RESIST]: { QUEST: Quest.WILL_TO_RESIST } },
		next: {
			part: RelicPart.WEAPON_LAWS_ORDER,
			view: View.RELIC_WEAPONS_RESISTANCE_LAWS_ORDER,
		},
		previous: {
			part: RelicPart.WEAPON_AUGMENTED_RESISTANCE,
			view: View.RELIC_WEAPONS_RESISTANCE_AUGMENTED_RESISTANCE,
		},
	};

	constructor(props:ViewWeaponResistanceRecollectionProps){
		super(props);
		this.requirements = this.getRequirements();
		this.importantNPCs = this.getImportantNPCs();
	}

	render(){
		const character = manager.data.getActiveCharacter();
		if(character === null){ return <NoCharacter />; }

		const completeSteps = manager.content.getCompleteSteps(character.progress, this.relicInfo);

		return (
			<div id="weapon-resistance-recollection" className="relic">
				<ContentHeader requirements={this.requirements} importantNPCs={this.importantNPCs} relicInfo={this.relicInfo}>
					<p>{t.t("The %PART% upgrade follows on from %PREVIOUSPART%", { PART: this.relicInfo.part, PREVIOUSPART: this.relicInfo.previous ? this.relicInfo.previous.part : "Unknown"})}</p>
					<p>{t.t("Complete")}<Link type={LinkType.LODESTONE} lodestoneType={EorzeaDataType.BLUEQUEST} text={Quest.WILL_TO_RESIST} lodestoneID={quests[Quest.WILL_TO_RESIST].lodestoneID} /></p>
				</ContentHeader>
				<div id="relic-checklist">
					<OverviewTable character={character} completeSteps={completeSteps} relicInfo={this.relicInfo} />
				</div>
				<div id="relic-details">
					<h1>{t.t("Details")}</h1>
					{this.props.hideCompletedSteps && completeSteps.allComplete ? t.t("All Steps Complete") : null }
					{this.relicInfo.steps.map((step) => {
						let row = "odd-row";

						const taskInfo:TaskInfo[] = [{ task: RelicTask.TASK_1, target: manager.relics.getTaskItem(step, RelicTask.TASK_1) }];

						return <DetailsSection title={step} placeholders={this.relicInfo.stepPlaceholders[step]} id={step} stepComplete={completeSteps[step]} key={step}>
							<div className="table-wrapper">
								<table className="table">
									<thead><tr><th>{t.t("Obtained")}</th><th>{t.t("Item")}</th><th>{t.t("Quantity / Job")}</th><th>{t.t("Total")}</th><th>{t.t("Used")}</th><th>{t.t("Remaining")}</th><th>{t.t("Inventory")}</th><th>{t.t("To Obtain")}</th></tr></thead>
									<tbody>
										{taskInfo.map((thisTask) => {
											const thisTarget = thisTask.target as Item;
											const inventory = manager.data.getInventoryStatus({
												item: thisTarget,
												relic: [this.relicInfo.type, this.relicInfo.relic, this.relicInfo.part, step, thisTask.task],
											});

											if(this.props.hideCompletedTasks && inventory.toObtain === 0){ return null; }

											const tableRow = <tr key={`${step}-${thisTask.task}`} className={row}>
												<td><Obtained obtained={items[thisTarget].obtained} qtyNeeded={inventory.total} /></td>
												<td>{t.t(thisTarget)}</td>
												<td>{inventory.qtyPerJob.toLocaleString()}</td>
												<td>{inventory.total.toLocaleString()}</td>
												<td>{inventory.used.toLocaleString()}</td>
												<td>{inventory.remaining.toLocaleString()}</td>
												<td><FormComponents.TextInput label={{ hide: true }} input={{ type: InputType.NUMBER, value: inventory.inventory, dataAttributes: { item: thisTarget }, events: { onChange: manager.content.inventoryUpdated }}} /></td>
												<td>{inventory.toObtain.toLocaleString()}</td>
											</tr>;
											row = row === "odd-row" ? "even-row" : "odd-row";
											return tableRow;
										})}
										{
											character.progress.isComplete(this.relicInfo.type, this.relicInfo.relic, this.relicInfo.part, step)
												? <tr className="all-complete"><td colSpan={8}><p>{t.t("All Complete")}</p></td></tr>
												: null
										}
									</tbody>
								</table>
							</div>
						</DetailsSection>;
					})}
				</div>
			</div>
		);
	}

	componentDidMount(){
		manager.view.changeComplete();
	}

	private getRequirements():Requirement[]{
		if(!this.relicInfo || this.relicInfo.previous === null){ return []; }
		return [
			{ type: "previous part", part: this.relicInfo.previous.part, partView: this.relicInfo.previous.view },
		];
	}

	private getImportantNPCs():NPC[]{
		return [NPC.ZLATAN];
	}
}
export default connect(mapStateToProps)(WeaponResistanceRecollection);
