//#region Imports
import FormComponents from "components/forms/FormComponents";
import Link from "components/link/Link";
import { shops } from "data-files/shops";
import { EorzeaDataType, Icon, LinkType, Modal } from "enums";
import manager from "managers/app";
import ModalContent from "modals/content/ModalContent";
import ModalFooter from "modals/footer/ModalFooter";
import ModalHeader from "modals/header/ModalHeader";
import { Component } from "react";
import { connect } from "react-redux";
import t from "translations/translator";
import { AppState, ShopsModalProps } from "types";
import "./ShopsModal.css";
//#endregion Imports

const mapStateToProps = (state:AppState) => {
	return {
		lang: state.userInfo.options.lang,
		gameLang: state.userInfo.options.gameLang,
		shops: state.modal[Modal.SHOPS],
	};
};

class ShopsModal extends Component<ShopsModalProps> {
	render(){
		return (
			<div id={Modal.SHOPS} className="modal-wrapper">
				<ModalHeader text="Shops" icon={Icon.SHOP} />
				<ModalContent>
					<div className="shop-wrapper">
						{this.props.shops.map((obtainedShop) => {
							const shop = shops[obtainedShop.shop];

							return <div key={shop.name} className="shop-card">
								<h3 className="shop-name"><Link type={LinkType.LODESTONE} lodestoneType={EorzeaDataType.SHOP} lodestoneID={shop.lodestoneID} text={shop.name} /></h3>
								<div className="shop-details-wrapper">
									<div className="shop-details">
										{obtainedShop.cost.map((cost, index) => {
											return <p key={`cost-${index}`}>{t.t("Price")}: {cost.price.toLocaleString()} {t.t(cost.currency)}</p>;
										})}
									</div>
									{/*
									// TODO: Add in NPC location (location like the other modals)
									{shop.found.map((loc) => {
										const enemyLocation = locations[loc.location];

										return <div key={loc.location} className="enemy-location">
											<span><BasicIcon icon={Icon.MAP} tooltip="Map" /> {t.t(enemyLocation.map)}</span>
											<span><BasicIcon icon={Icon.REGION} tooltip="Region" /> {enemyLocation.region ? t.t(enemyLocation.region) : t.t("Unknown")}</span>
											<span><BasicIcon icon={Icon.TELEPORT} tooltip="Teleport" /> {enemyLocation.teleport ? t.t(enemyLocation.teleport) : t.t("Unknown")}</span>
											<span><BasicIcon icon={Icon.LOCATION} tooltip="Co-ordinates" /> X: {loc.x}, Y: {loc.y}</span>
										</div>;
									})} */}
								</div>
							</div>;
						})}
					</div>
				</ModalContent>
				<ModalFooter>
					<FormComponents.Button text="Close" onClick={manager.modal.close} translate={true} />
				</ModalFooter>
			</div>
		);
	}
}
export default connect(mapStateToProps)(ShopsModal);
