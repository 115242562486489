//#region Imports
import { CharacterProgress, relicsStructure } from "character";
import { GameLanguage, Item, Job, Language, OldLocalKeys, RelicType, Theme } from "enums";
import { appEnv } from "managers/env";
import { store } from "managers/state";
import { Character, Inventory, StoredCharacter, StoredData, StoredOptions, UserData, UserInfo, UserOptions } from "types";
import utils from "utils";
import local from "./local";
//#endregion Imports

class Sanitise {
	clean(userInfo:UserInfo):UserInfo{
		const cleanedOptions = this.cleanOptions(userInfo.options);
		const cleanedData:UserData = this.cleanData(userInfo.data);

		local.cleanKeys([OldLocalKeys.VERSION]);

		return {
			...userInfo,
			options: cleanedOptions,
			data: cleanedData,
		};
	}

	private cleanOptions(options:UserOptions):UserOptions{
		const cleanedOptions:UserOptions = {
			lang: utils.guards.isEnumValue(Language, options.lang) ? options.lang : Language.EN,
			gameLang: utils.guards.isEnumValue(GameLanguage, options.gameLang) ? options.gameLang : GameLanguage.EN,
			theme: utils.guards.isEnumValue(Theme, options.theme) ? options.theme : Theme.DARK,
			job: utils.guards.isEnumValue(Job, options.job) ? options.job : null,
			hideCompletedSteps: options.hideCompletedSteps,
			hideCompletedTasks: options.hideCompletedTasks,
			showMaximumItemQty: options.showMaximumItemQty,
			relics: options.relics,
			items: options.items,
			progress: {
				ignorePhyseosPart: options.progress.ignorePhyseosPart,
				compactProgressView: options.progress.compactProgressView,
			},
		};

		return cleanedOptions;
	}

	private cleanData(userData:UserData):UserData{
		const cleanedData:UserData = { version: appEnv.version, characters: [] };
		userData.characters.forEach((character) => {
			const cleanedInventory:{ [key: string]: any } = {};

			Object.keys(character.inventory).forEach((inventoryItem) => {
				if(utils.guards.isEnumValue(Item, inventoryItem)){ cleanedInventory[inventoryItem] = character.inventory[inventoryItem]; }
			});

			const cleanedCharacter:Character = {
				...character,
				progress: new CharacterProgress(this.deepClean(character.progress, relicsStructure)),
				inventory: cleanedInventory,
			};

			if(typeof cleanedCharacter.progress[RelicType.ARMOUR] === "undefined"){ cleanedCharacter.progress[RelicType.ARMOUR] = {}; }

			cleanedData.characters.push(cleanedCharacter);
		});
		return cleanedData;
	}

	// Return final object with keys from masterObj and values from targetObj
	private deepClean(targetObj:{ [key: string]: any }, masterObj:{ [key: string]: any }):{ [key: string]: any }{
		const finalObj:{ [key: string]: any } = {};
		const masterKeys = Object.keys(masterObj);

		if(masterKeys.length === 0 && targetObj._completed){
			finalObj._completed = targetObj._completed;
		}

		masterKeys.forEach((masterKey) => {
			if(typeof masterObj[masterKey] === "object"){
				if(typeof targetObj[masterKey] === "undefined"){ targetObj[masterKey] = masterObj[masterKey]; }
				finalObj[masterKey] = this.deepClean(targetObj[masterKey], masterObj[masterKey]);
			}else{
				finalObj[masterKey] = targetObj[masterKey];
			}
		});

		return finalObj;
	}

	private getStoredProgressList(progress:CharacterProgress):string{
		return JSON.stringify(progress);
	}

	private getStoredInventory(inventory:Inventory):string{
		return JSON.stringify(inventory);
	}

	convertFromStoredOptions(stored_options:StoredOptions):UserOptions{ // eslint-disable-line complexity
		const defaultOptions = store.getState().userInfo.options;

		/* General */
		const lang = stored_options.lang ? stored_options.lang : defaultOptions.lang;
		const gameLang = stored_options.gameLang ? stored_options.gameLang : defaultOptions.gameLang;
		const theme = stored_options.theme ? stored_options.theme : defaultOptions.theme;
		const job = stored_options.job ? stored_options.job : defaultOptions.job;
		const hideCompletedSteps = stored_options.hideCompletedSteps ? stored_options.hideCompletedSteps : Number(defaultOptions.hideCompletedSteps);
		const hideCompletedTasks = stored_options.hideCompletedTasks ? stored_options.hideCompletedTasks : Number(defaultOptions.hideCompletedTasks);
		const showMaximumItemQty = stored_options.showMaximumItemQty ? stored_options.showMaximumItemQty : Number(defaultOptions.showMaximumItemQty);

		/* Relics */
		const animusStepJobs = stored_options.relics?.animusStepJobs ? stored_options.relics.animusStepJobs : defaultOptions.relics.animusStepJobs;
		const zodiacBravesStepJobs = stored_options.relics?.zodiacBravesStepJobs ? stored_options.relics.zodiacBravesStepJobs : defaultOptions.relics.zodiacBravesStepJobs;
		const zodiacZetaStepJob = stored_options.relics?.zodiacZetaStepJob ? stored_options.relics.zodiacZetaStepJob : defaultOptions.relics.zodiacZetaStepJob;
		const animaCompleteStepJob = stored_options.relics?.animaCompleteStepJob ? stored_options.relics.animaCompleteStepJob : defaultOptions.relics.animaCompleteStepJob;
		const animaLuxStepJob = stored_options.relics?.animaLuxStepJob ? stored_options.relics.animaLuxStepJob : defaultOptions.relics.animaLuxStepJob;

		/* Items */
		const hideNoRemaining = stored_options.items?.hideNoRemaining ? stored_options.items.hideNoRemaining : Number(defaultOptions.items.hideNoRemaining);
		const showOnlyBuyable = stored_options.items?.showOnlyBuyable ? stored_options.items.showOnlyBuyable : Number(defaultOptions.items.showOnlyBuyable);
		const showMaximumRequired = stored_options.items?.showMaximumRequired ? stored_options.items.showMaximumRequired : Number(defaultOptions.items.showMaximumRequired);
		const selectedView = stored_options.items?.selectedView ? stored_options.items.selectedView : defaultOptions.items.selectedView;
		const relicsFilter = stored_options.items?.relicsFilter ? stored_options.items.relicsFilter : defaultOptions.items.relicsFilter;
		const relicPartsFilter = stored_options.items?.relicPartsFilter ? stored_options.items.relicPartsFilter : defaultOptions.items.relicPartsFilter;
		const currencyFilter = stored_options.items?.currencyFilter ? stored_options.items.currencyFilter : defaultOptions.items.currencyFilter;

		/* Progresss */
		const ignorePhyseosPart = stored_options.progress?.ignorePhyseosPart ? stored_options.progress?.ignorePhyseosPart : Number(defaultOptions.progress.ignorePhyseosPart);
		const compactProgressView = stored_options.progress?.compactProgressView ? stored_options.progress?.compactProgressView : Number(defaultOptions.progress.compactProgressView);

		return {
			lang: lang,
			gameLang: gameLang,
			theme: theme,
			job: job,
			hideCompletedSteps: hideCompletedSteps === 1,
			hideCompletedTasks: hideCompletedTasks === 1,
			showMaximumItemQty: showMaximumItemQty === 1,
			relics: {
				animusStepJobs: animusStepJobs,
				zodiacBravesStepJobs: zodiacBravesStepJobs,
				zodiacZetaStepJob: zodiacZetaStepJob,
				animaCompleteStepJob: animaCompleteStepJob,
				animaLuxStepJob: animaLuxStepJob,
			},
			items: {
				hideNoRemaining: hideNoRemaining === 1,
				showOnlyBuyable: showOnlyBuyable === 1,
				showMaximumRequired: showMaximumRequired === 1,
				selectedView: selectedView,
				relicsFilter: relicsFilter,
				relicPartsFilter: relicPartsFilter,
				currencyFilter: currencyFilter,
			},
			progress: {
				ignorePhyseosPart: ignorePhyseosPart === 1,
				compactProgressView: compactProgressView === 1,
			},
		};
	}

	convertToStoredOptions(user_options:UserOptions):StoredOptions{
		return {
			lang: user_options.lang,
			gameLang: user_options.gameLang,
			theme: user_options.theme,
			job: user_options.job,
			hideCompletedSteps: Number(user_options.hideCompletedSteps),
			hideCompletedTasks: Number(user_options.hideCompletedTasks),
			showMaximumItemQty: Number(user_options.showMaximumItemQty),
			relics: user_options.relics,
			items: {
				hideNoRemaining: Number(user_options.items.hideNoRemaining),
				showOnlyBuyable: Number(user_options.items.showOnlyBuyable),
				showMaximumRequired: Number(user_options.items.showMaximumRequired),
				selectedView: user_options.items.selectedView,
				relicsFilter: user_options.items.relicsFilter,
				relicPartsFilter: user_options.items.relicPartsFilter,
				currencyFilter: user_options.items.currencyFilter,
			},
			progress: {
				ignorePhyseosPart: Number(user_options.progress.ignorePhyseosPart),
				compactProgressView: Number(user_options.progress.compactProgressView),
			},
		};
	}

	convertFromStoredData(stored_data:StoredData):UserData{
		const charactersToUse:Character[] = [];


		stored_data.characters.forEach((char) => {
			let inventory = char.inventory as Inventory;

			if(typeof inventory === "string"){
				inventory = JSON.parse(char.inventory);
			}

			let achievementsCheckedValue = (char as any).achievements_checked;
			if(!achievementsCheckedValue){ achievementsCheckedValue = char.achievementsChecked; }

			let lastUpdateValue = (char as any).last_update;
			if(!lastUpdateValue){ lastUpdateValue = char.lastUpdate; }

			const achievementsChecked = achievementsCheckedValue ? new Date(achievementsCheckedValue) : null;
			const lastUpdate = lastUpdateValue ? new Date(lastUpdateValue) : null;

			charactersToUse.push({
				// Bit hacky but the underscores have been removed - This can be removed in a later version
				seId: (char as any).se_id ? (char as any).se_id : char.seId,
				name: char.name,
				displayName: (char as any).display_name ? (char as any).display_name : char.displayName,
				avatar: char.avatar,
				active: char.active,
				progress: new CharacterProgress(char.progress),
				inventory: inventory,
				achievementsChecked: achievementsChecked,
				lastUpdate: lastUpdate,
			});
		});

		const toUse:UserData = { version: stored_data.version, characters: charactersToUse };
		return toUse;
	}

	convertToStoredData(user_data:UserData):StoredData|null{
		const toSave:StoredData = { version: user_data.version, characters: this.convertCharactersForStorage(user_data.characters) };
		return toSave;
	}

	convertCharacterForStorage(character:Character):StoredCharacter{
		return {
			...character,
			progress: this.getStoredProgressList(character.progress),
			inventory: this.getStoredInventory(character.inventory),
		};
	}

	convertCharactersForStorage(characters:Character[]):StoredCharacter[]{
		const charactersToStore:StoredCharacter[] = [];
		characters.forEach((char) => {
			charactersToStore.push(this.convertCharacterForStorage(char));
		});
		return charactersToStore;
	}
}

const sanitise = new Sanitise();
export default sanitise;
