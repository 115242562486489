//#region Imprts
import { EnvironmentType, RelicType, View } from "enums";
import { appEnv } from "managers/env";
import ChangeLog from "views/changelog/ChangeLog";
import Characters from "views/characters/Characters";
import Gallery from "views/gallery/Gallery";
import Home from "views/home/Home";
import Items from "views/items/Items";
import Progress from "views/progress/Progress";
import Tips from "views/tips/Tips";
import Translations from "views/translations/Translations";
import log from "../../logger";
import manager from "../app";
import { actions, store } from "../state";
import { lookupRelicContent } from "./component-lookup";
//#endregion Imports

export class ViewManager {
	private lastView:View | null = null;
	private switchingToView:View | null = null;

	private switchView = () => { // This is an event
		if(this.switchingToView === null){ return; }

		const overlay = document.getElementById("loading-overlay");
		if(overlay){ overlay.removeEventListener("animationend", this.switchView); }

		store.dispatch(actions.setView(this.switchingToView));
	}

	getViewUrl():string{
		const this_view = store.getState().view;
		const url_parts = this_view.split("-");
		const url = `/${url_parts.join("/").toLowerCase()}`;
		if(url === "/home"){ return "/"; }
		return url;
	}

	setViewFromUrl(isPageLoad = false){
		const path = window.location.pathname.slice(1);
		let viewToSet = path.replaceAll("%20", "_")
			.replaceAll("/", "-");

		if(viewToSet === "" || viewToSet === "/"){ viewToSet = "home"; }

		if(appEnv.type !== EnvironmentType.LOCAL && viewToSet === "dev_translations"){ viewToSet = "home"; }

		this.change(viewToSet as View, isPageLoad);
	}

	change(view:View|null, isPageLoad:boolean = false){
		if(view === null){ return; }

		this.lastView = store.getState().view;
		this.switchingToView = view;

		manager.request.abortAll();

		const overlay = document.getElementById("loading-overlay");
		if(overlay){
			if(overlay.classList.contains("show")){
				this.switchView();
				return;
			}

			overlay.addEventListener("animationend", this.switchView);
			overlay.classList.remove("hide");
			overlay.classList.add("show");
		}else{
			if(!isPageLoad){ log.warn("Loading overlay not found - switching views anyway"); }
			store.dispatch(actions.setView(view));
		}
	}

	changeComplete(){
		const overlay = document.getElementById("loading-overlay");
		if(overlay){
			overlay.classList.remove("show");
			overlay.classList.add("hide");

			window.history.pushState({}, "", manager.view.getViewUrl());
		}else{
			log.warn("Loading overlay not found - Unable to hide");
		}
	}

	getViewComponent():JSX.Element|null{
		const currentView = store.getState().view;
		const viewArr = currentView.split("-");
		switch(viewArr[0]){
			default: return null;
			case View.HOME: return <Home />;
			case View.CHARACTERS: return <Characters />;
			case RelicType.WEAPON:
			case RelicType.TOOL:
			case RelicType.ARMOUR: return lookupRelicContent(currentView);
			case View.ITEMS: return <Items />;
			case View.GALLERY: return <Gallery />;
			case View.PROGRESS: return <Progress />;
			case View.TIPS: return <Tips />;
			case View.CHANGE_LOG: return <ChangeLog />;
			case View.TRANSLATIONS: return <Translations />;
		}
	}

	toTop = () => { // Event
		const content = document.getElementById("content-wrapper");
		if(!content){ return; }
		content.scrollTo({ top: 0, behavior: "smooth" });
	}

	goToCharacterPage = () => { // Event - Call from button press
		this.change(View.CHARACTERS);
	}

	goToTranslationsPage = () => { // Event - Call from button press
		this.change(View.TRANSLATIONS);
	}
}

const view = new ViewManager();
export default view;
