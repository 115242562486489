//#region Imports
import { MapLocation, Teleport } from "enums";
import { LocationInfo } from "types";
//#endregion Imports

export const locations:{ [key in MapLocation]: LocationInfo } = {
	[MapLocation.UNKNOWN]: { map: MapLocation.UNKNOWN, region: MapLocation.UNKNOWN, teleport: Teleport.UNKNOWN },
	[MapLocation.COERTHAS_CENTRAL_HIGHLANDS]: { map: MapLocation.COERTHAS_CENTRAL_HIGHLANDS },
	[MapLocation.OUTER_LA_NOSCEA]: { map: MapLocation.OUTER_LA_NOSCEA },
	[MapLocation.UPPER_LA_NOSCEA]: { map: MapLocation.UPPER_LA_NOSCEA },
	[MapLocation.MIDDLE_LA_NOSCEA]: { map: MapLocation.MIDDLE_LA_NOSCEA },
	[MapLocation.WESTERN_LA_NOSCEA]: { map: MapLocation.WESTERN_LA_NOSCEA },
	[MapLocation.LOWER_LA_NOSCEA]: { map: MapLocation.LOWER_LA_NOSCEA },
	[MapLocation.EASTERN_THANALAN]: { map: MapLocation.EASTERN_THANALAN },
	[MapLocation.CENTRAL_THANALAN]: { map: MapLocation.CENTRAL_THANALAN },
	[MapLocation.WESTERN_THANALAN]: { map: MapLocation.WESTERN_THANALAN },
	[MapLocation.SOUTHERN_THANALAN]: { map: MapLocation.SOUTHERN_THANALAN },
	[MapLocation.NORTH_SHROUD]: { map: MapLocation.NORTH_SHROUD },
	[MapLocation.EAST_SHROUD]: { map: MapLocation.EAST_SHROUD },
	[MapLocation.CENTRAL_SHROUD]: { map: MapLocation.CENTRAL_SHROUD },
	[MapLocation.EASTERN_LA_NOSCEA]: { map: MapLocation.EASTERN_LA_NOSCEA },
	[MapLocation.SOUTH_SHROUD]: { map: MapLocation.SOUTH_SHROUD },
	[MapLocation.NORTHERN_THANALAN]: { map: MapLocation.NORTHERN_THANALAN },
	[MapLocation.NEW_GRIDANIA]: { map: MapLocation.NEW_GRIDANIA },
	[MapLocation.LIMSA_LOWER_DECKS]: { map: MapLocation.LIMSA_LOWER_DECKS },
	[MapLocation.ULDAH_STEPS_OF_NALD]: { map: MapLocation.ULDAH_STEPS_OF_NALD },
	[MapLocation.SEA_OF_CLOUDS]: { map: MapLocation.SEA_OF_CLOUDS },
	[MapLocation.AZYS_LLA]: { map: MapLocation.AZYS_LLA },
	[MapLocation.CHURNING_MISTS]: { map: MapLocation.CHURNING_MISTS },
	[MapLocation.COERTHAS_WESTERN_HIGHLANDS]: { map: MapLocation.COERTHAS_WESTERN_HIGHLANDS },
	[MapLocation.DRAVANIAN_FORELANDS]: { map: MapLocation.DRAVANIAN_FORELANDS },
	[MapLocation.DRAVANIAN_HINTERLANDS]: { map: MapLocation.DRAVANIAN_HINTERLANDS },
	[MapLocation.FOUNDATION]: { map: MapLocation.FOUNDATION },
	[MapLocation.LIMSA_UPPER_DECKS]: { map: MapLocation.LIMSA_UPPER_DECKS },
	[MapLocation.WOLVES_DEN]: { map: MapLocation.WOLVES_DEN },
	[MapLocation.OLD_GRIDANIA]: { map: MapLocation.OLD_GRIDANIA },
	[MapLocation.ULDAH_STEPS_OF_THAL]: { map: MapLocation.ULDAH_STEPS_OF_THAL },
	[MapLocation.GOLD_SAUCER]: { map: MapLocation.GOLD_SAUCER },
	[MapLocation.IDYLLSHIRE]: { map: MapLocation.IDYLLSHIRE },
	[MapLocation.RHALGRS_REACH]: { map: MapLocation.RHALGRS_REACH },
	[MapLocation.FRINGES]: { map: MapLocation.FRINGES },
	[MapLocation.PEAKS]: { map: MapLocation.PEAKS },
	[MapLocation.LOCHS]: { map: MapLocation.LOCHS },
	[MapLocation.KUGANE]: { map: MapLocation.KUGANE },
	[MapLocation.RUBY_SEA]: { map: MapLocation.RUBY_SEA },
	[MapLocation.YANXIA]: { map: MapLocation.YANXIA },
	[MapLocation.AZIM_STEPPE]: { map: MapLocation.AZIM_STEPPE },
	[MapLocation.DOMAN_ENCLAVE]: { map: MapLocation.DOMAN_ENCLAVE },
	[MapLocation.CRYSTARIUM]: { map: MapLocation.CRYSTARIUM },
	[MapLocation.EULMORE]: { map: MapLocation.EULMORE },
	[MapLocation.LAKELAND]: { map: MapLocation.LAKELAND },
	[MapLocation.KHOLUSIA]: { map: MapLocation.KHOLUSIA },
	[MapLocation.AMH_ARAENG]: { map: MapLocation.AMH_ARAENG },
	[MapLocation.IL_MHEG]: { map: MapLocation.IL_MHEG },
	[MapLocation.RAKTIKA_GREATWOOD]: { map: MapLocation.RAKTIKA_GREATWOOD },
	[MapLocation.TEMPEST]: { map: MapLocation.TEMPEST },
	[MapLocation.EUREKA_ANEMOS]: { map: MapLocation.EUREKA_ANEMOS },
	[MapLocation.EUREKA_PAGOS]: { map: MapLocation.EUREKA_PAGOS },
	[MapLocation.EUREKA_PYROS]: { map: MapLocation.EUREKA_PYROS },
	[MapLocation.EUREKA_HYDATOS]: { map: MapLocation.EUREKA_HYDATOS },
	[MapLocation.ZAHARAK]: { map: MapLocation.SOUTHERN_THANALAN, region: MapLocation.ZAHARAK, teleport: Teleport.FORGOTTEN_SPRINGS },
	[MapLocation.UGHAMARO_MINES]: { map: MapLocation.OUTER_LA_NOSCEA, region: MapLocation.UGHAMARO_MINES, teleport: Teleport.OVERLOOK },
	[MapLocation.NATALAN]: { map: MapLocation.COERTHAS_CENTRAL_HIGHLANDS, region: MapLocation.NATALAN, teleport: Teleport.DRAGONHEAD },
	[MapLocation.SAPSA_SPAWNING_GROUNDS]: { map: MapLocation.WESTERN_LA_NOSCEA, region: MapLocation.SAPSA_SPAWNING_GROUNDS, teleport: Teleport.ALEPORT },
	[MapLocation.SYLPHLANDS]: { map: MapLocation.EAST_SHROUD, region: MapLocation.SYLPHLANDS, teleport: Teleport.HAWTHORNE_HUT },
	[MapLocation.MOR_DHONA]: { map: MapLocation.MOR_DHONA, region: MapLocation.MOR_DHONA, teleport: Teleport.MOR_DHONA },
	[MapLocation.FOGFENS]: { map: MapLocation.MOR_DHONA, region: MapLocation.FOGFENS, teleport: Teleport.MOR_DHONA },
	[MapLocation.CASTRUM_CENTRI]: { map: MapLocation.MOR_DHONA, region: MapLocation.CASTRUM_CENTRI, teleport: Teleport.MOR_DHONA },
	[MapLocation.SAINT_COINACHS_FIND]: { map: MapLocation.MOR_DHONA, region: MapLocation.SAINT_COINACHS_FIND, teleport: Teleport.MOR_DHONA },
	[MapLocation.NORTH_SILVERTEAR]: { map: MapLocation.MOR_DHONA, region: MapLocation.NORTH_SILVERTEAR, teleport: Teleport.MOR_DHONA },
	[MapLocation.BOULDER_DOWNS]: { map: MapLocation.COERTHAS_CENTRAL_HIGHLANDS, region: MapLocation.BOULDER_DOWNS, teleport: Teleport.DRAGONHEAD },
	[MapLocation.WHITEBRIM]: { map: MapLocation.COERTHAS_CENTRAL_HIGHLANDS, region: MapLocation.WHITEBRIM, teleport: Teleport.DRAGONHEAD },
	[MapLocation.PROVIDENCE_POINT]: { map: MapLocation.COERTHAS_CENTRAL_HIGHLANDS, region: MapLocation.PROVIDENCE_POINT, teleport: Teleport.DRAGONHEAD },
	[MapLocation.AGELYSS_WISE]: { map: MapLocation.EASTERN_LA_NOSCEA, region: MapLocation.AGELYSS_WISE, teleport: Teleport.WINEPORT },
	[MapLocation.ISLES_OF_UMBRA]: { map: MapLocation.WESTERN_LA_NOSCEA, region: MapLocation.ISLES_OF_UMBRA, teleport: Teleport.ALEPORT }, // TODO: Make this more specific - Check its usages!
	[MapLocation.HALFSTONE]: { map: MapLocation.WESTERN_LA_NOSCEA, region: MapLocation.HALFSTONE, teleport: Teleport.ALEPORT },
	[MapLocation.IRON_LAKE]: { map: MapLocation.OUTER_LA_NOSCEA, region: MapLocation.IRON_LAKE, teleport: Teleport.OVERLOOK },
	[MapLocation.PROUD_CREEK]: { map: MapLocation.NORTH_SHROUD, region: MapLocation.PROUD_CREEK, teleport: Teleport.FALLGOURD_FLOAT },
	[MapLocation.LARKSCALL]: { map: MapLocation.EAST_SHROUD, region: MapLocation.LARKSCALL, teleport: Teleport.HAWTHORNE_HUT },
	[MapLocation.URTHS_GIFT]: { map: MapLocation.SOUTH_SHROUD, region: MapLocation.URTHS_GIFT, teleport: Teleport.QUARRYMILL },
	[MapLocation.BLUEFOG]: { map: MapLocation.NORTHERN_THANALAN, region: MapLocation.BLUEFOG, teleport: Teleport.BLUEFOG },
	[MapLocation.RAUBAHNS_PUSH]: { map: MapLocation.NORTHERN_THANALAN, region: MapLocation.RAUBAHNS_PUSH, teleport: Teleport.CERULEUM_PROCESSING_PLANT },
	[MapLocation.BURNING_WALL]: { map: MapLocation.EASTERN_THANALAN, region: MapLocation.BURNING_WALL, teleport: Teleport.DRYBONE },
	[MapLocation.ZANRAK]: { map: MapLocation.SOUTHERN_THANALAN, region: MapLocation.ZANRAK, teleport: Teleport.FORGOTTEN_SPRINGS },
	[MapLocation.CAPE_WESTWIND]: { map: MapLocation.WESTERN_THANALAN, region: MapLocation.CAPE_WESTWIND, teleport: Teleport.HORIZON },
	[MapLocation.ZELMAS_RUN]: { map: MapLocation.UPPER_LA_NOSCEA, region: MapLocation.ZELMAS_RUN, teleport: Teleport.BRONZE_LAKE },
	[MapLocation.STANDING_CORSES]: { map: MapLocation.CENTRAL_SHROUD, region: MapLocation.STANDING_CORSES, teleport: Teleport.BENTBRANCH },
	[MapLocation.KOBOLD_DIG]: { map: MapLocation.OUTER_LA_NOSCEA, region: MapLocation.KOBOLD_DIG, teleport: Teleport.OVERLOOK },
	[MapLocation.BENTBRANCH]: { map: MapLocation.CENTRAL_SHROUD, region: MapLocation.BENTBRANCH, teleport: Teleport.BENTBRANCH },
	[MapLocation.UPPER_PATHS]: { map: MapLocation.SOUTH_SHROUD, region: MapLocation.UPPER_PATHS, teleport: Teleport.QUARRYMILL },
	[MapLocation.SNAKEMOLT]: { map: MapLocation.SOUTH_SHROUD, region: MapLocation.SNAKEMOLT, teleport: Teleport.QUARRYMILL },
	[MapLocation.RAINCATCHER_GULLY]: { map: MapLocation.EASTERN_LA_NOSCEA, region: MapLocation.RAINCATCHER_GULLY, teleport: Teleport.WINEPORT },
	[MapLocation.BRONZE_LAKE]: { map: MapLocation.UPPER_LA_NOSCEA, region: MapLocation.BRONZE_LAKE, teleport: Teleport.BRONZE_LAKE },
	[MapLocation.HORIZONS_EDGE]: { map: MapLocation.WESTERN_THANALAN, region: MapLocation.HORIZONS_EDGE, teleport: Teleport.HORIZON },
	[MapLocation.BROKEN_WATER]: { map: MapLocation.SOUTHERN_THANALAN, region: MapLocation.BROKEN_WATER, teleport: Teleport.LITTLE_ALA_MHIGO },
	[MapLocation.SANDGATE]: { map: MapLocation.EASTERN_THANALAN, region: MapLocation.SANDGATE, teleport: Teleport.DRYBONE },
	[MapLocation.BLACK_BRUSH]: { map: MapLocation.CENTRAL_THANALAN, region: MapLocation.BLACK_BRUSH, teleport: Teleport.BLACK_BRUSH },
	[MapLocation.SKULL_VALLEY]: { map: MapLocation.WESTERN_LA_NOSCEA, region: MapLocation.SKULL_VALLEY, teleport: Teleport.ALEPORT },
	[MapLocation.SKYSTEEL_MANUFACTORY]: { map: MapLocation.FOUNDATION, region: MapLocation.SKYSTEEL_MANUFACTORY, teleport: Teleport.FOUNDATION },
	[MapLocation.LAKE_TUSI_MEKTA]: { map: MapLocation.RAKTIKA_GREATWOOD, region: MapLocation.LAKE_TUSI_MEKTA, teleport: Teleport.SLITHERBOUGH },
	[MapLocation.DELTA_QUADRANT]: { map: MapLocation.AZYS_LLA, region: MapLocation.DELTA_QUADRANT, teleport: Teleport.HELIX },
	[MapLocation.THALIAK_RIVER]: { map: MapLocation.DRAVANIAN_HINTERLANDS, region: MapLocation.THALIAK_RIVER, teleport: Teleport.IDYLLSHIRE },
	[MapLocation.DRAGONSPIT]: { map: MapLocation.COERTHAS_WESTERN_HIGHLANDS, region: MapLocation.DRAGONSPIT, teleport: Teleport.FALCONS_NEST },
	// [MapLocation.YANXIA]: { map: MapLocation.YANXIA, region: MapLocation.YANXIA, teleport: Teleport.NAMAI }, // Yanxia is the map, what region is this supposed to be?!
	[MapLocation.ALPHA_QUADRANT]: { map: MapLocation.AZYS_LLA, region: MapLocation.ALPHA_QUADRANT, teleport: Teleport.HELIX },
	[MapLocation.NEWVOID]: { map: MapLocation.EUREKA_ANEMOS, region: MapLocation.NEWVOID, teleport: Teleport.ABANDONED_LAB },
	[MapLocation.ICEBLOOM_CAVERN]: { map: MapLocation.EUREKA_PAGOS, region: MapLocation.ICEBLOOM_CAVERN, teleport: Teleport.GRAVITATIONAL_STUDIES },
	[MapLocation.EAST_FLAMEROK]: { map: MapLocation.EUREKA_PYROS, region: MapLocation.EAST_FLAMEROK, teleport: Teleport.FIRING_CHAMBER },
	[MapLocation.EAST_VAL_RIVER_BANK]: { map: MapLocation.EUREKA_HYDATOS, region: MapLocation.EAST_VAL_RIVER_BANK, teleport: Teleport.DORMITORY },
	[MapLocation.OK_VUNDU_VANA]: { map: MapLocation.SEA_OF_CLOUDS, region: MapLocation.OK_VUNDU_VANA, teleport: Teleport.OK_ZUNDU },
	[MapLocation.RED_RIM]: { map: MapLocation.COERTHAS_WESTERN_HIGHLANDS, region: MapLocation.RED_RIM, teleport: Teleport.FALCONS_NEST },
	[MapLocation.WHILOM_RIVER]: { map: MapLocation.DRAVANIAN_FORELANDS, region: MapLocation.WHILOM_RIVER, teleport: Teleport.TAILFEATHER },
	[MapLocation.WHALES_CROWN]: { map: MapLocation.SEA_OF_CLOUDS, region: MapLocation.WHALES_CROWN, teleport: Teleport.OK_ZUNDU },
	[MapLocation.FOUR_ARMS]: { map: MapLocation.CHURNING_MISTS, region: MapLocation.FOUR_ARMS, teleport: Teleport.ZENITH },
	[MapLocation.ASHPOOL]: { map: MapLocation.COERTHAS_WESTERN_HIGHLANDS, region: MapLocation.ASHPOOL, teleport: Teleport.FALCONS_NEST },
	[MapLocation.MOURN]: { map: MapLocation.DRAVANIAN_FORELANDS, region: MapLocation.MOURN, teleport: Teleport.ANYX_TRINE },
	[MapLocation.DAGGERS]: { map: MapLocation.DRAVANIAN_HINTERLANDS, region: MapLocation.DAGGERS, teleport: Teleport.IDYLLSHIRE },
	[MapLocation.DRAGONS_STRUGGLE]: { map: MapLocation.YANXIA, region: MapLocation.DRAGONS_STRUGGLE, teleport: Teleport.HOUSE_OF_THE_FIERCE },
	[MapLocation.PAUPERS_LODE]: { map: MapLocation.LOCHS, region: MapLocation.PAUPERS_LODE, teleport: Teleport.PORTA_PRAETORIA },
	[MapLocation.DESTROYER_LOCHS]: { map: MapLocation.LOCHS, region: MapLocation.DESTROYER_LOCHS, teleport: Teleport.PORTA_PRAETORIA },
	[MapLocation.VALLEY_OF_THE_FALLEN_RAINBOW]: { map: MapLocation.YANXIA, region: MapLocation.VALLEY_OF_THE_FALLEN_RAINBOW, teleport: Teleport.HOUSE_OF_THE_FIERCE },
	[MapLocation.COOLING_STATION]: { map: MapLocation.AZYS_LLA, region: MapLocation.COOLING_STATION, teleport: Teleport.HELIX },
	[MapLocation.AQUEDUCT]: { map: MapLocation.AZYS_LLA, region: MapLocation.AQUEDUCT, teleport: Teleport.HELIX },
	[MapLocation.HYPERSTELLAR_DOWNCONVERTER]: { map: MapLocation.AZYS_LLA, region: MapLocation.HYPERSTELLAR_DOWNCONVERTER, teleport: Teleport.HELIX },
	[MapLocation.PAPPUS_TREE]: { map: MapLocation.AZYS_LLA, region: MapLocation.PAPPUS_TREE, teleport: Teleport.HELIX },
	[MapLocation.GAMMA_QUADRANT]: { map: MapLocation.AZYS_LLA, region: MapLocation.GAMMA_QUADRANT, teleport: Teleport.HELIX },
	[MapLocation.DIADEM]: { map: MapLocation.DIADEM },
	[MapLocation.ANTITHESIS]: { map: MapLocation.AZYS_LLA, region: MapLocation.ANTITHESIS, teleport: Teleport.HELIX },
	[MapLocation.CITIA_SWAMPS]: { map: MapLocation.RAKTIKA_GREATWOOD, region: MapLocation.CITIA_SWAMPS, teleport: Teleport.SLITHERBOUGH },
	[MapLocation.BOOKMANS_SHELVES]: { map: MapLocation.IL_MHEG, region: MapLocation.BOOKMANS_SHELVES, teleport: Teleport.LYDHA_LRAN },
	[MapLocation.HARE_AMONG_GIANTS]: { map: MapLocation.LAKELAND, region: MapLocation.HARE_AMONG_GIANTS, teleport: Teleport.OSTALL_IMPERATIVE },
	[MapLocation.CHURCH_OF_FIRST_LIGHT]: { map: MapLocation.LAKELAND, region: MapLocation.CHURCH_OF_FIRST_LIGHT, teleport: Teleport.FORT_JOBB },
	[MapLocation.MOUNT_BIRAN_MINES]: { map: MapLocation.AMH_ARAENG, region: MapLocation.MOUNT_BIRAN_MINES, teleport: Teleport.TWINE },
	[MapLocation.INN_AT_JOURNEYS_HEAD]: { map: MapLocation.AMH_ARAENG, region: MapLocation.INN_AT_JOURNEYS_HEAD, teleport: Teleport.INN_AT_JOURNEYS_HEAD },
	[MapLocation.SCREE]: { map: MapLocation.KHOLUSIA, region: MapLocation.SCREE, teleport: Teleport.TOMRA },
	[MapLocation.CHILIAD]: { map: MapLocation.LAKELAND, region: MapLocation.CHILIAD, teleport: Teleport.FORT_JOBB },
	[MapLocation.LOZATLS_CONQUEST]: { map: MapLocation.RAKTIKA_GREATWOOD, region: MapLocation.LOZATLS_CONQUEST, teleport: Teleport.SLITHERBOUGH },
	[MapLocation.WOVEN_OATH]: { map: MapLocation.RAKTIKA_GREATWOOD, region: MapLocation.WOVEN_OATH, teleport: Teleport.SLITHERBOUGH },
	[MapLocation.DELIBERATING_DOLL]: { map: MapLocation.RAKTIKA_GREATWOOD, region: MapLocation.DELIBERATING_DOLL, teleport: Teleport.SLITHERBOUGH },
	[MapLocation.HALDRATHS_MARCH]: { map: MapLocation.COERTHAS_CENTRAL_HIGHLANDS, region: MapLocation.HALDRATHS_MARCH, teleport: Teleport.DRAGONHEAD },
	[MapLocation.DRAGONHEAD]: { map: MapLocation.COERTHAS_CENTRAL_HIGHLANDS, region: MapLocation.DRAGONHEAD, teleport: Teleport.DRAGONHEAD },
	[MapLocation.CLUTCH]: { map: MapLocation.CENTRAL_THANALAN, region: MapLocation.CLUTCH, teleport: Teleport.BLACK_BRUSH },
	[MapLocation.SPINELESS_BASIN]: { map: MapLocation.CENTRAL_THANALAN, region: MapLocation.SPINELESS_BASIN, teleport: Teleport.BLACK_BRUSH },
	[MapLocation.LANDLORD_COLONY]: { map: MapLocation.CHURNING_MISTS, region: MapLocation.LANDLORD_COLONY, teleport: Teleport.ZENITH },
	[MapLocation.TWINPOOLS]: { map: MapLocation.COERTHAS_WESTERN_HIGHLANDS, region: MapLocation.TWINPOOLS, teleport: Teleport.FALCONS_NEST },
	[MapLocation.RULING_QUARTER]: { map: MapLocation.DRAVANIAN_HINTERLANDS, region: MapLocation.RULING_QUARTER, teleport: Teleport.IDYLLSHIRE },
	[MapLocation.LADLE]: { map: MapLocation.AMH_ARAENG, region: MapLocation.LADLE, teleport: Teleport.TWINE },
	[MapLocation.CHOCOBO_FOREST]: { map: MapLocation.DRAVANIAN_FORELANDS, region: MapLocation.CHOCOBO_FOREST, teleport: Teleport.TAILFEATHER },
	[MapLocation.AMITY]: { map: MapLocation.KHOLUSIA, region: MapLocation.AMITY, teleport: Teleport.TOMRA },
	[MapLocation.PRISTINE_PALACE_OF_AMH_MALIK]: { map: MapLocation.AMH_ARAENG, region: MapLocation.PRISTINE_PALACE_OF_AMH_MALIK, teleport: Teleport.INN_AT_JOURNEYS_HEAD },
	[MapLocation.WILD_FETE]: { map: MapLocation.RAKTIKA_GREATWOOD, region: MapLocation.WILD_FETE, teleport: Teleport.SLITHERBOUGH },
	[MapLocation.LOCH_SELD]: { map: MapLocation.LOCHS, region: MapLocation.LOCH_SELD, teleport: Teleport.PORTA_PRAETORIA },
	[MapLocation.GORGAGNE_HOLDING]: { map: MapLocation.COERTHAS_WESTERN_HIGHLANDS, region: MapLocation.GORGAGNE_HOLDING, teleport: Teleport.FALCONS_NEST },
	[MapLocation.NORVRANDT_SLOPE]: { map: MapLocation.TEMPEST, region: MapLocation.NORVRANDT_SLOPE, teleport: Teleport.ONDO_CUPS },
	[MapLocation.HONEY_YARD]: { map: MapLocation.EAST_SHROUD, region: MapLocation.HONEY_YARD, teleport: Teleport.HAWTHORNE_HUT },
	[MapLocation.YUGRAM_RIVER]: { map: MapLocation.EASTERN_THANALAN, region: MapLocation.YUGRAM_RIVER, teleport: Teleport.DRYBONE },
	[MapLocation.ALDER_SPRINGS]: { map: MapLocation.NORTH_SHROUD, region: MapLocation.ALDER_SPRINGS, teleport: Teleport.FALLGOURD_FLOAT },
	[MapLocation.BRAMBLE_PATCH]: { map: MapLocation.EAST_SHROUD, region: MapLocation.BRAMBLE_PATCH, teleport: Teleport.HAWTHORNE_HUT },
	[MapLocation.LOWER_PATHS]: { map: MapLocation.SOUTH_SHROUD, region: MapLocation.LOWER_PATHS, teleport: Teleport.TRANQUIL },
	[MapLocation.SILVER_BAZAAR]: { map: MapLocation.WESTERN_THANALAN, region: MapLocation.SILVER_BAZAAR, teleport: Teleport.HORIZON },
	[MapLocation.COSTA_DEL_SOL]: { map: MapLocation.EASTERN_LA_NOSCEA, region: MapLocation.COSTA_DEL_SOL, teleport: Teleport.COSTA_DEL_SOL },
	[MapLocation.UMBRA_NORTHSHORE]: { map: MapLocation.ISLES_OF_UMBRA, region: MapLocation.UMBRA_NORTHSHORE, teleport: Teleport.ALEPORT },
	[MapLocation.SOUTH_BLOODSHORE]: { map: MapLocation.EASTERN_LA_NOSCEA, region: MapLocation.SOUTH_BLOODSHORE, teleport: Teleport.COSTA_DEL_SOL },
	[MapLocation.SUMMERFORD]: { map: MapLocation.MIDDLE_LA_NOSCEA, region: MapLocation.SUMMERFORD, teleport: Teleport.SUMMERFORD_FARMS },
	[MapLocation.SMOLDERING_WASTES]: { map: MapLocation.DRAVANIAN_FORELANDS, region: MapLocation.SMOLDERING_WASTES, teleport: Teleport.TAILFEATHER },
	[MapLocation.BETA_QUADRANT]: { map: MapLocation.AZYS_LLA, region: MapLocation.BETA_QUADRANT, teleport: Teleport.HELIX },
	[MapLocation.VOOR_SIAN_SIRAN]: { map: MapLocation.SEA_OF_CLOUDS, region: MapLocation.VOOR_SIAN_SIRAN, teleport: Teleport.CLOUDTOP },
	[MapLocation.EASTON_EYES]: { map: MapLocation.CHURNING_MISTS, region: MapLocation.EASTON_EYES, teleport: Teleport.ZENITH },
	[MapLocation.AVALONIA_FALLEN]: { map: MapLocation.DRAVANIAN_FORELANDS, region: MapLocation.AVALONIA_FALLEN, teleport: Teleport.ANYX_TRINE },
	[MapLocation.GREENSWARD]: { map: MapLocation.CHURNING_MISTS, region: MapLocation.GREENSWARD, teleport: Teleport.ZENITH },
	[MapLocation.PATHS_OF_CONTEMPLATION]: { map: MapLocation.DRAVANIAN_HINTERLANDS, region: MapLocation.PATHS_OF_CONTEMPLATION, teleport: Teleport.IDYLLSHIRE },
	[MapLocation.NORTHERN_HILLS_OF_AMBER]: { map: MapLocation.AMH_ARAENG, region: MapLocation.NORTHERN_HILLS_OF_AMBER, teleport: Teleport.TWINE },
	[MapLocation.PATHS_OF_CREATION]: { map: MapLocation.DRAVANIAN_HINTERLANDS, region: MapLocation.PATHS_OF_CREATION, teleport: Teleport.IDYLLSHIRE },
	[MapLocation.BLACK_IRON_BRIDGE]: { map: MapLocation.COERTHAS_WESTERN_HIGHLANDS, region: MapLocation.BLACK_IRON_BRIDGE, teleport: Teleport.FALCONS_NEST },
	[MapLocation.OK_GUNDU]: { map: MapLocation.SEA_OF_CLOUDS, region: MapLocation.OK_GUNDU, teleport: Teleport.CLOUDTOP },
	[MapLocation.OK_VUNDU_MOK]: { map: MapLocation.SEA_OF_CLOUDS, region: MapLocation.OK_VUNDU_MOK, teleport: Teleport.OK_ZUNDU },
	[MapLocation.OK_BENDU]: { map: MapLocation.SEA_OF_CLOUDS, region: MapLocation.OK_BENDU, teleport: Teleport.OK_ZUNDU },
	[MapLocation.DROWNING_WENCH]: { map: MapLocation.LIMSA_UPPER_DECKS, region: MapLocation.DROWNING_WENCH, teleport: Teleport.AFTCASTLE },
	[MapLocation.BOZJA]: { map: MapLocation.BOZJA },
	[MapLocation.ZADNOR]: { map: MapLocation.ZADNOR },
	[MapLocation.BOZJA_SOUTH]: { map: MapLocation.BOZJA, region: MapLocation.BOZJA_SOUTH },
	[MapLocation.BOZJA_MIDDLE]: { map: MapLocation.BOZJA, region: MapLocation.BOZJA_MIDDLE },
	[MapLocation.BOZJA_NORTH]: { map: MapLocation.BOZJA, region: MapLocation.BOZJA_NORTH },
	[MapLocation.ZADNOR_SOUTH]: { map: MapLocation.ZADNOR, region: MapLocation.ZADNOR_SOUTH },
	[MapLocation.ZADNOR_MIDDLE]: { map: MapLocation.ZADNOR, region: MapLocation.ZADNOR_MIDDLE },
	[MapLocation.ZADNOR_NORTH]: { map: MapLocation.ZADNOR, region: MapLocation.ZADNOR_NORTH },
	[MapLocation.HYRSTMILL]: { map: MapLocation.NORTH_SHROUD, region: MapLocation.HYRSTMILL, teleport: Teleport.FALLGOURD_FLOAT },
	[MapLocation.BONFIRE]: { map: MapLocation.CENTRAL_THANALAN, region: MapLocation.BONFIRE, teleport: Teleport.BLACK_BRUSH },
	[MapLocation.VESPER_BAY]: { map: MapLocation.WESTERN_THANALAN, region: MapLocation.VESPER_BAY, teleport: Teleport.HORIZON },
	[MapLocation.SWIFTPERCH]: { map: MapLocation.WESTERN_LA_NOSCEA, region: MapLocation.SWIFTPERCH, teleport: Teleport.SWIFTPERCH },
	[MapLocation.HELIX]: { map: MapLocation.AZYS_LLA, region: MapLocation.HELIX, teleport: Teleport.HELIX },
	[MapLocation.GANGOS]: { map: MapLocation.GANGOS },
	[MapLocation.PRIMA_VISTA]: { map: MapLocation.KUGANE, region: MapLocation.PRIMA_VISTA, teleport: Teleport.KUGANE },
	[MapLocation.MONZEN]: { map: MapLocation.YANXIA, region: MapLocation.MONZEN, teleport: Teleport.HOUSE_OF_THE_FIERCE },
	[MapLocation.STARFALL]: { map: MapLocation.RHALGRS_REACH, region: MapLocation.STARFALL, teleport: Teleport.RHALGRS_REACH },
	[MapLocation.MARE_LAMENTORUM]: { map: MapLocation.MARE_LAMENTORUM },
	[MapLocation.SOUTHERN_MARE_LAMENTORUM]: { map: MapLocation.MARE_LAMENTORUM, region: MapLocation.SOUTHERN_MARE_LAMENTORUM, teleport: Teleport.SINUS_LACRIMARUM },
	[MapLocation.RADZ_AT_HAN]: { map: MapLocation.RADZ_AT_HAN},
	[MapLocation.ARTHA]: { map: MapLocation.RADZ_AT_HAN, region: MapLocation.ARTHA, teleport: Teleport.RADZ_AT_HAN_PLAZA },
	[MapLocation.BRUME]: { map: MapLocation.FOUNDATION, region: MapLocation.BRUME, teleport: Teleport.FOUNDATION },
	[MapLocation.FIRMAMENT]: { map: MapLocation.FIRMAMENT },
	[MapLocation.FEATHERFALL]: { map: MapLocation.FIRMAMENT, region: MapLocation.FEATHERFALL, teleport: Teleport.FOUNDATION },
	[MapLocation.THAVNAIR]: { map: MapLocation.THAVNAIR },
	[MapLocation.PURUSA]: { map: MapLocation.THAVNAIR, region: MapLocation.PURUSA, teleport: Teleport.PALAKAS_STAND },
	[MapLocation.PENDANTS]: { map: MapLocation.CRYSTARIUM, region: MapLocation.PENDANTS, teleport: Teleport.CRYSTARIUM },
	[MapLocation.ROTUNDA]: { map: MapLocation.CRYSTARIUM, region: MapLocation.ROTUNDA, teleport: Teleport.CRYSTARIUM },
	[MapLocation.BALDAQUIN]: { map: MapLocation.CRYSTARIUM, region: MapLocation.BALDAQUIN, teleport: Teleport.CRYSTARIUM },
	[MapLocation.TIMH_GYEUS]: { map: MapLocation.IL_MHEG, region: MapLocation.TIMH_GYEUS, teleport: Teleport.LYDHA_LRAN },
	[MapLocation.DUERGAR_MOUNTAINS]: { map: MapLocation.KHOLUSIA, region: MapLocation.DUERGAR_MOUNTAINS, teleport: Teleport.STILLTIDE },
	[MapLocation.SOURCE]: { map: MapLocation.LAKELAND, region: MapLocation.SOURCE, teleport: Teleport.OSTALL_IMPERATIVE },
	[MapLocation.SHADOW_FAULT]: { map: MapLocation.KHOLUSIA, region: MapLocation.SHADOW_FAULT, teleport: Teleport.WRIGHT },
	[MapLocation.FIELDS_OF_AMBER]: { map: MapLocation.AMH_ARAENG, region: MapLocation.FIELDS_OF_AMBER, teleport: Teleport.MORD_SOUQ },
	[MapLocation.WEATHERING]: { map: MapLocation.LAKELAND, region: MapLocation.WEATHERING, teleport: Teleport.FORT_JOBB },
	[MapLocation.ELPIS]: { map: MapLocation.ELPIS },
	[MapLocation.KALLIMELIOS_ZEPHYROS]: { map: MapLocation.ELPIS, region: MapLocation.KALLIMELIOS_ZEPHYROS, teleport: Teleport.POIETEN_OIKOS },
	[MapLocation.EBLAN_RIME]: { map: MapLocation.GARLEMALD, region: MapLocation.EBLAN_RIME, teleport: Teleport.CAMP_BROKEN_GLASS },
	[MapLocation.GARLEMALD]: { map: MapLocation.GARLEMALD },
	[MapLocation.CERULEA_INGENS]: { map: MapLocation.GARLEMALD, region: MapLocation.CERULEA_INGENS, teleport: Teleport.CAMP_BROKEN_GLASS },
	[MapLocation.PHILOMYTHES_NOTOS]: { map: MapLocation.ELPIS, region: MapLocation.PHILOMYTHES_NOTOS, teleport: Teleport.ANAGNORISIS },
	[MapLocation.OSTRAKON_DEKA_OKTO]: { map: MapLocation.ULTIMA_THULE, region: MapLocation.OSTRAKON_DEKA_OKTO, teleport: Teleport.REAH_TAHRA },
	[MapLocation.ULTIMA_THULE]: { map: MapLocation.ULTIMA_THULE },
	[MapLocation.OSTRAKON_TRIA]: { map: MapLocation.ULTIMA_THULE, region: MapLocation.OSTRAKON_TRIA, teleport: Teleport.ABODE_OF_THE_EA },
	[MapLocation.ETHOSELETIKOS_BOREAS]: { map: MapLocation.ELPIS, region: MapLocation.ETHOSELETIKOS_BOREAS, teleport: Teleport.POIETEN_OIKOS },
	[MapLocation.SULLEN]: { map: MapLocation.LAKELAND, region: MapLocation.SULLEN, teleport: Teleport.FORT_JOBB },
	[MapLocation.MEDIAL_CIRCUIT]: { map: MapLocation.LABYRINTHOS, region: MapLocation.MEDIAL_CIRCUIT, teleport: Teleport.ARCHEION },
	[MapLocation.LABYRINTHOS]: { map: MapLocation.LABYRINTHOS },
	[MapLocation.PERFUMED_RISE]: { map: MapLocation.THAVNAIR, region: MapLocation.PERFUMED_RISE, teleport: Teleport.GREAT_WORK },
	[MapLocation.WELLWICK_WOOD]: { map: MapLocation.EASTERN_THANALAN, region: MapLocation.WELLWICK_WOOD, teleport: Teleport.DRYBONE },
	[MapLocation.GREENTEAR]: { map: MapLocation.CENTRAL_SHROUD, region: MapLocation.GREENTEAR, teleport: Teleport.BENTBRANCH },
	[MapLocation.SAGOLII_DESERT]: { map: MapLocation.SOUTHERN_THANALAN, region: MapLocation.SAGOLII_DESERT, teleport: Teleport.FORGOTTEN_SPRINGS },
	[MapLocation.TREESPEAK]: { map: MapLocation.NORTH_SHROUD, region: MapLocation.TREESPEAK, teleport: Teleport.FALLGOURD_FLOAT },
	[MapLocation.DRYBONE]: { map: MapLocation.EASTERN_THANALAN, region: MapLocation.DRYBONE, teleport: Teleport.DRYBONE },
	[MapLocation.HAMMERLEA]: { map: MapLocation.WESTERN_THANALAN, region: MapLocation.HAMMERLEA, teleport: Teleport.HORIZON },
	[MapLocation.NINE_IVIES]: { map: MapLocation.EAST_SHROUD, region: MapLocation.NINE_IVIES, teleport: Teleport.HAWTHORNE_HUT },
	[MapLocation.OAKWOOD]: { map: MapLocation.UPPER_LA_NOSCEA, region: MapLocation.OAKWOOD, teleport: Teleport.BRONZE_LAKE },
	[MapLocation.SORREL_HAVEN]: { map: MapLocation.CENTRAL_SHROUD, region: MapLocation.SORREL_HAVEN, teleport: Teleport.BENTBRANCH },
	[MapLocation.NOPHICAS_WELLS]: { map: MapLocation.WESTERN_THANALAN, region: MapLocation.NOPHICAS_WELLS, teleport: Teleport.HORIZON },
	[MapLocation.MITHAI_GLORIANDA]: { map: MapLocation.IL_MHEG, region: MapLocation.MITHAI_GLORIANDA, teleport: Teleport.WOLEKDORF },
	[MapLocation.BRIGHT_CLIFF]: { map: MapLocation.KHOLUSIA, region: MapLocation.BRIGHT_CLIFF, teleport: Teleport.STILLTIDE },
	[MapLocation.CENTRAL_HILLS_OF_AMBER]: { map: MapLocation.AMH_ARAENG, region: MapLocation.CENTRAL_HILLS_OF_AMBER, teleport: Teleport.TWINE },
	[MapLocation.GOOD_JENANNAS_GRACE]: { map: MapLocation.IL_MHEG, region: MapLocation.GOOD_JENANNAS_GRACE, teleport: Teleport.WOLEKDORF },
	[MapLocation.NABAATH_ARENG]: { map: MapLocation.AMH_ARAENG, region: MapLocation.NABAATH_ARENG, teleport: Teleport.INN_AT_JOURNEYS_HEAD },
	[MapLocation.CALIBAN_GORGE]: { map: MapLocation.TEMPEST, region: MapLocation.CALIBAN_GORGE, teleport: Teleport.ONDO_CUPS },
	[MapLocation.BLIND_FOREST]: { map: MapLocation.RAKTIKA_GREATWOOD, region: MapLocation.BLIND_FOREST, teleport: Teleport.SLITHERBOUGH },
	[MapLocation.VOEBURTENBURG]: { map: MapLocation.IL_MHEG, region: MapLocation.VOEBURTENBURG, teleport: Teleport.WOLEKDORF },
};
