/* eslint-disable max-len */
//#region Imports
import { jobLists } from "character";
import NoCharacter from "components/no-character/NoCharacter";
import { RelicGroup, RelicType } from "enums";
import manager from "managers/app";
import { Component } from "react";
import { connect } from "react-redux";
import t from "translations/translator";
import { AppState, ViewToolSkysteelOverallProps } from "types";
import ContentOptions from "views/page-parts/content/content-options/ContentOptions";
import OverallTable from "views/page-parts/content/overall-table/OverallTable";
import "./ToolSkysteelOverall.css";
//#endregion Imports

const mapStateToProps = (state:AppState) => {
	return {
		lang: state.userInfo.options.lang,
		gameLang: state.userInfo.options.gameLang,
		userInfo: state.userInfo,
	};
};

class ToolSkysteelOverall extends Component<ViewToolSkysteelOverallProps> {
	render(){
		const character = manager.data.getActiveCharacter();
		if(character === null){ return <NoCharacter />; }

		return (
			<div id="tool-skysteel-overall" className="relic">
				<h1>{t.t("Overall")}</h1>
				<div className="options">
					<ContentOptions jobs={jobLists.relic[RelicGroup.TOOL_SKYSTEEL]} relic={RelicGroup.TOOL_SKYSTEEL} />
				</div>
				<OverallTable relicType={RelicType.TOOL} relic={RelicGroup.TOOL_SKYSTEEL} />
			</div>
		);
	}

	componentDidMount(){
		manager.view.changeComplete();
	}

}
export default connect(mapStateToProps)(ToolSkysteelOverall);
