/* eslint-disable quote-props, max-len */
/*
	Useful Regex

	Find: .* = ([&_,".a-z ()':0-9-]*),
	Replace: \t$1: { EN: $1, FR: null, DE: null, JP: null },

	Find: \t([-'"a-z &+0-9]*): ([-'"a-z &+0-9]*),
	Replace: \t$1: { EN: $1, FR: null, DE: null, JP: null },
*/

const gameTranslations = {
	//#region General
	"": { EN: "", FR: "", DE: "", JP: "" },
	"GUnknown": { EN: "Unknown", FR: "Inconnu", DE: "Unbekannt", JP: "不明" },
	"The Baldesion Arsenal": { EN: "The Baldesion Arsenal", FR: "L'arsenal de Baldésion", DE: "Baldesions Arsenal", JP: "バルデシオンアーセナル" },
	//#endregion General

	//#region Used in multiple Enums
	"Allagan Hunter": { EN: "Allagan Hunter", FR: "Chasseur allagois", DE: "Allagischer Jäger", JP: "アラガンハンター" },
	"Aetherlouse": { EN: "Aetherlouse", FR: "Pou d'éther", DE: "Ätherlaus", JP: "エーテルラウス" },
	"Dragonspine": { EN: "Dragonspine", FR: "Coquillage draconique", DE: "Drachenknochenmuschel", JP: "竜骨貝" },
	"Flintstrike": { EN: "Flintstrike", FR: "Poisson follet", DE: "Feuerspeier", JP: "ファイアエミッター" },
	"Hammerhead Shark": { EN: "Hammerhead Shark", FR: "Requin-marteau", DE: "Hammerhai", JP: "ハンマーヘッド" },
	"Pagos Crystal": { EN: "Pagos Crystal", FR: "Cristal Pagos", DE: "Pagos-Kristall", JP: "パゴスクリスタル" },
	"Protean Crystal": { EN: "Protean Crystal", FR: "Cristal instable", DE: "Instabiler Kristall", JP: "乱属性クリスタル" },
	"Pyros Crystal": { EN: "Pyros Crystal", FR: "Cristal Pyros", DE: "Pyros-Kristall", JP: "ピューロスクリスタル" },
	"Petal Shell": { EN: "Petal Shell", FR: "Fleur-de-prunier", DE: "Pflaumenblüte", JP: "梅花貝" },
	"Pickled Pom": { EN: "Pickled Pom", FR: "Morimog", DE: "Ranziger Mogpom", JP: "ポクリポン" },
	"Silver Shark": { EN: "Silver Shark", FR: "Requin argenté", DE: "Silberhai", JP: "シルバーシャーク" },
	"Thinker's Coral": { EN: "Thinker's Coral", FR: "Corail sapient", DE: "Weisenkoralle", JP: "智慧珊瑚" },
	"Worm of Nym": { EN: "Worm of Nym", FR: "Ver de Nym", DE: "Wurm von Nym", JP: "ワーム・オブ・ニーム" },
	"Denys": { EN: "Denys", FR: "Denys", DE: "Denys", JP: "デニス" },
	"North Silvertear": { EN: "North Silvertear", FR: "Rive nord de Pleurargent", DE: "Silbertränen-Nordufer", JP: "銀泪湖北岸" },
	"Halfstone": { EN: "Halfstone", FR: "Demipierre", DE: "Halbstein", JP: "ハーフストーン" },
	"Cape Westwind": { EN: "Cape Westwind", FR: "Cap Vendouest", DE: "Kap Westwind", JP: "ウエストウインド岬" },
	"Delta Quadrant": { EN: "Delta Quadrant", FR: "Quadrant Delta", DE: "Delta Quadrant", JP: null },
	"Thaliak River": { EN: "Thaliak River", FR: "Rivière Thaliak", DE: "Der Thaliak", JP: null },
	"Dragonspit": { EN: "Dragonspit", FR: "Glaire-de-dragon", DE: "Drachengreifer", JP: null },
	"Alpha Quadrant": { EN: "Alpha Quadrant", FR: "Quadrant Alpha", DE: "Alpha Quadrant", JP: null },
	"The Pappus Tree": { EN: "The Pappus Tree", FR: "L'arbre à pappus", DE: "Pappus-Baum", JP: null },
	"The Silver Bazaar": { EN: "The Silver Bazaar", FR: "Le Bazar Argenté", DE: "Der Silberbasar", JP: "シルバーバザー" },
	"Costa Del Sol": { EN: "Costa Del Sol", FR: "Costa Del Sol", DE: "Sonnenküste", JP: "コスタ・デル・ソル" },
	"Isles of Umbra Northshore": { EN: "Isles of Umbra Northshore", FR: "Côte nord des îles d'Ombra", DE: "Nördliche Phantominseln", JP: null },
	"South Bloodshore": { EN: "South Bloodshore", FR: "Sud des Rives sanglantes", DE: "Südliche Rotgischt", JP: null },
	"Summerford": { EN: "Summerford", FR: "Vergers d'Estival", DE: "Sommerfurt", JP: "サマーフォード" },
	"Mor Dhona": { EN: "Mor Dhona", FR: "Mor Dhona", DE: "Mor Dhona", JP: "モードゥナ" },
	"Foundation": { EN: "Foundation", FR: "L'Assise", DE: "Fundamente", JP: null },
	"Helix": { EN: "Helix", FR: "L'Hélice", DE: "Helix", JP: null },
	"Idyllshire": { EN: "Idyllshire", FR: "Idyllée", DE: "Frohehalde", JP: null },
	"The Inn at Journey's Head": { EN: "The Inn at Journey's Head", FR: "Auberge du Grand Départ", DE: "Ruhstatt vor der Langen Reise", JP: null },
	"Swiftperch": { EN: "Swiftperch", FR: "Le Martinet", DE: "Tölpelturm-Siedlung", JP: null },
	"Kugane": { EN: "Kugane", FR: "Kugane", DE: "Kugane", JP: "クガネ" },
	"Rhalgr's Reach": { EN: "Rhalgr's Reach", FR: "L'étendue de Rhalgr", DE: "Rhalgrs Wacht", JP: null },
	"The Crystarium": { EN: "The Crystarium", FR: "Cristarium", DE: "Crystarium", JP: "クリスタリウム" },
	"Platinum Seahorse": { EN: "Platinum Seahorse", FR: "Hippocampe platiné", DE: "Platinpferdchen", JP: null },
	"Clavekeeper": { EN: "Clavekeeper", FR: "Gardienne du Clavis", DE: "Clavis-Wächter", JP: null },
	"Mirror Image": { EN: "Mirror Image", FR: "Moirure-de-Beaumiroir", DE: "Prinzessinnenfisch", JP: null },
	"Spangled Pirarucu": { EN: "Spangled Pirarucu", FR: "Pirarucu royal", DE: "Pailletten-Pirarucu", JP: null },
	"The Source": { EN: "The Source", FR: "L'Origine", DE: "Ursprungswelt", JP: null },
	"Lake Tusi Mek'ta": { EN: "Lake Tusi Mek'ta", FR: "Lac Tusi Mek'ta", DE: "Schlangensee Tusi Mek'ta", JP: "トゥシ・メキタ湖" },
	"Olgoi-Khorkhoi": { EN: "Olgoi-Khorkhoi", FR: "Olgoi-Khorkhoi", DE: "Olgoi-Khorkhoi", JP: null },
	"Camp Drybone": { EN: "Camp Drybone", FR: "Camp des Os desséchés", DE: "Camp Knochenbleich", JP: "キャンプ・ドライボーン" },
	"Talan": { EN: "Talan", FR: "Talan", DE: "Talan", JP: null },
	//#endregion Used in multiple Enums

	//#region Jobs - Code
	"PLD": { EN: "PLD", FR: "PLD", DE: "PLD", JP: "PLD" },
	"WAR": { EN: "WAR", FR: "GUE", DE: "KRG", JP: "WAR" },
	"DRK": { EN: "DRK", FR: "CHN", DE: "DKR", JP: "DRK" },
	"GNB": { EN: "GNB", FR: "PSB", DE: "REV", JP: "GNB" },
	"WHM": { EN: "WHM", FR: "MBL", DE: "WMA", JP: "WHM" },
	"SCH": { EN: "SCH", FR: "ERU", DE: "GLT", JP: "SCH" },
	"AST": { EN: "AST", FR: "AST", DE: "AST", JP: "AST" },
	"SGE": { EN: "SGE", FR: "SAG", DE: "WEI", JP: "SGE" },
	"MNK": { EN: "MNK", FR: "MOI", DE: "MÖN", JP: "MNK" },
	"DRG": { EN: "DRG", FR: "DRG", DE: "DRG", JP: "DRG" },
	"NIN": { EN: "NIN", FR: "NIN", DE: "NIN", JP: "NIN" },
	"SAM": { EN: "SAM", FR: "SAM", DE: "SAM", JP: "SAM" },
	"RPR": { EN: "RPR", FR: "FCH", DE: "SNT", JP: "RPR" },
	"BRD": { EN: "BRD", FR: "BRD", DE: "BRD", JP: "BRD" },
	"MCH": { EN: "MCH", FR: "MCH", DE: "MCH", JP: "MCH" },
	"DNC": { EN: "DNC", FR: "DNS", DE: "TÄN", JP: "DNC" },
	"BLM": { EN: "BLM", FR: "MNO", DE: "SMA", JP: "BLM" },
	"SMN": { EN: "SMN", FR: "INV", DE: "BSW", JP: "SMN" },
	"RDM": { EN: "RDM", FR: "MRG", DE: "RMA", JP: "RDM" },
	"BLU": { EN: "BLU", FR: "MBU", DE: "BMA", JP: "BLU" },
	"CRP": { EN: "CRP", FR: "MEN", DE: "ZMR", JP: "CRP" },
	"BSM": { EN: "BSM", FR: "FRG", DE: "GRS", JP: "BSM" },
	"ARM": { EN: "ARM", FR: "ARM", DE: "PLA", JP: "ARM" },
	"GSM": { EN: "GSM", FR: "ORF", DE: "GLD", JP: "GSM" },
	"LTW": { EN: "LTW", FR: "TAN", DE: "GER", JP: "LTW" },
	"WVR": { EN: "WVR", FR: "COU", DE: "WEB", JP: "WVR" },
	"ALC": { EN: "ALC", FR: "ALC", DE: "ALC", JP: "ALC" },
	"CUL": { EN: "CUL", FR: "CUI", DE: "GRM", JP: "CUL" },
	"MIN": { EN: "MIN", FR: "MIN", DE: "MIN", JP: "MIN" },
	"BTN": { EN: "BTN", FR: "BOT", DE: "GÄR", JP: "BTN" },
	"FSH": { EN: "FSH", FR: "PÊC", DE: "FIS", JP: "FSH" },
	//#endregion Jobs - Code

	//#region Jobs - Full Name
	"Paladin": { EN: "Paladin", FR: "Paladin", DE: "Paladin", JP: "ナイト (剣術士)" },
	"Warrior": { EN: "Warrior", FR: "Guerrier", DE: "Krieger", JP: "戦士 (斧術士)" },
	"Dark Knight": { EN: "Dark Knight", FR: "Chevalier noir", DE: "Dunkelritter", JP: "暗黒騎士" },
	"Gunbreaker": { EN: "Gunbreaker", FR: "Pistosabreur", DE: "Revolverklinge", JP: "ガンブレイカー" },
	"White Mage": { EN: "White Mage", FR: "Mage blanc", DE: "Weißmagier", JP: "白魔道士 (幻術士)" },
	"Scholar": { EN: "Scholar", FR: "Érudit", DE: "Gelehrter", JP: "学者" },
	"Astrologian": { EN: "Astrologian", FR: "Astromancien", DE: "Astrologe", JP: "占星術師" },
	"Sage": { EN: "Sage", FR: "Sage", DE: "Weiser", JP: "賢者" },
	"Monk": { EN: "Monk", FR: "Moine", DE: "Mönch", JP: "モンク (格闘士)" },
	"Dragoon": { EN: "Dragoon", FR: "Chevalier dragon", DE: "Dragoon", JP: "竜騎士 (槍術士)" },
	"Ninja": { EN: "Ninja", FR: "Ninja", DE: "Ninja", JP: "忍者 (双剣士)" },
	"Samurai": { EN: "Samurai", FR: "Samouraï", DE: "Samurai", JP: "侍" },
	"Reaper": { EN: "Reaper", FR: "Faucheur", DE: "Schnitter", JP: "リーパー" },
	"Bard": { EN: "Bard", FR: "Barde", DE: "Barde", JP: "吟遊詩人 (弓術士)" },
	"Machinist": { EN: "Machinist", FR: "Machiniste", DE: "Maschinist", JP: "機工士" },
	"Dancer": { EN: "Dancer", FR: "Danseur", DE: "Tänzer", JP: "踊り子" },
	"Black Mage": { EN: "Black Mage", FR: "Mage noir", DE: "Schwarzmagier", JP: "黒魔道士 (呪術士)" },
	"Summoner": { EN: "Summoner", FR: "Invocateur", DE: "Beschwörer", JP: "召喚士 (巴術士)" },
	"Red Mage": { EN: "Red Mage", FR: "Mage rouge", DE: "Rotmagier", JP: "赤魔道士" },
	"Blue Mage": { EN: "Blue Mage", FR: "Mage bleu", DE: "Blaumagier", JP: "青魔道士" },
	"Carpenter": { EN: "Carpenter", FR: "Menuisier", DE: "Zimmerer", JP: "木工師" },
	"Blacksmith": { EN: "Blacksmith", FR: "Forgeron", DE: "Grobschmied", JP: "鍛冶師" },
	"Armorer": { EN: "Armorer", FR: "Armurier", DE: "Plattner", JP: "甲冑師" },
	"Goldsmith": { EN: "Goldsmith", FR: "Orfèvre", DE: "Goldschmied", JP: "彫金師" },
	"Leatherworker": { EN: "Leatherworker", FR: "Tanneur", DE: "Gerber", JP: "革細工師" },
	"Weaver": { EN: "Weaver", FR: "Couturier", DE: "Weber", JP: "裁縫師" },
	"Alchemist": { EN: "Alchemist", FR: "Alchimiste", DE: "Alchemist", JP: "錬金術師" },
	"Culinarian": { EN: "Culinarian", FR: "Cuisinier", DE: "Gourmet", JP: "調理師" },
	"Miner": { EN: "Miner", FR: "Mineur", DE: "Minenarbeiter", JP: "採掘師" },
	"Botanist": { EN: "Botanist", FR: "Botaniste", DE: "Gärtner", JP: "園芸師" },
	"Fisher": { EN: "Fisher", FR: "Pêcheur", DE: "Fischer", JP: "漁師" },
	//#endregion Jobs - Full Name

	//#region Shops
	/* Denys */
	"Auriana": { EN: "Auriana", FR: "Auriana", DE: "Auriana", JP: "オーリアナ" },
	"Hismena": { EN: "Hismena", FR: "Hismena", DE: "Hismena", JP: "ヒスメナ" },
	"Rowena's Representative (Limsa Lominsa)": { EN: "Rowena's Representative (Limsa Lominsa)", FR: "Commise de Rowena (Limsa Lominsa)", DE: "Rowenas Händlerin (Limsa Lominsa)", JP: "ロウェナ商会の商人 (Limsa Lominsa)" },
	"Rowena's Representative (Ul'Dah)": { EN: "Rowena's Representative (Ul'Dah)", FR: "Commise de Rowena (Ul'Dah)", DE: "Rowenas Händlerin (Ul'Dah)", JP: "ロウェナ商会の商人 (Ul'Dah)" },
	"Rowena's Representative (Gridania)": { EN: "Rowena's Representative (Gridania)", FR: "Commise de Rowena (Gridania)", DE: "Rowenas Händlerin (Gridania)", JP: "ロウェナ商会の商人 (Gridania)" },
	"Rowena's Representative (Foundation)": { EN: "Rowena's Representative (Foundation)", FR: "Commise de Rowena (Foundation)", DE: "Rowenas Händlerin (Foundation)", JP: "ロウェナ商会の商人 (Foundation)" },
	"Serpent Quartermaster": { EN: "Serpent Quartermaster", FR: "Officier de la logistique", DE: "Quartiermeisterin", JP: "補給担当官" },
	"Storm Quartermaster": { EN: "Storm Quartermaster", FR: "Officier de la logistique", DE: "Quartiermeisterin", JP: "補給担当官" },
	"Flame Quartermaster": { EN: "Flame Quartermaster", FR: "Officier de la logistique", DE: "Quartiermeisterin", JP: "補給担当官" },
	"Junkmonger": { EN: "Junkmonger", FR: "Baudroie éboueuse", DE: "Gemischtwarenhändler", JP: "ジャンクモンガー" },
	"Merchant & Mender (Southern Thanalan)": { EN: "Merchant & Mender (Southern Thanalan)", FR: "Quincaillière", DE: "Krämerin", JP: null },
	"Merchant & Mender (Coerthas Central Highlands)": { EN: "Merchant & Mender (Coerthas Central Highlands)", FR: "Quincaillière", DE: "Händler", JP: null },
	"Tool Supplier & Mender": { EN: "Tool Supplier & Mender", FR: "Quincailler", DE: "Werkzeugmacher", JP: null },
	"Cristiana": { EN: "Cristiana", FR: "Cristiana", DE: "Cristiana", JP: "クリスティアナ" },
	"Hunt Billmaster (Ul'Dah)": { EN: "Hunt Billmaster (Ul'Dah)", FR: "Responsable de la Chasse (Ul'Dah)", DE: "Jagdmeister (Ul'Dah)", JP: null },
	"Hunt Billmaster (Gridania)": { EN: "Hunt Billmaster (Gridania)", FR: "Responsable de la Chasse (Gridania)", DE: "Jagdmeister (Gridania)", JP: null },
	"Hunt Billmaster (Limsa Lominsa)": { EN: "Hunt Billmaster (Limsa Lominsa)", FR: "Responsable de la Chasse (Limsa Lominsa)", DE: "Jagdmeister (Limsa Lominsa)", JP: null },
	"Luna Vanu": { EN: "Luna Vanu", FR: "Luna Vanu", DE: "Luna Vanu", JP: "商人のルナバヌ" },
	"Amalj'aa Vendor": { EN: "Amalj'aa Vendor", FR: "Vendeur Amalj'aa", DE: "Amalj'aa-Händler", JP: null },
	"Vath Stickpeddler": { EN: "Vath Stickpeddler", FR: "Camelot", DE: "Krämer (Vath)", JP: null },
	"Sabina": { EN: "Sabina", FR: "Sabina", DE: "Sabina", JP: "サビーナ" },
	"Mogmul Mogbelly": { EN: "Mogmul Mogbelly", FR: "Mogmul", DE: "Mogmul Mogbauch", JP: "大食いのモグムリ" },
	"Sahagin Vendor": { EN: "Sahagin Vendor", FR: "Vendeur Sahuagin", DE: "Sahagin-Händler", JP: null },
	"Kobold Vendor": { EN: "Kobold Vendor", FR: "Vendeur Kobold", DE: "Kobold-Händler", JP: null },
	"Sylphic Vendor": { EN: "Sylphic Vendor", FR: "Vendeur Sylphe", DE: "Sylphen-Händlerin", JP: null },
	"Scrip Exchange (Ul'Dah)": { EN: "Scrip Exchange (Ul'Dah)", FR: "Comptable des assignats (Ul'Dah)", DE: "Scheinewechslerin (Ul'Dah)", JP: null },
	"Scrip Exchange (Gridania)": { EN: "Scrip Exchange (Gridania)", FR: "Comptable des assignats (Gridania)", DE: "Scheinewechslerin (Gridania)", JP: null },
	"Scrip Exchange (Limsa Lominsa)": { EN: "Scrip Exchange (Limsa Lominsa)", FR: "Comptable des assignats (Limsa Lominsa)", DE: "Scheinewechslerin (Limsa Lominsa)", JP: null },
	"Scrip Exchange (Idyllshire)": { EN: "Scrip Exchange (Idyllshire)", FR: "Comptable des assignats (Idyllée)", DE: "Scheinewechslerin (Frohehalde)", JP: null },
	"Scrip Exchange (Mor Dhona)": { EN: "Scrip Exchange (Mor Dhona)", FR: "Comptable des assignats (Mor Dhona)", DE: "Scheinewechslerin (Mor Dhona)", JP: null },
	"Scrip Exchange (Rhalgr's Reach)": { EN: "Scrip Exchange (Rhalgr's Reach)", FR: "Comptable des assignats (L'Etendue de Rhalgr)", DE: "Scheinewechslerin (Rhalgrs Wacht)", JP: null },
	"Scrip Exchange (Eulmore)": { EN: "Scrip Exchange (Eulmore)", FR: "Comptable des assignats (Eulmore)", DE: "Scheinewechslerin (Eulmore)", JP: null },
	"Scrip Exchange (The Crystarium)": { EN: "Scrip Exchange (The Crystarium)", FR: "Comptable des assignats (Cristarium)", DE: "Scheinewechslerin (Crystarium)", JP: null },
	"Scrip Exchange (Old Sharlayan)": { EN: "Scrip Exchange (Old Sharlayan)", FR: "Comptable des assignats (Vieille Sharlayan)", DE: "Scheinewechsler (Alt-Sharlayan)", JP: null },
	"Script Exchange (Radz-at-Han)": { EN: "Script Exchange (Radz-at-Han)", FR: "Comptable des assignats (Radz-at-Han)", DE: "Scheinewechslerin (Radz-at-Han)", JP: null },
	"Sanana": { EN: "Sanana", FR: "Sanana", DE: "Sanana", JP: "サナナ" },
	"Anna": { EN: "Anna", FR: "Anna", DE: "Anna", JP: "アンナ" },
	"Diana": { EN: "Diana", FR: "Diana", DE: "Diana", JP: "ダイアナ" },
	"Enie": { EN: "Enie", FR: "Enie", DE: "Enie", JP: "エニー" },
	"Pedronille": { EN: "Pedronille", FR: "Pedronille", DE: "Pedronille", JP: "広域交易商 ペドロニール" },
	"Nacille": { EN: "Nacille", FR: "Nacille", DE: "Nacille", JP: "広域交易商 ナシル" },
	"Gramsol": { EN: "Gramsol", FR: "Gramsol", DE: "Gramsol", JP: "広域交易商 グラムソル" },
	"Expedition Birdwatcher (Pagos)": { EN: "Expedition Birdwatcher (Pagos)", FR: "Sentinelle de l'expédition (Pagos)", DE: "Expeditionswächter (Pagos)", JP: null },
	"Expedition Birdwatcher (Anemos)": { EN: "Expedition Birdwatcher (Anemos)", FR: "Sentinelle de l'expédition (Anemos)", DE: "Expeditionswächter (Anemos)", JP: null },
	"Expedition Birdwatcher (Pyros)": { EN: "Expedition Birdwatcher (Pyros)", FR: "Sentinelle de l'expédition (Pyros)", DE: "Expeditionswächter (Pyros)", JP: null },
	"Jubrunnah": { EN: "Jubrunnah", FR: "Jubrunnah", DE: "Jubrunnah", JP: "ジュブルンナ" },
	"Mowen's Merchant (The Crystarium)": { EN: "Mowen's Merchant (The Crystarium)", FR: "Commise de Mowen (Cristarium)", DE: "Mowens Händlerin (Crystarium)", JP: null },
	"Z'ranmaia": { EN: "Z'ranmaia", FR: "Z'ranmaia", DE: "Z'ranmaia", JP: "生薬屋 ジョ・ランマヤ" },
	"Allagan Resupply Node": { EN: "Allagan Resupply Node", FR: "Sphère de vente", DE: "Vertrag für ein allagisches Lieferungssystem", JP: null },
	"Ananta Material Supplier": { EN: "Ananta Material Supplier", FR: "Vendeuse ananta", DE: "Ananta-Lieferantin", JP: null },
	"Jossy": { EN: "Jossy", FR: "Jossy", DE: "Jossy", JP: "調理師 ヨッシー" },
	"Kojin Material Supplier": { EN: "Kojin Material Supplier", FR: "Vendeur kojin", DE: "Kojin-Materiallieferant", JP: null },
	"Material Supplier": { EN: "Material Supplier", FR: "Marchand", DE: "Materiallieferant", JP: null },
	"Namazu Material Supplier": { EN: "Namazu Material Supplier", FR: "Vendeur namazu", DE: "Namazuo-Lieferant", JP: null },
	"Esmenet": { EN: "Esmenet", FR: "Esmenet", DE: "Esmenet", JP: "錬金術師 エスメネット" },
	"Tanie": { EN: "Tanie", FR: "Tanie", DE: "Tanie", JP: "花屋 タニ" },
	"Scrap Salvager": { EN: "Scrap Salvager", FR: "Bazardeur", DE: "Materiallieferantin", JP: null },
	"Vral": { EN: "Vral", FR: "Vral", DE: "Vral", JP: "素材屋 ヴラール" },
	"Halden": { EN: "Halden", FR: "Halden", DE: "Halden", JP: "広域交易商 ハルデン" },
	"Goushs Ooan": { EN: "Goushs Ooan", FR: "Goushs Ooan", DE: "Goushs Ooan", JP: "広域交易商 ゴウスィー・オーン" },
	"Engerrand": { EN: "Engerrand", FR: "Engerrand", DE: "Engerrand", JP: "素材屋 エンゲランド" },
	"Fridurih": { EN: "Fridurih", FR: "Fridurih", DE: "Fridurih", JP: "素材屋 フリードウリッヒ" },
	"O'rhoyod": { EN: "O'rhoyod", FR: "O'rhoyod", DE: "O'rhoyod", JP: "素材屋 オ・ロヨド" },
	"Pasdevillet": { EN: "Pasdevillet", FR: "Pasdevillet", DE: "Pasdevillet", JP: "素材屋 パドゥビレ" },
	"Shirakashi": { EN: "Shirakashi", FR: "Shirakashi", DE: "Shirakashi", JP: "素材屋 シラカシ" },
	"Traveling Material Supplier": { EN: "Traveling Material Supplier", FR: "Marchande ambulante", DE: "Steppen-Materiallieferantin", JP: null },
	"Merchant & Mender (Silver Bazaar)": { EN: "Merchant & Mender (Silver Bazaar)", FR: "Quincaillier (Bazar Argenté)", DE: "Krämerin (Der Silberbasar)", JP: null },
	"Littlejohn": { EN: "Littlejohn", FR: "Littlejohn", DE: "Littlejohn", JP: "粉屋 リトルジョン" },
	"Alehouse Wench (Black Brush)": { EN: "Alehouse Wench (Black Brush)", FR: "Serveuse (Roncenoire)", DE: "Schankmaid (Kohlenstaub-Bahnhof)", JP: null },
	"Scrip Exchange (Ocean Fishing)": { EN: "Scrip Exchange (Ocean Fishing)", FR: "Comptable des assignats", DE: "Scheinewechslerin (Auf großer Fahrt)", JP: null },
	"Soemrwyb": { EN: "Soemrwyb", FR: "Soemrwyb", DE: "Soemrwyb", JP: "甲冑師 スムルウィブ" },
	"Calamity Salvager (Limsa)": { EN: "Calamity Salvager (Limsa)", FR: "Consigneur (Limsa Lominsa)", DE: "Fundsachen-Verwalter (Limsa)", JP: null },
	"Calamity Salvager (Old Gridania)": { EN: "Calamity Salvager (Old Gridania)", FR: "Consigneur (Vieille Gridania)", DE: "Fundsachen-Verwalter (Alt-Gridania)", JP: null },
	"Calamity Salvager (Ul'Dah)": { EN: "Calamity Salvager (Ul'Dah)", FR: "Consigneur (Ul'Dah)", DE: "Fundsachen-Verwalter (Ul'Dah)", JP: null },
	"Gadfrid": { EN: "Gadfrid", FR: "Gadrid", DE: "Gadfrid", JP: null },
	"Sajareen": { EN: "Sajareen", FR: "Sajareen", DE: "Sajareen", JP: null },
	"Zawawa": { EN: "Zawawa", FR: "Zawawa", DE: "Zawawa", JP: null },
	"Aisara": { EN: "Aisara", FR: "Aisara", DE: "Aisara", JP: null },
	"Tradingway": { EN: "Tradingway", FR: "Tradingway", DE: "Tradingway", JP: null },
	/* Talan */
	"Aistan": { EN: "Aistan", FR: "Aistan", DE: "Aistan", JP: null },
	"Smydhaemr": { EN: "Smydhaemr", FR: "Smydhaemr", DE: "Smydhaemr", JP: null },
	"Ixali Vendor": { EN: "Ixali Vendor", FR: "Vendeur Ixali", DE: "Ixal-Händler", JP: null },
	"Gigima": { EN: "Gigima", FR: "Gigima", DE: "Gigima", JP: null },
	"Mahveydah": { EN: "Mahveydah", FR: "Mahveydah", DE: "Mahveydah", JP: null },
	"Siulmet": { EN: "Siulmet", FR: "Siulmet", DE: "Siulmet", JP: null },
	"Faezbroes": { EN: "Faezbroes", FR: "Faezbroes", DE: "Faezbroes", JP: null },
	//#endregion Shops

	//#region NPCs
	"T'mokkri": { EN: "T'mokkri", FR: "T'mokkri", DE: "T'mokkri", JP: "チャ・モクリ" },
	"K'leytai": { EN: "K'leytai", FR: "K'leytai", DE: "K'leytai", JP: "ク・レータイ" },
	"Eustace": { EN: "Eustace", FR: "Eustace", DE: "Eustace", JP: "ユースタス" },
	"Gontrant": { EN: "Gontrant", FR: "Gontrant", DE: "Gontrant", JP: "ゴントラン" },
	"Eidhart": { EN: "Eidhart", FR: "Eidhart", DE: "Eidhart", JP: "エイドハート准闘士" },
	"Rurubana": { EN: "Rurubana", FR: "Rurubana", DE: "Rurubana", JP: "ルルバナ" },
	"Voilinaut": { EN: "Voilinaut", FR: "Voilinaut", DE: "Voilinaut", JP: "ブワリノー" },
	"Lodille": { EN: "Lodille", FR: "Lodille", DE: "Lodille", JP: "ロディユ准牙士" },
	"Nedrick Ironheart": { EN: "Nedrick Ironheart", FR: "Nedrick Ironheart", DE: "Nedrick Ironheart", JP: "ネドリック・アイアンハート" },
	"Gerolt (North Shroud)": { EN: "Gerolt (North Shroud)", FR: "Gerolt (Forêt du Nord)", DE: "Gerolt (North Shroud)", JP: "ゲロルト (North Shroud)" },
	"Gerolt (Anemos)": { EN: "Gerolt (Anemos)", FR: "Gerolt (Anemos)", DE: "Gerolt (Anemos)", JP: "ゲロルト (Anemos)" },
	"Gerolt (Pagos)": { EN: "Gerolt (Pagos)", FR: "Gerolt (Pagos)", DE: "Gerolt (Pagos)", JP: "ゲロルト (Pagos)" },
	"Gerolt (Pyros)": { EN: "Gerolt (Pyros)", FR: "Gerolt (Pyros)", DE: "Gerolt (Pyros)", JP: "ゲロルト (Pyros)" },
	"Gerolt (Hydatos)": { EN: "Gerolt (Hydatos)", FR: "Gerolt (Hydatos)", DE: "Gerolt (Hydatos)", JP: "ゲロルト (Hydatos)" },
	"Gerolt (Gangos)": { EN: "Gerolt (Gangos)", FR: "Gerolt (Gangos)", DE: "Gerolt (Gangos)", JP: "ゲロルト (Gangos)" },
	"Gerolt (Radz-At-Han)": { EN: "Gerolt (Radz-At-Han)", FR: "Gerolt (Radz-At-Han)", DE: "Gerolt (Radz-At-Han)", JP: "ゲロルト (Radz-At-Han)" },
	"Jalzahn": { EN: "Jalzahn", FR: "Jalzahn", DE: "Jalzahn", JP: "ジャルザーン" },
	"G'jusana": { EN: "G'jusana", FR: "G'jusana", DE: "G'jusana", JP: "グ・ジュサナ" },
	"Hubairtin": { EN: "Hubairtin", FR: "Hubairtin", DE: "Hubairtin", JP: "ウベールタン" },
	"Papana": { EN: "Papana", FR: "Papana", DE: "Papana", JP: "パパナ" },
	"Guiding Star": { EN: "Guiding Star", FR: "Guiding Star", DE: "Leitender Stern", JP: "ガイディング・スター" },
	"Brangwine": { EN: "Brangwine", FR: "Brangwine", DE: "Brangwine", JP: "ブラングワイン" },
	"Adkin": { EN: "Adkin", FR: "Adkin", DE: "Adkin", JP: "アドキン" },
	"Remon": { EN: "Remon", FR: "Remon", DE: "Remon", JP: "レモン" },
	"Rowena": { EN: "Rowena", FR: "Rowena", DE: "Rowena", JP: "ロウェナ" },
	"Ardashir": { EN: "Ardashir", FR: "Ardashir", DE: "Ardashir", JP: "アルダシール" },
	"Processing Node": { EN: "Processing Node", FR: "Dispositif de transformation", DE: "Veredelungssystem", JP: null },
	"Jenomis": { EN: "Jenomis", FR: "Jenomis", DE: "Jenomis Cen Lexentale", JP: null },
	"Keiten": { EN: "Keiten", FR: "Keiten", DE: "Keiten", JP: "ケイテン" },
	"Marsak": { EN: "Marsak", FR: "Marsak", DE: "Marsak", JP: "マルシャーク" },
	"Ironworks Engineer (Rhalgr's Reach)": { EN: "Ironworks Engineer (Rhalgr's Reach)", FR: "Employée des Forges (L'Etendue de Rhalgr)", DE: "Technikerin der Garlond-Metallwerke", JP: null },
	"Zlatan": { EN: "Zlatan", FR: "Zlatan", DE: "Zlatan", JP: "ズラタン" },
	"Alphinaud": { EN: "Alphinaud", FR: "Alphinaud", DE: "Alphinaud", JP: "アルフィノ" },
	"Stressed Soldier": { EN: "Stressed Soldier", FR: "Resistant inquiet", DE: "Besorgter Kämpfer", JP: null },
	"Bajsaljen": { EN: "Bajsaljen", FR: "Bajsaljen", DE: "Bajsaljen", JP: "バイシャーエン" },
	"Hildibrand (Mare Lamentorum)": { EN: "Hildibrand (Mare Lamentorum)", FR: "Hildibrand (Mare Lamentorum)", DE: "Hildibrand (Mare Lamentorum)", JP: null },
	"Hildibrand (Thavnair)": { EN: "Hildibrand (Thavnair)", FR: "Hildibrand (Thavnair)", DE: "Hildibrand (Thavnair)", JP: null },
	"House Manderville Manservant": { EN: "House Manderville Manservant", FR: "Vendeuse des Manderville", DE: "Diener der Mandervilles", JP: null },
	"House Manderville Artisan": { EN: "House Manderville Artisan", FR: "Artisan des Manderville", DE: "Handwerker der Mandervilles", JP: null },
	"Skysteel Engineer": { EN: "Skysteel Engineer", FR: "Ingénieur de la manufacture", DE: "Himmelsstahl-Ingenieur", JP: null },
	/* Denys */
	"Neillemard": { EN: "Neillemard", FR: "Neillemard", DE: "Neillemard", JP: "ネイユマル" },
	"Nimie": { EN: "Nimie", FR: "Nimie", DE: "Nimie", JP: "ニミ" },
	"Emeny": { EN: "Emeny", FR: "Emeny", DE: "Emeny", JP: "エメニー" },
	"Mowen": { EN: "Mowen", FR: "Mowen", DE: "Mowen", JP: null },
	"Katliss": { EN: "Katliss", FR: "Katliss", DE: "Katliss", JP: null },
	"Chora-Zoi": { EN: "Chora-Zoi", FR: "Chora-Zoi", DE: "Chora-Zoi", JP: null },
	"Quinnana": { EN: "Quinnana", FR: "Quinnana", DE: "Quinnana", JP: null },
	/* Talan */
	//#endregion NPCs

	//#region Trials
	"A Relic Reborn: The Chimera": { EN: "A Relic Reborn: The Chimera", FR: "La chimère dhorme du Coerthas", DE: "Kampf gegen die Dhorma-Chimära", JP: "ドルムキマイラ討伐戦" },
	"A Relic Reborn: The Hydra": { EN: "A Relic Reborn: The Hydra", FR: "L'hydre d'Halatali", DE: "Kampf gegen die Hydra", JP: "ハイドラ討伐戦" },
	"The Bowl of Embers": { EN: "The Bowl of Embers", FR: "Le Cratère des tisons", DE: "Das Grab der Lohe", JP: "イフリート討伐戦" },
	"The Bowl of Embers (Hard)": { EN: "The Bowl of Embers (Hard)", FR: "Le Cratère des tisons (brutal)", DE: "Götterdämmerung - Ifrit", JP: "真イフリート討滅戦" },
	"The Howling Eye (Hard)": { EN: "The Howling Eye (Hard)", FR: "Hurlœil (brutal)", DE: "Götterdämmerung - Garuda", JP: "真ガルーダ討滅戦" },
	"The Navel (Hard)": { EN: "The Navel (Hard)", FR: "Le Nombril (brutal)", DE: "Götterdämmerung - Titan", JP: "真タイタン討滅戦" },
	"Thornmarch (Hard)": { EN: "Thornmarch (Hard)", FR: "La Lisière de ronces (brutal)", DE: "Königliche Konfrontation (schwer)", JP: "善王モグル・モグXII世討滅戦" },
	"The Whorleater (Hard)": { EN: "The Whorleater (Hard)", FR: "Le Briseur des marées (brutal)", DE: "Götterdämmerung - Leviathan", JP: "真リヴァイアサン討滅戦" },
	"The Striking Tree (Hard)": { EN: "The Striking Tree (Hard)", FR: "L'Arbre du jugement (brutal)", DE: "Götterdämmerung - Ramuh", JP: "真ラムウ討滅戦" },
	"Akh Afah Amphitheatre (Hard)": { EN: "Akh Afah Amphitheatre (Hard)", FR: "L'Amphithéâtre d'Akh Afah (brutal)", DE: "Götterdämmerung - Shiva", JP: "真シヴァ討滅戦" },
	"Thok ast Thok (Hard)": { EN: "Thok ast Thok (Hard)", FR: "Thok ast Thok (brutal)", DE: "Götterdämmerung - Ravana", JP: "極ラーヴァナ討滅戦" },
	"The Limitless Blue (Hard)": { EN: "The Limitless Blue (Hard)", FR: "L'Immensité bleue (brutal)", DE: "Götterdämmerung - Bismarck", JP: "極ビスマルク討滅戦" },
	"Containment Bay S1T7": { EN: "Containment Bay S1T7", FR: "Unité de contention S1P7", DE: "Götterdämmerung - Sephirot", JP: "コンテイメントベイS1T7" },
	"Containment Bay P1T6": { EN: "Containment Bay P1T6", FR: "Unité de contention P1P6", DE: "Götterdämmerung - Sophia", JP: "コンテイメントベイP1T6" },
	"Containment Bay Z1T9": { EN: "Containment Bay Z1T9", FR: "Unité de contention Z1P9", DE: "Götterdämmerung - Zurvan", JP: "コンテイメントベイZ1T9" },
	//#endregion Trials

	//#region Dungeons
	"The Tam-Tara Deepcroft": { EN: "The Tam-Tara Deepcroft", FR: "Hypogée de Tam-Tara", DE: "Totenacker Tam-Tara", JP: "タムタラの墓所" },
	"Tam-Tara Deepcroft (Hard)": { EN: "Tam-Tara Deepcroft (Hard)", FR: "Hypogée de Tam-Tara (brutal)", DE: "Totenacker Tam-Tara (schwer)", JP: "タムタラの墓所 (Hard)" },
	"The Stone Vigil": { EN: "The Stone Vigil", FR: "Vigile de Pierre", DE: "Steinerne Wacht", JP: "ストーンヴィジル" },
	"The Stone Vigil (Hard)": { EN: "The Stone Vigil (Hard)", FR: "Vigile de Pierre (brutal)", DE: "Steinerne Wacht (schwer)", JP: "ストーンヴィジル (Hard)" },
	"Sastasha": { EN: "Sastasha", FR: "Sastasha", DE: "Sastasha-Höhle", JP: "サスタシャ浸食洞" },
	"Sastasha (Hard)": { EN: "Sastasha (Hard)", FR: "Sastasha (brutal)", DE: "Sastasha-Höhle (schwer)", JP: "サスタシャ浸食洞 (Hard)" },
	"The Lost City of Amdapor": { EN: "The Lost City of Amdapor", FR: "Vestiges de la cité d'Amdapor", DE: "Historisches Amdapor", JP: "古アムダプール市街" },
	"The Lost City of Amdapor (Hard)": { EN: "The Lost City of Amdapor (Hard)", FR: "Vestiges de la cité d'Amdapor (brutal)", DE: "Historisches Amdapor (schwer)", JP: "古アムダプール市街 (Hard)" },
	"Brayflox's Longstop": { EN: "Brayflox's Longstop", FR: "Bivouac de Brayflox", DE: "Brüllvolx' Langrast", JP: "ブレイフロクスの野営地" },
	"Brayflox's Longstop (Hard)": { EN: "Brayflox's Longstop (Hard)", FR: "Bivouac de Brayflox (brutal)", DE: "Brüllvolx' Langrast (schwer)", JP: "ブレイフロクスの野営地 (Hard)" },
	"The Wanderer's Palace": { EN: "The Wanderer's Palace", FR: " Palais du Vagabond", DE: "Palast des Wanderers", JP: "ワンダラーパレス" },
	"The Wanderer's Palace (Hard)": { EN: "The Wanderer's Palace (Hard)", FR: "Palais du Vagabond (brutal)", DE: "Palast des Wanderers (schwer)", JP: "ワンダラーパレス (Hard)" },
	"Copperbell Mines": { EN: "Copperbell Mines", FR: "Mines de Clochecuivre", DE: "Kupferglocken-Mine", JP: "カッパーベル銅山" },
	"Copperbell Mines (Hard)": { EN: "Copperbell Mines (Hard)", FR: "Mines de Clochecuivre (brutal)", DE: "Kupferglocken-Mine (schwer)", JP: "カッパーベル銅山 (Hard)" },
	"The Sunken Temple of Qarn": { EN: "The Sunken Temple of Qarn", FR: "Temple enseveli de Qarn", DE: "Versunkener Tempel von Qarn", JP: "カルン埋没寺院" },
	"The Sunken Temple of Qarn (Hard)": { EN: "The Sunken Temple of Qarn (Hard)", FR: "Temple enseveli de Qarn (brutal)", DE: "Versunkener Tempel von Qarn (schwer)", JP: "カルン埋没寺院 (Hard)" },
	"Haukke Manor": { EN: "Haukke Manor", FR: "Manoir des Haukke", DE: "Haukke-Herrenhaus", JP: "ハウケタ御用邸" },
	"Haukke Manor (Hard)": { EN: "Haukke Manor (Hard)", FR: "Manoir des Haukke (brutal)", DE: "Haukke-Herrenhaus (schwer)", JP: "ハウケタ御用邸 (Hard)" },
	"Halatali": { EN: "Halatali", FR: "Halatali", DE: "Halatali", JP: "ハラタリ修練所" },
	"Halatali (Hard)": { EN: "Halatali (Hard)", FR: "Halatali (brutal)", DE: "Halatali (schwer)", JP: "ハラタリ修練所 (Hard)" },
	"Dzemael Darkhold": { EN: "Dzemael Darkhold", FR: "Forteresse de Dzemael", DE: "Feste Dzemael", JP: "ゼーメル要塞" },
	"The Thousand Maws of Toto-Rak": { EN: "The Thousand Maws of Toto-Rak", FR: "Mille Gueules de Toto-Rak", DE: "Tausend Löcher von Toto-Rak", JP: "トトラクの千獄" },
	"Amdapor Keep": { EN: "Amdapor Keep", FR: "Château d'Amdapor", DE: "Ruinen von Amdapor", JP: "古城アムダプール" },
	"Amdapor Keep (Hard)": { EN: "Amdapor Keep (Hard)", FR: "Château d'Amdapor (brutal)", DE: "Die Ruinen von Amdapor (schwer)", JP: "古城アムダプール (Hard)" },
	"Cutter's Cry": { EN: "Cutter's Cry", FR: "Gouffre hurlant", DE: "Sägerschrei", JP: "カッターズクライ" },
	"Pharos Sirius": { EN: "Pharos Sirius", FR: "Phare de Sirius", DE: "Pharos Sirius", JP: "シリウス大灯台" },
	"The Aurum Vale": { EN: "The Aurum Vale", FR: "Val d'Aurum", DE: "Goldklamm", JP: "オーラムヴェイル" },
	"Snowcloak": { EN: "Snowcloak", FR: "Manteneige", DE: "Schneekleid", JP: "スノークローク大氷壁" },
	"Hullbreaker Isle": { EN: "Hullbreaker Isle", FR: "Île de Crèvecarène", DE: "Schiffbrecher-Insel", JP: "ハルブレーカー・アイル" },
	"The Keeper of the Lake": { EN: "The Keeper of the Lake", FR: "Guardien du lac", DE: "Der Hüter des Sees", JP: "黙約の塔" },
	"The Dusk Vigil": { EN: "The Dusk Vigil", FR: "Vigile du Crépuscule", DE: "Abendrot-Wacht", JP: "ダスクヴィジル" },
	"Sohm Al": { EN: "Sohm Al", FR: "Sohm Al", DE: "Sohm Al", JP: "ソーム・アル山麓" },
	"Sohm Al (Hard)": { EN: "Sohm Al (Hard)", FR: "Sohm Al (brutal)", DE: "Sohm Al (schwer)", JP: "ソーム・アル山麓 (Hard)" },
	"The Aery": { EN: "The Aery", FR: "L'Aire", DE: "Nest des Drachen", JP: "ドラゴンズエアリー" },
	"The Vault": { EN: "The Vault", FR: "La Voûte", DE: "Erzbasilika", JP: "イシュガルド教皇庁" },
	"The Great Gubal Library (Hard)": { EN: "The Great Gubal Library (Hard)", FR: "Grande bibliothèque de Gubal (brutal)", DE: "Große Gubal-Bibliothek (schwer)", JP: "グブラ幻想図書館 (Hard)" },
	"Castrum Meridianum": { EN: "Castrum Meridianum", FR: "Castrum Meridianum", DE: "Castrum Meridianum", JP: "カストルム・メリディアヌム" },
	"The Praetorium": { EN: "The Praetorium", FR: "Praetorium", DE: "Praetorium", JP: "魔導城プラエトリウム" },
	//#endregion Dungeons

	//#region Raids
	"Binding Coil of Bahamut": { EN: "Binding Coil of Bahamut", FR: "Le Labyrinthe de Bahamut", DE: "Verschlungene Schatten", JP: "大迷宮バハムート" },
	"The Labyrinth of the Ancients": { EN: "The Labyrinth of the Ancients", FR: "La Tour de Cristal - Dédale antique", DE: "Kristallturm - Das Labyrinth der Alten", JP: "古代の民の迷宮" },
	"Syrcus Tower": { EN: "Syrcus Tower", FR: "La Tour de Cristal - Tour de Syrcus", DE: "Kristallturm - Der Syrcus-Turm", JP: "シルクスの塔" },
	"The World of Darkness": { EN: "The World of Darkness", FR: "La Tour de Cristal - Monde des Ténèbres", DE: "Die Welt der Dunkelheit", JP: "闇の世界" },
	"The Void Ark": { EN: "The Void Ark", FR: "L'Arche du néanta", DE: "Die Nichts-Arche", JP: "魔航船ヴォイドアーク" },
	"The Weeping City of Mhach": { EN: "The Weeping City of Mhach", FR: "La Cité défendue de Mhach", DE: "Die Stadt der Tränen", JP: "禁忌都市マハ" },
	"Dun Scaith": { EN: "Dun Scaith", FR: "Dun Scaith", DE: "Dun Scaith", JP: "影の国ダン・スカー" },
	"The Royal City of Rabanastre": { EN: "The Royal City of Rabanastre", FR: "La Cité royale de Rabanastre", DE: "Die königliche Stadt Rabanastre", JP: "失われた都ラバナスタ" },
	"The Ridorana Lighthouse": { EN: "The Ridorana Lighthouse", FR: "Le Phare de Ridorana", DE: "Richtfeuer von Ridorana", JP: "封じられた聖塔リドルアナ" },
	"The Orbonne Monastery": { EN: "The Orbonne Monastery", FR: "Le Monastère d'Orbonne", DE: "Kloster von Orbonne", JP: "楽欲の僧院オーボンヌ" },
	"Castrum Lacus Litore": { EN: "Castrum Lacus Litore", FR: "Castrum Lacus Litore", DE: "Castrum Lacus Litore", JP: "カストルム・ラクスリトレ" },
	"Delubrum Reginae": { EN: "Delubrum Reginae", FR: "Delubrum Reginae", DE: "Delubrum Reginae", JP: "グンヒルド・ディルーブラム" },
	"The Dalriada": { EN: "The Dalriada", FR: "Le Dalriada", DE: "Die Dalriada", JP: "旗艦ダル・リアータ" },
	"The Fist of the Father (A1)": { EN: "The Fist of the Father (A1)", FR: "Alexander - Le Poing du Père", DE: "Alexander - Faust des Vaters", JP: "起動編1" },
	"The Cuff of the Father (A2)": { EN: "The Cuff of the Father (A2)", FR: "Alexander - Le Poignet du Père", DE: "Alexander - Elle des Vaters", JP: "起動編2" },
	"The Arm of the Father (A3)": { EN: "The Arm of the Father (A3)", FR: "Alexander - Le Bras du Père", DE: "Alexander - Arm des Vaters", JP: "起動編3" },
	"The Burden of the Father (A4)": { EN: "The Burden of the Father (A4)", FR: "Alexander - Le Fardeau du Père", DE: "Alexander - Last des Vaters", JP: "起動編4" },
	"The Fist of the Son (A5)": { EN: "The Fist of the Son (A5)", FR: "Alexander - Le poing du Fils", DE: "Alexander - Faust des Sohnes", JP: "律動編1" },
	"The Cuff of the Son (A6)": { EN: "The Cuff of the Son (A6)", FR: "Alexander - Le Poignet du Fils", DE: "Alexander - Elle des Sohnes", JP: "律動編2" },
	"The Arm of the Son (A7)": { EN: "The Arm of the Son (A7)", FR: "Alexander - Le Bras du Fils", DE: "Alexander - Arm des Sohnes", JP: "律動編3" },
	"The Burden of the Son (A8)": { EN: "The Burden of the Son (A8)", FR: "Alexander - Le Fardeau du Fils", DE: "Alexander - Last des Sohnes", JP: "律動編4" },
	"The Eyes of the Creator (A9)": { EN: "The Eyes of the Creator (A9)", FR: "Alexander - Les Yeux du Créateur", DE: "Alexander - Augen des Schöpfers", JP: "天動編1" },
	"The Breath of the Creator (A10)": { EN: "The Breath of the Creator (A10)", FR: "Alexander - Le Souffle du Créateur", DE: "Alexander - Atem des Schöpfers", JP: "天動編2" },
	"The Heart of the Creator (A11)": { EN: "The Heart of the Creator (A11)", FR: "Alexander - Le Cœur du Créateur", DE: "Alexander - Herz des Schöpfers", JP: "天動編3" },
	"The Soul of the Creator (A12)": { EN: "The Soul of the Creator (A12)", FR: "Alexander - L'Âme du Créateur", DE: "Alexander - Seele des Schöpfers", JP: "天動編4" },
	"Deltascape V1.0 (O1)": { EN: "Deltascape V1.0 (O1)", FR: "Deltasctice v1.0", DE: "Deltametrie 1.0", JP: "デルタ編 第1層" },
	"Deltascape V2.0 (O2)": { EN: "Deltascape V2.0 (O2)", FR: "Deltasctice v2.0", DE: "Deltametrie 2.0", JP: "デルタ編 第2層" },
	"Deltascape V3.0 (O3)": { EN: "Deltascape V3.0 (O3)", FR: "Deltasctice v3.0", DE: "Deltametrie 3.0", JP: "デルタ編 第3層" },
	"Deltascape V4.0 (O4)": { EN: "Deltascape V4.0 (O4)", FR: "Deltasctice v4.0", DE: "Deltametrie 4.0", JP: "デルタ編 第4層" },
	"Sigmascape V1.0 (O5)": { EN: "Sigmascape V1.0 (O5)", FR: "Sigmastice v1.0", DE: "Sigmametrie 1.0", JP: "シグマ編 第1層" },
	"Sigmascape V2.0 (O6)": { EN: "Sigmascape V2.0 (O6)", FR: "Sigmastice v2.0", DE: "Sigmametrie 2.0", JP: "シグマ編 第2層" },
	"Sigmascape V3.0 (O7)": { EN: "Sigmascape V3.0 (O7)", FR: "Sigmastice v3.0", DE: "Sigmametrie 3.0", JP: "シグマ編 第3層" },
	"Sigmascape V4.0 (O8)": { EN: "Sigmascape V4.0 (O8)", FR: "Sigmastice v4.0", DE: "Sigmametrie 4.0", JP: "シグマ編 第4層" },
	"Alphascape V1.0 (O9)": { EN: "Alphascape V1.0 (O9)", FR: "Alphastice v1.0", DE: "Alphametrie 1.0", JP: "アルファ編 第1層" },
	"Alphascape V2.0 (O10)": { EN: "Alphascape V2.0 (O10)", FR: "Alphastice v2.0", DE: "Alphametrie 2.0", JP: "アルファ編 第2層" },
	"Alphascape V3.0 (O11)": { EN: "Alphascape V3.0 (O11)", FR: "Alphastice v3.0", DE: "Alphametrie 3.0", JP: "アルファ編 第3層" },
	"Alphascape V4.0 (O12)": { EN: "Alphascape V4.0 (O12)", FR: "Alphastice v4.0", DE: "Alphametrie 4.0", JP: "アルファ編 第4層" },
	"Eden's Gate: Resurrection (E1)": { EN: "Eden's Gate: Resurrection (E1)", FR: "L'Eveil d'Eden - Résurrection", DE: "Edens Erwachen - Auferstehung", JP: "希望の園エデン: 覚醒編1層" },
	"Eden's Gate: Descent (E2)": { EN: "Eden's Gate: Descent (E2)", FR: "L'Eveil d'Eden - Descente", DE: "Edens Erwachen - Niederkunft", JP: "希望の園エデン: 覚醒編2層" },
	"Eden's Gate: Inundation (E3)": { EN: "Eden's Gate: Inundation (E3)", FR: "L'Eveil d'Eden - Déluge", DE: "Edens Erwachen - Überflutung", JP: "希望の園エデン: 覚醒編3層" },
	"Eden's Gate: Sepulture (E4)": { EN: "Eden's Gate: Sepulture (E4)", FR: "L'Eveil d'Eden - Inhumation", DE: "Edens Erwachen - Beerdigung", JP: "希望の園エデン: 覚醒編4層" },
	"Eden's Verse: Fulmination (E5)": { EN: "Eden's Verse: Fulmination (E5)", FR: "Les Accord d'Eden - Fulmination", DE: "Edens Resonanz - Entladung", JP: "希望の園エデン: 共鳴編 第1層" },
	"Eden's Verse: Furor (E6)": { EN: "Eden's Verse: Furor (E6)", FR: "Les Accord d'Eden - Fureur", DE: "Edens Resonanz - Raserei", JP: "希望の園エデン: 共鳴編 第2層" },
	"Eden's Verse: Iconoclasm (E7)": { EN: "Eden's Verse: Iconoclasm (E7)", FR: "Les Accord d'Eden - Iconoclasme", DE: "Edens Resonanz - Bildersturm", JP: "希望の園エデン: 共鳴編 第3層" },
	"Eden's Verse: Refulgence (E8)": { EN: "Eden's Verse: Refulgence (E8)", FR: "Les Accord d'Eden - Eclat", DE: "Edens Resonanz - Erstarrung", JP: "希望の園エデン: 共鳴編 第4層" },
	"Eden's Promise: Umbra (E9)": { EN: "Eden's Promise: Umbra (E9)", FR: "La Promesse d'Eden - Nuée", DE: "Edens Verheißung - Ûmbra", JP: "希望の園エデン: 再生編１" },
	"Eden's Promise: Litany (E10)": { EN: "Eden's Promise: Litany (E10)", FR: "La Promesse d'Eden - Litanie", DE: "Edens Verheißung - Litanei", JP: "希望の園エデン: 再生編2" },
	"Eden's Promise: Anamorphosis (E11)": { EN: "Eden's Promise: Anamorphosis (E11)", FR: "La Promesse d'Eden - Anarmophose", DE: "Edens Verheißung - Anamorphose", JP: "希望の園エデン: 再生編3" },
	"Eden's Promise: Eternity (E12)": { EN: "Eden's Promise: Eternity (E12)", FR: "La Promesse d'Eden - Eternité", DE: "Edens Verheißung - Ewigkeit", JP: "希望の園エデン: 再生編4" },
	//#endregion Raids

	//#region FATEs
	"The Enmity of My Enemy": { EN: "The Enmity of My Enemy", FR: "Subjugués ingrats", DE: "Mehr ist nicht genug", JP: "続ラークスコール遭遇戦" },
	"Giant Seps": { EN: "Giant Seps", FR: "Défi: le dernier jour de Seps", DE: "Schuppe um Schuppe", JP: "騎兵の天敵「セプス」" },
	"Make it Rain": { EN: "Make it Rain", FR: "Défi : peur de l'orage ?", DE: "Auf Wolkenbruch raus", JP: null },
	"Breaching South Tidegate": { EN: "Breaching South Tidegate", FR: "Percée du barrage du sud", DE: "Schlacht am Süddamm", JP: "南防波壁の戦い：本隊強襲" },
	"Air Supply": { EN: "Air Supply", FR: "Raid sur le site de déboisement ixal", DE: "Illegaler Holzexport", JP: "イクサル軍伐採所強襲作戦" },
	"Heroes of the 2nd": { EN: "Heroes of the 2nd", FR: "Protéger ses arrières", DE: "Hauptbrigadier für einen Tag", JP: "我ら第二戦闘大隊" },
	"Another Notch on the Torch": { EN: "Another Notch on the Torch", FR: "Défi: Porus et les 1000 chasseurs", DE: "Nichts geht mehr", JP: "残忍なる悪鬼「ポリュス」" },
	"Everything's Better": { EN: "Everything's Better", FR: "Flapi chapeau", DE: "Ende der Pilzsaison", JP: "とてとて楽しいキノコ狩り" },
	"Return to Cinder": { EN: "Return to Cinder", FR: "Défi: sombre futur pour le voyant", DE: "Schwarze Zukunft", JP: "運命の代弁者「黒煙のペグジ・チャー」" },
	"Bellyfull": { EN: "Bellyfull", FR: "Défi : dans la gamelle de Gargamelle", DE: "Schluss mit Gargamelle", JP: null },
	"The King's Justice": { EN: "The King's Justice", FR: "Défi: mante à l'eau", DE: "Verhängnisvolle Fracht", JP: "異国の魔蟲「マンティスキング」" },
	"Quartz Coupling": { EN: "Quartz Coupling", FR: "Quitte ou doblyn", DE: "Dem Quarz zuliebe", JP: "ワサワサドブラン" },
	"Black and Nburu": { EN: "Black and Nburu", FR: "Défi: sonner le Glas pour Nburu", DE: "Nburus Bitterkeit", JP: "湿林の怨念「ンブル」" },
	"Breaking Dawn": { EN: "Breaking Dawn", FR: "Défi: Daxio le chimiste", DE: "Gar nicht so harmlos", JP: "幽谷の薬師「覚醒のダキシオ」" },
	"Breaching North Tidegate": { EN: "Breaching North Tidegate", FR: "Percée du barrage du nord", DE: "Schlacht am Norddamm", JP: "北防波壁の戦い：本隊強襲" },
	"The Ceruleum Road": { EN: "The Ceruleum Road", FR: "La route du céruleum", DE: "Ehrenwache", JP: "セルリウムロード" },
	"The Four Winds": { EN: "The Four Winds", FR: "Défi: les quatre sœurs du malheur", DE: "Wilde Schwestern", JP: "烈風の勇士「フェロック四姉妹」" },
	"Rude Awakening": { EN: "Rude Awakening", FR: "Défi: les morts en marche", DE: "Skandal im Sperrgebiet", JP: "遺跡の亡霊騎士「ダイダロス」" },
	"Good to Be Bud": { EN: "Good to Be Bud", FR: "Trop de morbols tuent les morbols", DE: "Ausgekeimt", JP: "モルボル怒りの大増殖" },
	"Surprise": { EN: "Surprise", FR: "Escorte pour le Guet", DE: "Schutz und Hilfe", JP: "輸送部隊防衛命令" },
	"In Spite of It All": { EN: "In Spite of It All", FR: "Défi: le plus Revanchard des deux", DE: "Schadenfröhlich", JP: "陰険なる魔物「スパイトフル」" },
	"What Gored Before": { EN: "What Gored Before", FR: "Défi: Phaïa pas l'inviter", DE: "Grimmiges Beißendes", JP: "最後の闘猪王「パイア」" },
	"Taken": { EN: "Taken", FR: "Que décline son armée", DE: "Geflüchtet und entführt", JP: "友と、家族と" },
	"Tower of Power": { EN: "Tower of Power", FR: "Un giga problème", DE: "Der Wille von Haillenarte", JP: "アインハルトの意地" },
	"The Big Bagoly Theory": { EN: "The Big Bagoly Theory", FR: "Défi: se forger des chimères", DE: "Großer Badu, große Gefahr", JP: "醜悪なる合成獣「バドゥ」" },
	"Schism": { EN: "Schism", FR: "Défi: un Kobold sachant forer...", DE: "Gras grüner, Gestein größer", JP: "試掘地強襲作戦" },
	"The Taste of Fear": { EN: "The Taste of Fear", FR: "Défi: se battre Sobek et ongles", DE: "Späte Vergeltung", JP: "雪山の襲撃者「セベク」" },
	"Wail in the Willows": { EN: "Wail in the Willows", FR: "Défi: le démon des tempêtes", DE: "Ein windiger Kerl", JP: "暴風の魔王「パズズ」" },
	"Louhi on Ice": { EN: "Louhi on Ice", FR: "Défi: lames glacées", DE: "Eisige Klingen", JP: "蒼き氷刃「ロウヒ」" },
	"Lost Epic": { EN: "Lost Epic", FR: "Défi: papillons de la reine", DE: "Penthesilea, Königin der Flammenfeen", JP: "炎蝶の女王「ペンテシレイア」" },
	"Crystalline Provenance": { EN: "Crystalline Provenance", FR: "Défi: Provenance indéfinie", DE: "Der Kristallwächter", JP: "水晶の龍「プロヴェナンス・ウォッチャー」" },
	//#endregion FATEs

	//#region Leves
	"The Awry Salvages": { EN: "The Awry Salvages", FR: "Protection: sauve qui peut", DE: "Wertvolle Dokumente", JP: "防衛指令：アグリウスの研究任務" },
	"Necrologos: Pale Oblation": { EN: "Necrologos: Pale Oblation", FR: "Nécrologos: contrôle à distance", DE: "Verschwundene Seiten", JP: "焚書任務：禁書「異界の炎を宿せし者」の回収" },
	"An Imp Mobile": { EN: "An Imp Mobile", FR: "Pourchasse: les blagues les plus courtes sont les meilleures", DE: "Der weite Weg", JP: "索敵指令：クルザス潜伏中の手配犯" },
	"The Museum Is Closed": { EN: "The Museum Is Closed", FR: "Protection: apprendre à respecter le passé", DE: "Wie Monster im Porzellanladen", JP: "防衛指令：古代アラグの遺物" },
	"Yellow Is the New Black": { EN: "Yellow Is the New Black", FR: "Interception: le jaune, c'est tendance", DE: "Kein Grund zur Besorgnis", JP: "迎撃指令：前哨地を脅かす魔物" },
	"Don't Forget to Cry": { EN: "Don't Forget to Cry", FR: "Interception: sus au démon", DE: "Streckensicherung", JP: "要撃任務：邪眼のバロール" },
	"One Big Problem Solved": { EN: "One Big Problem Solved", FR: "Interception: un gros problème de résolu", DE: "Keine Gnade!", JP: "迎撃指令：調査地の脅威ギガース族" },
	"If you Put It That Way": { EN: "If you Put It That Way", FR: "Execution : le péril draconique", DE: "Steckbrief: Frost-Avis", JP: null },
	"Circling the Ceruleum": { EN: "Circling the Ceruleum", FR: "Patrouille: un point stratégique", DE: "Patrouillengang", JP: "巡回任務：補給路の安全確保" },
	"Go Home to Mama": { EN: "Go Home to Mama", FR: "Protection: sus aux intrus", DE: "Resteverwertung", JP: "防衛指令：魔導機器の残骸" },
	"Necrologos: Whispers of the Gem": { EN: "Necrologos: Whispers of the Gem", FR: "Nécrologos: dans l'antre des bêtes", DE: "Keine Manieren", JP: "焚書任務：禁書「闇に羽ばたく者」の回収" },
	"Get Off Our Lake": { EN: "Get Off Our Lake", FR: "Interception : des troupes un peu trop curieuses", DE: "Hauptsache dazwischenfunken!", JP: null },
	"The Area's a Bit Sketchy": { EN: "The Area's a Bit Sketchy", FR: "Patrouille: sécurité routière", DE: "Spiel auf Leben und Tod", JP: "巡回任務：ホワイトブリムの街道巡回" },
	"Someone's in the Doghouse": { EN: "Someone's in the Doghouse", FR: "Escorte: un chien fidèle", DE: "Bei Fuß!", JP: "誘導任務：行方知れずの番犬候補" },
	"Subduing the Subprime ": { EN: "Subduing the Subprime ", FR: "Extermination : l'habit ne fait pas le moine", DE: "Zu billig geht nicht", JP: "討伐任務：犯罪結社「バート商会」" },
	"Got a Gut Feeling about This": { EN: "Got a Gut Feeling about This", FR: "Interception: la chasse aux taurus", DE: "Komisches Gefühl im Bauch", JP: "要撃任務：徘徊するステゴタウルス" },
	"Who Writes History ": { EN: "Who Writes History ", FR: "Exécution : les vainqueurs écrivent l'histoire", DE: "Steckbrief: Okeanos der Rote", JP: "誅殺指令：紅髪のオケアノス" },
	"Big, Bad Idea": { EN: "Big, Bad Idea", FR: "Interception: la stèle d'invocation", DE: "Versklavt", JP: "要撃任務：ハパリット奴隷末裔説の検証" },
	"Someone's Got a Big Mouth": { EN: "Someone's Got a Big Mouth", FR: "Exécution: quelqu'un a une grande gueule", DE: "Gigantische Probleme", JP: "誅殺指令：怒号のミマス" },
	"Put Your Stomp On It": { EN: "Put Your Stomp On It", FR: "Patrouille : gigantesques prédateurs", DE: "Räuberische Begas", JP: null },
	"The Bloodhounds of Coerthas": { EN: "The Bloodhounds of Coerthas", FR: "Protection: suivre la piste", DE: "Leicht kaputtbares Erbstück", JP: "防衛指令：追撃者たちの遺品" },
	"No Big Whoop": { EN: "No Big Whoop", FR: "Pourchasse: ci-gisent les gigas", DE: "Licht auslöschen", JP: "索敵指令：調査員を惑わす悪霊" },
	"Necrologos: The Liminal Ones": { EN: "Necrologos: The Liminal Ones", FR: "Nécrologos: de malheureuses fouilles", DE: "Wer suchet, der findet", JP: "焚書任務：禁書「噛み砕きし者」の回収" },
	"I'd Rather Be Digging": { EN: "I'd Rather Be Digging", FR: "Livraison: tanner pour mieux creuser", DE: "Frohes Putzen", JP: "調達依頼：発掘作業用の革包丁" },
	"I Maul Right": { EN: "I Maul Right", FR: "Grosse commande: maillets à tout faire", DE: "Äther gegen Hammer", JP: "大口依頼：クリスタル砕岩用のモール" },
	"No Refunds, Only Exchanges": { EN: "No Refunds, Only Exchanges", FR: "Fabrication: limes défectueuses", DE: "Feine Feilen", JP: "製作依頼：熟練冒険者向けの金属ヤスリ" },
	"Pop That Top": { EN: "Pop That Top", FR: "Fabrication: boîtes à reliques", DE: "Kräftige Schläge", JP: "製作依頼：クリスタル除去用の工具" },
	"Talon Terrors": { EN: "Talon Terrors", FR: "Fabrication: terreur griffue", DE: "Zelte aufbauen", JP: "製作依頼：発掘調査隊向けの大工道具" },
	"You Stay on That Side": { EN: "You Stay on That Side", FR: "Grosse commande: pâturages en tenailles", DE: "Zangenbrecher", JP: "大口依頼：クルザス向けのプライヤー" },
	"A Matter of Vital Importance": { EN: "A Matter of Vital Importance", FR: "Grosse commande: essence de vitalité virile", DE: "Trendsetter", JP: "大口依頼：富裕層向け活力薬" },
	"Make Up Your Mind or Else": { EN: "Make Up Your Mind or Else", FR: "Fabrication: le doute et la bête", DE: "Köder auslegen", JP: "製作依頼：罠用の劇毒薬" },
	"Not Taking No for an Answer": { EN: "Not Taking No for an Answer", FR: "Grosse commande: entrez sans crainte...", DE: "Doping", JP: "大口依頼：発掘作業員用の錬金薬" },
	"Quit Your Jib-jab": { EN: "Quit Your Jib-jab", FR: "Fabrication: de pierre en aiguille", DE: "Gegen Versteinerung", JP: "製作依頼：輸出用の金の針" },
	"Shut Up Already": { EN: "Shut Up Already", FR: "Fabrication: le silence est d'or", DE: "Somniloquie nervt", JP: "製作依頼：安眠を守る沈黙薬" },
	"Sleepless in Silvertear": { EN: "Sleepless in Silvertear", FR: "Livraison: insomnies à Pleurargent", DE: "Trink das, Monster!", JP: "調達依頼：護身用の睡眠薬" },
	"Fuss in Boots": { EN: "Fuss in Boots", FR: "Fabrication: bottes en cuir de raptor", DE: "Gefährdete Waden", JP: "製作依頼：丈の長いサイブーツ" },
	"Handle with Care": { EN: "Handle with Care", FR: "Grosse commande: la folie des cestes", DE: "Touristenfalle", JP: "大口依頼：伝説の拳聖セスタス" },
	"Hold On Tight": { EN: "Hold On Tight", FR: "Livraison: un chef bien entouré", DE: "Gourmet-Griffel", JP: "調達依頼：裏方に贈る革手袋" },
	"Spelling Me Softly": { EN: "Spelling Me Softly", FR: "Grosse commande: jamais sans mon cuir", DE: "Fundstücke", JP: "大口依頼：発掘品梱包用の革" },
	"Tenderfoot Moments": { EN: "Tenderfoot Moments", FR: "Fabrication: pieds tendres", DE: "Abgetragene Sohlen", JP: "製作依頼：発掘調査員用のモカシン" },
	"Too Hot to Handle": { EN: "Too Hot to Handle", FR: "Point d'auto-inflammation", DE: "Hitzige Selbstzerstörung", JP: "高熱自爆" },
	"Bow Down to Magic": { EN: "Bow Down to Magic", FR: "Fabrication: une arme efficace", DE: "Im hohen Bogen", JP: "製作依頼：報酬用の合成弓" },
	"Bowing to Greater Power": { EN: "Bowing to Greater Power", FR: "Fabrication: l'arc ou la vie", DE: "Bogenlos", JP: "製作依頼：傭兵向けの弓" },
	"Clogs of War": { EN: "Clogs of War", FR: "Fabrication: patins scéniques", DE: "Mahagoni-Oper", JP: "製作依頼：美女が残した木靴" },
	"Incant Now, Think Later": { EN: "Incant Now, Think Later", FR: "Grosse commande: lancer d'abord, réfléchir après", DE: "Alte Magie", JP: "大口依頼：古代魔法実験用の杖" },
	"Trident and Error": { EN: "Trident and Error", FR: "Livraison: pour se faire les tridents", DE: "Auf Granit gebissen", JP: "調達依頼：クリスタルの除去道具" },
	"You Do the Heavy Lifting": { EN: "You Do the Heavy Lifting", FR: "Grosse commande: bois pour meuble de luxe", DE: "Nur das Beste vom Besten", JP: "大口依頼：高級家具用の木材" },
	"Gold Is So Last Year": { EN: "Gold Is So Last Year", FR: "Fabrication: mélange rentable", DE: "Bernstein muss es sein", JP: "製作依頼：輸出用のサークレット" },
	"Green and Bear It": { EN: "Green and Bear It", FR: "Livraison: radicaux ras de cou", DE: "Des Rätsels Lösung", JP: "調達依頼：希望の首飾り" },
	"Light in the Darkness": { EN: "Light in the Darkness", FR: "Grosse commande: ras de cou simples", DE: "Zusammenhalt stiften", JP: "大口依頼：結束の証" },
	"Sew Not Doing This": { EN: "Sew Not Doing This", FR: "Fabrication: des aiguilles pour tous", DE: "Flicken, flicken, flicken", JP: "製作依頼：調査員向けの縫い針" },
	"Sweet Charity": { EN: "Sweet Charity", FR: "Fabrication: présent pour bienfaitrices", DE: "Von dir für dich", JP: "製作依頼：パトロンへの贈り物" },
	"The Big Red": { EN: "The Big Red", FR: "Grosse commande: le grand final du corail", DE: "Rote Korallen für die Oberschicht", JP: "大口依頼：紅珊瑚の首飾り" },
	//#endregion Leves

	//#region Grand Companies
	"The Order of the Twin Adder": { EN: "The Order of the Twin Adder", FR: "L'Ordre des Deux Vipères", DE: "Bruderschaft der Morgenviper", JP: "双蛇党" },
	"The Immortal Flames": { EN: "The Immortal Flames", FR: "Les Immortels", DE: "Legion der Unsterblichen", JP: "不滅隊" },
	"The Maelstrom": { EN: "The Maelstrom", FR: "Le Maelstrom", DE: "Mahlstrom", JP: "黒渦団" },
	//#endregion Grand Companies

	//#region Enemies
	"Zahar'ak Lancer": { EN: "Zahar'ak Lancer", FR: "Lancier de Zahar'ak", DE: "Zahar'ak-Pikenier", JP: null },
	"Zahar'ak Pugilist": { EN: "Zahar'ak Pugilist", FR: "Pugiliste de Zahar'ak", DE: "Zahar'ak-Faustkämpfer", JP: null },
	"Zahar'ak Thaumaturge": { EN: "Zahar'ak Thaumaturge", FR: "Occultiste de Zahar'ak", DE: "Zahar'ak-Thaumaturg", JP: null },
	"U'Ghamaro Quarryman": { EN: "U'Ghamaro Quarryman", FR: "Terrassier d'U'Ghamaro", DE: "U'Ghamaro-Steinschlepper", JP: null },
	"U'Ghamaro Bedesman": { EN: "U'Ghamaro Bedesman", FR: "Prieur d'U'Ghamaro", DE: "U'Ghamaro-Fürbeter", JP: null },
	"U'Ghamaro Priest": { EN: "U'Ghamaro Priest", FR: "Prêtre d'U'Ghamaro", DE: "U'Ghamaro-Priester", JP: "ウ・ガマロ・プリースト" },
	"U'Ghamaro Roundsman": { EN: "U'Ghamaro Roundsman", FR: "Livreur d'U'Ghamaro", DE: "U'Ghamaro-Laufbursche", JP: null },
	"Natalan Boldwing": { EN: "Natalan Boldwing", FR: "Aile-hardie de Natalan", DE: "Natalan-Mutschwinge", JP: null },
	"Natalan Fogcaller": { EN: "Natalan Fogcaller", FR: "Embrumeur de Natalan", DE: "Natalan-Nebelrufer", JP: null },
	"Natalan Windtalon": { EN: "Natalan Windtalon", FR: "Serres-vent de Natalan", DE: "Natalan-Windkralle", JP: null },
	"Zahar'ak Archer": { EN: "Zahar'ak Archer", FR: "Archer de Zahar'ak", DE: "Zahar'ak-Bogenschütze", JP: null },
	"Sapsa Shelfspine": { EN: "Sapsa Shelfspine", FR: "Épine-des-profondeurs de Sapsa", DE: "Sapsa-Panzerdorn", JP: null },
	"Sapsa Shelfclaw": { EN: "Sapsa Shelfclaw", FR: "Griffe-des-profondeurs de Sapsa", DE: "Sapsa-Panzerklaue", JP: null },
	"Sapsa Shelftooth": { EN: "Sapsa Shelftooth", FR: "Dent-des-profondeurs de Sapsa", DE: "Sapsa-Panzerzahn", JP: null },
	"Violet Sigh": { EN: "Violet Sigh", FR: "Soupireur violet", DE: "Violette Seufzerin", JP: null },
	"Violet Screech": { EN: "Violet Screech", FR: "Crieur violet", DE: "Violette Kreischerin", JP: "ヴァイオレット・スクリーチ" },
	"Violet Snarl": { EN: "Violet Snarl", FR: "Grondeur violet", DE: "Violette Keiferin", JP: null },
	"Daring Harrier": { EN: "Daring Harrier", FR: "Harceleur casse-cou", DE: "Waghalsiger Plünderer", JP: null },
	"5th Cohort Vanguard": { EN: "5th Cohort Vanguard", FR: "Avant-garde de la 5e cohorte", DE: "Frontbrecher der V. Kohorte", JP: null },
	"4th Cohort Hoplomachus": { EN: "4th Cohort Hoplomachus", FR: "Hoplomachus de la 4e cohorte", DE: "Hoplomachus der IV. Kohorte", JP: null },
	"Basilisk": { EN: "Basilisk", FR: "Basilic", DE: "Basilisk", JP: null },
	"Zanr'ak Pugilist": { EN: "Zanr'ak Pugilist", FR: "Pugiliste de Zanr'ak ", DE: "Zanr'ak-Faustkämpfer", JP: null },
	"Milkroot Cluster": { EN: "Milkroot Cluster", FR: "Grappe de tuberculait", DE: "Milchwurzel-Knöterich", JP: null },
	"Giant Logger": { EN: "Giant Logger", FR: "Géant bûcheron", DE: "Riesen-Henker", JP: null },
	"Synthetic Doblyn": { EN: "Synthetic Doblyn", FR: "Doblyn synthétique", DE: "Synthetischer Dobalos", JP: null },
	"Shoalspine Sahagin": { EN: "Shoalspine Sahagin", FR: "Sahuagin épine-d'écueil", DE: "Schwarmdorn-Sahagin", JP: null },
	"2nd Cohort Hoplomachus": { EN: "2nd Cohort Hoplomachus", FR: "Hoplomachus de la 2e cohorte", DE: "Hoplomachus der II. Kohorte", JP: null },
	"Raging Harrier": { EN: "Raging Harrier", FR: "Harceleur enragé", DE: "wütender Plünderer", JP: null },
	"Biast": { EN: "Biast", FR: "Biast", DE: "Smei", JP: null },
	"Shoaltooth Sahagin": { EN: "Shoaltooth Sahagin", FR: "Sahuagin dent-d'écueil", DE: "Schwarmfang-Sahagin", JP: null },
	"Shelfscale Reaver": { EN: "Shelfscale Reaver", FR: "Scutelle des pillards du serpent", DE: "Seichtschuppen-Plünderer", JP: null },
	"U'Ghamaro Golem": { EN: "U'Ghamaro Golem", FR: "Golem d'U'Ghamaro", DE: "U'Ghamaro-Golem", JP: null },
	"Dullahan": { EN: "Dullahan", FR: "Dullahan", DE: "Dullahan", JP: null },
	"Sylpheed Sigh": { EN: "Sylpheed Sigh", FR: "Soupireur sylphe", DE: "Sylphen-Seufzerin", JP: null },
	"Tempered Gladiator": { EN: "Tempered Gladiator", FR: "Gladiateur subjugué", DE: "Abgeklärter Gladiator", JP: null },
	"Hexing Harrier": { EN: "Hexing Harrier", FR: "Harceleur ensorceleur", DE: "Hexende Plünderin", JP: null },
	"Gigas Bonze": { EN: "Gigas Bonze", FR: "Gigas bonze", DE: "Gigas-Bonze", JP: null },
	"Giant Lugger": { EN: "Giant Lugger", FR: "Géant jouteur", DE: "Riesen-Fäller", JP: null },
	"Wild Hog": { EN: "Wild Hog", FR: "Verrat sauvage", DE: "Wildschwein", JP: null },
	"Sylpheed Screech": { EN: "Sylpheed Screech", FR: "Crieur sylphe", DE: "Sylphen-Kreischerin", JP: null },
	"Shelfclaw Reaver": { EN: "Shelfclaw Reaver", FR: "Aiguillon des pillards du serpent", DE: "Seichtklauen-Plünderer", JP: null },
	"2nd Cohort Laquearius": { EN: "2nd Cohort Laquearius", FR: "Laquearius de la 2e cohorte", DE: "Laquearius der II. Kohorte", JP: null },
	"Zahar'ak Fortune-teller": { EN: "Zahar'ak Fortune-teller", FR: "Augure de Zahar'ak", DE: "Zahar'ak-Wahrsager", JP: null },
	"Tempered Orator": { EN: "Tempered Orator", FR: "Psalmodieur subjugué", DE: "Abgeklärter Redner", JP: null },
	"Mudpuppy": { EN: "Mudpuppy", FR: "Protée", DE: "Olm", JP: null },
	"Lake Cobra": { EN: "Lake Cobra", FR: "Cobra des lacs", DE: "Seekobra", JP: null },
	"Giant Reader": { EN: "Giant Reader", FR: "Géant devin", DE: "Riesen-Häuptling", JP: null },
	"Shelfscale Sahagin": { EN: "Shelfscale Sahagin", FR: "Sahuagin écaille-des-profondeurs", DE: "Panzerschuppen-Sahagin", JP: null },
	"Sea Wasp": { EN: "Sea Wasp", FR: "Guêpe des mers", DE: "Seewespe", JP: null },
	"2nd Cohort Eques": { EN: "2nd Cohort Eques", FR: "Eques de la 2e cohorte", DE: "Eques der II. Kohorte", JP: null },
	"Magitek Vanguard": { EN: "Magitek Vanguard", FR: "Avant-garde magitek", DE: "Magitek-Frontbrecher", JP: null },
	"Amalj'aa Lancer": { EN: "Amalj'aa Lancer", FR: "Lancier amalj'aa", DE: "Amalj'aa-Pikenier", JP: null },
	"Sylphlands Sentinel": { EN: "Sylphlands Sentinel", FR: "Sentinelle des Terres sylphes", DE: "Sylphenland-Wächter", JP: null },
	"Gigas Bhikkhu": { EN: "Gigas Bhikkhu", FR: "Gigas bhikkhu", DE: "Gigas-Bhikku", JP: null },
	"5th Cohort Hoplomachus": { EN: "5th Cohort Hoplomachus", FR: "Hoplomachus de la 5e cohorte", DE: "Hoplomachus der V. Kohorte", JP: null },
	"Natalan Watchwolf": { EN: "Natalan Watchwolf", FR: "Loup de Natalan", DE: "Natalan-Wachwolf", JP: null },
	"Sylph Bonnet": { EN: "Sylph Bonnet", FR: "Chapeau sylphe", DE: "Sylphenkappe", JP: null },
	"Ked": { EN: "Ked", FR: "Pou volant", DE: "Dämonenfliege", JP: null },
	"4th Cohort Laquearius": { EN: "4th Cohort Laquearius", FR: "Laquearius de la 4e cohorte", DE: "Laquearius der IV. Kohorte", JP: null },
	"Iron Tortoise": { EN: "Iron Tortoise", FR: "Tortue de fer", DE: "Eisenschildkröte", JP: null },
	"Shelfeye Reaver": { EN: "Shelfeye Reaver", FR: "Prunelle des pillards du serpent", DE: "Seichtaugen-Plünderer", JP: null },
	"Sapsa Shelfscale": { EN: "Sapsa Shelfscale", FR: "Écaille-des-profondeurs de Sapsa", DE: "Sapsa-Panzerschuppe", JP: null },
	"Amalj'aa Brigand": { EN: "Amalj'aa Brigand", FR: "Brigand amalj'aa", DE: "Amalj'aa-Brigant", JP: "アマルジャ・ブリガンド" },
	"4th Cohort Secutor": { EN: "4th Cohort Secutor", FR: "Secutor de la 4e cohorte", DE: "Secutor der IV. Kohorte", JP: "IVコホルス・セクトール" },
	"Snow Wolf": { EN: "Snow Wolf", FR: "Loup des neiges", DE: "Schneewolf", JP: "スノウウルフ" },
	"Ixali Windtalon": { EN: "Ixali Windtalon", FR: "Ixal serres-vent", DE: "Ixal-Windkralle", JP: "イクサル・ウィンドタロン" },
	"Lesser Kalong": { EN: "Lesser Kalong", FR: "Kalong inférieur", DE: "Kalong", JP: "カロング" },
	"Gigas Sozu": { EN: "Gigas Sozu", FR: "Gigas sôzu", DE: "Gigas-Sozu", JP: "ギガース・ソーズ" },
	"5th Cohort Laquearius": { EN: "5th Cohort Laquearius", FR: "Laquearius de la 5e cohorte", DE: "Laquearius der V. Kohorte", JP: "Vコホルス・ラクエリウス" },
	"Hippogryph": { EN: "Hippogryph", FR: "Hippogriffe", DE: "Hippogryph", JP: null },
	"5th Cohort Eques": { EN: "5th Cohort Eques", FR: "Eques de la 5e cohorte", DE: "Eques der V. Kohorte", JP: null },
	"Sapsa Elbst": { EN: "Sapsa Elbst", FR: "Elbst de Sapsa", DE: "Sapsa-Elbst", JP: null },
	"Trenchtooth Sahagin": { EN: "Trenchtooth Sahagin", FR: "Sahuagin dent-des-fosses", DE: "Grabenzahn-Sahagin", JP: null },
	"Elite Roundsman": { EN: "Elite Roundsman", FR: "Livreur d'élite", DE: "Elite-Laufbursche", JP: null },
	"2nd Cohort Secutores": { EN: "2nd Cohort Secutores", FR: "Secutor de la 2e cohorte", DE: "Secutor der II. Kohorte", JP: null },
	"Ahriman": { EN: "Ahriman", FR: "Ahriman", DE: "Ahriman", JP: null },
	"Amalj'aa Thaumaturge": { EN: "Amalj'aa Thaumaturge", FR: "Occultiste amalj'aa", DE: "Amalj'aa-Thaumaturg", JP: null },
	"Sylpheed Snarl": { EN: "Sylpheed Snarl", FR: "Grondeur sylphe", DE: "Sylphen-Keiferin", JP: null },
	"Gigas Shramana": { EN: "Gigas Shramana", FR: "Gigas shramana", DE: "Gigas-Shramana", JP: null },
	"5th Cohort Signifer": { EN: "5th Cohort Signifer", FR: "Signifer de la 5e cohorte", DE: "Signifer der V. Kohorte", JP: null },
	"Dreamtoad": { EN: "Dreamtoad", FR: "Crapaud rêveur", DE: "Traumkröte", JP: null },
	"Zahar'ak Battle Drake": { EN: "Zahar'ak Battle Drake", FR: "Draconide de Zahar'ak", DE: "Zahar'ak-Kampf-Drakon", JP: null },
	"Amalj'aa Archer": { EN: "Amalj'aa Archer", FR: "Archer amalj'aa", DE: "Amalj'aa-Bogenschütze", JP: null },
	"4th Cohort Signifer": { EN: "4th Cohort Signifer", FR: "Signifer de la 4e cohorte", DE: "Signifer der IV. Kohorte", JP: null },
	"Elite Priest": { EN: "Elite Priest", FR: "Prêtre d'élite", DE: "Elite-Priester", JP: null },
	"Ixali Boldwing": { EN: "Ixali Boldwing", FR: "Ixal aile-hardie", DE: "Ixal-Mutschwinge", JP: null },
	"Amalj'aa Scavenger": { EN: "Amalj'aa Scavenger", FR: "Charognard amalj'aa", DE: "Amalj'aa-Plünderer", JP: null },
	"Axolotl": { EN: "Axolotl", FR: "Axolotl", DE: "Drakolurch", JP: null },
	"Elite Quarryman": { EN: "Elite Quarryman", FR: "Terrassier d'élite", DE: "Elite-Steinschlepper", JP: null },
	"2nd Cohort Signifer": { EN: "2nd Cohort Signifer", FR: "Signifer de la 2e cohorte", DE: "Signifer der II. Kohorte", JP: null },
	"Natalan Swiftbeak": { EN: "Natalan Swiftbeak", FR: "Bec-vif de Natalan", DE: "Natalan-Flinkschnabel", JP: null },
	"5th Cohort Secutor": { EN: "5th Cohort Secutor", FR: "Secutor de la 5e cohorte", DE: "Secutor der V. Kohorte", JP: null },
	"Hapalit": { EN: "Hapalit", FR: "Hapalit", DE: null, JP: null },
	"Watchwolf": { EN: "Watchwolf", FR: "Loup de garde", DE: null, JP: null },
	"Atrociraptor": { EN: "Atrociraptor", FR: "Atrociraptor", DE: null, JP: null },
	"Vampire Vine": { EN: "Vampire Vine", FR: "Vigne vampire", DE: null, JP: null },
	"Vampire Cup": { EN: "Vampire Cup", FR: "Coupe du vampire", DE: null, JP: null },
	"Diadem Melia": { EN: "Diadem Melia", FR: "Mélia du Diadème", DE: null, JP: null },
	"Diadem Icetrap": { EN: "Diadem Icetrap", FR: "Piège-glace du Diadème", DE: null, JP: null },
	"Diadem Ice Bomb": { EN: "Diadem Ice Bomb", FR: "Bombo de glace", DE: null, JP: null },
	"Diadem Zoblyn": { EN: "Diadem Zoblyn", FR: "Zoblyn du Diadème", DE: null, JP: null },
	"Proto-noctilucale": { EN: "Proto-noctilucale", FR: "Proto-noctilucale", DE: null, JP: null },
	"Diadem Bloated Bulb": { EN: "Diadem Bloated Bulb", FR: "Bulbe boursouflé du Diadème", DE: null, JP: null },
	"Diadem Ice Golem": { EN: "Diadem Ice Golem", FR: "Golem de glace du Diadème", DE: null, JP: null },
	"Diadem Biast": { EN: "Diadem Biast", FR: "Biast du Diadème", DE: null, JP: null },
	"Diadem Golem": { EN: "Diadem Golem", FR: "Golem du Diadème", DE: null, JP: null },
	"Diadem Werewood": { EN: "Diadem Werewood", FR: "Arbre-garou du Diadème", DE: null, JP: null },
	"Grenade": { EN: "Grenade", FR: "Grenado", DE: null, JP: null },
	"Wooly Yak": { EN: "Wooly Yak", FR: "Yak laineux", DE: null, JP: null },
	"Okeanis": { EN: "Okeanis", FR: "Océanide", DE: null, JP: null },
	"Flame Zonure": { EN: "Flame Zonure", FR: "Zonure des flammes", DE: "Lohender Zonure", JP: null },
	"Tempest Swallow": { EN: "Tempest Swallow", FR: "Hirondelle de La Tempête", DE: "Sturmschwalbe", JP: null },
	"Ochu": { EN: "Ochu", FR: "Otyugh", DE: "Ochu", JP: null },
	"Rockskin Peiste": { EN: "Rockskin Peiste", FR: "Peiste à peau dure", DE: "Steinhaut-Peiste", JP: null },
	"Sandskin Peiste": { EN: "Sandskin Peiste", FR: "Peiste sablon", DE: "Sandhaut-Peiste", JP: null },
	"Ovim Billy": { EN: "Ovim Billy", FR: "Bouquetin ovim", DE: "Ovim-Bock", JP: null },
	"Ovim Nanny": { EN: "Ovim Nanny", FR: "Étagne ovim", DE: "Ovim-Geiß", JP: null },
	"Almasty": { EN: "Almasty", FR: " Almasty", DE: "Alma", JP: null },
	"Ianthine Petalouda": { EN: "Ianthine Petalouda", FR: "Iodopétalouda", DE: "Ianthine-Petalouda", JP: null },
	"Kokkine Petalouda": { EN: "Kokkine Petalouda", FR: "Érythropétalouda", DE: "Kokkine-Petalouda", JP: null },
	"Kyane Petalouda": { EN: "Kyane Petalouda", FR: "Cyanopétalouda", DE: "Kyane-Petalouda", JP: null },
	"Ophiotauros": { EN: "Ophiotauros", FR: "Ophiotauros", DE: "Ophiotauros", JP: null },
	"Lunatender": { EN: "Lunatender", FR: "Pampa sélénien", DE: "Lunatender", JP: null },
	"Mourning Billy": { EN: "Mourning Billy", FR: "Bouquetin triste", DE: "Klagendes Steinbock-Männchen", JP: null },
	"Mourning Nanny": { EN: "Mourning Nanny", FR: "Étagne triste", DE: "Klagendes Steinbock-Weibchen", JP: null },
	"Myotragus Billy": { EN: "Myotragus Billy", FR: "Bouquetin myotragus", DE: "Myotragus-Bock", JP: null },
	"Myotragus Nanny": { EN: "Myotragus Nanny", FR: "Étagne myotragus", DE: "Myotragus-Geiß", JP: null },
	"Kumbhira": { EN: "Kumbhira", FR: "Kumbhira", DE: "Kumbhila", JP: null },
	"Hoptrap": { EN: "Hoptrap", FR: "Saute-piège", DE: "Sprungfalle", JP: null },
	"Apkallu": { EN: "Apkallu", FR: "Apkallu", DE: "Apkallu", JP: null },
	"Saiga": { EN: "Saiga", FR: "Saïga", DE: "Saiga", JP: null },
	"Ovibos": { EN: "Ovibos", FR: "Ovibos", DE: "Ovibos", JP: null },
	"Mousse": { EN: "Mousse", FR: "Mousse", DE: "Mousse", JP: null },
	"Bird of Elpis": { EN: "Bird of Elpis", FR: "Oiseau d'Elpis", DE: "Elpisvogel", JP: null },
	"Berkanan": { EN: "Berkanan", FR: "Berkanan", DE: "Berkanan", JP: null },
	"Vajralangula": { EN: "Vajralangula", FR: "Vajralangula", DE: "Vajralangula", JP: null },
	//#endregion Enemies

	//#region Baits
	"Signature Skyball": { EN: "Signature Skyball", FR: "Boulette de Cielacier", DE: "Himmelsstahlköder", JP: "スカイボール" },
	"Butterworm": { EN: "Butterworm", FR: "Ver de beurre", DE: "Butterwurm", JP: "バターワーム" },
	"Glowworm": { EN: "Glowworm", FR: "Ver luisant", DE: "Glühwürmchen", JP: "グロウワーム" },
	"Heavy Steel Jig": { EN: "Heavy Steel Jig", FR: "Devon lesté", DE: "Schwerer Eisenköder", JP: "ヘヴィメタルジグ" },
	"Steel Jig": { EN: "Steel Jig", FR: "Devon en métal", DE: "Eisenköder", JP: "メタルジグ" },
	"Sinking Minnow": { EN: "Sinking Minnow", FR: "Vairon noyé", DE: "Sinkwobbler", JP: "シンキングミノー" },
	"Yumizuno": { EN: "Yumizuno", FR: "Yumizuno", DE: "Yumizuno", JP: "弓角" },
	"Northern Krill": { EN: "Northern Krill", FR: "Krill polaire", DE: "Nordkrill", JP: "ポーラークリル" },
	"Spoon Worm": { EN: "Spoon Worm", FR: "Ver cuiller", DE: "Löffelwurm", JP: "スプーンワーム" },
	"Versatile Lure": { EN: "Versatile Lure", FR: "Leurre universel", DE: "Universalköder", JP: "万能ルアー" },
	"Krill Cage Feeder": { EN: "Krill Cage Feeder", FR: "Amorçoir à krill", DE: "Krillkäfig-Köder", JP: "クリルフィーダー" },
	"Shrimp Cage Feeder": { EN: "Shrimp Cage Feeder", FR: "Amorçoir à crevette", DE: "Garnelenkäfig-Köder", JP: "シュリンプフィーダー" },
	"Lugworm": { EN: "Lugworm", FR: "Ver de vase", DE: "Wattwurm", JP: "ラグワーム" },
	"Pill Bug": { EN: "Pill Bug", FR: "Cloporte", DE: "Pillendreher", JP: "ピルバグ" },
	"Goby Ball": { EN: "Goby Ball", FR: "Boulette de gobie", DE: "Grundelball", JP: "ゴビーボール" },
	"Rat Tail": { EN: "Rat Tail", FR: "Queue de rat", DE: "Rattenschwanz", JP: "ラットの尾" },
	"Floating Minnow": { EN: "Floating Minnow", FR: "Vairon sec", DE: "Schwebwobbler", JP: "フローティングミノー" },
	"Saltwater Boilie": { EN: "Saltwater Boilie", FR: "Appât universel d'eau de mer", DE: "Salzwasser-Universalköder", JP: "海水万能餌" },
	"Select Bait Ball": { EN: "Select Bait Ball", FR: "Boulette des merveilles", DE: "Mowen-Ball", JP: null },
	"Sand Leech": { EN: "Sand Leech", FR: "Sangsue des sables", DE: "Sandegel", JP: null },
	"Sand Gecko": { EN: "Sand Gecko", FR: "Gecko des sables", DE: "Sandgecko", JP: null },
	//#endregion Baits

	//#region Fishing Holes

	/* Delta Quadrant */
	/* Thaliak River */
	/* Dragonspit */
	"Plum Spring": { EN: "Plum Spring", FR: "Source des Pruniers", DE: "Quelle am Pflaumenbaum", JP: null },
	/* Alpha Quadrant */
	/* The Pappus Tree */
	"Bronze Lake Shallows": { EN: "Bronze Lake Shallows", FR: "Bas-fonds du lac d'Airain", DE: "Bronzesee-Untiefen", JP: null },
	/* North Silvertear */
	/* Halfstone */
	/* The Silver Bazaar */
	/* Cape Westwind */
	/* Costa Del Sol */
	/* Isles of Umbra Northshore */
	/* South Bloodshore */
	/* Summerford */
	/* The Source */
	"Seagazer Shoals": { EN: "Seagazer Shoals", FR: "Calanque de Mervoir", DE: "Bucht von Meerblick", JP: null },
	"Handmirror Lake": { EN: "Handmirror Lake", FR: "Lac Beaumiroir", DE: "Spiegelchen", JP: null },
	/* Lake Tusi Mek'ta */
	"Sagolii Dunes": { EN: "Sagolii Dunes", FR: "Dunes de Sagolii", DE: "Sagolii-Dünen", JP: null },
	"The Nabaath Severance": { EN: "The Nabaath Severance", FR: "Abîme des Nabaath", DE: "Kluft von Nabaath", JP: null },
	"The Isle of Ken": { EN: "The Isle of Ken", FR: "Île de Ken", DE: "Insel der Erkenntnis", JP: null },
	"White Oil Falls": { EN: "White Oil Falls", FR: "Chute de l'Huile blanche", DE: "Weißölfälle", JP: null },
	"West Caliban Gap": { EN: "West Caliban Gap", FR: "Ouest de la caverne de Caliban", DE: "Calibans Nistplatz", JP: null },
	"Mjrl's Tears": { EN: "Mjrl's Tears", FR: "Les Larmes de Mjrl", DE: "Mjrls Tränen", JP: null },
	"Saint Fathric's Temple": { EN: "Saint Fathric's Temple", FR: "Temple de Saint-Fathric", DE: "Tempel des Heiligen Fathric", JP: null },
	//#endregion Fishing Holes

	//#region Fish
	/* Allagan Hunter */
	/* Aetherlouse */
	/* Dragonspine */
	/* Flintstrike */
	/* Hammerhead Shark */
	/* Petal Shell */
	/* Pickled Pom */
	/* Silver Shark */
	/* Thinker's Coral */
	/* Worm of Nym */
	/* Olgoi-Khorkhoi */

	/* Other Fish that a full item is not needed for */
	"Merlthor Goby": { EN: "Merlthor Goby", FR: "Gobie de Merlthor", DE: "Merlthor-Grundel", JP: "メルトールゴビー" },
	"Wahoo": { EN: "Wahoo", FR: "Thazard noir", DE: "Räubermakrele", JP: "ワフー" },
	"Fullmoon Sardine": { EN: "Fullmoon Sardine", FR: "Sardine sélénite", DE: "Vollmond-Sardine", JP: "フルムーンサーディン" },
	"Ogre Barracuda": { EN: "Ogre Barracuda", FR: "Barracuda ogre", DE: "Oger-Barrakuda", JP: "オーガバラクーダ" },
	"Harbor Herring": { EN: "Harbor Herring", FR: "Hareng portuaire", DE: "Hafenhering", JP: "ハーバーヘリング" },
	//#endregion Fish

	//#region Currencies
	/* Company Seal - in translations */
	"Tomestone of Poetics": { EN: "Tomestone of Poetics", FR: "Mémoquartz allagois poétique", DE: "Allagische Steine der Poesie", JP: "アラガントームストーン：詩学" },
	"Gil": { EN: "Gil", FR: "Gil", DE: "Gil", JP: "ギル" },
	"White Gatherer's Scrip": { EN: "White Gatherer's Scrip", FR: "Assignat blanc de récolteur", DE: "Weiße Sammlerschein", JP: "ギャザラースクリップ：白貨" },
	/* Pagos Crystal */
	"Tomestone of Astronomy": { EN: "Tomestone of Astronomy", FR: "Mémoquartz allagois astronomique", DE: "Allagische Steine der Astronomie", JP: "アラガントームストーン：天文" },
	"White Crafter's Scrip": { EN: "White Crafter's Scrip", FR: "Assignat blanc d'artisan", DE: "Weiße Handwerkerschein", JP: "クラフタースクリップ：白貨" },
	"Bicolor Gemstone": { EN: "Bicolor Gemstone", FR: "Gemme bicolore", DE: "Zweifarbiger Edelstein", JP: "バイカラージェム" },
	/* Protean Crystal */
	/* Pyros Crystal */
	"Steel Amalj'ok": { EN: "Steel Amalj'ok", FR: "Amalj'ok d'acier", DE: "Stahl-Amalj'ok", JP: "アマルジャ鋼鉄刀貨" },
	"Allied Seal": { EN: "Allied Seal", FR: "Insigne allié", DE: "Jagdabzeichen", JP: "同盟記章" },
	"Vanu Whitebone": { EN: "Vanu Whitebone", FR: "Pièce d'os blanc vanu vanu", DE: "Vanu-Weißknochen", JP: "バヌバヌ白骨貨" },
	"Carved Kupo Nut": { EN: "Carved Kupo Nut", FR: "Noix de kupo gravée", DE: "Kupo-Schnitznuss", JP: "飾りクポの実" },
	"Precision Gordian Bolt": { EN: "Precision Gordian Bolt", FR: "Boulon gordien de haute précision", DE: "Gordischer Präzisionsbolzen", JP: "高精度ゴルディオンボルト" },
	"Precision Gordian Lens": { EN: "Precision Gordian Lens", FR: "Lentille gordienne de haute précision", DE: "Gordische Präzisionslinse", JP: null },
	"Precision Gordian Spring": { EN: "Precision Gordian Spring", FR: "Ressort gordien de haute précision", DE: "Gordische Präzisionsfeder", JP: null },
	"Precision Gordian Shaft": { EN: "Precision Gordian Shaft", FR: "Hampe gordienne de haute précision", DE: "Gordische Präzisionsachse", JP: null },
	"Black Copper Gil": { EN: "Black Copper Gil", FR: "Gil de cuivre noir", DE: "Schwarzkupfer-Gil", JP: "グナース偽黒銅貨" },
	"Skybuilders' Scrip": { EN: "Skybuilders' Scrip", FR: "Assignat d'Azurée", DE: "Himmelsstadt-Aufbauschein", JP: "蒼天街振興券" },
	"Ixali Oaknot": { EN: "Ixali Oaknot", FR: "Pièce en chêne ixale", DE: "Ixal-Eichenmünze", JP: "イクサル樫木貨" },
	"Tomestone of Causality": { EN: "Tomestone of Causality", FR: "Mémoquartz allagois déterministe", DE: "Allagischer Stein der Kausalität", JP: "アラガントームストーン：因果" },
	"Sylphic Goldleaf": { EN: "Sylphic Goldleaf", FR: "Feuille dorée de Sylphe", DE: "Sylphen-Goldblatt", JP: null },
	//#endregion Currencies

	//#region Locations
	"Zahar'ak": { EN: "Zahar'ak", FR: "Zahar'ak", DE: "Zahar'ak", JP: "ザハラク戦陣" },
	"U'Ghamaro Mines": { EN: "U'Ghamaro Mines", FR: "Mines d'U'Ghamaro", DE: "U'Ghamaro-Minen", JP: "ウ・ガマロ武装鉱山" },
	"Natalan": { EN: "Natalan", FR: "Natalan", DE: "Natalan", JP: "ナタラン入植地" },
	"Sapsa Spawning Grounds": { EN: "Sapsa Spawning Grounds", FR: "Frai de Sapsa", DE: "Sapsa-Laichplatz", JP: "サプサ産卵地" },
	"The Sylphlands": { EN: "The Sylphlands", FR: "Terres sylphes", DE: "Sylphenland", JP: "シルフ領" },
	"Eastern La Noscea": { EN: "Eastern La Noscea", FR: "Noscea orientale", DE: "Östliches La Noscea", JP: "東ラノシア" },
	"Lower La Noscea": { EN: "Lower La Noscea", FR: "Basse-Noscea", DE: "Unteres La Noscea", JP: "低地ラノシア" },
	"Middle La Noscea": { EN: "Middle La Noscea", FR: "Noscea centrale", DE: "Zentrales La Noscea", JP: "中央ラノシア" },
	"Outer La Noscea": { EN: "Outer La Noscea", FR: "Noscea extérieure", DE: "Äußeres La Noscea", JP: "外地ラノシア" },
	"Upper La Noscea": { EN: "Upper La Noscea", FR: "Haute-Noscea", DE: "Oberes La Noscea", JP: "高地ラノシア" },
	"Western La Noscea": { EN: "Western La Noscea", FR: "Noscea occidentale", DE: "Westliches La Noscea", JP: "西ラノシア" },
	"Central Thanalan": { EN: "Central Thanalan", FR: "Thanalan central", DE: "Zentrales Thanalan", JP: "中央ザナラーン" },
	"Eastern Thanalan": { EN: "Eastern Thanalan", FR: "Thanalan oriental", DE: "Östliches Thanalan", JP: "東ザナラーン" },
	"Northern Thanalan": { EN: "Northern Thanalan", FR: "Thanalan septentrional", DE: "Nördliches Thanalan", JP: "北ザナラーン" },
	"Southern Thanalan": { EN: "Southern Thanalan", FR: "Thanalan méridional", DE: "Südliches Thanalan", JP: "南ザナラーン" },
	"Western Thanalan": { EN: "Western Thanalan", FR: "Thanalan occidental", DE: "Westliches Thanalan", JP: "西ザナラーン" },
	"Central Shroud": { EN: "Central Shroud", FR: "Forêt centrale", DE: "Tiefer Wald", JP: "黒衣森：中央森林" },
	"East Shroud": { EN: "East Shroud", FR: "Forêt de l'est", DE: "Ostwald", JP: "黒衣森：東部森林" },
	"North Shroud": { EN: "North Shroud", FR: "Forêt du nord", DE: "Nordwald", JP: "黒衣森：北部森林" },
	"South Shroud": { EN: "South Shroud", FR: "Forêt du sud", DE: "Südwald", JP: "黒衣森：南部森林" },
	/* Mor Dhona */
	"New Gridania": { EN: "New Gridania", FR: "Nouvelle Gridania", DE: "Neu-Gridania", JP: "グリダニア：新市街" },
	"Limsa Lominsa Lower Decks": { EN: "Limsa Lominsa Lower Decks", FR: "Limsa Lominsa - L'Entrepont", DE: "Untere Decks", JP: "リムサ・ロミンサ：下甲板層" },
	"Limsa Lominsa Upper Decks": { EN: "Limsa Lominsa Upper Decks", FR: "Limsa Lominsa - Le Tillac", DE: "Obere Decks", JP: "リムサ・ロミンサ：上甲板層" },
	"Ul'dah - Steps of Nald": { EN: "Ul'dah - Steps of Nald", FR: "Ul'dah - Faubourg de Nald", DE: "Nald-Kreuzgang", JP: "ウルダハ：ナル回廊" },
	"Ul'dah - Steps of Thal": { EN: "Ul'dah - Steps of Thal", FR: "Ul'dah - Faubourd de Thal", DE: "Thal-Kreuzgang", JP: "ウルダハ：ザル回廊" },
	"The Sea of Clouds": { EN: "The Sea of Clouds", FR: "L'Écume des cieux d'Abalathia", DE: "Abalathisches Wolkenmeer", JP: "アバラシア雲海" },
	"Azys Lla": { EN: "Azys Lla", FR: "Azys Lla", DE: "Azys Lla", JP: "アジス・ラー" },
	"The Churning Mists": { EN: "The Churning Mists", FR: "L'Écume des cieux de Dravania", DE: "Wallende Nebel", JP: "ドラヴァニア雲海" },
	"Coerthas Central Highlands": { EN: "Coerthas Central Highlands", FR: "Hautes terres du Coerthas central", DE: "Zentrales Hochland von Coerthas", JP: "クルザス中央高地" },
	"Coerthas Western Highlands": { EN: "Coerthas Western Highlands", FR: "Hautes terres du Coerthas occidental", DE: "Westliches Hochland von Coerthas", JP: "クルザス西部高地" },
	"The Dravanian Forelands": { EN: "The Dravanian Forelands", FR: "Avant-pays dravanien", DE: "Dravanisches Vorland", JP: "高地ドラヴァニア" },
	"The Dravanian Hinterlands": { EN: "The Dravanian Hinterlands", FR: "Arrière-pays dravanien", DE: "Dravanisches Hinterland", JP: "低地ドラヴァニア" },
	/* Foundation */
	"Wolves' Den Pier": { EN: "Wolves' Den Pier", FR: "Jetée de l'Antre des loups", DE: "Wolfshöhlen-Pier", JP: "ウルヴズジェイル係船場" },
	"Old Gridania": { EN: "Old Gridania", FR: "Vieille Gridania", DE: "Alt-Gridania", JP: "グリダニア：旧市街" },
	"The Gold Saucer": { EN: "The Gold Saucer", FR: "Gold Saucer", DE: "Gold Saucer", JP: "ゴールドソーサー" },
	/* Idyllshire */
	/* Rhalgr's Reach */
	"The Fringes": { EN: "The Fringes", FR: "Les Marges", DE: "Abanisches Grenzland", JP: "ギラバニア辺境地帯" },
	"The Peaks": { EN: "The Peaks", FR: "Les Pics", DE: "Die Zinnen", JP: "ギラバニア山岳地帯" },
	"The Lochs": { EN: "The Lochs", FR: "Les Lacs", DE: "Das Fenn", JP: "ギラバニア湖畔地帯" },
	/* Kugane */
	"The Ruby Sea": { EN: "The Ruby Sea", FR: "Mer de Rubis", DE: "Rubinsee", JP: "紅玉海" },
	"Yanxia": { EN: "Yanxia", FR: "Yanxia", DE: "Yanxia", JP: "ヤンサ" },
	"The Azim Steppe": { EN: "The Azim Steppe", FR: "Steppe d'Azim", DE: "Azim-Steppe", JP: "アジムステップ" },
	"The Doman Enclave": { EN: "The Doman Enclave", FR: "Quartier enclavé de Doma", DE: "Domanische Enklave", JP: "ドマ町人地" },
	/* The Crystarium */
	"Eulmore": { EN: "Eulmore", FR: "Eulmore", DE: "Eulmore", JP: "ユールモア" },
	"Lakeland": { EN: "Lakeland", FR: "Grand-Lac", DE: "Seenland", JP: "レイクランド" },
	"Kholusia": { EN: "Kholusia", FR: "Kholusia", DE: "Kholusia", JP: "コルシア島" },
	"Amh Araeng": { EN: "Amh Araeng", FR: "Amh Araeng", DE: "Amh Araeng", JP: "アム・アレーン" },
	"Il Mheg": { EN: "Il Mheg", FR: "Il Mheg", DE: "Il Mheg", JP: "イル・メグ" },
	"The Rak'tika Greatwood": { EN: "The Rak'tika Greatwood", FR: "Rak'tika", DE: "Der Große Wald Rak'tika", JP: "ラケティカ大森林" },
	"The Tempest": { EN: "The Tempest", FR: "La Tempête", DE: "Tempest", JP: "テンペスト" },
	"Eureka Anemos": { EN: "Eureka Anemos", FR: "Eurêka Anemos", DE: "Eureka Anemos", JP: "エウレカ：アネモス帯" },
	"Eureka Pagos": { EN: "Eureka Pagos", FR: "Eurêka Pagos", DE: "Eureka Pagos", JP: "エウレカ：パゴス帯" },
	"Eureka Pyros": { EN: "Eureka Pyros", FR: "Eurêka Pyros", DE: "Eureka Pyros", JP: "エウレカ：ピューロス帯" },
	"Eureka Hydatos": { EN: "Eureka Hydatos", FR: "Eurêka Hydatos", DE: "Eureka Hydatos", JP: "エウレカ：ヒュダトス帯" },
	"The Fogfens": { EN: "The Fogfens", FR: "Fangebrume", DE: "Nebelmoor", JP: "迷霧湿原" },
	"Castrum Centri": { EN: "Castrum Centri", FR: "Castrum Centri", DE: "Castrum Centri", JP: "カストルム・セントリ" },
	"Saint Coinach's Find": { EN: "Saint Coinach's Find", FR: "Trouvaille de Saint-Coinach", DE: "Sankt Coinach-Forschungslager", JP: "聖コイナク財団の調査地" },
	/* North Silvertear */
	"Boulder Downs": { EN: "Boulder Downs", FR: "Coteaux rocheux", DE: "Rothbruch", JP: "巨石の丘" },
	"Whitebrim": { EN: "Whitebrim", FR: "L'Arête blanche", DE: "Weißenfels", JP: "ホワイトブリム" },
	"Providence Point": { EN: "Providence Point", FR: "Providence", DE: "Stätte der Vorsehung", JP: "神意の地" },
	"Agelyss Wise": { EN: "Agelyss Wise", FR: "Chaussée d'Agelyss", DE: "Zeitloser Pfad", JP: "アジェレス旧街道" },
	"The Isles of Umbra": { EN: "The Isles of Umbra", FR: "Îles d'Ombra", DE: "Die Phantominseln", JP: "幻影諸島" },
	/* Halfstone */
	"Iron Lake": { EN: "Iron Lake", FR: "Lac de Fer", DE: "Eisensee", JP: "アイアンレイク" },
	"Proud Creek": { EN: "Proud Creek", FR: "La Fierté", DE: "Stolzbach", JP: "プラウドクリーク" },
	"Larkscall": { EN: "Larkscall", FR: "Chantalouette", DE: "Lerchenruf", JP: "ラークスコール" },
	"Urth's Gift": { EN: "Urth's Gift", FR: "Le Don d'Urth", DE: "Urths Segen", JP: "ウルズの恵み" },
	"Bluefog": { EN: "Bluefog", FR: "Brumebleue", DE: "Blauer Dunst", JP: "ブルーフォグ" },
	"Raubahn's Push": { EN: "Raubahn's Push", FR: "Poussée de Raubahn", DE: "Raubahns Trutz", JP: "ラウバーン緩衝地" },
	"The Burning Wall": { EN: "The Burning Wall", FR: "Mur Incandescent", DE: "Der Feuerwall", JP: "バーニングウォール" },
	"Zanr'ak": { EN: "Zanr'ak", FR: "Zanr'ak", DE: "Zanr'ak", JP: "ザンラク" },
	/* Cape Westwind */
	"Zelma's Run": { EN: "Zelma's Run", FR: "Sentier de Zelma", DE: "Zelmas Klamm", JP: "ゼルマ渓谷" },
	"The Standing Corses": { EN: "The Standing Corses", FR: "Les Corps levés", DE: "Der Kohlenwald", JP: "枯骨の森" },
	"Kobold Dig": { EN: "Kobold Dig", FR: "Excavation kobolde", DE: "Koboldgrube", JP: "コボルド軍試掘地" },
	"Bentbranch": { EN: "Bentbranch", FR: "Brancharquée", DE: "Zwieselgrund", JP: "ベントブランチ" },
	"Upper Paths": { EN: "Upper Paths", FR: "Hauts-Chemins", DE: "Obere Pfade", JP: "アッパーパス" },
	"Snakemolt": { EN: "Snakemolt", FR: "Bocage de la Mue", DE: "Natternhain", JP: "蛇殻林" },
	"Raincatcher Gullly": { EN: "Raincatcher Gullly", FR: "Ravine des Ceuillepluies", DE: "Regenfänger-Rinne", JP: "レインキャッチャー樹林" },
	"Bronze Lake": { EN: "Bronze Lake", FR: "Lac d'Airain", DE: "Bronzesee", JP: "ブロンズレイク" },
	"Horizon's Edge": { EN: "Horizon's Edge", FR: "Confins d'Horizon", DE: "Horizontblick", JP: "ホライズン・エッジ" },
	"Broken Water": { EN: "Broken Water", FR: "Eaux-Mortes", DE: "Mordsdurst", JP: "ブロークンウォーター" },
	"Sandgate": { EN: "Sandgate", FR: "Le Seuil de sable", DE: "Sandtor", JP: "サンドゲート" },
	"Black Brush": { EN: "Black Brush", FR: "Roncenoire", DE: "Kohlenstaub", JP: "ブラックブラッシュ" },
	"Skull Valley": { EN: "Skull Valley", FR: "Vallée du Crâne", DE: "Schädeltal", JP: "スカルバレー" },
	"Skysteel Manufactory": { EN: "Skysteel Manufactory", FR: "Manufacture de Cielacier", DE: "Himmelsstahl-Werkstätte", JP: "スカイスチール機工房" },
	/* Lake Tusi Mek'ta */
	/* "Delta Quadrant */
	/* "Thaliak River */
	/* "Dragonspit */
	/* "Alpha Quadrant */
	"Newvoid": { EN: "Newvoid", FR: "Néonéant", DE: "Neunichts", JP: "ニューヴォイド" },
	"Icebloom Cavern": { EN: "Icebloom Cavern", FR: "Caverne aux perce-glaces", DE: "Eisblumen-Höhle", JP: "氷華洞" },
	"East Flamerock": { EN: "East Flamerock", FR: "Est de Rocheflamme", DE: "Östlicher Flammenfels", JP: "フレイムロック溶岩洞東" },
	"The East Val River Bank": { EN: "The East Val River Bank", FR: "Rive Est", DE: "Ostufer des Val", JP: "バル川東岸" },
	"Ok' Vundu Vana": { EN: "Ok' Vundu Vana", FR: "Ok' Vundu Vana", DE: "Ok' Vundo Vana", JP: "オク・ブンド・ヴァナ" },
	"Red Rim": { EN: "Red Rim", FR: "Rougebord", DE: "Rotkar", JP: "レッドリム" },
	"Whilom River": { EN: "Whilom River", FR: "Rivière Vairlame", DE: "Der Weiland", JP: "ウィロームリバー" },
	"The Whale's Crown": { EN: "The Whale's Crown", FR: "Couronne du Migrateur", DE: "Walkrone", JP: "白鯨の冠" },
	"Mourn": { EN: "Mourn", FR: "Morne", DE: "Mourn", JP: "モーン大岩窟" },
	"The Daggers": { EN: "The Daggers", FR: "Les Dagues", DE: "Die Dolche", JP: "ダガー石柱群" },
	"Four Arms": { EN: "Four Arms", FR: "Fort-Heaume", DE: "Vornrum", JP: "フォーアームズ" },
	"Ashpool": { EN: "Ashpool", FR: "Cendrétang", DE: "Aschenpfuhl", JP: "アッシュプール" },
	"The Dragon's Struggle": { EN: "The Dragon's Struggle", FR: "Sombresaut du Dragon", DE: "Drachensprung", JP: "大龍瀑" },
	"The Pauper's Lode": { EN: "The Pauper's Lode", FR: "Filon du pauvre", DE: "Bettlers Katzengold", JP: "貧者の金蔵" },
	"The Destroyer": { EN: "The Destroyer", FR: "Statue du Destructeur", DE: "Der Zerstörer", JP: "壊神ラールガー像" },
	"Valley of the Fallen Rainbow": { EN: "Valley of the Fallen Rainbow", FR: "Vallée aux Prismes", DE: "Regenbogental", JP: "七彩渓谷" },
	"Cooling Station": { EN: "Cooling Station", FR: "Station de refroidissement", DE: "Kühlstation", JP: "総合冷却局" },
	"The Aqueduct": { EN: "The Aqueduct", FR: "Pont-canal", DE: "Aquädukt", JP: "デルタ送水局" },
	"Hyperstellar Downconverter": { EN: "Hyperstellar Downconverter", FR: "Convertisseur hyperstellaire", DE: "Hyperstellarer Decoder", JP: "超星間交信塔" },
	/* The Pappus Tree */
	"Gamma Quadrant": { EN: "Gamma Quadrant", FR: "Quadrant gamma", DE: "Gamma-Quadrant", JP: "ガンマ管区" },
	"The Diadem": { EN: "The Diadem", FR: "Le Diadème", DE: "Das Diadem", JP: "ディアデム諸島" },
	"Antithesis": { EN: "Antithesis", FR: "Antithèse", DE: "Antithese", JP: "対偶の磔刑台" },
	"The Citia Swamps": { EN: "The Citia Swamps", FR: "Marécages du Citia", DE: "Citia-Sumpfland", JP: "シチュア湿地" },
	"The Bookman's Shelves": { EN: "The Bookman's Shelves", FR: "Villégiature du Lettré", DE: "Lehnsgut des Gelehrten", JP: "篤学者の荘園" },
	"Hare Among Giants": { EN: "Hare Among Giants", FR: "Le Guêpier", DE: "Hasenhütte", JP: "巨人の兎小屋" },
	"The Church of the First Light": { EN: "The Church of the First Light", FR: "Eglise de l'Aurore", DE: "Kirche des Strahlenden Lichts", JP: "光耀教会" },
	"Mount Biran Mines": { EN: "Mount Biran Mines", FR: "Grandes mines de Brian", DE: "Biran-Mine", JP: "ビラン大鉱山" },
	/* The Inn at Journey's Head */
	"Scree": { EN: "Scree", FR: "Plateau aux Rocs", DE: "Schotterfeld", JP: "スクリー山地" },
	"The Chiliad": { EN: "The Chiliad", FR: "Les mille Lacis", DE: "Tausendgipfel", JP: "千の山々" },
	"Lozatl's Conquest": { EN: "Lozatl's Conquest", FR: "Conquête de Lozatl", DE: "Treppe des Lozatl", JP: "ロツァトル大階段" },
	"Woven Oath": { EN: "Woven Oath", FR: "Solide Serment", DE: "Eidbund", JP: "ウォーヴンオウス" },
	"The Deliberating Doll": { EN: "The Deliberating Doll", FR: "Géant accroupi", DE: "Hockender Riese", JP: "岩兵の瞑目" },
	"Haldrath's March": { EN: "Haldrath's March", FR: "Chemin de Haldrath", DE: "Haldraths Marsch", JP: "ハルドラス騎道" },
	"Dragonhead": { EN: "Dragonhead", FR: "Crâne-de-dragon", DE: "Drachenkopf", JP: "ドラゴンカブリ" },
	"The Clutch": { EN: "The Clutch", FR: "Le Nid", DE: "Die Klaue", JP: "クラッチ狭間" },
	"Spineless Basin": { EN: "Spineless Basin", FR: "Bassin des Désépinés", DE: "Kakteenmulde", JP: "刺抜盆地" },
	"Landlord Colony": { EN: "Landlord Colony", FR: "Colonie des Maîtres terriens", DE: "Grundherren-Kolonie", JP: "ランドロード遺構" },
	"Twinpools": { EN: "Twinpools", FR: "Les Jumeaux", DE: "Zwillingsteiche", JP: "ツインプールズ" },
	"The Ruling Quarter": { EN: "The Ruling Quarter", FR: "Quartier des Sages", DE: "Meister-Viertel", JP: "シャーレアン哲人街" },
	"Ladle": { EN: "Ladle", FR: "La Louche", DE: "Der Nasse Bahnhof", JP: "水の駅" },
	"Chocobo Forest": { EN: "Chocobo Forest", FR: "Forêt des chocobos", DE: "Chocobo-Wald", JP: "チョコボの森" },
	"Amity": { EN: "Amity", FR: "Amistie", DE: "Eintracht", JP: "アミティー" },
	"The Pristine Palace of Amh Malik": { EN: "The Pristine Palace of Amh Malik", FR: "Nouveau palais d'Amh Malik", DE: "Palast von Amh Malik", JP: "アム・マリク新宮殿" },
	"The WIld Fete": { EN: "The WIld Fete", FR: "La Frairie sauvage", DE: "Wildgarten", JP: "ワイルドガーデン" },
	"Loch Seld": { EN: "Loch Seld", FR: "Lac Seld", DE: "Seldsee", JP: "ロッホ・セル湖" },
	"Gorgagne Holding": { EN: "Gorgagne Holding", FR: "Prairie de Gorgagne", DE: "Gorgagne-Weiden", JP: "ゴルガニュ牧草地" },
	"Norvrandt Slope": { EN: "Norvrandt Slope", FR: "Marge continentale de Norvrandt", DE: "Norvrandt-Hang", JP: "ノルヴラント大陸斜面" },
	"Honey Yard": { EN: "Honey Yard", FR: "Jardinet du miel", DE: "Der Honighof", JP: "ハニーヤード" },
	"Yugr'am River": { EN: "Yugr'am River", FR: "Rivière du Yugr'am", DE: "Der Yugr'am", JP: "ユグラム川" },
	"Alder Springs": { EN: "Alder Springs", FR: "Sources de l'aulne", DE: "Erlenbrunnen", JP: "アルダースプリングス" },
	"The Bramble Patch": { EN: "The Bramble Patch", FR: "Massif de ronces", DE: "Brombeerlichtung", JP: "茨の森" },
	"Lower Paths": { EN: "Lower Paths", FR: "Bas-Chemins", DE: "Untere Pfade", JP: "ロウアーパス" },
	/* The Silver Bazaar */
	/* Costa Del Sol */
	/* Isles of Umbra Northshore */
	/* South Bloodshore */
	/* Summerford */
	"The Smoldering Wastes": { EN: "The Smoldering Wastes", FR: "Terres fumantes", DE: "Qualmende Öde", JP: "スモーキングウェイスト" },
	"Beta Quadrant": { EN: "Beta Quadrant", FR: "Quadrant beta", DE: "Beta-Quadrant", JP: "ベータ管区" },
	"Voor Sian Siran": { EN: "Voor Sian Siran", FR: "Voor Sian Siran", DE: "Voor Sian Siran", JP: "ヴール・シアンシラン" },
	"Easton Eyes": { EN: "Easton Eyes", FR: "Epieur d'Estebourg", DE: "Ostblick", JP: "イーストン・アイ" },
	"Avalonia Fallen": { EN: "Avalonia Fallen", FR: "Déclin d'Avalonia", DE: "Abglanz Avalonias", JP: "アヴァロニア・フォールン" },
	"Greensward": { EN: "Greensward", FR: "Clos-Vert", DE: "Grünwarte", JP: "グリーンスウォード島" },
	"The Paths of Contemplation": { EN: "The Paths of Contemplation", FR: "Voie de la Contemplation", DE: "Pfad der Kontemplation", JP: "黙想の道" },
	"The Northern Hills of Amber": { EN: "The Northern Hills of Amber", FR: "Collines d'Ambre du Nord", DE: "Nördliche Bernsteinhügel", JP: "北アンバーヒル" },
	"The Paths of Creation": { EN: "The Paths of Creation", FR: "Voie de la Création", DE: "Pfad der Schöpfung", JP: "創造の道" },
	"Black Iron Bridge": { EN: "Black Iron Bridge", FR: "Pont du Fer noir", DE: "Schwarzeisen-Brücke", JP: "ブラックアイアン・ブリッジ" },
	"Ok' Gundu": { EN: "Ok' Gundu", FR: "Ok' Gundu", DE: "Ok' Gundo", JP: "オク・グンド" },
	"Ok' Vundu Mok": { EN: "Ok' Vundu Mok", FR: "Ok' Vundu Mok", DE: "Ok' Vundo Mok", JP: "オク・ブンド・モック" },
	"Ok' Bendu": { EN: "Ok' Bendu", FR: "Ok' Bendu", DE: "Ok' Bendu", JP: "ブンド・オク・ベンド" },
	"The Drowning Wench": { EN: "The Drowning Wench", FR: "Le Dauphin noyé", DE: "Zur Ertränkten Sorge", JP: "溺れた海豚亭" },
	"The Bozjan Southern Front": { EN: "The Bozjan Southern Front", FR: "Le Front Sud de Bozja", DE: "Bozja-Südfront", JP: "南方ボズヤ戦線" },
	"Zadnor": { EN: "Zadnor", FR: "Zadnor", DE: "Zadnor", JP: "ザトゥノル高原" }, // TODO: Check as the original fr i had was just "Zadnor", these translations could be an area in zadnor
	"South Bozja Area": { EN: "South Bozja Area", FR: "Tranchées sud", DE: null, JP: null },
	"Middle Bozja Area": { EN: "Middle Bozja Area", FR: "Ancienne Bozja", DE: null, JP: null },
	"North Bozja Area": { EN: "North Bozja Area", FR: "Bourg d'Alermuc", DE: null, JP: null },
	"South Zadnor Area": { EN: "South Zadnor Area", FR: "Plateau sud", DE: null, JP: null },
	"Middle Zadnor Area": { EN: "Middle Zadnor Area", FR: "Plateau ouest", DE: null, JP: null },
	"North Zadnor Area": { EN: "North Zadnor Area", FR: "Plateau nord", DE: null, JP: null },
	"Hyrstmill": { EN: "Hyrstmill", FR: "Moulin des Bois", DE: "Mühlenhain", JP: "ハーストミル" },
	"The Bonfire": { EN: "The Bonfire", FR: "Les Fumerolles", DE: "Der Feuerhügel", JP: "狼煙の丘" },
	"Vesper Bay": { EN: "Vesper Bay", FR: "Baie des Vêpres", DE: "Bucht des Abendsterns", JP: "ベスパーベイ" },
	/* Swiftperch */
	/* Helix */
	"Gangos": { EN: "Gangos", FR: "Gangos", DE: "Gangos", JP: "ガンゴッシュ" },
	"The Prima Vista": { EN: "The Prima Vista", FR: "Salle de répétition du Prima Vista", DE: null, JP: null },
	"Monzen": { EN: "Monzen", FR: "Monzen", DE: "Monzen", JP: "門前侍町" },
	"Starfall": { EN: "Starfall", FR: "Etang des Etoiles chues", DE: "Kratersee", JP: "星降りの池" },
	"Southern Mare Lamentorum": { EN: "Southern Mare Lamentorum", FR: "Sud de Mare Lamentorum", DE: "Südliches Mare Lamentorum", JP: "嘆きの海 南" },
	"Mare Lamentorum": { EN: "Mare Lamentorum", FR: "Mare Lamentorum", DE: "Mare Lamentorum", JP: "嘆きの海" },
	"Radz-At-Han": { EN: "Radz-At-Han", FR: "Radz-At-Han", DE: "Radz-at-Han", JP: "ラザハン" },
	"Artha": { EN: "Artha", FR: "Artha", DE: "Artha", JP: "アルタ区" },
	"The Brume": { EN: "The Brume", FR: "Brouillasse", DE: "Die Schwaden", JP: "雲霧街" },
	"The Firmament": { EN: "The Firmament", FR: "Azurée", DE: "Himmelsstadt", JP: "蒼天街" },
	"Featherfall": { EN: "Featherfall", FR: "Tombeplume", DE: "Federfall-Quartier", JP: "フェザーフォール商業区" },
	"Thavnair": { EN: "Thavnair", FR: "Thavnair", DE: "Thavnair", JP: "サベネア島" },
	"Purusa": { EN: "Purusa", FR: "Temple du Purusa", DE: "Purusa", JP: "プルシャ寺院" },
	"The Pendants": { EN: "The Pendants", FR: "Les Pendants", DE: null, JP: null },
	"The Rotunda": { EN: "The Rotunda", FR: "La Rotonde", DE: null, JP: null },
	"The Baldaquin": { EN: "The Baldaquin", FR: "Le Baldaquin", DE: null, JP: null },
	"Timh Gyeus": { EN: "Timh Gyeus", FR: "Timh Gyeus", DE: null, JP: null },
	"The Duergar Mountains": { EN: "The Duergar Mountains", FR: "Montagnes des Duergars", DE: null, JP: null },
	/* The Source */
	"Shadow Fault": { EN: "Shadow Fault", FR: "Ombrefaille", DE: null, JP: null },
	"The Fields of Amber": { EN: "The Fields of Amber", FR: null, DE: null, JP: null },
	"Weathering": { EN: "Weathering", FR: null, DE: null, JP: null },
	"Kallimelios Zephyros": { EN: "Kallimelios Zephyros", FR: null, DE: null, JP: null },
	"Elpis": { EN: "Elpis", FR: "Elpis", DE: null, JP: null },
	"The Eblan Rime": { EN: "The Eblan Rime", FR: null, DE: null, JP: null },
	"Garlemald": { EN: "Garlemald", FR: "Garlemald", DE: null, JP: null },
	"Cerulea Ingens": { EN: "Cerulea Ingens", FR: "Cerulea Ingens", DE: null, JP: null },
	"Philomythes Notos": { EN: "Philomythes Notos", FR: null, DE: null, JP: null },
	"Ultima Thule": { EN: "Ultima Thule", FR: "Ultima Thule", DE: null, JP: null },
	"Ostrakon Deka-okto": { EN: "Ostrakon Deka-okto", FR: null, DE: null, JP: null },
	"Ostrakon Tria": { EN: "Ostrakon Tria", FR: null, DE: null, JP: null },
	"Ethoseletikos Boreas": { EN: "Ethoseletikos Boreas", FR: null, DE: null, JP: null },
	"Sullen": { EN: "Sullen", FR: null, DE: null, JP: null },
	"The Medial Circuit": { EN: "The Medial Circuit", FR: null, DE: null, JP: null },
	"Labyrinthos": { EN: "Labyrinthos", FR: "Labyrinthos", DE: null, JP: null },
	"The Perfumed Rise": { EN: "The Perfumed Rise", FR: null, DE: null, JP: null },
	"Wellwick Wood": { EN: "Wellwick Wood", FR: null, DE: null, JP: null },
	"Greentear": { EN: "Greentear", FR: null, DE: null, JP: null },
	"Sagolii Desert": { EN: "Sagolii Desert", FR: null, DE: null, JP: null },
	"Treespeak": { EN: "Treespeak", FR: null, DE: null, JP: null },
	/* Camp Drybone */
	"Hammerlea": { EN: "Hammerlea", FR: null, DE: null, JP: null },
	"Nine Ivies": { EN: "Nine Ivies", FR: null, DE: null, JP: null },
	"Oakwood": { EN: "Oakwood", FR: null, DE: null, JP: null },
	"Sorrel Haven": { EN: "Sorrel Haven", FR: null, DE: null, JP: null },
	"Nophica's Wells": { EN: "Nophica's Wells", FR: null, DE: null, JP: null },
	"Mithai Glorianda": { EN: "Mithai Glorianda", FR: null, DE: null, JP: null },
	"The Bright Cliff": { EN: "The Bright Cliff", FR: null, DE: null, JP: null },
	"The Central Hills of Amber": { EN: "The Central Hills of Amber", FR: null, DE: null, JP: null },
	"Good Jenanna's Grace": { EN: "Good Jenanna's Grace", FR: null, DE: null, JP: null },
	"Nabaath Areng": { EN: "Nabaath Areng", FR: null, DE: null, JP: null },
	"The Caliban Gorge": { EN: "The Caliban Gorge", FR: null, DE: null, JP: null },
	"The Blind Forest of Yx'Maja": { EN: "The Blind Forest of Yx'Maja", FR: null, DE: null, JP: null },
	"Voeburtenburg": { EN: "Voeburtenburg", FR: null, DE: null, JP: null },
	//#endregion Locations

	//#region Teleports
	"Forgotten Springs": { EN: "Forgotten Springs", FR: "Oasis oubliée", DE: null, JP: "忘れられたオアシス" },
	"Camp Overlook": { EN: "Camp Overlook", FR: "Camp du Guet", DE: null, JP: "キャンプ・オーバールック" },
	"Camp Dragonhead": { EN: "Camp Dragonhead", FR: "Camp de la Tête du dragon", DE: null, JP: "キャンプ・ドラゴンヘッド" },
	"Aleport": { EN: "Aleport", FR: "Port-aux-Ales", DE: null, JP: "エールポート" },
	"The Hawthorne Hut": { EN: "The Hawthorne Hut", FR: "Hutte des Hawthorne", DE: null, JP: "ホウソーン家の山塞" },
	/* Mor Dhona */
	"Wineport": { EN: "Wineport", FR: "Port-aux-Vins", DE: null, JP: "ワインポート" },
	"Fallgourd Float": { EN: "Fallgourd Float", FR: "Radeau de la Calebasse", DE: null, JP: "フォールゴウド" },
	"Quarrymill": { EN: "Quarrymill", FR: "Moulin de la Carrière", DE: null, JP: "クォーリーミル" },
	"Camp Bluefog": { EN: "Camp Bluefog", FR: "Camp de Brumebleue", DE: null, JP: "キャンプ・ブルーフォグ" },
	"Ceruleum Processing Plant": { EN: "Ceruleum Processing Plant", FR: "Usine de céruleum", DE: null, JP: "青燐精製所" },
	/* Camp Drybone */
	"Horizon": { EN: "Horizon", FR: "Horizon", DE: null, JP: "ホライズン" },
	"Camp Bronze Lake": { EN: "Camp Bronze Lake", FR: "Camp du lac d'Airain", DE: null, JP: "キャンプ・ブロンズレイク" },
	"Bentbranch Meadows": { EN: "Bentbranch Meadows", FR: "Ranch de Brancharquée", DE: null, JP: "ベントブランチ牧場" },
	"Little Ala Mhigo": { EN: "Little Ala Mhigo", FR: "Petite Ala Mhigo", DE: null, JP: "リトルアラミゴ" },
	"Black Brush Station": { EN: "Black Brush Station", FR: "Gare de Roncenoire", DE: null, JP: "ブラックブラッシュ停留所" },
	/* Foundation */
	"Slitherbough": { EN: "Slitherbough", FR: "Serpentige", DE: null, JP: null },
	/* Helix */
	/* Idyllshire */
	"Falcon's Nest": { EN: "Falcon's Nest", FR: "Nid du faucon", DE: null, JP: null },
	"Namai": { EN: "Namai", FR: "Namai", DE: null, JP: null },
	"Abandoned Laboratory": { EN: "Abandoned Laboratory", FR: "Laboratoire abandonné", DE: null, JP: null },
	"Gravitational Studies": { EN: "Gravitational Studies", FR: "Laboratoire d'études antigravitationnelles", DE: null, JP: null },
	"The Firing Chamber": { EN: "The Firing Chamber", FR: "Site d'experimentations calorifuges", DE: null, JP: null },
	"Dormitory": { EN: "Dormitory", FR: null, DE: null, JP: null },
	"Ok' Zundu": { EN: "Ok' Zundu", FR: "Ok' Zundu", DE: null, JP: null },
	"Tailfeather": { EN: "Tailfeather", FR: "La Penne", DE: null, JP: null },
	"Anyx Trine": { EN: "Anyx Trine", FR: "Annexe Trine", DE: null, JP: null },
	"Zenith": { EN: "Zenith", FR: "Zénith", DE: null, JP: null },
	"The House of the Fierce": { EN: "The House of the Fierce", FR: "Cercle des Intrépides", DE: null, JP: null },
	"Porta Praetoria": { EN: "Porta Praetoria", FR: "Porta Praetoria", DE: null, JP: null },
	"Lydha Lran": { EN: "Lydha Lran", FR: "Lydha Lran", DE: null, JP: null },
	"The Ostall Imperative": { EN: "The Ostall Imperative", FR: "L'Impératif d'Ostall", DE: null, JP: null },
	"Fort Jobb": { EN: "Fort Jobb", FR: "Fort Jobb", DE: null, JP: null },
	"Twine": { EN: "Twine", FR: "Queues-liées", DE: null, JP: null },
	/* The Inn at Journey's Head */
	"Tomra": { EN: "Tomra", FR: "Tomra", DE: null, JP: null },
	"Ondo Cups": { EN: "Ondo Cups", FR: "Baïne", DE: null, JP: null },
	"Camp Tranquil": { EN: "Camp Tranquil", FR: "Camp des Sentes tranquilles", DE: null, JP: "キャンプ・トランキル" },
	/* Costa Del Sol */
	"Summerford Farms": { EN: "Summerford Farms", FR: "Vergers d'Estival", DE: null, JP: "サマーフォード庄" },
	"Camp Cloudtop": { EN: "Camp Cloudtop", FR: "Camp de Cime-des-nuages", DE: null, JP: null },
	"Aftcastle": { EN: "Aftcastle", FR: "L'Entrepont", DE: null, JP: null },
	/* Swiftperch */
	/* Kugane */
	/* Rhalgr's Reach */
	"Sinus Lacrimarum": { EN: "Sinus Lacrimarum", FR: "Sinus Lacrimarum", DE: null, JP: null },
	"Radz-at-Han Aetheryte Plaza": { EN: "Radz-at-Han Aetheryte Plaza", FR: "Place de l'Ethérite de Radz-at-Han", DE: null, JP: null },
	"Palaka's Stand": { EN: "Palaka's Stand", FR: "Ecoute des Palaka", DE: null, JP: null },
	/* The Crystarium */
	"Stilltide": { EN: "Stilltide", FR: null, DE: null, JP: null },
	"Wright": { EN: "Wright", FR: null, DE: null, JP: null },
	"Mord Souq": { EN: "Mord Souq", FR: "Mord Souq", DE: null, JP: null },
	"Poieten Oikos": { EN: "Poieten Oikos", FR: "Poieten Oikos", DE: null, JP: null },
	"Camp Broken Glass": { EN: "Camp Broken Glass", FR: "Camp Bris-de-Glace", DE: null, JP: null },
	"Anagnorisis": { EN: "Anagnorisis", FR: null, DE: null, JP: null },
	"Reah Tahra": { EN: "Reah Tahra", FR: "Reah Tahra", DE: null, JP: null },
	"Abode of the Ea": { EN: "Abode of the Ea", FR: "Demeure des Eas", DE: null, JP: null },
	"The Archeion": { EN: "The Archeion", FR: "Archéion", DE: null, JP: null },
	"The Great Work": { EN: "The Great Work", FR: "Le Grand Oeuvre", DE: null, JP: null },
	"Wolekdorf": { EN: "Wolekdorf", FR: null, DE: null, JP: null },
	//#endregion Teleports

	//#region Gathering Methods
	"Mining": { EN: "Mining", FR: "Minage", DE: null, JP: "採掘" },
	"Quarrying": { EN: "Quarrying", FR: null, DE: null, JP: "砕岩" },
	"Logging": { EN: "Logging", FR: null, DE: null, JP: "伐採" },
	"Harvesting": { EN: "Harvesting", FR: null, DE: null, JP: "草刈" },
	//#endregion Gathering Methods

	//#region Quests
	"The Ultimate Weapon": { EN: "The Ultimate Weapon", FR: null, DE: null, JP: "究極幻想アルテマウェポン" },
	"The Weaponsmith of Legend": { EN: "The Weaponsmith of Legend", FR: null, DE: null, JP: "伝説の武具職人" },
	"A Relic Reborn": { EN: "A Relic Reborn", FR: null, DE: null, JP: "蘇る古の武器" },
	"Up in Arms": { EN: "Up in Arms", FR: "L'âme des armes", DE: null, JP: "英雄の武器を求めて" },
	"Trials of the Braves": { EN: "Trials of the Braves", FR: "Les Chroniques du zodiaque", DE: null, JP: "黄道十二文書" },
	"Celestial Radiance": { EN: "Celestial Radiance", FR: "Radiance sphérique", DE: null, JP: "スフィアの輝き" },
	"Star Light, Star Bright": { EN: "Star Light, Star Bright", FR: "Brillance stellaire", DE: null, JP: "輝きは新星の如く" },
	"Wherefore Art Thou, Zodiac": { EN: "Wherefore Art Thou, Zodiac", FR: "Une idée de génie", DE: null, JP: "ゾディアックウェポン" },
	"One Man's Trash": { EN: "One Man's Trash", FR: null, DE: null, JP: "現実悲惨！ トレジャーハンター！" },
	"Heavensward": { EN: "Heavensward", FR: "Heavensward", DE: null, JP: "蒼天のイシュガルド" },
	"An Unexpected Proposal": { EN: "An Unexpected Proposal", FR: null, DE: null, JP: null },
	"Soul Without Life": { EN: "Soul Without Life", FR: null, DE: null, JP: null },
	"Toughening Up": { EN: "Toughening Up", FR: null, DE: null, JP: null },
	"Coming into Its Own": { EN: "Coming into Its Own", FR: null, DE: null, JP: null },
	"Mislaid Plans": { EN: "Mislaid Plans", FR: null, DE: null, JP: null },
	"A Ponze of Flesh": { EN: "A Ponze of Flesh", FR: "La liberté a un prix", DE: null, JP: "パパナ借金道" },
	"Labor of Love": { EN: "Labor of Love", FR: "L'art et la matière", DE: null, JP: "真摯なる職人の挑戦" },
	"A Treasured Mother": { EN: "A Treasured Mother", FR: "Le coeur d'une mère est un trésor", DE: null, JP: "母の想い、息子の願い" },
	"Method in His Malice": { EN: "Method in His Malice", FR: "Le futur bras droit de Mutamix", DE: null, JP: "情熱と夢を込めて" },
	"His Dark Materia": { EN: "His Dark Materia", FR: "Dernier fragment d'un long voyage", DE: null, JP: "眠れる大器" },
	"Shadowbringers": { EN: "Shadowbringers", FR: null, DE: null, JP: "漆黒のヴィランズ" },
	"The Lost City of Angels": { EN: "The Lost City of Angels", FR: null, DE: null, JP: null },
	"Fire in the Forge": { EN: "Fire in the Forge", FR: null, DE: null, JP: null },
	"Resistance is (Not) Futile": { EN: "Resistance is (Not) Futile", FR: null, DE: null, JP: null },
	"A sober Proposal": { EN: "A sober Proposal", FR: null, DE: null, JP: null },
	"Where Eagles Nest": { EN: "Where Eagles Nest", FR: null, DE: null, JP: null },
	"Vows of Virtue, Deeds of Cruelty": { EN: "Vows of Virtue, Deeds of Cruelty", FR: null, DE: null, JP: null },
	"For want of a Memory": { EN: "For want of a Memory", FR: null, DE: null, JP: null },
	"The will to Resist": { EN: "The will to Resist", FR: null, DE: null, JP: null },
	"In the Queen's Image": { EN: "In the Queen's Image", FR: null, DE: null, JP: null },
	"Change of Arms": { EN: "Change of Arms", FR: null, DE: null, JP: null },
	"The Resistance Remembers": { EN: "The Resistance Remembers", FR: null, DE: null, JP: null },
	"A New Path of Resistance": { EN: "A New Path of Resistance", FR: null, DE: null, JP: null },
	"Hail to the Queen": { EN: "Hail to the Queen", FR: null, DE: null, JP: null },
	"Path to the Past": { EN: "Path to the Past", FR: null, DE: null, JP: null },
	"The Bozja Incident": { EN: "The Bozja Incident", FR: null, DE: null, JP: null },
	"Work It Harder, Make It Better": { EN: "Work It Harder, Make It Better", FR: null, DE: null, JP: null },
	"Towards the Firmament": { EN: "Towards the Firmament", FR: null, DE: null, JP: null },
	"Ever Skyward": { EN: "Ever Skyward", FR: null, DE: null, JP: null },
	"The Pinnacle of Possibility": { EN: "The Pinnacle of Possibility", FR: null, DE: null, JP: null },
	"The Tools of Tomorrow": { EN: "The Tools of Tomorrow", FR: null, DE: null, JP: null },
	"Finding your Voice": { EN: "Finding your Voice", FR: null, DE: null, JP: null },
	"The Gift of the Archmagus": { EN: "The Gift of the Archmagus", FR: null, DE: null, JP: null },
	"A Dream fulfilled": { EN: "A Dream fulfilled", FR: null, DE: null, JP: null },
	"Future Proof": { EN: "Future Proof", FR: null, DE: null, JP: null },
	"Born Again Anima": { EN: "Born Again Anima", FR: null, DE: null, JP: null },
	"Some Assembly Required": { EN: "Some Assembly Required", FR: null, DE: null, JP: null },
	"Words of Wisdom": { EN: "Words of Wisdom", FR: null, DE: null, JP: null },
	"Best Friends Forever": { EN: "Best Friends Forever", FR: null, DE: null, JP: null },
	"Stormblood": { EN: "Stormblood", FR: null, DE: null, JP: "紅蓮のリベレーター" },
	"And We Shall Call It Eureka": { EN: "And We Shall Call It Eureka", FR: null, DE: null, JP: null },
	"What Dreams Are Made Of": { EN: "What Dreams Are Made Of", FR: null, DE: null, JP: null },
	"Spare Parts": { EN: "Spare Parts", FR: null, DE: null, JP: null },
	"Tell Me a Story": { EN: "Tell Me a Story", FR: null, DE: null, JP: null },
	"A Fond Memory": { EN: "A Fond Memory", FR: null, DE: null, JP: null },
	"A Done Deal": { EN: "A Done Deal", FR: null, DE: null, JP: null },
	"Irresistible": { EN: "Irresistible", FR: null, DE: null, JP: null },
	"Mmmmmm, Soulglazed Relics": { EN: "Mmmmmm, Soulglazed Relics", FR: "L'harmonisation des âmes", DE: null, JP: "器に込められた魂" },
	"Rise and Shine": { EN: "Rise and Shine", FR: "L'heure du réveil", DE: null, JP: null },
	"The Vital Title": { EN: "The Vital Title", FR: "Un titre pour deux", DE: null, JP: null },
	"Seeking Inspiration": { EN: "Seeking Inspiration", FR: null, DE: null, JP: null },
	"Cut from a Different Cloth": { EN: "Cut from a Different Cloth", FR: null, DE: null, JP: null },
	"Body and Soul": { EN: "Body and Soul", FR: null, DE: null, JP: null },
	"Endwalker": { EN: "Endwalker", FR: null, DE: null, JP: "暁月のフィナーレ" },
	"The Imperfect Gentleman": { EN: "The Imperfect Gentleman", FR: null, DE: null, JP: null },
	"Make it a Manderville": { EN: "Make it a Manderville", FR: null, DE: null, JP: null },
	"Make Another Manderville": { EN: "Make Another Manderville", FR: null, DE: null, JP: null },
	"Oddness in the End": { EN: "Oddness in the End", FR: null, DE: null, JP: null },
	"In Everyone's Best Interests": { EN: "In Everyone's Best Interests", FR: null, DE: null, JP: null },
	"A Sign of What's to Come": { EN: "A Sign of What's to Come", FR: null, DE: null, JP: null },
	"Fit for a Queen": { EN: "Fit for a Queen", FR: null, DE: null, JP: null },
	"A New Playing Field": { EN: "A New Playing Field", FR: null, DE: null, JP: null },
	"Generation Bonding": { EN: "Generation Bonding", FR: null, DE: null, JP: null },
	"Well-oiled": { EN: "Well-oiled", FR: null, DE: null, JP: null },
	"The Next Mander-level": { EN: "The Next Mander-level", FR: null, DE: null, JP: null },
	"The Crystalline Mean": { EN: "The Crystalline Mean", FR: null, DE: null, JP: null },
	"An Original Improvement": { EN: "An Original Improvement", FR: null, DE: null, JP: null },
	"A Dedicated Tool": { EN: "A Dedicated Tool", FR: null, DE: null, JP: null },
	"An Adaptive Tool": { EN: "An Adaptive Tool", FR: null, DE: null, JP: null },
	"Just Tooling Around": { EN: "Just Tooling Around", FR: null, DE: null, JP: null },
	"Saving Captain Gairhard": { EN: "Saving Captain Gairhard", FR: null, DE: null, JP: null },
	"Waiting in the Winglet": { EN: "Waiting in the Winglet", FR: null, DE: null, JP: null },
	"Rivalry and Respect": { EN: "Rivalry and Respect", FR: null, DE: null, JP: null },
	"Jaded": { EN: "Jaded", FR: null, DE: null, JP: null },
	"Accept No Imitations": { EN: "Accept No Imitations", FR: null, DE: null, JP: null },
	"A Miner Reborn": { EN: "A Miner Reborn", FR: null, DE: null, JP: null },
	"Momentary Miracle": { EN: "Momentary Miracle", FR: null, DE: null, JP: null },
	"Revenge of the Chefsbane": { EN: "Revenge of the Chefsbane", FR: null, DE: null, JP: null },
	"Canyon of Regret": { EN: "Canyon of Regret", FR: null, DE: null, JP: null },
	"Seeds of Hope": { EN: "Seeds of Hope", FR: null, DE: null, JP: null },
	"So Long, and Thanks for All the Fish": { EN: "So Long, and Thanks for All the Fish", FR: null, DE: null, JP: null },
	"A Spirited Reforging": { EN: "A Spirited Reforging", FR: null, DE: null, JP: null },
	"In Need of Adjustment": { EN: "In Need of Adjustment", FR: null, DE: null, JP: null },
	"A Tool of Her Own": { EN: "A Tool of Her Own", FR: null, DE: null, JP: null },
	"A Tool without Compare": { EN: "A Tool without Compare", FR: null, DE: null, JP: null },
	"A Tool for the Ages": { EN: "A Tool for the Ages", FR: null, DE: null, JP: null },
	"Stand Tool, My Friend": { EN: "Stand Tool, My Friend", FR: null, DE: null, JP: null },
	"Gentlemen at Heart": { EN: "Gentlemen at Heart", FR: null, DE: null, JP: null },
	"Resonating with Perfection": { EN: "Resonating with Perfection", FR: null, DE: null, JP: null },
	"Positively Mandervillous": { EN: "Positively Mandervillous", FR: null, DE: null, JP: null },
	//#endregion Quests

	//#region Relic Related

	//#region Zodiac

	// Animus
	"Skyfire I": { EN: "Skyfire I", FR: "Feu céleste I", DE: null, JP: null },
	"Skyfire II": { EN: "Skyfire II", FR: "Feu céleste II", DE: null, JP: null },
	"Netherfire I": { EN: "Netherfire I", FR: "Feu infernal I", DE: null, JP: null },
	"Skyfall I": { EN: "Skyfall I", FR: "Eau céleste I", DE: null, JP: null },
	"Skyfall II": { EN: "Skyfall II", FR: "Eau céleste II", DE: null, JP: null },
	"Netherfall I": { EN: "Netherfall I", FR: "Eau infernale I", DE: null, JP: "水獄一巻" },
	"Skywind I": { EN: "Skywind I", FR: "Vent céleste I", DE: null, JP: null },
	"Skywind II": { EN: "Skywind II", FR: "Vent céleste II", DE: null, JP: null },
	"Skyearth I": { EN: "Skyearth I", FR: "Terre céleste I", DE: null, JP: null },
	// Novus
	"Sphere Scroll": { EN: "Sphere Scroll", FR: "Parchemin stellaire", DE: null, JP: "スフィアスクロール" },
	// Zodiac Braves
	"Book of Skylight": { EN: "Book of Skylight", FR: "Livre du ciel étoilé", DE: "Tafel des Sternenhimmels", JP: "黄道十二文書:星天" },
	"Zodium": { EN: "Zodium", FR: "Matière zodiacale", DE: "Zodium", JP: "ゾディアックマター" },
	"Flawless Alexandrite": { EN: "Flawless Alexandrite", FR: "Alexandrite immaculée", DE: "Purer Alexandrit", JP: "無垢のアレキサンドライト" },
	"Zodiac Scroll": { EN: "Zodiac Scroll", FR: "Parchemin zodiacal", DE: "Zodiak-Sphärentafel", JP: "ゾディアックスクロール" },
	// Zodiac Zeta
	"Mahatma": { EN: "Mahatma", FR: "Mahatma", DE: null, JP: null },
	//#endregion Zodiac

	//#region Anima
	"Enchanted Rubber": { EN: "Enchanted Rubber", FR: "Caoutchouc enchanté", DE: "Zaubergummi", JP: "エンチャントラバー" },
	"Fast-drying Carboncoat": { EN: "Fast-drying Carboncoat", FR: "Agent solidifiant allagois supérieur", DE: "Hochwertiger Karbonlack", JP: "アラグの上級硬化薬" },
	"Divine Water": { EN: "Divine Water", FR: "Eau divine", DE: "Weihwasser", JP: "神聖水" },
	"Fast-acting Allagan Catalyst": { EN: "Fast-acting Allagan Catalyst", FR: "Catalyseur allagois supérieur", DE: "Hochwertiger allagischer Katalysator", JP: "アラグの上級魔触媒" },
	//#endregion Anima

	//#region Eureka
	"Pagos": { EN: "Pagos", FR: "Pagos", DE: null, JP: null },
	"Pagos + 1": { EN: "Pagos + 1", FR: "Pagos +1", DE: null, JP: null },
	"Elemental": { EN: "Elemental", FR: null, DE: null, JP: null },
	"Elemental + 1": { EN: "Elemental + 1", FR: null, DE: null, JP: null },
	"Elemental + 2": { EN: "Elemental + 2", FR: null, DE: null, JP: null },
	"Pyros": { EN: "Pyros", FR: "Pyros", DE: null, JP: null },
	"Hydatos": { EN: "Hydatos", FR: "Hydatos", DE: null, JP: null },
	"Hydatos + 1": { EN: "Hydatos + 1", FR: null, DE: null, JP: null },
	//#endregion Eureka

	//#endregion Relic Related

	//#region Relic Groups
	"g_w_zodiac": { EN: "Zodiac", FR: "Zodiaque", DE: "Zodiak", JP: "ゾディアックウェポン" },
	"g_w_anima": { EN: "Anima", FR: "Anima", DE: "Anima", JP: "アニマ" },
	"g_w_eureka": { EN: "Eureka", FR: "Eurêka", DE: "Eureka", JP: "エウレカウェポン" },
	"g_w_resistance": { EN: "Resistance", FR: "Garde-la-Reine", DE: null, JP: "レジスタンスウェポン" },
	"g_w_manderville": { EN: "Manderville", FR: "Manderville", DE: null, JP: "マンダヴィルウェポン" },
	"g_t_lucis": { EN: "Lucis", FR: null, DE: null, JP: null },
	"g_t_skysteel": { EN: "Skysteel", FR: "Cielacier", DE: null, JP: "スカイスチール" },
	"g_t_splendorous": { EN: "Splendorous", FR: null, DE: null, JP: null },
	"g_a_anemos": { EN: "Anemos", FR: "Anemos", DE: null, JP: "アネモス" },
	"g_a_elemental": { EN: "Elemental", FR: null, DE: null, JP: "エレメンタル" },
	"g_a_bozjan": { EN: "Bozjan", FR: "Arme de Bozja", DE: null, JP: "ボズヤ" },
	"g_a_laws_order": { EN: "Law's Order", FR: "Verdict des Juges", DE: null, JP: null },
	"g_a_blades": { EN: "Blades", FR: null, DE: null, JP: null },
	//#endregion Relic Groups

	//#region Relic Parts
	"p_w_base_weapon": { EN: "Base Weapon", FR: "Arme de base", DE: null, JP: "レリック" }, // Zodiac
	"p_w_zenith": { EN: "Zenith", FR: "Zénith", DE: null, JP: "ゼニス" },
	"p_w_atma": { EN: "Atma", FR: "Atma", DE: null, JP: "アートマ" },
	"p_w_animus": { EN: "Animus", FR: "Animus", DE: null, JP: "アニムス" },
	"p_w_novus": { EN: "Novus", FR: "Novus", DE: null, JP: "ノウス" },
	"p_w_nexus": { EN: "Nexus", FR: "Nexus", DE: null, JP: "ネクサス" },
	"p_w_braves": { EN: "Zodiac Braves", FR: "Zodiaque", DE: null, JP: "ゾディアックウェポン" },
	"p_w_zeta": { EN: "Zodiac Zeta", FR: "Zodiaque Zêta", DE: null, JP: "ゾディアックウェポン・ゼータ" },
	"p_w_animated": { EN: "Animated", FR: "Animé", DE: null, JP: null }, // Anima
	"p_w_awoken": { EN: "Awoken", FR: "Eveillé", DE: null, JP: null },
	"p_w_anima": { EN: "Anima", FR: "Anima", DE: "Anima", JP: "アニマ" },
	"p_w_hyperconductive": { EN: "Hyperconductive", FR: "Hyperconducteur", DE: null, JP: null },
	"p_w_reconditioned": { EN: "Reconditioned", FR: "Epanoui", DE: null, JP: null },
	"p_w_sharpened": { EN: "Sharpened", FR: "Vivifié", DE: null, JP: null },
	"p_w_complete": { EN: "Complete", FR: "Parachevé", DE: null, JP: null },
	"p_w_lux": { EN: "Lux", FR: "Lux", DE: null, JP: null },
	"p_w_antiquated": { EN: "Antiquated", FR: "Vieilli", DE: null, JP: "ウェザード" }, // Eureka
	"p_w_anemos": { EN: "Anemos", FR: "Anemos", DE: null, JP: null },
	"p_w_elemental": { EN: "Elemental", FR: null, DE: null, JP: null },
	"p_w_pyros": { EN: "Pyros", FR: "Pyros", DE: null, JP: "ピューロス" },
	"p_w_eureka": { EN: "Eureka", FR: "Eurêka", DE: "Eureka", JP: "エウレカ" },
	"p_w_physeos": { EN: "Physeos", FR: null, DE: null, JP: "カスタム" },
	"p_w_resistance": { EN: "Resistance", FR: "Garde-la-Reine", DE: null, JP: "レジスタンス" }, // Resistance
	"p_w_augmented_resistance": { EN: "Augmented Resistance", FR: "Resistance augmenté", DE: null, JP: null },
	"p_w_recollection": { EN: "Recollection", FR: "in memoriam", DE: null, JP: "リコレクション" },
	"p_w_laws_order": { EN: "Law's Order", FR: "Verdict des Juges", DE: null, JP: "ジャッジオーダー" },
	"p_w_augmented_laws_order": { EN: "Augmented Law's Order", FR: "Verdict des Juges améliorée", DE: null, JP: "ジャッジオーダーRE" },
	"p_w_blade": { EN: "Blade", FR: "Gunhildr", DE: null, JP: "ブレイズ" },
	"p_w_manderville": { EN: "Manderville", FR: "Manderville", DE: null, JP: "マンダヴィル" }, // Manderville
	"p_w_amazing_manderville": { EN: "Amazing Manderville", FR: "Manderville éblouissant", DE: null, JP: "アメージング" },
	"p_w_majestic_manderville": { EN: "Majestic Manderville", FR: "Arme majestueuse des Manderville", DE: null, JP: "マジスティック" },
	"p_w_mandervillous": { EN: "Mandervillous", FR: null, DE: null, JP: null },
	"p_t_mastercraft": { EN: "Mastercraft", FR: null, DE: null, JP: null }, // Lucis
	"p_t_supra": { EN: "Supra", FR: null, DE: null, JP: null },
	"p_t_lucis": { EN: "Lucis", FR: null, DE: null, JP: null },
	"p_t_skysteel": { EN: "Skysteel", FR: "Cielacier", DE: null, JP: "スカイスチール" }, // Skysteel
	"p_t_skysteel_1": { EN: "Skysteel + 1", FR: null, DE: null, JP: null },
	"p_t_dragonsung": { EN: "Dragonsung", FR: null, DE: null, JP: null },
	"p_t_augmented_dragonsung": { EN: "Augmented Dragonsung", FR: null, DE: null, JP: null },
	"p_t_skysung": { EN: "Skysung", FR: null, DE: null, JP: null },
	"p_t_skybuilders": { EN: "Skybuilders", FR: null, DE: null, JP: null },
	"p_t_splendorous": { EN: "Splendorous", FR: null, DE: null, JP: null }, // Splendrous
	"p_t_augmented_splendorous": { EN: "Augmented Splendorous", FR: null, DE: null, JP: null },
	"p_t_crystalline": { EN: "Crystalline", FR: null, DE: null, JP: null },
	"p_t_chora_zois_crystalline": { EN: "Chora Zoi's Crystalline", FR: null, DE: null, JP: null },
	"p_t_brilliant": { EN: "Brilliant", FR: null, DE: null, JP: null },
	"p_t_vrandtic_visionarys": { EN: "Vrandtic Visionary's", FR: null, DE: null, JP: null },
	"p_t_lodestar": { EN: "Lodestar", FR: null, DE: null, JP: null },
	"p_a_antiquated": { EN: "Antiquated", FR: "Vieilli", DE: null, JP: null }, // Eureka Anemos
	"p_a_base_0": { EN: "Armour + 0", FR: null, DE: null, JP: null },
	"p_a_base_1": { EN: "Armour + 1", FR: null, DE: null, JP: null },
	"p_a_base_2": { EN: "Armour + 2", FR: null, DE: null, JP: null },
	"p_a_anemos": { EN: "Anemos", FR: "Anemos", DE: null, JP: null },
	"p_a_elemental": { EN: "Elemental", FR: null, DE: null, JP: null }, // Eureka Elemental
	"p_a_elemental_1": { EN: "Elemental + 1", FR: null, DE: null, JP: null },
	"p_a_elemental_2": { EN: "Elemental + 2", FR: null, DE: null, JP: null },
	"p_a_bozjan": { EN: "Bozjan", FR: "Arme de Bozja", DE: null, JP: null }, // Resistance Bozjan
	"p_a_augmented_bozjan": { EN: "Augmented Bozjan", FR: "Arme de Bozja Amélioré", DE: null, JP: null },
	"p_a_laws_order": { EN: "Law's Order", FR: "Verdict des Juges", DE: null, JP: null }, // Resistance Law's Order
	"p_a_augmented_laws_order": { EN: "Augmented Law's Order", FR: "Verdict des Juges améliorée", DE: null, JP: null },
	"p_a_blades": { EN: "Blades", FR: "Gunhildr", DE: null, JP: null },
	//#endregion Relic Parts

	//#region Relic Names

	//#region Weapon - Zodiac

	//#region Base Weapon
	"relicname-PLD-p_w_base_weapon": { EN: "Curtana & Holy Shield", FR: "Curtana et Bouclier saint", DE: null, JP: null },
	"relicname-MNK-p_w_base_weapon": { EN: "Sphairai", FR: "Sphairai", DE: null, JP: "スファライ" },
	"relicname-WAR-p_w_base_weapon": { EN: "Bravura", FR: "Bravura", DE: null, JP: "ブラビューラ" },
	"relicname-DRG-p_w_base_weapon": { EN: "Gae Bolg", FR: "Gae Bolg", DE: null, JP: "ゲイボルグ" },
	"relicname-BRD-p_w_base_weapon": { EN: "Artemis Bow", FR: "Arc d'Artemis", DE: null, JP: "アルテミスボウ" },
	"relicname-BLM-p_w_base_weapon": { EN: "Stardust Rod", FR: "Sceptre étoilé", DE: null, JP: "スターダストロッド" },
	"relicname-SMN-p_w_base_weapon": { EN: "The Veil of Wiyu", FR: "Œilvert", DE: null, JP: "ウイユヴェール" },
	"relicname-SCH-p_w_base_weapon": { EN: "Omnilex", FR: "Omnilex", DE: null, JP: "マダレムジエン" },
	"relicname-WHM-p_w_base_weapon": { EN: "Thyrus", FR: "Thyrse", DE: null, JP: "タイラス" },
	"relicname-NIN-p_w_base_weapon": { EN: "Yoshimitsu", FR: "Yoshimitsu", DE: null, JP: "吉光" },
	//#endregion Base Weapon

	//#region Zenith
	"relicname-PLD-p_w_zenith": { EN: "Curtana Zenith & Holy Shield Zenith", FR: "Curtana Zénith & Bouclier Saint Zénith", DE: null, JP: null },
	"relicname-MNK-p_w_zenith": { EN: "Sphairai Zenith", FR: "Sphairai Zénith", DE: null, JP: "スファライ・ゼニス" },
	"relicname-WAR-p_w_zenith": { EN: "Bravura Zenith", FR: "Bravura Zénith", DE: null, JP: "ブラビューラ・ゼニス" },
	"relicname-DRG-p_w_zenith": { EN: "Gae Bolg Zenith", FR: "Gae Bolg Zénith", DE: null, JP: "ゲイボルグ・ゼニス" },
	"relicname-BRD-p_w_zenith": { EN: "Artemis Bow Zenith", FR: "Arc D'Artemis Zénith", DE: null, JP: "アルテミスボウ・ゼニス" },
	"relicname-BLM-p_w_zenith": { EN: "Stardust Rod Zenith", FR: "Sceptre Etoilé Zénith", DE: null, JP: "スターダストロッド・ゼニス" },
	"relicname-SMN-p_w_zenith": { EN: "The Veil of Wiyu Zenith", FR: "Œilvert Zénith", DE: null, JP: "ウイユヴェール・ゼニス" },
	"relicname-SCH-p_w_zenith": { EN: "Omnilex Zenith", FR: "Omnilex Zénith", DE: null, JP: "マダレムジエン・ゼニス" },
	"relicname-WHM-p_w_zenith": { EN: "Thyrus Zenith", FR: "Thyrse Zénith", DE: null, JP: "タイラス・ゼニス" },
	"relicname-NIN-p_w_zenith": { EN: "Yoshimitsu Zenith", FR: "Yoshimitsu Zénith", DE: null, JP: "吉光 【改】" },
	//#endregion Zenith

	//#region Atma
	"relicname-PLD-p_w_atma": { EN: "Curtana Atma & Holy Shield Atma", FR: "Curtana Atma & Bouclier Saint Atma", DE: null, JP: null },
	"relicname-MNK-p_w_atma": { EN: "Sphairai Atma", FR: "Sphairai Atma", DE: null, JP: "スファライ・アートマ" },
	"relicname-WAR-p_w_atma": { EN: "Bravura Atma", FR: "Bravura Atma", DE: null, JP: "ブラビューラ" },
	"relicname-DRG-p_w_atma": { EN: "Gae Bolg Atma", FR: "Gae Bolg Atma", DE: null, JP: "ゲイボルグ・アートマ" },
	"relicname-BRD-p_w_atma": { EN: "Artemis Bow Atma", FR: "Arc d'Artemis Atma", DE: null, JP: "アルテミスボウ・アートマ" },
	"relicname-BLM-p_w_atma": { EN: "Stardust Rod Atma", FR: "Sceptre Etoilé Atma", DE: null, JP: "スターダストロッド" },
	"relicname-SMN-p_w_atma": { EN: "The Veil of Wiyu Atma", FR: "Œilvert Atma", DE: null, JP: "ウイユヴェール・アートマ" },
	"relicname-SCH-p_w_atma": { EN: "Omnilex Atma", FR: "Omnilex Atma", DE: null, JP: "マダレムジエン・アートマ" },
	"relicname-WHM-p_w_atma": { EN: "Thyrus Atma", FR: "Thyrse Atma", DE: null, JP: "タイラス・アートマ" },
	"relicname-NIN-p_w_atma": { EN: "Yoshimitsu Atma", FR: "Yoshimitsu Atma", DE: null, JP: "吉光 【真我】" },
	//#endregion Atma

	//#region Animus
	"relicname-PLD-p_w_animus": { EN: "Curtana Animus & Holy Shield Animus", FR: "Curtana Animus & Bouclier Saint Animus", DE: null, JP: null },
	"relicname-MNK-p_w_animus": { EN: "Sphairai Animus", FR: "Sphairai Animus", DE: null, JP: "スファライ" },
	"relicname-WAR-p_w_animus": { EN: "Bravura Animus", FR: "Bravura Animus", DE: null, JP: "ブラビューラ・アニムス" },
	"relicname-DRG-p_w_animus": { EN: "Gae Bolg Animus", FR: "Gae Bolg Animus", DE: null, JP: "ゲイボルグ・アニムス" },
	"relicname-BRD-p_w_animus": { EN: "Artemis Bow Animus", FR: "Arc D'Artemis Animus", DE: null, JP: "アルテミスボウ" },
	"relicname-BLM-p_w_animus": { EN: "Stardust Rod Animus", FR: "Sceptre Etoilé Animus", DE: null, JP: "スターダストロッド・アニムス" },
	"relicname-SMN-p_w_animus": { EN: "The Veil of Wiyu Animus", FR: "Œilvert Animus", DE: null, JP: "ウイユヴェール・アニムス" },
	"relicname-SCH-p_w_animus": { EN: "Omnilex Animus", FR: "Omnilex Animus", DE: null, JP: "マダレムジエン" },
	"relicname-WHM-p_w_animus": { EN: "Thyrus Animus", FR: "Thyrse Animus", DE: null, JP: "タイラス・アニムス" },
	"relicname-NIN-p_w_animus": { EN: "Yoshimitsu Animus", FR: "Yoshimitsu Animus", DE: null, JP: null },
	//#endregion Animus

	//#region Novus
	"relicname-PLD-p_w_novus": { EN: "Curtana Novus & Holy Shield Novus", FR: "Curtana Novus & Bouclier Saint Novus", DE: null, JP: null },
	"relicname-MNK-p_w_novus": { EN: "Sphairai Novus", FR: "Sphairai Novus", DE: null, JP: "スファライ・ノウス" },
	"relicname-WAR-p_w_novus": { EN: "Bravura Novus", FR: "Bravura Novus", DE: null, JP: "ブラビューラ" },
	"relicname-DRG-p_w_novus": { EN: "Gae Bolg Novus", FR: "Gae Bolg Novus", DE: null, JP: "ゲイボルグ・ノウス" },
	"relicname-BRD-p_w_novus": { EN: "Artemis Bow Novus", FR: "Arc d'Artemis Novus", DE: null, JP: "アルテミスボウ・ノウス" },
	"relicname-BLM-p_w_novus": { EN: "Stardust Rod Novus", FR: "Sceptre Etoilé Novus", DE: null, JP: "スターダストロッド・ノウス" },
	"relicname-SMN-p_w_novus": { EN: "The Veil of Wiyu Novus", FR: "Œilvert Novus", DE: null, JP: "ウイユヴェール・ノウス" },
	"relicname-SCH-p_w_novus": { EN: "Omnilex Novus", FR: "Omnilex Novus", DE: null, JP: "マダレムジエン・ノウス" },
	"relicname-WHM-p_w_novus": { EN: "Thyrus Novus", FR: "Thyrse Novus", DE: null, JP: "タイラス・ノウス" },
	"relicname-NIN-p_w_novus": { EN: "Yoshimitsu Novus", FR: "Yoshimitsu Novus", DE: null, JP: null },
	//#endregion Novus

	//#region Nexus
	"relicname-PLD-p_w_nexus": { EN: "Curtana Nexus & Holy Shield Nexus", FR: "Curtana Nexus & Bouclier Saint Nexus", DE: null, JP: null },
	"relicname-MNK-p_w_nexus": { EN: "Sphairai Nexus", FR: "Sphairai Nexus", DE: null, JP: "スファライ・ネクサス" },
	"relicname-WAR-p_w_nexus": { EN: "Bravura Nexus", FR: "Bravura Nexus", DE: null, JP: "ブラビューラ・ネクサス" },
	"relicname-DRG-p_w_nexus": { EN: "Gae Bolg Nexus", FR: "Gae Bolg Nexus", DE: null, JP: "ゲイボルグ・ネクサス" },
	"relicname-BRD-p_w_nexus": { EN: "Artemis Bow Nexus", FR: "Arc d'Artemis Nexus", DE: null, JP: "アルテミスボウ" },
	"relicname-BLM-p_w_nexus": { EN: "Stardust Rod Nexus", FR: "Sceptre Etoilé Nexus", DE: null, JP: "スターダストロッド" },
	"relicname-SMN-p_w_nexus": { EN: "The Veil of Wiyu Nexus", FR: "Œilvert Nexus", DE: null, JP: "ウイユヴェール・ネクサス" },
	"relicname-SCH-p_w_nexus": { EN: "Omnilex Nexus", FR: "Omnilex Nexus", DE: null, JP: "マダレムジエン" },
	"relicname-WHM-p_w_nexus": { EN: "Thyrus Nexus", FR: "Thyrse Nexus", DE: null, JP: "タイラス・ネクサス" },
	"relicname-NIN-p_w_nexus": { EN: "Yoshimitsu Nexus", FR: "Yoshimitsu Nexus", DE: null, JP: null },
	//#endregion Nexus

	//#region Braves
	"relicname-PLD-p_w_braves": { EN: "Excalibur & Aegis Shield", FR: "Excalibur & Bouclier Aegis", DE: null, JP: null },
	"relicname-MNK-p_w_braves": { EN: "Kaiser Knuckles", FR: "Griffes Kaiser", DE: null, JP: null },
	"relicname-WAR-p_w_braves": { EN: "Ragnarok", FR: "Ragnarok", DE: null, JP: null },
	"relicname-DRG-p_w_braves": { EN: "Longinus", FR: "Longinus", DE: null, JP: null },
	"relicname-BRD-p_w_braves": { EN: "Yoichi Bow", FR: "Arc de Yoichi", DE: null, JP: null },
	"relicname-BLM-p_w_braves": { EN: "Lilith Rod", FR: "Sceptre de Lilith", DE: null, JP: null },
	"relicname-SMN-p_w_braves": { EN: "Apocalypse", FR: "Apocalypse", DE: null, JP: null },
	"relicname-SCH-p_w_braves": { EN: "Last Resort", FR: "Dernier Recours", DE: null, JP: null },
	"relicname-WHM-p_w_braves": { EN: "Nirvana", FR: "Nirvâna", DE: null, JP: null },
	"relicname-NIN-p_w_braves": { EN: "Sasuke's Blades", FR: "Sasuke", DE: null, JP: null },
	//#endregion Braves

	//#region Zeta
	"relicname-PLD-p_w_zeta": { EN: "Excalibur Zeta & Aegis Shield Zeta", FR: "Excalibur Zêta & Aegis Zêta", DE: null, JP: null },
	"relicname-MNK-p_w_zeta": { EN: "Kaiser Knuckles Zeta", FR: "Griffes Kaiser Zêta", DE: null, JP: null },
	"relicname-WAR-p_w_zeta": { EN: "Ragnarok Zeta", FR: "Ragnarok Zêta", DE: null, JP: null },
	"relicname-DRG-p_w_zeta": { EN: "Longinus Zeta", FR: "Longinus Zêta", DE: null, JP: null },
	"relicname-BRD-p_w_zeta": { EN: "Yoichi Bow Zeta", FR: "Arc de Yoichi Zêta", DE: null, JP: null },
	"relicname-BLM-p_w_zeta": { EN: "Lilith Rod Zeta", FR: "Sceptre de Lilith Zêta", DE: null, JP: null },
	"relicname-SMN-p_w_zeta": { EN: "Apocalypse Zeta", FR: "Apocalypse Zêta", DE: null, JP: null },
	"relicname-SCH-p_w_zeta": { EN: "Last Resort Zeta", FR: "Dernier Recours Zêta", DE: null, JP: null },
	"relicname-WHM-p_w_zeta": { EN: "Nirvana Zeta", FR: "Nirvâna Zêta", DE: null, JP: null },
	"relicname-NIN-p_w_zeta": { EN: "Sasuke's Blades Zeta", FR: "Sasuke Zêta", DE: null, JP: null },
	//#endregion Zeta

	//#endregion Weapon - Zodiac

	//#region Weapon - Anima

	//#region Animated
	"relicname-PLD-p_w_animated": { EN: "Animated Hauteclaire & Animated Prytwen", FR: null, DE: null, JP: null },
	"relicname-MNK-p_w_animated": { EN: "Animated Rising Suns", FR: null, DE: null, JP: null },
	"relicname-WAR-p_w_animated": { EN: "Animated Parashu", FR: null, DE: null, JP: null },
	"relicname-DRG-p_w_animated": { EN: "Animated Brionac", FR: null, DE: null, JP: null },
	"relicname-BRD-p_w_animated": { EN: "Animated Berimbau", FR: null, DE: null, JP: null },
	"relicname-BLM-p_w_animated": { EN: "Animated Lunaris Rod", FR: null, DE: null, JP: null },
	"relicname-SMN-p_w_animated": { EN: "Animated Almandal", FR: null, DE: null, JP: null },
	"relicname-SCH-p_w_animated": { EN: "Animated Elements", FR: null, DE: null, JP: null },
	"relicname-WHM-p_w_animated": { EN: "Animated Seraph Cane", FR: null, DE: null, JP: null },
	"relicname-NIN-p_w_animated": { EN: "Animated Yukimitsu", FR: null, DE: null, JP: null },
	"relicname-DRK-p_w_animated": { EN: "Animated Deathbringer", FR: null, DE: null, JP: null },
	"relicname-AST-p_w_animated": { EN: "Animated Atlas", FR: null, DE: null, JP: null },
	"relicname-MCH-p_w_animated": { EN: "Animated Ferdinand", FR: null, DE: null, JP: null },
	//#endregion Animated

	//#region Awoken
	"relicname-PLD-p_w_awoken": { EN: "Hauteclaire Awoken & Prytwen Awoken", FR: null, DE: null, JP: null },
	"relicname-MNK-p_w_awoken": { EN: "Rising Suns Awoken", FR: null, DE: null, JP: null },
	"relicname-WAR-p_w_awoken": { EN: "Parashu Awoken", FR: null, DE: null, JP: null },
	"relicname-DRG-p_w_awoken": { EN: "Brionac Awoken", FR: null, DE: null, JP: null },
	"relicname-BRD-p_w_awoken": { EN: "Berimbau Awoken", FR: null, DE: null, JP: null },
	"relicname-BLM-p_w_awoken": { EN: "Lunaris Rod Awoken", FR: null, DE: null, JP: null },
	"relicname-SMN-p_w_awoken": { EN: "Almandal Awoken", FR: null, DE: null, JP: null },
	"relicname-SCH-p_w_awoken": { EN: "Elements Awoken", FR: null, DE: null, JP: null },
	"relicname-WHM-p_w_awoken": { EN: "Seraph Cane Awoken", FR: null, DE: null, JP: null },
	"relicname-NIN-p_w_awoken": { EN: "Yukimitsu Awoken", FR: null, DE: null, JP: null },
	"relicname-DRK-p_w_awoken": { EN: "Deathbringer Awoken", FR: null, DE: null, JP: null },
	"relicname-AST-p_w_awoken": { EN: "Atlas Awoken", FR: null, DE: null, JP: null },
	"relicname-MCH-p_w_awoken": { EN: "Ferdinand Awoken", FR: null, DE: null, JP: null },
	//#endregion Awoken

	//#region Anima
	"relicname-PLD-p_w_anima": { EN: "Almace & Ancile", FR: null, DE: null, JP: null },
	"relicname-MNK-p_w_anima": { EN: "Verethragna", FR: null, DE: null, JP: null },
	"relicname-WAR-p_w_anima": { EN: "Ukonvasara", FR: null, DE: null, JP: null },
	"relicname-DRG-p_w_anima": { EN: "Rhongomiant", FR: null, DE: null, JP: null },
	"relicname-BRD-p_w_anima": { EN: "Gandiva", FR: null, DE: null, JP: null },
	"relicname-BLM-p_w_anima": { EN: "Hvergelmir", FR: null, DE: null, JP: null },
	"relicname-SMN-p_w_anima": { EN: "Draconomicon", FR: null, DE: null, JP: null },
	"relicname-SCH-p_w_anima": { EN: "Tetrabiblos", FR: null, DE: null, JP: null },
	"relicname-WHM-p_w_anima": { EN: "Majestas", FR: null, DE: null, JP: null },
	"relicname-NIN-p_w_anima": { EN: "Kannagi", FR: null, DE: null, JP: null },
	"relicname-DRK-p_w_anima": { EN: "Nothung", FR: null, DE: null, JP: null },
	"relicname-AST-p_w_anima": { EN: "Deneb", FR: null, DE: null, JP: null },
	"relicname-MCH-p_w_anima": { EN: "Armageddon", FR: null, DE: null, JP: null },
	//#endregion Anima

	//#region Hyperconductive
	"relicname-PLD-p_w_hyperconductive": { EN: "Hyperconductive Almace & Hyperconductive Ancile", FR: null, DE: null, JP: null },
	"relicname-MNK-p_w_hyperconductive": { EN: "Hyperconductive Verethragna", FR: null, DE: null, JP: null },
	"relicname-WAR-p_w_hyperconductive": { EN: "Hyperconductive Ukonvasara", FR: null, DE: null, JP: null },
	"relicname-DRG-p_w_hyperconductive": { EN: "Hyperconductive Rhongomiant", FR: null, DE: null, JP: null },
	"relicname-BRD-p_w_hyperconductive": { EN: "Hyperconductive Gandiva", FR: null, DE: null, JP: null },
	"relicname-BLM-p_w_hyperconductive": { EN: "Hyperconductive Hvergelmir", FR: null, DE: null, JP: null },
	"relicname-SMN-p_w_hyperconductive": { EN: "Hyperconductive Draconomicon", FR: null, DE: null, JP: null },
	"relicname-SCH-p_w_hyperconductive": { EN: "Hyperconductive Tetrabiblos", FR: null, DE: null, JP: null },
	"relicname-WHM-p_w_hyperconductive": { EN: "Hyperconductive Majestas", FR: null, DE: null, JP: null },
	"relicname-NIN-p_w_hyperconductive": { EN: "Hyperconductive Kannagi", FR: null, DE: null, JP: null },
	"relicname-DRK-p_w_hyperconductive": { EN: "Hyperconductive Nothung", FR: null, DE: null, JP: null },
	"relicname-AST-p_w_hyperconductive": { EN: "Hyperconductive Deneb", FR: null, DE: null, JP: null },
	"relicname-MCH-p_w_hyperconductive": { EN: "Hyperconductive Armageddon", FR: null, DE: null, JP: null },
	//#endregion Hyperconductive

	//#region Reconditioned
	"relicname-PLD-p_w_reconditioned": { EN: "Sword of the Twin Thegns & Shield of the Twin Thegns", FR: null, DE: null, JP: null },
	"relicname-MNK-p_w_reconditioned": { EN: "Sultan's Fists", FR: null, DE: null, JP: null },
	"relicname-WAR-p_w_reconditioned": { EN: "Axe of the Blood Emperor", FR: null, DE: null, JP: null },
	"relicname-DRG-p_w_reconditioned": { EN: "Trident of the Overlord", FR: null, DE: null, JP: null },
	"relicname-BRD-p_w_reconditioned": { EN: "Bow of the Autarch", FR: null, DE: null, JP: null },
	"relicname-BLM-p_w_reconditioned": { EN: "Rod of the Black Khan", FR: null, DE: null, JP: null },
	"relicname-SMN-p_w_reconditioned": { EN: "Book of the Mad Queen", FR: null, DE: null, JP: null },
	"relicname-SCH-p_w_reconditioned": { EN: "Word of the Magnate", FR: null, DE: null, JP: null },
	"relicname-WHM-p_w_reconditioned": { EN: "Cane of the White Tsar", FR: null, DE: null, JP: null },
	"relicname-NIN-p_w_reconditioned": { EN: "Spurs of the Thorn Prince", FR: null, DE: null, JP: null },
	"relicname-DRK-p_w_reconditioned": { EN: "Guillotine of the Tyrant", FR: null, DE: null, JP: null },
	"relicname-AST-p_w_reconditioned": { EN: "Sphere of the Last Heir", FR: null, DE: null, JP: null },
	"relicname-MCH-p_w_reconditioned": { EN: "Flame of the Dynast", FR: null, DE: null, JP: null },
	//#endregion Reconditioned

	//#region Sharpened
	"relicname-PLD-p_w_sharpened": { EN: "Sharpened Sword of the Twin Thegns & Sharpened Shield of the Twin Thegns", FR: null, DE: null, JP: null },
	"relicname-MNK-p_w_sharpened": { EN: "Sharpened Sultan's Fists", FR: null, DE: null, JP: null },
	"relicname-WAR-p_w_sharpened": { EN: "Sharpened Axe of the Blood Emperor", FR: null, DE: null, JP: null },
	"relicname-DRG-p_w_sharpened": { EN: "Sharpened Trident of the Overlord", FR: null, DE: null, JP: null },
	"relicname-BRD-p_w_sharpened": { EN: "Sharpened Bow of the Autarch", FR: null, DE: null, JP: null },
	"relicname-BLM-p_w_sharpened": { EN: "Sharpened Rod of the Black Khan", FR: null, DE: null, JP: null },
	"relicname-SMN-p_w_sharpened": { EN: "Sharpened Book of the Mad Queen", FR: null, DE: null, JP: null },
	"relicname-SCH-p_w_sharpened": { EN: "Sharpened Word of the Magnate", FR: null, DE: null, JP: null },
	"relicname-WHM-p_w_sharpened": { EN: "Sharpened Cane of the White Tsar", FR: null, DE: null, JP: null },
	"relicname-NIN-p_w_sharpened": { EN: "Sharpened Spurs of the Thorn Prince", FR: null, DE: null, JP: null },
	"relicname-DRK-p_w_sharpened": { EN: "Sharpened Guillotine of the Tyrant", FR: null, DE: null, JP: null },
	"relicname-AST-p_w_sharpened": { EN: "Sharpened Sphere of the Last Heir", FR: null, DE: null, JP: null },
	"relicname-MCH-p_w_sharpened": { EN: "Sharpened Flame of the Dynast", FR: null, DE: null, JP: null },
	//#endregion Sharpened

	//#region Complete
	"relicname-PLD-p_w_complete": { EN: "Aettir & Priwen", FR: null, DE: null, JP: null },
	"relicname-MNK-p_w_complete": { EN: "Nyepel", FR: null, DE: null, JP: null },
	"relicname-WAR-p_w_complete": { EN: "Minos", FR: null, DE: null, JP: null },
	"relicname-DRG-p_w_complete": { EN: "Areadbhar", FR: null, DE: null, JP: null },
	"relicname-BRD-p_w_complete": { EN: "Terpander", FR: null, DE: null, JP: null },
	"relicname-BLM-p_w_complete": { EN: "Kaladanda", FR: null, DE: null, JP: null },
	"relicname-SMN-p_w_complete": { EN: "Mimesis", FR: null, DE: null, JP: null },
	"relicname-SCH-p_w_complete": { EN: "Anabasis", FR: null, DE: null, JP: null },
	"relicname-WHM-p_w_complete": { EN: "Sindri", FR: null, DE: null, JP: null },
	"relicname-NIN-p_w_complete": { EN: "Sandung", FR: null, DE: null, JP: null },
	"relicname-DRK-p_w_complete": { EN: "Cronus", FR: null, DE: null, JP: null },
	"relicname-AST-p_w_complete": { EN: "Canopus", FR: null, DE: null, JP: null },
	"relicname-MCH-p_w_complete": { EN: "Deathlocke", FR: null, DE: null, JP: null },
	//#endregion Complete

	//#region Lux
	"relicname-PLD-p_w_lux": { EN: "Aettir Lux & Priwen Lux", FR: null, DE: null, JP: null },
	"relicname-MNK-p_w_lux": { EN: "Nyepel Lux", FR: null, DE: null, JP: null },
	"relicname-WAR-p_w_lux": { EN: "Minos Lux", FR: null, DE: null, JP: null },
	"relicname-DRG-p_w_lux": { EN: "Areadbhar Lux", FR: null, DE: null, JP: null },
	"relicname-BRD-p_w_lux": { EN: "Terpander Lux", FR: null, DE: null, JP: null },
	"relicname-BLM-p_w_lux": { EN: "Kaladanda Lux", FR: null, DE: null, JP: null },
	"relicname-SMN-p_w_lux": { EN: "Mimesis Lux", FR: null, DE: null, JP: null },
	"relicname-SCH-p_w_lux": { EN: "Anabasis Lux", FR: null, DE: null, JP: null },
	"relicname-WHM-p_w_lux": { EN: "Sindri Lux", FR: null, DE: null, JP: null },
	"relicname-NIN-p_w_lux": { EN: "Sandung Lux", FR: null, DE: null, JP: null },
	"relicname-DRK-p_w_lux": { EN: "Cronus Lux", FR: null, DE: null, JP: null },
	"relicname-AST-p_w_lux": { EN: "Canopus Lux", FR: null, DE: null, JP: null },
	"relicname-MCH-p_w_lux": { EN: "Deathlocke Lux", FR: null, DE: null, JP: null },
	//#endregion Lux

	//#endregion Weapon - Anima

	//#region Weapon - Eureka

	//#region Antiquated
	"relicname-PLD-p_w_antiquated": { EN: "Antiquated Galatyn & Antiquated Evalach", FR: "Galatyn vieillie & Evalach vieilli", DE: null, JP: null },
	"relicname-MNK-p_w_antiquated": { EN: "Antiquated Sudarshana Chakra", FR: "Chakras Sudarshana vieillis", DE: null, JP: null },
	"relicname-WAR-p_w_antiquated": { EN: "Antiquated Farsha", FR: "Farsha vieillie", DE: null, JP: null },
	"relicname-DRG-p_w_antiquated": { EN: "Antiquated Ryunohige", FR: "Ryûnohige vieilli", DE: null, JP: null },
	"relicname-BRD-p_w_antiquated": { EN: "Antiquated Failnaught", FR: "Arc Qui-ne-faut vieilli", DE: null, JP: null },
	"relicname-BLM-p_w_antiquated": { EN: "Antiquated Vanargand", FR: "Vanargand vieilli", DE: null, JP: null },
	"relicname-SMN-p_w_antiquated": { EN: "Antiquated Lemegeton", FR: "Lemegeton vieilli", DE: null, JP: null },
	"relicname-SCH-p_w_antiquated": { EN: "Antiquated Organum", FR: "Organum vieilli", DE: null, JP: null },
	"relicname-WHM-p_w_antiquated": { EN: "Antiquated Aymur", FR: "Aymur vieilli", DE: null, JP: null },
	"relicname-NIN-p_w_antiquated": { EN: "Antiquated Nagi", FR: "Nagi vieillies", DE: null, JP: null },
	"relicname-DRK-p_w_antiquated": { EN: "Antiquated Caladbolg", FR: "Caladbolg vieilli", DE: null, JP: null },
	"relicname-AST-p_w_antiquated": { EN: "Antiquated Pleiades", FR: "Pléiade vieillie", DE: null, JP: null },
	"relicname-MCH-p_w_antiquated": { EN: "Antiquated Outsider", FR: "Outsider vieilli", DE: null, JP: null },
	"relicname-SAM-p_w_antiquated": { EN: "Antiquated Kiku-ichimonji", FR: "Kiku-Ichimonji vieilli", DE: null, JP: null },
	"relicname-RDM-p_w_antiquated": { EN: "Antiquated Murgleis", FR: "Murgleis vieillie", DE: null, JP: null },
	//#endregion Antiquated

	//#region Anemos
	"relicname-PLD-p_w_anemos": { EN: "Galatyn Anemos & Evalach Anemos", FR: "Galatyn Anemos & Evalach Anemos", DE: null, JP: null },
	"relicname-MNK-p_w_anemos": { EN: "Sudarshana Chakra Anemos", FR: "Chakras Sudarshana Anemos", DE: null, JP: null },
	"relicname-WAR-p_w_anemos": { EN: "Farsha Anemos", FR: "Farsha Anemos", DE: null, JP: null },
	"relicname-DRG-p_w_anemos": { EN: "Ryunohige Anemos", FR: "Ryûnohige Anemos", DE: null, JP: null },
	"relicname-BRD-p_w_anemos": { EN: "Failnaught Anemos", FR: "Arc Qui-ne-faut Anemos", DE: null, JP: null },
	"relicname-BLM-p_w_anemos": { EN: "Vanargand Anemos", FR: "Vanargand Anemos", DE: null, JP: null },
	"relicname-SMN-p_w_anemos": { EN: "Lemegeton Anemos", FR: "Lemegeton Anemos", DE: null, JP: null },
	"relicname-SCH-p_w_anemos": { EN: "Organum Anemos", FR: "Organum Anemos", DE: null, JP: null },
	"relicname-WHM-p_w_anemos": { EN: "Aymur Anemos", FR: "Aymur Anemos", DE: null, JP: null },
	"relicname-NIN-p_w_anemos": { EN: "Nagi Anemos", FR: "Nagi Anemos", DE: null, JP: null },
	"relicname-DRK-p_w_anemos": { EN: "Caladbolg Anemos", FR: "Caladbolg Anemos", DE: null, JP: null },
	"relicname-AST-p_w_anemos": { EN: "Pleiades Anemos", FR: "Pléiade Anemos", DE: null, JP: null },
	"relicname-MCH-p_w_anemos": { EN: "Outsider Anemos", FR: "Outsider Anemos", DE: null, JP: null },
	"relicname-SAM-p_w_anemos": { EN: "Kiku-ichimonji Anemos", FR: "Kiku-Ichimonji Anemos", DE: null, JP: null },
	"relicname-RDM-p_w_anemos": { EN: "Murgleis Anemos", FR: "Murgleis Anemos", DE: null, JP: null },
	//#endregion Anemos

	//#region Elemental
	"relicname-PLD-p_w_elemental": { EN: "Elemental Sword & Elemental Shield", FR: "Épée élémentaire & Bouclier élémentaire", DE: null, JP: null },
	"relicname-MNK-p_w_elemental": { EN: "Elemental Knuckles", FR: "Poings élémentaires", DE: null, JP: null },
	"relicname-WAR-p_w_elemental": { EN: "Elemental Battleaxe", FR: "Hache de combat élémentaire", DE: null, JP: null },
	"relicname-DRG-p_w_elemental": { EN: "Elemental Lance", FR: "Lance élémentaire", DE: null, JP: null },
	"relicname-BRD-p_w_elemental": { EN: "Elemental Harp Bow", FR: "Arc-harpe élémentaire", DE: null, JP: null },
	"relicname-BLM-p_w_elemental": { EN: "Elemental Rod", FR: "Sceptre élémentaire", DE: null, JP: null },
	"relicname-SMN-p_w_elemental": { EN: "Elemental Grimoire", FR: "Grimoire élémentaire", DE: null, JP: null },
	"relicname-SCH-p_w_elemental": { EN: "Elemental Codex", FR: "Codex élémentaire", DE: null, JP: null },
	"relicname-WHM-p_w_elemental": { EN: "Elemental Cane", FR: "Canne élémentaire", DE: null, JP: null },
	"relicname-NIN-p_w_elemental": { EN: "Elemental Knives", FR: "Couteaux élémentaires", DE: null, JP: null },
	"relicname-DRK-p_w_elemental": { EN: "Elemental Guillotine", FR: "Fendoir élémentaire", DE: null, JP: null },
	"relicname-AST-p_w_elemental": { EN: "Elemental Astrometer", FR: "Planisphère élémentaire", DE: null, JP: null },
	"relicname-MCH-p_w_elemental": { EN: "Elemental Handgonne", FR: "Haquebute élémentaire", DE: null, JP: null },
	"relicname-SAM-p_w_elemental": { EN: "Elemental Blade", FR: "Lame élémentaire", DE: null, JP: null },
	"relicname-RDM-p_w_elemental": { EN: "Elemental Tuck", FR: "Estoc élémentaire", DE: null, JP: null },
	//#endregion Elemental

	//#region Pyros
	"relicname-PLD-p_w_pyros": { EN: "Pyros Sword & Pyros Shield", FR: "Épée Pyros & Bouclier Pyros", DE: null, JP: null },
	"relicname-MNK-p_w_pyros": { EN: "Pyros Knuckles", FR: "Poings Pyros", DE: null, JP: null },
	"relicname-WAR-p_w_pyros": { EN: "Pyros Battleaxe", FR: "Hache de combat Pyros", DE: null, JP: null },
	"relicname-DRG-p_w_pyros": { EN: "Pyros Lance", FR: "Lance Pyros", DE: null, JP: null },
	"relicname-BRD-p_w_pyros": { EN: "Pyros Harp Bow", FR: "Arc-harpe Pyros", DE: null, JP: null },
	"relicname-BLM-p_w_pyros": { EN: "Pyros Rod", FR: "Sceptre Pyros", DE: null, JP: null },
	"relicname-SMN-p_w_pyros": { EN: "Pyros Grimoire", FR: "Grimoire Pyros", DE: null, JP: null },
	"relicname-SCH-p_w_pyros": { EN: "Pyros Codex", FR: "Codex Pyros", DE: null, JP: null },
	"relicname-WHM-p_w_pyros": { EN: "Pyros Cane", FR: "Canne Pyros", DE: null, JP: null },
	"relicname-NIN-p_w_pyros": { EN: "Pyros Knives", FR: "Couteaux Pyros", DE: null, JP: null },
	"relicname-DRK-p_w_pyros": { EN: "Pyros Guillotine", FR: "Fendoir Pyros", DE: null, JP: null },
	"relicname-AST-p_w_pyros": { EN: "Pyros Astrometer", FR: "Planisphère Pyros", DE: null, JP: null },
	"relicname-MCH-p_w_pyros": { EN: "Pyros Handgonne", FR: "Haquebute Pyros", DE: null, JP: null },
	"relicname-SAM-p_w_pyros": { EN: "Pyros Blade", FR: "Lame Pyros", DE: null, JP: null },
	"relicname-RDM-p_w_pyros": { EN: "Pyros Tuck", FR: "Estoc Pyros", DE: null, JP: null },
	//#endregion Pyros

	//#region Eureka
	"relicname-PLD-p_w_eureka": { EN: "Antea Eureka & Bellerophon Eureka", FR: "Antea Eurêka & Bellérophon Eurêka", DE: null, JP: null },
	"relicname-MNK-p_w_eureka": { EN: "Dumuzis Eureka", FR: "Dumuzi Eurêka", DE: null, JP: null },
	"relicname-WAR-p_w_eureka": { EN: "Shamash Eureka", FR: "Shamash Eurêka", DE: null, JP: null },
	"relicname-DRG-p_w_eureka": { EN: "Daboya Eureka", FR: "Daboya Eurêka", DE: null, JP: null },
	"relicname-BRD-p_w_eureka": { EN: "Circinae Eureka", FR: "Circinae Eurêka", DE: null, JP: null },
	"relicname-BLM-p_w_eureka": { EN: "Paikea Eureka", FR: "Paikea Eurêka", DE: null, JP: null },
	"relicname-SMN-p_w_eureka": { EN: "Tuah Eureka", FR: "Tuah Eurêka", DE: null, JP: null },
	"relicname-SCH-p_w_eureka": { EN: "Jebat Eureka", FR: "Jebat Eurêka", DE: null, JP: null },
	"relicname-WHM-p_w_eureka": { EN: "Rose Couverte Eureka", FR: "Rose Couverte Eurêka", DE: null, JP: null },
	"relicname-NIN-p_w_eureka": { EN: "Kasasagi Eureka", FR: "Kasasagi Eurêka", DE: null, JP: null },
	"relicname-DRK-p_w_eureka": { EN: "Xiphias Eureka", FR: "Xiphias Eurêka", DE: null, JP: null },
	"relicname-AST-p_w_eureka": { EN: "Albireo Eureka", FR: "Albiréo Eurêka", DE: null, JP: null },
	"relicname-MCH-p_w_eureka": { EN: "Mollfrith Eureka", FR: "Mollfrith Eurêka", DE: null, JP: null },
	"relicname-SAM-p_w_eureka": { EN: "Torigashira Eureka", FR: "Torigashira-no-tachi Eurêka", DE: null, JP: null },
	"relicname-RDM-p_w_eureka": { EN: "Brunello Eureka", FR: "Brunello Eurêka", DE: null, JP: null },
	//#endregion Eureka

	//#region Physeos
	"relicname-PLD-p_w_physeos": { EN: "Antea Physeos & Bellerophon Physeos", FR: "Antea personnalisée & Bellérophon personnalisé", DE: null, JP: null },
	"relicname-MNK-p_w_physeos": { EN: "Dumuzis Physeos", FR: "Dumuzi personnalisés", DE: null, JP: null },
	"relicname-WAR-p_w_physeos": { EN: "Shamash Physeos", FR: "Shamash personnalisée", DE: null, JP: null },
	"relicname-DRG-p_w_physeos": { EN: "Daboya Physeos", FR: "Daboya personnalisée", DE: null, JP: null },
	"relicname-BRD-p_w_physeos": { EN: "Circinae Physeos", FR: "Circinae personnalisé", DE: null, JP: null },
	"relicname-BLM-p_w_physeos": { EN: "Paikea Physeos", FR: "Paikea personnalisé", DE: null, JP: null },
	"relicname-SMN-p_w_physeos": { EN: "Tuah Physeos", FR: "Tuah personnalisé", DE: null, JP: null },
	"relicname-SCH-p_w_physeos": { EN: "Jebat Physeos", FR: "Jebat personnalisé", DE: null, JP: null },
	"relicname-WHM-p_w_physeos": { EN: "Rose Couverte Physeos", FR: "Rose Couverte personnalisée", DE: null, JP: null },
	"relicname-NIN-p_w_physeos": { EN: "Kasasagi Physeos", FR: "Kasasagi personnalisés", DE: null, JP: null },
	"relicname-DRK-p_w_physeos": { EN: "Xiphias Physeos", FR: "Xiphias personnalisé", DE: null, JP: null },
	"relicname-AST-p_w_physeos": { EN: "Albireo Physeos", FR: "Albiréo personnalisé", DE: null, JP: null },
	"relicname-MCH-p_w_physeos": { EN: "Mollfrith Physeos", FR: "Mollfrith personnalisée", DE: null, JP: null },
	"relicname-SAM-p_w_physeos": { EN: "Torigashira Physeos", FR: "Torigashira-no-tachi personnalisée", DE: null, JP: null },
	"relicname-RDM-p_w_physeos": { EN: "Brunello Physeos", FR: "Brunello personnalisé", DE: null, JP: null },
	//#endregion Physeos

	//#endregion Weapon - Eureka

	//#region Weapon - Resistance

	//#region Resistance
	"relicname-PLD-p_w_resistance": { EN: "Honorbound & Tenacity", FR: "Lien d'honneur & Ténacité", DE: null, JP: null },
	"relicname-MNK-p_w_resistance": { EN: "Samsara", FR: "Samsara", DE: null, JP: null },
	"relicname-WAR-p_w_resistance": { EN: "Skullrender", FR: "Arrache-crânes", DE: null, JP: null },
	"relicname-DRG-p_w_resistance": { EN: "Dreizack", FR: "Dreizack", DE: null, JP: null },
	"relicname-BRD-p_w_resistance": { EN: "Brilliance", FR: "Brillance", DE: null, JP: null },
	"relicname-BLM-p_w_resistance": { EN: "Soulscourge", FR: "Discipline de l'âme", DE: null, JP: null },
	"relicname-SMN-p_w_resistance": { EN: "Espiritus", FR: "Espiritus", DE: null, JP: null },
	"relicname-SCH-p_w_resistance": { EN: "Akademos", FR: "Akademos", DE: null, JP: null },
	"relicname-WHM-p_w_resistance": { EN: "Ingrimm", FR: "Ingrimm", DE: null, JP: null },
	"relicname-NIN-p_w_resistance": { EN: "Honeshirazu", FR: "Honeshirazu", DE: null, JP: null },
	"relicname-DRK-p_w_resistance": { EN: "Woeborn", FR: "Mal-née", DE: null, JP: null },
	"relicname-AST-p_w_resistance": { EN: "Solstice", FR: "Solstice", DE: null, JP: null },
	"relicname-MCH-p_w_resistance": { EN: "Lawman", FR: "Garde-loi", DE: null, JP: null },
	"relicname-SAM-p_w_resistance": { EN: "Hoshikiri", FR: "Hoshikiri", DE: null, JP: null },
	"relicname-RDM-p_w_resistance": { EN: "Talekeeper", FR: "Gardien-de-légende", DE: null, JP: null },
	"relicname-GNB-p_w_resistance": { EN: "Crownsblade", FR: "Protectrice de la couronne", DE: null, JP: null },
	"relicname-DNC-p_w_resistance": { EN: "Enchufla", FR: "Enchufla", DE: null, JP: null },
	//#endregion Resistance

	//#region Augmented Resistance
	"relicname-PLD-p_w_augmented_resistance": { EN: "Augmented Honorbound & Augmented Tenacity", FR: "Lien d'honneur amélioré & Ténacité améliorée", DE: null, JP: null },
	"relicname-MNK-p_w_augmented_resistance": { EN: "Augmented Samsara", FR: "Samsara améliorées", DE: null, JP: null },
	"relicname-WAR-p_w_augmented_resistance": { EN: "Augmented Skullrender", FR: "Arrache-crânes amélioré", DE: null, JP: null },
	"relicname-DRG-p_w_augmented_resistance": { EN: "Augmented Dreizack", FR: "Dreizack améliorée", DE: null, JP: null },
	"relicname-BRD-p_w_augmented_resistance": { EN: "Augmented Brilliance", FR: "Brillance améliorée", DE: null, JP: null },
	"relicname-BLM-p_w_augmented_resistance": { EN: "Augmented Soulscourge", FR: "Discipline de l'âme améliorée", DE: null, JP: null },
	"relicname-SMN-p_w_augmented_resistance": { EN: "Augmented Espiritus", FR: "Espiritus amélioré", DE: null, JP: null },
	"relicname-SCH-p_w_augmented_resistance": { EN: "Augmented Akademos", FR: "Akademos amélioré", DE: null, JP: null },
	"relicname-WHM-p_w_augmented_resistance": { EN: "Augmented Ingrimm", FR: "Ingrimm amélioré", DE: null, JP: null },
	"relicname-NIN-p_w_augmented_resistance": { EN: "Augmented Honeshirazu", FR: "Honeshirazu améliorées", DE: null, JP: null },
	"relicname-DRK-p_w_augmented_resistance": { EN: "Augmented Woeborn", FR: "Mal-née améliorée", DE: null, JP: null },
	"relicname-AST-p_w_augmented_resistance": { EN: "Augmented Solstice", FR: "Solstice amélioré", DE: null, JP: null },
	"relicname-MCH-p_w_augmented_resistance": { EN: "Augmented Lawman", FR: "Garde-loi amélioré", DE: null, JP: null },
	"relicname-SAM-p_w_augmented_resistance": { EN: "Augmented Hoshikiri", FR: "Hoshikiri améliorée", DE: null, JP: null },
	"relicname-RDM-p_w_augmented_resistance": { EN: "Augmented Talekeeper", FR: "Gardien-de-légende amélioré", DE: null, JP: null },
	"relicname-GNB-p_w_augmented_resistance": { EN: "Augmented Crownsblade", FR: "Protectrice de la couronne améliorée", DE: null, JP: null },
	"relicname-DNC-p_w_augmented_resistance": { EN: "Augmented Enchufla", FR: "Enchufla améliorés", DE: null, JP: null },
	//#endregion Augmented Resistance

	//#region Recollection
	"relicname-PLD-p_w_recollection": { EN: "Honorbound Recollection & Tenacity Recollection", FR: "Lien d'honneur in memoriam & Tenacité in memoriam", DE: null, JP: null },
	"relicname-MNK-p_w_recollection": { EN: "Samsara Recollection", FR: "Samsara in memoriam", DE: null, JP: null },
	"relicname-WAR-p_w_recollection": { EN: "Skullrender Recollection", FR: "Arrache-crânes in memoriam", DE: null, JP: null },
	"relicname-DRG-p_w_recollection": { EN: "Dreizack Recollection", FR: "Dreizack in memoriam", DE: null, JP: null },
	"relicname-BRD-p_w_recollection": { EN: "Brilliance Recollection", FR: "Brilliance in memoriam", DE: null, JP: null },
	"relicname-BLM-p_w_recollection": { EN: "Soulscourge Recollection", FR: "Discipline de l'âme in memoriam", DE: null, JP: null },
	"relicname-SMN-p_w_recollection": { EN: "Espiritus Recollection", FR: "Espiritus in memoriam", DE: null, JP: null },
	"relicname-SCH-p_w_recollection": { EN: "Akademos Recollection", FR: "Akademos in memoriam", DE: null, JP: null },
	"relicname-WHM-p_w_recollection": { EN: "Ingrimm Recollection", FR: "Ingrimm in memoriam", DE: null, JP: null },
	"relicname-NIN-p_w_recollection": { EN: "Honeshirazu Recollection", FR: "Honeshirazu in memoriam", DE: null, JP: null },
	"relicname-DRK-p_w_recollection": { EN: "Woeborn Recollection", FR: "Mal-née in memoriam", DE: null, JP: null },
	"relicname-AST-p_w_recollection": { EN: "Solstice Recollection", FR: "Solstice in memoriam", DE: null, JP: null },
	"relicname-MCH-p_w_recollection": { EN: "Lawman Recollection", FR: "Garde-loi in memoriam", DE: null, JP: null },
	"relicname-SAM-p_w_recollection": { EN: "Hoshikiri Recollection", FR: "Hoshikiri in memoriam", DE: null, JP: null },
	"relicname-RDM-p_w_recollection": { EN: "Talekeeper Recollection", FR: "Gardien-de-légende in memoriam", DE: null, JP: null },
	"relicname-GNB-p_w_recollection": { EN: "Crownsblade Recollection", FR: "Protectrice de la couronne in memoriam", DE: null, JP: null },
	"relicname-DNC-p_w_recollection": { EN: "Enchufla Recollection", FR: "Enchufla in memoriam", DE: null, JP: null },
	//#endregion Recollection

	//#region Law's Order
	"relicname-PLD-p_w_laws_order": { EN: "Law's Order Bastard Sword & Law's Order Kite Shield", FR: "Épée bâtarde du verdict des Juges & Écu mandorle du verdict des Juges", DE: null, JP: null },
	"relicname-MNK-p_w_laws_order": { EN: "Law's Order Knuckles", FR: "Poings du verdict des Juges", DE: null, JP: null },
	"relicname-WAR-p_w_laws_order": { EN: "Law's Order Labrys", FR: "Labrys du verdict des Juges", DE: null, JP: null },
	"relicname-DRG-p_w_laws_order": { EN: "Law's Order Spear", FR: "Hast du verdict des Juges", DE: null, JP: null },
	"relicname-BRD-p_w_laws_order": { EN: "Law's Order Composite Bow", FR: "Arc composite du verdict des Juges", DE: null, JP: null },
	"relicname-BLM-p_w_laws_order": { EN: "Law's Order Rod", FR: "Sceptre du verdict des Juges", DE: null, JP: null },
	"relicname-SMN-p_w_laws_order": { EN: "Law's Order Index", FR: "Index du verdict des Juges", DE: null, JP: null },
	"relicname-SCH-p_w_laws_order": { EN: "Law's Order Codex", FR: "Codex du verdict des Juges", DE: null, JP: null },
	"relicname-WHM-p_w_laws_order": { EN: "Law's Order Cane", FR: "Canne du verdict des Juges", DE: null, JP: null },
	"relicname-NIN-p_w_laws_order": { EN: "Law's Order Knives", FR: "Couteaux du verdict des Juges", DE: null, JP: null },
	"relicname-DRK-p_w_laws_order": { EN: "Law's Order Zweihander", FR: "Espadon du verdict des Juges", DE: null, JP: null },
	"relicname-AST-p_w_laws_order": { EN: "Law's Order Astrometer", FR: "Planisphère du verdict des Juges", DE: null, JP: null },
	"relicname-MCH-p_w_laws_order": { EN: "Law's Order Revolver", FR: "Revolver du verdict des Juges", DE: null, JP: null },
	"relicname-SAM-p_w_laws_order": { EN: "Law's Order Samurai Blade", FR: "Lame de samouraï du verdict des Juges", DE: null, JP: null },
	"relicname-RDM-p_w_laws_order": { EN: "Law's Order Rapier", FR: "Rapière du verdict des Juges", DE: null, JP: null },
	"relicname-GNB-p_w_laws_order": { EN: "Law's Order Manatrigger", FR: "Managâchette du verdict des Juges", DE: null, JP: null },
	"relicname-DNC-p_w_laws_order": { EN: "Law's Order Chakrams", FR: "Chakrams du verdict des Juges", DE: null, JP: null },
	//#endregion Law's Order

	//#region Augmented Law's Order
	"relicname-PLD-p_w_augmented_laws_order": { EN: "Augmented Law's Order Bastard Sword & Augmented Law's Order Kite Shield", FR: "Épée bâtarde du verdict des Juges améliorée & Écu mandorle du verdict des Juges améliorée", DE: null, JP: null },
	"relicname-MNK-p_w_augmented_laws_order": { EN: "Augmented Law's Order Knuckles", FR: "Poings du verdict des Juges améliorés", DE: null, JP: null },
	"relicname-WAR-p_w_augmented_laws_order": { EN: "Augmented Law's Order Labrys", FR: "Labrys du verdict des Juges amélioré", DE: null, JP: null },
	"relicname-DRG-p_w_augmented_laws_order": { EN: "Augmented Law's Order Spear", FR: "Hast du verdict des Juges amélioré", DE: null, JP: null },
	"relicname-BRD-p_w_augmented_laws_order": { EN: "Augmented Law's Order Composite Bow", FR: "Arc composite du verdict des Juges amélioré", DE: null, JP: null },
	"relicname-BLM-p_w_augmented_laws_order": { EN: "Augmented Law's Order Rod", FR: "Sceptre du verdict des Juges amélioré", DE: null, JP: null },
	"relicname-SMN-p_w_augmented_laws_order": { EN: "Augmented Law's Order Index", FR: "Index du verdict des Juges amélioré", DE: null, JP: null },
	"relicname-SCH-p_w_augmented_laws_order": { EN: "Augmented Law's Order Codex", FR: "Codex du verdict des Juges amélioré", DE: null, JP: null },
	"relicname-WHM-p_w_augmented_laws_order": { EN: "Augmented Law's Order Cane", FR: "Canne du verdict des Juges améliorée", DE: null, JP: null },
	"relicname-NIN-p_w_augmented_laws_order": { EN: "Augmented Law's Order Knives", FR: "Couteaux du verdict des Juges améliorés", DE: null, JP: null },
	"relicname-DRK-p_w_augmented_laws_order": { EN: "Augmented Law's Order Zweihander", FR: "Espadon du verdict des Juges amélioré", DE: null, JP: null },
	"relicname-AST-p_w_augmented_laws_order": { EN: "Augmented Law's Order Astrometer", FR: "Planisphère du verdict des Juges amélioré", DE: null, JP: null },
	"relicname-MCH-p_w_augmented_laws_order": { EN: "Augmented Law's Order Revolver", FR: "Revolver du verdict des Juges amélioré", DE: null, JP: null },
	"relicname-SAM-p_w_augmented_laws_order": { EN: "Augmented Law's Order Samurai Blade", FR: "Lame de samouraï du verdict des Juges améliorée", DE: null, JP: null },
	"relicname-RDM-p_w_augmented_laws_order": { EN: "Augmented Law's Order Rapier", FR: "Rapière du verdict des Juges améliorée", DE: null, JP: null },
	"relicname-GNB-p_w_augmented_laws_order": { EN: "Augmented Law's Order Manatrigger", FR: "Managâchette du verdict des Juges amélioré", DE: null, JP: null },
	"relicname-DNC-p_w_augmented_laws_order": { EN: "Augmented Law's Order Chakrams", FR: "Chakrams du verdict des Juges améliorés", DE: null, JP: null },
	//#endregion Augmented Law's Order

	//#region Blade
	"relicname-PLD-p_w_blade": { EN: "Blade's Honor & Blade's Fortitude", FR: "Honneur de Gunnhildr & Courage de Gunnhildr", DE: null, JP: null },
	"relicname-MNK-p_w_blade": { EN: "Blade's Serenity", FR: "Sérénités de Gunnhildr", DE: null, JP: null },
	"relicname-WAR-p_w_blade": { EN: "Blade's Valor", FR: "Bravoure de Gunnhildr", DE: null, JP: null },
	"relicname-DRG-p_w_blade": { EN: "Blade's Glory", FR: "Gloire de Gunnhildr", DE: null, JP: null },
	"relicname-BRD-p_w_blade": { EN: "Blade's Muse", FR: "Muse de Gunnhildr", DE: null, JP: null },
	"relicname-BLM-p_w_blade": { EN: "Blade's Fury", FR: "Fureur de Gunnhildr", DE: null, JP: null },
	"relicname-SMN-p_w_blade": { EN: "Blade's Acumen", FR: "Sagacité de Gunnhildr", DE: null, JP: null },
	"relicname-SCH-p_w_blade": { EN: "Blade's Wisdom", FR: "Sagesse de Gunnhildr", DE: null, JP: null },
	"relicname-WHM-p_w_blade": { EN: "Blade's Mercy", FR: "Clémence de Gunnhildr", DE: null, JP: null },
	"relicname-NIN-p_w_blade": { EN: "Blade's Subtlety", FR: "Subtilités de Gunnhildr", DE: null, JP: null },
	"relicname-DRK-p_w_blade": { EN: "Blade's Justice", FR: "Justice de Gunnhildr", DE: null, JP: null },
	"relicname-AST-p_w_blade": { EN: "Blade's Providence", FR: "Providence de Gunnhildr", DE: null, JP: null },
	"relicname-MCH-p_w_blade": { EN: "Blade's Ingenuity", FR: "Ingéniosité de Gunnhildr", DE: null, JP: null },
	"relicname-SAM-p_w_blade": { EN: "Blade's Fealty", FR: "Loyauté de Gunnhildr", DE: null, JP: null },
	"relicname-RDM-p_w_blade": { EN: "Blade's Temperance", FR: "Tempérance de Gunnhildr", DE: null, JP: null },
	"relicname-GNB-p_w_blade": { EN: "Blade's Resolve", FR: "Détermination de Gunnhildr", DE: null, JP: null },
	"relicname-DNC-p_w_blade": { EN: "Blade's Euphoria", FR: "Euphories de Gunnhildr", DE: null, JP: null },
	//#endregion Blade

	//#endregion Weapon - Resistance

	//#region Weapon - Manderville

	//#region Manderville
	"relicname-PLD-p_w_manderville": { EN: "Manderville Sword & Manderville Kite Shield", FR: "Épée des Manderville & Écu mandorle des Manderville", DE: null, JP: null },
	"relicname-MNK-p_w_manderville": { EN: "Manderville Knuckles", FR: "Poings des Manderville", DE: null, JP: null },
	"relicname-WAR-p_w_manderville": { EN: "Manderville Axe", FR: "Hache des Manderville", DE: null, JP: null },
	"relicname-DRG-p_w_manderville": { EN: "Manderville Spear", FR: "Hast des Manderville", DE: null, JP: null },
	"relicname-BRD-p_w_manderville": { EN: "Manderville Harp Bow", FR: "Arc-harpe des Manderville", DE: null, JP: null },
	"relicname-BLM-p_w_manderville": { EN: "Manderville Rod", FR: "Sceptre des Manderville", DE: null, JP: null },
	"relicname-SMN-p_w_manderville": { EN: "Manderville Index", FR: "Index des Manderville", DE: null, JP: null },
	"relicname-SCH-p_w_manderville": { EN: "Manderville Codex", FR: "Codex des Manderville", DE: null, JP: null },
	"relicname-WHM-p_w_manderville": { EN: "Manderville Cane", FR: "Canne des Manderville", DE: null, JP: null },
	"relicname-NIN-p_w_manderville": { EN: "Manderville Knives", FR: "Couteaux des Manderville", DE: null, JP: null },
	"relicname-DRK-p_w_manderville": { EN: "Manderville Zweihander", FR: "Espadon des Manderville", DE: null, JP: null },
	"relicname-AST-p_w_manderville": { EN: "Manderville Torquetum", FR: "Torquetum des Manderville", DE: null, JP: null },
	"relicname-MCH-p_w_manderville": { EN: "Manderville Revolver", FR: "Revolver des Manderville", DE: null, JP: null },
	"relicname-SAM-p_w_manderville": { EN: "Manderville Samurai Blade", FR: "Lame de samouraï des Manderville", DE: null, JP: null },
	"relicname-RDM-p_w_manderville": { EN: "Manderville Rapier", FR: "Rapière des Manderville", DE: null, JP: null },
	"relicname-GNB-p_w_manderville": { EN: "Manderville Gunblade", FR: "Pistolame des Manderville", DE: null, JP: null },
	"relicname-DNC-p_w_manderville": { EN: "Manderville Chakrams", FR: "Chakrams des Manderville", DE: null, JP: null },
	"relicname-SGE-p_w_manderville": { EN: "Manderville Milpreves", FR: "Mein gwrac'h des Manderville", DE: null, JP: null },
	"relicname-RPR-p_w_manderville": { EN: "Manderville Scythe", FR: "Faux des Manderville", DE: null, JP: null },
	//#endregion Manderville

	//#region Amazing Manderville
	"relicname-PLD-p_w_amazing_manderville": { EN: "Amazing Manderville Sword & Amazing Manderville Kite Shield", FR: "Épée éblouissante des Manderville & Écu mandorle éblouissant des Manderville", DE: null, JP: null },
	"relicname-MNK-p_w_amazing_manderville": { EN: "Amazing Manderville Knuckles", FR: "Poings éblouissants des Manderville", DE: null, JP: null },
	"relicname-WAR-p_w_amazing_manderville": { EN: "Amazing Manderville Axe", FR: "Hache éblouissante des Manderville", DE: null, JP: null },
	"relicname-DRG-p_w_amazing_manderville": { EN: "Amazing Manderville Spear", FR: "Hast éblouissant des Manderville", DE: null, JP: null },
	"relicname-BRD-p_w_amazing_manderville": { EN: "Amazing Manderville Harp Bow", FR: "Arc-harpe éblouissant des Manderville", DE: null, JP: null },
	"relicname-BLM-p_w_amazing_manderville": { EN: "Amazing Manderville Rod", FR: "Sceptre éblouissant des Manderville", DE: null, JP: null },
	"relicname-SMN-p_w_amazing_manderville": { EN: "Amazing Manderville Index", FR: "Index éblouissant des Manderville", DE: null, JP: null },
	"relicname-SCH-p_w_amazing_manderville": { EN: "Amazing Manderville Codex", FR: "Codex éblouissant des Manderville", DE: null, JP: null },
	"relicname-WHM-p_w_amazing_manderville": { EN: "Amazing Manderville Cane", FR: "Canne éblouissante des Manderville", DE: null, JP: null },
	"relicname-NIN-p_w_amazing_manderville": { EN: "Amazing Manderville Knives", FR: "Couteaux éblouissants des Manderville", DE: null, JP: null },
	"relicname-DRK-p_w_amazing_manderville": { EN: "Amazing Manderville Zweihander", FR: "Espadon éblouissant des Manderville", DE: null, JP: null },
	"relicname-AST-p_w_amazing_manderville": { EN: "Amazing Manderville Torquetum", FR: "Torquetum éblouissant des Manderville", DE: null, JP: null },
	"relicname-MCH-p_w_amazing_manderville": { EN: "Amazing Manderville Revolver", FR: "Revolver éblouissant des Manderville", DE: null, JP: null },
	"relicname-SAM-p_w_amazing_manderville": { EN: "Amazing Manderville Samurai Blade", FR: "Lame de samouraï éblouissante des Manderville", DE: null, JP: null },
	"relicname-RDM-p_w_amazing_manderville": { EN: "Amazing Manderville Rapier", FR: "Rapière éblouissante des Manderville", DE: null, JP: null },
	"relicname-GNB-p_w_amazing_manderville": { EN: "Amazing Manderville Gunblade", FR: "Pistolame éblouissante des Manderville", DE: null, JP: null },
	"relicname-DNC-p_w_amazing_manderville": { EN: "Amazing Manderville Chakrams", FR: "Chakrams éblouissants des Manderville", DE: null, JP: null },
	"relicname-SGE-p_w_amazing_manderville": { EN: "Amazing Manderville Milpreves", FR: "Mein gwrac'h éblouissants des Manderville", DE: null, JP: null },
	"relicname-RPR-p_w_amazing_manderville": { EN: "Amazing Manderville Scythe", FR: "Faux éblouissante des Manderville", DE: null, JP: null },
	//#endregion Amazing Manderville

	//#region Majestic Manderville
	"relicname-PLD-p_w_majestic_manderville": { EN: "Majestic Manderville Sword & Majestic Manderville Kite Shield", FR: "Épée majesteuse des Manderville & Écu mandorle majestueux des Manderville", DE: null, JP: null },
	"relicname-MNK-p_w_majestic_manderville": { EN: "Majestic Manderville Fists", FR: "Poings majestueux des Manderville", DE: null, JP: null },
	"relicname-WAR-p_w_majestic_manderville": { EN: "Majestic Manderville Bardiche", FR: "Bardiche majestueuse des Manderville", DE: null, JP: null },
	"relicname-DRG-p_w_majestic_manderville": { EN: "Majestic Manderville Spear", FR: "Hast majestueux des Manderville", DE: null, JP: null },
	"relicname-BRD-p_w_majestic_manderville": { EN: "Majestic Manderville Harp Bow", FR: "Arc-harpe majestueux des Manderville", DE: null, JP: null },
	"relicname-BLM-p_w_majestic_manderville": { EN: "Majestic Manderville Staff", FR: "Bâton majestueux des Manderville", DE: null, JP: null },
	"relicname-SMN-p_w_majestic_manderville": { EN: "Majestic Manderville Index", FR: "Index majestueux des Manderville", DE: null, JP: null },
	"relicname-SCH-p_w_majestic_manderville": { EN: "Majestic Manderville Codex", FR: "Codex majestueux des Manderville", DE: null, JP: null },
	"relicname-WHM-p_w_majestic_manderville": { EN: "Majestic Manderville Wand", FR: "Baguette majestueuse des Manderville", DE: null, JP: null },
	"relicname-NIN-p_w_majestic_manderville": { EN: "Majestic Manderville Knives", FR: "Couteaux majestueux des Manderville", DE: null, JP: null },
	"relicname-DRK-p_w_majestic_manderville": { EN: "Majestic Manderville Greatsword", FR: "Grande épée majestueuse des Manderville", DE: null, JP: null },
	"relicname-AST-p_w_majestic_manderville": { EN: "Majestic Manderville Orrery", FR: "Planétaire majestueux des Manderville", DE: null, JP: null },
	"relicname-MCH-p_w_majestic_manderville": { EN: "Majestic Manderville Pistol", FR: "Pistolet majestueux des Manderville", DE: null, JP: null },
	"relicname-SAM-p_w_majestic_manderville": { EN: "Majestic Manderville Samurai Blade", FR: "Lame de samouraï majestueuse des Manderville", DE: null, JP: null },
	"relicname-RDM-p_w_majestic_manderville": { EN: "Majestic Manderville Degen", FR: "Alfange majestueuse des Manderville", DE: null, JP: null },
	"relicname-GNB-p_w_majestic_manderville": { EN: "Majestic Manderville Bayonet", FR: "Baïonnette majestueuse des Manderville", DE: null, JP: null },
	"relicname-DNC-p_w_majestic_manderville": { EN: "Majestic Manderville Chakrams", FR: "Chakrams majestueux des Manderville", DE: null, JP: null },
	"relicname-SGE-p_w_majestic_manderville": { EN: "Majestic Manderville Wings", FR: "Ailes majestueuses des Manderville", DE: null, JP: null },
	"relicname-RPR-p_w_majestic_manderville": { EN: "Majestic Manderville War Scythe", FR: "Faux de guerre majestueuse des Manderville", DE: null, JP: null },
	//#endregion Majestic Manderville

	//#region Mandervillous
	"relicname-PLD-p_w_mandervillous": { EN: "Mandervillous Falchion", FR: null, DE: null, JP: null },
	"relicname-WAR-p_w_mandervillous": { EN: "Mandervillous Battleaxe", FR: null, DE: null, JP: null },
	"relicname-DRK-p_w_mandervillous": { EN: "Mandervillous Greatsword", FR: null, DE: null, JP: null },
	"relicname-GNB-p_w_mandervillous": { EN: "Mandervillous Gunblade", FR: null, DE: null, JP: null },
	"relicname-DRG-p_w_mandervillous": { EN: "Mandervillous Trident", FR: null, DE: null, JP: null },
	"relicname-RPR-p_w_mandervillous": { EN: "Mandervillous Zaghnal", FR: null, DE: null, JP: null },
	"relicname-MNK-p_w_mandervillous": { EN: "Mandervillous Fists", FR: null, DE: null, JP: null },
	"relicname-SAM-p_w_mandervillous": { EN: "Mandervillous Samurai Blade", FR: null, DE: null, JP: null },
	"relicname-NIN-p_w_mandervillous": { EN: "Mandervillous Knives", FR: null, DE: null, JP: null },
	"relicname-BRD-p_w_mandervillous": { EN: "Mandervillous Compound Bow", FR: null, DE: null, JP: null },
	"relicname-MCH-p_w_mandervillous": { EN: "Mandervillous Revolver", FR: null, DE: null, JP: null },
	"relicname-DNC-p_w_mandervillous": { EN: "Mandervillous Chakrams", FR: null, DE: null, JP: null },
	"relicname-BLM-p_w_mandervillous": { EN: "Mandervillous Rod", FR: null, DE: null, JP: null },
	"relicname-SMN-p_w_mandervillous": { EN: "Mandervillous Index", FR: null, DE: null, JP: null },
	"relicname-RDM-p_w_mandervillous": { EN: "Mandervillous Rapier", FR: null, DE: null, JP: null },
	"relicname-WHM-p_w_mandervillous": { EN: "Mandervillous Cane", FR: null, DE: null, JP: null },
	"relicname-SCH-p_w_mandervillous": { EN: "Mandervillous Codex", FR: null, DE: null, JP: null },
	"relicname-AST-p_w_mandervillous": { EN: "Mandervillous Torquetum", FR: null, DE: null, JP: null },
	"relicname-SGE-p_w_mandervillous": { EN: "Mandervillous Wings", FR: null, DE: null, JP: null },
	//#endregion Mandervillous

	//#endregion Weapon - Manderville

	//#region Tool - Lucis

	//#region Mastercraft
	"relicname-ALC-p_t_mastercraft": { EN: "Paracelsus", FR: "Alambic de Paracelse", DE: null, JP: null },
	"relicname-ARM-p_t_mastercraft": { EN: "Kurdalegon", FR: "Marteau de Kurdalaegon", DE: null, JP: null },
	"relicname-BSM-p_t_mastercraft": { EN: "Vulcan", FR: "Marteau de Vulcain", DE: null, JP: null },
	"relicname-CRP-p_t_mastercraft": { EN: "Ullikummi", FR: "Scie d'Ullikummi", DE: null, JP: null },
	"relicname-CUL-p_t_mastercraft": { EN: "Chantico", FR: "Sauteuse de Chantico", DE: null, JP: null },
	"relicname-GSM-p_t_mastercraft": { EN: "Urcaguary", FR: "Marteau d'Urcaguary", DE: null, JP: null },
	"relicname-LTW-p_t_mastercraft": { EN: "Pinga", FR: "Couteau de Pinga", DE: null, JP: null },
	"relicname-WVR-p_t_mastercraft": { EN: "Clotho", FR: "Fuseau de Clotho", DE: null, JP: null },
	"relicname-MIN-p_t_mastercraft": { EN: "Mammon", FR: "Piolet de Mammon", DE: null, JP: null },
	"relicname-BTN-p_t_mastercraft": { EN: "Rauni", FR: "Hachette de Rauni", DE: null, JP: null },
	"relicname-FSH-p_t_mastercraft": { EN: "Halcyon Rod", FR: "Canne à pêche alcyon", DE: null, JP: null },
	//#endregion Mastercraft

	//#region Supra
	"relicname-ALC-p_t_supra": { EN: "Paracelsus Supra", FR: "Alambic de Paracelse supra", DE: null, JP: null },
	"relicname-ARM-p_t_supra": { EN: "Kurdalegon Supra", FR: "Marteau de Kurdalaegon supra", DE: null, JP: null },
	"relicname-BSM-p_t_supra": { EN: "Vulcan Supra", FR: "Marteau de Vulcain supra", DE: null, JP: null },
	"relicname-CRP-p_t_supra": { EN: "Ullikummi Supra", FR: "Scie d'Ullikummi supra", DE: null, JP: null },
	"relicname-CUL-p_t_supra": { EN: "Chantico Supra", FR: "Sauteuse de Chantico supra", DE: null, JP: null },
	"relicname-GSM-p_t_supra": { EN: "Urcaguary Supra", FR: "Marteau d'Urcaguary supra", DE: null, JP: null },
	"relicname-LTW-p_t_supra": { EN: "Pinga Supra", FR: "Couteau de Pinga supra", DE: null, JP: null },
	"relicname-WVR-p_t_supra": { EN: "Clotho Supra", FR: "Fuseau de Clotho supra", DE: null, JP: null },
	"relicname-MIN-p_t_supra": { EN: "Mammon Supra", FR: "Piolet de Mammon supra", DE: null, JP: null },
	"relicname-BTN-p_t_supra": { EN: "Rauni Supra", FR: "Hachette de Rauni supra", DE: null, JP: null },
	"relicname-FSH-p_t_supra": { EN: "Halcyon Rod Supra", FR: "Canne à pêche alcyon supra", DE: null, JP: null },
	//#endregion Supra

	//#region Lucis
	"relicname-ALC-p_t_lucis": { EN: "Paracelsus Lucis", FR: "Alambic de Paracelse lucis", DE: null, JP: null },
	"relicname-ARM-p_t_lucis": { EN: "Kurdalegon Lucis", FR: "Marteau de Kurdalaegon lucis", DE: null, JP: null },
	"relicname-BSM-p_t_lucis": { EN: "Vulcan Lucis", FR: "Marteau de Vulcain lucis", DE: null, JP: null },
	"relicname-CRP-p_t_lucis": { EN: "Ullikummi Lucis", FR: "Scie d'Ullikummi lucis", DE: null, JP: null },
	"relicname-CUL-p_t_lucis": { EN: "Chantico Lucis", FR: "Sauteuse de Chantico lucis", DE: null, JP: null },
	"relicname-GSM-p_t_lucis": { EN: "Urcaguary Lucis", FR: "Marteau d'Urcaguary lucis", DE: null, JP: null },
	"relicname-LTW-p_t_lucis": { EN: "Pinga Lucis", FR: "Couteau de Pinga lucis", DE: null, JP: null },
	"relicname-WVR-p_t_lucis": { EN: "Clotho Lucis", FR: "Fuseau de Clotho lucis", DE: null, JP: null },
	"relicname-MIN-p_t_lucis": { EN: "Mammon Lucis", FR: "Piolet de Mammon lucis", DE: null, JP: null },
	"relicname-BTN-p_t_lucis": { EN: "Rauni Lucis", FR: "Hachette de Rauni lucis", DE: null, JP: null },
	"relicname-FSH-p_t_lucis": { EN: "Halcyon Rod Lucis", FR: "Canne à pêche alcyon lucis", DE: null, JP: null },
	//#endregion Lucis

	//#endregion Tool - Lucis

	//#region Tool - Skysteel

	//#region Base Tool
	"relicname-ALC-p_t_skysteel": { EN: "Skysteel Alembic", FR: "Alambic de Cielacier", DE: null, JP: null },
	"relicname-ARM-p_t_skysteel": { EN: "Skysteel Raising Hammer", FR: "Marteau de batteur de Cielacier", DE: null, JP: null },
	"relicname-BSM-p_t_skysteel": { EN: "Skysteel Cross-pein Hammer", FR: "Marteau à panne croisée de Cielacier", DE: null, JP: null },
	"relicname-CRP-p_t_skysteel": { EN: "Skysteel Saw", FR: "Scie de Cielacier", DE: null, JP: null },
	"relicname-CUL-p_t_skysteel": { EN: "Skysteel Frypan", FR: "Poêle à frire de Cielacier", DE: null, JP: null },
	"relicname-GSM-p_t_skysteel": { EN: "Skysteel Lapidary Hammer", FR: "Marteau de lapidaire de Cielacier", DE: null, JP: null },
	"relicname-LTW-p_t_skysteel": { EN: "Skysteel Round Knife", FR: "Couteau arrondi de Cielacier", DE: null, JP: null },
	"relicname-WVR-p_t_skysteel": { EN: "Skysteel Needle", FR: "Aiguille de Cielacier", DE: null, JP: null },
	"relicname-MIN-p_t_skysteel": { EN: "Skysteel Pickaxe", FR: "Pioche de Cielacier", DE: null, JP: null },
	"relicname-BTN-p_t_skysteel": { EN: "Skysteel Hatchet", FR: "Hachette de Cielacier", DE: null, JP: null },
	"relicname-FSH-p_t_skysteel": { EN: "Skysteel Fishing Rod", FR: "Canne à pêche de Cielacier", DE: null, JP: null },
	//#endregion Base Tool

	//#region Skysteel + 1
	"relicname-ALC-p_t_skysteel_1": { EN: "Skysteel Alembic + 1", FR: "Alambic de Cielacier + 1", DE: null, JP: null },
	"relicname-ARM-p_t_skysteel_1": { EN: "Skysteel Raising Hammer + 1", FR: "Marteau de batteur de Cielacier + 1", DE: null, JP: null },
	"relicname-BSM-p_t_skysteel_1": { EN: "Skysteel Cross-pein Hammer + 1", FR: "Marteau à panne croisée de Cielacier + 1", DE: null, JP: null },
	"relicname-CRP-p_t_skysteel_1": { EN: "Skysteel Saw + 1", FR: "Scie de Cielacier + 1", DE: null, JP: null },
	"relicname-CUL-p_t_skysteel_1": { EN: "Skysteel Frypan + 1", FR: "Poêle à frire de Cielacier + 1", DE: null, JP: null },
	"relicname-GSM-p_t_skysteel_1": { EN: "Skysteel Lapidary Hammer + 1", FR: "Marteau de lapidaire de Cielacier + 1", DE: null, JP: null },
	"relicname-LTW-p_t_skysteel_1": { EN: "Skysteel Round Knife + 1", FR: "Couteau arrondi de Cielacier + 1", DE: null, JP: null },
	"relicname-WVR-p_t_skysteel_1": { EN: "Skysteel Needle + 1", FR: "Aiguille de Cielacier + 1", DE: null, JP: null },
	"relicname-MIN-p_t_skysteel_1": { EN: "Skysteel Pickaxe + 1", FR: "Pioche de Cielacier + 1", DE: null, JP: null },
	"relicname-BTN-p_t_skysteel_1": { EN: "Skysteel Hatchet + 1", FR: "Hachette de Cielacier + 1", DE: null, JP: null },
	"relicname-FSH-p_t_skysteel_1": { EN: "Skysteel Fishing Rod + 1", FR: "Canne à pêche de Cielacier + 1", DE: null, JP: null },
	//#endregion Skysteel + 1

	//#region Dragonsung
	"relicname-ALC-p_t_dragonsung": { EN: "Dragonsung Alembic", FR: "Alambic du chant des dragons", DE: null, JP: null },
	"relicname-ARM-p_t_dragonsung": { EN: "Dragonsung Raising Hammer", FR: "Marteau de batteur du chant des dragons", DE: null, JP: null },
	"relicname-BSM-p_t_dragonsung": { EN: "Dragonsung Cross-pein Hammer", FR: "Marteau à panne croisée du chant des dragons", DE: null, JP: null },
	"relicname-CRP-p_t_dragonsung": { EN: "Dragonsung Saw", FR: "Scie du chant des dragons", DE: null, JP: null },
	"relicname-CUL-p_t_dragonsung": { EN: "Dragonsung Frypan", FR: "Poêle à frire du chant des dragons", DE: null, JP: null },
	"relicname-GSM-p_t_dragonsung": { EN: "Dragonsung Lapidary Hammer", FR: "Marteau de lapidaire du chant des dragons", DE: null, JP: null },
	"relicname-LTW-p_t_dragonsung": { EN: "Dragonsung Round Knife", FR: "Couteau arrondi du chant des dragons", DE: null, JP: null },
	"relicname-WVR-p_t_dragonsung": { EN: "Dragonsung Needle", FR: "Aiguille du chant des dragons", DE: null, JP: null },
	"relicname-MIN-p_t_dragonsung": { EN: "Dragonsung Pickaxe", FR: "Pioche du chant des dragons", DE: null, JP: null },
	"relicname-BTN-p_t_dragonsung": { EN: "Dragonsung Hatchet", FR: "Hachette du chant des dragons", DE: null, JP: null },
	"relicname-FSH-p_t_dragonsung": { EN: "Dragonsung Fishing Rod", FR: "Canne à pêche du chant des dragons", DE: null, JP: null },
	//#endregion Dragonsung

	//#region Augmented Dragonsung
	"relicname-ALC-p_t_augmented_dragonsung": { EN: "Augmented Dragonsung Alembic", FR: "Alambic du chant des dragons amélioré", DE: null, JP: null },
	"relicname-ARM-p_t_augmented_dragonsung": { EN: "Augmented Dragonsung Raising Hammer", FR: "Marteau de batteur du chant des dragons amélioré", DE: null, JP: null },
	"relicname-BSM-p_t_augmented_dragonsung": { EN: "Augmented Dragonsung Cross-pein Hammer", FR: "Marteau à panne croisée du chant des dragons amélioré", DE: null, JP: null },
	"relicname-CRP-p_t_augmented_dragonsung": { EN: "Augmented Dragonsung Saw", FR: "Scie du chant des dragons améliorée", DE: null, JP: null },
	"relicname-CUL-p_t_augmented_dragonsung": { EN: "Augmented Dragonsung Frypan", FR: "Poêle à frire du chant des dragons améliorée", DE: null, JP: null },
	"relicname-GSM-p_t_augmented_dragonsung": { EN: "Augmented Dragonsung Lapidary Hammer", FR: "Marteau de lapidaire du chant des dragons amélioré", DE: null, JP: null },
	"relicname-LTW-p_t_augmented_dragonsung": { EN: "Augmented Dragonsung Round Knife", FR: "Couteau arrondi du chant des dragons amélioré", DE: null, JP: null },
	"relicname-WVR-p_t_augmented_dragonsung": { EN: "Augmented Dragonsung Needle", FR: "Aiguille du chant des dragons améliorée", DE: null, JP: null },
	"relicname-MIN-p_t_augmented_dragonsung": { EN: "Augmented Dragonsung Pickaxe", FR: "Pioche du chant des dragons améliorée", DE: null, JP: null },
	"relicname-BTN-p_t_augmented_dragonsung": { EN: "Augmented Dragonsung Hatchet", FR: "Hachette du chant des dragons améliorée", DE: null, JP: null },
	"relicname-FSH-p_t_augmented_dragonsung": { EN: "Augmented Dragonsung Fishing Rod", FR: "Canne à pêche du chant des dragons améliorée", DE: null, JP: null },
	//#endregion Augmented Dragonsung

	//#region Skysung
	"relicname-ALC-p_t_skysung": { EN: "Skysung Alembic", FR: "Alambic du chant céleste", DE: null, JP: null },
	"relicname-ARM-p_t_skysung": { EN: "Skysung Raising Hammer", FR: "Marteau de batteur du chant céleste", DE: null, JP: null },
	"relicname-BSM-p_t_skysung": { EN: "Skysung Cross-pein Hammer", FR: "Marteau à panne croisée du chant céleste", DE: null, JP: null },
	"relicname-CRP-p_t_skysung": { EN: "Skysung Saw", FR: "Scie du chant céleste", DE: null, JP: null },
	"relicname-CUL-p_t_skysung": { EN: "Skysung Frypan", FR: "Poêle à frire du chant céleste", DE: null, JP: null },
	"relicname-GSM-p_t_skysung": { EN: "Skysung Lapidary Hammer", FR: "Marteau de lapidaire du chant céleste", DE: null, JP: null },
	"relicname-LTW-p_t_skysung": { EN: "Skysung Round Knife", FR: "Couteau arrondi du chant céleste", DE: null, JP: null },
	"relicname-WVR-p_t_skysung": { EN: "Skysung Needle", FR: "Aiguille du chant céleste", DE: null, JP: null },
	"relicname-MIN-p_t_skysung": { EN: "Skysung Pickaxe", FR: "Pioche du chant céleste", DE: null, JP: null },
	"relicname-BTN-p_t_skysung": { EN: "Skysung Hatchet", FR: "Hachette du chant céleste", DE: null, JP: null },
	"relicname-FSH-p_t_skysung": { EN: "Skysung Fishing Rod", FR: "Canne à pêche du chant céleste", DE: null, JP: null },
	//#endregion Skysung

	//#region Skybuilders
	"relicname-ALC-p_t_skybuilders": { EN: "Skybuilders' Alembic", FR: "Alambic de la forge céleste", DE: null, JP: null },
	"relicname-ARM-p_t_skybuilders": { EN: "Skybuilders' Raising Hammer", FR: "Marteau de batteur de la forge céleste", DE: null, JP: null },
	"relicname-BSM-p_t_skybuilders": { EN: "Skybuilders' Cross-pein Hammer", FR: "Marteau à panne croisée de la forge céleste", DE: null, JP: null },
	"relicname-CRP-p_t_skybuilders": { EN: "Skybuilders' Saw", FR: "Scie de la forge céleste", DE: null, JP: null },
	"relicname-CUL-p_t_skybuilders": { EN: "Skybuilders' Frypan", FR: "Poêle à frire de la forge céleste", DE: null, JP: null },
	"relicname-GSM-p_t_skybuilders": { EN: "Skybuilders' Lapidary Hammer", FR: "Marteau de lapidaire de la forge céleste", DE: null, JP: null },
	"relicname-LTW-p_t_skybuilders": { EN: "Skybuilders' Round Knife", FR: "Couteau arrondi de la forge céleste", DE: null, JP: null },
	"relicname-WVR-p_t_skybuilders": { EN: "Skybuilders' Needle", FR: "Aiguille de la forge céleste", DE: null, JP: null },
	"relicname-MIN-p_t_skybuilders": { EN: "Skybuilders' Pickaxe", FR: "Pioche de la forge céleste", DE: null, JP: null },
	"relicname-BTN-p_t_skybuilders": { EN: "Skybuilders' Hatchet", FR: "Hachette de la forge céleste", DE: null, JP: null },
	"relicname-FSH-p_t_skybuilders": { EN: "Skybuilders' Fishing Rod", FR: "Canne à pêche de la forge céleste", DE: null, JP: null },
	//#endregion Skybuilders

	//#endregion Tool - Skysteel

	//#region Tool - Splendorous

	//#region Splendorous
	"relicname-ALC-p_t_splendorous": { EN: "Splendorous Alembic", FR: "Alambic des merveilles", DE: null, JP: null },
	"relicname-ARM-p_t_splendorous": { EN: "Splendorous Raising Hammer", FR: "Marteau de batteur des merveilles", DE: null, JP: null },
	"relicname-BSM-p_t_splendorous": { EN: "Splendorous Cross-pein Hammer", FR: "Marteau à panne croisée des merveilles", DE: null, JP: null },
	"relicname-CRP-p_t_splendorous": { EN: "Splendorous Saw", FR: "Scie des merveilles", DE: null, JP: null },
	"relicname-CUL-p_t_splendorous": { EN: "Splendorous Frypan", FR: "Poêle à frire des merveilles", DE: null, JP: null },
	"relicname-GSM-p_t_splendorous": { EN: "Splendorous Mallet", FR: "Massette des merveilles", DE: null, JP: null },
	"relicname-LTW-p_t_splendorous": { EN: "Splendorous Knife", FR: "Couteau des merveilles", DE: null, JP: null },
	"relicname-WVR-p_t_splendorous": { EN: "Splendorous Needle", FR: "Aiguille des merveilles", DE: null, JP: null },
	"relicname-MIN-p_t_splendorous": { EN: "Splendorous Pickaxe", FR: "Pioche des merveilles", DE: null, JP: null },
	"relicname-BTN-p_t_splendorous": { EN: "Splendorous Hatchet", FR: "Hachette des merveilles", DE: null, JP: null },
	"relicname-FSH-p_t_splendorous": { EN: "Splendorous Fishing Rod", FR: "Canne à pêche des merveilles", DE: null, JP: null },
	//#endregion Splendorous

	//#region Augmented Splendorous
	"relicname-ALC-p_t_augmented_splendorous": { EN: "Augmented Splendorous Alembic", FR: "Alambic des merveilles amélioré", DE: null, JP: null },
	"relicname-ARM-p_t_augmented_splendorous": { EN: "Augmented Splendorous Raising Hammer", FR: "Marteau de batteur des merveilles amélioré", DE: null, JP: null },
	"relicname-BSM-p_t_augmented_splendorous": { EN: "Augmented Splendorous Cross-pein Hammer", FR: "Marteau à panne croisée des merveilles amélioré", DE: null, JP: null },
	"relicname-CRP-p_t_augmented_splendorous": { EN: "Augmented Splendorous Saw", FR: "Scie des merveilles améliorée", DE: null, JP: null },
	"relicname-CUL-p_t_augmented_splendorous": { EN: "Augmented Splendorous Frypan", FR: "Poêle à frire des merveilles améliorée", DE: null, JP: null },
	"relicname-GSM-p_t_augmented_splendorous": { EN: "Augmented Splendorous Mallet", FR: "Massette des merveilles améliorée", DE: null, JP: null },
	"relicname-LTW-p_t_augmented_splendorous": { EN: "Augmented Splendorous Knife", FR: "Couteau des merveilles amélioré", DE: null, JP: null },
	"relicname-WVR-p_t_augmented_splendorous": { EN: "Augmented Splendorous Needle", FR: "Aiguille des merveilles améliorée", DE: null, JP: null },
	"relicname-MIN-p_t_augmented_splendorous": { EN: "Augmented Splendorous Pickaxe", FR: "Pioche des merveilles améliorée", DE: null, JP: null },
	"relicname-BTN-p_t_augmented_splendorous": { EN: "Augmented Splendorous Hatchet", FR: "Hachette des merveilles améliorée", DE: null, JP: null },
	"relicname-FSH-p_t_augmented_splendorous": { EN: "Augmented Splendorous Fishing Rod", FR: "Canne à pêche des merveilles améliorée", DE: null, JP: null },
	//#endregion Augmented Splendorous

	//#region Crystalline
	"relicname-ALC-p_t_crystalline": { EN: "Crystalline Alembic", FR: "Alambic cristallin", DE: null, JP: null },
	"relicname-ARM-p_t_crystalline": { EN: "Crystalline Raising Hammer", FR: "Marteau de batteur cristallin", DE: null, JP: null },
	"relicname-BSM-p_t_crystalline": { EN: "Crystalline Cross-pein Hammer", FR: "Marteau à panne croisée cristallin", DE: null, JP: null },
	"relicname-CRP-p_t_crystalline": { EN: "Crystalline Saw", FR: "Scie cristalline", DE: null, JP: null },
	"relicname-CUL-p_t_crystalline": { EN: "Crystalline Frypan", FR: "Poêle à frire cristalline", DE: null, JP: null },
	"relicname-GSM-p_t_crystalline": { EN: "Crystalline Mallet", FR: "Massette cristalline", DE: null, JP: null },
	"relicname-LTW-p_t_crystalline": { EN: "Crystalline Round Knife", FR: "Couteau arrondi cristallin", DE: null, JP: null },
	"relicname-WVR-p_t_crystalline": { EN: "Crystalline Needle", FR: "Aiguille cristalline", DE: null, JP: null },
	"relicname-MIN-p_t_crystalline": { EN: "Crystalline Pickaxe", FR: "Pioche cristalline", DE: null, JP: null },
	"relicname-BTN-p_t_crystalline": { EN: "Crystalline Hatchet", FR: "Hachette cristalline", DE: null, JP: null },
	"relicname-FSH-p_t_crystalline": { EN: "Crystalline Fishing Rod", FR: "Canne à pêche cristalline", DE: null, JP: null },
	//#endregion Crystalline

	//#region Chora-Zoi's Crystalline
	"relicname-CRP-p_t_chora_zois_crystalline": { EN: "Chora-Zoi's Crystalline Saw", FR: null, DE: null, JP: null },
	"relicname-BSM-p_t_chora_zois_crystalline": { EN: "Chora-Zoi's Crystalline Cross-pein Hammer", FR: null, DE: null, JP: null },
	"relicname-ARM-p_t_chora_zois_crystalline": { EN: "Chora-Zoi's Crystalline Raising Hammer", FR: null, DE: null, JP: null },
	"relicname-GSM-p_t_chora_zois_crystalline": { EN: "Chora-Zoi's Crystalline Mallet", FR: null, DE: null, JP: null },
	"relicname-LTW-p_t_chora_zois_crystalline": { EN: "Chora-Zoi's Crystalline Round Knife", FR: null, DE: null, JP: null },
	"relicname-WVR-p_t_chora_zois_crystalline": { EN: "Chora-Zoi's Crystalline Needle", FR: null, DE: null, JP: null },
	"relicname-ALC-p_t_chora_zois_crystalline": { EN: "Chora-Zoi's Crystalline Alembic", FR: null, DE: null, JP: null },
	"relicname-CUL-p_t_chora_zois_crystalline": { EN: "Chora-Zoi's Crystalline Frypan", FR: null, DE: null, JP: null },
	"relicname-MIN-p_t_chora_zois_crystalline": { EN: "Chora-Zoi's Crystalline Pickaxe", FR: null, DE: null, JP: null },
	"relicname-BTN-p_t_chora_zois_crystalline": { EN: "Chora-Zoi's Crystalline Hatchet", FR: null, DE: null, JP: null },
	"relicname-FSH-p_t_chora_zois_crystalline": { EN: "Chora-Zoi's Crystalline Fishing Rod", FR: null, DE: null, JP: null },
	//#endregion

	//#region Brilliant
	"relicname-CRP-p_t_brilliant": { EN: "Brilliant Saw", FR: null, DE: null, JP: null },
	"relicname-BSM-p_t_brilliant": { EN: "Brilliant Cross-pein Hammer", FR: null, DE: null, JP: null },
	"relicname-ARM-p_t_brilliant": { EN: "Brilliant Raising Hammer", FR: null, DE: null, JP: null },
	"relicname-GSM-p_t_brilliant": { EN: "Brilliant Mallet", FR: null, DE: null, JP: null },
	"relicname-LTW-p_t_brilliant": { EN: "Brilliant Round Knife", FR: null, DE: null, JP: null },
	"relicname-WVR-p_t_brilliant": { EN: "Brilliant Needle", FR: null, DE: null, JP: null },
	"relicname-ALC-p_t_brilliant": { EN: "Brilliant Alembic", FR: null, DE: null, JP: null },
	"relicname-CUL-p_t_brilliant": { EN: "Brilliant Frypan", FR: null, DE: null, JP: null },
	"relicname-MIN-p_t_brilliant": { EN: "Brilliant Pickaxe", FR: null, DE: null, JP: null },
	"relicname-BTN-p_t_brilliant": { EN: "Brilliant Hatchet", FR: null, DE: null, JP: null },
	"relicname-FSH-p_t_brilliant": { EN: "Brilliant Fishing Rod", FR: null, DE: null, JP: null },
	//#endregion

	//#region Vrandtic Visionary's
	"relicname-CRP-p_t_vrandtic_visionarys": { EN: "Vrandtic Visionary's Saw", FR: null, DE: null, JP: null },
	"relicname-BSM-p_t_vrandtic_visionarys": { EN: "Vrandtic Visionary's Cross-pein Hammer", FR: null, DE: null, JP: null },
	"relicname-ARM-p_t_vrandtic_visionarys": { EN: "Vrandtic Visionary's Raising Hammer", FR: null, DE: null, JP: null },
	"relicname-GSM-p_t_vrandtic_visionarys": { EN: "Vrandtic Visionary's Mallet", FR: null, DE: null, JP: null },
	"relicname-LTW-p_t_vrandtic_visionarys": { EN: "Vrandtic Visionary's Round Knife", FR: null, DE: null, JP: null },
	"relicname-WVR-p_t_vrandtic_visionarys": { EN: "Vrandtic Visionary's Needle", FR: null, DE: null, JP: null },
	"relicname-ALC-p_t_vrandtic_visionarys": { EN: "Vrandtic Visionary's Alembic", FR: null, DE: null, JP: null },
	"relicname-CUL-p_t_vrandtic_visionarys": { EN: "Vrandtic Visionary's Frypan", FR: null, DE: null, JP: null },
	"relicname-MIN-p_t_vrandtic_visionarys": { EN: "Vrandtic Visionary's Pickaxe", FR: null, DE: null, JP: null },
	"relicname-BTN-p_t_vrandtic_visionarys": { EN: "Vrandtic Visionary's Hatchet", FR: null, DE: null, JP: null },
	"relicname-FSH-p_t_vrandtic_visionarys": { EN: "Vrandtic Visionary's Fishing Rod", FR: null, DE: null, JP: null },
	//#endregion

	//#region Lodestar
	"relicname-CRP-p_t_lodestar": { EN: "Lodestar Saw", FR: null, DE: null, JP: null },
	"relicname-BSM-p_t_lodestar": { EN: "Lodestar Cross-pein Hammer", FR: null, DE: null, JP: null },
	"relicname-ARM-p_t_lodestar": { EN: "Lodestar Raising Hammer", FR: null, DE: null, JP: null },
	"relicname-GSM-p_t_lodestar": { EN: "Lodestar Mallet", FR: null, DE: null, JP: null },
	"relicname-LTW-p_t_lodestar": { EN: "Lodestar Round Knife", FR: null, DE: null, JP: null },
	"relicname-WVR-p_t_lodestar": { EN: "Lodestar Needle", FR: null, DE: null, JP: null },
	"relicname-ALC-p_t_lodestar": { EN: "Lodestar Alembic", FR: null, DE: null, JP: null },
	"relicname-CUL-p_t_lodestar": { EN: "Lodestar Frypan", FR: null, DE: null, JP: null },
	"relicname-MIN-p_t_lodestar": { EN: "Lodestar Pickaxe", FR: null, DE: null, JP: null },
	"relicname-BTN-p_t_lodestar": { EN: "Lodestar Hatchet", FR: null, DE: null, JP: null },
	"relicname-FSH-p_t_lodestar": { EN: "Lodestar Fishing Rod", FR: null, DE: null, JP: null },
	//#endregion

	//#endregion Tool - Splendorous

	//#endregion Relic Names

	//#region Items
	"A Day in the Life: Battles for the Realm": { EN: "A Day in the Life: Battles for the Realm", FR: "Chroniques de batailles I", DE: "In der Hitze der Schlacht I", JP: "激戦の戦闘記録:第1集" },
	"A Day in the Life: Beyond the Rift": { EN: "A Day in the Life: Beyond the Rift", FR: "Chroniques de batailles II", DE: "In der Hitze der Schlacht II", JP: "激戦の戦闘記録:第2集" },
	"Abalathian Mistletoe": { EN: "Abalathian Mistletoe", FR: "Gui d'Abalathia", DE: "Abalathischer Mistelzweig", JP: "アバラシアミスルトゥ" },
	"Abalathian Spring Water": { EN: "Abalathian Spring Water", FR: "Eau de source d'Abalathia", DE: "Abalathisches Quellwasser", JP: "アバラシア天然水" },
	"Adamantite Francesca": { EN: "Adamantite Francesca", FR: "Francisque en adamantium", DE: "Adamantit-Franziska", JP: "アダマンフランシスカ" },
	"Adamantite Nugget": { EN: "Adamantite Nugget", FR: "Pépite d'adamant", DE: "Adamantit-Nugget", JP: "アダマンナゲット" },
	"Adamantite Ore": { EN: "Adamantite Ore", FR: "Minerai d'adamant", DE: "Adamantit-Erz", JP: "アダマン鉱" },
	"Aeolian Scimitar": { EN: "Aeolian Scimitar", FR: "Cimeterre éolien", DE: "Äolus-Scimitar", JP: "アイオリアンシミター" },
	"Aether Oil": { EN: "Aether Oil", FR: "Huile isolante", DE: "Äther-Öl", JP: "アラグの絶霊油" },
	/* Aetheric Density - in translations */
	/* Aetherlouse */
	"Aged Decanter": { EN: "Aged Decanter", FR: "Bouteille d'alcool séculaire", DE: "Antike Weinflasche", JP: "年代物の酒瓶" },
	"Aged Eye of Fire": { EN: "Aged Eye of Fire", FR: "Vieil œil-de-feu", DE: "Alter Feuerstein", JP: "オールドファイアペブル" },
	"Aged Grimoire": { EN: "Aged Grimoire", FR: "Grimoire séculaire", DE: "Antike Hexerfibel", JP: "年代物の魔道書" },
	"Aged Mortar": { EN: "Aged Mortar", FR: "Mortier séculaire", DE: "Antiker Mörser", JP: "年代物の乳鉢" },
	"Aged Mortar Pieces": { EN: "Aged Mortar Pieces", FR: "Vieil élément de mortier", DE: "Altes Mörserteil", JP: "オールドモーターパーツ" },
	"Aged Pestle": { EN: "Aged Pestle", FR: "Pilon séculaire", DE: "Antiker Stößel", JP: "年代物の乳棒" },
	"Aged Pestle Pieces": { EN: "Aged Pestle Pieces", FR: "Vieille tête de pilon", DE: "Alter Stößelkopf", JP: "オールドペストルヘッド" },
	"Aged Phial": { EN: "Aged Phial", FR: "Bouteille de remède séculaire", DE: "Antike Medizinflasche", JP: "年代物の薬瓶" },
	"Aged Ring": { EN: "Aged Ring", FR: "Bague séculaire", DE: "Antiker Ring", JP: "年代物の指輪" },
	"Aged Robe": { EN: "Aged Robe", FR: "Robe cléricale séculaire", DE: "Antikes Gewand", JP: "年代物の法衣" },
	"Aged Spear": { EN: "Aged Spear", FR: "Lance séculaire", DE: "Antike Lanze", JP: "年代物の長槍" },
	"Aged Spear Shaft": { EN: "Aged Spear Shaft", FR: "Vieille hampe de lance", DE: "Alter Lanzenschaft", JP: "オールドスピアシャフト" },
	"Aged Vellum": { EN: "Aged Vellum", FR: "Vieux rouleau de vélin", DE: "Altes Vellum", JP: "オールドベラム" },
	"Ala Mhigan Salt Crystal": { EN: "Ala Mhigan Salt Crystal", FR: "Sel d'Ala Mhigo", DE: "Mhigischer Salzkristall", JP: "アラミゴ塩" },
	"Alexandrite": { EN: "Alexandrite", FR: "Alexandrite", DE: "Alexandrit", JP: "アレキサンドライト" },
	"Allagan Resin": { EN: "Allagan Resin", FR: "Résine allagoise", DE: "Allag-Harz", JP: "アラグの樹脂" },
	"Amdapor Vellum": { EN: "Amdapor Vellum", FR: "Rouleau de vélin d'Amdapor", DE: null, JP: null },
	"Archaic Enchanted Ink": { EN: "Archaic Enchanted Ink", FR: "Encre enchantée ancienne", DE: "Archaische Zaubertinte", JP: "古式エンチャントインク" },
	"Atma of the Archer": { EN: "Atma of the Archer", FR: "Âtma du sagittaire", DE: "Atma des Schützen", JP: "人馬のアートマ" },
	"Atma of the Bull": { EN: "Atma of the Bull", FR: "Âtma du taureau", DE: "Atma des Stiers", JP: "金牛のアートマ" },
	"Atma of the Crab": { EN: "Atma of the Crab", FR: "Âtma du cancer", DE: "Atma des Krebses", JP: "巨蟹のアートマ" },
	"Atma of the Fish": { EN: "Atma of the Fish", FR: "Âtma des poissons", DE: "Atma der Fische", JP: "双魚のアートマ" },
	"Atma of the Goat": { EN: "Atma of the Goat", FR: "Âtma du capricorne", DE: "Atma des Steinbocks", JP: "磨羯のアートマ" },
	"Atma of the Lion": { EN: "Atma of the Lion", FR: "Âtma du lion", DE: "Atma des Löwen", JP: "獅子のアートマ" },
	"Atma of the Maiden": { EN: "Atma of the Maiden", FR: "Âtma de la vierge", DE: "Atma der Jungfrau", JP: "処女のアートマ" },
	"Atma of the Ram": { EN: "Atma of the Ram", FR: "Âtma du bélier", DE: "Atma des Widders", JP: "白羊のアートマ" },
	"Atma of the Scales": { EN: "Atma of the Scales", FR: "Âtma de la balance", DE: "Atma der Waage", JP: "天秤のアートマ" },
	"Atma of the Scorpion": { EN: "Atma of the Scorpion", FR: "Âtma du scorpion", DE: "Atma des Skorpions", JP: "天蠍のアートマ" },
	"Atma of the Twins": { EN: "Atma of the Twins", FR: "Âtma des gémeaux", DE: "Atma der Zwillinge", JP: "双子のアートマ" },
	"Atma of the Water-bearer": { EN: "Atma of the Water-bearer", FR: "Âtma du verseau", DE: "Atma des Wassermanns", JP: "宝瓶のアートマ" },
	/* Allagan Hunter */
	"Atrociraptor Skin": { EN: "Atrociraptor Skin", FR: "Peau d'atrociraptor", DE: "Atrociraptorhaut", JP: "アトロシラプトルの粗皮" },
	"Barbarian's Bardiche": { EN: "Barbarian's Bardiche", FR: "Bardiche de barbare", DE: "Barbaren-Berdysch", JP: "バーバリアンバルディッシュ" },
	"Basilisk Egg": { EN: "Basilisk Egg", FR: "Œuf de basilic", DE: "Basilisken-Ei", JP: "バジリスクの卵" },
	"Basilisk Whetstone": { EN: "Basilisk Whetstone", FR: "Pierre à aiguiser basilic", DE: "Basilisk-Wetzstein", JP: "卵砥石" },
	"Battledance Materia III": { EN: "Battledance Materia III", FR: "Matéria de la parade stratégique III", DE: "Fechtkunst-Materia III", JP: "剛柔のマテリダ" },
	"Belah'dian Silver": { EN: "Belah'dian Silver", FR: "Plaque d'argent belahdien", DE: "Belah'disches Silber", JP: "ベラフディアンシルバー" },
	"Birch Log": { EN: "Birch Log", FR: "Rondin de bouleau", DE: "Birkenholz", JP: "バーチ原木" },
	"Birch Lumber": { EN: "Birch Lumber", FR: "Madrier de bouleau", DE: "Birken-Bauholz", JP: "バーチ材" },
	"Birch Sap": { EN: "Birch Sap", FR: "Sève de bouleau", DE: "Birkensaft", JP: "バーチ樹液" },
	"Birch Syrup": { EN: "Birch Syrup", FR: "Sirop de bouleau", DE: "Birkensirup", JP: "バーチシロップ" },
	"Bitter Memory of the Dying": { EN: "Bitter Memory of the Dying", FR: "Amas mémoriel de sauvagerie", DE: "Erinnerungsklumpen der Wut", JP: "猛々しき記憶の一塊" },
	"Black Alumen": { EN: "Black Alumen", FR: "Alun noir", DE: "Schwarzalaun", JP: "ブラックアルメン" },
	"Black Pepper": { EN: "Black Pepper", FR: "Poivre noir", DE: "Schwarzer Pfeffer", JP: "ブラックペッパー" },
	"Black Soil": { EN: "Black Soil", FR: "Terreau noir", DE: "Schwarze Erde", JP: "黒土" },
	"Bleak Memory of the Dying": { EN: "Bleak Memory of the Dying", FR: "Amas mémoriel de grièveté", DE: "Erinnerungsklumpen der Last", JP: "重々しき記憶の一塊" },
	"Blessed Fletchings": { EN: "Blessed Fletchings", FR: "Penne bénie", DE: "Geweihte Befiederung", JP: "祝福された矢羽" },
	"Bloody Bardiche Head": { EN: "Bloody Bardiche Head", FR: "Lame de bardiche ensanglantée", DE: "Blutiger Berdysch-Kopf", JP: "バルディッシュヘッド(ブラッディ)" },
	"Bloody Bow Rim": { EN: "Bloody Bow Rim", FR: "Branches d'arc ensanglantées", DE: "Blutiger Bogen-Wurfarm", JP: "ボウリム(ブラッディ)" },
	"Bloody Cesti Covers": { EN: "Bloody Cesti Covers", FR: "Couvertures de cestes ensanglantées", DE: "Blutige Caesti-Stulpe", JP: "セスタスカバー(ブラッディ)" },
	"Bloody Grimoire Binding": { EN: "Bloody Grimoire Binding", FR: "Couverture de grimoire ensanglantée", DE: "Blutiger Grimoire-Einband", JP: "グリモアカバー(ブラッディ)" },
	"Bloody Knife Blades": { EN: "Bloody Knife Blades", FR: "Lame de couteau ensanglantée", DE: "Blutige Messerklingen", JP: "ナイフブレード(ブラッディ)" },
	"Bloody Lance Head": { EN: "Bloody Lance Head", FR: "Lame de lance ensanglantée", DE: "Blutige Lanzenspitze", JP: "ランスヘッド(ブラッディ)" },
	"Blunt Aeolian Scimitar": { EN: "Blunt Aeolian Scimitar", FR: "Cimeterre éolien émoussé", DE: "Stumpfer Äolus-Scimitar", JP: "オールドアイオリアンシミター" },
	"Bombard Core": { EN: "Bombard Core", FR: "Noyau de grand monstre explosif", DE: "Feuerbiest-Essenz", JP: "大炎獣の心核" },
	"Brass Kettle": { EN: "Brass Kettle", FR: "Bouilloire en laiton", DE: "Messingkessel", JP: "ブラスケトル" },
	"Bright Fire Rock": { EN: "Bright Fire Rock", FR: "Pierre de feu intense", DE: "Leuchtender Feuergesteinsbrocken", JP: "強火性岩" },
	"Bright Lightning Rock": { EN: "Bright Lightning Rock", FR: "Pierre de foudre intense", DE: "Leuchtender Blitzgesteinsbrocken", JP: "強雷性岩" },
	"Bronze Lake Crystal": { EN: "Bronze Lake Crystal", FR: "Cristal d'airain", DE: "Bronzekristall", JP: "ブロンズクリスタル" },
	"Buffalo Milk": { EN: "Buffalo Milk", FR: "Lait de bufflonne", DE: "Büffelmilch", JP: "水牛乳" },
	"Champion's Lance": { EN: "Champion's Lance", FR: "Lance de champion", DE: "Mythische Lanze", JP: "チャンピオンランス" },
	"Cinnabar": { EN: "Cinnabar", FR: "Minium", DE: "Mennige", JP: "辰砂" },
	"Cloud Cotton Boll": { EN: "Cloud Cotton Boll", FR: "Fleur de coton des nuages", DE: "Wolken-Baumwoll-Samenkapsel", JP: "雲綿" },
	"Cloves": { EN: "Cloves", FR: "Girofle", DE: "Gewürznelke", JP: "クローヴ" },
	"Compact Axle": { EN: "Compact Axle", FR: "Hampe miniature", DE: "Miniaturachse", JP: "超小型シャフト" },
	"Compact Spring": { EN: "Compact Spring", FR: "Ressort miniature", DE: "Miniaturfeder", JP: "超小型スプリング" },
	"Complementary Chondrite": { EN: "Complementary Chondrite", FR: "Chondrite rarissime", DE: "Seltener Chondrit", JP: "希少コンドライト" },
	"Crystal Sand": { EN: "Crystal Sand", FR: "Sable de cristal", DE: "Kristallsand", JP: "クリスタルサンド" },
	"Crystalline Scale": { EN: "Crystalline Scale", FR: "Écaille de dragon de cristal", DE: "Kristalldrachen-Schuppe", JP: "水晶龍の鱗" },
	"Cypress Log": { EN: "Cypress Log", FR: "Rondin de cyprès", DE: "Zypressen-Bauholz", JP: "サイプレス原木" },
	"Dark Chestnut Log": { EN: "Dark Chestnut Log", FR: "Rondin de châtaignier sombre", DE: "Dunkles Kastanienholz", JP: "ダークチェスナット原木" },
	"Dark Chestnut Lumber": { EN: "Dark Chestnut Lumber", FR: "Madrier de châtaignier sombre", DE: "Dunkles Kastanien-Bauholz", JP: "ダークチェスナット材" },
	"Darksteel Nugget": { EN: "Darksteel Nugget", FR: "Pépite de sombracier", DE: "Dunkelstahl-Nugget", JP: "ダークスチールナゲット" },
	"Darksteel Ore": { EN: "Darksteel Ore", FR: "Minerai de sombracier", DE: "Dunkelstahlerz", JP: "闇鉄鉱" },
	"Dawnborne Aethersand": { EN: "Dawnborne Aethersand", FR: "Sable éthéréen de l'aurore", DE: "Morgengrauen-Seelensand", JP: "暁光の霊砂" },
	"Desert Saffron": { EN: "Desert Saffron", FR: "Safran du désert", DE: "Safran", JP: "デザートサフラン" },
	"Dimythrite Ore": { EN: "Dimythrite Ore", FR: "Minerai de mithril noble", DE: "Edelmithril-Erzklumpen", JP: "闇霊銀鉱" },
	"Dimythrite Sand": { EN: "Dimythrite Sand", FR: "Sable de mithril noble", DE: "Edelmithril-Sandklumpen", JP: "闇霊銀砂" },
	"Dispelling Arrow": { EN: "Dispelling Arrow", FR: "Flèche-amulette", DE: "Dämonenbann-Pfeil", JP: "破魔矢" },
	"Dravanian Mistletoe": { EN: "Dravanian Mistletoe", FR: "Gui de Dravania", DE: "Dravanischer Mistelzweig", JP: "ドラヴァニアミスルトゥ" },
	"Dravanian Spring Water": { EN: "Dravanian Spring Water", FR: "Eau de source de Dravania", DE: "Dravanisches Quellwasser", JP: "ドラヴァニア天然水" },
	"Dried Ether": { EN: "Dried Ether", FR: "Éther séché", DE: "Trockenäther", JP: "ドライエーテル" },
	"Dwarven Cotton": { EN: "Dwarven Cotton", FR: "Étoffe de coton nain", DE: "Zwergenwollstoff", JP: "ドワーフ綿布" },
	"Dwarven Cotton Boll": { EN: "Dwarven Cotton Boll", FR: "Fleur de coton nain", DE: "Zwergenwolle", JP: "ドワーフ綿花" },
	"Dwarven Cotton Thread": { EN: "Dwarven Cotton Thread", FR: "Fil de coton nain", DE: "Zwergenwollgarn", JP: "ドワーフ綿糸" },
	"Dwarven Mythril Ingot": { EN: "Dwarven Mythril Ingot", FR: "Lingot de mithril noble", DE: "Edelmithril-Barren", JP: "ハイミスリルインゴット" },
	"Dwarven Mythril Nugget": { EN: "Dwarven Mythril Nugget", FR: "Pépite de mithril noble", DE: "Edelmithril-Nugget", JP: "ハイミスリルナゲット" },
	"Eagle Feather": { EN: "Eagle Feather", FR: "Plume d'aigle", DE: "Adlerfeder", JP: "イーグルの羽根" },
	"Earth Cluster": { EN: "Earth Cluster", FR: "Agrégat de terre", DE: "Erdpolykristall", JP: "アースクラスター" },
	"Earth Crystal": { EN: "Earth Crystal", FR: "Cristal de terre", DE: "Erdkristall", JP: "アースクリスタル" },
	"Earth Shard": { EN: "Earth Shard", FR: "Éclat de terre", DE: "Erdscherbe", JP: "アースシャード" },
	"Effervescent Water": { EN: "Effervescent Water", FR: "Eau effervescente", DE: "Sprudelwasser", JP: "ソーダ水" },
	"Electrum Sand": { EN: "Electrum Sand", FR: "Sable d'électrum", DE: "Elektrumsand", JP: "青金砂" },
	"Enchanted Gold Ink": { EN: "Enchanted Gold Ink", FR: "Encre d'or enchantée", DE: "Magische Goldtinte", JP: "ゴールドエンチャントインク" },
	"Erudite's Picatrix of Casting": { EN: "Erudite's Picatrix of Casting", FR: "Picatrix d'incantateur de lettré", DE: "Gelehrten-Grimoire der Magie", JP: "エルダイトキャスターピカトリクス" },
	"Erudite's Picatrix of Healing": { EN: "Erudite's Picatrix of Healing", FR: "Picatrix de soigneur de lettré", DE: "Gelehrten-Grimoire der Heilung", JP: "エルダイトヒーラーピカトリクス" },
	"Eureka Fragment": { EN: "Eureka Fragment", FR: "Fragment d'Eurêka", DE: "Eureka-Fragment", JP: "エウレカの断片" },
	"Extra Effervescent Water": { EN: "Extra Effervescent Water", FR: "Eau très effervescente", DE: "Starksprudelwasser", JP: "強ソーダ水" },
	/* Dragonspine */
	"Fire Cluster": { EN: "Fire Cluster", FR: "Agrégat de feu", DE: "Feuerpolykristall", JP: "ファイアクラスター" },
	"Fire Crystal": { EN: "Fire Crystal", FR: "Cristal de feu", DE: "Feuerkristall", JP: "ファイアクリスタル" },
	"Fire Shard": { EN: "Fire Shard", FR: "Éclat de feu", DE: "Feuerscherbe", JP: "ファイアシャード" },
	"Flax": { EN: "Flax", FR: "Fibres de lin", DE: "Flachs", JP: "亜麻" },
	/* Flintstrike */
	"Frantoio": { EN: "Frantoio", FR: "Frantoio", DE: "Frantoio", JP: "フラントーヨ" },
	"Frosted Protean Crystal": { EN: "Frosted Protean Crystal", FR: "Cristal instable de glace", DE: "Instabiler Eiskristall", JP: "乱属性クリスタル【氷】" },
	"Furite Sand": { EN: "Furite Sand", FR: "Sable de halonite", DE: "Halone-Sand", JP: "戦神砂" },
	"Furnace Ring": { EN: "Furnace Ring", FR: "Bague du feu", DE: "Feuerring", JP: "炎の指輪" },
	"Furnace Ring (HQ)": { EN: "Furnace Ring (HQ)", FR: "Bague du feu (HQ)", DE: "Feuerring (HQ)", JP: "炎の指輪 (HQ)" },
	"Gold Sand": { EN: "Gold Sand", FR: "Sable d'or", DE: "Goldsand", JP: "金砂" },
	"Grade 4 Artisanal Skybuilders' Barbgrass": { EN: "Grade 4 Artisanal Skybuilders' Barbgrass", FR: "Tige de prêle d'hiver sur commande de restauration (4e phase)", DE: "Himmelsang-Schachtelhalm", JP: "第四次復興用の特注研草" },
	"Grade 4 Artisanal Skybuilders' Caiman": { EN: "Grade 4 Artisanal Skybuilders' Caiman", FR: "Caïman sur commande de restauration (4e phase)", DE: "Himmelsang-Kaiman", JP: "第四次復興用の特注カイマン" },
	"Grade 4 Artisanal Skybuilders' Cloudstone": { EN: "Grade 4 Artisanal Skybuilders' Cloudstone", FR: "Minerai sur commande de restauration (4e phase)", DE: "Himmelsang-Edelerz", JP: "第四次復興用の特注鉱石" },
	"Grade 4 Artisanal Skybuilders' Cocoon": { EN: "Grade 4 Artisanal Skybuilders' Cocoon", FR: "Cocon sur commande de restauration (4e phase)", DE: "Himmelsang-Kokon", JP: "第四次復興用の特注マユ" },
	"Grade 4 Artisanal Skybuilders' Ice Stalagmite": { EN: "Grade 4 Artisanal Skybuilders' Ice Stalagmite", FR: "Stalagmite de glace sur commande de restauration (4e phase)", DE: "Himmelsang-Eisstalagmit", JP: "第四次復興用の特注氷筍" },
	"Grade 4 Artisanal Skybuilders' Log": { EN: "Grade 4 Artisanal Skybuilders' Log", FR: "Rondin de bois sur commande de restauration (4e phase)", DE: "Himmelsang-Edelholzscheit", JP: "第四次復興用の特注原木" },
	"Grade 4 Artisanal Skybuilders' Prismstone": { EN: "Grade 4 Artisanal Skybuilders' Prismstone", FR: "Cristal iridescent sur commande de restauration (4e phase)", DE: "Himmelsang-Spektralkristallsplitter", JP: "第四次復興用の特注虹結晶" },
	"Grade 4 Artisanal Skybuilders' Raspberry": { EN: "Grade 4 Artisanal Skybuilders' Raspberry", FR: "Framboises sur commande de restauration (4e phase)", DE: "Himmelsang-Edelhimbeere", JP: "第四次復興用の特注ラズベリー" },
	"Grade 4 Artisanal Skybuilders' Silex": { EN: "Grade 4 Artisanal Skybuilders' Silex", FR: "Silice sur commande de restauration (4e phase)", DE: "Himmelsang-Silex", JP: "第四次復興用の特注珪砂" },
	"Grade 4 Artisanal Skybuilders' Spring Water": { EN: "Grade 4 Artisanal Skybuilders' Spring Water", FR: "Eau minérale sur commande de restauration (4e phase)", DE: "Himmelsang-Spezialwasser", JP: "第四次復興用の特注天然水" },
	"Gobmachine Bangplate": { EN: "Gobmachine Bangplate", FR: "Blindage de gobtank G-VI", DE: null, JP: null },
	"Grenade Ash": { EN: "Grenade Ash", FR: "Cendres de grenado", DE: "Granatenasche", JP: "グレネードの灰" },
	"Growth Formula Delta": { EN: "Growth Formula Delta", FR: "Formule de croissance delta", DE: "Wachstumsformel Delta", JP: "グロースフォーミュラ・デルタ" },
	"Hallowed Chestnut Lumber": { EN: "Hallowed Chestnut Lumber", FR: "Madrier de châtaignier sanctifié", DE: "Geheiligtes Kastanien-Bauholz", JP: "ハロードチェスナット材" },
	"Hallowed Water": { EN: "Hallowed Water", FR: "Eau sanctifiée", DE: "Geheiligtes Wasser", JP: "祝聖水" },
	/* Hammerhead Shark */
	"Hardsilver Nugget": { EN: "Hardsilver Nugget", FR: "Pépite de durargent", DE: "Hartsilber-Nugget", JP: "ハードシルバーナゲット" },
	"Hardsilver Sand": { EN: "Hardsilver Sand", FR: "Sable de durargent", DE: "Hartsilber-Sandklumpen", JP: "硬銀砂" },
	"Harrowing Memory of the Dying": { EN: "Harrowing Memory of the Dying", FR: "Amas mémoriel de terreur", DE: "Erinnerungsklumpen der Furcht", JP: "恐ろしき記憶の一塊" },
	"Haunting Memory of the Dying": { EN: "Haunting Memory of the Dying", FR: "Amas mémoriel de menace", DE: "Erinnerungsklumpen des Unheils", JP: "禍々しき記憶の一塊" },
	"Heavens' Eye Materia III": { EN: "Heavens' Eye Materia III", FR: "Matéria du regard divin III", DE: "Hellseher-Materia III", JP: "天眼のマテリダ" },
	"Highland Flour": { EN: "Highland Flour", FR: "Farine des hautes terres", DE: "Hochlandmehl", JP: "ハイランド小麦粉" },
	"Highland Spring Water": { EN: "Highland Spring Water", FR: "Eau de source des hauts plateaux", DE: "Hochlandwasser", JP: "高地天然水" },
	"Highland Wheat": { EN: "Highland Wheat", FR: "Blé des hautes terres", DE: "Hochlandweizen", JP: "ハイランド小麦" },
	"Holy Water": { EN: "Holy Water", FR: "Eau bénite", DE: "Segenszeichen", JP: "ホリーウォーター" },
	"Horn of the Beast": { EN: "Horn of the Beast", FR: "Corne de Batraal", DE: null, JP: null },
	"Hydatos Crystal": { EN: "Hydatos Crystal", FR: "Cristal Hydatos", DE: "Hydatos-Kristall", JP: "ヒュダトスクリスタル" },
	"Ice Cluster": { EN: "Ice Cluster", FR: "Agrégat de glace", DE: "Eispolykristall", JP: "アイスクラスター" },
	"Ice Crystal": { EN: "Ice Crystal", FR: "Cristal de glace", DE: "Eiskristall", JP: "アイスクリスタル" },
	"Ice Shard": { EN: "Ice Shard", FR: "Éclat de glace", DE: "Eisscherbe", JP: "アイスシャード" },
	"Indigo Pearl": { EN: "Indigo Pearl", FR: "Perle de l'Indigo", DE: null, JP: null },
	"Iron Ore": { EN: "Iron Ore", FR: "Minerai de fer", DE: "Eisenerz", JP: "鉄鉱" },
	"Kingcake": { EN: "Kingcake", FR: "Brioche des rois", DE: "Brioche des Rois", JP: "ブリオッシュ・デ・ロワ" },
	"Kraken Blood": { EN: "Kraken Blood", FR: "Sang de Kraken", DE: null, JP: null },
	"Light": { EN: "Light", FR: "Lumière", DE: null, JP: null },
	"Lightning Cluster": { EN: "Lightning Cluster", FR: "Agrégat de foudre", DE: "Blitzpolykristall", JP: "ライトニングクラスター" },
	"Lightning Crystal": { EN: "Lightning Crystal", FR: "Cristal de foudre", DE: "Blitzkristall", JP: "ライトニングクリスタル" },
	"Lightning Shard": { EN: "Lightning Shard", FR: "Éclat de foudre", DE: "Blitzscherbe", JP: "ライトニングシャード" },
	"Lignum Vitae Log": { EN: "Lignum Vitae Log", FR: "Rondin de gaïac", DE: "Guajakholzscheit", JP: "リグナムバイタ原木" },
	"Lignum Vitae Lumber": { EN: "Lignum Vitae Lumber", FR: "Madrier de gaïac", DE: "Guajak-Bauholz", JP: "リグナムバイタ材" },
	"Linen Yarn": { EN: "Linen Yarn", FR: "Fil de lin", DE: "Leinengarn", JP: "亜麻糸" },
	"Loathsome Memory of the Dying": { EN: "Loathsome Memory of the Dying", FR: "Amas mémoriel de répugnance", DE: "Erinnerungsklumpen des Kummers", JP: "苦々しき記憶の一塊" },
	"Longarm's Composite Bow": { EN: "Longarm's Composite Bow", FR: "Arc composite double-allonge", DE: "Langarm-Kompositbogen", JP: "ロングアーム・コンポジットボウ" },
	"Lost Treasure of Amdapor": { EN: "Lost Treasure of Amdapor", FR: "Trésor perdu d'Amdapor", DE: null, JP: null },
	"Lost Treasure of Pharos Sirius": { EN: "Lost Treasure of Pharos Sirius", FR: "Trésor perdu du Phare de Sirius", DE: null, JP: null },
	"Lost Treasure of the Stone Vigil": { EN: "Lost Treasure of the Stone Vigil", FR: "Trésor perdu du Vigile de Pierre", DE: null, JP: null },
	"Lost Treasure of the Tam-Tara Deepcroft": { EN: "Lost Treasure of the Tam-Tara Deepcroft", FR: "Trésor perdu de Tam-Tara", DE: null, JP: null },
	"Louhi's Ice": { EN: "Louhi's Ice", FR: "Glace de Louhi", DE: "Louhi-Eis", JP: "ロウヒの氷片" },
	/* Pagos Crystal */
	"Luminous Earth Crystal": { EN: "Luminous Earth Crystal", FR: "Cristal lumineux de terre", DE: "Strahlender Erdkristall", JP: "発光性アースクリスタル" },
	"Luminous Fire Crystal": { EN: "Luminous Fire Crystal", FR: "Cristal lumineux de feu", DE: "Strahlender Feuerkristall", JP: "発光性ファイアクリスタル" },
	"Luminous Ice Crystal": { EN: "Luminous Ice Crystal", FR: "Cristal lumineux de glace", DE: "Strahlender Eiskristall", JP: "発光性アイスクリスタル" },
	"Luminous Lightning Crystal": { EN: "Luminous Lightning Crystal", FR: "Cristal lumineux de foudre", DE: "Strahlender Blitzkristall", JP: "発光性ライトニングクリスタル" },
	"Luminous Water Crystal": { EN: "Luminous Water Crystal", FR: "Cristal lumineux d'eau", DE: "Strahlender Wasserkristall", JP: "発光性ウォータークリスタル" },
	"Luminous Wind Crystal": { EN: "Luminous Wind Crystal", FR: "Cristal lumineux de vent", DE: "Strahlender Windkristall", JP: "発光性ウィンドクリスタル" },
	"Lurid Memory of the Dying": { EN: "Lurid Memory of the Dying", FR: "Amas mémoriel de rudesse", DE: "Erinnerungsklumpen der Wildheit", JP: "荒々しき記憶の一塊" },
	"Madman's Whispering Rod": { EN: "Madman's Whispering Rod", FR: "Bâton vivant du dément", DE: "Flüsternder Stab des Verrückten", JP: "マッドマンリビングロッド" },
	"Mahatma of the Archer": { EN: "Mahatma of the Archer", FR: "Mahatma du Sagittaire", DE: null, JP: null },
	"Mahatma of the Bull": { EN: "Mahatma of the Bull", FR: "Mahatma du Taureau", DE: null, JP: null },
	"Mahatma of the Crab": { EN: "Mahatma of the Crab", FR: "Mahatma du Cancer", DE: null, JP: null },
	"Mahatma of the Fish": { EN: "Mahatma of the Fish", FR: "Mahatma du Poisson", DE: null, JP: null },
	"Mahatma of the Goat": { EN: "Mahatma of the Goat", FR: "Mahatma du Capricorne", DE: null, JP: null },
	"Mahatma of the Lion": { EN: "Mahatma of the Lion", FR: "Mahatma du Lion", DE: null, JP: null },
	"Mahatma of the Maiden": { EN: "Mahatma of the Maiden", FR: "Mahatma de la Vierge", DE: null, JP: null },
	"Mahatma of the Ram": { EN: "Mahatma of the Ram", FR: "Mahatma du Bélier", DE: null, JP: null },
	"Mahatma of the Scales": { EN: "Mahatma of the Scales", FR: "Mahatma de la Balance", DE: null, JP: null },
	"Mahatma of the Scorpion": { EN: "Mahatma of the Scorpion", FR: "Mahatma du Scorpion", DE: null, JP: null },
	"Mahatma of the Twins": { EN: "Mahatma of the Twins", FR: "Mahatma des Gémeaux", DE: null, JP: null },
	"Mahatma of the Water-bearer": { EN: "Mahatma of the Water-bearer", FR: "Mahatma du Verseau", DE: null, JP: null },
	"Mahogany Log": { EN: "Mahogany Log", FR: "Rondin d'acajou", DE: "Mahagoni-Holzscheit", JP: "マホガニー原木" },
	"Mahogany Lumber": { EN: "Mahogany Lumber", FR: "Madrier d'acajou", DE: "Mahagoni-Bauholz", JP: "マホガニー材" },
	"Manasilver Sand": { EN: "Manasilver Sand", FR: "Sable de mana-argent", DE: "Manasilber-Sandklumpen", JP: "魔銀砂" },
	"Manderium Meteorite": { EN: "Manderium Meteorite", FR: "Météorite rarissime", DE: "Manderium-Meteoritenbrocken", JP: "希少メテオライト" },
	"Mineral Water": { EN: "Mineral Water", FR: "Eau minérale", DE: "Mineralwasser", JP: "ミネラルウォーター" },
	"Moogle Miniature": { EN: "Moogle Miniature", FR: "Fève mog", DE: "Porzellan-Mogry", JP: "モグモグフェーヴ" },
	"Mythrite Ore": { EN: "Mythrite Ore", FR: "Minerai de mithrite", DE: "Mythrit-Erz", JP: "精霊銀鉱" },
	"Mythrite Sand": { EN: "Mythrite Sand", FR: "Sable de mithrite", DE: "Mythrit-Sandklumpen", JP: "精霊銀砂" },
	"Narasimha Hide": { EN: "Narasimha Hide", FR: "Membrane de Narasimha", DE: null, JP: null },
	"Night Vinegar": { EN: "Night Vinegar", FR: "Vinaigre noir", DE: "Schwarzer Essig", JP: "ダークビネガー" },
	"Ochu Vine": { EN: "Ochu Vine", FR: "Sarment d'otyugh", DE: "Ochu-Ranke", JP: "オチューのつる" },
	"Oddly Delicate Adamantite Ore": { EN: "Oddly Delicate Adamantite Ore", FR: "Minerai d'adamant d'affinage final", DE: "Adamantit-Erz zur Himmelsstahl-Werkzeug-Vollendung", JP: "最終改良用のアダマン鉱" },
	"Oddly Delicate Birch Log": { EN: "Oddly Delicate Birch Log", FR: "Rondin de bouleau d'affinage final", DE: "Birkenholz zur Himmelsstahl-Werkzeug-Vollendung", JP: "最終改良用のバーチ原木" },
	"Oddly Delicate Celestine": { EN: "Oddly Delicate Celestine", FR: "Célestine d'affinage final", DE: "Coelestine zur Himmelsstahl-Werkzeug-Vollendung", JP: "最終改良用のセレスタイン" },
	"Oddly Delicate Feather": { EN: "Oddly Delicate Feather", FR: "Plume d'affinage final", DE: "Feder zur Himmelsstahl-Werkzeug-Vollendung", JP: "最終改良用の羽根" },
	"Oddly Delicate Gazelle Hide": { EN: "Oddly Delicate Gazelle Hide", FR: "Peau de gazelle d'affinage final", DE: "Gazellenhaut zur Himmelsstahl-Werkzeug-Vollendung", JP: "最終改良用のガゼルの粗皮" },
	"Oddly Delicate Gazelle Leather": { EN: "Oddly Delicate Gazelle Leather", FR: "Cuir de gazelle d'affinage final", DE: "Gazellenleder zur Himmelsstahl-Werkzeug-Vollendung", JP: "最終改良用のガゼルレザー" },
	"Oddly Delicate Hammerhead Shark": { EN: "Oddly Delicate Hammerhead Shark", FR: "Requin-marteau d'affinage final", DE: "Hammerhai zur Himmelsstahl-Werkzeug-Vollendung", JP: "最終改良用のハンマーヘッド" },
	"Oddly Delicate Holy Water": { EN: "Oddly Delicate Holy Water", FR: "Eau sacrée d'affinage final", DE: "Heiliges Wasser zur Himmelsstahl-Werkzeug-Vollendung", JP: "最終改良用の聖水" },
	"Oddly Delicate Mistletoe": { EN: "Oddly Delicate Mistletoe", FR: "Gui d'affinage final", DE: "Mistelzweig zur Himmelsstahl-Werkzeug-Vollendung", JP: "最終改良用のミスルトゥ" },
	"Oddly Delicate Pine Log": { EN: "Oddly Delicate Pine Log", FR: "Rondin de pin d'affinage final", DE: "Kiefern-Holzscheit zur Himmelsstahl-Werkzeug-Vollendung", JP: "最終改良用のパイン原木" },
	"Oddly Delicate Pine Lumber": { EN: "Oddly Delicate Pine Lumber", FR: "Madrier de pin d'affinage final", DE: "Kiefern-Bauholz zur Himmelsstahl-Werkzeug-Vollendung", JP: "最終改良用のパイン材" },
	"Oddly Delicate Raw Celestine": { EN: "Oddly Delicate Raw Celestine", FR: "Célestine brute d'affinage final", DE: "Roh-Coelestine zur Himmelsstahl-Werkzeug-Vollendung", JP: "最終改良用の天青石" },
	"Oddly Delicate Raw Jade": { EN: "Oddly Delicate Raw Jade", FR: "Jade brut d'affinage final", DE: "Roh-Jade zur Himmelsstahl-Werkzeug-Vollendung", JP: "最終改良用のジェード原石" },
	"Oddly Delicate Rhea": { EN: "Oddly Delicate Rhea", FR: "Orties indigo d'affinage final", DE: "Rhea zur Himmelsstahl-Werkzeug-Vollendung", JP: "最終改良用の藍麻" },
	"Oddly Delicate Rhea Cloth": { EN: "Oddly Delicate Rhea Cloth", FR: "Étoffe de coutil indigo d'affinage final", DE: "Rheastoff zur Himmelsstahl-Werkzeug-Vollendung", JP: "最終改良用の藍上布" },
	"Oddly Delicate Scheelite": { EN: "Oddly Delicate Scheelite", FR: "Minerai de scheelite d'affinage final", DE: "Scheelit zur Himmelsstahl-Werkzeug-Vollendung", JP: "最終改良用の灰重石" },
	"Oddly Delicate Shark Oil": { EN: "Oddly Delicate Shark Oil", FR: "Huile de requin d'affinage final", DE: "Haifischtran zur Himmelsstahl-Werkzeug-Vollendung", JP: "最終改良用のシャークオイル" },
	"Oddly Delicate Silver Gear": { EN: "Oddly Delicate Silver Gear", FR: "Pignon argenté d'affinage final", DE: "Silberzahnrad zur Himmelsstahl-Werkzeug-Vollendung", JP: "最終改良用のシルバーギア" },
	"Oddly Delicate Silvergrace Ore": { EN: "Oddly Delicate Silvergrace Ore", FR: "Minerai de graçargent d'affinage final", DE: "Silbergunsterz zur Himmelsstahl-Werkzeug-Vollendung", JP: "最終改良用の清銀鉱" },
	"Oddly Delicate Wolfram Square": { EN: "Oddly Delicate Wolfram Square", FR: "Plaquette de tungstène d'affinage final", DE: "Wolframplättchen zur Himmelsstahl-Werkzeug-Vollendung", JP: "最終改良用のウォルフラムプレートレット" },
	"Oddly Specific Aloe": { EN: "Oddly Specific Aloe", FR: "Aloès d'affinage", DE: "Werkstätten-Aloe-Blatt", JP: "改良用のアロエ" },
	"Oddly Specific Amber": { EN: "Oddly Specific Amber", FR: "Ambre d'affinage", DE: "Werkstätten-Bernstein", JP: "改良用のアンバー" },
	"Oddly Specific Bauble": { EN: "Oddly Specific Bauble", FR: "Joyau rare d'affinage", DE: "Werkstätten-Perle", JP: "改良用の珍宝" },
	"Oddly Specific Cedar Log": { EN: "Oddly Specific Cedar Log", FR: "Rondin de cèdre d'affinage", DE: "Werkstätten-Zedern-Holzscheit", JP: "改良用のシーダー原木" },
	"Oddly Specific Cedar Lumber": { EN: "Oddly Specific Cedar Lumber", FR: "Madrier de cèdre d'affinage", DE: "Werkstätten-Zedern-Bauholz", JP: "改良用のシーダー材" },
	"Oddly Specific Cedar Plank": { EN: "Oddly Specific Cedar Plank", FR: "Planche de cèdre d'affinage", DE: "Werkstätten-Zedernbrett", JP: "改良用のシーダー板" },
	"Oddly Specific Chainmail Sheet (HQ)": { EN: "Oddly Specific Chainmail Sheet (HQ)", FR: "Cadène d'affinage (HQ)", DE: null, JP: null },
	"Oddly Specific Cloth": { EN: "Oddly Specific Cloth", FR: "Étoffe d'affinage", DE: "Werkstätten-Tuch", JP: "改良用の生地" },
	"Oddly Specific Coerthan Iron Ore": { EN: "Oddly Specific Coerthan Iron Ore", FR: "Minerai de fer beitetsu d'affinage", DE: "Werkstätten-Domaeisen-Erzklumpen", JP: "改良用の餅鉄" },
	"Oddly Specific Cotton": { EN: "Oddly Specific Cotton", FR: "Fleur de coton d'affinage", DE: "Werkstätten-Wolle", JP: "改良用の綿花" },
	"Oddly Specific Dark Chestnut Log": { EN: "Oddly Specific Dark Chestnut Log", FR: "Rondin de châtaignier sombre d'affinage", DE: "Dunkles Werkstätten-Kastanienholz", JP: "改良用のダークチェスナット原木" },
	"Oddly Specific Dark Matter": { EN: "Oddly Specific Dark Matter", FR: "Matière sombre d'affinage", DE: "Werkstätten-Dunkelmaterie", JP: "改良用のダークマター" },
	"Oddly Specific Fitting (HQ)": { EN: "Oddly Specific Fitting (HQ)", FR: "Patte d'affinage (HQ)", DE: null, JP: null },
	"Oddly Specific Fleece": { EN: "Oddly Specific Fleece", FR: "Toison d'affinage", DE: "Werkstätten-Vlies", JP: "改良用のフリース" },
	"Oddly Specific Fossil Dust": { EN: "Oddly Specific Fossil Dust", FR: "Sable fossilisé d'affinage", DE: "Werkstätten-Fossilsand", JP: "改良用の化石砂" },
	"Oddly Specific Gagana Leather": { EN: "Oddly Specific Gagana Leather", FR: "Cuir de gagana d'affinage", DE: "Werkstätten-Gaganaleder", JP: "改良用のガガナレザー" },
	"Oddly Specific Gagana Skin": { EN: "Oddly Specific Gagana Skin", FR: "Peau de gagana d'affinage", DE: "Werkstätten-Gaganahaut", JP: "改良用のガガナの粗皮" },
	"Oddly Specific Gaganaskin Strap": { EN: "Oddly Specific Gaganaskin Strap", FR: "Lanière en cuir de gagana d'affinage", DE: "Werkstätten-Gagana-Lederriemen", JP: "改良用のガガナストラップ" },
	"Oddly Specific Gemstone (HQ)": { EN: "Oddly Specific Gemstone (HQ)", FR: "Perle d'affinage (HQ)", DE: null, JP: null },
	"Oddly Specific Glass (HQ)": { EN: "Oddly Specific Glass (HQ)", FR: "Plaque de verre d'affinage (HQ)", DE: null, JP: null },
	"Oddly Specific Glue": { EN: "Oddly Specific Glue", FR: "Glu d'affinage", DE: "Werkstätten-Tierleim", JP: "改良用のにかわ" },
	"Oddly Specific Iron Ingot": { EN: "Oddly Specific Iron Ingot", FR: "Lingot de fer d'affinage", DE: "Werkstätten-Eisenbarren", JP: "改良用のアイアンインゴット" },
	"Oddly Specific Iron Nails": { EN: "Oddly Specific Iron Nails", FR: "Clou en fer d'affinage", DE: "Werkstätten-Eisennägel", JP: "改良用のアイアンネイル" },
	"Oddly Specific Iron Ore": { EN: "Oddly Specific Iron Ore", FR: "Minerai de fer d'affinage", DE: "Werkstätten-Erz", JP: "改良用の鉄鉱" },
	"Oddly Specific Iron Sand": { EN: "Oddly Specific Iron Sand", FR: "Sable de fer d'affinage", DE: "Werkstätten-Eisensand", JP: "改良用の砂鉄" },
	"Oddly Specific Landborne Aethersand": { EN: "Oddly Specific Landborne Aethersand", FR: "Sable éthéréen tellurique d'affinage", DE: "Werkstätten-Mineral-Seelensand", JP: "改良用の大地の霊砂" },
	"Oddly Specific Latex": { EN: "Oddly Specific Latex", FR: "Latex d'affinage", DE: "Werkstätten-Latex", JP: "改良用のラテックス" },
	"Oddly Specific Leafborne Aethersand": { EN: "Oddly Specific Leafborne Aethersand", FR: "Sable éthéréen végétal d'affinage", DE: "Werkstätten-Blumen-Seelensand", JP: "改良用の大樹の霊砂" },
	"Oddly Specific Leather (HQ)": { EN: "Oddly Specific Leather (HQ)", FR: "Cuir d'affinage (HQ)", DE: null, JP: null },
	"Oddly Specific Mineral Sand": { EN: "Oddly Specific Mineral Sand", FR: "Poudre minérale d'affinage", DE: "Werkstätten-Mineralsand", JP: "改良用の鉱砂" },
	"Oddly Specific Moonbeam Silk (HQ)": { EN: "Oddly Specific Moonbeam Silk (HQ)", FR: "Fil de soie du clair de lune d'affinage (HQ)", DE: null, JP: null },
	"Oddly Specific Mythril Plate": { EN: "Oddly Specific Mythril Plate", FR: "Plaque de mithril d'affinage", DE: "Werkstätten-Mithril-Platte", JP: "改良用のミスリルプレート" },
	"Oddly Specific Mythril Rings": { EN: "Oddly Specific Mythril Rings", FR: "Anneaux en mithril d'affinage", DE: "Werkstätten-Mithrildraht-Ringe", JP: "改良用のミスリルリングズ" },
	"Oddly Specific Mythrite Sand": { EN: "Oddly Specific Mythrite Sand", FR: "Sable de mithrite d'affinage", DE: "Werkstätten-Mythrit-Sandklumpen", JP: "改良用の精霊銀砂" },
	"Oddly Specific Obsidian": { EN: "Oddly Specific Obsidian", FR: "Éclat d'obsidienne d'affinage", DE: "Werkstätten-Obsidian", JP: "改良用の黒曜石" },
	"Oddly Specific Oil": { EN: "Oddly Specific Oil", FR: "Huile d'affinage", DE: "Werkstätten-Öl", JP: "改良用のオイル" },
	"Oddly Specific Paste": { EN: "Oddly Specific Paste", FR: "Pâte d'affinage", DE: "Werkstätten-Klebstoff", JP: "改良用のペースト" },
	"Oddly Specific Petrified Log": { EN: "Oddly Specific Petrified Log", FR: "Rondin pétrifié d'affinage", DE: "Versteinertes Werkstätten-Weißholz", JP: "改良用の珪化木" },
	"Oddly Specific Petrified Orb (HQ)": { EN: "Oddly Specific Petrified Orb (HQ)", FR: "Bille de bois pétrifié d'affinage (HQ)", DE: null, JP: null },
	"Oddly Specific Primordial Asphaltum": { EN: "Oddly Specific Primordial Asphaltum", FR: "Bitume ancien d'affinage", DE: "Antikes Werkstätten-Pech", JP: "改良用の古代歴青" },
	"Oddly Specific Primordial Log": { EN: "Oddly Specific Primordial Log", FR: "Rondin de bois ancien d'affinage", DE: "Antikes Werkstätten-Holzscheit", JP: "改良用の古代原木" },
	"Oddly Specific Primordial Ore": { EN: "Oddly Specific Primordial Ore", FR: "Minerai ancien d'affinage", DE: "Werkstätten-Urstein", JP: "改良用の古代鉱石" },
	"Oddly Specific Primordial Resin": { EN: "Oddly Specific Primordial Resin", FR: "Résine ancienne d'affinage", DE: "Antikes Werkstätten-Harz", JP: "改良用の古代樹脂" },
	"Oddly Specific Quartz": { EN: "Oddly Specific Quartz", FR: "Quartz d'affinage", DE: "Werkstätten-Quarz", JP: "改良用のクォーツ" },
	"Oddly Specific Rivets (HQ)": { EN: "Oddly Specific Rivets (HQ)", FR: "Clous d'affinage (HQ)", DE: null, JP: null },
	"Oddly Specific Rubber": { EN: "Oddly Specific Rubber", FR: "Caoutchouc d'affinage", DE: "Werkstätten-Gummi", JP: "改良用のラバー" },
	"Oddly Specific Sap": { EN: "Oddly Specific Sap", FR: "Sève d'affinage", DE: "Werkstätten-Harz", JP: "改良用の樹液" },
	"Oddly Specific Schorl": { EN: "Oddly Specific Schorl", FR: "Schörl d'affinage", DE: "Werkstätten-Schörl", JP: "改良用のショール" },
	"Oddly Specific Seed Extract (HQ)": { EN: "Oddly Specific Seed Extract (HQ)", FR: "Huile de graines d'affinage (HQ)", DE: null, JP: null },
	"Oddly Specific Seed Flour (HQ)": { EN: "Oddly Specific Seed Flour (HQ)", FR: "Poudre de graines d'affinage (HQ)", DE: null, JP: null },
	"Oddly Specific Seeds": { EN: "Oddly Specific Seeds", FR: "Graines d'affinage", DE: "Werkstätten-Samen", JP: "改良用の種子" },
	"Oddly Specific Shaft (HQ)": { EN: "Oddly Specific Shaft (HQ)", FR: "Hampe d'affinage (HQ)", DE: null, JP: null },
	"Oddly Specific Silver Ingot": { EN: "Oddly Specific Silver Ingot", FR: "Lingot d'argent d'affinage", DE: "Werkstätten-Silberbarren", JP: "改良用のシルバーインゴット" },
	"Oddly Specific Silver Nugget": { EN: "Oddly Specific Silver Nugget", FR: "Pépite d'argent d'affinage", DE: "Werkstätten-Silber-Nugget", JP: "改良用のシルバーナゲット" },
	"Oddly Specific Silver Ore": { EN: "Oddly Specific Silver Ore", FR: "Minerai d'argent d'affinage", DE: "Werkstätten-Silbererzklumpen", JP: "改良用の銀鉱" },
	"Oddly Specific Skin": { EN: "Oddly Specific Skin", FR: "Tissu épidermique d'affinage", DE: "Werkstätten-Tierhaut", JP: "改良用の粗皮" },
	"Oddly Specific Striking Stone": { EN: "Oddly Specific Striking Stone", FR: "Braises d'affinage", DE: "Werkstätten-Kerze", JP: "改良用の種火" },
	"Oddly Specific Synthetic Resin (HQ)": { EN: "Oddly Specific Synthetic Resin (HQ)", FR: "Résine synthétique d'affinage (HQ)", DE: null, JP: null },
	"Oddly Specific Uncut Gemstone": { EN: "Oddly Specific Uncut Gemstone", FR: "Pierre brute d'affinage", DE: "Werkstätten-Rohedelstein", JP: "改良用の原石" },
	"Oddly Specific Undyed Woolen Cloth": { EN: "Oddly Specific Undyed Woolen Cloth", FR: "Étoffe de laine d'affinage", DE: "Werkstätten-Wollballen", JP: "改良用の羅紗" },
	"Oddly Specific Vellum (HQ)": { EN: "Oddly Specific Vellum (HQ)", FR: "Parchemin d'affinage (HQ)", DE: null, JP: null },
	"Oddly Specific Velvet (HQ)": { EN: "Oddly Specific Velvet (HQ)", FR: "Velours d'affinage (HQ)", DE: null, JP: null },
	"Oddly Specific Whetstone (HQ)": { EN: "Oddly Specific Whetstone (HQ)", FR: "Pierre à aiguiser d'affinage (HQ)", DE: null, JP: null },
	"Oddly Specific Wire (HQ)": { EN: "Oddly Specific Wire (HQ)", FR: "Fil de fer d'affinage (HQ)", DE: null, JP: null },
	"Okeanis Egg": { EN: "Okeanis Egg", FR: "Œuf d'océanide", DE: "Okeanis-Ei", JP: "オーケアニスの卵" },
	"Ovim Fleece": { EN: "Ovim Fleece", FR: "Toison d'ovim", DE: "Ovimwolle", JP: "オヴィムの獣毛" },
	"Ovim Wool": { EN: "Ovim Wool", FR: "Étoffe de laine d'ovim", DE: "Bockwollstoff", JP: "山羊毛布" },
	"Ovim Wool Yarn": { EN: "Ovim Wool Yarn", FR: "Fil de laine d'ovim", DE: "Bockwollgarn", JP: "山羊毛糸" },
	"Pazuzu's Feather": { EN: "Pazuzu's Feather", FR: "Plume de Pazuzu", DE: "Pazuzu-Feder", JP: "パズズの羽根" },
	"Peat Moss": { EN: "Peat Moss", FR: "Tourbe", DE: "Torfmoos", JP: "ピートモス" },
	"Peiste Leather": { EN: "Peiste Leather", FR: "Cuir de peiste", DE: "Peiste-Leder", JP: "ペイストレザー" },
	"Peiste Skin": { EN: "Peiste Skin", FR: "Peau de peiste", DE: "Peiste-Haut", JP: "ペイストの粗皮" },
	"Penthesilea's Flame": { EN: "Penthesilea's Flame", FR: "Braises de Penthésilée", DE: "Penthesilea-Kerze", JP: "ペンテシレイアの種火" },
	"Perfect Cloth": { EN: "Perfect Cloth", FR: "Étoffe antique parfaite", DE: "Hochwertiger Antikstoff", JP: "上質な古布" },
	"Perfect Cloth (HQ)": { EN: "Perfect Cloth (HQ)", FR: "Étoffe antique parfaite(HQ)", DE: null, JP: null },
	"Perfect Firewood": { EN: "Perfect Firewood", FR: "Bûches parfaites", DE: "Hochwertiges Holz", JP: "上質な薪" },
	"Perfect Firewood (HQ)": { EN: "Perfect Firewood (HQ)", FR: "Bûches parfaites(HQ)", DE: null, JP: null },
	"Perfect Mortar": { EN: "Perfect Mortar", FR: "Mortier parfait", DE: "Hochwertiger Mörser", JP: "上質な乳鉢" },
	"Perfect Mortar (HQ)": { EN: "Perfect Mortar (HQ)", FR: "Mortier parfait(HQ)", DE: null, JP: null },
	"Perfect Pestle": { EN: "Perfect Pestle", FR: "Pilon parfait", DE: "Hochwertiger Stößel", JP: "上質な乳棒" },
	"Perfect Pestle (HQ)": { EN: "Perfect Pestle (HQ)", FR: "Pilon parfait(HQ)", DE: null, JP: null },
	"Perfect Pounce": { EN: "Perfect Pounce", FR: "Fixateur parfait", DE: "Hochwertiges Fixiermittel", JP: "上質な定着剤" },
	"Perfect Pounce (HQ)": { EN: "Perfect Pounce (HQ)", FR: "Fixateur parfait(HQ)", DE: null, JP: null },
	"Perfect Vellum": { EN: "Perfect Vellum", FR: "Parchemin parfait", DE: "Hochwertiges Pergament", JP: "上質な羊皮紙" },
	"Perfect Vellum (HQ)": { EN: "Perfect Vellum (HQ)", FR: "Parchemin parfait(HQ)", DE: null, JP: null },
	/* Petal Shell */
	/* Pickled Pom */
	"Pie Dough": { EN: "Pie Dough", FR: "Pâte à tarte", DE: "Blätterteig", JP: "パイ生地" },
	"Pinprick Pebble": { EN: "Pinprick Pebble", FR: "Caillou sanglant", DE: "Nadelstich-Kiesel", JP: "ブラッディペブル" },
	"Pixie Floss Boll": { EN: "Pixie Floss Boll", FR: "Fleur de coton pixie", DE: "Pixiebaumwolle", JP: "妖綿花" },
	"Pneumite": { EN: "Pneumite", FR: "Agrégat obscur", DE: "Quasikristall", JP: "謎めいた結晶" },
	"Potent Sleeping Potion": { EN: "Potent Sleeping Potion", FR: "Potion soporifique ultra-puissante", DE: "Stark einschläfernder Trunk", JP: "睡眠の劇毒薬" },
	/* Protean Crystal */
	"Pterodactyl": { EN: "Pterodactyl", FR: "Ptérodactyle", DE: "Pterodaktylus", JP: "プテロダクティルス" },
	"Pterodactyl Strap": { EN: "Pterodactyl Strap", FR: "Lanière en cuir de ptérodactyle", DE: "Pterodaktylus-Lederriemen", JP: "プテロダクティルスストラップ" },
	"Pumice": { EN: "Pumice", FR: "Pierre ponce", DE: "Bimsstein", JP: "パミスストーン" },
	/* Pyros Crystal */
	"Quicksilver": { EN: "Quicksilver", FR: "Mercure", DE: "Quecksilber", JP: "水銀" },
	"Quicktongue Materia III": { EN: "Quicktongue Materia III", FR: "Matéria de la célérité III", DE: "Zauber-Materia III", JP: "詠唱のマテリダ" },
	"Radz-at-Han Quenching Oil": { EN: "Radz-at-Han Quenching Oil", FR: "Huile de trempe de Radz-at-Han", DE: "Radz-at-Han Abschreck-Öl", JP: "ラザハン焼入油" },
	"Raw Emotion": { EN: "Raw Emotion", FR: "Cristal du volcan émotionnel", DE: "Emotionsklumpen der Glorie", JP: "輝かしき激情の一塊" },
	"Refined Natron": { EN: "Refined Natron", FR: "Natron dense", DE: "Natron-Konzentrat", JP: "強重曹" },
	"Rock Salt": { EN: "Rock Salt", FR: "Sel gemme", DE: "Steinsalz", JP: "岩塩" },
	"Rosewood Log": { EN: "Rosewood Log", FR: "Rondin de palissandre", DE: "Palisander-Holzscheit", JP: "ローズウッド原木" },
	"Rosewood Lumber": { EN: "Rosewood Lumber", FR: "Madrier de palissandre", DE: "Palisander-Bauholz", JP: "ローズウッド材" },
	"Royal Gigant Blood": { EN: "Royal Gigant Blood", FR: "Sang de géant royal", DE: null, JP: null },
	"Royal Grapes": { EN: "Royal Grapes", FR: "Raisin royal", DE: "Königstraube", JP: "ロイヤルグレープ" },
	"Royal Mistletoe": { EN: "Royal Mistletoe", FR: "Gui royal", DE: "Königs-Mistelzweig", JP: "クラウンミスルトゥ" },
	"Sacred Spring Water": { EN: "Sacred Spring Water", FR: "Eau de montagne sacrée", DE: null, JP: null },
	"Sandteak Lumber": { EN: "Sandteak Lumber", FR: "Madrier de teck des sables", DE: "Sandteak-Bauholz", JP: "サンドチーク材" },
	"Sanguine Scepter": { EN: "Sanguine Scepter", FR: "Sceptre sanglant", DE: "Blut-Zepter", JP: "ブラッディセプター" },
	"Savage Aim Materia III": { EN: "Savage Aim Materia III", FR: "Matéria de la barbarie maîtrisée III", DE: "Strategen-Materia III", JP: "武略のマテリダ" },
	"Savage Might Materia III": { EN: "Savage Might Materia III", FR: "Matéria de la barbarie percutante III", DE: "Assassinen-Materia III", JP: "雄略のマテリダ" },
	"Sea Swallow Leather": { EN: "Sea Swallow Leather", FR: "Cuir de glaucus", DE: "Glaucus-Leder", JP: "シースワローレザー" },
	"Sea Swallow Skin": { EN: "Sea Swallow Skin", FR: "Peau de glaucus", DE: "Glaucus-Haut", JP: "シースワローの粗皮" },
	"Shark Oil": { EN: "Shark Oil", FR: "Huile de requin", DE: "Haifischtran", JP: "シャークオイル" },
	"Sickle Fang": { EN: "Sickle Fang", FR: "Croc de Fenrir", DE: null, JP: null },
	"Silver Ore": { EN: "Silver Ore", FR: "Minerai d'argent", DE: "Silbererz", JP: "銀鉱" },
	/* Silver Shark */
	"Singing Cluster": { EN: "Singing Cluster", FR: "Agrégat résonnant", DE: "Aktivkristallisat", JP: "活性化クラスター" },
	"Smooth Butter": { EN: "Smooth Butter", FR: "Beurre", DE: "Gesalzene Butter", JP: "バター" },
	"Sorrowful Memory of the Dying": { EN: "Sorrowful Memory of the Dying", FR: "Amas mémoriel de peine", DE: "Erinnerungsklumpen der Trauer", JP: "悲しき記憶の一塊" },
	"Sphalerite": { EN: "Sphalerite", FR: "Minerai de sphalérite", DE: "Sphalerit", JP: "閃亜鉛鉱" },
	"Spoken Blood": { EN: "Spoken Blood", FR: "Sang de conscient", DE: "Beseeltes Blut", JP: "六識の血" },
	"Spruce Log": { EN: "Spruce Log", FR: "Rondin d'épicéa", DE: "Fichten-Holzscheit", JP: "スプルース原木" },
	"Spruce Lumber": { EN: "Spruce Lumber", FR: "Madrier d'épicéa", DE: "Fichten-Bauholz", JP: "スプルース材" },
	"Stained Cloth": { EN: "Stained Cloth", FR: "Vieux tissu sale", DE: "Verschmutzter Stoff", JP: "薄汚れた古布" },
	"Sunset Wheat": { EN: "Sunset Wheat", FR: "Blé", DE: "Sonnenweizen", JP: "小麦" },
	"Sunset Wheat Flour": { EN: "Sunset Wheat Flour", FR: "Farine", DE: "Sonnenweizenmehl", JP: "小麦粉" },
	"Sandteak Log": { EN: "Sandteak Log", FR: "Rondin de teck des sables", DE: "Sandteakholzscheit", JP: "サンドチーク原木" },
	"Superior Enchanted Ink": { EN: "Superior Enchanted Ink", FR: "Encre enchantée de haute qualité", DE: "Starkzauber-Tinte", JP: "ハイエンチャントインク" },
	"Table Salt": { EN: "Table Salt", FR: "Sel de table", DE: "Speisesalz", JP: "食塩" },
	"Tailor-made Eel Pie": { EN: "Tailor-made Eel Pie", FR: "Tarte à l'anguille très spéciale", DE: "Aalpastete Spezial", JP: "秘製イールパイ" },
	"Tailor-made Eel Pie (HQ)": { EN: "Tailor-made Eel Pie (HQ)", FR: "Tarte à l'anguille très spéciale (HQ)", DE: null, JP: null },
	"Thavnairian Mist": { EN: "Thavnairian Mist", FR: "Lymphe de Thavnair", DE: "Thavnair-Nebel", JP: "サベネアの霊薬" },
	"Thavnairian Mistletoe": { EN: "Thavnairian Mistletoe", FR: "Gui de Thavnair", DE: "Thavnair-Mistelzweig", JP: "サベネアミスルトゥ" },
	"Thavnairian Scalepowder": { EN: "Thavnairian Scalepowder", FR: "Écailles ombrales de Thavnair", DE: "Thavnair-Seelenstaub", JP: "サベネアの霊鱗粉" },
	/* Thinker's Coral */
	"Timeworn Artifacts": { EN: "Timeworn Artifacts", FR: "Reliques abandonnées", DE: null, JP: null },
	"Tiny Crown": { EN: "Tiny Crown", FR: "Petite couronne", DE: "Kleine Krone", JP: "タイニークラウン" },
	"Titanbronze Ingot": { EN: "Titanbronze Ingot", FR: "Lingot de bronze titané", DE: "Titanbronze-Barren", JP: "チタンブロンズインゴット" },
	"Titancopper Ore": { EN: "Titancopper Ore", FR: "Minerai de bronze titané", DE: "Titanbronze-Erzklumpen", JP: "賢銅鉱" },
	"Titanium Alloy Mirror": { EN: "Titanium Alloy Mirror", FR: "Miroir en alliage de titane", DE: "Titanlegierungs-Spiegel", JP: "チタンアロイミラー" },
	"Titanium Alloy Square": { EN: "Titanium Alloy Square", FR: "Plaquette en alliage de titane", DE: "Titanlegierungs-Plättchen", JP: "チタンアロイプレートレット" },
	"Titanium Nugget": { EN: "Titanium Nugget", FR: "Pépite de titane", DE: "Titan-Nugget", JP: "チタンナゲット" },
	"Titanium Ore": { EN: "Titanium Ore", FR: "Minerai de titane", DE: "Titan-Erz", JP: "チタン鉱" },
	"Tonberry King Blood": { EN: "Tonberry King Blood", FR: "Sang de tomberry roi", DE: null, JP: null },
	"Tortured Memory of the Dying": { EN: "Tortured Memory of the Dying", FR: "Amas mémoriel de tourment", DE: "Erinnerungsklumpen des Schmerzes", JP: "悩ましき記憶の一塊" },
	"Trillium Bulb": { EN: "Trillium Bulb", FR: "Bulbe de trille", DE: "Waldlilien-Knolle", JP: "トリリウムの球根" },
	"Tuff Whetstone": { EN: "Tuff Whetstone", FR: "Pierre à aiguiser en tuf volcanique", DE: "Tuffwetzstein", JP: "凝灰岩砥石" },
	"Umbrite": { EN: "Umbrite", FR: "Roche ombrale dure", DE: "Seelenquarz", JP: "硬霊性岩" },
	"Unidentifiable Bone": { EN: "Unidentifiable Bone", FR: "Fragment d'os mystérieux", DE: "Rätselhafter Knochensplitter", JP: "謎めいた骨片" },
	"Unidentifiable Ore": { EN: "Unidentifiable Ore", FR: "Gemme brute mystérieuse", DE: "Rätselhafter Roh-Edelstein", JP: "謎めいた原石" },
	"Unidentifiable Seeds": { EN: "Unidentifiable Seeds", FR: "Graines mystérieuses", DE: "Rätselhafte Samen", JP: "謎めいた種子" },
	"Unidentifiable Shell": { EN: "Unidentifiable Shell", FR: "Coquillage mystérieux", DE: "Rätselhafte Muschelschale", JP: "謎めいた貝殻" },
	"Vale Bubo": { EN: "Vale Bubo", FR: "Pyroxène flavescent", DE: null, JP: null },
	"Vamper's Knives": { EN: "Vamper's Knives", FR: "Couteaux de spoliateur", DE: "Flickermesser", JP: "ヴァンパーナイフ" },
	"Vampire Cup Vine": { EN: "Vampire Cup Vine", FR: "Sarment de coupe du vampire", DE: "Vampirkelch-Ranke", JP: "ヴァンパイアカップのつる" },
	"Vampire Plant": { EN: "Vampire Plant", FR: "Baie vampire", DE: "Vampirpflanze", JP: "ヴァンパイアプラント" },
	"Vampire Vine Sap": { EN: "Vampire Vine Sap", FR: "Sève de vigne vampire", DE: "Vampirrankensaft", JP: "ヴァンパイアヴァインの樹液" },
	"Vexatious Memory of the Dying": { EN: "Vexatious Memory of the Dying", FR: "Amas mémoriel d'exécration", DE: "Erinnerungsklumpen des Zorns", JP: "忌々しき記憶の一塊" },
	"Vicegerent Blood": { EN: "Vicegerent Blood", FR: "Sang d'adjoint de la Gardienne", DE: null, JP: null },
	"Vintage Cooking Sherry": { EN: "Vintage Cooking Sherry", FR: "Vin de cuisson artisanal", DE: "Kostbarer Kochwein", JP: "秘蔵クッキングワイン" },
	"Vitriol": { EN: "Vitriol", FR: "Silence d'or", DE: "Schweigen ist Gold", JP: "有言実行" },
	"Voidweave": { EN: "Voidweave", FR: "Étoffe du néant", DE: null, JP: null },
	"Volcanic Tuff": { EN: "Volcanic Tuff", FR: "Tuf volcanique", DE: "Tuffstein", JP: "凝灰岩" },
	"Water Cluster": { EN: "Water Cluster", FR: "Agrégat d'eau", DE: "Wasserpolykristall", JP: "ウォータークラスター" },
	"Water Crystal": { EN: "Water Crystal", FR: "Cristal d'eau", DE: "Wasserkristall", JP: "ウォータークリスタル" },
	"Water Mint": { EN: "Water Mint", FR: "Menthe aquatique", DE: "Wasserminze", JP: "ウォーターミント" },
	"Water Shard": { EN: "Water Shard", FR: "Éclat d'eau", DE: "Wasserscherbe", JP: "ウォーターシャード" },
	"White Ash Log": { EN: "White Ash Log", FR: "Rondin de frêne blanc", DE: "Weißeschenholzscheit", JP: "ホワイトアッシュ原木" },
	"Wildling's Cesti": { EN: "Wildling's Cesti", FR: "Cestes de sauvage", DE: "Wildlings-Caesti", JP: "ワイルドリングセスタス" },
	"Wind Cluster": { EN: "Wind Cluster", FR: "Agrégat de vent", DE: "Windpolykristall", JP: "ウィンドクラスター" },
	"Wind Crystal": { EN: "Wind Crystal", FR: "Cristal de vent", DE: "Windkristall", JP: "ウィンドクリスタル" },
	"Wind Shard": { EN: "Wind Shard", FR: "Éclat de vent", DE: "Windscherbe", JP: "ウィンドシャード" },
	/* Worm of Nym */
	"Yak Milk": { EN: "Yak Milk", FR: "Lait de yak", DE: "Yak-Milch", JP: "ヤクの乳" },
	"Yellow Alumen": { EN: "Yellow Alumen", FR: "Alun jaune", DE: "Gelbalaun", JP: "イエローアルメン" },
	"Zonure Leather": { EN: "Zonure Leather", FR: "Cuir de zonure", DE: "Zonure-Leder", JP: "ゾヌールレザー" },
	"Zonure Skin": { EN: "Zonure Skin", FR: "Peau de zonure", DE: "Zonure-Haut", JP: "ゾヌールの粗皮" },
	"Connoisseur's Chair": { EN: "Connoisseur's Chair", FR: "Chaise en bois de dilettante", DE: null, JP: null },
	"Connoisseur's Wall Lantern ": { EN: "Connoisseur's Wall Lantern ", FR: "Lanterne murale de dilettante", DE: null, JP: null },
	"Connoisseur's Ornate Door": { EN: "Connoisseur's Ornate Door", FR: "Porte ornée de dilettante", DE: null, JP: null },
	"Connoisseur's Vanity Mirror": { EN: "Connoisseur's Vanity Mirror", FR: "Miroir cosmétique de dilettante", DE: null, JP: null },
	"Connoisseur's Rug": { EN: "Connoisseur's Rug", FR: "Tapis de dilettante", DE: null, JP: null },
	"Connoisseur's Swag Valance": { EN: "Connoisseur's Swag Valance", FR: "Cantonnière de dilettante", DE: null, JP: null },
	"Connoisseur's Cosmetics Box": { EN: "Connoisseur's Cosmetics Box", FR: "Valise à maquillage de dilettante", DE: null, JP: null },
	"Connoisseur's Pixieberry Tea": { EN: "Connoisseur's Pixieberry Tea", FR: "Thé à la fraise pixie de dilettante", DE: null, JP: null },
	"Connoisseur's Prismstone": { EN: "Connoisseur's Prismstone", FR: "Cristal iridescent de dilettante", DE: null, JP: null },
	"Splendorous Water Shard": { EN: "Splendorous Water Shard", FR: "Éclat d'eau d'affinage", DE: null, JP: null },
	"Connoisseur's Wattle Petribark": { EN: "Connoisseur's Wattle Petribark", FR: "Écorce d'acacia durcie de dilettante", DE: null, JP: null },
	"Splendorous Earth Shard": { EN: "Splendorous Earth Shard", FR: "Éclat de terre d'affinage", DE: null, JP: null },
	/* Platinum Seahorse */
	/* Clavekeeper */
	"Connoisseur's Marimba": { EN: "Connoisseur's Marimba", FR: "Marimba de dilettante", DE: null, JP: null },
	"Connoisseur's Coffee Brewer": { EN: "Connoisseur's Coffee Brewer", FR: "Cafetière à siphon de dilettante", DE: null, JP: null },
	"Connoisseur's Bench": { EN: "Connoisseur's Bench", FR: "Banc de dilettante", DE: null, JP: null },
	"Connoisseur's Astroscope": { EN: "Connoisseur's Astroscope", FR: "Astroscope de dilettante", DE: null, JP: null },
	"Connoisseur's Leather Sofa": { EN: "Connoisseur's Leather Sofa", FR: "Canapé en cuir de dilettante", DE: null, JP: null },
	"Connoisseur's Red Carpet": { EN: "Connoisseur's Red Carpet", FR: "Tapis rouge de dilettante", DE: null, JP: null },
	"Connoisseur's Elixir Bottle": { EN: "Connoisseur's Elixir Bottle", FR: "Bouteille d'élixir de dilettante", DE: null, JP: null },
	"Connoisseur's Pixieberry Tart": { EN: "Connoisseur's Pixieberry Tart", FR: "Tarte aux fraises pixies de dilettante", DE: null, JP: null },
	"Connoisseur's Red Malachite": { EN: "Connoisseur's Red Malachite", FR: "Œil-de-paon rouge de dilettante", DE: null, JP: null },
	"Adaptive Fire Crystal": { EN: "Adaptive Fire Crystal", FR: "Cristal de feu d'affinage", DE: null, JP: null },
	"Connoisseur's Levin Mint": { EN: "Connoisseur's Levin Mint", FR: "Menthe éclair de dilettante", DE: null, JP: null },
	"Adaptive Lightning Crystal": { EN: "Adaptive Lightning Crystal", FR: "Cristal de foudre d'affinage", DE: null, JP: null },
	/* Mirror Image */
	/* Spangled Pirarucu */
	"Select Ironwood Lumber": { EN: "Select Ironwood Lumber", FR: "Madrier de bois de fer des merveilles", DE: null, JP: null },
	"Ironwood Log": { EN: "Ironwood Log", FR: "Rondin de bois de fer", DE: null, JP: null },
	"Select Manganese Ingot": { EN: "Select Manganese Ingot", FR: "Lingot de manganèse des merveilles", DE: null, JP: null },
	"Phrygian Gold Ore": { EN: "Phrygian Gold Ore", FR: "Minerai d'or phrygien", DE: null, JP: null },
	"Select Titanium Plate": { EN: "Select Titanium Plate", FR: "Titane pur des merveilles", DE: null, JP: null },
	"Select Crystal Glass": { EN: "Select Crystal Glass", FR: "Verre en cristal des merveilles", DE: null, JP: null },
	"Select Smilodon Leather": { EN: "Select Smilodon Leather", FR: "Cuir de smilodon des merveilles", DE: null, JP: null },
	"Select Scarlet Moko Cloth": { EN: "Select Scarlet Moko Cloth", FR: "Étoffe de moko vermillon des merveilles", DE: null, JP: null },
	"Select Hoptrap Leaf": { EN: "Select Hoptrap Leaf", FR: "Feuille de saute-piège des merveilles", DE: null, JP: null },
	"Select Pixieberry": { EN: "Select Pixieberry", FR: "Fraise pixie des merveilles", DE: null, JP: null },
	"Bismuth Ore": { EN: "Bismuth Ore", FR: null, DE: null, JP: null },
	"Almasty Fur": { EN: "Almasty Fur", FR: null, DE: null, JP: null },
	"Petalouda Scales": { EN: "Petalouda Scales", FR: null, DE: null, JP: null },
	"Sideritis Leaves": { EN: "Sideritis Leaves", FR: null, DE: null, JP: null },
	"Integral Lumber": { EN: "Integral Lumber", FR: null, DE: null, JP: null },
	"Star Quartz": { EN: "Star Quartz", FR: null, DE: null, JP: null },
	"AR-Caean Velvet": { EN: "AR-Caean Velvet", FR: null, DE: null, JP: null },
	"Grade 5 Vitality Alkahest": { EN: "Grade 5 Vitality Alkahest", FR: null, DE: null, JP: null },
	"Dark Rye Flour": { EN: "Dark Rye Flour", FR: null, DE: null, JP: null },
	"Chondrite Ingot": { EN: "Chondrite Ingot", FR: null, DE: null, JP: null },
	"Ophiotauros Leather": { EN: "Ophiotauros Leather", FR: null, DE: null, JP: null },
	"Grade 5 Mind Alkahest": { EN: "Grade 5 Mind Alkahest", FR: null, DE: null, JP: null },
	"Palm Sugar": { EN: "Palm Sugar", FR: null, DE: null, JP: null },
	"Integral Log": { EN: "Integral Log", FR: null, DE: null, JP: null },
	"Chondrite": { EN: "Chondrite", FR: null, DE: null, JP: null },
	"Annite Whetstone": { EN: "Annite Whetstone", FR: null, DE: null, JP: null },
	"Raw Star Quartz": { EN: "Raw Star Quartz", FR: null, DE: null, JP: null },
	"AR-Caean Cotton Boll": { EN: "AR-Caean Cotton Boll", FR: null, DE: null, JP: null },
	"Ophiotauros Hide": { EN: "Ophiotauros Hide", FR: null, DE: null, JP: null },
	"Eblan Alumen": { EN: "Eblan Alumen", FR: null, DE: null, JP: null },
	"Underground Spring Water": { EN: "Underground Spring Water", FR: null, DE: null, JP: null },
	"Lunatender Blossom": { EN: "Lunatender Blossom", FR: null, DE: null, JP: null },
	"Lime Basil": { EN: "Lime Basil", FR: null, DE: null, JP: null },
	"Tiger Lily": { EN: "Tiger Lily", FR: null, DE: null, JP: null },
	"Dark Rye": { EN: "Dark Rye", FR: null, DE: null, JP: null },
	"Palm Syrup": { EN: "Palm Syrup", FR: null, DE: null, JP: null },
	"Annite": { EN: "Annite", FR: null, DE: null, JP: null },
	"Reinforced Spruce Plywood": { EN: "Reinforced Spruce Plywood", FR: null, DE: null, JP: null },
	"Balanced Darksteel Hook": { EN: "Balanced Darksteel Hook", FR: null, DE: null, JP: null },
	"Reinforced Darksteel Wire": { EN: "Reinforced Darksteel Wire", FR: null, DE: null, JP: null },
	"Calibrated Rose Gold Cog": { EN: "Calibrated Rose Gold Cog", FR: null, DE: null, JP: null },
	"Water-hardened Hippogryph Strap": { EN: "Water-hardened Hippogryph Strap", FR: null, DE: null, JP: null },
	"Intricate Silver Brocade": { EN: "Intricate Silver Brocade", FR: null, DE: null, JP: null },
	"Concentrated Spirits of Salt": { EN: "Concentrated Spirits of Salt", FR: null, DE: null, JP: null },
	"Rich Tomato Relish": { EN: "Rich Tomato Relish", FR: null, DE: null, JP: null },
	"Talan's Seal of Mining": { EN: "Talan's Seal of Mining", FR: "Avoir minier de Talan", DE: null, JP: null },
	"Talan's Seal of Botany": { EN: "Talan's Seal of Botany", FR: "Avoir botanique de Talan", DE: null, JP: null },
	"Talan's Seal of Fishing": { EN: "Talan's Seal of Fishing", FR: "Avoir pêcheur de Talan", DE: null, JP: null },
	"Mastercraft Demimateria": { EN: "Mastercraft Demimateria", FR: "Demi-matéria de la maîtrise divine", DE: null, JP: null },
	"Spruce Plywood": { EN: "Spruce Plywood", FR: null, DE: null, JP: null },
	"Fieldcraft Demimateria III": { EN: "Fieldcraft Demimateria III", FR: null, DE: null, JP: null },
	"Darksteel Hook": { EN: "Darksteel Hook", FR: null, DE: null, JP: null },
	"Darksteel Wire": { EN: "Darksteel Wire", FR: null, DE: null, JP: null },
	"Rose Gold Cog": { EN: "Rose Gold Cog", FR: null, DE: null, JP: null },
	"Gryphonskin Strap": { EN: "Gryphonskin Strap", FR: null, DE: null, JP: null },
	"Silver Brocade": { EN: "Silver Brocade", FR: null, DE: null, JP: null },
	"Spirits of Salt": { EN: "Spirits of Salt", FR: null, DE: null, JP: null },
	"Spicy Tomato Relish": { EN: "Spicy Tomato Relish", FR: null, DE: null, JP: null },
	"Antumbral Rock": { EN: "Antumbral Rock", FR: null, DE: null, JP: null },
	"Redolent Log": { EN: "Redolent Log", FR: null, DE: null, JP: null },
	/* Olgoi-Khorkhoi */
	"Aldgoat Horn": { EN: "Aldgoat Horn", FR: null, DE: null, JP: null },
	"Gold Ore": { EN: "Gold Ore", FR: null, DE: null, JP: null },
	"Copper Ore": { EN: "Copper Ore", FR: null, DE: null, JP: null },
	"Hippogryph Skin": { EN: "Hippogryph Skin", FR: null, DE: null, JP: null },
	"Silkworm Cocoon": { EN: "Silkworm Cocoon", FR: null, DE: null, JP: null },
	"Noble Grapes": { EN: "Noble Grapes", FR: null, DE: null, JP: null },

	"Petrified Orb (HQ)": { EN: "Petrified Orb (HQ)", FR: "Bille de bois pétrifié (HQ)", DE: null, JP: null },
	"Mummer's Daggers (HQ)": { EN: "Mummer's Daggers (HQ)", FR: "Dagues d'acrobate (HQ)", DE: null, JP: null },
	"Lapdog Collar Bell (HQ)": { EN: "Lapdog Collar Bell (HQ)", FR: "Cloche d'animal (HQ)", DE: null, JP: null },
	"Fluorite Lens (HQ)": { EN: "Fluorite Lens (HQ)", FR: "Lentille en fluorine claire (HQ)", DE: null, JP: null },
	"Patent Leather (HQ)": { EN: "Patent Leather (HQ)", FR: "Cuir de saurien verni (HQ)", DE: null, JP: null },
	"Camlet (HQ)": { EN: "Camlet (HQ)", FR: "Camelot (HQ)", DE: null, JP: null },
	"Enchanted Quicksilver (HQ)": { EN: "Enchanted Quicksilver (HQ)", FR: "Mercure enchanté (HQ)", DE: null, JP: null },
	"Duck Broth (HQ)": { EN: "Duck Broth (HQ)", FR: "Fond de canard (HQ)", DE: null, JP: null },
	"Talan's Seal of Mining Mastery": { EN: "Talan's Seal of Mining Mastery", FR: "Avoir minier expert de Talan", DE: null, JP: null },
	"Talan's Seal of Botany Mastery": { EN: "Talan's Seal of Botany Mastery", FR: "Avoir botanique expert de Talan", DE: null, JP: null },
	"Talan's Seal of Fishing Mastery": { EN: "Talan's Seal of Fishing Mastery", FR: "Avoir pêcheur expert de Talan", DE: null, JP: null },
	"Moonstone": { EN: "Moonstone", FR: "Pierre de lune", DE: null, JP: null },
	"Urushi": { EN: "Urushi", FR: "Urushi", DE: null, JP: null },
	"Petrified Log": { EN: "Petrified Log", FR: "Rondin pétrifié", DE: null, JP: null },
	"Scarlet Sap": { EN: "Scarlet Sap", FR: "Sève écarlate", DE: null, JP: null },
	"Composite Whetstone": { EN: "Composite Whetstone", FR: "Pierre à aiguiser artificielle", DE: null, JP: null },
	"Scheelite": { EN: "Scheelite", FR: "Minerai de scheelite", DE: null, JP: null },
	"Ferberite": { EN: "Ferberite", FR: "Minerai de ferbérite", DE: null, JP: null },
	"Heat-resistant Plaster": { EN: "Heat-resistant Plaster", FR: "Plâtre thermorésistant", DE: null, JP: null },
	"Clear Fluorite": { EN: "Clear Fluorite", FR: "Fluorine claire", DE: null, JP: null },
	"Emery": { EN: "Emery", FR: "Émeri", DE: null, JP: null },
	"Siltstone Whetstone": { EN: "Siltstone Whetstone", FR: "Pierre à aiguiser en siltstone", DE: null, JP: null },
	"Lampblack Linseed Oil": { EN: "Lampblack Linseed Oil", FR: "Huile noire de fumée", DE: null, JP: null },
	"Saurian Skin": { EN: "Saurian Skin", FR: "Peau de saurien", DE: null, JP: null },
	"Shroud Tea Leaves": { EN: "Shroud Tea Leaves", FR: "Feuilles de thé de Tinolqa", DE: null, JP: null },
	"Camel Hair": { EN: "Camel Hair", FR: "Toison de chameau", DE: null, JP: null },
	"Cashmere Fleece": { EN: "Cashmere Fleece", FR: "Toison de cachemire", DE: null, JP: null },
	"Diluted Vitriol": { EN: "Diluted Vitriol", FR: "Vitriol dilué", DE: null, JP: null },
	"Black Cinnabar": { EN: "Black Cinnabar", FR: "Minium noir", DE: null, JP: null },
	"Raziqsand": { EN: "Raziqsand", FR: "Sable de Raziq", DE: null, JP: null },
	"Tarantula": { EN: "Tarantula", FR: "Tarentule", DE: null, JP: null },
	"Duck Bones": { EN: "Duck Bones", FR: "Os de canard", DE: null, JP: null },
	"Canard Breast": { EN: "Canard Breast", FR: "Magret de canard", DE: null, JP: null },
	"Thyme": { EN: "Thyme", FR: "Thym", DE: null, JP: null },
	"Wild Onion": { EN: "Wild Onion", FR: "Oignon sauvage", DE: null, JP: null },
	"Garlean Garlic": { EN: "Garlean Garlic", FR: "Ail garlemaldais", DE: null, JP: null },
	"Magicked Oilcloth": { EN: "Magicked Oilcloth", FR: "Toile enduite éthéréenne", DE: null, JP: null },
	"Bamboo Paper": { EN: "Bamboo Paper", FR: "Papier bamboo", DE: null, JP: null },
	"Gigant Clam": { EN: "Gigant Clam", FR: "Cocoquillage géant", DE: null, JP: null },
	"Glacier Crystal": { EN: "Glacier Crystal", FR: "Cristal de givre", DE: null, JP: null },
	"Siltstone": { EN: "Siltstone", FR: "Siltstone", DE: null, JP: null },
	"Filtered Water": { EN: "Filtered Water", FR: "Eau purifiée", DE: null, JP: null },
	"Amplifying Achondrite": { EN: "Amplifying Achondrite", FR: "Achondrite rarissime", DE: null, JP: null },
	"Connoisseur's Picture Frame": { EN: "Connoisseur's Picture Frame", FR: null, DE: null, JP: null },
	"Connoisseur's Samurai Blade": { EN: "Connoisseur's Samurai Blade", FR: null, DE: null, JP: null },
	"Connoisseur's Aquarium": { EN: "Connoisseur's Aquarium", FR: null, DE: null, JP: null },
	"Connoisseur's Glaives": { EN: "Connoisseur's Glaives", FR: null, DE: null, JP: null },
	"Connoisseur's Varsity Shoes": { EN: "Connoisseur's Varsity Shoes", FR: null, DE: null, JP: null },
	"Connoisseur's Linen Parasol": { EN: "Connoisseur's Linen Parasol", FR: null, DE: null, JP: null },
	"Connoisseur's Topiary Moogle": { EN: "Connoisseur's Topiary Moogle", FR: null, DE: null, JP: null },
	"Connoisseur's Squid Baguette": { EN: "Connoisseur's Squid Baguette", FR: null, DE: null, JP: null },
	"Connoisseur's Soiled Femur": { EN: "Connoisseur's Soiled Femur", FR: null, DE: null, JP: null },
	"Connoisseur's Miracle Apple": { EN: "Connoisseur's Miracle Apple", FR: null, DE: null, JP: null },
	"Gold Dustfish": { EN: "Gold Dustfish", FR: null, DE: null, JP: null },
	"Forgiven Melancholy": { EN: "Forgiven Melancholy", FR: null, DE: null, JP: null },
	"Connoisseur's Bookshelf": { EN: "Connoisseur's Bookshelf", FR: null, DE: null, JP: null },
	"Connoisseur's Chandelier": { EN: "Connoisseur's Chandelier", FR: null, DE: null, JP: null },
	"Connoisseur's Escutcheon": { EN: "Connoisseur's Escutcheon", FR: null, DE: null, JP: null },
	"Connoisseur's Baghnakhs": { EN: "Connoisseur's Baghnakhs", FR: null, DE: null, JP: null },
	"Connoisseur's Drinking Apkallu": { EN: "Connoisseur's Drinking Apkallu", FR: null, DE: null, JP: null },
	"Connoisseur's Jacket": { EN: "Connoisseur's Jacket", FR: null, DE: null, JP: null },
	"Connoisseur's Planter Partition": { EN: "Connoisseur's Planter Partition", FR: null, DE: null, JP: null },
	"Connoisseur's Spaghetti al Nero": { EN: "Connoisseur's Spaghetti al Nero", FR: null, DE: null, JP: null },
	"Connoisseur's Aurum Regis Ore": { EN: "Connoisseur's Aurum Regis Ore", FR: null, DE: null, JP: null },
	"Connoisseur's Cloves": { EN: "Connoisseur's Cloves", FR: null, DE: null, JP: null },
	"Oil Slick": { EN: "Oil Slick", FR: null, DE: null, JP: null },
	"Gonzalo's Grace": { EN: "Gonzalo's Grace", FR: null, DE: null, JP: null },
	"Connoisseur's Bamboo Fence": { EN: "Connoisseur's Bamboo Fence", FR: null, DE: null, JP: null },
	"Connoisseur's Rousing Chronometer": { EN: "Connoisseur's Rousing Chronometer", FR: null, DE: null, JP: null },
	"Connoisseur's Trumpet": { EN: "Connoisseur's Trumpet", FR: null, DE: null, JP: null },
	"Connoisseur's Washbasin": { EN: "Connoisseur's Washbasin", FR: null, DE: null, JP: null },
	"Connoisseur's Targe": { EN: "Connoisseur's Targe", FR: null, DE: null, JP: null },
	"Connoisseur's Petasos": { EN: "Connoisseur's Petasos", FR: null, DE: null, JP: null },
	"Connoisseur's Lunar Curtain": { EN: "Connoisseur's Lunar Curtain", FR: null, DE: null, JP: null },
	"Connoisseur's Crab Cakes": { EN: "Connoisseur's Crab Cakes", FR: null, DE: null, JP: null },
	"Connoisseur's Asphaltum": { EN: "Connoisseur's Asphaltum", FR: null, DE: null, JP: null },
	"Connoisseur's Gianthive Chip": { EN: "Connoisseur's Gianthive Chip", FR: null, DE: null, JP: null },
	"Deadwood Shadow": { EN: "Deadwood Shadow", FR: null, DE: null, JP: null },
	"Ronkan Bullion": { EN: "Ronkan Bullion", FR: null, DE: null, JP: null },
	"Connoisseur's Shishi-odoshi": { EN: "Connoisseur's Shishi-odoshi", FR: null, DE: null, JP: null },
	"Connoisseur's Retainer Bell": { EN: "Connoisseur's Retainer Bell", FR: null, DE: null, JP: null },
	"Connoisseur's Marching Horn": { EN: "Connoisseur's Marching Horn", FR: null, DE: null, JP: null },
	"Connoisseur's Marble Fountain": { EN: "Connoisseur's Marble Fountain", FR: null, DE: null, JP: null },
	"Connoisseur's Leather Jacket": { EN: "Connoisseur's Leather Jacket", FR: null, DE: null, JP: null },
	"Connoisseur's Fat Cat Sofa": { EN: "Connoisseur's Fat Cat Sofa", FR: null, DE: null, JP: null },
	"Connoisseur's Tincture of Vitality": { EN: "Connoisseur's Tincture of Vitality", FR: null, DE: null, JP: null },
	"Connoisseur's Chili Crab": { EN: "Connoisseur's Chili Crab", FR: null, DE: null, JP: null },
	"Connoisseur's Raw Onyx": { EN: "Connoisseur's Raw Onyx", FR: null, DE: null, JP: null },
	"Connoisseur's Glimshroom": { EN: "Connoisseur's Glimshroom", FR: null, DE: null, JP: null },
	"Little Bounty": { EN: "Little Bounty", FR: null, DE: null, JP: null },
	"Saint Fathric's Face": { EN: "Saint Fathric's Face", FR: null, DE: null, JP: null },
	"Select Dark Chestnut Lumber": { EN: "Select Dark Chestnut Lumber", FR: null, DE: null, JP: null },
	"Red Pine Lumber": { EN: "Red Pine Lumber", FR: null, DE: null, JP: null },
	"Linen Canvas": { EN: "Linen Canvas", FR: null, DE: null, JP: null },
	"Red Pine Log": { EN: "Red Pine Log", FR: null, DE: null, JP: null },
	"Cotton Yarn": { EN: "Cotton Yarn", FR: null, DE: null, JP: null },
	"Beeswax": { EN: "Beeswax", FR: null, DE: null, JP: null },
	"Cotton Boll": { EN: "Cotton Boll", FR: null, DE: null, JP: null },
	"Beehive Chip": { EN: "Beehive Chip", FR: null, DE: null, JP: null },
	"Select Bismuth Ingot": { EN: "Select Bismuth Ingot", FR: null, DE: null, JP: null },
	"Pewter Ingot": { EN: "Pewter Ingot", FR: null, DE: null, JP: null },
	"Oroshigane Ingot": { EN: "Oroshigane Ingot", FR: null, DE: null, JP: null },
	"Pewter Ore": { EN: "Pewter Ore", FR: null, DE: null, JP: null },
	"Tin Ore": { EN: "Tin Ore", FR: null, DE: null, JP: null },
	"Doman Iron Ore": { EN: "Doman Iron Ore", FR: null, DE: null, JP: null },
	"Select Cobalt Plate": { EN: "Select Cobalt Plate", FR: null, DE: null, JP: null },
	"Manganese Ingot": { EN: "Manganese Ingot", FR: null, DE: null, JP: null },
	"Crystal Glass": { EN: "Crystal Glass", FR: null, DE: null, JP: null },
	"Manganese Ore": { EN: "Manganese Ore", FR: null, DE: null, JP: null },
	"Molybdenum Ore": { EN: "Molybdenum Ore", FR: null, DE: null, JP: null },
	"Silex": { EN: "Silex", FR: null, DE: null, JP: null },
	"Natron": { EN: "Natron", FR: null, DE: null, JP: null },
	"Minium": { EN: "Minium", FR: null, DE: null, JP: null },
	"Select Bluespirit Tile": { EN: "Select Bluespirit Tile", FR: null, DE: null, JP: null },
	"Manasilver Nugget": { EN: "Manasilver Nugget", FR: null, DE: null, JP: null },
	"Select Green Glider Leather": { EN: "Select Green Glider Leather", FR: null, DE: null, JP: null },
	"Kumbhira Leather": { EN: "Kumbhira Leather", FR: null, DE: null, JP: null },
	"Iridescent Silk": { EN: "Iridescent Silk", FR: null, DE: null, JP: null },
	"Kumbhira Skin": { EN: "Kumbhira Skin", FR: null, DE: null, JP: null },
	"Iridescent Silk Thread": { EN: "Iridescent Silk Thread", FR: null, DE: null, JP: null },
	"Iridescent Cocoon": { EN: "Iridescent Cocoon", FR: null, DE: null, JP: null },
	"Select Waterproof Cloth": { EN: "Select Waterproof Cloth", FR: null, DE: null, JP: null },
	"Select Rak'tika Seedling": { EN: "Select Rak'tika Seedling", FR: null, DE: null, JP: null },
	"Grade 5 Intelligence Alkahest": { EN: "Grade 5 Intelligence Alkahest", FR: null, DE: null, JP: null },
	"Growth Formula Kappa": { EN: "Growth Formula Kappa", FR: null, DE: null, JP: null },
	"Fernleaf Lavender": { EN: "Fernleaf Lavender", FR: null, DE: null, JP: null },
	"Hoptrap Leaf": { EN: "Hoptrap Leaf", FR: null, DE: null, JP: null },
	"Select Squid Ink": { EN: "Select Squid Ink", FR: null, DE: null, JP: null },
	"Northern Sea Salt": { EN: "Northern Sea Salt", FR: null, DE: null, JP: null },
	"Upland Wheat Flour": { EN: "Upland Wheat Flour", FR: null, DE: null, JP: null },
	"Sharlayan Rock Salt": { EN: "Sharlayan Rock Salt", FR: null, DE: null, JP: null },
	"Upland Wheat": { EN: "Upland Wheat", FR: null, DE: null, JP: null },
	"Ironwood Lumber": { EN: "Ironwood Lumber", FR: null, DE: null, JP: null },
	"Phrygian Gold Ingot": { EN: "Phrygian Gold Ingot", FR: null, DE: null, JP: null },
	"Zinc Ore": { EN: "Zinc Ore", FR: null, DE: null, JP: null },
	"Apkallu Down": { EN: "Apkallu Down", FR: null, DE: null, JP: null },
	"Scarlet Moko Cloth": { EN: "Scarlet Moko Cloth", FR: null, DE: null, JP: null },
	"Saiga Leather": { EN: "Saiga Leather", FR: null, DE: null, JP: null },
	"Scarlet Moko Grass": { EN: "Scarlet Moko Grass", FR: null, DE: null, JP: null },
	"Saiga Hide": { EN: "Saiga Hide", FR: null, DE: null, JP: null },
	"Perilla Oil": { EN: "Perilla Oil", FR: null, DE: null, JP: null },
	"Garlean Cheese": { EN: "Garlean Cheese", FR: null, DE: null, JP: null },
	"Vermicelli": { EN: "Vermicelli", FR: null, DE: null, JP: null },
	"Thavnairian Perilla Leaf": { EN: "Thavnairian Perilla Leaf", FR: null, DE: null, JP: null },
	"Ovibos Milk": { EN: "Ovibos Milk", FR: null, DE: null, JP: null },
	"Abalathian Rock Salt": { EN: "Abalathian Rock Salt", FR: null, DE: null, JP: null },
	"Select Bamboo Stick": { EN: "Select Bamboo Stick", FR: null, DE: null, JP: null },
	"Limestone": { EN: "Limestone", FR: null, DE: null, JP: null },
	"Select Duraluminum Ingot": { EN: "Select Duraluminum Ingot", FR: null, DE: null, JP: null },
	"Select Brashgold Plate": { EN: "Select Brashgold Plate", FR: null, DE: null, JP: null },
	"Truegold Ore": { EN: "Truegold Ore", FR: null, DE: null, JP: null },
	"Horse Chestnut Log": { EN: "Horse Chestnut Log", FR: null, DE: null, JP: null },
	"Select Marble": { EN: "Select Marble", FR: null, DE: null, JP: null },
	"Select Chalicotherium Leather": { EN: "Select Chalicotherium Leather", FR: null, DE: null, JP: null },
	"Select Duskcourt Cloth": { EN: "Select Duskcourt Cloth", FR: null, DE: null, JP: null },
	"Ruby Cotton Boll": { EN: "Ruby Cotton Boll", FR: null, DE: null, JP: null },
	"Select Cudweed": { EN: "Select Cudweed", FR: null, DE: null, JP: null },
	"Mousse Flesh": { EN: "Mousse Flesh", FR: null, DE: null, JP: null },
	"Select Blue Crab": { EN: "Select Blue Crab", FR: null, DE: null, JP: null },
	"Egg of Elpis": { EN: "Egg of Elpis", FR: null, DE: null, JP: null },
	"Ambrosial Water": { EN: "Ambrosial Water", FR: null, DE: null, JP: null },
	"Granite": { EN: "Granite", FR: null, DE: null, JP: null },
	"Wildfowl Feather": { EN: "Wildfowl Feather", FR: null, DE: null, JP: null },
	"Berkanan Sap": { EN: "Berkanan Sap", FR: null, DE: null, JP: null },
	"Amra": { EN: "Amra", FR: null, DE: null, JP: null },
	"Blood Tomato": { EN: "Blood Tomato", FR: null, DE: null, JP: null },
	"Pearl Ginger": { EN: "Pearl Ginger", FR: null, DE: null, JP: null },
	"Custom Ice Crystal": { EN: "Custom Ice Crystal", FR: null, DE: null, JP: null },
	"Custom Wind Crystal": { EN: "Custom Wind Crystal", FR: null, DE: null, JP: null },
	"Brilliant Lightning Cluster": { EN: "Brilliant Lightning Cluster", FR: null, DE: null, JP: null },
	"Brilliant Earth Cluster": { EN: "Brilliant Earth Cluster", FR: null, DE: null, JP: null },
	"Inspirational Wind Cluster": { EN: "Inspirational Wind Cluster", FR: null, DE: null, JP: null },
	"Inspirational Fire Cluster": { EN: "Inspirational Fire Cluster", FR: null, DE: null, JP: null },
	"Nightforged Ice Cluster": { EN: "Nightforged Ice Cluster", FR: null, DE: null, JP: null },
	"Nightforged Water Cluster": { EN: "Nightforged Water Cluster", FR: null, DE: null, JP: null },
	"Cosmic Crystallite": { EN: "Cosmic Crystallite", FR: null, DE: null, JP: null },
	"Horn Glue": { EN: "Horn Glue", FR: "Glu de démon", DE: "Ogerleim", JP: null },
	"Rose Gold Nugget": { EN: "Rose Gold Nugget", FR: "Pépite d'or rose", DE: "Rosengold-Nugget", JP: null },
	"Hippogryph Leather": { EN: "Hippogryph Leather", FR: "Cuir d'hippogriffe", DE: "Hippogryphenleder", JP: "ヒッポグリフレザー" },
	"Silk Thread": { EN: "Silk Thread", FR: "Fil de soie", DE: "Seidenfaden", JP: null },
	"Silver Ingot": { EN: "Silver Ingot", FR: "Lingot d'argent", DE: "Silberbarren", JP: "シルバーインゴット" },
	"Volcanic Rock Salt": { EN: "Volcanic Rock Salt", FR: "Sel gemme noscéen", DE: "Vulkanisches Steinsalz", JP: null },
	"Dzemael Tomato": { EN: "Dzemael Tomato", FR: "Tomate Dzemael", DE: "Dzemael-Tomate", JP: null },
	"Cinnamon": { EN: "Cinnamon", FR: "Cannelle", DE: "Zimt", JP: "シナモン" },
	"Dark Vinegar": { EN: "Dark Vinegar", FR: "Vinaigre balsamique", DE: "Balsamessig", JP: null },
	//#endregion Items

	//#region Other Items (Not in Items enum as full infomation not needed)
	"White-Hot Ember": { EN: "White-Hot Ember", FR: "Flamme d'Ifrit", DE: null, JP: null },
	"Howling Gale": { EN: "Howling Gale", FR: "Rafale de Garuda", DE: null, JP: null },
	"Hyperfused Ore": { EN: "Hyperfused Ore", FR: "Pierre de Titan", DE: null, JP: null },
	"Gobbiegoo": { EN: "Gobbiegoo", FR: "Composants pour affinage d'outil de Cielacier", DE: null, JP: null },
	"Highly Viscous Gobbiegoo": { EN: "Highly Viscous Gobbiegoo", FR: "Composants annexes pour affinage d'outil de Cielacier", DE: null, JP: null },
	"Oddly Delicate Parts": { EN: "Oddly Delicate Parts", FR: "Composants pour affinage final d'outil de Cielacier", DE: null, JP: null },
	"Splendorous Components": { EN: "Splendorous Components", FR: "Matériaux de premier niveau pour outils", DE: null, JP: null },
	"Adaptive Components": { EN: "Adaptive Components", FR: "Matériaux de deuxième niveau pour outils", DE: null, JP: null },
	"Customised Components": { EN: "Customised Components", FR: null, DE: null, JP: null },
	"Brilliant Components": { EN: "Brilliant Components", FR: null, DE: null, JP: null },
	"Inspirational Components": { EN: "Inspirational Components", FR: null, DE: null, JP: null },
	"Nightforged Components": { EN: "Nightforged Components", FR: null, DE: null, JP: null },
	//#endregion Other Items

};
export default gameTranslations;
