//#region Imports
import { SysMsgObject, SysMsgOptions } from "types";
import { actions, store } from "./state";
//#endregion Imports

export default new class SystemMessage {
	private nextID = 1;
	private _addMessage(sys_msg:SysMsgObject){
		store.dispatch(actions.addSystemMessage({
			id: this.nextID,
			timeout: sys_msg.timeout,
			title: sys_msg.title,
			message: sys_msg.message,
			type: sys_msg.type,
			titlePlaceholders: sys_msg.titlePlaceholders,
			messagePlaceholders: sys_msg.messagePlaceholders,
		}));

		if(this.nextID === 999999){ this.nextID = 0; }
		this.nextID++;
	}

	error(options:SysMsgOptions, timeout?:number){
		this._addMessage({
			timeout: typeof timeout === "number" ? timeout : 5000,
			title: options.title,
			message: options.message,
			type: "error",
			titlePlaceholders: options.titlePlaceholders,
			messagePlaceholders: options.messagePlaceholders,
		});
	}

	warn(options:SysMsgOptions, timeout?:number){
		this._addMessage({
			timeout: typeof timeout === "number" ? timeout : 5000,
			title: options.title,
			message: options.message,
			type: "warn",
			titlePlaceholders: options.titlePlaceholders,
			messagePlaceholders: options.messagePlaceholders,
		});
	}

	success(options:SysMsgOptions, timeout?:number){
		this._addMessage({
			timeout: typeof timeout === "number" ? timeout : 5000,
			title: options.title,
			message: options.message,
			type: "success",
			titlePlaceholders: options.titlePlaceholders,
			messagePlaceholders: options.messagePlaceholders,
		});
	}

	notification(options:SysMsgOptions, timeout?:number){
		this._addMessage({
			timeout: typeof timeout === "number" ? timeout : 5000,
			title: options.title,
			message: options.message,
			type: "notification",
			titlePlaceholders: options.titlePlaceholders,
			messagePlaceholders: options.messagePlaceholders,
		});
	}

	dismiss(id:number){
		const container = document.getElementById("sys-msg-container");
		if(container){
			const ele = container.querySelector(`[data-id="${id}"]`);
			if(ele){
				ele.addEventListener("transitionend", () => {
					store.dispatch(actions.removeSystemMessage(id));
				});
				ele.classList.add("close");
				return;
			}
		}
		store.dispatch(actions.removeSystemMessage(id));
	}
}();
