/* eslint-disable max-len */
//#region Imports
import { jobLists } from "character";
import FormComponents from "components/forms/FormComponents";
import Link from "components/link/Link";
import NoCharacter from "components/no-character/NoCharacter";
import Tip from "components/tip/Tip";
import { items } from "data-files/items";
import { quests } from "data-files/quests";
import { Dungeon, InputType, Item, LinkType, NPC, Quest, RelicGroup, RelicPart, RelicStep, RelicTask, RelicType, View } from "enums";
import log from "logger";
import manager from "managers/app";
import { Component, SyntheticEvent } from "react";
import { connect } from "react-redux";
import t from "translations/translator";
import { AppState, Requirement, ViewRelicInfo, ViewWeaponZodiacNexusProps } from "types";
import ContentHeader from "views/page-parts/content/content-header/ContentHeader";
import DetailsSection from "views/page-parts/content/details-section/DetailsSection";
import Obtained from "views/page-parts/content/obtained/Obtained";
import OverviewTable from "views/page-parts/content/overview-table/OverviewTable";
import "./WeaponZodiacNexus.css";
//#endregion Imports

const mapStateToProps = (state:AppState) => {
	return {
		lang: state.userInfo.options.lang,
		gameLang: state.userInfo.options.gameLang,
		userInfo: state.userInfo,
		hideCompletedSteps: state.userInfo.options.hideCompletedSteps,
		hideCompletedTasks: state.userInfo.options.hideCompletedTasks,
		showJob: state.userInfo.options.job,
	};
};

class WeaponZodiacNexus extends Component<ViewWeaponZodiacNexusProps> {
	private requirements:Requirement[] = [];
	private importantNPCs:NPC[] = [];
	private relicInfo:ViewRelicInfo = {
		jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC],
		type: RelicType.WEAPON,
		relic: RelicGroup.WEAPON_ZODIAC,
		part: RelicPart.WEAPON_NEXUS,
		steps: [RelicStep.SOULGLAZE, RelicStep.LIGHT],
		stepPlaceholders: {
			[RelicStep.SOULGLAZE]: { QUEST: Quest.SOULGLAZED_RELICS },
			[RelicStep.LIGHT]: { ITEM: Item.LIGHT },
		},
		next: {
			part: RelicPart.WEAPON_BRAVES,
			view: View.RELIC_WEAPONS_ZODIAC_ZODIAC_BRAVES,
		},
		previous: {
			part: RelicPart.WEAPON_NOVUS,
			view: View.RELIC_WEAPONS_ZODIAC_NOVUS,
		},
	};

	constructor(props:ViewWeaponZodiacNexusProps){
		super(props);
		this.requirements = this.getRequirements();
		this.importantNPCs = this.getImportantNPCs();
	}

	// eslint-disable-next-line complexity
	render(){
		const character = manager.data.getActiveCharacter();
		if(character === null){ return <NoCharacter />; }

		const completeSteps = manager.content.getCompleteSteps(character.progress, this.relicInfo);
		const lightInventory = manager.data.getInventoryStatus({
			item: manager.relics.getTaskItem(RelicStep.LIGHT, RelicTask.TASK_1),
			relic: [this.relicInfo.type, this.relicInfo.relic, this.relicInfo.part, RelicStep.LIGHT, RelicTask.TASK_1],
		});

		return (
			<div id="weapon-zodiac-nexus" className="relic">
				<ContentHeader requirements={this.requirements} importantNPCs={this.importantNPCs} relicInfo={this.relicInfo}>
					<p>{t.t("The %PART% upgrade follows on from %PREVIOUSPART%", { PART: this.relicInfo.part, PREVIOUSPART: this.relicInfo.previous ? this.relicInfo.previous.part : "Unknown"})}</p>
				</ContentHeader>
				<div id="relic-checklist">
					<OverviewTable character={character} completeSteps={completeSteps} relicInfo={this.relicInfo} />
				</div>
				<div id="relic-details">
					<h1>{t.t("Details")}</h1>
					{this.props.hideCompletedSteps && completeSteps.allComplete ? t.t("All Steps Complete") : null }
					<DetailsSection title={RelicStep.SOULGLAZE} placeholders={this.relicInfo.stepPlaceholders[RelicStep.SOULGLAZE]} id={RelicStep.SOULGLAZE} stepComplete={completeSteps[RelicStep.SOULGLAZE]}><p>{t.t("Complete")}<Link type={LinkType.LODESTONE} lodestoneType={quests[Quest.SOULGLAZED_RELICS].type} text={quests[Quest.SOULGLAZED_RELICS].name} lodestoneID={quests[Quest.SOULGLAZED_RELICS].lodestoneID} /></p></DetailsSection>
					<DetailsSection title={RelicStep.LIGHT} placeholders={this.relicInfo.stepPlaceholders[RelicStep.LIGHT]} id={RelicStep.LIGHT} stepComplete={completeSteps[RelicStep.LIGHT]}>
						<p>{t.t("Gather 'Light' from various ARR activites (ARR Dungeons, ARR Trials, ARR FATEs, Treasure Hunt, Alexandrite Maps, The Wolves' Den and Frontlines)")}</p>
						<Tip text="Dungeons can be run unsynced, and you only need your relic equipped for the final boss" />
						<Tip text="%TAMTARA% and %AURUM% are considered to be good dungeons to grind" placeholders={{ TAMTARA: Dungeon.TAM_TARA, AURUM: Dungeon.AURUM }} />
						<div className="light-info">
							<div className="wrapper">
								<div className="table-wrapper">
									<table className="table">
										<thead><tr><th>{t.t("Stage")}</th><th>{t.t("Points")}</th></tr></thead>
										<tbody>
											<tr><td>{t.t("No Activity")}</td><td>{(0).toLocaleString()} - {(199).toLocaleString()}</td></tr>
											<tr><td>{t.t("Indistinct Activity")}</td><td>{(200).toLocaleString()} - {(399).toLocaleString()}</td></tr>
											<tr><td>{t.t("Faint Activity")}</td><td>{(400).toLocaleString()} - {(599).toLocaleString()}</td></tr>
											<tr><td>{t.t("Slight Activity")}</td><td>{(600).toLocaleString()} - {(799).toLocaleString()}</td></tr>
											<tr><td>{t.t("Modest Activity")}</td><td>{(800).toLocaleString()} - {(999).toLocaleString()}</td></tr>
											<tr><td>{t.t("Distinct Activity")}</td><td>{(1000).toLocaleString()} - {(1199).toLocaleString()}</td></tr>
											<tr><td>{t.t("Robust Activity")}</td><td>{(1200).toLocaleString()} - {(1399).toLocaleString()}</td></tr>
											<tr><td>{t.t("Vigorous Activity")}</td><td>{(1400).toLocaleString()} - {(1599).toLocaleString()}</td></tr>
											<tr><td>{t.t("Intense Activity")}</td><td>{(1600).toLocaleString()} - {(1799).toLocaleString()}</td></tr>
											<tr><td>{t.t("Extreme Activity")}</td><td>{(1800).toLocaleString()} - {(1999).toLocaleString()}</td></tr>
											<tr><td>{t.t("Bursting Activity")}</td><td>{(2000).toLocaleString()}+</td></tr>
										</tbody>
									</table>
								</div>
							</div>
							<div className="light-buttons">
								<div className="button-group">
									<FormComponents.Button text="Add Feeble Light" onClick={this.addLight} dataAttributes={{ light: 8 }} translate={true} />
									<FormComponents.Button text="Add Gentle Light" onClick={this.addLight} dataAttributes={{ light: 16 }} translate={true} />
								</div>
								<div className="button-group">
									<FormComponents.Button text="Add Bright Light" onClick={this.addLight} dataAttributes={{ light: 32 }} translate={true} />
									<FormComponents.Button text="Add Brilliant Light" onClick={this.addLight} dataAttributes={{ light: 48 }} translate={true} />
								</div>
								<div className="button-group">
									<FormComponents.Button text="Add Blinding Light" onClick={this.addLight} dataAttributes={{ light: 96 }} translate={true} />
									<FormComponents.Button text="Add Newborn Star Light" onClick={this.addLight} dataAttributes={{ light: 128 }} translate={true} />
								</div>
							</div>
						</div>
						<div className="table-wrapper">
							<table className="table">
								<thead><tr><th>{t.t("Obtained")}</th><th>{t.t("Item")}</th><th>{t.t("Quantity / Job")}</th><th>{t.t("Total")}</th><th>{t.t("Used")}</th><th>{t.t("Remaining")}</th><th>{t.t("Inventory")}</th><th>{t.t("To Obtain")}</th></tr></thead>
								<tbody>
									{this.props.hideCompletedTasks && lightInventory.toObtain === 0
										? null
										: <tr className="odd-row">
											<td><Obtained obtained={items[Item.LIGHT].obtained} qtyNeeded={lightInventory.total} /></td>
											<td>{t.t(items[Item.LIGHT].name)}</td>
											<td>{lightInventory.qtyPerJob.toLocaleString()}</td>
											<td>{lightInventory.total.toLocaleString()}</td>
											<td>{lightInventory.used.toLocaleString()}</td>
											<td>{lightInventory.remaining.toLocaleString()}</td>
											<td><FormComponents.TextInput label={{ hide: true }} input={{ type: InputType.NUMBER, value: lightInventory.inventory, dataAttributes: { item: Item.LIGHT }, events: { onChange: manager.content.inventoryUpdated }}} /></td>
											<td>{lightInventory.toObtain.toLocaleString()}</td>
										</tr>
									}
									{
										character.progress.isComplete(this.relicInfo.type, this.relicInfo.relic, this.relicInfo.part, RelicStep.LIGHT)
											? <tr className="all-complete"><td colSpan={8}><p>{t.t("All Complete")}</p></td></tr>
											: null
									}
								</tbody>
							</table>
						</div>
					</DetailsSection>
				</div>
			</div>
		);
	}

	componentDidMount(){
		manager.view.changeComplete();
	}

	private getRequirements():Requirement[]{
		if(!this.relicInfo || this.relicInfo.previous === null){ return []; }
		return [
			{ type: "previous part", part: this.relicInfo.previous.part, partView: this.relicInfo.previous.view },
		];
	}

	private getImportantNPCs():NPC[]{
		return [NPC.JALZAHN];
	}

	addLight = (evt:SyntheticEvent) => { // Event
		const ele = evt.currentTarget as HTMLButtonElement;
		const lightPoints = ele.dataset.light ? parseInt(ele.dataset.light) : 0;
		const inputs = ele.closest(".light-info")?.nextElementSibling?.getElementsByTagName("input");

		if(lightPoints === 0){ log.error("Unable to find light point value"); return; }
		if(!inputs || inputs.length <= 0){ log.error("Unable to find light inventory input"); return; }

		const item = inputs[0].dataset.item as Item | undefined;

		const character = manager.data.getActiveCharacter();

		if(!item){ log.error("Missing Item from dataset"); return; }
		if(!character){ log.warn("No active character found"); return; }

		const value = inputs[0].valueAsNumber + lightPoints;
		manager.content.updateInventory(item, value, true);
	}
}
export default connect(mapStateToProps)(WeaponZodiacNexus);
