//#region Imports
import manager from "managers/app";
import { Component } from "react";
import { connect } from "react-redux";
import t from "translations/translator";
import { AppState, ViewTipsProps } from "types";
import "./Tips.css";
//#endregion Imports

const mapStateToProps = (state:AppState) => {
	return {
		lang: state.userInfo.options.lang,
		gameLang: state.userInfo.options.gameLang,
	};
};

class Tips extends Component<ViewTipsProps> {
	render(){
		return (
			<div id="tips">
				<h1 style={{ textAlign: "center" }}>{t.t("Coming Soon!")}</h1>
			</div>
		);
	}

	componentDidMount = () => {
		manager.view.changeComplete();
	}
}
export default connect(mapStateToProps)(Tips);
