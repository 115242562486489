/* eslint-disable max-len */
//#region Imports
import NoCharacter from "components/no-character/NoCharacter";
import manager from "managers/app";
import { Component } from "react";
import { connect } from "react-redux";
import t from "translations/translator";
import { AppState, ViewArmourLawsOrderOverallProps } from "types";
import "./ArmourLawsOrderOverall.css";
//#endregion Imports

const mapStateToProps = (state:AppState) => {
	return {
		lang: state.userInfo.options.lang,
		gameLang: state.userInfo.options.gameLang,
		userInfo: state.userInfo,
	};
};

class ArmourLawsOrderOverall extends Component<ViewArmourLawsOrderOverallProps> {
	render(){
		const character = manager.data.getActiveCharacter();
		if(character === null){ return <NoCharacter />; }

		return (
			<div id="armour-lawsorder-overall" className="relic">
				<h1 style={{ textAlign: "center" }}>{t.t("Coming Soon!")}</h1>
				{/* <h1>{t.t("Overall")}</h1>
				<div className="options">
					<ContentOptions jobs={jobLists.relic[RelicGroup.ARMOUR_LAWS_ORDER]} relic={RelicGroup.ARMOUR_LAWS_ORDER} />
				</div>
				<OverallTable relicType={RelicType.ARMOUR} relic={RelicGroup.ARMOUR_LAWS_ORDER} /> */}
			</div>
		);
	}

	componentDidMount(){
		manager.view.changeComplete();
	}

}
export default connect(mapStateToProps)(ArmourLawsOrderOverall);
