//#region Imports
import { Modal } from "enums";
import { Modals } from "types";
//#endregion Imports

export class ModalManager {
	modals:Modals = {
		[Modal.CONFIRMATION]: null,
		[Modal.ENEMIES]: null,
		[Modal.RECIPES]: null,
		[Modal.MARKETS]: null,
		[Modal.EXTRACTIONS]: null,
		[Modal.GATHERING]: null,
		[Modal.FISHING]: null,
		[Modal.SHOPS]: null,
		[Modal.LOCATIONS]: null,
		[Modal.TRIALS]: null,
		[Modal.DUNGEONS]: null,
		[Modal.RAIDS]: null,
		[Modal.FATES]: null,
		[Modal.LEVES]: null,
		[Modal.QUESTS]: null,
		[Modal.OTHER]: null,
		[Modal.DESYNTHESIS]: null,
		[Modal.REDUCTIONS]: null,
	}

	open(modalToOpen:Modal){
		this.close(); // Close all modals

		let thisModal = this.modals[modalToOpen];

		if(!thisModal){
			thisModal = document.getElementById(modalToOpen) as HTMLDivElement;
			this.modals[modalToOpen] = thisModal;
		}
		thisModal.classList.remove("hide");
		thisModal.classList.add("show");
	}

	close = () => { // Event
		const modals = Object.values(Modal);
		modals.forEach((modal) => {
			const thisModal = this.modals[modal];
			if(!thisModal){ return; }
			thisModal.classList.remove("hide", "show");
			thisModal.classList.add("hide");
		});
	}
}

const modal = new ModalManager();
export default modal;
