//#region Imports
import FormComponents from "components/forms/FormComponents";
import { Breakpoint } from "enums";
import manager from "managers/app";
import { Component } from "react";
import { connect } from "react-redux";
import t from "translations/translator";
import { AccountStatusProps, AppState, OutputString } from "types";
import "./AccountStatus.css";
//#endregion Imports

const mapStateToProps = (state:AppState) => {
	return {
		lang: state.userInfo.options.lang,
		gameLang: state.userInfo.options.gameLang,
		breakpoint: state.breakpoint,
		user: {
			isAuthenticated: state.userInfo.isAuthenticated,
			username: state.userInfo.username,
		},
	};
};

class AccountStatus extends Component<AccountStatusProps> {
	render(){
		let login_state:string = t.t("You are not logged in");
		let text:OutputString = "Log in / Sign up";
		let clickEvent = this.loginClick;
		const user = this.props.user;

		if(user.isAuthenticated){ // eslint-disable-line no-sync
			login_state = t.t("Logged in as %USERNAME%", { USERNAME: user.username });
			text = "Log out";
			clickEvent = this.logoutClick;
		}

		const statusClasses:string[] = ["account-status"];

		if(this.props.inline || this.props.breakpoint !== Breakpoint.DESKTOP){
			statusClasses.push("inline");
		}

		return (
			<div className={statusClasses.join(" ")}>
				<p>{login_state}</p>
				<FormComponents.Button
					text={text}
					class={["link"]}
					onClick={clickEvent}
					translate={true}
				/>
			</div>
		);
	}

	loginClick = async() => {
		const result = await manager.auth.login();
		manager.ga.loggedIn(result);
		if(result){
			manager.data.init();
		}
	}

	logoutClick = async() => {
		await manager.auth.logout();
	}
}
export default connect(mapStateToProps)(AccountStatus);
