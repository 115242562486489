//#region Imports
import { CharacterProgress } from "character";
import { items } from "data-files/items";
import { APIEndpoint, APIStatus, DataSource, GameLanguage, Item, Job, Language, Modal, RelicIndex, Theme } from "enums";
import log from "logger";
import manager from "managers/app";
import { appEnv } from "managers/env";
import { actions, store } from "managers/state";
import sysMsg from "managers/sysMsg";
import { Character, GetCharacterAchievementsRequest, GetCharacterAchievementsResponse, GetGameServersRequest, GetGameServersResponse, InventoryStatus, InventoryStatusOptions, OutputString, SaveActions, UserData, UserInfo, UserOptions } from "types"; // eslint-disable-line max-len
import utils from "utils";
import local from "./local";
import oldDataManager from "./oldDataManager";
import remote from "./remote";
import sanitise from "./sanitation";
//#endregion Imports

export class DataManager {
	private dataSource:DataSource = DataSource.LOCAL;
	private saveTimeout:number = -1;
	private saveDelay:number = appEnv.saveDelay;
	private saveActions:SaveActions = { options: null, data: null };

	async init():Promise<boolean>{
		log.init(log.InitStatus.START, "Data");

		this.setupServerList();

		// Check for and upgrade old data
		const updatedOldData = await this.upgradeOldData();

		// Get Current Data
		const userInfo = await this.getCurrentData();
		if(!userInfo){
			log.error("Critical Error - Unable to setup data!");
			// TODO: Stop the app - There is no data, this is not good! - It should not happen though, we hope :)
			return false;
		}


		local.saveOptions(userInfo.options); // Save options regardless of the data source
		log.status(`Using data from: ${this.dataSource}`);

		if(await manager.auth.isAuthenticated() && this.dataSource !== DataSource.REMOTE){
			store.dispatch(actions.setUser({ isAuthenticated: false, username: userInfo.username }));
			sysMsg.error({ message: "There was an error fetching your account information - Switched to %DATASOURCE% data instead", messagePlaceholders: { DATASOURCE: this.dataSource } });
		}

		// Clean data keys, Update state
		const cleanedUserInfo = sanitise.clean(userInfo);
		store.dispatch(actions.setUserOptions(cleanedUserInfo.options));
		store.dispatch(actions.setUserData(cleanedUserInfo.data));

		const anonChar = oldDataManager.getAnonymousChar();
		if(anonChar){
			store.dispatch(actions.setAnonymousAvailable(true));
		}

		// Handle old data - Show confirmations, merge, update state
		this.handleOldData(updatedOldData, userInfo);

		log.init(log.InitStatus.COMPLETE, "Data");
		return true;
	}

	private async upgradeOldData():Promise<UserData|null>{
		// Fetch old data, If any is available
		const [oldData, oldVersion] = await oldDataManager.getOldData();

		if(!oldData || !oldVersion){
			log.info("No old data found");
			return null;
		}

		// Upgrade the old data to the current version
		const updatedOldData = oldDataManager.updateData(oldData, oldVersion);
		if(!updatedOldData){
			log.warn("Unable to upgrade old data");
		}
		return updatedOldData;
	}

	private handleOldData(updatedOldData:UserData|null, currentInfo:UserInfo):void{
		if(!updatedOldData){ return; }

		const dataVersion = utils.helpers.getVersion(updatedOldData.version);
		const appVersion = utils.helpers.getVersion(appEnv.version);

		if(dataVersion.major === appVersion.major){
			oldDataManager.mergeData(currentInfo, updatedOldData, true);
			return;
		}

		manager.content.openModal(Modal.CONFIRMATION, {
			title: "Old version data found",
			text: "Data from an old version has been found, would you like to import this data?",
			confirm: {
				text: "Yes",
				action: () => {
					if(!currentInfo || !updatedOldData){ log.warn("Unable to merge previous data"); return; }
					oldDataManager.mergeData(currentInfo, updatedOldData);
				},
			},
			cancel: {
				text: "No",
				action: () => {
					manager.modal.close();
					setTimeout(() => {
						manager.content.openModal(Modal.CONFIRMATION, {
							title: "Delete old data",
							text: "Delete old version data? This is irreversable and you will lose the option to import later",
							confirm: {
								text: "Yes",
								action: () => { oldDataManager.clearOldData(); },
							},
							cancel: { text: "No" },
						});
					}, 500);
				},
			},
		});
	}

	private async remoteInit():Promise<UserInfo|null>{
		log.info("Fetching data from remote");
		const remoteData = await remote.getUserInfo();
		if(remoteData === null){
			log.warn("Unable to fetch from remote");
			return null;
		}
		this.dataSource = DataSource.REMOTE;
		return remoteData;
	}

	private localInit():UserInfo|null{
		log.info("Fetching data from local storage");
		const localData = local.getUserInfo();
		if(localData === null){
			log.warn("No local data");
			return null;
		}
		this.dataSource = DataSource.LOCAL;
		return localData;
	}

	private defaultInit():UserInfo{
		log.info("Fetching defaults");
		const userInfo:UserInfo|null = store.getState().userInfo;
		userInfo.data = this.getDefaultUserData();
		log.info("Saving defaults to local");
		local.saveUserData(userInfo.data);

		this.dataSource = DataSource.LOCAL;
		return userInfo;
	}

	private getDefaultUserData():UserData{
		const new_data:UserData = { version: appEnv.version, characters: [] };
		return new_data;
	}

	private async doSave(skipSaveTimeout:true):Promise<boolean>;
	private async doSave(skipSaveTimeout:false):Promise<void>;
	private async doSave(skipSaveTimeout:boolean):Promise<boolean|void>;
	private async doSave(skipSaveTimeout:boolean):Promise<boolean|void>{
		if(this.saveTimeout){ clearTimeout(this.saveTimeout); }

		if(this.saveActions.options){
			store.dispatch(actions.setUserOptions(this.saveActions.options));
		}
		if(this.saveActions.data){
			store.dispatch(actions.setUserData(this.saveActions.data));
		}

		const saveActions = async() => {
			const optionsResult = await this.doSaveOptions();
			const dataResult = await this.doSaveData();

			if(optionsResult){ this.saveActions.options = null; }
			if(dataResult){ this.saveActions.data = null; }

			if(optionsResult === false || dataResult === false){ return false; }
			return true;
		};

		if(skipSaveTimeout){
			const result = await saveActions();
			return result;
		}

		this.saveTimeout = window.setTimeout(saveActions, this.saveDelay);
	}

	private async doSaveOptions():Promise<boolean|null>{
		const options = this.saveActions.options;
		if(!options){ return null; }

		let result = false;

		if(this.dataSource === DataSource.REMOTE){
			result = await remote.saveOptions(options);
			if(result){ local.saveOptions(options); }
		}else{
			result = local.saveOptions(options);
		}

		let notificationText:OutputString;
		if(result){
			notificationText = "Preferences Saved";
		}else{
			notificationText = "Error saving your preferences";
		}

		sysMsg.notification({ message: notificationText });

		return result;
	}

	private async doSaveData():Promise<boolean|null>{
		const userData = this.saveActions.data;
		if(!userData){ return null; }

		let result = false;

		if(this.dataSource === DataSource.REMOTE){
			result = await remote.saveUserData(userData);
		}else{
			result = local.saveUserData(userData);
		}

		let notificationText:OutputString;
		if(result){
			notificationText = "Data Saved";
		}else{
			notificationText = "Error saving your data";
		}

		sysMsg.notification({ message: notificationText });

		return result;
	}

	private async setupServerList():Promise<void>{
		const serverListResponse = await manager.request.send<GetGameServersRequest, GetGameServersResponse>(APIEndpoint.SERVER_LIST, {});

		if(!serverListResponse || serverListResponse.status !== APIStatus.SUCCESS){
			sysMsg.error({ title: "Error processing request", message: serverListResponse ? serverListResponse.message as OutputString : "Error fetching server list" as OutputString });
			return;
		}

		store.dispatch(actions.setGameServers(serverListResponse.data.servers));
	}

	async getCurrentData():Promise<UserInfo|null>{
		let userInfo = await this.remoteInit();
		if(!userInfo){ userInfo = this.localInit(); }
		if(!userInfo){ userInfo = this.defaultInit(); }
		return userInfo;
	}

	getActiveCharacter():Character|null{
		const userData = store.getState().userInfo.data;
		const char = userData.characters.find((z) => { return z.active; });
		if(char){ return char; }
		if(userData.characters.length > 0){
			userData.characters[0].active = true;
			this.saveData(userData, false);
			return userData.characters[0];
		}
		return null;
	}

	async saveOptions(options:UserOptions, skipSaveTimeout:true):Promise<boolean>;
	async saveOptions(options:UserOptions, skipSaveTimeout:false):Promise<void>;
	async saveOptions(options:UserOptions, skipSaveTimeout:boolean):Promise<boolean|void>;
	async saveOptions(options:UserOptions, skipSaveTimeout:boolean):Promise<boolean|void>{
		this.saveActions.options = options;
		const result = await this.doSave(skipSaveTimeout);
		return result;
	}

	async saveData(data:UserData, skipSaveTimeout:true):Promise<boolean>;
	async saveData(data:UserData, skipSaveTimeout:false):Promise<void>;
	async saveData(data:UserData, skipSaveTimeout:boolean):Promise<boolean|void>;
	async saveData(data:UserData, skipSaveTimeout:boolean):Promise<boolean|void>{
		this.saveActions.data = data;
		const result = await this.doSave(skipSaveTimeout);
		return result;
	}

	async saveCharacters(updatedCharacters:Character[], skipSaveTimeout:true):Promise<boolean|null>;
	async saveCharacters(updatedCharacters:Character[], skipSaveTimeout:false):Promise<void>;
	async saveCharacters(updatedCharacters:Character[], skipSaveTimeout:boolean):Promise<boolean|null|void>;
	async saveCharacters(updatedCharacters:Character[], skipSaveTimeout:boolean):Promise<boolean|null|void>{
		if(updatedCharacters.length === 0){
			if(skipSaveTimeout){ return null; }
			return;
		}

		const userData = store.getState().userInfo.data;
		const finalCharacters = userData.characters;

		finalCharacters.forEach((char) => {
			const updatedChar = updatedCharacters.find((z) => { return z.seId === char.seId; });
			if(updatedChar){ char = updatedChar; } // eslint-disable-line no-param-reassign
		});

		updatedCharacters.forEach((updatedChar) => {
			updatedChar.lastUpdate = new Date();
			if(!finalCharacters.find((z) => { return z.seId === updatedChar.seId; })){
				finalCharacters.push(updatedChar);
			}
		});

		userData.characters = finalCharacters;
		const result = await this.saveData(userData, skipSaveTimeout);
		return result;
	}

	async deleteCharacters(seIds:string[], skipSaveTimeout:true):Promise<boolean|null>;
	async deleteCharacters(seIds:string[], skipSaveTimeout:false):Promise<void>;
	async deleteCharacters(seIds:string[], skipSaveTimeout:boolean):Promise<boolean|null|void>;
	async deleteCharacters(seIds:string[], skipSaveTimeout:boolean):Promise<boolean|null|void>{
		if(seIds.length === 0){
			if(skipSaveTimeout){ return null; }
			return;
		}

		const userData = store.getState().userInfo.data;
		userData.characters = userData.characters.filter((z) => { return !seIds.includes(z.seId); });
		const result = await this.saveData(userData, skipSaveTimeout);
		return result;
	}

	async deleteAllCharacters(skipSaveTimeout:true):Promise<boolean|void>;
	async deleteAllCharacters(skipSaveTimeout:false):Promise<boolean|void>;
	async deleteAllCharacters(skipSaveTimeout:boolean):Promise<boolean|void>;
	async deleteAllCharacters(skipSaveTimeout:boolean):Promise<boolean|void>{
		const userData = store.getState().userInfo.data;
		userData.characters = [];
		const result = await this.saveData(userData, skipSaveTimeout);
		return result;
	}

	getDefaultOptions():UserOptions{
		return {
			theme: Theme.DARK,
			lang: Language.EN,
			gameLang: GameLanguage.EN,
			job: null,
			hideCompletedSteps: false,
			hideCompletedTasks: false,
			showMaximumItemQty: true,
			relics: {
				animusStepJobs: new Array(9).fill(Job.PLD),
				zodiacBravesStepJobs: new Array(4).fill(Job.PLD),
				zodiacZetaStepJob: Job.PLD,
				animaCompleteStepJob: Job.PLD,
				animaLuxStepJob: Job.PLD,
			},
			items: {
				hideNoRemaining: false,
				showOnlyBuyable: false,
				showMaximumRequired: false,
				selectedView: "Items",
				relicsFilter: [],
				relicPartsFilter: [],
				currencyFilter: [],
			},
			progress: {
				ignorePhyseosPart: false,
				compactProgressView: false,
			},
		};
	}

	getInventory(item:Item):number{
		const character = this.getActiveCharacter();
		if(!character){ return -1; }
		const inventory = character.inventory[item];
		if(!inventory){ return 0; }
		return inventory;
	}

	setInventory(item:Item, value:number):void{
		const character = this.getActiveCharacter();
		if(!character){ return; }

		let inventory = character.inventory[item];
		if(!inventory){ inventory = 0; }

		character.inventory[item] = value;
	}

	getInventoryStatus(options:InventoryStatusOptions):InventoryStatus{
		const inventoryStatus = { inventory: -1, qtyPerJob: -1, total: -1, used: -1, remaining: -1, toObtain: -1 };

		const character = this.getActiveCharacter();
		if(!character){ log.warn("Unable to find active character"); return inventoryStatus; }

		const { item, relic, job, getMax } = options;

		const itemInfo = items[item];
		if(itemInfo.used.length === 0){ log.warn(`No relic info set on this item: ${item}`); return inventoryStatus; }

		const thisItemUsed = itemInfo.used.find((z) => { return z.relic[RelicIndex.STEP] === relic[RelicIndex.STEP] && z.relic[RelicIndex.TASK] === relic[RelicIndex.TASK]; });
		if(!thisItemUsed){ log.warn(`No relic info found for this relic step: ${item} - ${relic[RelicIndex.STEP]} - ${relic[RelicIndex.TASK]}`); return inventoryStatus; }

		let numJobs = thisItemUsed.jobs.length;
		let numCompletedJobs = 0;

		// If this is a one time step, then get inventory status for Job.PLD
		if(manager.relics.isFirstTimeOnlyStep(relic[RelicIndex.STEP]) || job){
			const jobToCheck = manager.relics.isFirstTimeOnlyStep(relic[RelicIndex.STEP]) ? Job.PLD : job;
			numJobs = 1;
			numCompletedJobs = Number(character.progress.isComplete(relic[RelicIndex.TYPE], relic[RelicIndex.RELIC], relic[RelicIndex.PART], relic[RelicIndex.STEP], relic[RelicIndex.TASK], jobToCheck));
		}else{
			numCompletedJobs = character.progress.getNumberOfJobsComplete(thisItemUsed.jobs, relic[RelicIndex.TYPE], relic[RelicIndex.RELIC], relic[RelicIndex.PART], relic[RelicIndex.STEP], relic[RelicIndex.TASK]);
		}

		let qtyPerJob = -1;
		if(getMax){
			qtyPerJob = thisItemUsed.maxQtyPerJob ? thisItemUsed.maxQtyPerJob : thisItemUsed.qtyPerJob;
		}else{
			qtyPerJob = thisItemUsed.qtyPerJob;
		}

		inventoryStatus.inventory = this.getInventory(item);

		const total = qtyPerJob * numJobs;
		const used = qtyPerJob * numCompletedJobs;
		const remaining = total - used;
		const toObtain = remaining - inventoryStatus.inventory;

		inventoryStatus.qtyPerJob = qtyPerJob;
		inventoryStatus.total = total;
		inventoryStatus.used = used > total ? total : used;
		inventoryStatus.remaining = remaining < 0 ? 0 : remaining;
		inventoryStatus.toObtain = toObtain < 0 ? 0 : toObtain;

		return inventoryStatus;
	}

	getDefaultCharacter():Character{
		return {
			seId: "",
			avatar: null,
			name: "",
			displayName: "",
			achievementsChecked: null,
			lastUpdate: new Date(),
			active: false,
			progress: new CharacterProgress(),
			inventory: {},
		};
	}

	resetCharacters(skipSaveTimeout:boolean):void{
		const defaultCharacter = this.getDefaultCharacter();
		const defaultCharacterData = {
			achievementsChecked: defaultCharacter.achievementsChecked,
			inventory: defaultCharacter.inventory,
			progress: defaultCharacter.progress,
			lastUpdate: defaultCharacter.lastUpdate,
		};

		const userData = store.getState().userInfo.data;
		const characters = userData.characters;
		const updatedCharacters:Character[] = [];

		characters.forEach((char) => {
			updatedCharacters.push({
				...char,
				...defaultCharacterData,
				displayName: char.name,
			});
		});

		userData.characters = updatedCharacters;
		this.saveData(userData, skipSaveTimeout);
	}

	resetOptions(skipSaveTimeout:boolean):void{
		const defaultOptions:UserOptions = this.getDefaultOptions();
		this.saveOptions(defaultOptions, skipSaveTimeout);
	}

	resetAllData(skipSaveTimeout:boolean):void{
		this.resetOptions(false);
		this.resetCharacters(skipSaveTimeout);
	}

	async checkAchievements(seId:string, skipSaveTimeout:boolean):Promise<void>{
		const allCharacters = store.getState().userInfo.data.characters;
		const thisCharacter = allCharacters.find((z) => { return z.seId === seId; });

		if(!thisCharacter){ return; }

		const reqData:GetCharacterAchievementsRequest = { id: seId };
		const achievementsResponse = await manager.request.send<GetCharacterAchievementsRequest, GetCharacterAchievementsResponse>(APIEndpoint.GET_ACHIEVEMENTS, reqData);

		if(!achievementsResponse || achievementsResponse.status !== APIStatus.SUCCESS){
			sysMsg.error({ title: "Error processing request", message: achievementsResponse ? achievementsResponse.message as OutputString : "Error fetching achievements" as OutputString });
			manager.ga.achievementsChecked(false);
			return;
		}

		achievementsResponse.data.achievements.forEach((achievement) => {
			thisCharacter.progress.setComplete(achievement.relicId, achievement.job, true);
		});

		thisCharacter.achievementsChecked = new Date();
		this.saveCharacters([thisCharacter], skipSaveTimeout);
		manager.ga.achievementsChecked(true);
	}
}

const data = new DataManager();
export default data;
