//#region Imports
import { Component } from "react";
import t from "translations/translator";
import { FormLabelProps } from "types";
//#endregion Imports

export class FormLabel extends Component<FormLabelProps> {
	render(){
		if(this.props.hide){ return null; }

		const labelClasses:string[] = ["field-label"];

		if(typeof this.props.wrap !== "undefined" && !this.props.wrap){
			labelClasses.push("no-wrap");
		}

		return (
			<label
				className={labelClasses.join(" ")}
			>
				{this.props.translate === false ? this.props.text : t.t(this.props.text, this.props.placeholders)}{this.props.position === "left" ? ":" : null}
			</label>
		);
	}
}
