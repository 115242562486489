//#region Imports
import { EorzeaDataType, Quest } from "enums";
import { QuestInfo } from "types";
//#endregion Imports

// TODO: Change keys and names into Quest enum references instead

export const quests:{ [key in Quest]: QuestInfo } = {
	[Quest.UNKNOWN]: { name: Quest.UNKNOWN, type: EorzeaDataType.MSQ, lodestoneID: "" },
	[Quest.ULTIMATE_WEAPON]: { name: Quest.ULTIMATE_WEAPON, type: EorzeaDataType.MSQ, lodestoneID: "2ba6b3e60da" },
	[Quest.WEAPONSMITH_OF_LEGEND]: { name: Quest.WEAPONSMITH_OF_LEGEND, type: EorzeaDataType.BLUEQUEST, lodestoneID: "b72db0c1975" },
	[Quest.RELIC_REBORN]: { name: Quest.RELIC_REBORN, type: EorzeaDataType.BLUEQUEST, lodestoneID: "e614e8a46e1" },
	[Quest.UP_IN_ARMS]: { name: Quest.UP_IN_ARMS, type: EorzeaDataType.BLUEQUEST, lodestoneID: "7222ccaeef2" },
	[Quest.TRIALS_OF_THE_BRAVES]: { name: Quest.TRIALS_OF_THE_BRAVES, type: EorzeaDataType.BLUEQUEST, lodestoneID: "ae51238f704" },
	[Quest.CELESTIAL_RADIANCE]: { name: Quest.CELESTIAL_RADIANCE, type: EorzeaDataType.BLUEQUEST, lodestoneID: "ae1ffb8bee4" },
	[Quest.STAR_LIGHT_STAR_BRIGHT]: { name: Quest.STAR_LIGHT_STAR_BRIGHT, type: EorzeaDataType.BLUEQUEST, lodestoneID: "be1dc9c9a63" },
	[Quest.WHEREFORE_ART_THOU_ZODIAC]: { name: Quest.WHEREFORE_ART_THOU_ZODIAC, type: EorzeaDataType.BLUEQUEST, lodestoneID: "229f8633138" },
	[Quest.ONE_MANS_TRASH]: { name: Quest.ONE_MANS_TRASH, type: EorzeaDataType.BLUEQUEST, lodestoneID: "e07310dc045" },
	[Quest.HEAVENSWARD]: { name: Quest.HEAVENSWARD, type: EorzeaDataType.MSQ, lodestoneID: "29fa56153f5" },
	[Quest.UNEXPECTED_PROPOSAL]: { name: Quest.UNEXPECTED_PROPOSAL, type: EorzeaDataType.BLUEQUEST, lodestoneID: "fb5d98ff180" },
	[Quest.SOUL_WITHOUT_LIFE]: { name: Quest.SOUL_WITHOUT_LIFE, type: EorzeaDataType.BLUEQUEST, lodestoneID: "0136a6df60a" },
	[Quest.TOUGHENING_UP]: { name: Quest.TOUGHENING_UP, type: EorzeaDataType.BLUEQUEST, lodestoneID: "3a19d47a205" },
	[Quest.COMING_INTO_ITS_OWN]: { name: Quest.COMING_INTO_ITS_OWN, type: EorzeaDataType.BLUEQUEST, lodestoneID: "a37d18ed018" },
	[Quest.MISLAID_PLANS]: { name: Quest.MISLAID_PLANS, type: EorzeaDataType.BLUEQUEST, lodestoneID: "77299131e16" },
	[Quest.PONZE_OF_FLESH]: { name: Quest.PONZE_OF_FLESH, type: EorzeaDataType.BLUEQUEST, lodestoneID: "69755431807" },
	[Quest.LABOR_OF_LOVE]: { name: Quest.LABOR_OF_LOVE, type: EorzeaDataType.BLUEQUEST, lodestoneID: "cd22a5b2b9e" },
	[Quest.TREASURED_MOTHER]: { name: Quest.TREASURED_MOTHER, type: EorzeaDataType.BLUEQUEST, lodestoneID: "bc589dd6bff" },
	[Quest.METHOD_IN_HIS_MALACE]: { name: Quest.METHOD_IN_HIS_MALACE, type: EorzeaDataType.BLUEQUEST, lodestoneID: "484dab85cd3" },
	[Quest.HIS_DARK_MATERIA]: { name: Quest.HIS_DARK_MATERIA, type: EorzeaDataType.BLUEQUEST, lodestoneID: "9e4b93903f8" },
	[Quest.SHADOWBRINGERS]: { name: Quest.SHADOWBRINGERS, type: EorzeaDataType.MSQ, lodestoneID: "4ed1668d377" },
	[Quest.LOST_CITY_OF_ANGELS]: { name: Quest.LOST_CITY_OF_ANGELS, type: EorzeaDataType.BLUEQUEST, lodestoneID: "4b5e7d8e71b" },
	[Quest.FIRE_IN_THE_FORGE]: { name: Quest.FIRE_IN_THE_FORGE, type: EorzeaDataType.BLUEQUEST, lodestoneID: "b8f5286c6eb" },
	[Quest.RESISTANCE_IS_NOT_FUTILE]: { name: Quest.RESISTANCE_IS_NOT_FUTILE, type: EorzeaDataType.BLUEQUEST, lodestoneID: "4bd506e3dcb" },
	[Quest.SOBER_PROPSAL]: { name: Quest.SOBER_PROPSAL, type: EorzeaDataType.BLUEQUEST, lodestoneID: "65a6b7eec0a" },
	[Quest.WHERE_EAGLES_NEST]: { name: Quest.WHERE_EAGLES_NEST, type: EorzeaDataType.BLUEQUEST, lodestoneID: "20ecde94d22" },
	[Quest.VOWS_VIRTUE_DEEDS_CRUELTY]: { name: Quest.VOWS_VIRTUE_DEEDS_CRUELTY, type: EorzeaDataType.MSQ, lodestoneID: "fc70298b76f" },
	[Quest.FOR_WANT_OF_A_MEMORY]: { name: Quest.FOR_WANT_OF_A_MEMORY, type: EorzeaDataType.BLUEQUEST, lodestoneID: "9673c789ba9" },
	[Quest.WILL_TO_RESIST]: { name: Quest.WILL_TO_RESIST, type: EorzeaDataType.BLUEQUEST, lodestoneID: "f267d4d0aac" },
	[Quest.IN_THE_QUEENS_IMAGE]: { name: Quest.IN_THE_QUEENS_IMAGE, type: EorzeaDataType.BLUEQUEST, lodestoneID: "3a701be315d" },
	[Quest.CHANGE_OF_ARMS]: { name: Quest.CHANGE_OF_ARMS, type: EorzeaDataType.BLUEQUEST, lodestoneID: "39fc3e4a86a" },
	[Quest.RESISTANCE_REMEMBERS]: { name: Quest.RESISTANCE_REMEMBERS, type: EorzeaDataType.BLUEQUEST, lodestoneID: "17861306223" },
	[Quest.NEW_PATH_OF_RESISTANCE]: { name: Quest.NEW_PATH_OF_RESISTANCE, type: EorzeaDataType.BLUEQUEST, lodestoneID: "f49e076d1e1" },
	[Quest.HAIL_TO_THE_QUEEN]: { name: Quest.HAIL_TO_THE_QUEEN, type: EorzeaDataType.BLUEQUEST, lodestoneID: "6404990a3da" },
	[Quest.PATH_TO_THE_PAST]: { name: Quest.PATH_TO_THE_PAST, type: EorzeaDataType.BLUEQUEST, lodestoneID: "0cb41fcce8c" },
	[Quest.BOZJA_INCIDENT]: { name: Quest.BOZJA_INCIDENT, type: EorzeaDataType.BLUEQUEST, lodestoneID: "66ddcfbca45" },
	[Quest.WORK_HARDR_MAKE_BETTER]: { name: Quest.WORK_HARDR_MAKE_BETTER, type: EorzeaDataType.BLUEQUEST, lodestoneID: "7ef9593bc85" },
	[Quest.TOWARDS_THE_FIRMAMENT]: { name: Quest.TOWARDS_THE_FIRMAMENT, type: EorzeaDataType.BLUEQUEST, lodestoneID: "5c20f6c7a0f" },
	[Quest.EVER_SKYWARD]: { name: Quest.EVER_SKYWARD, type: EorzeaDataType.BLUEQUEST, lodestoneID: "bcc0651b7db" },
	[Quest.PINNACLE_OF_POSSIBILITY]: { name: Quest.PINNACLE_OF_POSSIBILITY, type: EorzeaDataType.BLUEQUEST, lodestoneID: "a779436da04" },
	[Quest.TOOLS_OF_TOMORROW]: { name: Quest.TOOLS_OF_TOMORROW, type: EorzeaDataType.BLUEQUEST, lodestoneID: "b01179c4a45" },
	[Quest.FINDING_YOUR_VOICE]: { name: Quest.FINDING_YOUR_VOICE, type: EorzeaDataType.BLUEQUEST, lodestoneID: "bf6394ecf52" },
	[Quest.GIFT_OF_THE_ARCHMAGUS]: { name: Quest.GIFT_OF_THE_ARCHMAGUS, type: EorzeaDataType.BLUEQUEST, lodestoneID: "810119f8166" },
	[Quest.DREAM_FULFILLED]: { name: Quest.DREAM_FULFILLED, type: EorzeaDataType.BLUEQUEST, lodestoneID: "1ef99ba9eeb" },
	[Quest.FUTURE_PROOF]: { name: Quest.FUTURE_PROOF, type: EorzeaDataType.BLUEQUEST, lodestoneID: "855114f5906" },
	[Quest.BORN_AGAIN]: { name: Quest.BORN_AGAIN, type: EorzeaDataType.BLUEQUEST, lodestoneID: "496ec3951a9" },
	[Quest.SOME_ASSEMBLY_REQUIRED]: { name: Quest.SOME_ASSEMBLY_REQUIRED, type: EorzeaDataType.BLUEQUEST, lodestoneID: "637f7078f64" },
	[Quest.WORDS_OF_WISDOM]: { name: Quest.WORDS_OF_WISDOM, type: EorzeaDataType.BLUEQUEST, lodestoneID: "eba4f9b62a1" },
	[Quest.BEST_FRIENDS_FOREVER]: { name: Quest.BEST_FRIENDS_FOREVER, type: EorzeaDataType.BLUEQUEST, lodestoneID: "9da6270b969" },
	[Quest.STORMBLOOD]: { name: Quest.STORMBLOOD, type: EorzeaDataType.MSQ, lodestoneID: "08908744553" },
	[Quest.WE_SHALL_CALL_IT_EUREKA]: { name: Quest.WE_SHALL_CALL_IT_EUREKA, type: EorzeaDataType.BLUEQUEST, lodestoneID: "ffcee4a9225" },
	[Quest.WHAT_DREAMS_ARE_MADE_OF]: { name: Quest.WHAT_DREAMS_ARE_MADE_OF, type: EorzeaDataType.BLUEQUEST, lodestoneID: "2a048a18b6a" },
	[Quest.SPARE_PARTS]: { name: Quest.SPARE_PARTS, type: EorzeaDataType.BLUEQUEST, lodestoneID: "a3323888517" },
	[Quest.TELL_ME_A_STORY]: { name: Quest.TELL_ME_A_STORY, type: EorzeaDataType.BLUEQUEST, lodestoneID: "bc4087254f5" },
	[Quest.FOND_MEMORY]: { name: Quest.FOND_MEMORY, type: EorzeaDataType.BLUEQUEST, lodestoneID: "f342e232a32" },
	[Quest.DONE_DEAL]: { name: Quest.DONE_DEAL, type: EorzeaDataType.BLUEQUEST, lodestoneID: "726a756ddd0" },
	[Quest.IRRESISTIBLE]: { name: Quest.IRRESISTIBLE, type: EorzeaDataType.BLUEQUEST, lodestoneID: "a0f4270b30f" },
	[Quest.SOULGLAZED_RELICS]: { name: Quest.SOULGLAZED_RELICS, type: EorzeaDataType.BLUEQUEST, lodestoneID: "1e5ad76ac84" },
	[Quest.RISE_AND_SHINE]: { name: Quest.RISE_AND_SHINE, type: EorzeaDataType.BLUEQUEST, lodestoneID: "2460c3e21c2" },
	[Quest.VITAL_TITLE]: { name: Quest.VITAL_TITLE, type: EorzeaDataType.BLUEQUEST, lodestoneID: "1c20d4fbe7d" },
	[Quest.SEEKING_INSPIRATION]: { name: Quest.SEEKING_INSPIRATION, type: EorzeaDataType.BLUEQUEST, lodestoneID: "3e8fe25633a" },
	[Quest.CUT_FROM_DIFFERENT_CLOTH]: { name: Quest.CUT_FROM_DIFFERENT_CLOTH, type: EorzeaDataType.BLUEQUEST, lodestoneID: "d5e9310f06b" },
	[Quest.BODY_SOUL]: { name: Quest.BODY_SOUL, type: EorzeaDataType.BLUEQUEST, lodestoneID: "49e96ad4861" },
	[Quest.ENDWALKER]: { name: Quest.ENDWALKER, type: EorzeaDataType.MSQ, lodestoneID: "52a65d1961d" },
	[Quest.IMPERFECT_GENTLEMAN]: { name: Quest.IMPERFECT_GENTLEMAN, type: EorzeaDataType.BLUEQUEST, lodestoneID: "c47acc7ce48" },
	[Quest.MAKE_IT_A_MANDERVILLE]: { name: Quest.MAKE_IT_A_MANDERVILLE, type: EorzeaDataType.BLUEQUEST, lodestoneID: "021b46c4277" },
	[Quest.MAKE_ANOTHER_MANDERVILLE]: { name: Quest.MAKE_ANOTHER_MANDERVILLE, type: EorzeaDataType.BLUEQUEST, lodestoneID: "b120e48ba9f" },
	[Quest.ODDNESS_IN_THE_END]: { name: Quest.ODDNESS_IN_THE_END, type: EorzeaDataType.BLUEQUEST, lodestoneID: "34a4a35ce1a" },
	[Quest.IN_EVERYONES_BEST_INTERESTS]: { name: Quest.IN_EVERYONES_BEST_INTERESTS, type: EorzeaDataType.BLUEQUEST, lodestoneID: "e6ec2f401cb" },
	[Quest.SIGN_OF_WHATS_TO_COME]: { name: Quest.SIGN_OF_WHATS_TO_COME, type: EorzeaDataType.BLUEQUEST, lodestoneID: "85f5dca1ba9" },
	[Quest.FIT_FOR_A_QUEEN]: { name: Quest.FIT_FOR_A_QUEEN, type: EorzeaDataType.BLUEQUEST, lodestoneID: "248f168eb15" },
	[Quest.NEW_PLAYING_FIELD]: { name: Quest.NEW_PLAYING_FIELD, type: EorzeaDataType.BLUEQUEST, lodestoneID: "1baea97c99b" },
	[Quest.GENERATIONAL_BONDING]: { name: Quest.GENERATIONAL_BONDING, type: EorzeaDataType.BLUEQUEST, lodestoneID: "4b33f3c1e7d" },
	[Quest.WELL_OILED]: { name: Quest.WELL_OILED, type: EorzeaDataType.BLUEQUEST, lodestoneID: "705170c4e1d" },
	[Quest.NEXT_MANDER_LEVEL]: { name: Quest.NEXT_MANDER_LEVEL, type: EorzeaDataType.BLUEQUEST, lodestoneID: "1194db5d5f9" },
	[Quest.CRYSTALLINE_MEAN]: { name: Quest.CRYSTALLINE_MEAN, type: EorzeaDataType.BLUEQUEST, lodestoneID: "" },
	[Quest.ORIGINAL_IMPROVEMENT]: { name: Quest.ORIGINAL_IMPROVEMENT, type: EorzeaDataType.BLUEQUEST, lodestoneID: "" },
	[Quest.DEDICATED_TOOL]: { name: Quest.DEDICATED_TOOL, type: EorzeaDataType.BLUEQUEST, lodestoneID: "" },
	[Quest.ADAPTIVE_TOOL]: { name: Quest.ADAPTIVE_TOOL, type: EorzeaDataType.BLUEQUEST, lodestoneID: "" },
	[Quest.JUST_TOOLING_AROUND]: { name: Quest.JUST_TOOLING_AROUND, type: EorzeaDataType.BLUEQUEST, lodestoneID: "" },
	[Quest.SAVING_CAPTAIN_GAIRHARD]: { name: Quest.SAVING_CAPTAIN_GAIRHARD, type: EorzeaDataType.BLUEQUEST, lodestoneID: "" },
	[Quest.WAITING_IN_THE_WINGLET]: { name: Quest.WAITING_IN_THE_WINGLET, type: EorzeaDataType.BLUEQUEST, lodestoneID: "" },
	[Quest.RIVALRY_AND_RESPECT]: { name: Quest.RIVALRY_AND_RESPECT, type: EorzeaDataType.BLUEQUEST, lodestoneID: "" },
	[Quest.JADED]: { name: Quest.JADED, type: EorzeaDataType.BLUEQUEST, lodestoneID: "" },
	[Quest.ACCEPT_NO_IMITATIONS]: { name: Quest.ACCEPT_NO_IMITATIONS, type: EorzeaDataType.BLUEQUEST, lodestoneID: "" },
	[Quest.A_MINER_REBORN]: { name: Quest.A_MINER_REBORN, type: EorzeaDataType.BLUEQUEST, lodestoneID: "" },
	[Quest.MOMENTARY_MIRACLE]: { name: Quest.MOMENTARY_MIRACLE, type: EorzeaDataType.BLUEQUEST, lodestoneID: "" },
	[Quest.REVENGE_OF_THE_CHEFSBANE]: { name: Quest.REVENGE_OF_THE_CHEFSBANE, type: EorzeaDataType.BLUEQUEST, lodestoneID: "" },
	[Quest.CANYON_OF_REGRET]: { name: Quest.CANYON_OF_REGRET, type: EorzeaDataType.BLUEQUEST, lodestoneID: "" },
	[Quest.SEEDS_OF_HOPE]: { name: Quest.SEEDS_OF_HOPE, type: EorzeaDataType.BLUEQUEST, lodestoneID: "" },
	[Quest.SO_LONG_THANKS_FOR_ALL_THE_FISH]: { name: Quest.SO_LONG_THANKS_FOR_ALL_THE_FISH, type: EorzeaDataType.BLUEQUEST, lodestoneID: "" },
	[Quest.A_SPIRITED_REFORGING]: { name: Quest.A_SPIRITED_REFORGING, type: EorzeaDataType.BLUEQUEST, lodestoneID: "" },
	[Quest.IN_NEED_OF_ADJUSTMENT]: { name: Quest.IN_NEED_OF_ADJUSTMENT, type: EorzeaDataType.BLUEQUEST, lodestoneID: "" },
	[Quest.TOOL_OF_HER_OWN]: { name: Quest.TOOL_OF_HER_OWN, type: EorzeaDataType.BLUEQUEST, lodestoneID: "" },
	[Quest.TOOL_WITHOUT_COMPARE]: { name: Quest.TOOL_WITHOUT_COMPARE, type: EorzeaDataType.BLUEQUEST, lodestoneID: "" },
	[Quest.TOOL_FOR_THE_AGES]: { name: Quest.TOOL_FOR_THE_AGES, type: EorzeaDataType.BLUEQUEST, lodestoneID: "" },
	[Quest.STAND_TOOL_MY_FRIEND]: { name: Quest.STAND_TOOL_MY_FRIEND, type: EorzeaDataType.BLUEQUEST, lodestoneID: "" },
	[Quest.GENTLEMEN_AT_HEART]: { name: Quest.GENTLEMEN_AT_HEART, type: EorzeaDataType.BLUEQUEST, lodestoneID: "" },
	[Quest.RESONATING_WITH_PERFECTION]: { name: Quest.RESONATING_WITH_PERFECTION, type: EorzeaDataType.BLUEQUEST, lodestoneID: "" },
	[Quest.POSITIVELY_MANDERVILLOUS]: { name: Quest.POSITIVELY_MANDERVILLOUS, type: EorzeaDataType.BLUEQUEST, lodestoneID: "" },
};
