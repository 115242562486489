//#region Imports
import { faCircle, faCodeMerge, faPencilAlt, faPlus, faSave, faSearch, faSync, faTimes, faTrophy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CharacterProgress } from "character";
import CharacterDisplay from "components/character-display/CharacterDisplay";
import FormComponents from "components/forms/FormComponents";
import { APIEndpoint, APIStatus, InputType, Modal } from "enums";
import log from "logger";
import manager from "managers/app";
import oldDataManager from "managers/data/oldDataManager";
import sysMsg from "managers/sysMsg";
import { Component, SyntheticEvent } from "react";
import { connect } from "react-redux";
import { MultiValue, SingleValue } from "react-select";
import t from "translations/translator";
import { AppState, Character, CharacterDisplayAction, CharacterSearchRequest, CharacterSearchResponse, GetCharacterAvatarRequest, GetCharacterAvatarResponse, OutputString, RSOption, ViewCharactersProps, ViewCharactersState } from "types"; // eslint-disable-line max-len
import "./Characters.css";
//#endregion Imports

const mapStateToProps = (state:AppState) => {
	return {
		lang: state.userInfo.options.lang,
		gameLang: state.userInfo.options.gameLang,
		characters: state.userInfo.data.characters,
		userInfo: state.userInfo, // This is needed to trigger the re-render on update
		anonymousAvailable: state.anonymousAvailable,
		servers: state.gameServers,
	};
};

class Characters extends Component<ViewCharactersProps> {
	state:ViewCharactersState = {
		unsavedChanges: false,
		charsInEditMode: [],
		disableSaveButton: true,
		dc: null,
		server: null,
		searchName: null,
		searchResults: null,
		searching: false,
		newChars: [],
	};

	renderYourCharacters(){
		const saveBtnTxt = this.state.disableSaveButton ? "No Changes Made" : "Save Changes";
		const saveBtnIcon = this.state.disableSaveButton ? undefined : faSave; // eslint-disable-line no-undefined

		const characters = this.props.characters;
		const anonymousAvailable = this.props.anonymousAvailable;

		return (
			<div className="your-characters-container">
				<h1>{t.t("Your Characters")}</h1>
				{characters.length > 0 && anonymousAvailable
					? <div className="anonymous-character">
						<p>{t.t("Anonymous Character found. You can merge this character into any existing character below by clicking")} <FontAwesomeIcon icon={faCodeMerge} /> {t.t("or")}</p>
						<FormComponents.Button class={["error"]} text="Discard" onClick={this.discardAnonymous} />
					</div>
					: null
				}
				<div className="your-characters-wrapper">
					<div className="your-characters">
						{characters.length === 0
							? <div className="import-backup">
								<p>{t.t("No saved characters")}</p>
								<FormComponents.Button text="Upload backup from Version 2.1.1" onClick={this.importFromBackup} />
							</div>
							: characters.map((char) => {
								const actions:CharacterDisplayAction[] = [];
								if(anonymousAvailable){ actions.push({ icon: faCodeMerge, iconClass: ["info"], onClick: () => { this.mergeAnonymous(char.seId); }, tooltip: "Merge Anonymous data into this character" }); }

								actions.push(
									{ icon: faTrophy, iconClass: ["warn"], onClick: this.checkAchievements, tooltip: "Check your Achievements" },
									{ icon: faPencilAlt, iconClass: ["success"], onClick: this.editCharacter, tooltip: "Edit Character" },
									{ icon: faTimes, iconClass: ["error"], onClick: this.removeCharacter, tooltip: "Delete Character" },
								);

								return <div key={`your_character_${char.seId}`} className="char-wrapper">
									<div className="achievement-overlay">
										<p>{t.t("Fetching Achievements")}</p>
										<FontAwesomeIcon icon={faSync} />
									</div>
									<div className="remove-overlay">
										<p>{t.t("%CHARNAME% marked for removal", { CHARNAME: char.displayName })}</p>
										<FormComponents.Button text={"Undo"} class={["link"]} onClick={this.undoRemove} translate={true} />
									</div>
									<CharacterDisplay
										background="secondary"
										character={char}
										actions={actions}
										editMode={this.state.charsInEditMode.includes(char.seId)}
										onEditMade={this.setSaveButtonState}
									/>
								</div>;
							})
						}
					</div>
					<div className="save-changes">
						{characters.length > 0
							? <FormComponents.Button
								text={saveBtnTxt}
								onClick={this.saveYourCharacters}
								disabled={this.state.disableSaveButton}
								icon={saveBtnIcon}
								class={this.state.disableSaveButton ? [] : ["success"] }
								translate={true}
							/>
							: null
						}
					</div>
				</div>
			</div>
		);
	}

	renderCharacterSearch(){
		const servers = this.props.servers;
		const dc_options = Object.keys(servers).map((dc) => { return { label: dc, value: dc }; });

		const selectedDc = this.state.dc ? [this.state.dc] : [];
		const selectedServer = this.state.server ? [this.state.server] : [];
		const serverPlaceholder:OutputString = selectedDc.length === 0 ? "No Data Center Selected" : "Select a Server";
		const nameToSearch = this.state.searchName ? this.state.searchName : "";
		const server_options:RSOption[] = [];
		let disabledServerSelect = false;
		let disabledCharacterName = false;
		let disabledSearchButton = false;

		if(this.state.dc === null){
			disabledServerSelect = true;
		}else{
			servers[this.state.dc.value].forEach((server) => { server_options.push({ label: server, value: server }); });
		}

		if(this.state.server === null){ disabledCharacterName = true; }
		if(disabledCharacterName || this.state.searchName === null){ disabledSearchButton = true; }

		return (
			<div className="find-character-wrapper">
				<div className="find-character">
					<h1>{t.t("Find Character")}</h1>
					<div>
						<FormComponents.Form fieldDirection="column">
							<FormComponents.Select
								label={ { hide: true } }
								input={ { options: dc_options, value: selectedDc, placeholder: "Select Data Centre", class: ["primary"], events: { onChange: this.dcSelected } } }
							/>
							<FormComponents.Select
								label={ { hide: true } }
								input={ { options: server_options, value: selectedServer, placeholder: serverPlaceholder, class: ["primary"], events: { onChange: this.serverSelected }, disabled: disabledServerSelect } }
							/>
							<FormComponents.TextInput
								label={ { hide: true } }
								input={ { type: InputType.TEXT, placeholder: "Character Name", value: nameToSearch, disabled: disabledCharacterName, class: ["primary"], events: { onChange: this.charSearchEntered} } }
							/>
							<FormComponents.Button
								text="Search"
								disabled={disabledSearchButton}
								onClick={this.searchForCharacter}
								icon={faSearch}
								translate={true}
							/>
						</FormComponents.Form>
					</div>
				</div>
				<div className="search-wrapper">
					<h1>{t.t("Search Results")}</h1>
					{this.state.searching ? <FontAwesomeIcon icon={faCircle} className="searching" /> : null }
					{this.state.searchResults === null
						? ""
						: <div className="search-results">
							{this.state.searchResults.length === 0
								? <p className="no-results">{t.t("No results found")}</p>
								: this.state.searchResults.map((result) => {
									const char:Character = {
										seId: result.id,
										name: result.name,
										displayName: result.name,
										avatar: null,
										achievementsChecked: null,
										lastUpdate: null,
										active: false,
										progress: new CharacterProgress(),
										inventory: {},
									};

									let selected = false;
									if(this.state.newChars.findIndex((z) => { return z.seId === char.seId; }) > -1){ selected = true; }

									return (
										<CharacterDisplay
											key={`search_result-${char.seId}`}
											background="secondary"
											character={char}
											actions={[]} // Should be empty if selectable = true
											hideAchievementCheck={true}
											hideLastUpdate={true}
											minimalist={true}
											selectable={true}
											selected={selected}
											selectAction={this.selectSearchResult}
										/>
									);
								})
							}
						</div>
					}
					<FormComponents.Button
						text={this.state.newChars.length > 1 ? "Add Characters" : "Add Character"}
						disabled={this.state.newChars.length === 0}
						onClick={this.addCharacter}
						icon={faPlus}
						class={this.state.newChars.length > 0 ? ["add-character success"] : ["add-character"]}
						translate={true}
					/>
				</div>
			</div>
		);
	}

	render(){
		return (
			<div id="characters">
				{this.renderYourCharacters()}
				{this.renderCharacterSearch()}
				<div className="reset-data-wrapper">
					<h1>{t.t("Data Management")}</h1>
					<p>{t.t("Reset your preferences or reset/delete your characters - These actions are irreversible")}</p>
					<div className="reset-data">
						<FormComponents.Button text={"Reset All Characters"} class={["error"]} onClick={this.resetAllCharacters} translate={true} />
						<FormComponents.Button text={"Reset Account Settings"} class={["error"]} onClick={this.resetAccountSettings} translate={true} />
						<FormComponents.Button text={"Reset All Data"} class={["error"]} onClick={this.resetAllData} translate={true} />
						<FormComponents.Button text={"Delete All Characters"} class={["error"]} onClick={this.deleteAllCharacters} translate={true} />
						{/* <FormComponents.Button text={"Delete Account"} class={["error"]} onClick={this.deleteAccount} translate={true} /> TODO: This functionality */}
					</div>
				</div>
			</div>
		);
	}

	componentDidMount(){
		const anonChar = oldDataManager.getAnonymousChar();
		this.setState((old:ViewCharactersState) => { return { ...old, anonymousChar: anonChar }; });
		manager.view.changeComplete();
	}

	getCharId(charDisplayChild:Element):string|null{
		const charDisplay = charDisplayChild.closest<HTMLDivElement>(".character-display");
		const seId = charDisplay?.dataset.seid;
		if(!seId){
			return null;
		}
		return seId;
	}

	findCharacter(seId:string):Character|undefined{
		return this.props.characters.find((z) => { return z.seId === seId; });
	}

	checkAchievements = async(evt:SyntheticEvent) => {
		const icon = evt.currentTarget;
		const overlay = icon.closest(".char-wrapper")?.querySelector(".achievement-overlay");
		if(!overlay){ return; }

		overlay.classList.remove("hide");
		overlay.classList.add("show");

		const seId = this.getCharId(evt.currentTarget);
		if(!seId){ return; }

		await manager.data.checkAchievements(seId, true);

		overlay.classList.remove("show");
		overlay.classList.add("hide");
	}

	editCharacter = (evt:SyntheticEvent) => {
		const seId = this.getCharId(evt.currentTarget);
		if(!seId){ return; }

		if(this.state.charsInEditMode.includes(seId)){
			this.setState((old:ViewCharactersState):ViewCharactersState => { return { ...old, charsInEditMode: old.charsInEditMode.filter((id) => { return id !== seId; }) }; });
		}else{
			this.setState((old:ViewCharactersState):ViewCharactersState => { return { ...old, charsInEditMode: [seId].concat(old.charsInEditMode) }; });
		}
	}

	setSaveButtonState = () => { // Event
		const yourChars = document.getElementsByClassName("your-characters")[0];
		const changedInputs = yourChars.querySelector("input.hasChanged");
		const charsToRemove = yourChars.querySelector(".remove-overlay.show");

		this.setState((old:ViewCharactersState):ViewCharactersState => { return { ...old, disableSaveButton: !(changedInputs || charsToRemove) }; });
	}

	removeCharacter = (evt:SyntheticEvent) => { // Event
		const icon = evt.currentTarget;
		const overlay = icon.closest(".char-wrapper")?.querySelector(".remove-overlay");
		if(!overlay){ return; }
		overlay.classList.remove("hide");
		overlay.classList.add("show");

		const seId = this.getCharId(icon);
		if(seId){
			if(this.state.charsInEditMode.includes(seId)){
				this.setState((old:ViewCharactersState):ViewCharactersState => { return { ...old, charsInEditMode: old.charsInEditMode.filter((id) => { return id !== seId; }) }; });
			}
		}
		this.setSaveButtonState();
	}

	undoRemove = (evt:SyntheticEvent) => { // Event
		const ele = evt.currentTarget;
		const overlay = ele.closest(".char-wrapper")?.querySelector(".remove-overlay");
		if(!overlay){ return; }
		overlay.classList.remove("show");
		overlay.classList.add("hide");
		this.setSaveButtonState();
	}

	getCharactersFlaggedForRemove():string[]{
		const charsToRemove:string[] = [];
		const charactersDiv = document.getElementById("characters");
		if(!charactersDiv){ return []; }

		const toRemove = Array.from(charactersDiv.querySelectorAll(".remove-overlay.show"));
		toRemove.forEach((removeOverlay) => {
			const charDisplay = removeOverlay.nextSibling as HTMLDivElement;
			if(!charDisplay){ return; }

			const seId = charDisplay.dataset.seid;
			if(!seId){ return; }

			charsToRemove.push(seId);
		});
		return charsToRemove;
	}

	getCharactersFlaggedForUpdate():Character[]{
		const updatedChars:Character[] = [];
		const charactersDiv = document.getElementById("characters");
		if(!charactersDiv){ return []; }

		const toUpdate:HTMLInputElement[] = Array.from(charactersDiv.querySelectorAll("input.hasChanged"));
		toUpdate.forEach((changedInput) => {
			const seId = this.getCharId(changedInput);
			if(!seId){ return; }

			const char = this.findCharacter(seId);
			if(!char){ return; }

			char.displayName = changedInput.value;
			char.lastUpdate = new Date();
			updatedChars.push(char);
		});

		return updatedChars;
	}

	saveYourCharacters = async() => { // Event
		const charsFlaggedToRemove:string[] = this.getCharactersFlaggedForRemove();
		const charsFlaggedToUpdate:Character[] = this.getCharactersFlaggedForUpdate(); // These are ready to save as is

		if(charsFlaggedToRemove.length === 0 && charsFlaggedToUpdate.length === 0){ return; } // This shouldn't happen

		const charsToUpdate:Character[] = [];

		// Ensure we are not updating characters that are flagged to remove
		charsFlaggedToUpdate.forEach((updatedChar) => {
			if(!charsFlaggedToRemove.includes(updatedChar.seId)){
				charsToUpdate.push(updatedChar);
			}
		});


		await manager.data.saveCharacters(charsToUpdate, true);
		await manager.data.deleteCharacters(charsFlaggedToRemove, true);

		if(charsFlaggedToRemove.length > 0){
			manager.ga.characterDeleted(true);
		}

		this.setState((old:ViewCharactersState):ViewCharactersState => { return { ...old, charsInEditMode: [] }; });
		this.setSaveButtonState();
	}

	dcSelected = (option:MultiValue<RSOption>|SingleValue<RSOption>) => {
		if(option instanceof Array){ return; } // Should not be the case, This is a single select
		if(!option){ return; } // Should always have a value

		const dc = option.value === "placeholder" ? null : option;
		this.setState((old:ViewCharactersState):ViewCharactersState => { return { ...old, dc: dc, server: null }; });
	}

	serverSelected = (option:MultiValue<RSOption>|SingleValue<RSOption>) => {
		if(option instanceof Array){ return; } // Should not be the case, This is a single select
		if(!option){ return; } // Should always have a value

		const server = option.value === "placeholder" ? null : option;
		this.setState((old:ViewCharactersState):ViewCharactersState => { return { ...old, server: server }; });
	}

	charSearchEntered = (evt:SyntheticEvent) => {
		const ele = evt.currentTarget as HTMLInputElement;
		const searchName = ele.value === "" ? null : ele.value;
		this.setState((old:ViewCharactersState):ViewCharactersState => { return { ...old, searchName: searchName }; });
	}

	searchForCharacter = async() => { // Event
		this.setState((old:ViewCharactersState):ViewCharactersState => { return { ...old, searchResults: null, searching: true }; });

		const server = this.state.server;
		const searchName = this.state.searchName;
		if(server === null || searchName === null){ return; }

		const searchResultsResponse = await manager.request.send<CharacterSearchRequest, CharacterSearchResponse>(APIEndpoint.CHARACTER_SEARCH, { server: server.value, name: searchName });

		if(!searchResultsResponse || searchResultsResponse.status !== APIStatus.SUCCESS){
			sysMsg.error({ title: "Error processing request", message: searchResultsResponse ? searchResultsResponse.message as OutputString : "Error searching for characters" as OutputString });
			return;
		}

		this.setState((old:ViewCharactersState):ViewCharactersState => { return { ...old, searchResults: searchResultsResponse.data.searchResults, searching: false }; });
	}

	selectSearchResult = (evt:SyntheticEvent) => {
		const ele = evt.currentTarget as HTMLDivElement; // This is the .character-display wrapper element
		if(!ele){ return; }

		const seId = ele.dataset.seid;
		if(!seId){ return; }

		let newChars:Character[] = this.state.newChars;

		if(ele.classList.contains("selected")){
			newChars = newChars.filter((z) => { return z.seId !== seId; });
		}else{
			const searchResult = this.state.searchResults?.find((z) => { return z.id === seId; });
			if(!searchResult){ return; }

			const thisNewChar:Character = manager.data.getDefaultCharacter();
			thisNewChar.seId = searchResult.id;
			thisNewChar.name = searchResult.name;
			thisNewChar.displayName = searchResult.name;

			newChars.push(thisNewChar);
		}

		this.setState((old:ViewCharactersState):ViewCharactersState => { return { ...old, newChars: newChars }; });
	}

	addCharacter = async(evt:SyntheticEvent) => {
		const ele = evt.currentTarget;
		const newChars:Character[] = [];

		for(const newChar of this.state.newChars){
			if(!this.findCharacter(newChar.seId)){

				if(!newChar.avatar){
					const avatarResult = await manager.request.send<GetCharacterAvatarRequest, GetCharacterAvatarResponse>(APIEndpoint.GET_AVATAR, { id: newChar.seId }); // eslint-disable-line no-await-in-loop

					if(!avatarResult || avatarResult.status !== APIStatus.SUCCESS){
						sysMsg.error({ title: "Error processing request", message: avatarResult ? avatarResult.message as OutputString : "Error looking up avatar for character" as OutputString });
					}else{
						newChar.avatar = avatarResult.data.avatar;
					}
				}

				newChar.progress = new CharacterProgress();
				newChars.push(newChar);
			}
		}

		const result = await manager.data.saveCharacters(newChars, true);
		manager.ga.characterCreated(result === null ? false : result);

		if(result){
			this.setState((old:ViewCharactersState):ViewCharactersState => { return { ...old, newChars: [] }; });
			const selected_chars = ele.closest(".search-wrapper")?.querySelectorAll<HTMLDivElement>(".character-display.selected");
			if(!selected_chars){ return; }
			selected_chars.forEach((e) => { e.classList.remove("selected"); });
		}
	}

	resetAllCharacters = () => { // Event
		manager.content.openModal(Modal.CONFIRMATION, {
			title: "Are you sure?",
			text: "This will reset all your characters data - This action is irreversible",
			confirm: { text: "Yes, Do it", action: () => { manager.data.resetCharacters(true); } },
			cancel: { text: "No, Go back" },
		});
	}

	resetAccountSettings = () => { // Event
		manager.content.openModal(Modal.CONFIRMATION, {
			title: "Are you sure?",
			text: "This will reset all your preferences - This action is irreversible",
			confirm: { text: "Yes, Do it", action: () => { manager.data.resetOptions(true); } },
			cancel: { text: "No, Go back" },
		});
	}

	resetAllData = () => { // Event
		manager.content.openModal(Modal.CONFIRMATION, {
			title: "Are you sure?",
			text: "This will reset all your data - This action is irreversible",
			confirm: { text: "Yes, Do it", action: () => { manager.data.resetAllData(true); } },
			cancel: { text: "No, Go back" },
		});
	}

	deleteAllCharacters = () => { // Event
		manager.content.openModal(Modal.CONFIRMATION, {
			title: "Are you sure?",
			text: "This will delete all your characters - This action is irreversible",
			confirm: { text: "Yes, Do it", action: () => { manager.data.deleteAllCharacters(true); } },
			cancel: { text: "No, Go back" },
		});
	}

	discardAnonymous = () => { // Event
		manager.content.openModal(Modal.CONFIRMATION, {
			title: "Are you sure?",
			text: "Are you sure you wish to discard? - This is irreversable and you will lose the option to merge later",
			confirm: {
				text: "Yes",
				action: () => {
					oldDataManager.discardAnonymous();
				},
			},
			cancel: { text: "No" },
		});
	}

	mergeAnonymous = (seId:string) => { // Event
		const characters = this.props.characters;
		const characterToMergeInto = characters.find((z) => { return z.seId === seId; });
		const anonChar = oldDataManager.getAnonymousChar();
		if(!characterToMergeInto || !anonChar){ return; }

		manager.content.openModal(Modal.CONFIRMATION, {
			title: "Are you sure?",
			text: "Are you sure you wish to merge anonymous into this character?",
			confirm: {
				text: "Yes",
				action: () => {
					const mergedChar = oldDataManager.mergeCharacter(characterToMergeInto, anonChar);
					if(mergedChar){
						manager.data.saveCharacters([mergedChar], true);
						// oldDataManager.discardAnonymous();
					}
				},
			},
			cancel: { text: "No" },
		});
	}

	importFromBackup = () => { // Event
		const fileInput = document.createElement("input");
		fileInput.type = "file";
		fileInput.name = "saveddata[]";
		fileInput.accept = ".json";
		fileInput.onchange = (evt:Event) => {
			const file = (evt.target as any).files[0];

			if(file){
				const fileReader = new FileReader();
				fileReader.onload = (ev:ProgressEvent<FileReader>) => {
					try{
						const fileData = JSON.parse(ev.target?.result as string);
						oldDataManager.restoreFromFileBackup(fileData);
						window.location.reload();
					}catch{
						log.debug("Invalid file?!");
					}
				};
				fileReader.readAsText(file);
			}else{
				log.debug("No/Invalid file?!");
			}
		};
		fileInput.click();
	}
}
export default connect(mapStateToProps)(Characters);
