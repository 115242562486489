//#region Imports
import { RelicGroup, RelicPart, RelicType, View } from "enums";

import ArmourAnemosAnemos from "views/relics/armour/anemos/anemos/ArmourAnemosAnemos";
import ArmourAnemosAntiquated from "views/relics/armour/anemos/antiquated/ArmourAnemosAntiquated";
import ArmourAnemosBase1 from "views/relics/armour/anemos/base-1/ArmourAnemosBase1";
import ArmourAnemosBase2 from "views/relics/armour/anemos/base-2/ArmourAnemosBase2";
import ArmourAnemosBase0 from "views/relics/armour/anemos/base/ArmourAnemosBase0";
import ArmourAnemosOverall from "views/relics/armour/anemos/overall/ArmourAnemosOverall";
import ArmourBladeBlade from "views/relics/armour/blade/blade/ArmourBladeBlade";
import ArmourBladeOverall from "views/relics/armour/blade/overall/ArmourBladeOverall";
import ArmourBozjanAugmentedBozjan from "views/relics/armour/bozjan/augmented-bozjan/ArmourBozjanAugmentedBozjan";
import ArmourBozjanBozjan from "views/relics/armour/bozjan/bozjan/ArmourBozjanBozjan";
import ArmourBozjanOverall from "views/relics/armour/bozjan/overall/ArmourBozjanOverall";
import ArmourElementalElemental1 from "views/relics/armour/elemental/elemental-1/ArmourElementalElemental1";
import ArmourElementalElemental2 from "views/relics/armour/elemental/elemental-2/ArmourElementalElemental2";
import ArmourElementalElemental from "views/relics/armour/elemental/elemental/ArmourElementalElemental";
import ArmourElementalOverall from "views/relics/armour/elemental/overall/ArmourElementalOverall";
import ArmourLawsOrderAugmentedLawsOrder from "views/relics/armour/laws-order/augmented-laws-order/ArmourLawsOrderAugmentedLawsOrder";
import ArmourLawsOrderLawsOrder from "views/relics/armour/laws-order/laws-order/ArmourLawsOrderLawsOrder";
import ArmourLawsOrderOverall from "views/relics/armour/laws-order/overall/ArmourLawsOrderOverall";
import ToolLucisLucis from "views/relics/tools/lucis/lucis/ToolLucisLucis";
import ToolLucisMastercraft from "views/relics/tools/lucis/mastercraft/ToolLucisMastercraft";
import ToolLucisOverall from "views/relics/tools/lucis/overall/ToolLucisOverall";
import ToolLucisSupra from "views/relics/tools/lucis/supra/ToolLucisSupra";
import ToolSkysteelAugmentedDragonsung from "views/relics/tools/skysteel/augmented-dragonsung/ToolSkysteelAugmentedDragonsung";
import ToolSkysteelBase from "views/relics/tools/skysteel/base-tool/ToolSkysteelBase";
import ToolSkysteelDragonsung from "views/relics/tools/skysteel/dragonsung/ToolSkysteelDragonsung";
import ToolSkysteelOverall from "views/relics/tools/skysteel/overall/ToolSkysteelOverall";
import ToolSkysteelSkybuilders from "views/relics/tools/skysteel/skybuilders/ToolSkysteelSkybuilders";
import ToolSkysteelSkysteel1 from "views/relics/tools/skysteel/skysteel-1/ToolSkysteelSkysteel1";
import ToolSkysteelSkysung from "views/relics/tools/skysteel/skysung/ToolSkysteelSkysung";
import ToolSplendorousAugmentedSplendorous from "views/relics/tools/splendorous/augmented-splendorous/ToolSplendorousAugmentedSplendorous";
import ToolSplendorousBrilliant from "views/relics/tools/splendorous/brilliant/ToolSplendorousBrilliant";
import ToolSplendorousChoraZoisCrystalline from "views/relics/tools/splendorous/chora-zois-crystalline/ToolSplendorousChoraZoisCrystalline";
import ToolSplendorousCrystalline from "views/relics/tools/splendorous/crystalline/ToolSplendorousCrystalline";
import ToolSplendorousLodestar from "views/relics/tools/splendorous/lodestar/ToolSplendorousLodestar";
import ToolSplendorousOverall from "views/relics/tools/splendorous/overall/ToolSplendorousOverall";
import ToolSplendorousSplendorous from "views/relics/tools/splendorous/splendorous/ToolSplendorousSplendorous";
import ToolSplendorousVrandticVisionarys from "views/relics/tools/splendorous/vrandtic-visionarys/ToolSplendorousVrandticVisionarys";
import WeaponAnimaAnima from "views/relics/weapons/anima/anima/WeaponAnimaAnima";
import WeaponAnimaAnimated from "views/relics/weapons/anima/animated/WeaponAnimaAnimated";
import WeaponAnimaAwoken from "views/relics/weapons/anima/awoken/WeaponAnimaAwoken";
import WeaponAnimaComplete from "views/relics/weapons/anima/complete/WeaponAnimaComplete";
import WeaponAnimaHyperconductive from "views/relics/weapons/anima/hyderconductive/WeaponAnimaHyperconductive";
import WeaponAnimaLux from "views/relics/weapons/anima/lux/WeaponAnimaLux";
import WeaponAnimaOverall from "views/relics/weapons/anima/overall/WeaponAnimaOverall";
import WeaponAnimaReconditioned from "views/relics/weapons/anima/reconditioned/WeaponAnimaReconditioned";
import WeaponAnimaSharpened from "views/relics/weapons/anima/sharpened/WeaponAnimaSharpened";
import WeaponEurekaAnemos from "views/relics/weapons/eureka/anemos/WeaponEurekaAnemos";
import WeaponEurekaAntiquated from "views/relics/weapons/eureka/antiquated/WeaponEurekaAntiquated";
import WeaponEurekaElemental from "views/relics/weapons/eureka/elemental/WeaponEurekaElemental";
import WeaponEurekaEureka from "views/relics/weapons/eureka/eureka/WeaponEurekaEureka";
import WeaponEurekaOverall from "views/relics/weapons/eureka/overall/WeaponEurekaOverall";
import WeaponEurekaPhyseos from "views/relics/weapons/eureka/physeos/WeaponEurekaPhyseos";
import WeaponEurekaPyros from "views/relics/weapons/eureka/pyros/WeaponEurekaPyros";
import WeaponMandervilleAmazingManderville from "views/relics/weapons/manderville/amazing-manderville/WeaponMandervilleAmazingManderville";
import WeaponMandervilleMajesticManderville from "views/relics/weapons/manderville/majestic-manderville/WeaponMandervilleMajesticManderville";
import WeaponMandervilleManderville from "views/relics/weapons/manderville/manderville/WeaponMandervilleManderville";
import WeaponMandervilleMandervillous from "views/relics/weapons/manderville/mandervillous/WeaponMandervilleMandervillous";
import WeaponMandervilleOverall from "views/relics/weapons/manderville/overall/WeaponMandervilleOverall";
import WeaponResistanceAugmentedLawsOrder from "views/relics/weapons/resistance/augmented-laws-order/WeaponResistanceAugmentedLawsOrder";
import WeaponResistanceAugmentedResistance from "views/relics/weapons/resistance/augmented-resistance/WeaponResistanceAugmentedResistance";
import WeaponResistanceBlade from "views/relics/weapons/resistance/blade/WeaponResistanceBlade";
import WeaponResistanceLawsOrder from "views/relics/weapons/resistance/laws-order/WeaponResistanceLawsOrder";
import WeaponResistanceOverall from "views/relics/weapons/resistance/overall/WeaponResistanceOverall";
import WeaponResistanceRecollection from "views/relics/weapons/resistance/recollection/WeaponResistanceRecollection";
import WeaponResistanceResistance from "views/relics/weapons/resistance/resistance/WeaponResistanceResistance";
import WeaponZodiacAnimus from "views/relics/weapons/zodiac/animus/WeaponZodiacAnimus";
import WeaponZodiacAtma from "views/relics/weapons/zodiac/atma/WeaponZodiacAtma";
import WeaponZodiacBaseWeapon from "views/relics/weapons/zodiac/base-weapon/WeaponZodiacBaseWeapon";
import WeaponZodiacNexus from "views/relics/weapons/zodiac/nexus/WeaponZodiacNexus";
import WeaponZodiacNovus from "views/relics/weapons/zodiac/novus/WeaponZodiacNovus";
import WeaponZodiacOverall from "views/relics/weapons/zodiac/overall/WeaponZodiacOverall";
import WeaponZodiacZenith from "views/relics/weapons/zodiac/zenith/WeaponZodiacZenith";
import WeaponZodiacZodiacBraves from "views/relics/weapons/zodiac/zodiac-braves/WeaponZodiacZodiacBraves";
import WeaponZodiacZodiacZeta from "views/relics/weapons/zodiac/zodiac-zeta/WeaponZodiacZodiacZeta";
//#endregion Imports

/* Reference - "view" structure
	Index:
		0 = Type eg. (relic_weapon, relic_armour, relic_tool)
		1 = Relic eg. (zodiac, anima, eureka, resistance / anemos, elemental, resistance / lucis, skysteel)
		2 = Part eg. (base_weapon, zenith, atma, animus, novus, nexus, zodiac_braves, zodiac_zeta)
*/
export function lookupRelicContent(view:View):JSX.Element|null{
	const viewArr = view.split("-");
	let component:JSX.Element|null = null;
	switch(viewArr[0]){
		default: component = null; break;
		case RelicType.WEAPON: component = lookupWeaponComponent(viewArr); break;
		case RelicType.TOOL: component = lookupToolComponent(viewArr); break;
		case RelicType.ARMOUR: component = lookupArmourComponent(viewArr); break;
	}
	return component;
}

function lookupWeaponComponent(relicToView:string[]):JSX.Element|null{
	let component:JSX.Element|null = null;
	switch(relicToView[1]){
		default: component = null; break;
		case RelicGroup.WEAPON_ZODIAC: component = lookupWeaponZodiacComponent(relicToView[2]); break;
		case RelicGroup.WEAPON_ANIMA: component = lookupWeaponAnimaComponent(relicToView[2]); break;
		case RelicGroup.WEAPON_EUREKA: component = lookupWeaponEurekaComponent(relicToView[2]); break;
		case RelicGroup.WEAPON_RESISTANCE: component = lookupWeaponResistanceComponent(relicToView[2]); break;
		case RelicGroup.WEAPON_MANDERVILLE: component = lookupWeaponMandervilleComponent(relicToView[2]); break;
	}
	return component;
}

function lookupToolComponent(relicToView:string[]):JSX.Element|null{
	let component:JSX.Element|null = null;
	switch(relicToView[1]){
		default: component = null; break;
		case RelicGroup.TOOL_LUCIS: component = lookupToolLucisComponent(relicToView[2]); break;
		case RelicGroup.TOOL_SKYSTEEL: component = lookupToolSkysteelComponent(relicToView[2]); break;
		case RelicGroup.TOOL_SPLENDOROUS: component = lookupToolSplendorousComponent(relicToView[2]); break;
	}
	return component;
}

function lookupArmourComponent(relicToView:string[]):JSX.Element|null{
	let component:JSX.Element|null = null;
	switch(relicToView[1]){
		default: component = null; break;
		case RelicGroup.ARMOUR_ANEMOS: component = lookupArmourAnemosComponent(relicToView[2]); break;
		case RelicGroup.ARMOUR_ELEMENTAL: component = lookupArmourElementalComponent(relicToView[2]); break;
		case RelicGroup.ARMOUR_BOZJAN: component = lookupArmourBozjanComponent(relicToView[2]); break;
		case RelicGroup.ARMOUR_LAWS_ORDER: component = lookupArmourLawsOrderComponent(relicToView[2]); break;
		case RelicGroup.ARMOUR_BLADES: component = lookupArmourBladeComponent(relicToView[2]); break;
	}
	return component;
}

function lookupWeaponZodiacComponent(part:string):JSX.Element|null{
	let component:JSX.Element|null = null;
	switch(part){
		default: component = null; break;
		case "overall": component = <WeaponZodiacOverall />; break;
		case RelicPart.WEAPON_BASE_WEAPON: component = <WeaponZodiacBaseWeapon />; break;
		case RelicPart.WEAPON_ZENITH: component = <WeaponZodiacZenith />; break;
		case RelicPart.WEAPON_ATMA: component = <WeaponZodiacAtma />; break;
		case RelicPart.WEAPON_ANIMUS: component = <WeaponZodiacAnimus />; break;
		case RelicPart.WEAPON_NOVUS: component = <WeaponZodiacNovus />; break;
		case RelicPart.WEAPON_NEXUS: component = <WeaponZodiacNexus />; break;
		case RelicPart.WEAPON_BRAVES: component = <WeaponZodiacZodiacBraves />; break;
		case RelicPart.WEAPON_ZETA: component = <WeaponZodiacZodiacZeta />; break;
	}
	return component;
}

function lookupWeaponAnimaComponent(part:string):JSX.Element|null{
	let component:JSX.Element|null = null;
	switch(part){
		default: component = null; break;
		case "overall": component = <WeaponAnimaOverall />; break;
		case RelicPart.WEAPON_ANIMATED: component = <WeaponAnimaAnimated />; break;
		case RelicPart.WEAPON_AWOKEN: component = <WeaponAnimaAwoken />; break;
		case RelicPart.WEAPON_ANIMA: component = <WeaponAnimaAnima />; break;
		case RelicPart.WEAPON_HYPERCONDUCTIVE: component = <WeaponAnimaHyperconductive />; break;
		case RelicPart.WEAPON_RECONDITIONED: component = <WeaponAnimaReconditioned />; break;
		case RelicPart.WEAPON_SHARPENED: component = <WeaponAnimaSharpened />; break;
		case RelicPart.WEAPON_COMPLETE: component = <WeaponAnimaComplete />; break;
		case RelicPart.WEAPON_LUX: component = <WeaponAnimaLux />; break;
	}
	return component;
}

function lookupWeaponEurekaComponent(part:string):JSX.Element|null{
	let component:JSX.Element|null = null;
	switch(part){
		default: component = null; break;
		case "overall": component = <WeaponEurekaOverall />; break;
		case RelicPart.WEAPON_ANTIQUATED: component = <WeaponEurekaAntiquated />; break;
		case RelicPart.WEAPON_ANEMOS: component = <WeaponEurekaAnemos />; break;
		case RelicPart.WEAPON_ELEMENTAL: component = <WeaponEurekaElemental />; break;
		case RelicPart.WEAPON_PYROS: component = <WeaponEurekaPyros />; break;
		case RelicPart.WEAPON_EUREKA: component = <WeaponEurekaEureka />; break;
		case RelicPart.WEAPON_PHYSEOS: component = <WeaponEurekaPhyseos />; break;
	}
	return component;
}

function lookupWeaponResistanceComponent(part:string):JSX.Element|null{
	let component:JSX.Element|null = null;
	switch(part){
		default: component = null; break;
		case "overall": component = <WeaponResistanceOverall />; break;
		case RelicPart.WEAPON_RESISTANCE: component = <WeaponResistanceResistance />; break;
		case RelicPart.WEAPON_AUGMENTED_RESISTANCE: component = <WeaponResistanceAugmentedResistance />; break;
		case RelicPart.WEAPON_RECOLLECTION: component = <WeaponResistanceRecollection />; break;
		case RelicPart.WEAPON_LAWS_ORDER: component = <WeaponResistanceLawsOrder />; break;
		case RelicPart.WEAPON_AUGMENTED_LAWS_ORDER: component = <WeaponResistanceAugmentedLawsOrder />; break;
		case RelicPart.WEAPON_BLADE: component = <WeaponResistanceBlade />; break;
	}
	return component;
}

function lookupWeaponMandervilleComponent(part:string):JSX.Element|null{
	let component:JSX.Element|null = null;
	switch(part){
		default: component = null; break;
		case "overall": component = <WeaponMandervilleOverall />; break;
		case RelicPart.WEAPON_MANDERVILLE: component = <WeaponMandervilleManderville />; break;
		case RelicPart.WEAPON_AMAZING_MANDERVILLE: component = <WeaponMandervilleAmazingManderville />; break;
		case RelicPart.WEAPON_MAJESTIC_MANDERVILLE: component = <WeaponMandervilleMajesticManderville />; break;
		case RelicPart.WEAPON_MANDERVILLOUS: component = <WeaponMandervilleMandervillous />; break;
	}
	return component;
}

function lookupToolLucisComponent(part:string):JSX.Element|null{
	let component:JSX.Element|null = null;
	switch(part){
		default: component = null; break;
		case "overall": component = <ToolLucisOverall />; break;
		case RelicPart.TOOL_MASTERCRAFT: component = <ToolLucisMastercraft />; break;
		case RelicPart.TOOL_SUPRA: component = <ToolLucisSupra />; break;
		case RelicPart.TOOL_LUCIS: component = <ToolLucisLucis />; break;
	}
	return component;
}

function lookupToolSkysteelComponent(part:string):JSX.Element|null{
	let component:JSX.Element|null = null;
	switch(part){
		default: component = null; break;
		case "overall": component = <ToolSkysteelOverall />; break;
		case RelicPart.TOOL_SKYSTEEL: component = <ToolSkysteelBase />; break;
		case RelicPart.TOOL_SKYSTEEL_1: component = <ToolSkysteelSkysteel1 />; break;
		case RelicPart.TOOL_DRAGONSUNG: component = <ToolSkysteelDragonsung />; break;
		case RelicPart.TOOL_AUGMENTED_DRAGONSUNG: component = <ToolSkysteelAugmentedDragonsung />; break;
		case RelicPart.TOOL_SKYSUNG: component = <ToolSkysteelSkysung />; break;
		case RelicPart.TOOL_SKYBUILDERS: component = <ToolSkysteelSkybuilders />; break;
	}
	return component;
}

function lookupToolSplendorousComponent(part:string):JSX.Element|null{
	let component:JSX.Element|null = null;
	switch(part){
		default: component = null; break;
		case "overall": component = <ToolSplendorousOverall />; break;
		case RelicPart.TOOL_SPLENDOROUS: component = <ToolSplendorousSplendorous />; break;
		case RelicPart.TOOL_AUGMENTED_SPLENDOROUS: component = <ToolSplendorousAugmentedSplendorous />; break;
		case RelicPart.TOOL_CRYSTALLINE: component = <ToolSplendorousCrystalline />; break;
		case RelicPart.TOOL_CHORA_ZOIS_CRYSTALLINE: component = <ToolSplendorousChoraZoisCrystalline />; break;
		case RelicPart.TOOL_BRILLIANT: component = <ToolSplendorousBrilliant />; break;
		case RelicPart.TOOL_VRANDTIC_VISIONARYS: component = <ToolSplendorousVrandticVisionarys />; break;
		case RelicPart.TOOL_LODESTAR: component = <ToolSplendorousLodestar />; break;
	}
	return component;
}

function lookupArmourAnemosComponent(part:string):JSX.Element|null{
	let component:JSX.Element|null = null;
	switch(part){
		default: component = null; break;
		case "overall": component = <ArmourAnemosOverall />; break;
		case RelicPart.ARMOUR_ANTIQUATED: component = <ArmourAnemosAntiquated />; break;
		case RelicPart.ARMOUR_BASE_0: component = <ArmourAnemosBase0 />; break;
		case RelicPart.ARMOUR_BASE_1: component = <ArmourAnemosBase1 />; break;
		case RelicPart.ARMOUR_BASE_2: component = <ArmourAnemosBase2 />; break;
		case RelicPart.ARMOUR_ANEMOS: component = <ArmourAnemosAnemos />; break;
	}
	return component;
}

function lookupArmourElementalComponent(part:string):JSX.Element|null{
	let component:JSX.Element|null = null;
	switch(part){
		default: component = null; break;
		case "overall": component = <ArmourElementalOverall />; break;
		case RelicPart.ARMOUR_ELEMENTAL: component = <ArmourElementalElemental />; break;
		case RelicPart.ARMOUR_ELEMENTAL_1: component = <ArmourElementalElemental1 />; break;
		case RelicPart.ARMOUR_ELEMENTAL_2: component = <ArmourElementalElemental2 />; break;
	}
	return component;
}

function lookupArmourBozjanComponent(part:string):JSX.Element|null{
	let component:JSX.Element|null = null;
	switch(part){
		default: component = null; break;
		case "overall": component = <ArmourBozjanOverall />; break;
		case RelicPart.ARMOUR_BOZJAN: component = <ArmourBozjanBozjan />; break;
		case RelicPart.ARMOUR_AUGMENTED_BOZJAN: component = <ArmourBozjanAugmentedBozjan />; break;
	}
	return component;
}

function lookupArmourLawsOrderComponent(part:string):JSX.Element|null{
	let component:JSX.Element|null = null;
	switch(part){
		default: component = null; break;
		case "overall": component = <ArmourLawsOrderOverall />; break;
		case RelicPart.ARMOUR_LAWS_ORDER: component = <ArmourLawsOrderLawsOrder />; break;
		case RelicPart.ARMOUR_AUGMENTED_LAWS_ORDER: component = <ArmourLawsOrderAugmentedLawsOrder />; break;
	}
	return component;
}

function lookupArmourBladeComponent(part:string):JSX.Element|null{
	let component:JSX.Element|null = null;
	switch(part){
		default: component = null; break;
		case "overall": component = <ArmourBladeOverall />; break;
		case RelicPart.ARMOUR_BLADES: component = <ArmourBladeBlade />; break;
	}
	return component;
}
