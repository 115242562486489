/* eslint-disable max-len */
//#region Imports
import { jobLists } from "character";
import { Bait, Currency, Dungeon, Enemy, EorzeaDataType, Fate, Fish, Fishing, GatherMethod, Item, Job, Leve, MapLocation, Market, Quest, Raid, RelicGroup, RelicPart, RelicStep, RelicTask, RelicType, Shop, Weather } from "enums";
import { ItemInfo } from "types";
//#endregion Imports

/* Template Item Entry at the end of the file */

export const items: { [key in Item]: ItemInfo } = {
	[Item.UNKNOWN]: {
		name: Item.UNKNOWN, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {},
		used: [],
	},
	[Item.AEOLIAN_SCIMITAR]: {
		name: Item.AEOLIAN_SCIMITAR, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.AEOLIAN_SCIMITAR, qtyMade: 1, crafter: Job.BSM, level: 50, lodestoneID: "07f6b6e8be5",
					itemsRequired: [
						{ item: Item.BLUNT_AEOLIAN_SCIMITAR, qty: 1 },
						{ item: Item.DARKSTEEL_NUGGET, qty: 1 },
						{ item: Item.BASILISK_WHETSTONE, qty: 1 },
						{ item: Item.FIRE_CRYSTAL, qty: 3 },
						{ item: Item.EARTH_CRYSTAL, qty: 2 },
					],
				},
			],
			markets: { [Market.UNIVERSALIS]: 1670 },
		},
		used: [
			{ relic: [RelicType.WEAPON, RelicGroup.WEAPON_ZODIAC, RelicPart.WEAPON_BASE_WEAPON, RelicStep.CLASS_WEAPON, RelicTask.TASK_1], jobs: [Job.PLD], qtyPerJob: 1 },
		],
	},
	[Item.BARBARIANS_BARDICHE]: {
		name: Item.BARBARIANS_BARDICHE, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.BARBARIANS_BARDICHE, qtyMade: 1, crafter: Job.BSM, level: 50, lodestoneID: "e0a074085b1",
					itemsRequired: [
						{ item: Item.BLOODY_BARDICHE_HEAD, qty: 1 },
						{ item: Item.DARKSTEEL_NUGGET, qty: 1 },
						{ item: Item.BASILISK_WHETSTONE, qty: 1 },
						{ item: Item.FIRE_CRYSTAL, qty: 3 },
						{ item: Item.EARTH_CRYSTAL, qty: 2 },
					],
				},
			],
			markets: { [Market.UNIVERSALIS]: 1810 },
		},
		used: [
			{ relic: [RelicType.WEAPON, RelicGroup.WEAPON_ZODIAC, RelicPart.WEAPON_BASE_WEAPON, RelicStep.CLASS_WEAPON, RelicTask.TASK_1], jobs: [Job.WAR], qtyPerJob: 1 },
		],
	},
	[Item.MADMANS_WHISPERING_ROD]: {
		name: Item.MADMANS_WHISPERING_ROD, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.MADMANS_WHISPERING_ROD, qtyMade: 1, crafter: Job.ALC, level: 50, lodestoneID: "3a5dcd108d9",
					itemsRequired: [
						{ item: Item.THAVNAIRIAN_MISTLETOE, qty: 1 },
						{ item: Item.GROWTH_FORMULA_DELTA, qty: 1 },
						{ item: Item.VAMPIRE_PLANT, qty: 1 },
						{ item: Item.WATER_CRYSTAL, qty: 3 },
						{ item: Item.LIGHTNING_CRYSTAL, qty: 2 },
					],
				},
			],
			markets: { [Market.UNIVERSALIS]: 2049 },
		},
		used: [
			{ relic: [RelicType.WEAPON, RelicGroup.WEAPON_ZODIAC, RelicPart.WEAPON_BASE_WEAPON, RelicStep.CLASS_WEAPON, RelicTask.TASK_1], jobs: [Job.WHM], qtyPerJob: 1 },
		],
	},
	[Item.ERUDITES_PICATRIX_OF_HEALING]: {
		name: Item.ERUDITES_PICATRIX_OF_HEALING, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.ERUDITES_PICATRIX_OF_HEALING, qtyMade: 1, crafter: Job.ALC, level: 50, lodestoneID: "49e7d5dfab4",
					itemsRequired: [
						{ item: Item.BLOODY_GRIMOIRE_BINDING, qty: 1 },
						{ item: Item.ENCHANTED_GOLD_INK, qty: 1 },
						{ item: Item.WATER_CRYSTAL, qty: 3 },
						{ item: Item.LIGHTNING_CRYSTAL, qty: 2 },
					],
				},
			],
			markets: { [Market.UNIVERSALIS]: 2201 },
		},
		used: [
			{ relic: [RelicType.WEAPON, RelicGroup.WEAPON_ZODIAC, RelicPart.WEAPON_BASE_WEAPON, RelicStep.CLASS_WEAPON, RelicTask.TASK_1], jobs: [Job.SCH], qtyPerJob: 1 },
		],
	},
	[Item.WILDLINGS_CESTI]: {
		name: Item.WILDLINGS_CESTI, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.WILDLINGS_CESTI, qtyMade: 1, crafter: Job.LTW, level: 50, lodestoneID: "7495adcb749",
					itemsRequired: [
						{ item: Item.BLOODY_CESTI_COVERS, qty: 1 },
						{ item: Item.DARKSTEEL_NUGGET, qty: 1 },
						{ item: Item.EARTH_CRYSTAL, qty: 3 },
						{ item: Item.WIND_CRYSTAL, qty: 2 },
					],
				},
			],
			markets: { [Market.UNIVERSALIS]: 1740 },
		},
		used: [
			{ relic: [RelicType.WEAPON, RelicGroup.WEAPON_ZODIAC, RelicPart.WEAPON_BASE_WEAPON, RelicStep.CLASS_WEAPON, RelicTask.TASK_1], jobs: [Job.MNK], qtyPerJob: 1 },
		],
	},
	[Item.CHAMPIONS_LANCE]: {
		name: Item.CHAMPIONS_LANCE, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.CHAMPIONS_LANCE, qtyMade: 1, crafter: Job.CRP, level: 50, lodestoneID: "ae1cee7186f",
					itemsRequired: [
						{ item: Item.BLOODY_LANCE_HEAD, qty: 1 },
						{ item: Item.SPRUCE_LUMBER, qty: 1 },
						{ item: Item.WIND_CRYSTAL, qty: 3 },
						{ item: Item.ICE_CRYSTAL, qty: 2 },
					],
				},
			],
			markets: { [Market.UNIVERSALIS]: 1879 },
		},
		used: [
			{ relic: [RelicType.WEAPON, RelicGroup.WEAPON_ZODIAC, RelicPart.WEAPON_BASE_WEAPON, RelicStep.CLASS_WEAPON, RelicTask.TASK_1], jobs: [Job.DRG], qtyPerJob: 1 },
		],
	},
	[Item.VAMPERS_KNIVES]: {
		name: Item.VAMPERS_KNIVES, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.VAMPERS_KNIVES, qtyMade: 1, crafter: Job.ALC, level: 50, lodestoneID: "6ba72635c64",
					itemsRequired: [
						{ item: Item.BLOODY_KNIFE_BLADES, qty: 1 },
						{ item: Item.POTENT_SLEEPING_POTION, qty: 1 },
						{ item: Item.ROSEWOOD_LUMBER, qty: 1 },
						{ item: Item.WATER_CRYSTAL, qty: 3 },
						{ item: Item.LIGHTNING_CRYSTAL, qty: 2 },
					],
				},
			],
			markets: { [Market.UNIVERSALIS]: 9224 },
		},
		used: [
			{ relic: [RelicType.WEAPON, RelicGroup.WEAPON_ZODIAC, RelicPart.WEAPON_BASE_WEAPON, RelicStep.CLASS_WEAPON, RelicTask.TASK_1], jobs: [Job.NIN], qtyPerJob: 1 },
		],
	},
	[Item.LONGARMS_COMPOSITE_BOW]: {
		name: Item.LONGARMS_COMPOSITE_BOW, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.LONGARMS_COMPOSITE_BOW, qtyMade: 1, crafter: Job.CRP, level: 40, lodestoneID: "76db26731a6",
					itemsRequired: [
						{ item: Item.BLOODY_BOW_RIM, qty: 1 },
						{ item: Item.SPRUCE_LUMBER, qty: 1 },
						{ item: Item.WIND_CRYSTAL, qty: 3 },
						{ item: Item.ICE_CRYSTAL, qty: 2 },
					],
				},
			],
			markets: { [Market.UNIVERSALIS]: 1949 },
		},
		used: [
			{ relic: [RelicType.WEAPON, RelicGroup.WEAPON_ZODIAC, RelicPart.WEAPON_BASE_WEAPON, RelicStep.CLASS_WEAPON, RelicTask.TASK_1], jobs: [Job.BRD], qtyPerJob: 1 },
		],
	},
	[Item.SANGUINE_SCEPTER]: {
		name: Item.SANGUINE_SCEPTER, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.SANGUINE_SCEPTER, qtyMade: 1, crafter: Job.GSM, level: 50, lodestoneID: "112a8f97e77",
					itemsRequired: [
						{ item: Item.PINPRICK_PEBBLE, qty: 1 },
						{ item: Item.DARKSTEEL_NUGGET, qty: 1 },
						{ item: Item.WIND_CRYSTAL, qty: 3 },
						{ item: Item.FIRE_CRYSTAL, qty: 2 },
					],
				},
			],
			markets: { [Market.UNIVERSALIS]: 2102 },
		},
		used: [
			{ relic: [RelicType.WEAPON, RelicGroup.WEAPON_ZODIAC, RelicPart.WEAPON_BASE_WEAPON, RelicStep.CLASS_WEAPON, RelicTask.TASK_1], jobs: [Job.BLM], qtyPerJob: 1 },
		],
	},
	[Item.ERUDITES_PICATRIX_OF_CASTING]: {
		name: Item.ERUDITES_PICATRIX_OF_CASTING, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.ERUDITES_PICATRIX_OF_CASTING, qtyMade: 1, crafter: Job.ALC, level: 50, lodestoneID: "66cd94ee863",
					itemsRequired: [
						{ item: Item.BLOODY_GRIMOIRE_BINDING, qty: 1 },
						{ item: Item.ENCHANTED_GOLD_INK, qty: 1 },
						{ item: Item.WATER_CRYSTAL, qty: 3 },
						{ item: Item.LIGHTNING_CRYSTAL, qty: 2 },
					],
				},
			],
			markets: { [Market.UNIVERSALIS]: 2202 },
		},
		used: [
			{ relic: [RelicType.WEAPON, RelicGroup.WEAPON_ZODIAC, RelicPart.WEAPON_BASE_WEAPON, RelicStep.CLASS_WEAPON, RelicTask.TASK_1], jobs: [Job.SMN], qtyPerJob: 1 },
		],
	},
	[Item.BATTLEDANCE_MATERIA_III]: {
		name: Item.BATTLEDANCE_MATERIA_III, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { markets: { [Market.UNIVERSALIS]: 5681 }, extraction: true },
		used: [
			{ relic: [RelicType.WEAPON, RelicGroup.WEAPON_ZODIAC, RelicPart.WEAPON_BASE_WEAPON, RelicStep.CLASS_WEAPON, RelicTask.TASK_2], jobs: [Job.PLD, Job.WAR], qtyPerJob: 2 },
		],
	},
	[Item.QUICKTONGUE_MATERIA_III]: {
		name: Item.QUICKTONGUE_MATERIA_III, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { markets: { [Market.UNIVERSALIS]: 5721 }, extraction: true },
		used: [
			{ relic: [RelicType.WEAPON, RelicGroup.WEAPON_ZODIAC, RelicPart.WEAPON_BASE_WEAPON, RelicStep.CLASS_WEAPON, RelicTask.TASK_2], jobs: [Job.WHM, Job.SCH], qtyPerJob: 2 },
		],
	},
	[Item.SAVAGE_AIM_MATERIA_III]: {
		name: Item.SAVAGE_AIM_MATERIA_III, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { markets: { [Market.UNIVERSALIS]: 5671 }, extraction: true },
		used: [
			{ relic: [RelicType.WEAPON, RelicGroup.WEAPON_ZODIAC, RelicPart.WEAPON_BASE_WEAPON, RelicStep.CLASS_WEAPON, RelicTask.TASK_2], jobs: [Job.MNK, Job.DRG], qtyPerJob: 2 },
		],
	},
	[Item.HEAVENS_EYE_MATERIA_III]: {
		name: Item.HEAVENS_EYE_MATERIA_III, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { markets: { [Market.UNIVERSALIS]: 5666 }, extraction: true },
		used: [
			{ relic: [RelicType.WEAPON, RelicGroup.WEAPON_ZODIAC, RelicPart.WEAPON_BASE_WEAPON, RelicStep.CLASS_WEAPON, RelicTask.TASK_2], jobs: [Job.NIN, Job.BRD], qtyPerJob: 2 },
		],
	},
	[Item.SAVAGE_MIGHT_MATERIA_III]: {
		name: Item.SAVAGE_MIGHT_MATERIA_III, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { markets: { [Market.UNIVERSALIS]: 5676 }, extraction: true },
		used: [
			{ relic: [RelicType.WEAPON, RelicGroup.WEAPON_ZODIAC, RelicPart.WEAPON_BASE_WEAPON, RelicStep.CLASS_WEAPON, RelicTask.TASK_2], jobs: [Job.BLM, Job.SMN], qtyPerJob: 2 },
		],
	},
	[Item.QUENCHING_OIL]: {
		name: Item.QUENCHING_OIL, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { shops: [{ shop: Shop.AURIANA, cost: [{ price: 15, currency: Currency.POETICS }] }, { shop: Shop.HISMENA, cost: [{ price: 15, currency: Currency.POETICS }] }, { shop: Shop.ROWENA_REP_LIMSA, cost: [{ price: 15, currency: Currency.POETICS }] }, { shop: Shop.ROWENA_REP_UlDAH, cost: [{ price: 15, currency: Currency.POETICS }] }, { shop: Shop.ROWENA_REP_GRIDANIA, cost: [{ price: 15, currency: Currency.POETICS }] }, { shop: Shop.ROWENA_REP_FOUNDATION, cost: [{ price: 15, currency: Currency.POETICS }] }] },
		used: [
			{ relic: [RelicType.WEAPON, RelicGroup.WEAPON_ZODIAC, RelicPart.WEAPON_BASE_WEAPON, RelicStep.OIL, RelicTask.TASK_1], jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC], qtyPerJob: 1 },
		],
	},
	[Item.THAVNAIRIAN_MIST]: {
		name: Item.THAVNAIRIAN_MIST, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { shops: [{ shop: Shop.AURIANA, cost: [{ price: 15, currency: Currency.POETICS }] }, { shop: Shop.HISMENA, cost: [{ price: 15, currency: Currency.POETICS }] }, { shop: Shop.ROWENA_REP_LIMSA, cost: [{ price: 15, currency: Currency.POETICS }] }, { shop: Shop.ROWENA_REP_UlDAH, cost: [{ price: 15, currency: Currency.POETICS }] }, { shop: Shop.ROWENA_REP_GRIDANIA, cost: [{ price: 15, currency: Currency.POETICS }] }, { shop: Shop.ROWENA_REP_FOUNDATION, cost: [{ price: 15, currency: Currency.POETICS }] }] },
		used: [
			{ relic: [RelicType.WEAPON, RelicGroup.WEAPON_ZODIAC, RelicPart.WEAPON_ZENITH, RelicStep.MIST, RelicTask.TASK_1], jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC], qtyPerJob: 3 },
		],
	},
	[Item.ATMA_OF_THE_LION]: {
		name: Item.ATMA_OF_THE_LION, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { duties: { fates: [MapLocation.OUTER_LA_NOSCEA] } },
		used: [
			{ relic: [RelicType.WEAPON, RelicGroup.WEAPON_ZODIAC, RelicPart.WEAPON_ATMA, RelicStep.ATMAS, RelicTask.TASK_1], jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC], qtyPerJob: 1 },
		],
	},
	[Item.ATMA_OF_THE_WATERBEARER]: {
		name: Item.ATMA_OF_THE_WATERBEARER, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { duties: { fates: [MapLocation.UPPER_LA_NOSCEA] } },
		used: [
			{ relic: [RelicType.WEAPON, RelicGroup.WEAPON_ZODIAC, RelicPart.WEAPON_ATMA, RelicStep.ATMAS, RelicTask.TASK_2], jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC], qtyPerJob: 1 },
		],
	},
	[Item.ATMA_OF_THE_RAM]: {
		name: Item.ATMA_OF_THE_RAM, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { duties: { fates: [MapLocation.MIDDLE_LA_NOSCEA] } },
		used: [
			{ relic: [RelicType.WEAPON, RelicGroup.WEAPON_ZODIAC, RelicPart.WEAPON_ATMA, RelicStep.ATMAS, RelicTask.TASK_3], jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC], qtyPerJob: 1 },
		],
	},
	[Item.ATMA_OF_THE_CRAB]: {
		name: Item.ATMA_OF_THE_CRAB, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { duties: { fates: [MapLocation.WESTERN_LA_NOSCEA] } },
		used: [
			{ relic: [RelicType.WEAPON, RelicGroup.WEAPON_ZODIAC, RelicPart.WEAPON_ATMA, RelicStep.ATMAS, RelicTask.TASK_4], jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC], qtyPerJob: 1 },
		],
	},
	[Item.ATMA_OF_THE_FISH]: {
		name: Item.ATMA_OF_THE_FISH, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { duties: { fates: [MapLocation.LOWER_LA_NOSCEA] } },
		used: [
			{ relic: [RelicType.WEAPON, RelicGroup.WEAPON_ZODIAC, RelicPart.WEAPON_ATMA, RelicStep.ATMAS, RelicTask.TASK_5], jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC], qtyPerJob: 1 },
		],
	},
	[Item.ATMA_OF_THE_BULL]: {
		name: Item.ATMA_OF_THE_BULL, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { duties: { fates: [MapLocation.EASTERN_THANALAN] } },
		used: [
			{ relic: [RelicType.WEAPON, RelicGroup.WEAPON_ZODIAC, RelicPart.WEAPON_ATMA, RelicStep.ATMAS, RelicTask.TASK_6], jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC], qtyPerJob: 1 },
		],
	},
	[Item.ATMA_OF_THE_SCALES]: {
		name: Item.ATMA_OF_THE_SCALES, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { duties: { fates: [MapLocation.CENTRAL_THANALAN] } },
		used: [
			{ relic: [RelicType.WEAPON, RelicGroup.WEAPON_ZODIAC, RelicPart.WEAPON_ATMA, RelicStep.ATMAS, RelicTask.TASK_7], jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC], qtyPerJob: 1 },
		],
	},
	[Item.ATMA_OF_THE_TWINS]: {
		name: Item.ATMA_OF_THE_TWINS, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { duties: { fates: [MapLocation.WESTERN_THANALAN] } },
		used: [
			{ relic: [RelicType.WEAPON, RelicGroup.WEAPON_ZODIAC, RelicPart.WEAPON_ATMA, RelicStep.ATMAS, RelicTask.TASK_8], jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC], qtyPerJob: 1 },
		],
	},
	[Item.ATMA_OF_THE_SCORPION]: {
		name: Item.ATMA_OF_THE_SCORPION, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { duties: { fates: [MapLocation.SOUTHERN_THANALAN] } },
		used: [
			{ relic: [RelicType.WEAPON, RelicGroup.WEAPON_ZODIAC, RelicPart.WEAPON_ATMA, RelicStep.ATMAS, RelicTask.TASK_9], jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC], qtyPerJob: 1 },
		],
	},
	[Item.ATMA_OF_THE_ARCHER]: {
		name: Item.ATMA_OF_THE_ARCHER, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { duties: { fates: [MapLocation.NORTH_SHROUD] } },
		used: [
			{ relic: [RelicType.WEAPON, RelicGroup.WEAPON_ZODIAC, RelicPart.WEAPON_ATMA, RelicStep.ATMAS, RelicTask.TASK_10], jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC], qtyPerJob: 1 },
		],
	},
	[Item.ATMA_OF_THE_GOAT]: {
		name: Item.ATMA_OF_THE_GOAT, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { duties: { fates: [MapLocation.EAST_SHROUD] } },
		used: [
			{ relic: [RelicType.WEAPON, RelicGroup.WEAPON_ZODIAC, RelicPart.WEAPON_ATMA, RelicStep.ATMAS, RelicTask.TASK_11], jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC], qtyPerJob: 1 },
		],
	},
	[Item.ATMA_OF_THE_MAIDEN]: {
		name: Item.ATMA_OF_THE_MAIDEN, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { duties: { fates: [MapLocation.CENTRAL_SHROUD] } },
		used: [
			{ relic: [RelicType.WEAPON, RelicGroup.WEAPON_ZODIAC, RelicPart.WEAPON_ATMA, RelicStep.ATMAS, RelicTask.TASK_12], jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC], qtyPerJob: 1 },
		],
	},
	[Item.SUPERIOR_ENCHANTED_INK]: {
		name: Item.SUPERIOR_ENCHANTED_INK, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { shops: [{ shop: Shop.AURIANA, cost: [{ price: 25, currency: Currency.POETICS }] }, { shop: Shop.HISMENA, cost: [{ price: 25, currency: Currency.POETICS }] }, { shop: Shop.ROWENA_REP_LIMSA, cost: [{ price: 25, currency: Currency.POETICS }] }, { shop: Shop.ROWENA_REP_UlDAH, cost: [{ price: 25, currency: Currency.POETICS }] }, { shop: Shop.ROWENA_REP_GRIDANIA, cost: [{ price: 25, currency: Currency.POETICS }] }, { shop: Shop.ROWENA_REP_FOUNDATION, cost: [{ price: 25, currency: Currency.POETICS }] }] },
		used: [
			{ relic: [RelicType.WEAPON, RelicGroup.WEAPON_ZODIAC, RelicPart.WEAPON_NOVUS, RelicStep.SCROLL, RelicTask.TASK_1], jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC], qtyPerJob: 3 },
		],
	},
	[Item.ALEXANDRITE]: {
		name: Item.ALEXANDRITE, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { other: [{ element: "p", text: "Mysterious Maps - These can be obtained from Auriana in Mor Dhona, or from the daily quest 'Morbid Motivation' also in Mor Dhona" }, { element: "p", text: "ARR FATEs (Must have Relic equipped)" }, { element: "p", text: "Bought with Allied Seals" }] },
		used: [
			{ relic: [RelicType.WEAPON, RelicGroup.WEAPON_ZODIAC, RelicPart.WEAPON_NOVUS, RelicStep.ALEXANDRITE, RelicTask.TASK_1], jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC], qtyPerJob: 75 },
		],
	},
	[Item.LIGHT]: {
		name: Item.LIGHT, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { other: [{ element: "p", text: "Gather 'Light' from various ARR activites (ARR Dungeons, ARR Trials, ARR FATEs, Treasure Hunt, Alexandrite Maps, The Wolves' Den and Frontlines)" }] },
		used: [
			{ relic: [RelicType.WEAPON, RelicGroup.WEAPON_ZODIAC, RelicPart.WEAPON_NEXUS, RelicStep.LIGHT, RelicTask.TASK_1], jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC], qtyPerJob: 2000 },
		],
	},
	[Item.BOMBARD_CORE]: {
		name: Item.BOMBARD_CORE, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { shops: [{ shop: Shop.SERPENT_QUARTERMASTER, cost: [{ price: 20000, currency: Currency.COMPANY_SEAL }] }, { shop: Shop.STORM_QUARTERMASTER, cost: [{ price: 20000, currency: Currency.COMPANY_SEAL }] }, { shop: Shop.FLAME_QUARTERMASTER, cost: [{ price: 20000, currency: Currency.COMPANY_SEAL }] }] },
		used: [
			{ relic: [RelicType.WEAPON, RelicGroup.WEAPON_ZODIAC, RelicPart.WEAPON_BRAVES, RelicStep.SKYLIGHT, RelicTask.TASK_1], jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC], qtyPerJob: 1 },
			{ relic: [RelicType.WEAPON, RelicGroup.WEAPON_ZODIAC, RelicPart.WEAPON_BRAVES, RelicStep.ZODIUM, RelicTask.TASK_8], jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC], qtyPerJob: 1 },
			{ relic: [RelicType.WEAPON, RelicGroup.WEAPON_ZODIAC, RelicPart.WEAPON_BRAVES, RelicStep.FLAWLESS_ALEXANDRITE, RelicTask.TASK_1], jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC], qtyPerJob: 1 },
			{ relic: [RelicType.WEAPON, RelicGroup.WEAPON_ZODIAC, RelicPart.WEAPON_BRAVES, RelicStep.BRAVES_SCROLL, RelicTask.TASK_7], jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC], qtyPerJob: 1 },
		],
	},
	[Item.BRONZE_LAKE_CRYSTAL]: {
		name: Item.BRONZE_LAKE_CRYSTAL, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { shops: [{ shop: Shop.JUNKMONGER, cost: [{ price: 100000, currency: Currency.GIL }] }] },
		used: [
			{ relic: [RelicType.WEAPON, RelicGroup.WEAPON_ZODIAC, RelicPart.WEAPON_BRAVES, RelicStep.SKYLIGHT, RelicTask.TASK_2], jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC], qtyPerJob: 1 },
		],
	},
	[Item.SACRED_SPRING_WATER]: {
		name: Item.SACRED_SPRING_WATER, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { shops: [{ shop: Shop.AURIANA, cost: [{ price: 200, currency: Currency.POETICS }] }, { shop: Shop.HISMENA, cost: [{ price: 200, currency: Currency.POETICS }] }, { shop: Shop.ROWENA_REP_LIMSA, cost: [{ price: 200, currency: Currency.POETICS }] }, { shop: Shop.ROWENA_REP_UlDAH, cost: [{ price: 200, currency: Currency.POETICS }] }, { shop: Shop.ROWENA_REP_GRIDANIA, cost: [{ price: 200, currency: Currency.POETICS }] }, { shop: Shop.ROWENA_REP_FOUNDATION, cost: [{ price: 200, currency: Currency.POETICS }] }] },
		used: [
			{ relic: [RelicType.WEAPON, RelicGroup.WEAPON_ZODIAC, RelicPart.WEAPON_BRAVES, RelicStep.SKYLIGHT, RelicTask.TASK_3], jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC], qtyPerJob: 1 },
			{ relic: [RelicType.WEAPON, RelicGroup.WEAPON_ZODIAC, RelicPart.WEAPON_BRAVES, RelicStep.ZODIUM, RelicTask.TASK_9], jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC], qtyPerJob: 1 },
			{ relic: [RelicType.WEAPON, RelicGroup.WEAPON_ZODIAC, RelicPart.WEAPON_BRAVES, RelicStep.FLAWLESS_ALEXANDRITE, RelicTask.TASK_3], jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC], qtyPerJob: 1 },
			{ relic: [RelicType.WEAPON, RelicGroup.WEAPON_ZODIAC, RelicPart.WEAPON_BRAVES, RelicStep.BRAVES_SCROLL, RelicTask.TASK_9], jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC], qtyPerJob: 1 },
		],
	},
	[Item.HORN_OF_THE_BEAST]: {
		name: Item.HORN_OF_THE_BEAST, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { duties: { dungeons: [Dungeon.DZEMAEL_DARKHOLD] } },
		used: [
			{ relic: [RelicType.WEAPON, RelicGroup.WEAPON_ZODIAC, RelicPart.WEAPON_BRAVES, RelicStep.SKYLIGHT, RelicTask.TASK_4], jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC], qtyPerJob: 1 },
		],
	},
	[Item.GOBMACHINE_BANGPLATE]: {
		name: Item.GOBMACHINE_BANGPLATE, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { duties: { dungeons: [Dungeon.BRAYFLOX_HARD] } },
		used: [
			{ relic: [RelicType.WEAPON, RelicGroup.WEAPON_ZODIAC, RelicPart.WEAPON_BRAVES, RelicStep.SKYLIGHT, RelicTask.TASK_5], jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC], qtyPerJob: 1 },
		],
	},
	[Item.NARASIMHA_HIDE]: {
		name: Item.NARASIMHA_HIDE, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { duties: { dungeons: [Dungeon.HALATALI_HARD] } },
		used: [
			{ relic: [RelicType.WEAPON, RelicGroup.WEAPON_ZODIAC, RelicPart.WEAPON_BRAVES, RelicStep.SKYLIGHT, RelicTask.TASK_6], jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC], qtyPerJob: 1 },
		],
	},
	[Item.SICKLE_FANG]: {
		name: Item.SICKLE_FANG, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { duties: { dungeons: [Dungeon.SNOWCLOAK] } },
		used: [
			{ relic: [RelicType.WEAPON, RelicGroup.WEAPON_ZODIAC, RelicPart.WEAPON_BRAVES, RelicStep.SKYLIGHT, RelicTask.TASK_7], jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC], qtyPerJob: 1 },
		],
	},
	[Item.FURNACE_RING]: { // Obtained should match between normal and HQ versions
		name: Item.FURNACE_RING, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.FURNACE_RING, qtyMade: 1, crafter: Job.GSM, level: 50, lodestoneID: "9130d6abd95",
					itemsRequired: [
						{ item: Item.AGED_EYE_OF_FIRE, qty: 1 },
						{ item: Item.BELAHDIAN_SILVER, qty: 1 },
						{ item: Item.BASILISK_WHETSTONE, qty: 1 },
						{ item: Item.WIND_CLUSTER, qty: 2 },
						{ item: Item.FIRE_CLUSTER, qty: 1 },
					],
				},
			],
			markets: { [Market.UNIVERSALIS]: 9510 },
		},
		used: [],
	},
	[Item.FURNACE_RING_HQ]: { // Obtained should match between normal and HQ versions
		name: Item.FURNACE_RING_HQ, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.FURNACE_RING_HQ, qtyMade: 1, crafter: Job.GSM, level: 50, lodestoneID: "9130d6abd95",
					itemsRequired: [
						{ item: Item.AGED_EYE_OF_FIRE, qty: 1 },
						{ item: Item.BELAHDIAN_SILVER, qty: 1 },
						{ item: Item.BASILISK_WHETSTONE, qty: 1 },
						{ item: Item.WIND_CLUSTER, qty: 2 },
						{ item: Item.FIRE_CLUSTER, qty: 1 },
					],
				},
			],
			markets: { [Market.UNIVERSALIS]: 9510 },
		},
		used: [
			{ relic: [RelicType.WEAPON, RelicGroup.WEAPON_ZODIAC, RelicPart.WEAPON_BRAVES, RelicStep.SKYLIGHT, RelicTask.TASK_8], jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC], qtyPerJob: 1 },
		],
	},
	[Item.PERFECT_FIREWOOD]: { // Obtained should match between normal and HQ versions
		name: Item.PERFECT_FIREWOOD, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.PERFECT_FIREWOOD, qtyMade: 1, crafter: Job.CRP, level: 50, lodestoneID: "6dc973f7e54",
					itemsRequired: [
						{ item: Item.AGED_SPEAR_SHAFT, qty: 1 },
						{ item: Item.CYPRESS_LOG, qty: 2 },
						{ item: Item.GRENADE_ASH, qty: 1 },
						{ item: Item.WIND_CLUSTER, qty: 2 },
						{ item: Item.ICE_CLUSTER, qty: 1 },
					],
				},
			],
			markets: { [Market.UNIVERSALIS]: 9511 },
		},
		used: [],
	},
	[Item.PERFECT_FIREWOOD_HQ]: { // Obtained should match between normal and HQ versions
		name: Item.PERFECT_FIREWOOD_HQ, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.PERFECT_FIREWOOD_HQ, qtyMade: 1, crafter: Job.CRP, level: 50, lodestoneID: "6dc973f7e54",
					itemsRequired: [
						{ item: Item.AGED_SPEAR_SHAFT, qty: 1 },
						{ item: Item.CYPRESS_LOG, qty: 2 },
						{ item: Item.GRENADE_ASH, qty: 1 },
						{ item: Item.WIND_CLUSTER, qty: 2 },
						{ item: Item.ICE_CLUSTER, qty: 1 },
					],
				},
			],
			markets: { [Market.UNIVERSALIS]: 9511 },
		},
		used: [
			{ relic: [RelicType.WEAPON, RelicGroup.WEAPON_ZODIAC, RelicPart.WEAPON_BRAVES, RelicStep.SKYLIGHT, RelicTask.TASK_9], jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC], qtyPerJob: 1 },
		],
	},
	[Item.ALLAGAN_RESIN]: {
		name: Item.ALLAGAN_RESIN, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { shops: [{ shop: Shop.MERCHANT_MENDER_SOUTH_THANALAN, cost: [{ price: 100000, currency: Currency.GIL }] }] },
		used: [
			{ relic: [RelicType.WEAPON, RelicGroup.WEAPON_ZODIAC, RelicPart.WEAPON_BRAVES, RelicStep.ZODIUM, RelicTask.TASK_1], jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC], qtyPerJob: 1 },
		],
	},
	[Item.VALE_BUBO]: {
		name: Item.VALE_BUBO, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { duties: { dungeons: [Dungeon.AURUM] } },
		used: [
			{ relic: [RelicType.WEAPON, RelicGroup.WEAPON_ZODIAC, RelicPart.WEAPON_BRAVES, RelicStep.ZODIUM, RelicTask.TASK_2], jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC], qtyPerJob: 1 },
		],
	},
	[Item.VOIDWEAVE]: {
		name: Item.VOIDWEAVE, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { duties: { dungeons: [Dungeon.HAUKKE_MANOR_HARD] } },
		used: [
			{ relic: [RelicType.WEAPON, RelicGroup.WEAPON_ZODIAC, RelicPart.WEAPON_BRAVES, RelicStep.ZODIUM, RelicTask.TASK_3], jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC], qtyPerJob: 1 },
		],
	},
	[Item.AMDAPOR_VELLUM]: {
		name: Item.AMDAPOR_VELLUM, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { duties: { dungeons: [Dungeon.LOST_CITY_OF_AMDAPOR] } },
		used: [
			{ relic: [RelicType.WEAPON, RelicGroup.WEAPON_ZODIAC, RelicPart.WEAPON_BRAVES, RelicStep.ZODIUM, RelicTask.TASK_4], jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC], qtyPerJob: 1 },
		],
	},
	[Item.INDIGO_PEARL]: {
		name: Item.INDIGO_PEARL, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { duties: { dungeons: [Dungeon.SASTASHA_HARD] } },
		used: [
			{ relic: [RelicType.WEAPON, RelicGroup.WEAPON_ZODIAC, RelicPart.WEAPON_BRAVES, RelicStep.ZODIUM, RelicTask.TASK_5], jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC], qtyPerJob: 1 },
		],
	},
	[Item.PERFECT_MORTAR]: { // Obtained should match between normal and HQ versions
		name: Item.PERFECT_MORTAR, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.PERFECT_MORTAR, qtyMade: 1, crafter: Job.ARM, level: 50, lodestoneID: "0a574554dc3",
					itemsRequired: [
						{ item: Item.AGED_MORTAR_PIECES, qty: 1 },
						{ item: Item.BELAHDIAN_SILVER, qty: 1 },
						{ item: Item.MAHOGANY_LUMBER, qty: 1 },
						{ item: Item.ICE_CLUSTER, qty: 2 },
						{ item: Item.EARTH_CLUSTER, qty: 1 },
					],
				},
			],
			markets: { [Market.UNIVERSALIS]: 9512 },
		},
		used: [],
	},
	[Item.PERFECT_MORTAR_HQ]: { // Obtained should match between normal and HQ versions
		name: Item.PERFECT_MORTAR_HQ, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.PERFECT_MORTAR_HQ, qtyMade: 1, crafter: Job.ARM, level: 50, lodestoneID: "0a574554dc3",
					itemsRequired: [
						{ item: Item.AGED_MORTAR_PIECES, qty: 1 },
						{ item: Item.BELAHDIAN_SILVER, qty: 1 },
						{ item: Item.MAHOGANY_LUMBER, qty: 1 },
						{ item: Item.ICE_CLUSTER, qty: 2 },
						{ item: Item.EARTH_CLUSTER, qty: 1 },
					],
				},
			],
			markets: { [Market.UNIVERSALIS]: 9512 },
		},
		used: [
			{ relic: [RelicType.WEAPON, RelicGroup.WEAPON_ZODIAC, RelicPart.WEAPON_BRAVES, RelicStep.ZODIUM, RelicTask.TASK_6], jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC], qtyPerJob: 1 },
		],
	},
	[Item.PERFECT_PESTLE]: { // Obtained should match between normal and HQ versions
		name: Item.PERFECT_PESTLE, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.PERFECT_PESTLE, qtyMade: 1, crafter: Job.CRP, level: 0, lodestoneID: "80a72bead39",
					itemsRequired: [
						{ item: Item.AGED_PESTLE_PIECES, qty: 1 },
						{ item: Item.CYPRESS_LOG, qty: 2 },
						{ item: Item.PEISTE_LEATHER, qty: 1 },
						{ item: Item.FIRE_CLUSTER, qty: 2 },
						{ item: Item.EARTH_CLUSTER, qty: 1 },
					],
				},
			],
			markets: { [Market.UNIVERSALIS]: 9513 },
		},
		used: [],
	},
	[Item.PERFECT_PESTLE_HQ]: { // Obtained should match between normal and HQ versions
		name: Item.PERFECT_PESTLE_HQ, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.PERFECT_PESTLE_HQ, qtyMade: 1, crafter: Job.CRP, level: 0, lodestoneID: "80a72bead39",
					itemsRequired: [
						{ item: Item.AGED_PESTLE_PIECES, qty: 1 },
						{ item: Item.CYPRESS_LOG, qty: 2 },
						{ item: Item.PEISTE_LEATHER, qty: 1 },
						{ item: Item.FIRE_CLUSTER, qty: 2 },
						{ item: Item.EARTH_CLUSTER, qty: 1 },
					],
				},
			],
			markets: { [Market.UNIVERSALIS]: 9513 },
		},
		used: [
			{ relic: [RelicType.WEAPON, RelicGroup.WEAPON_ZODIAC, RelicPart.WEAPON_BRAVES, RelicStep.ZODIUM, RelicTask.TASK_7], jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC], qtyPerJob: 1 },
		],
	},
	[Item.BRASS_KETTLE]: {
		name: Item.BRASS_KETTLE, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { shops: [{ shop: Shop.TOOL_SUPPLIER_MENDER, cost: [{ price: 100000, currency: Currency.GIL }] }] },
		used: [
			{ relic: [RelicType.WEAPON, RelicGroup.WEAPON_ZODIAC, RelicPart.WEAPON_BRAVES, RelicStep.FLAWLESS_ALEXANDRITE, RelicTask.TASK_2], jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC], qtyPerJob: 1 },
		],
	},
	[Item.LOST_TREASURE_OF_AMDAPOR]: {
		name: Item.LOST_TREASURE_OF_AMDAPOR, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { duties: { dungeons: [Dungeon.AMDAPOR_KEEP] } },
		used: [
			{ relic: [RelicType.WEAPON, RelicGroup.WEAPON_ZODIAC, RelicPart.WEAPON_BRAVES, RelicStep.FLAWLESS_ALEXANDRITE, RelicTask.TASK_4], jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC], qtyPerJob: 1 },
		],
	},
	[Item.LOST_TREASURE_OF_PHAROS_SIRIUS]: {
		name: Item.LOST_TREASURE_OF_PHAROS_SIRIUS, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { duties: { dungeons: [Dungeon.PHAROS_SIRIUS] } },
		used: [
			{ relic: [RelicType.WEAPON, RelicGroup.WEAPON_ZODIAC, RelicPart.WEAPON_BRAVES, RelicStep.FLAWLESS_ALEXANDRITE, RelicTask.TASK_5], jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC], qtyPerJob: 1 },
		],
	},
	[Item.LOST_TREASURE_OF_TAM_TARA]: {
		name: Item.LOST_TREASURE_OF_TAM_TARA, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { duties: { dungeons: [Dungeon.TAM_TARA_HARD] } },
		used: [
			{ relic: [RelicType.WEAPON, RelicGroup.WEAPON_ZODIAC, RelicPart.WEAPON_BRAVES, RelicStep.FLAWLESS_ALEXANDRITE, RelicTask.TASK_6], jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC], qtyPerJob: 1 },
		],
	},
	[Item.LOST_TREASURE_OF_STONE_VIGIL]: {
		name: Item.LOST_TREASURE_OF_STONE_VIGIL, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { duties: { dungeons: [Dungeon.STONE_VIGIL_HARD] } },
		used: [
			{ relic: [RelicType.WEAPON, RelicGroup.WEAPON_ZODIAC, RelicPart.WEAPON_BRAVES, RelicStep.FLAWLESS_ALEXANDRITE, RelicTask.TASK_7], jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC], qtyPerJob: 1 },
		],
	},
	[Item.TAILOR_MADE_EEL_PIE]: { // Obtained should match between normal and HQ versions
		name: Item.TAILOR_MADE_EEL_PIE, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.TAILOR_MADE_EEL_PIE_HQ, qtyMade: 1, crafter: Job.CUL, level: 50, lodestoneID: "323eb2ddf17",
					itemsRequired: [
						{ item: Item.VINTAGE_COOKING_SHERRY, qty: 1 },
						{ item: Item.WORM_OF_NYM, qty: 1 },
						{ item: Item.PIE_DOUGH, qty: 1 },
						{ item: Item.SMOOTH_BUTTER, qty: 1 },
						{ item: Item.BLACK_PEPPER, qty: 1 },
						{ item: Item.CLOVES, qty: 1 },
						{ item: Item.FIRE_CLUSTER, qty: 2 },
						{ item: Item.WATER_CLUSTER, qty: 1 },
					],
				},
			],
			markets: { [Market.UNIVERSALIS]: 9516 },
		},
		used: [],
	},
	[Item.TAILOR_MADE_EEL_PIE_HQ]: { // Obtained should match between normal and HQ versions
		name: Item.TAILOR_MADE_EEL_PIE_HQ, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.TAILOR_MADE_EEL_PIE_HQ, qtyMade: 1, crafter: Job.CUL, level: 50, lodestoneID: "323eb2ddf17",
					itemsRequired: [
						{ item: Item.VINTAGE_COOKING_SHERRY, qty: 1 },
						{ item: Item.WORM_OF_NYM, qty: 1 },
						{ item: Item.PIE_DOUGH, qty: 1 },
						{ item: Item.SMOOTH_BUTTER, qty: 1 },
						{ item: Item.BLACK_PEPPER, qty: 1 },
						{ item: Item.CLOVES, qty: 1 },
						{ item: Item.FIRE_CLUSTER, qty: 2 },
						{ item: Item.WATER_CLUSTER, qty: 1 },
					],
				},
			],
			markets: { [Market.UNIVERSALIS]: 9516 },
		},
		used: [
			{ relic: [RelicType.WEAPON, RelicGroup.WEAPON_ZODIAC, RelicPart.WEAPON_BRAVES, RelicStep.FLAWLESS_ALEXANDRITE, RelicTask.TASK_8], jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC], qtyPerJob: 1 },
		],
	},
	[Item.PERFECT_CLOTH]: { // Obtained should match between normal and HQ versions
		name: Item.PERFECT_CLOTH, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.PERFECT_CLOTH, qtyMade: 1, crafter: Job.WVR, level: 50, lodestoneID: "320f8397d7a",
					itemsRequired: [
						{ item: Item.STAINED_CLOTH, qty: 1 },
						{ item: Item.PUMICE, qty: 2 },
						{ item: Item.LINEN_YARN, qty: 1 },
						{ item: Item.EFFERVESCENT_WATER, qty: 1 },
						{ item: Item.LIGHTNING_CLUSTER, qty: 2 },
						{ item: Item.WIND_CLUSTER, qty: 1 },
					],
				},
			],
			markets: { [Market.UNIVERSALIS]: 9517 },
		},
		used: [],
	},
	[Item.PERFECT_CLOTH_HQ]: { // Obtained should match between normal and HQ versions
		name: Item.PERFECT_CLOTH_HQ, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.PERFECT_CLOTH_HQ, qtyMade: 1, crafter: Job.WVR, level: 50, lodestoneID: "320f8397d7a",
					itemsRequired: [
						{ item: Item.STAINED_CLOTH, qty: 1 },
						{ item: Item.PUMICE, qty: 2 },
						{ item: Item.LINEN_YARN, qty: 1 },
						{ item: Item.EFFERVESCENT_WATER, qty: 1 },
						{ item: Item.LIGHTNING_CLUSTER, qty: 2 },
						{ item: Item.WIND_CLUSTER, qty: 1 },
					],
				},
			],
			markets: { [Market.UNIVERSALIS]: 9517 },
		},
		used: [
			{ relic: [RelicType.WEAPON, RelicGroup.WEAPON_ZODIAC, RelicPart.WEAPON_BRAVES, RelicStep.FLAWLESS_ALEXANDRITE, RelicTask.TASK_9], jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC], qtyPerJob: 1 },
		],
	},
	[Item.TONBERRY_KING_BLOOD]: {
		name: Item.TONBERRY_KING_BLOOD, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { duties: { dungeons: [Dungeon.WANDERERS] } },
		used: [
			{ relic: [RelicType.WEAPON, RelicGroup.WEAPON_ZODIAC, RelicPart.WEAPON_BRAVES, RelicStep.BRAVES_SCROLL, RelicTask.TASK_1], jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC], qtyPerJob: 1 },
		],
	},
	[Item.ROYAL_GIGANT_BLOOD]: {
		name: Item.ROYAL_GIGANT_BLOOD, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { duties: { dungeons: [Dungeon.COPPERBELL_HARD] } },
		used: [
			{ relic: [RelicType.WEAPON, RelicGroup.WEAPON_ZODIAC, RelicPart.WEAPON_BRAVES, RelicStep.BRAVES_SCROLL, RelicTask.TASK_2], jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC], qtyPerJob: 1 },
		],
	},
	[Item.KRAKEN_BLOOD]: {
		name: Item.KRAKEN_BLOOD, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { duties: { dungeons: [Dungeon.HULLBREAKER] } },
		used: [
			{ relic: [RelicType.WEAPON, RelicGroup.WEAPON_ZODIAC, RelicPart.WEAPON_BRAVES, RelicStep.BRAVES_SCROLL, RelicTask.TASK_3], jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC], qtyPerJob: 1 },
		],
	},
	[Item.VICEGERENT_BLOOD]: {
		name: Item.VICEGERENT_BLOOD, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { duties: { dungeons: [Dungeon.QARN_HARD] } },
		used: [
			{ relic: [RelicType.WEAPON, RelicGroup.WEAPON_ZODIAC, RelicPart.WEAPON_BRAVES, RelicStep.BRAVES_SCROLL, RelicTask.TASK_4], jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC], qtyPerJob: 1 },
		],
	},
	[Item.PERFECT_VELLUM]: { // Obtained should match between normal and HQ versions
		name: Item.PERFECT_VELLUM, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.PERFECT_VELLUM, qtyMade: 1, crafter: Job.LTW, level: 50, lodestoneID: "128be3afd40",
					itemsRequired: [
						{ item: Item.AGED_VELLUM, qty: 1 },
						{ item: Item.PUMICE, qty: 2 },
						{ item: Item.SHARK_OIL, qty: 1 },
						{ item: Item.EARTH_CLUSTER, qty: 2 },
						{ item: Item.WIND_CLUSTER, qty: 1 },
					],
				},
			],
			markets: { [Market.UNIVERSALIS]: 9514 },
		},
		used: [],
	},
	[Item.PERFECT_VELLUM_HQ]: { // Obtained should match between normal and HQ versions
		name: Item.PERFECT_VELLUM_HQ, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.PERFECT_VELLUM_HQ, qtyMade: 1, crafter: Job.LTW, level: 50, lodestoneID: "128be3afd40",
					itemsRequired: [
						{ item: Item.AGED_VELLUM, qty: 1 },
						{ item: Item.PUMICE, qty: 2 },
						{ item: Item.SHARK_OIL, qty: 1 },
						{ item: Item.EARTH_CLUSTER, qty: 2 },
						{ item: Item.WIND_CLUSTER, qty: 1 },
					],
				},
			],
			markets: { [Market.UNIVERSALIS]: 9514 },
		},
		used: [
			{ relic: [RelicType.WEAPON, RelicGroup.WEAPON_ZODIAC, RelicPart.WEAPON_BRAVES, RelicStep.BRAVES_SCROLL, RelicTask.TASK_5], jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC], qtyPerJob: 1 },
		],
	},
	[Item.PERFECT_POUNCE]: { // Obtained should match between normal and HQ versions
		name: Item.PERFECT_POUNCE, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.PERFECT_POUNCE, qtyMade: 1, crafter: Job.ALC, level: 50, lodestoneID: "f9e57147805",
					itemsRequired: [
						{ item: Item.DRIED_ETHER, qty: 1 },
						{ item: Item.AETHERLOUSE, qty: 1 },
						{ item: Item.ELECTRUM_SAND, qty: 1 },
						{ item: Item.WATER_CLUSTER, qty: 2 },
						{ item: Item.LIGHTNING_CLUSTER, qty: 1 },
					],
				},
			],
			markets: { [Market.UNIVERSALIS]: 9515 },
		},
		used: [],
	},
	[Item.PERFECT_POUNCE_HQ]: { // Obtained should match between normal and HQ versions
		name: Item.PERFECT_POUNCE_HQ, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.PERFECT_POUNCE_HQ, qtyMade: 1, crafter: Job.ALC, level: 50, lodestoneID: "f9e57147805",
					itemsRequired: [
						{ item: Item.DRIED_ETHER, qty: 1 },
						{ item: Item.AETHERLOUSE, qty: 1 },
						{ item: Item.ELECTRUM_SAND, qty: 1 },
						{ item: Item.WATER_CLUSTER, qty: 2 },
						{ item: Item.LIGHTNING_CLUSTER, qty: 1 },
					],
				},
			],
			markets: { [Market.UNIVERSALIS]: 9515 },
		},
		used: [
			{ relic: [RelicType.WEAPON, RelicGroup.WEAPON_ZODIAC, RelicPart.WEAPON_BRAVES, RelicStep.BRAVES_SCROLL, RelicTask.TASK_6], jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC], qtyPerJob: 1 },
		],
	},
	[Item.FURITE_SAND]: {
		name: Item.FURITE_SAND, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { shops: [{ shop: Shop.MERCHANT_MENDER_COERTHAS_CENTRAL_HIGHLANDS, cost: [{ price: 100000, currency: Currency.GIL }] }] },
		used: [
			{ relic: [RelicType.WEAPON, RelicGroup.WEAPON_ZODIAC, RelicPart.WEAPON_BRAVES, RelicStep.BRAVES_SCROLL, RelicTask.TASK_8], jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC], qtyPerJob: 1 },
		],
	},
	[Item.MAHATMA_OF_THE_RAM]: {
		name: Item.MAHATMA_OF_THE_RAM, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { other: [{ element: "p", text: "These need to be upgraded similar to 'Light' from the Nexus upgrade - 40 Points are required - Points are obtained from ARR dungeons, trials, raids" }] },
		used: [
			{ relic: [RelicType.WEAPON, RelicGroup.WEAPON_ZODIAC, RelicPart.WEAPON_ZETA, RelicStep.SOUL, RelicTask.TASK_1], jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC], qtyPerJob: 1 },
		],
	},
	[Item.MAHATMA_OF_THE_BULL]: {
		name: Item.MAHATMA_OF_THE_BULL, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { other: [{ element: "p", text: "These need to be upgraded similar to 'Light' from the Nexus upgrade - 40 Points are required - Points are obtained from ARR dungeons, trials, raids" }] },
		used: [
			{ relic: [RelicType.WEAPON, RelicGroup.WEAPON_ZODIAC, RelicPart.WEAPON_ZETA, RelicStep.SOUL, RelicTask.TASK_2], jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC], qtyPerJob: 1 },
		],
	},
	[Item.MAHATMA_OF_THE_TWINS]: {
		name: Item.MAHATMA_OF_THE_TWINS, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { other: [{ element: "p", text: "These need to be upgraded similar to 'Light' from the Nexus upgrade - 40 Points are required - Points are obtained from ARR dungeons, trials, raids" }] },
		used: [
			{ relic: [RelicType.WEAPON, RelicGroup.WEAPON_ZODIAC, RelicPart.WEAPON_ZETA, RelicStep.SOUL, RelicTask.TASK_3], jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC], qtyPerJob: 1 },
		],
	},
	[Item.MAHATMA_OF_THE_CRAB]: {
		name: Item.MAHATMA_OF_THE_CRAB, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { other: [{ element: "p", text: "These need to be upgraded similar to 'Light' from the Nexus upgrade - 40 Points are required - Points are obtained from ARR dungeons, trials, raids" }] },
		used: [
			{ relic: [RelicType.WEAPON, RelicGroup.WEAPON_ZODIAC, RelicPart.WEAPON_ZETA, RelicStep.SOUL, RelicTask.TASK_4], jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC], qtyPerJob: 1 },
		],
	},
	[Item.MAHATMA_OF_THE_LION]: {
		name: Item.MAHATMA_OF_THE_LION, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { other: [{ element: "p", text: "These need to be upgraded similar to 'Light' from the Nexus upgrade - 40 Points are required - Points are obtained from ARR dungeons, trials, raids" }] },
		used: [
			{ relic: [RelicType.WEAPON, RelicGroup.WEAPON_ZODIAC, RelicPart.WEAPON_ZETA, RelicStep.SOUL, RelicTask.TASK_5], jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC], qtyPerJob: 1 },
		],
	},
	[Item.MAHATMA_OF_THE_MAIDEN]: {
		name: Item.MAHATMA_OF_THE_MAIDEN, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { other: [{ element: "p", text: "These need to be upgraded similar to 'Light' from the Nexus upgrade - 40 Points are required - Points are obtained from ARR dungeons, trials, raids" }] },
		used: [
			{ relic: [RelicType.WEAPON, RelicGroup.WEAPON_ZODIAC, RelicPart.WEAPON_ZETA, RelicStep.SOUL, RelicTask.TASK_6], jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC], qtyPerJob: 1 },
		],
	},
	[Item.MAHATMA_OF_THE_SCALES]: {
		name: Item.MAHATMA_OF_THE_SCALES, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { other: [{ element: "p", text: "These need to be upgraded similar to 'Light' from the Nexus upgrade - 40 Points are required - Points are obtained from ARR dungeons, trials, raids" }] },
		used: [
			{ relic: [RelicType.WEAPON, RelicGroup.WEAPON_ZODIAC, RelicPart.WEAPON_ZETA, RelicStep.SOUL, RelicTask.TASK_7], jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC], qtyPerJob: 1 },
		],
	},
	[Item.MAHATMA_OF_THE_SCORPION]: {
		name: Item.MAHATMA_OF_THE_SCORPION, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { other: [{ element: "p", text: "These need to be upgraded similar to 'Light' from the Nexus upgrade - 40 Points are required - Points are obtained from ARR dungeons, trials, raids" }] },
		used: [
			{ relic: [RelicType.WEAPON, RelicGroup.WEAPON_ZODIAC, RelicPart.WEAPON_ZETA, RelicStep.SOUL, RelicTask.TASK_8], jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC], qtyPerJob: 1 },
		],
	},
	[Item.MAHATMA_OF_THE_ARCHER]: {
		name: Item.MAHATMA_OF_THE_ARCHER, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { other: [{ element: "p", text: "These need to be upgraded similar to 'Light' from the Nexus upgrade - 40 Points are required - Points are obtained from ARR dungeons, trials, raids" }] },
		used: [
			{ relic: [RelicType.WEAPON, RelicGroup.WEAPON_ZODIAC, RelicPart.WEAPON_ZETA, RelicStep.SOUL, RelicTask.TASK_9], jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC], qtyPerJob: 1 },
		],
	},
	[Item.MAHATMA_OF_THE_GOAT]: {
		name: Item.MAHATMA_OF_THE_GOAT, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { other: [{ element: "p", text: "These need to be upgraded similar to 'Light' from the Nexus upgrade - 40 Points are required - Points are obtained from ARR dungeons, trials, raids" }] },
		used: [
			{ relic: [RelicType.WEAPON, RelicGroup.WEAPON_ZODIAC, RelicPart.WEAPON_ZETA, RelicStep.SOUL, RelicTask.TASK_10], jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC], qtyPerJob: 1 },
		],
	},
	[Item.MAHATMA_OF_THE_WATERBEARER]: {
		name: Item.MAHATMA_OF_THE_WATERBEARER, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { other: [{ element: "p", text: "These need to be upgraded similar to 'Light' from the Nexus upgrade - 40 Points are required - Points are obtained from ARR dungeons, trials, raids" }] },
		used: [
			{ relic: [RelicType.WEAPON, RelicGroup.WEAPON_ZODIAC, RelicPart.WEAPON_ZETA, RelicStep.SOUL, RelicTask.TASK_11], jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC], qtyPerJob: 1 },
		],
	},
	[Item.MAHATMA_OF_THE_FISH]: {
		name: Item.MAHATMA_OF_THE_FISH, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { other: [{ element: "p", text: "These need to be upgraded similar to 'Light' from the Nexus upgrade - 40 Points are required - Points are obtained from ARR dungeons, trials, raids" }] },
		used: [
			{ relic: [RelicType.WEAPON, RelicGroup.WEAPON_ZODIAC, RelicPart.WEAPON_ZETA, RelicStep.SOUL, RelicTask.TASK_12], jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC], qtyPerJob: 1 },
		],
	},
	[Item.LUMINOUS_WIND_CRYSTAL]: {
		name: Item.LUMINOUS_WIND_CRYSTAL, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { duties: { fates: [MapLocation.SEA_OF_CLOUDS] } },
		used: [
			{ relic: [RelicType.WEAPON, RelicGroup.WEAPON_ANIMA, RelicPart.WEAPON_ANIMATED, RelicStep.CRYSTALS, RelicTask.TASK_1], jobs: jobLists.relic[RelicGroup.WEAPON_ANIMA], qtyPerJob: 1 },
		],
	},
	[Item.LUMINOUS_FIRE_CRYSTAL]: {
		name: Item.LUMINOUS_FIRE_CRYSTAL, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { duties: { fates: [MapLocation.AZYS_LLA] } },
		used: [
			{ relic: [RelicType.WEAPON, RelicGroup.WEAPON_ANIMA, RelicPart.WEAPON_ANIMATED, RelicStep.CRYSTALS, RelicTask.TASK_2], jobs: jobLists.relic[RelicGroup.WEAPON_ANIMA], qtyPerJob: 1 },
		],
	},
	[Item.LUMINOUS_LIGHTNING_CRYSTAL]: {
		name: Item.LUMINOUS_LIGHTNING_CRYSTAL, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { duties: { fates: [MapLocation.CHURNING_MISTS] } },
		used: [
			{ relic: [RelicType.WEAPON, RelicGroup.WEAPON_ANIMA, RelicPart.WEAPON_ANIMATED, RelicStep.CRYSTALS, RelicTask.TASK_3], jobs: jobLists.relic[RelicGroup.WEAPON_ANIMA], qtyPerJob: 1 },
		],
	},
	[Item.LUMINOUS_ICE_CRYSTAL]: {
		name: Item.LUMINOUS_ICE_CRYSTAL, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { duties: { fates: [MapLocation.COERTHAS_WESTERN_HIGHLANDS] } },
		used: [
			{ relic: [RelicType.WEAPON, RelicGroup.WEAPON_ANIMA, RelicPart.WEAPON_ANIMATED, RelicStep.CRYSTALS, RelicTask.TASK_4], jobs: jobLists.relic[RelicGroup.WEAPON_ANIMA], qtyPerJob: 1 },
		],
	},
	[Item.LUMINOUS_EARTH_CRYSTAL]: {
		name: Item.LUMINOUS_EARTH_CRYSTAL, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { duties: { fates: [MapLocation.DRAVANIAN_FORELANDS] } },
		used: [
			{ relic: [RelicType.WEAPON, RelicGroup.WEAPON_ANIMA, RelicPart.WEAPON_ANIMATED, RelicStep.CRYSTALS, RelicTask.TASK_5], jobs: jobLists.relic[RelicGroup.WEAPON_ANIMA], qtyPerJob: 1 },
		],
	},
	[Item.LUMINOUS_WATER_CRYSTAL]: {
		name: Item.LUMINOUS_WATER_CRYSTAL, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { duties: { fates: [MapLocation.DRAVANIAN_HINTERLANDS] } },
		used: [
			{ relic: [RelicType.WEAPON, RelicGroup.WEAPON_ANIMA, RelicPart.WEAPON_ANIMATED, RelicStep.CRYSTALS, RelicTask.TASK_6], jobs: jobLists.relic[RelicGroup.WEAPON_ANIMA], qtyPerJob: 1 },
		],
	},
	[Item.UNIDENTIFIABLE_BONE]: {
		name: Item.UNIDENTIFIABLE_BONE, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { shops: [{ shop: Shop.AURIANA, cost: [{ price: 150, currency: Currency.POETICS }] }, { shop: Shop.HISMENA, cost: [{ price: 150, currency: Currency.POETICS }] }, { shop: Shop.ROWENA_REP_LIMSA, cost: [{ price: 150, currency: Currency.POETICS }] }, { shop: Shop.ROWENA_REP_UlDAH, cost: [{ price: 150, currency: Currency.POETICS }] }, { shop: Shop.ROWENA_REP_GRIDANIA, cost: [{ price: 150, currency: Currency.POETICS }] }, { shop: Shop.ROWENA_REP_FOUNDATION, cost: [{ price: 150, currency: Currency.POETICS }] }, { shop: Shop.HUNT_BILLMASTER_ULDAH, cost: [{ price: 399, currency: Currency.ALLIED_SEAL }] }, { shop: Shop.HUNT_BILLMASTER_GRIDANIA, cost: [{ price: 399, currency: Currency.ALLIED_SEAL }] }, { shop: Shop.HUNT_BILLMASTER_LIMSA, cost: [{ price: 399, currency: Currency.ALLIED_SEAL }] }, { shop: Shop.SABINA, cost: [{ price: 3, currency: Currency.GORDIAN_BOLT }] }, { shop: Shop.LUNA_VANU, cost: [{ price: 6, currency: Currency.VANU_WHITEBONE }] }, { shop: Shop.AMALJAA_VENDOR, cost: [{ price: 3, currency: Currency.STEEL_AMALJOK }] }, { shop: Shop.VATH_STICKPEDDLER, cost: [{ price: 6, currency: Currency.BLACK_COPPER_GIL }] }, { shop: Shop.MOGMUL_MOGBELLY, cost: [{ price: 18, currency: Currency.KUPO_NUT }] }] },
		used: [
			{ relic: [RelicType.WEAPON, RelicGroup.WEAPON_ANIMA, RelicPart.WEAPON_ANIMA, RelicStep.RUBBER, RelicTask.TASK_1], jobs: jobLists.relic[RelicGroup.WEAPON_ANIMA], qtyPerJob: 10 },
		],
	},
	[Item.UNIDENTIFIABLE_SHELL]: {
		name: Item.UNIDENTIFIABLE_SHELL, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { shops: [{ shop: Shop.AURIANA, cost: [{ price: 150, currency: Currency.POETICS }] }, { shop: Shop.HISMENA, cost: [{ price: 150, currency: Currency.POETICS }] }, { shop: Shop.ROWENA_REP_LIMSA, cost: [{ price: 150, currency: Currency.POETICS }] }, { shop: Shop.ROWENA_REP_UlDAH, cost: [{ price: 150, currency: Currency.POETICS }] }, { shop: Shop.ROWENA_REP_GRIDANIA, cost: [{ price: 150, currency: Currency.POETICS }] }, { shop: Shop.ROWENA_REP_FOUNDATION, cost: [{ price: 150, currency: Currency.POETICS }] }, { shop: Shop.HUNT_BILLMASTER_ULDAH, cost: [{ price: 399, currency: Currency.ALLIED_SEAL }] }, { shop: Shop.HUNT_BILLMASTER_GRIDANIA, cost: [{ price: 399, currency: Currency.ALLIED_SEAL }] }, { shop: Shop.HUNT_BILLMASTER_LIMSA, cost: [{ price: 399, currency: Currency.ALLIED_SEAL }] }, { shop: Shop.SABINA, cost: [{ price: 3, currency: Currency.GORDIAN_LENS }] }, { shop: Shop.LUNA_VANU, cost: [{ price: 6, currency: Currency.VANU_WHITEBONE }] }, { shop: Shop.AMALJAA_VENDOR, cost: [{ price: 3, currency: Currency.STEEL_AMALJOK }] }, { shop: Shop.VATH_STICKPEDDLER, cost: [{ price: 6, currency: Currency.BLACK_COPPER_GIL }] }, { shop: Shop.MOGMUL_MOGBELLY, cost: [{ price: 18, currency: Currency.KUPO_NUT }] }] },
		used: [
			{ relic: [RelicType.WEAPON, RelicGroup.WEAPON_ANIMA, RelicPart.WEAPON_ANIMA, RelicStep.CARBONCOAT, RelicTask.TASK_1], jobs: jobLists.relic[RelicGroup.WEAPON_ANIMA], qtyPerJob: 10 },
		],
	},
	[Item.UNIDENTIFIABLE_ORE]: {
		name: Item.UNIDENTIFIABLE_ORE, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { shops: [{ shop: Shop.AURIANA, cost: [{ price: 150, currency: Currency.POETICS }] }, { shop: Shop.HISMENA, cost: [{ price: 150, currency: Currency.POETICS }] }, { shop: Shop.ROWENA_REP_LIMSA, cost: [{ price: 150, currency: Currency.POETICS }] }, { shop: Shop.ROWENA_REP_UlDAH, cost: [{ price: 150, currency: Currency.POETICS }] }, { shop: Shop.ROWENA_REP_GRIDANIA, cost: [{ price: 150, currency: Currency.POETICS }] }, { shop: Shop.ROWENA_REP_FOUNDATION, cost: [{ price: 150, currency: Currency.POETICS }] }, { shop: Shop.HUNT_BILLMASTER_ULDAH, cost: [{ price: 399, currency: Currency.ALLIED_SEAL }] }, { shop: Shop.HUNT_BILLMASTER_GRIDANIA, cost: [{ price: 399, currency: Currency.ALLIED_SEAL }] }, { shop: Shop.HUNT_BILLMASTER_LIMSA, cost: [{ price: 399, currency: Currency.ALLIED_SEAL }] }, { shop: Shop.SABINA, cost: [{ price: 3, currency: Currency.GORDIAN_SPRING }] }, { shop: Shop.LUNA_VANU, cost: [{ price: 6, currency: Currency.VANU_WHITEBONE }] }, { shop: Shop.AMALJAA_VENDOR, cost: [{ price: 3, currency: Currency.STEEL_AMALJOK }] }, { shop: Shop.VATH_STICKPEDDLER, cost: [{ price: 6, currency: Currency.BLACK_COPPER_GIL }] }, { shop: Shop.MOGMUL_MOGBELLY, cost: [{ price: 18, currency: Currency.KUPO_NUT }] }] },
		used: [
			{ relic: [RelicType.WEAPON, RelicGroup.WEAPON_ANIMA, RelicPart.WEAPON_ANIMA, RelicStep.WATER, RelicTask.TASK_1], jobs: jobLists.relic[RelicGroup.WEAPON_ANIMA], qtyPerJob: 10 },
		],
	},
	[Item.UNIDENTIFIABLE_SEEDS]: {
		name: Item.UNIDENTIFIABLE_SEEDS, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { shops: [{ shop: Shop.AURIANA, cost: [{ price: 150, currency: Currency.POETICS }] }, { shop: Shop.HISMENA, cost: [{ price: 150, currency: Currency.POETICS }] }, { shop: Shop.ROWENA_REP_LIMSA, cost: [{ price: 150, currency: Currency.POETICS }] }, { shop: Shop.ROWENA_REP_UlDAH, cost: [{ price: 150, currency: Currency.POETICS }] }, { shop: Shop.ROWENA_REP_GRIDANIA, cost: [{ price: 150, currency: Currency.POETICS }] }, { shop: Shop.ROWENA_REP_FOUNDATION, cost: [{ price: 150, currency: Currency.POETICS }] }, { shop: Shop.HUNT_BILLMASTER_ULDAH, cost: [{ price: 399, currency: Currency.ALLIED_SEAL }] }, { shop: Shop.HUNT_BILLMASTER_GRIDANIA, cost: [{ price: 399, currency: Currency.ALLIED_SEAL }] }, { shop: Shop.HUNT_BILLMASTER_LIMSA, cost: [{ price: 399, currency: Currency.ALLIED_SEAL }] }, { shop: Shop.SABINA, cost: [{ price: 3, currency: Currency.GORDIAN_SHAFT }] }, { shop: Shop.LUNA_VANU, cost: [{ price: 6, currency: Currency.VANU_WHITEBONE }] }, { shop: Shop.AMALJAA_VENDOR, cost: [{ price: 3, currency: Currency.STEEL_AMALJOK }] }, { shop: Shop.VATH_STICKPEDDLER, cost: [{ price: 6, currency: Currency.BLACK_COPPER_GIL }] }, { shop: Shop.MOGMUL_MOGBELLY, cost: [{ price: 18, currency: Currency.KUPO_NUT }] }] },
		used: [
			{ relic: [RelicType.WEAPON, RelicGroup.WEAPON_ANIMA, RelicPart.WEAPON_ANIMA, RelicStep.CATALYST, RelicTask.TASK_1], jobs: jobLists.relic[RelicGroup.WEAPON_ANIMA], qtyPerJob: 10 },
		],
	},
	[Item.ADAMANTITE_FRANCESCA]: {
		name: Item.ADAMANTITE_FRANCESCA, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.ADAMANTITE_FRANCESCA, qtyMade: 1, crafter: Job.BSM, level: 60, lodestoneID: "034f122df22",
					itemsRequired: [
						{ item: Item.PTERODACTYL_STRAP, qty: 1 },
						{ item: Item.ADAMANTITE_NUGGET, qty: 1 },
						{ item: Item.BIRCH_LUMBER, qty: 1 },
						{ item: Item.FIRE_CLUSTER, qty: 2 },
						{ item: Item.EARTH_CLUSTER, qty: 1 },
					],
				},
			],
			markets: { [Market.UNIVERSALIS]: 13589 },
			shops: [{ shop: Shop.SERPENT_QUARTERMASTER, cost: [{ price: 5000, currency: Currency.COMPANY_SEAL }] }, { shop: Shop.STORM_QUARTERMASTER, cost: [{ price: 5000, currency: Currency.COMPANY_SEAL }] }, { shop: Shop.FLAME_QUARTERMASTER, cost: [{ price: 5000, currency: Currency.COMPANY_SEAL }] }],
		},
		used: [
			{ relic: [RelicType.WEAPON, RelicGroup.WEAPON_ANIMA, RelicPart.WEAPON_ANIMA, RelicStep.RUBBER, RelicTask.TASK_2], jobs: jobLists.relic[RelicGroup.WEAPON_ANIMA], qtyPerJob: 4 },
		],
	},
	[Item.TITANIUM_ALLOY_MIRROR]: {
		name: Item.TITANIUM_ALLOY_MIRROR, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.TITANIUM_ALLOY_MIRROR, qtyMade: 1, crafter: Job.ALC, level: 60, lodestoneID: "5a1aea9bcf7",
					itemsRequired: [
						{ item: Item.TITANIUM_ALLOY_SQUARE, qty: 1 },
						{ item: Item.HOLY_WATER, qty: 1 },
						{ item: Item.HARDSILVER_SAND, qty: 1 },
						{ item: Item.VITRIOL, qty: 1 },
						{ item: Item.WATER_CLUSTER, qty: 2 },
						{ item: Item.LIGHTNING_CLUSTER, qty: 1 },
					],
				},
			],
			markets: { [Market.UNIVERSALIS]: 13591 },
			shops: [{ shop: Shop.SERPENT_QUARTERMASTER, cost: [{ price: 5000, currency: Currency.COMPANY_SEAL }] }, { shop: Shop.STORM_QUARTERMASTER, cost: [{ price: 5000, currency: Currency.COMPANY_SEAL }] }, { shop: Shop.FLAME_QUARTERMASTER, cost: [{ price: 5000, currency: Currency.COMPANY_SEAL }] }],
		},
		used: [
			{ relic: [RelicType.WEAPON, RelicGroup.WEAPON_ANIMA, RelicPart.WEAPON_ANIMA, RelicStep.CARBONCOAT, RelicTask.TASK_2], jobs: jobLists.relic[RelicGroup.WEAPON_ANIMA], qtyPerJob: 4 },
		],
	},
	[Item.DISPELLING_ARROW]: {
		name: Item.DISPELLING_ARROW, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.DISPELLING_ARROW, qtyMade: 1, crafter: Job.CRP, level: 60, lodestoneID: "977e3df8bb2",
					itemsRequired: [
						{ item: Item.BLESSED_FLETCHINGS, qty: 1 },
						{ item: Item.HALLOWED_CHESTNUT_LUMBER, qty: 1 },
						{ item: Item.HARDSILVER_NUGGET, qty: 1 },
						{ item: Item.WIND_CLUSTER, qty: 2 },
						{ item: Item.ICE_CLUSTER, qty: 1 },
					],
				},
			],
			markets: { [Market.UNIVERSALIS]: 13593 },
			shops: [{ shop: Shop.SERPENT_QUARTERMASTER, cost: [{ price: 5000, currency: Currency.COMPANY_SEAL }] }, { shop: Shop.STORM_QUARTERMASTER, cost: [{ price: 5000, currency: Currency.COMPANY_SEAL }] }, { shop: Shop.FLAME_QUARTERMASTER, cost: [{ price: 5000, currency: Currency.COMPANY_SEAL }] }],
		},
		used: [
			{ relic: [RelicType.WEAPON, RelicGroup.WEAPON_ANIMA, RelicPart.WEAPON_ANIMA, RelicStep.WATER, RelicTask.TASK_2], jobs: jobLists.relic[RelicGroup.WEAPON_ANIMA], qtyPerJob: 4 },
		],
	},
	[Item.KINGCAKE]: {
		name: Item.KINGCAKE, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.KINGCAKE, qtyMade: 1, crafter: Job.CUL, level: 60, lodestoneID: "2292b5ff258",
					itemsRequired: [
						{ item: Item.MOOGLE_MINIATURE, qty: 1 },
						{ item: Item.HIGHLAND_FLOUR, qty: 1 },
						{ item: Item.YAK_MILK, qty: 1 },
						{ item: Item.OKEANIS_EGG, qty: 1 },
						{ item: Item.BIRCH_SYRUP, qty: 1 },
						{ item: Item.TINY_CROWN, qty: 1 },
						{ item: Item.FIRE_CLUSTER, qty: 2 },
						{ item: Item.WATER_CLUSTER, qty: 1 },
					],
				},
			],
			markets: { [Market.UNIVERSALIS]: 13595 },
			shops: [{ shop: Shop.SERPENT_QUARTERMASTER, cost: [{ price: 5000, currency: Currency.COMPANY_SEAL }] }, { shop: Shop.STORM_QUARTERMASTER, cost: [{ price: 5000, currency: Currency.COMPANY_SEAL }] }, { shop: Shop.FLAME_QUARTERMASTER, cost: [{ price: 5000, currency: Currency.COMPANY_SEAL }] }],
		},
		used: [
			{ relic: [RelicType.WEAPON, RelicGroup.WEAPON_ANIMA, RelicPart.WEAPON_ANIMA, RelicStep.CATALYST, RelicTask.TASK_2], jobs: jobLists.relic[RelicGroup.WEAPON_ANIMA], qtyPerJob: 4 },
		],
	},
	[Item.AETHER_OIL]: {
		name: Item.AETHER_OIL, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { shops: [{ shop: Shop.AURIANA, cost: [{ price: 350, currency: Currency.POETICS }] }, { shop: Shop.HISMENA, cost: [{ price: 350, currency: Currency.POETICS }] }, { shop: Shop.ROWENA_REP_LIMSA, cost: [{ price: 350, currency: Currency.POETICS }] }, { shop: Shop.ROWENA_REP_UlDAH, cost: [{ price: 350, currency: Currency.POETICS }] }, { shop: Shop.ROWENA_REP_GRIDANIA, cost: [{ price: 350, currency: Currency.POETICS }] }, { shop: Shop.ROWENA_REP_FOUNDATION, cost: [{ price: 350, currency: Currency.POETICS }] }] },
		used: [
			{ relic: [RelicType.WEAPON, RelicGroup.WEAPON_ANIMA, RelicPart.WEAPON_HYPERCONDUCTIVE, RelicStep.OIL_HYPER, RelicTask.TASK_1], jobs: jobLists.relic[RelicGroup.WEAPON_ANIMA], qtyPerJob: 5 },
		],
	},
	[Item.CRYSTAL_SAND]: {
		name: Item.CRYSTAL_SAND, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { other: [{ element: "p", text: "Hand in any of the following groups of items for 2x Crystal Sand" }, { element: "li", text: "5x Rowena's Token (Blue Crafters' Scrip)" }, { element: "br" }, { element: "li", text: "5x Rowena's Token (Blue Gatherers' Scrip)" }, { element: "br" }, { element: "li", text: "1x Garlond Steel, 1x Celestine, 1x High Allagan Chimera Leather" }, { element: "br" }, { element: "li", text: "1x Amber-encased Vilekin" }, { element: "br" }, { element: "li", text: "3x Battlecraft Demimateria I" }, { element: "li", text: "2x Battlecraft Demimateria II" }, { element: "li", text: "3x Fieldcraft Demimateria I" }, { element: "li", text: "2x Fieldcraft Demimateria II" }, { element: "br" }, { element: "li", text: "5x Moonstone" }, { element: "br" }, { element: "li", text: "1x Inferno Horn" }, { element: "li", text: "1x Crag Heart" }, { element: "li", text: "1x Diamond Tear" }, { element: "br" }, { element: "li", text: "1x Vortex Feather" }, { element: "li", text: "1x Barb of the Whorl" }, { element: "li", text: "1x Large Levin Orb" }, { element: "br" }, { element: "li", text: "5x Superior Enchanted Ink" }, { element: "li", text: "5x Thavnairian Mist" }, { element: "br" }, { element: "li", text: "2x Mhachi Farthing" }, { element: "li", text: "5x Allagan Catalyst" }, { element: "br" }, { element: "li", text: "1x Unidentifiable Bone" }, { element: "li", text: "1x Unidentifiable Shell" }, { element: "li", text: "1x Unidentifiable Ore" }, { element: "li", text: "1x Unidentifiable Seeds" }] },
		used: [
			{ relic: [RelicType.WEAPON, RelicGroup.WEAPON_ANIMA, RelicPart.WEAPON_RECONDITIONED, RelicStep.SAND, RelicTask.TASK_1], jobs: jobLists.relic[RelicGroup.WEAPON_ANIMA], qtyPerJob: 60 },
		],
	},
	[Item.UMBRITE]: {
		name: Item.UMBRITE, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { shops: [{ shop: Shop.AURIANA, cost: [{ price: 75, currency: Currency.POETICS }] }, { shop: Shop.HISMENA, cost: [{ price: 75, currency: Currency.POETICS }] }, { shop: Shop.ROWENA_REP_LIMSA, cost: [{ price: 75, currency: Currency.POETICS }] }, { shop: Shop.ROWENA_REP_UlDAH, cost: [{ price: 75, currency: Currency.POETICS }] }, { shop: Shop.ROWENA_REP_GRIDANIA, cost: [{ price: 75, currency: Currency.POETICS }] }, { shop: Shop.ROWENA_REP_FOUNDATION, cost: [{ price: 75, currency: Currency.POETICS }] }] },
		used: [
			{ relic: [RelicType.WEAPON, RelicGroup.WEAPON_ANIMA, RelicPart.WEAPON_RECONDITIONED, RelicStep.SAND, RelicTask.TASK_2], jobs: jobLists.relic[RelicGroup.WEAPON_ANIMA], qtyPerJob: 60 },
		],
	},
	[Item.SINGING_CLUSTER]: {
		name: Item.SINGING_CLUSTER, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { shops: [{ shop: Shop.AURIANA, cost: [{ price: 40, currency: Currency.POETICS }] }, { shop: Shop.HISMENA, cost: [{ price: 40, currency: Currency.POETICS }] }, { shop: Shop.ROWENA_REP_LIMSA, cost: [{ price: 40, currency: Currency.POETICS }] }, { shop: Shop.ROWENA_REP_UlDAH, cost: [{ price: 40, currency: Currency.POETICS }] }, { shop: Shop.ROWENA_REP_GRIDANIA, cost: [{ price: 40, currency: Currency.POETICS }] }, { shop: Shop.ROWENA_REP_FOUNDATION, cost: [{ price: 40, currency: Currency.POETICS }] }], quests: [Quest.SEEKING_INSPIRATION, Quest.CUT_FROM_DIFFERENT_CLOTH] },
		used: [
			{ relic: [RelicType.WEAPON, RelicGroup.WEAPON_ANIMA, RelicPart.WEAPON_SHARPENED, RelicStep.CLUSTERS, RelicTask.TASK_1], jobs: jobLists.relic[RelicGroup.WEAPON_ANIMA], qtyPerJob: 50 },
		],
	},
	[Item.PNEUMITE]: {
		name: Item.PNEUMITE, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { shops: [{ shop: Shop.AURIANA, cost: [{ price: 100, currency: Currency.POETICS }] }, { shop: Shop.HISMENA, cost: [{ price: 100, currency: Currency.POETICS }] }, { shop: Shop.ROWENA_REP_LIMSA, cost: [{ price: 100, currency: Currency.POETICS }] }, { shop: Shop.ROWENA_REP_UlDAH, cost: [{ price: 100, currency: Currency.POETICS }] }, { shop: Shop.ROWENA_REP_GRIDANIA, cost: [{ price: 100, currency: Currency.POETICS }] }, { shop: Shop.ROWENA_REP_FOUNDATION, cost: [{ price: 100, currency: Currency.POETICS }] }, { shop: Shop.SERPENT_QUARTERMASTER, cost: [{ price: 4000, currency: Currency.COMPANY_SEAL }] }, { shop: Shop.STORM_QUARTERMASTER, cost: [{ price: 4000, currency: Currency.COMPANY_SEAL }] }, { shop: Shop.FLAME_QUARTERMASTER, cost: [{ price: 4000, currency: Currency.COMPANY_SEAL }] }] },
		used: [
			{ relic: [RelicType.WEAPON, RelicGroup.WEAPON_ANIMA, RelicPart.WEAPON_COMPLETE, RelicStep.PNEUMITE, RelicTask.TASK_1], jobs: jobLists.relic[RelicGroup.WEAPON_ANIMA], qtyPerJob: 15 },
		],
	},
	[Item.AETHERIC_DENSITY]: {
		name: Item.AETHERIC_DENSITY, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { other: [{ element: "p", text: "Aetheric Density points can be gained by the following" }, { element: "li", text: "HW Dungeons" }, { element: "li", text: "HW Trials" }, { element: "li", text: "HW Raids" }, { element: "li", text: "HW FATEs" }, { element: "li", text: "Level 50 ARR Dungeons, except MSQ" }] },
		used: [
			{ relic: [RelicType.WEAPON, RelicGroup.WEAPON_ANIMA, RelicPart.WEAPON_COMPLETE, RelicStep.DENSITY, RelicTask.TASK_1], jobs: jobLists.relic[RelicGroup.WEAPON_ANIMA], qtyPerJob: 2000 },
		],
	},
	[Item.ARCHAIC_ENCHANTED_INK]: {
		name: Item.ARCHAIC_ENCHANTED_INK, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { shops: [{ shop: Shop.AURIANA, cost: [{ price: 500, currency: Currency.POETICS }] }, { shop: Shop.HISMENA, cost: [{ price: 500, currency: Currency.POETICS }] }, { shop: Shop.ROWENA_REP_LIMSA, cost: [{ price: 500, currency: Currency.POETICS }] }, { shop: Shop.ROWENA_REP_UlDAH, cost: [{ price: 500, currency: Currency.POETICS }] }, { shop: Shop.ROWENA_REP_GRIDANIA, cost: [{ price: 500, currency: Currency.POETICS }] }, { shop: Shop.ROWENA_REP_FOUNDATION, cost: [{ price: 500, currency: Currency.POETICS }] }] },
		used: [
			{ relic: [RelicType.WEAPON, RelicGroup.WEAPON_ANIMA, RelicPart.WEAPON_LUX, RelicStep.BFF, RelicTask.TASK_1], jobs: jobLists.relic[RelicGroup.WEAPON_ANIMA], qtyPerJob: 1 },
		],
	},
	[Item.PROTEAN_CRYSTAL]: {
		name: Item.PROTEAN_CRYSTAL, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { other: [{ element: "p", text: "Drops from any mob in Eureka" }, { element: "p", text: "Trade in Anemos Crystals which are obtained from FATEs in Eureka" }] },
		used: [
			{ relic: [RelicType.WEAPON, RelicGroup.WEAPON_EUREKA, RelicPart.WEAPON_ANEMOS, RelicStep.ANEMOS_0, RelicTask.TASK_1], jobs: jobLists.relic[RelicGroup.WEAPON_EUREKA], qtyPerJob: 100 },
			{ relic: [RelicType.WEAPON, RelicGroup.WEAPON_EUREKA, RelicPart.WEAPON_ANEMOS, RelicStep.ANEMOS_1, RelicTask.TASK_1], jobs: jobLists.relic[RelicGroup.WEAPON_EUREKA], qtyPerJob: 400 },
			{ relic: [RelicType.WEAPON, RelicGroup.WEAPON_EUREKA, RelicPart.WEAPON_ANEMOS, RelicStep.ANEMOS_2, RelicTask.TASK_1], jobs: jobLists.relic[RelicGroup.WEAPON_EUREKA], qtyPerJob: 800 },
		],
	},
	[Item.PAZUZUS_FEATHER]: {
		name: Item.PAZUZUS_FEATHER, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { shops: [{ shop: Shop.EXPEDITION_BIRDWATCHER_ANEMOS, cost: [{ price: 300, currency: Item.PROTEAN_CRYSTAL }] }, { shop: Shop.EXPEDITION_BIRDWATCHER_PYROS, cost: [{ price: 300, currency: Item.PROTEAN_CRYSTAL }] }, { shop: Shop.EXPEDITION_BIRDWATCHER_PAGOS, cost: [{ price: 300, currency: Item.PROTEAN_CRYSTAL }] }], duties: { fates: [Fate.WAIL_IN_THE_WILLOWS] } },
		used: [
			{ relic: [RelicType.WEAPON, RelicGroup.WEAPON_EUREKA, RelicPart.WEAPON_ANEMOS, RelicStep.ANEMOS, RelicTask.TASK_1], jobs: jobLists.relic[RelicGroup.WEAPON_EUREKA], qtyPerJob: 3 },
		],
	},
	[Item.FROSTED_PROTEAN_CRYSTAL]: {
		name: Item.FROSTED_PROTEAN_CRYSTAL, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { other: [{ element: "p", text: "Fill Kettle with Vitiated Aether and each full bar can be converted at the Crystal Forge into Frosted Protean Crystals" }] },
		used: [
			{ relic: [RelicType.WEAPON, RelicGroup.WEAPON_EUREKA, RelicPart.WEAPON_ELEMENTAL, RelicStep.PAGOS, RelicTask.TASK_1], jobs: jobLists.relic[RelicGroup.WEAPON_EUREKA], qtyPerJob: 5 },
			{ relic: [RelicType.WEAPON, RelicGroup.WEAPON_EUREKA, RelicPart.WEAPON_ELEMENTAL, RelicStep.PAGOS_1, RelicTask.TASK_2], jobs: jobLists.relic[RelicGroup.WEAPON_EUREKA], qtyPerJob: 10 },
			{ relic: [RelicType.WEAPON, RelicGroup.WEAPON_EUREKA, RelicPart.WEAPON_ELEMENTAL, RelicStep.ELEMENTAL, RelicTask.TASK_1], jobs: jobLists.relic[RelicGroup.WEAPON_EUREKA], qtyPerJob: 16 },
		],
	},
	[Item.PAGOS_CRYSTAL]: {
		name: Item.PAGOS_CRYSTAL, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { other: [{ element: "p", text: "FATEs in Eureka Pagos" }] },
		used: [
			{ relic: [RelicType.WEAPON, RelicGroup.WEAPON_EUREKA, RelicPart.WEAPON_ELEMENTAL, RelicStep.PAGOS_1, RelicTask.TASK_1], jobs: jobLists.relic[RelicGroup.WEAPON_EUREKA], qtyPerJob: 500 },
		],
	},
	[Item.LOUHIS_ICE]: {
		name: Item.LOUHIS_ICE, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { shops: [{ shop: Shop.EXPEDITION_BIRDWATCHER_ANEMOS, cost: [{ price: 50, currency: Item.PAGOS_CRYSTAL }] }, { shop: Shop.EXPEDITION_BIRDWATCHER_PYROS, cost: [{ price: 50, currency: Item.PAGOS_CRYSTAL }] }, { shop: Shop.EXPEDITION_BIRDWATCHER_PAGOS, cost: [{ price: 50, currency: Item.PAGOS_CRYSTAL }] }], duties: { fates: [Fate.LOUHI_ON_ICE] } },
		used: [
			{ relic: [RelicType.WEAPON, RelicGroup.WEAPON_EUREKA, RelicPart.WEAPON_ELEMENTAL, RelicStep.ELEMENTAL, RelicTask.TASK_2], jobs: jobLists.relic[RelicGroup.WEAPON_EUREKA], qtyPerJob: 5 },
		],
	},
	[Item.PYROS_CRYSTAL]: {
		name: Item.PYROS_CRYSTAL, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { other: [{ element: "p", text: "FATEs in Eureka Pyros" }] },
		used: [
			{ relic: [RelicType.WEAPON, RelicGroup.WEAPON_EUREKA, RelicPart.WEAPON_PYROS, RelicStep.ELEMENTAL_1, RelicTask.TASK_1], jobs: jobLists.relic[RelicGroup.WEAPON_EUREKA], qtyPerJob: 150 },
			{ relic: [RelicType.WEAPON, RelicGroup.WEAPON_EUREKA, RelicPart.WEAPON_PYROS, RelicStep.ELEMENTAL_2, RelicTask.TASK_1], jobs: jobLists.relic[RelicGroup.WEAPON_EUREKA], qtyPerJob: 200 },
			{ relic: [RelicType.WEAPON, RelicGroup.WEAPON_EUREKA, RelicPart.WEAPON_PYROS, RelicStep.PYROS, RelicTask.TASK_1], jobs: jobLists.relic[RelicGroup.WEAPON_EUREKA], qtyPerJob: 300 },
		],
	},
	[Item.PENTHESILEAS_FLAME]: {
		name: Item.PENTHESILEAS_FLAME, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { shops: [{ shop: Shop.EXPEDITION_BIRDWATCHER_ANEMOS, cost: [{ price: 50, currency: Item.PYROS_CRYSTAL }] }, { shop: Shop.EXPEDITION_BIRDWATCHER_PYROS, cost: [{ price: 50, currency: Item.PYROS_CRYSTAL }] }, { shop: Shop.EXPEDITION_BIRDWATCHER_PAGOS, cost: [{ price: 50, currency: Item.PYROS_CRYSTAL }] }], duties: { fates: [Fate.LOST_EPIC] } },
		used: [
			{ relic: [RelicType.WEAPON, RelicGroup.WEAPON_EUREKA, RelicPart.WEAPON_PYROS, RelicStep.PYROS, RelicTask.TASK_2], jobs: jobLists.relic[RelicGroup.WEAPON_EUREKA], qtyPerJob: 5 },
		],
	},
	[Item.HYDATOS_CRYSTAL]: {
		name: Item.HYDATOS_CRYSTAL, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { other: [{ element: "p", text: "FATEs in Eureka Hydatos" }] },
		used: [
			{ relic: [RelicType.WEAPON, RelicGroup.WEAPON_EUREKA, RelicPart.WEAPON_EUREKA, RelicStep.HYDATOS, RelicTask.TASK_1], jobs: jobLists.relic[RelicGroup.WEAPON_EUREKA], qtyPerJob: 50 },
			{ relic: [RelicType.WEAPON, RelicGroup.WEAPON_EUREKA, RelicPart.WEAPON_EUREKA, RelicStep.HYDATOS_1, RelicTask.TASK_1], jobs: jobLists.relic[RelicGroup.WEAPON_EUREKA], qtyPerJob: 100 },
			{ relic: [RelicType.WEAPON, RelicGroup.WEAPON_EUREKA, RelicPart.WEAPON_EUREKA, RelicStep.BASE, RelicTask.TASK_1], jobs: jobLists.relic[RelicGroup.WEAPON_EUREKA], qtyPerJob: 100 },
			{ relic: [RelicType.WEAPON, RelicGroup.WEAPON_EUREKA, RelicPart.WEAPON_EUREKA, RelicStep.EUREKA, RelicTask.TASK_1], jobs: jobLists.relic[RelicGroup.WEAPON_EUREKA], qtyPerJob: 100 },
		],
	},
	[Item.CRYSTALLINE_SCALE]: {
		name: Item.CRYSTALLINE_SCALE, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { duties: { fates: [Fate.CRYSTALLINE_PROVENANCE] } },
		used: [
			{ relic: [RelicType.WEAPON, RelicGroup.WEAPON_EUREKA, RelicPart.WEAPON_EUREKA, RelicStep.EUREKA, RelicTask.TASK_2], jobs: jobLists.relic[RelicGroup.WEAPON_EUREKA], qtyPerJob: 5 },
		],
	},
	[Item.EUREKA_FRAGMENT]: {
		name: Item.EUREKA_FRAGMENT, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { other: [{ element: "p", text: "Obtained from Baldesion Arsenal" }] },
		used: [
			{ relic: [RelicType.WEAPON, RelicGroup.WEAPON_EUREKA, RelicPart.WEAPON_PHYSEOS, RelicStep.FRAGMENTS, RelicTask.TASK_1], jobs: jobLists.relic[RelicGroup.WEAPON_EUREKA], qtyPerJob: 100 },
		],
	},
	[Item.THAVNAIRIAN_SCALEPOWDER]: {
		name: Item.THAVNAIRIAN_SCALEPOWDER, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { shops: [{ shop: Shop.AURIANA, cost: [{ price: 250, currency: Currency.POETICS }] }, { shop: Shop.HISMENA, cost: [{ price: 250, currency: Currency.POETICS }] }, { shop: Shop.ROWENA_REP_LIMSA, cost: [{ price: 250, currency: Currency.POETICS }] }, { shop: Shop.ROWENA_REP_UlDAH, cost: [{ price: 250, currency: Currency.POETICS }] }, { shop: Shop.ROWENA_REP_GRIDANIA, cost: [{ price: 250, currency: Currency.POETICS }] }, { shop: Shop.ROWENA_REP_FOUNDATION, cost: [{ price: 250, currency: Currency.POETICS }] }] },
		used: [
			{ relic: [RelicType.WEAPON, RelicGroup.WEAPON_RESISTANCE, RelicPart.WEAPON_RESISTANCE, RelicStep.SCALEPOWDER, RelicTask.TASK_1], jobs: jobLists.relic[RelicGroup.WEAPON_RESISTANCE], qtyPerJob: 4 },
		],
	},
	[Item.TORTURED_MEMORY_OF_THE_DYING]: {
		name: Item.TORTURED_MEMORY_OF_THE_DYING, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { duties: { fates: [MapLocation.COERTHAS_WESTERN_HIGHLANDS, MapLocation.SEA_OF_CLOUDS, MapLocation.BOZJA_SOUTH], trials: [MapLocation.BOZJA_SOUTH] } },
		used: [
			{ relic: [RelicType.WEAPON, RelicGroup.WEAPON_RESISTANCE, RelicPart.WEAPON_AUGMENTED_RESISTANCE, RelicStep.MEMORY, RelicTask.TASK_1], jobs: jobLists.relic[RelicGroup.WEAPON_RESISTANCE], qtyPerJob: 20 },
		],
	},
	[Item.SORROWFUL_MEMORY_OF_THE_DYING]: {
		name: Item.SORROWFUL_MEMORY_OF_THE_DYING, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { duties: { fates: [MapLocation.DRAVANIAN_FORELANDS, MapLocation.CHURNING_MISTS, MapLocation.BOZJA_MIDDLE], trials: [MapLocation.BOZJA_MIDDLE] } },
		used: [
			{ relic: [RelicType.WEAPON, RelicGroup.WEAPON_RESISTANCE, RelicPart.WEAPON_AUGMENTED_RESISTANCE, RelicStep.MEMORY, RelicTask.TASK_2], jobs: jobLists.relic[RelicGroup.WEAPON_RESISTANCE], qtyPerJob: 20 },
		],
	},
	[Item.HARROWING_MEMORY_OF_THE_DYING]: {
		name: Item.HARROWING_MEMORY_OF_THE_DYING, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { duties: { fates: [MapLocation.DRAVANIAN_HINTERLANDS, MapLocation.AZYS_LLA, MapLocation.BOZJA_NORTH], trials: [MapLocation.BOZJA_NORTH] } },
		used: [
			{ relic: [RelicType.WEAPON, RelicGroup.WEAPON_RESISTANCE, RelicPart.WEAPON_AUGMENTED_RESISTANCE, RelicStep.MEMORY, RelicTask.TASK_3], jobs: jobLists.relic[RelicGroup.WEAPON_RESISTANCE], qtyPerJob: 20 },
		],
	},
	[Item.BITTER_MEMORY_OF_THE_DYING]: {
		name: Item.BITTER_MEMORY_OF_THE_DYING, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { other: [{ element: "p", text: "Any level 60 dungeon" }, { element: "p", text: "Leveling Roulette (Only once per day)" }, { element: "p", text: "Activities in the Bozjan Southern Front" }] },
		used: [
			{ relic: [RelicType.WEAPON, RelicGroup.WEAPON_RESISTANCE, RelicPart.WEAPON_RECOLLECTION, RelicStep.RESIST, RelicTask.TASK_1], jobs: jobLists.relic[RelicGroup.WEAPON_RESISTANCE], qtyPerJob: 6 },
		],
	},
	[Item.LOATHSOME_MEMORY_OF_THE_DYING]: {
		name: Item.LOATHSOME_MEMORY_OF_THE_DYING, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			duties: {
				trials: [MapLocation.BOZJA],
				raids: [Raid.LABYRINTH_OF_ANCIENTS, Raid.SYRCUS_TOWER, Raid.WORLD_OF_DARKNESS, Raid.CASTRUM_LACUS_LITORE],
			},
		},
		used: [
			{ relic: [RelicType.WEAPON, RelicGroup.WEAPON_RESISTANCE, RelicPart.WEAPON_LAWS_ORDER, RelicStep.CHANGE_OF_ARMS, RelicTask.TASK_1], jobs: jobLists.relic[RelicGroup.WEAPON_RESISTANCE], qtyPerJob: 15 },
		],
	},
	[Item.HAUNTING_MEMORY_OF_THE_DYING]: {
		name: Item.HAUNTING_MEMORY_OF_THE_DYING, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { duties: { raids: [Raid.VOID_ARK, Raid.MHACH, Raid.DUN_SCAITH], fates: [MapLocation.FRINGES, MapLocation.PEAKS, MapLocation.LOCHS] } },
		used: [
			{ relic: [RelicType.WEAPON, RelicGroup.WEAPON_RESISTANCE, RelicPart.WEAPON_AUGMENTED_LAWS_ORDER, RelicStep.REMEMBERS, RelicTask.TASK_1], jobs: jobLists.relic[RelicGroup.WEAPON_RESISTANCE], qtyPerJob: 18 },
		],
	},
	[Item.VEXATIOUS_MEMORY_OF_THE_DYING]: {
		name: Item.VEXATIOUS_MEMORY_OF_THE_DYING, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { duties: { raids: [Raid.RABANASTRE, Raid.RIDORANA, Raid.ORBONNE], fates: [MapLocation.RUBY_SEA, MapLocation.YANXIA, MapLocation.AZIM_STEPPE] } },
		used: [
			{ relic: [RelicType.WEAPON, RelicGroup.WEAPON_RESISTANCE, RelicPart.WEAPON_AUGMENTED_LAWS_ORDER, RelicStep.REMEMBERS, RelicTask.TASK_2], jobs: jobLists.relic[RelicGroup.WEAPON_RESISTANCE], qtyPerJob: 18 },
		],
	},
	[Item.TIMEWORN_ARTIFACTS]: {
		name: Item.TIMEWORN_ARTIFACTS, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { duties: { raids: [Raid.DELUBRUM_REGINAE] }, other: [{ element: "p", text: "Palace of the Dead" }] },
		used: [
			{ relic: [RelicType.WEAPON, RelicGroup.WEAPON_RESISTANCE, RelicPart.WEAPON_AUGMENTED_LAWS_ORDER, RelicStep.PATH, RelicTask.TASK_1], jobs: jobLists.relic[RelicGroup.WEAPON_RESISTANCE], qtyPerJob: 15 },
		],
	},
	[Item.COMPACT_AXLE]: {
		name: Item.COMPACT_AXLE, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { duties: { fates: [MapLocation.ZADNOR_SOUTH], raids: [Raid.ALEXANDER_1, Raid.ALEXANDER_2, Raid.ALEXANDER_5, Raid.ALEXANDER_6, Raid.ALEXANDER_9, Raid.ALEXANDER_10] } },
		used: [
			{ relic: [RelicType.WEAPON, RelicGroup.WEAPON_RESISTANCE, RelicPart.WEAPON_BLADE, RelicStep.SPARE_PARTS, RelicTask.TASK_1], jobs: [Job.PLD], qtyPerJob: 30 },
		],
	},
	[Item.COMPACT_SPRING]: {
		name: Item.COMPACT_SPRING, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { duties: { trials: [MapLocation.ZADNOR_SOUTH], raids: [Raid.ALEXANDER_3, Raid.ALEXANDER_4, Raid.ALEXANDER_7, Raid.ALEXANDER_8, Raid.ALEXANDER_11, Raid.ALEXANDER_12] } },
		used: [
			{ relic: [RelicType.WEAPON, RelicGroup.WEAPON_RESISTANCE, RelicPart.WEAPON_BLADE, RelicStep.SPARE_PARTS, RelicTask.TASK_2], jobs: [Job.PLD], qtyPerJob: 30 },
		],
	},
	[Item.BATTLES_FOR_THE_REALM]: {
		name: Item.BATTLES_FOR_THE_REALM, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { duties: { fates: [MapLocation.ZADNOR_MIDDLE], raids: [Raid.OMEGA_1, Raid.OMEGA_2, Raid.OMEGA_5, Raid.OMEGA_6, Raid.OMEGA_9, Raid.OMEGA_10] } },
		used: [
			{ relic: [RelicType.WEAPON, RelicGroup.WEAPON_RESISTANCE, RelicPart.WEAPON_BLADE, RelicStep.STORY, RelicTask.TASK_1], jobs: [Job.PLD], qtyPerJob: 30 },
		],
	},
	[Item.BEYOND_THE_RIFT]: {
		name: Item.BEYOND_THE_RIFT, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { duties: { trials: [MapLocation.ZADNOR_MIDDLE], raids: [Raid.OMEGA_3, Raid.OMEGA_4, Raid.OMEGA_7, Raid.OMEGA_8, Raid.OMEGA_11, Raid.OMEGA_12] } },
		used: [
			{ relic: [RelicType.WEAPON, RelicGroup.WEAPON_RESISTANCE, RelicPart.WEAPON_BLADE, RelicStep.STORY, RelicTask.TASK_2], jobs: [Job.PLD], qtyPerJob: 30 },
		],
	},
	[Item.BLEAK_MEMORY_OF_THE_DYING]: {
		name: Item.BLEAK_MEMORY_OF_THE_DYING, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { duties: { fates: [MapLocation.ZADNOR_NORTH], raids: [Raid.EDEN_1, Raid.EDEN_2, Raid.EDEN_5, Raid.EDEN_6, Raid.EDEN_9, Raid.EDEN_10] } },
		used: [
			{ relic: [RelicType.WEAPON, RelicGroup.WEAPON_RESISTANCE, RelicPart.WEAPON_BLADE, RelicStep.FOND_MEMORY, RelicTask.TASK_1], jobs: [Job.PLD], qtyPerJob: 30 },
		],
	},
	[Item.LURID_MEMORY_OF_THE_DYING]: {
		name: Item.LURID_MEMORY_OF_THE_DYING, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { duties: { trials: [MapLocation.ZADNOR_NORTH], raids: [Raid.EDEN_3, Raid.EDEN_4, Raid.EDEN_7, Raid.EDEN_8, Raid.EDEN_11, Raid.EDEN_12] } },
		used: [
			{ relic: [RelicType.WEAPON, RelicGroup.WEAPON_RESISTANCE, RelicPart.WEAPON_BLADE, RelicStep.FOND_MEMORY, RelicTask.TASK_2], jobs: [Job.PLD], qtyPerJob: 30 },
		],
	},
	[Item.RAW_EMOTION]: {
		name: Item.RAW_EMOTION, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { duties: { raids: [Raid.DALRIADA, Raid.DELUBRUM_REGINAE] }, other: [{ element: "p", text: "1 per level 70 Dungeon" }, { element: "p", text: "1 per 10 floors of Heaven-on-High (This is not 100% - Chances increases on higher floors)" }] },
		used: [
			{ relic: [RelicType.WEAPON, RelicGroup.WEAPON_RESISTANCE, RelicPart.WEAPON_BLADE, RelicStep.IRRESISTIBLE, RelicTask.TASK_1], jobs: jobLists.relic[RelicGroup.WEAPON_RESISTANCE], qtyPerJob: 15 },
		],
	},
	[Item.MANDERIUM_METEORITE]: {
		name: Item.MANDERIUM_METEORITE, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { shops: [{ shop: Shop.JUBRUNNAH, cost: [{ price: 500, currency: Currency.POETICS }] }] },
		used: [
			{ relic: [RelicType.WEAPON, RelicGroup.WEAPON_MANDERVILLE, RelicPart.WEAPON_MANDERVILLE, RelicStep.MANDERVILLE, RelicTask.TASK_1], jobs: jobLists.relic[RelicGroup.WEAPON_MANDERVILLE], qtyPerJob: 3 },
		],
	},
	[Item.ODDLY_SPECIFIC_PETRIFIED_ORB_HQ]: {
		name: Item.ODDLY_SPECIFIC_PETRIFIED_ORB_HQ, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.ODDLY_SPECIFIC_PETRIFIED_ORB_HQ, qtyMade: 1, crafter: Job.CRP, level: 80, lodestoneID: "fa41b2bbb4a",
					itemsRequired: [
						{ item: Item.ODDLY_SPECIFIC_PETRIFIED_LOG, qty: 1 },
						{ item: Item.WHITE_ASH_LOG, qty: 1 },
						{ item: Item.WIND_CRYSTAL, qty: 7 },
						{ item: Item.ICE_CRYSTAL, qty: 7 },
					],
				},
			],
		},
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SKYSTEEL, RelicPart.TOOL_SKYSTEEL_1, RelicStep.SKYSTEEL_ITEMS, RelicTask.TASK_1], jobs: [Job.CRP], qtyPerJob: 20 },
		],
	},
	[Item.ODDLY_SPECIFIC_PETRIFIED_LOG]: {
		name: Item.ODDLY_SPECIFIC_PETRIFIED_LOG, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { shops: [{ shop: Shop.ANNA, cost: [{ price: 25, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.DIANA, cost: [{ price: 25, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.MOWEN, cost: [{ price: 25, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SANANA, cost: [{ price: 25, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_EULMORE, cost: [{ price: 25, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_GRIDANIA, cost: [{ price: 25, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_IDYLLSHIRE, cost: [{ price: 25, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_LIMSA, cost: [{ price: 25, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_MOR_DHONA, cost: [{ price: 25, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_SHARLAYAN, cost: [{ price: 25, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_RADZ_AT_HAN, cost: [{ price: 25, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_RHALGRS_REACH, cost: [{ price: 25, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_CRYSTARIUM, cost: [{ price: 25, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_ULDAH, cost: [{ price: 25, currency: Currency.WHITE_CRAFTERS_SCRIP }] }] },
		used: [],
	},
	[Item.WHITE_ASH_LOG]: {
		name: Item.WHITE_ASH_LOG, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { markets: { [Market.UNIVERSALIS]: 27685 }, gathering: [{ found: { location: MapLocation.WOVEN_OATH, x: 0, y: 0 }, method: GatherMethod.LOGGING }] },
		used: [],
	},
	[Item.ODDLY_SPECIFIC_RIVETS_HQ]: {
		name: Item.ODDLY_SPECIFIC_RIVETS_HQ, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.ODDLY_SPECIFIC_RIVETS_HQ, qtyMade: 1, crafter: Job.BSM, level: 80, lodestoneID: "c3dc42e6cef",
					itemsRequired: [
						{ item: Item.ODDLY_SPECIFIC_IRON_SAND, qty: 1 },
						{ item: Item.MANASILVER_SAND, qty: 1 },
						{ item: Item.FIRE_CRYSTAL, qty: 7 },
						{ item: Item.EARTH_CRYSTAL, qty: 7 },
					],
				},
			],
		},
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SKYSTEEL, RelicPart.TOOL_SKYSTEEL_1, RelicStep.SKYSTEEL_ITEMS, RelicTask.TASK_1], jobs: [Job.BSM], qtyPerJob: 20 },
		],
	},
	[Item.ODDLY_SPECIFIC_IRON_SAND]: {
		name: Item.ODDLY_SPECIFIC_IRON_SAND, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { shops: [{ shop: Shop.ANNA, cost: [{ price: 25, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.DIANA, cost: [{ price: 25, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.MOWEN, cost: [{ price: 25, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SANANA, cost: [{ price: 25, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_EULMORE, cost: [{ price: 25, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_GRIDANIA, cost: [{ price: 25, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_IDYLLSHIRE, cost: [{ price: 25, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_LIMSA, cost: [{ price: 25, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_MOR_DHONA, cost: [{ price: 25, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_SHARLAYAN, cost: [{ price: 25, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_RADZ_AT_HAN, cost: [{ price: 25, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_RHALGRS_REACH, cost: [{ price: 25, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_CRYSTARIUM, cost: [{ price: 25, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_ULDAH, cost: [{ price: 25, currency: Currency.WHITE_CRAFTERS_SCRIP }] }] },
		used: [],
	},
	[Item.MANASILVER_SAND]: {
		name: Item.MANASILVER_SAND, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { markets: { [Market.UNIVERSALIS]: 27699 }, gathering: [{ found: { location: MapLocation.LOZATLS_CONQUEST, x: 0, y: 0 }, method: GatherMethod.QUARRYING }] },
		used: [],
	},
	[Item.ODDLY_SPECIFIC_WIRE_HQ]: {
		name: Item.ODDLY_SPECIFIC_WIRE_HQ, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.ODDLY_SPECIFIC_WIRE_HQ, qtyMade: 1, crafter: Job.ARM, level: 80, lodestoneID: "7357b87dbbd",
					itemsRequired: [
						{ item: Item.ODDLY_SPECIFIC_IRON_ORE, qty: 1 },
						{ item: Item.MANASILVER_SAND, qty: 1 },
						{ item: Item.ICE_CRYSTAL, qty: 7 },
						{ item: Item.EARTH_CRYSTAL, qty: 7 },
					],
				},
			],
		},
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SKYSTEEL, RelicPart.TOOL_SKYSTEEL_1, RelicStep.SKYSTEEL_ITEMS, RelicTask.TASK_1], jobs: [Job.ARM], qtyPerJob: 20 },
		],
	},
	[Item.ODDLY_SPECIFIC_IRON_ORE]: {
		name: Item.ODDLY_SPECIFIC_IRON_ORE, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { shops: [{ shop: Shop.ANNA, cost: [{ price: 25, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.DIANA, cost: [{ price: 25, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.MOWEN, cost: [{ price: 25, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SANANA, cost: [{ price: 25, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_EULMORE, cost: [{ price: 25, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_GRIDANIA, cost: [{ price: 25, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_IDYLLSHIRE, cost: [{ price: 25, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_LIMSA, cost: [{ price: 25, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_MOR_DHONA, cost: [{ price: 25, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_SHARLAYAN, cost: [{ price: 25, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_RADZ_AT_HAN, cost: [{ price: 25, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_RHALGRS_REACH, cost: [{ price: 25, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_CRYSTARIUM, cost: [{ price: 25, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_ULDAH, cost: [{ price: 25, currency: Currency.WHITE_CRAFTERS_SCRIP }] }] },
		used: [],
	},
	[Item.ODDLY_SPECIFIC_WHETSTONE_HQ]: {
		name: Item.ODDLY_SPECIFIC_WHETSTONE_HQ, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.ODDLY_SPECIFIC_WHETSTONE_HQ, qtyMade: 1, crafter: Job.GSM, level: 80, lodestoneID: "61e2aac21db",
					itemsRequired: [
						{ item: Item.ODDLY_SPECIFIC_UNCUT_GEMSTONE, qty: 1 },
						{ item: Item.MANASILVER_SAND, qty: 1 },
						{ item: Item.WIND_CRYSTAL, qty: 7 },
						{ item: Item.FIRE_CRYSTAL, qty: 7 },
					],
				},
			],
		},
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SKYSTEEL, RelicPart.TOOL_SKYSTEEL_1, RelicStep.SKYSTEEL_ITEMS, RelicTask.TASK_1], jobs: [Job.GSM], qtyPerJob: 20 },
		],
	},
	[Item.ODDLY_SPECIFIC_UNCUT_GEMSTONE]: {
		name: Item.ODDLY_SPECIFIC_UNCUT_GEMSTONE, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { shops: [{ shop: Shop.ANNA, cost: [{ price: 25, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.DIANA, cost: [{ price: 25, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.MOWEN, cost: [{ price: 25, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SANANA, cost: [{ price: 25, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_EULMORE, cost: [{ price: 25, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_GRIDANIA, cost: [{ price: 25, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_IDYLLSHIRE, cost: [{ price: 25, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_LIMSA, cost: [{ price: 25, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_MOR_DHONA, cost: [{ price: 25, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_SHARLAYAN, cost: [{ price: 25, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_RADZ_AT_HAN, cost: [{ price: 25, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_RHALGRS_REACH, cost: [{ price: 25, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_CRYSTARIUM, cost: [{ price: 25, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_ULDAH, cost: [{ price: 25, currency: Currency.WHITE_CRAFTERS_SCRIP }] }] },
		used: [],
	},
	[Item.ODDLY_SPECIFIC_LEATHER_HQ]: {
		name: Item.ODDLY_SPECIFIC_LEATHER_HQ, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.ODDLY_SPECIFIC_LEATHER_HQ, qtyMade: 1, crafter: Job.LTW, level: 80, lodestoneID: "9571475cb4d",
					itemsRequired: [
						{ item: Item.ODDLY_SPECIFIC_SKIN, qty: 1 },
						{ item: Item.ATROCIRAPTOR_SKIN, qty: 1 },
						{ item: Item.EARTH_CRYSTAL, qty: 7 },
						{ item: Item.WIND_CRYSTAL, qty: 7 },
					],
				},
			],
		},
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SKYSTEEL, RelicPart.TOOL_SKYSTEEL_1, RelicStep.SKYSTEEL_ITEMS, RelicTask.TASK_1], jobs: [Job.LTW], qtyPerJob: 20 },
		],
	},
	[Item.ODDLY_SPECIFIC_SKIN]: {
		name: Item.ODDLY_SPECIFIC_SKIN, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { shops: [{ shop: Shop.ANNA, cost: [{ price: 25, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.DIANA, cost: [{ price: 25, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.MOWEN, cost: [{ price: 25, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SANANA, cost: [{ price: 25, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_EULMORE, cost: [{ price: 25, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_GRIDANIA, cost: [{ price: 25, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_IDYLLSHIRE, cost: [{ price: 25, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_LIMSA, cost: [{ price: 25, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_MOR_DHONA, cost: [{ price: 25, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_SHARLAYAN, cost: [{ price: 25, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_RADZ_AT_HAN, cost: [{ price: 25, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_RHALGRS_REACH, cost: [{ price: 25, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_CRYSTARIUM, cost: [{ price: 25, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_ULDAH, cost: [{ price: 25, currency: Currency.WHITE_CRAFTERS_SCRIP }] }] },
		used: [],
	},
	[Item.ATROCIRAPTOR_SKIN]: {
		name: Item.ATROCIRAPTOR_SKIN, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { markets: { [Market.UNIVERSALIS]: 27734 }, enemies: [Enemy.ATROCIRAPTOR] },
		used: [],
	},
	[Item.ODDLY_SPECIFIC_MOONBEAM_SILK_HQ]: {
		name: Item.ODDLY_SPECIFIC_MOONBEAM_SILK_HQ, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.ODDLY_SPECIFIC_MOONBEAM_SILK_HQ, qtyMade: 1, crafter: Job.WVR, level: 80, lodestoneID: "1c59d8f8897",
					itemsRequired: [
						{ item: Item.ODDLY_SPECIFIC_COTTON, qty: 1 },
						{ item: Item.PIXIE_FLOSS_BOLL, qty: 1 },
						{ item: Item.LIGHTNING_CRYSTAL, qty: 7 },
						{ item: Item.WIND_CRYSTAL, qty: 7 },
					],
				},
			],
		},
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SKYSTEEL, RelicPart.TOOL_SKYSTEEL_1, RelicStep.SKYSTEEL_ITEMS, RelicTask.TASK_1], jobs: [Job.WVR], qtyPerJob: 20 },
		],
	},
	[Item.ODDLY_SPECIFIC_COTTON]: {
		name: Item.ODDLY_SPECIFIC_COTTON, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { shops: [{ shop: Shop.ANNA, cost: [{ price: 25, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.DIANA, cost: [{ price: 25, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.MOWEN, cost: [{ price: 25, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SANANA, cost: [{ price: 25, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_EULMORE, cost: [{ price: 25, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_GRIDANIA, cost: [{ price: 25, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_IDYLLSHIRE, cost: [{ price: 25, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_LIMSA, cost: [{ price: 25, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_MOR_DHONA, cost: [{ price: 25, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_SHARLAYAN, cost: [{ price: 25, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_RADZ_AT_HAN, cost: [{ price: 25, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_RHALGRS_REACH, cost: [{ price: 25, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_CRYSTARIUM, cost: [{ price: 25, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_ULDAH, cost: [{ price: 25, currency: Currency.WHITE_CRAFTERS_SCRIP }] }] },
		used: [],
	},
	[Item.PIXIE_FLOSS_BOLL]: {
		name: Item.PIXIE_FLOSS_BOLL, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { markets: { [Market.UNIVERSALIS]: 27753 }, gathering: [{ found: { location: MapLocation.DELIBERATING_DOLL, x: 0, y: 0 }, method: GatherMethod.HARVESTING }] },
		used: [],
	},
	[Item.ODDLY_SPECIFIC_SYNTHETIC_RESIN_HQ]: {
		name: Item.ODDLY_SPECIFIC_SYNTHETIC_RESIN_HQ, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.ODDLY_SPECIFIC_SYNTHETIC_RESIN_HQ, qtyMade: 1, crafter: Job.ALC, level: 80, lodestoneID: "920cd90653f",
					itemsRequired: [
						{ item: Item.ODDLY_SPECIFIC_QUARTZ, qty: 1 },
						{ item: Item.VAMPIRE_VINE_SAP, qty: 1 },
						{ item: Item.WATER_CRYSTAL, qty: 7 },
						{ item: Item.LIGHTNING_CRYSTAL, qty: 7 },
					],
				},
			],
		},
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SKYSTEEL, RelicPart.TOOL_SKYSTEEL_1, RelicStep.SKYSTEEL_ITEMS, RelicTask.TASK_1], jobs: [Job.ALC], qtyPerJob: 20 },
		],
	},
	[Item.ODDLY_SPECIFIC_QUARTZ]: {
		name: Item.ODDLY_SPECIFIC_QUARTZ, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { shops: [{ shop: Shop.ANNA, cost: [{ price: 25, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.DIANA, cost: [{ price: 25, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.MOWEN, cost: [{ price: 25, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SANANA, cost: [{ price: 25, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_EULMORE, cost: [{ price: 25, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_GRIDANIA, cost: [{ price: 25, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_IDYLLSHIRE, cost: [{ price: 25, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_LIMSA, cost: [{ price: 25, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_MOR_DHONA, cost: [{ price: 25, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_SHARLAYAN, cost: [{ price: 25, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_RADZ_AT_HAN, cost: [{ price: 25, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_RHALGRS_REACH, cost: [{ price: 25, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_CRYSTARIUM, cost: [{ price: 25, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_ULDAH, cost: [{ price: 25, currency: Currency.WHITE_CRAFTERS_SCRIP }] }] },
		used: [],
	},
	[Item.VAMPIRE_VINE_SAP]: {
		name: Item.VAMPIRE_VINE_SAP, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { markets: { [Market.UNIVERSALIS]: 27774 }, shops: [{ shop: Shop.GRAMSOL, cost: [{ price: 1, currency: Currency.BICOLOUR_GEMSTONE }] }, { shop: Shop.NACILLE, cost: [{ price: 1, currency: Currency.BICOLOUR_GEMSTONE }] }, { shop: Shop.PEDRONILLE, cost: [{ price: 1, currency: Currency.BICOLOUR_GEMSTONE }] }], enemies: [Enemy.VAMPIRE_VINE] },
		used: [],
	},
	[Item.ODDLY_SPECIFIC_SEED_EXTRACT_HQ]: {
		name: Item.ODDLY_SPECIFIC_SEED_EXTRACT_HQ, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.ODDLY_SPECIFIC_SEED_EXTRACT_HQ, qtyMade: 1, crafter: Job.CUL, level: 80, lodestoneID: "2bd24d1a30b",
					itemsRequired: [
						{ item: Item.ODDLY_SPECIFIC_SEEDS, qty: 1 },
						{ item: Item.HIGHLAND_SPRING_WATER, qty: 1 },
						{ item: Item.FIRE_CRYSTAL, qty: 7 },
						{ item: Item.WATER_CRYSTAL, qty: 7 },
					],
				},
			],
		},
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SKYSTEEL, RelicPart.TOOL_SKYSTEEL_1, RelicStep.SKYSTEEL_ITEMS, RelicTask.TASK_1], jobs: [Job.CUL], qtyPerJob: 20 },
		],
	},
	[Item.ODDLY_SPECIFIC_SEEDS]: {
		name: Item.ODDLY_SPECIFIC_SEEDS, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { shops: [{ shop: Shop.ANNA, cost: [{ price: 25, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.DIANA, cost: [{ price: 25, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.MOWEN, cost: [{ price: 25, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SANANA, cost: [{ price: 25, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_EULMORE, cost: [{ price: 25, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_GRIDANIA, cost: [{ price: 25, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_IDYLLSHIRE, cost: [{ price: 25, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_LIMSA, cost: [{ price: 25, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_MOR_DHONA, cost: [{ price: 25, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_SHARLAYAN, cost: [{ price: 25, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_RADZ_AT_HAN, cost: [{ price: 25, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_RHALGRS_REACH, cost: [{ price: 25, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_CRYSTARIUM, cost: [{ price: 25, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_ULDAH, cost: [{ price: 25, currency: Currency.WHITE_CRAFTERS_SCRIP }] }] },
		used: [],
	},
	[Item.HIGHLAND_SPRING_WATER]: {
		name: Item.HIGHLAND_SPRING_WATER, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { markets: { [Market.UNIVERSALIS]: 27968 }, gathering: [{ found: { location: MapLocation.BOOKMANS_SHELVES, x: 0, y: 0 }, method: GatherMethod.MINING }] },
		used: [],
	},
	[Item.ODDLY_SPECIFIC_OBSIDIAN]: {
		name: Item.ODDLY_SPECIFIC_OBSIDIAN, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { gathering: [{ found: { location: MapLocation.OK_VUNDU_VANA, x: 0, y: 0 }, method: GatherMethod.MINING }, { found: { location: MapLocation.RED_RIM, x: 0, y: 0 }, method: GatherMethod.MINING }] },
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SKYSTEEL, RelicPart.TOOL_SKYSTEEL_1, RelicStep.SKYSTEEL_ITEMS, RelicTask.TASK_1], jobs: [Job.MIN], qtyPerJob: 340 },
		],
	},
	[Item.ODDLY_SPECIFIC_MINERAL_SAND]: {
		name: Item.ODDLY_SPECIFIC_MINERAL_SAND, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { gathering: [{ found: { location: MapLocation.OK_VUNDU_VANA, x: 0, y: 0 }, method: GatherMethod.MINING }, { found: { location: MapLocation.RED_RIM, x: 0, y: 0 }, method: GatherMethod.MINING }] },
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SKYSTEEL, RelicPart.TOOL_SKYSTEEL_1, RelicStep.SKYSTEEL_ITEMS, RelicTask.TASK_2], jobs: [Job.MIN], qtyPerJob: 120 },
		],
	},
	[Item.ODDLY_SPECIFIC_LATEX]: {
		name: Item.ODDLY_SPECIFIC_LATEX, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { gathering: [{ found: { location: MapLocation.WHILOM_RIVER, x: 0, y: 0 }, method: GatherMethod.LOGGING }, { found: { location: MapLocation.WHALES_CROWN, x: 0, y: 0 }, method: GatherMethod.HARVESTING }] },
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SKYSTEEL, RelicPart.TOOL_SKYSTEEL_1, RelicStep.SKYSTEEL_ITEMS, RelicTask.TASK_1], jobs: [Job.BTN], qtyPerJob: 340 },
		],
	},
	[Item.ODDLY_SPECIFIC_FOSSIL_DUST]: {
		name: Item.ODDLY_SPECIFIC_FOSSIL_DUST, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { gathering: [{ found: { location: MapLocation.WHILOM_RIVER, x: 0, y: 0 }, method: GatherMethod.LOGGING }, { found: { location: MapLocation.WHALES_CROWN, x: 0, y: 0 }, method: GatherMethod.HARVESTING }] },
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SKYSTEEL, RelicPart.TOOL_SKYSTEEL_1, RelicStep.SKYSTEEL_ITEMS, RelicTask.TASK_2], jobs: [Job.BTN], qtyPerJob: 120 },
		],
	},
	[Item.THINKERS_CORAL]: {
		name: Item.THINKERS_CORAL, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { fishingHoles: [{ fish: Fish.THINKERS_CORAL, hole: Fishing.THALIAK_RIVER, bait: [Bait.SKYBALL] }] },
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SKYSTEEL, RelicPart.TOOL_SKYSTEEL_1, RelicStep.SKYSTEEL_ITEMS, RelicTask.TASK_1], jobs: [Job.FSH], qtyPerJob: 40 },
		],
	},
	[Item.ODDLY_SPECIFIC_SHAFT_HQ]: {
		name: Item.ODDLY_SPECIFIC_SHAFT_HQ, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.ODDLY_SPECIFIC_SHAFT_HQ, qtyMade: 1, crafter: Job.CRP, level: 80, lodestoneID: "9f25a343d79",
					itemsRequired: [
						{ item: Item.ODDLY_SPECIFIC_PETRIFIED_LOG, qty: 1 },
						{ item: Item.SANDTEAK_LUMBER, qty: 1 },
						{ item: Item.WIND_CRYSTAL, qty: 7 },
						{ item: Item.ICE_CRYSTAL, qty: 7 },
					],
				},
			],
		},
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SKYSTEEL, RelicPart.TOOL_DRAGONSUNG, RelicStep.DRAGONSUNG_ITEMS, RelicTask.TASK_1], jobs: [Job.CRP], qtyPerJob: 30 },
		],
	},
	[Item.SANDTEAK_LUMBER]: {
		name: Item.SANDTEAK_LUMBER, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.SANDTEAK_LUMBER, qtyMade: 1, crafter: Job.CRP, level: 76, lodestoneID: "ec4fcd3ac53",
					itemsRequired: [
						{ item: Item.SANDTEAK_LOG, qty: 4 },
						{ item: Item.WIND_CRYSTAL, qty: 7 },
					],
				},
			],
			markets: { [Market.UNIVERSALIS]: 27692 },
		},
		used: [],
	},
	[Item.ODDLY_SPECIFIC_FITTING_HQ]: {
		name: Item.ODDLY_SPECIFIC_FITTING_HQ, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.ODDLY_SPECIFIC_FITTING_HQ, qtyMade: 1, crafter: Job.BSM, level: 80, lodestoneID: "668a6a07a53",
					itemsRequired: [
						{ item: Item.ODDLY_SPECIFIC_IRON_SAND, qty: 1 },
						{ item: Item.TITANBRONZE_INGOT, qty: 1 },
						{ item: Item.FIRE_CRYSTAL, qty: 7 },
						{ item: Item.EARTH_CRYSTAL, qty: 7 },
					],
				},
			],
		},
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SKYSTEEL, RelicPart.TOOL_DRAGONSUNG, RelicStep.DRAGONSUNG_ITEMS, RelicTask.TASK_1], jobs: [Job.BSM], qtyPerJob: 30 },
		],
	},
	[Item.TITANBRONZE_INGOT]: {
		name: Item.TITANBRONZE_INGOT, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.TITANBRONZE_INGOT, qtyMade: 1, crafter: Job.BSM, level: 76, lodestoneID: "058898fed1e",
					itemsRequired: [
						{ item: Item.TITANCOPPER_ORE, qty: 4 },
						{ item: Item.TITANIUM_ORE, qty: 1 },
						{ item: Item.FIRE_CRYSTAL, qty: 7 },
					],
				},
				{
					itemMade: Item.TITANBRONZE_INGOT, qtyMade: 1, crafter: Job.ARM, level: 76, lodestoneID: "65cd046a22e",
					itemsRequired: [
						{ item: Item.TITANCOPPER_ORE, qty: 4 },
						{ item: Item.TITANIUM_ORE, qty: 1 },
						{ item: Item.ICE_CRYSTAL, qty: 7 },
					],
				},
			],
			markets: { [Market.UNIVERSALIS]: 27713 },
		},
		used: [],
	},
	[Item.ODDLY_SPECIFIC_CHAINMAIL_SHEET_HQ]: {
		name: Item.ODDLY_SPECIFIC_CHAINMAIL_SHEET_HQ, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.ODDLY_SPECIFIC_CHAINMAIL_SHEET_HQ, qtyMade: 1, crafter: Job.ARM, level: 80, lodestoneID: "af91eefbcf4",
					itemsRequired: [
						{ item: Item.ODDLY_SPECIFIC_IRON_ORE, qty: 1 },
						{ item: Item.TITANBRONZE_INGOT, qty: 1 },
						{ item: Item.ICE_CRYSTAL, qty: 7 },
						{ item: Item.EARTH_CRYSTAL, qty: 7 },
					],
				},
			],
		},
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SKYSTEEL, RelicPart.TOOL_DRAGONSUNG, RelicStep.DRAGONSUNG_ITEMS, RelicTask.TASK_1], jobs: [Job.ARM], qtyPerJob: 30 },
		],
	},
	[Item.ODDLY_SPECIFIC_GEMSTONE_HQ]: {
		name: Item.ODDLY_SPECIFIC_GEMSTONE_HQ, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.ODDLY_SPECIFIC_GEMSTONE_HQ, qtyMade: 1, crafter: Job.GSM, level: 80, lodestoneID: "a4f2e1f8fc2",
					itemsRequired: [
						{ item: Item.ODDLY_SPECIFIC_UNCUT_GEMSTONE, qty: 1 },
						{ item: Item.TUFF_WHETSTONE, qty: 1 },
						{ item: Item.WIND_CRYSTAL, qty: 7 },
						{ item: Item.FIRE_CRYSTAL, qty: 7 },
					],
				},
			],
		},
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SKYSTEEL, RelicPart.TOOL_DRAGONSUNG, RelicStep.DRAGONSUNG_ITEMS, RelicTask.TASK_1], jobs: [Job.GSM], qtyPerJob: 30 },
		],
	},
	[Item.TUFF_WHETSTONE]: {
		name: Item.TUFF_WHETSTONE, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.TUFF_WHETSTONE, qtyMade: 1, crafter: Job.GSM, level: 76, lodestoneID: "28b74a11836",
					itemsRequired: [
						{ item: Item.VOLCANIC_TUFF, qty: 3 },
						{ item: Item.WIND_CRYSTAL, qty: 7 },
					],
				},
			],
			markets: { [Market.UNIVERSALIS]: 27804 },
		},
		used: [],
	},
	[Item.ODDLY_SPECIFIC_VELLUM_HQ]: {
		name: Item.ODDLY_SPECIFIC_VELLUM_HQ, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.ODDLY_SPECIFIC_VELLUM_HQ, qtyMade: 1, crafter: Job.LTW, level: 80, lodestoneID: "bb8187cd885",
					itemsRequired: [
						{ item: Item.ODDLY_SPECIFIC_SKIN, qty: 1 },
						{ item: Item.ZONURE_LEATHER, qty: 1 },
						{ item: Item.EARTH_CRYSTAL, qty: 7 },
						{ item: Item.WIND_CRYSTAL, qty: 7 },
					],
				},
			],
		},
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SKYSTEEL, RelicPart.TOOL_DRAGONSUNG, RelicStep.DRAGONSUNG_ITEMS, RelicTask.TASK_1], jobs: [Job.LTW], qtyPerJob: 30 },
		],
	},
	[Item.ZONURE_LEATHER]: {
		name: Item.ZONURE_LEATHER, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.ZONURE_LEATHER, qtyMade: 1, crafter: Job.LTW, level: 76, lodestoneID: "7f12bd918f1",
					itemsRequired: [
						{ item: Item.ZONURE_SKIN, qty: 4 },
						{ item: Item.YELLOW_ALUMEN, qty: 1 },
						{ item: Item.EARTH_CRYSTAL, qty: 7 },
					],
				},
			],
			markets: { [Market.UNIVERSALIS]: 27741 },
		},
		used: [],
	},
	[Item.ODDLY_SPECIFIC_VELVET_HQ]: {
		name: Item.ODDLY_SPECIFIC_VELVET_HQ, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.ODDLY_SPECIFIC_VELVET_HQ, qtyMade: 1, crafter: Job.WVR, level: 80, lodestoneID: "93c26191232",
					itemsRequired: [
						{ item: Item.ODDLY_SPECIFIC_COTTON, qty: 1 },
						{ item: Item.OVIM_WOOL, qty: 1 },
						{ item: Item.LIGHTNING_CRYSTAL, qty: 7 },
						{ item: Item.WIND_CRYSTAL, qty: 7 },
					],
				},
			],
		},
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SKYSTEEL, RelicPart.TOOL_DRAGONSUNG, RelicStep.DRAGONSUNG_ITEMS, RelicTask.TASK_1], jobs: [Job.WVR], qtyPerJob: 30 },
		],
	},
	[Item.OVIM_WOOL]: {
		name: Item.OVIM_WOOL, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.OVIM_WOOL, qtyMade: 1, crafter: Job.WVR, level: 76, lodestoneID: "b10b1891d15",
					itemsRequired: [
						{ item: Item.OVIM_WOOL_YARN, qty: 3 },
						{ item: Item.LIGHTNING_CRYSTAL, qty: 4 },
					],
				},
			],
			markets: { [Market.UNIVERSALIS]: 27754 },
		},
		used: [],
	},
	[Item.ODDLY_SPECIFIC_GLASS_HQ]: {
		name: Item.ODDLY_SPECIFIC_GLASS_HQ, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.ODDLY_SPECIFIC_GLASS_HQ, qtyMade: 1, crafter: Job.CRP, level: 0, lodestoneID: "b506262a9b9",
					itemsRequired: [
						{ item: Item.ODDLY_SPECIFIC_QUARTZ, qty: 1 },
						{ item: Item.REFINED_NATRON, qty: 1 },
						{ item: Item.WATER_CRYSTAL, qty: 7 },
						{ item: Item.LIGHTNING_CRYSTAL, qty: 7 },
					],
				},
			],
		},
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SKYSTEEL, RelicPart.TOOL_DRAGONSUNG, RelicStep.DRAGONSUNG_ITEMS, RelicTask.TASK_1], jobs: [Job.ALC], qtyPerJob: 30 },
		],
	},
	[Item.REFINED_NATRON]: {
		name: Item.REFINED_NATRON, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.REFINED_NATRON, qtyMade: 1, crafter: Job.ALC, level: 76, lodestoneID: "ea6ca0bd4a6",
					itemsRequired: [
						{ item: Item.EXTRA_EFFERVESCENT_WATER, qty: 1 },
						{ item: Item.ALA_MHIGAN_SALT_CRYSTAL, qty: 1 },
						{ item: Item.WATER_CRYSTAL, qty: 7 },
					],
				},
			],
			markets: { [Market.UNIVERSALIS]: 27777 },
		},
		used: [],
	},
	[Item.ODDLY_SPECIFIC_SEED_FLOUR_HQ]: {
		name: Item.ODDLY_SPECIFIC_SEED_FLOUR_HQ, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.ODDLY_SPECIFIC_SEED_FLOUR_HQ, qtyMade: 1, crafter: Job.CUL, level: 80, lodestoneID: "d2edc823c5c",
					itemsRequired: [
						{ item: Item.ODDLY_SPECIFIC_SEEDS, qty: 1 },
						{ item: Item.NIGHT_VINEGAR, qty: 1 },
						{ item: Item.FIRE_CRYSTAL, qty: 7 },
						{ item: Item.WATER_CRYSTAL, qty: 7 },
					],
				},
			],
		},
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SKYSTEEL, RelicPart.TOOL_DRAGONSUNG, RelicStep.DRAGONSUNG_ITEMS, RelicTask.TASK_1], jobs: [Job.CUL], qtyPerJob: 30 },
		],
	},
	[Item.NIGHT_VINEGAR]: {
		name: Item.NIGHT_VINEGAR, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.NIGHT_VINEGAR, qtyMade: 3, crafter: Job.CUL, level: 75, lodestoneID: "f643e83f867",
					itemsRequired: [
						{ item: Item.ROYAL_GRAPES, qty: 5 },
						{ item: Item.FIRE_CRYSTAL, qty: 7 },
					],
				},
			],
			markets: { [Market.UNIVERSALIS]: 27843 },
		},
		used: [],
	},
	[Item.ODDLY_SPECIFIC_DARK_MATTER]: {
		name: Item.ODDLY_SPECIFIC_DARK_MATTER, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { gathering: [{ found: { location: MapLocation.MOURN, x: 0, y: 0 }, method: GatherMethod.MINING }, { found: { location: MapLocation.DAGGERS, x: 0, y: 0 }, method: GatherMethod.QUARRYING }] },
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SKYSTEEL, RelicPart.TOOL_DRAGONSUNG, RelicStep.DRAGONSUNG_ITEMS, RelicTask.TASK_1], jobs: [Job.MIN], qtyPerJob: 510 },
		],
	},
	[Item.ODDLY_SPECIFIC_STRIKING_STONE]: {
		name: Item.ODDLY_SPECIFIC_STRIKING_STONE, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { gathering: [{ found: { location: MapLocation.MOURN, x: 0, y: 0 }, method: GatherMethod.MINING }, { found: { location: MapLocation.DAGGERS, x: 0, y: 0 }, method: GatherMethod.QUARRYING }] },
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SKYSTEEL, RelicPart.TOOL_DRAGONSUNG, RelicStep.DRAGONSUNG_ITEMS, RelicTask.TASK_2], jobs: [Job.MIN], qtyPerJob: 180 },
		],
	},
	[Item.ODDLY_SPECIFIC_AMBER]: {
		name: Item.ODDLY_SPECIFIC_AMBER, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { gathering: [{ found: { location: MapLocation.FOUR_ARMS, x: 0, y: 0 }, method: GatherMethod.LOGGING }, { found: { location: MapLocation.ASHPOOL, x: 0, y: 0 }, method: GatherMethod.HARVESTING }] },
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SKYSTEEL, RelicPart.TOOL_DRAGONSUNG, RelicStep.DRAGONSUNG_ITEMS, RelicTask.TASK_1], jobs: [Job.BTN], qtyPerJob: 510 },
		],
	},
	[Item.ODDLY_SPECIFIC_BAUBLE]: {
		name: Item.ODDLY_SPECIFIC_BAUBLE, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { gathering: [{ found: { location: MapLocation.FOUR_ARMS, x: 0, y: 0 }, method: GatherMethod.LOGGING }, { found: { location: MapLocation.ASHPOOL, x: 0, y: 0 }, method: GatherMethod.HARVESTING }] },
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SKYSTEEL, RelicPart.TOOL_DRAGONSUNG, RelicStep.DRAGONSUNG_ITEMS, RelicTask.TASK_2], jobs: [Job.BTN], qtyPerJob: 180 },
		],
	},
	[Item.DRAGONSPINE]: {
		name: Item.DRAGONSPINE, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { fishingHoles: [{ fish: Fish.DRAGONSPINE, hole: Fishing.DRAGONSPIT, bait: [Bait.SKYBALL] }] },
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SKYSTEEL, RelicPart.TOOL_DRAGONSUNG, RelicStep.DRAGONSUNG_ITEMS, RelicTask.TASK_1], jobs: [Job.FSH], qtyPerJob: 60 },
		],
	},
	[Item.ODDLY_SPECIFIC_CEDAR_LUMBER]: {
		name: Item.ODDLY_SPECIFIC_CEDAR_LUMBER, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.ODDLY_SPECIFIC_CEDAR_LUMBER, qtyMade: 1, collectable: true, crafter: Job.CRP, level: 80, lodestoneID: "4846abb678f",
					itemsRequired: [
						{ item: Item.ODDLY_SPECIFIC_CEDAR_LOG, qty: 1 },
						{ item: Item.LIGNUM_VITAE_LOG, qty: 2 },
						{ item: Item.WIND_CRYSTAL, qty: 7 },
						{ item: Item.ICE_CRYSTAL, qty: 7 },
					],
				},
			],
		},
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SKYSTEEL, RelicPart.TOOL_AUGMENTED_DRAGONSUNG, RelicStep.BASIC_GOBBIEGOO, RelicTask.TASK_1], jobs: [Job.CRP], qtyPerJob: 18, maxQtyPerJob: 45 },
		],
	},
	[Item.ODDLY_SPECIFIC_CEDAR_LOG]: {
		name: Item.ODDLY_SPECIFIC_CEDAR_LOG, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { shops: [{ shop: Shop.ANNA, cost: [{ price: 30, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.DIANA, cost: [{ price: 30, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.ENIE, cost: [{ price: 20, currency: Currency.SKUBUILDERS_SCRIP }] }, { shop: Shop.MOWEN, cost: [{ price: 30, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SANANA, cost: [{ price: 30, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_EULMORE, cost: [{ price: 30, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_GRIDANIA, cost: [{ price: 30, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_IDYLLSHIRE, cost: [{ price: 30, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_LIMSA, cost: [{ price: 30, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_MOR_DHONA, cost: [{ price: 30, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_SHARLAYAN, cost: [{ price: 30, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_RADZ_AT_HAN, cost: [{ price: 30, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_RHALGRS_REACH, cost: [{ price: 30, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_CRYSTARIUM, cost: [{ price: 30, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_ULDAH, cost: [{ price: 30, currency: Currency.WHITE_CRAFTERS_SCRIP }] }] },
		used: [],
	},
	[Item.LIGNUM_VITAE_LOG]: {
		name: Item.LIGNUM_VITAE_LOG, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { gathering: [{ found: { location: MapLocation.HARE_AMONG_GIANTS, x: 0, y: 0 }, method: GatherMethod.LOGGING }], markets: { [Market.UNIVERSALIS]: 27687 } },
		used: [],
	},
	[Item.ODDLY_SPECIFIC_IRON_NAILS]: {
		name: Item.ODDLY_SPECIFIC_IRON_NAILS, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.ODDLY_SPECIFIC_IRON_NAILS, qtyMade: 1, collectable: true, crafter: Job.BSM, level: 80, lodestoneID: "cbc0a3d606f",
					itemsRequired: [
						{ item: Item.ODDLY_SPECIFIC_COERTHAN_IRON_ORE, qty: 1 },
						{ item: Item.DIMYTHRITE_ORE, qty: 2 },
						{ item: Item.FIRE_CRYSTAL, qty: 7 },
						{ item: Item.EARTH_CRYSTAL, qty: 7 },
					],
				},
			],
		},
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SKYSTEEL, RelicPart.TOOL_AUGMENTED_DRAGONSUNG, RelicStep.BASIC_GOBBIEGOO, RelicTask.TASK_1], jobs: [Job.BSM], qtyPerJob: 18, maxQtyPerJob: 45 },
		],
	},
	[Item.ODDLY_SPECIFIC_COERTHAN_IRON_ORE]: {
		name: Item.ODDLY_SPECIFIC_COERTHAN_IRON_ORE, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { shops: [{ shop: Shop.ANNA, cost: [{ price: 30, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.DIANA, cost: [{ price: 30, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.ENIE, cost: [{ price: 20, currency: Currency.SKUBUILDERS_SCRIP }] }, { shop: Shop.MOWEN, cost: [{ price: 30, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SANANA, cost: [{ price: 30, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_EULMORE, cost: [{ price: 30, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_GRIDANIA, cost: [{ price: 30, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_IDYLLSHIRE, cost: [{ price: 30, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_LIMSA, cost: [{ price: 30, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_MOR_DHONA, cost: [{ price: 30, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_SHARLAYAN, cost: [{ price: 30, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_RADZ_AT_HAN, cost: [{ price: 30, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_RHALGRS_REACH, cost: [{ price: 30, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_CRYSTARIUM, cost: [{ price: 30, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_ULDAH, cost: [{ price: 30, currency: Currency.WHITE_CRAFTERS_SCRIP }] }] },
		used: [],
	},
	[Item.DIMYTHRITE_ORE]: {
		name: Item.DIMYTHRITE_ORE, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { gathering: [{ found: { location: MapLocation.CHURCH_OF_FIRST_LIGHT, x: 0, y: 0 }, method: GatherMethod.MINING }], markets: { [Market.UNIVERSALIS]: 27703 } },
		used: [],
	},
	[Item.ODDLY_SPECIFIC_MYTHRIL_RINGS]: {
		name: Item.ODDLY_SPECIFIC_MYTHRIL_RINGS, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.ODDLY_SPECIFIC_MYTHRIL_RINGS, qtyMade: 1, collectable: true, crafter: Job.ARM, level: 80, lodestoneID: "b59c21a3b35",
					itemsRequired: [
						{ item: Item.ODDLY_SPECIFIC_MYTHRITE_SAND, qty: 1 },
						{ item: Item.DIMYTHRITE_ORE, qty: 2 },
						{ item: Item.ICE_CRYSTAL, qty: 7 },
						{ item: Item.EARTH_CRYSTAL, qty: 7 },
					],
				},
			],
		},
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SKYSTEEL, RelicPart.TOOL_AUGMENTED_DRAGONSUNG, RelicStep.BASIC_GOBBIEGOO, RelicTask.TASK_1], jobs: [Job.ARM], qtyPerJob: 18, maxQtyPerJob: 45 },
		],
	},
	[Item.ODDLY_SPECIFIC_MYTHRITE_SAND]: {
		name: Item.ODDLY_SPECIFIC_MYTHRITE_SAND, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { shops: [{ shop: Shop.ANNA, cost: [{ price: 30, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.DIANA, cost: [{ price: 30, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.ENIE, cost: [{ price: 20, currency: Currency.SKUBUILDERS_SCRIP }] }, { shop: Shop.MOWEN, cost: [{ price: 30, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SANANA, cost: [{ price: 30, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_EULMORE, cost: [{ price: 30, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_GRIDANIA, cost: [{ price: 30, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_IDYLLSHIRE, cost: [{ price: 30, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_LIMSA, cost: [{ price: 30, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_MOR_DHONA, cost: [{ price: 30, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_SHARLAYAN, cost: [{ price: 30, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_RADZ_AT_HAN, cost: [{ price: 30, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_RHALGRS_REACH, cost: [{ price: 30, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_CRYSTARIUM, cost: [{ price: 30, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_ULDAH, cost: [{ price: 30, currency: Currency.WHITE_CRAFTERS_SCRIP }] }] },
		used: [],
	},
	[Item.ODDLY_SPECIFIC_SILVER_NUGGET]: {
		name: Item.ODDLY_SPECIFIC_SILVER_NUGGET, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.ODDLY_SPECIFIC_SILVER_NUGGET, qtyMade: 1, collectable: true, crafter: Job.GSM, level: 80, lodestoneID: "21263e04c67",
					itemsRequired: [
						{ item: Item.ODDLY_SPECIFIC_SILVER_ORE, qty: 1 },
						{ item: Item.DIMYTHRITE_SAND, qty: 2 },
						{ item: Item.WIND_CRYSTAL, qty: 7 },
						{ item: Item.FIRE_CRYSTAL, qty: 7 },
					],
				},
			],
		},
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SKYSTEEL, RelicPart.TOOL_AUGMENTED_DRAGONSUNG, RelicStep.BASIC_GOBBIEGOO, RelicTask.TASK_1], jobs: [Job.GSM], qtyPerJob: 18, maxQtyPerJob: 45 },
		],
	},
	[Item.ODDLY_SPECIFIC_SILVER_ORE]: {
		name: Item.ODDLY_SPECIFIC_SILVER_ORE, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { shops: [{ shop: Shop.ANNA, cost: [{ price: 30, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.DIANA, cost: [{ price: 30, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.ENIE, cost: [{ price: 20, currency: Currency.SKUBUILDERS_SCRIP }] }, { shop: Shop.MOWEN, cost: [{ price: 30, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SANANA, cost: [{ price: 30, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_EULMORE, cost: [{ price: 30, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_GRIDANIA, cost: [{ price: 30, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_IDYLLSHIRE, cost: [{ price: 30, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_LIMSA, cost: [{ price: 30, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_MOR_DHONA, cost: [{ price: 30, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_SHARLAYAN, cost: [{ price: 30, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_RADZ_AT_HAN, cost: [{ price: 30, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_RHALGRS_REACH, cost: [{ price: 30, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_CRYSTARIUM, cost: [{ price: 30, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_ULDAH, cost: [{ price: 30, currency: Currency.WHITE_CRAFTERS_SCRIP }] }] },
		used: [],
	},
	[Item.DIMYTHRITE_SAND]: {
		name: Item.DIMYTHRITE_SAND, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { gathering: [{ found: { location: MapLocation.MOUNT_BIRAN_MINES, x: 17, y: 12 }, method: GatherMethod.QUARRYING }], markets: { [Market.UNIVERSALIS]: 27702 } },
		used: [],
	},
	[Item.ODDLY_SPECIFIC_GAGANASKIN_STRAP]: {
		name: Item.ODDLY_SPECIFIC_GAGANASKIN_STRAP, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.ODDLY_SPECIFIC_GAGANASKIN_STRAP, qtyMade: 1, collectable: true, crafter: Job.LTW, level: 80, lodestoneID: "7b674b922d3",
					itemsRequired: [
						{ item: Item.ODDLY_SPECIFIC_GAGANA_SKIN, qty: 1 },
						{ item: Item.YELLOW_ALUMEN, qty: 2 },
						{ item: Item.EARTH_CRYSTAL, qty: 7 },
						{ item: Item.WIND_CRYSTAL, qty: 7 },
					],
				},
			],
		},
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SKYSTEEL, RelicPart.TOOL_AUGMENTED_DRAGONSUNG, RelicStep.BASIC_GOBBIEGOO, RelicTask.TASK_1], jobs: [Job.LTW], qtyPerJob: 18, maxQtyPerJob: 45 },
		],
	},
	[Item.ODDLY_SPECIFIC_GAGANA_SKIN]: {
		name: Item.ODDLY_SPECIFIC_GAGANA_SKIN, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { shops: [{ shop: Shop.ANNA, cost: [{ price: 30, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.DIANA, cost: [{ price: 30, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.ENIE, cost: [{ price: 20, currency: Currency.SKUBUILDERS_SCRIP }] }, { shop: Shop.MOWEN, cost: [{ price: 30, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SANANA, cost: [{ price: 30, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_EULMORE, cost: [{ price: 30, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_GRIDANIA, cost: [{ price: 30, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_IDYLLSHIRE, cost: [{ price: 30, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_LIMSA, cost: [{ price: 30, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_MOR_DHONA, cost: [{ price: 30, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_SHARLAYAN, cost: [{ price: 30, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_RADZ_AT_HAN, cost: [{ price: 30, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_RHALGRS_REACH, cost: [{ price: 30, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_CRYSTARIUM, cost: [{ price: 30, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_ULDAH, cost: [{ price: 30, currency: Currency.WHITE_CRAFTERS_SCRIP }] }] },
		used: [],
	},
	[Item.YELLOW_ALUMEN]: {
		name: Item.YELLOW_ALUMEN, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { gathering: [{ found: { location: MapLocation.INN_AT_JOURNEYS_HEAD, x: 30, y: 23 }, method: GatherMethod.QUARRYING }], markets: { [Market.UNIVERSALIS]: 27817 } },
		used: [],
	},
	[Item.ODDLY_SPECIFIC_CLOTH]: {
		name: Item.ODDLY_SPECIFIC_CLOTH, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.ODDLY_SPECIFIC_CLOTH, qtyMade: 1, collectable: true, crafter: Job.WVR, level: 80, lodestoneID: "bd3029cb2f6",
					itemsRequired: [
						{ item: Item.ODDLY_SPECIFIC_FLEECE, qty: 1 },
						{ item: Item.DWARVEN_COTTON_BOLL, qty: 2 },
						{ item: Item.LIGHTNING_CRYSTAL, qty: 7 },
						{ item: Item.WIND_CRYSTAL, qty: 7 },
					],
				},
			],
		},
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SKYSTEEL, RelicPart.TOOL_AUGMENTED_DRAGONSUNG, RelicStep.BASIC_GOBBIEGOO, RelicTask.TASK_1], jobs: [Job.WVR], qtyPerJob: 18, maxQtyPerJob: 45 },
		],
	},
	[Item.ODDLY_SPECIFIC_FLEECE]: {
		name: Item.ODDLY_SPECIFIC_FLEECE, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { shops: [{ shop: Shop.ANNA, cost: [{ price: 30, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.DIANA, cost: [{ price: 30, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.ENIE, cost: [{ price: 20, currency: Currency.SKUBUILDERS_SCRIP }] }, { shop: Shop.MOWEN, cost: [{ price: 30, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SANANA, cost: [{ price: 30, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_EULMORE, cost: [{ price: 30, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_GRIDANIA, cost: [{ price: 30, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_IDYLLSHIRE, cost: [{ price: 30, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_LIMSA, cost: [{ price: 30, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_MOR_DHONA, cost: [{ price: 30, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_SHARLAYAN, cost: [{ price: 30, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_RADZ_AT_HAN, cost: [{ price: 30, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_RHALGRS_REACH, cost: [{ price: 30, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_CRYSTARIUM, cost: [{ price: 30, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_ULDAH, cost: [{ price: 30, currency: Currency.WHITE_CRAFTERS_SCRIP }] }] },
		used: [],
	},
	[Item.DWARVEN_COTTON_BOLL]: {
		name: Item.DWARVEN_COTTON_BOLL, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { gathering: [{ found: { location: MapLocation.SCREE, x: 14, y: 14 }, method: GatherMethod.HARVESTING }], markets: { [Market.UNIVERSALIS]: 27759 } },
		used: [],
	},
	[Item.ODDLY_SPECIFIC_GLUE]: {
		name: Item.ODDLY_SPECIFIC_GLUE, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.ODDLY_SPECIFIC_GLUE, qtyMade: 1, collectable: true, crafter: Job.ALC, level: 80, lodestoneID: "cccb30edae4",
					itemsRequired: [
						{ item: Item.ODDLY_SPECIFIC_SAP, qty: 1 },
						{ item: Item.VAMPIRE_CUP_VINE, qty: 2 },
						{ item: Item.WATER_CRYSTAL, qty: 7 },
						{ item: Item.LIGHTNING_CRYSTAL, qty: 7 },
					],
				},
			],
		},
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SKYSTEEL, RelicPart.TOOL_AUGMENTED_DRAGONSUNG, RelicStep.BASIC_GOBBIEGOO, RelicTask.TASK_1], jobs: [Job.ALC], qtyPerJob: 18, maxQtyPerJob: 45 },
		],
	},
	[Item.ODDLY_SPECIFIC_SAP]: {
		name: Item.ODDLY_SPECIFIC_SAP, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { shops: [{ shop: Shop.ANNA, cost: [{ price: 30, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.DIANA, cost: [{ price: 30, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.ENIE, cost: [{ price: 20, currency: Currency.SKUBUILDERS_SCRIP }] }, { shop: Shop.MOWEN, cost: [{ price: 30, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SANANA, cost: [{ price: 30, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_EULMORE, cost: [{ price: 30, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_GRIDANIA, cost: [{ price: 30, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_IDYLLSHIRE, cost: [{ price: 30, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_LIMSA, cost: [{ price: 30, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_MOR_DHONA, cost: [{ price: 30, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_SHARLAYAN, cost: [{ price: 30, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_RADZ_AT_HAN, cost: [{ price: 30, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_RHALGRS_REACH, cost: [{ price: 30, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_CRYSTARIUM, cost: [{ price: 30, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_ULDAH, cost: [{ price: 30, currency: Currency.WHITE_CRAFTERS_SCRIP }] }] },
		used: [],
	},
	[Item.VAMPIRE_CUP_VINE]: {
		name: Item.VAMPIRE_CUP_VINE, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { enemies: [Enemy.VAMPIRE_CUP], shops: [{ shop: Shop.GRAMSOL, cost: [{ price: 1, currency: Currency.BICOLOUR_GEMSTONE }] }, { shop: Shop.NACILLE, cost: [{ price: 1, currency: Currency.BICOLOUR_GEMSTONE }] }, { shop: Shop.PEDRONILLE, cost: [{ price: 1, currency: Currency.BICOLOUR_GEMSTONE }] }], markets: { [Market.UNIVERSALIS]: 27773 } },
		used: [],
	},
	[Item.ODDLY_SPECIFIC_OIL]: {
		name: Item.ODDLY_SPECIFIC_OIL, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.ODDLY_SPECIFIC_OIL, qtyMade: 1, collectable: true, crafter: Job.CUL, level: 80, lodestoneID: "1d374634082",
					itemsRequired: [
						{ item: Item.ODDLY_SPECIFIC_ALOE, qty: 1 },
						{ item: Item.FRANTOIO, qty: 2 },
						{ item: Item.FIRE_CRYSTAL, qty: 7 },
						{ item: Item.WATER_CRYSTAL, qty: 7 },
					],
				},
			],
		},
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SKYSTEEL, RelicPart.TOOL_AUGMENTED_DRAGONSUNG, RelicStep.BASIC_GOBBIEGOO, RelicTask.TASK_1], jobs: [Job.CUL], qtyPerJob: 18, maxQtyPerJob: 45 },
		],
	},
	[Item.ODDLY_SPECIFIC_ALOE]: {
		name: Item.ODDLY_SPECIFIC_ALOE, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { shops: [{ shop: Shop.ANNA, cost: [{ price: 30, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.DIANA, cost: [{ price: 30, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.ENIE, cost: [{ price: 20, currency: Currency.SKUBUILDERS_SCRIP }] }, { shop: Shop.MOWEN, cost: [{ price: 30, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SANANA, cost: [{ price: 30, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_EULMORE, cost: [{ price: 30, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_GRIDANIA, cost: [{ price: 30, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_IDYLLSHIRE, cost: [{ price: 30, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_LIMSA, cost: [{ price: 30, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_MOR_DHONA, cost: [{ price: 30, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_SHARLAYAN, cost: [{ price: 30, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_RADZ_AT_HAN, cost: [{ price: 30, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_RHALGRS_REACH, cost: [{ price: 30, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_CRYSTARIUM, cost: [{ price: 30, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_ULDAH, cost: [{ price: 30, currency: Currency.WHITE_CRAFTERS_SCRIP }] }] },
		used: [],
	},
	[Item.FRANTOIO]: {
		name: Item.FRANTOIO, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { gathering: [{ found: { location: MapLocation.CHILIAD, x: 0, y: 0 }, method: GatherMethod.LOGGING }], markets: { [Market.UNIVERSALIS]: 27820 } },
		used: [],
	},
	[Item.ODDLY_SPECIFIC_SCHORL]: {
		name: Item.ODDLY_SPECIFIC_SCHORL, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { gathering: [{ found: { location: MapLocation.DRAGONS_STRUGGLE, x: 0, y: 0 }, method: GatherMethod.MINING }, { found: { location: MapLocation.PAUPERS_LODE, x: 0, y: 0 }, method: GatherMethod.MINING }] },
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SKYSTEEL, RelicPart.TOOL_AUGMENTED_DRAGONSUNG, RelicStep.BASIC_GOBBIEGOO, RelicTask.TASK_1], jobs: [Job.MIN], qtyPerJob: 500 },
		],
	},
	[Item.ODDLY_SPECIFIC_LANDBORNE_AETHERSAND]: {
		name: Item.ODDLY_SPECIFIC_LANDBORNE_AETHERSAND, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { gathering: [{ found: { location: MapLocation.DRAGONS_STRUGGLE, x: 0, y: 0 }, method: GatherMethod.MINING }, { found: { location: MapLocation.PAUPERS_LODE, x: 0, y: 0 }, method: GatherMethod.MINING }] },
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SKYSTEEL, RelicPart.TOOL_AUGMENTED_DRAGONSUNG, RelicStep.BASIC_GOBBIEGOO, RelicTask.TASK_2], jobs: [Job.MIN], qtyPerJob: 180 },
		],
	},
	[Item.ODDLY_SPECIFIC_DARK_CHESTNUT_LOG]: {
		name: Item.ODDLY_SPECIFIC_DARK_CHESTNUT_LOG, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { gathering: [{ found: { location: MapLocation.DESTROYER_LOCHS, x: 0, y: 0 }, method: GatherMethod.LOGGING }, { found: { location: MapLocation.VALLEY_OF_THE_FALLEN_RAINBOW, x: 0, y: 0 }, method: GatherMethod.LOGGING }] },
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SKYSTEEL, RelicPart.TOOL_AUGMENTED_DRAGONSUNG, RelicStep.BASIC_GOBBIEGOO, RelicTask.TASK_1], jobs: [Job.BTN], qtyPerJob: 500 },
		],
	},
	[Item.ODDLY_SPECIFIC_LEAFBORNE_AETHERSAND]: {
		name: Item.ODDLY_SPECIFIC_LEAFBORNE_AETHERSAND, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { gathering: [{ found: { location: MapLocation.DESTROYER_LOCHS, x: 0, y: 0 }, method: GatherMethod.LOGGING }, { found: { location: MapLocation.VALLEY_OF_THE_FALLEN_RAINBOW, x: 0, y: 0 }, method: GatherMethod.LOGGING }] },
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SKYSTEEL, RelicPart.TOOL_AUGMENTED_DRAGONSUNG, RelicStep.BASIC_GOBBIEGOO, RelicTask.TASK_2], jobs: [Job.BTN], qtyPerJob: 180 },
		],
	},
	[Item.PETAL_SHELL]: {
		name: Item.PETAL_SHELL, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { fishingHoles: [{ fish: Fish.PETAL_SHELL, hole: Fishing.PLUM_SPRING, bait: [Bait.SKYBALL] }] },
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SKYSTEEL, RelicPart.TOOL_AUGMENTED_DRAGONSUNG, RelicStep.BASIC_GOBBIEGOO, RelicTask.TASK_1], jobs: [Job.FSH], qtyPerJob: 60 },
		],
	},
	[Item.ODDLY_SPECIFIC_CEDAR_PLANK]: {
		name: Item.ODDLY_SPECIFIC_CEDAR_PLANK, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.ODDLY_SPECIFIC_CEDAR_PLANK, qtyMade: 1, collectable: true, crafter: Job.CRP, level: 80, lodestoneID: "291a61b19e4",
					itemsRequired: [
						{ item: Item.ODDLY_SPECIFIC_CEDAR_LOG, qty: 1 },
						{ item: Item.LIGNUM_VITAE_LUMBER, qty: 1 },
						{ item: Item.WIND_CRYSTAL, qty: 7 },
						{ item: Item.ICE_CRYSTAL, qty: 7 },
					],
				},
			],
		},
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SKYSTEEL, RelicPart.TOOL_SKYSUNG, RelicStep.VISCOUS_GOBBIEGOO, RelicTask.TASK_1], jobs: [Job.CRP], qtyPerJob: 21, maxQtyPerJob: 53 },
		],
	},
	[Item.LIGNUM_VITAE_LUMBER]: {
		name: Item.LIGNUM_VITAE_LUMBER, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.LIGNUM_VITAE_LUMBER, qtyMade: 1, crafter: Job.CRP, level: 78, lodestoneID: "90cb971f86d",
					itemsRequired: [
						{ item: Item.LIGNUM_VITAE_LOG, qty: 4 },
						{ item: Item.WIND_CRYSTAL, qty: 7 },
					],
				},
			],
			markets: { [Market.UNIVERSALIS]: 27693 },
		},
		used: [],
	},
	[Item.ODDLY_SPECIFIC_IRON_INGOT]: {
		name: Item.ODDLY_SPECIFIC_IRON_INGOT, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.ODDLY_SPECIFIC_IRON_INGOT, qtyMade: 1, collectable: true, crafter: Job.BSM, level: 80, lodestoneID: "5e4bd73a73d",
					itemsRequired: [
						{ item: Item.ODDLY_SPECIFIC_COERTHAN_IRON_ORE, qty: 1 },
						{ item: Item.DWARVEN_MYTHRIL_INGOT, qty: 1 },
						{ item: Item.FIRE_CRYSTAL, qty: 7 },
						{ item: Item.EARTH_CRYSTAL, qty: 7 },
					],
				},
			],
		},
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SKYSTEEL, RelicPart.TOOL_SKYSUNG, RelicStep.VISCOUS_GOBBIEGOO, RelicTask.TASK_1], jobs: [Job.BSM], qtyPerJob: 21, maxQtyPerJob: 53 },
		],
	},
	[Item.DWARVEN_MYTHRIL_INGOT]: {
		name: Item.DWARVEN_MYTHRIL_INGOT, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.DWARVEN_MYTHRIL_INGOT, qtyMade: 1, crafter: Job.BSM, level: 78, lodestoneID: "47184e5257d",
					itemsRequired: [
						{ item: Item.DIMYTHRITE_ORE, qty: 4 },
						{ item: Item.MYTHRITE_ORE, qty: 1 },
						{ item: Item.FIRE_CRYSTAL, qty: 7 },
					],
				},
				{
					itemMade: Item.DWARVEN_MYTHRIL_INGOT, qtyMade: 1, crafter: Job.ARM, level: 78, lodestoneID: "95be9660f8d",
					itemsRequired: [
						{ item: Item.DIMYTHRITE_ORE, qty: 4 },
						{ item: Item.MYTHRITE_ORE, qty: 1 },
						{ item: Item.ICE_CRYSTAL, qty: 7 },
					],
				},
			],
			markets: { [Market.UNIVERSALIS]: 27714 },
		},
		used: [],
	},
	[Item.ODDLY_SPECIFIC_MYTHRIL_PLATE]: {
		name: Item.ODDLY_SPECIFIC_MYTHRIL_PLATE, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.ODDLY_SPECIFIC_MYTHRIL_PLATE, qtyMade: 1, collectable: true, crafter: Job.ARM, level: 80, lodestoneID: "e70abfd01e5",
					itemsRequired: [
						{ item: Item.ODDLY_SPECIFIC_MYTHRITE_SAND, qty: 1 },
						{ item: Item.DWARVEN_MYTHRIL_INGOT, qty: 1 },
						{ item: Item.ICE_CRYSTAL, qty: 7 },
						{ item: Item.EARTH_CRYSTAL, qty: 7 },
					],
				},
			],
		},
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SKYSTEEL, RelicPart.TOOL_SKYSUNG, RelicStep.VISCOUS_GOBBIEGOO, RelicTask.TASK_1], jobs: [Job.ARM], qtyPerJob: 21, maxQtyPerJob: 53 },
		],
	},
	[Item.ODDLY_SPECIFIC_SILVER_INGOT]: {
		name: Item.ODDLY_SPECIFIC_SILVER_INGOT, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.ODDLY_SPECIFIC_SILVER_INGOT, qtyMade: 1, collectable: true, crafter: Job.GSM, level: 80, lodestoneID: "a2c56402703",
					itemsRequired: [
						{ item: Item.ODDLY_SPECIFIC_SILVER_ORE, qty: 1 },
						{ item: Item.DWARVEN_MYTHRIL_NUGGET, qty: 1 },
						{ item: Item.WIND_CRYSTAL, qty: 7 },
						{ item: Item.FIRE_CRYSTAL, qty: 7 },
					],
				},
			],
		},
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SKYSTEEL, RelicPart.TOOL_SKYSUNG, RelicStep.VISCOUS_GOBBIEGOO, RelicTask.TASK_1], jobs: [Job.GSM], qtyPerJob: 21, maxQtyPerJob: 53 },
		],
	},
	[Item.DWARVEN_MYTHRIL_NUGGET]: {
		name: Item.DWARVEN_MYTHRIL_NUGGET, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.DWARVEN_MYTHRIL_NUGGET, qtyMade: 1, crafter: Job.GSM, level: 78, lodestoneID: "eb9829c05b4",
					itemsRequired: [
						{ item: Item.DIMYTHRITE_SAND, qty: 4 },
						{ item: Item.MYTHRITE_SAND, qty: 1 },
						{ item: Item.WIND_CRYSTAL, qty: 7 },
					],
				},
			],
			markets: { [Market.UNIVERSALIS]: 27715 },
		},
		used: [],
	},
	[Item.ODDLY_SPECIFIC_GAGANA_LEATHER]: {
		name: Item.ODDLY_SPECIFIC_GAGANA_LEATHER, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.ODDLY_SPECIFIC_GAGANA_LEATHER, qtyMade: 1, collectable: true, crafter: Job.LTW, level: 80, lodestoneID: "e177e170ac8",
					itemsRequired: [
						{ item: Item.ODDLY_SPECIFIC_GAGANA_SKIN, qty: 1 },
						{ item: Item.SEA_SWALLOW_LEATHER, qty: 1 },
						{ item: Item.EARTH_CRYSTAL, qty: 7 },
						{ item: Item.WIND_CRYSTAL, qty: 7 },
					],
				},
			],
		},
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SKYSTEEL, RelicPart.TOOL_SKYSUNG, RelicStep.VISCOUS_GOBBIEGOO, RelicTask.TASK_1], jobs: [Job.LTW], qtyPerJob: 21, maxQtyPerJob: 53 },
		],
	},
	[Item.SEA_SWALLOW_LEATHER]: {
		name: Item.SEA_SWALLOW_LEATHER, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.SEA_SWALLOW_LEATHER, qtyMade: 1, crafter: Job.LTW, level: 78, lodestoneID: "cb1c3b94199",
					itemsRequired: [
						{ item: Item.SEA_SWALLOW_SKIN, qty: 4 },
						{ item: Item.YELLOW_ALUMEN, qty: 1 },
						{ item: Item.EARTH_CRYSTAL, qty: 7 },
					],
				},
			],
			markets: { [Market.UNIVERSALIS]: 27742 },
		},
		used: [],
	},
	[Item.ODDLY_SPECIFIC_UNDYED_WOOLEN_CLOTH]: {
		name: Item.ODDLY_SPECIFIC_UNDYED_WOOLEN_CLOTH, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.ODDLY_SPECIFIC_UNDYED_WOOLEN_CLOTH, qtyMade: 1, collectable: true, crafter: Job.WVR, level: 80, lodestoneID: "3e98b055df8",
					itemsRequired: [
						{ item: Item.ODDLY_SPECIFIC_FLEECE, qty: 1 },
						{ item: Item.DWARVEN_COTTON, qty: 1 },
						{ item: Item.LIGHTNING_CRYSTAL, qty: 7 },
						{ item: Item.WIND_CRYSTAL, qty: 7 },
					],
				},
			],
		},
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SKYSTEEL, RelicPart.TOOL_SKYSUNG, RelicStep.VISCOUS_GOBBIEGOO, RelicTask.TASK_1], jobs: [Job.WVR], qtyPerJob: 21, maxQtyPerJob: 53 },
		],
	},
	[Item.DWARVEN_COTTON]: {
		name: Item.DWARVEN_COTTON, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.DWARVEN_COTTON, qtyMade: 1, crafter: Job.WVR, level: 78, lodestoneID: "da17b5d43e4",
					itemsRequired: [
						{ item: Item.DWARVEN_COTTON_THREAD, qty: 3 },
						{ item: Item.LIGHTNING_CRYSTAL, qty: 4 },
					],
				},
			],
			markets: { [Market.UNIVERSALIS]: 27757 },
		},
		used: [],
	},
	[Item.ODDLY_SPECIFIC_RUBBER]: {
		name: Item.ODDLY_SPECIFIC_RUBBER, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.ODDLY_SPECIFIC_RUBBER, qtyMade: 1, collectable: true, crafter: Job.ALC, level: 80, lodestoneID: "48b151c003f",
					itemsRequired: [
						{ item: Item.ODDLY_SPECIFIC_SAP, qty: 1 },
						{ item: Item.REFINED_NATRON, qty: 1 },
						{ item: Item.WATER_CRYSTAL, qty: 7 },
						{ item: Item.LIGHTNING_CRYSTAL, qty: 7 },
					],
				},
			],
		},
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SKYSTEEL, RelicPart.TOOL_SKYSUNG, RelicStep.VISCOUS_GOBBIEGOO, RelicTask.TASK_1], jobs: [Job.ALC], qtyPerJob: 21, maxQtyPerJob: 53 },
		],
	},
	[Item.ODDLY_SPECIFIC_PASTE]: {
		name: Item.ODDLY_SPECIFIC_PASTE, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.ODDLY_SPECIFIC_PASTE, qtyMade: 1, collectable: true, crafter: Job.CUL, level: 80, lodestoneID: "e7d9c343ab6",
					itemsRequired: [
						{ item: Item.ODDLY_SPECIFIC_ALOE, qty: 1 },
						{ item: Item.NIGHT_VINEGAR, qty: 1 },
						{ item: Item.FIRE_CRYSTAL, qty: 7 },
						{ item: Item.WATER_CRYSTAL, qty: 7 },
					],
				},
			],
		},
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SKYSTEEL, RelicPart.TOOL_SKYSUNG, RelicStep.VISCOUS_GOBBIEGOO, RelicTask.TASK_1], jobs: [Job.CUL], qtyPerJob: 21, maxQtyPerJob: 53 },
		],
	},
	[Item.ODDLY_SPECIFIC_PRIMORDIAL_ORE]: {
		name: Item.ODDLY_SPECIFIC_PRIMORDIAL_ORE, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { gathering: [{ found: { location: MapLocation.COOLING_STATION, x: 0, y: 0 }, method: GatherMethod.MINING }, { found: { location: MapLocation.AQUEDUCT, x: 0, y: 0 }, method: GatherMethod.MINING }] },
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SKYSTEEL, RelicPart.TOOL_SKYSUNG, RelicStep.VISCOUS_GOBBIEGOO, RelicTask.TASK_1], jobs: [Job.MIN], qtyPerJob: 600 },
		],
	},
	[Item.ODDLY_SPECIFIC_PRIMORDIAL_ASPHALTUM]: {
		name: Item.ODDLY_SPECIFIC_PRIMORDIAL_ASPHALTUM, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { gathering: [{ found: { location: MapLocation.COOLING_STATION, x: 0, y: 0 }, method: GatherMethod.MINING }, { found: { location: MapLocation.AQUEDUCT, x: 0, y: 0 }, method: GatherMethod.MINING }] },
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SKYSTEEL, RelicPart.TOOL_SKYSUNG, RelicStep.VISCOUS_GOBBIEGOO, RelicTask.TASK_2], jobs: [Job.MIN], qtyPerJob: 200 },
		],
	},
	[Item.ODDLY_SPECIFIC_PRIMORDIAL_LOG]: {
		name: Item.ODDLY_SPECIFIC_PRIMORDIAL_LOG, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { gathering: [{ found: { location: MapLocation.HYPERSTELLAR_DOWNCONVERTER, x: 0, y: 0 }, method: GatherMethod.LOGGING }, { found: { location: MapLocation.PAPPUS_TREE, x: 0, y: 0 }, method: GatherMethod.LOGGING }] },
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SKYSTEEL, RelicPart.TOOL_SKYSUNG, RelicStep.VISCOUS_GOBBIEGOO, RelicTask.TASK_1], jobs: [Job.BTN], qtyPerJob: 600 },
		],
	},
	[Item.ODDLY_SPECIFIC_PRIMORDIAL_RESIN]: {
		name: Item.ODDLY_SPECIFIC_PRIMORDIAL_RESIN, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { gathering: [{ found: { location: MapLocation.HYPERSTELLAR_DOWNCONVERTER, x: 0, y: 0 }, method: GatherMethod.LOGGING }, { found: { location: MapLocation.PAPPUS_TREE, x: 0, y: 0 }, method: GatherMethod.LOGGING }] },
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SKYSTEEL, RelicPart.TOOL_SKYSUNG, RelicStep.VISCOUS_GOBBIEGOO, RelicTask.TASK_2], jobs: [Job.BTN], qtyPerJob: 200 },
		],
	},
	[Item.ALLAGAN_HUNTER]: {
		name: Item.ALLAGAN_HUNTER, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { fishingHoles: [{ fish: Fish.ALLAGAN_HUNTER, hole: Fishing.ALPHA_QUADRANT, bait: [Bait.SKYBALL] }] },
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SKYSTEEL, RelicPart.TOOL_SKYSUNG, RelicStep.VISCOUS_GOBBIEGOO, RelicTask.TASK_1], jobs: [Job.FSH], qtyPerJob: 70 },
		],
	},
	[Item.ODDLY_DELICATE_PINE_LUMBER]: {
		name: Item.ODDLY_DELICATE_PINE_LUMBER, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.ODDLY_DELICATE_PINE_LUMBER, qtyMade: 1, collectable: true, crafter: Job.CRP, level: 80, lodestoneID: "3e98b055df8",
					itemsRequired: [
						{ item: Item.ODDLY_DELICATE_PINE_LOG, qty: 1 },
						{ item: Item.GRADE_4_ARTISANAL_SKYBUILDERS_LOG, qty: 5 },
						{ item: Item.GRADE_4_ARTISANAL_SKYBUILDERS_BARBGRASS, qty: 5 },
						{ item: Item.WIND_CLUSTER, qty: 3 },
						{ item: Item.ICE_CLUSTER, qty: 3 },
					],
				},
			],
		},
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SKYSTEEL, RelicPart.TOOL_SKYBUILDERS, RelicStep.PARTS, RelicTask.TASK_1], jobs: [Job.CRP], qtyPerJob: 20, maxQtyPerJob: 60 },
		],
	},
	[Item.ODDLY_DELICATE_PINE_LOG]: {
		name: Item.ODDLY_DELICATE_PINE_LOG, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { shops: [{ shop: Shop.ANNA, cost: [{ price: 45, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.DIANA, cost: [{ price: 45, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.ENIE, cost: [{ price: 30, currency: Currency.SKUBUILDERS_SCRIP }] }, { shop: Shop.MOWEN, cost: [{ price: 45, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SANANA, cost: [{ price: 45, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_EULMORE, cost: [{ price: 45, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_GRIDANIA, cost: [{ price: 45, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_IDYLLSHIRE, cost: [{ price: 45, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_LIMSA, cost: [{ price: 45, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_MOR_DHONA, cost: [{ price: 45, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_SHARLAYAN, cost: [{ price: 45, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_RADZ_AT_HAN, cost: [{ price: 45, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_RHALGRS_REACH, cost: [{ price: 45, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_CRYSTARIUM, cost: [{ price: 45, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_ULDAH, cost: [{ price: 45, currency: Currency.WHITE_CRAFTERS_SCRIP }] }] },
		used: [],
	},
	[Item.GRADE_4_ARTISANAL_SKYBUILDERS_LOG]: {
		name: Item.GRADE_4_ARTISANAL_SKYBUILDERS_LOG, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { markets: { [Market.UNIVERSALIS]: 31991 }, enemies: [Enemy.DIADEM_MELIA], gathering: [{ found: { location: MapLocation.DIADEM, x: 0, y: 0 }, method: GatherMethod.LOGGING }] },
		used: [],
	},
	[Item.GRADE_4_ARTISANAL_SKYBUILDERS_BARBGRASS]: {
		name: Item.GRADE_4_ARTISANAL_SKYBUILDERS_BARBGRASS, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { markets: { [Market.UNIVERSALIS]: 31995 }, enemies: [Enemy.DIADEM_ICETRAP], gathering: [{ found: { location: MapLocation.DIADEM, x: 0, y: 0 }, method: GatherMethod.HARVESTING }] },
		used: [],
	},
	[Item.ODDLY_DELICATE_SILVER_GEAR]: {
		name: Item.ODDLY_DELICATE_SILVER_GEAR, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.ODDLY_DELICATE_SILVER_GEAR, qtyMade: 1, collectable: true, crafter: Job.BSM, level: 80, lodestoneID: "ed142cbb422",
					itemsRequired: [
						{ item: Item.ODDLY_DELICATE_SILVERGRACE_ORE, qty: 1 },
						{ item: Item.GRADE_4_ARTISANAL_SKYBUILDERS_CLOUDSTONE, qty: 5 },
						{ item: Item.GRADE_4_ARTISANAL_SKYBUILDERS_SILEX, qty: 5 },
						{ item: Item.FIRE_CLUSTER, qty: 3 },
						{ item: Item.EARTH_CLUSTER, qty: 3 },
					],
				},
			],
		},
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SKYSTEEL, RelicPart.TOOL_SKYBUILDERS, RelicStep.PARTS, RelicTask.TASK_1], jobs: [Job.BSM], qtyPerJob: 20, maxQtyPerJob: 60 },
		],
	},
	[Item.ODDLY_DELICATE_SILVERGRACE_ORE]: {
		name: Item.ODDLY_DELICATE_SILVERGRACE_ORE, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { shops: [{ shop: Shop.ANNA, cost: [{ price: 45, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.DIANA, cost: [{ price: 45, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.ENIE, cost: [{ price: 30, currency: Currency.SKUBUILDERS_SCRIP }] }, { shop: Shop.MOWEN, cost: [{ price: 45, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SANANA, cost: [{ price: 45, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_EULMORE, cost: [{ price: 45, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_GRIDANIA, cost: [{ price: 45, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_IDYLLSHIRE, cost: [{ price: 45, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_LIMSA, cost: [{ price: 45, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_MOR_DHONA, cost: [{ price: 45, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_SHARLAYAN, cost: [{ price: 45, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_RADZ_AT_HAN, cost: [{ price: 45, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_RHALGRS_REACH, cost: [{ price: 45, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_CRYSTARIUM, cost: [{ price: 45, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_ULDAH, cost: [{ price: 45, currency: Currency.WHITE_CRAFTERS_SCRIP }] }] },
		used: [],
	},
	[Item.GRADE_4_ARTISANAL_SKYBUILDERS_CLOUDSTONE]: {
		name: Item.GRADE_4_ARTISANAL_SKYBUILDERS_CLOUDSTONE, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { markets: { [Market.UNIVERSALIS]: 31996 }, gathering: [{ found: { location: MapLocation.DIADEM, x: 0, y: 0 }, method: GatherMethod.MINING }] },
		used: [],
	},
	[Item.GRADE_4_ARTISANAL_SKYBUILDERS_SILEX]: {
		name: Item.GRADE_4_ARTISANAL_SKYBUILDERS_SILEX, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { markets: { [Market.UNIVERSALIS]: 31999 }, enemies: [Enemy.DIADEM_ICE_BOMB], gathering: [{ found: { location: MapLocation.DIADEM, x: 0, y: 0 }, method: GatherMethod.QUARRYING }] },
		used: [],
	},
	[Item.ODDLY_DELICATE_WOLFRAM_SQUARE]: {
		name: Item.ODDLY_DELICATE_WOLFRAM_SQUARE, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.ODDLY_DELICATE_WOLFRAM_SQUARE, qtyMade: 1, collectable: true, crafter: Job.ARM, level: 80, lodestoneID: "0df59d11e84",
					itemsRequired: [
						{ item: Item.ODDLY_DELICATE_SCHEELITE, qty: 1 },
						{ item: Item.GRADE_4_ARTISANAL_SKYBUILDERS_CLOUDSTONE, qty: 5 },
						{ item: Item.GRADE_4_ARTISANAL_SKYBUILDERS_PRISMSTONE, qty: 5 },
						{ item: Item.ICE_CLUSTER, qty: 3 },
						{ item: Item.EARTH_CLUSTER, qty: 3 },
					],
				},
			],
		},
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SKYSTEEL, RelicPart.TOOL_SKYBUILDERS, RelicStep.PARTS, RelicTask.TASK_1], jobs: [Job.ARM], qtyPerJob: 20, maxQtyPerJob: 60 },
		],
	},
	[Item.ODDLY_DELICATE_SCHEELITE]: {
		name: Item.ODDLY_DELICATE_SCHEELITE, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { shops: [{ shop: Shop.ANNA, cost: [{ price: 45, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.DIANA, cost: [{ price: 45, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.ENIE, cost: [{ price: 30, currency: Currency.SKUBUILDERS_SCRIP }] }, { shop: Shop.MOWEN, cost: [{ price: 45, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SANANA, cost: [{ price: 45, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_EULMORE, cost: [{ price: 45, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_GRIDANIA, cost: [{ price: 45, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_IDYLLSHIRE, cost: [{ price: 45, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_LIMSA, cost: [{ price: 45, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_MOR_DHONA, cost: [{ price: 45, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_SHARLAYAN, cost: [{ price: 45, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_RADZ_AT_HAN, cost: [{ price: 45, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_RHALGRS_REACH, cost: [{ price: 45, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_CRYSTARIUM, cost: [{ price: 45, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_ULDAH, cost: [{ price: 45, currency: Currency.WHITE_CRAFTERS_SCRIP }] }] },
		used: [],
	},
	[Item.GRADE_4_ARTISANAL_SKYBUILDERS_PRISMSTONE]: {
		name: Item.GRADE_4_ARTISANAL_SKYBUILDERS_PRISMSTONE, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { markets: { [Market.UNIVERSALIS]: 32000 }, enemies: [Enemy.DIADEM_ZOBLYN], gathering: [{ found: { location: MapLocation.DIADEM, x: 0, y: 0 }, method: GatherMethod.QUARRYING }] },
		used: [],
	},
	[Item.ODDLY_DELICATE_CELESTINE]: {
		name: Item.ODDLY_DELICATE_CELESTINE, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.ODDLY_DELICATE_CELESTINE, qtyMade: 1, collectable: true, crafter: Job.GSM, level: 80, lodestoneID: "4cfeab87a79",
					itemsRequired: [
						{ item: Item.ODDLY_DELICATE_RAW_CELESTINE, qty: 1 },
						{ item: Item.GRADE_4_ARTISANAL_SKYBUILDERS_SILEX, qty: 5 },
						{ item: Item.GRADE_4_ARTISANAL_SKYBUILDERS_BARBGRASS, qty: 5 },
						{ item: Item.WIND_CLUSTER, qty: 3 },
						{ item: Item.FIRE_CLUSTER, qty: 3 },
					],
				},
			],
		},
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SKYSTEEL, RelicPart.TOOL_SKYBUILDERS, RelicStep.PARTS, RelicTask.TASK_1], jobs: [Job.GSM], qtyPerJob: 20, maxQtyPerJob: 60 },
		],
	},
	[Item.ODDLY_DELICATE_RAW_CELESTINE]: {
		name: Item.ODDLY_DELICATE_RAW_CELESTINE, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { shops: [{ shop: Shop.ANNA, cost: [{ price: 45, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.DIANA, cost: [{ price: 45, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.ENIE, cost: [{ price: 30, currency: Currency.SKUBUILDERS_SCRIP }] }, { shop: Shop.MOWEN, cost: [{ price: 45, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SANANA, cost: [{ price: 45, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_EULMORE, cost: [{ price: 45, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_GRIDANIA, cost: [{ price: 45, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_IDYLLSHIRE, cost: [{ price: 45, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_LIMSA, cost: [{ price: 45, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_MOR_DHONA, cost: [{ price: 45, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_SHARLAYAN, cost: [{ price: 45, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_RADZ_AT_HAN, cost: [{ price: 45, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_RHALGRS_REACH, cost: [{ price: 45, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_CRYSTARIUM, cost: [{ price: 45, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_ULDAH, cost: [{ price: 45, currency: Currency.WHITE_CRAFTERS_SCRIP }] }] },
		used: [],
	},
	[Item.ODDLY_DELICATE_GAZELLE_LEATHER]: {
		name: Item.ODDLY_DELICATE_GAZELLE_LEATHER, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.ODDLY_DELICATE_GAZELLE_LEATHER, qtyMade: 1, collectable: true, crafter: Job.LTW, level: 80, lodestoneID: "4b47c5757b9",
					itemsRequired: [
						{ item: Item.ODDLY_DELICATE_GAZELLE_HIDE, qty: 1 },
						{ item: Item.GRADE_4_ARTISANAL_SKYBUILDERS_LOG, qty: 5 },
						{ item: Item.GRADE_4_ARTISANAL_SKYBUILDERS_CAIMAN, qty: 5 },
						{ item: Item.EARTH_CLUSTER, qty: 3 },
						{ item: Item.WIND_CLUSTER, qty: 3 },
					],
				},
			],
		},
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SKYSTEEL, RelicPart.TOOL_SKYBUILDERS, RelicStep.PARTS, RelicTask.TASK_1], jobs: [Job.LTW], qtyPerJob: 20, maxQtyPerJob: 60 },
		],
	},
	[Item.ODDLY_DELICATE_GAZELLE_HIDE]: {
		name: Item.ODDLY_DELICATE_GAZELLE_HIDE, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { shops: [{ shop: Shop.ANNA, cost: [{ price: 45, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.DIANA, cost: [{ price: 45, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.ENIE, cost: [{ price: 30, currency: Currency.SKUBUILDERS_SCRIP }] }, { shop: Shop.MOWEN, cost: [{ price: 45, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SANANA, cost: [{ price: 45, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_EULMORE, cost: [{ price: 45, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_GRIDANIA, cost: [{ price: 45, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_IDYLLSHIRE, cost: [{ price: 45, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_LIMSA, cost: [{ price: 45, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_MOR_DHONA, cost: [{ price: 45, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_SHARLAYAN, cost: [{ price: 45, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_RADZ_AT_HAN, cost: [{ price: 45, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_RHALGRS_REACH, cost: [{ price: 45, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_CRYSTARIUM, cost: [{ price: 45, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_ULDAH, cost: [{ price: 45, currency: Currency.WHITE_CRAFTERS_SCRIP }] }] },
		used: [],
	},
	[Item.GRADE_4_ARTISANAL_SKYBUILDERS_CAIMAN]: {
		name: Item.GRADE_4_ARTISANAL_SKYBUILDERS_CAIMAN, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { markets: { [Market.UNIVERSALIS]: 31993 }, enemies: [Enemy.PROTO_NOCTILUCALE], gathering: [{ found: { location: MapLocation.DIADEM, x: 0, y: 0 }, method: GatherMethod.HARVESTING }] },
		used: [],
	},
	[Item.ODDLY_DELICATE_RHEA_CLOTH]: {
		name: Item.ODDLY_DELICATE_RHEA_CLOTH, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.ODDLY_DELICATE_RHEA_CLOTH, qtyMade: 1, collectable: true, crafter: Job.WVR, level: 80, lodestoneID: "425399f2379",
					itemsRequired: [
						{ item: Item.ODDLY_DELICATE_RHEA, qty: 1 },
						{ item: Item.GRADE_4_ARTISANAL_SKYBUILDERS_COCOON, qty: 5 },
						{ item: Item.GRADE_4_ARTISANAL_SKYBUILDERS_ICE_STALAGMITE, qty: 5 },
						{ item: Item.LIGHTNING_CLUSTER, qty: 3 },
						{ item: Item.WIND_CLUSTER, qty: 3 },
					],
				},
			],
		},
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SKYSTEEL, RelicPart.TOOL_SKYBUILDERS, RelicStep.PARTS, RelicTask.TASK_1], jobs: [Job.WVR], qtyPerJob: 20, maxQtyPerJob: 60 },
		],
	},
	[Item.ODDLY_DELICATE_RHEA]: {
		name: Item.ODDLY_DELICATE_RHEA, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { shops: [{ shop: Shop.ANNA, cost: [{ price: 45, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.DIANA, cost: [{ price: 45, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.ENIE, cost: [{ price: 30, currency: Currency.SKUBUILDERS_SCRIP }] }, { shop: Shop.MOWEN, cost: [{ price: 45, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SANANA, cost: [{ price: 45, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_EULMORE, cost: [{ price: 45, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_GRIDANIA, cost: [{ price: 45, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_IDYLLSHIRE, cost: [{ price: 45, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_LIMSA, cost: [{ price: 45, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_MOR_DHONA, cost: [{ price: 45, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_SHARLAYAN, cost: [{ price: 45, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_RADZ_AT_HAN, cost: [{ price: 45, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_RHALGRS_REACH, cost: [{ price: 45, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_CRYSTARIUM, cost: [{ price: 45, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_ULDAH, cost: [{ price: 45, currency: Currency.WHITE_CRAFTERS_SCRIP }] }] },
		used: [],
	},
	[Item.GRADE_4_ARTISANAL_SKYBUILDERS_COCOON]: {
		name: Item.GRADE_4_ARTISANAL_SKYBUILDERS_COCOON, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { markets: { [Market.UNIVERSALIS]: 31994 }, enemies: [Enemy.DIADEM_BLOATED_BULB], gathering: [{ found: { location: MapLocation.DIADEM, x: 0, y: 0 }, method: GatherMethod.HARVESTING }] },
		used: [],
	},
	[Item.GRADE_4_ARTISANAL_SKYBUILDERS_ICE_STALAGMITE]: {
		name: Item.GRADE_4_ARTISANAL_SKYBUILDERS_ICE_STALAGMITE, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { markets: { [Market.UNIVERSALIS]: 31998 }, enemies: [Enemy.DIADEM_ICE_GOLEM], gathering: [{ found: { location: MapLocation.DIADEM, x: 0, y: 0 }, method: GatherMethod.MINING }] },
		used: [],
	},
	[Item.ODDLY_DELICATE_HOLY_WATER]: {
		name: Item.ODDLY_DELICATE_HOLY_WATER, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.ODDLY_DELICATE_HOLY_WATER, qtyMade: 1, collectable: true, crafter: Job.ALC, level: 80, lodestoneID: "2c78c1be5d1",
					itemsRequired: [
						{ item: Item.ODDLY_DELICATE_MISTLETOE, qty: 1 },
						{ item: Item.GRADE_4_ARTISANAL_SKYBUILDERS_SPRING_WATER, qty: 5 },
						{ item: Item.GRADE_4_ARTISANAL_SKYBUILDERS_RASPBERRY, qty: 5 },
						{ item: Item.WATER_CLUSTER, qty: 3 },
						{ item: Item.LIGHTNING_CLUSTER, qty: 3 },
					],
				},
			],
		},
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SKYSTEEL, RelicPart.TOOL_SKYBUILDERS, RelicStep.PARTS, RelicTask.TASK_1], jobs: [Job.ALC], qtyPerJob: 20, maxQtyPerJob: 60 },
		],
	},
	[Item.ODDLY_DELICATE_MISTLETOE]: {
		name: Item.ODDLY_DELICATE_MISTLETOE, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { shops: [{ shop: Shop.ANNA, cost: [{ price: 45, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.DIANA, cost: [{ price: 45, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.ENIE, cost: [{ price: 30, currency: Currency.SKUBUILDERS_SCRIP }] }, { shop: Shop.MOWEN, cost: [{ price: 45, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SANANA, cost: [{ price: 45, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_EULMORE, cost: [{ price: 45, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_GRIDANIA, cost: [{ price: 45, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_IDYLLSHIRE, cost: [{ price: 45, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_LIMSA, cost: [{ price: 45, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_MOR_DHONA, cost: [{ price: 45, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_SHARLAYAN, cost: [{ price: 45, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_RADZ_AT_HAN, cost: [{ price: 45, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_RHALGRS_REACH, cost: [{ price: 45, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_CRYSTARIUM, cost: [{ price: 45, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_ULDAH, cost: [{ price: 45, currency: Currency.WHITE_CRAFTERS_SCRIP }] }] },
		used: [],
	},
	[Item.GRADE_4_ARTISANAL_SKYBUILDERS_SPRING_WATER]: {
		name: Item.GRADE_4_ARTISANAL_SKYBUILDERS_SPRING_WATER, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { markets: { [Market.UNIVERSALIS]: 31997 }, enemies: [Enemy.DIADEM_BIAST, Enemy.DIADEM_GOLEM], gathering: [{ found: { location: MapLocation.DIADEM, x: 0, y: 0 }, method: GatherMethod.MINING }] },
		used: [],
	},
	[Item.GRADE_4_ARTISANAL_SKYBUILDERS_RASPBERRY]: {
		name: Item.GRADE_4_ARTISANAL_SKYBUILDERS_RASPBERRY, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { markets: { [Market.UNIVERSALIS]: 31992 }, enemies: [Enemy.DIADEM_WEREWOOD], gathering: [{ found: { location: MapLocation.DIADEM, x: 0, y: 0 }, method: GatherMethod.LOGGING }] },
		used: [],
	},
	[Item.ODDLY_DELICATE_SHARK_OIL]: {
		name: Item.ODDLY_DELICATE_SHARK_OIL, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.ODDLY_DELICATE_SHARK_OIL, qtyMade: 1, collectable: true, crafter: Job.CUL, level: 80, lodestoneID: "9f4e48b106e",
					itemsRequired: [
						{ item: Item.ODDLY_DELICATE_HAMMERHEAD_SHARK, qty: 1 },
						{ item: Item.GRADE_4_ARTISANAL_SKYBUILDERS_SPRING_WATER, qty: 5 },
						{ item: Item.GRADE_4_ARTISANAL_SKYBUILDERS_ICE_STALAGMITE, qty: 5 },
						{ item: Item.FIRE_CLUSTER, qty: 3 },
						{ item: Item.WATER_CLUSTER, qty: 3 },
					],
				},
			],
		},
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SKYSTEEL, RelicPart.TOOL_SKYBUILDERS, RelicStep.PARTS, RelicTask.TASK_1], jobs: [Job.CUL], qtyPerJob: 20, maxQtyPerJob: 60 },
		],
	},
	[Item.ODDLY_DELICATE_HAMMERHEAD_SHARK]: {
		name: Item.ODDLY_DELICATE_HAMMERHEAD_SHARK, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { shops: [{ shop: Shop.ANNA, cost: [{ price: 45, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.DIANA, cost: [{ price: 45, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.ENIE, cost: [{ price: 30, currency: Currency.SKUBUILDERS_SCRIP }] }, { shop: Shop.MOWEN, cost: [{ price: 45, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SANANA, cost: [{ price: 45, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_EULMORE, cost: [{ price: 45, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_GRIDANIA, cost: [{ price: 45, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_IDYLLSHIRE, cost: [{ price: 45, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_LIMSA, cost: [{ price: 45, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_MOR_DHONA, cost: [{ price: 45, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_SHARLAYAN, cost: [{ price: 45, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_RADZ_AT_HAN, cost: [{ price: 45, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_RHALGRS_REACH, cost: [{ price: 45, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_CRYSTARIUM, cost: [{ price: 45, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_ULDAH, cost: [{ price: 45, currency: Currency.WHITE_CRAFTERS_SCRIP }] }] },
		used: [],
	},
	[Item.ODDLY_DELICATE_ADAMANTITE_ORE]: {
		name: Item.ODDLY_DELICATE_ADAMANTITE_ORE, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { gathering: [{ found: { location: MapLocation.GAMMA_QUADRANT, x: 0, y: 0 }, method: GatherMethod.MINING }] },
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SKYSTEEL, RelicPart.TOOL_SKYBUILDERS, RelicStep.PARTS, RelicTask.TASK_1], jobs: [Job.MIN], qtyPerJob: 36, maxQtyPerJob: 250 },
		],
	},
	[Item.ODDLY_DELICATE_RAW_JADE]: {
		name: Item.ODDLY_DELICATE_RAW_JADE, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { gathering: [{ found: { location: MapLocation.DIADEM, x: 0, y: 0 }, method: GatherMethod.MINING }] },
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SKYSTEEL, RelicPart.TOOL_SKYBUILDERS, RelicStep.PARTS, RelicTask.TASK_2], jobs: [Job.MIN], qtyPerJob: 750 },
		],
	},
	[Item.ODDLY_DELICATE_FEATHER]: {
		name: Item.ODDLY_DELICATE_FEATHER, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { gathering: [{ found: { location: MapLocation.ANTITHESIS, x: 0, y: 0 }, method: GatherMethod.LOGGING }] },
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SKYSTEEL, RelicPart.TOOL_SKYBUILDERS, RelicStep.PARTS, RelicTask.TASK_1], jobs: [Job.BTN], qtyPerJob: 36, maxQtyPerJob: 250 },
		],
	},
	[Item.ODDLY_DELICATE_BIRCH_LOG]: {
		name: Item.ODDLY_DELICATE_BIRCH_LOG, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { gathering: [{ found: { location: MapLocation.DIADEM, x: 0, y: 0 }, method: GatherMethod.LOGGING }] },
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SKYSTEEL, RelicPart.TOOL_SKYBUILDERS, RelicStep.PARTS, RelicTask.TASK_2], jobs: [Job.BTN], qtyPerJob: 750 },
		],
	},
	[Item.FLINTSTRIKE]: {
		name: Item.FLINTSTRIKE, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { fishingHoles: [{ fish: Fish.FLINTSTRIKE, hole: Fishing.PAPPUS_TREE, bait: [Bait.SKYBALL] }] },
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SKYSTEEL, RelicPart.TOOL_SKYBUILDERS, RelicStep.PARTS, RelicTask.TASK_1], jobs: [Job.FSH], qtyPerJob: 50, maxQtyPerJob: 200 },
		],
	},
	[Item.PICKLED_POM]: {
		name: Item.PICKLED_POM, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { fishingHoles: [{ fish: Fish.PICKLED_POM, hole: Fishing.DELTA_QUADRANT, bait: [Bait.SKYBALL] }] },
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SKYSTEEL, RelicPart.TOOL_SKYBUILDERS, RelicStep.PARTS, RelicTask.TASK_2], jobs: [Job.FSH], qtyPerJob: 50, maxQtyPerJob: 200 },
		],
	},
	[Item.DARKSTEEL_NUGGET]: {
		name: Item.DARKSTEEL_NUGGET, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			markets: { [Market.UNIVERSALIS]: 5060 },
			recipes: [
				{
					itemMade: Item.DARKSTEEL_NUGGET, qtyMade: 1, crafter: Job.BSM, level: 50, lodestoneID: "",
					itemsRequired: [
						{ item: Item.DARKSTEEL_ORE, qty: 3 },
						{ item: Item.FIRE_CRYSTAL, qty: 3 },
					],
				},
				{
					itemMade: Item.DARKSTEEL_NUGGET, qtyMade: 1, crafter: Job.ARM, level: 50, lodestoneID: "",
					itemsRequired: [
						{ item: Item.DARKSTEEL_ORE, qty: 3 },
						{ item: Item.ICE_CRYSTAL, qty: 3 },
					],
				},
			],
		},
		used: [],
	},
	[Item.BASILISK_WHETSTONE]: {
		name: Item.BASILISK_WHETSTONE, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			markets: { [Market.UNIVERSALIS]: 5262 },
			recipes: [
				{
					itemMade: Item.BASILISK_WHETSTONE, qtyMade: 1, crafter: Job.GSM, level: 40, lodestoneID: "",
					itemsRequired: [
						{ item: Item.BASILISK_EGG, qty: 1 },
						{ item: Item.WIND_SHARD, qty: 5 },
					],
				},
			],
		},
		used: [],
	},
	[Item.FIRE_SHARD]: {
		name: Item.FIRE_SHARD, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			other: [
				{ element: "p", text: "Shards can be obtained from many places, for example" },
				{ element: "li", text: "Level 1-50 gathering nodes" },
				{ element: "li", text: "Sprites / Elementals of the relevant element" },
				{ element: "li", text: "Gardening" },
			],
			markets: { [Market.UNIVERSALIS]: 2 },
		},
		used: [],
	},
	[Item.ICE_SHARD]: {
		name: Item.ICE_SHARD, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			other: [
				{ element: "p", text: "Shards can be obtained from many places, for example" },
				{ element: "li", text: "Level 1-50 gathering nodes" },
				{ element: "li", text: "Sprites / Elementals of the relevant element" },
				{ element: "li", text: "Gardening" },
			],
			markets: { [Market.UNIVERSALIS]: 3 },
		},
		used: [],
	},
	[Item.WIND_SHARD]: {
		name: Item.WIND_SHARD, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			other: [
				{ element: "p", text: "Shards can be obtained from many places, for example" },
				{ element: "li", text: "Level 1-50 gathering nodes" },
				{ element: "li", text: "Sprites / Elementals of the relevant element" },
				{ element: "li", text: "Gardening" },
			],
			markets: { [Market.UNIVERSALIS]: 4 },
		},
		used: [],
	},
	[Item.EARTH_SHARD]: {
		name: Item.EARTH_SHARD, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			other: [
				{ element: "p", text: "Shards can be obtained from many places, for example" },
				{ element: "li", text: "Level 1-50 gathering nodes" },
				{ element: "li", text: "Sprites / Elementals of the relevant element" },
				{ element: "li", text: "Gardening" },
			],
			markets: { [Market.UNIVERSALIS]: 5 },
		},
		used: [],
	},
	[Item.LIGHTNING_SHARD]: {
		name: Item.LIGHTNING_SHARD, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			other: [
				{ element: "p", text: "Shards can be obtained from many places, for example" },
				{ element: "li", text: "Level 1-50 gathering nodes" },
				{ element: "li", text: "Sprites / Elementals of the relevant element" },
				{ element: "li", text: "Gardening" },
			],
			markets: { [Market.UNIVERSALIS]: 6 },
		},
		used: [],
	},
	[Item.WATER_SHARD]: {
		name: Item.WATER_SHARD, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			other: [
				{ element: "p", text: "Shards can be obtained from many places, for example" },
				{ element: "li", text: "Level 1-50 gathering nodes" },
				{ element: "li", text: "Sprites / Elementals of the relevant element" },
				{ element: "li", text: "Gardening" },
			],
			markets: { [Market.UNIVERSALIS]: 7 },
		},
		used: [],
	},
	[Item.FIRE_CRYSTAL]: {
		name: Item.FIRE_CRYSTAL, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			other: [
				{ element: "p", text: "Crystals can be obtained from many places, for example" },
				{ element: "li", text: "Level 25+ gathering nodes" },
				{ element: "li", text: "Sprites / Elementals of the relevant element" },
				{ element: "li", text: "Aetherial Reduction" },
			],
			markets: { [Market.UNIVERSALIS]: 8 },
		},
		used: [],
	},
	[Item.ICE_CRYSTAL]: {
		name: Item.ICE_CRYSTAL, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			other: [
				{ element: "p", text: "Crystals can be obtained from many places, for example" },
				{ element: "li", text: "Level 25+ gathering nodes" },
				{ element: "li", text: "Sprites / Elementals of the relevant element" },
				{ element: "li", text: "Aetherial Reduction" },
			],
			markets: { [Market.UNIVERSALIS]: 9 },
		},
		used: [],
	},
	[Item.WIND_CRYSTAL]: {
		name: Item.WIND_CRYSTAL, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			other: [
				{ element: "p", text: "Crystals can be obtained from many places, for example" },
				{ element: "li", text: "Level 25+ gathering nodes" },
				{ element: "li", text: "Sprites / Elementals of the relevant element" },
				{ element: "li", text: "Aetherial Reduction" },
			],
			markets: { [Market.UNIVERSALIS]: 10 },
		},
		used: [],
	},
	[Item.EARTH_CRYSTAL]: {
		name: Item.EARTH_CRYSTAL, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			other: [
				{ element: "p", text: "Crystals can be obtained from many places, for example" },
				{ element: "li", text: "Level 25+ gathering nodes" },
				{ element: "li", text: "Sprites / Elementals of the relevant element" },
				{ element: "li", text: "Aetherial Reduction" },
			],
			markets: { [Market.UNIVERSALIS]: 11 },
		},
		used: [],
	},
	[Item.LIGHTNING_CRYSTAL]: {
		name: Item.LIGHTNING_CRYSTAL, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			other: [
				{ element: "p", text: "Crystals can be obtained from many places, for example" },
				{ element: "li", text: "Level 25+ gathering nodes" },
				{ element: "li", text: "Sprites / Elementals of the relevant element" },
				{ element: "li", text: "Aetherial Reduction" },
			],
			markets: { [Market.UNIVERSALIS]: 12 },
		},
		used: [],
	},
	[Item.WATER_CRYSTAL]: {
		name: Item.WATER_CRYSTAL, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			other: [
				{ element: "p", text: "Crystals can be obtained from many places, for example" },
				{ element: "li", text: "Level 25+ gathering nodes" },
				{ element: "li", text: "Sprites / Elementals of the relevant element" },
				{ element: "li", text: "Aetherial Reduction" },
			],
			markets: { [Market.UNIVERSALIS]: 13 },
		},
		used: [],
	},
	[Item.FIRE_CLUSTER]: {
		name: Item.FIRE_CLUSTER, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			other: [
				{ element: "p", text: "Clusters can be obtained from many places, for example" },
				{ element: "li", text: "Level 50+ gathering nodes" },
				{ element: "li", text: "Sprites / Elementals of the relevant element" },
				{ element: "li", text: "Aetherial Reduction" },
			],
			markets: { [Market.UNIVERSALIS]: 14 },
		},
		used: [],
	},
	[Item.ICE_CLUSTER]: {
		name: Item.ICE_CLUSTER, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			other: [
				{ element: "p", text: "Clusters can be obtained from many places, for example" },
				{ element: "li", text: "Level 50+ gathering nodes" },
				{ element: "li", text: "Sprites / Elementals of the relevant element" },
				{ element: "li", text: "Aetherial Reduction" },
			],
			markets: { [Market.UNIVERSALIS]: 15 },
		},
		used: [],
	},
	[Item.WIND_CLUSTER]: {
		name: Item.WIND_CLUSTER, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			other: [
				{ element: "p", text: "Clusters can be obtained from many places, for example" },
				{ element: "li", text: "Level 50+ gathering nodes" },
				{ element: "li", text: "Sprites / Elementals of the relevant element" },
				{ element: "li", text: "Aetherial Reduction" },
			],
			markets: { [Market.UNIVERSALIS]: 16 },
		},
		used: [],
	},
	[Item.EARTH_CLUSTER]: {
		name: Item.EARTH_CLUSTER, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			other: [
				{ element: "p", text: "Clusters can be obtained from many places, for example" },
				{ element: "li", text: "Level 50+ gathering nodes" },
				{ element: "li", text: "Sprites / Elementals of the relevant element" },
				{ element: "li", text: "Aetherial Reduction" },
			],
			markets: { [Market.UNIVERSALIS]: 17 },
		},
		used: [],
	},
	[Item.LIGHTNING_CLUSTER]: {
		name: Item.LIGHTNING_CLUSTER, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			other: [
				{ element: "p", text: "Clusters can be obtained from many places, for example" },
				{ element: "li", text: "Level 50+ gathering nodes" },
				{ element: "li", text: "Sprites / Elementals of the relevant element" },
				{ element: "li", text: "Aetherial Reduction" },
			],
			markets: { [Market.UNIVERSALIS]: 18 },
		},
		used: [],
	},
	[Item.WATER_CLUSTER]: {
		name: Item.WATER_CLUSTER, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			other: [
				{ element: "p", text: "Clusters can be obtained from many places, for example" },
				{ element: "li", text: "Level 50+ gathering nodes" },
				{ element: "li", text: "Sprites / Elementals of the relevant element" },
				{ element: "li", text: "Aetherial Reduction" },
			],
			markets: { [Market.UNIVERSALIS]: 19 },
		},
		used: [],
	},
	[Item.BASILISK_EGG]: {
		name: Item.BASILISK_EGG, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			enemies: [Enemy.BASILISK],
			markets: { [Market.UNIVERSALIS]: 5263 },
			gathering: [{ method: GatherMethod.MINING, found: { location: MapLocation.BLUEFOG, x: 23, y: 24 } }],
		},
		used: [],
	},
	[Item.BLUNT_AEOLIAN_SCIMITAR]: {
		name: Item.BLUNT_AEOLIAN_SCIMITAR, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			markets: { [Market.UNIVERSALIS]: 1636 },
			shops: [{ shop: Shop.JUNKMONGER, cost: [{ price: 3168, currency: Currency.GIL }] }],
			duties: {
				dungeons: [Dungeon.WANDERERS],
				leves: [Leve.ID_RATHER_BE_DIGGING, Leve.I_MAUL_RIGHT, Leve.NO_REFUNDS_ONLY_EXCHANGES, Leve.POP_THAT_TOP, Leve.TALON_TERRORS, Leve.YOU_STAY_ON_THAT_SIDE],
			},
		},
		used: [],
	},
	[Item.DARKSTEEL_ORE]: {
		name: Item.DARKSTEEL_ORE, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { markets: { [Market.UNIVERSALIS]: 5121 }, gathering: [{ method: GatherMethod.MINING, found: { location: MapLocation.HALDRATHS_MARCH, x: 27, y: 20 }, timedNode: true, times: ["1 AM"] }] },
		used: [],
	},
	[Item.BLOODY_BARDICHE_HEAD]: {
		name: Item.BLOODY_BARDICHE_HEAD, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			markets: { [Market.UNIVERSALIS]: 5566 },
			duties: {
				dungeons: [Dungeon.WANDERERS],
				leves: [Leve.ID_RATHER_BE_DIGGING, Leve.I_MAUL_RIGHT, Leve.NO_REFUNDS_ONLY_EXCHANGES, Leve.POP_THAT_TOP, Leve.TALON_TERRORS, Leve.YOU_STAY_ON_THAT_SIDE],
			},
		},
		used: [],
	},
	[Item.THAVNAIRIAN_MISTLETOE]: {
		name: Item.THAVNAIRIAN_MISTLETOE, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			markets: { [Market.UNIVERSALIS]: 5537 },
			gathering: [{ method: GatherMethod.LOGGING, found: { location: MapLocation.WHITEBRIM, x: 7, y: 11 }, timedNode: true, times: ["5 PM"] }],
			duties: { leves: [Leve.MATTER_OF_VITAL_IMPORTANCE, Leve.MAKE_UP_YOUR_MIND_OR_ELSE, Leve.NOT_TAKING_NO_FOR_AN_ANSWER, Leve.QUIT_YOUR_JIBJAB, Leve.SHUT_UP_ALREADY, Leve.SLEEPLESS_IN_SILVERTEAR] },
		},
		used: [],
	},
	[Item.GROWTH_FORMULA_DELTA]: {
		name: Item.GROWTH_FORMULA_DELTA, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			markets: { [Market.UNIVERSALIS]: 5473 },
			recipes: [
				{
					itemMade: Item.GROWTH_FORMULA_DELTA, qtyMade: 1, crafter: Job.ALC, level: 50, lodestoneID: "",
					itemsRequired: [
						{ item: Item.TRILLIUM_BULB, qty: 1 },
						{ item: Item.QUICKSILVER, qty: 1 },
						{ item: Item.ROCK_SALT, qty: 1 },
						{ item: Item.WATER_CRYSTAL, qty: 3 },
					],
				},
			],
		},
		used: [],
	},
	[Item.VAMPIRE_PLANT]: {
		name: Item.VAMPIRE_PLANT, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { markets: { [Market.UNIVERSALIS]: 5547 }, gathering: [{ method: GatherMethod.LOGGING, found: { location: MapLocation.DRAGONHEAD, x: 28, y: 25 }, timedNode: true, times: ["1 PM"] }] },
		used: [],
	},
	[Item.BLOODY_GRIMOIRE_BINDING]: {
		name: Item.BLOODY_GRIMOIRE_BINDING, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			markets: { [Market.UNIVERSALIS]: 5569 },
			duties: {
				dungeons: [Dungeon.WANDERERS],
				leves: [Leve.MATTER_OF_VITAL_IMPORTANCE, Leve.MAKE_UP_YOUR_MIND_OR_ELSE, Leve.NOT_TAKING_NO_FOR_AN_ANSWER, Leve.QUIT_YOUR_JIBJAB, Leve.SHUT_UP_ALREADY, Leve.SLEEPLESS_IN_SILVERTEAR],
			},
		},
		used: [],
	},
	[Item.ENCHANTED_GOLD_INK]: {
		name: Item.ENCHANTED_GOLD_INK, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			markets: { [Market.UNIVERSALIS]: 5480 },
			recipes: [
				{
					itemMade: Item.ENCHANTED_GOLD_INK, qtyMade: 1, crafter: Job.ALC, level: 50, lodestoneID: "",
					itemsRequired: [
						{ item: Item.GOLD_SAND, qty: 3 },
						{ item: Item.SPOKEN_BLOOD, qty: 1 },
						{ item: Item.WATER_CRYSTAL, qty: 3 },
					],
				},
			],
		},
		used: [],
	},
	[Item.BLOODY_CESTI_COVERS]: {
		name: Item.BLOODY_CESTI_COVERS, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			markets: { [Market.UNIVERSALIS]: 5567 },
			duties: {
				dungeons: [Dungeon.WANDERERS],
				leves: [Leve.FUSS_IN_BOOTS, Leve.HANDLE_WITH_CARE, Leve.HOLD_ON_TIGHT, Leve.SPELLING_ME_SOFTLY, Leve.TENDERFOOT_MOMENTS, Leve.TOO_HOT_TO_HANDLE],
			},
		},
		used: [],
	},
	[Item.BLOODY_LANCE_HEAD]: {
		name: Item.BLOODY_LANCE_HEAD, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			markets: { [Market.UNIVERSALIS]: 5565 },
			duties: {
				dungeons: [Dungeon.WANDERERS],
				leves: [Leve.BOW_DOWN_TO_MAGIC, Leve.BOWING_TO_GREATER_POWER, Leve.CLOGS_OF_WAR, Leve.INCANT_NOW_THINK_LATER, Leve.TRIDENT_AND_ERROR, Leve.YOU_DO_THE_HEAVY_LIFTING],
			},
		},
		used: [],
	},
	[Item.SPRUCE_LUMBER]: {
		name: Item.SPRUCE_LUMBER, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			markets: { [Market.UNIVERSALIS]: 5378 },
			recipes: [
				{
					itemMade: Item.SPRUCE_LUMBER, qtyMade: 1, crafter: Job.CRP, level: 50, lodestoneID: "",
					itemsRequired: [
						{ item: Item.SPRUCE_LOG, qty: 3 },
						{ item: Item.WIND_CRYSTAL, qty: 3 },
					],
				},
			],
		},
		used: [],
	},
	[Item.SPRUCE_LOG]: {
		name: Item.SPRUCE_LOG, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { markets: { [Market.UNIVERSALIS]: 5395 }, gathering: [{ method: GatherMethod.LOGGING, found: { location: MapLocation.PROVIDENCE_POINT, x: 27, y: 13 }, timedNode: true, times: ["9 AM"] }] },
		used: [],
	},
	[Item.BLOODY_KNIFE_BLADES]: {
		name: Item.BLOODY_KNIFE_BLADES, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			markets: { [Market.UNIVERSALIS]: 9374 },
			duties: {
				dungeons: [Dungeon.WANDERERS],
				leves: [Leve.MATTER_OF_VITAL_IMPORTANCE, Leve.MAKE_UP_YOUR_MIND_OR_ELSE, Leve.NOT_TAKING_NO_FOR_AN_ANSWER, Leve.QUIT_YOUR_JIBJAB, Leve.SHUT_UP_ALREADY, Leve.SLEEPLESS_IN_SILVERTEAR],
			},
		},
		used: [],
	},
	[Item.POTENT_SLEEPING_POTION]: {
		name: Item.POTENT_SLEEPING_POTION, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			markets: { [Market.UNIVERSALIS]: 4587 },
			recipes: [
				{
					itemMade: Item.POTENT_SLEEPING_POTION, qtyMade: 3, crafter: Job.ALC, level: 48, lodestoneID: "",
					itemsRequired: [
						{ item: Item.QUICKSILVER, qty: 1 },
						{ item: Item.OCHU_VINE, qty: 1 },
						{ item: Item.DESERT_SAFFRON, qty: 1 },
						{ item: Item.WATER_SHARD, qty: 6 },
						{ item: Item.LIGHTNING_SHARD, qty: 6 },
					],
				},
			],
		},
		used: [],
	},
	[Item.ROSEWOOD_LUMBER]: {
		name: Item.ROSEWOOD_LUMBER, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			markets: { [Market.UNIVERSALIS]: 5376 },
			recipes: [
				{
					itemMade: Item.ROSEWOOD_LUMBER, qtyMade: 3, crafter: Job.CRP, level: 45, lodestoneID: "",
					itemsRequired: [
						{ item: Item.ROSEWOOD_LOG, qty: 3 },
						{ item: Item.WIND_SHARD, qty: 6 },
					],
				},
			],
		},
		used: [],
	},
	[Item.BLOODY_BOW_RIM]: {
		name: Item.BLOODY_BOW_RIM, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			markets: { [Market.UNIVERSALIS]: 5568 },
			duties: {
				dungeons: [Dungeon.WANDERERS],
				leves: [Leve.BOW_DOWN_TO_MAGIC, Leve.BOWING_TO_GREATER_POWER, Leve.CLOGS_OF_WAR, Leve.INCANT_NOW_THINK_LATER, Leve.TRIDENT_AND_ERROR, Leve.YOU_DO_THE_HEAVY_LIFTING],
			},
		},
		used: [],
	},
	[Item.PINPRICK_PEBBLE]: {
		name: Item.PINPRICK_PEBBLE, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			markets: { [Market.UNIVERSALIS]: 5206 },
			duties: {
				dungeons: [Dungeon.WANDERERS],
				leves: [Leve.GOLD_IS_SO_LAST_YEAR, Leve.GREEN_AND_BEAR_IT, Leve.LIGHT_IN_THE_DARKNESS, Leve.SEW_NOT_DOING_THIS, Leve.SWEET_CHARITY, Leve.BIG_RED],
			},
		},
		used: [],
	},
	[Item.AGED_EYE_OF_FIRE]: {
		name: Item.AGED_EYE_OF_FIRE, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { desynthesis: [Item.AGED_RING, Item.FURNACE_RING] },
		used: [],
	},
	[Item.BELAHDIAN_SILVER]: {
		name: Item.BELAHDIAN_SILVER, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { desynthesis: [Item.FURNACE_RING, Item.PERFECT_MORTAR] },
		used: [],
	},
	[Item.AGED_SPEAR_SHAFT]: {
		name: Item.AGED_SPEAR_SHAFT, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { desynthesis: [Item.AGED_SPEAR, Item.PERFECT_FIREWOOD] },
		used: [],
	},
	[Item.CYPRESS_LOG]: {
		name: Item.CYPRESS_LOG, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			markets: { [Market.UNIVERSALIS]: 9518 },
			gathering: [{ method: GatherMethod.LOGGING, found: { location: MapLocation.BENTBRANCH, x: 24, y: 31 }, timedNode: true, times: ["10 AM"] }],
		},
		used: [],
	},
	[Item.GRENADE_ASH]: {
		name: Item.GRENADE_ASH, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			markets: { [Market.UNIVERSALIS]: 5526 },
			enemies: [Enemy.GRENADE],
			gathering: [{ method: GatherMethod.QUARRYING, found: { location: MapLocation.BLUEFOG, x: 22, y: 28 } }],
			shops: [{ shop: Shop.ZRANMAIA, cost: [{ price: 216, currency: Currency.GIL }] }],
		},
		used: [],
	},
	[Item.AGED_MORTAR_PIECES]: {
		name: Item.AGED_MORTAR_PIECES, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { desynthesis: [Item.AGED_MORTAR, Item.PERFECT_MORTAR] },
		used: [],
	},
	[Item.MAHOGANY_LUMBER]: {
		name: Item.MAHOGANY_LUMBER, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			markets: { [Market.UNIVERSALIS]: 5374 },
			recipes: [
				{
					itemMade: Item.MAHOGANY_LUMBER, qtyMade: 1, crafter: Job.CRP, level: 39, lodestoneID: "",
					itemsRequired: [
						{ item: Item.MAHOGANY_LOG, qty: 3 },
						{ item: Item.WIND_SHARD, qty: 5 },
					],
				},
			],
		},
		used: [],
	},
	[Item.AGED_PESTLE_PIECES]: {
		name: Item.AGED_PESTLE_PIECES, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { desynthesis: [Item.AGED_PESTLE, Item.PERFECT_PESTLE] },
		used: [],
	},
	[Item.PEISTE_LEATHER]: {
		name: Item.PEISTE_LEATHER, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			markets: { [Market.UNIVERSALIS]: 5284 },
			recipes: [
				{
					itemMade: Item.PEISTE_LEATHER, qtyMade: 1, crafter: Job.LTW, level: 38, lodestoneID: "",
					itemsRequired: [
						{ item: Item.PEISTE_SKIN, qty: 1 },
						{ item: Item.BLACK_ALUMEN, qty: 1 },
						{ item: Item.EARTH_SHARD, qty: 4 },
					],
				},
			],
		},
		used: [],
	},
	[Item.VINTAGE_COOKING_SHERRY]: {
		name: Item.VINTAGE_COOKING_SHERRY, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { markets: { [Market.UNIVERSALIS]: 9528 }, desynthesis: [Item.AGED_DECANTER, Item.TAILOR_MADE_EEL_PIE] },
		used: [],
	},
	[Item.WORM_OF_NYM]: {
		name: Item.WORM_OF_NYM, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			markets: { [Market.UNIVERSALIS]: 7916 },
			fishingHoles: [{ fish: Fish.WORM_OF_NYM, hole: Fishing.BRONZE_LAKE_SHALLOWS, bait: [Bait.BUTTERWORM] }],
		},
		used: [],
	},
	[Item.PIE_DOUGH]: {
		name: Item.PIE_DOUGH, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			markets: { [Market.UNIVERSALIS]: 4861 },
			recipes: [
				{
					itemMade: Item.PIE_DOUGH, qtyMade: 6, crafter: Job.CUL, level: 14, lodestoneID: "",
					itemsRequired: [
						{ item: Item.SUNSET_WHEAT_FLOUR, qty: 1 },
						{ item: Item.TABLE_SALT, qty: 1 },
						{ item: Item.MINERAL_WATER, qty: 1 },
						{ item: Item.FIRE_SHARD, qty: 1 },
					],
				},
			],
		},
		used: [],
	},
	[Item.SMOOTH_BUTTER]: {
		name: Item.SMOOTH_BUTTER, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			markets: { [Market.UNIVERSALIS]: 4853 },
			recipes: [
				{
					itemMade: Item.SMOOTH_BUTTER, qtyMade: 5, crafter: Job.CUL, level: 7, lodestoneID: "",
					itemsRequired: [
						{ item: Item.BUFFALO_MILK, qty: 2 },
						{ item: Item.FIRE_SHARD, qty: 1 },
					],
				},
			],
			shops: [{ shop: Shop.SAHAGIN_VENDOR, cost: [{ price: 2, currency: Currency.GIL }] }],
		},
		used: [],
	},
	[Item.BLACK_PEPPER]: {
		name: Item.BLACK_PEPPER, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			markets: { [Market.UNIVERSALIS]: 4830 },
			gathering: [{ method: GatherMethod.HARVESTING, found: { location: MapLocation.CLUTCH, x: 25, y: 20 } }],
			shops: [
				{ shop: Shop.ALLAGAN_RESUPPLY_NODE, cost: [{ price: 13, currency: Currency.GIL }] },
				{ shop: Shop.JOSSY, cost: [{ price: 13, currency: Currency.GIL }] },
				{ shop: Shop.MATERIAL_SUPPLIER, cost: [{ price: 13, currency: Currency.GIL }] },
				{ shop: Shop.SAHAGIN_VENDOR, cost: [{ price: 13, currency: Currency.GIL }] },
			],
		},
		used: [],
	},
	[Item.CLOVES]: {
		name: Item.CLOVES, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			markets: { [Market.UNIVERSALIS]: 4831 },
			gathering: [{ method: GatherMethod.LOGGING, found: { location: MapLocation.SPINELESS_BASIN, x: 23, y: 26 } }],
			shops: [
				{ shop: Shop.ESMENET, cost: [{ price: 16, currency: Currency.GIL }] },
				{ shop: Shop.KOBOLD_VENDOR, cost: [{ price: 16, currency: Currency.GIL }] },
				{ shop: Shop.TANIE, cost: [{ price: 16, currency: Currency.GIL }] },
			],
		},
		used: [],
	},
	[Item.STAINED_CLOTH]: {
		name: Item.STAINED_CLOTH, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { desynthesis: [Item.AGED_ROBE, Item.PERFECT_CLOTH] },
		used: [],
	},
	[Item.PUMICE]: {
		name: Item.PUMICE, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			markets: { [Market.UNIVERSALIS]: 9519 },
			gathering: [{ method: GatherMethod.QUARRYING, found: { location: MapLocation.RAINCATCHER_GULLY, x: 19, y: 28 }, timedNode: true, times: ["1 AM"] }],
		},
		used: [],
	},
	[Item.LINEN_YARN]: {
		name: Item.LINEN_YARN, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			markets: { [Market.UNIVERSALIS]: 5336 },
			recipes: [
				{
					itemMade: Item.LINEN_YARN, qtyMade: 2, crafter: Job.WVR, level: 32, lodestoneID: "",
					itemsRequired: [
						{ item: Item.FLAX, qty: 2 },
						{ item: Item.LIGHTNING_SHARD, qty: 2 },
					],
				},
			],
			shops: [{ shop: Shop.SYLPHIC_VENDOR, cost: [{ price: 186, currency: Currency.GIL }] }],
		},
		used: [],
	},
	[Item.EFFERVESCENT_WATER]: {
		name: Item.EFFERVESCENT_WATER, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { markets: { [Market.UNIVERSALIS]: 5491 }, gathering: [{ method: GatherMethod.MINING, found: { location: MapLocation.UPPER_PATHS, x: 16, y: 20 } }] },
		used: [],
	},
	[Item.AGED_VELLUM]: {
		name: Item.AGED_VELLUM, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { desynthesis: [Item.AGED_GRIMOIRE, Item.PERFECT_VELLUM] },
		used: [],
	},
	[Item.SHARK_OIL]: {
		name: Item.SHARK_OIL, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			markets: { [Market.UNIVERSALIS]: 5486 },
			recipes: [
				{
					itemMade: Item.SHARK_OIL, qtyMade: 1, crafter: Job.ALC, level: 41, lodestoneID: "",
					itemsRequired: [
						{ item: Item.HAMMERHEAD_SHARK, qty: 1 },
						{ item: Item.SILVER_SHARK, qty: 1 },
						{ item: Item.WATER_SHARD, qty: 5 },
					],
				},
			],
		},
		used: [],
	},
	[Item.DRIED_ETHER]: {
		name: Item.DRIED_ETHER, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { desynthesis: [Item.AGED_PHIAL, Item.PERFECT_POUNCE] },
		used: [],
	},
	[Item.AETHERLOUSE]: {
		name: Item.AETHERLOUSE, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			markets: { [Market.UNIVERSALIS]: 7951 },
			fishingHoles: [{ fish: Fish.AETHERLOUSE, hole: Fishing.NORTH_SILVERTEAR, bait: [Bait.GLOWWORM] }],
		},
		used: [],
	},
	[Item.ELECTRUM_SAND]: {
		name: Item.ELECTRUM_SAND, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { markets: { [Market.UNIVERSALIS]: 5272 }, gathering: [{ method: GatherMethod.QUARRYING, found: { location: MapLocation.BRONZE_LAKE, x: 28, y: 22 } }] },
		used: [],
	},
	[Item.PTERODACTYL_STRAP]: {
		name: Item.PTERODACTYL_STRAP, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			markets: { [Market.UNIVERSALIS]: 13590 },
			recipes: [
				{
					itemMade: Item.PTERODACTYL_STRAP, qtyMade: 1, crafter: Job.LTW, level: 60, lodestoneID: "",
					itemsRequired: [
						{ item: Item.PTERODACTYL, qty: 1 },
						{ item: Item.DARK_CHESTNUT_LOG, qty: 1 },
						{ item: Item.DAWNBORNE_AETHERSAND, qty: 1 },
						{ item: Item.WIND_CLUSTER, qty: 1 },
						{ item: Item.EARTH_CLUSTER, qty: 2 },
					],
				},
			],
		},
		used: [],
	},
	[Item.ADAMANTITE_NUGGET]: {
		name: Item.ADAMANTITE_NUGGET, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			markets: { [Market.UNIVERSALIS]: 12526 },
			recipes: [
				{
					itemMade: Item.ADAMANTITE_NUGGET, qtyMade: 1, crafter: Job.BSM, level: 58, lodestoneID: "",
					itemsRequired: [
						{ item: Item.ADAMANTITE_ORE, qty: 5 },
						{ item: Item.DARKSTEEL_ORE, qty: 1 },
						{ item: Item.FIRE_CRYSTAL, qty: 5 },
					],
				},
				{
					itemMade: Item.ADAMANTITE_NUGGET, qtyMade: 1, crafter: Job.ARM, level: 58, lodestoneID: "",
					itemsRequired: [
						{ item: Item.ADAMANTITE_ORE, qty: 5 },
						{ item: Item.DARKSTEEL_ORE, qty: 1 },
						{ item: Item.ICE_CRYSTAL, qty: 5 },
					],
				},
			],
			shops: [{ shop: Shop.SCRAP_SALVAGER, cost: [{ price: 2958, currency: Currency.GIL }] }],
		},
		used: [],
	},
	[Item.BIRCH_LUMBER]: {
		name: Item.BIRCH_LUMBER, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			markets: { [Market.UNIVERSALIS]: 12583 },
			recipes: [
				{
					itemMade: Item.BIRCH_LUMBER, qtyMade: 1, crafter: Job.CRP, level: 58, lodestoneID: "",
					itemsRequired: [
						{ item: Item.BIRCH_LOG, qty: 5 },
						{ item: Item.WIND_CRYSTAL, qty: 5 },
					],
				},
			],
			shops: [{ shop: Shop.SCRAP_SALVAGER, cost: [{ price: 2958, currency: Currency.GIL }] }],
		},
		used: [],
	},
	[Item.TITANIUM_ALLOY_SQUARE]: {
		name: Item.TITANIUM_ALLOY_SQUARE, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			markets: { [Market.UNIVERSALIS]: 13592 },
			recipes: [
				{
					itemMade: Item.TITANIUM_ALLOY_SQUARE, qtyMade: 1, crafter: Job.ARM, level: 60, lodestoneID: "",
					itemsRequired: [
						{ item: Item.SPHALERITE, qty: 2 },
						{ item: Item.TITANIUM_NUGGET, qty: 1 },
						{ item: Item.DAWNBORNE_AETHERSAND, qty: 1 },
						{ item: Item.ICE_CLUSTER, qty: 2 },
						{ item: Item.EARTH_CLUSTER, qty: 1 },
					],
				},
			],
		},
		used: [],
	},
	[Item.HOLY_WATER]: {
		name: Item.HOLY_WATER, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			markets: { [Market.UNIVERSALIS]: 12604 },
			recipes: [
				{
					itemMade: Item.HOLY_WATER, qtyMade: 3, crafter: Job.ALC, level: 52, lodestoneID: "",
					itemsRequired: [
						{ item: Item.DRAVANIAN_SPRING_WATER, qty: 2 },
						{ item: Item.DRAVANIAN_MISTLETOE, qty: 2 },
						{ item: Item.WATER_CRYSTAL, qty: 3 },
					],
				},
			],
			shops: [{ shop: Shop.SCRAP_SALVAGER, cost: [{ price: 2236, currency: Currency.GIL }] }],
		},
		used: [],
	},
	[Item.HARDSILVER_SAND]: {
		name: Item.HARDSILVER_SAND, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			markets: { [Market.UNIVERSALIS]: 12532 },
			gathering: [{ method: GatherMethod.QUARRYING, found: { location: MapLocation.LANDLORD_COLONY, x: 28, y: 19 } }],
			shops: [{ shop: Shop.MOGMUL_MOGBELLY, cost: [{ price: 807, currency: Currency.GIL }] }],
		},
		used: [],
	},
	[Item.VITRIOL]: {
		name: Item.VITRIOL, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			markets: { [Market.UNIVERSALIS]: 5490 },
			shops: [{ shop: Shop.KOBOLD_VENDOR, cost: [{ price: 216, currency: Currency.GIL }] }],
		},
		used: [],
	},
	[Item.BLESSED_FLETCHINGS]: {
		name: Item.BLESSED_FLETCHINGS, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			markets: { [Market.UNIVERSALIS]: 13594 },
			recipes: [
				{
					itemMade: Item.BLESSED_FLETCHINGS, qtyMade: 1, crafter: Job.WVR, level: 60, lodestoneID: "",
					itemsRequired: [
						{ item: Item.ROYAL_MISTLETOE, qty: 2 },
						{ item: Item.EAGLE_FEATHER, qty: 1 },
						{ item: Item.DAWNBORNE_AETHERSAND, qty: 1 },
						{ item: Item.LIGHTNING_CLUSTER, qty: 2 },
						{ item: Item.WIND_CLUSTER, qty: 1 },
					],
				},
			],
		},
		used: [],
	},
	[Item.HALLOWED_CHESTNUT_LUMBER]: {
		name: Item.HALLOWED_CHESTNUT_LUMBER, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			markets: { [Market.UNIVERSALIS]: 12582 },
			recipes: [
				{
					itemMade: Item.HALLOWED_CHESTNUT_LUMBER, qtyMade: 1, crafter: Job.CRP, level: 56, lodestoneID: "",
					itemsRequired: [
						{ item: Item.DARK_CHESTNUT_LUMBER, qty: 1 },
						{ item: Item.HALLOWED_WATER, qty: 2 },
						{ item: Item.WIND_CRYSTAL, qty: 5 },
					],
				},
			],
			shops: [{ shop: Shop.SCRAP_SALVAGER, cost: [{ price: 2688, currency: Currency.GIL }] }],
		},
		used: [],
	},
	[Item.HARDSILVER_NUGGET]: {
		name: Item.HARDSILVER_NUGGET, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			markets: { [Market.UNIVERSALIS]: 12520 },
			recipes: [
				{
					itemMade: Item.HARDSILVER_NUGGET, qtyMade: 1, crafter: Job.GSM, level: 54, lodestoneID: "",
					itemsRequired: [
						{ item: Item.HARDSILVER_SAND, qty: 5 },
						{ item: Item.SILVER_ORE, qty: 1 },
						{ item: Item.WIND_CRYSTAL, qty: 4 },
					],
				},
			],
			shops: [{ shop: Shop.SCRAP_SALVAGER, cost: [{ price: 2962, currency: Currency.GIL }] }],
		},
		used: [],
	},
	[Item.MOOGLE_MINIATURE]: {
		name: Item.MOOGLE_MINIATURE, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			markets: { [Market.UNIVERSALIS]: 13596 },
			recipes: [
				{
					itemMade: Item.MOOGLE_MINIATURE, qtyMade: 1, crafter: Job.GSM, level: 60, lodestoneID: "",
					itemsRequired: [
						{ item: Item.CLOUD_COTTON_BOLL, qty: 2 },
						{ item: Item.BLACK_SOIL, qty: 1 },
						{ item: Item.DAWNBORNE_AETHERSAND, qty: 1 },
						{ item: Item.WIND_CLUSTER, qty: 2 },
						{ item: Item.FIRE_CLUSTER, qty: 1 },
					],
				},
			],
		},
		used: [],
	},
	[Item.HIGHLAND_FLOUR]: {
		name: Item.HIGHLAND_FLOUR, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			markets: { [Market.UNIVERSALIS]: 12881 },
			recipes: [
				{
					itemMade: Item.HIGHLAND_FLOUR, qtyMade: 1, crafter: Job.CUL, level: 52, lodestoneID: "",
					itemsRequired: [
						{ item: Item.HIGHLAND_WHEAT, qty: 6 },
						{ item: Item.FIRE_SHARD, qty: 7 },
					],
				},
			],
		},
		used: [],
	},
	[Item.YAK_MILK]: {
		name: Item.YAK_MILK, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			markets: { [Market.UNIVERSALIS]: 12888 },
			enemies: [Enemy.WOOLY_YAK],
			shops: [{ shop: Shop.VRAL, cost: [{ price: 617, currency: Currency.GIL }] }],
		},
		used: [],
	},
	[Item.OKEANIS_EGG]: {
		name: Item.OKEANIS_EGG, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { markets: { [Market.UNIVERSALIS]: 12872 }, enemies: [Enemy.OKEANIS] },
		used: [],
	},
	[Item.BIRCH_SYRUP]: {
		name: Item.BIRCH_SYRUP, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			markets: { [Market.UNIVERSALIS]: 12892 },
			recipes: [
				{
					itemMade: Item.BIRCH_SYRUP, qtyMade: 3, crafter: Job.CUL, level: 59, lodestoneID: "",
					itemsRequired: [
						{ item: Item.BIRCH_SAP, qty: 3 },
						{ item: Item.FIRE_SHARD, qty: 8 },
					],
				},
			],
		},
		used: [],
	},
	[Item.TINY_CROWN]: {
		name: Item.TINY_CROWN, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			markets: { [Market.UNIVERSALIS]: 5590 },
			shops: [{ shop: Shop.JOSSY, cost: [{ price: 150, currency: Currency.GIL }] }],
		},
		used: [],
	},
	[Item.SANDTEAK_LOG]: {
		name: Item.SANDTEAK_LOG, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			markets: { [Market.UNIVERSALIS]: 27686 },
			gathering: [{ method: GatherMethod.LOGGING, found: { location: MapLocation.LADLE, x: 16, y: 30 } }],
		},
		used: [],
	},
	[Item.TITANCOPPER_ORE]: {
		name: Item.TITANCOPPER_ORE, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			markets: { [Market.UNIVERSALIS]: 27701 },
			gathering: [{ method: GatherMethod.MINING, found: { location: MapLocation.CHURCH_OF_FIRST_LIGHT, x: 35, y: 15 } }],
		},
		used: [],
	},
	[Item.TITANIUM_ORE]: {
		name: Item.TITANIUM_ORE, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			markets: { [Market.UNIVERSALIS]: 12537 },
			gathering: [{ method: GatherMethod.MINING, found: { location: MapLocation.CHOCOBO_FOREST, x: 30, y: 15 } }],
		},
		used: [],
	},
	[Item.VOLCANIC_TUFF]: {
		name: Item.VOLCANIC_TUFF, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			markets: { [Market.UNIVERSALIS]: 27803 },
			gathering: [{ method: GatherMethod.QUARRYING, found: { location: MapLocation.AMITY, x: 22, y: 18 } }],
		},
		used: [],
	},
	[Item.ZONURE_SKIN]: {
		name: Item.ZONURE_SKIN, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			enemies: [Enemy.FLAME_ZONURE],
			markets: { [Market.UNIVERSALIS]: 27735 },
			shops: [
				{ shop: Shop.GRAMSOL, cost: [{ price: 2, currency: Currency.BICOLOUR_GEMSTONE }] },
				{ shop: Shop.HALDEN, cost: [{ price: 2, currency: Currency.BICOLOUR_GEMSTONE }] },
				{ shop: Shop.PEDRONILLE, cost: [{ price: 2, currency: Currency.BICOLOUR_GEMSTONE }] },
			],
		},
		used: [],
	},
	[Item.OVIM_WOOL_YARN]: {
		name: Item.OVIM_WOOL_YARN, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			markets: { [Market.UNIVERSALIS]: 27755 },
			recipes: [
				{
					itemMade: Item.OVIM_WOOL_YARN, qtyMade: 3, crafter: Job.WVR, level: 76, lodestoneID: "",
					itemsRequired: [
						{ item: Item.OVIM_FLEECE, qty: 4 },
						{ item: Item.REFINED_NATRON, qty: 1 },
						{ item: Item.LIGHTNING_CRYSTAL, qty: 3 },
					],
				},
			],
		},
		used: [],
	},
	[Item.EXTRA_EFFERVESCENT_WATER]: {
		name: Item.EXTRA_EFFERVESCENT_WATER, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			markets: { [Market.UNIVERSALIS]: 27776 },
			gathering: [{ method: GatherMethod.MINING, found: { location: MapLocation.WILD_FETE, x: 26, y: 29 } }],
		},
		used: [],
	},
	[Item.ALA_MHIGAN_SALT_CRYSTAL]: {
		name: Item.ALA_MHIGAN_SALT_CRYSTAL, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			markets: { [Market.UNIVERSALIS]: 19907 },
			gathering: [{ method: GatherMethod.QUARRYING, found: { location: MapLocation.LOCH_SELD, x: 21, y: 29 } }],
		},
		used: [],
	},
	[Item.ROYAL_GRAPES]: {
		name: Item.ROYAL_GRAPES, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			markets: { [Market.UNIVERSALIS]: 27826 },
			gathering: [{ method: GatherMethod.HARVESTING, found: { location: MapLocation.DELIBERATING_DOLL, x: 15, y: 27 } }],
		},
		used: [],
	},
	[Item.MYTHRITE_ORE]: {
		name: Item.MYTHRITE_ORE, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			markets: { [Market.UNIVERSALIS]: 12534 },
			gathering: [{ method: GatherMethod.MINING, found: { location: MapLocation.GORGAGNE_HOLDING, x: 31, y: 12 } }],
		},
		used: [],
	},
	[Item.MYTHRITE_SAND]: {
		name: Item.MYTHRITE_SAND, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			markets: { [Market.UNIVERSALIS]: 12531 },
			gathering: [{ method: GatherMethod.QUARRYING, found: { location: MapLocation.TWINPOOLS, x: 17, y: 13 } }],
		},
		used: [],
	},
	[Item.SEA_SWALLOW_SKIN]: {
		name: Item.SEA_SWALLOW_SKIN, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			enemies: [Enemy.TEMPEST_SWALLOW],
			markets: { [Market.UNIVERSALIS]: 27736 },
			shops: [
				{ shop: Shop.GOUSHS_OOAN, cost: [{ price: 2, currency: Currency.BICOLOUR_GEMSTONE }] },
				{ shop: Shop.GRAMSOL, cost: [{ price: 2, currency: Currency.BICOLOUR_GEMSTONE }] },
				{ shop: Shop.PEDRONILLE, cost: [{ price: 2, currency: Currency.BICOLOUR_GEMSTONE }] },
			],
		},
		used: [],
	},
	[Item.DWARVEN_COTTON_THREAD]: {
		name: Item.DWARVEN_COTTON_THREAD, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			markets: { [Market.UNIVERSALIS]: 27758 },
			recipes: [
				{
					itemMade: Item.DWARVEN_COTTON_THREAD, qtyMade: 3, crafter: Job.WVR, level: 78, lodestoneID: "",
					itemsRequired: [
						{ item: Item.DWARVEN_COTTON_BOLL, qty: 4 },
						{ item: Item.LIGHTNING_CRYSTAL, qty: 3 },
					],
				},
			],
		},
		used: [],
	},
	[Item.TRILLIUM_BULB]: {
		name: Item.TRILLIUM_BULB, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			markets: { [Market.UNIVERSALIS]: 5546 },
			gathering: [{ method: GatherMethod.HARVESTING, found: { location: MapLocation.HONEY_YARD, x: 14, y: 23 }, timedNode: true, times: ["9 PM"] }],
		},
		used: [],
	},
	[Item.QUICKSILVER]: {
		name: Item.QUICKSILVER, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			markets: { [Market.UNIVERSALIS]: 5489 },
			recipes: [
				{
					itemMade: Item.QUICKSILVER, qtyMade: 1, crafter: Job.ALC, level: 1, lodestoneID: "",
					itemsRequired: [
						{ item: Item.CINNABAR, qty: 2 },
						{ item: Item.WATER_SHARD, qty: 1 },
					],
				},
			],
			shops: [
				{ shop: Shop.ENGERRAND, cost: [{ price: 4, currency: Currency.GIL }] },
				{ shop: Shop.FRIDURIH, cost: [{ price: 4, currency: Currency.GIL }] },
				{ shop: Shop.KOBOLD_VENDOR, cost: [{ price: 4, currency: Currency.GIL }] },
				{ shop: Shop.ORHOYOD, cost: [{ price: 4, currency: Currency.GIL }] },
				{ shop: Shop.PASDEVILLET, cost: [{ price: 4, currency: Currency.GIL }] },
				{ shop: Shop.SHIRAKASHI, cost: [{ price: 4, currency: Currency.GIL }] },
				{ shop: Shop.TRAVELING_MATERIAL_SUPPLIER, cost: [{ price: 4, currency: Currency.GIL }] },
				{ shop: Shop.VRAL, cost: [{ price: 4, currency: Currency.GIL }] },
			],
		},
		used: [],
	},
	[Item.ROCK_SALT]: {
		name: Item.ROCK_SALT, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			markets: { [Market.UNIVERSALIS]: 5518 },
			gathering: [{ method: GatherMethod.QUARRYING, found: { location: MapLocation.BLACK_BRUSH, x: 15, y: 23 } }],
			shops: [
				{ shop: Shop.ALLAGAN_RESUPPLY_NODE, cost: [{ price: 3, currency: Currency.GIL }] },
				{ shop: Shop.ENGERRAND, cost: [{ price: 3, currency: Currency.GIL }] },
				{ shop: Shop.ESMENET, cost: [{ price: 3, currency: Currency.GIL }] },
				{ shop: Shop.FRIDURIH, cost: [{ price: 3, currency: Currency.GIL }] },
				{ shop: Shop.JOSSY, cost: [{ price: 3, currency: Currency.GIL }] },
				{ shop: Shop.MATERIAL_SUPPLIER, cost: [{ price: 3, currency: Currency.GIL }] },
				{ shop: Shop.ORHOYOD, cost: [{ price: 3, currency: Currency.GIL }] },
				{ shop: Shop.SHIRAKASHI, cost: [{ price: 3, currency: Currency.GIL }] },
				{ shop: Shop.TRAVELING_MATERIAL_SUPPLIER, cost: [{ price: 3, currency: Currency.GIL }] },
				{ shop: Shop.VRAL, cost: [{ price: 3, currency: Currency.GIL }] },
			],
		},
		used: [],
	},
	[Item.GOLD_SAND]: {
		name: Item.GOLD_SAND, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			markets: { [Market.UNIVERSALIS]: 5273 },
			gathering: [{ method: GatherMethod.QUARRYING, found: { location: MapLocation.YUGRAM_RIVER, x: 26, y: 23 }, timedNode: true, times: ["5 AM"] }],
		},
		used: [],
	},
	[Item.SPOKEN_BLOOD]: {
		name: Item.SPOKEN_BLOOD, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			markets: { [Market.UNIVERSALIS]: 5494 },
			enemies: [Enemy.LESSER_KALONG],
		},
		used: [],
	},
	[Item.OCHU_VINE]: {
		name: Item.OCHU_VINE, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			markets: { [Market.UNIVERSALIS]: 5555 },
			enemies: [Enemy.MILKROOT_CLUSTER, Enemy.OCHU],
		},
		used: [],
	},
	[Item.DESERT_SAFFRON]: {
		name: Item.DESERT_SAFFRON, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			markets: { [Market.UNIVERSALIS]: 4843 },
			gathering: [{ method: GatherMethod.HARVESTING, found: { location: MapLocation.BROKEN_WATER, x: 21, y: 9 } }],
		},
		used: [],
	},
	[Item.ROSEWOOD_LOG]: {
		name: Item.ROSEWOOD_LOG, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			markets: { [Market.UNIVERSALIS]: 5393 },
			gathering: [{ method: GatherMethod.LOGGING, found: { location: MapLocation.BRAMBLE_PATCH, x: 17, y: 23 } }],
		},
		used: [],
	},
	[Item.AGED_DECANTER]: {
		name: Item.AGED_DECANTER, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { shops: [{ shop: Shop.MERCHANT_MENDER_SILVER_BAZAAR, cost: [{ price: 3000, currency: Currency.GIL }] }] },
		used: [],
	},
	[Item.AGED_GRIMOIRE]: {
		name: Item.AGED_GRIMOIRE, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { shops: [{ shop: Shop.MERCHANT_MENDER_SILVER_BAZAAR, cost: [{ price: 3000, currency: Currency.GIL }] }] },
		used: [],
	},
	[Item.AGED_MORTAR]: {
		name: Item.AGED_MORTAR, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { shops: [{ shop: Shop.MERCHANT_MENDER_SILVER_BAZAAR, cost: [{ price: 3000, currency: Currency.GIL }] }] },
		used: [],
	},
	[Item.AGED_PESTLE]: {
		name: Item.AGED_PESTLE, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { shops: [{ shop: Shop.MERCHANT_MENDER_SILVER_BAZAAR, cost: [{ price: 3000, currency: Currency.GIL }] }] },
		used: [],
	},
	[Item.AGED_PHIAL]: {
		name: Item.AGED_PHIAL, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { shops: [{ shop: Shop.MERCHANT_MENDER_SILVER_BAZAAR, cost: [{ price: 3000, currency: Currency.GIL }] }] },
		used: [],
	},
	[Item.AGED_RING]: {
		name: Item.AGED_RING, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { shops: [{ shop: Shop.MERCHANT_MENDER_SILVER_BAZAAR, cost: [{ price: 3000, currency: Currency.GIL }] }] },
		used: [],
	},
	[Item.AGED_ROBE]: {
		name: Item.AGED_ROBE, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { shops: [{ shop: Shop.MERCHANT_MENDER_SILVER_BAZAAR, cost: [{ price: 3000, currency: Currency.GIL }] }] },
		used: [],
	},
	[Item.AGED_SPEAR]: {
		name: Item.AGED_SPEAR, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { shops: [{ shop: Shop.MERCHANT_MENDER_SILVER_BAZAAR, cost: [{ price: 3000, currency: Currency.GIL }] }] },
		used: [],
	},
	[Item.MAHOGANY_LOG]: {
		name: Item.MAHOGANY_LOG, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			markets: { [Market.UNIVERSALIS]: 5391 },
			gathering: [{ method: GatherMethod.LOGGING, found: { location: MapLocation.RAINCATCHER_GULLY, x: 19, y: 26 } }],
		},
		used: [],
	},
	[Item.PEISTE_SKIN]: {
		name: Item.PEISTE_SKIN, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			markets: { [Market.UNIVERSALIS]: 5306 },
			enemies: [Enemy.BASILISK, Enemy.ROCKSKIN_PEISTE, Enemy.SANDSKIN_PEISTE],
		},
		used: [],
	},
	[Item.BLACK_ALUMEN]: {
		name: Item.BLACK_ALUMEN, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			markets: { [Market.UNIVERSALIS]: 5525 },
			gathering: [{ method: GatherMethod.MINING, found: { location: MapLocation.URTHS_GIFT, x: 29, y: 23 } }],
		},
		used: [],
	},
	[Item.SUNSET_WHEAT_FLOUR]: {
		name: Item.SUNSET_WHEAT_FLOUR, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			markets: { [Market.UNIVERSALIS]: 4826 },
			recipes: [
				{
					itemMade: Item.SUNSET_WHEAT_FLOUR, qtyMade: 3, crafter: Job.CUL, level: 7, lodestoneID: "",
					itemsRequired: [
						{ item: Item.SUNSET_WHEAT, qty: 4 },
						{ item: Item.FIRE_SHARD, qty: 1 },
					],
				},
			],
			shops: [
				{ shop: Shop.LITTLEJOHN, cost: [{ price: 4, currency: Currency.GIL }] },
				{ shop: Shop.SAHAGIN_VENDOR, cost: [{ price: 4, currency: Currency.GIL }] },
			],
		},
		used: [],
	},
	[Item.TABLE_SALT]: {
		name: Item.TABLE_SALT, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			markets: { [Market.UNIVERSALIS]: 4847 },
			recipes: [
				{
					itemMade: Item.TABLE_SALT, qtyMade: 3, crafter: Job.CUL, level: 7, lodestoneID: "",
					itemsRequired: [
						{ item: Item.ROCK_SALT, qty: 1 },
						{ item: Item.FIRE_SHARD, qty: 1 },
					],
				},
			],
			shops: [{ shop: Shop.SAHAGIN_VENDOR, cost: [{ price: 2, currency: Currency.GIL }] }],
		},
		used: [],
	},
	[Item.MINERAL_WATER]: {
		name: Item.MINERAL_WATER, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			markets: { [Market.UNIVERSALIS]: 4751 },
			shops: [
				{ shop: Shop.ALLAGAN_RESUPPLY_NODE, cost: [{ price: 4, currency: Currency.GIL }] },
				{ shop: Shop.ENGERRAND, cost: [{ price: 4, currency: Currency.GIL }] },
				{ shop: Shop.FRIDURIH, cost: [{ price: 4, currency: Currency.GIL }] },
				{ shop: Shop.JOSSY, cost: [{ price: 4, currency: Currency.GIL }] },
				{ shop: Shop.MATERIAL_SUPPLIER, cost: [{ price: 4, currency: Currency.GIL }] },
				{ shop: Shop.ORHOYOD, cost: [{ price: 4, currency: Currency.GIL }] },
				{ shop: Shop.PASDEVILLET, cost: [{ price: 4, currency: Currency.GIL }] },
				{ shop: Shop.SHIRAKASHI, cost: [{ price: 4, currency: Currency.GIL }] },
				{ shop: Shop.TRAVELING_MATERIAL_SUPPLIER, cost: [{ price: 4, currency: Currency.GIL }] },
			],
		},
		used: [],
	},
	[Item.BUFFALO_MILK]: {
		name: Item.BUFFALO_MILK, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			markets: { [Market.UNIVERSALIS]: 4753 },
			shops: [
				{ shop: Shop.ALEHOUSE_WENCH_BLACK_BRUSH, cost: [{ price: 4, currency: Currency.GIL }] },
				{ shop: Shop.ALLAGAN_RESUPPLY_NODE, cost: [{ price: 4, currency: Currency.GIL }] },
				{ shop: Shop.ENGERRAND, cost: [{ price: 4, currency: Currency.GIL }] },
				{ shop: Shop.FRIDURIH, cost: [{ price: 4, currency: Currency.GIL }] },
				{ shop: Shop.JOSSY, cost: [{ price: 4, currency: Currency.GIL }] },
				{ shop: Shop.MATERIAL_SUPPLIER, cost: [{ price: 4, currency: Currency.GIL }] },
				{ shop: Shop.ORHOYOD, cost: [{ price: 4, currency: Currency.GIL }] },
				{ shop: Shop.PASDEVILLET, cost: [{ price: 4, currency: Currency.GIL }] },
				{ shop: Shop.SHIRAKASHI, cost: [{ price: 4, currency: Currency.GIL }] },
				{ shop: Shop.TRAVELING_MATERIAL_SUPPLIER, cost: [{ price: 4, currency: Currency.GIL }] },
				{ shop: Shop.VRAL, cost: [{ price: 4, currency: Currency.GIL }] },
			],
		},
		used: [],
	},
	[Item.FLAX]: {
		name: Item.FLAX, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			markets: { [Market.UNIVERSALIS]: 5346 },
			gathering: [{ method: GatherMethod.HARVESTING, found: { location: MapLocation.LOWER_PATHS, x: 17, y: 29 } }],
		},
		used: [],
	},
	[Item.HAMMERHEAD_SHARK]: {
		name: Item.HAMMERHEAD_SHARK, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			markets: { [Market.UNIVERSALIS]: 4893 },
			fishingHoles: [
				{ fish: Fish.HAMMERHEAD_SHARK, hole: Fishing.HALFSTONE, bait: [Bait.HEAVY_STEEL_JIG, Bait.STEEL_JIG, Bait.SINKING_MINNOW, Bait.YUMIZUNO, Bait.NORTHERN_KRILL, Bait.SPOON_WORM, Bait.VERSATILE_LURE, Bait.KRILL_CAGE_FEEDER] },
				{ fish: Fish.HAMMERHEAD_SHARK, hole: Fishing.SILVER_BAZAAR, bait: [Bait.SHRIMP_CAGE_FEEDER, Bait.SPOON_WORM, Bait.SINKING_MINNOW, Bait.HEAVY_STEEL_JIG, Bait.STEEL_JIG, Bait.KRILL_CAGE_FEEDER, Bait.YUMIZUNO, Bait.NORTHERN_KRILL] },
			],
		},
		used: [],
	},
	[Item.SILVER_SHARK]: {
		name: Item.SILVER_SHARK, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			markets: { [Market.UNIVERSALIS]: 4903 },
			fishingHoles: [
				{
					fish: Fish.SILVER_SHARK,
					hole: Fishing.CAPE_WESTWIND,
					mooch: [
						[{ fish: Fish.MERLTHOR_GOBY, bait: [Bait.LUGWORM, Bait.PILL_BUG, Bait.GOBY_BALL, Bait.RAT_TAIL, Bait.FLOATING_MINNOW, Bait.KRILL_CAGE_FEEDER, Bait.HEAVY_STEEL_JIG, Bait.NORTHERN_KRILL, Bait.VERSATILE_LURE] }],
						[
							{ fish: Fish.MERLTHOR_GOBY, bait: [Bait.LUGWORM, Bait.PILL_BUG, Bait.GOBY_BALL, Bait.RAT_TAIL, Bait.FLOATING_MINNOW, Bait.KRILL_CAGE_FEEDER, Bait.HEAVY_STEEL_JIG, Bait.NORTHERN_KRILL, Bait.VERSATILE_LURE] },
							{ fish: Fish.WHAOO }, // Mooched from Goby
						],
					],
				},
				{
					fish: Fish.SILVER_SHARK,
					hole: Fishing.COSTA_DEL_SOL,
					mooch: [[{ fish: Fish.FULLMOON_SARDINE, bait: [Bait.SPOON_WORM, Bait.STEEL_JIG, Bait.SINKING_MINNOW, Bait.HEAVY_STEEL_JIG, Bait.SALTWATER_BOILIE, Bait.VERSATILE_LURE] }]],
				},
				{
					fish: Fish.SILVER_SHARK,
					hole: Fishing.UMBRA_NORTHSHORE,
					mooch: [
						[{ fish: Fish.MERLTHOR_GOBY, bait: [Bait.LUGWORM, Bait.PILL_BUG, Bait.GOBY_BALL, Bait.RAT_TAIL, Bait.FLOATING_MINNOW, Bait.KRILL_CAGE_FEEDER, Bait.HEAVY_STEEL_JIG, Bait.NORTHERN_KRILL, Bait.VERSATILE_LURE] }],
						[{ fish: Fish.FULLMOON_SARDINE, bait: [Bait.SPOON_WORM, Bait.STEEL_JIG, Bait.SINKING_MINNOW, Bait.HEAVY_STEEL_JIG, Bait.SALTWATER_BOILIE, Bait.VERSATILE_LURE] }],
						[
							{ fish: Fish.MERLTHOR_GOBY, bait: [Bait.LUGWORM, Bait.PILL_BUG, Bait.GOBY_BALL, Bait.RAT_TAIL, Bait.FLOATING_MINNOW, Bait.KRILL_CAGE_FEEDER, Bait.HEAVY_STEEL_JIG, Bait.NORTHERN_KRILL, Bait.VERSATILE_LURE] },
							{ fish: Fish.WHAOO }, // Mooched from Goby
						],
					],
				},
				{
					fish: Fish.SILVER_SHARK,
					hole: Fishing.SOUTH_BLOODSHORE,
					mooch: [
						[{ fish: Fish.MERLTHOR_GOBY, bait: [Bait.LUGWORM, Bait.PILL_BUG, Bait.GOBY_BALL, Bait.RAT_TAIL, Bait.FLOATING_MINNOW, Bait.KRILL_CAGE_FEEDER, Bait.HEAVY_STEEL_JIG, Bait.NORTHERN_KRILL, Bait.VERSATILE_LURE] }],
						[{ fish: Fish.FULLMOON_SARDINE, bait: [Bait.SPOON_WORM, Bait.STEEL_JIG, Bait.SINKING_MINNOW, Bait.HEAVY_STEEL_JIG, Bait.SALTWATER_BOILIE, Bait.VERSATILE_LURE] }],
					],
				},
				{
					fish: Fish.SILVER_SHARK,
					hole: Fishing.SUMMERFORD,
					mooch: [
						[{ fish: Fish.MERLTHOR_GOBY, bait: [Bait.LUGWORM, Bait.PILL_BUG, Bait.GOBY_BALL, Bait.RAT_TAIL, Bait.FLOATING_MINNOW, Bait.KRILL_CAGE_FEEDER, Bait.HEAVY_STEEL_JIG, Bait.NORTHERN_KRILL, Bait.VERSATILE_LURE] }],
						[{ fish: Fish.HARBOR_HERRING, bait: [Bait.PILL_BUG, Bait.GOBY_BALL, Bait.RAT_TAIL, Bait.STEEL_JIG, Bait.FLOATING_MINNOW] }],
						[
							{ fish: Fish.HARBOR_HERRING, bait: [Bait.PILL_BUG, Bait.GOBY_BALL, Bait.RAT_TAIL, Bait.STEEL_JIG, Bait.FLOATING_MINNOW] },
							{ fish: Fish.ORGRE_BARRACUDA },
						],
					],
				},
			],
		},
		used: [],
	},
	[Item.PTERODACTYL]: {
		name: Item.PTERODACTYL, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			markets: { [Market.UNIVERSALIS]: 13733 },
			shops: [
				{ shop: Shop.ANNA, cost: [{ price: 20, currency: Currency.WHITE_GATHERERS_SCRIP }] },
				{ shop: Shop.DIANA, cost: [{ price: 20, currency: Currency.WHITE_GATHERERS_SCRIP }] },
				{ shop: Shop.ROWENA_REP_FOUNDATION, cost: [{ price: 20, currency: Currency.WHITE_GATHERERS_SCRIP }] },
				{ shop: Shop.SANANA, cost: [{ price: 20, currency: Currency.WHITE_GATHERERS_SCRIP }] },
				{ shop: Shop.SCRIP_EXCHANGE_EULMORE, cost: [{ price: 20, currency: Currency.WHITE_GATHERERS_SCRIP }] },
				{ shop: Shop.SCRIP_EXCHANGE_GRIDANIA, cost: [{ price: 20, currency: Currency.WHITE_GATHERERS_SCRIP }] },
				{ shop: Shop.SCRIP_EXCHANGE_IDYLLSHIRE, cost: [{ price: 20, currency: Currency.WHITE_GATHERERS_SCRIP }] },
				{ shop: Shop.SCRIP_EXCHANGE_LIMSA, cost: [{ price: 20, currency: Currency.WHITE_GATHERERS_SCRIP }] },
				{ shop: Shop.SCRIP_EXCHANGE_MOR_DHONA, cost: [{ price: 20, currency: Currency.WHITE_GATHERERS_SCRIP }] },
				{ shop: Shop.SCRIP_EXCHANGE_OCEAN_FISHING, cost: [{ price: 20, currency: Currency.WHITE_GATHERERS_SCRIP }] },
				{ shop: Shop.SCRIP_EXCHANGE_SHARLAYAN, cost: [{ price: 20, currency: Currency.WHITE_GATHERERS_SCRIP }] },
				{ shop: Shop.SCRIP_EXCHANGE_RADZ_AT_HAN, cost: [{ price: 20, currency: Currency.WHITE_GATHERERS_SCRIP }] },
				{ shop: Shop.SCRIP_EXCHANGE_RHALGRS_REACH, cost: [{ price: 20, currency: Currency.WHITE_GATHERERS_SCRIP }] },
				{ shop: Shop.SCRIP_EXCHANGE_CRYSTARIUM, cost: [{ price: 20, currency: Currency.WHITE_GATHERERS_SCRIP }] },
				{ shop: Shop.SCRIP_EXCHANGE_ULDAH, cost: [{ price: 20, currency: Currency.WHITE_GATHERERS_SCRIP }] },
			],
		},
		used: [],
	},
	[Item.DARK_CHESTNUT_LOG]: {
		name: Item.DARK_CHESTNUT_LOG, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			markets: { [Market.UNIVERSALIS]: 12578 },
			gathering: [{ method: GatherMethod.LOGGING, found: { location: MapLocation.SMOLDERING_WASTES, x: 26, y: 27 } }],
		},
		used: [],
	},
	[Item.DAWNBORNE_AETHERSAND]: {
		name: Item.DAWNBORNE_AETHERSAND, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			markets: { [Market.UNIVERSALIS]: 12937 },
			reduction: [Item.BRIGHT_FIRE_ROCK, Item.BRIGHT_LIGHTNING_ROCK, Item.PEAT_MOSS, Item.WATER_MINT],
		},
		used: [],
	},
	[Item.ADAMANTITE_ORE]: {
		name: Item.ADAMANTITE_ORE, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			markets: { [Market.UNIVERSALIS]: 12538 },
			gathering: [{ method: GatherMethod.MINING, found: { location: MapLocation.BETA_QUADRANT, x: 24, y: 6 }, timedNode: true, times: ["12 AM", "12 PM"] }],
		},
		used: [],
	},
	[Item.BIRCH_LOG]: {
		name: Item.BIRCH_LOG, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			markets: { [Market.UNIVERSALIS]: 12579 },
			gathering: [{ method: GatherMethod.LOGGING, found: { location: MapLocation.VOOR_SIAN_SIRAN, x: 27, y: 34 } }],
		},
		used: [],
	},
	[Item.SPHALERITE]: {
		name: Item.SPHALERITE, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			markets: { [Market.UNIVERSALIS]: 13750 },
			shops: [
				{ shop: Shop.ANNA, cost: [{ price: 10, currency: Currency.WHITE_GATHERERS_SCRIP }] },
				{ shop: Shop.DIANA, cost: [{ price: 10, currency: Currency.WHITE_GATHERERS_SCRIP }] },
				{ shop: Shop.ROWENA_REP_FOUNDATION, cost: [{ price: 10, currency: Currency.WHITE_GATHERERS_SCRIP }] },
				{ shop: Shop.SANANA, cost: [{ price: 10, currency: Currency.WHITE_GATHERERS_SCRIP }] },
				{ shop: Shop.SCRIP_EXCHANGE_EULMORE, cost: [{ price: 10, currency: Currency.WHITE_GATHERERS_SCRIP }] },
				{ shop: Shop.SCRIP_EXCHANGE_GRIDANIA, cost: [{ price: 10, currency: Currency.WHITE_GATHERERS_SCRIP }] },
				{ shop: Shop.SCRIP_EXCHANGE_IDYLLSHIRE, cost: [{ price: 10, currency: Currency.WHITE_GATHERERS_SCRIP }] },
				{ shop: Shop.SCRIP_EXCHANGE_LIMSA, cost: [{ price: 10, currency: Currency.WHITE_GATHERERS_SCRIP }] },
				{ shop: Shop.SCRIP_EXCHANGE_MOR_DHONA, cost: [{ price: 10, currency: Currency.WHITE_GATHERERS_SCRIP }] },
				{ shop: Shop.SCRIP_EXCHANGE_OCEAN_FISHING, cost: [{ price: 10, currency: Currency.WHITE_GATHERERS_SCRIP }] },
				{ shop: Shop.SCRIP_EXCHANGE_SHARLAYAN, cost: [{ price: 10, currency: Currency.WHITE_GATHERERS_SCRIP }] },
				{ shop: Shop.SCRIP_EXCHANGE_RADZ_AT_HAN, cost: [{ price: 10, currency: Currency.WHITE_GATHERERS_SCRIP }] },
				{ shop: Shop.SCRIP_EXCHANGE_RHALGRS_REACH, cost: [{ price: 10, currency: Currency.WHITE_GATHERERS_SCRIP }] },
				{ shop: Shop.SCRIP_EXCHANGE_CRYSTARIUM, cost: [{ price: 10, currency: Currency.WHITE_GATHERERS_SCRIP }] },
				{ shop: Shop.SCRIP_EXCHANGE_ULDAH, cost: [{ price: 10, currency: Currency.WHITE_GATHERERS_SCRIP }] },
			],
		},
		used: [],
	},
	[Item.TITANIUM_NUGGET]: {
		name: Item.TITANIUM_NUGGET, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			markets: { [Market.UNIVERSALIS]: 12524 },
			recipes: [
				{
					itemMade: Item.TITANIUM_NUGGET, qtyMade: 1, crafter: Job.BSM, level: 54, lodestoneID: "",
					itemsRequired: [
						{ item: Item.TITANIUM_ORE, qty: 5 },
						{ item: Item.IRON_ORE, qty: 1 },
						{ item: Item.GRENADE_ASH, qty: 1 },
						{ item: Item.FIRE_CRYSTAL, qty: 4 },
					],
				},
				{
					itemMade: Item.TITANIUM_NUGGET, qtyMade: 1, crafter: Job.ARM, level: 54, lodestoneID: "",
					itemsRequired: [
						{ item: Item.TITANIUM_ORE, qty: 5 },
						{ item: Item.IRON_ORE, qty: 1 },
						{ item: Item.GRENADE_ASH, qty: 1 },
						{ item: Item.ICE_CRYSTAL, qty: 4 },
					],
				},
			],
			shops: [{ shop: Shop.SCRAP_SALVAGER, cost: [{ price: 2692, currency: Currency.GIL }] }],
		},
		used: [],
	},
	[Item.DRAVANIAN_SPRING_WATER]: {
		name: Item.DRAVANIAN_SPRING_WATER, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			markets: { [Market.UNIVERSALIS]: 12631 },
			gathering: [{ method: GatherMethod.MINING, found: { location: MapLocation.SMOLDERING_WASTES, x: 28, y: 31 } }],
		},
		used: [],
	},
	[Item.DRAVANIAN_MISTLETOE]: {
		name: Item.DRAVANIAN_MISTLETOE, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			markets: { [Market.UNIVERSALIS]: 12943 },
			gathering: [{ method: GatherMethod.LOGGING, found: { location: MapLocation.EASTON_EYES, x: 26, y: 9 } }],
			shops: [
				{ shop: Shop.MOGMUL_MOGBELLY, cost: [{ price: 1893, currency: Currency.GIL }] },
				{ shop: Shop.PASDEVILLET, cost: [{ price: 1893, currency: Currency.GIL }] },
			],
		},
		used: [],
	},
	[Item.ROYAL_MISTLETOE]: {
		name: Item.ROYAL_MISTLETOE, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			markets: { [Market.UNIVERSALIS]: 13752 },
			shops: [
				{ shop: Shop.ANNA, cost: [{ price: 10, currency: Currency.WHITE_GATHERERS_SCRIP }] },
				{ shop: Shop.DIANA, cost: [{ price: 10, currency: Currency.WHITE_GATHERERS_SCRIP }] },
				{ shop: Shop.ROWENA_REP_FOUNDATION, cost: [{ price: 10, currency: Currency.WHITE_GATHERERS_SCRIP }] },
				{ shop: Shop.SANANA, cost: [{ price: 10, currency: Currency.WHITE_GATHERERS_SCRIP }] },
				{ shop: Shop.SCRIP_EXCHANGE_EULMORE, cost: [{ price: 10, currency: Currency.WHITE_GATHERERS_SCRIP }] },
				{ shop: Shop.SCRIP_EXCHANGE_GRIDANIA, cost: [{ price: 10, currency: Currency.WHITE_GATHERERS_SCRIP }] },
				{ shop: Shop.SCRIP_EXCHANGE_IDYLLSHIRE, cost: [{ price: 10, currency: Currency.WHITE_GATHERERS_SCRIP }] },
				{ shop: Shop.SCRIP_EXCHANGE_LIMSA, cost: [{ price: 10, currency: Currency.WHITE_GATHERERS_SCRIP }] },
				{ shop: Shop.SCRIP_EXCHANGE_MOR_DHONA, cost: [{ price: 10, currency: Currency.WHITE_GATHERERS_SCRIP }] },
				{ shop: Shop.SCRIP_EXCHANGE_OCEAN_FISHING, cost: [{ price: 10, currency: Currency.WHITE_GATHERERS_SCRIP }] },
				{ shop: Shop.SCRIP_EXCHANGE_SHARLAYAN, cost: [{ price: 10, currency: Currency.WHITE_GATHERERS_SCRIP }] },
				{ shop: Shop.SCRIP_EXCHANGE_RADZ_AT_HAN, cost: [{ price: 10, currency: Currency.WHITE_GATHERERS_SCRIP }] },
				{ shop: Shop.SCRIP_EXCHANGE_RHALGRS_REACH, cost: [{ price: 10, currency: Currency.WHITE_GATHERERS_SCRIP }] },
				{ shop: Shop.SCRIP_EXCHANGE_CRYSTARIUM, cost: [{ price: 10, currency: Currency.WHITE_GATHERERS_SCRIP }] },
				{ shop: Shop.SCRIP_EXCHANGE_ULDAH, cost: [{ price: 10, currency: Currency.WHITE_GATHERERS_SCRIP }] },
			],
		},
		used: [],
	},
	[Item.EAGLE_FEATHER]: {
		name: Item.EAGLE_FEATHER, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			markets: { [Market.UNIVERSALIS]: 5358 },
			shops: [
				{ shop: Shop.SERPENT_QUARTERMASTER, cost: [{ price: 200, currency: Currency.COMPANY_SEAL }] },
				{ shop: Shop.STORM_QUARTERMASTER, cost: [{ price: 200, currency: Currency.COMPANY_SEAL }] },
				{ shop: Shop.FLAME_QUARTERMASTER, cost: [{ price: 200, currency: Currency.COMPANY_SEAL }] },
			],
		},
		used: [],
	},
	[Item.DARK_CHESTNUT_LUMBER]: {
		name: Item.DARK_CHESTNUT_LUMBER, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			markets: { [Market.UNIVERSALIS]: 12581 },
			recipes: [
				{
					itemMade: Item.DARK_CHESTNUT_LUMBER, qtyMade: 1, crafter: Job.CRP, level: 54, lodestoneID: "",
					itemsRequired: [
						{ item: Item.DARK_CHESTNUT_LOG, qty: 5 },
						{ item: Item.WIND_CRYSTAL, qty: 4 },
					],
				},
			],
			shops: [{ shop: Shop.SCRAP_SALVAGER, cost: [{ price: 2592, currency: Currency.GIL }] }],
		},
		used: [],
	},
	[Item.HALLOWED_WATER]: {
		name: Item.HALLOWED_WATER, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			markets: { [Market.UNIVERSALIS]: 12605 },
			recipes: [
				{
					itemMade: Item.HALLOWED_WATER, qtyMade: 1, crafter: Job.ALC, level: 56, lodestoneID: "",
					itemsRequired: [
						{ item: Item.ABALATHIAN_SPRING_WATER, qty: 2 },
						{ item: Item.ABALATHIAN_MISTLETOE, qty: 2 },
						{ item: Item.WATER_CRYSTAL, qty: 5 },
					],
				},
			],
			shops: [{ shop: Shop.SCRAP_SALVAGER, cost: [{ price: 2408, currency: Currency.GIL }] }],
		},
		used: [],
	},
	[Item.SILVER_ORE]: {
		name: Item.SILVER_ORE, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			markets: { [Market.UNIVERSALIS]: 5113 },
			gathering: [{ method: GatherMethod.MINING, found: { location: MapLocation.UPPER_PATHS, x: 16, y: 20 } }],
		},
		used: [],
	},
	[Item.CLOUD_COTTON_BOLL]: {
		name: Item.CLOUD_COTTON_BOLL, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			markets: { [Market.UNIVERSALIS]: 13753 },
			shops: [
				{ shop: Shop.ANNA, cost: [{ price: 10, currency: Currency.WHITE_GATHERERS_SCRIP }] },
				{ shop: Shop.DIANA, cost: [{ price: 10, currency: Currency.WHITE_GATHERERS_SCRIP }] },
				{ shop: Shop.ROWENA_REP_FOUNDATION, cost: [{ price: 10, currency: Currency.WHITE_GATHERERS_SCRIP }] },
				{ shop: Shop.SANANA, cost: [{ price: 10, currency: Currency.WHITE_GATHERERS_SCRIP }] },
				{ shop: Shop.SCRIP_EXCHANGE_EULMORE, cost: [{ price: 10, currency: Currency.WHITE_GATHERERS_SCRIP }] },
				{ shop: Shop.SCRIP_EXCHANGE_GRIDANIA, cost: [{ price: 10, currency: Currency.WHITE_GATHERERS_SCRIP }] },
				{ shop: Shop.SCRIP_EXCHANGE_IDYLLSHIRE, cost: [{ price: 10, currency: Currency.WHITE_GATHERERS_SCRIP }] },
				{ shop: Shop.SCRIP_EXCHANGE_LIMSA, cost: [{ price: 10, currency: Currency.WHITE_GATHERERS_SCRIP }] },
				{ shop: Shop.SCRIP_EXCHANGE_MOR_DHONA, cost: [{ price: 10, currency: Currency.WHITE_GATHERERS_SCRIP }] },
				{ shop: Shop.SCRIP_EXCHANGE_OCEAN_FISHING, cost: [{ price: 10, currency: Currency.WHITE_GATHERERS_SCRIP }] },
				{ shop: Shop.SCRIP_EXCHANGE_SHARLAYAN, cost: [{ price: 10, currency: Currency.WHITE_GATHERERS_SCRIP }] },
				{ shop: Shop.SCRIP_EXCHANGE_RADZ_AT_HAN, cost: [{ price: 10, currency: Currency.WHITE_GATHERERS_SCRIP }] },
				{ shop: Shop.SCRIP_EXCHANGE_RHALGRS_REACH, cost: [{ price: 10, currency: Currency.WHITE_GATHERERS_SCRIP }] },
				{ shop: Shop.SCRIP_EXCHANGE_CRYSTARIUM, cost: [{ price: 10, currency: Currency.WHITE_GATHERERS_SCRIP }] },
				{ shop: Shop.SCRIP_EXCHANGE_ULDAH, cost: [{ price: 10, currency: Currency.WHITE_GATHERERS_SCRIP }] },
			],
		},
		used: [],
	},
	[Item.BLACK_SOIL]: {
		name: Item.BLACK_SOIL, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			markets: { [Market.UNIVERSALIS]: 12970 },
			gathering: [
				{ method: GatherMethod.HARVESTING, found: { location: MapLocation.AVALONIA_FALLEN, x: 17, y: 32 }, timedNode: true, times: ["8 AM"] },
				{ method: GatherMethod.HARVESTING, found: { location: MapLocation.GREENSWARD, x: 10, y: 37 }, timedNode: true, times: ["4 PM"] },
				{ method: GatherMethod.HARVESTING, found: { location: MapLocation.PATHS_OF_CONTEMPLATION, x: 7, y: 27 }, timedNode: true, times: ["4 AM"] },
			],
		},
		used: [],
	},
	[Item.HIGHLAND_WHEAT]: {
		name: Item.HIGHLAND_WHEAT, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			markets: { [Market.UNIVERSALIS]: 12880 },
			gathering: [{ method: GatherMethod.HARVESTING, found: { location: MapLocation.CHOCOBO_FOREST, x: 38, y: 20 } }],
		},
		used: [],
	},
	[Item.BIRCH_SAP]: {
		name: Item.BIRCH_SAP, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			markets: { [Market.UNIVERSALIS]: 12891 },
			gathering: [{ method: GatherMethod.LOGGING, found: { location: MapLocation.VOOR_SIAN_SIRAN, x: 27, y: 34 } }],
			shops: [
				{ shop: Shop.LUNA_VANU, cost: [{ price: 468, currency: Currency.GIL }] },
				{ shop: Shop.LUNA_VANU, cost: [{ price: 1, currency: Currency.VANU_WHITEBONE }] },
			],
		},
		used: [],
	},
	[Item.OVIM_FLEECE]: {
		name: Item.OVIM_FLEECE, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			markets: { [Market.UNIVERSALIS]: 27756 },
			enemies: [Enemy.OVIM_BILLY, Enemy.OVIM_NANNY],
			shops: [
				{ shop: Shop.GRAMSOL, cost: [{ price: 2, currency: Currency.BICOLOUR_GEMSTONE }] },
				{ shop: Shop.HALDEN, cost: [{ price: 2, currency: Currency.BICOLOUR_GEMSTONE }] },
				{ shop: Shop.PEDRONILLE, cost: [{ price: 2, currency: Currency.BICOLOUR_GEMSTONE }] },
			],
		},
		used: [],
	},
	[Item.CINNABAR]: {
		name: Item.CINNABAR, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			markets: { [Market.UNIVERSALIS]: 5519 },
			gathering: [{ method: GatherMethod.QUARRYING, found: { location: MapLocation.HORIZONS_EDGE, x: 24, y: 19 } }],
			shops: [
				{ shop: Shop.ALLAGAN_RESUPPLY_NODE, cost: [{ price: 3, currency: Currency.GIL }] },
				{ shop: Shop.ENGERRAND, cost: [{ price: 3, currency: Currency.GIL }] },
				{ shop: Shop.ESMENET, cost: [{ price: 3, currency: Currency.GIL }] },
				{ shop: Shop.FRIDURIH, cost: [{ price: 3, currency: Currency.GIL }] },
				{ shop: Shop.MATERIAL_SUPPLIER, cost: [{ price: 3, currency: Currency.GIL }] },
				{ shop: Shop.ORHOYOD, cost: [{ price: 3, currency: Currency.GIL }] },
				{ shop: Shop.PASDEVILLET, cost: [{ price: 3, currency: Currency.GIL }] },
			],
		},
		used: [],
	},
	[Item.SUNSET_WHEAT]: {
		name: Item.SUNSET_WHEAT, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			markets: { [Market.UNIVERSALIS]: 4824 },
			gathering: [{ method: GatherMethod.HARVESTING, found: { location: MapLocation.SUMMERFORD, x: 23, y: 19 } }],
			shops: [
				{ shop: Shop.ALLAGAN_RESUPPLY_NODE, cost: [{ price: 5, currency: Currency.GIL }] },
				{ shop: Shop.JOSSY, cost: [{ price: 5, currency: Currency.GIL }] },
				{ shop: Shop.MATERIAL_SUPPLIER, cost: [{ price: 5, currency: Currency.GIL }] },
			],
		},
		used: [],
	},
	[Item.BRIGHT_FIRE_ROCK]: {
		name: Item.BRIGHT_FIRE_ROCK, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			markets: { [Market.UNIVERSALIS]: 12966 },
			gathering: [
				{ method: GatherMethod.QUARRYING, found: { location: MapLocation.AVALONIA_FALLEN, x: 25, y: 20 }, timedNode: true, times: ["4 AM"] },
				{ method: GatherMethod.QUARRYING, found: { location: MapLocation.GREENSWARD, x: 22, y: 38 }, timedNode: true, times: ["12 PM"] },
				{ method: GatherMethod.QUARRYING, found: { location: MapLocation.PATHS_OF_CREATION, x: 26, y: 17 }, timedNode: true, times: ["8 AM"] },
			],
		},
		used: [],
	},
	[Item.BRIGHT_LIGHTNING_ROCK]: {
		name: Item.BRIGHT_LIGHTNING_ROCK, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			markets: { [Market.UNIVERSALIS]: 12967 },
			gathering: [
				{ method: GatherMethod.QUARRYING, found: { location: MapLocation.BLACK_IRON_BRIDGE, x: 25, y: 20 }, timedNode: true, times: ["8 PM"] },
				{ method: GatherMethod.QUARRYING, found: { location: MapLocation.OK_GUNDU, x: 22, y: 38 }, timedNode: true, times: ["4 PM"] },
			],
		},
		used: [],
	},
	[Item.PEAT_MOSS]: {
		name: Item.PEAT_MOSS, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			markets: { [Market.UNIVERSALIS]: 12969 },
			gathering: [
				{ method: GatherMethod.HARVESTING, found: { location: MapLocation.AVALONIA_FALLEN, x: 17, y: 32 }, timedNode: true, times: ["8 AM"] },
				{ method: GatherMethod.HARVESTING, found: { location: MapLocation.GREENSWARD, x: 10, y: 37 }, timedNode: true, times: ["4 PM"] },
				{ method: GatherMethod.HARVESTING, found: { location: MapLocation.PATHS_OF_CONTEMPLATION, x: 7, y: 27 }, timedNode: true, times: ["4 AM"] },
			],
		},
		used: [],
	},
	[Item.WATER_MINT]: {
		name: Item.WATER_MINT, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			markets: { [Market.UNIVERSALIS]: 33149 },
			gathering: [
				{ method: GatherMethod.HARVESTING, found: { location: MapLocation.OK_VUNDU_MOK, x: 13, y: 22 }, timedNode: true, times: ["8 PM"] },
				{ method: GatherMethod.HARVESTING, found: { location: MapLocation.RED_RIM, x: 26, y: 29 }, timedNode: true, times: ["12 AM"] },
			],
		},
		used: [],
	},
	[Item.IRON_ORE]: {
		name: Item.IRON_ORE, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			markets: { [Market.UNIVERSALIS]: 5111 },
			gathering: [{ method: GatherMethod.MINING, found: { location: MapLocation.HORIZONS_EDGE, x: 27, y: 17 } }],
			shops: [
				{ shop: Shop.ALLAGAN_RESUPPLY_NODE, cost: [{ price: 18, currency: Currency.GIL }] },
				{ shop: Shop.MATERIAL_SUPPLIER, cost: [{ price: 18, currency: Currency.GIL }] },
				{ shop: Shop.SOEMRWYB, cost: [{ price: 18, currency: Currency.GIL }] },
			],
		},
		used: [],
	},
	[Item.ABALATHIAN_SPRING_WATER]: {
		name: Item.ABALATHIAN_SPRING_WATER, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			markets: { [Market.UNIVERSALIS]: 12632 },
			gathering: [{ method: GatherMethod.MINING, found: { location: MapLocation.VOOR_SIAN_SIRAN, x: 35, y: 31 } }],
		},
		used: [],
	},
	[Item.ABALATHIAN_MISTLETOE]: {
		name: Item.ABALATHIAN_MISTLETOE, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			markets: { [Market.UNIVERSALIS]: 12944 },
			gathering: [{ method: GatherMethod.LOGGING, found: { location: MapLocation.OK_BENDU, x: 40, y: 22 }, timedNode: true, times: ["6AM", "6PM"] }],
			shops: [{ shop: Shop.PASDEVILLET, cost: [{ price: 3370, currency: Currency.GIL }] }],
		},
		used: [],
	},
	[Item.COMPLEMENTARY_CHONDRITE]: {
		name: Item.COMPLEMENTARY_CHONDRITE, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { shops: [{ shop: Shop.JUBRUNNAH, cost: [{ price: 500, currency: Currency.POETICS }] }] },
		used: [
			{ relic: [RelicType.WEAPON, RelicGroup.WEAPON_MANDERVILLE, RelicPart.WEAPON_AMAZING_MANDERVILLE, RelicStep.AMAZING_MANDERVILLE, RelicTask.TASK_1], jobs: jobLists.relic[RelicGroup.WEAPON_MANDERVILLE], qtyPerJob: 3 },
		],
	},
	[Item.CONNOISSEURS_CHAIR]: {
		name: Item.CONNOISSEURS_CHAIR, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.CONNOISSEURS_CHAIR, qtyMade: 1, crafter: Job.CRP, level: 90, lodestoneID: "",
					itemsRequired: [
						{ item: Item.SELECT_IRONWOOD_LUMBER, qty: 1 },
						{ item: Item.IRONWOOD_LOG, qty: 2 },
						{ item: Item.ICE_CLUSTER, qty: 2 },
						{ item: Item.WIND_CLUSTER, qty: 4 },
					],
				},
			],
		},
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SPLENDOROUS, RelicPart.TOOL_AUGMENTED_SPLENDOROUS, RelicStep.SPLENDOROUS_COMPONENT, RelicTask.TASK_1], jobs: [Job.CRP], qtyPerJob: 20, maxQtyPerJob: 60 },
		],
	},
	[Item.CONNOISSEURS_WALL_LANTERN]: {
		name: Item.CONNOISSEURS_WALL_LANTERN, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.CONNOISSEURS_WALL_LANTERN, qtyMade: 1, crafter: Job.BSM, level: 90, lodestoneID: "",
					itemsRequired: [
						{ item: Item.SELECT_MANGANESE_INGOT, qty: 1 },
						{ item: Item.PHRYGIAN_GOLD_ORE, qty: 2 },
						{ item: Item.EARTH_CLUSTER, qty: 2 },
						{ item: Item.FIRE_CLUSTER, qty: 4 },
					],
				},
			],
		},
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SPLENDOROUS, RelicPart.TOOL_AUGMENTED_SPLENDOROUS, RelicStep.SPLENDOROUS_COMPONENT, RelicTask.TASK_1], jobs: [Job.BSM], qtyPerJob: 20, maxQtyPerJob: 60 },
		],
	},
	[Item.CONNOISSEURS_ORNATE_DOOR]: {
		name: Item.CONNOISSEURS_ORNATE_DOOR, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.CONNOISSEURS_ORNATE_DOOR, qtyMade: 1, crafter: Job.ARM, level: 90, lodestoneID: "",
					itemsRequired: [
						{ item: Item.SELECT_TITANIUM_PLATE, qty: 1 },
						{ item: Item.IRONWOOD_LOG, qty: 2 },
						{ item: Item.EARTH_CLUSTER, qty: 2 },
						{ item: Item.ICE_CLUSTER, qty: 4 },
					],
				},
			],
		},
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SPLENDOROUS, RelicPart.TOOL_AUGMENTED_SPLENDOROUS, RelicStep.SPLENDOROUS_COMPONENT, RelicTask.TASK_1], jobs: [Job.ARM], qtyPerJob: 20, maxQtyPerJob: 60 },
		],
	},
	[Item.CONNOISSEURS_VANITY_MIRROR]: {
		name: Item.CONNOISSEURS_VANITY_MIRROR, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.CONNOISSEURS_VANITY_MIRROR, qtyMade: 1, crafter: Job.GSM, level: 90, lodestoneID: "",
					itemsRequired: [
						{ item: Item.SELECT_CRYSTAL_GLASS, qty: 1 },
						{ item: Item.BISMUTH_ORE, qty: 2 },
						{ item: Item.FIRE_CLUSTER, qty: 2 },
						{ item: Item.WIND_CLUSTER, qty: 4 },
					],
				},
			],
		},
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SPLENDOROUS, RelicPart.TOOL_AUGMENTED_SPLENDOROUS, RelicStep.SPLENDOROUS_COMPONENT, RelicTask.TASK_1], jobs: [Job.GSM], qtyPerJob: 20, maxQtyPerJob: 60 },
		],
	},
	[Item.CONNOISSEURS_RUG]: {
		name: Item.CONNOISSEURS_RUG, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.CONNOISSEURS_RUG, qtyMade: 1, crafter: Job.LTW, level: 90, lodestoneID: "",
					itemsRequired: [
						{ item: Item.SELECT_SMILODON_LEATHER, qty: 1 },
						{ item: Item.ALMASTY_FUR, qty: 2 },
						{ item: Item.WIND_CLUSTER, qty: 2 },
						{ item: Item.EARTH_CLUSTER, qty: 4 },
					],
				},
			],
		},
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SPLENDOROUS, RelicPart.TOOL_AUGMENTED_SPLENDOROUS, RelicStep.SPLENDOROUS_COMPONENT, RelicTask.TASK_1], jobs: [Job.LTW], qtyPerJob: 20, maxQtyPerJob: 60 },
		],
	},
	[Item.CONNOISSEURS_SWAG_VALANCE]: {
		name: Item.CONNOISSEURS_SWAG_VALANCE, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.CONNOISSEURS_SWAG_VALANCE, qtyMade: 1, crafter: Job.WVR, level: 90, lodestoneID: "",
					itemsRequired: [
						{ item: Item.SELECT_SCARLET_MOKO_CLOTH, qty: 1 },
						{ item: Item.ALMASTY_FUR, qty: 2 },
						{ item: Item.WIND_CLUSTER, qty: 2 },
						{ item: Item.LIGHTNING_CLUSTER, qty: 4 },
					],
				},
			],
		},
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SPLENDOROUS, RelicPart.TOOL_AUGMENTED_SPLENDOROUS, RelicStep.SPLENDOROUS_COMPONENT, RelicTask.TASK_1], jobs: [Job.WVR], qtyPerJob: 20, maxQtyPerJob: 60 },
		],
	},
	[Item.CONNOISSEURS_COSMETICS_BOX]: {
		name: Item.CONNOISSEURS_COSMETICS_BOX, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.CONNOISSEURS_COSMETICS_BOX, qtyMade: 1, crafter: Job.ALC, level: 90, lodestoneID: "",
					itemsRequired: [
						{ item: Item.SELECT_HOPTRAP_LEAF, qty: 1 },
						{ item: Item.PETALOUDA_SCALES, qty: 2 },
						{ item: Item.LIGHTNING_CLUSTER, qty: 2 },
						{ item: Item.WATER_CLUSTER, qty: 4 },
					],
				},
			],
		},
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SPLENDOROUS, RelicPart.TOOL_AUGMENTED_SPLENDOROUS, RelicStep.SPLENDOROUS_COMPONENT, RelicTask.TASK_1], jobs: [Job.ALC], qtyPerJob: 20, maxQtyPerJob: 60 },
		],
	},
	[Item.CONNOISSEURS_PIXIEBERRY_TEA]: {
		name: Item.CONNOISSEURS_PIXIEBERRY_TEA, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.CONNOISSEURS_PIXIEBERRY_TEA, qtyMade: 1, crafter: Job.CUL, level: 90, lodestoneID: "",
					itemsRequired: [
						{ item: Item.SELECT_PIXIEBERRY, qty: 1 },
						{ item: Item.SIDERITIS_LEAVES, qty: 2 },
						{ item: Item.WATER_CLUSTER, qty: 2 },
						{ item: Item.FIRE_CLUSTER, qty: 4 },
					],
				},
			],
		},
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SPLENDOROUS, RelicPart.TOOL_AUGMENTED_SPLENDOROUS, RelicStep.SPLENDOROUS_COMPONENT, RelicTask.TASK_1], jobs: [Job.CUL], qtyPerJob: 20, maxQtyPerJob: 60 },
		],
	},
	[Item.CONNOISSEURS_PRISMSTONE]: {
		name: Item.CONNOISSEURS_PRISMSTONE, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { gathering: [{ found: { location: MapLocation.TIMH_GYEUS, x: 11, y: 36 }, method: GatherMethod.MINING }] },
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SPLENDOROUS, RelicPart.TOOL_AUGMENTED_SPLENDOROUS, RelicStep.SPLENDOROUS_COMPONENT, RelicTask.TASK_1], jobs: [Job.MIN], qtyPerJob: 60, maxQtyPerJob: 180 },
		],
	},
	[Item.SPLENDOROUS_WATER_SHARD]: {
		name: Item.SPLENDOROUS_WATER_SHARD, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { gathering: [{ found: { location: MapLocation.TIMH_GYEUS, x: 11, y: 36 }, method: GatherMethod.MINING }] },
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SPLENDOROUS, RelicPart.TOOL_AUGMENTED_SPLENDOROUS, RelicStep.SPLENDOROUS_COMPONENT, RelicTask.TASK_2], jobs: [Job.MIN], qtyPerJob: 180 },
		],
	},
	[Item.CONNOISSEURS_WATTLE_PETRIBARK]: {
		name: Item.CONNOISSEURS_WATTLE_PETRIBARK, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { gathering: [{ found: { location: MapLocation.DUERGAR_MOUNTAINS, x: 25, y: 12 }, method: GatherMethod.LOGGING }] },
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SPLENDOROUS, RelicPart.TOOL_AUGMENTED_SPLENDOROUS, RelicStep.SPLENDOROUS_COMPONENT, RelicTask.TASK_1], jobs: [Job.BTN], qtyPerJob: 60, maxQtyPerJob: 180 },
		],
	},
	[Item.SPLENDOROUS_EARTH_SHARD]: {
		name: Item.SPLENDOROUS_EARTH_SHARD, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { gathering: [{ found: { location: MapLocation.DUERGAR_MOUNTAINS, x: 25, y: 12 }, method: GatherMethod.LOGGING }] },
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SPLENDOROUS, RelicPart.TOOL_AUGMENTED_SPLENDOROUS, RelicStep.SPLENDOROUS_COMPONENT, RelicTask.TASK_2], jobs: [Job.BTN], qtyPerJob: 180 },
		],
	},
	[Item.PLATINUM_SEAHORSE]: {
		name: Item.PLATINUM_SEAHORSE, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { fishingHoles: [{ fish: Fish.PLATINUM_SEAHORSE, hole: Fishing.SOURCE, bait: [Bait.SELECT_BAIT_BALL] }] },
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SPLENDOROUS, RelicPart.TOOL_AUGMENTED_SPLENDOROUS, RelicStep.SPLENDOROUS_COMPONENT, RelicTask.TASK_1], jobs: [Job.FSH], qtyPerJob: 30, maxQtyPerJob: 60 },
		],
	},
	[Item.CLAVEKEEPER]: {
		name: Item.CLAVEKEEPER, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { fishingHoles: [{ fish: Fish.CLAVEKEEPER, hole: Fishing.SEAGAZER_SHOALS, bait: [Bait.SELECT_BAIT_BALL] }] },
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SPLENDOROUS, RelicPart.TOOL_AUGMENTED_SPLENDOROUS, RelicStep.SPLENDOROUS_COMPONENT, RelicTask.TASK_2], jobs: [Job.FSH], qtyPerJob: 30, maxQtyPerJob: 60 },
		],
	},
	[Item.CONNOISSEURS_MARIMBA]: {
		name: Item.CONNOISSEURS_MARIMBA, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.CONNOISSEURS_MARIMBA, qtyMade: 1, crafter: Job.CRP, level: 90, lodestoneID: "",
					itemsRequired: [
						{ item: Item.SELECT_IRONWOOD_LUMBER, qty: 1 },
						{ item: Item.INTEGRAL_LUMBER, qty: 1 },
						{ item: Item.CHONDRITE_INGOT, qty: 1 },
						{ item: Item.ICE_CLUSTER, qty: 2 },
						{ item: Item.WIND_CLUSTER, qty: 4 },
					],
				},
			],
		},
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SPLENDOROUS, RelicPart.TOOL_CRYSTALLINE, RelicStep.ADAPTIVE_COMPONENT, RelicTask.TASK_1], jobs: [Job.CRP], qtyPerJob: 30, maxQtyPerJob: 90 },
		],
	},
	[Item.CONNOISSEURS_COFFEE_BREWER]: {
		name: Item.CONNOISSEURS_COFFEE_BREWER, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.CONNOISSEURS_COFFEE_BREWER, qtyMade: 1, crafter: Job.BSM, level: 90, lodestoneID: "",
					itemsRequired: [
						{ item: Item.SELECT_MANGANESE_INGOT, qty: 1 },
						{ item: Item.STAR_QUARTZ, qty: 1 },
						{ item: Item.CHONDRITE_INGOT, qty: 1 },
						{ item: Item.EARTH_CLUSTER, qty: 2 },
						{ item: Item.FIRE_CLUSTER, qty: 4 },
					],
				},
			],
		},
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SPLENDOROUS, RelicPart.TOOL_CRYSTALLINE, RelicStep.ADAPTIVE_COMPONENT, RelicTask.TASK_1], jobs: [Job.BSM], qtyPerJob: 30, maxQtyPerJob: 90 },
		],
	},
	[Item.CONNOISSEURS_BENCH]: {
		name: Item.CONNOISSEURS_BENCH, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.CONNOISSEURS_BENCH, qtyMade: 1, crafter: Job.ARM, level: 90, lodestoneID: "",
					itemsRequired: [
						{ item: Item.SELECT_TITANIUM_PLATE, qty: 1 },
						{ item: Item.INTEGRAL_LUMBER, qty: 1 },
						{ item: Item.CHONDRITE_INGOT, qty: 1 },
						{ item: Item.EARTH_CLUSTER, qty: 2 },
						{ item: Item.ICE_CLUSTER, qty: 4 },
					],
				},
			],
		},
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SPLENDOROUS, RelicPart.TOOL_CRYSTALLINE, RelicStep.ADAPTIVE_COMPONENT, RelicTask.TASK_1], jobs: [Job.ARM], qtyPerJob: 30, maxQtyPerJob: 90 },
		],
	},
	[Item.CONNOISSEURS_ASTROSCOPE]: {
		name: Item.CONNOISSEURS_ASTROSCOPE, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.CONNOISSEURS_ASTROSCOPE, qtyMade: 1, crafter: Job.GSM, level: 90, lodestoneID: "",
					itemsRequired: [
						{ item: Item.SELECT_CRYSTAL_GLASS, qty: 1 },
						{ item: Item.STAR_QUARTZ, qty: 1 },
						{ item: Item.CHONDRITE_INGOT, qty: 1 },
						{ item: Item.FIRE_CLUSTER, qty: 2 },
						{ item: Item.WIND_CLUSTER, qty: 4 },
					],
				},
			],
		},
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SPLENDOROUS, RelicPart.TOOL_CRYSTALLINE, RelicStep.ADAPTIVE_COMPONENT, RelicTask.TASK_1], jobs: [Job.GSM], qtyPerJob: 30, maxQtyPerJob: 90 },
		],
	},
	[Item.CONNOISSEURS_LEATHER_SOFA]: {
		name: Item.CONNOISSEURS_LEATHER_SOFA, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.CONNOISSEURS_LEATHER_SOFA, qtyMade: 1, crafter: Job.LTW, level: 90, lodestoneID: "",
					itemsRequired: [
						{ item: Item.SELECT_SMILODON_LEATHER, qty: 1 },
						{ item: Item.INTEGRAL_LUMBER, qty: 1 },
						{ item: Item.AR_CAEAN_VELVET, qty: 1 },
						{ item: Item.WIND_CLUSTER, qty: 2 },
						{ item: Item.EARTH_CLUSTER, qty: 4 },
					],
				},
			],
		},
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SPLENDOROUS, RelicPart.TOOL_CRYSTALLINE, RelicStep.ADAPTIVE_COMPONENT, RelicTask.TASK_1], jobs: [Job.LTW], qtyPerJob: 30, maxQtyPerJob: 90 },
		],
	},
	[Item.CONNOISSEURS_RED_CARPET]: {
		name: Item.CONNOISSEURS_RED_CARPET, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.CONNOISSEURS_RED_CARPET, qtyMade: 1, crafter: Job.WVR, level: 90, lodestoneID: "",
					itemsRequired: [
						{ item: Item.SELECT_SCARLET_MOKO_CLOTH, qty: 1 },
						{ item: Item.AR_CAEAN_VELVET, qty: 1 },
						{ item: Item.OPHIOTAUROS_LEATHER, qty: 1 },
						{ item: Item.WIND_CLUSTER, qty: 2 },
						{ item: Item.LIGHTNING_CLUSTER, qty: 4 },
					],
				},
			],
		},
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SPLENDOROUS, RelicPart.TOOL_CRYSTALLINE, RelicStep.ADAPTIVE_COMPONENT, RelicTask.TASK_1], jobs: [Job.WVR], qtyPerJob: 30, maxQtyPerJob: 90 },
		],
	},
	[Item.CONNOISSEURS_ELIXIR_BOTTLE]: {
		name: Item.CONNOISSEURS_ELIXIR_BOTTLE, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.CONNOISSEURS_ELIXIR_BOTTLE, qtyMade: 1, crafter: Job.ALC, level: 90, lodestoneID: "",
					itemsRequired: [
						{ item: Item.SELECT_HOPTRAP_LEAF, qty: 1 },
						{ item: Item.GRADE_5_VITALITY_ALKAHEST, qty: 1 },
						{ item: Item.GRADE_5_MIND_ALKAHEST, qty: 1 },
						{ item: Item.LIGHTNING_CLUSTER, qty: 2 },
						{ item: Item.WATER_CLUSTER, qty: 4 },
					],
				},
			],
		},
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SPLENDOROUS, RelicPart.TOOL_CRYSTALLINE, RelicStep.ADAPTIVE_COMPONENT, RelicTask.TASK_1], jobs: [Job.ALC], qtyPerJob: 30, maxQtyPerJob: 90 },
		],
	},
	[Item.CONNOISSEURS_PIXIEBERRY_TART]: {
		name: Item.CONNOISSEURS_PIXIEBERRY_TART, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.CONNOISSEURS_PIXIEBERRY_TART, qtyMade: 1, crafter: Job.CUL, level: 90, lodestoneID: "",
					itemsRequired: [
						{ item: Item.SELECT_PIXIEBERRY, qty: 1 },
						{ item: Item.DARK_RYE_FLOUR, qty: 1 },
						{ item: Item.PALM_SUGAR, qty: 1 },
						{ item: Item.WATER_CLUSTER, qty: 2 },
						{ item: Item.FIRE_CLUSTER, qty: 4 },
					],
				},
			],
		},
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SPLENDOROUS, RelicPart.TOOL_CRYSTALLINE, RelicStep.ADAPTIVE_COMPONENT, RelicTask.TASK_1], jobs: [Job.CUL], qtyPerJob: 30, maxQtyPerJob: 90 },
		],
	},
	[Item.CONNOISSEURS_RED_MALACHITE]: {
		name: Item.CONNOISSEURS_RED_MALACHITE, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { gathering: [{ found: { location: MapLocation.FIELDS_OF_AMBER, x: 28, y: 14 }, method: GatherMethod.MINING }] },
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SPLENDOROUS, RelicPart.TOOL_CRYSTALLINE, RelicStep.ADAPTIVE_COMPONENT, RelicTask.TASK_1], jobs: [Job.MIN], qtyPerJob: 70, maxQtyPerJob: 210 },
		],
	},
	[Item.ADAPTIVE_FIRE_CRYSTAL]: {
		name: Item.ADAPTIVE_FIRE_CRYSTAL, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { gathering: [{ found: { location: MapLocation.FIELDS_OF_AMBER, x: 28, y: 14 }, method: GatherMethod.MINING }] },
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SPLENDOROUS, RelicPart.TOOL_CRYSTALLINE, RelicStep.ADAPTIVE_COMPONENT, RelicTask.TASK_2], jobs: [Job.MIN], qtyPerJob: 210 },
		],
	},
	[Item.CONNOISSEURS_LEVIN_MINT]: {
		name: Item.CONNOISSEURS_LEVIN_MINT, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { gathering: [{ found: { location: MapLocation.WEATHERING, x: 34, y: 10 }, method: GatherMethod.LOGGING }] },
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SPLENDOROUS, RelicPart.TOOL_CRYSTALLINE, RelicStep.ADAPTIVE_COMPONENT, RelicTask.TASK_1], jobs: [Job.BTN], qtyPerJob: 70, maxQtyPerJob: 210 },
		],
	},
	[Item.ADAPTIVE_LIGHTNING_CRYSTAL]: {
		name: Item.ADAPTIVE_LIGHTNING_CRYSTAL, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { gathering: [{ found: { location: MapLocation.WEATHERING, x: 34, y: 10 }, method: GatherMethod.LOGGING }] },
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SPLENDOROUS, RelicPart.TOOL_CRYSTALLINE, RelicStep.ADAPTIVE_COMPONENT, RelicTask.TASK_2], jobs: [Job.BTN], qtyPerJob: 210 },
		],
	},
	[Item.MIRROR_IMAGE]: {
		name: Item.MIRROR_IMAGE, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { fishingHoles: [{ fish: Fish.MIRROR_IMAGE, hole: Fishing.HANDMIRROR_LAKE, bait: [Bait.SELECT_BAIT_BALL] }] },
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SPLENDOROUS, RelicPart.TOOL_CRYSTALLINE, RelicStep.ADAPTIVE_COMPONENT, RelicTask.TASK_1], jobs: [Job.FSH], qtyPerJob: 40, maxQtyPerJob: 80 },
		],
	},
	[Item.SPANGLED_PIRARUCU]: {
		name: Item.SPANGLED_PIRARUCU, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { fishingHoles: [{ fish: Fish.SPANGLED_PIRARUCU, hole: Fishing.LAKE_TUSI_MEKTA, bait: [Bait.SELECT_BAIT_BALL] }] },
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SPLENDOROUS, RelicPart.TOOL_CRYSTALLINE, RelicStep.ADAPTIVE_COMPONENT, RelicTask.TASK_2], jobs: [Job.FSH], qtyPerJob: 40, maxQtyPerJob: 80 },
		],
	},
	[Item.SELECT_IRONWOOD_LUMBER]: {
		name: Item.SELECT_IRONWOOD_LUMBER, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { shops: [{ shop: Shop.QUINNANA, cost: [{ price: 50, currency: Currency.WHITE_CRAFTERS_SCRIP }] }] },
		used: [],
	},
	[Item.SELECT_MANGANESE_INGOT]: {
		name: Item.SELECT_MANGANESE_INGOT, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { shops: [{ shop: Shop.QUINNANA, cost: [{ price: 50, currency: Currency.WHITE_CRAFTERS_SCRIP }] }] },
		used: [],
	},
	[Item.SELECT_TITANIUM_PLATE]: {
		name: Item.SELECT_TITANIUM_PLATE, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { shops: [{ shop: Shop.QUINNANA, cost: [{ price: 50, currency: Currency.WHITE_CRAFTERS_SCRIP }] }] },
		used: [],
	},
	[Item.SELECT_CRYSTAL_GLASS]: {
		name: Item.SELECT_CRYSTAL_GLASS, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { shops: [{ shop: Shop.QUINNANA, cost: [{ price: 50, currency: Currency.WHITE_CRAFTERS_SCRIP }] }] },
		used: [],
	},
	[Item.SELECT_SMILODON_LEATHER]: {
		name: Item.SELECT_SMILODON_LEATHER, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { shops: [{ shop: Shop.QUINNANA, cost: [{ price: 50, currency: Currency.WHITE_CRAFTERS_SCRIP }] }] },
		used: [],
	},
	[Item.SELECT_SCARLET_MOKO_CLOTH]: {
		name: Item.SELECT_SCARLET_MOKO_CLOTH, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { shops: [{ shop: Shop.QUINNANA, cost: [{ price: 50, currency: Currency.WHITE_CRAFTERS_SCRIP }] }] },
		used: [],
	},
	[Item.SELECT_HOPTRAP_LEAF]: {
		name: Item.SELECT_HOPTRAP_LEAF, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { shops: [{ shop: Shop.QUINNANA, cost: [{ price: 50, currency: Currency.WHITE_CRAFTERS_SCRIP }] }] },
		used: [],
	},
	[Item.SELECT_PIXIEBERRY]: {
		name: Item.SELECT_PIXIEBERRY, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { shops: [{ shop: Shop.QUINNANA, cost: [{ price: 50, currency: Currency.WHITE_CRAFTERS_SCRIP }] }] },
		used: [],
	},
	[Item.IRONWOOD_LOG]: {
		name: Item.IRONWOOD_LOG, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			gathering: [{ found: { location: MapLocation.KALLIMELIOS_ZEPHYROS, x: 9, y: 23 }, method: GatherMethod.LOGGING }],
			markets: { [Market.UNIVERSALIS]: -1 },
		},
		used: [],
	},
	[Item.PHRYGIAN_GOLD_ORE]: {
		name: Item.PHRYGIAN_GOLD_ORE, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			gathering: [{ found: { location: MapLocation.EBLAN_RIME, x: 21, y: 29 }, method: GatherMethod.QUARRYING }],
			markets: { [Market.UNIVERSALIS]: -1 },
		},
		used: [],
	},
	[Item.BISMUTH_ORE]: {
		name: Item.BISMUTH_ORE, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			gathering: [{ found: { location: MapLocation.SOUTHERN_MARE_LAMENTORUM, x: 29, y: 36 }, method: GatherMethod.MINING }],
			markets: { [Market.UNIVERSALIS]: -1 },
		},
		used: [],
	},
	[Item.ALMASTY_FUR]: {
		name: Item.ALMASTY_FUR, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			enemies: [Enemy.ALMASTY],
			shops: [{ shop: Shop.GADFRID, cost: [{ price: 2, currency: Currency.BICOLOUR_GEMSTONE }] }, { shop: Shop.SAJAREEN, cost: [{ price: 2, currency: Currency.BICOLOUR_GEMSTONE }] }, { shop: Shop.ZAWAWA, cost: [{ price: 2, currency: Currency.BICOLOUR_GEMSTONE }] }],
			markets: { [Market.UNIVERSALIS]: -1 },
		},
		used: [],
	},
	[Item.PETALOUDA_SCALES]: {
		name: Item.PETALOUDA_SCALES, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			enemies: [Enemy.IANTHINE_PETALOUDA, Enemy.KOKKINE_PETALOUDA, Enemy.KYANE_PETALOUDA],
			shops: [{ shop: Shop.AISARA, cost: [{ price: 2, currency: Currency.BICOLOUR_GEMSTONE }] }, { shop: Shop.GADFRID, cost: [{ price: 2, currency: Currency.BICOLOUR_GEMSTONE }] }, { shop: Shop.SAJAREEN, cost: [{ price: 2, currency: Currency.BICOLOUR_GEMSTONE }] }],
			markets: { [Market.UNIVERSALIS]: -1 },
		},
		used: [],
	},
	[Item.SIDERITIS_LEAVES]: {
		name: Item.SIDERITIS_LEAVES, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			gathering: [{ found: { location: MapLocation.PHILOMYTHES_NOTOS, x: 21, y: 24 }, method: GatherMethod.HARVESTING }],
			markets: { [Market.UNIVERSALIS]: -1 },
		},
		used: [],
	},
	[Item.INTEGRAL_LUMBER]: {
		name: Item.INTEGRAL_LUMBER, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.INTEGRAL_LUMBER, qtyMade: 1, crafter: Job.CRP, level: 88, lodestoneID: "",
					itemsRequired: [
						{ item: Item.INTEGRAL_LOG, qty: 5 },
						{ item: Item.WIND_CRYSTAL, qty: 8 },
					],
				},
			],
			markets: { [Market.UNIVERSALIS]: -1 },
		},
		used: [],
	},
	[Item.CHONDRITE_INGOT]: {
		name: Item.CHONDRITE_INGOT, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.CHONDRITE_INGOT, qtyMade: 1, crafter: Job.ARM, level: 88, lodestoneID: "",
					itemsRequired: [
						{ item: Item.CHONDRITE, qty: 5 },
						{ item: Item.DIMYTHRITE_ORE, qty: 1 },
						{ item: Item.ICE_CRYSTAL, qty: 8 },
					],
				},
				{
					itemMade: Item.CHONDRITE_INGOT, qtyMade: 1, crafter: Job.BSM, level: 88, lodestoneID: "",
					itemsRequired: [
						{ item: Item.CHONDRITE, qty: 5 },
						{ item: Item.DIMYTHRITE_ORE, qty: 1 },
						{ item: Item.FIRE_CRYSTAL, qty: 8 },
					],
				},
			],
			markets: { [Market.UNIVERSALIS]: -1 },
		},
		used: [],
	},
	[Item.STAR_QUARTZ]: {
		name: Item.STAR_QUARTZ, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.STAR_QUARTZ, qtyMade: 1, crafter: Job.GSM, level: 88, lodestoneID: "",
					itemsRequired: [
						{ item: Item.ANNITE_WHETSTONE, qty: 1 },
						{ item: Item.RAW_STAR_QUARTZ, qty: 3 },
						{ item: Item.WIND_CRYSTAL, qty: 8 },
					],
				},
			],
			markets: { [Market.UNIVERSALIS]: -1 },
		},
		used: [],
	},
	[Item.AR_CAEAN_VELVET]: {
		name: Item.AR_CAEAN_VELVET, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.AR_CAEAN_VELVET, qtyMade: 1, crafter: Job.WVR, level: 88, lodestoneID: "",
					itemsRequired: [
						{ item: Item.AR_CAEAN_COTTON_BOLL, qty: 5 },
						{ item: Item.LIGHTNING_CRYSTAL, qty: 8 },
					],
				},
			],
			markets: { [Market.UNIVERSALIS]: -1 },
		},
		used: [],
	},
	[Item.OPHIOTAUROS_LEATHER]: {
		name: Item.OPHIOTAUROS_LEATHER, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.OPHIOTAUROS_LEATHER, qtyMade: 1, crafter: Job.LTW, level: 88, lodestoneID: "",
					itemsRequired: [
						{ item: Item.OPHIOTAUROS_HIDE, qty: 4 },
						{ item: Item.EBLAN_ALUMEN, qty: 1 },
						{ item: Item.EARTH_CRYSTAL, qty: 8 },
					],
				},
			],
			markets: { [Market.UNIVERSALIS]: -1 },
		},
		used: [],
	},
	[Item.GRADE_5_VITALITY_ALKAHEST]: {
		name: Item.GRADE_5_VITALITY_ALKAHEST, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.GRADE_5_VITALITY_ALKAHEST, qtyMade: 3, crafter: Job.ALC, level: 83, lodestoneID: "",
					itemsRequired: [
						{ item: Item.UNDERGROUND_SPRING_WATER, qty: 2 },
						{ item: Item.LUNATENDER_BLOSSOM, qty: 1 },
						{ item: Item.LIME_BASIL, qty: 2 },
						{ item: Item.WATER_CRYSTAL, qty: 8 },
					],
				},
			],
			markets: { [Market.UNIVERSALIS]: -1 },
		},
		used: [],
	},
	[Item.GRADE_5_MIND_ALKAHEST]: {
		name: Item.GRADE_5_MIND_ALKAHEST, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.GRADE_5_MIND_ALKAHEST, qtyMade: 3, crafter: Job.ALC, level: 83, lodestoneID: "",
					itemsRequired: [
						{ item: Item.UNDERGROUND_SPRING_WATER, qty: 2 },
						{ item: Item.LUNATENDER_BLOSSOM, qty: 1 },
						{ item: Item.TIGER_LILY, qty: 2 },
						{ item: Item.WATER_CRYSTAL, qty: 8 },
					],
				},
			],
			markets: { [Market.UNIVERSALIS]: -1 },
		},
		used: [],
	},
	[Item.DARK_RYE_FLOUR]: {
		name: Item.DARK_RYE_FLOUR, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.DARK_RYE_FLOUR, qtyMade: 3, crafter: Job.CUL, level: 81, lodestoneID: "",
					itemsRequired: [
						{ item: Item.DARK_RYE, qty: 6 },
						{ item: Item.FIRE_CRYSTAL, qty: 8 },
					],
				},
			],
			markets: { [Market.UNIVERSALIS]: -1 },
		},
		used: [],
	},
	[Item.PALM_SUGAR]: {
		name: Item.PALM_SUGAR, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.PALM_SUGAR, qtyMade: 3, crafter: Job.CUL, level: 82, lodestoneID: "",
					itemsRequired: [
						{ item: Item.PALM_SYRUP, qty: 6 },
						{ item: Item.FIRE_CRYSTAL, qty: 8 },
					],
				},
			],
			markets: { [Market.UNIVERSALIS]: -1 },
		},
		used: [],
	},
	[Item.INTEGRAL_LOG]: {
		name: Item.INTEGRAL_LOG, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			gathering: [{ found: { location: MapLocation.OSTRAKON_DEKA_OKTO, x: 8, y: 22 }, method: GatherMethod.LOGGING }],
			markets: { [Market.UNIVERSALIS]: -1 },
		},
		used: [],
	},
	[Item.CHONDRITE]: {
		name: Item.CHONDRITE, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			gathering: [{ found: { location: MapLocation.OSTRAKON_TRIA, x: 19, y: 13 }, method: GatherMethod.MINING }],
			markets: { [Market.UNIVERSALIS]: -1 },
		},
		used: [],
	},
	[Item.ANNITE_WHETSTONE]: {
		name: Item.ANNITE_WHETSTONE, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.ANNITE_WHETSTONE, qtyMade: 1, crafter: Job.GSM, level: 86, lodestoneID: "",
					itemsRequired: [
						{ item: Item.ANNITE, qty: 4 },
						{ item: Item.WIND_CRYSTAL, qty: 8 },
					],
				},
			],
			markets: { [Market.UNIVERSALIS]: -1 },
		},
		used: [],
	},
	[Item.RAW_STAR_QUARTZ]: {
		name: Item.RAW_STAR_QUARTZ, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			gathering: [{ found: { location: MapLocation.OSTRAKON_DEKA_OKTO, x: 21, y: 33 }, method: GatherMethod.QUARRYING }],
			markets: { [Market.UNIVERSALIS]: -1 },
		},
		used: [],
	},
	[Item.AR_CAEAN_COTTON_BOLL]: {
		name: Item.AR_CAEAN_COTTON_BOLL, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			gathering: [{ found: { location: MapLocation.OSTRAKON_DEKA_OKTO, x: 14, y: 28 }, method: GatherMethod.HARVESTING }],
			markets: { [Market.UNIVERSALIS]: -1 },
		},
		used: [],
	},
	[Item.OPHIOTAUROS_HIDE]: {
		name: Item.OPHIOTAUROS_HIDE, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			enemies: [Enemy.OPHIOTAUROS],
			shops: [{ shop: Shop.AISARA, cost: [{ price: 2, currency: Currency.BICOLOUR_GEMSTONE }] }, { shop: Shop.GADFRID, cost: [{ price: 2, currency: Currency.BICOLOUR_GEMSTONE }] }, { shop: Shop.SAJAREEN, cost: [{ price: 2, currency: Currency.BICOLOUR_GEMSTONE }] }],
			markets: { [Market.UNIVERSALIS]: -1 },
		},
		used: [],
	},
	[Item.EBLAN_ALUMEN]: {
		name: Item.EBLAN_ALUMEN, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			gathering: [{ found: { location: MapLocation.EBLAN_RIME, x: 21, y: 29 }, method: GatherMethod.QUARRYING }],
			markets: { [Market.UNIVERSALIS]: -1 },
		},
		used: [],
	},
	[Item.UNDERGROUND_SPRING_WATER]: {
		name: Item.UNDERGROUND_SPRING_WATER, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			gathering: [{ found: { location: MapLocation.CHURCH_OF_FIRST_LIGHT, x: 35, y: 15 }, method: GatherMethod.MINING }],
			markets: { [Market.UNIVERSALIS]: -1 },
		},
		used: [],
	},
	[Item.LIME_BASIL]: {
		name: Item.LIME_BASIL, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			gathering: [{ found: { location: MapLocation.SULLEN, x: 25, y: 30 }, method: GatherMethod.HARVESTING }],
			markets: { [Market.UNIVERSALIS]: -1 },
		},
		used: [],
	},
	[Item.TIGER_LILY]: {
		name: Item.TIGER_LILY, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			gathering: [{ found: { location: MapLocation.SULLEN, x: 25, y: 30 }, method: GatherMethod.HARVESTING }],
			markets: { [Market.UNIVERSALIS]: -1 },
		},
		used: [],
	},
	[Item.LUNATENDER_BLOSSOM]: {
		name: Item.LUNATENDER_BLOSSOM, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			enemies: [Enemy.LUNATENDER],
			shops: [{ shop: Shop.GADFRID, cost: [{ price: 2, currency: Currency.BICOLOUR_GEMSTONE }] }, { shop: Shop.SAJAREEN, cost: [{ price: 2, currency: Currency.BICOLOUR_GEMSTONE }] }, { shop: Shop.TRADINGWAY, cost: [{ price: 2, currency: Currency.BICOLOUR_GEMSTONE }] }],
			markets: { [Market.UNIVERSALIS]: -1 },
		},
		used: [],
	},
	[Item.DARK_RYE]: {
		name: Item.DARK_RYE, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			gathering: [{ found: { location: MapLocation.MEDIAL_CIRCUIT, x: 28, y: 17 }, method: GatherMethod.HARVESTING }],
			markets: { [Market.UNIVERSALIS]: -1 },
		},
		used: [],
	},
	[Item.PALM_SYRUP]: {
		name: Item.PALM_SYRUP, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			gathering: [{ found: { location: MapLocation.PERFUMED_RISE, x: 17, y: 22 }, method: GatherMethod.LOGGING }],
			markets: { [Market.UNIVERSALIS]: -1 },
		},
		used: [],
	},
	[Item.ANNITE]: {
		name: Item.ANNITE, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			gathering: [{ found: { location: MapLocation.KALLIMELIOS_ZEPHYROS, x: 14, y: 18 }, method: GatherMethod.QUARRYING }],
			markets: { [Market.UNIVERSALIS]: -1 },
		},
		used: [],
	},
	[Item.REINFORCED_SPRUCE_PLYWOOD]: {
		name: Item.REINFORCED_SPRUCE_PLYWOOD, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.REINFORCED_SPRUCE_PLYWOOD, qtyMade: 1, crafter: Job.CRP, level: 50, lodestoneID: "",
					itemsRequired: [
						{ item: Item.SPRUCE_PLYWOOD, qty: 1 },
						{ item: Item.FIELDCRAFT_DEMIMATERIA_III, qty: 2 },
						{ item: Item.WIND_SHARD, qty: 12 },
						{ item: Item.ICE_SHARD, qty: 11 },
					],
				},
			],
		},
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_LUCIS, RelicPart.TOOL_SUPRA, RelicStep.SUPRA_ITEMS, RelicTask.TASK_1], jobs: [Job.CRP], qtyPerJob: 1 },
		],
	},
	[Item.BALANCED_DARKSTEEL_HOOK]: {
		name: Item.BALANCED_DARKSTEEL_HOOK, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.BALANCED_DARKSTEEL_HOOK, qtyMade: 1, crafter: Job.BSM, level: 50, lodestoneID: "",
					itemsRequired: [
						{ item: Item.DARKSTEEL_HOOK, qty: 1 },
						{ item: Item.FIELDCRAFT_DEMIMATERIA_III, qty: 2 },
						{ item: Item.FIRE_SHARD, qty: 12 },
						{ item: Item.EARTH_SHARD, qty: 11 },
					],
				},
			],
		},
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_LUCIS, RelicPart.TOOL_SUPRA, RelicStep.SUPRA_ITEMS, RelicTask.TASK_1], jobs: [Job.BSM], qtyPerJob: 1 },
		],
	},
	[Item.REINFOCED_DARKSTEEL_WIRE]: {
		name: Item.REINFOCED_DARKSTEEL_WIRE, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.REINFOCED_DARKSTEEL_WIRE, qtyMade: 1, crafter: Job.ARM, level: 50, lodestoneID: "",
					itemsRequired: [
						{ item: Item.DARKSTEEL_WIRE, qty: 1 },
						{ item: Item.FIELDCRAFT_DEMIMATERIA_III, qty: 2 },
						{ item: Item.ICE_SHARD, qty: 12 },
						{ item: Item.EARTH_SHARD, qty: 11 },
					],
				},
			],
		},
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_LUCIS, RelicPart.TOOL_SUPRA, RelicStep.SUPRA_ITEMS, RelicTask.TASK_1], jobs: [Job.ARM], qtyPerJob: 1 },
		],
	},
	[Item.CALIBRATED_ROSE_GOLD_COG]: {
		name: Item.CALIBRATED_ROSE_GOLD_COG, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.CALIBRATED_ROSE_GOLD_COG, qtyMade: 1, crafter: Job.GSM, level: 50, lodestoneID: "",
					itemsRequired: [
						{ item: Item.ROSE_GOLD_COG, qty: 1 },
						{ item: Item.FIELDCRAFT_DEMIMATERIA_III, qty: 2 },
						{ item: Item.WIND_SHARD, qty: 12 },
						{ item: Item.FIRE_SHARD, qty: 11 },
					],
				},
			],
		},
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_LUCIS, RelicPart.TOOL_SUPRA, RelicStep.SUPRA_ITEMS, RelicTask.TASK_1], jobs: [Job.GSM], qtyPerJob: 1 },
		],
	},
	[Item.WATERHARDENED_HIPPOGRYPH_STRAP]: {
		name: Item.WATERHARDENED_HIPPOGRYPH_STRAP, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.WATERHARDENED_HIPPOGRYPH_STRAP, qtyMade: 1, crafter: Job.LTW, level: 50, lodestoneID: "",
					itemsRequired: [
						{ item: Item.GRYPHONSKIN_STRAP, qty: 1 },
						{ item: Item.FIELDCRAFT_DEMIMATERIA_III, qty: 2 },
						{ item: Item.EARTH_SHARD, qty: 12 },
						{ item: Item.WIND_SHARD, qty: 11 },
					],
				},
			],
		},
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_LUCIS, RelicPart.TOOL_SUPRA, RelicStep.SUPRA_ITEMS, RelicTask.TASK_1], jobs: [Job.LTW], qtyPerJob: 1 },
		],
	},
	[Item.INTRICATE_SILVER_BROCADE]: {
		name: Item.INTRICATE_SILVER_BROCADE, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.INTRICATE_SILVER_BROCADE, qtyMade: 1, crafter: Job.WVR, level: 50, lodestoneID: "",
					itemsRequired: [
						{ item: Item.SILVER_BROCADE, qty: 1 },
						{ item: Item.FIELDCRAFT_DEMIMATERIA_III, qty: 2 },
						{ item: Item.LIGHTNING_SHARD, qty: 12 },
						{ item: Item.WIND_SHARD, qty: 11 },
					],
				},
			],
		},
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_LUCIS, RelicPart.TOOL_SUPRA, RelicStep.SUPRA_ITEMS, RelicTask.TASK_1], jobs: [Job.WVR], qtyPerJob: 1 },
		],
	},
	[Item.CONCENTRATED_SPIRITS_OF_SALT]: {
		name: Item.CONCENTRATED_SPIRITS_OF_SALT, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.CONCENTRATED_SPIRITS_OF_SALT, qtyMade: 1, crafter: Job.ALC, level: 50, lodestoneID: "",
					itemsRequired: [
						{ item: Item.SPIRITS_OF_SALT, qty: 1 },
						{ item: Item.FIELDCRAFT_DEMIMATERIA_III, qty: 2 },
						{ item: Item.WATER_SHARD, qty: 12 },
						{ item: Item.LIGHTNING_SHARD, qty: 11 },
					],
				},
			],
		},
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_LUCIS, RelicPart.TOOL_SUPRA, RelicStep.SUPRA_ITEMS, RelicTask.TASK_1], jobs: [Job.ALC], qtyPerJob: 1 },
		],
	},
	[Item.RICH_TOMATO_RELISH]: {
		name: Item.RICH_TOMATO_RELISH, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.RICH_TOMATO_RELISH, qtyMade: 1, crafter: Job.CUL, level: 50, lodestoneID: "",
					itemsRequired: [
						{ item: Item.SPICY_TOMATO_RELISH, qty: 1 },
						{ item: Item.FIELDCRAFT_DEMIMATERIA_III, qty: 2 },
						{ item: Item.FIRE_SHARD, qty: 12 },
						{ item: Item.WATER_SHARD, qty: 11 },
					],
				},
			],
		},
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_LUCIS, RelicPart.TOOL_SUPRA, RelicStep.SUPRA_ITEMS, RelicTask.TASK_1], jobs: [Job.CUL], qtyPerJob: 1 },
		],
	},
	[Item.TALANS_SEAL_OF_MINING]: {
		name: Item.TALANS_SEAL_OF_MINING, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { shops: [{ shop: Shop.TALAN, cost: [{ price: 30, currency: Item.ANTUMBRAL_ROCK }, { price: 2, currency: Item.FIELDCRAFT_DEMIMATERIA_III }] }] },
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_LUCIS, RelicPart.TOOL_SUPRA, RelicStep.SUPRA_ITEMS, RelicTask.TASK_1], jobs: [Job.MIN], qtyPerJob: 1 },
		],
	},
	[Item.TALANS_SEAL_OF_BOTANY]: {
		name: Item.TALANS_SEAL_OF_BOTANY, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { shops: [{ shop: Shop.TALAN, cost: [{ price: 30, currency: Item.REDOLENT_LOG }, { price: 2, currency: Item.FIELDCRAFT_DEMIMATERIA_III }] }] },
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_LUCIS, RelicPart.TOOL_SUPRA, RelicStep.SUPRA_ITEMS, RelicTask.TASK_1], jobs: [Job.BTN], qtyPerJob: 1 },
		],
	},
	[Item.TALANS_SEAL_OF_FISHING]: {
		name: Item.TALANS_SEAL_OF_FISHING, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { shops: [{ shop: Shop.TALAN, cost: [{ price: 1, currency: Item.OLGOI_KHORKHOI }, { price: 2, currency: Item.FIELDCRAFT_DEMIMATERIA_III }] }] },
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_LUCIS, RelicPart.TOOL_SUPRA, RelicStep.SUPRA_ITEMS, RelicTask.TASK_1], jobs: [Job.FSH], qtyPerJob: 1 },
		],
	},
	[Item.MASTERCRAFT_DEMIMATERIA]: {
		name: Item.MASTERCRAFT_DEMIMATERIA, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { shops: [{ shop: Shop.ANNA, cost: [{ price: 800, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.DIANA, cost: [{ price: 800, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SANANA, cost: [{ price: 800, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_RHALGRS_REACH, cost: [{ price: 800, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_RADZ_AT_HAN, cost: [{ price: 800, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_EULMORE, cost: [{ price: 800, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_MOR_DHONA, cost: [{ price: 800, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_IDYLLSHIRE, cost: [{ price: 800, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_ULDAH, cost: [{ price: 800, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_SHARLAYAN, cost: [{ price: 800, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_GRIDANIA, cost: [{ price: 800, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_CRYSTARIUM, cost: [{ price: 800, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_LIMSA, cost: [{ price: 800, currency: Currency.WHITE_CRAFTERS_SCRIP }] }] },
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_LUCIS, RelicPart.TOOL_SUPRA, RelicStep.SUPRA_ITEMS, RelicTask.TASK_2], jobs: jobLists.type.utility, qtyPerJob: 1 },
		],
	},
	[Item.SPRUCE_PLYWOOD]: {
		name: Item.SPRUCE_PLYWOOD, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.SPRUCE_PLYWOOD, qtyMade: 1, crafter: Job.CRP, level: 50, lodestoneID: "",
					itemsRequired: [
						{ item: Item.SPRUCE_LUMBER, qty: 1 },
						{ item: Item.HORN_GLUE, qty: 2 },
						{ item: Item.WIND_SHARD, qty: 11 },
						{ item: Item.ICE_SHARD, qty: 10 },
					],
				},
			],
		},
		used: [],
	},
	[Item.FIELDCRAFT_DEMIMATERIA_III]: {
		name: Item.FIELDCRAFT_DEMIMATERIA_III, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { shops: [{ shop: Shop.ANNA, cost: [{ price: 200, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.DIANA, cost: [{ price: 200, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SANANA, cost: [{ price: 200, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_RHALGRS_REACH, cost: [{ price: 200, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_RADZ_AT_HAN, cost: [{ price: 200, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_EULMORE, cost: [{ price: 200, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_MOR_DHONA, cost: [{ price: 200, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_IDYLLSHIRE, cost: [{ price: 200, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_ULDAH, cost: [{ price: 200, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_SHARLAYAN, cost: [{ price: 200, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_GRIDANIA, cost: [{ price: 200, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_CRYSTARIUM, cost: [{ price: 200, currency: Currency.WHITE_CRAFTERS_SCRIP }] }, { shop: Shop.SCRIP_EXCHANGE_LIMSA, cost: [{ price: 200, currency: Currency.WHITE_CRAFTERS_SCRIP }] }] },
		used: [],
	},
	[Item.DARKSTEEL_HOOK]: {
		name: Item.DARKSTEEL_HOOK, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.DARKSTEEL_HOOK, qtyMade: 1, crafter: Job.BSM, level: 50, lodestoneID: "",
					itemsRequired: [
						{ item: Item.DARKSTEEL_NUGGET, qty: 2 },
						{ item: Item.FIRE_SHARD, qty: 11 },
						{ item: Item.EARTH_SHARD, qty: 10 },
					],
				},
			],
		},
		used: [],
	},
	[Item.DARKSTEEL_WIRE]: {
		name: Item.DARKSTEEL_WIRE, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.DARKSTEEL_WIRE, qtyMade: 1, crafter: Job.ARM, level: 50, lodestoneID: "",
					itemsRequired: [
						{ item: Item.DARKSTEEL_NUGGET, qty: 2 },
						{ item: Item.ICE_SHARD, qty: 11 },
						{ item: Item.EARTH_SHARD, qty: 10 },
					],
				},
			],
		},
		used: [],
	},
	[Item.ROSE_GOLD_COG]: {
		name: Item.ROSE_GOLD_COG, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.ROSE_GOLD_COG, qtyMade: 1, crafter: Job.GSM, level: 50, lodestoneID: "",
					itemsRequired: [
						{ item: Item.ROSE_GOLD_NUGGET, qty: 2 },
						{ item: Item.WIND_SHARD, qty: 11 },
						{ item: Item.FIRE_SHARD, qty: 10 },
					],
				},
			],
		},
		used: [],
	},
	[Item.GRYPHONSKIN_STRAP]: {
		name: Item.GRYPHONSKIN_STRAP, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.GRYPHONSKIN_STRAP, qtyMade: 1, crafter: Job.LTW, level: 50, lodestoneID: "",
					itemsRequired: [
						{ item: Item.HIPPOGRYPH_LEATHER, qty: 2 },
						{ item: Item.EARTH_SHARD, qty: 11 },
						{ item: Item.WIND_SHARD, qty: 10 },
					],
				},
			],
		},
		used: [],
	},
	[Item.SILVER_BROCADE]: {
		name: Item.SILVER_BROCADE, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.SILVER_BROCADE, qtyMade: 1, crafter: Job.WVR, level: 50, lodestoneID: "",
					itemsRequired: [
						{ item: Item.SILK_THREAD, qty: 2 },
						{ item: Item.SILVER_INGOT, qty: 1 },
						{ item: Item.LIGHTNING_SHARD, qty: 11 },
						{ item: Item.WIND_SHARD, qty: 10 },
					],
				},
			],
		},
		used: [],
	},
	[Item.SPIRITS_OF_SALT]: {
		name: Item.SPIRITS_OF_SALT, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.SPIRITS_OF_SALT, qtyMade: 1, crafter: Job.ALC, level: 50, lodestoneID: "",
					itemsRequired: [
						{ item: Item.VOLCANIC_ROCK_SALT, qty: 2 },
						{ item: Item.VITRIOL, qty: 1 },
						{ item: Item.WATER_SHARD, qty: 11 },
						{ item: Item.LIGHTNING_SHARD, qty: 10 },
					],
				},
			],
		},
		used: [],
	},
	[Item.SPICY_TOMATO_RELISH]: {
		name: Item.SPICY_TOMATO_RELISH, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.SPICY_TOMATO_RELISH, qtyMade: 1, crafter: Job.CUL, level: 50, lodestoneID: "",
					itemsRequired: [
						{ item: Item.DZEMAEL_TOMATO, qty: 1 },
						{ item: Item.CINNAMON, qty: 1 },
						{ item: Item.TABLE_SALT, qty: 1 },
						{ item: Item.DARK_VINEGAR, qty: 1 },
						{ item: Item.FIRE_SHARD, qty: 11 },
						{ item: Item.WATER_SHARD, qty: 10 },
					],
				},
			],
		},
		used: [],
	},
	[Item.ANTUMBRAL_ROCK]: {
		name: Item.ANTUMBRAL_ROCK, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { gathering: [{ found: { location: MapLocation.WELLWICK_WOOD, x: 27, y: 19 }, method: GatherMethod.QUARRYING, timedNode: true, times: ["2AM"] }] },
		used: [],
	},
	[Item.REDOLENT_LOG]: {
		name: Item.REDOLENT_LOG, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { gathering: [{ found: { location: MapLocation.GREENTEAR, x: 31, y: 19 }, method: GatherMethod.LOGGING, timedNode: true, times: ["6AM"] }] },
		used: [],
	},
	[Item.OLGOI_KHORKHOI]: {
		name: Item.OLGOI_KHORKHOI, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { fishingHoles: [{ fish: Fish.OLGOI_KHORKHOI, hole: Fishing.SAGOLII_DUNES, weather: Weather.HEAT_WAVES, mooch: [[{ fish: Fish.STORM_RIDER, bait: [Bait.SAND_LEECH, Bait.SAND_GECKO, Bait.VERSATILE_LURE] }]] }] },
		used: [],
	},
	[Item.HORN_GLUE]: {
		name: Item.HORN_GLUE, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.HORN_GLUE, qtyMade: 1, crafter: Job.ALC, level: 30, lodestoneID: "",
					itemsRequired: [
						{ item: Item.ALDGOAT_HORN, qty: 2 },
						{ item: Item.WATER_SHARD, qty: 3 },
					],
				},
			],
			markets: { [Market.UNIVERSALIS]: -1 },
		},
		used: [],
	},
	[Item.ROSE_GOLD_NUGGET]: {
		name: Item.ROSE_GOLD_NUGGET, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.ROSE_GOLD_NUGGET, qtyMade: 1, crafter: Job.GSM, level: 50, lodestoneID: "",
					itemsRequired: [
						{ item: Item.GOLD_ORE, qty: 3 },
						{ item: Item.COPPER_ORE, qty: 1 },
						{ item: Item.WIND_CRYSTAL, qty: 3 },
					],
				},
			],
			markets: { [Market.UNIVERSALIS]: -1 },
		},
		used: [],
	},
	[Item.HIPPOGRYPH_LEATHER]: {
		name: Item.HIPPOGRYPH_LEATHER, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.HIPPOGRYPH_LEATHER, qtyMade: 1, crafter: Job.LTW, level: 50, lodestoneID: "",
					itemsRequired: [
						{ item: Item.HIPPOGRYPH_SKIN, qty: 1 },
						{ item: Item.BLACK_ALUMEN, qty: 1 },
						{ item: Item.EARTH_CRYSTAL, qty: 3 },
					],
				},
			],
			markets: { [Market.UNIVERSALIS]: -1 },
		},
		used: [],
	},
	[Item.SILK_THREAD]: {
		name: Item.SILK_THREAD, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.SILK_THREAD, qtyMade: 2, crafter: Job.WVR, level: 50, lodestoneID: "",
					itemsRequired: [
						{ item: Item.SILKWORM_COCOON, qty: 2 },
						{ item: Item.EFFERVESCENT_WATER, qty: 1 },
						{ item: Item.LIGHTNING_CRYSTAL, qty: 3 },
					],
				},
			],
			markets: { [Market.UNIVERSALIS]: -1 },
		},
		used: [],
	},
	[Item.SILVER_INGOT]: {
		name: Item.SILVER_INGOT, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.SILVER_INGOT, qtyMade: 1, crafter: Job.GSM, level: 23, lodestoneID: "",
					itemsRequired: [
						{ item: Item.SILVER_ORE, qty: 3 },
						{ item: Item.WIND_SHARD, qty: 2 },
					],
				},
			],
			markets: { [Market.UNIVERSALIS]: -1 },
		},
		used: [],
	},
	[Item.VOLCANIC_ROCK_SALT]: {
		name: Item.VOLCANIC_ROCK_SALT, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			gathering: [{ found: { location: MapLocation.RAINCATCHER_GULLY, x: 21, y: 33 }, method: GatherMethod.QUARRYING, timedNode: true, times: ["5PM"] }],
			markets: { [Market.UNIVERSALIS]: -1 },
		},
		used: [],
	},
	[Item.DZEMAEL_TOMATO]: {
		name: Item.DZEMAEL_TOMATO, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			gathering: [{ found: { location: MapLocation.RAINCATCHER_GULLY, x: 16, y: 28 }, method: GatherMethod.HARVESTING, timedNode: true, times: ["1PM"] }],
			markets: { [Market.UNIVERSALIS]: -1 },
		},
		used: [],
	},
	[Item.CINNAMON]: {
		name: Item.CINNAMON, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			shops: [{ shop: Shop.ENGERRAND, cost: [{ price: 4, currency: Currency.GIL }] }, { shop: Shop.FRIDURIH, cost: [{ price: 4, currency: Currency.GIL }] }, { shop: Shop.JOSSY, cost: [{ price: 4, currency: Currency.GIL }] }, { shop: Shop.MATERIAL_SUPPLIER, cost: [{ price: 4, currency: Currency.GIL }] }, { shop: Shop.ORHOYOD, cost: [{ price: 4, currency: Currency.GIL }] }, { shop: Shop.PASDEVILLET, cost: [{ price: 4, currency: Currency.GIL }] }, { shop: Shop.SAHAGIN_VENDOR, cost: [{ price: 4, currency: Currency.GIL }] }, { shop: Shop.SHIRAKASHI, cost: [{ price: 4, currency: Currency.GIL }] }, { shop: Shop.TRAVELING_MATERIAL_SUPPLIER, cost: [{ price: 4, currency: Currency.GIL }] }, { shop: Shop.VRAL, cost: [{ price: 4, currency: Currency.GIL }] }],
			gathering: [{ found: { location: MapLocation.TREESPEAK, x: 29, y: 26 }, method: GatherMethod.LOGGING }],
			markets: { [Market.UNIVERSALIS]: -1 },
		},
		used: [],
	},
	[Item.DARK_VINEGAR]: {
		name: Item.DARK_VINEGAR, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.DARK_VINEGAR, qtyMade: 3, crafter: Job.CUL, level: 31, lodestoneID: "",
					itemsRequired: [
						{ item: Item.NOBLE_GRAPES, qty: 4 },
						{ item: Item.FIRE_SHARD, qty: 3 },
					],
				},
			],
			markets: { [Market.UNIVERSALIS]: -1 },
		},
		used: [],
	},
	[Item.ALDGOAT_HORN]: {
		name: Item.ALDGOAT_HORN, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			enemies: [Enemy.MOURNING_BILLY, Enemy.MOURNING_NANNY, Enemy.MYOTRAGUS_BILLY, Enemy.MYOTRAGUS_NANNY],
			shops: [{ shop: Shop.KOBOLD_VENDOR, cost: [{ price: 238, currency: Currency.GIL }] }],
		},
		used: [],
	},
	[Item.GOLD_ORE]: {
		name: Item.GOLD_ORE, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			gathering: [{ found: { location: MapLocation.WELLWICK_WOOD, x: 28, y: 21 }, method: GatherMethod.MINING, timedNode: true, times: ["9AM"] }],
			markets: { [Market.UNIVERSALIS]: -1 },
		},
		used: [],
	},
	[Item.COPPER_ORE]: {
		name: Item.COPPER_ORE, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			shops: [{ shop: Shop.AISTAN, cost: [{ price: 2, currency: Currency.GIL }] }, { shop: Shop.ENGERRAND, cost: [{ price: 2, currency: Currency.GIL }] }, { shop: Shop.FRIDURIH, cost: [{ price: 2, currency: Currency.GIL }] }, { shop: Shop.MATERIAL_SUPPLIER, cost: [{ price: 2, currency: Currency.GIL }] }, { shop: Shop.ORHOYOD, cost: [{ price: 2, currency: Currency.GIL }] }, { shop: Shop.PASDEVILLET, cost: [{ price: 2, currency: Currency.GIL }] }, { shop: Shop.SMYDHAEMR, cost: [{ price: 2, currency: Currency.GIL }] }, { shop: Shop.SOEMRWYB, cost: [{ price: 2, currency: Currency.GIL }] }],
			gathering: [{ found: { location: MapLocation.HAMMERLEA, x: 27, y: 26 }, method: GatherMethod.MINING }, { found: { location: MapLocation.SPINELESS_BASIN, x: 19, y: 26 }, method: GatherMethod.MINING }],
			markets: { [Market.UNIVERSALIS]: -1 },
		},
		used: [],
	},
	[Item.HIPPOGRYPH_SKIN]: {
		name: Item.HIPPOGRYPH_SKIN, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			enemies: [Enemy.HIPPOGRYPH],
			markets: { [Market.UNIVERSALIS]: -1 },
		},
		used: [],
	},
	[Item.SILKWORM_COCOON]: {
		name: Item.SILKWORM_COCOON, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			gathering: [{ found: { location: MapLocation.NINE_IVIES, x: 22, y: 26 }, method: GatherMethod.HARVESTING, timedNode: true, times: ["1AM"] }],
			markets: { [Market.UNIVERSALIS]: -1 },
		},
		used: [],
	},
	[Item.NOBLE_GRAPES]: {
		name: Item.NOBLE_GRAPES, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			gathering: [{ found: { location: MapLocation.OAKWOOD, x: 14, y: 25 }, method: GatherMethod.HARVESTING }],
			markets: { [Market.UNIVERSALIS]: -1 },
		},
		used: [],
	},
	[Item.PETRIFIED_ORB_HQ]: {
		name: Item.PETRIFIED_ORB_HQ, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.PETRIFIED_ORB_HQ, qtyMade: 1, crafter: Job.CRP, level: 50, lodestoneID: "",
					itemsRequired: [
						{ item: Item.URUSHI, qty: 3 },
						{ item: Item.PETRIFIED_LOG, qty: 1 },
						{ item: Item.SCARLET_SAP, qty: 1 },
						{ item: Item.WIND_CLUSTER, qty: 3 },
						{ item: Item.ICE_CLUSTER, qty: 2 },
					],
				},
			],
		},
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_LUCIS, RelicPart.TOOL_LUCIS, RelicStep.LUCIS_ITEMS, RelicTask.TASK_1], jobs: [Job.CRP], qtyPerJob: 5 },
		],
	},
	[Item.MUMMERS_DAGGERS_HQ]: {
		name: Item.MUMMERS_DAGGERS_HQ, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.MUMMERS_DAGGERS_HQ, qtyMade: 1, crafter: Job.BSM, level: 50, lodestoneID: "",
					itemsRequired: [
						{ item: Item.COMPOSITE_WHETSTONE, qty: 3 },
						{ item: Item.SCHEELITE, qty: 1 },
						{ item: Item.FERBERITE, qty: 1 },
						{ item: Item.FIRE_CLUSTER, qty: 3 },
						{ item: Item.EARTH_CLUSTER, qty: 2 },
					],
				},
			],
		},
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_LUCIS, RelicPart.TOOL_LUCIS, RelicStep.LUCIS_ITEMS, RelicTask.TASK_1], jobs: [Job.BSM], qtyPerJob: 5 },
		],
	},
	[Item.LAPDOG_COLLAR_BELL_HQ]: {
		name: Item.LAPDOG_COLLAR_BELL_HQ, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.LAPDOG_COLLAR_BELL_HQ, qtyMade: 1, crafter: Job.ARM, level: 50, lodestoneID: "",
					itemsRequired: [
						{ item: Item.HEAT_RESISTANT_PLASTER, qty: 3 },
						{ item: Item.SCHEELITE, qty: 1 },
						{ item: Item.FERBERITE, qty: 1 },
						{ item: Item.ICE_CLUSTER, qty: 3 },
						{ item: Item.EARTH_CLUSTER, qty: 2 },
					],
				},
			],
		},
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_LUCIS, RelicPart.TOOL_LUCIS, RelicStep.LUCIS_ITEMS, RelicTask.TASK_1], jobs: [Job.ARM], qtyPerJob: 5 },
		],
	},
	[Item.FLUORITE_LENS_HQ]: {
		name: Item.FLUORITE_LENS_HQ, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.FLUORITE_LENS_HQ, qtyMade: 1, crafter: Job.GSM, level: 50, lodestoneID: "",
					itemsRequired: [
						{ item: Item.CLEAR_FLUORITE, qty: 3 },
						{ item: Item.EMERY, qty: 1 },
						{ item: Item.BASILISK_WHETSTONE, qty: 1 },
						{ item: Item.SILTSTONE_WHETSTONE, qty: 1 },
						{ item: Item.WIND_CLUSTER, qty: 3 },
						{ item: Item.FIRE_CLUSTER, qty: 2 },
					],
				},
			],
		},
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_LUCIS, RelicPart.TOOL_LUCIS, RelicStep.LUCIS_ITEMS, RelicTask.TASK_1], jobs: [Job.GSM], qtyPerJob: 5 },
		],
	},
	[Item.PATENT_LEATHER_HQ]: {
		name: Item.PATENT_LEATHER_HQ, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.PATENT_LEATHER_HQ, qtyMade: 1, crafter: Job.LTW, level: 50, lodestoneID: "",
					itemsRequired: [
						{ item: Item.LAMPBLACK_LINSEED_OIL, qty: 3 },
						{ item: Item.SAURIAN_SKIN, qty: 1 },
						{ item: Item.SHROUD_TEA_LEAVES, qty: 1 },
						{ item: Item.EARTH_CLUSTER, qty: 3 },
						{ item: Item.WIND_CLUSTER, qty: 2 },
					],
				},
			],
		},
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_LUCIS, RelicPart.TOOL_LUCIS, RelicStep.LUCIS_ITEMS, RelicTask.TASK_1], jobs: [Job.LTW], qtyPerJob: 5 },
		],
	},
	[Item.CAMLET_HQ]: {
		name: Item.CAMLET_HQ, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.CAMLET_HQ, qtyMade: 1, crafter: Job.WVR, level: 50, lodestoneID: "",
					itemsRequired: [
						{ item: Item.CAMEL_HAIR, qty: 3 },
						{ item: Item.CASHMERE_FLEECE, qty: 1 },
						{ item: Item.DILUTED_VITRIOL, qty: 1 },
						{ item: Item.LIGHTNING_CLUSTER, qty: 3 },
						{ item: Item.WIND_CLUSTER, qty: 2 },
					],
				},
			],
		},
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_LUCIS, RelicPart.TOOL_LUCIS, RelicStep.LUCIS_ITEMS, RelicTask.TASK_1], jobs: [Job.WVR], qtyPerJob: 5 },
		],
	},
	[Item.ENCHANTED_QUICKSILVER_HQ]: {
		name: Item.ENCHANTED_QUICKSILVER_HQ, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.ENCHANTED_QUICKSILVER_HQ, qtyMade: 1, crafter: Job.ALC, level: 50, lodestoneID: "",
					itemsRequired: [
						{ item: Item.BLACK_CINNABAR, qty: 3 },
						{ item: Item.RAZIQSAND, qty: 1 },
						{ item: Item.TARANTULA, qty: 2 },
						{ item: Item.WATER_CLUSTER, qty: 3 },
						{ item: Item.LIGHTNING_CLUSTER, qty: 2 },
					],
				},
			],
		},
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_LUCIS, RelicPart.TOOL_LUCIS, RelicStep.LUCIS_ITEMS, RelicTask.TASK_1], jobs: [Job.ALC], qtyPerJob: 5 },
		],
	},
	[Item.DUCK_BROTH_HQ]: {
		name: Item.DUCK_BROTH_HQ, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.DUCK_BROTH_HQ, qtyMade: 1, crafter: Job.CUL, level: 50, lodestoneID: "",
					itemsRequired: [
						{ item: Item.DUCK_BONES, qty: 3 },
						{ item: Item.CANARD_BREAST, qty: 1 },
						{ item: Item.THYME, qty: 1 },
						{ item: Item.WILD_ONION, qty: 4 },
						{ item: Item.GARLEAN_GARLIC, qty: 2 },
						{ item: Item.FIRE_CLUSTER, qty: 3 },
						{ item: Item.WATER_CLUSTER, qty: 2 },
					],
				},
			],
		},
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_LUCIS, RelicPart.TOOL_LUCIS, RelicStep.LUCIS_ITEMS, RelicTask.TASK_1], jobs: [Job.CUL], qtyPerJob: 5 },
		],
	},
	[Item.TALANS_SEAL_OF_MINING_MASTERY]: {
		name: Item.TALANS_SEAL_OF_MINING_MASTERY, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { shops: [{ shop: Shop.TALAN, cost: [{ price: 30, currency: Item.ANTUMBRAL_ROCK }, { price: 15, currency: Item.MAGICKED_OILCLOTH }] }] },
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_LUCIS, RelicPart.TOOL_LUCIS, RelicStep.LUCIS_ITEMS, RelicTask.TASK_1], jobs: [Job.MIN], qtyPerJob: 1 },
		],
	},
	[Item.TALANS_SEAL_OF_BOTANY_MASTERY]: {
		name: Item.TALANS_SEAL_OF_BOTANY_MASTERY, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { shops: [{ shop: Shop.TALAN, cost: [{ price: 30, currency: Item.REDOLENT_LOG }, { price: 15, currency: Item.BAMBOO_PAPER }] }] },
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_LUCIS, RelicPart.TOOL_LUCIS, RelicStep.LUCIS_ITEMS, RelicTask.TASK_1], jobs: [Job.BTN], qtyPerJob: 1 },
		],
	},
	[Item.TALANS_SEAL_OF_FISHING_MASTERY]: {
		name: Item.TALANS_SEAL_OF_FISHING_MASTERY, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { shops: [{ shop: Shop.TALAN, cost: [{ price: 10, currency: Item.GIGANT_CLAM }, { price: 15, currency: Item.GLACIER_CRYSTAL }] }] },
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_LUCIS, RelicPart.TOOL_LUCIS, RelicStep.LUCIS_ITEMS, RelicTask.TASK_1], jobs: [Job.FSH], qtyPerJob: 1 },
		],
	},
	[Item.MOONSTONE]: {
		name: Item.MOONSTONE, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { shops: [{ shop: Shop.FLAME_QUARTERMASTER, cost: [{ price: 4000, currency: Currency.COMPANY_SEAL }] }, { shop: Shop.SERPENT_QUARTERMASTER, cost: [{ price: 4000, currency: Currency.COMPANY_SEAL }] }, { shop: Shop.STORM_QUARTERMASTER, cost: [{ price: 4000, currency: Currency.COMPANY_SEAL }] }] },
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_LUCIS, RelicPart.TOOL_LUCIS, RelicStep.LUCIS_ITEMS, RelicTask.TASK_2], jobs: jobLists.type.utility, qtyPerJob: 5 },
		],
	},
	[Item.URUSHI]: {
		name: Item.URUSHI, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { shops: [{ shop: Shop.FLAME_QUARTERMASTER, cost: [{ price: 600, currency: Currency.COMPANY_SEAL }] }, { shop: Shop.SERPENT_QUARTERMASTER, cost: [{ price: 600, currency: Currency.COMPANY_SEAL }] }, { shop: Shop.STORM_QUARTERMASTER, cost: [{ price: 600, currency: Currency.COMPANY_SEAL }] }, { shop: Shop.HUNT_BILLMASTER_GRIDANIA, cost: [{ price: 600, currency: Currency.ALLIED_SEAL }] }, { shop: Shop.HUNT_BILLMASTER_ULDAH, cost: [{ price: 600, currency: Currency.ALLIED_SEAL }] }, { shop: Shop.HUNT_BILLMASTER_LIMSA, cost: [{ price: 600, currency: Currency.ALLIED_SEAL }] }] },
		used: [],
	},
	[Item.PETRIFIED_LOG]: {
		name: Item.PETRIFIED_LOG, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			shops: [{ shop: Shop.FLAME_QUARTERMASTER, cost: [{ price: 1500, currency: Currency.COMPANY_SEAL }] }, { shop: Shop.SERPENT_QUARTERMASTER, cost: [{ price: 1500, currency: Currency.COMPANY_SEAL }] }, { shop: Shop.STORM_QUARTERMASTER, cost: [{ price: 1500, currency: Currency.COMPANY_SEAL }] }, { shop: Shop.IXALI_VENDOR, cost: [{ price: 7, currency: Currency.IXALI_OAKNOT }] }],
			markets: { [Market.UNIVERSALIS]: -1 },
		},
		used: [],
	},
	[Item.SCARLET_SAP]: {
		name: Item.SCARLET_SAP, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { gathering: [{ found: { location: MapLocation.ALDER_SPRINGS, x: 16, y: 28 }, method: GatherMethod.LOGGING, timedNode: true, times: ["3AM"] }] },
		used: [],
	},
	[Item.COMPOSITE_WHETSTONE]: {
		name: Item.COMPOSITE_WHETSTONE, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { shops: [{ shop: Shop.FLAME_QUARTERMASTER, cost: [{ price: 600, currency: Currency.COMPANY_SEAL }] }, { shop: Shop.SERPENT_QUARTERMASTER, cost: [{ price: 600, currency: Currency.COMPANY_SEAL }] }, { shop: Shop.STORM_QUARTERMASTER, cost: [{ price: 600, currency: Currency.COMPANY_SEAL }] }, { shop: Shop.HUNT_BILLMASTER_GRIDANIA, cost: [{ price: 50, currency: Currency.ALLIED_SEAL }] }, { shop: Shop.HUNT_BILLMASTER_LIMSA, cost: [{ price: 50, currency: Currency.ALLIED_SEAL }] }, { shop: Shop.HUNT_BILLMASTER_ULDAH, cost: [{ price: 50, currency: Currency.ALLIED_SEAL }] }] },
		used: [],
	},
	[Item.SCHEELITE]: {
		name: Item.SCHEELITE, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			shops: [{ shop: Shop.FLAME_QUARTERMASTER, cost: [{ price: 1500, currency: Currency.COMPANY_SEAL }] }, { shop: Shop.SERPENT_QUARTERMASTER, cost: [{ price: 1500, currency: Currency.COMPANY_SEAL }] }, { shop: Shop.STORM_QUARTERMASTER, cost: [{ price: 1500, currency: Currency.COMPANY_SEAL }] }, { shop: Shop.IXALI_VENDOR, cost: [{ price: 7, currency: Currency.IXALI_OAKNOT }] }],
			markets: { [Market.UNIVERSALIS]: -1 },
		},
		used: [],
	},
	[Item.FERBERITE]: {
		name: Item.FERBERITE, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { gathering: [{ found: { location: MapLocation.RAUBAHNS_PUSH, x: 16, y: 20 }, method: GatherMethod.MINING, timedNode: true, times: ["3AM"] }] },
		used: [],
	},
	[Item.HEAT_RESISTANT_PLASTER]: {
		name: Item.HEAT_RESISTANT_PLASTER, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { shops: [{ shop: Shop.FLAME_QUARTERMASTER, cost: [{ price: 600, currency: Currency.COMPANY_SEAL }] }, { shop: Shop.SERPENT_QUARTERMASTER, cost: [{ price: 600, currency: Currency.COMPANY_SEAL }] }, { shop: Shop.STORM_QUARTERMASTER, cost: [{ price: 600, currency: Currency.COMPANY_SEAL }] }, { shop: Shop.HUNT_BILLMASTER_GRIDANIA, cost: [{ price: 50, currency: Currency.ALLIED_SEAL }] }, { shop: Shop.HUNT_BILLMASTER_LIMSA, cost: [{ price: 50, currency: Currency.ALLIED_SEAL }] }, { shop: Shop.HUNT_BILLMASTER_ULDAH, cost: [{ price: 50, currency: Currency.ALLIED_SEAL }] }] },
		used: [],
	},
	[Item.CLEAR_FLUORITE]: {
		name: Item.CLEAR_FLUORITE, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { shops: [{ shop: Shop.FLAME_QUARTERMASTER, cost: [{ price: 600, currency: Currency.COMPANY_SEAL }] }, { shop: Shop.SERPENT_QUARTERMASTER, cost: [{ price: 600, currency: Currency.COMPANY_SEAL }] }, { shop: Shop.STORM_QUARTERMASTER, cost: [{ price: 600, currency: Currency.COMPANY_SEAL }] }, { shop: Shop.HUNT_BILLMASTER_GRIDANIA, cost: [{ price: 50, currency: Currency.ALLIED_SEAL }] }, { shop: Shop.HUNT_BILLMASTER_LIMSA, cost: [{ price: 50, currency: Currency.ALLIED_SEAL }] }, { shop: Shop.HUNT_BILLMASTER_ULDAH, cost: [{ price: 50, currency: Currency.ALLIED_SEAL }] }] },
		used: [],
	},
	[Item.EMERY]: {
		name: Item.EMERY, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			shops: [{ shop: Shop.FLAME_QUARTERMASTER, cost: [{ price: 1500, currency: Currency.COMPANY_SEAL }] }, { shop: Shop.SERPENT_QUARTERMASTER, cost: [{ price: 1500, currency: Currency.COMPANY_SEAL }] }, { shop: Shop.STORM_QUARTERMASTER, cost: [{ price: 1500, currency: Currency.COMPANY_SEAL }] }, { shop: Shop.IXALI_VENDOR, cost: [{ price: 7, currency: Currency.IXALI_OAKNOT }] }],
			markets: { [Market.UNIVERSALIS]: -1 },
		},
		used: [],
	},
	[Item.SILTSTONE_WHETSTONE]: {
		name: Item.SILTSTONE_WHETSTONE, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.SILTSTONE_WHETSTONE, qtyMade: 1, crafter: Job.GSM, level: 30, lodestoneID: "",
					itemsRequired: [
						{ item: Item.SILTSTONE, qty: 2 },
						{ item: Item.WIND_SHARD, qty: 3 },
					],
				},
			],
			markets: { [Market.UNIVERSALIS]: -1 },
		},
		used: [],
	},
	[Item.LAMPBLACK_LINSEED_OIL]: {
		name: Item.LAMPBLACK_LINSEED_OIL, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { shops: [{ shop: Shop.FLAME_QUARTERMASTER, cost: [{ price: 600, currency: Currency.COMPANY_SEAL }] }, { shop: Shop.SERPENT_QUARTERMASTER, cost: [{ price: 600, currency: Currency.COMPANY_SEAL }] }, { shop: Shop.STORM_QUARTERMASTER, cost: [{ price: 600, currency: Currency.COMPANY_SEAL }] }, { shop: Shop.HUNT_BILLMASTER_GRIDANIA, cost: [{ price: 50, currency: Currency.ALLIED_SEAL }] }, { shop: Shop.HUNT_BILLMASTER_LIMSA, cost: [{ price: 50, currency: Currency.ALLIED_SEAL }] }, { shop: Shop.HUNT_BILLMASTER_ULDAH, cost: [{ price: 50, currency: Currency.ALLIED_SEAL }] }] },
		used: [],
	},
	[Item.SAURIAN_SKIN]: {
		name: Item.SAURIAN_SKIN, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			shops: [{ shop: Shop.FLAME_QUARTERMASTER, cost: [{ price: 1500, currency: Currency.COMPANY_SEAL }] }, { shop: Shop.SERPENT_QUARTERMASTER, cost: [{ price: 1500, currency: Currency.COMPANY_SEAL }] }, { shop: Shop.STORM_QUARTERMASTER, cost: [{ price: 1500, currency: Currency.COMPANY_SEAL }] }, { shop: Shop.IXALI_VENDOR, cost: [{ price: 7, currency: Currency.IXALI_OAKNOT }] }],
			markets: { [Market.UNIVERSALIS]: -1 },
		},
		used: [],
	},
	[Item.SHROUD_TEA_LEAVES]: {
		name: Item.SHROUD_TEA_LEAVES, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { gathering: [{ found: { location: MapLocation.SORREL_HAVEN, x: 16, y: 20 }, method: GatherMethod.HARVESTING, timedNode: true, times: ["2AM"] }] },
		used: [],
	},
	[Item.CAMEL_HAIR]: {
		name: Item.CAMEL_HAIR, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { shops: [{ shop: Shop.FLAME_QUARTERMASTER, cost: [{ price: 600, currency: Currency.COMPANY_SEAL }] }, { shop: Shop.SERPENT_QUARTERMASTER, cost: [{ price: 600, currency: Currency.COMPANY_SEAL }] }, { shop: Shop.STORM_QUARTERMASTER, cost: [{ price: 600, currency: Currency.COMPANY_SEAL }] }, { shop: Shop.HUNT_BILLMASTER_GRIDANIA, cost: [{ price: 50, currency: Currency.ALLIED_SEAL }] }, { shop: Shop.HUNT_BILLMASTER_LIMSA, cost: [{ price: 50, currency: Currency.ALLIED_SEAL }] }, { shop: Shop.HUNT_BILLMASTER_ULDAH, cost: [{ price: 50, currency: Currency.ALLIED_SEAL }] }] },
		used: [],
	},
	[Item.CASHMERE_FLEECE]: {
		name: Item.CASHMERE_FLEECE, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			shops: [{ shop: Shop.FLAME_QUARTERMASTER, cost: [{ price: 1500, currency: Currency.COMPANY_SEAL }] }, { shop: Shop.SERPENT_QUARTERMASTER, cost: [{ price: 1500, currency: Currency.COMPANY_SEAL }] }, { shop: Shop.STORM_QUARTERMASTER, cost: [{ price: 1500, currency: Currency.COMPANY_SEAL }] }, { shop: Shop.IXALI_VENDOR, cost: [{ price: 7, currency: Currency.IXALI_OAKNOT }] }],
			markets: { [Market.UNIVERSALIS]: -1 },
		},
		used: [],
	},
	[Item.DILUTED_VITRIOL]: {
		name: Item.DILUTED_VITRIOL, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.DILUTED_VITRIOL, qtyMade: 9, crafter: Job.ALC, level: 50, lodestoneID: "",
					itemsRequired: [
						{ item: Item.FILTERED_WATER, qty: 9 },
						{ item: Item.VITRIOL, qty: 6 },
						{ item: Item.WATER_CLUSTER, qty: 1 },
					],
				},
			],
			markets: { [Market.UNIVERSALIS]: -1 },
		},
		used: [],
	},
	[Item.BLACK_CINNABAR]: {
		name: Item.BLACK_CINNABAR, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { shops: [{ shop: Shop.FLAME_QUARTERMASTER, cost: [{ price: 600, currency: Currency.COMPANY_SEAL }] }, { shop: Shop.SERPENT_QUARTERMASTER, cost: [{ price: 600, currency: Currency.COMPANY_SEAL }] }, { shop: Shop.STORM_QUARTERMASTER, cost: [{ price: 600, currency: Currency.COMPANY_SEAL }] }, { shop: Shop.HUNT_BILLMASTER_GRIDANIA, cost: [{ price: 50, currency: Currency.ALLIED_SEAL }] }, { shop: Shop.HUNT_BILLMASTER_LIMSA, cost: [{ price: 50, currency: Currency.ALLIED_SEAL }] }, { shop: Shop.HUNT_BILLMASTER_ULDAH, cost: [{ price: 50, currency: Currency.ALLIED_SEAL }] }] },
		used: [],
	},
	[Item.RAZIQSAND]: {
		name: Item.RAZIQSAND, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			shops: [{ shop: Shop.FLAME_QUARTERMASTER, cost: [{ price: 1500, currency: Currency.COMPANY_SEAL }] }, { shop: Shop.SERPENT_QUARTERMASTER, cost: [{ price: 1500, currency: Currency.COMPANY_SEAL }] }, { shop: Shop.STORM_QUARTERMASTER, cost: [{ price: 1500, currency: Currency.COMPANY_SEAL }] }, { shop: Shop.IXALI_VENDOR, cost: [{ price: 7, currency: Currency.IXALI_OAKNOT }] }],
			markets: { [Market.UNIVERSALIS]: -1 },
		},
		used: [],
	},
	[Item.TARANTULA]: {
		name: Item.TARANTULA, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { gathering: [{ found: { location: MapLocation.LOWER_PATHS, x: 17, y: 30 }, method: GatherMethod.LOGGING }] },
		used: [],
	},
	[Item.DUCK_BONES]: {
		name: Item.DUCK_BONES, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { shops: [{ shop: Shop.FLAME_QUARTERMASTER, cost: [{ price: 600, currency: Currency.COMPANY_SEAL }] }, { shop: Shop.SERPENT_QUARTERMASTER, cost: [{ price: 600, currency: Currency.COMPANY_SEAL }] }, { shop: Shop.STORM_QUARTERMASTER, cost: [{ price: 600, currency: Currency.COMPANY_SEAL }] }, { shop: Shop.HUNT_BILLMASTER_GRIDANIA, cost: [{ price: 50, currency: Currency.ALLIED_SEAL }] }, { shop: Shop.HUNT_BILLMASTER_LIMSA, cost: [{ price: 50, currency: Currency.ALLIED_SEAL }] }, { shop: Shop.HUNT_BILLMASTER_ULDAH, cost: [{ price: 50, currency: Currency.ALLIED_SEAL }] }] },
		used: [],
	},
	[Item.CANARD_BREAST]: {
		name: Item.CANARD_BREAST, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			shops: [{ shop: Shop.FLAME_QUARTERMASTER, cost: [{ price: 30, currency: Currency.COMPANY_SEAL }] }, { shop: Shop.SERPENT_QUARTERMASTER, cost: [{ price: 30, currency: Currency.COMPANY_SEAL }] }, { shop: Shop.STORM_QUARTERMASTER, cost: [{ price: 30, currency: Currency.COMPANY_SEAL }] }, { shop: Shop.IXALI_VENDOR, cost: [{ price: 1, currency: Currency.IXALI_OAKNOT }] }],
			markets: { [Market.UNIVERSALIS]: -1 },
		},
		used: [],
	},
	[Item.THYME]: {
		name: Item.THYME, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			gathering: [{ found: { location: MapLocation.LOWER_PATHS, x: 21, y: 29 }, method: GatherMethod.HARVESTING }],
			markets: { [Market.UNIVERSALIS]: -1 },
		},
		used: [],
	},
	[Item.WILD_ONION]: {
		name: Item.WILD_ONION, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			shops: [{ shop: Shop.JOSSY, cost: [{ price: 7, currency: Currency.GIL }] }, { shop: Shop.MATERIAL_SUPPLIER, cost: [{ price: 7, currency: Currency.GIL }] }],
			gathering: [{ found: { location: MapLocation.NOPHICAS_WELLS, x: 24, y: 23 }, method: GatherMethod.HARVESTING }],
			markets: { [Market.UNIVERSALIS]: -1 },
		},
		used: [],
	},
	[Item.GARLEAN_GARLIC]: {
		name: Item.GARLEAN_GARLIC, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			shops: [{ shop: Shop.JOSSY, cost: [{ price: 5, currency: Currency.GIL }] }, { shop: Shop.MATERIAL_SUPPLIER, cost: [{ price: 5, currency: Currency.GIL }] }, { shop: Shop.SAHAGIN_VENDOR, cost: [{ price: 5, currency: Currency.GIL }] }],
			gathering: [{ found: { location: MapLocation.NOPHICAS_WELLS, x: 24, y: 23 }, method: GatherMethod.HARVESTING }],
			markets: { [Market.UNIVERSALIS]: -1 },
		},
		used: [],
	},
	[Item.MAGICKED_OILCLOTH]: {
		name: Item.MAGICKED_OILCLOTH, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { shops: [{ shop: Shop.FLAME_QUARTERMASTER, cost: [{ price: 600, currency: Currency.COMPANY_SEAL }] }, { shop: Shop.SERPENT_QUARTERMASTER, cost: [{ price: 600, currency: Currency.COMPANY_SEAL }] }, { shop: Shop.STORM_QUARTERMASTER, cost: [{ price: 600, currency: Currency.COMPANY_SEAL }] }, { shop: Shop.HUNT_BILLMASTER_GRIDANIA, cost: [{ price: 50, currency: Currency.ALLIED_SEAL }] }, { shop: Shop.HUNT_BILLMASTER_LIMSA, cost: [{ price: 50, currency: Currency.ALLIED_SEAL }] }, { shop: Shop.HUNT_BILLMASTER_ULDAH, cost: [{ price: 50, currency: Currency.ALLIED_SEAL }] }] },
		used: [],
	},
	[Item.BAMBOO_PAPER]: {
		name: Item.BAMBOO_PAPER, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { shops: [{ shop: Shop.FLAME_QUARTERMASTER, cost: [{ price: 600, currency: Currency.COMPANY_SEAL }] }, { shop: Shop.SERPENT_QUARTERMASTER, cost: [{ price: 600, currency: Currency.COMPANY_SEAL }] }, { shop: Shop.STORM_QUARTERMASTER, cost: [{ price: 600, currency: Currency.COMPANY_SEAL }] }, { shop: Shop.HUNT_BILLMASTER_GRIDANIA, cost: [{ price: 50, currency: Currency.ALLIED_SEAL }] }, { shop: Shop.HUNT_BILLMASTER_LIMSA, cost: [{ price: 50, currency: Currency.ALLIED_SEAL }] }, { shop: Shop.HUNT_BILLMASTER_ULDAH, cost: [{ price: 50, currency: Currency.ALLIED_SEAL }] }] },
		used: [],
	},
	[Item.GIGANT_CLAM]: {
		name: Item.GIGANT_CLAM, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { fishingHoles: [{ fish: Fish.GIGANT_CLAM, hole: Fishing.SOUTH_BLOODSHORE, bait: [Bait.HEAVY_STEEL_JIG], time: "11PM-2AM" }] },
		used: [],
	},
	[Item.GLACIER_CRYSTAL]: {
		name: Item.GLACIER_CRYSTAL, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { shops: [{ shop: Shop.FLAME_QUARTERMASTER, cost: [{ price: 600, currency: Currency.COMPANY_SEAL }] }, { shop: Shop.SERPENT_QUARTERMASTER, cost: [{ price: 600, currency: Currency.COMPANY_SEAL }] }, { shop: Shop.STORM_QUARTERMASTER, cost: [{ price: 600, currency: Currency.COMPANY_SEAL }] }, { shop: Shop.HUNT_BILLMASTER_GRIDANIA, cost: [{ price: 50, currency: Currency.ALLIED_SEAL }] }, { shop: Shop.HUNT_BILLMASTER_LIMSA, cost: [{ price: 50, currency: Currency.ALLIED_SEAL }] }, { shop: Shop.HUNT_BILLMASTER_ULDAH, cost: [{ price: 50, currency: Currency.ALLIED_SEAL }] }] },
		used: [],
	},
	[Item.SILTSTONE]: {
		name: Item.SILTSTONE, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { gathering: [{ found: { location: MapLocation.OAKWOOD, x: 13, y: 26 }, method: GatherMethod.QUARRYING }] },
		used: [],
	},
	[Item.FILTERED_WATER]: {
		name: Item.FILTERED_WATER, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			shops: [{ shop: Shop.FLAME_QUARTERMASTER, cost: [{ price: 20, currency: Currency.COMPANY_SEAL }] }, { shop: Shop.SERPENT_QUARTERMASTER, cost: [{ price: 20, currency: Currency.COMPANY_SEAL }] }, { shop: Shop.STORM_QUARTERMASTER, cost: [{ price: 20, currency: Currency.COMPANY_SEAL }] }, { shop: Shop.IXALI_VENDOR, cost: [{ price: 1, currency: Currency.IXALI_OAKNOT }] }],
			markets: { [Market.UNIVERSALIS]: -1 },
		},
		used: [],
	},
	[Item.AMPLIFYING_ACHONDRITE]: {
		name: Item.AMPLIFYING_ACHONDRITE, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { shops: [{ shop: Shop.JUBRUNNAH, cost: [{ price: 500, currency: Currency.POETICS }] }] },
		used: [
			{ relic: [RelicType.WEAPON, RelicGroup.WEAPON_MANDERVILLE, RelicPart.WEAPON_MAJESTIC_MANDERVILLE, RelicStep.MAJESTIC_MANDERVILLE, RelicTask.TASK_1], jobs: jobLists.relic[RelicGroup.WEAPON_MANDERVILLE], qtyPerJob: 3 },
		],
	},
	[Item.CONNOISSEURS_PICTURE_FRAME]: {
		name: Item.CONNOISSEURS_PICTURE_FRAME, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.CONNOISSEURS_PICTURE_FRAME, qtyMade: 1, crafter: Job.CRP, level: 90, lodestoneID: "",
					itemsRequired: [
						{ item: Item.SELECT_DARK_CHESTNUT_LUMBER, qty: 1 },
						{ item: Item.RED_PINE_LUMBER, qty: 1 },
						{ item: Item.LINEN_CANVAS, qty: 1 },
						{ item: Item.WIND_CLUSTER, qty: 4 },
						{ item: Item.ICE_CLUSTER, qty: 2 },
					],
				},
			],
		},
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SPLENDOROUS, RelicPart.TOOL_CHORA_ZOIS_CRYSTALLINE, RelicStep.CUSTOMISED_COMPONENT, RelicTask.TASK_1], jobs: [Job.CRP], qtyPerJob: 30, maxQtyPerJob: 90 },
		],
	},
	[Item.CONNOISSEURS_SAMURAI_BLADE]: {
		name: Item.CONNOISSEURS_SAMURAI_BLADE, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.CONNOISSEURS_SAMURAI_BLADE, qtyMade: 1, crafter: Job.BSM, level: 90, lodestoneID: "",
					itemsRequired: [
						{ item: Item.SELECT_BISMUTH_INGOT, qty: 1 },
						{ item: Item.PEWTER_INGOT, qty: 1 },
						{ item: Item.OROSHIGANE_INGOT, qty: 1 },
						{ item: Item.FIRE_CLUSTER, qty: 4 },
						{ item: Item.EARTH_CLUSTER, qty: 2 },
					],
				},
			],
		},
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SPLENDOROUS, RelicPart.TOOL_CHORA_ZOIS_CRYSTALLINE, RelicStep.CUSTOMISED_COMPONENT, RelicTask.TASK_1], jobs: [Job.BSM], qtyPerJob: 30, maxQtyPerJob: 90 },
		],
	},
	[Item.CONNOISSEURS_AQUARIUM]: {
		name: Item.CONNOISSEURS_AQUARIUM, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.CONNOISSEURS_AQUARIUM, qtyMade: 1, crafter: Job.ARM, level: 90, lodestoneID: "",
					itemsRequired: [
						{ item: Item.SELECT_COBALT_PLATE, qty: 1 },
						{ item: Item.MANGANESE_INGOT, qty: 1 },
						{ item: Item.CRYSTAL_GLASS, qty: 1 },
						{ item: Item.ICE_CLUSTER, qty: 4 },
						{ item: Item.EARTH_CLUSTER, qty: 2 },
					],
				},
			],
		},
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SPLENDOROUS, RelicPart.TOOL_CHORA_ZOIS_CRYSTALLINE, RelicStep.CUSTOMISED_COMPONENT, RelicTask.TASK_1], jobs: [Job.ARM], qtyPerJob: 30, maxQtyPerJob: 90 },
		],
	},
	[Item.CONNOISSEURS_GLAIVES]: {
		name: Item.CONNOISSEURS_GLAIVES, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.CONNOISSEURS_GLAIVES, qtyMade: 1, crafter: Job.GSM, level: 90, lodestoneID: "",
					itemsRequired: [
						{ item: Item.SELECT_BLUESPIRIT_TILE, qty: 1 },
						{ item: Item.PEWTER_INGOT, qty: 1 },
						{ item: Item.MANASILVER_NUGGET, qty: 1 },
						{ item: Item.WIND_CLUSTER, qty: 4 },
						{ item: Item.FIRE_CLUSTER, qty: 2 },
					],
				},
			],
		},
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SPLENDOROUS, RelicPart.TOOL_CHORA_ZOIS_CRYSTALLINE, RelicStep.CUSTOMISED_COMPONENT, RelicTask.TASK_1], jobs: [Job.GSM], qtyPerJob: 30, maxQtyPerJob: 90 },
		],
	},
	[Item.CONNOISSEURS_VARSITY_SHOES]: {
		name: Item.CONNOISSEURS_VARSITY_SHOES, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.CONNOISSEURS_VARSITY_SHOES, qtyMade: 1, crafter: Job.LTW, level: 90, lodestoneID: "",
					itemsRequired: [
						{ item: Item.SELECT_GREEN_GLIDER_LEATHER, qty: 1 },
						{ item: Item.KUMBHIRA_LEATHER, qty: 1 },
						{ item: Item.IRIDESCENT_SILK, qty: 1 },
						{ item: Item.EARTH_CLUSTER, qty: 4 },
						{ item: Item.WIND_CLUSTER, qty: 2 },
					],
				},
			],
		},
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SPLENDOROUS, RelicPart.TOOL_CHORA_ZOIS_CRYSTALLINE, RelicStep.CUSTOMISED_COMPONENT, RelicTask.TASK_1], jobs: [Job.LTW], qtyPerJob: 30, maxQtyPerJob: 90 },
		],
	},
	[Item.CONNOISSEURS_LINEN_PARASOL]: {
		name: Item.CONNOISSEURS_LINEN_PARASOL, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.CONNOISSEURS_LINEN_PARASOL, qtyMade: 1, crafter: Job.WVR, level: 90, lodestoneID: "",
					itemsRequired: [
						{ item: Item.SELECT_WATERPROOF_CLOTH, qty: 1 },
						{ item: Item.MANGANESE_INGOT, qty: 1 },
						{ item: Item.LINEN_CANVAS, qty: 1 },
						{ item: Item.LIGHTNING_CLUSTER, qty: 4 },
						{ item: Item.WIND_CLUSTER, qty: 2 },
					],
				},
			],
		},
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SPLENDOROUS, RelicPart.TOOL_CHORA_ZOIS_CRYSTALLINE, RelicStep.CUSTOMISED_COMPONENT, RelicTask.TASK_1], jobs: [Job.WVR], qtyPerJob: 30, maxQtyPerJob: 90 },
		],
	},
	[Item.CONNOISSEURS_TOPIARY_MOOGLE]: {
		name: Item.CONNOISSEURS_TOPIARY_MOOGLE, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.CONNOISSEURS_TOPIARY_MOOGLE, qtyMade: 1, crafter: Job.ALC, level: 90, lodestoneID: "",
					itemsRequired: [
						{ item: Item.SELECT_RAKTIKA_SEEDLING, qty: 1 },
						{ item: Item.GRADE_5_INTELLIGENCE_ALKAHEST, qty: 3 },
						{ item: Item.GROWTH_FORMULA_KAPPA, qty: 1 },
						{ item: Item.WATER_CLUSTER, qty: 4 },
						{ item: Item.LIGHTNING_CLUSTER, qty: 2 },
					],
				},
			],
		},
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SPLENDOROUS, RelicPart.TOOL_CHORA_ZOIS_CRYSTALLINE, RelicStep.CUSTOMISED_COMPONENT, RelicTask.TASK_1], jobs: [Job.ALC], qtyPerJob: 30, maxQtyPerJob: 90 },
		],
	},
	[Item.CONNOISSEURS_SQUID_BAGUETTE]: {
		name: Item.CONNOISSEURS_SQUID_BAGUETTE, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.CONNOISSEURS_SQUID_BAGUETTE, qtyMade: 1, crafter: Job.CUL, level: 90, lodestoneID: "",
					itemsRequired: [
						{ item: Item.SELECT_SQUID_INK, qty: 1 },
						{ item: Item.NORTHERN_SEA_SALT, qty: 3 },
						{ item: Item.UPLAND_WHEAT_FLOUR, qty: 3 },
						{ item: Item.FIRE_CLUSTER, qty: 4 },
						{ item: Item.WATER_CLUSTER, qty: 2 },
					],
				},
			],
		},
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SPLENDOROUS, RelicPart.TOOL_CHORA_ZOIS_CRYSTALLINE, RelicStep.CUSTOMISED_COMPONENT, RelicTask.TASK_1], jobs: [Job.CUL], qtyPerJob: 30, maxQtyPerJob: 90 },
		],
	},
	[Item.CONNOISSEURS_SOILED_FEMUR]: {
		name: Item.CONNOISSEURS_SOILED_FEMUR, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { gathering: [{ found: { location: MapLocation.WEATHERING, x: 17, y: 15 }, method: GatherMethod.MINING, collectable: true }] },
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SPLENDOROUS, RelicPart.TOOL_CHORA_ZOIS_CRYSTALLINE, RelicStep.CUSTOMISED_COMPONENT, RelicTask.TASK_1], jobs: [Job.MIN], qtyPerJob: 70, maxQtyPerJob: 210 },
		],
	},
	[Item.CONNOISSEURS_MIRACLE_APPLE]: {
		name: Item.CONNOISSEURS_MIRACLE_APPLE, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { gathering: [{ found: { location: MapLocation.MITHAI_GLORIANDA, x: 26, y: 19 }, method: GatherMethod.LOGGING, collectable: true }] },
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SPLENDOROUS, RelicPart.TOOL_CHORA_ZOIS_CRYSTALLINE, RelicStep.CUSTOMISED_COMPONENT, RelicTask.TASK_1], jobs: [Job.BTN], qtyPerJob: 70, maxQtyPerJob: 210 },
		],
	},
	[Item.GOLD_DUSTFISH]: {
		name: Item.GOLD_DUSTFISH, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { fishingHoles: [{ fish: Fish.GOLD_DUSTFISH, hole: Fishing.NABAATH_SEVERANCE, bait: [Bait.SELECT_BAIT_BALL] }] },
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SPLENDOROUS, RelicPart.TOOL_CHORA_ZOIS_CRYSTALLINE, RelicStep.CUSTOMISED_COMPONENT, RelicTask.TASK_1], jobs: [Job.FSH], qtyPerJob: 40, maxQtyPerJob: 80 },
		],
	},
	[Item.FORGIVEN_MELANCHOLY]: {
		name: Item.FORGIVEN_MELANCHOLY, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { fishingHoles: [{ fish: Fish.FORGIVEN_MELANCHOLY, hole: Fishing.ISLE_OF_KEN, bait: [Bait.SELECT_BAIT_BALL] }] },
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SPLENDOROUS, RelicPart.TOOL_CHORA_ZOIS_CRYSTALLINE, RelicStep.CUSTOMISED_COMPONENT, RelicTask.TASK_2], jobs: [Job.FSH], qtyPerJob: 40, maxQtyPerJob: 80 },
		],
	},
	[Item.CONNOISSEUR_BOOKSHELF]: {
		name: Item.CONNOISSEUR_BOOKSHELF, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.CONNOISSEUR_BOOKSHELF, qtyMade: 1, crafter: Job.CRP, level: 90, lodestoneID: "",
					itemsRequired: [
						{ item: Item.SELECT_DARK_CHESTNUT_LUMBER, qty: 1 },
						{ item: Item.INTEGRAL_LUMBER, qty: 1 },
						{ item: Item.IRONWOOD_LUMBER, qty: 1 },
						{ item: Item.MANGANESE_INGOT, qty: 1 },
						{ item: Item.WIND_CLUSTER, qty: 4 },
						{ item: Item.ICE_CLUSTER, qty: 2 },
					],
				},
			],
		},
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SPLENDOROUS, RelicPart.TOOL_BRILLIANT, RelicStep.BRILLIANT_COMPONENT, RelicTask.TASK_1], jobs: [Job.CRP], qtyPerJob: 30, maxQtyPerJob: 90 },
		],
	},
	[Item.CONNOISSEUR_CHANDELIER]: {
		name: Item.CONNOISSEUR_CHANDELIER, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.CONNOISSEUR_CHANDELIER, qtyMade: 1, crafter: Job.BSM, level: 90, lodestoneID: "",
					itemsRequired: [
						{ item: Item.SELECT_BISMUTH_INGOT, qty: 1 },
						{ item: Item.CHONDRITE_INGOT, qty: 1 },
						{ item: Item.MANGANESE_INGOT, qty: 1 },
						{ item: Item.PHRYGIAN_GOLD_INGOT, qty: 1 },
						{ item: Item.FIRE_CLUSTER, qty: 4 },
						{ item: Item.EARTH_CLUSTER, qty: 2 },
					],
				},
			],
		},
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SPLENDOROUS, RelicPart.TOOL_BRILLIANT, RelicStep.BRILLIANT_COMPONENT, RelicTask.TASK_1], jobs: [Job.BSM], qtyPerJob: 30, maxQtyPerJob: 90 },
		],
	},
	[Item.CONNOISSEUR_ESCUTCHEON]: {
		name: Item.CONNOISSEUR_ESCUTCHEON, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.CONNOISSEUR_ESCUTCHEON, qtyMade: 1, crafter: Job.ARM, level: 90, lodestoneID: "",
					itemsRequired: [
						{ item: Item.SELECT_COBALT_PLATE, qty: 1 },
						{ item: Item.CHONDRITE_INGOT, qty: 1 },
						{ item: Item.MANGANESE_INGOT, qty: 1 },
						{ item: Item.IRONWOOD_LUMBER, qty: 1 },
						{ item: Item.ICE_CLUSTER, qty: 4 },
						{ item: Item.EARTH_CLUSTER, qty: 2 },
					],
				},
			],
		},
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SPLENDOROUS, RelicPart.TOOL_BRILLIANT, RelicStep.BRILLIANT_COMPONENT, RelicTask.TASK_1], jobs: [Job.ARM], qtyPerJob: 30, maxQtyPerJob: 90 },
		],
	},
	[Item.CONNOISSEUR_BAGHNAKHS]: {
		name: Item.CONNOISSEUR_BAGHNAKHS, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.CONNOISSEUR_BAGHNAKHS, qtyMade: 1, crafter: Job.GSM, level: 90, lodestoneID: "",
					itemsRequired: [
						{ item: Item.SELECT_BLUESPIRIT_TILE, qty: 1 },
						{ item: Item.STAR_QUARTZ, qty: 1 },
						{ item: Item.PHRYGIAN_GOLD_INGOT, qty: 1 },
						{ item: Item.MANGANESE_INGOT, qty: 1 },
						{ item: Item.WIND_CLUSTER, qty: 4 },
						{ item: Item.FIRE_CLUSTER, qty: 2 },
					],
				},
			],
		},
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SPLENDOROUS, RelicPart.TOOL_BRILLIANT, RelicStep.BRILLIANT_COMPONENT, RelicTask.TASK_1], jobs: [Job.GSM], qtyPerJob: 30, maxQtyPerJob: 90 },
		],
	},
	[Item.CONNOISSEUR_DRINKING_APKALLU]: {
		name: Item.CONNOISSEUR_DRINKING_APKALLU, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.CONNOISSEUR_DRINKING_APKALLU, qtyMade: 1, crafter: Job.LTW, level: 90, lodestoneID: "",
					itemsRequired: [
						{ item: Item.SELECT_GREEN_GLIDER_LEATHER, qty: 1 },
						{ item: Item.OPHIOTAUROS_LEATHER, qty: 1 },
						{ item: Item.PHRYGIAN_GOLD_INGOT, qty: 1 },
						{ item: Item.APKALLU_DOWN, qty: 2 },
						{ item: Item.EARTH_CLUSTER, qty: 4 },
						{ item: Item.WIND_CLUSTER, qty: 2 },
					],
				},
			],
		},
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SPLENDOROUS, RelicPart.TOOL_BRILLIANT, RelicStep.BRILLIANT_COMPONENT, RelicTask.TASK_1], jobs: [Job.LTW], qtyPerJob: 30, maxQtyPerJob: 90 },
		],
	},
	[Item.CONNOISSEUR_JACKET]: {
		name: Item.CONNOISSEUR_JACKET, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.CONNOISSEUR_JACKET, qtyMade: 1, crafter: Job.WVR, level: 90, lodestoneID: "",
					itemsRequired: [
						{ item: Item.SELECT_WATERPROOF_CLOTH, qty: 1 },
						{ item: Item.AR_CAEAN_VELVET, qty: 1 },
						{ item: Item.SCARLET_MOKO_CLOTH, qty: 1 },
						{ item: Item.SAIGA_LEATHER, qty: 1 },
						{ item: Item.LIGHTNING_CLUSTER, qty: 4 },
						{ item: Item.WIND_CLUSTER, qty: 2 },
					],
				},
			],
		},
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SPLENDOROUS, RelicPart.TOOL_BRILLIANT, RelicStep.BRILLIANT_COMPONENT, RelicTask.TASK_1], jobs: [Job.WVR], qtyPerJob: 30, maxQtyPerJob: 90 },
		],
	},
	[Item.CONNOISSEUR_PLANTER_PARTITION]: {
		name: Item.CONNOISSEUR_PLANTER_PARTITION, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.CONNOISSEUR_PLANTER_PARTITION, qtyMade: 1, crafter: Job.ALC, level: 90, lodestoneID: "",
					itemsRequired: [
						{ item: Item.SELECT_RAKTIKA_SEEDLING, qty: 1 },
						{ item: Item.IRONWOOD_LUMBER, qty: 1 },
						{ item: Item.GRADE_5_VITALITY_ALKAHEST, qty: 1 },
						{ item: Item.GROWTH_FORMULA_KAPPA, qty: 1 },
						{ item: Item.WATER_CLUSTER, qty: 4 },
						{ item: Item.LIGHTNING_CLUSTER, qty: 2 },
					],
				},
			],
		},
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SPLENDOROUS, RelicPart.TOOL_BRILLIANT, RelicStep.BRILLIANT_COMPONENT, RelicTask.TASK_1], jobs: [Job.ALC], qtyPerJob: 30, maxQtyPerJob: 90 },
		],
	},
	[Item.CONNOISSEUR_SPAGHETTI_AL_NERO]: {
		name: Item.CONNOISSEUR_SPAGHETTI_AL_NERO, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.CONNOISSEUR_SPAGHETTI_AL_NERO, qtyMade: 1, crafter: Job.CUL, level: 90, lodestoneID: "",
					itemsRequired: [
						{ item: Item.SELECT_SQUID_INK, qty: 1 },
						{ item: Item.PERILLA_OIL, qty: 3 },
						{ item: Item.GARLEAN_CHEESE, qty: 3 },
						{ item: Item.VERMICELLI, qty: 2 },
						{ item: Item.FIRE_CLUSTER, qty: 4 },
						{ item: Item.WATER_CLUSTER, qty: 2 },
					],
				},
			],
		},
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SPLENDOROUS, RelicPart.TOOL_BRILLIANT, RelicStep.BRILLIANT_COMPONENT, RelicTask.TASK_1], jobs: [Job.CUL], qtyPerJob: 30, maxQtyPerJob: 90 },
		],
	},
	[Item.CONNOISSEUR_AURUM_REGIS_ORE]: {
		name: Item.CONNOISSEUR_AURUM_REGIS_ORE, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { gathering: [{ found: { location: MapLocation.BRIGHT_CLIFF, x: 33, y: 29 }, method: GatherMethod.MINING, collectable: true }] },
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SPLENDOROUS, RelicPart.TOOL_BRILLIANT, RelicStep.BRILLIANT_COMPONENT, RelicTask.TASK_1], jobs: [Job.MIN], qtyPerJob: 70, maxQtyPerJob: 210 },
		],
	},
	[Item.CONNOISSEUR_CLOVES]: {
		name: Item.CONNOISSEUR_CLOVES, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { gathering: [{ found: { location: MapLocation.CENTRAL_HILLS_OF_AMBER, x: 18, y: 19 }, method: GatherMethod.LOGGING, collectable: true }] },
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SPLENDOROUS, RelicPart.TOOL_BRILLIANT, RelicStep.BRILLIANT_COMPONENT, RelicTask.TASK_1], jobs: [Job.BTN], qtyPerJob: 70, maxQtyPerJob: 210 },
		],
	},
	[Item.OIL_SLICK]: {
		name: Item.OIL_SLICK, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { fishingHoles: [{ fish: Fish.OIL_SLICK, hole: Fishing.WHITE_OIL_FALLS, bait: [Bait.SELECT_BAIT_BALL] }] },
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SPLENDOROUS, RelicPart.TOOL_BRILLIANT, RelicStep.BRILLIANT_COMPONENT, RelicTask.TASK_1], jobs: [Job.FSH], qtyPerJob: 40, maxQtyPerJob: 80 },
		],
	},
	[Item.GONZALOS_GRACE]: {
		name: Item.GONZALOS_GRACE, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { fishingHoles: [{ fish: Fish.GONZALOS_GRACE, hole: Fishing.WEST_CALIBAN_GAP, bait: [Bait.SELECT_BAIT_BALL] }] },
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SPLENDOROUS, RelicPart.TOOL_BRILLIANT, RelicStep.BRILLIANT_COMPONENT, RelicTask.TASK_2], jobs: [Job.FSH], qtyPerJob: 40, maxQtyPerJob: 80 },
		],
	},
	[Item.CONNOISSEUR_BAMBOO_FENCE]: {
		name: Item.CONNOISSEUR_BAMBOO_FENCE, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.CONNOISSEUR_BAMBOO_FENCE, qtyMade: 1, crafter: Job.CRP, level: 90, lodestoneID: "",
					itemsRequired: [
						{ item: Item.SELECT_BAMBOO_STICK, qty: 1 },
						{ item: Item.INTEGRAL_LOG, qty: 1 },
						{ item: Item.LIMESTONE, qty: 1 },
						{ item: Item.WIND_CLUSTER, qty: 4 },
						{ item: Item.ICE_CLUSTER, qty: 2 },
					],
				},
			],
		},
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SPLENDOROUS, RelicPart.TOOL_VRANDTIC_VISIONARYS, RelicStep.INSPIRATIONAL_COMPONENT, RelicTask.TASK_1], jobs: [Job.CRP], qtyPerJob: 20, maxQtyPerJob: 60 },
		],
	},
	[Item.CONNOISSEUR_ROUSING_CHRONOMETER]: {
		name: Item.CONNOISSEUR_ROUSING_CHRONOMETER, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.CONNOISSEUR_ROUSING_CHRONOMETER, qtyMade: 1, crafter: Job.BSM, level: 90, lodestoneID: "",
					itemsRequired: [
						{ item: Item.SELECT_DURALUMINUM_INGOT, qty: 1 },
						{ item: Item.CHONDRITE, qty: 1 },
						{ item: Item.RAW_STAR_QUARTZ, qty: 1 },
						{ item: Item.FIRE_CLUSTER, qty: 4 },
						{ item: Item.EARTH_CLUSTER, qty: 2 },
					],
				},
			],
		},
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SPLENDOROUS, RelicPart.TOOL_VRANDTIC_VISIONARYS, RelicStep.INSPIRATIONAL_COMPONENT, RelicTask.TASK_1], jobs: [Job.BSM], qtyPerJob: 20, maxQtyPerJob: 60 },
		],
	},
	[Item.CONNOISSEUR_TRUMPET]: {
		name: Item.CONNOISSEUR_TRUMPET, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.CONNOISSEUR_TRUMPET, qtyMade: 1, crafter: Job.ARM, level: 90, lodestoneID: "",
					itemsRequired: [
						{ item: Item.SELECT_BRASHGOLD_PLATE, qty: 1 },
						{ item: Item.TRUEGOLD_ORE, qty: 1 },
						{ item: Item.HORSE_CHESTNUT_LOG, qty: 1 },
						{ item: Item.ICE_CLUSTER, qty: 4 },
						{ item: Item.EARTH_CLUSTER, qty: 2 },
					],
				},
			],
		},
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SPLENDOROUS, RelicPart.TOOL_VRANDTIC_VISIONARYS, RelicStep.INSPIRATIONAL_COMPONENT, RelicTask.TASK_1], jobs: [Job.ARM], qtyPerJob: 20, maxQtyPerJob: 60 },
		],
	},
	[Item.CONNOISSEUR_WASHBASIN]: {
		name: Item.CONNOISSEUR_WASHBASIN, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.CONNOISSEUR_WASHBASIN, qtyMade: 1, crafter: Job.GSM, level: 90, lodestoneID: "",
					itemsRequired: [
						{ item: Item.SELECT_MARBLE, qty: 1 },
						{ item: Item.PEWTER_ORE, qty: 1 },
						{ item: Item.BISMUTH_ORE, qty: 1 },
						{ item: Item.WIND_CLUSTER, qty: 4 },
						{ item: Item.FIRE_CLUSTER, qty: 2 },
					],
				},
			],
		},
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SPLENDOROUS, RelicPart.TOOL_VRANDTIC_VISIONARYS, RelicStep.INSPIRATIONAL_COMPONENT, RelicTask.TASK_1], jobs: [Job.GSM], qtyPerJob: 20, maxQtyPerJob: 60 },
		],
	},
	[Item.CONNOISSEUR_TARGE]: {
		name: Item.CONNOISSEUR_TARGE, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.CONNOISSEUR_TARGE, qtyMade: 1, crafter: Job.LTW, level: 90, lodestoneID: "",
					itemsRequired: [
						{ item: Item.SELECT_CHALICOTHERIUM_LEATHER, qty: 1 },
						{ item: Item.HORSE_CHESTNUT_LOG, qty: 1 },
						{ item: Item.MANGANESE_ORE, qty: 1 },
						{ item: Item.EARTH_CLUSTER, qty: 4 },
						{ item: Item.WIND_CLUSTER, qty: 2 },
					],
				},
			],
		},
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SPLENDOROUS, RelicPart.TOOL_VRANDTIC_VISIONARYS, RelicStep.INSPIRATIONAL_COMPONENT, RelicTask.TASK_1], jobs: [Job.LTW], qtyPerJob: 20, maxQtyPerJob: 60 },
		],
	},
	[Item.CONNOISSEUR_PETASOS]: {
		name: Item.CONNOISSEUR_PETASOS, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.CONNOISSEUR_PETASOS, qtyMade: 1, crafter: Job.WVR, level: 90, lodestoneID: "",
					itemsRequired: [
						{ item: Item.SELECT_DUSKCOURT_CLOTH, qty: 1 },
						{ item: Item.RUBY_COTTON_BOLL, qty: 1 },
						{ item: Item.AR_CAEAN_COTTON_BOLL, qty: 1 },
						{ item: Item.LIGHTNING_CLUSTER, qty: 4 },
						{ item: Item.WIND_CLUSTER, qty: 2 },
					],
				},
			],
		},
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SPLENDOROUS, RelicPart.TOOL_VRANDTIC_VISIONARYS, RelicStep.INSPIRATIONAL_COMPONENT, RelicTask.TASK_1], jobs: [Job.WVR], qtyPerJob: 20, maxQtyPerJob: 60 },
		],
	},
	[Item.CONNOISSEUR_LUNAR_CURTAIN]: {
		name: Item.CONNOISSEUR_LUNAR_CURTAIN, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.CONNOISSEUR_LUNAR_CURTAIN, qtyMade: 1, crafter: Job.ALC, level: 90, lodestoneID: "",
					itemsRequired: [
						{ item: Item.SELECT_CUDWEED, qty: 1 },
						{ item: Item.LUNATENDER_BLOSSOM, qty: 1 },
						{ item: Item.MOUSSE_FLESH, qty: 1 },
						{ item: Item.WATER_CLUSTER, qty: 4 },
						{ item: Item.LIGHTNING_CLUSTER, qty: 2 },
					],
				},
			],
		},
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SPLENDOROUS, RelicPart.TOOL_VRANDTIC_VISIONARYS, RelicStep.INSPIRATIONAL_COMPONENT, RelicTask.TASK_1], jobs: [Job.ALC], qtyPerJob: 20, maxQtyPerJob: 60 },
		],
	},
	[Item.CONNOISSEUR_CRAB_CAKES]: {
		name: Item.CONNOISSEUR_CRAB_CAKES, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.CONNOISSEUR_CRAB_CAKES, qtyMade: 1, crafter: Job.CUL, level: 90, lodestoneID: "",
					itemsRequired: [
						{ item: Item.SELECT_BLUE_CRAB, qty: 1 },
						{ item: Item.UPLAND_WHEAT, qty: 1 },
						{ item: Item.EGG_OF_ELPIS, qty: 1 },
						{ item: Item.FIRE_CLUSTER, qty: 4 },
						{ item: Item.WATER_CLUSTER, qty: 2 },
					],
				},
			],
		},
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SPLENDOROUS, RelicPart.TOOL_VRANDTIC_VISIONARYS, RelicStep.INSPIRATIONAL_COMPONENT, RelicTask.TASK_1], jobs: [Job.CUL], qtyPerJob: 20, maxQtyPerJob: 60 },
		],
	},
	[Item.CONNOISSEUR_ASPHALTUM]: {
		name: Item.CONNOISSEUR_ASPHALTUM, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { gathering: [{ found: { location: MapLocation.GOOD_JENANNAS_GRACE, x: 12, y: 11 }, method: GatherMethod.MINING, collectable: true }] },
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SPLENDOROUS, RelicPart.TOOL_VRANDTIC_VISIONARYS, RelicStep.INSPIRATIONAL_COMPONENT, RelicTask.TASK_1], jobs: [Job.MIN], qtyPerJob: 110, maxQtyPerJob: 220 },
		],
	},
	[Item.CONNOISSEUR_GIANTHIVE_CHIP]: {
		name: Item.CONNOISSEUR_GIANTHIVE_CHIP, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { gathering: [{ found: { location: MapLocation.CITIA_SWAMPS, x: 15, y: 35 }, method: GatherMethod.LOGGING, collectable: true }] },
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SPLENDOROUS, RelicPart.TOOL_VRANDTIC_VISIONARYS, RelicStep.INSPIRATIONAL_COMPONENT, RelicTask.TASK_1], jobs: [Job.BTN], qtyPerJob: 110, maxQtyPerJob: 220 },
		],
	},
	[Item.DEADWOOD_SHADOW]: {
		name: Item.DEADWOOD_SHADOW, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { fishingHoles: [{ fish: Fish.DEADWOOD_SHADOW, hole: Fishing.MJRLS_TEARS, bait: [Bait.SELECT_BAIT_BALL], mooch: [[{ fish: Fish.GOLDING, bait: [Bait.SELECT_BAIT_BALL] }]] }] },
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SPLENDOROUS, RelicPart.TOOL_VRANDTIC_VISIONARYS, RelicStep.INSPIRATIONAL_COMPONENT, RelicTask.TASK_1], jobs: [Job.FSH], qtyPerJob: 85, maxQtyPerJob: 170 },
		],
	},
	[Item.RONKAN_BULLION]: {
		name: Item.RONKAN_BULLION, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			fishingHoles: [
				{ fish: Fish.RONKAN_BULLION, hole: Fishing.MJRLS_TEARS, bait: [Bait.SELECT_BAIT_BALL], mooch: [[{ fish: Fish.GOLDING, bait: [Bait.SELECT_BAIT_BALL] }]] },
			],
		},
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SPLENDOROUS, RelicPart.TOOL_VRANDTIC_VISIONARYS, RelicStep.INSPIRATIONAL_COMPONENT, RelicTask.TASK_2], jobs: [Job.FSH], qtyPerJob: 43, maxQtyPerJob: 57 },
		],
	},
	[Item.CONNOSISSEUR_SHISHI_ODOSHI]: {
		name: Item.CONNOSISSEUR_SHISHI_ODOSHI, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.CONNOSISSEUR_SHISHI_ODOSHI, qtyMade: 1, crafter: Job.CRP, level: 90, lodestoneID: "",
					itemsRequired: [
						{ item: Item.SELECT_BAMBOO_STICK, qty: 1 },
						{ item: Item.AMBROSIAL_WATER, qty: 1 },
						{ item: Item.SILTSTONE, qty: 1 },
						{ item: Item.GRANITE, qty: 1 },
						{ item: Item.WIND_CLUSTER, qty: 4 },
						{ item: Item.ICE_CLUSTER, qty: 2 },
					],
				},
			],
		},
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SPLENDOROUS, RelicPart.TOOL_LODESTAR, RelicStep.NIGHTFORGED_COMPONENT, RelicTask.TASK_1], jobs: [Job.CRP], qtyPerJob: 20, maxQtyPerJob: 60 },
		],
	},
	[Item.CONNOSISSEUR_RETAINER_BELL]: {
		name: Item.CONNOSISSEUR_RETAINER_BELL, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.CONNOSISSEUR_RETAINER_BELL, qtyMade: 1, crafter: Job.BSM, level: 90, lodestoneID: "",
					itemsRequired: [
						{ item: Item.SELECT_DURALUMINUM_INGOT, qty: 1 },
						{ item: Item.INTEGRAL_LOG, qty: 1 },
						{ item: Item.PEWTER_ORE, qty: 1 },
						{ item: Item.WILDFOWN_FEATHER, qty: 1 },
						{ item: Item.FIRE_CLUSTER, qty: 4 },
						{ item: Item.EARTH_CLUSTER, qty: 2 },
					],
				},
			],
		},
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SPLENDOROUS, RelicPart.TOOL_LODESTAR, RelicStep.NIGHTFORGED_COMPONENT, RelicTask.TASK_1], jobs: [Job.BSM], qtyPerJob: 20, maxQtyPerJob: 60 },
		],
	},
	[Item.CONNOSISSEUR_MARCHING_HORN]: {
		name: Item.CONNOSISSEUR_MARCHING_HORN, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.CONNOSISSEUR_MARCHING_HORN, qtyMade: 1, crafter: Job.ARM, level: 90, lodestoneID: "",
					itemsRequired: [
						{ item: Item.SELECT_BRASHGOLD_PLATE, qty: 1 },
						{ item: Item.MAHOGANY_LOG, qty: 1 },
						{ item: Item.BISMUTH_ORE, qty: 1 },
						{ item: Item.MANGANESE_ORE, qty: 1 },
						{ item: Item.ICE_CLUSTER, qty: 4 },
						{ item: Item.EARTH_CLUSTER, qty: 2 },
					],
				},
			],
		},
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SPLENDOROUS, RelicPart.TOOL_LODESTAR, RelicStep.NIGHTFORGED_COMPONENT, RelicTask.TASK_1], jobs: [Job.ARM], qtyPerJob: 20, maxQtyPerJob: 60 },
		],
	},
	[Item.CONNOSISSEUR_MARBLE_FOUNTAIN]: {
		name: Item.CONNOSISSEUR_MARBLE_FOUNTAIN, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.CONNOSISSEUR_MARBLE_FOUNTAIN, qtyMade: 1, crafter: Job.GSM, level: 90, lodestoneID: "",
					itemsRequired: [
						{ item: Item.SELECT_MARBLE, qty: 1 },
						{ item: Item.AMBROSIAL_WATER, qty: 1 },
						{ item: Item.ANNITE, qty: 1 },
						{ item: Item.GRANITE, qty: 1 },
						{ item: Item.WIND_CLUSTER, qty: 4 },
						{ item: Item.FIRE_CLUSTER, qty: 2 },
					],
				},
			],
		},
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SPLENDOROUS, RelicPart.TOOL_LODESTAR, RelicStep.NIGHTFORGED_COMPONENT, RelicTask.TASK_1], jobs: [Job.GSM], qtyPerJob: 20, maxQtyPerJob: 60 },
		],
	},
	[Item.CONNOSISSEUR_LEATHER_JACKET]: {
		name: Item.CONNOSISSEUR_LEATHER_JACKET, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.CONNOSISSEUR_LEATHER_JACKET, qtyMade: 1, crafter: Job.LTW, level: 90, lodestoneID: "",
					itemsRequired: [
						{ item: Item.SELECT_CHALICOTHERIUM_LEATHER, qty: 1 },
						{ item: Item.RUBY_COTTON_BOLL, qty: 1 },
						{ item: Item.ALMASTY_FUR, qty: 1 },
						{ item: Item.AR_CAEAN_COTTON_BOLL, qty: 1 },
						{ item: Item.EARTH_CLUSTER, qty: 4 },
						{ item: Item.WIND_CLUSTER, qty: 2 },
					],
				},
			],
		},
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SPLENDOROUS, RelicPart.TOOL_LODESTAR, RelicStep.NIGHTFORGED_COMPONENT, RelicTask.TASK_1], jobs: [Job.LTW], qtyPerJob: 20, maxQtyPerJob: 60 },
		],
	},
	[Item.CONNOSISSEUR_FAT_CAT_SOFA]: {
		name: Item.CONNOSISSEUR_FAT_CAT_SOFA, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.CONNOSISSEUR_FAT_CAT_SOFA, qtyMade: 1, crafter: Job.WVR, level: 90, lodestoneID: "",
					itemsRequired: [
						{ item: Item.SELECT_DUSKCOURT_CLOTH, qty: 1 },
						{ item: Item.ALMASTY_FUR, qty: 1 },
						{ item: Item.AR_CAEAN_COTTON_BOLL, qty: 1 },
						{ item: Item.SCARLET_MOKO_GRASS, qty: 1 },
						{ item: Item.LIGHTNING_CLUSTER, qty: 4 },
						{ item: Item.WIND_CLUSTER, qty: 2 },
					],
				},
			],
		},
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SPLENDOROUS, RelicPart.TOOL_LODESTAR, RelicStep.NIGHTFORGED_COMPONENT, RelicTask.TASK_1], jobs: [Job.WVR], qtyPerJob: 20, maxQtyPerJob: 60 },
		],
	},
	[Item.CONNOSISSEUR_TINCTURE_OF_VITALITY]: {
		name: Item.CONNOSISSEUR_TINCTURE_OF_VITALITY, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.CONNOSISSEUR_TINCTURE_OF_VITALITY, qtyMade: 1, crafter: Job.ALC, level: 90, lodestoneID: "",
					itemsRequired: [
						{ item: Item.SELECT_CUDWEED, qty: 1 },
						{ item: Item.PETALOUDA_SCALES, qty: 1 },
						{ item: Item.BERKANAN_SAP, qty: 1 },
						{ item: Item.AMRA, qty: 1 },
						{ item: Item.WATER_CLUSTER, qty: 4 },
						{ item: Item.LIGHTNING_CLUSTER, qty: 2 },
					],
				},
			],
		},
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SPLENDOROUS, RelicPart.TOOL_LODESTAR, RelicStep.NIGHTFORGED_COMPONENT, RelicTask.TASK_1], jobs: [Job.ALC], qtyPerJob: 20, maxQtyPerJob: 60 },
		],
	},
	[Item.CONNOSISSEUR_CHILI_CRAB]: {
		name: Item.CONNOSISSEUR_CHILI_CRAB, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.CONNOSISSEUR_CHILI_CRAB, qtyMade: 1, crafter: Job.CUL, level: 90, lodestoneID: "",
					itemsRequired: [
						{ item: Item.SELECT_BLUE_CRAB, qty: 1 },
						{ item: Item.THAVNAIRIAN_PERILLA_LEAF, qty: 1 },
						{ item: Item.BLOOD_TOMATO, qty: 1 },
						{ item: Item.PEARL_GINGER, qty: 1 },
						{ item: Item.FIRE_CLUSTER, qty: 4 },
						{ item: Item.WATER_CLUSTER, qty: 2 },
					],
				},
			],
		},
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SPLENDOROUS, RelicPart.TOOL_LODESTAR, RelicStep.NIGHTFORGED_COMPONENT, RelicTask.TASK_1], jobs: [Job.CUL], qtyPerJob: 20, maxQtyPerJob: 60 },
		],
	},
	[Item.CONNOISSEUR_RAW_ONYX]: {
		name: Item.CONNOISSEUR_RAW_ONYX, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { gathering: [{ found: { location: MapLocation.NORVRANDT_SLOPE, x: 34, y: 22 }, method: GatherMethod.MINING, collectable: true }] },
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SPLENDOROUS, RelicPart.TOOL_LODESTAR, RelicStep.NIGHTFORGED_COMPONENT, RelicTask.TASK_1], jobs: [Job.MIN], qtyPerJob: 110, maxQtyPerJob: 210 },
		],
	},
	[Item.CONNOISSEUR_GLIMSHROOM]: {
		name: Item.CONNOISSEUR_GLIMSHROOM, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { gathering: [{ found: { location: MapLocation.NORVRANDT_SLOPE, x: 37, y: 13 }, method: GatherMethod.LOGGING, collectable: true }] },
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SPLENDOROUS, RelicPart.TOOL_LODESTAR, RelicStep.NIGHTFORGED_COMPONENT, RelicTask.TASK_1], jobs: [Job.BTN], qtyPerJob: 110, maxQtyPerJob: 210 },
		],
	},
	[Item.LITTLE_BOUNTY]: {
		name: Item.LITTLE_BOUNTY, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { fishingHoles: [{ fish: Fish.LITTLE_BOUNTY, hole: Fishing.SAINT_FATHRICS_TEMPLE, bait: [Bait.SELECT_BAIT_BALL] }] },
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SPLENDOROUS, RelicPart.TOOL_LODESTAR, RelicStep.NIGHTFORGED_COMPONENT, RelicTask.TASK_1], jobs: [Job.FSH], qtyPerJob: 85, maxQtyPerJob: 170 },
		],
	},
	[Item.SAINT_FATHRICS_FACE]: {
		name: Item.SAINT_FATHRICS_FACE, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			fishingHoles: [
				{ fish: Fish.SAINT_FATHRICS_FACE, hole: Fishing.SAINT_FATHRICS_TEMPLE, bait: [Bait.SELECT_BAIT_BALL] },
			],
		},
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SPLENDOROUS, RelicPart.TOOL_LODESTAR, RelicStep.NIGHTFORGED_COMPONENT, RelicTask.TASK_2], jobs: [Job.FSH], qtyPerJob: 43, maxQtyPerJob: 57 },
		],
	},
	[Item.SELECT_DARK_CHESTNUT_LUMBER]: {
		name: Item.SELECT_DARK_CHESTNUT_LUMBER, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {shops: [{ shop: Shop.QUINNANA, cost: [{ price: 60, currency: Currency.WHITE_CRAFTERS_SCRIP }] }]},
		used: [],
	},
	[Item.RED_PINE_LUMBER]: {
		name: Item.RED_PINE_LUMBER, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.RED_PINE_LUMBER, qtyMade: 1, crafter: Job.CRP, level: 84, lodestoneID: "",
					itemsRequired: [
						{ item: Item.RED_PINE_LOG, qty: 5 },
						{ item: Item.WIND_CRYSTAL, qty: 8 },
					],
				},
			],
		},
		used: [],
	},
	[Item.RED_PINE_LOG]: {
		name: Item.RED_PINE_LOG, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: { gathering: [{ found: { location: MapLocation.EBLAN_RIME, x: 16, y: 29 }, method: GatherMethod.LOGGING }] },
		used: [],
	},
	[Item.LINEN_CANVAS]: {
		name: Item.LINEN_CANVAS, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.LINEN_CANVAS, qtyMade: 1, crafter: Job.WVR, level: 36, lodestoneID: "",
					itemsRequired: [
						{ item: Item.LINEN_YARN, qty: 2 },
						{ item: Item.COTTON_YARN, qty: 1 },
						{ item: Item.BEESWAX, qty: 1 },
						{ item: Item.LIGHTNING_SHARD, qty: 4 },
					],
				},
			],
		},
		used: [],
	},
	[Item.COTTON_YARN]: {
		name: Item.COTTON_YARN, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.COTTON_YARN, qtyMade: 2, crafter: Job.WVR, level: 12, lodestoneID: "",
					itemsRequired: [
						{ item: Item.COTTON_BOLL, qty: 2 },
						{ item: Item.LIGHTNING_SHARD, qty: 1 },
					],
				},
			],
			shops: [
				{ shop: Shop.ESMENET, cost: [{ price: 22, currency: Currency.GIL }] },
				{ shop: Shop.ALLAGAN_RESUPPLY_NODE, cost: [{ price: 22, currency: Currency.GIL }] },
				{ shop: Shop.MATERIAL_SUPPLIER, cost: [{ price: 22, currency: Currency.GIL }] },
				{ shop: Shop.SYLPHIC_VENDOR, cost: [{ price: 22, currency: Currency.GIL }, { price: 1, currency: Currency.SYLPHIC_GOLDLEAF }] },
			],
		},
		used: [],
	},
	[Item.BEESWAX]: {
		name: Item.BEESWAX, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.BEESWAX, qtyMade: 1, crafter: Job.ALC, level: 10, lodestoneID: "",
					itemsRequired: [
						{ item: Item.BEEHIVE_CHIP, qty: 3 },
						{ item: Item.WATER_SHARD, qty: 1 },
					],
				},
			],
			shops: [
				{ shop: Shop.JOSSY, cost: [{ price: 14, currency: Currency.GIL }] },
				{ shop: Shop.ALLAGAN_RESUPPLY_NODE, cost: [{ price: 14, currency: Currency.GIL }] },
				{ shop: Shop.MATERIAL_SUPPLIER, cost: [{ price: 14, currency: Currency.GIL }] },
			],
		},
		used: [],
	},
	[Item.COTTON_BOLL]: {
		name: Item.COTTON_BOLL, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			gathering: [{ found: { location: MapLocation.BENTBRANCH, x: 22, y: 24 }, method: GatherMethod.HARVESTING }],
			shops: [
				{ shop: Shop.GIGIMA, cost: [{ price: 10, currency: Currency.GIL }] },
				{ shop: Shop.MATERIAL_SUPPLIER, cost: [{ price: 10, currency: Currency.GIL }] },
				{ shop: Shop.ALLAGAN_RESUPPLY_NODE, cost: [{ price: 10, currency: Currency.GIL }] },
			],
		},
		used: [],
	},
	[Item.BEEHIVE_CHIP]: {
		name: Item.BEEHIVE_CHIP, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			gathering: [{ found: { location: MapLocation.NORTH_SHROUD, x: 26, y: 27 }, method: GatherMethod.LOGGING }],
			shops: [
				{ shop: Shop.ALLAGAN_RESUPPLY_NODE, cost: [{ price: 5, currency: Currency.GIL }] },
				{ shop: Shop.MATERIAL_SUPPLIER, cost: [{ price: 5, currency: Currency.GIL }] },
				{ shop: Shop.ENGERRAND, cost: [{ price: 5, currency: Currency.GIL }] },
				{ shop: Shop.ESMENET, cost: [{ price: 5, currency: Currency.GIL }] },
				{ shop: Shop.FRIDURIH, cost: [{ price: 5, currency: Currency.GIL }] },
				{ shop: Shop.JOSSY, cost: [{ price: 5, currency: Currency.GIL }] },
				{ shop: Shop.ORHOYOD, cost: [{ price: 5, currency: Currency.GIL }] },
				{ shop: Shop.PASDEVILLET, cost: [{ price: 5, currency: Currency.GIL }] },
				{ shop: Shop.SHIRAKASHI, cost: [{ price: 5, currency: Currency.GIL }] },
				{ shop: Shop.VRAL, cost: [{ price: 5, currency: Currency.GIL }] },
				{ shop: Shop.TRAVELING_MATERIAL_SUPPLIER, cost: [{ price: 5, currency: Currency.GIL }] },
			],
		},
		used: [],
	},
	[Item.SELECT_BISMUTH_INGOT]: {
		name: Item.SELECT_BISMUTH_INGOT, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {shops: [{ shop: Shop.QUINNANA, cost: [{ price: 60, currency: Currency.WHITE_CRAFTERS_SCRIP }] }]},
		used: [],
	},
	[Item.PEWTER_INGOT]: {
		name: Item.PEWTER_INGOT, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.PEWTER_INGOT, qtyMade: 1, crafter: Job.GSM, level: 82, lodestoneID: "",
					itemsRequired: [
						{ item: Item.PEWTER_ORE, qty: 6 },
						{ item: Item.TIN_ORE, qty: 1 },
						{ item: Item.WIND_CRYSTAL, qty: 8 },
					],
				},
			],
		},
		used: [],
	},
	[Item.OROSHIGANE_INGOT]: {
		name: Item.OROSHIGANE_INGOT, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.OROSHIGANE_INGOT, qtyMade: 1, crafter: Job.BSM, level: 64, lodestoneID: "",
					itemsRequired: [
						{ item: Item.DOMAN_IRON_ORE, qty: 4 },
						{ item: Item.IRON_ORE, qty: 1 },
						{ item: Item.FIRE_CRYSTAL, qty: 4 },
					],
				},
				{
					itemMade: Item.OROSHIGANE_INGOT, qtyMade: 1, crafter: Job.ARM, level: 64, lodestoneID: "",
					itemsRequired: [
						{ item: Item.DOMAN_IRON_ORE, qty: 4 },
						{ item: Item.IRON_ORE, qty: 1 },
						{ item: Item.ICE_CRYSTAL, qty: 4 },
					],
				},
			],
			shops: [
				{ shop: Shop.MATERIAL_SUPPLIER, cost: [{ price: 3264, currency: Currency.GIL }] },
			],
		},
		used: [],
	},
	[Item.PEWTER_ORE]: {
		name: Item.PEWTER_ORE, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			gathering: [
				{ found: { location: MapLocation.THAVNAIR, x: 10, y: 14 }, method: GatherMethod.QUARRYING },
			],
		},
		used: [],
	},
	[Item.TIN_ORE]: {
		name: Item.TIN_ORE, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			gathering: [
				{ found: { location: MapLocation.WESTERN_THANALAN, x: 22, y: 29 }, method: GatherMethod.MINING },
				{ found: { location: MapLocation.CENTRAL_THANALAN, x: 20, y: 23 }, method: GatherMethod.MINING },
			],
			shops: [
				{ shop: Shop.ALLAGAN_RESUPPLY_NODE, cost: [{ price: 2, currency: Currency.GIL }] },
				{ shop: Shop.MATERIAL_SUPPLIER, cost: [{ price: 2, currency: Currency.GIL }] },
				{ shop: Shop.ENGERRAND, cost: [{ price: 2, currency: Currency.GIL }] },
				{ shop: Shop.FRIDURIH, cost: [{ price: 2, currency: Currency.GIL }] },
				{ shop: Shop.ORHOYOD, cost: [{ price: 2, currency: Currency.GIL }] },
				{ shop: Shop.PASDEVILLET, cost: [{ price: 2, currency: Currency.GIL }] },
				{ shop: Shop.SMYDHAEMR, cost: [{ price: 2, currency: Currency.GIL }] },
				{ shop: Shop.SOEMRWYB, cost: [{ price: 2, currency: Currency.GIL }] },
			],
		},
		used: [],
	},
	[Item.DOMAN_IRON_ORE]: {
		name: Item.DOMAN_IRON_ORE, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			gathering: [
				{ found: { location: MapLocation.YANXIA, x: 34, y: 20}, method: GatherMethod.MINING },
			],
		},
		used: [],
	},
	[Item.SELECT_COBALT_PLATE]: {
		name: Item.SELECT_COBALT_PLATE, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {shops: [{ shop: Shop.QUINNANA, cost: [{ price: 60, currency: Currency.WHITE_CRAFTERS_SCRIP }] }]},
		used: [],
	},
	[Item.MANGANESE_INGOT]: {
		name: Item.MANGANESE_INGOT, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.MANGANESE_INGOT, qtyMade: 1, crafter: Job.BSM, level: 86, lodestoneID: "",
					itemsRequired: [
						{ item: Item.MANGANESE_ORE, qty: 5 },
						{ item: Item.MOLYBDENUM_ORE, qty: 1 },
						{ item: Item.FIRE_CRYSTAL, qty: 8 },
					],
				},
				{
					itemMade: Item.MANGANESE_INGOT, qtyMade: 1, crafter: Job.ARM, level: 86, lodestoneID: "",
					itemsRequired: [
						{ item: Item.MANGANESE_ORE, qty: 5 },
						{ item: Item.MOLYBDENUM_ORE, qty: 1 },
						{ item: Item.ICE_CRYSTAL, qty: 8 },
					],
				},
			],
		},
		used: [],
	},
	[Item.CRYSTAL_GLASS]: {
		name: Item.CRYSTAL_GLASS, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.CRYSTAL_GLASS, qtyMade: 1, crafter: Job.ALC, level: 50, lodestoneID: "",
					itemsRequired: [
						{ item: Item.SILEX, qty: 3 },
						{ item: Item.NATRON, qty: 1 },
						{ item: Item.MINIUM, qty: 1 },
						{ item: Item.WATER_CRYSTAL, qty: 5 },
					],
				},
			],
		},
		used: [],
	},
	[Item.MANGANESE_ORE]: {
		name: Item.MANGANESE_ORE, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			gathering: [
				{ found: { location: MapLocation.ELPIS, x: 24, y: 7 }, method: GatherMethod.MINING },
			],
		},
		used: [],
	},
	[Item.MOLYBDENUM_ORE]: {
		name: Item.MOLYBDENUM_ORE, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			gathering: [
				{ found: { location: MapLocation.LOCHS, x: 11, y: 16 }, method: GatherMethod.MINING },
			],
		},
		used: [],
	},
	[Item.SILEX]: {
		name: Item.SILEX, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			gathering: [
				{ found: { location: MapLocation.SOUTHERN_THANALAN, x: 21, y: 29 }, method: GatherMethod.QUARRYING },
			],
		},
		used: [],
	},
	[Item.NATRON]: {
		name: Item.NATRON, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.NATRON, qtyMade: 1, crafter: Job.ALC, level: 25, lodestoneID: "",
					itemsRequired: [
						{ item: Item.EFFERVESCENT_WATER, qty: 1 },
						{ item: Item.ROCK_SALT, qty: 1 },
						{ item: Item.WATER_SHARD, qty: 2 },
					],
				},
			],
		},
		used: [],
	},
	[Item.MINIUM]: {
		name: Item.MINIUM, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			shops: [
				{ shop: Shop.FLAME_QUARTERMASTER, cost: [{ price: 200, currency: Currency.COMPANY_SEAL }] },
				{ shop: Shop.STORM_QUARTERMASTER, cost: [{ price: 200, currency: Currency.COMPANY_SEAL }] },
				{ shop: Shop.SERPENT_QUARTERMASTER, cost: [{ price: 200, currency: Currency.COMPANY_SEAL }] },
				{ shop: Shop.IXALI_VENDOR, cost: [{ price: 1, currency: Currency.IXALI_OAKNOT }] },
			],
		},
		used: [],
	},
	[Item.SELECT_BLUESPIRIT_TILE]: {
		name: Item.SELECT_BLUESPIRIT_TILE, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {shops: [{ shop: Shop.QUINNANA, cost: [{ price: 60, currency: Currency.WHITE_CRAFTERS_SCRIP }] }]},
		used: [],
	},
	[Item.MANASILVER_NUGGET]: {
		name: Item.MANASILVER_NUGGET, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.MANASILVER_NUGGET, qtyMade: 1, crafter: Job.GSM, level: 74, lodestoneID: "",
					itemsRequired: [
						{ item: Item.MANASILVER_SAND, qty: 4 },
						{ item: Item.SILVER_ORE, qty: 1 },
						{ item: Item.WIND_CRYSTAL, qty: 7 },
					],
				},
			],
		},
		used: [],
	},
	[Item.SELECT_GREEN_GLIDER_LEATHER]: {
		name: Item.SELECT_GREEN_GLIDER_LEATHER, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {shops: [{ shop: Shop.QUINNANA, cost: [{ price: 60, currency: Currency.WHITE_CRAFTERS_SCRIP }] }]},
		used: [],
	},
	[Item.KUMBHIRA_LEATHER]: {
		name: Item.KUMBHIRA_LEATHER, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.KUMBHIRA_LEATHER, qtyMade: 1, crafter: Job.LTW, level: 86, lodestoneID: "",
					itemsRequired: [
						{ item: Item.KUMBHIRA_SKIN, qty: 4 },
						{ item: Item.EBLAN_ALUMEN, qty: 1 },
						{ item: Item.EARTH_CRYSTAL, qty: 8 },
					],
				},
			],
		},
		used: [],
	},
	[Item.IRIDESCENT_SILK]: {
		name: Item.IRIDESCENT_SILK, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.IRIDESCENT_SILK, qtyMade: 1, crafter: Job.WVR, level: 72, lodestoneID: "",
					itemsRequired: [
						{ item: Item.IRIDESCENT_SILK_THREAD, qty: 3 },
						{ item: Item.LIGHTNING_CRYSTAL, qty: 6 },
					],
				},
			],
		},
		used: [],
	},
	[Item.KUMBHIRA_SKIN]: {
		name: Item.KUMBHIRA_SKIN, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			enemies: [Enemy.KUMBHIRA],
			shops: [
				{ shop: Shop.GADFRID, cost: [{ price: 2, currency: Currency.BICOLOUR_GEMSTONE }] },
				{ shop: Shop.MAHVEYDAH, cost: [{ price: 2, currency: Currency.BICOLOUR_GEMSTONE }] },
				{ shop: Shop.SAJAREEN, cost: [{ price: 2, currency: Currency.BICOLOUR_GEMSTONE }] },
			],
		},
		used: [],
	},
	[Item.IRIDESCENT_SILK_THREAD]: {
		name: Item.IRIDESCENT_SILK_THREAD, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.IRIDESCENT_SILK_THREAD, qtyMade: 3, crafter: Job.WVR, level: 72, lodestoneID: "",
					itemsRequired: [
						{ item: Item.IRIDESCENT_COCOON, qty: 4 },
						{ item: Item.EFFERVESCENT_WATER, qty: 1 },
						{ item: Item.LIGHTNING_CRYSTAL, qty: 3 },
					],
				},
			],
		},
		used: [],
	},
	[Item.IRIDESCENT_COCOON]: {
		name: Item.IRIDESCENT_COCOON, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			gathering: [
				{ found: { location: MapLocation.GOOD_JENANNAS_GRACE, x: 11, y: 24 }, method: GatherMethod.HARVESTING },
			],
		},
		used: [],
	},
	[Item.SELECT_WATERPROOF_CLOTH]: {
		name: Item.SELECT_WATERPROOF_CLOTH, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {shops: [{ shop: Shop.QUINNANA, cost: [{ price: 60, currency: Currency.WHITE_CRAFTERS_SCRIP }] }]},
		used: [],
	},
	[Item.SELECT_RAKTIKA_SEEDLING]: {
		name: Item.SELECT_RAKTIKA_SEEDLING, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {shops: [{ shop: Shop.QUINNANA, cost: [{ price: 60, currency: Currency.WHITE_CRAFTERS_SCRIP }] }]},
		used: [],
	},
	[Item.GRADE_5_INTELLIGENCE_ALKAHEST]: {
		name: Item.GRADE_5_INTELLIGENCE_ALKAHEST, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.GRADE_5_INTELLIGENCE_ALKAHEST, qtyMade: 3, crafter: Job.ALC, level: 83, lodestoneID: "",
					itemsRequired: [
						{ item: Item.UNDERGROUND_SPRING_WATER, qty: 2 },
						{ item: Item.LUNATENDER_BLOSSOM, qty: 1 },
						{ item: Item.FERNLEAF_LAVENDER, qty: 2 },
						{ item: Item.WATER_CRYSTAL, qty: 8 },
					],
				},
			],
		},
		used: [],
	},
	[Item.GROWTH_FORMULA_KAPPA]: {
		name: Item.GROWTH_FORMULA_KAPPA, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.GROWTH_FORMULA_KAPPA, qtyMade: 1, crafter: Job.ALC, level: 76, lodestoneID: "",
					itemsRequired: [
						{ item: Item.HOPTRAP_LEAF, qty: 2 },
						{ item: Item.VAMPIRE_VINE_SAP, qty: 2 },
						{ item: Item.UNDERGROUND_SPRING_WATER, qty: 1 },
						{ item: Item.WATER_CRYSTAL, qty: 7 },
					],
				},
			],
		},
		used: [],
	},
	[Item.FERNLEAF_LAVENDER]: {
		name: Item.FERNLEAF_LAVENDER, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			gathering: [
				{ found: { location: MapLocation.BLIND_FOREST, x: 27, y: 25 }, method: GatherMethod.HARVESTING },
			],
		},
		used: [],
	},
	[Item.HOPTRAP_LEAF]: {
		name: Item.HOPTRAP_LEAF, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			enemies: [Enemy.HOPTRAP],
			shops: [
				{ shop: Shop.GRAMSOL, cost: [{ price: 1, currency: Currency.BICOLOUR_GEMSTONE }] },
				{ shop: Shop.PEDRONILLE, cost: [{ price: 1, currency: Currency.BICOLOUR_GEMSTONE }] },
				{ shop: Shop.SIULMET, cost: [{ price: 1, currency: Currency.BICOLOUR_GEMSTONE }] },
			],
		},
		used: [],
	},
	[Item.SELECT_SQUID_INK]: {
		name: Item.SELECT_SQUID_INK, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {shops: [{ shop: Shop.QUINNANA, cost: [{ price: 60, currency: Currency.WHITE_CRAFTERS_SCRIP }] }]},
		used: [],
	},
	[Item.NORTHERN_SEA_SALT]: {
		name: Item.NORTHERN_SEA_SALT, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.NORTHERN_SEA_SALT, qtyMade: 3, crafter: Job.CUL, level: 81, lodestoneID: "",
					itemsRequired: [
						{ item: Item.SHARLAYAN_ROCK_SALT, qty: 6 },
						{ item: Item.FIRE_CRYSTAL, qty: 8 },
					],
				},
			],
		},
		used: [],
	},
	[Item.UPLAND_WHEAT_FLOUR]: {
		name: Item.UPLAND_WHEAT_FLOUR, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.UPLAND_WHEAT_FLOUR, qtyMade: 3, crafter: Job.CUL, level: 74, lodestoneID: "",
					itemsRequired: [
						{ item: Item.UPLAND_WHEAT, qty: 5 },
						{ item: Item.FIRE_CRYSTAL, qty: 7 },
					],
				},
			],
		},
		used: [],
	},
	[Item.SHARLAYAN_ROCK_SALT]: {
		name: Item.SHARLAYAN_ROCK_SALT, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			gathering: [
				{ found: { location: MapLocation.LABYRINTHOS, x: 30, y: 9 }, method: GatherMethod.MINING },
			],
		},
		used: [],
	},
	[Item.UPLAND_WHEAT]: {
		name: Item.UPLAND_WHEAT, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			gathering: [
				{ found: { location: MapLocation.SHADOW_FAULT, x: 16, y: 36 }, method: GatherMethod.HARVESTING },
			],
		},
		used: [],
	},
	[Item.IRONWOOD_LUMBER]: {
		name: Item.IRONWOOD_LUMBER, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.IRONWOOD_LUMBER, qtyMade: 1, crafter: Job.CRP, level: 86, lodestoneID: "",
					itemsRequired: [
						{ item: Item.IRONWOOD_LOG, qty: 5 },
						{ item: Item.WIND_CRYSTAL, qty: 8 },
					],
				},
			],
		},
		used: [],
	},
	[Item.PHRYGIAN_GOLD_INGOT]: {
		name: Item.PHRYGIAN_GOLD_INGOT, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.PHRYGIAN_GOLD_INGOT, qtyMade: 1, crafter: Job.GSM, level: 84, lodestoneID: "",
					itemsRequired: [
						{ item: Item.PHRYGIAN_GOLD_ORE, qty: 5 },
						{ item: Item.ZINC_ORE, qty: 1 },
						{ item: Item.WIND_CRYSTAL, qty: 8 },
					],
				},
			],
		},
		used: [],
	},
	[Item.ZINC_ORE]: {
		name: Item.ZINC_ORE, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			gathering: [
				{ found: { location: MapLocation.CENTRAL_THANALAN, x: 19, y: 21 }, method: GatherMethod.MINING },
			],
			shops: [
				{ shop: Shop.AISTAN, cost: [{ price: 20, currency: Currency.GIL }] },
				{ shop: Shop.MATERIAL_SUPPLIER, cost: [{ price: 20, currency: Currency.GIL }] },
				{ shop: Shop.ALLAGAN_RESUPPLY_NODE, cost: [{ price: 20, currency: Currency.GIL }] },
			],
		},
		used: [],
	},
	[Item.APKALLU_DOWN]: {
		name: Item.APKALLU_DOWN, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			enemies: [Enemy.APKALLU],
			markets: { [Market.UNIVERSALIS]: -1 },
		},
		used: [],
	},
	[Item.SCARLET_MOKO_CLOTH]: {
		name: Item.SCARLET_MOKO_CLOTH, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.SCARLET_MOKO_CLOTH, qtyMade: 1, crafter: Job.WVR, level: 86, lodestoneID: "",
					itemsRequired: [
						{ item: Item.SCARLET_MOKO_GRASS, qty: 5 },
						{ item: Item.LIGHTNING_CRYSTAL, qty: 8 },
					],
				},
			],
		},
		used: [],
	},
	[Item.SAIGA_LEATHER]: {
		name: Item.SAIGA_LEATHER, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.SAIGA_LEATHER, qtyMade: 1, crafter: Job.LTW, level: 84, lodestoneID: "",
					itemsRequired: [
						{ item: Item.SAIGA_HIDE, qty: 4 },
						{ item: Item.EBLAN_ALUMEN, qty: 1 },
						{ item: Item.EARTH_CRYSTAL, qty: 8 },
					],
				},
			],
		},
		used: [],
	},
	[Item.SCARLET_MOKO_GRASS]: {
		name: Item.SCARLET_MOKO_GRASS, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			gathering: [
				{ found: { location: MapLocation.ELPIS, x: 21, y: 24 }, method: GatherMethod.HARVESTING },
			],
		},
		used: [],
	},
	[Item.SAIGA_HIDE]: {
		name: Item.SAIGA_HIDE, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			enemies: [Enemy.SAIGA],
			shops: [
				{ shop: Shop.GADFRID, cost: [{ price: 2, currency: Currency.BICOLOUR_GEMSTONE }] },
				{ shop: Shop.SAJAREEN, cost: [{ price: 2, currency: Currency.BICOLOUR_GEMSTONE }] },
				{ shop: Shop.ZAWAWA, cost: [{ price: 2, currency: Currency.BICOLOUR_GEMSTONE }] },
			],
		},
		used: [],
	},
	[Item.PERILLA_OIL]: {
		name: Item.PERILLA_OIL, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.PERILLA_OIL, qtyMade: 3, crafter: Job.CUL, level: 82, lodestoneID: "",
					itemsRequired: [
						{ item: Item.THAVNAIRIAN_PERILLA_LEAF, qty: 6 },
						{ item: Item.FIRE_CRYSTAL, qty: 8 },
					],
				},
			],
		},
		used: [],
	},
	[Item.GARLEAN_CHEESE]: {
		name: Item.GARLEAN_CHEESE, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.GARLEAN_CHEESE, qtyMade: 3, crafter: Job.CUL, level: 84, lodestoneID: "",
					itemsRequired: [
						{ item: Item.OVIBOS_MILK, qty: 5 },
						{ item: Item.FIRE_CRYSTAL, qty: 8 },
					],
				},
			],
		},
		used: [],
	},
	[Item.VERMICELLI]: {
		name: Item.VERMICELLI, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			recipes: [
				{
					itemMade: Item.VERMICELLI, qtyMade: 3, crafter: Job.CUL, level: 56, lodestoneID: "",
					itemsRequired: [
						{ item: Item.HIGHLAND_FLOUR, qty: 3 },
						{ item: Item.DRAVANIAN_MISTLETOE, qty: 1 },
						{ item: Item.ABALATHIAN_ROCK_SALT, qty: 1 },
						{ item: Item.FIRE_SHARD, qty: 8 },
					],
				},
			],
		},
		used: [],
	},
	[Item.THAVNAIRIAN_PERILLA_LEAF]: {
		name: Item.THAVNAIRIAN_PERILLA_LEAF, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			gathering: [
				{ found: { location: MapLocation.THAVNAIR, x: 22, y: 10 }, method: GatherMethod.HARVESTING },
			],
		},
		used: [],
	},
	[Item.OVIBOS_MILK]: {
		name: Item.OVIBOS_MILK, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			enemies: [Enemy.OVIBOS],
			shops: [
				{ shop: Shop.GADFRID, cost: [{ price: 2, currency: Currency.BICOLOUR_GEMSTONE }] },
				{ shop: Shop.SAJAREEN, cost: [{ price: 2, currency: Currency.BICOLOUR_GEMSTONE }] },
				{ shop: Shop.ZAWAWA, cost: [{ price: 2, currency: Currency.BICOLOUR_GEMSTONE }] },
			],
		},
		used: [],
	},
	[Item.ABALATHIAN_ROCK_SALT]: {
		name: Item.ABALATHIAN_ROCK_SALT, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			gathering: [
				{ found: { location: MapLocation.SEA_OF_CLOUDS, x: 8, y: 8 }, method: GatherMethod.QUARRYING, timedNode: true, times: ["10AM/10PM"] },
			],
		},
		used: [],
	},
	[Item.SELECT_BAMBOO_STICK]: {
		name: Item.SELECT_BAMBOO_STICK, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {shops: [{ shop: Shop.QUINNANA, cost: [{ price: 70, currency: Currency.WHITE_CRAFTERS_SCRIP }] }]},
		used: [],
	},
	[Item.LIMESTONE]: {
		name: Item.LIMESTONE, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			gathering: [
				{ found: { location: MapLocation.WESTERN_LA_NOSCEA, x: 27, y: 25 }, method: GatherMethod.QUARRYING },
			],
		},
		used: [],
	},
	[Item.SELECT_DURALUMINUM_INGOT]: {
		name: Item.SELECT_DURALUMINUM_INGOT, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {shops: [{ shop: Shop.QUINNANA, cost: [{ price: 70, currency: Currency.WHITE_CRAFTERS_SCRIP }] }]},
		used: [],
	},
	[Item.SELECT_BRASHGOLD_PLATE]: {
		name: Item.SELECT_BRASHGOLD_PLATE, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {shops: [{ shop: Shop.QUINNANA, cost: [{ price: 70, currency: Currency.WHITE_CRAFTERS_SCRIP }] }]},
		used: [],
	},
	[Item.TRUEGOLD_ORE]: {
		name: Item.TRUEGOLD_ORE, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			gathering: [
				{ found: { location: MapLocation.BOOKMANS_SHELVES, x: 7, y: 19 }, method: GatherMethod.MINING },
			],
		},
		used: [],
	},
	[Item.HORSE_CHESTNUT_LOG]: {
		name: Item.HORSE_CHESTNUT_LOG, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			gathering: [
				{ found: { location: MapLocation.LABYRINTHOS, x: 14, y: 7 }, method: GatherMethod.LOGGING },
			],
		},
		used: [],
	},
	[Item.SELECT_MARBLE]: {
		name: Item.SELECT_MARBLE, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {shops: [{ shop: Shop.QUINNANA, cost: [{ price: 70, currency: Currency.WHITE_CRAFTERS_SCRIP }] }]},
		used: [],
	},
	[Item.SELECT_CHALICOTHERIUM_LEATHER]: {
		name: Item.SELECT_CHALICOTHERIUM_LEATHER, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {shops: [{ shop: Shop.QUINNANA, cost: [{ price: 70, currency: Currency.WHITE_CRAFTERS_SCRIP }] }]},
		used: [],
	},
	[Item.SELECT_DUSKCOURT_CLOTH]: {
		name: Item.SELECT_DUSKCOURT_CLOTH, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {shops: [{ shop: Shop.QUINNANA, cost: [{ price: 70, currency: Currency.WHITE_CRAFTERS_SCRIP }] }]},
		used: [],
	},
	[Item.RUBY_COTTON_BOLL]: {
		name: Item.RUBY_COTTON_BOLL, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			gathering: [
				{ found: { location: MapLocation.RUBY_SEA, x: 28, y: 22 }, method: GatherMethod.HARVESTING },
			],
		},
		used: [],
	},
	[Item.SELECT_CUDWEED]: {
		name: Item.SELECT_CUDWEED, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {shops: [{ shop: Shop.QUINNANA, cost: [{ price: 70, currency: Currency.WHITE_CRAFTERS_SCRIP }] }]},
		used: [],
	},
	[Item.MOUSSE_FLESH]: {
		name: Item.MOUSSE_FLESH, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			enemies: [Enemy.MOUSSE],
			shops: [
				{ shop: Shop.GADFRID, cost: [{ price: 2, currency: Currency.BICOLOUR_GEMSTONE }] },
				{ shop: Shop.SAJAREEN, cost: [{ price: 2, currency: Currency.BICOLOUR_GEMSTONE }] },
				{ shop: Shop.TRADINGWAY, cost: [{ price: 2, currency: Currency.BICOLOUR_GEMSTONE }] },
			],
		},
		used: [],
	},
	[Item.SELECT_BLUE_CRAB]: {
		name: Item.SELECT_BLUE_CRAB, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {shops: [{ shop: Shop.QUINNANA, cost: [{ price: 70, currency: Currency.WHITE_CRAFTERS_SCRIP }] }]},
		used: [],
	},
	[Item.EGG_OF_ELPIS]: {
		name: Item.EGG_OF_ELPIS, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			enemies: [Enemy.BIRD_OF_ELPIS],
			shops: [
				{ shop: Shop.GADFRID, cost: [{ price: 2, currency: Currency.BICOLOUR_GEMSTONE }] },
				{ shop: Shop.SAJAREEN, cost: [{ price: 2, currency: Currency.BICOLOUR_GEMSTONE }] },
				{ shop: Shop.AISARA, cost: [{ price: 2, currency: Currency.BICOLOUR_GEMSTONE }] },
			],
		},
		used: [],
	},
	[Item.AMBROSIAL_WATER]: {
		name: Item.AMBROSIAL_WATER, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			gathering: [
				{ found: { location: MapLocation.ELPIS, x: 14, y: 18 }, method: GatherMethod.QUARRYING },
			],
		},
		used: [],
	},
	[Item.GRANITE]: {
		name: Item.GRANITE, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			gathering: [
				{ found: { location: MapLocation.CENTRAL_SHROUD, x: 14, y: 21 }, method: GatherMethod.MINING },
			],
		},
		used: [],
	},
	[Item.WILDFOWN_FEATHER]: {
		name: Item.WILDFOWN_FEATHER, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			gathering: [
				{ found: { location: MapLocation.LOWER_PATHS, x: 17, y: 29 }, method: GatherMethod.HARVESTING },
			],
			shops: [
				{ shop: Shop.SYLPHIC_VENDOR, cost: [{ price: 259, currency: Currency.GIL }] },
				{ shop: Shop.SYLPHIC_VENDOR, cost: [{ price: 1, currency: Currency.SYLPHIC_GOLDLEAF }] },
			],
		},
		used: [],
	},
	[Item.BERKANAN_SAP]: {
		name: Item.BERKANAN_SAP, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			enemies: [Enemy.BERKANAN],
			shops: [
				{ shop: Shop.FAEZBROES, cost: [{ price: 2, currency: Currency.BICOLOUR_GEMSTONE }] },
				{ shop: Shop.GADFRID, cost: [{ price: 2, currency: Currency.BICOLOUR_GEMSTONE }] },
				{ shop: Shop.SAJAREEN, cost: [{ price: 2, currency: Currency.BICOLOUR_GEMSTONE }] },
			],
		},
		used: [],
	},
	[Item.AMRA]: {
		name: Item.AMRA, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			enemies: [Enemy.VAJRALANGULA],
			shops: [
				{ shop: Shop.GADFRID, cost: [{ price: 2, currency: Currency.BICOLOUR_GEMSTONE }] },
				{ shop: Shop.MAHVEYDAH, cost: [{ price: 2, currency: Currency.BICOLOUR_GEMSTONE }] },
				{ shop: Shop.SAJAREEN, cost: [{ price: 2, currency: Currency.BICOLOUR_GEMSTONE }] },
			],
		},
		used: [],
	},
	[Item.BLOOD_TOMATO]: {
		name: Item.BLOOD_TOMATO, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			gathering: [
				{ found: { location: MapLocation.GOOD_JENANNAS_GRACE, x: 11, y: 24 }, method: GatherMethod.HARVESTING },
			],
		},
		used: [],
	},
	[Item.PEARL_GINGER]: {
		name: Item.PEARL_GINGER, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			gathering: [
				{ found: { location: MapLocation.EASTERN_THANALAN, x: 15, y: 22 }, method: GatherMethod.HARVESTING },
			],
		},
		used: [],
	},
	[Item.CUSTOM_ICE_CRYSTAL]: {
		name: Item.CUSTOM_ICE_CRYSTAL, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			gathering: [
				{ found: { location: MapLocation.LAKELAND, x: 17, y: 15 }, method: GatherMethod.MINING },
			],
		},
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SPLENDOROUS, RelicPart.TOOL_CHORA_ZOIS_CRYSTALLINE, RelicStep.CUSTOMISED_COMPONENT, RelicTask.TASK_2], jobs: [Job.MIN], qtyPerJob: 210 },
		],
	},
	[Item.CUSTOM_WIND_CRYSTAL]: {
		name: Item.CUSTOM_WIND_CRYSTAL, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			gathering: [
				{ found: { location: MapLocation.MITHAI_GLORIANDA, x: 26, y: 19 }, method: GatherMethod.LOGGING },
			],
		},
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SPLENDOROUS, RelicPart.TOOL_CHORA_ZOIS_CRYSTALLINE, RelicStep.CUSTOMISED_COMPONENT, RelicTask.TASK_2], jobs: [Job.BTN], qtyPerJob: 210 },
		],
	},
	[Item.BRILLIANT_LIGHTNING_CLUSTER]: {
		name: Item.BRILLIANT_LIGHTNING_CLUSTER, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			gathering: [
				{ found: { location: MapLocation.BRIGHT_CLIFF, x: 33, y: 29 }, method: GatherMethod.MINING },
			],
		},
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SPLENDOROUS, RelicPart.TOOL_BRILLIANT, RelicStep.BRILLIANT_COMPONENT, RelicTask.TASK_2], jobs: [Job.MIN], qtyPerJob: 210 },
		],
	},
	[Item.BRILLIANT_EARTH_CLUSTER]: {
		name: Item.BRILLIANT_EARTH_CLUSTER, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			gathering: [
				{ found: { location: MapLocation.CENTRAL_HILLS_OF_AMBER, x: 18, y: 19 }, method: GatherMethod.LOGGING },
			],
		},
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SPLENDOROUS, RelicPart.TOOL_BRILLIANT, RelicStep.BRILLIANT_COMPONENT, RelicTask.TASK_2], jobs: [Job.BTN], qtyPerJob: 210 },
		],
	},
	[Item.INSPIRATIONAL_WIND_CLUSTER]: {
		name: Item.INSPIRATIONAL_WIND_CLUSTER, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			gathering: [
				{ found: { location: MapLocation.GOOD_JENANNAS_GRACE, x: 14, y: 10 }, method: GatherMethod.MINING },
			],
		},
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SPLENDOROUS, RelicPart.TOOL_VRANDTIC_VISIONARYS, RelicStep.INSPIRATIONAL_COMPONENT, RelicTask.TASK_2], jobs: [Job.MIN], qtyPerJob: 220 },
		],
	},
	[Item.INSPIRATIONAL_FIRE_CLUSTER]: {
		name: Item.INSPIRATIONAL_FIRE_CLUSTER, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			gathering: [
				{ found: { location: MapLocation.RAKTIKA_GREATWOOD, x: 15, y: 35 }, method: GatherMethod.LOGGING },
			],
		},
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SPLENDOROUS, RelicPart.TOOL_VRANDTIC_VISIONARYS, RelicStep.INSPIRATIONAL_COMPONENT, RelicTask.TASK_2], jobs: [Job.BTN], qtyPerJob: 220 },
		],
	},
	[Item.NIGHTFORGED_ICE_CLUSTER]: {
		name: Item.NIGHTFORGED_ICE_CLUSTER, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			gathering: [
				{ found: { location: MapLocation.TEMPEST, x: 34, y: 22 }, method: GatherMethod.MINING },
			],
		},
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SPLENDOROUS, RelicPart.TOOL_LODESTAR, RelicStep.NIGHTFORGED_COMPONENT, RelicTask.TASK_2], jobs: [Job.MIN], qtyPerJob: 220 },
		],
	},
	[Item.NIGHTFORGED_WATER_CLUSTER]: {
		name: Item.NIGHTFORGED_WATER_CLUSTER, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			gathering: [
				{ found: { location: MapLocation.TEMPEST, x: 37, y: 13 }, method: GatherMethod.LOGGING },
			],
		},
		used: [
			{ relic: [RelicType.TOOL, RelicGroup.TOOL_SPLENDOROUS, RelicPart.TOOL_LODESTAR, RelicStep.NIGHTFORGED_COMPONENT, RelicTask.TASK_2], jobs: [Job.BTN], qtyPerJob: 220 },
		],
	},
	[Item.COSMIC_CRYSTALLITE]: {
		name: Item.COSMIC_CRYSTALLITE, type: EorzeaDataType.ITEM, lodestoneID: "",
		obtained: {
			shops: [
				{ shop: Shop.JUBRUNNAH, cost: [{ price: 500, currency: Currency.POETICS }] },
			],
		},
		used: [
			{ relic: [RelicType.WEAPON, RelicGroup.WEAPON_MANDERVILLE, RelicPart.WEAPON_MANDERVILLOUS, RelicStep.MANDERVILLOUS, RelicTask.TASK_1], jobs: jobLists.relic[RelicGroup.WEAPON_MANDERVILLE], qtyPerJob: 3 },
		],
	},
};

/* Template Item Entry
	[Item.]: {
		name: Item., type: EorzeaDataType.ITEM, lodestoneID: ,
		obtained: {
			enemies: [Enemy.],
			recipes: [
				{
					itemMade: Item., qtyMade: , crafter: Job., level: , lodestoneID: "",
					itemsRequired: [
						{ item: Item., qty:  },
					],
				},
			],
			markets: { [Market.UNIVERSALIS]:  },
			extraction: true,
			gathering: [
				{ found: { location: MapLocation., x: , y:  }, method: GatherMethod., collectable: , timedNode: , times: [] },
			],
			fishingHoles: [
				{ fish: Fish., hole: Fishing., bait: , time: , weather: , mooch: { fish: Fish., bait: }[][]; }
			],
			shops: [
				{ shop: Shop., cost: [{ price: , currency: Currency. }] }
			],
			locations: [
				{ found: { location: MapLocation., x: , y:  }
			],
			duties: {
				trials: [Trial.],
				dungeons: [Dungeon.],
				raids: [Raid.],
				fates: [Fate.],
				leves: [Leve.],
			},
			quests: [Quest.],
			other: [{ element: , text: }]
		},
		used: [
			{ relic: [RelicType., RelicGroup., RelicPart., RelicStep., RelicTask.] jobs: jobLists.relic., qtyPerJob: }
		]
	},
*/
