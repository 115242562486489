/* eslint-disable max-len */
//#region Imports
import { jobLists } from "character";
import FormComponents from "components/forms/FormComponents";
import NoCharacter from "components/no-character/NoCharacter";
import { items } from "data-files/items";
import { InputType, Item, NPC, RelicGroup, RelicPart, RelicStep, RelicTask, RelicType, View } from "enums";
import manager from "managers/app";
import { Component } from "react";
import { connect } from "react-redux";
import t from "translations/translator";
import { AppState, Requirement, ViewRelicInfo, ViewWeaponZodiacZenithProps } from "types";
import ContentHeader from "views/page-parts/content/content-header/ContentHeader";
import DetailsSection from "views/page-parts/content/details-section/DetailsSection";
import Obtained from "views/page-parts/content/obtained/Obtained";
import OverviewTable from "views/page-parts/content/overview-table/OverviewTable";
import "./WeaponZodiacZenith.css";
//#endregion Imports

const mapStateToProps = (state:AppState) => {
	return {
		lang: state.userInfo.options.lang,
		gameLang: state.userInfo.options.gameLang,
		userInfo: state.userInfo,
		hideCompletedSteps: state.userInfo.options.hideCompletedSteps,
		hideCompletedTasks: state.userInfo.options.hideCompletedTasks,
		showJob: state.userInfo.options.job,
	};
};

class WeaponZodiacZenith extends Component<ViewWeaponZodiacZenithProps> {
	private requirements:Requirement[] = [];
	private importantNPCs:NPC[] = [];
	private relicInfo:ViewRelicInfo = {
		jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC],
		type: RelicType.WEAPON,
		relic: RelicGroup.WEAPON_ZODIAC,
		part: RelicPart.WEAPON_ZENITH,
		steps: [RelicStep.MIST],
		stepPlaceholders: { [RelicStep.MIST]: { ITEM: Item.THAVNAIRIAN_MIST } },
		next: {
			part: RelicPart.WEAPON_ATMA,
			view: View.RELIC_WEAPONS_ZODIAC_ATMA,
		},
		previous: {
			part: RelicPart.WEAPON_BASE_WEAPON,
			view: View.RELIC_WEAPONS_ZODIAC_BASE_WEAPON,
		},
	};

	constructor(props:ViewWeaponZodiacZenithProps){
		super(props);
		this.requirements = this.getRequirements();
		this.importantNPCs = this.getImportantNPCs();
	}

	render(){
		const character = manager.data.getActiveCharacter();
		if(character === null){ return <NoCharacter />; }

		const completeSteps = manager.content.getCompleteSteps(character.progress, this.relicInfo);
		const stepInventory = manager.data.getInventoryStatus({
			item: manager.relics.getTaskItem(RelicStep.MIST, RelicTask.TASK_1),
			relic: [this.relicInfo.type, this.relicInfo.relic, this.relicInfo.part, RelicStep.MIST, RelicTask.TASK_1],
		});

		return (
			<div id="weapon-zodiac-zenith" className="relic">
				<ContentHeader requirements={this.requirements} importantNPCs={this.importantNPCs} relicInfo={this.relicInfo}>
					<p>{t.t("The %PART% upgrade follows on from %PREVIOUSPART%", { PART: this.relicInfo.part, PREVIOUSPART: this.relicInfo.previous ? this.relicInfo.previous.part : "Unknown"})}</p>
					<p>{t.t("Take the below items to the Furnace in Hyrstmill, North Shroud (x30, y20), to upgrade your Base Relic")}</p>
				</ContentHeader>
				<div id="relic-checklist">
					<OverviewTable character={character} completeSteps={completeSteps} relicInfo={this.relicInfo} />
				</div>
				<div id="relic-details">
					<h1>{t.t("Details")}</h1>
					{this.props.hideCompletedSteps && completeSteps.allComplete ? t.t("All Steps Complete") : null }
					<DetailsSection title={RelicStep.MIST} placeholders={this.relicInfo.stepPlaceholders[RelicStep.MIST]} id={RelicStep.MIST} stepComplete={completeSteps[RelicStep.MIST]}>
						<div className="table-wrapper">
							<table className="table">
								<thead><tr><th>{t.t("Obtained")}</th><th>{t.t("Item")}</th><th>{t.t("Quantity / Job")}</th><th>{t.t("Total")}</th><th>{t.t("Used")}</th><th>{t.t("Remaining")}</th><th>{t.t("Inventory")}</th><th>{t.t("To Obtain")}</th></tr></thead>
								<tbody>
									{this.props.hideCompletedTasks && stepInventory.toObtain === 0
										? null
										: <tr className="odd-row">
											<td><Obtained obtained={items[Item.THAVNAIRIAN_MIST].obtained} qtyNeeded={stepInventory.total} /></td>
											<td>{t.t(items[Item.THAVNAIRIAN_MIST].name)}</td>
											<td>{stepInventory.qtyPerJob.toLocaleString()}</td>
											<td>{stepInventory.total.toLocaleString()}</td>
											<td>{stepInventory.used.toLocaleString()}</td>
											<td>{stepInventory.remaining.toLocaleString()}</td>
											<td><FormComponents.TextInput label={{ hide: true }} input={{ type: InputType.NUMBER, value: stepInventory.inventory, dataAttributes: { item: Item.THAVNAIRIAN_MIST }, events: { onChange: manager.content.inventoryUpdated }}} /></td>
											<td>{stepInventory.toObtain.toLocaleString()}</td>
										</tr>
									}
									{
										character.progress.isComplete(this.relicInfo.type, this.relicInfo.relic, this.relicInfo.part, RelicStep.MIST)
											? <tr className="all-complete"><td colSpan={8}><p>{t.t("All Complete")}</p></td></tr>
											: null
									}
								</tbody>
							</table>
						</div>
					</DetailsSection>
				</div>
			</div>
		);
	}

	componentDidMount(){
		manager.view.changeComplete();
	}

	private getRequirements():Requirement[]{
		if(!this.relicInfo || this.relicInfo.previous === null){ return []; }
		return [
			{ type: "previous part", part: this.relicInfo.previous.part, partView: this.relicInfo.previous.view },
		];
	}

	private getImportantNPCs():NPC[]{
		return [];
	}
}
export default connect(mapStateToProps)(WeaponZodiacZenith);
