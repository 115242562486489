//#region Imports
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Breakpoint, RelicGroup, RelicPart, RelicType, View } from "enums";
import manager from "managers/app";
import { actions } from "managers/state";
import { Component, SyntheticEvent } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import t from "translations/translator";
import { AppState, ViewMenuItemsProps } from "types";
import utils from "utils";
import SubMenu from "./SubMenu";
//#endregion Imports

const mapStateToProps = (state:AppState) => {
	return {
		lang: state.userInfo.options.lang,
		gameLang: state.userInfo.options.gameLang,
		userInfo: state.userInfo,
		view: state.view,
		breakpoint: state.breakpoint,
		ignorePhyseosPart: state.userInfo.options.progress.ignorePhyseosPart,
		showJob: state.userInfo.options.job,
	};
};

const mapDispatchToProps = (dispatch:Dispatch) => {
	return { setView: (view:View) => { dispatch(actions.setView(view)); } };
};

class MenuItem extends Component<ViewMenuItemsProps> {
	render(){
		const character = manager.data.getActiveCharacter();
		const item_icon = this.props.options.submenu ? <FontAwesomeIcon icon={faChevronRight} /> : null;
		const isSelected = this.props.options.menuItemView === this.props.view;
		const menuView = typeof this.props.options.menuItemView === "string" ? this.props.options.menuItemView : null;
		const headers = this.props.options.headers;
		const thisHeader = headers[headers.length - 1];

		let menu_header = t.t(thisHeader);

		if(menu_header === t.t(RelicPart.WEAPON_PHYSEOS) && this.props.ignorePhyseosPart){
			menu_header += ` (${t.t("Ignored")})`;
		}

		let isComplete = false;
		if(utils.guards.isEnumValue(RelicPart, thisHeader)){
			const type = headers[0] as RelicType;
			const relic = headers[1] as RelicGroup;
			isComplete = character ? character.progress.isComplete(type, relic, thisHeader, this.props.showJob ? this.props.showJob : undefined) : false; // eslint-disable-line no-undefined
		}else if(utils.guards.isEnumValue(RelicGroup, thisHeader)){
			const type = headers[0] as RelicType;
			isComplete = character ? character.progress.isComplete(type, thisHeader, this.props.showJob ? this.props.showJob : undefined) : false; // eslint-disable-line no-undefined
		}else if(thisHeader === "Overall"){
			const type = headers[0] as RelicType;
			const relic = headers[1] as RelicGroup;
			isComplete = character ? character.progress.isComplete(type, relic, this.props.showJob ? this.props.showJob : undefined) : false; // eslint-disable-line no-undefined
		}

		const menuItemClasses = ["menu-item"];
		menuItemClasses.push(this.props.options.background === "primary" ? "bg-primary" : "bg-secondary");
		if(isSelected){ menuItemClasses.push("selected"); }
		if(isComplete){ menuItemClasses.push("complete"); }

		return (
			<div className={menuItemClasses.join(" ")}>
				<h3 onClick={this.menuItemClick} data-view={menuView}><span>{menu_header}</span>{item_icon}</h3>
				{this.props.options.submenu ? <SubMenu options={this.props.options} /> : null}
			</div>
		);
	}

	menuItemClick = (evt:SyntheticEvent) => {
		const ele = evt.currentTarget;
		const thisMenuItem = ele.closest(".menu-item");

		if(!thisMenuItem || thisMenuItem.classList.contains("selected")){ return; }

		const submenu = ele.nextSibling as HTMLElement;
		const isOpen = thisMenuItem.classList.contains("open");

		const parentSubMenu = thisMenuItem.parentElement;
		const isPartOfSubMenu = parentSubMenu ? parentSubMenu.classList.contains("sub-menu") : false;

		if(submenu && submenu.classList.contains("sub-menu")){
			const openMenuItems = document.querySelectorAll("#menu .open");
			openMenuItems.forEach((e) => { e.classList.remove("open"); });

			if(isOpen){
				if(isPartOfSubMenu && parentSubMenu && parentSubMenu.parentElement){
					parentSubMenu.classList.add("open");
					parentSubMenu.parentElement.classList.add("open");
				}
			}else{
				submenu.classList.add("open");
				thisMenuItem.classList.add("open");
				if(isPartOfSubMenu && parentSubMenu && parentSubMenu.parentElement){
					parentSubMenu.classList.add("open");
					parentSubMenu.parentElement.classList.add("open");
				}
			}
		}else{
			const new_view = ele.getAttribute("data-view");
			if(!new_view){ return; }
			manager.view.change(new_view as View);
			if(this.props.breakpoint === Breakpoint.MOBILE){
				const menu = document.getElementById("menu");
				if(menu){ menu.classList.add("hide"); }
			}
		}
	}
}
export default connect(mapStateToProps, mapDispatchToProps)(MenuItem);
