/* eslint-disable max-len */
//#region Imports
import { jobLists } from "character";
import FormComponents from "components/forms/FormComponents";
import Link from "components/link/Link";
import NoCharacter from "components/no-character/NoCharacter";
import { items } from "data-files/items";
import { quests } from "data-files/quests";
import { EorzeaDataType, InputType, Item, LinkType, NPC, Quest, RelicGroup, RelicPart, RelicStep, RelicTask, RelicType, View } from "enums";
import manager from "managers/app";
import { Component } from "react";
import { connect } from "react-redux";
import t from "translations/translator";
import { AppState, Requirement, ViewRelicInfo, ViewWeaponAnimaSharpenedProps } from "types";
import ContentHeader from "views/page-parts/content/content-header/ContentHeader";
import DetailsSection from "views/page-parts/content/details-section/DetailsSection";
import Obtained from "views/page-parts/content/obtained/Obtained";
import OverviewTable from "views/page-parts/content/overview-table/OverviewTable";
import "./WeaponAnimaSharpened.css";
//#endregion Imports

const mapStateToProps = (state:AppState) => {
	return {
		lang: state.userInfo.options.lang,
		gameLang: state.userInfo.options.gameLang,
		userInfo: state.userInfo,
		hideCompletedSteps: state.userInfo.options.hideCompletedSteps,
		hideCompletedTasks: state.userInfo.options.hideCompletedTasks,
		showJob: state.userInfo.options.job,
	};
};

class WeaponAnimaSharpened extends Component<ViewWeaponAnimaSharpenedProps> {
	private requirements:Requirement[] = [];
	private importantNPCs:NPC[] = [];
	private relicInfo:ViewRelicInfo = {
		jobs: jobLists.relic[RelicGroup.WEAPON_ANIMA],
		type: RelicType.WEAPON,
		relic: RelicGroup.WEAPON_ANIMA,
		part: RelicPart.WEAPON_SHARPENED,
		steps: [RelicStep.CLUSTERS],
		stepPlaceholders: { [RelicStep.CLUSTERS]: { ITEM: Item.SINGING_CLUSTER } },
		next: {
			part: RelicPart.WEAPON_COMPLETE,
			view: View.RELIC_WEAPONS_ANIMA_COMPLETE,
		},
		previous: {
			part: RelicPart.WEAPON_RECONDITIONED,
			view: View.RELIC_WEAPONS_ANIMA_RECONDITIONED,
		},
	};

	constructor(props:ViewWeaponAnimaSharpenedProps){
		super(props);
		this.requirements = this.getRequirements();
		this.importantNPCs = this.getImportantNPCs();
	}

	render(){
		const character = manager.data.getActiveCharacter();
		if(character === null){ return <NoCharacter />; }

		const completeSteps = manager.content.getCompleteSteps(character.progress, this.relicInfo);
		const stepInventory = manager.data.getInventoryStatus({
			item: manager.relics.getTaskItem(RelicStep.CLUSTERS, RelicTask.TASK_1),
			relic: [this.relicInfo.type, this.relicInfo.relic, this.relicInfo.part, RelicStep.CLUSTERS, RelicTask.TASK_1],
		});

		return (
			<div id="weapon-anima-sharpened" className="relic">
				<ContentHeader requirements={this.requirements} importantNPCs={this.importantNPCs} relicInfo={this.relicInfo}>
					<p>{t.t("The %PART% upgrade follows on from %PREVIOUSPART%", { PART: this.relicInfo.part, PREVIOUSPART: this.relicInfo.previous ? this.relicInfo.previous.part : "Unknown"})}</p>
					<p>{t.t("Complete")}<Link type={LinkType.LODESTONE} lodestoneType={EorzeaDataType.BLUEQUEST} text={Quest.FUTURE_PROOF} lodestoneID={quests[Quest.FUTURE_PROOF].lodestoneID} /></p>
				</ContentHeader>
				<div id="relic-checklist">
					<OverviewTable character={character} completeSteps={completeSteps} relicInfo={this.relicInfo} />
				</div>
				<div id="relic-details">
					<h1>{t.t("Details")}</h1>
					{this.props.hideCompletedSteps && completeSteps.allComplete ? t.t("All Steps Complete") : null }
					<DetailsSection title={RelicStep.CLUSTERS} placeholders={this.relicInfo.stepPlaceholders[RelicStep.CLUSTERS]} id={RelicStep.CLUSTERS} stepComplete={completeSteps[RelicStep.CLUSTERS]}>
						<div className="table-wrapper">
							<table className="table">
								<thead><tr><th>{t.t("Obtained")}</th><th>{t.t("Item")}</th><th>{t.t("Quantity / Job")}</th><th>{t.t("Total")}</th><th>{t.t("Used")}</th><th>{t.t("Remaining")}</th><th>{t.t("Inventory")}</th><th>{t.t("To Obtain")}</th></tr></thead>
								<tbody>
									{this.props.hideCompletedTasks && stepInventory.toObtain === 0
										? null
										: <tr className="odd-row">
											<td><Obtained obtained={items[Item.SINGING_CLUSTER].obtained} qtyNeeded={stepInventory.total} /></td>
											<td>{t.t(items[Item.SINGING_CLUSTER].name)}</td>
											<td>{stepInventory.qtyPerJob.toLocaleString()}</td>
											<td>{stepInventory.total.toLocaleString()}</td>
											<td>{stepInventory.used.toLocaleString()}</td>
											<td>{stepInventory.remaining.toLocaleString()}</td>
											<td><FormComponents.TextInput label={{ hide: true }} input={{ type: InputType.NUMBER, value: stepInventory.inventory, dataAttributes: { item: Item.SINGING_CLUSTER }, events: { onChange: manager.content.inventoryUpdated }}} /></td>
											<td>{stepInventory.toObtain.toLocaleString()}</td>
										</tr>
									}
									{
										character.progress.isComplete(this.relicInfo.type, this.relicInfo.relic, this.relicInfo.part, RelicStep.CLUSTERS)
											? <tr className="all-complete"><td colSpan={8}><p>{t.t("All Complete")}</p></td></tr>
											: null
									}
								</tbody>
							</table>
						</div>
					</DetailsSection>
				</div>
			</div>
		);
	}

	componentDidMount(){
		manager.view.changeComplete();
	}

	private getRequirements():Requirement[]{
		if(!this.relicInfo || this.relicInfo.previous === null){ return []; }
		return [
			{ type: "previous part", part: this.relicInfo.previous.part, partView: this.relicInfo.previous.view },
		];
	}

	private getImportantNPCs():NPC[]{
		return [NPC.ARDASHIR];
	}
}
export default connect(mapStateToProps)(WeaponAnimaSharpened);
