/* eslint-disable max-len */
//#region Imports
import { jobLists } from "character";
import Link from "components/link/Link";
import NoCharacter from "components/no-character/NoCharacter";
import { quests } from "data-files/quests";
import { shops } from "data-files/shops";
import { EorzeaDataType, LinkType, NPC, Quest, RelicGroup, RelicPart, RelicStep, RelicType, Shop, View } from "enums";
import manager from "managers/app";
import { Component } from "react";
import { connect } from "react-redux";
import t from "translations/translator";
import { AppState, Requirement, ViewRelicInfo, ViewWeaponEurekaAntiquatedProps } from "types";
import ContentHeader from "views/page-parts/content/content-header/ContentHeader";
import DetailsSection from "views/page-parts/content/details-section/DetailsSection";
import OverviewTable from "views/page-parts/content/overview-table/OverviewTable";
import "./WeaponEurekaAntiquated.css";
//#endregion Imports

const mapStateToProps = (state:AppState) => {
	return {
		lang: state.userInfo.options.lang,
		gameLang: state.userInfo.options.gameLang,
		userInfo: state.userInfo,
		hideCompletedSteps: state.userInfo.options.hideCompletedSteps,
		hideCompletedTasks: state.userInfo.options.hideCompletedTasks,
		showJob: state.userInfo.options.job,
	};
};

class WeaponEurekaAntiquated extends Component<ViewWeaponEurekaAntiquatedProps> {
	private requirements:Requirement[] = [];
	private importantNPCs:NPC[] = [];
	private relicInfo:ViewRelicInfo = {
		jobs: jobLists.relic[RelicGroup.WEAPON_EUREKA],
		type: RelicType.WEAPON,
		relic: RelicGroup.WEAPON_EUREKA,
		part: RelicPart.WEAPON_ANTIQUATED,
		steps: [RelicStep.ANTIQUATED],
		stepPlaceholders: {},
		next: {
			part: RelicPart.WEAPON_ANEMOS,
			view: View.RELIC_WEAPONS_EUREKA_ANEMOS,
		},
		previous: null,
	};

	constructor(props:ViewWeaponEurekaAntiquatedProps){
		super(props);
		this.requirements = this.getRequirements();
		this.importantNPCs = this.getImportantNPCs();
	}

	render(){
		const character = manager.data.getActiveCharacter();
		if(character === null){ return <NoCharacter />; }

		const completeSteps = manager.content.getCompleteSteps(character.progress, this.relicInfo);

		return (
			<div id="weapon-eureka-antiquated" className="relic">
				<ContentHeader requirements={this.requirements} importantNPCs={this.importantNPCs} relicInfo={this.relicInfo}>
				</ContentHeader>
				<div id="relic-checklist">
					<OverviewTable character={character} completeSteps={completeSteps} relicInfo={this.relicInfo} />
				</div>
				<div id="relic-details">
					<h1>{t.t("Details")}</h1>
					{this.props.hideCompletedSteps && completeSteps.allComplete ? t.t("All Steps Complete") : null }
					<DetailsSection title={RelicStep.ANTIQUATED} id={RelicStep.ANTIQUATED} stepComplete={completeSteps[RelicStep.ANTIQUATED]}>
						<p>{t.t("Rewarded from level 70 job quest - If you have lost or discarded it, you can purchase a replacement for 1,000 Gil from any of the following")}</p>
						<p><Link type={LinkType.LODESTONE} lodestoneType={EorzeaDataType.SHOP} lodestoneID={shops[Shop.CALAMITY_SALVAGER_LIMSA].lodestoneID} text={Shop.CALAMITY_SALVAGER_LIMSA} /></p>
						<p><Link type={LinkType.LODESTONE} lodestoneType={EorzeaDataType.SHOP} lodestoneID={shops[Shop.CALAMITY_SALVAGER_GRIDANIA].lodestoneID} text={Shop.CALAMITY_SALVAGER_GRIDANIA} /></p>
						<p><Link type={LinkType.LODESTONE} lodestoneType={EorzeaDataType.SHOP} lodestoneID={shops[Shop.CALAMITY_SALVAGER_ULDAH].lodestoneID} text={Shop.CALAMITY_SALVAGER_ULDAH} /></p>
					</DetailsSection>
				</div>
			</div>
		);
	}

	componentDidMount(){
		manager.view.changeComplete();
	}

	private getRequirements():Requirement[]{
		return [
			{ type: quests[Quest.STORMBLOOD].type, text: Quest.STORMBLOOD, lodestoneID: quests[Quest.STORMBLOOD].lodestoneID },
		];
	}

	private getImportantNPCs():NPC[]{
		return [];
	}
}
export default connect(mapStateToProps)(WeaponEurekaAntiquated);
