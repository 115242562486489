//#region Imports
import { Fishing, MapLocation } from "enums";
import { FishingInfo } from "types";
//#endregion Imports

export const fishing:{ [key in Fishing]: FishingInfo } = {
	[Fishing.UNKNOWN]: { name: Fishing.UNKNOWN, found: { location: MapLocation.UNKNOWN, x: 0, y: 0 } },
	[Fishing.DELTA_QUADRANT]: { name: Fishing.DELTA_QUADRANT, found: { location: MapLocation.DELTA_QUADRANT, x: 0, y: 0 } },
	[Fishing.THALIAK_RIVER]: { name: Fishing.THALIAK_RIVER, found: { location: MapLocation.THALIAK_RIVER, x: 0, y: 0 } },
	[Fishing.DRAGONSPIT]: { name: Fishing.DRAGONSPIT, found: { location: MapLocation.DRAGONSPIT, x: 0, y: 0 } },
	[Fishing.PLUM_SPRING]: { name: Fishing.PLUM_SPRING, found: { location: MapLocation.YANXIA, x: 0, y: 0 } },
	[Fishing.ALPHA_QUADRANT]: { name: Fishing.ALPHA_QUADRANT, found: { location: MapLocation.ALPHA_QUADRANT, x: 0, y: 0 } },
	[Fishing.PAPPUS_TREE]: { name: Fishing.PAPPUS_TREE, found: { location: MapLocation.DELTA_QUADRANT, x: 0, y: 0 } },
	[Fishing.BRONZE_LAKE_SHALLOWS]: { name: Fishing.BRONZE_LAKE_SHALLOWS, found: { location: MapLocation.BRONZE_LAKE, x: 33, y: 24 } },
	[Fishing.NORTH_SILVERTEAR]: { name: Fishing.NORTH_SILVERTEAR, found: { location: MapLocation.NORTH_SILVERTEAR, x: 24, y: 12 } },
	[Fishing.HALFSTONE]: { name: Fishing.HALFSTONE, found: { location: MapLocation.HALFSTONE, x: 17, y: 20 } },
	[Fishing.SILVER_BAZAAR]: { name: Fishing.SILVER_BAZAAR, found: { location: MapLocation.SILVER_BAZAAR, x: 15, y: 29 } },
	[Fishing.CAPE_WESTWIND]: { name: Fishing.CAPE_WESTWIND, found: { location: MapLocation.CAPE_WESTWIND, x: 9, y: 6 } },
	[Fishing.COSTA_DEL_SOL]: { name: Fishing.COSTA_DEL_SOL, found: { location: MapLocation.COSTA_DEL_SOL, x: 35, y: 29 } },
	[Fishing.UMBRA_NORTHSHORE]: { name: Fishing.UMBRA_NORTHSHORE, found: { location: MapLocation.UMBRA_NORTHSHORE, x: 15, y: 29 } },
	[Fishing.SOUTH_BLOODSHORE]: { name: Fishing.SOUTH_BLOODSHORE, found: { location: MapLocation.SOUTH_BLOODSHORE, x: 32, y: 34 } },
	[Fishing.SUMMERFORD]: { name: Fishing.SUMMERFORD, found: { location: MapLocation.SUMMERFORD, x: 14, y: 15 } },
	[Fishing.SOURCE]: { name: Fishing.SOURCE, found: { location: MapLocation.SOURCE, x: 16, y: 26 } },
	[Fishing.SEAGAZER_SHOALS]: { name: Fishing.SEAGAZER_SHOALS, found: { location: MapLocation.SHADOW_FAULT, x: 17, y: 36 } },
	[Fishing.HANDMIRROR_LAKE]: { name: Fishing.HANDMIRROR_LAKE, found: { location: MapLocation.TIMH_GYEUS, x: 2, y: 21 } },
	[Fishing.LAKE_TUSI_MEKTA]: { name: Fishing.LAKE_TUSI_MEKTA, found: { location: MapLocation.LAKE_TUSI_MEKTA, x: 7, y: 29 } },
	[Fishing.SAGOLII_DUNES]: { name: Fishing.SAGOLII_DUNES, found: { location: MapLocation.SAGOLII_DESERT, x: 27, y: 37 } },
	[Fishing.NABAATH_SEVERANCE]: { name: Fishing.NABAATH_SEVERANCE, found: { location: MapLocation.NABAATH_ARENG, x: 32, y: 33 } },
	[Fishing.ISLE_OF_KEN]: { name: Fishing.ISLE_OF_KEN, found: { location: MapLocation.SOURCE, x: 9, y: 38 } },
	[Fishing.WHITE_OIL_FALLS]: { name: Fishing.WHITE_OIL_FALLS, found: { location: MapLocation.BRIGHT_CLIFF, x: 29, y: 21 } },
	[Fishing.WEST_CALIBAN_GAP]: { name: Fishing.WEST_CALIBAN_GAP, found: { location: MapLocation.CALIBAN_GORGE, x: 6, y: 14 } },
	[Fishing.MJRLS_TEARS]: { name: Fishing.MJRLS_TEARS, found: { location: MapLocation.BLIND_FOREST, x: 26, y: 37 } },
	[Fishing.SAINT_FATHRICS_TEMPLE]: { name: Fishing.SAINT_FATHRICS_TEMPLE, found: { location: MapLocation.VOEBURTENBURG, x: 35, y: 26 } },
};
