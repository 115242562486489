//#region Imports
import ReductionIcon from "assets/icons/action-icons/aetherial_reduction.png";
import UnkownIcon from "assets/icons/action-icons/blacklist.png";
import BlueQuestIcon from "assets/icons/action-icons/bluequest.png";
import NPCIcon from "assets/icons/action-icons/contacts.png";
import MarketIcon from "assets/icons/action-icons/currency.png";
import DesynthesisIcon from "assets/icons/action-icons/desynthesis.png";
import DungeonIcon from "assets/icons/action-icons/dungeon.png";
import DutyIcon from "assets/icons/action-icons/duty_finder.png";
import FateIcon from "assets/icons/action-icons/fate.png";
import FishingIcon from "assets/icons/action-icons/fishing_log.png";
import GrandCompanyIcon from "assets/icons/action-icons/free_company.png";
import GatheringIcon from "assets/icons/action-icons/gather.png";
import LeveIcon from "assets/icons/action-icons/leve.png";
import LocationIcon from "assets/icons/action-icons/location.png";
// import LocationIcon from "assets/icons/action-icons/signs.png"; // Alt Location Icon
import MapIcon from "assets/icons/action-icons/map.png";
import ExtractionIcon from "assets/icons/action-icons/materia_assimilating.png";
import MSQIcon from "assets/icons/action-icons/msq.png";
import OtherIcon from "assets/icons/action-icons/other.png";
import QuestIcon from "assets/icons/action-icons/quest.png";
import RaidIcon from "assets/icons/action-icons/raid.png";
import RecipeIcon from "assets/icons/action-icons/recipe.png";
import ShopIcon from "assets/icons/action-icons/shop.png";
import EnemyIcon from "assets/icons/action-icons/target.png";
import TeleportIcon from "assets/icons/action-icons/teleport.png";
import TrialIcon from "assets/icons/action-icons/trial.png";
import RegionIcon from "assets/icons/action-icons/waymarks.png";
// import BaitIcon from "assets/icons/action-icons/shop.png"; // Defined below due to dupe import
import { Icon } from "enums";
//#endregion Imports

//#region Duplicate Import Icons
const BaitIcon = ShopIcon;
//#endregion Duplicate Import Icons

export class IconManager {
	getIcon(type:Icon):string{ // eslint-disable-line complexity
		switch(type){
			default: return UnkownIcon;
			case Icon.REDUCTION: return ReductionIcon;
			case Icon.UNKNOWN: return UnkownIcon;
			case Icon.BLUE_QUEST: return BlueQuestIcon;
			case Icon.NPC: return NPCIcon;
			case Icon.MARKET: return MarketIcon;
			case Icon.DESYNTHESIS: return DesynthesisIcon;
			case Icon.DUNGEON: return DungeonIcon;
			case Icon.DUTY: return DutyIcon;
			case Icon.FATE: return FateIcon;
			case Icon.FISHING: return FishingIcon;
			case Icon.GRAND_COMPANY: return GrandCompanyIcon;
			case Icon.GATHERING: return GatheringIcon;
			case Icon.LEVE: return LeveIcon;
			case Icon.LOCATION: return LocationIcon;
			case Icon.MAP: return MapIcon;
			case Icon.EXTRACTION: return ExtractionIcon;
			case Icon.MSQ: return MSQIcon;
			case Icon.OTHER: return OtherIcon;
			case Icon.QUEST: return QuestIcon;
			case Icon.RAID: return RaidIcon;
			case Icon.RECIPE: return RecipeIcon;
			case Icon.SHOP: return ShopIcon;
			case Icon.ENEMY: return EnemyIcon;
			case Icon.TELEPORT: return TeleportIcon;
			case Icon.TRIAL: return TrialIcon;
			case Icon.REGION: return RegionIcon;
			case Icon.BAIT: return BaitIcon;
		}
	}
}

const appIcons = new IconManager();
export default appIcons;
