/* eslint-disable max-len */
//#region Imports
import { Dungeon, EorzeaDataType, Fate, GrandCompany, Leve, MapLocation, NPC, Quest, Raid, Trial } from "enums";
import { DungeonInfo, FateInfo, LeveInfo, RaidInfo, TrialInfo } from "types";
//#endregion Imports

//#region Trials
export const trials:{ [key in Trial]: TrialInfo } = {
	[Trial.UNKNOWN]: { name: Trial.UNKNOWN, type: EorzeaDataType.TRIAL, lodestoneID: "", unlocked: Quest.UNKNOWN },
	[Trial.CHIMERA]: { name: Trial.CHIMERA, type: EorzeaDataType.TRIAL, lodestoneID: "decf353d4e7", unlocked: Quest.UNKNOWN },
	[Trial.HYDRA]: { name: Trial.HYDRA, type: EorzeaDataType.TRIAL, lodestoneID: "c87fbc421a0", unlocked: Quest.UNKNOWN },
	[Trial.IFRIT]: { name: Trial.IFRIT, type: EorzeaDataType.TRIAL, lodestoneID: "", unlocked: Quest.UNKNOWN },
	[Trial.IFRIT_HARD]: { name: Trial.IFRIT_HARD, type: EorzeaDataType.TRIAL, lodestoneID: "2640986aff3", unlocked: Quest.UNKNOWN },
	[Trial.GARUDA_HARD]: { name: Trial.GARUDA_HARD, type: EorzeaDataType.TRIAL, lodestoneID: "b74b4328a17", unlocked: Quest.UNKNOWN },
	[Trial.TITAN_HARD]: { name: Trial.TITAN_HARD, type: EorzeaDataType.TRIAL, lodestoneID: "349d6ca4465", unlocked: Quest.UNKNOWN },
	[Trial.MOGGLE_MOG_HARD]: { name: Trial.MOGGLE_MOG_HARD, type: EorzeaDataType.TRIAL, lodestoneID: "", unlocked: Quest.UNKNOWN },
	[Trial.LEVIATHAN_HARD]: { name: Trial.LEVIATHAN_HARD, type: EorzeaDataType.TRIAL, lodestoneID: "", unlocked: Quest.UNKNOWN },
	[Trial.RAMUH_HARD]: { name: Trial.RAMUH_HARD, type: EorzeaDataType.TRIAL, lodestoneID: "", unlocked: Quest.UNKNOWN },
	[Trial.SHIVA_HARD]: { name: Trial.SHIVA_HARD, type: EorzeaDataType.TRIAL, lodestoneID: "", unlocked: Quest.UNKNOWN },
	[Trial.RAVANA_HARD]: { name: Trial.RAVANA_HARD, type: EorzeaDataType.TRIAL, lodestoneID: "", unlocked: Quest.UNKNOWN },
	[Trial.BISMARCK]: { name: Trial.BISMARCK, type: EorzeaDataType.TRIAL, lodestoneID: "", unlocked: Quest.UNKNOWN },
	[Trial.SEPHIROT]: { name: Trial.SEPHIROT, type: EorzeaDataType.TRIAL, lodestoneID: "", unlocked: Quest.UNKNOWN },
	[Trial.SOPHIA]: { name: Trial.SOPHIA, type: EorzeaDataType.TRIAL, lodestoneID: "", unlocked: Quest.UNKNOWN },
	[Trial.ZURVAN]: { name: Trial.ZURVAN, type: EorzeaDataType.TRIAL, lodestoneID: "", unlocked: Quest.UNKNOWN },
};
//#endregion Trials

//#region Dungeons
export const dungeons:{ [key in Dungeon]: DungeonInfo } = {
	[Dungeon.UNKNOWN]: { name: Dungeon.UNKNOWN, type: EorzeaDataType.DUNGEON, lodestoneID: "", unlocked: Quest.UNKNOWN },
	[Dungeon.TAM_TARA]: { name: Dungeon.TAM_TARA, type: EorzeaDataType.DUNGEON, lodestoneID: "29e71c3b0a0", unlocked: Quest.UNKNOWN },
	[Dungeon.STONE_VIGIL]: { name: Dungeon.STONE_VIGIL, type: EorzeaDataType.DUNGEON, lodestoneID: "b6491e1b508", unlocked: Quest.UNKNOWN },
	[Dungeon.LOST_CITY_OF_AMDAPOR]: { name: Dungeon.LOST_CITY_OF_AMDAPOR, type: EorzeaDataType.DUNGEON, lodestoneID: "87d3b951d3d", unlocked: Quest.UNKNOWN },
	[Dungeon.BRAYFLOX]: { name: Dungeon.BRAYFLOX, type: EorzeaDataType.DUNGEON, lodestoneID: "e371c7ab919", unlocked: Quest.UNKNOWN },
	[Dungeon.WANDERERS]: { name: Dungeon.WANDERERS, type: EorzeaDataType.DUNGEON, lodestoneID: "3fd66be47b2", unlocked: Quest.UNKNOWN },
	[Dungeon.COPPERBELL_HARD]: { name: Dungeon.COPPERBELL_HARD, type: EorzeaDataType.DUNGEON, lodestoneID: "a780ca9b970", unlocked: Quest.UNKNOWN },
	[Dungeon.QARN]: { name: Dungeon.QARN, type: EorzeaDataType.DUNGEON, lodestoneID: "b7a48152df9", unlocked: Quest.UNKNOWN },
	[Dungeon.HAUKKE_MANOR_HARD]: { name: Dungeon.HAUKKE_MANOR_HARD, type: EorzeaDataType.DUNGEON, lodestoneID: "61c74c68e00", unlocked: Quest.UNKNOWN },
	[Dungeon.HALATALI_HARD]: { name: Dungeon.HALATALI_HARD, type: EorzeaDataType.DUNGEON, lodestoneID: "d536b8f0cc8", unlocked: Quest.UNKNOWN },
	[Dungeon.COPPERBELL]: { name: Dungeon.COPPERBELL, type: EorzeaDataType.DUNGEON, lodestoneID: "619923ac984", unlocked: Quest.UNKNOWN },
	[Dungeon.DZEMAEL_DARKHOLD]: { name: Dungeon.DZEMAEL_DARKHOLD, type: EorzeaDataType.DUNGEON, lodestoneID: "4a36cbca533", unlocked: Quest.UNKNOWN },
	[Dungeon.BRAYFLOX_HARD]: { name: Dungeon.BRAYFLOX_HARD, type: EorzeaDataType.DUNGEON, lodestoneID: "40681f6c94a", unlocked: Quest.UNKNOWN },
	[Dungeon.TOTO_RAK]: { name: Dungeon.TOTO_RAK, type: EorzeaDataType.DUNGEON, lodestoneID: "cf7612fa294", unlocked: Quest.UNKNOWN },
	[Dungeon.AMDAPOR_KEEP]: { name: Dungeon.AMDAPOR_KEEP, type: EorzeaDataType.DUNGEON, lodestoneID: "ae8a92122ec", unlocked: Quest.UNKNOWN },
	[Dungeon.CUTTERS]: { name: Dungeon.CUTTERS, type: EorzeaDataType.DUNGEON, lodestoneID: "d601f85dc1e", unlocked: Quest.UNKNOWN },
	[Dungeon.PHAROS_SIRIUS]: { name: Dungeon.PHAROS_SIRIUS, type: EorzeaDataType.DUNGEON, lodestoneID: "a8dd3748467", unlocked: Quest.UNKNOWN },
	[Dungeon.SASTASHA]: { name: Dungeon.SASTASHA, type: EorzeaDataType.DUNGEON, lodestoneID: "b229b89b3a8", unlocked: Quest.UNKNOWN },
	[Dungeon.AURUM]: { name: Dungeon.AURUM, type: EorzeaDataType.DUNGEON, lodestoneID: "f507633618c", unlocked: Quest.UNKNOWN },
	[Dungeon.HAUKKE_MANOR]: { name: Dungeon.HAUKKE_MANOR, type: EorzeaDataType.DUNGEON, lodestoneID: "e9740dde26c", unlocked: Quest.UNKNOWN },
	[Dungeon.HALATALI]: { name: Dungeon.HALATALI, type: EorzeaDataType.DUNGEON, lodestoneID: "98319325b98", unlocked: Quest.UNKNOWN },
	[Dungeon.SASTASHA_HARD]: { name: Dungeon.SASTASHA_HARD, type: EorzeaDataType.DUNGEON, lodestoneID: "df38ed5c808", unlocked: Quest.UNKNOWN },
	[Dungeon.TAM_TARA_HARD]: { name: Dungeon.TAM_TARA_HARD, type: EorzeaDataType.DUNGEON, lodestoneID: "84d01fe5b6c", unlocked: Quest.UNKNOWN },
	[Dungeon.STONE_VIGIL_HARD]: { name: Dungeon.STONE_VIGIL_HARD, type: EorzeaDataType.DUNGEON, lodestoneID: "418628edfbf", unlocked: Quest.UNKNOWN },
	[Dungeon.SNOWCLOAK]: { name: Dungeon.SNOWCLOAK, type: EorzeaDataType.DUNGEON, lodestoneID: "6f1778eb631", unlocked: Quest.UNKNOWN },
	[Dungeon.HULLBREAKER]: { name: Dungeon.HULLBREAKER, type: EorzeaDataType.DUNGEON, lodestoneID: "18aeffad7c5", unlocked: Quest.UNKNOWN },
	[Dungeon.QARN_HARD]: { name: Dungeon.QARN_HARD, type: EorzeaDataType.DUNGEON, lodestoneID: "6b35d4a1179", unlocked: Quest.UNKNOWN },
	[Dungeon.KEEPER_OF_THE_LAKE]: { name: Dungeon.KEEPER_OF_THE_LAKE, type: EorzeaDataType.DUNGEON, lodestoneID: "5e75d2059af", unlocked: Quest.UNKNOWN },
	[Dungeon.WANDERERS_HARD]: { name: Dungeon.WANDERERS_HARD, type: EorzeaDataType.DUNGEON, lodestoneID: "7c11b0ba080", unlocked: Quest.UNKNOWN },
	[Dungeon.AMDAPOR_KEEP_HARD]: { name: Dungeon.AMDAPOR_KEEP_HARD, type: EorzeaDataType.DUNGEON, lodestoneID: "a4288ecf826", unlocked: Quest.UNKNOWN },
	[Dungeon.DUSK_VIGIL]: { name: Dungeon.DUSK_VIGIL, type: EorzeaDataType.DUNGEON, lodestoneID: "df5ab8bfd61", unlocked: Quest.UNKNOWN },
	[Dungeon.SOHM_AL]: { name: Dungeon.SOHM_AL, type: EorzeaDataType.DUNGEON, lodestoneID: "eed0add7a62", unlocked: Quest.UNKNOWN },
	[Dungeon.AERY]: { name: Dungeon.AERY, type: EorzeaDataType.DUNGEON, lodestoneID: "339c4923556", unlocked: Quest.UNKNOWN },
	[Dungeon.VAULT]: { name: Dungeon.VAULT, type: EorzeaDataType.DUNGEON, lodestoneID: "a62f7ee3718", unlocked: Quest.UNKNOWN },
	[Dungeon.LOST_CITY_OF_AMDAPOR_HARD]: { name: Dungeon.LOST_CITY_OF_AMDAPOR_HARD, type: EorzeaDataType.DUNGEON, lodestoneID: "193a96b0fa4", unlocked: Quest.UNKNOWN },
	[Dungeon.GUBAL_LIBRARY_HARD]: { name: Dungeon.GUBAL_LIBRARY_HARD, type: EorzeaDataType.DUNGEON, lodestoneID: "d6e98e35e6f", unlocked: Quest.UNKNOWN },
	[Dungeon.SOHM_AL_HARD]: { name: Dungeon.SOHM_AL_HARD, type: EorzeaDataType.DUNGEON, lodestoneID: "9bd9004a140", unlocked: Quest.UNKNOWN },
	[Dungeon.MERIDIANUM]: { name: Dungeon.MERIDIANUM, type: EorzeaDataType.DUNGEON, lodestoneID: "", unlocked: Quest.UNKNOWN },
	[Dungeon.PRAETORIUM]: { name: Dungeon.PRAETORIUM, type: EorzeaDataType.DUNGEON, lodestoneID: "", unlocked: Quest.UNKNOWN },
};
//#endregion Dungeons

//#region Raids
export const raids:{ [key in Raid]: RaidInfo } = {
	[Raid.UNKNOWN]: { name: Raid.UNKNOWN, type: EorzeaDataType.RAID, lodestoneID: "", unlocked: Quest.UNKNOWN },
	[Raid.LABYRINTH_OF_ANCIENTS]: { name: Raid.LABYRINTH_OF_ANCIENTS, type: EorzeaDataType.RAID, lodestoneID: "d9f4e986d0e", unlocked: Quest.UNKNOWN },
	[Raid.SYRCUS_TOWER]: { name: Raid.SYRCUS_TOWER, type: EorzeaDataType.RAID, lodestoneID: "47eb1d018b6", unlocked: Quest.UNKNOWN },
	[Raid.WORLD_OF_DARKNESS]: { name: Raid.WORLD_OF_DARKNESS, type: EorzeaDataType.RAID, lodestoneID: "7f0a3551ab6", unlocked: Quest.UNKNOWN },
	[Raid.VOID_ARK]: { name: Raid.VOID_ARK, type: EorzeaDataType.RAID, lodestoneID: "07fc9cb5bc8", unlocked: Quest.UNKNOWN },
	[Raid.MHACH]: { name: Raid.MHACH, type: EorzeaDataType.RAID, lodestoneID: "b0a1515fd3d", unlocked: Quest.UNKNOWN },
	[Raid.DUN_SCAITH]: { name: Raid.DUN_SCAITH, type: EorzeaDataType.RAID, lodestoneID: "35a8825ed8e", unlocked: Quest.UNKNOWN },
	[Raid.RABANASTRE]: { name: Raid.RABANASTRE, type: EorzeaDataType.RAID, lodestoneID: "56209386296", unlocked: Quest.UNKNOWN },
	[Raid.RIDORANA]: { name: Raid.RIDORANA, type: EorzeaDataType.RAID, lodestoneID: "390fb10fd68", unlocked: Quest.UNKNOWN },
	[Raid.ORBONNE]: { name: Raid.ORBONNE, type: EorzeaDataType.RAID, lodestoneID: "95dbcc947db", unlocked: Quest.UNKNOWN },
	[Raid.CASTRUM_LACUS_LITORE]: { name: Raid.CASTRUM_LACUS_LITORE, type: EorzeaDataType.RAID, lodestoneID: "", unlocked: Quest.UNKNOWN },
	[Raid.DELUBRUM_REGINAE]: { name: Raid.DELUBRUM_REGINAE, type: EorzeaDataType.RAID, lodestoneID: "", unlocked: Quest.UNKNOWN },
	[Raid.DALRIADA]: { name: Raid.DALRIADA, type: EorzeaDataType.RAID, lodestoneID: "", unlocked: Quest.UNKNOWN },
	[Raid.ALEXANDER_1]: { name: Raid.ALEXANDER_1, type: EorzeaDataType.RAID, lodestoneID: "", unlocked: Quest.UNKNOWN },
	[Raid.ALEXANDER_2]: { name: Raid.ALEXANDER_2, type: EorzeaDataType.RAID, lodestoneID: "", unlocked: Quest.UNKNOWN },
	[Raid.ALEXANDER_3]: { name: Raid.ALEXANDER_3, type: EorzeaDataType.RAID, lodestoneID: "", unlocked: Quest.UNKNOWN },
	[Raid.ALEXANDER_4]: { name: Raid.ALEXANDER_4, type: EorzeaDataType.RAID, lodestoneID: "", unlocked: Quest.UNKNOWN },
	[Raid.ALEXANDER_5]: { name: Raid.ALEXANDER_5, type: EorzeaDataType.RAID, lodestoneID: "", unlocked: Quest.UNKNOWN },
	[Raid.ALEXANDER_6]: { name: Raid.ALEXANDER_6, type: EorzeaDataType.RAID, lodestoneID: "", unlocked: Quest.UNKNOWN },
	[Raid.ALEXANDER_7]: { name: Raid.ALEXANDER_7, type: EorzeaDataType.RAID, lodestoneID: "", unlocked: Quest.UNKNOWN },
	[Raid.ALEXANDER_8]: { name: Raid.ALEXANDER_8, type: EorzeaDataType.RAID, lodestoneID: "", unlocked: Quest.UNKNOWN },
	[Raid.ALEXANDER_9]: { name: Raid.ALEXANDER_9, type: EorzeaDataType.RAID, lodestoneID: "", unlocked: Quest.UNKNOWN },
	[Raid.ALEXANDER_10]: { name: Raid.ALEXANDER_10, type: EorzeaDataType.RAID, lodestoneID: "", unlocked: Quest.UNKNOWN },
	[Raid.ALEXANDER_11]: { name: Raid.ALEXANDER_11, type: EorzeaDataType.RAID, lodestoneID: "", unlocked: Quest.UNKNOWN },
	[Raid.ALEXANDER_12]: { name: Raid.ALEXANDER_12, type: EorzeaDataType.RAID, lodestoneID: "", unlocked: Quest.UNKNOWN },
	[Raid.OMEGA_1]: { name: Raid.OMEGA_1, type: EorzeaDataType.RAID, lodestoneID: "", unlocked: Quest.UNKNOWN },
	[Raid.OMEGA_2]: { name: Raid.OMEGA_2, type: EorzeaDataType.RAID, lodestoneID: "", unlocked: Quest.UNKNOWN },
	[Raid.OMEGA_3]: { name: Raid.OMEGA_3, type: EorzeaDataType.RAID, lodestoneID: "", unlocked: Quest.UNKNOWN },
	[Raid.OMEGA_4]: { name: Raid.OMEGA_4, type: EorzeaDataType.RAID, lodestoneID: "", unlocked: Quest.UNKNOWN },
	[Raid.OMEGA_5]: { name: Raid.OMEGA_5, type: EorzeaDataType.RAID, lodestoneID: "", unlocked: Quest.UNKNOWN },
	[Raid.OMEGA_6]: { name: Raid.OMEGA_6, type: EorzeaDataType.RAID, lodestoneID: "", unlocked: Quest.UNKNOWN },
	[Raid.OMEGA_7]: { name: Raid.OMEGA_7, type: EorzeaDataType.RAID, lodestoneID: "", unlocked: Quest.UNKNOWN },
	[Raid.OMEGA_8]: { name: Raid.OMEGA_8, type: EorzeaDataType.RAID, lodestoneID: "", unlocked: Quest.UNKNOWN },
	[Raid.OMEGA_9]: { name: Raid.OMEGA_9, type: EorzeaDataType.RAID, lodestoneID: "", unlocked: Quest.UNKNOWN },
	[Raid.OMEGA_10]: { name: Raid.OMEGA_10, type: EorzeaDataType.RAID, lodestoneID: "", unlocked: Quest.UNKNOWN },
	[Raid.OMEGA_11]: { name: Raid.OMEGA_11, type: EorzeaDataType.RAID, lodestoneID: "", unlocked: Quest.UNKNOWN },
	[Raid.OMEGA_12]: { name: Raid.OMEGA_12, type: EorzeaDataType.RAID, lodestoneID: "", unlocked: Quest.UNKNOWN },
	[Raid.EDEN_1]: { name: Raid.EDEN_1, type: EorzeaDataType.RAID, lodestoneID: "", unlocked: Quest.UNKNOWN },
	[Raid.EDEN_2]: { name: Raid.EDEN_2, type: EorzeaDataType.RAID, lodestoneID: "", unlocked: Quest.UNKNOWN },
	[Raid.EDEN_3]: { name: Raid.EDEN_3, type: EorzeaDataType.RAID, lodestoneID: "", unlocked: Quest.UNKNOWN },
	[Raid.EDEN_4]: { name: Raid.EDEN_4, type: EorzeaDataType.RAID, lodestoneID: "", unlocked: Quest.UNKNOWN },
	[Raid.EDEN_5]: { name: Raid.EDEN_5, type: EorzeaDataType.RAID, lodestoneID: "", unlocked: Quest.UNKNOWN },
	[Raid.EDEN_6]: { name: Raid.EDEN_6, type: EorzeaDataType.RAID, lodestoneID: "", unlocked: Quest.UNKNOWN },
	[Raid.EDEN_7]: { name: Raid.EDEN_7, type: EorzeaDataType.RAID, lodestoneID: "", unlocked: Quest.UNKNOWN },
	[Raid.EDEN_8]: { name: Raid.EDEN_8, type: EorzeaDataType.RAID, lodestoneID: "", unlocked: Quest.UNKNOWN },
	[Raid.EDEN_9]: { name: Raid.EDEN_9, type: EorzeaDataType.RAID, lodestoneID: "", unlocked: Quest.UNKNOWN },
	[Raid.EDEN_10]: { name: Raid.EDEN_10, type: EorzeaDataType.RAID, lodestoneID: "", unlocked: Quest.UNKNOWN },
	[Raid.EDEN_11]: { name: Raid.EDEN_11, type: EorzeaDataType.RAID, lodestoneID: "", unlocked: Quest.UNKNOWN },
	[Raid.EDEN_12]: { name: Raid.EDEN_12, type: EorzeaDataType.RAID, lodestoneID: "", unlocked: Quest.UNKNOWN },
};
//#endregion Raids

//#region FATEs
export const fates:{ [key in Fate]: FateInfo } = { // TODO: Add NPCs and previous FATEs
	[Fate.UNKNOWN]: { name: Fate.UNKNOWN, type: EorzeaDataType.FATE, found: { location: MapLocation.UNKNOWN, x: 0, y: 0 } },
	[Fate.ENMITY_OF_MY_ENEMY]: { name: Fate.ENMITY_OF_MY_ENEMY, type: EorzeaDataType.FATE, found: { location: MapLocation.LARKSCALL, x: 27, y: 22 } }, // note: "Speak to 'Manne Thousandmalm' to start - Chained from 'The Enemy of my Enemy'",
	[Fate.GIANT_SEPS]: { name: Fate.GIANT_SEPS, type: EorzeaDataType.FATE, found: { location: MapLocation.WHITEBRIM, x: 9, y: 12 } },
	[Fate.MAKE_IT_RAIN]: { name: Fate.MAKE_IT_RAIN, type: EorzeaDataType.FATE, found: { location: MapLocation.IRON_LAKE, x: 25, y: 18 } },
	[Fate.BREACHING_SOUTH_TIDEGATE]: { name: Fate.BREACHING_SOUTH_TIDEGATE, type: EorzeaDataType.FATE, found: { location: MapLocation.HALFSTONE, x: 18, y: 22 } },
	[Fate.AIR_SUPPLY]: { name: Fate.AIR_SUPPLY, type: EorzeaDataType.FATE, found: { location: MapLocation.PROUD_CREEK, x: 19, y: 20 } },
	[Fate.HEROES_OF_THE_2ND]: { name: Fate.HEROES_OF_THE_2ND, type: EorzeaDataType.FATE, found: { location: MapLocation.ZANRAK, x: 21, y: 16 } },
	[Fate.ANOTHER_NOTCH]: { name: Fate.ANOTHER_NOTCH, type: EorzeaDataType.FATE, found: { location: MapLocation.NORTH_SILVERTEAR, x: 31, y: 5 } },
	[Fate.EVERYTHINGS_BETTER]: { name: Fate.EVERYTHINGS_BETTER, type: EorzeaDataType.FATE, found: { location: MapLocation.SYLPHLANDS, x: 23, y: 14 } },
	[Fate.RETURN_TO_CINDER]: { name: Fate.RETURN_TO_CINDER, type: EorzeaDataType.FATE, found: { location: MapLocation.ZANRAK, x: 24, y: 26 } },
	[Fate.BELLYFULL]: { name: Fate.BELLYFULL, type: EorzeaDataType.FATE, found: { location: MapLocation.PROVIDENCE_POINT, x: 34, y: 14 } },
	[Fate.KINGS_JUSTICE]: { name: Fate.KINGS_JUSTICE, type: EorzeaDataType.FATE, found: { location: MapLocation.ISLES_OF_UMBRA, x: 14, y: 34 } },
	[Fate.QUARTZ_COUPLING]: { name: Fate.QUARTZ_COUPLING, type: EorzeaDataType.FATE, found: { location: MapLocation.BURNING_WALL, x: 26, y: 24 } },
	[Fate.BLACK_AND_NBURU]: { name: Fate.BLACK_AND_NBURU, type: EorzeaDataType.FATE, found: { location: MapLocation.FOGFENS, x: 15, y: 13 } },
	[Fate.BREAKING_DAWN]: { name: Fate.BREAKING_DAWN, type: EorzeaDataType.FATE, found: { location: MapLocation.SYLPHLANDS, x: 32, y: 14 } },
	[Fate.BREACHING_NORTH_TIDEGATE]: { name: Fate.BREACHING_NORTH_TIDEGATE, type: EorzeaDataType.FATE, found: { location: MapLocation.HALFSTONE, x: 21, y: 19 } }, // note: "Chained from 'Gauging North Tidegate'"
	[Fate.CERULEUM_ROAD]: { name: Fate.CERULEUM_ROAD, type: EorzeaDataType.FATE, found: { location: MapLocation.BLUEFOG, x: 21, y: 29 } }, // note: "Speak with 'Wary Merchant'"
	[Fate.FOUR_WINDS]: { name: Fate.FOUR_WINDS, type: EorzeaDataType.FATE, found: { location: MapLocation.NATALAN, x: 34, y: 20 } },
	[Fate.RUDE_AWAKENING]: { name: Fate.RUDE_AWAKENING, type: EorzeaDataType.FATE, found: { location: MapLocation.PROUD_CREEK, x: 21, y: 19 } },
	[Fate.GOOD_TO_BE_BAD]: { name: Fate.GOOD_TO_BE_BAD, type: EorzeaDataType.FATE, found: { location: MapLocation.FOGFENS, x: 13, y: 12 } },
	[Fate.SURPRISE]: { name: Fate.SURPRISE, type: EorzeaDataType.FATE, found: { location: MapLocation.ZELMAS_RUN, x: 26, y: 18 } },
	[Fate.IN_SPITE_OF_IT_ALL]: { name: Fate.IN_SPITE_OF_IT_ALL, type: EorzeaDataType.FATE, found: { location: MapLocation.STANDING_CORSES, x: 11, y: 18 } },
	[Fate.WHAT_GORED_BEFORE]: { name: Fate.WHAT_GORED_BEFORE, type: EorzeaDataType.FATE, found: { location: MapLocation.URTHS_GIFT, x: 32, y: 25 } },
	[Fate.TAKEN]: { name: Fate.TAKEN, type: EorzeaDataType.FATE, found: { location: MapLocation.ZANRAK, x: 18, y: 20 } },
	[Fate.TOWER_OF_POWER]: { name: Fate.TOWER_OF_POWER, type: EorzeaDataType.FATE, found: { location: MapLocation.BOULDER_DOWNS, x: 11, y: 29 } }, // note: "Speak with 'House Haillenarte Guard'"
	[Fate.BIG_BAGOLY_THEORY]: { name: Fate.BIG_BAGOLY_THEORY, type: EorzeaDataType.FATE, found: { location: MapLocation.BURNING_WALL, x: 30, y: 25 } },
	[Fate.SCHISM]: { name: Fate.SCHISM, type: EorzeaDataType.FATE, found: { location: MapLocation.KOBOLD_DIG, x: 25, y: 16 } }, // note: "Speak to 'Storm Private'"
	[Fate.TASTE_OF_FEAR]: { name: Fate.TASTE_OF_FEAR, type: EorzeaDataType.FATE, found: { location: MapLocation.WHITEBRIM, x: 4, y: 21 } },
	[Fate.WAIL_IN_THE_WILLOWS]: { name: Fate.WAIL_IN_THE_WILLOWS, type: EorzeaDataType.FATE, found: { location: MapLocation.NEWVOID, x: 7, y: 22 } },
	[Fate.LOUHI_ON_ICE]: { name: Fate.LOUHI_ON_ICE, type: EorzeaDataType.FATE, found: { location: MapLocation.ICEBLOOM_CAVERN, x: 36, y: 19 } },
	[Fate.LOST_EPIC]: { name: Fate.LOST_EPIC, type: EorzeaDataType.FATE, found: { location: MapLocation.EAST_FLAMEROK, x: 35, y: 6 } },
	[Fate.CRYSTALLINE_PROVENANCE]: { name: Fate.CRYSTALLINE_PROVENANCE, type: EorzeaDataType.FATE, found: { location: MapLocation.EAST_VAL_RIVER_BANK, x: 33, y: 20 } },
};
//#endregion FATEs

//#region Leves
export const leves:{ [key in Leve]: LeveInfo } = {
	[Leve.UNKNOWN]: { name: Leve.UNKNOWN, type: EorzeaDataType.LEVE, npc: NPC.UNKNOWN },
	[Leve.BIG_BAD_IDEA]: { name: Leve.BIG_BAD_IDEA, type: EorzeaDataType.LEVE, npc: NPC.KLEYTAI },
	[Leve.PUT_YOUR_STOMP_ON_IT]: { name: Leve.PUT_YOUR_STOMP_ON_IT, type: EorzeaDataType.LEVE, npc: NPC.KLEYTAI },
	[Leve.NECROLOGOS_THE_LIMINAL_ONES]: { name: Leve.NECROLOGOS_THE_LIMINAL_ONES, type: EorzeaDataType.LEVE, npc: NPC.KLEYTAI },
	[Leve.AWRY_SALVAGES]: { name: Leve.AWRY_SALVAGES, type: EorzeaDataType.LEVE, npc: NPC.EIDHART, grandCompany: GrandCompany.TWIN_ADDER },
	[Leve.GET_OFF_OUR_LAKE]: { name: Leve.GET_OFF_OUR_LAKE, type: EorzeaDataType.LEVE, npc: NPC.EIDHART, grandCompany: GrandCompany.TWIN_ADDER },
	[Leve.MUSEUM_IS_CLOSED]: { name: Leve.MUSEUM_IS_CLOSED, type: EorzeaDataType.LEVE, npc: NPC.EIDHART, grandCompany: GrandCompany.FLAME },
	[Leve.WHO_WRITES_HISTORY]: { name: Leve.WHO_WRITES_HISTORY, type: EorzeaDataType.LEVE, npc: NPC.EIDHART, grandCompany: GrandCompany.FLAME },
	[Leve.ONE_BIG_PROBLEM_SOLVED]: { name: Leve.ONE_BIG_PROBLEM_SOLVED, type: EorzeaDataType.LEVE, npc: NPC.EIDHART, grandCompany: GrandCompany.MAELSTROM },
	[Leve.GO_HOME_TO_MAMA]: { name: Leve.GO_HOME_TO_MAMA, type: EorzeaDataType.LEVE, npc: NPC.EIDHART, grandCompany: GrandCompany.MAELSTROM },
	[Leve.NECROLOGOS_PALE_OBLATION]: { name: Leve.NECROLOGOS_PALE_OBLATION, type: EorzeaDataType.LEVE, npc: NPC.RURUBANA },
	[Leve.DONT_FORGET_TO_CRY]: { name: Leve.DONT_FORGET_TO_CRY, type: EorzeaDataType.LEVE, npc: NPC.RURUBANA },
	[Leve.CIRCLING_THE_CERULEUM]: { name: Leve.CIRCLING_THE_CERULEUM, type: EorzeaDataType.LEVE, npc: NPC.RURUBANA },
	[Leve.SOMEONES_IN_THE_DOGHOUSE]: { name: Leve.SOMEONES_IN_THE_DOGHOUSE, type: EorzeaDataType.LEVE, npc: NPC.RURUBANA },
	[Leve.SUBDUING_THE_SUBPRIME]: { name: Leve.SUBDUING_THE_SUBPRIME, type: EorzeaDataType.LEVE, npc: NPC.RURUBANA },
	[Leve.NECROLOGOS_WHISPERS_OF_THE_GEM]: { name: Leve.NECROLOGOS_WHISPERS_OF_THE_GEM, type: EorzeaDataType.LEVE, npc: NPC.VOILINAUT },
	[Leve.AREAS_A_BIT_SKETCHY]: { name: Leve.AREAS_A_BIT_SKETCHY, type: EorzeaDataType.LEVE, npc: NPC.VOILINAUT },
	[Leve.GOT_A_GUT_FEELING_ABOUT_THIS]: { name: Leve.GOT_A_GUT_FEELING_ABOUT_THIS, type: EorzeaDataType.LEVE, npc: NPC.VOILINAUT },
	[Leve.YELLOW_IS_THE_NEW_BLACK]: { name: Leve.YELLOW_IS_THE_NEW_BLACK, type: EorzeaDataType.LEVE, npc: NPC.LODILLE, grandCompany: GrandCompany.TWIN_ADDER },
	[Leve.BLOODHOUNDS_OF_COERTHAS]: { name: Leve.BLOODHOUNDS_OF_COERTHAS, type: EorzeaDataType.LEVE, npc: NPC.LODILLE, grandCompany: GrandCompany.TWIN_ADDER },
	[Leve.IF_YOU_PUT_IT_THAT_WAY]: { name: Leve.IF_YOU_PUT_IT_THAT_WAY, type: EorzeaDataType.LEVE, npc: NPC.LODILLE, grandCompany: GrandCompany.FLAME },
	[Leve.NO_BIG_WHOOP]: { name: Leve.NO_BIG_WHOOP, type: EorzeaDataType.LEVE, npc: NPC.LODILLE, grandCompany: GrandCompany.FLAME },
	[Leve.AN_IMP_MOBILE]: { name: Leve.AN_IMP_MOBILE, type: EorzeaDataType.LEVE, npc: NPC.LODILLE, grandCompany: GrandCompany.MAELSTROM },
	[Leve.SOMEONES_GOT_A_BIG_MOUTH]: { name: Leve.SOMEONES_GOT_A_BIG_MOUTH, type: EorzeaDataType.LEVE, npc: NPC.LODILLE, grandCompany: GrandCompany.MAELSTROM },
	[Leve.ID_RATHER_BE_DIGGING]: { name: Leve.ID_RATHER_BE_DIGGING, type: EorzeaDataType.LEVE, npc: NPC.TMOKKRI, tradecraft: true },
	[Leve.I_MAUL_RIGHT]: { name: Leve.I_MAUL_RIGHT, type: EorzeaDataType.LEVE, npc: NPC.KLEYTAI, tradecraft: true },
	[Leve.NO_REFUNDS_ONLY_EXCHANGES]: { name: Leve.NO_REFUNDS_ONLY_EXCHANGES, type: EorzeaDataType.LEVE, npc: NPC.TMOKKRI, tradecraft: true },
	[Leve.POP_THAT_TOP]: { name: Leve.POP_THAT_TOP, type: EorzeaDataType.LEVE, npc: NPC.KLEYTAI, tradecraft: true },
	[Leve.TALON_TERRORS]: { name: Leve.TALON_TERRORS, type: EorzeaDataType.LEVE, npc: NPC.KLEYTAI, tradecraft: true },
	[Leve.YOU_STAY_ON_THAT_SIDE]: { name: Leve.YOU_STAY_ON_THAT_SIDE, type: EorzeaDataType.LEVE, npc: NPC.TMOKKRI, tradecraft: true },
	[Leve.MATTER_OF_VITAL_IMPORTANCE]: { name: Leve.MATTER_OF_VITAL_IMPORTANCE, type: EorzeaDataType.LEVE, npc: NPC.EUSTACE, tradecraft: true },
	[Leve.MAKE_UP_YOUR_MIND_OR_ELSE]: { name: Leve.MAKE_UP_YOUR_MIND_OR_ELSE, type: EorzeaDataType.LEVE, npc: NPC.KLEYTAI, tradecraft: true },
	[Leve.NOT_TAKING_NO_FOR_AN_ANSWER]: { name: Leve.NOT_TAKING_NO_FOR_AN_ANSWER, type: EorzeaDataType.LEVE, npc: NPC.KLEYTAI, tradecraft: true },
	[Leve.QUIT_YOUR_JIBJAB]: { name: Leve.QUIT_YOUR_JIBJAB, type: EorzeaDataType.LEVE, npc: NPC.EUSTACE, tradecraft: true },
	[Leve.SHUT_UP_ALREADY]: { name: Leve.SHUT_UP_ALREADY, type: EorzeaDataType.LEVE, npc: NPC.KLEYTAI, tradecraft: true },
	[Leve.SLEEPLESS_IN_SILVERTEAR]: { name: Leve.SLEEPLESS_IN_SILVERTEAR, type: EorzeaDataType.LEVE, npc: NPC.EUSTACE, tradecraft: true },
	[Leve.FUSS_IN_BOOTS]: { name: Leve.FUSS_IN_BOOTS, type: EorzeaDataType.LEVE, npc: NPC.KLEYTAI, tradecraft: true },
	[Leve.HANDLE_WITH_CARE]: { name: Leve.HANDLE_WITH_CARE, type: EorzeaDataType.LEVE, npc: NPC.GONTRANT, tradecraft: true },
	[Leve.HOLD_ON_TIGHT]: { name: Leve.HOLD_ON_TIGHT, type: EorzeaDataType.LEVE, npc: NPC.GONTRANT, tradecraft: true },
	[Leve.SPELLING_ME_SOFTLY]: { name: Leve.SPELLING_ME_SOFTLY, type: EorzeaDataType.LEVE, npc: NPC.KLEYTAI, tradecraft: true },
	[Leve.TENDERFOOT_MOMENTS]: { name: Leve.TENDERFOOT_MOMENTS, type: EorzeaDataType.LEVE, npc: NPC.KLEYTAI, tradecraft: true },
	[Leve.TOO_HOT_TO_HANDLE]: { name: Leve.TOO_HOT_TO_HANDLE, type: EorzeaDataType.LEVE, npc: NPC.GONTRANT, tradecraft: true },
	[Leve.BOW_DOWN_TO_MAGIC]: { name: Leve.BOW_DOWN_TO_MAGIC, type: EorzeaDataType.LEVE, npc: NPC.KLEYTAI, tradecraft: true },
	[Leve.BOWING_TO_GREATER_POWER]: { name: Leve.BOWING_TO_GREATER_POWER, type: EorzeaDataType.LEVE, npc: NPC.KLEYTAI, tradecraft: true },
	[Leve.CLOGS_OF_WAR]: { name: Leve.CLOGS_OF_WAR, type: EorzeaDataType.LEVE, npc: NPC.GONTRANT, tradecraft: true },
	[Leve.INCANT_NOW_THINK_LATER]: { name: Leve.INCANT_NOW_THINK_LATER, type: EorzeaDataType.LEVE, npc: NPC.KLEYTAI, tradecraft: true },
	[Leve.TRIDENT_AND_ERROR]: { name: Leve.TRIDENT_AND_ERROR, type: EorzeaDataType.LEVE, npc: NPC.GONTRANT, tradecraft: true },
	[Leve.YOU_DO_THE_HEAVY_LIFTING]: { name: Leve.YOU_DO_THE_HEAVY_LIFTING, type: EorzeaDataType.LEVE, npc: NPC.GONTRANT, tradecraft: true },
	[Leve.GOLD_IS_SO_LAST_YEAR]: { name: Leve.GOLD_IS_SO_LAST_YEAR, type: EorzeaDataType.LEVE, npc: NPC.EUSTACE, tradecraft: true },
	[Leve.GREEN_AND_BEAR_IT]: { name: Leve.GREEN_AND_BEAR_IT, type: EorzeaDataType.LEVE, npc: NPC.EUSTACE, tradecraft: true },
	[Leve.LIGHT_IN_THE_DARKNESS]: { name: Leve.LIGHT_IN_THE_DARKNESS, type: EorzeaDataType.LEVE, npc: NPC.KLEYTAI, tradecraft: true },
	[Leve.SEW_NOT_DOING_THIS]: { name: Leve.SEW_NOT_DOING_THIS, type: EorzeaDataType.LEVE, npc: NPC.KLEYTAI, tradecraft: true },
	[Leve.SWEET_CHARITY]: { name: Leve.SWEET_CHARITY, type: EorzeaDataType.LEVE, npc: NPC.KLEYTAI, tradecraft: true },
	[Leve.BIG_RED]: { name: Leve.BIG_RED, type: EorzeaDataType.LEVE, npc: NPC.EUSTACE, tradecraft: true },
};
//#endregion Leves
