/* eslint-disable max-len */
//#region Imports
import { faSync } from "@fortawesome/free-solid-svg-icons";
import { jobLists } from "character";
import BasicIcon from "components/basic-icon/BasicIcon";
import FormComponents from "components/forms/FormComponents";
import JobIcon from "components/job-icon/JobIcon";
import Link from "components/link/Link";
import NoCharacter from "components/no-character/NoCharacter";
import RelicCheckbox from "components/relic-checkbox/RelicCheckbox";
import { dungeons, fates, leves } from "data-files/duties";
import { enemies } from "data-files/enemies";
import { locations } from "data-files/locations";
import { npcs } from "data-files/npc";
import { quests } from "data-files/quests";
import { Dungeon, Enemy, Fate, Icon, Job, Leve, LinkType, NPC, Quest, RelicGroup, RelicPart, RelicStep, RelicTask, RelicType, View } from "enums";
import log from "logger";
import manager from "managers/app";
import { Component } from "react";
import { connect } from "react-redux";
import t from "translations/translator";
import { AppState, Character, Duty, ObtainedMethod, OutputGameString, Requirement, TaskInfo, ViewRelicInfo, ViewWeaponZodiacAnimusProps } from "types";
import utils from "utils";
import ContentHeader from "views/page-parts/content/content-header/ContentHeader";
import DetailsSection from "views/page-parts/content/details-section/DetailsSection";
import Obtained from "views/page-parts/content/obtained/Obtained";
import OverviewTable from "views/page-parts/content/overview-table/OverviewTable";
import "./WeaponZodiacAnimus.css";
//#endregion Imports

const mapStateToProps = (state:AppState) => {
	return {
		lang: state.userInfo.options.lang,
		gameLang: state.userInfo.options.gameLang,
		userInfo: state.userInfo,
		options: state.userInfo.options,
		hideCompletedSteps: state.userInfo.options.hideCompletedSteps,
		hideCompletedTasks: state.userInfo.options.hideCompletedTasks,
		showJob: state.userInfo.options.job,
		animusStepJobs: state.userInfo.options.relics.animusStepJobs,
	};
};

class WeaponZodiacAnimus extends Component<ViewWeaponZodiacAnimusProps> {
	private requirements:Requirement[] = [];
	private importantNPCs:NPC[] = [];
	private relicInfo:ViewRelicInfo = {
		jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC],
		type: RelicType.WEAPON,
		relic: RelicGroup.WEAPON_ZODIAC,
		part: RelicPart.WEAPON_ANIMUS,
		steps: [RelicStep.TRIALS, RelicStep.SKYFIRE_1, RelicStep.SKYFIRE_2, RelicStep.NETHERFIRE_1, RelicStep.SKYFALL_1, RelicStep.SKYFALL_2, RelicStep.NETHERFALL_1, RelicStep.SKYWIND_1, RelicStep.SKYWIND_2, RelicStep.SKYEARTH_1],
		stepPlaceholders: {
			[RelicStep.TRIALS]: { BOOK: "Trials of the Braves" },
			[RelicStep.SKYFIRE_1]: { BOOK: "Skyfire I" },
			[RelicStep.SKYFIRE_2]: { BOOK: "Skyfire II" },
			[RelicStep.NETHERFIRE_1]: { BOOK: "Netherfire I" },
			[RelicStep.SKYFALL_1]: { BOOK: "Skyfall I" },
			[RelicStep.SKYFALL_2]: { BOOK: "Skyfall II" },
			[RelicStep.NETHERFALL_1]: { BOOK: "Netherfall I" },
			[RelicStep.SKYWIND_1]: { BOOK: "Skywind I" },
			[RelicStep.SKYWIND_2]: { BOOK: "Skywind II" },
			[RelicStep.SKYEARTH_1]: { BOOK: "Skyearth I" },
		},
		next: {
			part: RelicPart.WEAPON_NOVUS,
			view: View.RELIC_WEAPONS_ZODIAC_NOVUS,
		},
		previous: {
			part: RelicPart.WEAPON_ATMA,
			view: View.RELIC_WEAPONS_ZODIAC_ATMA,
		},
	};

	constructor(props:ViewWeaponZodiacAnimusProps){
		super(props);
		this.requirements = this.getRequirements();
		this.importantNPCs = this.getImportantNPCs();
	}

	render(){
		const character = manager.data.getActiveCharacter();
		if(character === null){ return <NoCharacter />; }

		const completeSteps = manager.content.getCompleteSteps(character.progress, this.relicInfo);

		return (
			<div id="weapon-zodiac-animus" className="relic">
				<ContentHeader requirements={this.requirements} importantNPCs={this.importantNPCs} relicInfo={this.relicInfo}>
					<p>{t.t("The %PART% upgrade follows on from %PREVIOUSPART%", { PART: this.relicInfo.part, PREVIOUSPART: this.relicInfo.previous ? this.relicInfo.previous.part : "Unknown"})}</p>
					<p>{t.t("Books can be purchased from G'jusana in Mor Dhona (x23, y7) for 100 Allagan Tomestones of Poetics")}</p>
					<p>{t.t("You can only carry 1 book at a time")}</p>
				</ContentHeader>
				<div id="relic-checklist">
					<OverviewTable character={character} completeSteps={completeSteps} relicInfo={this.relicInfo} />
				</div>
				<div id="relic-details">
					<h1>{t.t("Details")}</h1>
					{this.props.hideCompletedSteps && completeSteps.allComplete ? t.t("All Steps Complete") : null }
					<DetailsSection title={RelicStep.TRIALS} placeholders={this.relicInfo.stepPlaceholders[RelicStep.TRIALS]} id={RelicStep.TRIALS} stepComplete={completeSteps[RelicStep.TRIALS]}><p>{t.t("Complete")}<Link type={LinkType.LODESTONE} lodestoneType={quests[Quest.TRIALS_OF_THE_BRAVES].type} text={quests[Quest.TRIALS_OF_THE_BRAVES].name} lodestoneID={quests[Quest.TRIALS_OF_THE_BRAVES].lodestoneID} /></p></DetailsSection>
					{
						this.relicInfo.steps.map((step, index) => {
							if(index === 0){ return null; } // Skip Trials step as this is done above

							const thisJob = this.props.animusStepJobs[index - 1];
							const taskInfo = this.getTasksInfo(step, character, thisJob);

							return <DetailsSection title={step} placeholders={this.relicInfo.stepPlaceholders[step]} id={step} stepComplete={completeSteps[step]} key={step}>
								<div className="job-select">
									<FormComponents.Label
										hide={false}
										translate={true}
										text="Select job to track"
										position="left"
										alignment="center"
									/>
									{this.relicInfo.jobs.map((job) => {
										if(this.props.showJob !== null && this.props.showJob !== job){ return null; }
										const weaponName = manager.relics.getRelicNameKey(job, this.relicInfo.part);
										return <JobIcon key={`${index}_${job}`} job={job} tooltip={weaponName} selected={job === thisJob} onClick={() => { this.selectJob(job, index - 1); }} />;
									})}
									<FormComponents.Button
										icon={faSync}
										onClick={() => { this.syncJobs(index - 1); }}
									/>
								</div>
								<div className="table-wrapper">
									<table className="table">
										<thead><tr><th>{t.t("Location")}</th><th>{t.t("Type")}</th><th>{t.t("Target")}</th><th>{t.t("Quantity")}</th><th>{t.t("Done")}</th></tr></thead>
										<tbody>
											{
												// eslint-disable-next-line complexity
												taskInfo.map((thisTask) => {
													const thisComplete = character.progress.isComplete(this.relicInfo.type, this.relicInfo.relic, this.relicInfo.part, step, thisTask.task, thisJob);
													if((this.props.hideCompletedSteps || this.props.hideCompletedTasks) && thisComplete){ return null; }

													let targetName:OutputGameString = "GUnknown";
													let teleport:OutputGameString = "GUnknown";
													let region:OutputGameString = "GUnknown";
													let map:OutputGameString = "GUnknown";
													let x = -1;
													let y = -1;
													let qty = 1;
													let isLeve = false;
													let isDungeon = false;
													let grandCompany:OutputGameString|null = null;
													const targetObtained:ObtainedMethod = { duties: {} };

													if(utils.guards.isEnumValue(Enemy, thisTask.target)){
														const thisTarget = enemies[thisTask.target];
														targetName = thisTarget.name;
														targetObtained.enemies = [thisTask.target];
														qty = 3;

														const thisTeleport = locations[thisTarget.found[0].location].teleport;
														const thisRegion = locations[thisTarget.found[0].location].region;
														x = thisTarget.found[0].x;
														y = thisTarget.found[0].y;
														teleport = thisTeleport ? thisTeleport : "GUnknown";
														region = thisRegion ? thisRegion : "GUnknown";
														map = locations[thisTarget.found[0].location].map;
													}else if(utils.guards.isEnumValue(Leve, thisTask.target)){
														const thisTarget = leves[thisTask.target];
														targetName = thisTarget.name;
														targetObtained.duties = { leves: [thisTask.target] };
														isLeve = true;
														grandCompany = thisTarget.grandCompany ? thisTarget.grandCompany : null;

														const npc = npcs[thisTarget.npc];
														const thisTeleport = locations[npc.found.location].teleport;
														const thisRegion = locations[npc.found.location].region;
														x = npc.found.x;
														y = npc.found.y;
														teleport = thisTeleport ? thisTeleport : "GUnknown";
														region = thisRegion ? thisRegion : "GUnknown";
														map = locations[npc.found.location].map;
													}else if(utils.guards.isEnumValue(Fate, thisTask.target)){
														const thisTarget = fates[thisTask.target];
														targetName = thisTarget.name;
														targetObtained.duties = { fates: [thisTask.target] };

														const thisTeleport = locations[thisTarget.found.location].teleport;
														const thisRegion = locations[thisTarget.found.location].region;
														x = thisTarget.found.x;
														y = thisTarget.found.y;
														teleport = thisTeleport ? thisTeleport : "GUnknown";
														region = thisRegion ? thisRegion : "GUnknown";
														map = locations[thisTarget.found.location].map;
													}else if(utils.guards.isEnumValue(Dungeon, thisTask.target)){
														const thisTarget = dungeons[thisTask.target];
														targetName = thisTarget.name;
														targetObtained.duties = { dungeons: [thisTask.target] };
														isDungeon = true;
													}else{
														log.error("Wrong info set for this task"); return null;
													}

													return <tr key={`${step}-${thisTask.task}`}>
														{ isDungeon
															? <td>{t.t("Any")}</td>
															: <td>
																<div className="location-wrapper">
																	<div className="location">
																		<span><BasicIcon icon={Icon.MAP} tooltip="Map" /> {t.t(map)}</span>
																		{region ? <span><BasicIcon icon={Icon.REGION} tooltip="Region" /> {t.t(region)}</span> : null}
																		{teleport ? <span><BasicIcon icon={Icon.TELEPORT} tooltip="Teleport" /> {t.t(teleport)}</span> : null}
																		{x > -1 ? <span><BasicIcon icon={Icon.LOCATION} tooltip="Co-ordinates" /> X: {x}, Y: {y}</span> : null}
																	</div>
																</div>
															</td>
														}
														<td><Obtained obtained={targetObtained} qtyNeeded={qty} /></td>
														<td>{t.t(targetName)}{isLeve ? ` (${t.t(grandCompany === null ? "General" : grandCompany)})` : null}</td>
														<td>{qty}</td>
														<td><RelicCheckbox character={character} job={thisJob} relicInfo={this.relicInfo} step={step} task={thisTask.task} /></td>
													</tr>;
												})
											}
											{
												character.progress.isComplete(this.relicInfo.type, this.relicInfo.relic, this.relicInfo.part, step, thisJob)
													? <tr className="all-complete"><td colSpan={7}><p>{t.t("All Complete")}</p></td></tr>
													: null
											}
										</tbody>
									</table>
								</div>
							</DetailsSection>;
						})
					}
				</div>
			</div>
		);
	}

	componentDidMount(){
		manager.view.changeComplete();
	}

	private getRequirements():Requirement[]{
		if(!this.relicInfo || this.relicInfo.previous === null){ return []; }
		return [
			{ type: "previous part", part: this.relicInfo.previous.part, partView: this.relicInfo.previous.view },
		];
	}

	private getImportantNPCs():NPC[]{
		return [NPC.JALZAHN, NPC.GJUSANA];
	}

	private getTasksInfo(step:RelicStep, character:Character, job:Job):TaskInfo[]{
		const tasksInfo:TaskInfo[] = [];
		const tasks = [RelicTask.TASK_1, RelicTask.TASK_2, RelicTask.TASK_3, RelicTask.TASK_4, RelicTask.TASK_5, RelicTask.TASK_6, RelicTask.TASK_7, RelicTask.TASK_8, RelicTask.TASK_9, RelicTask.TASK_10, RelicTask.TASK_11, RelicTask.TASK_12, RelicTask.TASK_13, RelicTask.TASK_14, RelicTask.TASK_15, RelicTask.TASK_16, RelicTask.TASK_17, RelicTask.TASK_18, RelicTask.TASK_19];

		tasks.forEach((task) => {
			let target:Enemy|Duty = manager.relics.getTaskEnemy(step, task, job);
			if(target === Enemy.UNKNOWN){ target = manager.relics.getTaskDuty(step, task); }

			tasksInfo.push({ task: task, complete: character.progress.isComplete(this.relicInfo.type, this.relicInfo.relic, this.relicInfo.part, step, task, job), target: target });
		});

		return tasksInfo;
	}

	private syncJobs(stepJobsIndex:number){
		const stepJobs:Job[] = [...this.props.animusStepJobs];
		const thisJob = this.props.animusStepJobs[stepJobsIndex];
		const options = this.props.options;

		stepJobs.forEach((job, i) => {
			stepJobs[i] = thisJob;
		});

		options.relics = {
			...options.relics,
			animusStepJobs: stepJobs,
		};
		manager.data.saveOptions(options, true);
	}

	private selectJob(job:Job, stepJobsIndex:number){ // Event
		const options = this.props.options;
		const newStepJobs:Job[] = [...this.props.animusStepJobs];

		if(utils.guards.isEnumValue(Job, job)){
			newStepJobs[stepJobsIndex] = job;
		}else{
			newStepJobs[stepJobsIndex] = Job.PLD;
		}

		options.relics = {
			...options.relics,
			animusStepJobs: newStepJobs,
		};
		manager.data.saveOptions(options, true);
	}
}
export default connect(mapStateToProps)(WeaponZodiacAnimus);
