//#region Imports
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FormComponents from "components/forms/FormComponents";
import { InputType } from "enums";
import { Component } from "react";
import { connect } from "react-redux";
import t from "translations/translator";
import { AppState, CharacterDisplayProps } from "types";
import utils from "utils";
import "./CharacterDisplay.css";
//#endregion Imports

const mapStateToProps = (state:AppState) => {
	return {
		lang: state.userInfo.options.lang,
		gameLang: state.userInfo.options.gameLang,
		breakpoint: state.breakpoint,
	};
};
class CharacterDisplay extends Component<CharacterDisplayProps> {
	render(){
		const char = this.props.character;
		const bg = this.props.background;
		const actions = this.props.actions;
		const avatar = char.avatar;
		const hasDisplayName = char.displayName !== char.name;
		const editMode = this.props.editMode ? this.props.editMode : false;
		const achievementsChecked = utils.helpers.timeSince(char.achievementsChecked);
		const lastUpdate = utils.helpers.timeSince(char.lastUpdate);
		const hideAchievementCheck = typeof this.props.hideAchievementCheck === "undefined" ? false : this.props.hideAchievementCheck;
		const hideLastUpdate = typeof this.props.hideLastUpdate === "undefined" ? false : this.props.hideLastUpdate;
		const minimalist = this.props.minimalist ? "minimalist" : null;
		const selectable = this.props.selectable ? this.props.selectable : false;
		const selected = this.props.selected;
		const nameValue = hasDisplayName ? char.displayName : char.name;

		return (
			<div
				className={`character-display ${bg} ${minimalist} ${selectable ? "selectable" : null} ${selected ? "selected" : null}`}
				data-seid={char.seId}
				onClick={selectable ? this.props.selectAction : undefined } // eslint-disable-line no-undefined
			>
				<div className="actions">
					{selectable
						? <div key={`${char.seId}_action_0`}><FontAwesomeIcon icon={selected ? faCheck : faTimes} /></div>
						: actions.map((action, i) => {
							return <div key={`${char.seId}_action_${i}`}>
								<FormComponents.Button
									class={action.class}
									iconClass={action.iconClass}
									icon={action.icon}
									onClick={action.onClick}
									tooltip={action.tooltip}
								/>
							</div>;
						})
					}
				</div>
				<div className="avatar-wrapper">
					{avatar ? <img src={avatar} alt="" /> : null}
				</div>
				<div className="name-wrapper">
					{editMode
						? <FormComponents.TextInput
							label={{ hide: true }}
							input={{ type: InputType.TEXT, value: nameValue, minimalist: true, class: ["primary"], events: { onChange: this.props.onEditMade } }}
							hidden={{ type: InputType.HIDDEN, value: nameValue }}
						/>
						: <p>{nameValue}</p>
					}
					{hasDisplayName && <p className="additional-detail">{t.t("Real Name")}: {char.name}</p>}
					{hideAchievementCheck === false && <p className="additional-detail">{t.t("Achievements Checked")}: {achievementsChecked}</p>}
					{hideLastUpdate === false && <p className="additional-detail">{t.t("Last Update")}: {lastUpdate}</p>}
				</div>
			</div>
		);
	}
}
export default connect(mapStateToProps)(CharacterDisplay);
