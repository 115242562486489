/* eslint-disable max-len */
//#region Imports
import { jobLists } from "character";
import FormComponents from "components/forms/FormComponents";
import JobIcon from "components/job-icon/JobIcon";
import Link from "components/link/Link";
import NoCharacter from "components/no-character/NoCharacter";
import RelicCheckbox from "components/relic-checkbox/RelicCheckbox";
import { trials } from "data-files/duties";
import { items } from "data-files/items";
import { quests } from "data-files/quests";
import { Item, Job, LinkType, NPC, Quest, RelicGroup, RelicPart, RelicStep, RelicTask, RelicType, Trial, View } from "enums";
import log from "logger";
import manager from "managers/app";
import { Component } from "react";
import { connect } from "react-redux";
import t from "translations/translator";
import { AppState, Character, ItemInfo, ObtainedMethod, Requirement, TaskInfo, TrialInfo, ViewRelicInfo, ViewWeaponAnimaLuxProps } from "types";
import utils from "utils";
import ContentHeader from "views/page-parts/content/content-header/ContentHeader";
import DetailsSection from "views/page-parts/content/details-section/DetailsSection";
import Obtained from "views/page-parts/content/obtained/Obtained";
import OverviewTable from "views/page-parts/content/overview-table/OverviewTable";
import "./WeaponAnimaLux.css";
//#endregion Imports

const mapStateToProps = (state:AppState) => {
	return {
		lang: state.userInfo.options.lang,
		gameLang: state.userInfo.options.gameLang,
		userInfo: state.userInfo,
		options: state.userInfo.options,
		hideCompletedSteps: state.userInfo.options.hideCompletedSteps,
		hideCompletedTasks: state.userInfo.options.hideCompletedTasks,
		showJob: state.userInfo.options.job,
		animaLuxStepJob: state.userInfo.options.relics.animaLuxStepJob,
	};
};

class WeaponAnimaLux extends Component<ViewWeaponAnimaLuxProps> {
	private requirements:Requirement[] = [];
	private importantNPCs:NPC[] = [];
	private relicInfo:ViewRelicInfo = {
		jobs: jobLists.relic[RelicGroup.WEAPON_ANIMA],
		type: RelicType.WEAPON,
		relic: RelicGroup.WEAPON_ANIMA,
		part: RelicPart.WEAPON_LUX,
		steps: [RelicStep.BFF],
		stepPlaceholders: { [RelicStep.BFF]: { QUEST: Quest.BEST_FRIENDS_FOREVER } },
		next: null,
		previous: {
			part: RelicPart.WEAPON_COMPLETE,
			view: View.RELIC_WEAPONS_ANIMA_COMPLETE,
		},
	};

	constructor(props:ViewWeaponAnimaLuxProps){
		super(props);
		this.requirements = this.getRequirements();
		this.importantNPCs = this.getImportantNPCs();
	}

	render(){
		const character = manager.data.getActiveCharacter();
		if(character === null){ return <NoCharacter />; }

		const completeSteps = manager.content.getCompleteSteps(character.progress, this.relicInfo);
		const stepJob = this.props.animaLuxStepJob;
		const taskInfo = this.getTasksInfo(RelicStep.BFF, character, stepJob);

		return (
			<div id="weapon-anima-lux" className="relic">
				<ContentHeader requirements={this.requirements} importantNPCs={this.importantNPCs} relicInfo={this.relicInfo}>
					<p>{t.t("The %PART% upgrade follows on from %PREVIOUSPART%", { PART: this.relicInfo.part, PREVIOUSPART: this.relicInfo.previous ? this.relicInfo.previous.part : "Unknown"})}</p>
					<p>{t.t("Complete")}<Link type={LinkType.LODESTONE} lodestoneType={quests[Quest.BEST_FRIENDS_FOREVER].type} text={quests[Quest.BEST_FRIENDS_FOREVER].name} lodestoneID={quests[Quest.BEST_FRIENDS_FOREVER].lodestoneID} /></p>
				</ContentHeader>
				<div id="relic-checklist">
					<OverviewTable character={character} completeSteps={completeSteps} relicInfo={this.relicInfo} />
				</div>
				<div id="relic-details">
					<h1>{t.t("Details")}</h1>
					{this.props.hideCompletedSteps && completeSteps.allComplete ? t.t("All Steps Complete") : null }
					<DetailsSection title={RelicStep.BFF} placeholders={this.relicInfo.stepPlaceholders[RelicStep.BFF]} id={RelicStep.BFF} stepComplete={completeSteps[RelicStep.BFF]}>
						<div className="job-select">
							<FormComponents.Label
								hide={false}
								translate={true}
								text="Select job to track"
								position="left"
								alignment="center"
							/>
							{this.relicInfo.jobs.map((job) => {
								if(this.props.showJob !== null && this.props.showJob !== job){ return null; }
								const weaponName = manager.relics.getRelicNameKey(job, this.relicInfo.part);
								return <JobIcon key={`${job}`} job={job} tooltip={weaponName} selected={job === stepJob} onClick={() => { this.selectJob(job); }} />;
							})}
						</div>
						<div className="table-wrapper">
							<table className="table">
								<thead><tr><th>{t.t("Type")}</th><th>{t.t("Target")}</th><th>{t.t("Quantity")}</th><th>{t.t("Done")}</th></tr></thead>
								<tbody>
									{
										taskInfo.map((thisTask) => {
											const thisComplete = thisTask.complete;
											if((this.props.hideCompletedSteps || this.props.hideCompletedTasks) && thisComplete){ return null; }

											let thisObtained:ObtainedMethod = {};
											let thisTarget:TrialInfo|ItemInfo|null = null;

											if(utils.guards.isEnumValue(Trial, thisTask.target)){
												thisTarget = trials[thisTask.target];
												thisObtained.duties = { trials: [thisTask.target]};
											}else if(utils.guards.isEnumValue(Item, thisTask.target)){
												thisTarget = items[thisTask.target];
												thisObtained = thisTarget.obtained;
											}
											if(thisTarget === null){ log.error("Wrong info set for this task"); return null; }

											return <tr key={`${RelicStep.BFF}-${thisTask.task}`}>
												<td><Obtained obtained={thisObtained} qtyNeeded={1} /></td>
												<td>{t.t(thisTarget.name)}</td>
												<td>1</td>
												<td><RelicCheckbox character={character} job={stepJob} relicInfo={this.relicInfo} step={RelicStep.BFF} task={thisTask.task} /></td>
											</tr>;
										})
									}
									{
										character.progress.isComplete(this.relicInfo.type, this.relicInfo.relic, this.relicInfo.part, RelicStep.BFF)
											? <tr className="all-complete"><td colSpan={4}><p>{t.t("All Complete")}</p></td></tr>
											: null
									}
								</tbody>
							</table>
						</div>
					</DetailsSection>
				</div>
			</div>
		);
	}

	componentDidMount(){
		manager.view.changeComplete();
	}

	private getRequirements():Requirement[]{
		if(!this.relicInfo || this.relicInfo.previous === null){ return []; }
		return [
			{ type: "previous part", part: this.relicInfo.previous.part, partView: this.relicInfo.previous.view },
			{ type: quests[Quest.BODY_SOUL].type, text: Quest.BODY_SOUL, lodestoneID: quests[Quest.BODY_SOUL].lodestoneID },
		];
	}

	private getImportantNPCs():NPC[]{
		return [NPC.ARDASHIR];
	}

	private getTasksInfo(step:RelicStep, character:Character, job:Job):TaskInfo[]{
		return [
			{ task: RelicTask.TASK_1, complete: character.progress.isComplete(this.relicInfo.type, this.relicInfo.relic, this.relicInfo.part, step, RelicTask.TASK_1, job), target: manager.relics.getTaskDuty(step, RelicTask.TASK_1) },
			{ task: RelicTask.TASK_2, complete: character.progress.isComplete(this.relicInfo.type, this.relicInfo.relic, this.relicInfo.part, step, RelicTask.TASK_2, job), target: manager.relics.getTaskDuty(step, RelicTask.TASK_2) },
			{ task: RelicTask.TASK_3, complete: character.progress.isComplete(this.relicInfo.type, this.relicInfo.relic, this.relicInfo.part, step, RelicTask.TASK_3, job), target: manager.relics.getTaskDuty(step, RelicTask.TASK_3) },
			{ task: RelicTask.TASK_4, complete: character.progress.isComplete(this.relicInfo.type, this.relicInfo.relic, this.relicInfo.part, step, RelicTask.TASK_4, job), target: manager.relics.getTaskDuty(step, RelicTask.TASK_4) },
			{ task: RelicTask.TASK_5, complete: character.progress.isComplete(this.relicInfo.type, this.relicInfo.relic, this.relicInfo.part, step, RelicTask.TASK_5, job), target: manager.relics.getTaskDuty(step, RelicTask.TASK_5) },
			{ task: RelicTask.TASK_6, complete: character.progress.isComplete(this.relicInfo.type, this.relicInfo.relic, this.relicInfo.part, step, RelicTask.TASK_6, job), target: manager.relics.getTaskDuty(step, RelicTask.TASK_6) },
			{ task: RelicTask.TASK_7, complete: character.progress.isComplete(this.relicInfo.type, this.relicInfo.relic, this.relicInfo.part, step, RelicTask.TASK_7, job), target: manager.relics.getTaskDuty(step, RelicTask.TASK_7) },
			{ task: RelicTask.TASK_8, complete: character.progress.isComplete(this.relicInfo.type, this.relicInfo.relic, this.relicInfo.part, step, RelicTask.TASK_8, job), target: manager.relics.getTaskDuty(step, RelicTask.TASK_8) },
			{ task: RelicTask.TASK_9, complete: character.progress.isComplete(this.relicInfo.type, this.relicInfo.relic, this.relicInfo.part, step, RelicTask.TASK_9, job), target: manager.relics.getTaskDuty(step, RelicTask.TASK_9) },
			{ task: RelicTask.TASK_10, complete: character.progress.isComplete(this.relicInfo.type, this.relicInfo.relic, this.relicInfo.part, step, RelicTask.TASK_10, job), target: manager.relics.getTaskDuty(step, RelicTask.TASK_10) },
			{ task: RelicTask.TASK_11, complete: character.progress.isComplete(this.relicInfo.type, this.relicInfo.relic, this.relicInfo.part, step, RelicTask.TASK_11, job), target: manager.relics.getTaskDuty(step, RelicTask.TASK_11) },
			{ task: RelicTask.TASK_12, complete: character.progress.isComplete(this.relicInfo.type, this.relicInfo.relic, this.relicInfo.part, step, RelicTask.TASK_12, job), target: manager.relics.getTaskDuty(step, RelicTask.TASK_12) },
			{ task: RelicTask.TASK_13, complete: character.progress.isComplete(this.relicInfo.type, this.relicInfo.relic, this.relicInfo.part, step, RelicTask.TASK_13, job), target: manager.relics.getTaskItem(step, RelicTask.TASK_13, job) },
		];
	}

	private selectJob(job:Job){ // Event
		const options = this.props.options;
		options.relics = {
			...options.relics,
			animaLuxStepJob: utils.guards.isEnumValue(Job, job) ? job : Job.PLD,
		};
		manager.data.saveOptions(options, true);
	}
}
export default connect(mapStateToProps)(WeaponAnimaLux);
