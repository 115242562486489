//#region Imports
import { LocalKeys, OldLocalKeys } from "enums";
import log from "logger";
import { store } from "managers/state";
import { StoredData, StoredOptions, UserData, UserInfo, UserOptions } from "types";
import sanitise from "./sanitation";
//#endregion Imports

class LocalStorageManager {
	private DataLocation = log.DataLocation.LOCAL;
	private DataOperations = log.DataOperation;

	private getItem(key:LocalKeys.DATA):StoredData|null;
	private getItem(key:LocalKeys.OPTIONS):StoredOptions|null;
	private getItem(key:LocalKeys):string|StoredData|StoredOptions|null{
		log.data(this.DataLocation, this.DataOperations.FETCH, key);
		const data = localStorage.getItem(key);
		if(!data){ return null; }

		try{
			const parsedData:string|StoredData|StoredOptions = JSON.parse(data);
			if(!parsedData){ return null; }
			return parsedData;
		}catch(e){
			log.error(e);
			return null;
		}
	}

	private setItem(key:LocalKeys.DATA, payload:StoredData):boolean;
	private setItem(key:LocalKeys.OPTIONS, payload:StoredOptions):boolean;
	private setItem(key:LocalKeys, payload:string|StoredData|StoredOptions):boolean{
		log.data(this.DataLocation, this.DataOperations.SAVE, key);
		try{
			const stringified = JSON.stringify(payload);
			localStorage.setItem(key, stringified);
			return true;
		}catch(e){
			log.error(e);
			return false;
		}
	}

	cleanKeys(keys:OldLocalKeys[]){
		keys.forEach((key) => {
			localStorage.removeItem(key);
		});
	}

	getUserInfo():UserInfo|null{
		const storedOptions = this.getItem(LocalKeys.OPTIONS);
		const storedData = this.getItem(LocalKeys.DATA);
		if(!storedOptions || !storedData){ return null; }

		const options = sanitise.convertFromStoredOptions(storedOptions);
		const data = sanitise.convertFromStoredData(storedData);
		if(!options || !data){ return null; }

		const user = store.getState().userInfo;
		return {
			isAuthenticated: user.isAuthenticated,
			username: user.username,
			options: options,
			data: data,
		};
	}

	saveOptions(options:UserOptions):boolean{
		const toSave = sanitise.convertToStoredOptions(options);
		if(!toSave){ return false; }

		return this.setItem(LocalKeys.OPTIONS, toSave);
	}

	saveUserData(data:UserData):boolean{
		const toSave = sanitise.convertToStoredData(data);
		if(!toSave){ return false; }

		return this.setItem(LocalKeys.DATA, toSave);
	}
}
const local = new LocalStorageManager();
export default local;
