//#region Imports
import FormComponents from "components/forms/FormComponents";
import { Breakpoint } from "enums";
import manager from "managers/app";
import { actions } from "managers/state";
import { Component } from "react";
import { connect } from "react-redux";
import { MultiValue, SingleValue } from "react-select";
import { Dispatch } from "redux";
import { AppState, Character, CharacterSelectorProps, RSOption, UserOptions } from "types";
import "./CharacterSelector.css";
//#endregion Imports

const mapStateToProps = (state:AppState) => {
	return {
		lang: state.userInfo.options.lang,
		gameLang: state.userInfo.options.gameLang,
		characters: state.userInfo.data.characters,
		userInfo: state.userInfo,
		breakpoint: state.breakpoint,
		options: state.userInfo.options,
		jobOption: state.userInfo.options.job,
	};
};

const mapDispatchToProps = (dispatch:Dispatch) => {
	return {
		setCharacters: (chars:Character[]) => { dispatch(actions.setCharacters(chars)); },
		setUserOptions: (options:UserOptions) => { dispatch(actions.setUserOptions(options)); },
	};
};

class CharacterSelector extends Component<CharacterSelectorProps> {
	render(){
		let labelPosition = this.props.labelPosition;

		if(!labelPosition){
			labelPosition = this.props.breakpoint === Breakpoint.TABLET || this.props.breakpoint === Breakpoint.MOBILE ? "left" : "top";
		}

		const characterOptions:RSOption[] = [];
		const characterDefaultValue:RSOption[] = [];
		let isDisabled = false;

		if(this.props.characters.length === 0){
			isDisabled = true;
		}else{
			this.props.characters.forEach((char) => {
				const thisOption:RSOption = { label: char.displayName, value: char.seId };
				if(char.active){ characterDefaultValue.push(thisOption); }
				characterOptions.push(thisOption);
			});

			if(characterDefaultValue.length === 0){
				characterDefaultValue.push({ label: this.props.characters[0].displayName, value: this.props.characters[0].seId });
			}
		}

		return (
			<div className="character-selector">
				<FormComponents.Form fieldDirection="column">
					<FormComponents.Select
						label={ { hide: false, text: "Character", position: labelPosition, alignment: "center" } }
						input={ { options: characterOptions, value: characterDefaultValue, placeholder: "No Characters", class: ["secondary"], events: { onChange: this.switchChar }, disabled: isDisabled } }
					/>
				</FormComponents.Form>
			</div>
		);
	}

	switchChar = (option:MultiValue<RSOption>|SingleValue<RSOption>) => {
		if(option instanceof Array){ return; } // Should not be the case, This is a single select

		const chars = [...this.props.characters];

		if(option){
			chars.forEach((char) => {
				if(char.seId === option.value){
					char.active = true;
				}else{
					char.active = false;
				}
			});
		}else{
			chars.forEach((char) => { char.active = false; });
			chars[0].active = true;
		}

		manager.data.saveCharacters(chars, true);
	}
}
export default connect(mapStateToProps, mapDispatchToProps)(CharacterSelector);
