/* eslint-disable max-len, brace-style */
//#region Imports
import { jobLists } from "character";
import { Dungeon, Enemy, Fate, Item, Job, Leve, RelicGroup, RelicIndex, RelicPart, RelicStep, RelicTask, RelicType, Trial } from "enums";
import log from "logger";
import { Duty, OutputGameString, Prerequisite, RelicID, RelicImage } from "types";
import utils from "utils";
//#endregion Imports

type AvailableImages = { [key in Job]: string[]; }

export class RelicsManager {
	private availableImages:AvailableImages = { [Job.PLD]: [], [Job.WAR]: [], [Job.DRK]: [], [Job.GNB]: [], [Job.WHM]: [], [Job.SCH]: [], [Job.AST]: [], [Job.SGE]: [], [Job.MNK]: [], [Job.DRG]: [], [Job.NIN]: [], [Job.SAM]: [], [Job.RPR]: [], [Job.BRD]: [], [Job.MCH]: [], [Job.DNC]: [], [Job.BLM]: [], [Job.SMN]: [], [Job.RDM]: [], [Job.CRP]: [], [Job.BSM]: [], [Job.ARM]: [], [Job.GSM]: [], [Job.LTW]: [], [Job.WVR]: [], [Job.ALC]: [], [Job.CUL]: [], [Job.MIN]: [], [Job.BTN]: [], [Job.FSH]: [] }; // eslint-disable-line max-len

	init(){
		log.init(log.InitStatus.START, "Relics");

		let context; // require.context requires string literals due to static dependencies/requirements
		context = require.context("assets/images/relics/PLD", false, /^assets\/.*.(png|jpe?g|svg)$/); this.availableImages[Job.PLD] = context.keys().map(context) as string[];
		context = require.context("assets/images/relics/WAR", false, /^assets\/.*.(png|jpe?g|svg)$/); this.availableImages[Job.WAR] = context.keys().map(context) as string[];
		context = require.context("assets/images/relics/DRK", false, /^assets\/.*.(png|jpe?g|svg)$/); this.availableImages[Job.DRK] = context.keys().map(context) as string[];
		context = require.context("assets/images/relics/GNB", false, /^assets\/.*.(png|jpe?g|svg)$/); this.availableImages[Job.GNB] = context.keys().map(context) as string[];
		context = require.context("assets/images/relics/WHM", false, /^assets\/.*.(png|jpe?g|svg)$/); this.availableImages[Job.WHM] = context.keys().map(context) as string[];
		context = require.context("assets/images/relics/SCH", false, /^assets\/.*.(png|jpe?g|svg)$/); this.availableImages[Job.SCH] = context.keys().map(context) as string[];
		context = require.context("assets/images/relics/AST", false, /^assets\/.*.(png|jpe?g|svg)$/); this.availableImages[Job.AST] = context.keys().map(context) as string[];
		context = require.context("assets/images/relics/SGE", false, /^assets\/.*.(png|jpe?g|svg)$/); this.availableImages[Job.SGE] = context.keys().map(context) as string[];
		context = require.context("assets/images/relics/MNK", false, /^assets\/.*.(png|jpe?g|svg)$/); this.availableImages[Job.MNK] = context.keys().map(context) as string[];
		context = require.context("assets/images/relics/DRG", false, /^assets\/.*.(png|jpe?g|svg)$/); this.availableImages[Job.DRG] = context.keys().map(context) as string[];
		context = require.context("assets/images/relics/NIN", false, /^assets\/.*.(png|jpe?g|svg)$/); this.availableImages[Job.NIN] = context.keys().map(context) as string[];
		context = require.context("assets/images/relics/SAM", false, /^assets\/.*.(png|jpe?g|svg)$/); this.availableImages[Job.SAM] = context.keys().map(context) as string[];
		context = require.context("assets/images/relics/RPR", false, /^assets\/.*.(png|jpe?g|svg)$/); this.availableImages[Job.RPR] = context.keys().map(context) as string[];
		context = require.context("assets/images/relics/BRD", false, /^assets\/.*.(png|jpe?g|svg)$/); this.availableImages[Job.BRD] = context.keys().map(context) as string[];
		context = require.context("assets/images/relics/MCH", false, /^assets\/.*.(png|jpe?g|svg)$/); this.availableImages[Job.MCH] = context.keys().map(context) as string[];
		context = require.context("assets/images/relics/DNC", false, /^assets\/.*.(png|jpe?g|svg)$/); this.availableImages[Job.DNC] = context.keys().map(context) as string[];
		context = require.context("assets/images/relics/BLM", false, /^assets\/.*.(png|jpe?g|svg)$/); this.availableImages[Job.BLM] = context.keys().map(context) as string[];
		context = require.context("assets/images/relics/SMN", false, /^assets\/.*.(png|jpe?g|svg)$/); this.availableImages[Job.SMN] = context.keys().map(context) as string[];
		context = require.context("assets/images/relics/RDM", false, /^assets\/.*.(png|jpe?g|svg)$/); this.availableImages[Job.RDM] = context.keys().map(context) as string[];
		context = require.context("assets/images/relics/CRP", false, /^assets\/.*.(png|jpe?g|svg)$/); this.availableImages[Job.CRP] = context.keys().map(context) as string[];
		context = require.context("assets/images/relics/BSM", false, /^assets\/.*.(png|jpe?g|svg)$/); this.availableImages[Job.BSM] = context.keys().map(context) as string[];
		context = require.context("assets/images/relics/ARM", false, /^assets\/.*.(png|jpe?g|svg)$/); this.availableImages[Job.ARM] = context.keys().map(context) as string[];
		context = require.context("assets/images/relics/GSM", false, /^assets\/.*.(png|jpe?g|svg)$/); this.availableImages[Job.GSM] = context.keys().map(context) as string[];
		context = require.context("assets/images/relics/LTW", false, /^assets\/.*.(png|jpe?g|svg)$/); this.availableImages[Job.LTW] = context.keys().map(context) as string[];
		context = require.context("assets/images/relics/WVR", false, /^assets\/.*.(png|jpe?g|svg)$/); this.availableImages[Job.WVR] = context.keys().map(context) as string[];
		context = require.context("assets/images/relics/ALC", false, /^assets\/.*.(png|jpe?g|svg)$/); this.availableImages[Job.ALC] = context.keys().map(context) as string[];
		context = require.context("assets/images/relics/CUL", false, /^assets\/.*.(png|jpe?g|svg)$/); this.availableImages[Job.CUL] = context.keys().map(context) as string[];
		context = require.context("assets/images/relics/MIN", false, /^assets\/.*.(png|jpe?g|svg)$/); this.availableImages[Job.MIN] = context.keys().map(context) as string[];
		context = require.context("assets/images/relics/BTN", false, /^assets\/.*.(png|jpe?g|svg)$/); this.availableImages[Job.BTN] = context.keys().map(context) as string[];
		context = require.context("assets/images/relics/FSH", false, /^assets\/.*.(png|jpe?g|svg)$/); this.availableImages[Job.FSH] = context.keys().map(context) as string[];

		log.init(log.InitStatus.COMPLETE, "Relics");
	}

	getAllImages():RelicImage[]{
		const relicImages:RelicImage[] = [];

		jobLists.all.forEach((job) => {
			const imageList = this.availableImages[job];
			imageList.forEach((img) => {
				const startIndex = img.indexOf("/static/media/") + "/static/media/".length;
				const endIndex = img.indexOf(".");
				const relic = img.substring(startIndex, endIndex).split("-");
				relicImages.push({
					image: img,
					type: relic[0] as RelicType,
					relic: relic[1] as RelicGroup,
					part: relic[2] as RelicPart,
					job: job,
					relicName: this.getRelicNameKey(job, relic[2] as RelicPart),
				});
			});
		});
		return relicImages;
	}

	getJobName(job:Job):OutputGameString{ // eslint-disable-line complexity
		switch(job){
			default: return "GUnknown";
			case Job.PLD: return "Paladin";
			case Job.WAR: return "Warrior";
			case Job.DRK: return "Dark Knight";
			case Job.GNB: return "Gunbreaker";
			case Job.WHM: return "White Mage";
			case Job.SCH: return "Scholar";
			case Job.AST: return "Astrologian";
			case Job.SGE: return "Sage";
			case Job.MNK: return "Monk";
			case Job.DRG: return "Dragoon";
			case Job.NIN: return "Ninja";
			case Job.SAM: return "Samurai";
			case Job.RPR: return "Reaper";
			case Job.BRD: return "Bard";
			case Job.MCH: return "Machinist";
			case Job.DNC: return "Dancer";
			case Job.BLM: return "Black Mage";
			case Job.SMN: return "Summoner";
			case Job.RDM: return "Red Mage";
			case Job.CRP: return "Carpenter";
			case Job.BSM: return "Blacksmith";
			case Job.ARM: return "Armorer";
			case Job.GSM: return "Goldsmith";
			case Job.LTW: return "Leatherworker";
			case Job.WVR: return "Weaver";
			case Job.ALC: return "Alchemist";
			case Job.CUL: return "Culinarian";
			case Job.MIN: return "Miner";
			case Job.BTN: return "Botanist";
			case Job.FSH: return "Fisher";
		}
	}

	getRelicNameKey(job:Job, part:RelicPart):OutputGameString{
		const key = `relicname-${job}-${part}`;

		if(utils.guards.isOutputGameString(key)){ return key; }
		log.warn(`Unknown Relic Name Key: ${key}`);
		return "GUnknown";
	}

	getPartILvl(part:RelicPart):number{ // eslint-disable-line complexity
		switch(part){
			default: return -1;
			case RelicPart.WEAPON_BASE_WEAPON: return 80;
			case RelicPart.WEAPON_ZENITH: return 90;
			case RelicPart.WEAPON_ATMA: return 100;
			case RelicPart.WEAPON_ANIMUS: return 100;
			case RelicPart.WEAPON_NOVUS: return 110;
			case RelicPart.WEAPON_NEXUS: return 115;
			case RelicPart.WEAPON_BRAVES: return 125;
			case RelicPart.WEAPON_ZETA: return 135;
			case RelicPart.WEAPON_ANIMATED: return 170;
			case RelicPart.WEAPON_AWOKEN: return 200;
			case RelicPart.WEAPON_ANIMA: return 210;
			case RelicPart.WEAPON_HYPERCONDUCTIVE: return 230;
			case RelicPart.WEAPON_RECONDITIONED: return 240;
			case RelicPart.WEAPON_SHARPENED: return 260;
			case RelicPart.WEAPON_COMPLETE: return 270;
			case RelicPart.WEAPON_LUX: return 275;
			case RelicPart.WEAPON_ANTIQUATED: return 290;
			case RelicPart.WEAPON_ANEMOS: return 355;
			case RelicPart.WEAPON_ELEMENTAL: return 370;
			case RelicPart.WEAPON_PYROS: return 385;
			case RelicPart.WEAPON_EUREKA: return 405;
			case RelicPart.WEAPON_PHYSEOS: return 405;
			case RelicPart.WEAPON_RESISTANCE: return 485;
			case RelicPart.WEAPON_AUGMENTED_RESISTANCE: return 500;
			case RelicPart.WEAPON_RECOLLECTION: return 500;
			case RelicPart.WEAPON_LAWS_ORDER: return 510;
			case RelicPart.WEAPON_AUGMENTED_LAWS_ORDER: return 515;
			case RelicPart.WEAPON_BLADE: return 535;
			case RelicPart.WEAPON_MANDERVILLE: return 615;
			case RelicPart.WEAPON_AMAZING_MANDERVILLE: return 630;
			case RelicPart.WEAPON_MAJESTIC_MANDERVILLE: return 645;
			case RelicPart.WEAPON_MANDERVILLOUS: return 665;
			case RelicPart.TOOL_MASTERCRAFT: return 55;
			case RelicPart.TOOL_SUPRA: return 75;
			case RelicPart.TOOL_LUCIS: return 90;
			case RelicPart.TOOL_SKYSTEEL: return 440;
			case RelicPart.TOOL_SKYSTEEL_1: return 455;
			case RelicPart.TOOL_DRAGONSUNG: return 475;
			case RelicPart.TOOL_AUGMENTED_DRAGONSUNG: return 485;
			case RelicPart.TOOL_SKYSUNG: return 500;
			case RelicPart.TOOL_SKYBUILDERS: return 510;
			case RelicPart.TOOL_SPLENDOROUS: return 570;
			case RelicPart.TOOL_AUGMENTED_SPLENDOROUS: return 590;
			case RelicPart.TOOL_CRYSTALLINE: return 620;
			case RelicPart.TOOL_CHORA_ZOIS_CRYSTALLINE: return 625;
			case RelicPart.TOOL_BRILLIANT: return 630;
			case RelicPart.TOOL_VRANDTIC_VISIONARYS: return 635;
			case RelicPart.TOOL_LODESTAR: return 640;
			case RelicPart.ARMOUR_ANTIQUATED: return 290;
			case RelicPart.ARMOUR_BASE_0: return 335;
			case RelicPart.ARMOUR_BASE_1: return 340;
			case RelicPart.ARMOUR_BASE_2: return 345;
			case RelicPart.ARMOUR_ANEMOS: return 350;
			case RelicPart.ARMOUR_ELEMENTAL: return 380;
			case RelicPart.ARMOUR_ELEMENTAL_1: return 390;
			case RelicPart.ARMOUR_ELEMENTAL_2: return 390;
			case RelicPart.ARMOUR_BOZJAN: return 495;
			case RelicPart.ARMOUR_AUGMENTED_BOZJAN: return 495;
			case RelicPart.ARMOUR_LAWS_ORDER: return 510;
			case RelicPart.ARMOUR_AUGMENTED_LAWS_ORDER: return 510;
			case RelicPart.ARMOUR_BLADES: return 525;
		}
	}

	isFirstTimeOnlyStep(step:RelicStep){
		return [
			RelicStep.ARMS,
			RelicStep.TRIALS,
			RelicStep.RADIANCE,
			RelicStep.STAR,
			RelicStep.SOULGLAZE,
			RelicStep.WHEREFORE,
			RelicStep.MATERIA,
			RelicStep.SHINE,
			RelicStep.VITAL,
			RelicStep.REMEMBERS,
			RelicStep.SPARE_PARTS,
			RelicStep.STORY,
			RelicStep.FOND_MEMORY,
			RelicStep.DEAL,
		].includes(step);
	}

	getRelicType(relic:RelicGroup):RelicType{
		const typeStr = relic.substring(2, 3);
		switch(typeStr){
			default:
			case "w": return RelicType.WEAPON;
			case "t": return RelicType.TOOL;
			case "a": return RelicType.ARMOUR;
		}
	}

	getRelicPartsForRelic(relic:RelicGroup):RelicPart[]{
		switch(relic){
			default: return [];
			case RelicGroup.WEAPON_ZODIAC: return [RelicPart.WEAPON_BASE_WEAPON, RelicPart.WEAPON_ZENITH, RelicPart.WEAPON_ATMA, RelicPart.WEAPON_ANIMUS, RelicPart.WEAPON_NOVUS, RelicPart.WEAPON_NEXUS, RelicPart.WEAPON_BRAVES, RelicPart.WEAPON_ZETA];
			case RelicGroup.WEAPON_ANIMA: return [RelicPart.WEAPON_ANIMATED, RelicPart.WEAPON_AWOKEN, RelicPart.WEAPON_ANIMA, RelicPart.WEAPON_HYPERCONDUCTIVE, RelicPart.WEAPON_RECONDITIONED, RelicPart.WEAPON_SHARPENED, RelicPart.WEAPON_COMPLETE, RelicPart.WEAPON_LUX];
			case RelicGroup.WEAPON_EUREKA: return [RelicPart.WEAPON_ANTIQUATED, RelicPart.WEAPON_ANEMOS, RelicPart.WEAPON_ELEMENTAL, RelicPart.WEAPON_PYROS, RelicPart.WEAPON_EUREKA, RelicPart.WEAPON_PHYSEOS];
			case RelicGroup.WEAPON_RESISTANCE: return [RelicPart.WEAPON_RESISTANCE, RelicPart.WEAPON_AUGMENTED_RESISTANCE, RelicPart.WEAPON_RECOLLECTION, RelicPart.WEAPON_LAWS_ORDER, RelicPart.WEAPON_AUGMENTED_LAWS_ORDER, RelicPart.WEAPON_BLADE];
			case RelicGroup.WEAPON_MANDERVILLE: return [RelicPart.WEAPON_MANDERVILLE, RelicPart.WEAPON_AMAZING_MANDERVILLE, RelicPart.WEAPON_MAJESTIC_MANDERVILLE, RelicPart.WEAPON_MANDERVILLOUS];
			case RelicGroup.TOOL_LUCIS: return [RelicPart.TOOL_MASTERCRAFT, RelicPart.TOOL_SUPRA, RelicPart.TOOL_LUCIS];
			case RelicGroup.TOOL_SKYSTEEL: return [RelicPart.TOOL_SKYSTEEL, RelicPart.TOOL_SKYSTEEL_1, RelicPart.TOOL_DRAGONSUNG, RelicPart.TOOL_AUGMENTED_DRAGONSUNG, RelicPart.TOOL_SKYSUNG, RelicPart.TOOL_SKYBUILDERS];
			case RelicGroup.TOOL_SPLENDOROUS: return [RelicPart.TOOL_SPLENDOROUS, RelicPart.TOOL_AUGMENTED_SPLENDOROUS, RelicPart.TOOL_CRYSTALLINE, RelicPart.TOOL_CHORA_ZOIS_CRYSTALLINE, RelicPart.TOOL_BRILLIANT, RelicPart.TOOL_VRANDTIC_VISIONARYS, RelicPart.TOOL_LODESTAR];
			case RelicGroup.ARMOUR_ANEMOS: return [RelicPart.ARMOUR_ANTIQUATED, RelicPart.ARMOUR_BASE_0, RelicPart.ARMOUR_BASE_1, RelicPart.ARMOUR_BASE_2, RelicPart.ARMOUR_ANEMOS];
			case RelicGroup.ARMOUR_ELEMENTAL: return [RelicPart.ARMOUR_ELEMENTAL, RelicPart.ARMOUR_ELEMENTAL_1, RelicPart.ARMOUR_ELEMENTAL_2];
			case RelicGroup.ARMOUR_BOZJAN: return [RelicPart.ARMOUR_BOZJAN, RelicPart.ARMOUR_AUGMENTED_BOZJAN];
			case RelicGroup.ARMOUR_LAWS_ORDER: return [RelicPart.ARMOUR_LAWS_ORDER, RelicPart.ARMOUR_AUGMENTED_LAWS_ORDER];
			case RelicGroup.ARMOUR_BLADES: return [RelicPart.ARMOUR_BLADES];
		}
	}

	private addToPreReqs(preReqs:Prerequisite[], stepArr:RelicStep[], thisType:RelicType, thisRelic:RelicGroup, thisPart:RelicPart, thisStep:RelicStep):Prerequisite[]{ // eslint-disable-line max-params
		let addToReqs = false;
		stepArr.forEach((step, index) => {
			if(thisStep === step){ addToReqs = true; }
			const thisRelicID = `${thisType}-${thisRelic}-${thisPart}-${step}`;

			const nextStep = stepArr[index + 1];
			const nextRelicId = nextStep ? `${thisType}-${thisRelic}-${thisPart}-${nextStep}` : null;

			if(utils.guards.isRelicId(thisRelicID) && (nextRelicId === null || utils.guards.isRelicId(nextRelicId))){
				if(addToReqs && preReqs.findIndex((z) => { return z.id === thisRelicID; }) === -1){
					preReqs.push({ id: thisRelicID, nextId: nextRelicId });
				}
			}
		});

		return preReqs;
	}

	findPrerequisites(relicId:RelicID, value:boolean):Prerequisite[]{
		const relicFromId = utils.helpers.getRelicFromId(relicId);

		if(relicFromId[RelicIndex.RELIC] === RelicGroup.WEAPON_ZODIAC){ return this.getWeaponZodiacPreReqs(relicFromId[RelicIndex.PART], relicFromId[RelicIndex.STEP], value); }
		if(relicFromId[RelicIndex.RELIC] === RelicGroup.WEAPON_ANIMA){ return this.getWeaponAnimaPreReqs(relicFromId[RelicIndex.PART], relicFromId[RelicIndex.STEP], value); }
		if(relicFromId[RelicIndex.RELIC] === RelicGroup.WEAPON_EUREKA){ return this.getWeaponEurekaPreReqs(relicFromId[RelicIndex.PART], relicFromId[RelicIndex.STEP], value); }
		if(relicFromId[RelicIndex.RELIC] === RelicGroup.WEAPON_RESISTANCE){ return this.getWeaponResistancePreReqs(relicFromId[RelicIndex.PART], relicFromId[RelicIndex.STEP], value); }
		if(relicFromId[RelicIndex.RELIC] === RelicGroup.WEAPON_MANDERVILLE){ return this.getWeaponMandervillePreReqs(relicFromId[RelicIndex.PART], relicFromId[RelicIndex.STEP], value); }
		if(relicFromId[RelicIndex.RELIC] === RelicGroup.TOOL_LUCIS){ return this.getToolLucisPreReqs(relicFromId[RelicIndex.PART], relicFromId[RelicIndex.STEP], value); }
		if(relicFromId[RelicIndex.RELIC] === RelicGroup.TOOL_SKYSTEEL){ return this.getToolSkysteelPreReqs(relicFromId[RelicIndex.PART], relicFromId[RelicIndex.STEP], value); }
		if(relicFromId[RelicIndex.RELIC] === RelicGroup.TOOL_SPLENDOROUS){ return this.getToolSplendorousPreReqs(relicFromId[RelicIndex.PART], relicFromId[RelicIndex.STEP], value); }
		return [];
	}

	getWeaponZodiacPreReqs(part:RelicPart, step:RelicStep, value:boolean):Prerequisite[]{ // eslint-disable-line complexity
		const preReqs:Prerequisite[] = [];
		let stepArr:RelicStep[] = [];

		switch(part){
			default: log.warn(`No PreReqs found for ${part}`); return [];
			case RelicPart.WEAPON_BASE_WEAPON:
				stepArr = [RelicStep.OIL, RelicStep.ORE, RelicStep.GALE, RelicStep.EMBER, RelicStep.HYDRA, RelicStep.BEASTMAN, RelicStep.AMDAPOR, RelicStep.CHIMERA, RelicStep.CLASS_WEAPON, RelicStep.BROKEN_WEAPON];
				if(!value){ stepArr.reverse(); }

				this.addToPreReqs(preReqs, stepArr, RelicType.WEAPON, RelicGroup.WEAPON_ZODIAC, part, step);

				if(!value){ preReqs.push(...this.getWeaponZodiacPreReqs(RelicPart.WEAPON_ZENITH, RelicStep.MIST, value)); } // Next part, First step
				break;
			case RelicPart.WEAPON_ZENITH:
				stepArr = [RelicStep.MIST];
				if(!value){ stepArr.reverse(); }

				this.addToPreReqs(preReqs, stepArr, RelicType.WEAPON, RelicGroup.WEAPON_ZODIAC, part, step);

				if(value){ preReqs.push(...this.getWeaponZodiacPreReqs(RelicPart.WEAPON_BASE_WEAPON, RelicStep.OIL, value)); } // Previous part, Last step
				else{ preReqs.push(...this.getWeaponZodiacPreReqs(RelicPart.WEAPON_ATMA, RelicStep.ARMS, value)); } // Next part, First step
				break;
			case RelicPart.WEAPON_ATMA:
				stepArr = [RelicStep.ATMAS, RelicStep.ARMS];
				if(!value){ stepArr.reverse(); }

				this.addToPreReqs(preReqs, stepArr, RelicType.WEAPON, RelicGroup.WEAPON_ZODIAC, part, step);

				if(value){ preReqs.push(...this.getWeaponZodiacPreReqs(RelicPart.WEAPON_ZENITH, RelicStep.MIST, value)); }
				else{ preReqs.push(...this.getWeaponZodiacPreReqs(RelicPart.WEAPON_ANIMUS, RelicStep.TRIALS, value)); }
				break;
			case RelicPart.WEAPON_ANIMUS:
				switch(step){
					default:
					case RelicStep.TRIALS:
						stepArr = [RelicStep.SKYEARTH_1, RelicStep.SKYWIND_2, RelicStep.SKYWIND_1, RelicStep.NETHERFALL_1, RelicStep.SKYFALL_2, RelicStep.SKYFALL_1, RelicStep.NETHERFIRE_1, RelicStep.SKYFIRE_2, RelicStep.SKYFIRE_1]; // eslint-disable-line max-len
						break;
					case RelicStep.SKYFIRE_1:
					case RelicStep.SKYFIRE_2:
					case RelicStep.NETHERFIRE_1:
						stepArr = [RelicStep.NETHERFIRE_1, RelicStep.SKYFIRE_2, RelicStep.SKYFIRE_1];
						break;
					case RelicStep.SKYFALL_1:
					case RelicStep.SKYFALL_2:
					case RelicStep.NETHERFALL_1:
						stepArr = [RelicStep.NETHERFALL_1, RelicStep.SKYFALL_2, RelicStep.SKYFALL_1];
						break;
					case RelicStep.SKYWIND_1:
					case RelicStep.SKYWIND_2:
						stepArr = [RelicStep.SKYWIND_2, RelicStep.SKYWIND_1];
						break;
					case RelicStep.SKYEARTH_1:
						stepArr = [RelicStep.SKYEARTH_1];
						break;
				}

				stepArr.push(RelicStep.TRIALS);

				if(!value){ stepArr.reverse(); }

				this.addToPreReqs(preReqs, stepArr, RelicType.WEAPON, RelicGroup.WEAPON_ZODIAC, part, step);

				if(value){ preReqs.push(...this.getWeaponZodiacPreReqs(RelicPart.WEAPON_ATMA, RelicStep.ATMAS, value)); }
				else{ preReqs.push(...this.getWeaponZodiacPreReqs(RelicPart.WEAPON_NOVUS, RelicStep.RADIANCE, value)); }
				break;
			case RelicPart.WEAPON_NOVUS:
				stepArr = [RelicStep.BINDING, RelicStep.ALEXANDRITE, RelicStep.STAR, RelicStep.SCROLL, RelicStep.RADIANCE];
				if(!value){ stepArr.reverse(); }

				this.addToPreReqs(preReqs, stepArr, RelicType.WEAPON, RelicGroup.WEAPON_ZODIAC, part, step);

				if(value){
					// Will need to add each set of requirements in order to check all
					preReqs.push(...this.getWeaponZodiacPreReqs(RelicPart.WEAPON_ANIMUS, RelicStep.SKYEARTH_1, value));
					preReqs.push(...this.getWeaponZodiacPreReqs(RelicPart.WEAPON_ANIMUS, RelicStep.SKYWIND_2, value));
					preReqs.push(...this.getWeaponZodiacPreReqs(RelicPart.WEAPON_ANIMUS, RelicStep.NETHERFALL_1, value));
					preReqs.push(...this.getWeaponZodiacPreReqs(RelicPart.WEAPON_ANIMUS, RelicStep.NETHERFIRE_1, value));
				}else{
					preReqs.push(...this.getWeaponZodiacPreReqs(RelicPart.WEAPON_NEXUS, RelicStep.SOULGLAZE, value));
				}
				break;
			case RelicPart.WEAPON_NEXUS:
				stepArr = [RelicStep.LIGHT, RelicStep.SOULGLAZE];
				if(!value){ stepArr.reverse(); }

				this.addToPreReqs(preReqs, stepArr, RelicType.WEAPON, RelicGroup.WEAPON_ZODIAC, part, step);

				if(value){ preReqs.push(...this.getWeaponZodiacPreReqs(RelicPart.WEAPON_NOVUS, RelicStep.BINDING, value)); }
				else{ preReqs.push(...this.getWeaponZodiacPreReqs(RelicPart.WEAPON_BRAVES, RelicStep.WHEREFORE, value)); }
				break;
			case RelicPart.WEAPON_BRAVES:
				stepArr = [RelicStep.MATERIA, RelicStep.BRAVES_SCROLL, RelicStep.FLAWLESS_ALEXANDRITE, RelicStep.ZODIUM, RelicStep.SKYLIGHT, RelicStep.WHEREFORE];
				if(!value){ stepArr.reverse(); }

				this.addToPreReqs(preReqs, stepArr, RelicType.WEAPON, RelicGroup.WEAPON_ZODIAC, part, step);

				if(value){ preReqs.push(...this.getWeaponZodiacPreReqs(RelicPart.WEAPON_NEXUS, RelicStep.LIGHT, value)); }
				else{ preReqs.push(...this.getWeaponZodiacPreReqs(RelicPart.WEAPON_ZETA, RelicStep.SHINE, value)); }
				break;
			case RelicPart.WEAPON_ZETA:
				stepArr = [RelicStep.VITAL, RelicStep.SOUL, RelicStep.SHINE];
				if(!value){ stepArr.reverse(); }

				this.addToPreReqs(preReqs, stepArr, RelicType.WEAPON, RelicGroup.WEAPON_ZODIAC, part, step);

				if(value){ preReqs.push(...this.getWeaponZodiacPreReqs(RelicPart.WEAPON_BRAVES, RelicStep.MATERIA, value)); }
				break;
		}
		return preReqs;
	}

	getWeaponAnimaPreReqs(part:RelicPart, step:RelicStep, value:boolean):Prerequisite[]{ // eslint-disable-line complexity
		const preReqs:Prerequisite[] = [];
		let stepArr:RelicStep[] = [];

		switch(part){
			default: log.warn(`No PreReqs found for ${part}`); return [];
			case RelicPart.WEAPON_ANIMATED:
				stepArr = [RelicStep.CRYSTALS];
				if(!value){ stepArr.reverse(); }

				this.addToPreReqs(preReqs, stepArr, RelicType.WEAPON, RelicGroup.WEAPON_ANIMA, part, step);

				if(!value){ preReqs.push(...this.getWeaponAnimaPreReqs(RelicPart.WEAPON_AWOKEN, RelicStep.SNOWCLOAK, value)); }
				break;
			case RelicPart.WEAPON_AWOKEN:
				stepArr = [RelicStep.VAULT, RelicStep.AERY, RelicStep.SOHM, RelicStep.VIGIL, RelicStep.AMDAPOR_AWOKEN, RelicStep.WANDERER, RelicStep.KEEPER, RelicStep.QARN, RelicStep.SASTASHA, RelicStep.SNOWCLOAK];
				if(!value){ stepArr.reverse(); }

				this.addToPreReqs(preReqs, stepArr, RelicType.WEAPON, RelicGroup.WEAPON_ANIMA, part, step);

				if(value){ preReqs.push(...this.getWeaponAnimaPreReqs(RelicPart.WEAPON_ANIMATED, RelicStep.CRYSTALS, value)); }
				else{ preReqs.push(...this.getWeaponAnimaPreReqs(RelicPart.WEAPON_ANIMA, RelicStep.RUBBER, value)); }
				break;
			case RelicPart.WEAPON_ANIMA:
				stepArr = [RelicStep.CATALYST, RelicStep.WATER, RelicStep.CARBONCOAT, RelicStep.RUBBER];
				if(!value){ stepArr.reverse(); }

				this.addToPreReqs(preReqs, stepArr, RelicType.WEAPON, RelicGroup.WEAPON_ANIMA, part, step);

				if(value){ preReqs.push(...this.getWeaponAnimaPreReqs(RelicPart.WEAPON_AWOKEN, RelicStep.VAULT, value)); }
				else{ preReqs.push(...this.getWeaponAnimaPreReqs(RelicPart.WEAPON_HYPERCONDUCTIVE, RelicStep.OIL_HYPER, value)); }
				break;
			case RelicPart.WEAPON_HYPERCONDUCTIVE:
				stepArr = [RelicStep.OIL_HYPER];
				if(!value){ stepArr.reverse(); }

				this.addToPreReqs(preReqs, stepArr, RelicType.WEAPON, RelicGroup.WEAPON_ANIMA, part, step);

				if(value){ preReqs.push(...this.getWeaponAnimaPreReqs(RelicPart.WEAPON_ANIMA, RelicStep.CATALYST, value)); }
				else{ preReqs.push(...this.getWeaponAnimaPreReqs(RelicPart.WEAPON_RECONDITIONED, RelicStep.SAND, value)); }
				break;
			case RelicPart.WEAPON_RECONDITIONED:
				stepArr = [RelicStep.SAND];
				if(!value){ stepArr.reverse(); }

				this.addToPreReqs(preReqs, stepArr, RelicType.WEAPON, RelicGroup.WEAPON_ANIMA, part, step);

				if(value){ preReqs.push(...this.getWeaponAnimaPreReqs(RelicPart.WEAPON_HYPERCONDUCTIVE, RelicStep.OIL_HYPER, value)); }
				else{ preReqs.push(...this.getWeaponAnimaPreReqs(RelicPart.WEAPON_SHARPENED, RelicStep.CLUSTERS, value)); }
				break;
			case RelicPart.WEAPON_SHARPENED:
				stepArr = [RelicStep.CLUSTERS];
				if(!value){ stepArr.reverse(); }

				this.addToPreReqs(preReqs, stepArr, RelicType.WEAPON, RelicGroup.WEAPON_ANIMA, part, step);

				if(value){ preReqs.push(...this.getWeaponAnimaPreReqs(RelicPart.WEAPON_RECONDITIONED, RelicStep.SAND, value)); }
				else{ preReqs.push(...this.getWeaponAnimaPreReqs(RelicPart.WEAPON_COMPLETE, RelicStep.DUNGEONS, value)); }
				break;
			case RelicPart.WEAPON_COMPLETE:
				stepArr = [RelicStep.DENSITY, RelicStep.PNEUMITE, RelicStep.DUNGEONS];
				if(!value){ stepArr.reverse(); }

				this.addToPreReqs(preReqs, stepArr, RelicType.WEAPON, RelicGroup.WEAPON_ANIMA, part, step);

				if(value){ preReqs.push(...this.getWeaponAnimaPreReqs(RelicPart.WEAPON_SHARPENED, RelicStep.CLUSTERS, value)); }
				else{ preReqs.push(...this.getWeaponAnimaPreReqs(RelicPart.WEAPON_LUX, RelicStep.BFF, value)); }
				break;
			case RelicPart.WEAPON_LUX:
				stepArr = [RelicStep.BFF];
				if(!value){ stepArr.reverse(); }

				this.addToPreReqs(preReqs, stepArr, RelicType.WEAPON, RelicGroup.WEAPON_ANIMA, part, step);

				if(value){ preReqs.push(...this.getWeaponAnimaPreReqs(RelicPart.WEAPON_COMPLETE, RelicStep.DENSITY, value)); }
				break;
		}
		return preReqs;
	}

	getWeaponEurekaPreReqs(part:RelicPart, step:RelicStep, value:boolean):Prerequisite[]{
		const preReqs:Prerequisite[] = [];
		let stepArr:RelicStep[] = [];

		switch(part){
			default: log.warn(`No PreReqs found for ${part}`); return [];
			case RelicPart.WEAPON_ANTIQUATED:
				stepArr = [RelicStep.ANTIQUATED]; // Steps in reverse order
				if(!value){ stepArr.reverse(); }

				this.addToPreReqs(preReqs, stepArr, RelicType.WEAPON, RelicGroup.WEAPON_EUREKA, part, step);

				if(!value){ preReqs.push(...this.getWeaponEurekaPreReqs(RelicPart.WEAPON_ANEMOS, RelicStep.ANEMOS_0, value)); } // Next Part, First Step
				break;
			case RelicPart.WEAPON_ANEMOS:
				stepArr = [RelicStep.ANEMOS, RelicStep.ANEMOS_2, RelicStep.ANEMOS_1, RelicStep.ANEMOS_0]; // Steps in reverse order
				if(!value){ stepArr.reverse(); }

				this.addToPreReqs(preReqs, stepArr, RelicType.WEAPON, RelicGroup.WEAPON_EUREKA, part, step);

				if(value){ preReqs.push(...this.getWeaponEurekaPreReqs(RelicPart.WEAPON_ANTIQUATED, RelicStep.ANTIQUATED, value)); } // Previous part, Last step
				else{ preReqs.push(...this.getWeaponEurekaPreReqs(RelicPart.WEAPON_ELEMENTAL, RelicStep.PAGOS, value)); } // Next part, First step
				break;
			case RelicPart.WEAPON_ELEMENTAL:
				stepArr = [RelicStep.ELEMENTAL, RelicStep.PAGOS_1, RelicStep.PAGOS]; // Steps in reverse order
				if(!value){ stepArr.reverse(); }

				this.addToPreReqs(preReqs, stepArr, RelicType.WEAPON, RelicGroup.WEAPON_EUREKA, part, step);

				if(value){ preReqs.push(...this.getWeaponEurekaPreReqs(RelicPart.WEAPON_ANEMOS, RelicStep.ANEMOS, value)); } // Previous part, Last step
				else{ preReqs.push(...this.getWeaponEurekaPreReqs(RelicPart.WEAPON_PYROS, RelicStep.ELEMENTAL_1, value)); } // Next part, First step
				break;
			case RelicPart.WEAPON_PYROS:
				stepArr = [RelicStep.PYROS, RelicStep.ELEMENTAL_2, RelicStep.ELEMENTAL_1]; // Steps in reverse order
				if(!value){ stepArr.reverse(); }

				this.addToPreReqs(preReqs, stepArr, RelicType.WEAPON, RelicGroup.WEAPON_EUREKA, part, step);

				if(value){ preReqs.push(...this.getWeaponEurekaPreReqs(RelicPart.WEAPON_ELEMENTAL, RelicStep.ELEMENTAL, value)); } // Previous part, Last step
				else{ preReqs.push(...this.getWeaponEurekaPreReqs(RelicPart.WEAPON_EUREKA, RelicStep.HYDATOS, value)); } // Next part, First step
				break;
			case RelicPart.WEAPON_EUREKA:
				stepArr = [RelicStep.EUREKA, RelicStep.BASE, RelicStep.HYDATOS_1, RelicStep.HYDATOS]; // Steps in reverse order
				if(!value){ stepArr.reverse(); }

				this.addToPreReqs(preReqs, stepArr, RelicType.WEAPON, RelicGroup.WEAPON_EUREKA, part, step);

				if(value){ preReqs.push(...this.getWeaponEurekaPreReqs(RelicPart.WEAPON_PYROS, RelicStep.PYROS, value)); } // Previous part, Last step
				else{ preReqs.push(...this.getWeaponEurekaPreReqs(RelicPart.WEAPON_PHYSEOS, RelicStep.FRAGMENTS, value)); } // Next part, First step
				break;
			case RelicPart.WEAPON_PHYSEOS:
				stepArr = [RelicStep.FRAGMENTS]; // Steps in reverse order
				if(!value){ stepArr.reverse(); }

				this.addToPreReqs(preReqs, stepArr, RelicType.WEAPON, RelicGroup.WEAPON_EUREKA, part, step);

				if(value){ preReqs.push(...this.getWeaponEurekaPreReqs(RelicPart.WEAPON_EUREKA, RelicStep.EUREKA, value)); } // Previous part, Last step
				break;
		}
		return preReqs;
	}

	getWeaponResistancePreReqs(part:RelicPart, step:RelicStep, value:boolean):Prerequisite[]{
		const preReqs:Prerequisite[] = [];
		let stepArr:RelicStep[] = [];

		switch(part){
			default: log.warn(`No PreReqs found for ${part}`); return [];
			case RelicPart.WEAPON_RESISTANCE:
				stepArr = [RelicStep.SCALEPOWDER]; // Steps in reverse order
				if(!value){ stepArr.reverse(); }

				this.addToPreReqs(preReqs, stepArr, RelicType.WEAPON, RelicGroup.WEAPON_RESISTANCE, part, step);

				if(!value){ preReqs.push(...this.getWeaponResistancePreReqs(RelicPart.WEAPON_AUGMENTED_RESISTANCE, RelicStep.MEMORY, value)); } // Next Part, First Step
				break;
			case RelicPart.WEAPON_AUGMENTED_RESISTANCE:
				stepArr = [RelicStep.MEMORY]; // Steps in reverse order
				if(!value){ stepArr.reverse(); }

				this.addToPreReqs(preReqs, stepArr, RelicType.WEAPON, RelicGroup.WEAPON_RESISTANCE, part, step);

				if(value){ preReqs.push(...this.getWeaponResistancePreReqs(RelicPart.WEAPON_RESISTANCE, RelicStep.SCALEPOWDER, value)); } // Previous part, Last step
				else{ preReqs.push(...this.getWeaponResistancePreReqs(RelicPart.WEAPON_RECOLLECTION, RelicStep.RESIST, value)); } // Next part, First step
				break;
			case RelicPart.WEAPON_RECOLLECTION:
				stepArr = [RelicStep.RESIST]; // Steps in reverse order
				if(!value){ stepArr.reverse(); }

				this.addToPreReqs(preReqs, stepArr, RelicType.WEAPON, RelicGroup.WEAPON_RESISTANCE, part, step);

				if(value){ preReqs.push(...this.getWeaponResistancePreReqs(RelicPart.WEAPON_AUGMENTED_RESISTANCE, RelicStep.MEMORY, value)); } // Previous part, Last step
				else{ preReqs.push(...this.getWeaponResistancePreReqs(RelicPart.WEAPON_LAWS_ORDER, RelicStep.CHANGE_OF_ARMS, value)); } // Next part, First step
				break;
			case RelicPart.WEAPON_LAWS_ORDER:
				stepArr = [RelicStep.CHANGE_OF_ARMS]; // Steps in reverse order
				if(!value){ stepArr.reverse(); }

				this.addToPreReqs(preReqs, stepArr, RelicType.WEAPON, RelicGroup.WEAPON_RESISTANCE, part, step);

				if(value){ preReqs.push(...this.getWeaponResistancePreReqs(RelicPart.WEAPON_RECOLLECTION, RelicStep.RESIST, value)); } // Previous part, Last step
				else{ preReqs.push(...this.getWeaponResistancePreReqs(RelicPart.WEAPON_AUGMENTED_LAWS_ORDER, RelicStep.REMEMBERS, value)); } // Next part, First step
				break;
			case RelicPart.WEAPON_AUGMENTED_LAWS_ORDER:
				stepArr = [RelicStep.PATH, RelicStep.REMEMBERS]; // Steps in reverse order
				if(!value){ stepArr.reverse(); }

				this.addToPreReqs(preReqs, stepArr, RelicType.WEAPON, RelicGroup.WEAPON_RESISTANCE, part, step);

				if(value){ preReqs.push(...this.getWeaponResistancePreReqs(RelicPart.WEAPON_LAWS_ORDER, RelicStep.CHANGE_OF_ARMS, value)); } // Previous part, Last step
				else{ preReqs.push(...this.getWeaponResistancePreReqs(RelicPart.WEAPON_BLADE, RelicStep.SPARE_PARTS, value)); } // Next part, First step
				break;
			case RelicPart.WEAPON_BLADE:
				stepArr = [RelicStep.IRRESISTIBLE, RelicStep.DEAL, RelicStep.FOND_MEMORY, RelicStep.STORY, RelicStep.SPARE_PARTS]; // Steps in reverse order
				if(!value){ stepArr.reverse(); }

				this.addToPreReqs(preReqs, stepArr, RelicType.WEAPON, RelicGroup.WEAPON_RESISTANCE, part, step);

				if(value){ preReqs.push(...this.getWeaponResistancePreReqs(RelicPart.WEAPON_AUGMENTED_LAWS_ORDER, RelicStep.PATH, value)); } // Previous part, Last step
				break;
		}
		return preReqs;
	}

	getWeaponMandervillePreReqs(part:RelicPart, step:RelicStep, value:boolean):Prerequisite[]{
		const preReqs:Prerequisite[] = [];
		let stepArr:RelicStep[] = [];

		switch(part){
			default: log.warn(`No PreReqs found for ${part}`); return [];
			case RelicPart.WEAPON_MANDERVILLE:
				stepArr = [RelicStep.MANDERVILLE]; // Steps in reverse order
				if(!value){ stepArr.reverse(); }

				this.addToPreReqs(preReqs, stepArr, RelicType.WEAPON, RelicGroup.WEAPON_MANDERVILLE, part, step);

				if(!value){ preReqs.push(...this.getWeaponMandervillePreReqs(RelicPart.WEAPON_AMAZING_MANDERVILLE, RelicStep.AMAZING_MANDERVILLE, value)); } // Next Part, First Step
				break;
			case RelicPart.WEAPON_AMAZING_MANDERVILLE:
				stepArr = [RelicStep.AMAZING_MANDERVILLE]; // Steps in reverse order
				if(!value){ stepArr.reverse(); }

				this.addToPreReqs(preReqs, stepArr, RelicType.WEAPON, RelicGroup.WEAPON_MANDERVILLE, part, step);

				if(value){ preReqs.push(...this.getWeaponMandervillePreReqs(RelicPart.WEAPON_MANDERVILLE, RelicStep.MANDERVILLE, value)); } // Previous part, Last step
				else{ preReqs.push(...this.getWeaponMandervillePreReqs(RelicPart.WEAPON_MAJESTIC_MANDERVILLE, RelicStep.MAJESTIC_MANDERVILLE, value)); } // Next part, First step
				break;
			case RelicPart.WEAPON_MAJESTIC_MANDERVILLE:
				stepArr = [RelicStep.MAJESTIC_MANDERVILLE]; // Steps in reverse order
				if(!value){ stepArr.reverse(); }

				this.addToPreReqs(preReqs, stepArr, RelicType.WEAPON, RelicGroup.WEAPON_MANDERVILLE, part, step);

				if(value){ preReqs.push(...this.getWeaponMandervillePreReqs(RelicPart.WEAPON_AMAZING_MANDERVILLE, RelicStep.AMAZING_MANDERVILLE, value)); } // Previous part, Last step
				else{ preReqs.push(...this.getWeaponMandervillePreReqs(RelicPart.WEAPON_MANDERVILLOUS, RelicStep.MANDERVILLOUS, value)); } // Next part, First step
				break;
			case RelicPart.WEAPON_MANDERVILLOUS:
				stepArr = [RelicStep.MANDERVILLOUS]; // Steps in reverse order
				if(!value){ stepArr.reverse(); }

				this.addToPreReqs(preReqs, stepArr, RelicType.WEAPON, RelicGroup.WEAPON_MANDERVILLE, part, step);

				if(value){ preReqs.push(...this.getWeaponMandervillePreReqs(RelicPart.WEAPON_MAJESTIC_MANDERVILLE, RelicStep.MAJESTIC_MANDERVILLE, value)); } // Previous part, Last step
				break;
		}
		return preReqs;
	}

	getToolLucisPreReqs(part:RelicPart, step:RelicStep, value:boolean):Prerequisite[]{
		const preReqs:Prerequisite[] = [];
		let stepArr:RelicStep[] = [];

		switch(part){
			default: log.warn(`No PreReqs found for ${part}`); return [];
			case RelicPart.TOOL_MASTERCRAFT:
				stepArr = [RelicStep.CLASS_QUESTS];
				this.addToPreReqs(preReqs, stepArr, RelicType.TOOL, RelicGroup.TOOL_LUCIS, part, step);

				if(!value){ preReqs.push(...this.getToolLucisPreReqs(RelicPart.TOOL_SUPRA, RelicStep.SUPRA_ITEMS, value)); } // Next part, First step
				break;
			case RelicPart.TOOL_SUPRA:
				stepArr = [RelicStep.SUPRA_ITEMS];
				this.addToPreReqs(preReqs, stepArr, RelicType.TOOL, RelicGroup.TOOL_LUCIS, part, step);

				if(value){ preReqs.push(...this.getToolLucisPreReqs(RelicPart.TOOL_MASTERCRAFT, RelicStep.CLASS_QUESTS, value)); }  // Previous part, Last step
				else{ preReqs.push(...this.getToolLucisPreReqs(RelicPart.TOOL_LUCIS, RelicStep.LUCIS_ITEMS, value)); } // Next part, First step
				break;
			case RelicPart.TOOL_LUCIS:
				stepArr = [RelicStep.LUCIS_ITEMS];
				this.addToPreReqs(preReqs, stepArr, RelicType.TOOL, RelicGroup.TOOL_LUCIS, part, step);

				if(value){ preReqs.push(...this.getToolLucisPreReqs(RelicPart.TOOL_SUPRA, RelicStep.SUPRA_ITEMS, value)); }  // Previous part, Last step
				break;
		}
		return preReqs;
	}

	getToolSkysteelPreReqs(part:RelicPart, step:RelicStep, value:boolean):Prerequisite[]{
		const preReqs:Prerequisite[] = [];
		let stepArr:RelicStep[] = [];

		switch(part){
			default: log.warn(`No PreReqs found for ${part}`); return [];
			case RelicPart.TOOL_SKYSTEEL:
				stepArr = [RelicStep.PURCHASE];
				this.addToPreReqs(preReqs, stepArr, RelicType.TOOL, RelicGroup.TOOL_SKYSTEEL, part, step);

				if(!value){ preReqs.push(...this.getToolSkysteelPreReqs(RelicPart.TOOL_SKYSTEEL_1, RelicStep.SKYSTEEL_ITEMS, value)); } // Next part, First step
				break;
			case RelicPart.TOOL_SKYSTEEL_1:
				stepArr = [RelicStep.SKYSTEEL_ITEMS];
				this.addToPreReqs(preReqs, stepArr, RelicType.TOOL, RelicGroup.TOOL_SKYSTEEL, part, step);

				if(value){ preReqs.push(...this.getToolSkysteelPreReqs(RelicPart.TOOL_SKYSTEEL, RelicStep.PURCHASE, value)); }  // Previous part, Last step
				else{ preReqs.push(...this.getToolSkysteelPreReqs(RelicPart.TOOL_DRAGONSUNG, RelicStep.DRAGONSUNG_ITEMS, value)); } // Next part, First step
				break;
			case RelicPart.TOOL_DRAGONSUNG:
				stepArr = [RelicStep.DRAGONSUNG_ITEMS];
				this.addToPreReqs(preReqs, stepArr, RelicType.TOOL, RelicGroup.TOOL_SKYSTEEL, part, step);

				if(value){ preReqs.push(...this.getToolSkysteelPreReqs(RelicPart.TOOL_SKYSTEEL_1, RelicStep.SKYSTEEL_ITEMS, value)); }  // Previous part, Last step
				else{ preReqs.push(...this.getToolSkysteelPreReqs(RelicPart.TOOL_AUGMENTED_DRAGONSUNG, RelicStep.BASIC_GOBBIEGOO, value)); } // Next part, First step
				break;
			case RelicPart.TOOL_AUGMENTED_DRAGONSUNG:
				stepArr = [RelicStep.BASIC_GOBBIEGOO];
				this.addToPreReqs(preReqs, stepArr, RelicType.TOOL, RelicGroup.TOOL_SKYSTEEL, part, step);

				if(value){ preReqs.push(...this.getToolSkysteelPreReqs(RelicPart.TOOL_DRAGONSUNG, RelicStep.DRAGONSUNG_ITEMS, value)); }  // Previous part, Last step
				else{ preReqs.push(...this.getToolSkysteelPreReqs(RelicPart.TOOL_SKYSUNG, RelicStep.VISCOUS_GOBBIEGOO, value)); } // Next part, First step
				break;
			case RelicPart.TOOL_SKYSUNG:
				stepArr = [RelicStep.VISCOUS_GOBBIEGOO];
				this.addToPreReqs(preReqs, stepArr, RelicType.TOOL, RelicGroup.TOOL_SKYSTEEL, part, step);

				if(value){ preReqs.push(...this.getToolSkysteelPreReqs(RelicPart.TOOL_AUGMENTED_DRAGONSUNG, RelicStep.BASIC_GOBBIEGOO, value)); }  // Previous part, Last step
				else{ preReqs.push(...this.getToolSkysteelPreReqs(RelicPart.TOOL_SKYBUILDERS, RelicStep.PARTS, value)); } // Next part, First step
				break;
			case RelicPart.TOOL_SKYBUILDERS:
				stepArr = [RelicStep.PARTS];
				this.addToPreReqs(preReqs, stepArr, RelicType.TOOL, RelicGroup.TOOL_SKYSTEEL, part, step);

				if(value){ preReqs.push(...this.getToolSkysteelPreReqs(RelicPart.TOOL_SKYSUNG, RelicStep.VISCOUS_GOBBIEGOO, value)); }  // Previous part, Last step
				break;
		}
		return preReqs;
	}

	getToolSplendorousPreReqs(part:RelicPart, step:RelicStep, value:boolean):Prerequisite[]{
		const preReqs:Prerequisite[] = [];
		let stepArr:RelicStep[] = [];

		switch(part){
			default: log.warn(`No PreReqs found for ${part}`); return [];
			case RelicPart.TOOL_SPLENDOROUS:
				stepArr = [RelicStep.SPLENDOROUS_PURCHASE];
				this.addToPreReqs(preReqs, stepArr, RelicType.TOOL, RelicGroup.TOOL_SPLENDOROUS, part, step);

				if(!value){ preReqs.push(...this.getToolSplendorousPreReqs(RelicPart.TOOL_AUGMENTED_SPLENDOROUS, RelicStep.SPLENDOROUS_COMPONENT, value)); } // Next part, First step
				break;
			case RelicPart.TOOL_AUGMENTED_SPLENDOROUS:
				stepArr = [RelicStep.SPLENDOROUS_COMPONENT];
				this.addToPreReqs(preReqs, stepArr, RelicType.TOOL, RelicGroup.TOOL_SPLENDOROUS, part, step);

				if(value){ preReqs.push(...this.getToolSplendorousPreReqs(RelicPart.TOOL_SPLENDOROUS, RelicStep.SPLENDOROUS_PURCHASE, value)); } // Previous part, Last step
				else{ preReqs.push(...this.getToolSplendorousPreReqs(RelicPart.TOOL_CRYSTALLINE, RelicStep.ADAPTIVE_COMPONENT, value)); } // Next part, First step
				break;
			case RelicPart.TOOL_CRYSTALLINE:
				stepArr = [RelicStep.ADAPTIVE_COMPONENT];
				this.addToPreReqs(preReqs, stepArr, RelicType.TOOL, RelicGroup.TOOL_SPLENDOROUS, part, step);

				if(value){ preReqs.push(...this.getToolSplendorousPreReqs(RelicPart.TOOL_AUGMENTED_SPLENDOROUS, RelicStep.SPLENDOROUS_COMPONENT, value)); } // Previous part, Last step
				else{ preReqs.push(...this.getToolSplendorousPreReqs(RelicPart.TOOL_CHORA_ZOIS_CRYSTALLINE, RelicStep.CUSTOMISED_COMPONENT, value)); } // Next part, First step
				break;
			case RelicPart.TOOL_CHORA_ZOIS_CRYSTALLINE:
				stepArr = [RelicStep.CUSTOMISED_COMPONENT];
				this.addToPreReqs(preReqs, stepArr, RelicType.TOOL, RelicGroup.TOOL_SPLENDOROUS, part, step);

				if(value){ preReqs.push(...this.getToolSplendorousPreReqs(RelicPart.TOOL_CRYSTALLINE, RelicStep.ADAPTIVE_COMPONENT, value)); } // Previous part, Last step
				else{ preReqs.push(...this.getToolSplendorousPreReqs(RelicPart.TOOL_BRILLIANT, RelicStep.BRILLIANT_COMPONENT, value)); } // Next part, First step
				break;
			case RelicPart.TOOL_BRILLIANT:
				stepArr = [RelicStep.BRILLIANT_COMPONENT];
				this.addToPreReqs(preReqs, stepArr, RelicType.TOOL, RelicGroup.TOOL_SPLENDOROUS, part, step);

				if(value){ preReqs.push(...this.getToolSplendorousPreReqs(RelicPart.TOOL_CHORA_ZOIS_CRYSTALLINE, RelicStep.CUSTOMISED_COMPONENT, value)); } // Previous part, Last step
				else{ preReqs.push(...this.getToolSplendorousPreReqs(RelicPart.TOOL_VRANDTIC_VISIONARYS, RelicStep.INSPIRATIONAL_COMPONENT, value)); } // Next part, First step
				break;
			case RelicPart.TOOL_VRANDTIC_VISIONARYS:
				stepArr = [RelicStep.INSPIRATIONAL_COMPONENT];
				this.addToPreReqs(preReqs, stepArr, RelicType.TOOL, RelicGroup.TOOL_SPLENDOROUS, part, step);

				if(value){ preReqs.push(...this.getToolSplendorousPreReqs(RelicPart.TOOL_BRILLIANT, RelicStep.BRILLIANT_COMPONENT, value)); } // Previous part, Last step
				else{ preReqs.push(...this.getToolSplendorousPreReqs(RelicPart.TOOL_LODESTAR, RelicStep.NIGHTFORGED_COMPONENT, value)); } // Next part, First step
				break;
			case RelicPart.TOOL_LODESTAR:
				stepArr = [RelicStep.NIGHTFORGED_COMPONENT];
				this.addToPreReqs(preReqs, stepArr, RelicType.TOOL, RelicGroup.TOOL_SPLENDOROUS, part, step);

				if(value){ preReqs.push(...this.getToolSplendorousPreReqs(RelicPart.TOOL_VRANDTIC_VISIONARYS, RelicStep.INSPIRATIONAL_COMPONENT, value)); } // Previous part, Last step
				break;
		}
		return preReqs;
	}

	// eslint-disable-next-line complexity
	getTaskItem(step:RelicStep, task:RelicTask, job?:Job):Item{
		switch(step){
			default: return Item.UNKNOWN;
			case RelicStep.CLASS_WEAPON: return job ? this.getClassWeaponItem(task, job) : Item.UNKNOWN;
			case RelicStep.OIL: return Item.QUENCHING_OIL;
			case RelicStep.MIST: return Item.THAVNAIRIAN_MIST;
			case RelicStep.ATMAS: return this.getAtmasItem(task);
			case RelicStep.SCROLL: return Item.SUPERIOR_ENCHANTED_INK;
			case RelicStep.ALEXANDRITE: return Item.ALEXANDRITE;
			case RelicStep.LIGHT: return Item.LIGHT;
			case RelicStep.SKYLIGHT: return this.getSkylightItem(task);
			case RelicStep.ZODIUM: return this.getZodiumItem(task);
			case RelicStep.FLAWLESS_ALEXANDRITE: return this.getFlawlessAlexandriteItem(task);
			case RelicStep.BRAVES_SCROLL: return this.getBravesScrollItem(task);
			case RelicStep.SOUL: return this.getSoulItem(task);
			case RelicStep.CRYSTALS: return this.getCrystalsItem(task);
			case RelicStep.RUBBER: return task === RelicTask.TASK_1 ? Item.UNIDENTIFIABLE_BONE : Item.ADAMANTITE_FRANCESCA;
			case RelicStep.CARBONCOAT: return task === RelicTask.TASK_1 ? Item.UNIDENTIFIABLE_SHELL : Item.TITANIUM_ALLOY_MIRROR;
			case RelicStep.WATER: return task === RelicTask.TASK_1 ? Item.UNIDENTIFIABLE_ORE : Item.DISPELLING_ARROW;
			case RelicStep.CATALYST: return task === RelicTask.TASK_1 ? Item.UNIDENTIFIABLE_SEEDS : Item.KINGCAKE;
			case RelicStep.OIL_HYPER: return Item.AETHER_OIL;
			case RelicStep.SAND: return task === RelicTask.TASK_1 ? Item.CRYSTAL_SAND : Item.UMBRITE;
			case RelicStep.CLUSTERS: return Item.SINGING_CLUSTER;
			case RelicStep.PNEUMITE: return Item.PNEUMITE;
			case RelicStep.DENSITY: return Item.AETHERIC_DENSITY;
			case RelicStep.BFF: return Item.ARCHAIC_ENCHANTED_INK;
			case RelicStep.ANEMOS_0: return Item.PROTEAN_CRYSTAL;
			case RelicStep.ANEMOS_1: return Item.PROTEAN_CRYSTAL;
			case RelicStep.ANEMOS_2: return Item.PROTEAN_CRYSTAL;
			case RelicStep.ANEMOS: return Item.PAZUZUS_FEATHER;
			case RelicStep.PAGOS: return Item.FROSTED_PROTEAN_CRYSTAL;
			case RelicStep.PAGOS_1: return task === RelicTask.TASK_1 ? Item.PAGOS_CRYSTAL : Item.FROSTED_PROTEAN_CRYSTAL;
			case RelicStep.ELEMENTAL: return task === RelicTask.TASK_1 ? Item.FROSTED_PROTEAN_CRYSTAL : Item.LOUHIS_ICE;
			case RelicStep.ELEMENTAL_1: return Item.PYROS_CRYSTAL;
			case RelicStep.ELEMENTAL_2: return Item.PYROS_CRYSTAL;
			case RelicStep.PYROS: return task === RelicTask.TASK_1 ? Item.PYROS_CRYSTAL : Item.PENTHESILEAS_FLAME;
			case RelicStep.HYDATOS: return Item.HYDATOS_CRYSTAL;
			case RelicStep.HYDATOS_1: return Item.HYDATOS_CRYSTAL;
			case RelicStep.BASE: return Item.HYDATOS_CRYSTAL;
			case RelicStep.EUREKA: return task === RelicTask.TASK_1 ? Item.HYDATOS_CRYSTAL : Item.CRYSTALLINE_SCALE;
			case RelicStep.FRAGMENTS: return Item.EUREKA_FRAGMENT;
			case RelicStep.SCALEPOWDER: return Item.THAVNAIRIAN_SCALEPOWDER;
			case RelicStep.MEMORY: return this.getMemoryItem(task);
			case RelicStep.RESIST: return Item.BITTER_MEMORY_OF_THE_DYING;
			case RelicStep.CHANGE_OF_ARMS: return Item.LOATHSOME_MEMORY_OF_THE_DYING;
			case RelicStep.REMEMBERS: return task === RelicTask.TASK_1 ? Item.HAUNTING_MEMORY_OF_THE_DYING : Item.VEXATIOUS_MEMORY_OF_THE_DYING;
			case RelicStep.PATH: return Item.TIMEWORN_ARTIFACTS;
			case RelicStep.SPARE_PARTS: return task === RelicTask.TASK_1 ? Item.COMPACT_AXLE : Item.COMPACT_SPRING;
			case RelicStep.STORY: return task === RelicTask.TASK_1 ? Item.BATTLES_FOR_THE_REALM : Item.BEYOND_THE_RIFT;
			case RelicStep.FOND_MEMORY: return task === RelicTask.TASK_1 ? Item.BLEAK_MEMORY_OF_THE_DYING : Item.LURID_MEMORY_OF_THE_DYING;
			case RelicStep.IRRESISTIBLE: return Item.RAW_EMOTION;
			case RelicStep.MANDERVILLE: return Item.MANDERIUM_METEORITE;
			case RelicStep.AMAZING_MANDERVILLE: return Item.COMPLEMENTARY_CHONDRITE;
			case RelicStep.MAJESTIC_MANDERVILLE: return Item.AMPLIFYING_ACHONDRITE;
			case RelicStep.MANDERVILLOUS: return Item.COSMIC_CRYSTALLITE;
			case RelicStep.SUPRA_ITEMS: return job ? this.getSupraItem(task, job) : Item.UNKNOWN;
			case RelicStep.LUCIS_ITEMS: return job ? this.getLucisItem(task, job) : Item.UNKNOWN;
			case RelicStep.SKYSTEEL_ITEMS: return job ? this.getSkysteel_1Item(task, job) : Item.UNKNOWN;
			case RelicStep.DRAGONSUNG_ITEMS: return job ? this.getDragonsungItem(task, job) : Item.UNKNOWN;
			case RelicStep.BASIC_GOBBIEGOO: return job ? this.getAugmentedDragonsungItem(task, job) : Item.UNKNOWN;
			case RelicStep.VISCOUS_GOBBIEGOO: return job ? this.getSkysungItem(task, job) : Item.UNKNOWN;
			case RelicStep.PARTS: return job ? this.getSkybuildersItem(task, job) : Item.UNKNOWN;
			case RelicStep.SPLENDOROUS_COMPONENT: return job ? this.getSplendorousItem(task, job) : Item.UNKNOWN;
			case RelicStep.ADAPTIVE_COMPONENT: return job ? this.getAdaptiveItem(task, job) : Item.UNKNOWN;
			case RelicStep.CUSTOMISED_COMPONENT: return job ? this.getCustomisedItem(task, job) : Item.UNKNOWN;
			case RelicStep.BRILLIANT_COMPONENT: return job ? this.getBrilliantItem(task, job) : Item.UNKNOWN;
			case RelicStep.INSPIRATIONAL_COMPONENT: return job ? this.getInspirationalItem(task, job) : Item.UNKNOWN;
			case RelicStep.NIGHTFORGED_COMPONENT: return job ? this.getNightforgedItem(task, job) : Item.UNKNOWN;
		}
	}

	// eslint-disable-next-line complexity
	private getClassWeaponItem(task:RelicTask, job:Job):Item{
		switch(job){
			default: return Item.UNKNOWN;
			case Job.PLD: return task === RelicTask.TASK_1 ? Item.AEOLIAN_SCIMITAR : Item.BATTLEDANCE_MATERIA_III;
			case Job.WAR: return task === RelicTask.TASK_1 ? Item.BARBARIANS_BARDICHE : Item.BATTLEDANCE_MATERIA_III;
			case Job.WHM: return task === RelicTask.TASK_1 ? Item.MADMANS_WHISPERING_ROD : Item.QUICKTONGUE_MATERIA_III;
			case Job.SCH: return task === RelicTask.TASK_1 ? Item.ERUDITES_PICATRIX_OF_HEALING : Item.QUICKTONGUE_MATERIA_III;
			case Job.MNK: return task === RelicTask.TASK_1 ? Item.WILDLINGS_CESTI : Item.SAVAGE_AIM_MATERIA_III;
			case Job.DRG: return task === RelicTask.TASK_1 ? Item.CHAMPIONS_LANCE : Item.SAVAGE_AIM_MATERIA_III;
			case Job.NIN: return task === RelicTask.TASK_1 ? Item.VAMPERS_KNIVES : Item.HEAVENS_EYE_MATERIA_III;
			case Job.BRD: return task === RelicTask.TASK_1 ? Item.LONGARMS_COMPOSITE_BOW : Item.HEAVENS_EYE_MATERIA_III;
			case Job.BLM: return task === RelicTask.TASK_1 ? Item.SANGUINE_SCEPTER : Item.SAVAGE_MIGHT_MATERIA_III;
			case Job.SMN: return task === RelicTask.TASK_1 ? Item.ERUDITES_PICATRIX_OF_CASTING : Item.SAVAGE_MIGHT_MATERIA_III;
		}
	}

	private getAtmasItem(task:RelicTask):Item{
		switch(task){
			default: return Item.UNKNOWN;
			case RelicTask.TASK_1: return Item.ATMA_OF_THE_LION;
			case RelicTask.TASK_2: return Item.ATMA_OF_THE_WATERBEARER;
			case RelicTask.TASK_3: return Item.ATMA_OF_THE_RAM;
			case RelicTask.TASK_4: return Item.ATMA_OF_THE_CRAB;
			case RelicTask.TASK_5: return Item.ATMA_OF_THE_FISH;
			case RelicTask.TASK_6: return Item.ATMA_OF_THE_BULL;
			case RelicTask.TASK_7: return Item.ATMA_OF_THE_SCALES;
			case RelicTask.TASK_8: return Item.ATMA_OF_THE_TWINS;
			case RelicTask.TASK_9: return Item.ATMA_OF_THE_SCORPION;
			case RelicTask.TASK_10: return Item.ATMA_OF_THE_ARCHER;
			case RelicTask.TASK_11: return Item.ATMA_OF_THE_GOAT;
			case RelicTask.TASK_12: return Item.ATMA_OF_THE_MAIDEN;
		}
	}

	private getSkylightItem(task:RelicTask):Item{
		switch(task){
			default: return Item.UNKNOWN;
			case RelicTask.TASK_1: return Item.BOMBARD_CORE;
			case RelicTask.TASK_2: return Item.BRONZE_LAKE_CRYSTAL;
			case RelicTask.TASK_3: return Item.SACRED_SPRING_WATER;
			case RelicTask.TASK_4: return Item.HORN_OF_THE_BEAST;
			case RelicTask.TASK_5: return Item.GOBMACHINE_BANGPLATE;
			case RelicTask.TASK_6: return Item.NARASIMHA_HIDE;
			case RelicTask.TASK_7: return Item.SICKLE_FANG;
			case RelicTask.TASK_8: return Item.FURNACE_RING_HQ;
			case RelicTask.TASK_9: return Item.PERFECT_FIREWOOD_HQ;
		}
	}

	private getZodiumItem(task:RelicTask):Item{
		switch(task){
			default: return Item.UNKNOWN;
			case RelicTask.TASK_1: return Item.ALLAGAN_RESIN;
			case RelicTask.TASK_2: return Item.VALE_BUBO;
			case RelicTask.TASK_3: return Item.VOIDWEAVE;
			case RelicTask.TASK_4: return Item.AMDAPOR_VELLUM;
			case RelicTask.TASK_5: return Item.INDIGO_PEARL;
			case RelicTask.TASK_6: return Item.PERFECT_MORTAR_HQ;
			case RelicTask.TASK_7: return Item.PERFECT_PESTLE_HQ;
			case RelicTask.TASK_8: return Item.BOMBARD_CORE;
			case RelicTask.TASK_9: return Item.SACRED_SPRING_WATER;
		}
	}

	private getFlawlessAlexandriteItem(task:RelicTask):Item{
		switch(task){
			default: return Item.UNKNOWN;
			case RelicTask.TASK_1: return Item.BOMBARD_CORE;
			case RelicTask.TASK_2: return Item.BRASS_KETTLE;
			case RelicTask.TASK_3: return Item.SACRED_SPRING_WATER;
			case RelicTask.TASK_4: return Item.LOST_TREASURE_OF_AMDAPOR;
			case RelicTask.TASK_5: return Item.LOST_TREASURE_OF_PHAROS_SIRIUS;
			case RelicTask.TASK_6: return Item.LOST_TREASURE_OF_TAM_TARA;
			case RelicTask.TASK_7: return Item.LOST_TREASURE_OF_STONE_VIGIL;
			case RelicTask.TASK_8: return Item.TAILOR_MADE_EEL_PIE_HQ;
			case RelicTask.TASK_9: return Item.PERFECT_CLOTH_HQ;
		}
	}

	private getBravesScrollItem(task:RelicTask):Item{
		switch(task){
			default: return Item.UNKNOWN;
			case RelicTask.TASK_1: return Item.TONBERRY_KING_BLOOD;
			case RelicTask.TASK_2: return Item.ROYAL_GIGANT_BLOOD;
			case RelicTask.TASK_3: return Item.KRAKEN_BLOOD;
			case RelicTask.TASK_4: return Item.VICEGERENT_BLOOD;
			case RelicTask.TASK_5: return Item.PERFECT_VELLUM_HQ;
			case RelicTask.TASK_6: return Item.PERFECT_POUNCE_HQ;
			case RelicTask.TASK_7: return Item.BOMBARD_CORE;
			case RelicTask.TASK_8: return Item.FURITE_SAND;
			case RelicTask.TASK_9: return Item.SACRED_SPRING_WATER;
		}
	}

	private getSoulItem(task:RelicTask):Item{
		switch(task){
			default: return Item.UNKNOWN;
			case RelicTask.TASK_1: return Item.MAHATMA_OF_THE_RAM;
			case RelicTask.TASK_2: return Item.MAHATMA_OF_THE_BULL;
			case RelicTask.TASK_3: return Item.MAHATMA_OF_THE_TWINS;
			case RelicTask.TASK_4: return Item.MAHATMA_OF_THE_CRAB;
			case RelicTask.TASK_5: return Item.MAHATMA_OF_THE_LION;
			case RelicTask.TASK_6: return Item.MAHATMA_OF_THE_MAIDEN;
			case RelicTask.TASK_7: return Item.MAHATMA_OF_THE_SCALES;
			case RelicTask.TASK_8: return Item.MAHATMA_OF_THE_SCORPION;
			case RelicTask.TASK_9: return Item.MAHATMA_OF_THE_ARCHER;
			case RelicTask.TASK_10: return Item.MAHATMA_OF_THE_GOAT;
			case RelicTask.TASK_11: return Item.MAHATMA_OF_THE_WATERBEARER;
			case RelicTask.TASK_12: return Item.MAHATMA_OF_THE_FISH;
		}
	}

	private getCrystalsItem(task:RelicTask){
		switch(task){
			default: return Item.UNKNOWN;
			case RelicTask.TASK_1: return Item.LUMINOUS_WIND_CRYSTAL;
			case RelicTask.TASK_2: return Item.LUMINOUS_FIRE_CRYSTAL;
			case RelicTask.TASK_3: return Item.LUMINOUS_LIGHTNING_CRYSTAL;
			case RelicTask.TASK_4: return Item.LUMINOUS_ICE_CRYSTAL;
			case RelicTask.TASK_5: return Item.LUMINOUS_EARTH_CRYSTAL;
			case RelicTask.TASK_6: return Item.LUMINOUS_WATER_CRYSTAL;
		}
	}

	private getMemoryItem(task:RelicTask):Item{
		switch(task){
			default: return Item.UNKNOWN;
			case RelicTask.TASK_1: return Item.TORTURED_MEMORY_OF_THE_DYING;
			case RelicTask.TASK_2: return Item.SORROWFUL_MEMORY_OF_THE_DYING;
			case RelicTask.TASK_3: return Item.HARROWING_MEMORY_OF_THE_DYING;
		}
	}

	// eslint-disable-next-line complexity
	private getSupraItem(task:RelicTask, job:Job){
		switch(job){
			default: return Item.UNKNOWN;
			case Job.CRP: return task === RelicTask.TASK_1 ? Item.REINFORCED_SPRUCE_PLYWOOD : Item.MASTERCRAFT_DEMIMATERIA;
			case Job.BSM: return task === RelicTask.TASK_1 ? Item.BALANCED_DARKSTEEL_HOOK : Item.MASTERCRAFT_DEMIMATERIA;
			case Job.ARM: return task === RelicTask.TASK_1 ? Item.REINFOCED_DARKSTEEL_WIRE : Item.MASTERCRAFT_DEMIMATERIA;
			case Job.GSM: return task === RelicTask.TASK_1 ? Item.CALIBRATED_ROSE_GOLD_COG : Item.MASTERCRAFT_DEMIMATERIA;
			case Job.LTW: return task === RelicTask.TASK_1 ? Item.WATERHARDENED_HIPPOGRYPH_STRAP : Item.MASTERCRAFT_DEMIMATERIA;
			case Job.WVR: return task === RelicTask.TASK_1 ? Item.INTRICATE_SILVER_BROCADE : Item.MASTERCRAFT_DEMIMATERIA;
			case Job.ALC: return task === RelicTask.TASK_1 ? Item.CONCENTRATED_SPIRITS_OF_SALT : Item.MASTERCRAFT_DEMIMATERIA;
			case Job.CUL: return task === RelicTask.TASK_1 ? Item.RICH_TOMATO_RELISH : Item.MASTERCRAFT_DEMIMATERIA;
			case Job.MIN: return task === RelicTask.TASK_1 ? Item.TALANS_SEAL_OF_MINING : Item.MASTERCRAFT_DEMIMATERIA;
			case Job.BTN: return task === RelicTask.TASK_1 ? Item.TALANS_SEAL_OF_BOTANY : Item.MASTERCRAFT_DEMIMATERIA;
			case Job.FSH: return task === RelicTask.TASK_1 ? Item.TALANS_SEAL_OF_FISHING : Item.MASTERCRAFT_DEMIMATERIA;
		}
	}

	// eslint-disable-next-line complexity
	private getLucisItem(task:RelicTask, job:Job){
		switch(job){
			default: return Item.UNKNOWN;
			case Job.CRP: return task === RelicTask.TASK_1 ? Item.PETRIFIED_ORB_HQ : Item.MOONSTONE;
			case Job.BSM: return task === RelicTask.TASK_1 ? Item.MUMMERS_DAGGERS_HQ : Item.MOONSTONE;
			case Job.ARM: return task === RelicTask.TASK_1 ? Item.LAPDOG_COLLAR_BELL_HQ : Item.MOONSTONE;
			case Job.GSM: return task === RelicTask.TASK_1 ? Item.FLUORITE_LENS_HQ : Item.MOONSTONE;
			case Job.LTW: return task === RelicTask.TASK_1 ? Item.PATENT_LEATHER_HQ : Item.MOONSTONE;
			case Job.WVR: return task === RelicTask.TASK_1 ? Item.CAMLET_HQ : Item.MOONSTONE;
			case Job.ALC: return task === RelicTask.TASK_1 ? Item.ENCHANTED_QUICKSILVER_HQ : Item.MOONSTONE;
			case Job.CUL: return task === RelicTask.TASK_1 ? Item.DUCK_BROTH_HQ : Item.MOONSTONE;
			case Job.MIN: return task === RelicTask.TASK_1 ? Item.TALANS_SEAL_OF_MINING_MASTERY : Item.MOONSTONE;
			case Job.BTN: return task === RelicTask.TASK_1 ? Item.TALANS_SEAL_OF_BOTANY_MASTERY : Item.MOONSTONE;
			case Job.FSH: return task === RelicTask.TASK_1 ? Item.TALANS_SEAL_OF_FISHING_MASTERY : Item.MOONSTONE;
		}
	}

	// eslint-disable-next-line complexity
	private getSkysteel_1Item(task:RelicTask, job:Job):Item{
		switch(job){
			default: return Item.UNKNOWN;
			case Job.CRP: return task === RelicTask.TASK_1 ? Item.ODDLY_SPECIFIC_PETRIFIED_ORB_HQ : Item.UNKNOWN;
			case Job.BSM: return task === RelicTask.TASK_1 ? Item.ODDLY_SPECIFIC_RIVETS_HQ : Item.UNKNOWN;
			case Job.ARM: return task === RelicTask.TASK_1 ? Item.ODDLY_SPECIFIC_WIRE_HQ : Item.UNKNOWN;
			case Job.GSM: return task === RelicTask.TASK_1 ? Item.ODDLY_SPECIFIC_WHETSTONE_HQ : Item.UNKNOWN;
			case Job.LTW: return task === RelicTask.TASK_1 ? Item.ODDLY_SPECIFIC_LEATHER_HQ : Item.UNKNOWN;
			case Job.WVR: return task === RelicTask.TASK_1 ? Item.ODDLY_SPECIFIC_MOONBEAM_SILK_HQ : Item.UNKNOWN;
			case Job.ALC: return task === RelicTask.TASK_1 ? Item.ODDLY_SPECIFIC_SYNTHETIC_RESIN_HQ : Item.UNKNOWN;
			case Job.CUL: return task === RelicTask.TASK_1 ? Item.ODDLY_SPECIFIC_SEED_EXTRACT_HQ : Item.UNKNOWN;
			case Job.MIN: return task === RelicTask.TASK_1 ? Item.ODDLY_SPECIFIC_OBSIDIAN : Item.ODDLY_SPECIFIC_MINERAL_SAND;
			case Job.BTN: return task === RelicTask.TASK_1 ? Item.ODDLY_SPECIFIC_LATEX : Item.ODDLY_SPECIFIC_FOSSIL_DUST;
			case Job.FSH: return task === RelicTask.TASK_1 ? Item.THINKERS_CORAL : Item.UNKNOWN;
		}
	}

	// eslint-disable-next-line complexity
	private getDragonsungItem(task:RelicTask, job:Job):Item{
		switch(job){
			default: return Item.UNKNOWN;
			case Job.CRP: return task === RelicTask.TASK_1 ? Item.ODDLY_SPECIFIC_SHAFT_HQ : Item.UNKNOWN;
			case Job.BSM: return task === RelicTask.TASK_1 ? Item.ODDLY_SPECIFIC_FITTING_HQ : Item.UNKNOWN;
			case Job.ARM: return task === RelicTask.TASK_1 ? Item.ODDLY_SPECIFIC_CHAINMAIL_SHEET_HQ : Item.UNKNOWN;
			case Job.GSM: return task === RelicTask.TASK_1 ? Item.ODDLY_SPECIFIC_GEMSTONE_HQ : Item.UNKNOWN;
			case Job.LTW: return task === RelicTask.TASK_1 ? Item.ODDLY_SPECIFIC_VELLUM_HQ : Item.UNKNOWN;
			case Job.WVR: return task === RelicTask.TASK_1 ? Item.ODDLY_SPECIFIC_VELVET_HQ : Item.UNKNOWN;
			case Job.ALC: return task === RelicTask.TASK_1 ? Item.ODDLY_SPECIFIC_GLASS_HQ : Item.UNKNOWN;
			case Job.CUL: return task === RelicTask.TASK_1 ? Item.ODDLY_SPECIFIC_SEED_FLOUR_HQ : Item.UNKNOWN;
			case Job.MIN: return task === RelicTask.TASK_1 ? Item.ODDLY_SPECIFIC_DARK_MATTER : Item.ODDLY_SPECIFIC_STRIKING_STONE;
			case Job.BTN: return task === RelicTask.TASK_1 ? Item.ODDLY_SPECIFIC_AMBER : Item.ODDLY_SPECIFIC_BAUBLE;
			case Job.FSH: return task === RelicTask.TASK_1 ? Item.DRAGONSPINE : Item.UNKNOWN;
		}
	}

	// eslint-disable-next-line complexity
	private getAugmentedDragonsungItem(task:RelicTask, job:Job):Item{
		switch(job){
			default: return Item.UNKNOWN;
			case Job.CRP: return task === RelicTask.TASK_1 ? Item.ODDLY_SPECIFIC_CEDAR_LUMBER : Item.UNKNOWN;
			case Job.BSM: return task === RelicTask.TASK_1 ? Item.ODDLY_SPECIFIC_IRON_NAILS : Item.UNKNOWN;
			case Job.ARM: return task === RelicTask.TASK_1 ? Item.ODDLY_SPECIFIC_MYTHRIL_RINGS : Item.UNKNOWN;
			case Job.GSM: return task === RelicTask.TASK_1 ? Item.ODDLY_SPECIFIC_SILVER_NUGGET : Item.UNKNOWN;
			case Job.LTW: return task === RelicTask.TASK_1 ? Item.ODDLY_SPECIFIC_GAGANASKIN_STRAP : Item.UNKNOWN;
			case Job.WVR: return task === RelicTask.TASK_1 ? Item.ODDLY_SPECIFIC_CLOTH : Item.UNKNOWN;
			case Job.ALC: return task === RelicTask.TASK_1 ? Item.ODDLY_SPECIFIC_GLUE : Item.UNKNOWN;
			case Job.CUL: return task === RelicTask.TASK_1 ? Item.ODDLY_SPECIFIC_OIL : Item.UNKNOWN;
			case Job.MIN: return task === RelicTask.TASK_1 ? Item.ODDLY_SPECIFIC_SCHORL : Item.ODDLY_SPECIFIC_LANDBORNE_AETHERSAND;
			case Job.BTN: return task === RelicTask.TASK_1 ? Item.ODDLY_SPECIFIC_DARK_CHESTNUT_LOG : Item.ODDLY_SPECIFIC_LEAFBORNE_AETHERSAND;
			case Job.FSH: return task === RelicTask.TASK_1 ? Item.PETAL_SHELL : Item.UNKNOWN;
		}
	}

	// eslint-disable-next-line complexity
	private getSkysungItem(task:RelicTask, job:Job):Item{
		switch(job){
			default: return Item.UNKNOWN;
			case Job.CRP: return task === RelicTask.TASK_1 ? Item.ODDLY_SPECIFIC_CEDAR_PLANK : Item.UNKNOWN;
			case Job.BSM: return task === RelicTask.TASK_1 ? Item.ODDLY_SPECIFIC_IRON_INGOT : Item.UNKNOWN;
			case Job.ARM: return task === RelicTask.TASK_1 ? Item.ODDLY_SPECIFIC_MYTHRIL_PLATE : Item.UNKNOWN;
			case Job.GSM: return task === RelicTask.TASK_1 ? Item.ODDLY_SPECIFIC_SILVER_INGOT : Item.UNKNOWN;
			case Job.LTW: return task === RelicTask.TASK_1 ? Item.ODDLY_SPECIFIC_GAGANA_LEATHER : Item.UNKNOWN;
			case Job.WVR: return task === RelicTask.TASK_1 ? Item.ODDLY_SPECIFIC_UNDYED_WOOLEN_CLOTH : Item.UNKNOWN;
			case Job.ALC: return task === RelicTask.TASK_1 ? Item.ODDLY_SPECIFIC_RUBBER : Item.UNKNOWN;
			case Job.CUL: return task === RelicTask.TASK_1 ? Item.ODDLY_SPECIFIC_PASTE : Item.UNKNOWN;
			case Job.MIN: return task === RelicTask.TASK_1 ? Item.ODDLY_SPECIFIC_PRIMORDIAL_ORE : Item.ODDLY_SPECIFIC_PRIMORDIAL_ASPHALTUM;
			case Job.BTN: return task === RelicTask.TASK_1 ? Item.ODDLY_SPECIFIC_PRIMORDIAL_LOG : Item.ODDLY_SPECIFIC_PRIMORDIAL_RESIN;
			case Job.FSH: return task === RelicTask.TASK_1 ? Item.ALLAGAN_HUNTER : Item.UNKNOWN;
		}
	}

	// eslint-disable-next-line complexity
	private getSkybuildersItem(task:RelicTask, job:Job):Item{
		switch(job){
			default: return Item.UNKNOWN;
			case Job.CRP: return task === RelicTask.TASK_1 ? Item.ODDLY_DELICATE_PINE_LUMBER : Item.UNKNOWN;
			case Job.BSM: return task === RelicTask.TASK_1 ? Item.ODDLY_DELICATE_SILVER_GEAR : Item.UNKNOWN;
			case Job.ARM: return task === RelicTask.TASK_1 ? Item.ODDLY_DELICATE_WOLFRAM_SQUARE : Item.UNKNOWN;
			case Job.GSM: return task === RelicTask.TASK_1 ? Item.ODDLY_DELICATE_CELESTINE : Item.UNKNOWN;
			case Job.LTW: return task === RelicTask.TASK_1 ? Item.ODDLY_DELICATE_GAZELLE_LEATHER : Item.UNKNOWN;
			case Job.WVR: return task === RelicTask.TASK_1 ? Item.ODDLY_DELICATE_RHEA_CLOTH : Item.UNKNOWN;
			case Job.ALC: return task === RelicTask.TASK_1 ? Item.ODDLY_DELICATE_HOLY_WATER : Item.UNKNOWN;
			case Job.CUL: return task === RelicTask.TASK_1 ? Item.ODDLY_DELICATE_SHARK_OIL : Item.UNKNOWN;
			case Job.MIN: return task === RelicTask.TASK_1 ? Item.ODDLY_DELICATE_ADAMANTITE_ORE : Item.ODDLY_DELICATE_RAW_JADE;
			case Job.BTN: return task === RelicTask.TASK_1 ? Item.ODDLY_DELICATE_FEATHER : Item.ODDLY_DELICATE_BIRCH_LOG;
			case Job.FSH: return task === RelicTask.TASK_1 ? Item.FLINTSTRIKE : Item.PICKLED_POM;
		}
	}

	// eslint-disable-next-line complexity
	private getSplendorousItem(task:RelicTask, job:Job):Item{
		switch(job){
			default: return Item.UNKNOWN;
			case Job.CRP: return task === RelicTask.TASK_1 ? Item.CONNOISSEURS_CHAIR : Item.UNKNOWN;
			case Job.BSM: return task === RelicTask.TASK_1 ? Item.CONNOISSEURS_WALL_LANTERN : Item.UNKNOWN;
			case Job.ARM: return task === RelicTask.TASK_1 ? Item.CONNOISSEURS_ORNATE_DOOR : Item.UNKNOWN;
			case Job.GSM: return task === RelicTask.TASK_1 ? Item.CONNOISSEURS_VANITY_MIRROR : Item.UNKNOWN;
			case Job.LTW: return task === RelicTask.TASK_1 ? Item.CONNOISSEURS_RUG : Item.UNKNOWN;
			case Job.WVR: return task === RelicTask.TASK_1 ? Item.CONNOISSEURS_SWAG_VALANCE : Item.UNKNOWN;
			case Job.ALC: return task === RelicTask.TASK_1 ? Item.CONNOISSEURS_COSMETICS_BOX : Item.UNKNOWN;
			case Job.CUL: return task === RelicTask.TASK_1 ? Item.CONNOISSEURS_PIXIEBERRY_TEA : Item.UNKNOWN;
			case Job.MIN: return task === RelicTask.TASK_1 ? Item.CONNOISSEURS_PRISMSTONE : Item.SPLENDOROUS_WATER_SHARD;
			case Job.BTN: return task === RelicTask.TASK_1 ? Item.CONNOISSEURS_WATTLE_PETRIBARK : Item.SPLENDOROUS_EARTH_SHARD;
			case Job.FSH: return task === RelicTask.TASK_1 ? Item.PLATINUM_SEAHORSE : Item.CLAVEKEEPER;
		}
	}

	// eslint-disable-next-line complexity
	private getAdaptiveItem(task:RelicTask, job:Job):Item{
		switch(job){
			default: return Item.UNKNOWN;
			case Job.CRP: return task === RelicTask.TASK_1 ? Item.CONNOISSEURS_MARIMBA : Item.UNKNOWN;
			case Job.BSM: return task === RelicTask.TASK_1 ? Item.CONNOISSEURS_COFFEE_BREWER : Item.UNKNOWN;
			case Job.ARM: return task === RelicTask.TASK_1 ? Item.CONNOISSEURS_BENCH : Item.UNKNOWN;
			case Job.GSM: return task === RelicTask.TASK_1 ? Item.CONNOISSEURS_ASTROSCOPE : Item.UNKNOWN;
			case Job.LTW: return task === RelicTask.TASK_1 ? Item.CONNOISSEURS_LEATHER_SOFA : Item.UNKNOWN;
			case Job.WVR: return task === RelicTask.TASK_1 ? Item.CONNOISSEURS_RED_CARPET : Item.UNKNOWN;
			case Job.ALC: return task === RelicTask.TASK_1 ? Item.CONNOISSEURS_ELIXIR_BOTTLE : Item.UNKNOWN;
			case Job.CUL: return task === RelicTask.TASK_1 ? Item.CONNOISSEURS_PIXIEBERRY_TART : Item.UNKNOWN;
			case Job.MIN: return task === RelicTask.TASK_1 ? Item.CONNOISSEURS_RED_MALACHITE : Item.ADAPTIVE_FIRE_CRYSTAL;
			case Job.BTN: return task === RelicTask.TASK_1 ? Item.CONNOISSEURS_LEVIN_MINT : Item.ADAPTIVE_LIGHTNING_CRYSTAL;
			case Job.FSH: return task === RelicTask.TASK_1 ? Item.MIRROR_IMAGE : Item.SPANGLED_PIRARUCU;
		}
	}

	// eslint-disable-next-line complexity
	private getCustomisedItem(task:RelicTask, job:Job):Item{
		switch(job){
			default: return Item.UNKNOWN;
			case Job.CRP: return task === RelicTask.TASK_1 ? Item.CONNOISSEURS_PICTURE_FRAME : Item.UNKNOWN;
			case Job.BSM: return task === RelicTask.TASK_1 ? Item.CONNOISSEURS_SAMURAI_BLADE : Item.UNKNOWN;
			case Job.ARM: return task === RelicTask.TASK_1 ? Item.CONNOISSEURS_AQUARIUM : Item.UNKNOWN;
			case Job.GSM: return task === RelicTask.TASK_1 ? Item.CONNOISSEURS_GLAIVES : Item.UNKNOWN;
			case Job.LTW: return task === RelicTask.TASK_1 ? Item.CONNOISSEURS_VARSITY_SHOES : Item.UNKNOWN;
			case Job.WVR: return task === RelicTask.TASK_1 ? Item.CONNOISSEURS_LINEN_PARASOL : Item.UNKNOWN;
			case Job.ALC: return task === RelicTask.TASK_1 ? Item.CONNOISSEURS_TOPIARY_MOOGLE : Item.UNKNOWN;
			case Job.CUL: return task === RelicTask.TASK_1 ? Item.CONNOISSEURS_SQUID_BAGUETTE : Item.UNKNOWN;
			case Job.MIN: return task === RelicTask.TASK_1 ? Item.CONNOISSEURS_SOILED_FEMUR : Item.CUSTOM_ICE_CRYSTAL;
			case Job.BTN: return task === RelicTask.TASK_1 ? Item.CONNOISSEURS_MIRACLE_APPLE : Item.CUSTOM_WIND_CRYSTAL;
			case Job.FSH: return task === RelicTask.TASK_1 ? Item.GOLD_DUSTFISH : Item.FORGIVEN_MELANCHOLY;
		}
	}

	// eslint-disable-next-line complexity
	private getBrilliantItem(task:RelicTask, job:Job):Item{
		switch(job){
			default: return Item.UNKNOWN;
			case Job.CRP: return task === RelicTask.TASK_1 ? Item.CONNOISSEUR_BOOKSHELF : Item.UNKNOWN;
			case Job.BSM: return task === RelicTask.TASK_1 ? Item.CONNOISSEUR_CHANDELIER : Item.UNKNOWN;
			case Job.ARM: return task === RelicTask.TASK_1 ? Item.CONNOISSEUR_ESCUTCHEON : Item.UNKNOWN;
			case Job.GSM: return task === RelicTask.TASK_1 ? Item.CONNOISSEUR_BAGHNAKHS : Item.UNKNOWN;
			case Job.LTW: return task === RelicTask.TASK_1 ? Item.CONNOISSEUR_DRINKING_APKALLU : Item.UNKNOWN;
			case Job.WVR: return task === RelicTask.TASK_1 ? Item.CONNOISSEUR_JACKET : Item.UNKNOWN;
			case Job.ALC: return task === RelicTask.TASK_1 ? Item.CONNOISSEUR_PLANTER_PARTITION : Item.UNKNOWN;
			case Job.CUL: return task === RelicTask.TASK_1 ? Item.CONNOISSEUR_SPAGHETTI_AL_NERO : Item.UNKNOWN;
			case Job.MIN: return task === RelicTask.TASK_1 ? Item.CONNOISSEUR_AURUM_REGIS_ORE : Item.BRILLIANT_LIGHTNING_CLUSTER;
			case Job.BTN: return task === RelicTask.TASK_1 ? Item.CONNOISSEUR_CLOVES : Item.BRILLIANT_EARTH_CLUSTER;
			case Job.FSH: return task === RelicTask.TASK_1 ? Item.OIL_SLICK : Item.GONZALOS_GRACE;
		}
	}

	// eslint-disable-next-line complexity
	private getInspirationalItem(task:RelicTask, job:Job):Item{
		switch(job){
			default: return Item.UNKNOWN;
			case Job.CRP: return task === RelicTask.TASK_1 ? Item.CONNOISSEUR_BAMBOO_FENCE : Item.UNKNOWN;
			case Job.BSM: return task === RelicTask.TASK_1 ? Item.CONNOISSEUR_ROUSING_CHRONOMETER : Item.UNKNOWN;
			case Job.ARM: return task === RelicTask.TASK_1 ? Item.CONNOISSEUR_TRUMPET : Item.UNKNOWN;
			case Job.GSM: return task === RelicTask.TASK_1 ? Item.CONNOISSEUR_WASHBASIN : Item.UNKNOWN;
			case Job.LTW: return task === RelicTask.TASK_1 ? Item.CONNOISSEUR_TARGE : Item.UNKNOWN;
			case Job.WVR: return task === RelicTask.TASK_1 ? Item.CONNOISSEUR_PETASOS : Item.UNKNOWN;
			case Job.ALC: return task === RelicTask.TASK_1 ? Item.CONNOISSEUR_LUNAR_CURTAIN : Item.UNKNOWN;
			case Job.CUL: return task === RelicTask.TASK_1 ? Item.CONNOISSEUR_CRAB_CAKES : Item.UNKNOWN;
			case Job.MIN: return task === RelicTask.TASK_1 ? Item.CONNOISSEUR_ASPHALTUM : Item.INSPIRATIONAL_WIND_CLUSTER;
			case Job.BTN: return task === RelicTask.TASK_1 ? Item.CONNOISSEUR_GIANTHIVE_CHIP : Item.INSPIRATIONAL_FIRE_CLUSTER;
			case Job.FSH: return task === RelicTask.TASK_1 ? Item.DEADWOOD_SHADOW : Item.RONKAN_BULLION;
		}
	}

	// eslint-disable-next-line complexity
	private getNightforgedItem(task:RelicTask, job:Job):Item{
		switch(job){
			default: return Item.UNKNOWN;
			case Job.CRP: return task === RelicTask.TASK_1 ? Item.CONNOSISSEUR_SHISHI_ODOSHI : Item.UNKNOWN;
			case Job.BSM: return task === RelicTask.TASK_1 ? Item.CONNOSISSEUR_RETAINER_BELL : Item.UNKNOWN;
			case Job.ARM: return task === RelicTask.TASK_1 ? Item.CONNOSISSEUR_MARCHING_HORN : Item.UNKNOWN;
			case Job.GSM: return task === RelicTask.TASK_1 ? Item.CONNOSISSEUR_MARBLE_FOUNTAIN : Item.UNKNOWN;
			case Job.LTW: return task === RelicTask.TASK_1 ? Item.CONNOSISSEUR_LEATHER_JACKET : Item.UNKNOWN;
			case Job.WVR: return task === RelicTask.TASK_1 ? Item.CONNOSISSEUR_FAT_CAT_SOFA : Item.UNKNOWN;
			case Job.ALC: return task === RelicTask.TASK_1 ? Item.CONNOSISSEUR_TINCTURE_OF_VITALITY : Item.UNKNOWN;
			case Job.CUL: return task === RelicTask.TASK_1 ? Item.CONNOSISSEUR_CHILI_CRAB : Item.UNKNOWN;
			case Job.MIN: return task === RelicTask.TASK_1 ? Item.CONNOISSEUR_RAW_ONYX : Item.NIGHTFORGED_ICE_CLUSTER;
			case Job.BTN: return task === RelicTask.TASK_1 ? Item.CONNOISSEUR_GLIMSHROOM : Item.NIGHTFORGED_WATER_CLUSTER;
			case Job.FSH: return task === RelicTask.TASK_1 ? Item.LITTLE_BOUNTY : Item.SAINT_FATHRICS_FACE;
		}
	}

	// eslint-disable-next-line complexity
	getTaskDuty(step:RelicStep, task:RelicTask):Duty{
		switch(step){
			default: return Trial.UNKNOWN;
			case RelicStep.SKYFIRE_1: return this.getSkyfire_1Duty(task);
			case RelicStep.SKYFIRE_2: return this.getSkyfire_2Duty(task);
			case RelicStep.NETHERFIRE_1: return this.getNetherfire_1Duty(task);
			case RelicStep.SKYFALL_1: return this.getSkyfall_1Duty(task);
			case RelicStep.SKYFALL_2: return this.getSkyfall_2Duty(task);
			case RelicStep.NETHERFALL_1: return this.getNetherfall_1Duty(task);
			case RelicStep.SKYWIND_1: return this.getSkywind_1Duty(task);
			case RelicStep.SKYWIND_2: return this.getSkywind_2Duty(task);
			case RelicStep.SKYEARTH_1: return this.getSkyearth_1Duty(task);
			case RelicStep.DUNGEONS: return this.getAnimaDungeonsDuty(task);
			case RelicStep.BFF: return this.getBFFDuty(task);
		}
	}

	private getSkyfire_1Duty(task:RelicTask):Duty{
		switch(task){
			default: return Dungeon.UNKNOWN;
			case RelicTask.TASK_1: return Leve.AWRY_SALVAGES;
			case RelicTask.TASK_5: return Leve.NECROLOGOS_PALE_OBLATION;
			case RelicTask.TASK_8: return Fate.ENMITY_OF_MY_ENEMY;
			case RelicTask.TASK_10: return Fate.GIANT_SEPS;
			case RelicTask.TASK_11: return Leve.AN_IMP_MOBILE;
			case RelicTask.TASK_13: return Fate.MAKE_IT_RAIN;
			case RelicTask.TASK_17: return Dungeon.TAM_TARA;
			case RelicTask.TASK_18: return Dungeon.STONE_VIGIL;
			case RelicTask.TASK_19: return Dungeon.LOST_CITY_OF_AMDAPOR;
		}
	}

	private getSkyfire_2Duty(task:RelicTask):Duty{
		switch(task){
			default: return Dungeon.UNKNOWN;
			case RelicTask.TASK_1: return Leve.MUSEUM_IS_CLOSED;
			case RelicTask.TASK_3: return Leve.YELLOW_IS_THE_NEW_BLACK;
			case RelicTask.TASK_6: return Fate.BREACHING_SOUTH_TIDEGATE;
			case RelicTask.TASK_10: return Fate.AIR_SUPPLY;
			case RelicTask.TASK_13: return Fate.HEROES_OF_THE_2ND;
			case RelicTask.TASK_16: return Leve.DONT_FORGET_TO_CRY;
			case RelicTask.TASK_17: return Dungeon.BRAYFLOX;
			case RelicTask.TASK_18: return Dungeon.WANDERERS;
			case RelicTask.TASK_19: return Dungeon.COPPERBELL_HARD;
		}
	}

	private getNetherfire_1Duty(task:RelicTask):Duty{
		switch(task){
			default: return Dungeon.UNKNOWN;
			case RelicTask.TASK_1: return Fate.ANOTHER_NOTCH;
			case RelicTask.TASK_2: return Leve.ONE_BIG_PROBLEM_SOLVED;
			case RelicTask.TASK_5: return Leve.IF_YOU_PUT_IT_THAT_WAY;
			case RelicTask.TASK_8: return Fate.EVERYTHINGS_BETTER;
			case RelicTask.TASK_13: return Fate.RETURN_TO_CINDER;
			case RelicTask.TASK_16: return Leve.CIRCLING_THE_CERULEUM;
			case RelicTask.TASK_17: return Dungeon.QARN;
			case RelicTask.TASK_18: return Dungeon.HAUKKE_MANOR_HARD;
			case RelicTask.TASK_19: return Dungeon.HALATALI_HARD;
		}
	}

	private getSkyfall_1Duty(task:RelicTask):Duty{
		switch(task){
			default: return Dungeon.UNKNOWN;
			case RelicTask.TASK_1: return Leve.GO_HOME_TO_MAMA;
			case RelicTask.TASK_4: return Fate.BELLYFULL;
			case RelicTask.TASK_5: return Leve.NECROLOGOS_WHISPERS_OF_THE_GEM;
			case RelicTask.TASK_7: return Fate.KINGS_JUSTICE;
			case RelicTask.TASK_12: return Leve.CIRCLING_THE_CERULEUM;
			case RelicTask.TASK_15: return Fate.QUARTZ_COUPLING;
			case RelicTask.TASK_17: return Dungeon.COPPERBELL;
			case RelicTask.TASK_18: return Dungeon.DZEMAEL_DARKHOLD;
			case RelicTask.TASK_19: return Dungeon.BRAYFLOX_HARD;
		}
	}

	private getSkyfall_2Duty(task:RelicTask):Duty{
		switch(task){
			default: return Dungeon.UNKNOWN;
			case RelicTask.TASK_1: return Fate.BLACK_AND_NBURU;
			case RelicTask.TASK_2: return Leve.GET_OFF_OUR_LAKE;
			case RelicTask.TASK_5: return Leve.AREAS_A_BIT_SKETCHY;
			case RelicTask.TASK_7: return Fate.BREAKING_DAWN;
			case RelicTask.TASK_12: return Leve.SOMEONES_IN_THE_DOGHOUSE;
			case RelicTask.TASK_13: return Fate.BREACHING_NORTH_TIDEGATE;
			case RelicTask.TASK_17: return Dungeon.TOTO_RAK;
			case RelicTask.TASK_18: return Dungeon.AMDAPOR_KEEP;
			case RelicTask.TASK_19: return Dungeon.HAUKKE_MANOR_HARD;
		}
	}

	private getNetherfall_1Duty(task:RelicTask):Duty{
		switch(task){
			default: return Dungeon.UNKNOWN;
			case RelicTask.TASK_1: return Fate.CERULEUM_ROAD;
			case RelicTask.TASK_2: return Leve.SUBDUING_THE_SUBPRIME;
			case RelicTask.TASK_5: return Fate.FOUR_WINDS;
			case RelicTask.TASK_6: return Leve.GOT_A_GUT_FEELING_ABOUT_THIS;
			case RelicTask.TASK_11: return Fate.RUDE_AWAKENING;
			case RelicTask.TASK_14: return Leve.WHO_WRITES_HISTORY;
			case RelicTask.TASK_17: return Dungeon.CUTTERS;
			case RelicTask.TASK_18: return Dungeon.PHAROS_SIRIUS;
			case RelicTask.TASK_19: return Dungeon.LOST_CITY_OF_AMDAPOR;
		}
	}

	private getSkywind_1Duty(task:RelicTask):Duty{
		switch(task){
			default: return Dungeon.UNKNOWN;
			case RelicTask.TASK_1: return Fate.GOOD_TO_BE_BAD;
			case RelicTask.TASK_2: return Leve.BIG_BAD_IDEA;
			case RelicTask.TASK_5: return Leve.SOMEONES_GOT_A_BIG_MOUTH;
			case RelicTask.TASK_10: return Fate.SURPRISE;
			case RelicTask.TASK_12: return Leve.SUBDUING_THE_SUBPRIME;
			case RelicTask.TASK_16: return Fate.IN_SPITE_OF_IT_ALL;
			case RelicTask.TASK_17: return Dungeon.SASTASHA;
			case RelicTask.TASK_18: return Dungeon.AURUM;
			case RelicTask.TASK_19: return Dungeon.HALATALI_HARD;
		}
	}

	private getSkywind_2Duty(task:RelicTask):Duty{
		switch(task){
			default: return Dungeon.UNKNOWN;
			case RelicTask.TASK_1: return Leve.PUT_YOUR_STOMP_ON_IT;
			case RelicTask.TASK_6: return Fate.WHAT_GORED_BEFORE;
			case RelicTask.TASK_7: return Fate.TAKEN;
			case RelicTask.TASK_11: return Leve.NECROLOGOS_PALE_OBLATION;
			case RelicTask.TASK_14: return Fate.TOWER_OF_POWER;
			case RelicTask.TASK_15: return Leve.BLOODHOUNDS_OF_COERTHAS;
			case RelicTask.TASK_17: return Dungeon.HAUKKE_MANOR;
			case RelicTask.TASK_18: return Dungeon.COPPERBELL_HARD;
			case RelicTask.TASK_19: return Dungeon.BRAYFLOX_HARD;
		}
	}

	private getSkyearth_1Duty(task:RelicTask):Duty{
		switch(task){
			default: return Dungeon.UNKNOWN;
			case RelicTask.TASK_3: return Leve.DONT_FORGET_TO_CRY;
			case RelicTask.TASK_4: return Fate.BIG_BAGOLY_THEORY;
			case RelicTask.TASK_8: return Fate.SCHISM;
			case RelicTask.TASK_11: return Fate.TASTE_OF_FEAR;
			case RelicTask.TASK_12: return Leve.NO_BIG_WHOOP;
			case RelicTask.TASK_14: return Leve.NECROLOGOS_THE_LIMINAL_ONES;
			case RelicTask.TASK_17: return Dungeon.PHAROS_SIRIUS;
			case RelicTask.TASK_18: return Dungeon.HALATALI;
			case RelicTask.TASK_19: return Dungeon.AMDAPOR_KEEP;
		}
	}

	private getAnimaDungeonsDuty(task:RelicTask):Duty{
		switch(task){
			default: return Dungeon.UNKNOWN;
			case RelicTask.TASK_1: return Dungeon.LOST_CITY_OF_AMDAPOR_HARD;
			case RelicTask.TASK_2: return Dungeon.GUBAL_LIBRARY_HARD;
			case RelicTask.TASK_3: return Dungeon.SOHM_AL_HARD;
		}
	}

	private getBFFDuty(task:RelicTask):Duty{
		switch(task){
			default: return Trial.UNKNOWN;
			case RelicTask.TASK_1: return Trial.IFRIT_HARD;
			case RelicTask.TASK_2: return Trial.GARUDA_HARD;
			case RelicTask.TASK_3: return Trial.TITAN_HARD;
			case RelicTask.TASK_4: return Trial.MOGGLE_MOG_HARD;
			case RelicTask.TASK_5: return Trial.LEVIATHAN_HARD;
			case RelicTask.TASK_6: return Trial.RAMUH_HARD;
			case RelicTask.TASK_7: return Trial.SHIVA_HARD;
			case RelicTask.TASK_8: return Trial.RAVANA_HARD;
			case RelicTask.TASK_9: return Trial.BISMARCK;
			case RelicTask.TASK_10: return Trial.SOPHIA;
			case RelicTask.TASK_11: return Trial.SEPHIROT;
			case RelicTask.TASK_12: return Trial.ZURVAN;
		}
	}

	getTaskEnemy(step:RelicStep, task:RelicTask, job?:Job):Enemy{
		switch(step){
			default: return Enemy.UNKNOWN;
			case RelicStep.BEASTMAN: return job ? this.getBeastmanEnemy(task, job) : Enemy.UNKNOWN;
			case RelicStep.SKYFIRE_1: return this.skyfire_1Enemy(task);
			case RelicStep.SKYFIRE_2: return this.skyfire_2Enemy(task);
			case RelicStep.NETHERFIRE_1: return this.netherfire_1Enemy(task);
			case RelicStep.SKYFALL_1: return this.skyfall_1Enemy(task);
			case RelicStep.SKYFALL_2: return this.skyfall_2Enemy(task);
			case RelicStep.NETHERFALL_1: return this.netherfall_1Enemy(task);
			case RelicStep.SKYWIND_1: return this.skywind_1Enemy(task);
			case RelicStep.SKYWIND_2: return this.skywind_2Enemy(task);
			case RelicStep.SKYEARTH_1: return this.skyearth_1Enemy(task);
		}
	}

	private getBeastmanEnemy(task:RelicTask, job:Job):Enemy{ // eslint-disable-line complexity
		switch(job){
			default: return Enemy.UNKNOWN;
			case Job.PLD: return task === RelicTask.TASK_1 ? Enemy.ZAHARAK_LANCER : task === RelicTask.TASK_2 ? Enemy.ZAHARAK_PUGILIST : Enemy.ZAHARAK_THAUMATURGE; // eslint-disable-line no-nested-ternary
			case Job.WAR: return task === RelicTask.TASK_1 ? Enemy.UGHAMARO_QUARRYMAN : task === RelicTask.TASK_2 ? Enemy.UGHAMARO_BEDESMAN : Enemy.UGHAMARO_ROUNDSMAN; // eslint-disable-line no-nested-ternary
			case Job.WHM: return task === RelicTask.TASK_1 ? Enemy.UGHAMARO_QUARRYMAN : task === RelicTask.TASK_2 ? Enemy.UGHAMARO_BEDESMAN : Enemy.UGHAMARO_PRIEST; // eslint-disable-line no-nested-ternary
			case Job.SCH: return task === RelicTask.TASK_1 ? Enemy.SAPSA_SHELFSPINE : task === RelicTask.TASK_2 ? Enemy.SAPSA_SHELFCLAW : Enemy.SAPSA_SHELFTOOTH; // eslint-disable-line no-nested-ternary
			case Job.MNK: return task === RelicTask.TASK_1 ? Enemy.ZAHARAK_LANCER : task === RelicTask.TASK_2 ? Enemy.ZAHARAK_PUGILIST : Enemy.ZAHARAK_ARCHER; // eslint-disable-line no-nested-ternary
			case Job.DRG: return task === RelicTask.TASK_1 ? Enemy.NATALAN_BOLDWING : task === RelicTask.TASK_2 ? Enemy.NATALAN_FOGCALLER : Enemy.NATALAN_WINDTALON; // eslint-disable-line no-nested-ternary
			case Job.NIN: return task === RelicTask.TASK_1 ? Enemy.SAPSA_SHELFSPINE : task === RelicTask.TASK_2 ? Enemy.SAPSA_SHELFCLAW : Enemy.SAPSA_SHELFTOOTH; // eslint-disable-line no-nested-ternary
			case Job.BRD: return task === RelicTask.TASK_1 ? Enemy.NATALAN_BOLDWING : task === RelicTask.TASK_2 ? Enemy.NATALAN_FOGCALLER : Enemy.NATALAN_WINDTALON; // eslint-disable-line no-nested-ternary
			case Job.BLM: return task === RelicTask.TASK_1 ? Enemy.UGHAMARO_QUARRYMAN : task === RelicTask.TASK_2 ? Enemy.UGHAMARO_BEDESMAN : Enemy.UGHAMARO_PRIEST; // eslint-disable-line no-nested-ternary
			case Job.SMN: return task === RelicTask.TASK_1 ? Enemy.VIOLET_SIGH : task === RelicTask.TASK_2 ? Enemy.VIOLET_SCREECH : Enemy.VIOLET_SNARL; // eslint-disable-line no-nested-ternary
		}
	}

	private skyfire_1Enemy(task:RelicTask):Enemy{
		switch(task){
			default: return Enemy.UNKNOWN;
			case RelicTask.TASK_2: return Enemy.DARING_HARRIER;
			case RelicTask.TASK_3: return Enemy.COHORT_VANGUARD_5TH;
			case RelicTask.TASK_4: return Enemy.COHORT_HOPLOMACHUS_4TH;
			case RelicTask.TASK_6: return Enemy.BASILISK;
			case RelicTask.TASK_7: return Enemy.ZANRAK_PUGILIST;
			case RelicTask.TASK_9: return Enemy.MILKROOT_CLUSTER;
			case RelicTask.TASK_12: return Enemy.GIANT_LOGGER;
			case RelicTask.TASK_14: return Enemy.SYNTHETIC_DOBLYN;
			case RelicTask.TASK_15: return Enemy.SHOALSPINE_SAHAGIN;
			case RelicTask.TASK_16: return Enemy.COHORT_HOPLOMACHUS_2ND;
		}
	}

	private skyfire_2Enemy(task:RelicTask):Enemy{
		switch(task){
			default: return Enemy.UNKNOWN;
			case RelicTask.TASK_2: return Enemy.RAGING_HARRIER;
			case RelicTask.TASK_4: return Enemy.BIAST;
			case RelicTask.TASK_5: return Enemy.NATALAN_BOLDWING;
			case RelicTask.TASK_7: return Enemy.SHOALTOOTH_SAHAGIN;
			case RelicTask.TASK_8: return Enemy.SHELFSCALE_REAVER;
			case RelicTask.TASK_9: return Enemy.UGHAMARO_GOLEM;
			case RelicTask.TASK_11: return Enemy.DULLAHAN;
			case RelicTask.TASK_12: return Enemy.SYLPHEED_SIGH;
			case RelicTask.TASK_14: return Enemy.ZAHARAK_ARCHER;
			case RelicTask.TASK_15: return Enemy.TEMPERED_GLADIATOR;
		}
	}

	private netherfire_1Enemy(task:RelicTask):Enemy{
		switch(task){
			default: return Enemy.UNKNOWN;
			case RelicTask.TASK_3: return Enemy.HEXING_HARRIER;
			case RelicTask.TASK_4: return Enemy.GIGAS_BONZE;
			case RelicTask.TASK_6: return Enemy.GIANT_LUGGER;
			case RelicTask.TASK_7: return Enemy.WILD_HOG;
			case RelicTask.TASK_9: return Enemy.SYLPHEED_SCREECH;
			case RelicTask.TASK_10: return Enemy.UGHAMARO_ROUNDSMAN;
			case RelicTask.TASK_11: return Enemy.SHELFCLAW_REAVER;
			case RelicTask.TASK_12: return Enemy.COHORT_LAQUEARIUS_2ND;
			case RelicTask.TASK_14: return Enemy.ZAHARAK_FORTUNETELLER;
			case RelicTask.TASK_15: return Enemy.TEMPERED_ORATOR;
		}
	}

	private skyfall_1Enemy(task:RelicTask):Enemy{
		switch(task){
			default: return Enemy.UNKNOWN;
			case RelicTask.TASK_2: return Enemy.MUDPUPPY;
			case RelicTask.TASK_3: return Enemy.LAKE_COBRA;
			case RelicTask.TASK_6: return Enemy.GIANT_READER;
			case RelicTask.TASK_8: return Enemy.SHELFSCALE_SAHAGIN;
			case RelicTask.TASK_9: return Enemy.SEA_WASP;
			case RelicTask.TASK_10: return Enemy.UGHAMARO_QUARRYMAN;
			case RelicTask.TASK_11: return Enemy.COHORT_EQUES_2ND;
			case RelicTask.TASK_13: return Enemy.MAGITEK_VANGUARD;
			case RelicTask.TASK_14: return Enemy.AMALJAA_LANCER;
			case RelicTask.TASK_16: return Enemy.SYLPHLANDS_SENTINEL;
		}
	}

	private skyfall_2Enemy(task:RelicTask):Enemy{
		switch(task){
			default: return Enemy.UNKNOWN;
			case RelicTask.TASK_3: return Enemy.GIGAS_BHIKKHU;
			case RelicTask.TASK_4: return Enemy.COHORT_HOPLOMACHUS_5TH;
			case RelicTask.TASK_6: return Enemy.NATALAN_WATCHWOLF;
			case RelicTask.TASK_8: return Enemy.SYLPH_BONNET;
			case RelicTask.TASK_9: return Enemy.KED;
			case RelicTask.TASK_10: return Enemy.COHORT_LAQUEARIUS_4TH;
			case RelicTask.TASK_11: return Enemy.IRON_TORTOISE;
			case RelicTask.TASK_14: return Enemy.SHELFEYE_REAVER;
			case RelicTask.TASK_15: return Enemy.SAPSA_SHELFSCALE;
			case RelicTask.TASK_16: return Enemy.UGHAMARO_BEDESMAN;
		}
	}

	private netherfall_1Enemy(task:RelicTask):Enemy{
		switch(task){
			default: return Enemy.UNKNOWN;
			case RelicTask.TASK_3: return Enemy.AMALJAA_BRIGAND;
			case RelicTask.TASK_4: return Enemy.COHORT_SECUTOR_4TH;
			case RelicTask.TASK_7: return Enemy.SNOW_WOLF;
			case RelicTask.TASK_8: return Enemy.SAPSA_SHELFCLAW;
			case RelicTask.TASK_9: return Enemy.UGHAMARO_PRIEST;
			case RelicTask.TASK_10: return Enemy.VIOLET_SCREECH;
			case RelicTask.TASK_12: return Enemy.IXALI_WINDTALON;
			case RelicTask.TASK_13: return Enemy.LESSER_KALONG;
			case RelicTask.TASK_15: return Enemy.GIGAS_SOZU;
			case RelicTask.TASK_16: return Enemy.COHORT_LAQUEARIUS_5TH;
		}
	}

	private skywind_1Enemy(task:RelicTask):Enemy{
		switch(task){
			default: return Enemy.UNKNOWN;
			case RelicTask.TASK_3: return Enemy.HIPPOGRYPH;
			case RelicTask.TASK_4: return Enemy.COHORT_EQUES_5TH;
			case RelicTask.TASK_6: return Enemy.NATALAN_WINDTALON;
			case RelicTask.TASK_7: return Enemy.SAPSA_ELBST;
			case RelicTask.TASK_8: return Enemy.TRENCHTOOTH_SAHAGIN;
			case RelicTask.TASK_9: return Enemy.ELITE_ROUNDSMAN;
			case RelicTask.TASK_11: return Enemy.COHORT_SECUTORES_2ND;
			case RelicTask.TASK_13: return Enemy.AHRIMAN;
			case RelicTask.TASK_14: return Enemy.AMALJAA_THAUMATURGE;
			case RelicTask.TASK_15: return Enemy.SYLPHEED_SNARL;
		}
	}

	private skywind_2Enemy(task:RelicTask):Enemy{
		switch(task){
			default: return Enemy.UNKNOWN;
			case RelicTask.TASK_2: return Enemy.GIGAS_SHRAMANA;
			case RelicTask.TASK_3: return Enemy.COHORT_SIGNIFER_5TH;
			case RelicTask.TASK_4: return Enemy.WATCHWOLF;
			case RelicTask.TASK_5: return Enemy.DREAMTOAD;
			case RelicTask.TASK_8: return Enemy.ZAHARAK_BATTLE_DRAKE;
			case RelicTask.TASK_9: return Enemy.AMALJAA_ARCHER;
			case RelicTask.TASK_10: return Enemy.COHORT_SIGNIFER_4TH;
			case RelicTask.TASK_12: return Enemy.ELITE_PRIEST;
			case RelicTask.TASK_13: return Enemy.SAPSA_SHELFTOOTH;
			case RelicTask.TASK_16: return Enemy.NATALAN_FOGCALLER;
		}
	}

	private skyearth_1Enemy(task:RelicTask):Enemy{
		switch(task){
			default: return Enemy.UNKNOWN;
			case RelicTask.TASK_1: return Enemy.VIOLET_SIGH;
			case RelicTask.TASK_2: return Enemy.IXALI_BOLDWING;
			case RelicTask.TASK_5: return Enemy.AMALJAA_SCAVENGER;
			case RelicTask.TASK_6: return Enemy.ZAHARAK_PUGILIST;
			case RelicTask.TASK_7: return Enemy.AXOLOTL;
			case RelicTask.TASK_9: return Enemy.ELITE_QUARRYMAN;
			case RelicTask.TASK_10: return Enemy.COHORT_SIGNIFER_2ND;
			case RelicTask.TASK_13: return Enemy.NATALAN_SWIFTBEAK;
			case RelicTask.TASK_15: return Enemy.COHORT_SECUTOR_5TH;
			case RelicTask.TASK_16: return Enemy.HAPALIT;
		}
	}
}

const relics = new RelicsManager();
export default relics;
