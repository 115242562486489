/* eslint-disable max-len */
//#region Imports
import { jobLists } from "character";
import FormComponents from "components/forms/FormComponents";
import JobIcon from "components/job-icon/JobIcon";
import Link from "components/link/Link";
import NoCharacter from "components/no-character/NoCharacter";
import RelicCheckbox from "components/relic-checkbox/RelicCheckbox";
import { items } from "data-files/items";
import { quests } from "data-files/quests";
import { EorzeaDataType, InputType, Item, Job, LinkType, NPC, Quest, RelicGroup, RelicPart, RelicStep, RelicTask, RelicType, View } from "enums";
import manager from "managers/app";
import { Component } from "react";
import { connect } from "react-redux";
import t from "translations/translator";
import { AppState, Requirement, TaskInfo, ViewRelicInfo, ViewToolSplendorousLodestarProps } from "types";
import utils from "utils";
import ContentHeader from "views/page-parts/content/content-header/ContentHeader";
import DetailsSection from "views/page-parts/content/details-section/DetailsSection";
import Obtained from "views/page-parts/content/obtained/Obtained";
import OverviewTable from "views/page-parts/content/overview-table/OverviewTable";
import "./ToolSplendorousLodestar.css";
//#endregion Imports

const mapStateToProps = (state:AppState) => {
	return {
		lang: state.userInfo.options.lang,
		gameLang: state.userInfo.options.gameLang,
		userInfo: state.userInfo,
		hideCompletedSteps: state.userInfo.options.hideCompletedSteps,
		hideCompletedTasks: state.userInfo.options.hideCompletedTasks,
		showMaximumItemQty: state.userInfo.options.showMaximumItemQty,
		showJob: state.userInfo.options.job,
	};
};

class ToolSplendorousLodestar extends Component<ViewToolSplendorousLodestarProps> {
	private requirements:Requirement[] = [];
	private importantNPCs:NPC[] = [];
	private relicInfo:ViewRelicInfo = {
		jobs: jobLists.relic[RelicGroup.TOOL_SPLENDOROUS],
		type: RelicType.TOOL,
		relic: RelicGroup.TOOL_SPLENDOROUS,
		part: RelicPart.TOOL_LODESTAR,
		steps: [RelicStep.NIGHTFORGED_COMPONENT],
		stepPlaceholders: { [RelicStep.NIGHTFORGED_COMPONENT]: { ITEM: "Nightforged Components" } },
		next: null,
		previous: {
			part: RelicPart.TOOL_VRANDTIC_VISIONARYS,
			view: View.RELIC_TOOLS_SPLENDOROUS_VRANDTIC_VISIONARYS,
		},
	};

	constructor(props:ViewToolSplendorousLodestarProps){
		super(props);
		this.requirements = this.getRequirements();
		this.importantNPCs = this.getImportantNPCs();
	}

	render(){
		const character = manager.data.getActiveCharacter();
		if(character === null){ return <NoCharacter />; }

		const completeSteps = manager.content.getCompleteSteps(character.progress, this.relicInfo);

		let row = "odd-row";

		return (
			<div id="tool-splendorous-lodestar" className="relic">
				<ContentHeader requirements={this.requirements} importantNPCs={this.importantNPCs} relicInfo={this.relicInfo}>
					<p>{t.t("Complete")}<Link type={LinkType.LODESTONE} lodestoneType={EorzeaDataType.BLUEQUEST} text={Quest.STAND_TOOL_MY_FRIEND} lodestoneID={quests[Quest.STAND_TOOL_MY_FRIEND].lodestoneID} /></p>
				</ContentHeader>
				<div id="relic-checklist">
					<OverviewTable character={character} completeSteps={completeSteps} relicInfo={this.relicInfo} />
				</div>
				<div id="relic-details">
					<h1>{t.t("Details")}</h1>
					{this.props.hideCompletedSteps && completeSteps.allComplete ? t.t("All Steps Complete") : null }
					<DetailsSection title={RelicStep.NIGHTFORGED_COMPONENT} placeholders={this.relicInfo.stepPlaceholders[RelicStep.NIGHTFORGED_COMPONENT]} id={RelicStep.NIGHTFORGED_COMPONENT} stepComplete={completeSteps[RelicStep.NIGHTFORGED_COMPONENT]}>
						<div className="table-wrapper">
							<table className="table">
								<thead><tr><th>{t.t("Job")}</th><th>{t.t("Task")}</th><th>{t.t("Found")}</th><th>{t.t("Quantity")}</th><th>{t.t("Inventory")}</th><th>{t.t("To Obtain")}</th><th>{t.t("Done")}</th></tr></thead>
								<tbody>
									{
										this.relicInfo.jobs.map((job) => {
											if(this.props.showJob !== null && this.props.showJob !== job){ return null; }

											const stepComplete = character.progress.isComplete(this.relicInfo.type, this.relicInfo.relic, this.relicInfo.part, RelicStep.NIGHTFORGED_COMPONENT, job);
											if(this.props.hideCompletedSteps && stepComplete){ return null; }

											const taskInfo:TaskInfo[] = [{
												task: RelicTask.TASK_1,
												complete: character.progress.isComplete(this.relicInfo.type, this.relicInfo.relic, this.relicInfo.part, RelicStep.NIGHTFORGED_COMPONENT, RelicTask.TASK_1, job),
												target: manager.relics.getTaskItem(RelicStep.NIGHTFORGED_COMPONENT, RelicTask.TASK_1, job),
											}];

											if(jobLists.type.gatherer.includes(job)){
												taskInfo.push({
													task: RelicTask.TASK_2,
													complete: character.progress.isComplete(this.relicInfo.type, this.relicInfo.relic, this.relicInfo.part, RelicStep.NIGHTFORGED_COMPONENT, RelicTask.TASK_2, job),
													target: manager.relics.getTaskItem(RelicStep.NIGHTFORGED_COMPONENT, RelicTask.TASK_2, job)
												});
											}

											let tableRows:(JSX.Element|null)[] = [];

											if(job === Job.FSH){
												const task1Info = taskInfo[0];
												const task2Info = taskInfo[1];

												const task1Target = utils.guards.isEnumValue(Item, task1Info.target) ? task1Info.target : Item.UNKNOWN;
												const task2Target = utils.guards.isEnumValue(Item, task2Info.target) ? task2Info.target : Item.UNKNOWN;

												const task1Inventory = manager.data.getInventoryStatus({
													item: task1Target,
													relic: [this.relicInfo.type, this.relicInfo.relic, this.relicInfo.part, RelicStep.NIGHTFORGED_COMPONENT, task1Info.task],
													job: job,
													getMax: this.props.showMaximumItemQty,
												});
												const task2Inventory = manager.data.getInventoryStatus({
													item: task2Target,
													relic: [this.relicInfo.type, this.relicInfo.relic, this.relicInfo.part, RelicStep.NIGHTFORGED_COMPONENT, task2Info.task],
													job: job,
													getMax: this.props.showMaximumItemQty,
												});

												let totalOwnedComponents = 0;

												if(this.props.showMaximumItemQty){
													totalOwnedComponents = task1Inventory.inventory + (task2Inventory.inventory * 3);
													task1Inventory.toObtain = task1Inventory.total - totalOwnedComponents;
													task2Inventory.toObtain = task2Inventory.total - (totalOwnedComponents / 3);
												}else{
													totalOwnedComponents = (task1Inventory.inventory * 2) + (task2Inventory.inventory * 4);
													task1Inventory.toObtain = task1Inventory.total - (totalOwnedComponents / 2);
													task2Inventory.toObtain = task2Inventory.total - (totalOwnedComponents / 4);
												}

												tableRows = taskInfo.map((thisTask, taskIndex) => {
													if(this.props.hideCompletedTasks && thisTask.complete){ return null; }

													const thisTarget = thisTask.task === RelicTask.TASK_1 ? task1Target : task2Target;
													const inventory = thisTask.task === RelicTask.TASK_1 ? task1Inventory : task2Inventory;

													const showJobIcon = manager.content.shouldShowJobIcon(this.props.hideCompletedTasks, taskInfo, taskIndex);
													const rowSpan = manager.content.getRowSpan(this.props.hideCompletedTasks, taskInfo, taskIndex) + 1;

													return <tr key={`${job}-${RelicStep.NIGHTFORGED_COMPONENT}-${thisTask.task}`} className={row}>
														{showJobIcon ? <td rowSpan={rowSpan}><JobIcon job={job} /></td> : null}
														<td>{t.t(thisTarget)}</td>
														<td><Obtained obtained={items[thisTarget].obtained} qtyNeeded={inventory.total} /></td>
														<td>{inventory.qtyPerJob.toLocaleString()}</td>
														<td><FormComponents.TextInput label={{ hide: true }} input={{ type: InputType.NUMBER, value: inventory.inventory, dataAttributes: { item: thisTarget }, events: { onChange: manager.content.inventoryUpdated }}} /></td>
														<td>{inventory.toObtain.toLocaleString()}</td>
														<td><RelicCheckbox character={character} job={job} relicInfo={this.relicInfo} step={RelicStep.NIGHTFORGED_COMPONENT} task={thisTask.task} /></td>
													</tr>;
												});

												tableRows.push(
													<tr key={`${job}-${RelicStep.NIGHTFORGED_COMPONENT}-inforow`} className={row}>
														<td><p>{t.t("Note")}:</p></td>
														<td colSpan={5}>
															<p>{t.t("Both fish provide the same component")}</p>
															<p>{t.t("Quantity column shows the number of that single fish is required, where as the To Obtain column takes inventory from both fish into account")}</p>
														</td>
													</tr>,
												);
											}else{
												tableRows = taskInfo.map((thisTask, taskIndex) => {
													if(this.props.hideCompletedTasks && thisTask.complete){ return null; }

													let thisTarget = Item.UNKNOWN;
													if(utils.guards.isEnumValue(Item, thisTask.target)){ thisTarget = thisTask.target; }

													const inventory = manager.data.getInventoryStatus({
														item: thisTarget,
														relic: [this.relicInfo.type, this.relicInfo.relic, this.relicInfo.part, RelicStep.NIGHTFORGED_COMPONENT, thisTask.task],
														job: job,
														getMax: this.props.showMaximumItemQty,
													});

													const showJobIcon = manager.content.shouldShowJobIcon(this.props.hideCompletedTasks, taskInfo, taskIndex);
													const rowSpan = manager.content.getRowSpan(this.props.hideCompletedTasks, taskInfo, taskIndex);

													return <tr key={`${job}-${RelicStep.NIGHTFORGED_COMPONENT}-${thisTask.task}`} className={row}>
														{showJobIcon ? <td rowSpan={rowSpan}><JobIcon job={job} /></td> : null}
														<td>{t.t(thisTarget)}</td>
														<td><Obtained obtained={items[thisTarget].obtained} qtyNeeded={inventory.total} /></td>
														<td>{inventory.qtyPerJob.toLocaleString()}</td>
														<td><FormComponents.TextInput label={{ hide: true }} input={{ type: InputType.NUMBER, value: inventory.inventory, dataAttributes: { item: thisTarget }, events: { onChange: manager.content.inventoryUpdated }}} /></td>
														<td>{inventory.toObtain.toLocaleString()}</td>
														<td><RelicCheckbox character={character} job={job} relicInfo={this.relicInfo} step={RelicStep.NIGHTFORGED_COMPONENT} task={thisTask.task} /></td>
													</tr>;
												});
											}

											row = row === "odd-row" ? "even-row" : "odd-row";
											return tableRows;
										})
									}
									{
										completeSteps[RelicStep.NIGHTFORGED_COMPONENT]
											? <tr className="all-complete"><td colSpan={7}><p>{t.t("All Complete")}</p></td></tr>
											: null
									}
								</tbody>
							</table>
						</div>
					</DetailsSection>
				</div>
			</div>
		);
	}

	componentDidMount(){
		manager.view.changeComplete();
	}

	private getRequirements():Requirement[]{
		if(!this.relicInfo || this.relicInfo.previous === null){ return []; }
		return [
			{ type: "previous part", part: this.relicInfo.previous.part, partView: this.relicInfo.previous.view },
		];
	}

	private getImportantNPCs():NPC[]{
		return [];
	}
}
export default connect(mapStateToProps)(ToolSplendorousLodestar);
