/* eslint-disable max-len */
//#region Imports
import { jobLists } from "character";
import FormComponents from "components/forms/FormComponents";
import Link from "components/link/Link";
import NoCharacter from "components/no-character/NoCharacter";
import { items } from "data-files/items";
import { quests } from "data-files/quests";
import { InputType, Item, LinkType, NPC, Quest, RelicGroup, RelicPart, RelicStep, RelicTask, RelicType, View } from "enums";
import manager from "managers/app";
import { Component } from "react";
import { connect } from "react-redux";
import t from "translations/translator";
import { AppState, Requirement, ViewRelicInfo, ViewWeaponZodiacNovusProps } from "types";
import ContentHeader from "views/page-parts/content/content-header/ContentHeader";
import DetailsSection from "views/page-parts/content/details-section/DetailsSection";
import Obtained from "views/page-parts/content/obtained/Obtained";
import OverviewTable from "views/page-parts/content/overview-table/OverviewTable";
import "./WeaponZodiacNovus.css";
//#endregion Imports

const mapStateToProps = (state:AppState) => {
	return {
		lang: state.userInfo.options.lang,
		gameLang: state.userInfo.options.gameLang,
		userInfo: state.userInfo,
		hideCompletedSteps: state.userInfo.options.hideCompletedSteps,
		hideCompletedTasks: state.userInfo.options.hideCompletedTasks,
		showJob: state.userInfo.options.job,
	};
};

class WeaponZodiacNovus extends Component<ViewWeaponZodiacNovusProps> {
	private requirements:Requirement[] = [];
	private importantNPCs:NPC[] = [];
	private relicInfo:ViewRelicInfo = {
		jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC],
		type: RelicType.WEAPON,
		relic: RelicGroup.WEAPON_ZODIAC,
		part: RelicPart.WEAPON_NOVUS,
		steps: [RelicStep.RADIANCE, RelicStep.SCROLL, RelicStep.STAR, RelicStep.ALEXANDRITE, RelicStep.BINDING],
		stepPlaceholders: {
			[RelicStep.RADIANCE]: { QUEST: Quest.CELESTIAL_RADIANCE },
			[RelicStep.SCROLL]: { ITEM: "Sphere Scroll" },
			[RelicStep.STAR]: { QUEST: Quest.STAR_LIGHT_STAR_BRIGHT },
			[RelicStep.ALEXANDRITE]: { ITEM: "Alexandrite" },
		},
		next: {
			part: RelicPart.WEAPON_NEXUS,
			view: View.RELIC_WEAPONS_ZODIAC_NEXUS,
		},
		previous: {
			part: RelicPart.WEAPON_ANIMUS,
			view: View.RELIC_WEAPONS_ZODIAC_ANIMUS,
		},
	};

	constructor(props:ViewWeaponZodiacNovusProps){
		super(props);
		this.requirements = this.getRequirements();
		this.importantNPCs = this.getImportantNPCs();
	}

	// eslint-disable-next-line complexity
	render(){
		const character = manager.data.getActiveCharacter();
		if(character === null){ return <NoCharacter />; }

		const completeSteps = manager.content.getCompleteSteps(character.progress, this.relicInfo);
		const scrollInventory = manager.data.getInventoryStatus({
			item: manager.relics.getTaskItem(RelicStep.SCROLL, RelicTask.TASK_1),
			relic: [this.relicInfo.type, this.relicInfo.relic, this.relicInfo.part, RelicStep.SCROLL, RelicTask.TASK_1],
		});
		const alexandriteInventory = manager.data.getInventoryStatus({
			item: manager.relics.getTaskItem(RelicStep.ALEXANDRITE, RelicTask.TASK_1),
			relic: [this.relicInfo.type, this.relicInfo.relic, this.relicInfo.part, RelicStep.ALEXANDRITE, RelicTask.TASK_1],
		});

		return (
			<div id="weapon-zodiac-novus" className="relic">
				<ContentHeader requirements={this.requirements} importantNPCs={this.importantNPCs} relicInfo={this.relicInfo}>
					<p>{t.t("The %PART% upgrade follows on from %PREVIOUSPART%", { PART: this.relicInfo.part, PREVIOUSPART: this.relicInfo.previous ? this.relicInfo.previous.part : "Unknown"})}</p>
				</ContentHeader>
				<div id="relic-checklist">
					<OverviewTable character={character} completeSteps={completeSteps} relicInfo={this.relicInfo} />
				</div>
				<div id="relic-details">
					<h1>{t.t("Details")}</h1>
					{this.props.hideCompletedSteps && completeSteps.allComplete ? t.t("All Steps Complete") : null }
					<DetailsSection title={RelicStep.RADIANCE} placeholders={this.relicInfo.stepPlaceholders[RelicStep.RADIANCE]} id={RelicStep.RADIANCE} stepComplete={completeSteps[RelicStep.RADIANCE]}><p>{t.t("Complete")}<Link type={LinkType.LODESTONE} lodestoneType={quests[Quest.CELESTIAL_RADIANCE].type} text={quests[Quest.CELESTIAL_RADIANCE].name} lodestoneID={quests[Quest.CELESTIAL_RADIANCE].lodestoneID} /></p></DetailsSection>
					<DetailsSection title={RelicStep.SCROLL} placeholders={this.relicInfo.stepPlaceholders[RelicStep.SCROLL]} id={RelicStep.SCROLL} stepComplete={completeSteps[RelicStep.SCROLL]}>
						<div className="table-wrapper">
							<table className="table">
								<thead><tr><th>{t.t("Obtained")}</th><th>{t.t("Item")}</th><th>{t.t("Quantity / Job")}</th><th>{t.t("Total")}</th><th>{t.t("Used")}</th><th>{t.t("Remaining")}</th><th>{t.t("Inventory")}</th><th>{t.t("To Obtain")}</th></tr></thead>
								<tbody>
									{this.props.hideCompletedTasks && scrollInventory.toObtain === 0
										? null
										: <tr className="odd-row">
											<td><Obtained obtained={items[Item.SUPERIOR_ENCHANTED_INK].obtained} qtyNeeded={scrollInventory.total} /></td>
											<td>{t.t(items[Item.SUPERIOR_ENCHANTED_INK].name)}</td>
											<td>{scrollInventory.qtyPerJob.toLocaleString()}</td>
											<td>{scrollInventory.total.toLocaleString()}</td>
											<td>{scrollInventory.used.toLocaleString()}</td>
											<td>{scrollInventory.remaining.toLocaleString()}</td>
											<td><FormComponents.TextInput label={{ hide: true }} input={{ type: InputType.NUMBER, value: scrollInventory.inventory, dataAttributes: { item: Item.SUPERIOR_ENCHANTED_INK }, events: { onChange: manager.content.inventoryUpdated }}} /></td>
											<td>{scrollInventory.toObtain.toLocaleString()}</td>
										</tr>
									}
									{
										character.progress.isComplete(this.relicInfo.type, this.relicInfo.relic, this.relicInfo.part, RelicStep.SCROLL)
											? <tr className="all-complete"><td colSpan={8}><p>{t.t("All Complete")}</p></td></tr>
											: null
									}
								</tbody>
							</table>
						</div>
					</DetailsSection>
					<DetailsSection title={RelicStep.STAR} placeholders={this.relicInfo.stepPlaceholders[RelicStep.STAR]} id={RelicStep.STAR} stepComplete={completeSteps[RelicStep.STAR]}><p>{t.t("Complete")}<Link type={LinkType.LODESTONE} lodestoneType={quests[Quest.STAR_LIGHT_STAR_BRIGHT].type} text={quests[Quest.STAR_LIGHT_STAR_BRIGHT].name} lodestoneID={quests[Quest.STAR_LIGHT_STAR_BRIGHT].lodestoneID} /></p></DetailsSection>
					<DetailsSection title={RelicStep.ALEXANDRITE} placeholders={this.relicInfo.stepPlaceholders[RelicStep.ALEXANDRITE]} id={RelicStep.ALEXANDRITE} stepComplete={completeSteps[RelicStep.ALEXANDRITE]}>
						<div className="table-wrapper">
							<table className="table">
								<thead><tr><th>{t.t("Obtained")}</th><th>{t.t("Item")}</th><th>{t.t("Quantity / Job")}</th><th>{t.t("Total")}</th><th>{t.t("Used")}</th><th>{t.t("Remaining")}</th><th>{t.t("Inventory")}</th><th>{t.t("To Obtain")}</th></tr></thead>
								<tbody>
									{this.props.hideCompletedTasks && alexandriteInventory.toObtain === 0
										? null
										: <tr className="odd-row">
											<td><Obtained obtained={items[Item.ALEXANDRITE].obtained} qtyNeeded={alexandriteInventory.total} /></td>
											<td>{t.t(items[Item.ALEXANDRITE].name)}</td>
											<td>{alexandriteInventory.qtyPerJob.toLocaleString()}</td>
											<td>{alexandriteInventory.total.toLocaleString()}</td>
											<td>{alexandriteInventory.used.toLocaleString()}</td>
											<td>{alexandriteInventory.remaining.toLocaleString()}</td>
											<td><FormComponents.TextInput label={{ hide: true }} input={{ type: InputType.NUMBER, value: alexandriteInventory.inventory, dataAttributes: { item: Item.ALEXANDRITE }, events: { onChange: manager.content.inventoryUpdated }}} /></td>
											<td>{alexandriteInventory.toObtain.toLocaleString()}</td>
										</tr>
									}
									{
										character.progress.isComplete(this.relicInfo.type, this.relicInfo.relic, this.relicInfo.part, RelicStep.ALEXANDRITE)
											? <tr className="all-complete"><td colSpan={8}><p>{t.t("All Complete")}</p></td></tr>
											: null
									}
								</tbody>
							</table>
						</div>
					</DetailsSection>
					<DetailsSection title={RelicStep.BINDING} id={RelicStep.BINDING} stepComplete={completeSteps[RelicStep.BINDING]}>
						<p>{t.t("This step requires 75 of various Grade I-IV materia along with the Alexandrite and Sphere Scroll from the previous steps")}</p>
						<p>{t.t("The materia you choose will effect the stats on the created weapon")}</p>
						<p>{t.t("You can track your progress through the Sphere Scroll")}</p>
					</DetailsSection>
				</div>
			</div>
		);
	}

	componentDidMount(){
		manager.view.changeComplete();
	}

	private getRequirements():Requirement[]{
		if(!this.relicInfo || this.relicInfo.previous === null){ return []; }
		return [
			{ type: "previous part", part: this.relicInfo.previous.part, partView: this.relicInfo.previous.view },
		];
	}

	private getImportantNPCs():NPC[]{
		return [NPC.JALZAHN, NPC.HUBAIRTIN];
	}
}
export default connect(mapStateToProps)(WeaponZodiacNovus);
