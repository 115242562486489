//#region Imports
import { APIEndpoint, APIStatus } from "enums";
import { AppManagers, GetNoticesRequest, GetNoticesResponse } from "types";
import log from "../logger";
import auth from "./auth";
import content from "./content";
import data from "./data/data";
import ga from "./ga";
import appIcons from "./icons";
import modal from "./modals";
import net from "./net";
import relics from "./relics";
import { actions, store } from "./state";
import sysMsg from "./sysMsg";
import view from "./view/view";
//#endregion Imports

const manager:AppManagers = {
	init: managerInit,
	auth: auth,
	data: data,
	view: view,
	request: net,
	relics: relics,
	content: content,
	modal: modal,
	icons: appIcons,
	ga: ga,
};
export default manager;

async function managerInit():Promise<void>{
	log.status("Initialisation Start");

	ga.init();
	net.init();
	relics.init();
	initNotices();

	/* Do auth init & data last */
	await auth.init();
	const isDataSetup = await data.init();
	if(!isDataSetup){
		log.status("Unable to setup data");
		sysMsg.error({
			title: "Error preparing data",
			message: "Refresh the page and try again, if the problem persists get in contact",
		}, 0);
	}
	log.status("Initialisation Completed");
}

function initNotices(){
	const interval = 60 * 60 * 1000; // 1hr
	setInterval(fetchNotices, interval);
	fetchNotices();
}

async function fetchNotices(){
	const result = await manager.request.send<GetNoticesRequest, GetNoticesResponse>(APIEndpoint.GET_NOTICES, {});
	if(!result){ return; }

	if(result.status === APIStatus.SUCCESS){
		store.dispatch(actions.setNotices(result.data.notices));
	}
}
