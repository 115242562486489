//#region Imports
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AppConstants from "AppConstants";
import SystemMessages from "components/system-messages/SystemMessages";
import "css/animations.css";
import "css/page-content/app.css";
import "css/page-content/general.css";
import "css/themes/theme.css";
import "css/variables.css";
import { Breakpoint, RelicGroup, RelicPart, RelicType, View } from "enums";
import log from "logger";
import manager from "managers/app";
import { actions } from "managers/state";
import Modals from "modals/Modals";
import React, { Component, Suspense } from "react"; // eslint-disable-line @typescript-eslint/no-shadow
import { connect } from "react-redux";
import { Dispatch } from "redux";
import t from "translations/translator";
import { AppState, AppWrapperProps, OutputGameString, OutputString } from "types";
import utils from "utils";
import AppMenu from "views/page-parts/app-menu/Menu";
import SiteHeader from "views/page-parts/site-header/SiteHeader";
import Unknown from "views/unknown/Unknown";
import "yet-another-react-lightbox/plugins/captions.css";
import "yet-another-react-lightbox/plugins/counter.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import "yet-another-react-lightbox/styles.css";

//#endregion Imports

const AppNotices = React.lazy(() => { return import("components/app-notices/AppNotices"); });

window.addEventListener("message", (e) => {
	if (e.data && typeof e.data === "string" && e.data.match(/webpackHotUpdate/)){
		console.log("--------------------------------------------------------------------"); // eslint-disable-line no-console
	}
});

const mapStateToProps = (state:AppState) => {
	return {
		theme: state.userInfo.options.theme,
		lang: state.userInfo.options.lang,
		gameLang: state.userInfo.options.gameLang,
		view: state.view,
	};
};

const mapDispatchToProps = (dispatch:Dispatch) => {
	return { setBreakpoint: (breakpoint:Breakpoint) => { dispatch(actions.setBreakpoint(breakpoint)); } };
};

class App extends Component<AppWrapperProps> {
	render(){
		const component = manager.view.getViewComponent();
		const view = component === null ? View.PAGE_NOT_FOUND : this.props.view;
		const crumbs = this.getBreadcrumbs(view);

		return (
			<div id="site-wrapper" className={this.props.theme}>
				<SiteHeader />
				<div id="main">
					<AppMenu />
					<div id="page-wrapper">
						<div id="loading-overlay">
							<div className="loading">
								<FontAwesomeIcon icon={faCircle} />
							</div>
						</div>
						<div id="breadcrumbs">
							{crumbs.map((crumb:OutputString|OutputGameString, index:number) => {
								const marker = index > 0 ? <span className="marker">&gt;</span> : null;
								return <p key={`crumbs_${crumb}_${index}`}>{marker}<span key={crumb} className="breadcrumb">{t.t(crumb)}</span></p>;
							})}
						</div>
						<Suspense fallback={null}>
							<AppNotices />
						</Suspense>
						<div id="content-wrapper">
							<div id="content">
								{component === null ? <Unknown /> : component}
							</div>
						</div>
					</div>
				</div>
				<SystemMessages />
				<Modals />
			</div>
		);
	}

	private getBreadcrumbs(view:View):(OutputString|OutputGameString)[]{
		const arr = view.split("-");
		const type = arr[0];

		let result:(OutputString|OutputGameString)[] = [];

		if(utils.guards.isEnumValue(RelicType, type)){
			const relic = arr[1] as RelicGroup;
			const part = arr[2] as RelicPart;

			if(part as string === "overall"){
				const overall = part.charAt(0).toUpperCase() + part.slice(1);
				result.push(type, relic, overall as OutputString|OutputGameString);
			}else{
				result.push(type, relic, part);
			}
		}else{
			result = arr.map((val:string) => {
				const formattedVal = val.split("_").map((word) => { return word.charAt(0).toUpperCase() + word.slice(1); }).join(" ");
				return formattedVal as OutputString|OutputGameString;
			});
		}
		return result;
	}

	async componentDidMount(){
		log.status("Preparing App");

		await manager.init();

		window.addEventListener("resize", this.setBreakpoint);
		this.setBreakpoint();

		log.status("App Ready!");
	}

	componentWillUnmount(){
		manager.request.abortAll();
	}

	setBreakpoint = () => { // Event
		const vw = utils.helpers.getWindowWidth();
		let breakpoint = Breakpoint.DESKTOP;
		switch(true){
			default: breakpoint = Breakpoint.DESKTOP; break;
			case vw <= AppConstants.MobileWidth: breakpoint = Breakpoint.MOBILE; break; /* Var(--breakpoint-mobile-max-width) */
			case vw <= AppConstants.TabletWidth: breakpoint = Breakpoint.TABLET; break; /* Var(--breakpoint-tablet-max-width) */
		}
		this.props.setBreakpoint(breakpoint);
	}
}
export default connect(mapStateToProps, mapDispatchToProps)(App);
