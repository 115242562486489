//#region Imports
import { faBug, faCommentDots, faTasks } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FormComponents from "components/forms/FormComponents";
import TestsiteMessage from "components/testsite-message/TestsiteMessage";
import { View } from "enums";
import manager from "managers/app";
import { Component } from "react";
import { connect } from "react-redux";
import t from "translations/translator";
import { AppState, ViewHomeProps } from "types";
import LatestChanges from "views/changelog/LatestChanges";
import "./Home.css";
//#endregion Imports

const mapStateToProps = (state:AppState) => {
	return {
		lang: state.userInfo.options.lang,
		gameLang: state.userInfo.options.gameLang,
		appVersion: state.appVersion,
	};
};

class Home extends Component<ViewHomeProps> {
	render(){
		return (
			<div id="home">
				<div className="welcome-message">
					<h1>FFXIV Relic Tracker {t.t("Version")} {this.props.appVersion.toString()}</h1>
					<h2>{t.t("A Relic Tracker Reborn")}</h2>
					<TestsiteMessage />
					<p>
						<FontAwesomeIcon icon={faTasks} /> {t.t("Track your relic progress & Find out what you will need so you can spend those currencies effectively without sitting on cap!")}
					</p>
					<p>
						<FontAwesomeIcon icon={faCommentDots} /> <a href="https://discord.gg/7fBmAzsmgU">{t.t("Come mingle in the Discord")}</a> {t.t("Get some help, maybe find some relic farming buddies!")} {/* eslint-disable-line max-len */}
					</p>
					<p>
						<FontAwesomeIcon icon={faBug} /> {t.t("Found an error? or maybe a bug that needs squashing?")}&nbsp;
						<FormComponents.Button inline={true} asLink={true} onClick={this.showContactForm} asPlainLink={true} text="Fill out this form" />&nbsp;
						{t.t("or")}&nbsp;
						<a href="https://discord.gg/7fBmAzsmgU">{t.t("Report on Discord")}</a> {/* eslint-disable-line max-len */}
					</p>
				</div>
				<LatestChanges />
				<FormComponents.Button
					class={["changes-btn"]}
					onClick={this.viewChangeLogClick}
					text="View Change Log"
				/>
			</div>
		);
	}

	componentDidMount = () => {
		manager.view.changeComplete();
	}

	viewChangeLogClick = () => {
		manager.view.change(View.CHANGE_LOG);
	}

	showContactForm = () => {
		const iframe = document.getElementById("jsd-widget") as HTMLIFrameElement | null;
		if(!iframe){ return; }

		const content = iframe.contentDocument;
		if(!content){ return; }

		const helpButton = content.querySelector("#help-button") as HTMLDivElement | null;
		if(!helpButton){ return; }

		helpButton.click();
	}
}
export default connect(mapStateToProps)(Home);
