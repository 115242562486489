//#region Imports
import { availableRelicIDs } from "data-files/relic-ids";
import { Job, RelicGroup, RelicIndex, RelicPart, RelicStep, RelicTask, RelicType } from "enums";
import manager from "managers/app";
import { store } from "managers/state";
import utils from "utils";
import { CharacterProgressBit, CharacterProgressKey, JobLists, RelicID, StoredCharacterProgress } from "./types";
//#endregion Imports

const tankJobs = [Job.PLD, Job.WAR, Job.DRK, Job.GNB];
const healerJobs = [Job.WHM, Job.SCH, Job.AST, Job.SGE];
const dpsJobs = [Job.MNK, Job.DRG, Job.NIN, Job.SAM, Job.RPR, Job.BRD, Job.MCH, Job.DNC, Job.BLM, Job.SMN, Job.RDM];
const crafterJobs = [Job.CRP, Job.BSM, Job.ARM, Job.GSM, Job.LTW, Job.WVR, Job.ALC, Job.CUL];
const gathererJobs = [Job.MIN, Job.BTN, Job.FSH];

const arrJobs = [Job.PLD, Job.WAR, Job.WHM, Job.SCH, Job.MNK, Job.DRG, Job.NIN, Job.BRD, Job.BLM, Job.SMN];
const hwJobs = [Job.PLD, Job.WAR, Job.DRK, Job.WHM, Job.SCH, Job.AST, Job.MNK, Job.DRG, Job.NIN, Job.BRD, Job.MCH, Job.BLM, Job.SMN];
const sbJobs = [Job.PLD, Job.WAR, Job.DRK, Job.WHM, Job.SCH, Job.AST, Job.MNK, Job.DRG, Job.NIN, Job.SAM, Job.BRD, Job.MCH, Job.BLM, Job.SMN, Job.RDM];
const shbJobs = [Job.PLD, Job.WAR, Job.DRK, Job.GNB, Job.WHM, Job.SCH, Job.AST, Job.MNK, Job.DRG, Job.NIN, Job.SAM, Job.BRD, Job.MCH, Job.DNC, Job.BLM, Job.SMN, Job.RDM];
const ewJobs = [Job.PLD, Job.WAR, Job.DRK, Job.GNB, Job.WHM, Job.SCH, Job.AST, Job.SGE, Job.MNK, Job.DRG, Job.NIN, Job.SAM, Job.RPR, Job.BRD, Job.MCH, Job.DNC, Job.BLM, Job.SMN, Job.RDM];

const combatJobs = (<Job[]>[]).concat(tankJobs, healerJobs, dpsJobs);
const utilityJobs = (<Job[]>[]).concat(crafterJobs, gathererJobs);

export const jobLists:JobLists = {
	all: (<Job[]>[]).concat(combatJobs, utilityJobs),
	type: {
		combat: combatJobs,
		utility: utilityJobs,
		tanks: tankJobs,
		healers: healerJobs,
		dps: dpsJobs,
		crafter: crafterJobs,
		gatherer: gathererJobs,
	},
	relic: {
		[RelicGroup.WEAPON_ZODIAC]: arrJobs, [RelicGroup.WEAPON_ANIMA]: hwJobs, [RelicGroup.WEAPON_EUREKA]: sbJobs, [RelicGroup.WEAPON_RESISTANCE]: shbJobs, [RelicGroup.WEAPON_MANDERVILLE]: ewJobs,
		[RelicGroup.TOOL_LUCIS]: utilityJobs, [RelicGroup.TOOL_SKYSTEEL]: utilityJobs, [RelicGroup.TOOL_SPLENDOROUS]: utilityJobs,
		[RelicGroup.ARMOUR_ANEMOS]: sbJobs, [RelicGroup.ARMOUR_ELEMENTAL]: sbJobs, [RelicGroup.ARMOUR_BOZJAN]: shbJobs, [RelicGroup.ARMOUR_LAWS_ORDER]: shbJobs, [RelicGroup.ARMOUR_BLADES]: shbJobs,
	},
};
export const relicsStructure = generateData();

function generateData():StoredCharacterProgress{
	const final_data:StoredCharacterProgress = {};

	availableRelicIDs.forEach((id) => {
		const relicFromId = utils.helpers.getRelicFromId(id);
		relicFromId.reverse();
		/*
			id_parts = [
				0: task_1,
				1: class_weapon,
				2: base_weapon,
				3: zodiac,
				4: relic_weapon
			]
		*/
		const this_result:StoredCharacterProgress = relicFromId.reduce((prev, current) => {
			if(current){
				return { [current]: {...prev} };
			}
			return prev;
		}, {});
		/*
			this_result = {
				relic_weapon: {				// type
					zodiac: {				// group
						base_weapon: {		// part
							class_weapon: {	// step
								task_1: {}
							}
						}
					}
				}
			}
		*/
		utils.helpers.merge(final_data, this_result);
	});
	return final_data;
}

export class CharacterProgress {
	[RelicType.WEAPON] = {} as CharacterProgressBit;
	[RelicType.ARMOUR] = {} as CharacterProgressBit;
	[RelicType.TOOL] = {} as CharacterProgressBit;

	constructor(existingData?:StoredCharacterProgress);
	constructor(existingData?:string);
	constructor(existingData?:StoredCharacterProgress|string){
		const newList = this.createList(relicsStructure, null);
		if(existingData){
			let storedData = existingData;
			if(typeof storedData === "string"){
				storedData = JSON.parse(storedData) as StoredCharacterProgress;
			}
			utils.helpers.merge(this, newList, storedData);
		}else{
			utils.helpers.merge(this, newList);
		}
	}

	private createList(nextObj:StoredCharacterProgress, relic:RelicGroup|null):CharacterProgressBit{
		const thisList:CharacterProgressBit = {};
		const keys = Object.keys(nextObj) as (CharacterProgressKey)[];
		if(keys.length === 0){
			thisList._completed = [];
		}

		keys.forEach((key) => {
			const thisRelic = relic === null ? key : relic;
			if(utils.guards.isEnumValue(RelicType, key)){
				thisList[key] = this.createList(nextObj[key] as StoredCharacterProgress, null);
			}else if(utils.guards.isEnumValue(RelicGroup, thisRelic)){
				thisList[key] = this.createList(nextObj[key] as StoredCharacterProgress, thisRelic);
			}
		});

		return thisList;
	}

	private getListToCheck(type?:RelicType, relic?:RelicGroup, part?:RelicPart, step?: RelicStep, task?: RelicTask):CharacterProgressBit|null{ // eslint-disable-line max-params
		if(!type){ return this; }
		const typeList = this[type];
		if(!typeList){ return null; }

		if(!relic){ return typeList; }
		const relicList = typeList[relic];
		if(!relicList){ return null; }

		if(!part){ return relicList; }
		const partList = relicList[part];
		if(!partList){ return null; }

		if(!step){ return partList; }
		const stepList = partList[step];
		if(!stepList){ return null; }

		if(!task){ return stepList; }
		const taskList = stepList[task];
		if(!taskList){ return null; }

		return taskList;
	}

	private checkList(list:CharacterProgressBit, jobs:Job[]):boolean{
		const ignorePhyseos = store.getState().userInfo.options.progress.ignorePhyseosPart;
		const keys = Object.keys(list) as (CharacterProgressKey|"_completed")[];
		const isComplete = keys.every((key) => {
			if(key === RelicPart.WEAPON_PHYSEOS && ignorePhyseos){ return true; }
			if(key === "_completed"){
				const completedList = list[key];
				if(!completedList){ return false; }
				return jobs.every((job) => { return completedList.includes(job); });
			}
			return this.checkList(list[key] as CharacterProgressBit, jobs);
		});
		return isComplete;
	}

	isComplete():boolean;
	isComplete(job:Job|Job[]):boolean;
	isComplete(type:RelicType, job?:Job|Job[]):boolean;
	isComplete(type:RelicType, relic:RelicGroup, job?:Job|Job[]):boolean;
	isComplete(type:RelicType, relic:RelicGroup, part:RelicPart, job?:Job|Job[]):boolean;
	isComplete(type:RelicType, relic:RelicGroup, part:RelicPart, step:RelicStep, job?:Job|Job[]):boolean;
	isComplete(type:RelicType, relic:RelicGroup, part:RelicPart, step:RelicStep, task:RelicTask, job?:Job|Job[]):boolean;
	isComplete(typeOrJob?:RelicType|Job|Job[], relicOrJob?:RelicGroup|Job|Job[], partOrJob?:RelicPart|Job|Job[], stepOrJob?: RelicStep|Job|Job[], taskOrJob?: RelicTask|Job|Job[], maybeJob?:Job|Job[]):boolean{ // eslint-disable-line max-params, max-len
		let type:RelicType|undefined; // eslint-disable-line no-undefined
		let relic:RelicGroup|undefined; // eslint-disable-line no-undefined
		let part:RelicPart|undefined; // eslint-disable-line no-undefined
		let step:RelicStep|undefined; // eslint-disable-line no-undefined
		let task:RelicTask|undefined; // eslint-disable-line no-undefined
		let job:Job|null = null;

		if(typeOrJob instanceof Array){ job = typeOrJob[0]; }else if(utils.guards.isEnumValue(Job, typeOrJob)){ job = typeOrJob; }else{ type = typeOrJob; } // eslint-disable-line max-statements-per-line
		if(relicOrJob instanceof Array){ job = relicOrJob[0]; }else if(utils.guards.isEnumValue(Job, relicOrJob)){ job = relicOrJob; }else{ relic = relicOrJob; } // eslint-disable-line max-statements-per-line
		if(partOrJob instanceof Array){ job = partOrJob[0]; }else if(utils.guards.isEnumValue(Job, partOrJob)){ job = partOrJob; }else{ part = partOrJob; } // eslint-disable-line max-statements-per-line
		if(stepOrJob instanceof Array){ job = stepOrJob[0]; }else if(utils.guards.isEnumValue(Job, stepOrJob)){ job = stepOrJob; }else{ step = stepOrJob; } // eslint-disable-line max-statements-per-line
		if(taskOrJob instanceof Array){ job = taskOrJob[0]; }else if(utils.guards.isEnumValue(Job, taskOrJob)){ job = taskOrJob; }else{ task = taskOrJob; } // eslint-disable-line max-statements-per-line
		if(maybeJob instanceof Array){ job = maybeJob[0]; }else if(utils.guards.isEnumValue(Job, maybeJob)){ job = maybeJob; } // eslint-disable-line max-statements-per-line

		const list = this.getListToCheck(type, relic, part, step, task);
		if(!list){ return true; }

		let jobList:Job[] = [];

		if(job){
			jobList = [job];
		}else if(relic){
			jobList = jobLists.relic[relic];
		}else{
			jobList = jobLists.all;
		}

		return this.checkList(list, jobList);
	}

	// This isn't technically a full RelicID - it can exclude parts off the end
	setComplete(relicId:RelicID, jobOrJobs:Job|Job[], value:boolean):void{
		const relicFromId = utils.helpers.getRelicFromId(relicId);
		let valueToSet = value;

		let jobValues = jobOrJobs;
		if(manager.relics.isFirstTimeOnlyStep(relicFromId[RelicIndex.STEP])){
			jobValues = jobLists.relic[relicFromId[RelicIndex.RELIC]];
		}

		this.setThisBitComplete(relicId, valueToSet, jobValues);

		// If we have set a task, the step may not be fully complete
		if(relicFromId[RelicIndex.TASK]){
			valueToSet = this.isComplete(relicFromId[RelicIndex.TYPE], relicFromId[RelicIndex.RELIC], relicFromId[RelicIndex.PART], relicFromId[RelicIndex.STEP], jobValues);
		}

		if(
			!manager.relics.isFirstTimeOnlyStep(relicFromId[RelicIndex.STEP])
			|| (manager.relics.isFirstTimeOnlyStep(relicFromId[RelicIndex.STEP]) && !valueToSet)
		){
			this.checkPrerequisites(relicId, jobValues, valueToSet);
		}
	}

	private setThisBitComplete(relicId:RelicID, value:boolean, job:Job|Job[], list?:CharacterProgressBit){
		const relicFromId = utils.helpers.getRelicFromId(relicId);
		const thisList = list ? list : this.getListToCheck(relicFromId[RelicIndex.TYPE], relicFromId[RelicIndex.RELIC], relicFromId[RelicIndex.PART], relicFromId[RelicIndex.STEP], relicFromId[RelicIndex.TASK]);
		if(!thisList){ return; }

		if(typeof thisList._completed === "undefined"){
			const keys = Object.keys(thisList) as (CharacterProgressKey|"_completed")[];
			keys.forEach((key) => {
				const nextList = thisList[key] as CharacterProgressBit;
				let jobValues = job;
				if(utils.guards.isEnumValue(RelicStep, key) && manager.relics.isFirstTimeOnlyStep(key)){
					jobValues = jobLists.relic[relicFromId[RelicIndex.RELIC]];
				}
				this.setThisBitComplete(relicId, value, jobValues, nextList);
			});
			return;
		}

		if(value){
			if(job instanceof Array){
				thisList._completed = [...new Set(([] as Job[]).concat(thisList._completed, job))]; // Concat _completed and job arrays, ignoring duplicates
			}else if(!thisList._completed.includes(job)){
				thisList._completed.push(job);
			}
			return;
		}

		// Value is false
		if(job instanceof Array){
			thisList._completed = thisList._completed.filter((z) => { return !job.includes(z); });
		}else{
			const index = thisList._completed.indexOf(job);
			if(index > -1){ thisList._completed.splice(index, 1); }
		}
	}

	private getCompletedJobs(relicId:RelicID):Job[]{
		const relicFromId = utils.helpers.getRelicFromId(relicId);
		const list = this.getListToCheck(relicFromId[RelicIndex.TYPE], relicFromId[RelicIndex.RELIC], relicFromId[RelicIndex.PART], relicFromId[RelicIndex.STEP], relicFromId[RelicIndex.TASK]);
		if(!list){ return []; }

		if(list._completed){ return list._completed; }

		const keys = Object.keys(list) as CharacterProgressKey[];
		let jobs:Job[] = [];

		keys.forEach((key) => {
			const thisList = list[key];
			if(thisList && thisList._completed){
				jobs = [...new Set(([] as Job[]).concat(thisList._completed, jobs))]; // Concat _completed and job arrays, ignoring duplicates
			}
		});

		return jobs;
	}


	private checkPrerequisites(relicId:RelicID, job:Job|Job[], value:boolean){
		const prerequisites = manager.relics.findPrerequisites(relicId, value);

		prerequisites.forEach((requirement) => {
			if(relicId.includes(requirement.id)){ return; }

			const relic = utils.helpers.getRelicFromId(requirement.id);

			if(manager.relics.isFirstTimeOnlyStep(relic[RelicIndex.STEP])){
				if(value){
					this.setThisBitComplete(requirement.id, value, jobLists.relic[relic[RelicIndex.RELIC]]);
					return;
				}

				if(!value && requirement.nextId){
					const nextStepCompletedJobs = this.getCompletedJobs(requirement.nextId);
					if(nextStepCompletedJobs.length === 0 || (nextStepCompletedJobs.length === 1 && nextStepCompletedJobs[0] === job)){
						this.setThisBitComplete(requirement.id, value, jobLists.relic[relic[RelicIndex.RELIC]]);
					}
					return;
				}

				if(!value){
					this.setThisBitComplete(requirement.id, value, jobLists.relic[relic[RelicIndex.RELIC]]);
					return;
				}

				return;
			}

			this.setThisBitComplete(requirement.id, value, job);
		});
	}

	getNumberOfJobsComplete(relevantJobs:Job[], type:RelicType, relic:RelicGroup, part:RelicPart, step: RelicStep, task?: RelicTask):number{ // eslint-disable-line max-params
		const list = this.getListToCheck(type, relic, part, step, task);
		if(!list){ return -1; }
		if(!list._completed){ return -1; }

		const relevantCompleted = list._completed.filter((z) => { return relevantJobs.includes(z); });
		return relevantCompleted.length;
	}
}
