//#region Imports
import { RelicGroup, RelicPart, RelicType, View } from "enums";
import { MenuItemWithSubMenu, MenuItemWithView } from "types";
//#endregion Imports

/* eslint-disable object-curly-newline */
const app_menu:(MenuItemWithView | MenuItemWithSubMenu)[] = [
	{ header: "Home", view: View.HOME },
	{ header: "Characters", view: View.CHARACTERS },
	{ header: "Progress", view: View.PROGRESS },
	{ header: RelicType.WEAPON, submenu: [
		{ header: RelicGroup.WEAPON_ZODIAC, submenu: [
			{ header: "Overall", view: View.RELIC_WEAPONS_ZODIAC_OVERALL },
			{ header: RelicPart.WEAPON_BASE_WEAPON, view: View.RELIC_WEAPONS_ZODIAC_BASE_WEAPON },
			{ header: RelicPart.WEAPON_ZENITH, view: View.RELIC_WEAPONS_ZODIAC_ZENITH },
			{ header: RelicPart.WEAPON_ATMA, view: View.RELIC_WEAPONS_ZODIAC_ATMA },
			{ header: RelicPart.WEAPON_ANIMUS, view: View.RELIC_WEAPONS_ZODIAC_ANIMUS },
			{ header: RelicPart.WEAPON_NOVUS, view: View.RELIC_WEAPONS_ZODIAC_NOVUS },
			{ header: RelicPart.WEAPON_NEXUS, view: View.RELIC_WEAPONS_ZODIAC_NEXUS },
			{ header: RelicPart.WEAPON_BRAVES, view: View.RELIC_WEAPONS_ZODIAC_ZODIAC_BRAVES },
			{ header: RelicPart.WEAPON_ZETA, view: View.RELIC_WEAPONS_ZODIAC_ZODIAC_ZETA },
		]},
		{ header: RelicGroup.WEAPON_ANIMA, submenu: [
			{ header: "Overall", view: View.RELIC_WEAPONS_ANIMA_OVERALL },
			{ header: RelicPart.WEAPON_ANIMATED, view: View.RELIC_WEAPONS_ANIMA_ANIMATED },
			{ header: RelicPart.WEAPON_AWOKEN, view: View.RELIC_WEAPONS_ANIMA_AWOKEN },
			{ header: RelicPart.WEAPON_ANIMA, view: View.RELIC_WEAPONS_ANIMA_ANIMA },
			{ header: RelicPart.WEAPON_HYPERCONDUCTIVE, view: View.RELIC_WEAPONS_ANIMA_HYPERCONDUCTIVE },
			{ header: RelicPart.WEAPON_RECONDITIONED, view: View.RELIC_WEAPONS_ANIMA_RECONDITIONED },
			{ header: RelicPart.WEAPON_SHARPENED, view: View.RELIC_WEAPONS_ANIMA_SHARPENED },
			{ header: RelicPart.WEAPON_COMPLETE, view: View.RELIC_WEAPONS_ANIMA_COMPLETE },
			{ header: RelicPart.WEAPON_LUX, view: View.RELIC_WEAPONS_ANIMA_LUX },
		]},
		{ header: RelicGroup.WEAPON_EUREKA, submenu: [
			{ header: "Overall", view: View.RELIC_WEAPONS_EUREKA_OVERALL },
			{ header: RelicPart.WEAPON_ANTIQUATED, view: View.RELIC_WEAPONS_EUREKA_ANTIQUATED },
			{ header: RelicPart.WEAPON_ANEMOS, view: View.RELIC_WEAPONS_EUREKA_ANEMOS },
			{ header: RelicPart.WEAPON_ELEMENTAL, view: View.RELIC_WEAPONS_EUREKA_ELEMENTAL },
			{ header: RelicPart.WEAPON_PYROS, view: View.RELIC_WEAPONS_EUREKA_PYROS },
			{ header: RelicPart.WEAPON_EUREKA, view: View.RELIC_WEAPONS_EUREKA_EUREKA },
			{ header: RelicPart.WEAPON_PHYSEOS, view: View.RELIC_WEAPONS_EUREKA_PHYSEOS },
		]},
		{ header: RelicGroup.WEAPON_RESISTANCE, submenu: [
			{ header: "Overall", view: View.RELIC_WEAPONS_RESISTANCE_OVERALL },
			{ header: RelicPart.WEAPON_RESISTANCE, view: View.RELIC_WEAPONS_RESISTANCE_RESISTANCE },
			{ header: RelicPart.WEAPON_AUGMENTED_RESISTANCE, view: View.RELIC_WEAPONS_RESISTANCE_AUGMENTED_RESISTANCE },
			{ header: RelicPart.WEAPON_RECOLLECTION, view: View.RELIC_WEAPONS_RESISTANCE_RECOLLECTION },
			{ header: RelicPart.WEAPON_LAWS_ORDER, view: View.RELIC_WEAPONS_RESISTANCE_LAWS_ORDER },
			{ header: RelicPart.WEAPON_AUGMENTED_LAWS_ORDER, view: View.RELIC_WEAPONS_RESISTANCE_AUGMENTED_LAWS_ORDER },
			{ header: RelicPart.WEAPON_BLADE, view: View.RELIC_WEAPONS_RESISTANCE_BLADE },
		]},
		{ header: RelicGroup.WEAPON_MANDERVILLE, submenu: [
			{ header: "Overall", view: View.RELIC_WEAPONS_MANDERVILLE_OVERALL },
			{ header: RelicPart.WEAPON_MANDERVILLE, view: View.RELIC_WEAPONS_MANDERVILLE_MANDERVILLE },
			{ header: RelicPart.WEAPON_AMAZING_MANDERVILLE, view: View.RELIC_WEAPONS_MANDERVILLE_AMAZING_MANDERVILLE },
			{ header: RelicPart.WEAPON_MAJESTIC_MANDERVILLE, view: View.RELIC_WEAPONS_MANDERVILLE_MAJESTIC_MANDERVILLE },
			{ header: RelicPart.WEAPON_MANDERVILLOUS, view: View.RELIC_WEAPONS_MANDERVILLE_MANDERVILLOUS },
		]},
	]},
	{ header: RelicType.TOOL, submenu: [
		{ header: RelicGroup.TOOL_LUCIS, submenu: [
			{ header: "Overall", view: View.RELIC_TOOLS_LUCIS_OVERALL },
			{ header: RelicPart.TOOL_MASTERCRAFT, view: View.RELIC_TOOLS_LUCIS_MASTERCRAFT },
			{ header: RelicPart.TOOL_SUPRA, view: View.RELIC_TOOLS_LUCIS_SUPRA },
			{ header: RelicPart.TOOL_LUCIS, view: View.RELIC_TOOLS_LUCIS_LUCIS },
		]},
		{ header: RelicGroup.TOOL_SKYSTEEL, submenu: [
			{ header: "Overall", view: View.RELIC_TOOLS_SKYSTEEL_OVERALL },
			{ header: RelicPart.TOOL_SKYSTEEL, view: View.RELIC_TOOLS_SKYSTEEL_BASE_SKYSTEEL },
			{ header: RelicPart.TOOL_SKYSTEEL_1, view: View.RELIC_TOOLS_SKYSTEEL_SKYSTEEL_1 },
			{ header: RelicPart.TOOL_DRAGONSUNG, view: View.RELIC_TOOLS_SKYSTEEL_DRAGONSUNG },
			{ header: RelicPart.TOOL_AUGMENTED_DRAGONSUNG, view: View.RELIC_TOOLS_SKYSTEEL_AUGMENTED_DRAGONSUNG },
			{ header: RelicPart.TOOL_SKYSUNG, view: View.RELIC_TOOLS_SKYSTEEL_SKYSUNG },
			{ header: RelicPart.TOOL_SKYBUILDERS, view: View.RELIC_TOOLS_SKYSTEEL_SKYBUILDERS },
		]},
		{ header: RelicGroup.TOOL_SPLENDOROUS, submenu: [
			{ header: "Overall", view: View.RELIC_TOOLS_SPLENDOROUS_OVERALL },
			{ header: RelicPart.TOOL_SPLENDOROUS, view: View.RELIC_TOOLS_SPLENDOROUS_SPLENDOROUS },
			{ header: RelicPart.TOOL_AUGMENTED_SPLENDOROUS, view: View.RELIC_TOOLS_SPLENDOROUS_AUGMENTED_SPLENDOROUS },
			{ header: RelicPart.TOOL_CRYSTALLINE, view: View.RELIC_TOOLS_SPLENDOROUS_CRYSTALLINE },
			{ header: RelicPart.TOOL_CHORA_ZOIS_CRYSTALLINE, view: View.RELIC_TOOLS_SPLENDOROUS_CHORA_ZOIS_CRYSTALLINE },
			{ header: RelicPart.TOOL_BRILLIANT, view: View.RELIC_TOOLS_SPLENDOROUS_BRILLIANT },
			{ header: RelicPart.TOOL_VRANDTIC_VISIONARYS, view: View.RELIC_TOOLS_SPLENDOROUS_VRANDTIC_VISIONARYS },
			{ header: RelicPart.TOOL_LODESTAR, view: View.RELIC_TOOLS_SPLENDOROUS_LODESTAR },
		]},
	]},
	/* TODO: Add in relic armour
	{ header: RelicType.ARMOUR, submenu: [
		{ header: RelicGroup.ARMOUR_ANEMOS, submenu: [
			{ header: "Overall", view: View.RELIC_ARMOUR_ANEMOS_OVERALL },
			{ header: RelicPart.ARMOUR_ANTIQUATED, view: View.RELIC_ARMOUR_ANEMOS_ANTIQUATED_ARMOUR },
			{ header: RelicPart.ARMOUR_BASE_0, view: View.RELIC_ARMOUR_ANEMOS_BASE_0 },
			{ header: RelicPart.ARMOUR_BASE_1, view: View.RELIC_ARMOUR_ANEMOS_BASE_1 },
			{ header: RelicPart.ARMOUR_BASE_2, view: View.RELIC_ARMOUR_ANEMOS_BASE_2 },
			{ header: RelicPart.ARMOUR_ANEMOS, view: View.RELIC_ARMOUR_ANEMOS_ANEMOS_ARMOUR },
		]},
		{ header: RelicGroup.ARMOUR_ELEMENTAL, submenu: [
			{ header: "Overall", view: View.RELIC_ARMOUR_ELEMENTAL_OVERALL },
			{ header: RelicPart.ARMOUR_ELEMENTAL, view: View.RELIC_ARMOUR_ELEMENTAL_ELEMENTAL_ARMOUR },
			{ header: RelicPart.ARMOUR_ELEMENTAL_1, view: View.RELIC_ARMOUR_ELEMENTAL_ELEMENTAL_1 },
			{ header: RelicPart.ARMOUR_ELEMENTAL_2, view: View.RELIC_ARMOUR_ELEMENTAL_ELEMENTAL_2 },
		]},
		{ header: RelicGroup.ARMOUR_BOZJAN, submenu: [
			{ header: "Overall", view: View.RELIC_ARMOUR_BOZJAN_OVERALL },
			{ header: RelicPart.ARMOUR_BOZJAN, view: View.RELIC_ARMOUR_BOZJAN_BOZJAN },
			{ header: RelicPart.ARMOUR_AUGMENTED_BOZJAN, view: View.RELIC_ARMOUR_BOZJAN_AUGMENTED_BOZJAN },
		]},
		{ header: RelicGroup.ARMOUR_LAWS_ORDER, submenu: [
			{ header: "Overall", view: View.RELIC_ARMOUR_LAWS_ORDER_OVERALL },
			{ header: RelicPart.ARMOUR_LAWS_ORDER, view: View.RELIC_ARMOUR_LAWS_ORDER_LAWS_ORDER },
			{ header: RelicPart.ARMOUR_AUGMENTED_LAWS_ORDER, view: View.RELIC_ARMOUR_LAWS_ORDER_AUGMENTED_LAWS_ORDER },
		]},
		{ header: RelicGroup.ARMOUR_BLADES, submenu: [
			{ header: "Overall", view: View.RELIC_ARMOUR_BLADES_OVERALL },
			{ header: RelicPart.ARMOUR_BLADES, view: View.RELIC_ARMOUR_BLADES_BLADES },
		]},
	]},
	*/
	{ header: "Items", view: View.ITEMS },
	// { header: "Tips", view: View.TIPS },// TODO: Add in tips
	{ header: "Gallery", view: View.GALLERY },
];
/* eslint-enable object-curly-newline */
export default app_menu;
