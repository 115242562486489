//#region Imports
import AppConstants from "AppConstants";
import { Component } from "react";
import { connect } from "react-redux";
import { AppState, MenuItemOptions, MenuItemWithSubMenu, MenuItemWithView, ViewMenuProps } from "types";
import utils from "utils";
import "./Menu.css";
import MenuFooter from "./MenuFooter";
import MenuHeader from "./MenuHeader";
import MenuItem from "./MenuItem";
import app_menu from "./items";
//#endregion Imports

const mapStateToProps = (state:AppState) => {
	return {
		lang: state.userInfo.options.lang,
		gameLang: state.userInfo.options.gameLang,
	};
};

class Menu extends Component<ViewMenuProps> {
	private nextMenuID = 1;
	constructor(props:ViewMenuProps){
		super(props);
		this.attachIDsToMenu(app_menu); // eslint-disable-line @typescript-eslint/no-use-before-define
	}

	render(){
		return (
			<div id="menu" className="hide">
				<MenuHeader />
				<div className="menu-wrapper">
					{app_menu.map((menu:MenuItemWithView|MenuItemWithSubMenu) => { // eslint-disable-line @typescript-eslint/no-use-before-define
						const options:MenuItemOptions = {
							background: "primary",
							menuItemView: this.hasSubMenu(menu) ? false as false : menu.view,
							submenu: this.hasSubMenu(menu) ? menu.submenu : false as false,
							headers: [menu.header],
						};

						return (
							<MenuItem key={`menu_${menu.header}_${menu.id}`} options={options} />
						);
					})}
				</div>
				<MenuFooter />
			</div>
		);
	}

	componentDidMount(){
		const vw = utils.helpers.getWindowWidth();
		if(vw > AppConstants.TabletWidth){
			const menu = document.getElementById("menu");
			if(menu){ menu.classList.remove("hide"); }
		}

		const menuItem = document.querySelector<HTMLDivElement>(".menu-item.selected");
		if(menuItem){
			let parentEle = menuItem.closest(".sub-menu");
			let nextParentSelector = ".menu-item";
			while(parentEle){
				parentEle.classList.add("open");
				parentEle = parentEle.closest(nextParentSelector);

				if(nextParentSelector === ".menu-item"){
					nextParentSelector = ".sub-menu";
				}else{
					nextParentSelector = ".menu-item";
				}
			}
		}
	}

	private hasSubMenu(menuItem:MenuItemWithView|MenuItemWithSubMenu): menuItem is MenuItemWithSubMenu{
		if(typeof (menuItem as MenuItemWithSubMenu).submenu === "undefined"){
			return false;
		}
		return true;
	}

	attachIDsToMenu(menu:(MenuItemWithView | MenuItemWithSubMenu)[]){
		menu.forEach((item) => {
			item.id = this.nextMenuID++;
			if(this.hasSubMenu(item)){ this.attachIDsToMenu(item.submenu); }
		});
	}
}
export default connect(mapStateToProps)(Menu);
