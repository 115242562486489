//#region Imports
import { EorzeaDataType, Shop } from "enums";
import { ShopInfo } from "types";
//#endregion Imports

// TODO: Add found to these
export const shops:{ [key in Shop]: ShopInfo } = {
	[Shop.UNKNOWN]: { name: Shop.UNKNOWN, type: EorzeaDataType.SHOP, lodestoneID: "" },
	[Shop.DENYS]: { name: Shop.DENYS, type: EorzeaDataType.SHOP, lodestoneID: "caefe09de69" },
	[Shop.AURIANA]: { name: Shop.AURIANA, type: EorzeaDataType.SHOP, lodestoneID: "27773b8b4a7" },
	[Shop.HISMENA]: { name: Shop.HISMENA, type: EorzeaDataType.SHOP, lodestoneID: "02b3c29bf1c" },
	[Shop.ROWENA_REP_LIMSA]: { name: Shop.ROWENA_REP_LIMSA, type: EorzeaDataType.SHOP, lodestoneID: "14f6bc64c9c" },
	[Shop.ROWENA_REP_UlDAH]: { name: Shop.ROWENA_REP_UlDAH, type: EorzeaDataType.SHOP, lodestoneID: "69c3f2a1f57" },
	[Shop.ROWENA_REP_GRIDANIA]: { name: Shop.ROWENA_REP_GRIDANIA, type: EorzeaDataType.SHOP, lodestoneID: "c1a51d285c6" },
	[Shop.ROWENA_REP_FOUNDATION]: { name: Shop.ROWENA_REP_FOUNDATION, type: EorzeaDataType.SHOP, lodestoneID: "7c42398e727" },
	[Shop.SERPENT_QUARTERMASTER]: { name: Shop.SERPENT_QUARTERMASTER, type: EorzeaDataType.SHOP, lodestoneID: "6301481339f" },
	[Shop.STORM_QUARTERMASTER]: { name: Shop.STORM_QUARTERMASTER, type: EorzeaDataType.SHOP, lodestoneID: "5bb28b3316b" },
	[Shop.FLAME_QUARTERMASTER]: { name: Shop.FLAME_QUARTERMASTER, type: EorzeaDataType.SHOP, lodestoneID: "277def3c97a" },
	[Shop.JUNKMONGER]: { name: Shop.JUNKMONGER, type: EorzeaDataType.SHOP, lodestoneID: "b588203984d" },
	[Shop.MERCHANT_MENDER_SOUTH_THANALAN]: { name: Shop.MERCHANT_MENDER_SOUTH_THANALAN, type: EorzeaDataType.SHOP, lodestoneID: "59ffef0f53b" },
	[Shop.MERCHANT_MENDER_COERTHAS_CENTRAL_HIGHLANDS]: { name: Shop.MERCHANT_MENDER_COERTHAS_CENTRAL_HIGHLANDS, type: EorzeaDataType.SHOP, lodestoneID: "d95faa50ec5" },
	[Shop.TOOL_SUPPLIER_MENDER]: { name: Shop.TOOL_SUPPLIER_MENDER, type: EorzeaDataType.SHOP, lodestoneID: "8bb0e8cac46" },
	[Shop.CRISTIANA]: { name: Shop.CRISTIANA, type: EorzeaDataType.SHOP, lodestoneID: "6e5a734f413" },
	[Shop.HUNT_BILLMASTER_ULDAH]: { name: Shop.HUNT_BILLMASTER_ULDAH, type: EorzeaDataType.SHOP, lodestoneID: "6d49026aada" },
	[Shop.HUNT_BILLMASTER_GRIDANIA]: { name: Shop.HUNT_BILLMASTER_GRIDANIA, type: EorzeaDataType.SHOP, lodestoneID: "3a4229b9b91" },
	[Shop.HUNT_BILLMASTER_LIMSA]: { name: Shop.HUNT_BILLMASTER_LIMSA, type: EorzeaDataType.SHOP, lodestoneID: "ed586fa817a" },
	[Shop.LUNA_VANU]: { name: Shop.LUNA_VANU, type: EorzeaDataType.SHOP, lodestoneID: "db55434d5e1" },
	[Shop.AMALJAA_VENDOR]: { name: Shop.AMALJAA_VENDOR, type: EorzeaDataType.SHOP, lodestoneID: "9f78134b4ab" },
	[Shop.VATH_STICKPEDDLER]: { name: Shop.VATH_STICKPEDDLER, type: EorzeaDataType.SHOP, lodestoneID: "e8d45deba58" },
	[Shop.SABINA]: { name: Shop.SABINA, type: EorzeaDataType.SHOP, lodestoneID: "9e725a25004" },
	[Shop.MOGMUL_MOGBELLY]: { name: Shop.MOGMUL_MOGBELLY, type: EorzeaDataType.SHOP, lodestoneID: "d1029996df5" },
	[Shop.SAHAGIN_VENDOR]: { name: Shop.SAHAGIN_VENDOR, type: EorzeaDataType.SHOP, lodestoneID: "79ffac0a51a" },
	[Shop.KOBOLD_VENDOR]: { name: Shop.KOBOLD_VENDOR, type: EorzeaDataType.SHOP, lodestoneID: "9b8055ffab0" },
	[Shop.SYLPHIC_VENDOR]: { name: Shop.SYLPHIC_VENDOR, type: EorzeaDataType.SHOP, lodestoneID: "bc33445b21a" },
	[Shop.SCRIP_EXCHANGE_ULDAH]: { name: Shop.SCRIP_EXCHANGE_ULDAH, type: EorzeaDataType.SHOP, lodestoneID: "64bfdf2f346" },
	[Shop.SCRIP_EXCHANGE_GRIDANIA]: { name: Shop.SCRIP_EXCHANGE_GRIDANIA, type: EorzeaDataType.SHOP, lodestoneID: "4178048d21f" },
	[Shop.SCRIP_EXCHANGE_LIMSA]: { name: Shop.SCRIP_EXCHANGE_LIMSA, type: EorzeaDataType.SHOP, lodestoneID: "4b4aa4d9dce" },
	[Shop.SCRIP_EXCHANGE_IDYLLSHIRE]: { name: Shop.SCRIP_EXCHANGE_IDYLLSHIRE, type: EorzeaDataType.SHOP, lodestoneID: "e0307922024" },
	[Shop.SCRIP_EXCHANGE_MOR_DHONA]: { name: Shop.SCRIP_EXCHANGE_MOR_DHONA, type: EorzeaDataType.SHOP, lodestoneID: "60deb62b043" },
	[Shop.SCRIP_EXCHANGE_RHALGRS_REACH]: { name: Shop.SCRIP_EXCHANGE_RHALGRS_REACH, type: EorzeaDataType.SHOP, lodestoneID: "a4f2bc54f52" },
	[Shop.SCRIP_EXCHANGE_EULMORE]: { name: Shop.SCRIP_EXCHANGE_EULMORE, type: EorzeaDataType.SHOP, lodestoneID: "c12b55015cb" },
	[Shop.SCRIP_EXCHANGE_CRYSTARIUM]: { name: Shop.SCRIP_EXCHANGE_CRYSTARIUM, type: EorzeaDataType.SHOP, lodestoneID: "d91472e245e" },
	[Shop.SCRIP_EXCHANGE_SHARLAYAN]: { name: Shop.SCRIP_EXCHANGE_SHARLAYAN, type: EorzeaDataType.SHOP, lodestoneID: "" },
	[Shop.SCRIP_EXCHANGE_RADZ_AT_HAN]: { name: Shop.SCRIP_EXCHANGE_RADZ_AT_HAN, type: EorzeaDataType.SHOP, lodestoneID: "" },
	[Shop.SANANA]: { name: Shop.SANANA, type: EorzeaDataType.SHOP, lodestoneID: "8c8f6f44243" },
	[Shop.ANNA]: { name: Shop.ANNA, type: EorzeaDataType.SHOP, lodestoneID: "243c663c808" },
	[Shop.DIANA]: { name: Shop.DIANA, type: EorzeaDataType.SHOP, lodestoneID: "6b18082e8b9" },
	[Shop.ENIE]: { name: Shop.ENIE, type: EorzeaDataType.SHOP, lodestoneID: "1bb8b3193e9" },
	[Shop.PEDRONILLE]: { name: Shop.PEDRONILLE, type: EorzeaDataType.SHOP, lodestoneID: "72d5a0053a9" },
	[Shop.NACILLE]: { name: Shop.NACILLE, type: EorzeaDataType.SHOP, lodestoneID: "dc3c9ff7ca9" },
	[Shop.GRAMSOL]: { name: Shop.GRAMSOL, type: EorzeaDataType.SHOP, lodestoneID: "16b7a49108e" },
	[Shop.EXPEDITION_BIRDWATCHER_PAGOS]: { name: Shop.EXPEDITION_BIRDWATCHER_PAGOS, type: EorzeaDataType.SHOP, lodestoneID: "8826233d300" },
	[Shop.EXPEDITION_BIRDWATCHER_ANEMOS]: { name: Shop.EXPEDITION_BIRDWATCHER_ANEMOS, type: EorzeaDataType.SHOP, lodestoneID: "71cea6a2ae1" },
	[Shop.EXPEDITION_BIRDWATCHER_PYROS]: { name: Shop.EXPEDITION_BIRDWATCHER_PYROS, type: EorzeaDataType.SHOP, lodestoneID: "cb8fa42daec" },
	[Shop.JUBRUNNAH]: { name: Shop.JUBRUNNAH, type: EorzeaDataType.SHOP, lodestoneID: "c2faa44bb5e" },
	[Shop.MOWEN]: { name: Shop.MOWEN, type: EorzeaDataType.SHOP, lodestoneID: "" },
	[Shop.ZRANMAIA]: { name: Shop.ZRANMAIA, type: EorzeaDataType.SHOP, lodestoneID: "" },
	[Shop.ALLAGAN_RESUPPLY_NODE]: { name: Shop.ALLAGAN_RESUPPLY_NODE, type: EorzeaDataType.SHOP, lodestoneID: "" },
	// [Shop.ANANTA_MATERIAL_SUPPLIER]: { name: Shop.ANANTA_MATERIAL_SUPPLIER, type: EorzeaDataType.SHOP, lodestoneID: "" },
	[Shop.JOSSY]: { name: Shop.JOSSY, type: EorzeaDataType.SHOP, lodestoneID: "" },
	// [Shop.KOJIN_MATERIAL_SUPPLIER]: { name: Shop.KOJIN_MATERIAL_SUPPLIER, type: EorzeaDataType.SHOP, lodestoneID: "" },
	[Shop.MATERIAL_SUPPLIER]: { name: Shop.MATERIAL_SUPPLIER, type: EorzeaDataType.SHOP, lodestoneID: "" },
	// [Shop.NAMAZU_MATERIAL_SUPPLIER]: { name: Shop.NAMAZU_MATERIAL_SUPPLIER, type: EorzeaDataType.SHOP, lodestoneID: "" },
	[Shop.ESMENET]: { name: Shop.ESMENET, type: EorzeaDataType.SHOP, lodestoneID: "" },
	[Shop.TANIE]: { name: Shop.TANIE, type: EorzeaDataType.SHOP, lodestoneID: "" },
	[Shop.SCRAP_SALVAGER]: { name: Shop.SCRAP_SALVAGER, type: EorzeaDataType.SHOP, lodestoneID: "" },
	[Shop.VRAL]: { name: Shop.VRAL, type: EorzeaDataType.SHOP, lodestoneID: "" },
	[Shop.HALDEN]: { name: Shop.HALDEN, type: EorzeaDataType.SHOP, lodestoneID: "" },
	[Shop.GOUSHS_OOAN]: { name: Shop.GOUSHS_OOAN, type: EorzeaDataType.SHOP, lodestoneID: "" },
	[Shop.ENGERRAND]: { name: Shop.ENGERRAND, type: EorzeaDataType.SHOP, lodestoneID: "" },
	[Shop.FRIDURIH]: { name: Shop.FRIDURIH, type: EorzeaDataType.SHOP, lodestoneID: "" },
	[Shop.ORHOYOD]: { name: Shop.ORHOYOD, type: EorzeaDataType.SHOP, lodestoneID: "" },
	[Shop.PASDEVILLET]: { name: Shop.PASDEVILLET, type: EorzeaDataType.SHOP, lodestoneID: "" },
	[Shop.SHIRAKASHI]: { name: Shop.SHIRAKASHI, type: EorzeaDataType.SHOP, lodestoneID: "" },
	[Shop.TRAVELING_MATERIAL_SUPPLIER]: { name: Shop.TRAVELING_MATERIAL_SUPPLIER, type: EorzeaDataType.SHOP, lodestoneID: "" },
	[Shop.MERCHANT_MENDER_SILVER_BAZAAR]: { name: Shop.MERCHANT_MENDER_SILVER_BAZAAR, type: EorzeaDataType.SHOP, lodestoneID: "" },
	[Shop.LITTLEJOHN]: { name: Shop.LITTLEJOHN, type: EorzeaDataType.SHOP, lodestoneID: "" },
	[Shop.ALEHOUSE_WENCH_BLACK_BRUSH]: { name: Shop.ALEHOUSE_WENCH_BLACK_BRUSH, type: EorzeaDataType.SHOP, lodestoneID: "" },
	[Shop.SOEMRWYB]: { name: Shop.SOEMRWYB, type: EorzeaDataType.SHOP, lodestoneID: "" },
	[Shop.SCRIP_EXCHANGE_OCEAN_FISHING]: { name: Shop.SCRIP_EXCHANGE_OCEAN_FISHING, type: EorzeaDataType.SHOP, lodestoneID: "" },
	[Shop.CALAMITY_SALVAGER_LIMSA]: { name: Shop.CALAMITY_SALVAGER_LIMSA, type: EorzeaDataType.SHOP, lodestoneID: "" },
	[Shop.CALAMITY_SALVAGER_GRIDANIA]: { name: Shop.CALAMITY_SALVAGER_GRIDANIA, type: EorzeaDataType.SHOP, lodestoneID: "" },
	[Shop.CALAMITY_SALVAGER_ULDAH]: { name: Shop.CALAMITY_SALVAGER_ULDAH, type: EorzeaDataType.SHOP, lodestoneID: "" },
	[Shop.QUINNANA]: { name: Shop.QUINNANA, type: EorzeaDataType.SHOP, lodestoneID: "" },
	[Shop.GADFRID]: { name: Shop.GADFRID, type: EorzeaDataType.SHOP, lodestoneID: "" },
	[Shop.SAJAREEN]: { name: Shop.SAJAREEN, type: EorzeaDataType.SHOP, lodestoneID: "" },
	[Shop.ZAWAWA]: { name: Shop.ZAWAWA, type: EorzeaDataType.SHOP, lodestoneID: "" },
	[Shop.AISARA]: { name: Shop.AISARA, type: EorzeaDataType.SHOP, lodestoneID: "" },
	[Shop.TRADINGWAY]: { name: Shop.TRADINGWAY, type: EorzeaDataType.SHOP, lodestoneID: "" },
	[Shop.TALAN]: { name: Shop.TALAN, type: EorzeaDataType.SHOP, lodestoneID: "" },
	[Shop.AISTAN]: { name: Shop.AISTAN, type: EorzeaDataType.SHOP, lodestoneID: "" },
	[Shop.SMYDHAEMR]: { name: Shop.SMYDHAEMR, type: EorzeaDataType.SHOP, lodestoneID: "" },
	[Shop.IXALI_VENDOR]: { name: Shop.IXALI_VENDOR, type: EorzeaDataType.SHOP, lodestoneID: "" },
	[Shop.GIGIMA]: { name: Shop.GIGIMA, type: EorzeaDataType.SHOP, lodestoneID: "" },
	[Shop.MAHVEYDAH]: { name: Shop.MAHVEYDAH, type: EorzeaDataType.SHOP, lodestoneID: "" },
	[Shop.SIULMET]: { name: Shop.SIULMET, type: EorzeaDataType.SHOP, lodestoneID: "" },
	[Shop.FAEZBROES]: { name: Shop.FAEZBROES, type: EorzeaDataType.SHOP, lodestoneID: "" },
};
