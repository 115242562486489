//#region Imports
import BasicIcon from "components/basic-icon/BasicIcon";
import FormComponents from "components/forms/FormComponents";
import { fishing } from "data-files/fishing-holes";
import { locations } from "data-files/locations";
import { Icon, Modal } from "enums";
import manager from "managers/app";
import ModalContent from "modals/content/ModalContent";
import ModalFooter from "modals/footer/ModalFooter";
import ModalHeader from "modals/header/ModalHeader";
import { Component } from "react";
import { connect } from "react-redux";
import t from "translations/translator";
import { AppState, FishingModalProps } from "types";
import "./FishingModal.css";
//#endregion Imports

const mapStateToProps = (state:AppState) => {
	return {
		lang: state.userInfo.options.lang,
		gameLang: state.userInfo.options.gameLang,
		fishing: state.modal[Modal.FISHING],
	};
};

class FishingModal extends Component<FishingModalProps> {
	render(){
		return (
			<div id={Modal.FISHING} className="modal-wrapper">
				<ModalHeader text="Fishing" icon={Icon.FISHING} />
				<ModalContent>
					<div className="fishing-wrapper">
						{this.props.fishing.map((fishingDetails) => {
							const fishingHole = fishing[fishingDetails.hole];
							const fishingLocation = locations[fishingHole.found.location];

							return <div key={fishingHole.name} className="fishing-card">
								<h2 className="fishing-name">{t.t(fishingHole.name)}</h2>
								<h3>{t.t("Bait")}</h3>
								{fishingDetails.bait
									? <ul>
										{fishingDetails.bait.map((bait) => {
											return <li key={bait} className="fishing-bait"><BasicIcon icon={Icon.BAIT} />{t.t(bait)}</li>;
										})}
									</ul>
									: null
								}
								<div className="fishing-location-wrapper">
									<div className="fishing-location">
										<span><BasicIcon icon={Icon.MAP} tooltip="Map" /> {t.t(fishingLocation.map)}</span>
										<span><BasicIcon icon={Icon.REGION} tooltip="Region" /> {fishingLocation.region ? t.t(fishingLocation.region) : t.t("Unknown")}</span>
										<span><BasicIcon icon={Icon.TELEPORT} tooltip="Teleport" /> {fishingLocation.teleport ? t.t(fishingLocation.teleport) : t.t("Unknown")}</span>
										<span><BasicIcon icon={Icon.LOCATION} tooltip="Co-ordinates" /> X: {fishingHole.found.x}, Y: {fishingHole.found.y}</span>
									</div>
								</div>
							</div>;
						})}
					</div>
				</ModalContent>
				<ModalFooter>
					<FormComponents.Button text="Close" onClick={manager.modal.close} translate={true} />
				</ModalFooter>
			</div>
		);
	}
}
export default connect(mapStateToProps)(FishingModal);
