//#region Imports
import App from "App";
import manager from "managers/app";
import { store } from "managers/state";
import React from "react"; // eslint-disable-line @typescript-eslint/no-shadow
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
//#endregion Imports

window.addEventListener("popstate", () => {
	manager.view.setViewFromUrl();
});

manager.view.setViewFromUrl(true);

ReactDOM.render(
	<React.StrictMode>
		<Provider store={store}>
			<App />
		</Provider>
	</React.StrictMode>,
	document.getElementById("root"),
);
