//#region Imports
import BasicIcon from "components/basic-icon/BasicIcon";
import FormComponents from "components/forms/FormComponents";
import { fates } from "data-files/duties";
import { locations } from "data-files/locations";
import { EorzeaDataType, Fate, Icon, MapLocation, Modal } from "enums";
import manager from "managers/app";
import ModalContent from "modals/content/ModalContent";
import ModalFooter from "modals/footer/ModalFooter";
import ModalHeader from "modals/header/ModalHeader";
import { Component } from "react";
import { connect } from "react-redux";
import t from "translations/translator";
import { AppState, FateInfo, FatesModalProps, ObtainedLocation, OutputString } from "types";
import utils from "utils";
import "./FatesModal.css";
//#endregion Imports

const mapStateToProps = (state:AppState) => {
	return {
		lang: state.userInfo.options.lang,
		gameLang: state.userInfo.options.gameLang,
		fates: state.modal[Modal.FATES],
	};
};

class FatesModal extends Component<FatesModalProps> {
	render(){
		return (
			<div id={Modal.FATES} className="modal-wrapper">
				<ModalHeader text="FATEs" icon={Icon.FATE} />
				<ModalContent>
					<div className="fate-wrapper">
						{this.props.fates.map((obtainedFate, index) => {
							let isFate = false;
							let fate:FateInfo = { name: Fate.UNKNOWN, type: EorzeaDataType.FATE, found: { location: MapLocation.UNKNOWN, x: -1, y: -1 } };
							let obtainedLocation:ObtainedLocation = { location: MapLocation.UNKNOWN, x: -1, y: -1 };

							if(utils.guards.isEnumValue(Fate, obtainedFate)){
								fate = fates[obtainedFate];
								isFate = true;
							}else if(utils.guards.isEnumValue(MapLocation, obtainedFate)){
								obtainedLocation = { location: obtainedFate, x: -1, y: -1 };
								isFate = false;
							}

							let key:string = `unknown-${index}`;
							if(isFate){ key = fate.name; }else{ key = obtainedLocation.location; }

							const fateLocation:ObtainedLocation = isFate ? fate.found : obtainedLocation;
							const locationInfo = locations[fateLocation.location];

							const fateName = isFate ? fate.name : this.getNameFromLocation(fateLocation);

							return <div key={key} className="fate-card">
								<h3 className="fate-name">{t.t(fateName)}</h3>
								{isFate && fate.previousFATE ? <p>{t.t("Chained from %FATE%", { FATE: fate.previousFATE })}</p> : null}
								{isFate && fate.npc ? <p>{t.t("Speak to %NPC% to start this FATE", { NPC: fate.npc})}</p> : null}
								<div className="fate-location-wrapper">
									<div className="fate-location">
										<span><BasicIcon icon={Icon.MAP} tooltip="Map" /> {t.t(locationInfo.map)}</span>
										{locationInfo.region ? <span><BasicIcon icon={Icon.REGION} tooltip="Region" /> {t.t(locationInfo.region)}</span> : null}
										{locationInfo.teleport ? <span><BasicIcon icon={Icon.TELEPORT} tooltip="Teleport" /> {t.t(locationInfo.teleport)}</span> : null}
										{fateLocation.x > -1 ? <span><BasicIcon icon={Icon.LOCATION} tooltip="Co-ordinates" /> X: {fateLocation.x}, Y: {fateLocation.y}</span> : null}
									</div>
								</div>
							</div>;
						})}
					</div>
				</ModalContent>
				<ModalFooter>
					<FormComponents.Button text="Close" onClick={manager.modal.close} translate={true} />
				</ModalFooter>
			</div>
		);
	}

	getNameFromLocation(obtainedLocation:ObtainedLocation):OutputString{
		if(
			obtainedLocation.location === MapLocation.BOZJA
			|| obtainedLocation.location === MapLocation.BOZJA_SOUTH
			|| obtainedLocation.location === MapLocation.BOZJA_MIDDLE
			|| obtainedLocation.location === MapLocation.BOZJA_NORTH
			|| obtainedLocation.location === MapLocation.ZADNOR
			|| obtainedLocation.location === MapLocation.ZADNOR_SOUTH
			|| obtainedLocation.location === MapLocation.ZADNOR_MIDDLE
			|| obtainedLocation.location === MapLocation.ZADNOR_NORTH
		){
			return "Any Skirmish";
		}
		return "Any FATEs";
	}
}
export default connect(mapStateToProps)(FatesModal);
