//#region Imports
import { Component, createRef } from "react";
import { FormProps } from "types";
import "./FormComponents.css";
import { FormButton } from "./part/FormButton";
import { FormCheckbox } from "./part/FormCheckbox";
import { FormFreeText } from "./part/FormFreeText";
import { FormLabel } from "./part/FormLabel";
import { FormSelect } from "./part/FormSelect";
import { FormText } from "./part/FormText";
import { FormTextArea } from "./part/FormTextArea";
//#endregion Imports

class AppForm extends Component<FormProps> {
	private formRef = createRef<HTMLFormElement>();

	render(){
		const formClasses:string[] = ["rt-form", this.props.fieldDirection];

		if(this.props.justifyFields){
			formClasses.push(this.props.justifyFields);
		}

		return (
			<form className={formClasses.join(" ")} onSubmit={(e) => { e.preventDefault(); }} ref={this.formRef}>
				{this.props.children}
			</form>
		);
	}

	componentDidMount = () => {
		if(this.props.fieldDirection === "column"){
			this.setLabelWidth();
		}

		if(!this.formRef.current){ return; }

		const editButtons = this.formRef.current.querySelectorAll<HTMLDivElement>(".field-edit");
		if(!editButtons){ return; }

		editButtons.forEach((btn:HTMLDivElement) => {

			// Remove the listeners first to prevent them from being added multiple times
			btn.removeEventListener("click", this.editButtonClick);
			btn.addEventListener("click", this.editButtonClick);
		});
	}


	setLabelWidth = () => {
		const wrapper = this.formRef.current as HTMLFormElement;
		if(!wrapper){ return; }
		const labels = wrapper.getElementsByClassName("field-label");
		let maxWidth = 0;
		const labelsArr = Array.from(labels) as HTMLLabelElement[];
		labelsArr.forEach((label) => { maxWidth = label.offsetWidth > maxWidth ? label.offsetWidth : maxWidth; });
		labelsArr.forEach((label) => { label.style.width = `${maxWidth}px`; });
	}

	editButtonClick = (evt:MouseEvent) => {
		const ele = evt.currentTarget as HTMLDivElement;
		const input = ele.previousSibling as HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement;
		if(!input){ return; }

		input.disabled = false;
	}
}

const FormComponents = {
	Form: AppForm,
	Button: FormButton,
	Label: FormLabel,
	Select: FormSelect,
	TextArea: FormTextArea,
	TextInput: FormText,
	Checkbox: FormCheckbox,
	FreeText: FormFreeText,
};
Object.freeze(FormComponents);
export default FormComponents;
