import { MapLocation, NPC } from "enums";
import { NPCInfo } from "types";

export const npcs:{ [key in NPC]: NPCInfo } = {
	[NPC.UNKNOWN]: { name: NPC.UNKNOWN, found: { location: MapLocation.UNKNOWN, x: 0, y: 0 } },
	[NPC.TMOKKRI]: { name: NPC.TMOKKRI, found: { location: MapLocation.DROWNING_WENCH, x: 11, y: 11 } },
	[NPC.KLEYTAI]: { name: NPC.KLEYTAI, found: { location: MapLocation.SAINT_COINACHS_FIND, x: 30, y: 13 } },
	[NPC.EUSTACE]: { name: NPC.EUSTACE, found: { location: MapLocation.ULDAH_STEPS_OF_NALD, x: 12, y: 9 } },
	[NPC.GONTRANT]: { name: NPC.GONTRANT, found: { location: MapLocation.NEW_GRIDANIA, x: 12, y: 13 } },
	[NPC.EIDHART]: { name: NPC.EIDHART, found: { location: MapLocation.SAINT_COINACHS_FIND, x: 30, y: 12 } },
	[NPC.RURUBANA]: { name: NPC.RURUBANA, found: { location: MapLocation.BLUEFOG, x: 22, y: 29 } },
	[NPC.VOILINAUT]: { name: NPC.VOILINAUT, found: { location: MapLocation.WHITEBRIM, x: 13, y: 17 } },
	[NPC.LODILLE]: { name: NPC.LODILLE, found: { location: MapLocation.WHITEBRIM, x: 12, y: 17 } },
	[NPC.NEDRICK]: { name: NPC.NEDRICK, found: { location: MapLocation.VESPER_BAY, x: 12, y: 14 } },
	[NPC.GEROLT_NORTH_SHROUD]: { name: NPC.GEROLT_NORTH_SHROUD, found: { location: MapLocation.HYRSTMILL, x: 30, y: 20 } },
	[NPC.GEROLT_ANEMOS]: { name: NPC.GEROLT_ANEMOS, found: { location: MapLocation.EUREKA_ANEMOS, x: 18, y: 32 } },
	[NPC.GEROLT_PAGOS]: { name: NPC.GEROLT_PAGOS, found: { location: MapLocation.EUREKA_PAGOS, x: 4, y: 25 } },
	[NPC.GEROLT_PYROS]: { name: NPC.GEROLT_PYROS, found: { location: MapLocation.EUREKA_PYROS, x: 15, y: 24 } },
	[NPC.GEROLT_HYDATOS]: { name: NPC.GEROLT_HYDATOS, found: { location: MapLocation.EUREKA_HYDATOS, x: 22, y: 14 } },
	[NPC.GEROLT_GANGOS]: { name: NPC.GEROLT_GANGOS, found: { location: MapLocation.GANGOS, x: 6, y: 5 } },
	[NPC.GEROLT_RADZ_AT_HAN]: { name: NPC.GEROLT_RADZ_AT_HAN, found: { location: MapLocation.ARTHA, x: 12, y: 7 } },
	[NPC.JALZAHN]: { name: NPC.JALZAHN, found: { location: MapLocation.HYRSTMILL, x: 30, y: 20 } },
	[NPC.GJUSANA]: { name: NPC.GJUSANA, found: { location: MapLocation.MOR_DHONA, x: 23, y: 7 } },
	[NPC.HUBAIRTIN]: { name: NPC.HUBAIRTIN, found: { location: MapLocation.BONFIRE, x: 24, y: 14 } },
	[NPC.PAPANA]: { name: NPC.PAPANA, found: { location: MapLocation.MOR_DHONA, x: 23, y: 7 } },
	[NPC.GUIDING_STAR]: { name: NPC.GUIDING_STAR, found: { location: MapLocation.MOR_DHONA, x: 21, y: 6 } },
	[NPC.BRANGWINE]: { name: NPC.BRANGWINE, found: { location: MapLocation.MOR_DHONA, x: 22, y: 7 } },
	[NPC.ADKIN]: { name: NPC.ADKIN, found: { location: MapLocation.BONFIRE, x: 24, y: 14 } },
	[NPC.REMON]: { name: NPC.REMON, found: { location: MapLocation.SWIFTPERCH, x: 34, y: 32 } },
	[NPC.ROWENA_IDYLLSHIRE]: { name: NPC.ROWENA_IDYLLSHIRE, found: { location: MapLocation.IDYLLSHIRE, x: 6, y: 6 } },
	[NPC.ARDASHIR]: { name: NPC.ARDASHIR, found: { location: MapLocation.HELIX, x: 7, y: 11 } },
	[NPC.PROCESSING_NODE]: { name: NPC.PROCESSING_NODE, found: { location: MapLocation.HELIX, x: 7, y: 11 } },
	[NPC.JENOMIS]: { name: NPC.JENOMIS, found: { location: MapLocation.PRIMA_VISTA, x: 6, y: 6 } },
	[NPC.KEITEN]: { name: NPC.KEITEN, found: { location: MapLocation.KUGANE, x: 12, y: 12 } },
	[NPC.MARSAK]: { name: NPC.MARSAK, found: { location: MapLocation.MONZEN, x: 16, y: 9 } },
	[NPC.IRONWORKS_ENGINEER_RHALGRS]: { name: NPC.IRONWORKS_ENGINEER_RHALGRS, found: { location: MapLocation.STARFALL, x: 12, y: 12 } },
	[NPC.ZLATAN]: { name: NPC.ZLATAN, found: { location: MapLocation.GANGOS, x: 6, y: 5 } },
	[NPC.ALPHINAUD]: { name: NPC.ALPHINAUD, found: { location: MapLocation.EULMORE, x: 12, y: 12 } },
	[NPC.STRESSED_SOLDIER]: { name: NPC.STRESSED_SOLDIER, found: { location: MapLocation.GANGOS, x: 6, y: 5 } },
	[NPC.BAJSALJEN]: { name: NPC.BAJSALJEN, found: { location: MapLocation.GANGOS, x: 7, y: 6 } },
	[NPC.HILDIBRAND_LAMENTORUM]: { name: NPC.HILDIBRAND_LAMENTORUM, found: { location: MapLocation.SOUTHERN_MARE_LAMENTORUM, x: 28, y: 36 } },
	[NPC.HILDIBRAND_THAVNAIR]: { name: NPC.HILDIBRAND_THAVNAIR, found: { location: MapLocation.PURUSA, x: 33, y: 26 } },
	[NPC.HOUSE_MANDERVILLE_MANSERVANT]: { name: NPC.HOUSE_MANDERVILLE_MANSERVANT, found: { location: MapLocation.ARTHA, x: 12, y: 11 } },
	[NPC.HOUSE_MANDERVILLE_ARTISAN]: { name: NPC.HOUSE_MANDERVILLE_ARTISAN, found: { location: MapLocation.ARTHA, x: 12, y: 7 } },
	[NPC.SKYSTEEL_ENGINEER]: { name: NPC.SKYSTEEL_ENGINEER, found: { location: MapLocation.BRUME, x: 14, y: 13 } },
	[NPC.DENYS]: { name: NPC.DENYS, found: { location: MapLocation.SKYSTEEL_MANUFACTORY, x: 8, y: 10 } },
	[NPC.NEILLEMARD]: { name: NPC.NEILLEMARD, found: { location: MapLocation.SKYSTEEL_MANUFACTORY, x: 8, y: 10 } },
	[NPC.NIMIE]: { name: NPC.NIMIE, found: { location: MapLocation.SKYSTEEL_MANUFACTORY, x: 8, y: 11 } },
	[NPC.EMENY]: { name: NPC.EMENY, found: { location: MapLocation.FEATHERFALL, x: 8, y: 11 } },
	[NPC.MOWEN]: { name: NPC.MOWEN, found: { location: MapLocation.EULMORE, x: 11, y: 11 } },
	[NPC.KATLISS]: { name: NPC.KATLISS, found: { location: MapLocation.PENDANTS, x: 11, y: 8 } },
	[NPC.CHORA_ZOI]: { name: NPC.CHORA_ZOI, found: { location: MapLocation.ROTUNDA, x: 8, y: 12 } },
	[NPC.TALAN]: { name: NPC.TALAN, found: { location: MapLocation.MOR_DHONA, x: 22, y: 6 }},
};
