/* eslint-disable max-len */
//#region Imports
import { jobLists } from "character";
import FormComponents from "components/forms/FormComponents";
import JobIcon from "components/job-icon/JobIcon";
import Link from "components/link/Link";
import NoCharacter from "components/no-character/NoCharacter";
import RelicCheckbox from "components/relic-checkbox/RelicCheckbox";
import Tip from "components/tip/Tip";
import { dungeons } from "data-files/duties";
import { items } from "data-files/items";
import { quests } from "data-files/quests";
import { Dungeon, EorzeaDataType, InputType, Item, Job, LinkType, NPC, Quest, RelicGroup, RelicPart, RelicStep, RelicTask, RelicType, View } from "enums";
import log from "logger";
import manager from "managers/app";
import { Component, SyntheticEvent } from "react";
import { connect } from "react-redux";
import t from "translations/translator";
import { AppState, DungeonInfo, Requirement, TaskInfo, ViewRelicInfo, ViewWeaponAnimaCompleteProps } from "types";
import utils from "utils";
import ContentHeader from "views/page-parts/content/content-header/ContentHeader";
import DetailsSection from "views/page-parts/content/details-section/DetailsSection";
import Obtained from "views/page-parts/content/obtained/Obtained";
import OverviewTable from "views/page-parts/content/overview-table/OverviewTable";
import "./WeaponAnimaComplete.css";
//#endregion Imports

const mapStateToProps = (state:AppState) => {
	return {
		lang: state.userInfo.options.lang,
		gameLang: state.userInfo.options.gameLang,
		userInfo: state.userInfo,
		options: state.userInfo.options,
		hideCompletedSteps: state.userInfo.options.hideCompletedSteps,
		hideCompletedTasks: state.userInfo.options.hideCompletedTasks,
		showJob: state.userInfo.options.job,
		animaCompleteStepJob: state.userInfo.options.relics.animaCompleteStepJob,
	};
};

class WeaponAnimaComplete extends Component<ViewWeaponAnimaCompleteProps> {
	private requirements:Requirement[] = [];
	private importantNPCs:NPC[] = [];
	private relicInfo:ViewRelicInfo = {
		jobs: jobLists.relic[RelicGroup.WEAPON_ANIMA],
		type: RelicType.WEAPON,
		relic: RelicGroup.WEAPON_ANIMA,
		part: RelicPart.WEAPON_COMPLETE,
		steps: [RelicStep.DUNGEONS, RelicStep.PNEUMITE, RelicStep.DENSITY],
		stepPlaceholders: {
			[RelicStep.PNEUMITE]: { ITEM: Item.PNEUMITE },
			[RelicStep.DENSITY]: { ITEM: Item.AETHERIC_DENSITY },
		},
		next: {
			part: RelicPart.WEAPON_LUX,
			view: View.RELIC_WEAPONS_ANIMA_LUX,
		},
		previous: {
			part: RelicPart.WEAPON_SHARPENED,
			view: View.RELIC_WEAPONS_ANIMA_SHARPENED,
		},
	};

	constructor(props:ViewWeaponAnimaCompleteProps){
		super(props);
		this.requirements = this.getRequirements();
		this.importantNPCs = this.getImportantNPCs();
	}

	render(){
		const character = manager.data.getActiveCharacter();
		if(character === null){ return <NoCharacter />; }

		const completeSteps = manager.content.getCompleteSteps(character.progress, this.relicInfo);
		const stepJob = this.props.animaCompleteStepJob;
		const taskInfo:TaskInfo[] = [
			{ task: RelicTask.TASK_1, complete: character.progress.isComplete(this.relicInfo.type, this.relicInfo.relic, this.relicInfo.part, RelicStep.DUNGEONS, RelicTask.TASK_1, stepJob), target: manager.relics.getTaskDuty(RelicStep.DUNGEONS, RelicTask.TASK_1) },
			{ task: RelicTask.TASK_2, complete: character.progress.isComplete(this.relicInfo.type, this.relicInfo.relic, this.relicInfo.part, RelicStep.DUNGEONS, RelicTask.TASK_2, stepJob), target: manager.relics.getTaskDuty(RelicStep.DUNGEONS, RelicTask.TASK_2) },
			{ task: RelicTask.TASK_3, complete: character.progress.isComplete(this.relicInfo.type, this.relicInfo.relic, this.relicInfo.part, RelicStep.DUNGEONS, RelicTask.TASK_3, stepJob), target: manager.relics.getTaskDuty(RelicStep.DUNGEONS, RelicTask.TASK_3) },
		];
		const pneumiteInventory = manager.data.getInventoryStatus({
			item: manager.relics.getTaskItem(RelicStep.PNEUMITE, RelicTask.TASK_1),
			relic: [this.relicInfo.type, this.relicInfo.relic, this.relicInfo.part, RelicStep.PNEUMITE, RelicTask.TASK_1],
		});
		const densityInventory = manager.data.getInventoryStatus({
			item: manager.relics.getTaskItem(RelicStep.DENSITY, RelicTask.TASK_1),
			relic: [this.relicInfo.type, this.relicInfo.relic, this.relicInfo.part, RelicStep.DENSITY, RelicTask.TASK_1],
		});

		// TOOD: Make use of getTaskItem() below
		return (
			<div id="weapon-anima-complete" className="relic">
				<ContentHeader requirements={this.requirements} importantNPCs={this.importantNPCs} relicInfo={this.relicInfo}>
					<p>{t.t("The %PART% upgrade follows on from %PREVIOUSPART%", { PART: this.relicInfo.part, PREVIOUSPART: this.relicInfo.previous ? this.relicInfo.previous.part : "Unknown"})}</p>
					<p>{t.t("Complete")}<Link type={LinkType.LODESTONE} lodestoneType={EorzeaDataType.BLUEQUEST} text={Quest.BORN_AGAIN} lodestoneID={quests[Quest.BORN_AGAIN].lodestoneID} /></p>
				</ContentHeader>
				<div id="relic-checklist">
					<OverviewTable character={character} completeSteps={completeSteps} relicInfo={this.relicInfo} />
				</div>
				<div id="relic-details">
					<h1>{t.t("Details")}</h1>
					{this.props.hideCompletedSteps && completeSteps.allComplete ? t.t("All Steps Complete") : null }
					<DetailsSection title={RelicStep.DUNGEONS} id={RelicStep.DUNGEONS} stepComplete={completeSteps[RelicStep.DUNGEONS]}>
						<p>{t.t("Complete the following dungeons")}</p>
						<Tip text="The relic weapon is not required for these dungeons" />
						<div className="job-select">
							<FormComponents.Label
								hide={false}
								translate={true}
								text="Select job to track"
								position="left"
								alignment="center"
							/>
							{this.relicInfo.jobs.map((job) => {
								if(this.props.showJob !== null && this.props.showJob !== job){ return null; }
								const weaponName = manager.relics.getRelicNameKey(job, this.relicInfo.part);
								return <JobIcon key={`${job}`} job={job} tooltip={weaponName} selected={job === stepJob} onClick={() => { this.selectJob(job); }} />;
							})}
						</div>
						<div className="table-wrapper">
							<table className="table">
								<thead><tr><th>{t.t("Type")}</th><th>{t.t("Target")}</th><th>{t.t("Quantity")}</th><th>{t.t("Done")}</th></tr></thead>
								<tbody>
									{
										taskInfo.map((thisTask) => {
											const thisComplete = thisTask.complete;
											if((this.props.hideCompletedSteps || this.props.hideCompletedTasks) && thisComplete){ return null; }

											let thisTarget:DungeonInfo|null = null;

											if(utils.guards.isEnumValue(Dungeon, thisTask.target)){
												thisTarget = dungeons[thisTask.target];
											}else{
												log.error("Wrong info set for this task"); return null;
											}

											return <tr key={`${RelicStep.DUNGEONS}-${thisTask.task}`}>
												<td><Obtained obtained={{ duties: { dungeons: [thisTask.target]}}} qtyNeeded={1} /></td>
												<td>{t.t(thisTarget.name)}</td>
												<td>1</td>
												<td><RelicCheckbox character={character} job={stepJob} relicInfo={this.relicInfo} step={RelicStep.DUNGEONS} task={thisTask.task} /></td>
											</tr>;
										})
									}
									{
										character.progress.isComplete(this.relicInfo.type, this.relicInfo.relic, this.relicInfo.part, RelicStep.DUNGEONS)
											? <tr className="all-complete"><td colSpan={4}><p>{t.t("All Complete")}</p></td></tr>
											: null
									}
								</tbody>
							</table>
						</div>
					</DetailsSection>
					<DetailsSection title={RelicStep.PNEUMITE} placeholders={this.relicInfo.stepPlaceholders[RelicStep.PNEUMITE]} id={RelicStep.PNEUMITE} stepComplete={completeSteps[RelicStep.PNEUMITE]}>
						<p>{t.t("Complete")}<Link type={LinkType.LODESTONE} lodestoneType={EorzeaDataType.BLUEQUEST} text={Quest.SOME_ASSEMBLY_REQUIRED} lodestoneID={quests[Quest.SOME_ASSEMBLY_REQUIRED].lodestoneID} /></p>
						<div className="table-wrapper">
							<table className="table">
								<thead><tr><th>{t.t("Obtained")}</th><th>{t.t("Item")}</th><th>{t.t("Quantity / Job")}</th><th>{t.t("Total")}</th><th>{t.t("Used")}</th><th>{t.t("Remaining")}</th><th>{t.t("Inventory")}</th><th>{t.t("To Obtain")}</th></tr></thead>
								<tbody>
									{this.props.hideCompletedTasks && pneumiteInventory.toObtain === 0
										? null
										: <tr className="odd-row">
											<td><Obtained obtained={items[Item.PNEUMITE].obtained} qtyNeeded={pneumiteInventory.total} /></td>
											<td>{t.t(items[Item.PNEUMITE].name)}</td>
											<td>{pneumiteInventory.qtyPerJob.toLocaleString()}</td>
											<td>{pneumiteInventory.total.toLocaleString()}</td>
											<td>{pneumiteInventory.used.toLocaleString()}</td>
											<td>{pneumiteInventory.remaining.toLocaleString()}</td>
											<td><FormComponents.TextInput label={{ hide: true }} input={{ type: InputType.NUMBER, value: pneumiteInventory.inventory, dataAttributes: { item: Item.PNEUMITE }, events: { onChange: manager.content.inventoryUpdated }}} /></td>
											<td>{pneumiteInventory.toObtain.toLocaleString()}</td>
										</tr>
									}
									{
										character.progress.isComplete(this.relicInfo.type, this.relicInfo.relic, this.relicInfo.part, RelicStep.PNEUMITE)
											? <tr className="all-complete"><td colSpan={8}><p>{t.t("All Complete")}</p></td></tr>
											: null
									}
								</tbody>
							</table>
						</div>
					</DetailsSection>
					<DetailsSection title={RelicStep.DENSITY} placeholders={this.relicInfo.stepPlaceholders[RelicStep.DENSITY]} id={RelicStep.DENSITY} stepComplete={completeSteps[RelicStep.DENSITY]}>
						<div className="light-info">
							<div className="wrapper">
								<div className="table-wrapper">
									<table className="table">
										<thead><tr><th>{t.t("Stage")}</th><th>{t.t("Points")}</th></tr></thead>
										<tbody>
											<tr><td>1 {t.t("Rune")}</td><td>{(200).toLocaleString()} - {(399).toLocaleString()}</td></tr>
											<tr><td>2 {t.t("Runes")}</td><td>{(400).toLocaleString()} - {(599).toLocaleString()}</td></tr>
											<tr><td>3 {t.t("Runes")}</td><td>{(600).toLocaleString()} - {(799).toLocaleString()}</td></tr>
											<tr><td>4 {t.t("Runes")}</td><td>{(800).toLocaleString()} - {(999).toLocaleString()}</td></tr>
											<tr><td>5 {t.t("Runes")}</td><td>{(1000).toLocaleString()} - {(1199).toLocaleString()}</td></tr>
											<tr><td>6 {t.t("Runes")}</td><td>{(1200).toLocaleString()} - {(1399).toLocaleString()}</td></tr>
											<tr><td>7 {t.t("Runes")}</td><td>{(1400).toLocaleString()} - {(1599).toLocaleString()}</td></tr>
											<tr><td>8 {t.t("Runes")}</td><td>{(1600).toLocaleString()} - {(1799).toLocaleString()}</td></tr>
											<tr><td>9 {t.t("Runes")}</td><td>{(1800).toLocaleString()} - {(1999).toLocaleString()}</td></tr>
											<tr><td>10 {t.t("Runes")}</td><td>{(2000).toLocaleString()}</td></tr>
										</tbody>
									</table>
								</div>
							</div>
							<div className="light-buttons">
								<div className="button-group">
									<FormComponents.Button text="Add Vague condensation" onClick={this.addCondensation} dataAttributes={{ condensation: 4 }} translate={true} />
									<FormComponents.Button text="Add Meager condensation" onClick={this.addCondensation} dataAttributes={{ condensation: 16 }} translate={true} />
								</div>
								<div className="button-group">
									<FormComponents.Button text="Add Vigorous condensation" onClick={this.addCondensation} dataAttributes={{ condensation: 32 }} translate={true} />
									<FormComponents.Button text="Add Robust condensation" onClick={this.addCondensation} dataAttributes={{ condensation: 48 }} translate={true} />
								</div>
								<div className="button-group">
									<FormComponents.Button text="Add Sturdy condensation" onClick={this.addCondensation} dataAttributes={{ condensation: 64 }} translate={true} />
									<FormComponents.Button text="Add Hardended condensation" onClick={this.addCondensation} dataAttributes={{ condensation: 96 }} translate={true} />
								</div>
								<div className="button-group">
									<FormComponents.Button text="Add Stalwart condensation" onClick={this.addCondensation} dataAttributes={{ condensation: 120 }} translate={true} />
								</div>
							</div>
						</div>
						<div className="table-wrapper">
							<table className="table">
								<thead><tr><th>{t.t("Obtained")}</th><th>{t.t("Item")}</th><th>{t.t("Quantity / Job")}</th><th>{t.t("Total")}</th><th>{t.t("Used")}</th><th>{t.t("Remaining")}</th><th>{t.t("Inventory")}</th><th>{t.t("To Obtain")}</th></tr></thead>
								<tbody>
									{this.props.hideCompletedTasks && densityInventory.toObtain === 0
										? null
										: <tr className="odd-row">
											<td><Obtained obtained={items[Item.AETHERIC_DENSITY].obtained} qtyNeeded={densityInventory.total} /></td>
											<td>{t.t(items[Item.AETHERIC_DENSITY].name)}</td>
											<td>{densityInventory.qtyPerJob.toLocaleString()}</td>
											<td>{densityInventory.total.toLocaleString()}</td>
											<td>{densityInventory.used.toLocaleString()}</td>
											<td>{densityInventory.remaining.toLocaleString()}</td>
											<td><FormComponents.TextInput label={{ hide: true }} input={{ type: InputType.NUMBER, value: densityInventory.inventory, dataAttributes: { item: Item.AETHERIC_DENSITY }, events: { onChange: manager.content.inventoryUpdated }}} /></td>
											<td>{densityInventory.toObtain.toLocaleString()}</td>
										</tr>
									}
									{
										character.progress.isComplete(this.relicInfo.type, this.relicInfo.relic, this.relicInfo.part, RelicStep.DENSITY)
											? <tr className="all-complete"><td colSpan={8}><p>{t.t("All Complete")}</p></td></tr>
											: null
									}
								</tbody>
							</table>
						</div>
					</DetailsSection>
				</div>
			</div>
		);
	}

	componentDidMount(){
		manager.view.changeComplete();
	}

	private getRequirements():Requirement[]{
		if(!this.relicInfo || this.relicInfo.previous === null){ return []; }
		return [
			{ type: "previous part", part: this.relicInfo.previous.part, partView: this.relicInfo.previous.view },
		];
	}

	private getImportantNPCs():NPC[]{
		return [NPC.ARDASHIR, NPC.PROCESSING_NODE];
	}

	addCondensation = (evt:SyntheticEvent) => { // Event
		const ele = evt.currentTarget as HTMLButtonElement;
		const condensationPoints = ele.dataset.condensation ? parseInt(ele.dataset.condensation) : 0;
		const inputs = ele.closest(".light-info")?.nextElementSibling?.getElementsByTagName("input");

		if(condensationPoints === 0){ log.error("Unable to find condensation point value"); return; }
		if(!inputs || inputs.length <= 0){ log.error("Unable to find condensation inventory input"); return; }

		const item = inputs[0].dataset.item as Item | undefined;
		const character = manager.data.getActiveCharacter();

		if(!item){ log.error("Missing Item from dataset"); return; }
		if(!character){ log.warn("No active character found"); return; }

		const value = inputs[0].valueAsNumber + condensationPoints;
		manager.content.updateInventory(item, value, true);
	}

	addLight = (evt:SyntheticEvent) => { // Event
		const ele = evt.currentTarget as HTMLButtonElement;
		const lightPoints = ele.dataset.light ? parseInt(ele.dataset.light) : 0;
		const inputs = ele.closest(".light-info")?.nextElementSibling?.getElementsByTagName("input");

		if(lightPoints === 0){ log.error("Unable to find light point value"); return; }
		if(!inputs || inputs.length <= 0){ log.error("Unable to find light inventory input"); return; }

		const item = inputs[0].dataset.item as Item | undefined;

		const character = manager.data.getActiveCharacter();

		if(!item){ log.error("Missing Item from dataset"); return; }
		if(!character){ log.warn("No active character found"); return; }

		const value = inputs[0].valueAsNumber + lightPoints;
		manager.content.updateInventory(item, value, true);
	}

	private selectJob(job:Job){ // Event
		const options = this.props.options;
		options.relics = {
			...options.relics,
			animaCompleteStepJob: utils.guards.isEnumValue(Job, job) ? job : Job.PLD,
		};
		manager.data.saveOptions(options, true);
	}
}
export default connect(mapStateToProps)(WeaponAnimaComplete);
