//#region Imports
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import FormComponents from "components/forms/FormComponents";
import { Component, SyntheticEvent } from "react";
import { connect } from "react-redux";
import t from "translations/translator";
import { AppState, PaginationProps, RSOption } from "types";
import utils from "utils";
import "./Pagination.css";
//#endregion Imports

const mapStateToProps = (state:AppState) => {
	return {
		lang: state.userInfo.options.lang,
		gameLang: state.userInfo.options.gameLang,
		breakpoint: state.breakpoint,
		options: state.userInfo.options,
	};
};

class Pagination extends Component<PaginationProps> {
	private rowOptions:RSOption[] = [];
	private maxPagesToDisplay = 5;

	render(){
		const totalPages = Math.ceil(this.props.totalEntries / this.props.entriesPerPage);
		const previousPage = this.props.page - 1;
		const nextPage = this.props.page + 1;

		const {start, end} = utils.helpers.getPaginationIndexes(this.props.page, this.props.entriesPerPage, this.props.totalEntries, () => {}); // eslint-disable-line no-empty-function
		const selectedRowOption:RSOption = { label: `${this.props.entriesPerPage}`, value: `${this.props.entriesPerPage}` };

		const startNum = start >= this.props.totalEntries ? this.props.totalEntries : start + 1;

		return <div className="pagination">
			<div>
				<p>{t.t("Showing %STARTENTRY% - %ENDENTRY% of %TOTALENTRIES%", { STARTENTRY: startNum.toString(), ENDENTRY: end.toString(), TOTALENTRIES: this.props.totalEntries.toString() })}</p>
				<FormComponents.Select
					label={{ hide: false, text: "Rows", position: "left", alignment: "center" }}
					input={{ options: this.rowOptions, value: [selectedRowOption], events: { onChange: this.props.changeNumberOfRows } }}
				/>
			</div>
			{this.props.totalEntries === 0
				? null
				: <div>
					<FormComponents.Button icon={faChevronLeft} text="" translate={false} dataAttributes={{ page: previousPage }} onClick={this.goToPage} />
					{[...Array(totalPages)].map((e, i) => {
						const page = i + 1;
						let lowestPage = this.props.page - Math.floor(this.maxPagesToDisplay / 2);
						let highestPage = this.props.page + Math.floor(this.maxPagesToDisplay / 2);

						if(lowestPage <= 0){ highestPage += (lowestPage * -1) + 1; }
						if(highestPage >= totalPages){ lowestPage -= highestPage - totalPages; }

						if(page < lowestPage){
							if(page === lowestPage - 1 && lowestPage - 1 !== 1){
								return <span key={`page-btn-${page}`}>...</span>;
							}else if(page !== 1){
								return null;
							}
						}
						if(page > highestPage){
							if(page === highestPage + 1 && highestPage + 1 !== totalPages){
								return <span key={`page-btn-${page}`}>...</span>;
							}else if(page !== totalPages){
								return null;
							}
						}

						const currentClass = page === this.props.page ? "current" : "";
						return <FormComponents.Button key={`page-btn-${page}`} class={[currentClass]} text={page.toString()} dataAttributes={{ page: page}} onClick={this.goToPage} translate={false} />;
					})}
					<FormComponents.Button icon={faChevronRight} text="" translate={false} dataAttributes={{ page: nextPage }} onClick={this.goToPage} />
				</div>
			}
		</div>;
	}

	componentDidMount(){
		this.rowOptions.push(
			{ label: "25", value: "25" },
			{ label: "50", value: "50" },
			{ label: "75", value: "75" },
			{ label: "100", value: "100" },
		);
	}

	goToPage = (evt:SyntheticEvent) => { // Event
		const ele = evt.currentTarget as HTMLButtonElement;
		if(!ele.dataset.page){ return; }

		const newPage = parseInt(ele.dataset.page);
		const maxPage = Math.ceil(this.props.totalEntries / this.props.entriesPerPage);

		let page = newPage;
		if(page < 1){ page = 1; }
		if(page > maxPage){ page = maxPage; }

		this.props.pageChange(page);
	}
}
export default connect(mapStateToProps)(Pagination);
