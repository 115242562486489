//#region Imports
import FormComponents from "components/forms/FormComponents";
import LanguageSwitcher from "components/language-switcher/LanguageSwitcher";
import ThemeSwitcher from "components/theme-switcher/ThemeSwitcher";
import { View } from "enums";
import manager from "managers/app";
import { actions } from "managers/state";
import { Component } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { AppState, ViewMenuFooterProps } from "types";
//#endregion Imports


const mapStateToProps = (state:AppState) => {
	return {
		lang: state.userInfo.options.lang,
		gameLang: state.userInfo.options.gameLang,
		breakpoint: state.breakpoint,
	};
};

const mapDispatchToProps = (dispatch:Dispatch) => {
	return { setView: (view:View) => { dispatch(actions.setView(view)); } };
};

class MenuFooter extends Component<ViewMenuFooterProps> {
	render(){
		return (
			<div className="menu-footer">
				<LanguageSwitcher labelPosition="left" />
				<ThemeSwitcher />
				<FormComponents.Button class={["secondary"]} text="View Change Log" onClick={this.viewChangeLogClick} />
				<div className="patreon-wrapper"><a href="https://www.patreon.com/bePatron?u=57369773" data-patreon-widget-type="become-patron-button">Become a Patron!</a></div>
			</div>
		);
	}

	componentDidMount(){
		const script = document.createElement("script");
		script.src = "https://c6.patreon.com/becomePatronButton.bundle.js";
		document.head.appendChild(script);
	}

	viewChangeLogClick = () => { // Event
		manager.view.change(View.CHANGE_LOG);
	}
}
export default connect(mapStateToProps, mapDispatchToProps)(MenuFooter);
