//#region Imports
import { Component } from "react";
import Select, { StylesConfig } from "react-select";
import makeAnimated from "react-select/animated";
import Creatable from "react-select/creatable";
import t from "translations/translator";
import { FormSelectProps, RSOption } from "types";
import { FormFieldWrapper } from "./FormFieldWrapper";
import { FormInputWrapper } from "./FormInputWrapper";
import { FormLabel } from "./FormLabel";
//#endregion Imports

const animatedComponents = makeAnimated();

const selectStyles:StylesConfig<RSOption> = {
	// clearIndicator: (styles) => ({ ...styles }),
	container: (styles) => { return { ...styles, color: "var(--font-color-dark)", width: "100%" }; },
	control: (styles, state) => {
		return {
			...styles,
			cursor: state.isDisabled ? "default" : "pointer",
			padding: 0,
			borderRadius: "var(--border-radius-md)",
			backgroundColor: state.isDisabled ? "var(--background-disabled)" : "var(--background-inputs-primary)",
			color: state.isDisabled ? "var(--font-color-disabled)" : "inherit",
		};
	},
	option: (styles, state) => { return { ...styles, cursor: state.isSelected ? "default" : "pointer" }; },
	multiValue: (styles) => { return { ...styles, backgroundColor: "var(--background-secondary)", borderRadius: "var(--border-radius-sm)" }; },
	multiValueLabel: (styles) => { return { ...styles, color: "var(--font-color-light)", padding: "var(--padding-sm)" }; },
	multiValueRemove: (styles) => { return { ...styles, color: "var(--font-color-light)" }; },
};

/*
	onChange Event Notes
		For multi select:
			newValue will be everything thats now selected as an array
			actionMeta contains:
				Adding Items:
					action: "select-option", name: undefined, option: RSOption - The added option
				Removing Items:
					action: "remove-value", name: undefined, removedvalue: RSOption - The removed option
		For single select:
			newValue = selected value
			actionMeta contains:
				Selecting an option:
					action: "select-option", name: undefined, option: undefined
*/

export class FormSelect extends Component<FormSelectProps> {
	render(){
		const label = this.props.label;
		const input = this.props.input;
		const isCreatable = input.creatable;

		return (
			<FormFieldWrapper label={label} width={this.props.fieldWidth}>
				<FormLabel {...label} />
				<FormInputWrapper>
					{isCreatable
						? <Creatable
							styles={selectStyles}
							components={animatedComponents}
							options={input.options}
							isMulti={input.multiple}
							value={input.value}
							placeholder={input.placeholder && t.t(input.placeholder)}
							isDisabled={input.disabled}
							menuPlacement="auto"
							{...input.events}
						/>
						: <Select
							styles={selectStyles}
							components={animatedComponents}
							options={input.options}
							isMulti={input.multiple}
							value={input.value}
							placeholder={input.placeholder && t.t(input.placeholder)}
							isDisabled={input.disabled}
							menuPlacement="auto"
							{...input.events}
						/>
					}
				</FormInputWrapper>
			</FormFieldWrapper>
		);
	}
}
