//#region Imports
import { AnyAction } from "@reduxjs/toolkit";
import { ActionType, Breakpoint, Modal, View } from "enums";
import { createStore } from "redux";
import { AppState, AppUser, Character, ConfirmationOptions, GameServers, ISysMsg, Notice, ObtainedDungeon, ObtainedEnemy, ObtainedExtraction, ObtainedFate, ObtainedFishing, ObtainedGathering, ObtainedLeve, ObtainedLocation, ObtainedMarket, ObtainedOther, ObtainedQuest, ObtainedRaid, ObtainedShop, ObtainedTrial, RecipeModalData, UserData, UserInfo, UserOptions } from "types"; // eslint-disable-line max-len
import manager from "./app";
import { appEnv } from "./env";
//#endregion Imports

export const actions = {
	setBreakpoint: function(payload:string){ return { type: ActionType.SET_BREAKPOINT, payload }; },
	setView: function(payload:View){ return { type: ActionType.SET_VIEW, payload }; },
	addSystemMessage: function(payload:ISysMsg){ return { type: ActionType.ADD_SYS_MESSAGE, payload }; },
	removeSystemMessage: function(payload:number){ return { type: ActionType.REMOVE_SYS_MESSAGE, payload }; },
	setUserInfo: function(payload:UserInfo){ return { type: ActionType.SET_USER_INFO, payload }; },
	setUser: function(payload:AppUser){ return { type: ActionType.SET_USER, payload }; },
	setUserOptions: function(payload:UserOptions){ return { type: ActionType.SET_USER_OPTIONS, payload }; },
	setUserData: function(payload:UserData){ return { type: ActionType.SET_USER_DATA, payload }; },
	setCharacters: function(payload:Character[]){ return { type: ActionType.SET_CHARACTERS, payload }; },
	deleteCharacters: function(payload:string[]){ return { type: ActionType.DELETE_CHARACTERS, payload }; },
	setNotices: function(payload:Notice[]){ return { type: ActionType.SET_NOTICES, payload }; },
	setModalConfirmation: function(payload:ConfirmationOptions){ return { type: ActionType.SET_MODAL_CONFIRMATION, payload }; },
	setModalEnemies: function(payload:ObtainedEnemy[]){ return { type: ActionType.SET_MODAL_ENEMIES, payload }; },
	setModalRecipes: function(payload:RecipeModalData){ return { type: ActionType.SET_MODAL_RECIPES, payload }; },
	setModalMarkets: function(payload:ObtainedMarket){ return { type: ActionType.SET_MODAL_MARKETS, payload }; },
	setModalExtractions: function(payload:ObtainedExtraction){ return { type: ActionType.SET_MODAL_EXTRACTIONS, payload }; },
	setModalGathering: function(payload:ObtainedGathering[]){ return { type: ActionType.SET_MODAL_GATHERING, payload }; },
	setModalFishing: function(payload:ObtainedFishing[]){ return { type: ActionType.SET_MODAL_FISHING, payload }; },
	setModalShops: function(payload:ObtainedShop[]){ return { type: ActionType.SET_MODAL_SHOPS, payload }; },
	setModalLocations: function(payload:ObtainedLocation[]){ return { type: ActionType.SET_MODAL_LOCATIONS, payload }; },
	setModalTrials: function(payload:ObtainedTrial[]){ return { type: ActionType.SET_MODAL_TRIALS, payload }; },
	setModalDungeons: function(payload:ObtainedDungeon[]){ return { type: ActionType.SET_MODAL_DUNGEONS, payload }; },
	setModalRaids: function(payload:ObtainedRaid[]){ return { type: ActionType.SET_MODAL_RAIDS, payload }; },
	setModalFates: function(payload:ObtainedFate[]){ return { type: ActionType.SET_MODAL_FATES, payload }; },
	setModalLeves: function(payload:ObtainedLeve[]){ return { type: ActionType.SET_MODAL_LEVES, payload }; },
	setModalQuests: function(payload:ObtainedQuest[]){ return { type: ActionType.SET_MODAL_QUESTS, payload }; },
	setModalOther: function(payload:ObtainedOther[]){ return { type: ActionType.SET_MODAL_OTHER, payload }; },
	setAnonymousAvailable: function(payload:boolean){ return { type: ActionType.SET_ANONYMOUS_AVAILABLE, payload }; },
	setGameServers: function(payload:GameServers){ return { type: ActionType.SET_GAME_SERVERS, payload }; },
};

const initialState:AppState = {
	appVersion: appEnv.version,
	breakpoint: Breakpoint.DESKTOP,
	userInfo: {
		isAuthenticated: false,
		username: "",
		options: manager.data.getDefaultOptions(),
		data: { version: appEnv.dataVersion, characters: [] },
	},
	maxOfflineCharacters: 3,
	view: View.HOME,
	systemMessages: [],
	notices: [],
	modal: {
		[Modal.CONFIRMATION]: { title: "", text: "", confirm: { text: "", action: () => {} }, cancel: { text: "", action: () => {} } }, // eslint-disable-line no-empty-function
		[Modal.ENEMIES]: [],
		[Modal.RECIPES]: { recipes: [], qtyNeeded: -1 },
		[Modal.MARKETS]: {},
		[Modal.EXTRACTIONS]: true,
		[Modal.GATHERING]: [],
		[Modal.FISHING]: [],
		[Modal.SHOPS]: [],
		[Modal.LOCATIONS]: [],
		[Modal.TRIALS]: [],
		[Modal.DUNGEONS]: [],
		[Modal.RAIDS]: [],
		[Modal.FATES]: [],
		[Modal.LEVES]: [],
		[Modal.QUESTS]: [],
		[Modal.OTHER]: [],
		[Modal.DESYNTHESIS]: [],
		[Modal.REDUCTIONS]: [],
	},
	anonymousAvailable: false,
	gameServers: {},
};

export const appReducer = function(state:AppState = initialState, action:AnyAction){ // eslint-disable-line complexity
	switch(action.type as ActionType){
		default: return state;
		case ActionType.SET_BREAKPOINT: return setBreakpoint(state, action.payload);
		case ActionType.SET_VIEW: return setView(state, action.payload);
		case ActionType.ADD_SYS_MESSAGE: return addSysMessage(state, action.payload);
		case ActionType.REMOVE_SYS_MESSAGE: return removeSysMessage(state, action.payload);
		case ActionType.SET_USER_INFO: return setUserInfo(state, action.payload);
		case ActionType.SET_USER: return setUser(state, action.payload);
		case ActionType.SET_USER_OPTIONS: return setUserOptions(state, action.payload);
		case ActionType.SET_USER_DATA: return setUserData(state, action.payload);
		case ActionType.SET_CHARACTERS: return setCharacters(state, action.payload);
		case ActionType.DELETE_CHARACTERS: return deleteCharacters(state, action.payload);
		case ActionType.SET_NOTICES: return setNotices(state, action.payload);
		case ActionType.SET_MODAL_CONFIRMATION: return setModalConfirmation(state, action.payload);
		case ActionType.SET_MODAL_ENEMIES: return setModalEnemies(state, action.payload);
		case ActionType.SET_MODAL_RECIPES: return setModalRecipes(state, action.payload);
		case ActionType.SET_MODAL_MARKETS: return setModalMarkets(state, action.payload);
		case ActionType.SET_MODAL_EXTRACTIONS: return setModalExtractions(state, action.payload);
		case ActionType.SET_MODAL_GATHERING: return setModalGathering(state, action.payload);
		case ActionType.SET_MODAL_FISHING: return setModalFishing(state, action.payload);
		case ActionType.SET_MODAL_SHOPS: return setModalShops(state, action.payload);
		case ActionType.SET_MODAL_LOCATIONS: return setModalLocations(state, action.payload);
		case ActionType.SET_MODAL_TRIALS: return setModalTrials(state, action.payload);
		case ActionType.SET_MODAL_DUNGEONS: return setModalDungeons(state, action.payload);
		case ActionType.SET_MODAL_RAIDS: return setModalRaids(state, action.payload);
		case ActionType.SET_MODAL_FATES: return setModalFates(state, action.payload);
		case ActionType.SET_MODAL_LEVES: return setModalLeves(state, action.payload);
		case ActionType.SET_MODAL_QUESTS: return setModalQuests(state, action.payload);
		case ActionType.SET_MODAL_OTHER: return setModalOther(state, action.payload);
		case ActionType.SET_ANONYMOUS_AVAILABLE: return setAnonymousAvailable(state, action.payload);
		case ActionType.SET_GAME_SERVERS: return setGameServers(state, action.payload);
	}
};
export const store = createStore(appReducer);

function setBreakpoint(state:AppState, payload:Breakpoint):AppState{ return { ...state, breakpoint: payload }; }
function setView(state:AppState, payload:View):AppState{ return { ...state, view: payload }; }
function addSysMessage(state:AppState, payload:ISysMsg):AppState{ return { ...state, systemMessages: state.systemMessages.concat(payload) }; }
function removeSysMessage(state:AppState, payload:number):AppState{
	const index = state.systemMessages.findIndex((z) => { return z.id === payload; });
	if(index === -1){ return state; }
	return {
		...state,
		systemMessages: [
			...state.systemMessages.slice(0, index),
			...state.systemMessages.slice(index + 1),
		],
	};
}
function setUserInfo(state:AppState, payload:UserInfo):AppState{ return { ...state, userInfo: payload }; }
function setUser(state:AppState, payload:AppUser):AppState{ return { ...state, userInfo: { ...state.userInfo, isAuthenticated: payload.isAuthenticated, username: payload.username } }; }
function setUserOptions(state:AppState, payload:UserOptions):AppState{
	return { ...state, userInfo: { ...state.userInfo, options: payload } };
}
function setUserData(state:AppState, payload:UserData):AppState{ return { ...state, userInfo: { ...state.userInfo, data: payload } }; }
function setCharacters(state:AppState, payload:Character[]):AppState{
	const newCharState = [...state.userInfo.data.characters];
	payload.forEach((char) => {
		const index = newCharState.findIndex((z) => { return z.seId === char.seId; });
		if(index === -1){
			newCharState.push(char); // New Character
		}else{
			newCharState[index] = char;
		}
	});
	return { ...state, userInfo: { ...state.userInfo, data: { ...state.userInfo.data, characters: newCharState } } };
}
function deleteCharacters(state:AppState, payload:string[]):AppState{ return { ...state, userInfo: { ...state.userInfo, data: { ...state.userInfo.data, characters: state.userInfo.data.characters.filter((z) => { return !payload.includes(z.seId); }) } } }; } // eslint-disable-line max-len
function setNotices(state:AppState, payload:Notice[]):AppState{ return { ...state, notices: payload }; }
function setModalConfirmation(state:AppState, payload:ConfirmationOptions):AppState{ return { ...state, modal: { ...state.modal, [Modal.CONFIRMATION]: payload } }; }
function setModalEnemies(state:AppState, payload:ObtainedEnemy[]):AppState{ return { ...state, modal: { ...state.modal, [Modal.ENEMIES]: payload } }; }
function setModalRecipes(state:AppState, payload:RecipeModalData):AppState{ return { ...state, modal: { ...state.modal, [Modal.RECIPES]: payload } }; }
function setModalMarkets(state:AppState, payload:ObtainedMarket):AppState{ return { ...state, modal: { ...state.modal, [Modal.MARKETS]: payload } }; }
function setModalExtractions(state:AppState, payload:ObtainedExtraction):AppState{ return { ...state, modal: { ...state.modal, [Modal.EXTRACTIONS]: payload } }; }
function setModalGathering(state:AppState, payload:ObtainedGathering[]):AppState{ return { ...state, modal: { ...state.modal, [Modal.GATHERING]: payload } }; }
function setModalFishing(state:AppState, payload:ObtainedFishing[]):AppState{ return { ...state, modal: { ...state.modal, [Modal.FISHING]: payload } }; }
function setModalShops(state:AppState, payload:ObtainedShop[]):AppState{ return { ...state, modal: { ...state.modal, [Modal.SHOPS]: payload } }; }
function setModalLocations(state:AppState, payload:ObtainedLocation[]):AppState{ return { ...state, modal: { ...state.modal, [Modal.LOCATIONS]: payload } }; }
function setModalTrials(state:AppState, payload:ObtainedTrial[]):AppState{ return { ...state, modal: { ...state.modal, [Modal.TRIALS]: payload } }; }
function setModalDungeons(state:AppState, payload:ObtainedDungeon[]):AppState{ return { ...state, modal: { ...state.modal, [Modal.DUNGEONS]: payload } }; }
function setModalRaids(state:AppState, payload:ObtainedRaid[]):AppState{ return { ...state, modal: { ...state.modal, [Modal.RAIDS]: payload } }; }
function setModalFates(state:AppState, payload:ObtainedFate[]):AppState{ return { ...state, modal: { ...state.modal, [Modal.FATES]: payload } }; }
function setModalLeves(state:AppState, payload:ObtainedLeve[]):AppState{ return { ...state, modal: { ...state.modal, [Modal.LEVES]: payload } }; }
function setModalQuests(state:AppState, payload:ObtainedQuest[]):AppState{ return { ...state, modal: { ...state.modal, [Modal.QUESTS]: payload } }; }
function setModalOther(state:AppState, payload:ObtainedOther[]):AppState{ return { ...state, modal: { ...state.modal, [Modal.OTHER]: payload } }; }
function setAnonymousAvailable(state:AppState, payload:boolean):AppState{ return { ...state, anonymousAvailable: payload }; }
function setGameServers(state:AppState, payload:GameServers):AppState{ return { ...state, gameServers: payload }; }
