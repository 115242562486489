//#region Imports
import BasicIcon from "components/basic-icon/BasicIcon";
import FormComponents from "components/forms/FormComponents";
import { enemies } from "data-files/enemies";
import { locations } from "data-files/locations";
import { Icon, Modal } from "enums";
import manager from "managers/app";
import ModalContent from "modals/content/ModalContent";
import ModalFooter from "modals/footer/ModalFooter";
import ModalHeader from "modals/header/ModalHeader";
import { Component } from "react";
import { connect } from "react-redux";
import t from "translations/translator";
import { AppState, EnemiesModalProps } from "types";
import "./EnemiesModal.css";
//#endregion Imports

const mapStateToProps = (state:AppState) => {
	return {
		lang: state.userInfo.options.lang,
		gameLang: state.userInfo.options.gameLang,
		enemies: state.modal[Modal.ENEMIES],
	};
};

class EnemiesModal extends Component<EnemiesModalProps> {
	render(){
		return (
			<div id={Modal.ENEMIES} className="modal-wrapper">
				<ModalHeader text="Enemies" icon={Icon.ENEMY}/>
				<ModalContent>
					<div className="enemy-wrapper">
						{this.props.enemies.map((target) => {
							const enemy = enemies[target];

							return <div key={enemy.name} className="enemy-card">
								<h3 className="enemy-name">{t.t(enemy.name)}</h3>
								<div className="enemy-location-wrapper">
									{enemy.found.map((loc) => {
										const enemyLocation = locations[loc.location];

										return <div key={loc.location} className="enemy-location">
											<span><BasicIcon icon={Icon.MAP} tooltip="Map" /> {t.t(enemyLocation.map)}</span>
											<span><BasicIcon icon={Icon.REGION} tooltip="Region" /> {enemyLocation.region ? t.t(enemyLocation.region) : t.t("Unknown")}</span>
											<span><BasicIcon icon={Icon.TELEPORT} tooltip="Teleport" /> {enemyLocation.teleport ? t.t(enemyLocation.teleport) : t.t("Unknown")}</span>
											<span><BasicIcon icon={Icon.LOCATION} tooltip="Co-ordinates" /> X: {loc.x}, Y: {loc.y}</span>
										</div>;
									})}
								</div>
							</div>;
						})}
					</div>
				</ModalContent>
				<ModalFooter>
					<FormComponents.Button text="Close" onClick={manager.modal.close} translate={true} />
				</ModalFooter>
			</div>
		);
	}
}
export default connect(mapStateToProps)(EnemiesModal);
