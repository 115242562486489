import { EnvironmentType, GameLanguage, Language } from "enums";
import log from "logger";
import { appEnv } from "managers/env";
import { store } from "managers/state";
import { GameTranslationProgress, OutputGameString, OutputString, TranslationPlaceholders, TranslationProgress } from "types";
import utils from "utils";
import gameTranslations from "./game-translations";
import siteTranslations from "./site-translations";

class Translator {
	private totalTranslationEntries:number = Object.keys(siteTranslations).length;
	private totalGameTranslationEntries:number = Object.keys(gameTranslations).length;
	private translationProgress:TranslationProgress = { EN: 0, ES: 0, FR: 0, DE: 0 };
	private gameTranslationProgress:GameTranslationProgress = { EN: 0, FR: 0, DE: 0, JP: 0 };

	constructor(){
		Object.keys(siteTranslations).forEach((key) => {
			Object.values(Language).forEach((lang) => { if(lang !== Language.TEST && siteTranslations[key as OutputString][lang] !== null){ this.translationProgress[lang]++; } });
		});

		Object.keys(gameTranslations).forEach((key) => {
			Object.values(GameLanguage).forEach((lang) => { if(lang !== GameLanguage.TEST && gameTranslations[key as OutputGameString][lang] !== null){ this.gameTranslationProgress[lang]++; } });
		});
	}

	getTranslationPercent(lang:Language):number{
		const thisLang = lang === Language.TEST ? Language.EN : lang;
		return Math.floor(this.translationProgress[thisLang] / this.totalTranslationEntries * 100);
	}

	getGameTranslationsPercent(lang:GameLanguage):number{
		const thisLang = lang === GameLanguage.TEST ? GameLanguage.EN : lang;
		return Math.floor(this.gameTranslationProgress[thisLang] / this.totalGameTranslationEntries * 100);
	}

	private getLanguageToTranslateTo(textToTranslate:OutputString|OutputGameString){
		const options = store.getState().userInfo.options;
		let lang:Language|GameLanguage = options.lang;

		if(utils.guards.isOutputGameString(textToTranslate)){
			lang = options.gameLang;
			return lang === GameLanguage.TEST ? GameLanguage.EN : lang;
		}
		return lang === Language.TEST ? Language.EN : lang;
	}

	// This is for dev to help check things are being run through the translator
	private getAppendValue(translateKey:OutputString|OutputGameString){
		const options = store.getState().userInfo.options;
		if(utils.guards.isOutputGameString(translateKey)){
			return options.gameLang === GameLanguage.TEST ? `${GameLanguage.TEST}:` : "";
		}
		return options.lang === Language.TEST ? `${Language.TEST}:` : "";
	}

	// Translate
	t(translateKey:OutputString|OutputGameString, placeholders?:TranslationPlaceholders):string{
		const language = this.getLanguageToTranslateTo(translateKey);

		let englishText:string = "Translate Error";
		let translatedText:string|null = null;

		// As there are language cross-overs an else if will not work here, need to check if transaltedText is empty after gametranslations
		if(utils.guards.isEnumValue(GameLanguage, language)){
			if(utils.guards.isOutputGameString(translateKey)){
				englishText = gameTranslations[translateKey][GameLanguage.EN];
				translatedText = gameTranslations[translateKey][language];
			}
		}

		if(utils.guards.isEnumValue(Language, language) && translatedText === null){
			if(utils.guards.isOutputString(translateKey)){
				englishText = siteTranslations[translateKey][Language.EN];
				translatedText = siteTranslations[translateKey][language];
			}
		}

		/* Helps to check strings are being translated while in dev */
		let append = this.getAppendValue(translateKey);
		/* ******************************************************** */

		if(translatedText === null){
			if(appEnv.type === EnvironmentType.LOCAL && englishText !== "Translate Error"){
				log.missingTranslation(language, englishText);
			}
			append = append === "" ? append : "ER:";
		}

		let outputText = this.translate(englishText, translatedText, placeholders);

		if(outputText === "Translate Error"){
			if(appEnv.type === EnvironmentType.LOCAL){
				log.missingTranslation(Language.EN, translateKey);
			}
			outputText = translateKey;
		}
		return append + outputText;
	}

	// Translate & Replace placeholders
	private translate(englishText:string, translatedText:string|null, placeholders?:TranslationPlaceholders):string{
		const outputText = translatedText === null ? englishText : translatedText;
		if(!placeholders){ return outputText; }

		return outputText.replace(/%\w+%/g, (match) => {
			const key = match.substring(1, match.length - 1);
			let value = placeholders[key];

			if(typeof value === "undefined"){
				value = match;
				log.missingPlaceholder(key);
			}

			if(this.shouldTranslatePlaceholder(key, value)){
				return this.t(value, placeholders);
			}
			return value;
		});
	}

	private shouldTranslatePlaceholder(placeholderKey:string, placeholderValue:string): placeholderValue is OutputString|OutputGameString{
		const exceptions = ["USERNAME", "CHARNAME", "COUNT", "STARTENTRY", "ENDENTRY", "TOTALENTRIES"];

		if(exceptions.includes(placeholderKey)){
			return false;
		}
		return true;
	}
}
const t = new Translator();
export default t;
