//#region Imports
import sysMsg from "managers/sysMsg";
import { Component, SyntheticEvent } from "react";
import { connect } from "react-redux";
import t from "translations/translator";
import { AppState, SystemMessagesProps } from "types";
import "./SystemMessages.css";
//#endregion Imports

const mapStateToProps = (state:AppState) => {
	return {
		lang: state.userInfo.options.lang,
		gameLang: state.userInfo.options.gameLang,
		systemMessages: state.systemMessages,
	};
};

class SystemMessages extends Component<SystemMessagesProps> {
	render(){
		return (
			<div id="sys-msg-container">
				{this.props.systemMessages.map((sysmsg) => {
					const title = sysmsg.title ? <h3>{t.t(sysmsg.title, sysmsg.titlePlaceholders)}</h3> : null;
					const message = sysmsg.message ? <p>{t.t(sysmsg.message, sysmsg.messagePlaceholders)}</p> : null;
					this.timeoutMsg(sysmsg.id, sysmsg.timeout);
					return (
						<div key={`sysmsg_${sysmsg.id}`}className={`sys-message ${sysmsg.type}`} onClick={this.dismissMessage} data-id={sysmsg.id}>
							{title}{message}
						</div>
					);
				})}
			</div>
		);
	}

	dismissMessage = (evt:SyntheticEvent) => {
		const ele = evt.currentTarget;
		const id = ele.getAttribute("data-id");
		if(!id){ return; }
		sysMsg.dismiss(parseInt(id));
	}

	timeoutMsg = (id:number, timeoutInMS:number) => {
		if(timeoutInMS === 0){ return; }
		setTimeout(() => { sysMsg.dismiss(id); }, timeoutInMS);
	}
}
export default connect(mapStateToProps)(SystemMessages);
