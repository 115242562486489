//#region Imports
import { Component } from "react";
import t from "translations/translator";
import { FormFreeTextProps } from "types";
import utils from "utils";
import { FormFieldWrapper } from "./FormFieldWrapper";
import { FormInputWrapper } from "./FormInputWrapper";
import { FormLabel } from "./FormLabel";
//#endregion Imports


export class FormFreeText extends Component<FormFreeTextProps> {
	render(){
		const label = this.props.label;
		const freeText = this.props.input;
		const dataAttrs = this.props.input.dataAttributes ? utils.helpers.convertToDataAttributes(this.props.input.dataAttributes) : null;
		const freeTextClass = freeText.class ? freeText.class : [];

		freeTextClass.push("field-free-text");

		return (
			<FormFieldWrapper label={label} minimalist={false} width={this.props.fieldWidth}>
				<FormLabel {...this.props.label} />
				<FormInputWrapper>
					<p
						className={freeTextClass.join(" ")}
						{...dataAttrs}
					>
						{freeText.translate === false ? freeText.text : t.t(freeText.text, freeText.placeholders)}
					</p>
				</FormInputWrapper>
			</FormFieldWrapper>
		);
	}
}
