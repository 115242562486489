//#region Imports
import FormComponents from "components/forms/FormComponents";
import Link from "components/link/Link";
import { LinkType } from "enums";
import manager from "managers/app";
import { Component } from "react";
import { connect } from "react-redux";
import t from "translations/translator";
import { AppState, RelicRequirementsProps } from "types";
import "./RelicRequirements.css";
//#endregion Imports

const mapStateToProps = (state:AppState) => {
	return {
		lang: state.userInfo.options.lang,
		gameLang: state.userInfo.options.gameLang,
	};
};

class RelicRequirements extends Component<RelicRequirementsProps> {
	render(){
		const requirements = this.props.requirements;
		return (
			<div id="relic-requirements">
				<h2>{t.t("Requirements")}</h2>
				{requirements.length === 0
					? <p className="no-requirements">{t.t("No additional requirements")}</p>
					: <ul>
						{
							requirements.map((requirement, index) => {
								let child = null;
								switch(requirement.type){
									default: child = <Link type={LinkType.LODESTONE} lodestoneType={requirement.type} text={requirement.text} lodestoneID={requirement.lodestoneID} />; break;
									case "text":
										child = <p>{t.t(requirement.text, requirement.placeholders)}</p>;
										break;
									case "previous part":
										child = <FormComponents.Button text={requirement.part} onClick={() => { manager.view.change(requirement.partView); }} translate={true} asLink={true} />;
										break;
								}

								return <li key={`requirement-${index}`}>{child}</li>;
							})
						}
					</ul>
				}
			</div>
		);
	}
}
export default connect(mapStateToProps)(RelicRequirements);
