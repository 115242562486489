/* eslint-disable max-len */
//#region Imports
import { faSync } from "@fortawesome/free-solid-svg-icons";
import { jobLists } from "character";
import FormComponents from "components/forms/FormComponents";
import JobIcon from "components/job-icon/JobIcon";
import Link from "components/link/Link";
import NoCharacter from "components/no-character/NoCharacter";
import RelicCheckbox from "components/relic-checkbox/RelicCheckbox";
import { dungeons } from "data-files/duties";
import { items } from "data-files/items";
import { quests } from "data-files/quests";
import { Item, Job, LinkType, NPC, Quest, RelicGroup, RelicPart, RelicStep, RelicTask, RelicType, View } from "enums";
import manager from "managers/app";
import { Component } from "react";
import { connect } from "react-redux";
import t from "translations/translator";
import { AppState, Character, Requirement, TaskInfo, ViewRelicInfo, ViewWeaponZodiacZodiacBravesProps } from "types";
import utils from "utils";
import ContentHeader from "views/page-parts/content/content-header/ContentHeader";
import DetailsSection from "views/page-parts/content/details-section/DetailsSection";
import Obtained from "views/page-parts/content/obtained/Obtained";
import OverviewTable from "views/page-parts/content/overview-table/OverviewTable";
import "./WeaponZodiacZodiacBraves.css";
//#endregion Imports

const mapStateToProps = (state:AppState) => {
	return {
		lang: state.userInfo.options.lang,
		gameLang: state.userInfo.options.gameLang,
		userInfo: state.userInfo,
		options: state.userInfo.options,
		hideCompletedSteps: state.userInfo.options.hideCompletedSteps,
		hideCompletedTasks: state.userInfo.options.hideCompletedTasks,
		showJob: state.userInfo.options.job,
		zodiacBravesStepJobs: state.userInfo.options.relics.zodiacBravesStepJobs,
	};
};

class WeaponZodiacZodiacBraves extends Component<ViewWeaponZodiacZodiacBravesProps> {
	private requirements:Requirement[] = [];
	private importantNPCs:NPC[] = [];
	private relicInfo:ViewRelicInfo = {
		jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC],
		type: RelicType.WEAPON,
		relic: RelicGroup.WEAPON_ZODIAC,
		part: RelicPart.WEAPON_BRAVES,
		steps: [RelicStep.WHEREFORE, RelicStep.SKYLIGHT, RelicStep.ZODIUM, RelicStep.FLAWLESS_ALEXANDRITE, RelicStep.BRAVES_SCROLL, RelicStep.MATERIA],
		stepPlaceholders: {
			[RelicStep.WHEREFORE]: { QUEST: Quest.WHEREFORE_ART_THOU_ZODIAC },
			[RelicStep.SKYLIGHT]: { ITEM: "Book of Skylight" },
			[RelicStep.ZODIUM]: { ITEM: "Zodium" },
			[RelicStep.FLAWLESS_ALEXANDRITE]: { ITEM: "Flawless Alexandrite" },
			[RelicStep.BRAVES_SCROLL]: { ITEM: "Zodiac Scroll" },
			[RelicStep.MATERIA]: { QUEST: Quest.HIS_DARK_MATERIA },
		},
		next: {
			part: RelicPart.WEAPON_ZETA,
			view: View.RELIC_WEAPONS_ZODIAC_ZODIAC_ZETA,
		},
		previous: {
			part: RelicPart.WEAPON_NEXUS,
			view: View.RELIC_WEAPONS_ZODIAC_NEXUS,
		},
	};

	constructor(props:ViewWeaponZodiacZodiacBravesProps){
		super(props);
		this.requirements = this.getRequirements();
		this.importantNPCs = this.getImportantNPCs();
	}

	// eslint-disable-next-line complexity
	render(){
		const character = manager.data.getActiveCharacter();
		if(character === null){ return <NoCharacter />; }

		const completeSteps = manager.content.getCompleteSteps(character.progress, this.relicInfo);

		return (
			<div id="weapon-zodiac-zodiac-braves" className="relic">
				<ContentHeader requirements={this.requirements} importantNPCs={this.importantNPCs} relicInfo={this.relicInfo}>
					<p>{t.t("The %PART% upgrade follows on from %PREVIOUSPART%", { PART: this.relicInfo.part, PREVIOUSPART: this.relicInfo.previous ? this.relicInfo.previous.part : "Unknown"})}</p>
				</ContentHeader>
				<div id="relic-checklist">
					<OverviewTable character={character} completeSteps={completeSteps} relicInfo={this.relicInfo} />
				</div>
				<div id="relic-details">
					<h1>{t.t("Details")}</h1>
					{this.props.hideCompletedSteps && completeSteps.allComplete ? t.t("All Steps Complete") : null }
					<DetailsSection title={RelicStep.WHEREFORE} placeholders={this.relicInfo.stepPlaceholders[RelicStep.WHEREFORE]} id={RelicStep.WHEREFORE} stepComplete={completeSteps[RelicStep.WHEREFORE]}><p>{t.t("Complete")}<Link type={LinkType.LODESTONE} lodestoneType={quests[Quest.WHEREFORE_ART_THOU_ZODIAC].type} text={quests[Quest.WHEREFORE_ART_THOU_ZODIAC].name} lodestoneID={quests[Quest.WHEREFORE_ART_THOU_ZODIAC].lodestoneID} /></p></DetailsSection>
					{
						this.relicInfo.steps.map((step, index) => {
							if(index === 0 || index === 5){ return null; } // Skip First and Last steps

							const thisJob = this.props.zodiacBravesStepJobs[index - 1];
							const taskInfo = this.getTasksInfo(step, character, thisJob);

							let stepQuest = Quest.UNKNOWN;

							switch(index){
								default: stepQuest = Quest.UNKNOWN; break;
								case 1: stepQuest = Quest.PONZE_OF_FLESH; break;
								case 2: stepQuest = Quest.LABOR_OF_LOVE; break;
								case 3: stepQuest = Quest.TREASURED_MOTHER; break;
								case 4: stepQuest = Quest.METHOD_IN_HIS_MALACE; break;
							}

							return <DetailsSection title={step} id={step} placeholders={this.relicInfo.stepPlaceholders[step]} stepComplete={completeSteps[step]} key={step}>
								<div>
									<p>{t.t("Complete")}<Link type={LinkType.LODESTONE} lodestoneType={quests[stepQuest].type} text={quests[stepQuest].name} lodestoneID={quests[stepQuest].lodestoneID} /></p>
								</div>
								<div className="job-select">
									<FormComponents.Label
										hide={false}
										translate={true}
										text="Select job to track"
										position="left"
										alignment="center"
									/>
									{this.relicInfo.jobs.map((job) => {
										if(this.props.showJob !== null && this.props.showJob !== job){ return null; }
										const weaponName = manager.relics.getRelicNameKey(job, this.relicInfo.part);
										return <JobIcon key={`${index}_${job}`} job={job} tooltip={weaponName} selected={job === thisJob} onClick={() => { this.selectJob(job, index - 1); }} />;
									})}
									<FormComponents.Button
										icon={faSync}
										onClick={() => { this.syncJobs(index - 1); }}
									/>
								</div>
								<div className="table-wrapper">
									<table className="table">
										<thead><tr><th>{t.t("Type")}</th><th>{t.t("Target")}</th><th>{t.t("Quantity")}</th><th>{t.t("Done")}</th></tr></thead>
										<tbody>
											{
												taskInfo.map((thisTask) => {
													const thisComplete = character.progress.isComplete(this.relicInfo.type, this.relicInfo.relic, this.relicInfo.part, step, thisTask.task, thisJob);
													if((this.props.hideCompletedSteps || this.props.hideCompletedTasks) && thisComplete){ return null; }

													const target = items[thisTask.target as Item];
													let targetText = t.t(target.name);

													const inventoryStatus = manager.data.getInventoryStatus({
														item: thisTask.target as Item,
														relic: [this.relicInfo.type, this.relicInfo.relic, this.relicInfo.part, step, thisTask.task],
													});

													if(target.obtained.duties && target.obtained.duties.dungeons){
														const dungeon = target.obtained.duties.dungeons[0];
														targetText = `${targetText} (${t.t(dungeons[dungeon].name)})`;
													}

													return <tr key={`${step}-${thisTask.task}`}>
														<td><Obtained obtained={target.obtained} qtyNeeded={inventoryStatus.total} /></td>
														<td>{targetText}</td>
														<td>{inventoryStatus.qtyPerJob.toLocaleString()}</td>
														<td><RelicCheckbox character={character} job={thisJob} relicInfo={this.relicInfo} step={step} task={thisTask.task} /></td>
													</tr>;
												})
											}
											{
												character.progress.isComplete(this.relicInfo.type, this.relicInfo.relic, this.relicInfo.part, step)
													? <tr className="all-complete"><td colSpan={4}><p>{t.t("All Complete")}</p></td></tr>
													: null
											}
										</tbody>
									</table>
								</div>
							</DetailsSection>;
						})
					}
					<DetailsSection title={RelicStep.MATERIA} placeholders={this.relicInfo.stepPlaceholders[RelicStep.MATERIA]} id={RelicStep.MATERIA} stepComplete={completeSteps[RelicStep.MATERIA]}><p>{t.t("Complete")}<Link type={LinkType.LODESTONE} lodestoneType={quests[Quest.HIS_DARK_MATERIA].type} text={quests[Quest.HIS_DARK_MATERIA].name} lodestoneID={quests[Quest.HIS_DARK_MATERIA].lodestoneID} /></p></DetailsSection>
				</div>
			</div>
		);
	}

	componentDidMount(){
		manager.view.changeComplete();
	}

	private getRequirements():Requirement[]{
		if(!this.relicInfo || this.relicInfo.previous === null){ return []; }
		return [
			{ type: "previous part", part: this.relicInfo.previous.part, partView: this.relicInfo.previous.view },
		];
	}

	private getImportantNPCs():NPC[]{
		return [NPC.JALZAHN, NPC.PAPANA, NPC.GUIDING_STAR, NPC.BRANGWINE, NPC.ADKIN, NPC.GEROLT_NORTH_SHROUD];
	}

	private getTasksInfo(step:RelicStep, character:Character, job:Job):TaskInfo[]{
		const tasksInfo:TaskInfo[] = [];
		const tasks = [RelicTask.TASK_1, RelicTask.TASK_2, RelicTask.TASK_3, RelicTask.TASK_4, RelicTask.TASK_5, RelicTask.TASK_6, RelicTask.TASK_7, RelicTask.TASK_8, RelicTask.TASK_9];

		tasks.forEach((task) => {
			const target = manager.relics.getTaskItem(step, task, job);
			const complete = character.progress.isComplete(this.relicInfo.type, this.relicInfo.relic, this.relicInfo.part, step, task, job);
			tasksInfo.push({ task: task, complete: complete, target: target });
		});
		return tasksInfo;
	}

	private selectJob(job:Job, stepJobsIndex:number){ // Event
		const options = this.props.options;
		const newStepJobs:Job[] = [...this.props.zodiacBravesStepJobs];

		if(utils.guards.isEnumValue(Job, job)){
			newStepJobs[stepJobsIndex] = job;
		}else{
			newStepJobs[stepJobsIndex] = Job.PLD;
		}

		options.relics = {
			...options.relics,
			zodiacBravesStepJobs: newStepJobs,
		};
		manager.data.saveOptions(options, true);
	}

	private syncJobs(stepJobsIndex:number){
		const stepJobs:Job[] = [...this.props.zodiacBravesStepJobs];
		const thisJob = this.props.zodiacBravesStepJobs[stepJobsIndex];
		const options = this.props.options;

		stepJobs.forEach((job, i) => {
			stepJobs[i] = thisJob;
		});

		options.relics = {
			...options.relics,
			zodiacBravesStepJobs: stepJobs,
		};
		manager.data.saveOptions(options, true);
	}
}
export default connect(mapStateToProps)(WeaponZodiacZodiacBraves);
