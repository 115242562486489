/* eslint-disable max-len */
//#region Imports
import { jobLists } from "character";
import FormComponents from "components/forms/FormComponents";
import JobIcon from "components/job-icon/JobIcon";
import Link from "components/link/Link";
import NoCharacter from "components/no-character/NoCharacter";
import RelicCheckbox from "components/relic-checkbox/RelicCheckbox";
import Tip from "components/tip/Tip";
import { items } from "data-files/items";
import { quests } from "data-files/quests";
import { Dungeon, Item, Job, LinkType, NPC, Quest, Raid, RelicGroup, RelicPart, RelicStep, RelicTask, RelicType, Trial, View } from "enums";
import manager from "managers/app";
import { Component } from "react";
import { connect } from "react-redux";
import t from "translations/translator";
import { AppState, Character, Requirement, TaskInfo, ViewRelicInfo, ViewWeaponZodiacZodiacZetaProps } from "types";
import utils from "utils";
import ContentHeader from "views/page-parts/content/content-header/ContentHeader";
import DetailsSection from "views/page-parts/content/details-section/DetailsSection";
import Obtained from "views/page-parts/content/obtained/Obtained";
import OverviewTable from "views/page-parts/content/overview-table/OverviewTable";
import "./WeaponZodiacZodiacZeta.css";
//#endregion Imports

const mapStateToProps = (state:AppState) => {
	return {
		lang: state.userInfo.options.lang,
		gameLang: state.userInfo.options.gameLang,
		userInfo: state.userInfo,
		options: state.userInfo.options,
		hideCompletedSteps: state.userInfo.options.hideCompletedSteps,
		hideCompletedTasks: state.userInfo.options.hideCompletedTasks,
		showJob: state.userInfo.options.job,
		zodiacZetaStepJob: state.userInfo.options.relics.zodiacZetaStepJob,
	};
};

class WeaponZodiacZodiacZeta extends Component<ViewWeaponZodiacZodiacZetaProps> {
	private requirements:Requirement[] = [];
	private importantNPCs:NPC[] = [];
	private relicInfo:ViewRelicInfo = {
		jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC],
		type: RelicType.WEAPON,
		relic: RelicGroup.WEAPON_ZODIAC,
		part: RelicPart.WEAPON_ZETA,
		steps: [RelicStep.SHINE, RelicStep.SOUL, RelicStep.VITAL],
		stepPlaceholders: {
			[RelicStep.SHINE]: { QUEST: Quest.RISE_AND_SHINE },
			[RelicStep.SOUL]: { ITEM: "Mahatma" },
			[RelicStep.VITAL]: { QUEST: Quest.VITAL_TITLE },
		},
		next: null,
		previous: {
			part: RelicPart.WEAPON_BRAVES,
			view: View.RELIC_WEAPONS_ZODIAC_ZODIAC_BRAVES,
		},
	};

	constructor(props:ViewWeaponZodiacZodiacZetaProps){
		super(props);
		this.requirements = this.getRequirements();
		this.importantNPCs = this.getImportantNPCs();
	}

	// eslint-disable-next-line complexity
	render(){
		const character = manager.data.getActiveCharacter();
		if(character === null){ return <NoCharacter />; }

		const completeSteps = manager.content.getCompleteSteps(character.progress, this.relicInfo);
		const stepJob = this.props.zodiacZetaStepJob;
		const taskInfo = this.getTasksInfo(RelicStep.SOUL, character, stepJob);

		return (
			<div id="weapon-zodiac-zodiac-zeta" className="relic">
				<ContentHeader requirements={this.requirements} importantNPCs={this.importantNPCs} relicInfo={this.relicInfo}>
					<p>{t.t("The %PART% upgrade follows on from %PREVIOUSPART%", { PART: this.relicInfo.part, PREVIOUSPART: this.relicInfo.previous ? this.relicInfo.previous.part : "Unknown"})}</p>
					<p>{t.t("The Final step of the Zodiac Relic weapon - Achieve Full Soul Resonance!")}</p>
				</ContentHeader>
				<div id="relic-checklist">
					<OverviewTable character={character} completeSteps={completeSteps} relicInfo={this.relicInfo} />
				</div>
				<div id="relic-details">
					<h1>{t.t("Details")}</h1>
					{this.props.hideCompletedSteps && completeSteps.allComplete ? t.t("All Steps Complete") : null }
					<DetailsSection title={RelicStep.SHINE} placeholders={this.relicInfo.stepPlaceholders[RelicStep.SHINE]} id={RelicStep.SHINE} stepComplete={completeSteps[RelicStep.SHINE]}><p>{t.t("Complete")}<Link type={LinkType.LODESTONE} lodestoneType={quests[Quest.RISE_AND_SHINE].type} text={quests[Quest.RISE_AND_SHINE].name} lodestoneID={quests[Quest.RISE_AND_SHINE].lodestoneID} /></p></DetailsSection>
					<DetailsSection title={RelicStep.SOUL} placeholders={this.relicInfo.stepPlaceholders[RelicStep.SOUL]} id={RelicStep.SOUL} stepComplete={completeSteps[RelicStep.SOUL]}>
						<p>{t.t("Speak to Remon to affix a Mahatama to your weapon - You must then gain 'Soul Resonance' from various ARR activities")}</p>
						<Tip text="Dungeons can be run unsynced, and you only need your relic equipped for the final boss" />
						<Tip text="%AURUM% and %SASTASHA% can fully resonate in 2 runs (1 if bonus is active)" placeholders={{ SASTASHA: Dungeon.SASTASHA, AURUM: Dungeon.AURUM }} />
						<div className="light-info">
							<div className="wrapper">
								<div className="table-wrapper">
									<table className="table">
										<thead><tr><th>{t.t("Stage")}</th><th>{t.t("Points")}</th></tr></thead>
										<tbody>
											<tr><td>{t.t("No Sense")}</td><td>0-3</td></tr>
											<tr><td>{t.t("Indisctinct Sense")}</td><td>4-7</td></tr>
											<tr><td>{t.t("Faint Sense")}</td><td>8-11</td></tr>
											<tr><td>{t.t("Slight Sense")}</td><td>12-15</td></tr>
											<tr><td>{t.t("Modest Sense")}</td><td>16-19</td></tr>
											<tr><td>{t.t("Distinct Sense")}</td><td>20-23</td></tr>
											<tr><td>{t.t("Robust Sense")}</td><td>24-27</td></tr>
											<tr><td>{t.t("Vigorous Sense")}</td><td>28-31</td></tr>
											<tr><td>{t.t("Intense Sense")}</td><td>32-35</td></tr>
											<tr><td>{t.t("Extreme Sense")}</td><td>36-39</td></tr>
											<tr><td>{t.t("Full Soul Resonance")}</td><td>40</td></tr>
										</tbody>
									</table>
								</div>
							</div>
							<div className="wrapper">
								<div className="table-wrapper">
									<table className="table">
										<thead><tr><th>{t.t("Level")}</th><th>{t.t("Points")}</th></tr></thead>
										<tbody>
											<tr><td>{t.t("Feeble")}</td><td>4</td></tr>
											<tr><td>{t.t("Faint")}</td><td>8</td></tr>
											<tr><td>{t.t("Gentle")}</td><td>16</td></tr>
											<tr><td>{t.t("Steady")}</td><td>24</td></tr>
											<tr><td>{t.t("Forceful")}</td><td>48</td></tr>
											<tr><td>{t.t("Nigh Sings")}</td><td>64</td></tr>
										</tbody>
									</table>
								</div>
							</div>
							<div className="wrapper">
								<div className="table-wrapper">
									<table className="table">
										<thead><tr><th>{t.t("Duty")}</th><th>{t.t("Level")}</th></tr></thead>
										<tbody>
											<tr><td>{t.t("FATEs")}</td><td>Feeble</td></tr>
											<tr><td>{t.t(Trial.IFRIT)}</td><td>Feeble</td></tr>
											<tr><td>{t.t(Trial.CHIMERA)}</td><td>Feeble</td></tr>
											<tr><td>{t.t("Primals EX")}</td><td>Faint</td></tr>
											<tr><td>{t.t("The Porta Decumana")}</td><td>Faint</td></tr>
											<tr><td>{t.t(Dungeon.BRAYFLOX_HARD)}</td><td>Gentle</td></tr>
											<tr><td>{t.t("Binding Coil of Bahamut")}</td><td>Gentle</td></tr>
											<tr><td>{t.t(Dungeon.SASTASHA)}</td><td>Steady</td></tr>
											<tr><td>{t.t(Dungeon.AURUM)}</td><td>Steady</td></tr>
											<tr><td>{t.t(Dungeon.MERIDIANUM)}</td><td>Steady</td></tr>
											<tr><td>{t.t(Dungeon.PRAETORIUM)}</td><td>Steady</td></tr>
											<tr><td>{t.t("The Borderland Ruins (Secure)")}</td><td>Steady</td></tr>
											<tr><td>{t.t(Raid.LABYRINTH_OF_ANCIENTS)}</td><td>Steady</td></tr>
											<tr><td>{t.t(Raid.SYRCUS_TOWER)}</td><td>Forceful</td></tr>
											<tr><td>{t.t(Raid.WORLD_OF_DARKNESS)}</td><td>Forceful</td></tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
						<div className="job-select">
							<FormComponents.Label
								hide={false}
								translate={true}
								text="Select job to track"
								position="left"
								alignment="center"
							/>
							{this.relicInfo.jobs.map((job) => {
								if(this.props.showJob !== null && this.props.showJob !== job){ return null; }
								const weaponName = manager.relics.getRelicNameKey(job, this.relicInfo.part);
								return <JobIcon key={`${job}`} job={job} tooltip={weaponName} selected={job === stepJob} onClick={() => { this.selectJob(job); }} />;
							})}
						</div>
						<div className="table-wrapper">
							<table className="table">
								<thead><tr><th>{t.t("Type")}</th><th>{t.t("Target")}</th><th>{t.t("Quantity")}</th><th>{t.t("Done")}</th></tr></thead>
								<tbody>
									{
										taskInfo.map((thisTask) => {
											const thisComplete = thisTask.complete;
											if((this.props.hideCompletedSteps || this.props.hideCompletedTasks) && thisComplete){ return null; }

											const inventoryStatus = manager.data.getInventoryStatus({
												item: thisTask.target as Item,
												relic: [this.relicInfo.type, this.relicInfo.relic, this.relicInfo.part, RelicStep.SOUL, thisTask.task],
											});

											return <tr key={`${RelicStep.SOUL}-${thisTask.task}`}>
												<td><Obtained obtained={items[Item.LIGHT].obtained} qtyNeeded={inventoryStatus.total} /></td>
												<td>{t.t(thisTask.target)}</td>
												<td>{inventoryStatus.qtyPerJob.toLocaleString()}</td>
												<td><RelicCheckbox character={character} job={stepJob} relicInfo={this.relicInfo} step={RelicStep.SOUL} task={thisTask.task} /></td>
											</tr>;
										})
									}
									{
										character.progress.isComplete(this.relicInfo.type, this.relicInfo.relic, this.relicInfo.part, RelicStep.SOUL)
											? <tr className="all-complete"><td colSpan={4}><p>{t.t("All Complete")}</p></td></tr>
											: null
									}
								</tbody>
							</table>
						</div>
					</DetailsSection>
					<DetailsSection title={RelicStep.VITAL} placeholders={this.relicInfo.stepPlaceholders[RelicStep.VITAL]} id={RelicStep.VITAL} stepComplete={completeSteps[RelicStep.VITAL]}><p>{t.t("Complete")}<Link type={LinkType.LODESTONE} lodestoneType={quests[Quest.VITAL_TITLE].type} text={quests[Quest.VITAL_TITLE].name} lodestoneID={quests[Quest.VITAL_TITLE].lodestoneID} /></p></DetailsSection>
				</div>
			</div>
		);
	}

	componentDidMount(){
		manager.view.changeComplete();
	}

	private getRequirements():Requirement[]{
		if(!this.relicInfo || this.relicInfo.previous === null){ return []; }
		return [
			{ type: "previous part", part: this.relicInfo.previous.part, partView: this.relicInfo.previous.view },
		];
	}

	private getImportantNPCs():NPC[]{
		return [NPC.JALZAHN, NPC.REMON];
	}

	private getTasksInfo(step:RelicStep, character:Character, job:Job):TaskInfo[]{
		return [
			{ task: RelicTask.TASK_1, complete: character.progress.isComplete(this.relicInfo.type, this.relicInfo.relic, this.relicInfo.part, step, RelicTask.TASK_1, job), target: manager.relics.getTaskItem(step, RelicTask.TASK_1, job) },
			{ task: RelicTask.TASK_2, complete: character.progress.isComplete(this.relicInfo.type, this.relicInfo.relic, this.relicInfo.part, step, RelicTask.TASK_2, job), target: manager.relics.getTaskItem(step, RelicTask.TASK_2, job) },
			{ task: RelicTask.TASK_3, complete: character.progress.isComplete(this.relicInfo.type, this.relicInfo.relic, this.relicInfo.part, step, RelicTask.TASK_3, job), target: manager.relics.getTaskItem(step, RelicTask.TASK_3, job) },
			{ task: RelicTask.TASK_4, complete: character.progress.isComplete(this.relicInfo.type, this.relicInfo.relic, this.relicInfo.part, step, RelicTask.TASK_4, job), target: manager.relics.getTaskItem(step, RelicTask.TASK_4, job) },
			{ task: RelicTask.TASK_5, complete: character.progress.isComplete(this.relicInfo.type, this.relicInfo.relic, this.relicInfo.part, step, RelicTask.TASK_5, job), target: manager.relics.getTaskItem(step, RelicTask.TASK_5, job) },
			{ task: RelicTask.TASK_6, complete: character.progress.isComplete(this.relicInfo.type, this.relicInfo.relic, this.relicInfo.part, step, RelicTask.TASK_6, job), target: manager.relics.getTaskItem(step, RelicTask.TASK_6, job) },
			{ task: RelicTask.TASK_7, complete: character.progress.isComplete(this.relicInfo.type, this.relicInfo.relic, this.relicInfo.part, step, RelicTask.TASK_7, job), target: manager.relics.getTaskItem(step, RelicTask.TASK_7, job) },
			{ task: RelicTask.TASK_8, complete: character.progress.isComplete(this.relicInfo.type, this.relicInfo.relic, this.relicInfo.part, step, RelicTask.TASK_8, job), target: manager.relics.getTaskItem(step, RelicTask.TASK_8, job) },
			{ task: RelicTask.TASK_9, complete: character.progress.isComplete(this.relicInfo.type, this.relicInfo.relic, this.relicInfo.part, step, RelicTask.TASK_9, job), target: manager.relics.getTaskItem(step, RelicTask.TASK_9, job) },
			{ task: RelicTask.TASK_10, complete: character.progress.isComplete(this.relicInfo.type, this.relicInfo.relic, this.relicInfo.part, step, RelicTask.TASK_10, job), target: manager.relics.getTaskItem(step, RelicTask.TASK_10, job) },
			{ task: RelicTask.TASK_11, complete: character.progress.isComplete(this.relicInfo.type, this.relicInfo.relic, this.relicInfo.part, step, RelicTask.TASK_11, job), target: manager.relics.getTaskItem(step, RelicTask.TASK_11, job) },
			{ task: RelicTask.TASK_12, complete: character.progress.isComplete(this.relicInfo.type, this.relicInfo.relic, this.relicInfo.part, step, RelicTask.TASK_12, job), target: manager.relics.getTaskItem(step, RelicTask.TASK_12, job) },
		];
	}

	private selectJob(job:Job){ // Event
		const options = this.props.options;
		options.relics = {
			...options.relics,
			zodiacZetaStepJob: utils.guards.isEnumValue(Job, job) ? job : Job.PLD,
		};
		manager.data.saveOptions(options, true);
	}
}
export default connect(mapStateToProps)(WeaponZodiacZodiacZeta);
