//#region Imports
import FormComponents from "components/forms/FormComponents";
import { Icon, Modal } from "enums";
import manager from "managers/app";
import ModalContent from "modals/content/ModalContent";
import ModalFooter from "modals/footer/ModalFooter";
import ModalHeader from "modals/header/ModalHeader";
import { Component } from "react";
import { connect } from "react-redux";
import { AppState, ExtractionsModalProps } from "types";
import "./ExtractionsModal.css";
//#endregion Imports

const mapStateToProps = (state:AppState) => {
	return {
		lang: state.userInfo.options.lang,
		gameLang: state.userInfo.options.gameLang,
		extractions: state.modal[Modal.EXTRACTIONS],
	};
};

class ExtractionsModal extends Component<ExtractionsModalProps> {
	render(){
		return (
			<div id={Modal.EXTRACTIONS} className="modal-wrapper">
				<ModalHeader text="Materia Extraction" icon={Icon.EXTRACTION} />
				<ModalContent>
					<div>
						{/* Placeheolder Modal - Currently this is unusued as extraction can only be true or undefined */}
					</div>
				</ModalContent>
				<ModalFooter>
					<FormComponents.Button text="Close" onClick={manager.modal.close} translate={true} />
				</ModalFooter>
			</div>
		);
	}
}
export default connect(mapStateToProps)(ExtractionsModal);
