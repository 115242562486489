//#region Imports
import FormComponents from "components/forms/FormComponents";
import Link from "components/link/Link";
import { raids } from "data-files/duties";
import { quests } from "data-files/quests";
import { Icon, LinkType, Modal } from "enums";
import manager from "managers/app";
import ModalContent from "modals/content/ModalContent";
import ModalFooter from "modals/footer/ModalFooter";
import ModalHeader from "modals/header/ModalHeader";
import { Component } from "react";
import { connect } from "react-redux";
import t from "translations/translator";
import { AppState, RaidsModalProps } from "types";
import "./RaidsModal.css";
//#endregion Imports

const mapStateToProps = (state:AppState) => {
	return {
		lang: state.userInfo.options.lang,
		gameLang: state.userInfo.options.gameLang,
		raids: state.modal[Modal.RAIDS],
	};
};

class RaidsModal extends Component<RaidsModalProps> {
	render(){
		return (
			<div id={Modal.RAIDS} className="modal-wrapper">
				<ModalHeader text="Raids" icon={Icon.RAID} />
				<ModalContent>
					<div className="raid-wrapper">
						{this.props.raids.map((raid) => {
							const thisRaid = raids[raid];
							const unlockQuest = quests[thisRaid.unlocked];

							return <div key={thisRaid.name} className="raid-card">
								<h3 className="raid-name"><Link type={LinkType.LODESTONE} lodestoneType={thisRaid.type} lodestoneID={thisRaid.lodestoneID} text={thisRaid.name} /></h3>
								<p className="raid-unlocked">
									<span>{t.t("Quest to unlock")}</span><Link type={LinkType.LODESTONE} lodestoneType={unlockQuest.type} lodestoneID={unlockQuest.lodestoneID} text={unlockQuest.name} />
								</p>
							</div>;
						})}
					</div>
				</ModalContent>
				<ModalFooter>
					<FormComponents.Button text="Close" onClick={manager.modal.close} translate={true} />
				</ModalFooter>
			</div>
		);
	}
}
export default connect(mapStateToProps)(RaidsModal);
