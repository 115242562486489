//#region Imports
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Icon } from "enums";
import manager from "managers/app";
import { Component } from "react";
import { connect } from "react-redux";
import t from "translations/translator";
import { AppState, BasicIconProps } from "types";
import utils from "utils";
import "./BasicIcon.css";
//#endregion Imports

const mapStateToProps = (state:AppState) => {
	return {
		lang: state.userInfo.options.lang,
		gameLang: state.userInfo.options.gameLang,
	};
};

class BasicIcon extends Component<BasicIconProps> {
	render(){
		const imgClass = ["rt-icon", ...this.props.class ? this.props.class : []];

		if(!utils.guards.isEnumValue(Icon, this.props.icon)){
			return <FontAwesomeIcon icon={this.props.icon} className={imgClass.join(" ")} />;
		}

		return <img
			className={imgClass.join(" ")}
			src={manager.icons.getIcon(this.props.icon)}
			title={this.props.tooltip ? t.t(this.props.tooltip, this.props.placeholders) : undefined} // eslint-disable-line no-undefined
		/>;
	}
}
export default connect(mapStateToProps)(BasicIcon);
