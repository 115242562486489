//#region Imports
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AccountStatus from "components/account-status/AccountStatus";
import CharacterSelector from "components/character-selector/CharacterSelector";
import { Component } from "react";
import { connect } from "react-redux";
import { AppState, ViewSiteHeaderProps } from "types";
import "./SiteHeader.css";
//#endregion Imports

const mapStateToProps = (state:AppState) => {
	return {
		appVersion: state.appVersion,
		lang: state.userInfo.options.lang,
		gameLang: state.userInfo.options.gameLang,
		breakpoint: state.breakpoint,
	};
};

class SiteHeader extends Component<ViewSiteHeaderProps> {
	render(){
		return (
			<header id="site-header">
				<div className="left">
					<div className="menu-toggler" onClick={this.toggleMenu}><FontAwesomeIcon icon={faBars} /></div>
					<div className="title">
						<h1>FFXIV Relic Tracker {this.props.appVersion.toString()}</h1>
					</div>
				</div>
				{this.props.breakpoint === "desktop"
					? <div className="right">
						<CharacterSelector />
						<AccountStatus />
					</div>
					: null
				}
			</header>
		);
	}

	toggleMenu = () => { // Event
		const menu = document.getElementById("menu");
		if(menu){ menu.classList.toggle("hide"); }
	}
}
export default connect(mapStateToProps)(SiteHeader);
