//#region Imports
import { InputType } from "enums";
import { Component, SyntheticEvent } from "react";
import t from "translations/translator";
import { FormTextProps, FormTextState } from "types";
import utils from "utils";
import { FieldEdit } from "./FieldEdit";
import { FormFieldWrapper } from "./FormFieldWrapper";
import { FormInputWrapper } from "./FormInputWrapper";
import { FormLabel } from "./FormLabel";
//#endregion Imports

export class FormText extends Component<FormTextProps> {
	state:FormTextState = { edit: true, overrideDisabled: false, inputValue: "" }
	render(){
		const label = this.props.label;
		const input = this.props.input;
		const hidden = this.props.hidden;
		const dataAttrs = this.props.input.dataAttributes ? utils.helpers.convertToDataAttributes(this.props.input.dataAttributes) : null;
		const inputClass = input.class ? input.class : [];

		if(this.state.overrideDisabled){ input.disabled = this.state.edit; }

		let min = 0;
		let max = 999999;

		if(input.type === InputType.NUMBER){
			min = input.min ? input.min : 0;
			max = input.max ? input.max : 999999;
		}

		inputClass.push("field-input");
		if(hidden && this.state.inputValue !== hidden.value){ inputClass.push("hasChanged"); }

		return (
			<FormFieldWrapper label={label} minimalist={input.minimalist} width={this.props.fieldWidth}>
				<FormLabel {...this.props.label} />
				<FormInputWrapper>
					<input
						type={input.type}
						className={inputClass.join(" ")}
						name={input.name}
						placeholder={input.placeholder ? t.t(input.placeholder) : undefined } // eslint-disable-line no-undefined
						autoComplete={input.autoComplete}
						value={this.state.inputValue}
						disabled={input.disabled}
						required={input.required}
						onChange={this.doChangeEvents}
						min={min}
						max={max}
						{...dataAttrs}
					/>
					{ hidden && <input
						type="hidden"
						name={hidden.name}
						value={hidden.value}
					/> }
					{input.showEditButton && <FieldEdit edit={this.state.edit} action={this.editClicked} /> }
				</FormInputWrapper>
			</FormFieldWrapper>
		);
	}

	componentDidMount(){
		this.setState((old:FormTextState):FormTextState => { return { ...old, inputValue: this.props.input.value }; });
	}

	doChangeEvents = (evt:SyntheticEvent) => {
		this.checkModified(evt);

		const inputEle = evt.currentTarget as HTMLInputElement;
		this.setState((old:FormTextState):FormTextState => { return { ...old, inputValue: inputEle.value }; });

		const input = this.props.input;
		if(input.events && input.events.onChange){
			input.events.onChange(evt);
		}
	}

	checkModified = (evt:SyntheticEvent) => {
		const input = evt.currentTarget as HTMLInputElement;
		const hiddenInput = input.nextElementSibling as HTMLInputElement | null;
		if(!hiddenInput){ return; }

		if(input.value === hiddenInput.value){
			input.classList.remove("hasChanged");
		}else{
			input.classList.add("hasChanged");
		}
	}

	editClicked = () => { // Event
		const editable = !this.state.edit;
		this.setState((old:FormTextState):FormTextState => { return { ...old, edit: editable, overrideDisabled: true }; });
	}
}
