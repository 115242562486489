//#region Imports
import BasicIcon from "components/basic-icon/BasicIcon";
import FormComponents from "components/forms/FormComponents";
import { locations } from "data-files/locations";
import { Icon, Modal } from "enums";
import manager from "managers/app";
import ModalContent from "modals/content/ModalContent";
import ModalFooter from "modals/footer/ModalFooter";
import ModalHeader from "modals/header/ModalHeader";
import { Component } from "react";
import { connect } from "react-redux";
import t from "translations/translator";
import { AppState, GatheringModalProps } from "types";
import "./GatheringModal.css";
//#endregion Imports

const mapStateToProps = (state:AppState) => {
	return {
		lang: state.userInfo.options.lang,
		gameLang: state.userInfo.options.gameLang,
		gathering: state.modal[Modal.GATHERING],
	};
};

class GatheringModal extends Component<GatheringModalProps> {
	render(){
		return (
			<div id={Modal.GATHERING} className="modal-wrapper">
				<ModalHeader text="Gathering" icon={Icon.GATHERING} />
				<ModalContent>
					<div className="gathering-wrapper">
						{this.props.gathering.map((obtainedGathering) => {
							const obtainedLocation = obtainedGathering.found;
							const gatherLocation = locations[obtainedLocation.location];
							const isCollectable = obtainedGathering.collectable ? obtainedGathering.collectable : false;

							return <div key={obtainedLocation.location} className="gathering-card">
								<h3 className="gathering-name">{t.t(obtainedGathering.method)}</h3>
								{isCollectable ? <p className="gathering-collectable">{t.t("Collectable")}</p> : null}
								<div className="gathering-location">
									<span><BasicIcon icon={Icon.MAP} tooltip="Map" /> {t.t(gatherLocation.map)}</span>
									<span><BasicIcon icon={Icon.REGION} tooltip="Region" /> {gatherLocation.region ? t.t(gatherLocation.region) : t.t("Unknown")}</span>
									<span><BasicIcon icon={Icon.TELEPORT} tooltip="Teleport" /> {gatherLocation.teleport ? t.t(gatherLocation.teleport) : t.t("Unknown")}</span>
									<span><BasicIcon icon={Icon.LOCATION} tooltip="Co-ordinates" /> X: {obtainedLocation.x}, Y: {obtainedLocation.y}</span>
								</div>
							</div>;
						})}
					</div>
				</ModalContent>
				<ModalFooter>
					<FormComponents.Button text="Close" onClick={manager.modal.close} translate={true} />
				</ModalFooter>
			</div>
		);
	}
}
export default connect(mapStateToProps)(GatheringModal);
