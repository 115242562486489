//#region Imports
import FormComponents from "components/forms/FormComponents";
import { Breakpoint, EnvironmentType, GameLanguage, Language } from "enums";
import manager from "managers/app";
import { appEnv } from "managers/env";
import { Component } from "react";
import { connect } from "react-redux";
import { MultiValue, SingleValue } from "react-select";
import t from "translations/translator";
import { AppState, LanguageSwitcherProps, RSOption } from "types";
import "./LanguageSwitcher.css";
//#endregion Imports

const mapStateToProps = (state:AppState) => {
	return {
		lang: state.userInfo.options.lang,
		gameLang: state.userInfo.options.gameLang,
		breakpoint: state.breakpoint,
		options: state.userInfo.options,
		isAuthenticated: state.userInfo.isAuthenticated,
	};
};

class LanguageSwitcher extends Component<LanguageSwitcherProps> {
	render(){
		let labelPosition = this.props.labelPosition;

		if(!labelPosition){
			labelPosition = this.props.breakpoint === Breakpoint.TABLET || this.props.breakpoint === Breakpoint.MOBILE ? "left" : "top";
		}

		const languages = Object.values(Language);
		const gameLanguages = Object.values(GameLanguage);

		const selectedLanguage:RSOption[] = [];
		const selectedGameLanguage:RSOption[] = [];

		const languageOptions:RSOption[] = [];
		const gameLanguageOptions:RSOption[] = [];

		languages.forEach((language:Language) => {
			if(appEnv.type !== EnvironmentType.LOCAL && language === Language.TEST){ return; } // Skip Test language for all other environments than localhost

			const percentCompleted = t.getTranslationPercent(language);
			const thisOption = { label: `${language} (${percentCompleted}%)`, value: language };
			languageOptions.push(thisOption);

			if(language === this.props.lang){ selectedLanguage.push(thisOption); }
		});

		gameLanguages.forEach((gameLanguage:GameLanguage) => {
			if(appEnv.type !== EnvironmentType.LOCAL && gameLanguage === GameLanguage.TEST){ return; } // Skip Test language for all other environments than localhost

			const percentCompleted = t.getGameTranslationsPercent(gameLanguage);
			const thisOption = { label: `${gameLanguage} (${percentCompleted}%)`, value: gameLanguage };
			gameLanguageOptions.push(thisOption);

			if(gameLanguage === this.props.gameLang){ selectedGameLanguage.push(thisOption); }
		});

		return (
			<div className="language-switcher">
				<FormComponents.Form fieldDirection="column">
					<FormComponents.Select
						label={ { hide: false, text: "Language", position: labelPosition, alignment: "center" } }
						input={ { options: languageOptions, value: selectedLanguage, class: ["secondary"], events: { onChange: this.languageSelect } } }
					/>
					<FormComponents.Select
						label={ { hide: false, text: "Game Language", position: labelPosition, alignment: "center", wrap: true } }
						input={ { options: gameLanguageOptions, value: selectedGameLanguage, class: ["secondary"], events: { onChange: this.gameLanguageSelect } } }
					/>
					<FormComponents.Button
						asLink={true}
						text="Submit Translations"
						onClick={manager.view.goToTranslationsPage}
					/>
				</FormComponents.Form>
			</div>
		);
	}

	languageSelect = (option:MultiValue<RSOption>|SingleValue<RSOption>) => {
		if(option instanceof Array){ return; } // Should not be the case, This is a single select
		if(!option){ return; } // Should always have a value

		const newOptions = this.props.options;
		newOptions.lang = option.value as Language;
		manager.data.saveOptions(newOptions, true);
	}

	gameLanguageSelect = (option:MultiValue<RSOption>|SingleValue<RSOption>) => {
		if(option instanceof Array){ return; } // Should not be the case, This is a single select
		if(!option){ return; } // Should always have a value

		const newOptions = this.props.options;
		newOptions.gameLang = option.value as GameLanguage;
		manager.data.saveOptions(newOptions, true);
	}
}
export default connect(mapStateToProps)(LanguageSwitcher);
