//#region Imports
import { Theme } from "enums";
import manager from "managers/app";
import { Component, SyntheticEvent } from "react";
import { connect } from "react-redux";
import t from "translations/translator";
import { AppState, ThemeSwitcherProps } from "types";
import utils from "utils";
import "./ThemeSwitcher.css";
//#endregion Imports

const mapStateToProps = (state:AppState) => {
	return {
		lang: state.userInfo.options.lang,
		gameLang: state.userInfo.options.gameLang,
		theme: state.userInfo.options.theme,
		options: state.userInfo.options,
	};
};

class ThemeSwitcher extends Component<ThemeSwitcherProps> {
	private newTheme:Theme = Theme.DARK;

	render(){
		const available_themes = Object.values(Theme);

		return (
			<div className="theme-toggler">
				<span className="title">{t.t("Change Theme")}</span>
				<div className="theme-options">
					{available_themes.map((theme) => {
						const themeClasses:string[] = ["theme-choice", theme];
						if(this.props.theme === theme){ themeClasses.push("selected"); }

						return (
							<span
								key={theme}
								className={themeClasses.join(" ")}
								data-theme={theme}
								onClick={this.changeTheme}
							></span>
						);
					})}
				</div>
			</div>
		);
	}

	changeTheme = (evt:SyntheticEvent) => { // Event
		const theme = evt.currentTarget.getAttribute("data-theme");
		const siteWrapper = document.getElementById("site-wrapper");
		if(!siteWrapper){ return; }

		this.newTheme = utils.guards.isEnumValue(Theme, theme) ? theme : Theme.DARK;

		siteWrapper.addEventListener("animationend", this.themeChangeAnimationEnd);
		siteWrapper.classList.add("theme-change");
	}

	themeChangeAnimationEnd = () => { // Event
		const siteWrapper = document.getElementById("site-wrapper");
		if(!siteWrapper){ return; }

		const newOptions = this.props.options;
		newOptions.theme = this.newTheme;
		manager.data.saveOptions(newOptions, true);

		setTimeout(() => {
			siteWrapper.classList.remove("theme-change");
			siteWrapper.removeEventListener("animationend", this.themeChangeAnimationEnd);
		}, 500);
	}
}
export default connect(mapStateToProps)(ThemeSwitcher);
