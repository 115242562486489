//#region Imports
import { faSort, faSortAmountDown, faSortAmountUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FormComponents from "components/forms/FormComponents";
import { Currency, InputType, Item, ItemTableColumn, RelicPart } from "enums";
import log from "logger";
import manager from "managers/app";
import { Component } from "react";
import { connect } from "react-redux";
import t from "translations/translator";
import { AppState, ItemRowData, ItemsTableProps } from "types";
import Obtained from "views/page-parts/content/obtained/Obtained";
//#endregion Imports

const mapStateToProps = (state:AppState) => {
	return {
		lang: state.userInfo.options.lang,
		gameLang: state.userInfo.options.gameLang,
		breakpoint: state.breakpoint,
		options: state.userInfo.options,
		itemOptions: state.userInfo.options.items,
	};
};


class ItemsTable extends Component<ItemsTableProps> {
	render(){

		let rowClass = "even-row";

		return (
			<table className="table sortable">
				<thead>
					<tr>
						<th colSpan={Object.values(ItemTableColumn).length}>
							<p>{t.t("These values only take into account the currencies when they are used to purchase something, and does not include when they are used as an item")}</p>
							<p>{t.t("For example: %CURRENCY% show the total to purchase all %ITEM% but not the crystals that are needed to hand in for %PART%", { CURRENCY: Item.PROTEAN_CRYSTAL, ITEM: Item.PAZUZUS_FEATHER, PART: RelicPart.WEAPON_ANTIQUATED })}</p>{/* eslint-disable-line max-len */}
						</th>
					</tr>
					<tr>
						<th>{t.t("Obtained")}</th>
						<th onClick={this.props.sortTable} data-sortfield={ItemTableColumn.ITEM}>{t.t("Item")}<FontAwesomeIcon icon={this.getSortIcon(ItemTableColumn.ITEM)}/></th>
						<th onClick={this.props.sortTable} data-sortfield={ItemTableColumn.CURRENCY}>{t.t("Currency")}<FontAwesomeIcon icon={this.getSortIcon(ItemTableColumn.CURRENCY)}/></th>
						<th onClick={this.props.sortTable} data-sortfield={ItemTableColumn.PRICE}>{t.t("Price")}<FontAwesomeIcon icon={this.getSortIcon(ItemTableColumn.PRICE)}/></th>
						<th onClick={this.props.sortTable} data-sortfield={ItemTableColumn.COST}>{t.t("Total Cost")}<FontAwesomeIcon icon={this.getSortIcon(ItemTableColumn.COST)}/></th>
						<th onClick={this.props.sortTable} data-sortfield={ItemTableColumn.REMAININGCOST}>{t.t("Remaining Cost")}<FontAwesomeIcon icon={this.getSortIcon(ItemTableColumn.REMAININGCOST)}/></th>
						<th onClick={this.props.sortTable} data-sortfield={ItemTableColumn.REQUIRED}>{t.t("Items Required")}<FontAwesomeIcon icon={this.getSortIcon(ItemTableColumn.REQUIRED)}/></th>
						<th onClick={this.props.sortTable} data-sortfield={ItemTableColumn.USED}>{t.t("Items Used")}<FontAwesomeIcon icon={this.getSortIcon(ItemTableColumn.USED)}/></th>
						<th onClick={this.props.sortTable} data-sortfield={ItemTableColumn.REMAINING}>{t.t("Items Remaining")}<FontAwesomeIcon icon={this.getSortIcon(ItemTableColumn.REMAINING)}/></th>
						<th onClick={this.props.sortTable} data-sortfield={ItemTableColumn.INVENTORY}>{t.t("Inventory")}<FontAwesomeIcon icon={this.getSortIcon(ItemTableColumn.INVENTORY)}/></th>
						<th onClick={this.props.sortTable} data-sortfield={ItemTableColumn.TOOBTAIN}>{t.t("Remaining To Obtain")}<FontAwesomeIcon icon={this.getSortIcon(ItemTableColumn.TOOBTAIN)}/></th>
						<th onClick={this.props.sortTable} data-sortfield={ItemTableColumn.RELIC}>{t.t("Relics")}<FontAwesomeIcon icon={this.getSortIcon(ItemTableColumn.RELIC)}/></th>
						<th onClick={this.props.sortTable} data-sortfield={ItemTableColumn.ILVL}>{t.t("Item Level")}<FontAwesomeIcon icon={this.getSortIcon(ItemTableColumn.ILVL)}/></th>
					</tr>
				</thead>
				<tbody>
					{this.props.rows.map((row, rowIndex) => { // eslint-disable-line complexity
						const currencies:(Currency|Item|"N/A")[] = [];
						const prices:string[] = [];
						const amounts:{ [key:string]:string}[] = [];
						const inventoryInputs:JSX.Element[] = [];

						row.costs.forEach((cost) => {
							currencies.push(cost.currency);
							prices.push(cost.price.toLocaleString());
						});
						row.amounts.forEach((amount, i) => {
							amounts.push({
								totalCost: amount.totalCost.toLocaleString(),
								totalRequired: amount.totalRequired.toLocaleString(),
								totalUsed: amount.totalUsed.toLocaleString(),
								totalRemaining: amount.totalRemaining.toLocaleString(),
								totalToObtain: amount.totalToObtain.toLocaleString(),
								remainingCost: amount.remainingCost.toLocaleString(),
							});
							inventoryInputs.push(<FormComponents.TextInput
								key={`item-${row.item.name}-amount-${i}`}
								label={{ hide: true }}
								input={{ type: InputType.NUMBER, value: amount.inventory, dataAttributes: { item: row.item.name }, events: { onChange: manager.content.inventoryUpdated }}}
							/>);
						});

						const costNA = currencies.length === 0;
						const trs:JSX.Element[] = [];

						if(costNA){
							if(amounts.length > 1){ log.debug(`amounts has more than 1 row, but there are no currencies, item ${row.item} (${row.itemNumber})`); }

							rowClass = rowClass === "odd-row" ? "even-row" : "odd-row";

							trs.push(this.getOutputTr({
								key: `row-${rowIndex}`,
								row: row,
								rowClass: rowClass,
								rowSpan: 1,
								isParentRow: true,
								currency: "N/A",
								price: "N/A",
								totalCost: "N/A",
								totalRequired: amounts[0].totalRequired,
								totalUsed: amounts[0].totalUsed,
								totalRemaining: amounts[0].totalRemaining,
								inventoryInput: inventoryInputs[0],
								totalToObtain: amounts[0].totalToObtain,
								remainingCost: "N/A",
							}));
						}else{
							currencies.forEach((currency, i) => {
								if(i === 0){ rowClass = rowClass === "odd-row" ? "even-row" : "odd-row"; }

								trs.push(this.getOutputTr({
									key: `row-${rowIndex}-${i}`,
									row: row,
									rowClass: rowClass,
									rowSpan: currencies.length,
									isParentRow: i === 0,
									currency: currency,
									price: prices[i],
									totalCost: amounts[i].totalCost,
									totalRequired: amounts[i].totalRequired,
									totalUsed: amounts[i].totalUsed,
									totalRemaining: amounts[i].totalRemaining,
									inventoryInput: inventoryInputs[i],
									totalToObtain: amounts[i].totalToObtain,
									remainingCost: amounts[i].remainingCost,
								}));
							});
						}

						return trs;
					})}
				</tbody>
			</table>
		);
	}

	getSortIcon(column:ItemTableColumn){
		const defaultSortIcon = faSort;
		const currentSortIcon = this.props.sortAsc ? faSortAmountUp : faSortAmountDown;
		return this.props.sortColumn === column ? currentSortIcon : defaultSortIcon;
	}

	// eslint-disable-next-line complexity
	getOutputTr(itemRowOptions:ItemRowOutputOptions){
		const key = itemRowOptions.key;
		const row = itemRowOptions.row;
		const rowClass = itemRowOptions.rowClass;
		const rowSpan = itemRowOptions.rowSpan;
		const isParentRow = itemRowOptions.isParentRow;
		const currency = itemRowOptions.currency;
		const price = itemRowOptions.price;
		let totalCost = itemRowOptions.totalCost;
		let totalRequired = itemRowOptions.totalRequired;
		let totalUsed = itemRowOptions.totalUsed;
		let totalRemaining = itemRowOptions.totalRemaining;
		const inventoryInput = itemRowOptions.inventoryInput;
		let totalToObtain = itemRowOptions.totalToObtain;
		let remainingCost = itemRowOptions.remainingCost;

		const relicDetails = row.itemUsed;
		const isUsedInRelic = relicDetails.length > 0;

		const currencyClass = [];
		const priceClass = [];
		const totalCostClass = [];
		const remainingCostClass = [];
		const totalRequiredClass = [];
		const totalUsedClass = [];
		const totalRemainingClass = [];
		const totalToObtainClass = [];
		const relicClass = [];

		let relicColSpan = 1;
		const relicValue = "N/A";

		if(currency === "N/A"){
			currencyClass.push("na");
		}

		if(price === "N/A"){
			priceClass.push("na");
		}

		if(totalCost === "N/A" || !isUsedInRelic){
			totalCostClass.push("na");
			totalCost = "N/A";
		}

		if(remainingCost === "N/A" || !isUsedInRelic){
			remainingCostClass.push("na");
			remainingCost = "N/A";
		}

		if(totalRequired === "N/A" || !isUsedInRelic){
			totalRequiredClass.push("na");
			totalRequired = "N/A";

			totalUsedClass.push("na");
			totalUsed = "N/A";

			totalRemainingClass.push("na");
			totalRemaining = "N/A";

			totalToObtainClass.push("na");
			totalToObtain = "N/A";
		}

		if(!isUsedInRelic){
			relicClass.push("na");
			relicColSpan = 2;
		}


		return <tr key={key} className={rowClass}>
			{isParentRow ? <td rowSpan={rowSpan}><Obtained obtained={row.item.obtained} qtyNeeded={Number(row.amounts[0].totalRequired)} /></td> : null }
			{isParentRow ? <td rowSpan={rowSpan}>{t.t(row.item.name)}</td> : null }
			<td className={currencyClass.join("")}>{t.t(currency)}</td>
			<td className={priceClass.join("")}>{price}</td>
			<td className={totalCostClass.join("")}>{totalCost}</td>
			<td className={remainingCostClass.join("")}>{remainingCost}</td>
			{isParentRow ? <td rowSpan={rowSpan} className={totalRequiredClass.join("")}>{totalRequired}</td> : null }
			{isParentRow ? <td rowSpan={rowSpan} className={totalUsedClass.join("")}>{totalUsed}</td> : null }
			{isParentRow ? <td rowSpan={rowSpan} className={totalRemainingClass.join("")}>{totalRemaining}</td> : null }
			{isParentRow ? <td rowSpan={rowSpan}>{inventoryInput}</td> : null }
			{isParentRow ? <td rowSpan={rowSpan} className={totalToObtainClass.join("")}>{totalToObtain}</td> : null }
			{/* eslint-disable-next-line no-nested-ternary */}
			{isUsedInRelic
				? isParentRow
					? <td rowSpan={rowSpan} className={""}>
						{relicDetails.map((detail, nameIndex) => { return <span key={nameIndex} className="relic-detail">{t.t(detail.part)}</span>; }) }
					</td>
					: null
				: <td rowSpan={rowSpan} colSpan={relicColSpan} className={relicClass.join("")}>{relicValue}</td>
			}
			{/* eslint-disable-next-line no-nested-ternary */}
			{isUsedInRelic
				? isParentRow
					? <td rowSpan={rowSpan} className={""}>
						{relicDetails.map((detail, ilvlIndex) => { return <span key={ilvlIndex} className="relic-detail">{detail.ilvl}</span>; }) }
					</td>
					: null
				: null
			}
		</tr>;
	}
}
export default connect(mapStateToProps)(ItemsTable);


interface ItemRowOutputOptions {
	key: string;
	row: ItemRowData;
	rowClass: string;
	rowSpan: number;
	isParentRow: boolean;
	currency: Currency|Item|"N/A";
	price: string;
	totalCost: string;
	totalRequired: string;
	totalUsed: string;
	totalRemaining: string;
	inventoryInput: JSX.Element;
	totalToObtain: string;
	remainingCost: string;
}
