//#region Imports
import { Component } from "react";
import { connect } from "react-redux";
import { AppState, ModalContentProps } from "types";
import "./ModalContent.css";
//#endregion Imports

const mapStateToProps = (state:AppState) => {
	return {
		lang: state.userInfo.options.lang,
		gameLang: state.userInfo.options.gameLang,
	};
};

class ModalContent extends Component<ModalContentProps> {
	render(){
		return (
			<div className="modal-content">
				{this.props.children}
			</div>
		);
	}


}
export default connect(mapStateToProps)(ModalContent);
