/* eslint-disable max-len */
//#region Imports
import { jobLists } from "character";
import BasicIcon from "components/basic-icon/BasicIcon";
import FormComponents from "components/forms/FormComponents";
import JobIcon from "components/job-icon/JobIcon";
import Link from "components/link/Link";
import NoCharacter from "components/no-character/NoCharacter";
import RelicCheckbox from "components/relic-checkbox/RelicCheckbox";
import Tip from "components/tip/Tip";
import { dungeons, trials } from "data-files/duties";
import { enemies } from "data-files/enemies";
import { items } from "data-files/items";
import { locations } from "data-files/locations";
import { quests } from "data-files/quests";
import { Dungeon, Enemy, EorzeaDataType, Icon, InputType, Item, LinkType, NPC, Quest, RelicGroup, RelicPart, RelicStep, RelicTask, RelicType, Trial, View } from "enums";
import manager from "managers/app";
import { Component } from "react";
import { connect } from "react-redux";
import t from "translations/translator";
import { AppState, Requirement, TaskInfo, ViewRelicInfo, ViewWeaponZodiacBaseWeaponProps } from "types";
import utils from "utils";
import ContentHeader from "views/page-parts/content/content-header/ContentHeader";
import DetailsSection from "views/page-parts/content/details-section/DetailsSection";
import Obtained from "views/page-parts/content/obtained/Obtained";
import OverviewTable from "views/page-parts/content/overview-table/OverviewTable";
import "./WeaponZodiacBaseWeapon.css";
//#endregion Imports

const mapStateToProps = (state:AppState) => {
	return {
		lang: state.userInfo.options.lang,
		gameLang: state.userInfo.options.gameLang,
		userInfo: state.userInfo,
		hideCompletedSteps: state.userInfo.options.hideCompletedSteps,
		hideCompletedTasks: state.userInfo.options.hideCompletedTasks,
		showJob: state.userInfo.options.job,
	};
};

class WeaponZodiacBaseWeapon extends Component<ViewWeaponZodiacBaseWeaponProps> {
	private requirements:Requirement[] = [];
	private importantNPCs:NPC[] = [];
	private relicInfo:ViewRelicInfo = {
		jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC],
		type: RelicType.WEAPON,
		relic: RelicGroup.WEAPON_ZODIAC,
		part: RelicPart.WEAPON_BASE_WEAPON,
		steps: [RelicStep.BROKEN_WEAPON, RelicStep.CLASS_WEAPON, RelicStep.CHIMERA, RelicStep.AMDAPOR, RelicStep.BEASTMAN, RelicStep.HYDRA, RelicStep.EMBER, RelicStep.GALE, RelicStep.ORE, RelicStep.OIL],
		stepPlaceholders: {
			[RelicStep.CHIMERA]: { TRIAL: Trial.CHIMERA },
			[RelicStep.AMDAPOR]: { DUNGEON: Dungeon.AMDAPOR_KEEP },
			[RelicStep.HYDRA]: { TRIAL: Trial.HYDRA },
			[RelicStep.EMBER]: { ITEM: "White-Hot Ember" },
			[RelicStep.GALE]: { ITEM: "Howling Gale" },
			[RelicStep.ORE]: { ITEM: "Hyperfused Ore" },
			[RelicStep.OIL]: { ITEM: Item.QUENCHING_OIL },
		},
		next: {
			part: RelicPart.WEAPON_ZENITH,
			view: View.RELIC_WEAPONS_ZODIAC_ZENITH,
		},
		previous: null,
	};

	constructor(props:ViewWeaponZodiacBaseWeaponProps){
		super(props);
		this.requirements = this.getRequirements();
		this.importantNPCs = this.getImportantNPCs();
	}

	// eslint-disable-next-line complexity
	render(){
		const character = manager.data.getActiveCharacter();
		if(character === null){ return <NoCharacter />; }

		const completeSteps = manager.content.getCompleteSteps(character.progress, this.relicInfo);

		let row = "odd-row";
		const oilStepInventory = manager.data.getInventoryStatus({
			item: manager.relics.getTaskItem(RelicStep.OIL, RelicTask.TASK_1),
			relic: [this.relicInfo.type, this.relicInfo.relic, this.relicInfo.part, RelicStep.OIL, RelicTask.TASK_1],
		});

		return (
			<div id="weapon-zodiac-base-weapon" className="relic">
				<ContentHeader requirements={this.requirements} importantNPCs={this.importantNPCs} relicInfo={this.relicInfo}>
					<p>
						{t.t("In order to obtain the %PART% you must complete the quest", { PART: RelicPart.WEAPON_BASE_WEAPON })}
						<Link type={LinkType.LODESTONE} lodestoneType={EorzeaDataType.BLUEQUEST} text={Quest.RELIC_REBORN} lodestoneID={quests[Quest.RELIC_REBORN].lodestoneID} />
					</p>
					<p>{t.t("The quest follows the steps below")}</p>
				</ContentHeader>
				<div id="relic-checklist">
					<OverviewTable character={character} completeSteps={completeSteps} relicInfo={this.relicInfo} />
				</div>
				<div id="relic-details">
					<h1>{t.t("Details")}</h1>
					{this.props.hideCompletedSteps && completeSteps.allComplete ? t.t("All Steps Complete") : null }
					<DetailsSection title={RelicStep.BROKEN_WEAPON} id={RelicStep.BROKEN_WEAPON} stepComplete={completeSteps[RelicStep.BROKEN_WEAPON]}><p>{t.t("The Broken Weapon is obtained during the first parts of the quest")}</p></DetailsSection>
					<DetailsSection title={RelicStep.CLASS_WEAPON} id={RelicStep.CLASS_WEAPON} stepComplete={completeSteps[RelicStep.CLASS_WEAPON]}>
						<p>{t.t("Each job requires a specific weapon with 2 Grade III Materia melded")}</p>
						<div className="table-wrapper">
							<table className="table">
								<thead><tr><th>{t.t("Job")}</th><th>{t.t("Task")}</th><th>{t.t("Found")}</th><th>{t.t("Quantity")}</th><th>{t.t("Inventory")}</th><th>{t.t("To Obtain")}</th><th>{t.t("Done")}</th></tr></thead>
								<tbody>
									{
										this.relicInfo.jobs.map((job) => {
											if(this.props.showJob !== null && this.props.showJob !== job){ return null; }

											const stepComplete = character.progress.isComplete(this.relicInfo.type, this.relicInfo.relic, this.relicInfo.part, RelicStep.CLASS_WEAPON, job);
											if(this.props.hideCompletedSteps && stepComplete){ return null; }

											const taskInfo:TaskInfo[] = [
												{ task: RelicTask.TASK_1, complete: character.progress.isComplete(this.relicInfo.type, this.relicInfo.relic, this.relicInfo.part, RelicStep.CLASS_WEAPON, RelicTask.TASK_1, job), target: manager.relics.getTaskItem(RelicStep.CLASS_WEAPON, RelicTask.TASK_1, job) },
												{ task: RelicTask.TASK_2, complete: character.progress.isComplete(this.relicInfo.type, this.relicInfo.relic, this.relicInfo.part, RelicStep.CLASS_WEAPON, RelicTask.TASK_2, job), target: manager.relics.getTaskItem(RelicStep.CLASS_WEAPON, RelicTask.TASK_2, job) },
											];

											const tableRows = taskInfo.map((thisTask, taskIndex) => {
												if(this.props.hideCompletedTasks && thisTask.complete){ return null; }

												let thisTarget = Item.UNKNOWN;
												if(utils.guards.isEnumValue(Item, thisTask.target)){ thisTarget = thisTask.target; }

												const inventory = manager.data.getInventoryStatus({
													item: thisTarget,
													relic: [this.relicInfo.type, this.relicInfo.relic, this.relicInfo.part, RelicStep.CLASS_WEAPON, thisTask.task],
													job: job,
												});

												const showJobIcon = manager.content.shouldShowJobIcon(this.props.hideCompletedTasks, taskInfo, taskIndex);
												const rowSpan = manager.content.getRowSpan(this.props.hideCompletedTasks, taskInfo, taskIndex);

												return <tr key={`${job}-${RelicStep.CLASS_WEAPON}-${thisTask.task}`} className={row}>
													{showJobIcon ? <td rowSpan={rowSpan}><JobIcon job={job} /></td> : null}
													<td>{t.t(thisTarget)}</td>
													<td><Obtained obtained={items[thisTarget].obtained} qtyNeeded={inventory.total} /></td>
													<td>{inventory.qtyPerJob.toLocaleString()}</td>
													<td><FormComponents.TextInput label={{ hide: true }} input={{ type: InputType.NUMBER, value: inventory.inventory, dataAttributes: { item: thisTarget }, events: { onChange: manager.content.inventoryUpdated }}} /></td>
													<td>{inventory.toObtain.toLocaleString()}</td>
													<td><RelicCheckbox character={character} job={job} relicInfo={this.relicInfo} step={RelicStep.CLASS_WEAPON} task={thisTask.task} /></td>
												</tr>;
											});
											row = row === "odd-row" ? "even-row" : "odd-row";
											return tableRows;
										})
									}
									{
										completeSteps[RelicStep.CLASS_WEAPON]
											? <tr className="all-complete"><td colSpan={7}><p>{t.t("All Complete")}</p></td></tr>
											: null
									}
								</tbody>
							</table>
						</div>
					</DetailsSection>
					<DetailsSection title={RelicStep.CHIMERA} placeholders={this.relicInfo.stepPlaceholders[RelicStep.CHIMERA]} id={RelicStep.CHIMERA} stepComplete={completeSteps[RelicStep.CHIMERA]}><p>{t.t("Complete")}<Link type={LinkType.LODESTONE} lodestoneType={trials[Trial.CHIMERA].type} text={trials[Trial.CHIMERA].name} lodestoneID={trials[Trial.CHIMERA].lodestoneID} /></p></DetailsSection>
					<DetailsSection title={RelicStep.AMDAPOR} placeholders={this.relicInfo.stepPlaceholders[RelicStep.AMDAPOR]} id={RelicStep.AMDAPOR} stepComplete={completeSteps[RelicStep.AMDAPOR]}>
						<Tip text={"While in Mor Dhona for this step, Purchase 1x %OIL% & 3x %MIST% for each job, you will need these later"} placeholders={{ OIL: Item.QUENCHING_OIL, MIST: Item.THAVNAIRIAN_MIST }}/>
						<p>{t.t("Complete")}<Link type={LinkType.LODESTONE} lodestoneType={dungeons[Dungeon.AMDAPOR_KEEP].type} text={dungeons[Dungeon.AMDAPOR_KEEP].name} lodestoneID={dungeons[Dungeon.AMDAPOR_KEEP].lodestoneID} /></p>
					</DetailsSection>
					<DetailsSection title={RelicStep.BEASTMAN} id={RelicStep.BEASTMAN} stepComplete={completeSteps[RelicStep.BEASTMAN]}>
						<p>{t.t("Each job has a specific set of enemies to hunt")}</p>
						<div className="table-wrapper">
							<table className="table">
								<thead><tr><th>{t.t("Job")}</th><th>{t.t("Target")}</th><th>{t.t("Location")}</th><th>{t.t("Quantity")}</th><th>{t.t("Done")}</th></tr></thead>
								<tbody>
									{
										this.relicInfo.jobs.map((job) => {
											if(this.props.showJob !== null && this.props.showJob !== job){ return null; }

											const stepComplete = character.progress.isComplete(this.relicInfo.type, this.relicInfo.relic, this.relicInfo.part, RelicStep.BEASTMAN, job);
											if(this.props.hideCompletedSteps && stepComplete){ return null; }

											const taskInfo:TaskInfo[] = [
												{ task: RelicTask.TASK_1, complete: character.progress.isComplete(this.relicInfo.type, this.relicInfo.relic, this.relicInfo.part, RelicStep.BEASTMAN, RelicTask.TASK_1, job), target: manager.relics.getTaskEnemy(RelicStep.BEASTMAN, RelicTask.TASK_1, job) },
												{ task: RelicTask.TASK_2, complete: character.progress.isComplete(this.relicInfo.type, this.relicInfo.relic, this.relicInfo.part, RelicStep.BEASTMAN, RelicTask.TASK_2, job), target: manager.relics.getTaskEnemy(RelicStep.BEASTMAN, RelicTask.TASK_2, job) },
												{ task: RelicTask.TASK_3, complete: character.progress.isComplete(this.relicInfo.type, this.relicInfo.relic, this.relicInfo.part, RelicStep.BEASTMAN, RelicTask.TASK_3, job), target: manager.relics.getTaskEnemy(RelicStep.BEASTMAN, RelicTask.TASK_3, job) },
											];

											const tableRows = taskInfo.map((thisTask, taskIndex) => {
												if(this.props.hideCompletedTasks && thisTask.complete){ return null; }

												let thisTarget = Enemy.UNKNOWN;
												if(utils.guards.isEnumValue(Enemy, thisTask.target)){ thisTarget = thisTask.target; }

												const showJobIcon = manager.content.shouldShowJobIcon(this.props.hideCompletedTasks, taskInfo, taskIndex);
												const rowSpan = manager.content.getRowSpan(this.props.hideCompletedTasks, taskInfo, taskIndex);

												const obtainedLocation = enemies[thisTarget].found[0];
												const thisLocation = locations[obtainedLocation.location];

												return <tr key={`${job}-${RelicStep.BEASTMAN}-${thisTask.task}`} className={row}>
													{showJobIcon ? <td rowSpan={rowSpan}><JobIcon job={job} /></td> : null}
													<td>{t.t(thisTask.target)}</td>
													{showJobIcon
														? <td rowSpan={rowSpan}>
															<div className="location-wrapper">
																<div className="location">
																	<span><BasicIcon icon={Icon.MAP} tooltip="Map" /> {t.t(thisLocation.map)}</span>
																	{thisLocation.region ? <span><BasicIcon icon={Icon.REGION} tooltip="Region" /> {t.t(thisLocation.region)}</span> : null}
																	{thisLocation.teleport ? <span><BasicIcon icon={Icon.TELEPORT} tooltip="Teleport" /> {t.t(thisLocation.teleport)}</span> : null}
																	{obtainedLocation.x > -1 ? <span><BasicIcon icon={Icon.LOCATION} tooltip="Co-ordinates" /> X: {obtainedLocation.x}, Y: {obtainedLocation.y}</span> : null}
																</div>
															</div>
														</td>
														: null
													}
													<td>8</td>
													<td><RelicCheckbox character={character} job={job} relicInfo={this.relicInfo} step={RelicStep.BEASTMAN} task={thisTask.task} /></td>
												</tr>;
											});
											row = row === "odd-row" ? "even-row" : "odd-row";
											return tableRows;
										})
									}
									{
										character.progress.isComplete(this.relicInfo.type, this.relicInfo.relic, this.relicInfo.part, RelicStep.CLASS_WEAPON)
											? <tr className="all-complete"><td colSpan={5}><p>{t.t("All Complete")}</p></td></tr>
											: null
									}
								</tbody>
							</table>
						</div>
					</DetailsSection>
					<DetailsSection title={RelicStep.HYDRA} placeholders={this.relicInfo.stepPlaceholders[RelicStep.HYDRA]} id={RelicStep.HYDRA} stepComplete={completeSteps[RelicStep.HYDRA]}><p>{t.t("Complete")}<Link type={LinkType.LODESTONE} lodestoneType={trials[Trial.HYDRA].type} text={trials[Trial.HYDRA].name} lodestoneID={trials[Trial.HYDRA].lodestoneID} /></p></DetailsSection>
					<DetailsSection title={RelicStep.EMBER} placeholders={this.relicInfo.stepPlaceholders[RelicStep.EMBER]} id={RelicStep.EMBER} stepComplete={completeSteps[RelicStep.EMBER]}><p>{t.t("Complete")}<Link type={LinkType.LODESTONE} lodestoneType={trials[Trial.IFRIT_HARD].type} text={trials[Trial.IFRIT_HARD].name} lodestoneID={trials[Trial.IFRIT_HARD].lodestoneID} /></p></DetailsSection>
					<DetailsSection title={RelicStep.GALE} placeholders={this.relicInfo.stepPlaceholders[RelicStep.GALE]} id={RelicStep.GALE} stepComplete={completeSteps[RelicStep.GALE]}><p>{t.t("Complete")}<Link type={LinkType.LODESTONE} lodestoneType={trials[Trial.GARUDA_HARD].type} text={trials[Trial.GARUDA_HARD].name} lodestoneID={trials[Trial.GARUDA_HARD].lodestoneID} /></p></DetailsSection>
					<DetailsSection title={RelicStep.ORE} placeholders={this.relicInfo.stepPlaceholders[RelicStep.ORE]} id={RelicStep.ORE} stepComplete={completeSteps[RelicStep.ORE]}><p>{t.t("Complete")}<Link type={LinkType.LODESTONE} lodestoneType={trials[Trial.TITAN_HARD].type} text={trials[Trial.TITAN_HARD].name} lodestoneID={trials[Trial.TITAN_HARD].lodestoneID} /></p></DetailsSection>
					<DetailsSection title={RelicStep.OIL} placeholders={this.relicInfo.stepPlaceholders[RelicStep.OIL]} id={RelicStep.OIL} stepComplete={completeSteps[RelicStep.OIL]}>
						<div className="table-wrapper">
							<table className="table">
								<thead><tr><th>{t.t("Obtained")}</th><th>{t.t("Item")}</th><th>{t.t("Quantity / Job")}</th><th>{t.t("Total")}</th><th>{t.t("Used")}</th><th>{t.t("Remaining")}</th><th>{t.t("Inventory")}</th><th>{t.t("To Obtain")}</th></tr></thead>
								<tbody>
									{this.props.hideCompletedTasks && oilStepInventory.toObtain === 0
										? null
										: <tr className="odd-row">
											<td><Obtained obtained={items[Item.QUENCHING_OIL].obtained} qtyNeeded={oilStepInventory.total} /></td>
											<td>{t.t(items[Item.QUENCHING_OIL].name)}</td>
											<td>{oilStepInventory.qtyPerJob.toLocaleString()}</td>
											<td>{oilStepInventory.total.toLocaleString()}</td>
											<td>{oilStepInventory.used.toLocaleString()}</td>
											<td>{oilStepInventory.remaining.toLocaleString()}</td>
											<td><FormComponents.TextInput label={{ hide: true }} input={{ type: InputType.NUMBER, value: oilStepInventory.inventory, dataAttributes: { item: Item.QUENCHING_OIL }, events: { onChange: manager.content.inventoryUpdated }}} /></td>
											<td>{oilStepInventory.toObtain.toLocaleString()}</td>
										</tr>
									}
									{
										character.progress.isComplete(this.relicInfo.type, this.relicInfo.relic, this.relicInfo.part, RelicStep.OIL)
											? <tr className="all-complete"><td colSpan={8}><p>{t.t("All Complete")}</p></td></tr>
											: null
									}
								</tbody>
							</table>
						</div>
					</DetailsSection>
				</div>
			</div>
		);
	}

	componentDidMount(){
		manager.view.changeComplete();
	}

	private getRequirements():Requirement[]{
		return [
			{ type: quests[Quest.ULTIMATE_WEAPON].type, text: Quest.ULTIMATE_WEAPON, lodestoneID: quests[Quest.ULTIMATE_WEAPON].lodestoneID },
			{ type: "text", text: "Complete all Job Quests (level 50) for your chosen jobs" },
			{ type: quests[Quest.WEAPONSMITH_OF_LEGEND].type, text: Quest.WEAPONSMITH_OF_LEGEND, lodestoneID: quests[Quest.WEAPONSMITH_OF_LEGEND].lodestoneID },
		];
	}

	private getImportantNPCs():NPC[]{
		return [NPC.NEDRICK, NPC.GEROLT_NORTH_SHROUD];
	}
}
export default connect(mapStateToProps)(WeaponZodiacBaseWeapon);
