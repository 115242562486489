/* eslint-disable max-len */
//#region Imports
import { jobLists } from "character";
import Link from "components/link/Link";
import NoCharacter from "components/no-character/NoCharacter";
import { quests } from "data-files/quests";
import { shops } from "data-files/shops";
import { Currency, EorzeaDataType, LinkType, NPC, Quest, RelicGroup, RelicPart, RelicStep, RelicType, Shop, View } from "enums";
import manager from "managers/app";
import { Component } from "react";
import { connect } from "react-redux";
import t from "translations/translator";
import { AppState, Requirement, ViewRelicInfo, ViewToolSplendorousSplendorousProps } from "types";
import ContentHeader from "views/page-parts/content/content-header/ContentHeader";
import DetailsSection from "views/page-parts/content/details-section/DetailsSection";
import OverviewTable from "views/page-parts/content/overview-table/OverviewTable";
import "./ToolSplendorousSplendorous.css";
//#endregion Imports

const mapStateToProps = (state:AppState) => {
	return {
		lang: state.userInfo.options.lang,
		gameLang: state.userInfo.options.gameLang,
		userInfo: state.userInfo,
		hideCompletedSteps: state.userInfo.options.hideCompletedSteps,
		hideCompletedTasks: state.userInfo.options.hideCompletedTasks,
		showJob: state.userInfo.options.job,
	};
};

class ToolSplendorousSplendorous extends Component<ViewToolSplendorousSplendorousProps> {
	private requirements:Requirement[] = [];
	private importantNPCs:NPC[] = [];
	private relicInfo:ViewRelicInfo = {
		jobs: jobLists.relic[RelicGroup.TOOL_SPLENDOROUS],
		type: RelicType.TOOL,
		relic: RelicGroup.TOOL_SPLENDOROUS,
		part: RelicPart.TOOL_SPLENDOROUS,
		steps: [RelicStep.SPLENDOROUS_PURCHASE],
		stepPlaceholders: {},
		next: {
			part: RelicPart.TOOL_AUGMENTED_SPLENDOROUS,
			view: View.RELIC_TOOLS_SPLENDOROUS_AUGMENTED_SPLENDOROUS,
		},
		previous: null,
	};

	constructor(props:ViewToolSplendorousSplendorousProps){
		super(props);
		this.requirements = this.getRequirements();
		this.importantNPCs = this.getImportantNPCs();
	}

	render(){
		const character = manager.data.getActiveCharacter();
		if(character === null){ return <NoCharacter />; }

		const completeSteps = manager.content.getCompleteSteps(character.progress, this.relicInfo);

		return (
			<div id="tool-splendorous-splendorous" className="relic">
				<ContentHeader requirements={this.requirements} importantNPCs={this.importantNPCs} relicInfo={this.relicInfo}>
					<p>{t.t("Complete")}<Link type={LinkType.LODESTONE} lodestoneType={EorzeaDataType.BLUEQUEST} text={Quest.ORIGINAL_IMPROVEMENT} lodestoneID={quests[Quest.ORIGINAL_IMPROVEMENT].lodestoneID} /></p>
				</ContentHeader>
				<div id="relic-checklist">
					<OverviewTable character={character} completeSteps={completeSteps} relicInfo={this.relicInfo} />
				</div>
				<div id="relic-details">
					<h1>{t.t("Details")}</h1>
					{this.props.hideCompletedSteps && completeSteps.allComplete ? t.t("All Steps Complete") : null }
					<DetailsSection title={RelicStep.SPLENDOROUS_PURCHASE} id={RelicStep.SPLENDOROUS_PURCHASE} stepComplete={completeSteps[RelicStep.SPLENDOROUS_PURCHASE]}>
						<p>{t.t("After completing %QUEST% - The rest of the base tools can be purchased from %SHOP% for 1,500 %CURRENCY1% or %CURRENCY2% each (12,00 %CURRENCY1% and 4,500 %CURRENCY2% in total)", { QUEST: Quest.ORIGINAL_IMPROVEMENT, SHOP: Shop.QUINNANA, CURRENCY1: Currency.WHITE_CRAFTERS_SCRIP, CURRENCY2: Currency.WHITE_GATHERERS_SCRIP })}</p>
						<p><Link type={LinkType.LODESTONE} lodestoneType={EorzeaDataType.SHOP} lodestoneID={shops[Shop.QUINNANA].lodestoneID} text={Shop.QUINNANA} /></p>
					</DetailsSection>
				</div>
			</div>
		);
	}

	componentDidMount(){
		manager.view.changeComplete();
	}

	private getRequirements():Requirement[]{
		return [
			{ type: quests[Quest.ENDWALKER].type, text: Quest.ENDWALKER, lodestoneID: quests[Quest.ENDWALKER].lodestoneID },
			{ type: quests[Quest.CRYSTALLINE_MEAN].type, text: Quest.CRYSTALLINE_MEAN, lodestoneID: quests[Quest.CRYSTALLINE_MEAN].lodestoneID },
			{ type: "text", text: "Complete 'The Boutique Always Wins' from Mowen in Eulmore (x11, y11)" },
		];
	}

	private getImportantNPCs():NPC[]{
		return [NPC.MOWEN, NPC.KATLISS, NPC.CHORA_ZOI];
	}
}
export default connect(mapStateToProps)(ToolSplendorousSplendorous);
