//#region Imports
import { Component } from "react";
import { connect } from "react-redux";
import { AppState, JobIconProps } from "types";
import "./JobIcon.css";
//#endregion Imports

//#region Job Icon Imports
// eslint-disable-next-line sort-imports
import ALC from "assets/icons/job-icons/ALC.png";
import ARM from "assets/icons/job-icons/ARM.png";
import AST from "assets/icons/job-icons/AST.png";
import BLM from "assets/icons/job-icons/BLM.png";
import BRD from "assets/icons/job-icons/BRD.png";
import BSM from "assets/icons/job-icons/BSM.png";
import BTN from "assets/icons/job-icons/BTN.png";
import CRP from "assets/icons/job-icons/CRP.png";
import CUL from "assets/icons/job-icons/CUL.png";
import DNC from "assets/icons/job-icons/DNC.png";
import DRG from "assets/icons/job-icons/DRG.png";
import DRK from "assets/icons/job-icons/DRK.png";
import FSH from "assets/icons/job-icons/FSH.png";
import GNB from "assets/icons/job-icons/GNB.png";
import GSM from "assets/icons/job-icons/GSM.png";
import LTW from "assets/icons/job-icons/LTW.png";
import MCH from "assets/icons/job-icons/MCH.png";
import MIN from "assets/icons/job-icons/MIN.png";
import MNK from "assets/icons/job-icons/MNK.png";
import NIN from "assets/icons/job-icons/NIN.png";
import PLD from "assets/icons/job-icons/PLD.png";
import RDM from "assets/icons/job-icons/RDM.png";
import RPR from "assets/icons/job-icons/RPR.png";
import SAM from "assets/icons/job-icons/SAM.png";
import SCH from "assets/icons/job-icons/SCH.png";
import SGE from "assets/icons/job-icons/SGE.png";
import SMN from "assets/icons/job-icons/SMN.png";
import WAR from "assets/icons/job-icons/WAR.png";
import WHM from "assets/icons/job-icons/WHM.png";
import WVR from "assets/icons/job-icons/WVR.png";
import { jobLists } from "character";
import t from "translations/translator";
//#endregion Job Icon Imports

const mapStateToProps = (state:AppState) => {
	return {
		lang: state.userInfo.options.lang,
		gameLang: state.userInfo.options.gameLang,
	};
};

class JobIcon extends Component<JobIconProps> {
	render(){
		const jobIcon = this.getIcon();
		const tooltip = this.props.tooltip ? this.props.tooltip : this.props.job;

		const iconClasses = ["rt-jobicon"];
		if(this.props.onClick){ iconClasses.push("interactable"); }
		if(this.props.selected){ iconClasses.push("selected"); }
		if(jobLists.type.tanks.includes(this.props.job)){ iconClasses.push("tank"); }
		if(jobLists.type.healers.includes(this.props.job)){ iconClasses.push("healer"); }
		if(jobLists.type.dps.includes(this.props.job)){ iconClasses.push("dps"); }

		return <img className={iconClasses.join(" ")} src={jobIcon} title={t.t(tooltip)} onClick={this.props.onClick} />;
	}

	// eslint-disable-next-line complexity
	getIcon(){
		switch(this.props.job){
			default: return this.props.job;
			case "ALC": return ALC;
			case "ARM": return ARM;
			case "AST": return AST;
			case "BLM": return BLM;
			case "BRD": return BRD;
			case "BSM": return BSM;
			case "BTN": return BTN;
			case "CRP": return CRP;
			case "CUL": return CUL;
			case "DNC": return DNC;
			case "DRG": return DRG;
			case "DRK": return DRK;
			case "FSH": return FSH;
			case "GNB": return GNB;
			case "GSM": return GSM;
			case "LTW": return LTW;
			case "MCH": return MCH;
			case "MIN": return MIN;
			case "MNK": return MNK;
			case "NIN": return NIN;
			case "PLD": return PLD;
			case "RDM": return RDM;
			case "RPR": return RPR;
			case "SAM": return SAM;
			case "SCH": return SCH;
			case "SGE": return SGE;
			case "SMN": return SMN;
			case "WAR": return WAR;
			case "WHM": return WHM;
			case "WVR": return WVR;
		}
	}
}
export default connect(mapStateToProps)(JobIcon);
