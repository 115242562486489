/* eslint-disable max-len */
//#region Imports
import { jobLists } from "character";
import Link from "components/link/Link";
import NoCharacter from "components/no-character/NoCharacter";
import { quests } from "data-files/quests";
import { shops } from "data-files/shops";
import { Currency, EorzeaDataType, LinkType, NPC, Quest, RelicGroup, RelicPart, RelicStep, RelicType, Shop, View } from "enums";
import manager from "managers/app";
import { Component } from "react";
import { connect } from "react-redux";
import t from "translations/translator";
import { AppState, Requirement, ViewRelicInfo, ViewToolSkysteelBaseProps } from "types";
import ContentHeader from "views/page-parts/content/content-header/ContentHeader";
import DetailsSection from "views/page-parts/content/details-section/DetailsSection";
import OverviewTable from "views/page-parts/content/overview-table/OverviewTable";
import "./ToolSkysteelBase.css";
//#endregion Imports

const mapStateToProps = (state:AppState) => {
	return {
		lang: state.userInfo.options.lang,
		gameLang: state.userInfo.options.gameLang,
		userInfo: state.userInfo,
		hideCompletedSteps: state.userInfo.options.hideCompletedSteps,
		hideCompletedTasks: state.userInfo.options.hideCompletedTasks,
		showJob: state.userInfo.options.job,
	};
};

class ToolSkysteelBase extends Component<ViewToolSkysteelBaseProps> {
	private requirements:Requirement[] = [];
	private importantNPCs:NPC[] = [];
	private relicInfo:ViewRelicInfo = {
		jobs: jobLists.relic[RelicGroup.TOOL_SKYSTEEL],
		type: RelicType.TOOL,
		relic: RelicGroup.TOOL_SKYSTEEL,
		part: RelicPart.TOOL_SKYSTEEL,
		steps: [RelicStep.PURCHASE],
		stepPlaceholders: {},
		next: {
			part: RelicPart.TOOL_SKYSTEEL_1,
			view: View.RELIC_TOOLS_SKYSTEEL_SKYSTEEL_1,
		},
		previous: null,
	};

	constructor(props:ViewToolSkysteelBaseProps){
		super(props);
		this.requirements = this.getRequirements();
		this.importantNPCs = this.getImportantNPCs();
	}

	render(){
		const character = manager.data.getActiveCharacter();
		if(character === null){ return <NoCharacter />; }

		const completeSteps = manager.content.getCompleteSteps(character.progress, this.relicInfo);

		return (
			<div id="tool-skysteel-base" className="relic">
				<ContentHeader requirements={this.requirements} importantNPCs={this.importantNPCs} relicInfo={this.relicInfo}>
					<p>{t.t("Complete")}<Link type={LinkType.LODESTONE} lodestoneType={EorzeaDataType.BLUEQUEST} text={Quest.MISLAID_PLANS} lodestoneID={quests[Quest.MISLAID_PLANS].lodestoneID} /></p>
				</ContentHeader>
				<div id="relic-checklist">
					<OverviewTable character={character} completeSteps={completeSteps} relicInfo={this.relicInfo} />
				</div>
				<div id="relic-details">
					<h1>{t.t("Details")}</h1>
					{this.props.hideCompletedSteps && completeSteps.allComplete ? t.t("All Steps Complete") : null }
					<DetailsSection title={RelicStep.PURCHASE} id={RelicStep.PURCHASE} stepComplete={completeSteps[RelicStep.PURCHASE]}>
						<p>{t.t("After completing %QUEST% - The rest of the base tools can be purchased from %SHOP% for 80,000 %CURRENCY% each (800,000 total for all tools)", { QUEST: Quest.MISLAID_PLANS, SHOP: Shop.DENYS, CURRENCY: Currency.GIL })}</p>
						<p><Link type={LinkType.LODESTONE} lodestoneType={EorzeaDataType.SHOP} lodestoneID={shops[Shop.DENYS].lodestoneID} text={Shop.DENYS} /></p>
					</DetailsSection>
				</div>
			</div>
		);
	}

	componentDidMount(){
		manager.view.changeComplete();
	}

	private getRequirements():Requirement[]{
		return [
			{ type: quests[Quest.TOWARDS_THE_FIRMAMENT].type, text: Quest.TOWARDS_THE_FIRMAMENT, lodestoneID: quests[Quest.TOWARDS_THE_FIRMAMENT].lodestoneID },
		];
	}

	private getImportantNPCs():NPC[]{
		return [NPC.SKYSTEEL_ENGINEER, NPC.DENYS];
	}
}
export default connect(mapStateToProps)(ToolSkysteelBase);
