//#region Imports
import FormComponents from "components/forms/FormComponents";
import Link from "components/link/Link";
import { items } from "data-files/items";
import { EorzeaDataType, Icon, LinkType, Modal } from "enums";
import manager from "managers/app";
import ModalContent from "modals/content/ModalContent";
import ModalFooter from "modals/footer/ModalFooter";
import ModalHeader from "modals/header/ModalHeader";
import { Component } from "react";
import { connect } from "react-redux";
import t from "translations/translator";
import { AppState, RecipeModalData, RecipesModalProps, RecipeTableData } from "types";
import Obtained from "views/page-parts/content/obtained/Obtained";
import "./RecipesModal.css";
//#endregion Imports

const mapStateToProps = (state:AppState) => {
	return {
		lang: state.userInfo.options.lang,
		gameLang: state.userInfo.options.gameLang,
		data: state.modal[Modal.RECIPES],
	};
};


class RecipesModal extends Component<RecipesModalProps> {
	render(){
		const tablesToGenerate:RecipeTableData[] = this.getTablesToGenerate(this.props.data);
		const recipeCards:JSX.Element[] = this.getRecipeCards(tablesToGenerate);

		return (
			<div id={Modal.RECIPES} className="modal-wrapper">
				<ModalHeader text="Recipes" icon={Icon.RECIPE} />
				<ModalContent>
					<p>{t.t("'Crafts' is the number of times you need to craft a recipe to obtain the required number of items")}</p>
					{this.props.data.recipes.map((recipe, index) => {
						return <div className="recipe-wrapper" key={`recipe-${recipe.itemMade}-${index}`}>
							{this.getRecipeCard({ recipe: recipe, qtyToMake: this.props.data.qtyNeeded}, true, recipeCards)}
						</div>;
					})}
				</ModalContent>
				<ModalFooter>
					<FormComponents.Button text="Close" onClick={manager.modal.close} translate={true} />
				</ModalFooter>
			</div>
		);
	}

	getTablesToGenerate(recipeData:RecipeModalData):RecipeTableData[]{
		const tables:RecipeTableData[] = [];

		recipeData.recipes.forEach((mainItemRecipe) => {
			mainItemRecipe.itemsRequired.forEach((mainRecipeRequiredItem) => {
				const subItemRecipes = items[mainRecipeRequiredItem.item].obtained.recipes;
				if(!subItemRecipes){ return; } // We do not need to add a recipe card for this item

				subItemRecipes.forEach((subRecipe) => {
					tables.push({ recipe: subRecipe, qtyToMake: Math.ceil(recipeData.qtyNeeded / subRecipe.qtyMade) * mainRecipeRequiredItem.qty });
				});

				tables.push(...this.getTablesToGenerate({ recipes: subItemRecipes, qtyNeeded: recipeData.qtyNeeded * mainRecipeRequiredItem.qty }));
			});
		});

		return tables;
	}

	getRecipeCards(recipesData:RecipeTableData[]):JSX.Element[]{
		const recipeCards = recipesData.map((thisRecipe) => { return this.getRecipeCard(thisRecipe); });
		return recipeCards;
	}

	getRecipeCard(recipeData:RecipeTableData, isMainRecipe?:boolean, recipeCards?:JSX.Element[]):JSX.Element{
		const recipe = recipeData.recipe; // Will only ever contain 1 recipe due to getTablesToGenerate

		return <div key={`${recipe.itemMade}-${recipe.crafter}`} className={`recipe-card ${isMainRecipe ? "" : "inverted"}`}>
			<h3><Link type={LinkType.LODESTONE} lodestoneType={EorzeaDataType.RECIPE} lodestoneID={recipe.lodestoneID} text={recipe.itemMade} /></h3>
			<div className="recipe-details">
				<span>{t.t("Crafter")}: {t.t(manager.relics.getJobName(recipe.crafter))}</span>
				<span>{t.t("Level")}: {recipe.level}</span>
				<span>{t.t("Makes")}: {recipe.qtyMade}</span>
				<span>{t.t("Crafts")}: {recipeData.qtyToMake.toLocaleString()}</span>
			</div>
			<h3>{t.t("Ingredients")}</h3>
			<div className="table-wrapper">
				<table className="table">
					<thead><tr><th>{t.t("Item")}</th><th>{t.t("Quantity")}</th><th>{t.t("Obtained")}</th></tr></thead>
					<tbody>
						{recipe.itemsRequired.map((thisRequiredItem, index) => {
							const thisPreCraftItem = items[thisRequiredItem.item];
							const qtyItemNeeded = recipeData.qtyToMake * thisRequiredItem.qty;

							return <tr key={`${thisRequiredItem.item}-${index}`}>
								<td>{thisRequiredItem.item}</td>
								<td>{qtyItemNeeded.toLocaleString()}</td>
								<td><Obtained obtained={thisPreCraftItem.obtained} qtyNeeded={thisRequiredItem.qty} ignoreRecipe={true} /></td>
							</tr>;
						})}
					</tbody>
				</table>
			</div>
			{isMainRecipe
				? <div className="recipe-precrafts-wrapper">
					{recipeCards}
				</div>
				: null
			}
		</div>;
	}
}
export default connect(mapStateToProps)(RecipesModal);
