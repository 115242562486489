//#region Imports
import AccountStatus from "components/account-status/AccountStatus";
import CharacterSelector from "components/character-selector/CharacterSelector";
import { Breakpoint } from "enums";
import { Component } from "react";
import { connect } from "react-redux";
import { AppState, ViewMenuHeaderProps } from "types";
//#endregion Imports

const mapStateToProps = (state:AppState) => {
	return {
		lang: state.userInfo.options.lang,
		gameLang: state.userInfo.options.gameLang,
		breakpoint: state.breakpoint,
	};
};

class MenuHeader extends Component<ViewMenuHeaderProps> {
	render(){
		return (
			<div>
				{this.props.breakpoint === Breakpoint.DESKTOP
					? null
					: <div className="menu-header">
						<AccountStatus inline={true} />
						<CharacterSelector labelPosition="left" />
					</div>
				}
			</div>
		);
	}
}
export default connect(mapStateToProps)(MenuHeader);
