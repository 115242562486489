//#region Imports
import BasicIcon from "components/basic-icon/BasicIcon";
import { EorzeaDataType, GameLanguage, Icon, LinkType, Market } from "enums";
import { Component, SyntheticEvent } from "react";
import { connect } from "react-redux";
import t from "translations/translator";
import { AppState, LinkProps } from "types";
import utils from "utils";
import "./Link.css";
//#endregion Imports

const mapStateToProps = (state:AppState) => {
	return {
		lang: state.userInfo.options.lang,
		gameLang: state.userInfo.options.gameLang,
	};
};

class Link extends Component<LinkProps> {
	private baseLinks = {
		lodestone: "https://%LANG%.finalfantasyxiv.com/lodestone/playguide/db",
		universalis: "https://universalis.app/market",
	};

	render(){
		let component:JSX.Element|null = null;

		switch(this.props.type){
			default: component = null; break;
			case LinkType.LINK: component = this.getLink(); break;
			case LinkType.ANCHOR: component = this.getAnchorLink(); break;
			case LinkType.LODESTONE: component = this.getLodestoneLink(); break;
			case LinkType.MARKET: component = this.getMarketLink(); break;
		}
		return component;
	}

	private getLodestoneRequestType(type:EorzeaDataType):string{
		switch(type){
			default: return "";
			case "bluequest": case "msq": case "sidequest": return "quest";
			case "recipe": return "recipe";
			case "leve": case "fate": case "dungeon": case "trial": case "raid": return "duty";
			case "shop": return "shop";
		}
	}

	private getIcon(type:EorzeaDataType):Icon{
		switch(type){
			default: return Icon.OTHER;
			case "bluequest": return Icon.BLUE_QUEST;
			case "msq": return Icon.MSQ;
			case "sidequest": return Icon.QUEST;
			case "recipe": return Icon.RECIPE;
			case "leve": return Icon.LEVE;
			case "fate": return Icon.FATE;
			case "dungeon": return Icon.DUNGEON;
			case "trial": return Icon.TRIAL;
			case "raid": return Icon.RAID;
			case "shop": return Icon.SHOP;
		}
	}

	anchorSmoothScroll = (evt:SyntheticEvent) => { // Event
		evt.preventDefault();
		const ele = evt.currentTarget as HTMLAnchorElement;
		const anchor = ele.getAttribute("href");
		if(!anchor){ return; }
		const targetEle = document.querySelector(anchor);
		if(!targetEle){ return; }
		targetEle.scrollIntoView({ behavior: "smooth" });
	}

	getLink():JSX.Element|null{
		if(this.props.type !== LinkType.LINK){ return null; }

		let linkText:string|null = null;

		const linkClasses = [...this.props.class ? this.props.class : []];
		const dataAttrs = this.props.dataAttributes ? utils.helpers.convertToDataAttributes(this.props.dataAttributes) : null;

		if(this.props.text){ linkText = this.props.translate === false ? this.props.text : t.t(this.props.text, this.props.placeholders); }

		return <a
			target={this.props.target}
			className={linkClasses.join(" ")}
			href={this.props.link}
			{...dataAttrs}
			title={this.props.tooltip ? t.t(this.props.tooltip) : undefined} // eslint-disable-line no-undefined
		>
			{this.props.icon ? <BasicIcon icon={this.props.icon} /> : null}
			{linkText}
		</a>;
	}

	getAnchorLink():JSX.Element|null{
		if(this.props.type !== LinkType.ANCHOR){ return null; }

		let linkText:string|null = null;

		const linkClasses = [...this.props.class ? this.props.class : []];
		const triggerClickOnTarget = this.props.triggerClickOnTarget;
		const dataAttrs = this.props.dataAttributes ? utils.helpers.convertToDataAttributes(this.props.dataAttributes) : null;
		const link = this.props.link;

		linkClasses.push("anchor");

		if(this.props.text){ linkText = this.props.translate === false ? this.props.text : t.t(this.props.text, this.props.placeholders); }

		return <a
			className={linkClasses.join(" ")}
			href={link}
			title={this.props.tooltip ? t.t(this.props.tooltip) : undefined} // eslint-disable-line no-undefined
			onClick={(evt:SyntheticEvent) => {
				this.anchorSmoothScroll(evt);
				if(triggerClickOnTarget){ document.querySelector<HTMLHeadingElement>(`${link}:not(.show) > h2`)?.click(); } // eslint-disable-line no-unused-expressions
			}}
			{...dataAttrs}
		>
			{this.props.icon ? <BasicIcon icon={this.props.icon} /> : null}
			{linkText}
		</a>;
	}

	getLodestoneLink():JSX.Element|null{
		if(this.props.type !== LinkType.LODESTONE){ return null; }

		let linkText:string|null = null;

		const linkClasses = [...this.props.class ? this.props.class : []];
		const showIcon = typeof this.props.showIcon === "undefined" ? true : this.props.showIcon;
		const dataAttrs = this.props.dataAttributes ? utils.helpers.convertToDataAttributes(this.props.dataAttributes) : null;
		const requestType = this.getLodestoneRequestType(this.props.lodestoneType);
		const lodestoneIcon = this.getIcon(this.props.lodestoneType);
		const baseLink = this.baseLinks.lodestone.replace("%LANG%", (this.props.gameLang === GameLanguage.EN || this.props.gameLang === GameLanguage.TEST) ? "eu" : this.props.gameLang.toLowerCase());
		const link = `${baseLink}/${requestType}/${this.props.lodestoneID}`;

		if(this.props.text){ linkText = this.props.translate === false ? this.props.text : t.t(this.props.text, this.props.placeholders); }

		linkClasses.push("eorzeadb_link"); // This class is required for the tooltips to work

		return <a
			target="_blank"
			className={linkClasses.join(" ")}
			title={this.props.tooltip ? t.t(this.props.tooltip) : undefined} // eslint-disable-line no-undefined
			href={link}
			{...dataAttrs}
		>
			{showIcon && lodestoneIcon ? <BasicIcon icon={lodestoneIcon} /> : null}
			{linkText}
		</a>;
	}

	getMarketLink():JSX.Element|null{
		if(this.props.type !== LinkType.MARKET){ return null; }

		let linkText:string|null = null;
		let link:string|null = null;
		let icon:Icon = Icon.UNKNOWN;

		const linkClasses = [...this.props.class ? this.props.class : []];
		const showIcon = typeof this.props.showIcon === "undefined" ? true : this.props.showIcon;
		const dataAttrs = this.props.dataAttributes ? utils.helpers.convertToDataAttributes(this.props.dataAttributes) : null;

		switch(this.props.market){
			default: break; // Link / Icon default already set when initilising variables
			case Market.UNIVERSALIS:
				link = `${this.baseLinks.universalis}/${this.props.params}`;
				icon = Icon.MARKET; // Done this way to allow different icons to be used for different market places
				break;
		}

		if(link === null){ return null; }
		if(this.props.market){ linkText = this.props.translate === false ? this.props.market : t.t(this.props.market); }

		return <a
			target="_blank"
			className={linkClasses.join(" ")}
			title={this.props.tooltip ? t.t(this.props.tooltip) : undefined} // eslint-disable-line no-undefined
			href={link}
			{...dataAttrs}
		>
			{showIcon && icon ? <BasicIcon icon={icon} /> : null}
			{linkText}
		</a>;
	}
}
export default connect(mapStateToProps)(Link);
