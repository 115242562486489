//#region Imports
import FormComponents from "components/forms/FormComponents";
import Pagination from "components/pagination/Pagination";
import { APIEndpoint, APIStatus, GameLanguage, InputType, Language, LanguageType } from "enums";
import manager from "managers/app";
import sysMsg from "managers/sysMsg";
import { Component, SyntheticEvent } from "react";
import { connect } from "react-redux";
import { MultiValue, SingleValue } from "react-select";
import gameTranslations from "translations/game-translations";
import siteTranslations from "translations/site-translations";
import t from "translations/translator";
import { AppState, OutputString, RSOption, SaveTranslationsRequest, SaveTranslationsResponse, TranslationRow, TranslationTypeRows, TranslationsAllRows, ViewTranslationsProps, ViewTranslationsState } from "types";
import utils from "utils";
import "./Translations.css";
//#endregion Imports

const mapStateToProps = (state:AppState) => {
	return {
		lang: state.userInfo.options.lang,
		gameLang: state.userInfo.options.gameLang,
	};
};

class Translations extends Component<ViewTranslationsProps> {
	private stateUpdateDelay:number = -1; // Timeout ID
	state:ViewTranslationsState = {
		showOnlyMissing: false,
		showOnlyChanges: false,
		createdLanguages: [],
		languageType: LanguageType.SITE,
		language: Object.values(Language)[2],
		rows: { [LanguageType.SITE]: {}, [LanguageType.GAME]: {} },
		page: 1,
		translationsPerPage: 50,
	};

	render(){
		const languageTypeOptions:RSOption[] = [{ label: t.t(LanguageType.SITE), value: LanguageType.SITE }, { label: t.t(LanguageType.GAME), value: LanguageType.GAME }];
		const selectedLanguageTypeOption = this.state.languageType === LanguageType.SITE ? languageTypeOptions[0] : languageTypeOptions[1];
		const languageOptions = this.getLanguageOptions(this.state.languageType);
		const selectedLangOption = languageOptions.find((z) => { return z.label === this.state.language; });
		const selectedLanguageOption = selectedLangOption ? selectedLangOption : languageOptions[0];

		const allRows = this.state.rows[this.state.languageType][this.state.language];
		if(!allRows){ return <p>{t.t("An unexpected error occurred")}</p>; }
		const filteredRows = this.filterRows(allRows);

		const {start, end} = utils.helpers.getPaginationIndexes(this.state.page, this.state.translationsPerPage, filteredRows.length, this.changePage);

		const rowsToShow = filteredRows.slice(start, end);

		let rowClass = "even-row";

		return (
			<div id="translations">
				<div>
					<p>{t.t("Use this page to submit translations")}</p>
					<p>{t.t("Either select the language you wish to submit translations for from the dropdown, or enter a new language code to add the new language")}</p>
					<p>{t.t("Submissions are not automatically added to the site, and are sent for review")}</p>
				</div>
				<div>
					<FormComponents.Form fieldDirection="row" justifyFields="evenly">
						<FormComponents.Select
							label={{ hide: false, text: "Language Set", position: "left", alignment: "center" }}
							input={{ options: languageTypeOptions, value: [selectedLanguageTypeOption], events: { onChange: this.switchLanguageType } }}
						/>
						<FormComponents.Select
							label={{ hide: false, text: "Select or Enter your language", position: "left", alignment: "center" }}
							input={{ options: languageOptions, value: [selectedLanguageOption], creatable: true, events: { onCreateOption: this.newLanguage, onChange: this.switchLanguage } }}
						/>
						<FormComponents.Checkbox
							label={{ hide: false, text: "Show only missing translations", position: "right", alignment: "center" }}
							input={{ checked: this.state.showOnlyMissing, events: { onClick: this.toggleShowOnlyMissing } }}
						/>
						<FormComponents.Checkbox
							label={{ hide: false, text: "Show only my changes", position: "right", alignment: "center" }}
							input={{ checked: this.state.showOnlyChanges, events: { onClick: this.toggleShowOnlyChanges } }}
						/>
					</FormComponents.Form>
					<FormComponents.Form fieldDirection="row" justifyFields="center">
						<FormComponents.Button
							text="Submit Changes"
							onClick={this.submitChanges}
							class={["translation-submit"]}
						/>
					</FormComponents.Form>
					<p>{t.t("Tracked submissions are cleared when leaving this page")}</p>
				</div>
				<Pagination
					page={this.state.page}
					entriesPerPage={this.state.translationsPerPage}
					totalEntries={filteredRows.length}
					pageChange={this.changePage}
					changeNumberOfRows={this.changeNumberOfRows}
				/>
				<div className="table-wrapper">
					<table className="table">
						<thead>
							<tr>
								<th>{t.t("Text between and including '%' are placeholders and should be preserved in the translation")}</th>
							</tr>
						</thead>
						<tbody>
							{rowsToShow.map((row) => {
								rowClass = rowClass === "odd-row" ? "even-row" : "odd-row";
								return <tr key={`translation-${this.state.languageType}-${row.language}-${row.key}`} className={rowClass}>
									<td>
										<FormComponents.FreeText
											label={{ hide: false, text: "EN", translate: false, position: "left", alignment: "center" }}
											input={{ text: row.en, translate: false }}
										/>
										{row.existing === null
											? null
											: <FormComponents.FreeText
												label={{ hide: false, text: "Existing Translation", position: "left", alignment: "center" }}
												input={{ text: row.existing, translate: false }}
											/>
										}
										{row.submitted === null
											? null
											: <FormComponents.FreeText
												label={{ hide: false, text: "Submitted Translation", position: "left", alignment: "center" }}
												input={{ text: row.submitted, translate: false }}
											/>
										}
										<FormComponents.TextInput
											label={{ hide: false, text: "New Translation", position: "left", alignment: "center" }}
											input={{ type: InputType.TEXT, value: row.new, autoComplete: "off", dataAttributes: { key: row.key }, events: { onChange: this.translationUpdated } }} // eslint-disable-line no-undefined, max-len
											hidden={{ type: InputType.HIDDEN, value: row.initialNew }}
										/>
									</td>
								</tr>;
							})}
						</tbody>
					</table>
				</div>
				<Pagination
					page={this.state.page}
					entriesPerPage={this.state.translationsPerPage}
					totalEntries={filteredRows.length}
					pageChange={this.changePage}
					changeNumberOfRows={this.changeNumberOfRows}
				/>
				<div>
					<FormComponents.Form fieldDirection="row" justifyFields="center">
						<FormComponents.Button
							text="Submit Changes"
							onClick={this.submitChanges}
							class={["translation-submit"]}
						/>
					</FormComponents.Form>
				</div>
			</div>
		);
	}

	componentDidMount = () => {
		this.initAllTableRows();
		manager.view.changeComplete();
	}

	getSelectOption(label:string, value:string):RSOption{
		return { label: label, value: value };
	}

	getLanguageOptions(languageType:string):RSOption[]{
		const languageOptions:RSOption[] = [];
		const languageList = Object.values(languageType === LanguageType.SITE ? Language : GameLanguage);

		if(languageType === LanguageType.SITE){ languageList.push(...this.state.createdLanguages); }

		languageList.forEach((language:Language|GameLanguage) => {
			if(language === Language.TEST || language === GameLanguage.TEST){ return; } // Skip Test language for all other environments than localhost
			if(language === Language.EN || language === GameLanguage.EN){ return; } // Skip English as these will not need translating
			languageOptions.push({ label: language, value: language });
		});

		return languageOptions;
	}

	initAllTableRows(){
		const siteRows:TranslationTypeRows = {};
		const gameRows:TranslationTypeRows = {};

		Object.entries(siteTranslations).forEach(([key, translation]) => {
			if(key === ""){ return; }

			Object.values(Language).forEach((language) => {
				const thisRow = this.getRow(LanguageType.SITE, key, translation, language);

				if(!siteRows[language]){ siteRows[language] = new Map<string, TranslationRow>(); }
				siteRows[language].set(key, thisRow);
			});
		});

		Object.entries(gameTranslations).forEach(([key, translation]) => {
			if(key === ""){ return; }

			Object.values(GameLanguage).forEach((language) => {
				const thisRow = this.getRow(LanguageType.GAME, key, translation, language);

				if(!gameRows[language]){ gameRows[language] = new Map<string, TranslationRow>(); }
				gameRows[language].set(key, thisRow);
			});
		});

		const rows:TranslationsAllRows = {
			[LanguageType.SITE]: siteRows,
			[LanguageType.GAME]: gameRows,
		};

		this.setState((old:ViewTranslationsState) => { return { ...old, rows: rows }; });
	}

	getRow(type:LanguageType, key:string, translation:{ [key: string]: string|null}, language:string):TranslationRow{
		const existingTranslation = translation[language];
		return {
			type: type,
			key: key,
			language: language,
			en: translation.EN as string, // English always exists
			existing: existingTranslation,
			submitted: null,
			initialNew: existingTranslation === null ? "" : existingTranslation,
			new: existingTranslation === null ? "" : existingTranslation,
		};
	}

	filterRows(allRows:Map<string, TranslationRow>):TranslationRow[]{
		const filteredRows:TranslationRow[] = [];
		for(const row of allRows.values()){
			if(this.state.showOnlyMissing && row.existing !== null){ continue; }
			if(this.state.showOnlyChanges && row.new === row.initialNew){ continue; }
			filteredRows.push(row);
		}
		return filteredRows;
	}

	changePage = (newPage:number) => { // Event
		this.setState((old:ViewTranslationsState):ViewTranslationsState => { return { ...old, page: newPage }; });
	}

	changeNumberOfRows = (selectedOptions:MultiValue<RSOption>|SingleValue<RSOption>) => { // Event
		if((selectedOptions instanceof Array)){ return; }

		const selectedValue = selectedOptions?.value;
		if(!selectedValue){ return; }

		this.setState((old:ViewTranslationsState):ViewTranslationsState => { return { ...old, translationsPerPage: parseInt(selectedValue) }; });
	}

	switchLanguageType = (option:MultiValue<RSOption>|SingleValue<RSOption>) => { // Event
		if(option instanceof Array){ return; } // Should not be the case, This is a single select
		if(!option){ return; } // Should always have a value

		const languageType = option.value;
		if(!utils.guards.isEnumValue(LanguageType, languageType)){ return; }

		let language:string = Language.EN;
		if(languageType === LanguageType.SITE){
			language = Object.values(Language)[2];
		}else{
			language = Object.values(GameLanguage)[2];
		}

		this.setState((old:ViewTranslationsState):ViewTranslationsState => { return { ...old, languageType: languageType, language: language, page: 1 }; });
	}

	newLanguage = (value:string) => { // Event
		const createdLanguages = this.state.createdLanguages;
		const newLang = value.toUpperCase();

		if(!createdLanguages.includes(newLang)){ createdLanguages.push(newLang); }
		this.setState((old:ViewTranslationsState):ViewTranslationsState => { return { ...old, language: newLang, createdLanguages: createdLanguages }; });
	}

	switchLanguage = (option:MultiValue<RSOption>|SingleValue<RSOption>) => { // Event
		if(option instanceof Array){ return; } // Should not be the case, This is a single select
		if(!option){ return; } // Should always have a value

		const language = option.value;
		this.setState((old:ViewTranslationsState):ViewTranslationsState => { return { ...old, language: language }; });
	}

	toggleShowOnlyMissing = () => { // Event
		this.setState((old:ViewTranslationsState):ViewTranslationsState => { return { ...old, showOnlyMissing: !old.showOnlyMissing, page: 1 }; });
	}

	toggleShowOnlyChanges = () => { // Event
		this.setState((old:ViewTranslationsState):ViewTranslationsState => { return { ...old, showOnlyChanges: !old.showOnlyChanges, page: 1 }; });
	}

	translationUpdated = (evt:SyntheticEvent) => { // Event
		if(this.stateUpdateDelay > -1){ clearTimeout(this.stateUpdateDelay); }
		this.stateUpdateDelay = window.setTimeout(() => {
			const ele = evt.target as HTMLInputElement;
			if(!ele.dataset.key){ return; }

			const key = ele.dataset.key;
			const languageType = this.state.languageType;
			const language = this.state.language;

			const allRows = this.state.rows;
			const theseRows = allRows[languageType][language];
			const thisRow = theseRows.get(key);
			if(!thisRow){ return; }

			if(ele.classList.contains("hasChanged")){
				thisRow.new = ele.value;
			}else{
				thisRow.new = thisRow.initialNew;
			}

			this.setState((old:ViewTranslationsState):ViewTranslationsState => { return { ...old, rows: allRows }; });
		}, 500);
	}

	submitChanges = async() => { // Event
		this.setSubmitButtonDisabled(true);

		const allRows = this.state.rows;
		const relevantRows = allRows[this.state.languageType][this.state.language];
		const changedRows:TranslationRow[] = [];
		for(const row of relevantRows.values()){
			if(row.new !== row.initialNew){ changedRows.push(row); }
		}

		const response = await manager.request.send<SaveTranslationsRequest, SaveTranslationsResponse>(APIEndpoint.SAVE_TRANSLATIONS, { translations: changedRows });
		// const response:ResponseData<SubmitTranslationsResponse> = { status: APIStatus.SUCCESS, data: {}};
		if(!response){ return; }

		changedRows.forEach((row) => {
			row.submitted = row.new;
			row.initialNew = row.new;
		});

		this.setState((old:ViewTranslationsState):ViewTranslationsState => { return { ...old, rows: allRows }; });

		let notificationText:OutputString;
		if(response.status === APIStatus.SUCCESS){
			notificationText = "Translations Submited";
		}else{
			notificationText = "Error submitting translations";
		}

		sysMsg.notification({ message: notificationText });

		this.setSubmitButtonDisabled(false);
	}

	private setSubmitButtonDisabled(value:boolean){
		const buttons = document.querySelectorAll<HTMLButtonElement>(".translation-submit");
		buttons.forEach((button) => { button.disabled = value; });
	}
}
export default connect(mapStateToProps)(Translations);
