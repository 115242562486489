//#region Imports
import { APIEndpoint } from "enums";
import { AnyRequest, AnyResponse, CurrentRequest, RequestData, ResponseData } from "types"; // eslint-disable-line max-len
import log from "../logger";
import manager from "./app";
import { appEnv } from "./env";
//#endregion Imports

export class NetManager {
	private xivapi:string = "https://xivapi.com";
	private apiUrl:string = appEnv.apiUrl;
	private isInit:boolean = false;
	private requests:CurrentRequest[] = [];
	private nextRequestId:number = 1;

	private fetchComplete(requestId:number){
		const index = this.requests.findIndex((z) => { return z.id === requestId; });
		if(index > -1){
			this.requests.splice(index, 1);
		}
	}

	init(){
		log.init(log.InitStatus.START, "Net");
		this.isInit = true;
		log.init(log.InitStatus.COMPLETE, "Net");
	}

	abortAll(){
		this.requests.forEach((request) => {
			try{
				request.abort();
			}catch(e){
				// Do nothing
			}
		});

		this.requests = [];
	}

	isProtectedEndpoint(endpoint:APIEndpoint):boolean{
		switch(endpoint){
			default: return true;
			case APIEndpoint.SAVE_TRANSLATIONS:
			case APIEndpoint.GET_NOTICES:
			case APIEndpoint.CHARACTER_SEARCH:
			case APIEndpoint.GET_ACHIEVEMENTS:
			case APIEndpoint.GET_AVATAR:
			case APIEndpoint.SERVER_LIST:
				return false;
		}
	}

	send<ReqType extends AnyRequest, ResType extends AnyResponse>(endpoint: APIEndpoint, reqData:RequestData<ReqType>):Promise<ResponseData<ResType>|null>{ // eslint-disable-line max-len
		return new Promise((resolve, reject) => {
			if(!this.isInit){ return reject(new Error("Net Manager has not be initialised")); }
			const token = manager.auth.getToken();
			if(this.isProtectedEndpoint(endpoint) && !token){ return reject(new Error("Not Authenticated")); }

			log.status(`Sending ${endpoint} request with data: `, reqData);

			const abortController = new AbortController();
			const url = this.apiUrl + endpoint;
			const body = JSON.stringify(reqData);

			const fetchPromise = fetch(url, {
				signal: abortController.signal,
				credentials: "include",
				method: "POST",
				mode: "cors", // Cors is required for the content-type to work
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: body,
			});

			const thisRequestID = this.nextRequestId++;

			fetchPromise.then((res) => { return res.json(); })
				.then((result:ResponseData<ResType>) => {
					log.status(`${endpoint} Response: `, result);
					this.fetchComplete(thisRequestID);
					resolve(result);
				})
				.catch((e) => {
					log.error("There was an error performing the request");
					log.error("Fetch Error: ", e);
					this.fetchComplete(thisRequestID);
					resolve(null);
				});
			this.requests.push({
				id: thisRequestID,
				req: fetchPromise,
				abort: abortController.abort,
			});
		});
	}
}
const net = new NetManager();
export default net;
