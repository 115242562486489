//#region Imports
import { Auth0Client } from "@auth0/auth0-spa-js";
import { APIEndpoint, APIStatus } from "enums";
import { GetUserDataRequest, GetUserDataResponse } from "types";
import utils from "utils";
import log from "../logger";
import manager from "./app";
import sanitise from "./data/sanitation";
import { appEnv } from "./env";
import { actions, store } from "./state";
import sysMsg from "./sysMsg";
//#endregion Imports

export class AuthManager {
	private auth0:Auth0Client;
	private token:string|null = null;
	private lastAuthCheck:Date = new Date();
	private recheckToken:number = 10;

	constructor(){
		this.auth0 = new Auth0Client({
			domain: appEnv.auth0.domain,
			client_id: appEnv.auth0.clientId,
			redirect_uri: window.location.origin,
			audience: appEnv.auth0.audience,
			cacheLocation: "localstorage",
		});

		this.lastAuthCheck = utils.helpers.addMinutesToDate(this.lastAuthCheck, this.recheckToken);
	}

	async init(){
		log.init(log.InitStatus.START, "Auth");
		const loggedIn = await this.login(true);
		manager.ga.loggedIn(loggedIn);
		log.init(log.InitStatus.COMPLETE, "Auth");
	}

	logout(){
		this.auth0.logout({ returnTo: window.location.origin });
	}

	async login(init:boolean = false):Promise<boolean>{
		const stateInfo = store.getState().userInfo;
		store.dispatch(actions.setUser({ isAuthenticated: false, username: "" }));

		if(!init){
			try{
				await this.auth0.loginWithPopup({ redirect_uri: window.location.href });
			}catch(e:any){
				if(e.error !== "login_required"){ log.error("Error logging in: ", e); }
				return false;
			}
		}

		this.token = await this.getTokenSilently();

		if(!this.token){
			store.dispatch(actions.setUser({
				username: "",
				isAuthenticated: false,
			}));
			return false;
		}

		const authUser = await this.auth0.getUser();
		if(!authUser || !authUser.sub || !authUser.name || !authUser.nickname){ return false; }

		const storedOptions = sanitise.convertToStoredOptions(stateInfo.options);

		const result = await manager.request.send<GetUserDataRequest, GetUserDataResponse>(APIEndpoint.GET_USER_DATA, {
			username: authUser.nickname,
			options: storedOptions,
		});

		if(!result || result.status === APIStatus.APPERROR || result.status === APIStatus.ERROR){
			if(!init){ sysMsg.error({ message: "There was an error logging in" }); }
			return false;
		}else if(result.status === APIStatus.SUCCESS){
			store.dispatch(actions.setUser({
				isAuthenticated: true,
				username: result.data.username,
			}));
			sysMsg.success({ message: "Logged in as %USERNAME%", messagePlaceholders: { USERNAME: result.data.username }});
			if(result.data.isNewAccount){ manager.ga.accountCreated(); }
			return true;
		}

		sysMsg.error({ message: "An unexpected error occurred" });
		return false;
	}

	private async getTokenSilently():Promise<string|null>{
		try{
			return await this.auth0.getTokenSilently();
		}catch(e:any){
			if(e.error !== "login_required"){
				log.error("Error fetching token: ", e);
			}
			return null;
		}
	}

	async isAuthenticated():Promise<boolean>{
		const userInfo = store.getState().userInfo;
		const timeForNextCheck = new Date();
		utils.helpers.addMinutesToDate(timeForNextCheck, this.recheckToken);

		if(timeForNextCheck.getTime() > new Date().getTime()){
			return userInfo.isAuthenticated;
		}

		this.lastAuthCheck = new Date();
		try{
			const isAuthed = await this.auth0.isAuthenticated();
			store.dispatch(actions.setUser({
				isAuthenticated: isAuthed,
				username: isAuthed ? userInfo.username : "",
			}));
			return isAuthed;
		}catch(e){
			return false;
		}
	}

	getToken(){
		return this.token;
	}

	deleteAccount(){
		// TOOD: Work out how to remove account from Auth0
	}
}

const auth = new AuthManager();
export default auth;
