//#region Imports
import NoCharacter from "components/no-character/NoCharacter";
import Pagination from "components/pagination/Pagination";
import { items } from "data-files/items";
import { CurrencyTableColumn, Item, ItemTableColumn, RelicIndex } from "enums";
import manager from "managers/app";
import { Component, SyntheticEvent } from "react";
import { connect } from "react-redux";
import { MultiValue, SingleValue } from "react-select";
import t from "translations/translator";
import { AppState, CurrencyRowData, ItemInfo, ItemRelic, ItemRowAmount, ItemRowData, RSOption, ShopCost, ViewItemsProps, ViewItemsState } from "types";
import utils from "utils";
import "./Items.css";
import Filters from "./parts/Filters";
import CurrencyTable from "./views/currency-table";
import ItemsTable from "./views/items-table";
//#endregion Imports

const mapStateToProps = (state:AppState) => {
	return {
		lang: state.userInfo.options.lang,
		gameLang: state.userInfo.options.gameLang,
		options: state.userInfo.options,
		itemOptions: state.userInfo.options.items,
	};
};

class Items extends Component<ViewItemsProps> {
	private isSetup:boolean = false;
	state:ViewItemsState = {
		sortColumn: null,
		sortAsc: true,
		allItems: [],
		page: 1,
		itemsPerPage: 50,
	};

	render(){
		const character = manager.data.getActiveCharacter();
		if(character === null || !this.isSetup){ return <NoCharacter />; }

		const preparedRows = this.prepareRows();
		const {start, end} = utils.helpers.getPaginationIndexes(this.state.page, this.state.itemsPerPage, preparedRows.length, this.changePage);

		let rowsData:ItemRowData[] = [];
		let currencyData:CurrencyRowData[] = [];

		if(this.props.itemOptions.selectedView === "Items"){
			rowsData = preparedRows.slice(start, end);
		}else{
			currencyData = this.getCurrencyTableRows(preparedRows);
			currencyData = this.sortCurrencyRows(currencyData);
			currencyData = currencyData.slice(start, end);
		}

		return (
			<div id="items">
				<Filters />
				{this.props.itemOptions.selectedView === "Items"
					? <div>
						<Pagination
							page={this.state.page}
							entriesPerPage={this.state.itemsPerPage}
							totalEntries={preparedRows.length}
							pageChange={this.changePage}
							changeNumberOfRows={this.changeNumberOfRows}
						/>
						<div className="table-wrapper">
							<ItemsTable
								rows={rowsData}
								sortColumn={utils.guards.isEnumValue(ItemTableColumn, this.state.sortColumn) ? this.state.sortColumn : null}
								sortAsc={this.state.sortAsc}
								sortTable={this.sortTable}
							/>
						</div>
						<Pagination
							page={this.state.page}
							entriesPerPage={this.state.itemsPerPage}
							totalEntries={preparedRows.length}
							pageChange={this.changePage}
							changeNumberOfRows={this.changeNumberOfRows}
						/>
					</div>
					: <div className="table-wrapper">
						<CurrencyTable
							rows={currencyData}
							sortColumn={utils.guards.isEnumValue(CurrencyTableColumn, this.state.sortColumn) ? this.state.sortColumn : null}
							sortAsc={this.state.sortAsc}
							sortTable={this.sortTable}
						/>
					</div>
				}
			</div>
		);
	}

	componentDidMount(){
		this.setupComponent();
		manager.view.changeComplete();
	}

	setupComponent(){
		this.setupItemData();
		this.isSetup = true;
	}

	setupItemData(){
		const itemRows:ItemRowData[] = [];
		let itemNumber = 1;

		Object.values(Item).forEach((item) => {
			if(item === Item.UNKNOWN){ return; }

			const itemInfo = items[item];

			const costs:ShopCost[] = [];
			if(itemInfo.obtained.shops){
				itemInfo.obtained.shops[0].cost.forEach((cost) => {
					costs.push({
						currency: cost.currency,
						price: cost.price,
					});
				});
			}

			const itemRelics:ItemRelic[] = [];

			itemInfo.used.forEach((used) => {
				if(itemRelics.findIndex((z) => { return z.part === used.relic[RelicIndex.PART]; }) === -1){
					itemRelics.push({
						relic: used.relic[RelicIndex.RELIC],
						part: used.relic[RelicIndex.PART],
						ilvl: manager.relics.getPartILvl(used.relic[RelicIndex.PART]),
					});
				}
			});

			itemRelics.sort((a, b) => {
				const moveUp = this.state.sortAsc ? 1 : -1;
				const moveDown = this.state.sortAsc ? -1 : 1;
				const stay = 0;

				if(t.t(a.part) > t.t(b.part)){ return moveUp; }
				if(t.t(a.part) < t.t(b.part)){ return moveDown; }
				if(t.t(a.part) === t.t(b.part)){
					if(a.ilvl > b.ilvl){ return moveUp; }
					if(a.ilvl < b.ilvl){ return moveDown; }
				}
				return stay;
			});

			const amounts:ItemRowAmount[] = [];
			if(costs.length === 0){
				amounts.push({ inventory: 0, totalCost: 0, totalRequired: 0, totalUsed: 0, totalRemaining: 0, totalToObtain: 0, remainingCost: 0 });
			}else{
				costs.forEach(() => { amounts.push({ inventory: 0, totalCost: 0, totalRequired: 0, totalUsed: 0, totalRemaining: 0, totalToObtain: 0, remainingCost: 0 }); });
			}

			itemRows.push({
				itemNumber: itemNumber,
				item: itemInfo,
				costs: costs,
				itemUsed: itemRelics,
				amounts: amounts,
			});

			itemNumber++;
		});
		this.setState((old:ViewItemsState):ViewItemsState => { return { ...old, allItems: itemRows }; });
	}

	sortTable = (evt:SyntheticEvent) => { // Event
		const ele = evt.currentTarget as HTMLTableCellElement;
		const selectedColumn = ele.dataset.sortfield as string | undefined;
		if(!selectedColumn){ return; }

		if(utils.guards.isEnumValue(ItemTableColumn, selectedColumn)){
			let sortOrder = true;
			let sortColumn:ItemTableColumn|null = selectedColumn;
			if(sortColumn === this.state.sortColumn){
				sortColumn = this.state.sortAsc ? sortColumn : null;
				sortOrder = !this.state.sortAsc;
			}
			this.setState((old:ViewItemsState):ViewItemsState => { return { ...old, sortColumn: sortColumn, sortAsc: sortOrder }; });
		}
	}

	prepareRows(){
		let preparedRows = this.updateRowDetails(this.state.allItems);
		preparedRows = this.filterRows(preparedRows);
		if(this.state.sortColumn){
			preparedRows = this.sortItemRows(preparedRows);
		}
		return preparedRows;
	}

	updateRowDetails(rows:ItemRowData[]):ItemRowData[]{
		rows.forEach((row) => {
			row.amounts.forEach((amount, index) => {
				const costs = row.costs[index];
				const price = costs ? costs.price : 0;
				row.amounts[index] = this.getRowAmount(row.item, price, this.props.itemOptions.showMaximumRequired);
			});
		});
		return rows;
	}

	getRowAmount(itemInfo:ItemInfo, price:number, getMax:boolean){
		const amounts:ItemRowAmount = { inventory: 0, totalCost: 0, totalRequired: 0, totalUsed: 0, totalRemaining: 0, totalToObtain: 0, remainingCost: 0 };

		if(itemInfo.used.length > 0){
			itemInfo.used.forEach((itemUsedInfo) => {
				const inventory = manager.data.getInventoryStatus({
					item: itemInfo.name as Item,
					relic: itemUsedInfo.relic,
					getMax: getMax,
				});
				amounts.inventory = inventory.inventory;
				amounts.totalRequired += inventory.total;
				amounts.totalUsed += inventory.used;
			});
		}else{
			amounts.inventory = manager.data.getInventory(itemInfo.name as Item);
		}

		amounts.totalCost = amounts.totalRequired * price;
		amounts.totalRemaining = amounts.totalRequired - amounts.totalUsed;

		amounts.totalToObtain = amounts.totalRemaining - amounts.inventory;
		amounts.totalToObtain = amounts.totalToObtain < 0 ? 0 : amounts.totalToObtain;

		amounts.remainingCost = amounts.totalToObtain * price;
		return amounts;
	}

	filterRows(rows:ItemRowData[]):ItemRowData[]{
		return rows.filter((row) => { return this.isRowShown(row); });
	}

	isRowShown(row:ItemRowData):boolean{
		const selectedRelics = this.props.itemOptions.relicsFilter;
		const selectedParts = this.props.itemOptions.relicPartsFilter;
		const selectedCurrencies = this.props.itemOptions.currencyFilter;
		const hideNoRemaining = this.props.itemOptions.hideNoRemaining;
		const showOnlyBuyable = this.props.itemOptions.showOnlyBuyable;

		if(hideNoRemaining && row.amounts.findIndex((z) => { return z.totalToObtain <= 0; }) !== -1){ return false; }
		if(showOnlyBuyable && row.costs.length === 0){ return false; }

		if(selectedRelics.length > 0){
			const hasRelic = selectedRelics.some((z) => { return row.itemUsed.some((itemUsed) => { return z === itemUsed.relic; }); });
			if(!hasRelic){ return false; }
		}

		if(selectedParts.length > 0){
			const hasPart = selectedParts.some((z) => { return row.itemUsed.some((itemUsed) => { return z === itemUsed.part; }); });
			if(!hasPart){ return false; }
		}

		if(selectedCurrencies.length > 0){
			const hasCurrency = selectedCurrencies.some((z) => { return row.costs.find((y) => { return y.currency === z; }); });
			if(!hasCurrency){ return false; }
		}

		return true;
	}

	sortCurrencyRows(rows:CurrencyRowData[]):CurrencyRowData[]{
		return [...rows].sort((rowA, rowB) => {
			const moveUp = this.state.sortAsc ? 1 : -1;
			const moveDown = this.state.sortAsc ? -1 : 1;
			const stay = 0;

			let valA:string|number = "";
			let valB:string|number = "";

			switch(this.state.sortColumn){
				default:
				case CurrencyTableColumn.CURRENCY:	valA = rowA.currency;	valB = rowB.currency;	break;
				case CurrencyTableColumn.REQUIRED:	valA = rowA.required;	valB = rowB.required;	break;
				case CurrencyTableColumn.USED:		valA = rowA.used;		valB = rowB.used;		break;
				case CurrencyTableColumn.REMAINING:	valA = rowA.remaining;	valB = rowB.remaining;	break;
			}

			if(valA > valB){ return moveUp; }
			if(valA < valB){ return moveDown; }
			return stay;
		});
	}

	sortItemRows(rows:ItemRowData[]):ItemRowData[]{
		return [...rows].sort((itemA, itemB) => { // eslint-disable-line complexity
			const moveUp = this.state.sortAsc ? 1 : -1;
			const moveDown = this.state.sortAsc ? -1 : 1;
			const stay = 0;

			let valA:string|number = "";
			let valB:string|number = "";

			const itemUsedA = itemA.itemUsed[0];
			const itemUsedB = itemB.itemUsed[0];

			switch(this.state.sortColumn){
				default:
				case ItemTableColumn.ITEM:
					valA = itemA.item.name;
					valB = itemB.item.name;
					break;
				case CurrencyTableColumn.CURRENCY:
				case ItemTableColumn.CURRENCY:
					valA = this.sortAndGetFirstIndexValueToSortOn(itemA.costs, ItemTableColumn.CURRENCY);
					valB = this.sortAndGetFirstIndexValueToSortOn(itemB.costs, ItemTableColumn.CURRENCY);
					break;
				case ItemTableColumn.PRICE:
					valA = this.sortAndGetFirstIndexValueToSortOn(itemA.costs, ItemTableColumn.PRICE);
					valB = this.sortAndGetFirstIndexValueToSortOn(itemB.costs, ItemTableColumn.PRICE);
					break;
				case ItemTableColumn.COST:
					valA = this.sortAndGetFirstIndexValueToSortOn(itemA.amounts, ItemTableColumn.COST);
					valB = this.sortAndGetFirstIndexValueToSortOn(itemB.amounts, ItemTableColumn.COST);
					break;
				case CurrencyTableColumn.REQUIRED:
				case ItemTableColumn.REQUIRED:
					valA = this.sortAndGetFirstIndexValueToSortOn(itemA.amounts, ItemTableColumn.REQUIRED);
					valB = this.sortAndGetFirstIndexValueToSortOn(itemB.amounts, ItemTableColumn.REQUIRED);
					break;
				case CurrencyTableColumn.USED:
				case ItemTableColumn.USED:
					valA = this.sortAndGetFirstIndexValueToSortOn(itemA.amounts, ItemTableColumn.USED);
					valB = this.sortAndGetFirstIndexValueToSortOn(itemB.amounts, ItemTableColumn.USED);
					break;
				case ItemTableColumn.INVENTORY:
					valA = this.sortAndGetFirstIndexValueToSortOn(itemA.amounts, ItemTableColumn.INVENTORY);
					valB = this.sortAndGetFirstIndexValueToSortOn(itemB.amounts, ItemTableColumn.INVENTORY);
					break;
				case CurrencyTableColumn.REMAINING:
				case ItemTableColumn.REMAINING:
					valA = this.sortAndGetFirstIndexValueToSortOn(itemA.amounts, ItemTableColumn.REMAINING);
					valB = this.sortAndGetFirstIndexValueToSortOn(itemB.amounts, ItemTableColumn.REMAINING);
					break;
				case ItemTableColumn.TOOBTAIN:
					valA = this.sortAndGetFirstIndexValueToSortOn(itemA.amounts, ItemTableColumn.TOOBTAIN);
					valB = this.sortAndGetFirstIndexValueToSortOn(itemB.amounts, ItemTableColumn.TOOBTAIN);
					break;
				case ItemTableColumn.REMAININGCOST:
					valA = this.sortAndGetFirstIndexValueToSortOn(itemA.amounts, ItemTableColumn.REMAININGCOST);
					valB = this.sortAndGetFirstIndexValueToSortOn(itemB.amounts, ItemTableColumn.REMAININGCOST);
					break;
				case ItemTableColumn.RELIC:
					if(typeof itemUsedA === "undefined"){ valA = ""; }else{ valA = itemA.itemUsed[0].part; }
					if(typeof itemUsedB === "undefined"){ valB = ""; }else{ valB = itemB.itemUsed[0].part; }
					break;
				case ItemTableColumn.ILVL:
					if(typeof itemUsedA === "undefined"){ valA = -1; }else{ valA = itemUsedA.ilvl; }
					if(typeof itemUsedB === "undefined"){ valB = -1; }else{ valB = itemUsedB.ilvl; }
					break;
			}

			if(valA > valB){ return moveUp; }
			if(valA < valB){ return moveDown; }
			return stay;
		});
	}

	sortAndGetFirstIndexValueToSortOn(arrayToSort:ShopCost[]|ItemRowAmount[], sortColumn:ItemTableColumn):string|number{
		const moveUp = this.state.sortAsc ? 1 : -1;
		const moveDown = this.state.sortAsc ? -1 : 1;
		const stay = 0;
		let valA:string|number = "";
		let valB:string|number = "";
		let firstValue:string|number = "";

		if(arrayToSort.length === 0){ return firstValue; }

		if(utils.guards.isShopCostArray(arrayToSort)){
			arrayToSort.sort((a, b) => {
				switch(sortColumn){
					default: valA = valB = ""; break;
					case ItemTableColumn.PRICE:		valA = a.price;		valB = b.price;		break;
					case ItemTableColumn.CURRENCY:	valA = a.currency;	valB = b.currency;	break;
				}

				if(valA > valB){ return moveUp; }
				if(valA < valB){ return moveDown; }
				return stay;
			});

			switch(sortColumn){
				default: firstValue = ""; break;
				case ItemTableColumn.PRICE: firstValue = arrayToSort[0].price; break;
				case ItemTableColumn.CURRENCY: firstValue = arrayToSort[0].currency; break;
			}
		}else{
			arrayToSort.sort((a, b) => {
				switch(sortColumn){
					default: valA = valB = ""; break;
					case ItemTableColumn.COST:			valA = a.totalCost;			valB = b.totalCost;			break;
					case ItemTableColumn.REQUIRED:		valA = a.totalRequired;		valB = b.totalRequired;		break;
					case ItemTableColumn.USED:			valA = a.totalUsed;			valB = b.totalUsed;			break;
					case ItemTableColumn.INVENTORY:		valA = a.inventory;			valB = b.inventory;			break;
					case ItemTableColumn.REMAINING:		valA = a.totalRemaining;	valB = b.totalRemaining;	break;
					case ItemTableColumn.TOOBTAIN:		valA = a.totalToObtain;		valB = b.totalToObtain;		break;
					case ItemTableColumn.REMAININGCOST:	valA = a.remainingCost;		valB = b.remainingCost;		break;
				}

				if(valA > valB){ return moveUp; }
				if(valA < valB){ return moveDown; }
				return stay;
			});

			switch(sortColumn){
				default: firstValue = ""; break;
				case ItemTableColumn.COST: firstValue = arrayToSort[0].totalCost; break;
				case ItemTableColumn.REQUIRED: firstValue = arrayToSort[0].totalRequired; break;
				case ItemTableColumn.USED: firstValue = arrayToSort[0].totalUsed; break;
				case ItemTableColumn.INVENTORY: firstValue = arrayToSort[0].inventory; break;
				case ItemTableColumn.REMAINING: firstValue = arrayToSort[0].totalRemaining; break;
				case ItemTableColumn.TOOBTAIN: firstValue = arrayToSort[0].totalToObtain; break;
				case ItemTableColumn.REMAININGCOST: firstValue = arrayToSort[0].remainingCost; break;
			}
		}

		return firstValue;
	}


	changePage = (newPage:number) => { // Event
		this.setState((old:ViewItemsState):ViewItemsState => { return { ...old, page: newPage }; });
	}

	changeNumberOfRows = (selectedOptions:MultiValue<RSOption>|SingleValue<RSOption>) => { // Event
		if((selectedOptions instanceof Array)){ return; }

		const selectedValue = selectedOptions?.value;
		if(!selectedValue){ return; }

		this.setState((old:ViewItemsState):ViewItemsState => { return { ...old, itemsPerPage: parseInt(selectedValue) }; });
	}

	getCurrencyTableRows(rows:ItemRowData[]):CurrencyRowData[]{
		const currencyRows:CurrencyRowData[] = [];
		let currencyNumber = 1;

		rows.forEach((row) => {
			row.costs.forEach((cost, index) => {
				const thisCurrencyRow = currencyRows.find((z) => { return z.currency === cost.currency; });
				const thisAmount = row.amounts[index];
				const used = thisAmount.totalUsed * cost.price;

				if(thisCurrencyRow){
					thisCurrencyRow.required += thisAmount.totalCost;
					thisCurrencyRow.used += used;
					thisCurrencyRow.remaining += thisAmount.remainingCost;
				}else{
					currencyRows.push({
						currencyNumber: currencyNumber,
						currency: cost.currency,
						required: thisAmount.totalCost,
						used: used,
						remaining: thisAmount.remainingCost,
					});
					currencyNumber++;
				}
			});
		});
		return currencyRows;
	}
}
export default connect(mapStateToProps)(Items);
