//#region Imports
import BasicIcon from "components/basic-icon/BasicIcon";
import { Component } from "react";
import { connect } from "react-redux";
import t from "translations/translator";
import { AppState, ModalHeaderProps } from "types";
import "./ModalHeader.css";
//#endregion Imports

const mapStateToProps = (state:AppState) => {
	return {
		lang: state.userInfo.options.lang,
		gameLang: state.userInfo.options.gameLang,
	};
};

class ModalHeader extends Component<ModalHeaderProps> {
	render(){
		let iconElement:JSX.Element|null = null;
		if(this.props.icon){ iconElement = <BasicIcon icon={this.props.icon} />; }

		return (
			<div className="modal-header">
				{iconElement}<h2>{t.t(this.props.text)}</h2>{iconElement}
			</div>
		);
	}
}
export default connect(mapStateToProps)(ModalHeader);
