//#region Imports
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ChangeType } from "enums";
import { Component } from "react";
import { connect } from "react-redux";
import t from "translations/translator";
import { AppState, ChangeEntryProps } from "types";
import "./ChangeEntry.css";
//#endregion Imports

const mapStateToProps = (state:AppState) => {
	return {
		lang: state.userInfo.options.lang,
		gameLang: state.userInfo.options.gameLang,
	};
};

class ChangeLogEntry extends Component<ChangeEntryProps> {
	render(){
		const typeClass = Object.keys(ChangeType)[Object.values(ChangeType).indexOf(this.props.type)];
		return (
			<li>
				<div className="change-log-entry">
					<div>
						<FontAwesomeIcon icon={faAngleRight} />
						<span className={typeClass}>{t.t(this.props.type)}</span>
					</div>
					<div>
						<p>{this.props.entry}</p>
					</div>
				</div>
			</li>
		);
	}
}
export default connect(mapStateToProps)(ChangeLogEntry);
