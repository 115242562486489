//#region Imports
import { faQuestion } from "@fortawesome/free-solid-svg-icons";
import FormComponents from "components/forms/FormComponents";
import { Modal } from "enums";
import manager from "managers/app";
import ModalContent from "modals/content/ModalContent";
import ModalFooter from "modals/footer/ModalFooter";
import ModalHeader from "modals/header/ModalHeader";
import { Component } from "react";
import { connect } from "react-redux";
import t from "translations/translator";
import { AppState, ConfirmationModalProps } from "types";
import "./ConfirmationModal.css";
//#endregion Imports

const mapStateToProps = (state:AppState) => {
	return {
		lang: state.userInfo.options.lang,
		gameLang: state.userInfo.options.gameLang,
		confirmation: state.modal[Modal.CONFIRMATION],
	};
};

class ConfirmationModal extends Component<ConfirmationModalProps> {
	render(){
		const options = this.props.confirmation;
		return (
			<div id={Modal.CONFIRMATION} className="modal-wrapper">
				<ModalHeader text={options.title} icon={faQuestion} />
				<ModalContent>
					<p>{t.t(options.text)}</p>
				</ModalContent>
				<ModalFooter>
					<FormComponents.Button text={options.confirm.text} class={["warn"]} onClick={this.handleConfirm} translate={true} />
					<FormComponents.Button text={options.cancel.text} class={["error"]} onClick={this.handleCancel} translate={true} />
				</ModalFooter>
			</div>
		);
	}

	handleConfirm = () => { // Events
		const action = this.props.confirmation.confirm.action;
		if(action){ action(); }
		manager.modal.close();
	}

	handleCancel = () => { // Events
		const action = this.props.confirmation.cancel.action;
		if(action){ action(); }
		manager.modal.close();
	}
}
export default connect(mapStateToProps)(ConfirmationModal);
