import { LocalKeys, Local_2_1_Keys } from "enums";
import log from "logger";
import manager from "managers/app";
import { appEnv } from "managers/env";
import { actions, store } from "managers/state";
import sysMsg from "managers/sysMsg";
import { Character, CharacterV3, DataVersion2, DataVersion3, OldData, UserData, UserInfo, Version } from "types";
import utils from "utils";
import { mapV2CompletedToV3, mapV2InventoryToV3 } from "./mappings/v2";

class OldDataManager {
	async mergeData(existingInfo:UserInfo, oldUpdated:UserData, isRecentUpdate:boolean = false){
		const existingData = existingInfo.data;
		const mergedData = existingData;

		let error = false;
		let anonCharAvailable = false;

		oldUpdated.characters.forEach((oldCharacter) => {
			if(error){ return; }

			const existingCharIndex = existingData.characters.findIndex((z) => { return z.seId === oldCharacter.seId; });

			if(existingCharIndex > -1){
				const existingChar = existingData.characters[existingCharIndex];
				const mergedCharacter = this.mergeCharacter(existingChar, oldCharacter);
				if(mergedCharacter){
					mergedData.characters[existingCharIndex] = mergedCharacter;
				}else{
					error = true;
				}
			}else if(oldCharacter.seId === "0"){
				anonCharAvailable = true;
				this.saveAnonymousChar(oldCharacter);
			}else{
				mergedData.characters.push(oldCharacter);
			}
		});

		if(error){
			sysMsg.warn({
				title: "Import error",
				message: "There was an error importing data, no changes have been made, if the problem persists get in contact",
			});
		}

		const charsSaved = await manager.data.saveCharacters(mergedData.characters, true);
		if(charsSaved && !isRecentUpdate){
			this.clearOldData();
			sysMsg.success({
				title: "Data imported",
				message: `Data imported successfully${anonCharAvailable ? " - Anonymous character data can be merged with a specific character on the Characters page" : ""}`,
			}, 10000);
		}
	}

	setLocalStorage(key:string, value:any){
		log.data(log.DataLocation.LOCAL, log.DataOperation.SAVE, key);
		try{
			const stringified = JSON.stringify(value);
			localStorage.setItem(key, stringified);
			return true;
		}catch(e){
			log.error(e);
			return false;
		}
	}

	clearOldData(){
		localStorage.removeItem(Local_2_1_Keys.DATA);
		localStorage.removeItem(Local_2_1_Keys.VERSION);
	}

	getAnonymousChar():Character|null{
		const anonChar = localStorage.getItem(LocalKeys.ANONCHAR);
		if(!anonChar){ return null; }
		try{
			return JSON.parse(anonChar);
		}catch(e){
			log.error(e);
			return null;
		}
	}

	saveAnonymousChar(anonChar:Character){
		store.dispatch(actions.setAnonymousAvailable(true));
		this.setLocalStorage(LocalKeys.ANONCHAR, anonChar);
	}

	discardAnonymous(){
		store.dispatch(actions.setAnonymousAvailable(false));
		localStorage.removeItem(LocalKeys.ANONCHAR);
	}

	mergeCharacter(targetChar:Character, sourceChar:Character):Character|null{
		utils.helpers.merge(targetChar.progress, sourceChar.progress);
		utils.helpers.merge(targetChar.inventory, sourceChar.inventory);

		const mergedCharacter:Character = {
			seId: targetChar.seId,
			name: targetChar.name,
			displayName: targetChar.displayName,
			avatar: targetChar.avatar,
			achievementsChecked: targetChar.achievementsChecked,
			lastUpdate: new Date(),
			progress: targetChar.progress,
			inventory: targetChar.inventory,
			active: targetChar.active,
		};

		return mergedCharacter;
	}

	restoreFromFileBackup(fileData:{ version: string; user_data:DataVersion2}){
		if(!fileData){ log.warn("No data"); return; }
		if(!fileData.version || fileData.version !== "2.1.1"){ log.warn("Data version not supported"); return; }
		if(!fileData.user_data || !fileData.user_data.data){ log.warn("No character data found"); return; }

		log.data(log.DataLocation.LOCAL, log.DataOperation.SAVE, Local_2_1_Keys.VERSION);
		localStorage.setItem(Local_2_1_Keys.VERSION, fileData.version);

		log.data(log.DataLocation.LOCAL, log.DataOperation.SAVE, Local_2_1_Keys.DATA);
		localStorage.setItem(Local_2_1_Keys.DATA, JSON.stringify(fileData.user_data));
	}

	async getOldData():Promise<[OldData|null, string|null]>{
		let oldData:OldData|null = null;
		let oldVersion:string|null = null;

		[oldData, oldVersion] = this.get2_1_1Data();
		if(oldData && oldVersion){ return [oldData, oldVersion]; }

		[oldData, oldVersion] = await this.get3_1_0Data();
		if(oldData && oldVersion){ return [oldData, oldVersion]; }

		[oldData, oldVersion] = await this.get3_2_0Data();
		if(oldData && oldVersion){ return [oldData, oldVersion]; }

		/*
			When we have another version to check after then include it here eg

			[oldData, oldVersion] = this.get3_1Data();
			if(oldData && oldVersion){ return oldData; }
		*/
		return [oldData, oldVersion];
	}

	private get2_1_1Data():[DataVersion2|null, string|null]{
		log.data(log.DataLocation.LOCAL, log.DataOperation.FETCH, Local_2_1_Keys.VERSION);
		const oldVersion = localStorage.getItem(Local_2_1_Keys.VERSION);

		log.data(log.DataLocation.LOCAL, log.DataOperation.FETCH, Local_2_1_Keys.DATA);
		const oldDataStr = localStorage.getItem(Local_2_1_Keys.DATA);

		if(!oldVersion || !oldDataStr){
			log.info("No V2 data found");
			return [null, null];
		}

		if(oldVersion !== "2.1.1"){
			log.info("Only V2.1.1 data is supported");
			return [null, null];
		}

		try{
			const oldData = JSON.parse(oldDataStr);
			this.setLocalStorage(LocalKeys.BACKUP, oldData);
			return [oldData, oldVersion];
		}catch(e){
			log.warn("Unable to parse V2.1.1 data");
			return [null, null];
		}
	}

	private async get3_1_0Data():Promise<[DataVersion3|null, string|null]>{
		const oldInfo = await manager.data.getCurrentData();
		return [oldInfo?.data ?? null, oldInfo?.data.version ? null : "3.1.0"];
	}

	private async get3_2_0Data():Promise<[DataVersion3|null, string|null]>{
		const oldInfo = await manager.data.getCurrentData();
		return [oldInfo?.data ?? null, oldInfo?.data.version === "3.2.0" ? oldInfo.data.version : null];
	}

	updateData(oldData:OldData, oldVersion:string):UserData|null{
		let updatedData:OldData|null = oldData;
		let updatedVersion = utils.helpers.getVersion(oldVersion);

		if(updatedData && updatedVersion.major === 2){ [updatedData, updatedVersion] = this.convertFromVersion2(updatedData as DataVersion2, updatedVersion); }
		if(updatedData && updatedVersion.major === 3){ [updatedData, updatedVersion] = this.convertFromVersion3(updatedData as DataVersion3, updatedVersion); }

		if(!updatedData || !this.assertLatestVersion(updatedData, updatedVersion)){ return null; }
		return updatedData;
	}

	private assertLatestVersion(data:OldData, version:Version): data is UserData{
		const appVersion = store.getState().appVersion;
		const dataVersion = `${version.major}.${version.minor}.${version.patch}`;
		if(appVersion === dataVersion){
			return true;
		}
		return false;
	}

	//#region Version 2 Converters
	private convertFromVersion2(dataV2:DataVersion2, version:Version):[DataVersion3|null, Version]{
		if(!dataV2){ return [null, version]; }

		let updatedData:any = dataV2;
		let updatedVersion = version;
		if(version.minor === 1){ [updatedData, updatedVersion] = this.convertFromVersion2_1(updatedData, updatedVersion); } // Note: This will return DataVersion3 as there are no additional version here to update
		return [updatedData, updatedVersion];
	}

	private convertFromVersion2_1(dataV2:DataVersion2, version:Version):[DataVersion3|null, Version]{
		log.debug("Data to convert: ", dataV2);

		const dataV3:DataVersion3 = { version: "3.1.0", characters: [] };

		dataV2.data.forEach((charV2, index) => {
			const mappedInventory = mapV2InventoryToV3(charV2.inventory);
			const mappedProgress = mapV2CompletedToV3(charV2.completed);

			const charV3:CharacterV3 = {
				seId: charV2.character_id,
				name: charV2.character_name,
				displayName: charV2.character_name,
				avatar: charV2.character_avatar,
				achievementsChecked: new Date(charV2.last_achievement_check * 1000),
				lastUpdate: new Date(),
				progress: mappedProgress,
				inventory: mappedInventory,
				active: dataV2.character === index,
			};
			dataV3.characters.push(charV3);
		});

		const updatedVersion = version;
		updatedVersion.major = 3;
		updatedVersion.minor = 1;
		updatedVersion.patch = 0;
		return [dataV3, updatedVersion];
	}
	//#endregion Version 2 Converters

	//#region Version 3 Converters
	private convertFromVersion3(dataV3:DataVersion3, version:Version):[UserData|null, Version]{
		if(!dataV3){ return [null, version]; }
		let updatedData:any = dataV3;
		let updatedVersion = version;

		if(version.minor === 1){ [updatedData, updatedVersion] = this.convertFromVersion3_1(updatedData, updatedVersion); }

		// No data structure changes from version 3.2.0 to 3.3.0
		updatedVersion = utils.helpers.getVersion(appEnv.version);
		updatedData.version = appEnv.version;
		return [updatedData, updatedVersion];
	}

	private convertFromVersion3_1(dataV3_1:DataVersion3, version:Version):[DataVersion3|null, Version]{
		log.debug("Data to convert: ", dataV3_1);

		const dataV3_2:DataVersion3 = { version: "3.2.0", characters: dataV3_1.characters };

		const updatedVersion = version;
		updatedVersion.major = 3;
		updatedVersion.minor = 2;
		updatedVersion.patch = 0;
		return [dataV3_2, updatedVersion];
	}
	//#endregion Version 3 Converters
}

const oldDataManager = new OldDataManager();
export default oldDataManager;
