//#region Imports
import { Component } from "react";
import { connect } from "react-redux";
import { AppState, MenuItemOptions, MenuItemWithSubMenu, MenuItemWithView, ViewSubMenuProps } from "types";
import MenuItem from "./MenuItem";
//#endregion Imports

const mapStateToProps = (state:AppState) => {
	return {
		lang: state.userInfo.options.lang,
		gameLang: state.userInfo.options.gameLang,
	};
};

class SubMenu extends Component<ViewSubMenuProps> {
	render(){
		const sub_menu = this.props.options.submenu;
		if(!sub_menu){ return null; }

		return (
			<div className={`sub-menu`}>
				{sub_menu.map((menu) => {
					const options:MenuItemOptions = {
						background: this.props.options.background === "primary" ? "secondary" : "primary",
						menuItemView: this.hasSubMenu(menu) ? false as false : menu.view,
						submenu: this.hasSubMenu(menu) ? menu.submenu : false as false,
						headers: [...this.props.options.headers, menu.header],
					};
					return (
						<MenuItem key={`menu_${menu.header}_${menu.id}`} options={options} />
					);
				})}
			</div>
		);
	}

	private hasSubMenu(menuItem:MenuItemWithView|MenuItemWithSubMenu): menuItem is MenuItemWithSubMenu{
		if(typeof (menuItem as MenuItemWithSubMenu).submenu === "undefined"){
			return false;
		}
		return true;
	}
}
export default connect(mapStateToProps)(SubMenu);
