//#region imports
import BasicIcon from "components/basic-icon/BasicIcon";
import { Component } from "react";
import t from "translations/translator";
import { FormButtonProps, OutputString } from "types";
import utils from "utils";
import { FormInputWrapper } from "./FormInputWrapper";
//#endregion Imports

export class FormButton extends Component<FormButtonProps> {
	render(){
		let buttonText:string|null = null;

		const dataAttrs = this.props.dataAttributes ? utils.helpers.convertToDataAttributes(this.props.dataAttributes) : null;
		const buttonClasses = [...this.props.class ? this.props.class : []];

		buttonClasses.push("rt-button");

		if(this.props.text || this.props.text === ""){
			buttonText = this.props.translate === false ? this.props.text : t.t(this.props.text as OutputString, this.props.placeholders);
		}else{
			buttonClasses.push("rt-icon-wrapper");
		}

		if(this.props.asLink){ buttonClasses.push("link"); } else
		if(this.props.asPlainLink){ buttonClasses.push("link", "plain"); }

		const button = <button
			className={buttonClasses.join(" ")}
			onClick={this.props.onClick}
			disabled={this.props.disabled}
			title={this.props.tooltip}
			{...dataAttrs}
		>
			{this.props.icon ? <BasicIcon icon={this.props.icon} tooltip={this.props.tooltip} placeholders={this.props.placeholders ? this.props.placeholders : {}} class={this.props.iconClass} /> : null}
			<span>{buttonText}</span>
		</button>;

		return this.props.inline
			? button
			: <FormInputWrapper>{button}</FormInputWrapper>;
	}
}
