//#region Imports
import { Component, SyntheticEvent } from "react";
import t from "translations/translator";
import { FormTextAreaProps, FormTextAreaState } from "types";
import { FieldEdit } from "./FieldEdit";
import { FormFieldWrapper } from "./FormFieldWrapper";
import { FormInputWrapper } from "./FormInputWrapper";
import { FormLabel } from "./FormLabel";
//#endregion Imports

export class FormTextArea extends Component<FormTextAreaProps> {
	state:FormTextAreaState = { edit: true, overrideDisabled: false, originalValue: "", currentValue: "" }
	render(){
		const label = this.props.label;
		const input = this.props.input;
		const hidden = this.props.hidden;

		if(this.state.overrideDisabled){ input.disabled = this.state.edit; }

		return (
			<FormFieldWrapper label={label} width={this.props.fieldWidth}>
				<FormLabel {...this.props.label} />
				<FormInputWrapper>
					<textarea
						className={`field-input ${input.class ? input.class : null}`}
						name={input.name}
						placeholder={input.placeholder ? t.t(input.placeholder) : undefined } // eslint-disable-line no-undefined
						autoComplete={input.autoComplete}
						value={this.state.currentValue}
						disabled={input.disabled}
						required={input.required}
						rows={input.rows}
						{...input.events}
						onChange={this.doChangeEvents}
					/>
					{ hidden && <input
						type="hidden"
						name={hidden.name}
						value={hidden.value}
					/> }
					{input.showEditButton && <FieldEdit edit={this.state.edit} action={this.editClicked} /> }
				</FormInputWrapper>
			</FormFieldWrapper>
		);
	}

	componentDidMount = () => {
		const value:string = typeof this.props.input.value === "string" ? this.props.input.value : "";
		this.setState((old:FormTextAreaState):FormTextAreaState => { return { ...old, originalValue: value, currentValue: value }; });
	}

	doChangeEvents = (evt:SyntheticEvent) => {
		this.checkModified(evt);

		const inputEle = evt.currentTarget as HTMLTextAreaElement;
		this.setState((old:FormTextAreaState):FormTextAreaState => { return { ...old, currentValue: inputEle.value }; });

		const input = this.props.input;
		if(input.events && input.events.onChange){
			input.events.onChange(evt);
		}
	}

	checkModified = (evt:SyntheticEvent) => {
		const input = evt.currentTarget as HTMLTextAreaElement;
		const hiddenInput = input.nextElementSibling as HTMLTextAreaElement | null;
		if(!hiddenInput){ return; }

		if(input.value === hiddenInput.value){
			input.classList.remove("hasChanged");
		}else{
			input.classList.add("hasChanged");
		}
	}

	editClicked = () => {
		const editable = !this.state.edit;
		this.setState((old:FormTextAreaState):FormTextAreaState => { return { ...old, edit: editable, overrideDisabled: true }; });
	}
}
