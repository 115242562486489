//#region Imports
import { availableRelicIDs } from "data-files/relic-ids";
import { MapLocation, RelicGroup, RelicPart, RelicStep, RelicTask, RelicType } from "enums";
import log from "logger";
import gameTranslations from "translations/game-translations";
import translations from "translations/site-translations";
import t from "translations/translator";
import { DataAttributes, OutputGameString, OutputString, Relic, RelicID, ShopCost, TimeInterval, Version } from "types";
//#endregion Imports

const utils = {
	helpers: {
		getWindowWidth,
		merge,
		timeSince,
		getDateInSeconds,
		addMinutesToDate,
		convertToDataAttributes,
		sleep,
		getVersion,
		getPercent,
		getPaginationIndexes,
		getRelicFromId,
	},
	guards: {
		isOutputString,
		isOutputGameString,
		isEnumValue,
		isMapLocationArray,
		isRelicId,
		isShopCostArray,
		isShopCost,
	},
};

export default utils;

//#region Variables
const intervals:TimeInterval[] = [
	{ single: "year", plural: "years", seconds: 60 * 60 * 24 * 30 * 12 },
	{ single: "month", plural: "months", seconds: 60 * 60 * 24 * 30 },
	{ single: "day", plural: "days", seconds: 60 * 60 * 24 },
	{ single: "hour", plural: "hours", seconds: 60 * 60 },
	{ single: "minute", plural: "minutes", seconds: 60 },
	{ single: "second", plural: "seconds", seconds: 1 },
];
//#endregion Variables

//#region Util Functions
function getWindowWidth():number{
	return Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
}

function merge(...args:object[]|Array<any>[]){ // Modifies the first object in args
	if(args.length === 0){ return; }
	if(args.length === 1){ return; }

	const mergedObject:{ [key: string]: any; } = args[0];

	args.forEach((source:{ [key: string]: any; }) => {
		for(const prop in source){
			if(Object.prototype.hasOwnProperty.call(source, prop)){
				const sourceObj = source[prop];

				if(sourceObj instanceof Array){
					if(!mergedObject[prop]){ mergedObject[prop] = []; }
					sourceObj.forEach((value) => {
						if(!mergedObject[prop].includes(value)){ mergedObject[prop].push(value); }
					});
				}else if(sourceObj instanceof Object){
					if(!mergedObject[prop]){ mergedObject[prop] = {}; }

					const merged = merge(mergedObject[prop], sourceObj);
					Object.assign(mergedObject[prop], merged);
				}else if(typeof sourceObj === "string" || typeof sourceObj === "number"){
					mergedObject[prop] = sourceObj;
				}
			}
		}
	});
}

function getDateInSeconds(time:Date|null):number{
	return time
		? Math.floor(time.getTime() / 1000)
		: Math.floor(Date.now() / 1000);
}

function addMinutesToDate(time:Date, minutes:number):Date{
	return new Date(time.getTime() + (minutes * 60 * 1000)); // eslint-disable-line no-param-reassign
}

function timeSince(time:Date|null):string{
	if(!time){ return t.t("Never"); }

	const now = getDateInSeconds(null);
	const seconds = getDateInSeconds(time);
	const timePassed = now - seconds;

	if(timePassed < 10){ return t.t("Just Now"); }

	const interval = intervals.find((z) => { return z.seconds < timePassed; });
	if(!interval){ return "Error"; }

	const count = Math.floor(timePassed / interval?.seconds);
	const intervalName = count > 1 ? interval.plural : interval.single;

	return t.t("%COUNT% %INTERVAL% ago", { COUNT: count.toString(), INTERVAL: intervalName});
}

function convertToDataAttributes(attrs:DataAttributes):DataAttributes{
	const dataAttrs:DataAttributes = {};
	for(const prop in attrs){
		if(Object.prototype.hasOwnProperty.call(attrs, prop)){
			dataAttrs[`data-${prop}`] = attrs[prop];
		}
	}
	return dataAttrs;
}

function sleep(time:number){
	return new Promise((resolve) => { setTimeout(resolve, time); });
}

function getVersion(version:string):Version{
	const versionParts = version.split(".").map((z) => { return parseInt(z); });
	return {
		major: versionParts[0] ? versionParts[0] : 0,
		minor: versionParts[1] ? versionParts[1] : 0,
		patch: versionParts[2] ? versionParts[2] : 0,
	};
}

function getPercent(numberOfThings:number, totalThings:number):number{
	const percent = Math.floor(numberOfThings / totalThings * 100);
	if(isNaN(percent)){
		return 0;
	}
	return percent;
}

function getPaginationIndexes(page:number, entriesPerPage:number, totalEntries:number, changePage:(newPage:number) => void):{ start:number, end:number }{
	let start = (page - 1) * entriesPerPage;
	let end = start + entriesPerPage;

	if(start > totalEntries){
		changePage(1);
		start = end = 0;
	}

	if(end > totalEntries){
		end = totalEntries;
	}

	return { start, end };
}

function getRelicFromId(relicId:RelicID):Relic{
	const splitId = relicId.split("-");
	return [
		splitId[0] as RelicType,
		splitId[1] as RelicGroup,
		splitId[2] as RelicPart,
		splitId[3] as RelicStep,
		splitId[4] as RelicTask,
	];
}

//#endregion Util Functions

//#region Type Guards
function isOutputString(maybeOutputString:any): maybeOutputString is OutputString{
	const translationValues = Object.keys(translations);
	if(translationValues.includes(maybeOutputString)){
		return true;
	}

	return false;
}

function isOutputGameString(maybeOutputGameString:any): maybeOutputGameString is OutputGameString{
	const gameTranslationValues = Object.keys(gameTranslations);
	if(gameTranslationValues.includes(maybeOutputGameString)){
		return true;
	}

	return false;
}

function isEnumValue<T extends {}>(enumToTest:T, token: unknown): token is T[keyof T]{
	const keys = Object.keys(enumToTest).filter((k) => { return !(/^\d/).test(k); }); // Get enum keys, filter out the "number" keys
	const values = keys.map((k) => { return (enumToTest as any)[k]; }); // Get enum values, from filtered keys
	return values.includes(token);
}

function isMapLocationArray(maybeLocationArray:any[]):maybeLocationArray is MapLocation[]{
	if(isEnumValue(MapLocation, maybeLocationArray[0])){
		return true;
	}
	return false;
}

function isRelicId(maybeRelicID:any):maybeRelicID is RelicID{
	if(availableRelicIDs.includes(maybeRelicID)){
		return true;
	}

	log.debug(`${maybeRelicID} is not a Relic ID`);

	return false;
}

function isShopCostArray(maybeShopCostArray:any[]):maybeShopCostArray is ShopCost[]{
	const firstValue = maybeShopCostArray[0];
	return isShopCost(firstValue);
}

function isShopCost(maybeShopCost:any):maybeShopCost is ShopCost{
	return maybeShopCost.price && maybeShopCost.currency;
}
//#endregion Type Guards
