//#region Imports
import ChangeEntry from "components/change-entry/ChangeEntry";
import { ChangeType } from "enums";
import manager from "managers/app";
import { Component } from "react";
import { connect } from "react-redux";
import t from "translations/translator";
import { AppState, ViewLatestChangesProps } from "types";
//#endregion Imports

const mapStateToProps = (state:AppState) => {
	return {
		lang: state.userInfo.options.lang,
		gameLang: state.userInfo.options.gameLang,
		appVersion: state.appVersion,
	};
};

class LatestChanges extends Component<ViewLatestChangesProps> {
	render(){
		return (
			<div className="recent-changes">
				<h2>{t.t("Latest Changes")}</h2>
				<h3>{`${t.t("Version")} ${this.props.appVersion}`}</h3>
				<ul>
					<ChangeEntry type={ChangeType.UPDATED} entry="Updated the way fetching game server information is obtained, Added missing Servers" />
					<ChangeEntry type={ChangeType.UPDATED} entry="Currency for Manderville relic items" />
					<ChangeEntry type={ChangeType.UPDATED} entry="Updated App/Data Version handling" />
				</ul>
			</div>
		);
	}

	componentDidMount = () => {
		manager.view.changeComplete();
	}
}
export default connect(mapStateToProps)(LatestChanges);
