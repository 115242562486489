//#region Imports
import { faPencilAlt, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Component } from "react";
import { FieldEditProps } from "types";
//#endregion Imports

export class FieldEdit extends Component<FieldEditProps> {
	render(){
		const icon = this.props.edit ? faPencilAlt : faTimesCircle;

		return (
			<div className={`field-edit`}>
				<FontAwesomeIcon icon={icon} onClick={this.props.action}/>
			</div>
		);
	}
}
