//#region Imports
import BasicIcon from "components/basic-icon/BasicIcon";
import FormComponents from "components/forms/FormComponents";
import Link from "components/link/Link";
import { trials } from "data-files/duties";
import { locations } from "data-files/locations";
import { quests } from "data-files/quests";
import { Icon, LinkType, MapLocation, Modal, Trial } from "enums";
import manager from "managers/app";
import ModalContent from "modals/content/ModalContent";
import ModalFooter from "modals/footer/ModalFooter";
import ModalHeader from "modals/header/ModalHeader";
import { Component } from "react";
import { connect } from "react-redux";
import t from "translations/translator";
import { AppState, ObtainedLocation, OutputString, TrialInfo, TrialsModalProps } from "types";
import utils from "utils";
import "./TrialsModal.css";
//#endregion Imports

const mapStateToProps = (state:AppState) => {
	return {
		lang: state.userInfo.options.lang,
		gameLang: state.userInfo.options.gameLang,
		trials: state.modal[Modal.TRIALS],
	};
};

class TrialsModal extends Component<TrialsModalProps> {
	render(){
		return (
			<div id={Modal.TRIALS} className="modal-wrapper">
				<ModalHeader text="Trials" icon={Icon.TRIAL} />
				<ModalContent>
					<div className="trial-wrapper">
						{this.props.trials.map((obtainedTrial) => {
							let isTrial = false;
							let trial = trials[Trial.UNKNOWN];
							let obtainedLocation:ObtainedLocation = { location: MapLocation.UNKNOWN, x: -1, y: -1 };

							if(utils.guards.isEnumValue(Trial, obtainedTrial)){
								trial = trials[obtainedTrial];
								isTrial = true;
							}else if(utils.guards.isEnumValue(MapLocation, obtainedTrial)){
								obtainedLocation = { location: obtainedTrial, x: -1, y: -1 };
							}

							return isTrial ? this.getTrialCard(trial) : this.getLocationCard(obtainedLocation);
						})}
					</div>
				</ModalContent>
				<ModalFooter>
					<FormComponents.Button text="Close" onClick={manager.modal.close} translate={true} />
				</ModalFooter>
			</div>
		);
	}

	getTrialCard(trial:TrialInfo):JSX.Element{
		const unlockQuest = quests[trial.unlocked];
		return <div key={trial.name} className="trial-card">
			<h3 className="trial-name"><Link type={LinkType.LODESTONE} lodestoneType={trial.type} lodestoneID={trial.lodestoneID} text={trial.name} /></h3>
			<p className="trial-unlocked">
				<span>{t.t("Quest to unlock")}</span><Link type={LinkType.LODESTONE} lodestoneType={unlockQuest.type} lodestoneID={unlockQuest.lodestoneID} text={unlockQuest.name} />
			</p>
		</div>;
	}

	getLocationCard(obtainedLocation:ObtainedLocation):JSX.Element{
		const locationInfo = locations[obtainedLocation.location];
		let trialName:OutputString = "Any Trials";

		if(
			obtainedLocation.location === MapLocation.BOZJA
			|| obtainedLocation.location === MapLocation.BOZJA_SOUTH
			|| obtainedLocation.location === MapLocation.BOZJA_MIDDLE
			|| obtainedLocation.location === MapLocation.BOZJA_NORTH
			|| obtainedLocation.location === MapLocation.ZADNOR
			|| obtainedLocation.location === MapLocation.ZADNOR_SOUTH
			|| obtainedLocation.location === MapLocation.ZADNOR_MIDDLE
			|| obtainedLocation.location === MapLocation.ZADNOR_NORTH
		){
			trialName = "Any Critical Engagement";
		}

		return <div key={locationInfo.region ? locationInfo.region : locationInfo.map} className="trial-card">
			<h3 className="trial-name">{t.t(trialName)}</h3>
			<div className="trial-location-wrapper">
				<div className="trial-location">
					<span><BasicIcon icon={Icon.MAP} tooltip="Map" /> {t.t(locationInfo.map)}</span>
					{locationInfo.region ? <span><BasicIcon icon={Icon.REGION} tooltip="Region" /> {locationInfo.region ? t.t(locationInfo.region) : null}</span> : null}
					{locationInfo.teleport ? <span><BasicIcon icon={Icon.TELEPORT} tooltip="Teleport" /> {t.t(locationInfo.teleport)}</span> : null}
				</div>
			</div>
		</div>;
	}
}
export default connect(mapStateToProps)(TrialsModal);
