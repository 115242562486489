//#region Breakpoints
export enum Breakpoint {
	DESKTOP = "desktop",
	TABLET = "tablet",
	MOBILE = "mobile",
}
//#endregion Breakpoints

//#region Environment Types
export enum EnvironmentType {
	LOCAL = "local",
	DEV = "dev",
	PRODUCTION = "production"
}
//#endregion Environment Types

//#region Languages
export enum Language {
	TEST = "AL",
	EN = "EN",
	FR = "FR",
	ES = "ES",
	DE = "DE",
}

export enum GameLanguage {
	TEST = "GL",
	EN = "EN",
	FR = "FR",
	DE = "DE",
	JP = "JP",
}

export enum LanguageType {
	SITE = "site",
	GAME = "game",
}
//#endregion Languages

//#region Themes
export enum Theme {
	DARK = "dark",
	LIGHT = "light",
}
//#endregion Themes

//#region Views / Pages
export enum View {
	PAGE_NOT_FOUND = "page_not_found",
	HOME = "home",
	CHARACTERS = "characters",
	PROGRESS = "progress",
	ITEMS = "items",
	GALLERY = "gallery",
	TIPS = "tips",
	CHANGE_LOG = "change_log",
	TRANSLATIONS = "translations",
	RELIC_WEAPONS_ZODIAC_OVERALL = "relic_weapon-g_w_zodiac-overall", // Weapon - Zodiac
	RELIC_WEAPONS_ZODIAC_BASE_WEAPON = "relic_weapon-g_w_zodiac-p_w_base_weapon",
	RELIC_WEAPONS_ZODIAC_ZENITH = "relic_weapon-g_w_zodiac-p_w_zenith",
	RELIC_WEAPONS_ZODIAC_ATMA = "relic_weapon-g_w_zodiac-p_w_atma",
	RELIC_WEAPONS_ZODIAC_ANIMUS = "relic_weapon-g_w_zodiac-p_w_animus",
	RELIC_WEAPONS_ZODIAC_NOVUS = "relic_weapon-g_w_zodiac-p_w_novus",
	RELIC_WEAPONS_ZODIAC_NEXUS = "relic_weapon-g_w_zodiac-p_w_nexus",
	RELIC_WEAPONS_ZODIAC_ZODIAC_BRAVES = "relic_weapon-g_w_zodiac-p_w_braves",
	RELIC_WEAPONS_ZODIAC_ZODIAC_ZETA = "relic_weapon-g_w_zodiac-p_w_zeta",
	RELIC_WEAPONS_ANIMA_OVERALL = "relic_weapon-g_w_anima-overall", // Weapon - Anima
	RELIC_WEAPONS_ANIMA_ANIMATED = "relic_weapon-g_w_anima-p_w_animated",
	RELIC_WEAPONS_ANIMA_AWOKEN = "relic_weapon-g_w_anima-p_w_awoken",
	RELIC_WEAPONS_ANIMA_ANIMA = "relic_weapon-g_w_anima-p_w_anima",
	RELIC_WEAPONS_ANIMA_HYPERCONDUCTIVE = "relic_weapon-g_w_anima-p_w_hyperconductive",
	RELIC_WEAPONS_ANIMA_RECONDITIONED = "relic_weapon-g_w_anima-p_w_reconditioned",
	RELIC_WEAPONS_ANIMA_SHARPENED = "relic_weapon-g_w_anima-p_w_sharpened",
	RELIC_WEAPONS_ANIMA_COMPLETE = "relic_weapon-g_w_anima-p_w_complete",
	RELIC_WEAPONS_ANIMA_LUX = "relic_weapon-g_w_anima-p_w_lux",
	RELIC_WEAPONS_EUREKA_OVERALL = "relic_weapon-g_w_eureka-overall", // Weapon - Eureka
	RELIC_WEAPONS_EUREKA_ANTIQUATED = "relic_weapon-g_w_eureka-p_w_antiquated",
	RELIC_WEAPONS_EUREKA_ANEMOS = "relic_weapon-g_w_eureka-p_w_anemos",
	RELIC_WEAPONS_EUREKA_ELEMENTAL = "relic_weapon-g_w_eureka-p_w_elemental",
	RELIC_WEAPONS_EUREKA_PYROS = "relic_weapon-g_w_eureka-p_w_pyros",
	RELIC_WEAPONS_EUREKA_EUREKA = "relic_weapon-g_w_eureka-p_w_eureka",
	RELIC_WEAPONS_EUREKA_PHYSEOS = "relic_weapon-g_w_eureka-p_w_physeos",
	RELIC_WEAPONS_RESISTANCE_OVERALL = "relic_weapon-g_w_resistance-overall", // Weapon - Resistance
	RELIC_WEAPONS_RESISTANCE_RESISTANCE = "relic_weapon-g_w_resistance-p_w_resistance",
	RELIC_WEAPONS_RESISTANCE_AUGMENTED_RESISTANCE = "relic_weapon-g_w_resistance-p_w_augmented_resistance",
	RELIC_WEAPONS_RESISTANCE_RECOLLECTION = "relic_weapon-g_w_resistance-p_w_recollection",
	RELIC_WEAPONS_RESISTANCE_LAWS_ORDER = "relic_weapon-g_w_resistance-p_w_laws_order",
	RELIC_WEAPONS_RESISTANCE_AUGMENTED_LAWS_ORDER = "relic_weapon-g_w_resistance-p_w_augmented_laws_order",
	RELIC_WEAPONS_RESISTANCE_BLADE = "relic_weapon-g_w_resistance-p_w_blade",
	RELIC_WEAPONS_MANDERVILLE_OVERALL = "relic_weapon-g_w_manderville-overall", // Weapon - Manderville
	RELIC_WEAPONS_MANDERVILLE_MANDERVILLE = "relic_weapon-g_w_manderville-p_w_manderville",
	RELIC_WEAPONS_MANDERVILLE_AMAZING_MANDERVILLE = "relic_weapon-g_w_manderville-p_w_amazing_manderville",
	RELIC_WEAPONS_MANDERVILLE_MAJESTIC_MANDERVILLE = "relic_weapon-g_w_manderville-p_w_majestic_manderville",
	RELIC_WEAPONS_MANDERVILLE_MANDERVILLOUS = "relic_weapon-g_w_manderville-p_w_mandervillous",
	RELIC_TOOLS_LUCIS_OVERALL = "relic_tool-g_t_lucis-overall", // Tool - Lucis
	RELIC_TOOLS_LUCIS_MASTERCRAFT = "relic_tool-g_t_lucis-p_t_mastercraft",
	RELIC_TOOLS_LUCIS_SUPRA = "relic_tool-g_t_lucis-p_t_supra",
	RELIC_TOOLS_LUCIS_LUCIS = "relic_tool-g_t_lucis-p_t_lucis",
	RELIC_TOOLS_SKYSTEEL_OVERALL = "relic_tool-g_t_skysteel-overall", // Tool - Skysteel
	RELIC_TOOLS_SKYSTEEL_BASE_SKYSTEEL = "relic_tool-g_t_skysteel-p_t_skysteel",
	RELIC_TOOLS_SKYSTEEL_SKYSTEEL_1 = "relic_tool-g_t_skysteel-p_t_skysteel_1",
	RELIC_TOOLS_SKYSTEEL_DRAGONSUNG = "relic_tool-g_t_skysteel-p_t_dragonsung",
	RELIC_TOOLS_SKYSTEEL_AUGMENTED_DRAGONSUNG = "relic_tool-g_t_skysteel-p_t_augmented_dragonsung",
	RELIC_TOOLS_SKYSTEEL_SKYSUNG = "relic_tool-g_t_skysteel-p_t_skysung",
	RELIC_TOOLS_SKYSTEEL_SKYBUILDERS = "relic_tool-g_t_skysteel-p_t_skybuilders",
	RELIC_TOOLS_SPLENDOROUS_OVERALL = "relic_tool-g_t_splendorous-overall", // Tool - Splendorous
	RELIC_TOOLS_SPLENDOROUS_SPLENDOROUS = "relic_tool-g_t_splendorous-p_t_splendorous",
	RELIC_TOOLS_SPLENDOROUS_AUGMENTED_SPLENDOROUS = "relic_tool-g_t_splendorous-p_t_augmented_splendorous",
	RELIC_TOOLS_SPLENDOROUS_CRYSTALLINE = "relic_tool-g_t_splendorous-p_t_crystalline",
	RELIC_TOOLS_SPLENDOROUS_CHORA_ZOIS_CRYSTALLINE = "relic_tool-g_t_splendorous-p_t_chora_zois_crystalline",
	RELIC_TOOLS_SPLENDOROUS_BRILLIANT = "relic_tool-g_t_splendorous-p_t_brilliant",
	RELIC_TOOLS_SPLENDOROUS_VRANDTIC_VISIONARYS = "relic_tool-g_t_splendorous-p_t_vrandtic_visionarys",
	RELIC_TOOLS_SPLENDOROUS_LODESTAR = "relic_tool-g_t_splendorous-p_t_lodestar",
	RELIC_ARMOUR_ANEMOS_OVERALL = "relic_armour-g_a_anemos-overall", // Armour - Eureka Anemos
	RELIC_ARMOUR_ANEMOS_ANTIQUATED_ARMOUR = "relic_armour-g_a_anemos-p_a_antiquated",
	RELIC_ARMOUR_ANEMOS_BASE_0 = "relic_armour-g_a_anemos-p_a_base_0",
	RELIC_ARMOUR_ANEMOS_BASE_1 = "relic_armour-g_a_anemos-p_a_base_1",
	RELIC_ARMOUR_ANEMOS_BASE_2 = "relic_armour-g_a_anemos-p_a_base_2",
	RELIC_ARMOUR_ANEMOS_ANEMOS_ARMOUR = "relic_armour-g_a_anemos-p_a_anemos",
	RELIC_ARMOUR_ELEMENTAL_OVERALL = "relic_armour-g_a_elemental-overall", // Armour - Eureka Elemental
	RELIC_ARMOUR_ELEMENTAL_ELEMENTAL_ARMOUR = "relic_armour-g_a_elemental-p_a_elemental",
	RELIC_ARMOUR_ELEMENTAL_ELEMENTAL_1 = "relic_armour-g_a_elemental-p_a_elemental_1",
	RELIC_ARMOUR_ELEMENTAL_ELEMENTAL_2 = "relic_armour-g_a_elemental-p_a_elemental_2",
	RELIC_ARMOUR_BOZJAN_OVERALL = "relic_armour-g_a_bozjan-overall", // Armour - Resistance Bozjan
	RELIC_ARMOUR_BOZJAN_BOZJAN = "relic_armour-g_a_bozjan-p_a_bozjan",
	RELIC_ARMOUR_BOZJAN_AUGMENTED_BOZJAN = "relic_armour-g_a_bozjan-p_a_augmented_bozjan",
	RELIC_ARMOUR_LAWS_ORDER_OVERALL = "relic_armour-g_a_laws_order-overall", // Resistance Law's Order
	RELIC_ARMOUR_LAWS_ORDER_LAWS_ORDER = "relic_armour-g_a_laws_order-p_a_laws_order",
	RELIC_ARMOUR_LAWS_ORDER_AUGMENTED_LAWS_ORDER = "relic_armour-g_a_laws_order-p_a_augmented_laws_order",
	RELIC_ARMOUR_BLADES_OVERALL = "relic_armour-g_a_blades-overall", // Resistance Blade's
	RELIC_ARMOUR_BLADES_BLADES = "relic_armour-g_a_blades-p_a_blades",
}
//#endregion Views / Pages

//#region Notices
export enum NoticeType {
	WARN,
	ERROR,
	INFO,
}
//#endregion Notices

//#region Modals
export enum Modal {
	CONFIRMATION = "modal-confirmation",
	ENEMIES = "modal-enemies",
	RECIPES = "modal-recipes",
	MARKETS = "modal-markets",
	EXTRACTIONS = "modal-extractions",
	GATHERING = "modal-gathering",
	FISHING = "modal-fishing",
	SHOPS = "modal-shops",
	LOCATIONS = "modal-locations",
	TRIALS = "modal-trials",
	DUNGEONS = "modal-dungeons",
	RAIDS = "modal-raids",
	FATES = "modal-fates",
	LEVES = "modal-leves",
	QUESTS = "modal-quests",
	OTHER = "modal-other",
	DESYNTHESIS = "modal-desynthesis",
	REDUCTIONS = "modal-reductions",
}
//#endregion Modals

//#region Jobs
export enum Job {
	PLD = "PLD", WAR = "WAR", DRK = "DRK", GNB = "GNB",
	WHM = "WHM", SCH = "SCH", AST = "AST", SGE = "SGE",
	MNK = "MNK", DRG = "DRG", NIN = "NIN", SAM = "SAM", RPR = "RPR",
	BRD = "BRD", MCH = "MCH", DNC = "DNC",
	BLM = "BLM", SMN = "SMN", RDM = "RDM",
	CRP = "CRP", BSM = "BSM", ARM = "ARM", GSM = "GSM", LTW = "LTW", WVR = "WVR", ALC = "ALC", CUL = "CUL",
	MIN = "MIN", BTN = "BTN", FSH = "FSH",
}
//#endregion Jobs

//#region MapLocation
export enum MapLocation {
	UNKNOWN = "GUnknown",
	ZAHARAK = "Zahar'ak",
	UGHAMARO_MINES = "U'Ghamaro Mines",
	NATALAN = "Natalan",
	SAPSA_SPAWNING_GROUNDS = "Sapsa Spawning Grounds",
	SYLPHLANDS = "The Sylphlands",
	OUTER_LA_NOSCEA = "Outer La Noscea",
	UPPER_LA_NOSCEA = "Upper La Noscea",
	MIDDLE_LA_NOSCEA = "Middle La Noscea",
	WESTERN_LA_NOSCEA = "Western La Noscea",
	LOWER_LA_NOSCEA = "Lower La Noscea",
	EASTERN_THANALAN = "Eastern Thanalan",
	WESTERN_THANALAN = "Western Thanalan",
	CENTRAL_THANALAN = "Central Thanalan",
	SOUTHERN_THANALAN = "Southern Thanalan",
	NORTH_SHROUD = "North Shroud",
	EAST_SHROUD = "East Shroud",
	CENTRAL_SHROUD = "Central Shroud",
	MOR_DHONA = "Mor Dhona",
	EASTERN_LA_NOSCEA = "Eastern La Noscea",
	SOUTH_SHROUD = "South Shroud",
	NORTHERN_THANALAN = "Northern Thanalan",
	NEW_GRIDANIA = "New Gridania",
	LIMSA_LOWER_DECKS = "Limsa Lominsa Lower Decks",
	ULDAH_STEPS_OF_NALD = "Ul'dah - Steps of Nald",
	SEA_OF_CLOUDS = "The Sea of Clouds",
	AZYS_LLA = "Azys Lla",
	CHURNING_MISTS = "The Churning Mists",
	COERTHAS_WESTERN_HIGHLANDS = "Coerthas Western Highlands",
	DRAVANIAN_FORELANDS = "The Dravanian Forelands",
	DRAVANIAN_HINTERLANDS = "The Dravanian Hinterlands",
	FOUNDATION = "Foundation",
	LIMSA_UPPER_DECKS = "Limsa Lominsa Upper Decks",
	WOLVES_DEN = "Wolves' Den Pier",
	OLD_GRIDANIA = "Old Gridania",
	ULDAH_STEPS_OF_THAL = "Ul'dah - Steps of Thal",
	GOLD_SAUCER = "The Gold Saucer",
	IDYLLSHIRE = "Idyllshire",
	RHALGRS_REACH = "Rhalgr's Reach",
	FRINGES = "The Fringes",
	PEAKS = "The Peaks",
	LOCHS = "The Lochs",
	KUGANE = "Kugane",
	RUBY_SEA = "The Ruby Sea",
	YANXIA = "Yanxia",
	AZIM_STEPPE = "The Azim Steppe",
	DOMAN_ENCLAVE = "The Doman Enclave",
	CRYSTARIUM = "The Crystarium",
	EULMORE = "Eulmore",
	LAKELAND = "Lakeland",
	KHOLUSIA = "Kholusia",
	AMH_ARAENG = "Amh Araeng",
	IL_MHEG = "Il Mheg",
	RAKTIKA_GREATWOOD = "The Rak'tika Greatwood",
	TEMPEST = "The Tempest",
	EUREKA_ANEMOS = "Eureka Anemos",
	EUREKA_PAGOS = "Eureka Pagos",
	EUREKA_PYROS = "Eureka Pyros",
	EUREKA_HYDATOS = "Eureka Hydatos",
	FOGFENS = "The Fogfens",
	CASTRUM_CENTRI = "Castrum Centri",
	SAINT_COINACHS_FIND = "Saint Coinach's Find",
	NORTH_SILVERTEAR = "North Silvertear",
	BOULDER_DOWNS = "Boulder Downs",
	WHITEBRIM = "Whitebrim",
	PROVIDENCE_POINT = "Providence Point",
	AGELYSS_WISE = "Agelyss Wise",
	ISLES_OF_UMBRA = "The Isles of Umbra",
	HALFSTONE = "Halfstone",
	IRON_LAKE = "Iron Lake",
	PROUD_CREEK = "Proud Creek",
	LARKSCALL = "Larkscall",
	URTHS_GIFT = "Urth's Gift",
	BLUEFOG = "Bluefog",
	RAUBAHNS_PUSH = "Raubahn's Push",
	BURNING_WALL = "The Burning Wall",
	ZANRAK = "Zanr'ak",
	CAPE_WESTWIND = "Cape Westwind",
	ZELMAS_RUN = "Zelma's Run",
	STANDING_CORSES = "The Standing Corses",
	KOBOLD_DIG = "Kobold Dig",
	BENTBRANCH = "Bentbranch",
	UPPER_PATHS = "Upper Paths",
	SNAKEMOLT = "Snakemolt",
	RAINCATCHER_GULLY = "Raincatcher Gullly",
	BRONZE_LAKE = "Bronze Lake",
	HORIZONS_EDGE = "Horizon's Edge",
	BROKEN_WATER = "Broken Water",
	SANDGATE = "Sandgate",
	BLACK_BRUSH = "Black Brush",
	SKULL_VALLEY = "Skull Valley",
	SKYSTEEL_MANUFACTORY = "Skysteel Manufactory",
	LAKE_TUSI_MEKTA = "Lake Tusi Mek'ta",
	DELTA_QUADRANT = "Delta Quadrant",
	THALIAK_RIVER = "Thaliak River",
	DRAGONSPIT = "Dragonspit",
	ALPHA_QUADRANT = "Alpha Quadrant",
	NEWVOID = "Newvoid",
	ICEBLOOM_CAVERN = "Icebloom Cavern",
	EAST_FLAMEROK = "East Flamerock",
	EAST_VAL_RIVER_BANK = "The East Val River Bank",
	COERTHAS_CENTRAL_HIGHLANDS = "Coerthas Central Highlands",
	OK_VUNDU_VANA = "Ok' Vundu Vana",
	RED_RIM = "Red Rim",
	WHILOM_RIVER = "Whilom River",
	WHALES_CROWN = "The Whale's Crown",
	MOURN = "Mourn",
	DAGGERS = "The Daggers",
	FOUR_ARMS = "Four Arms",
	ASHPOOL = "Ashpool",
	DRAGONS_STRUGGLE = "The Dragon's Struggle",
	PAUPERS_LODE = "The Pauper's Lode",
	DESTROYER_LOCHS = "The Destroyer",
	VALLEY_OF_THE_FALLEN_RAINBOW = "Valley of the Fallen Rainbow",
	COOLING_STATION = "Cooling Station",
	AQUEDUCT = "The Aqueduct",
	HYPERSTELLAR_DOWNCONVERTER = "Hyperstellar Downconverter",
	PAPPUS_TREE = "The Pappus Tree",
	GAMMA_QUADRANT = "Gamma Quadrant",
	DIADEM = "The Diadem",
	ANTITHESIS = "Antithesis",
	CITIA_SWAMPS = "The Citia Swamps",
	BOOKMANS_SHELVES = "The Bookman's Shelves",
	HARE_AMONG_GIANTS = "Hare Among Giants",
	CHURCH_OF_FIRST_LIGHT = "The Church of the First Light",
	MOUNT_BIRAN_MINES = "Mount Biran Mines",
	INN_AT_JOURNEYS_HEAD = "The Inn at Journey's Head",
	SCREE = "Scree",
	CHILIAD = "The Chiliad",
	LOZATLS_CONQUEST = "Lozatl's Conquest",
	WOVEN_OATH = "Woven Oath",
	DELIBERATING_DOLL = "The Deliberating Doll",
	HALDRATHS_MARCH = "Haldrath's March",
	DRAGONHEAD = "Dragonhead",
	CLUTCH = "The Clutch",
	SPINELESS_BASIN = "Spineless Basin",
	LANDLORD_COLONY = "Landlord Colony",
	TWINPOOLS = "Twinpools",
	RULING_QUARTER = "The Ruling Quarter",
	LADLE = "Ladle",
	CHOCOBO_FOREST = "Chocobo Forest",
	AMITY = "Amity",
	PRISTINE_PALACE_OF_AMH_MALIK = "The Pristine Palace of Amh Malik",
	WILD_FETE = "The WIld Fete",
	LOCH_SELD = "Loch Seld",
	GORGAGNE_HOLDING = "Gorgagne Holding",
	NORVRANDT_SLOPE = "Norvrandt Slope",
	HONEY_YARD = "Honey Yard",
	YUGRAM_RIVER = "Yugr'am River",
	ALDER_SPRINGS = "Alder Springs",
	BRAMBLE_PATCH = "The Bramble Patch",
	LOWER_PATHS = "Lower Paths",
	SILVER_BAZAAR = "The Silver Bazaar",
	COSTA_DEL_SOL = "Costa Del Sol",
	UMBRA_NORTHSHORE = "Isles of Umbra Northshore",
	SOUTH_BLOODSHORE = "South Bloodshore",
	SUMMERFORD = "Summerford",
	SMOLDERING_WASTES = "The Smoldering Wastes",
	BETA_QUADRANT = "Beta Quadrant",
	VOOR_SIAN_SIRAN = "Voor Sian Siran",
	EASTON_EYES = "Easton Eyes",
	AVALONIA_FALLEN = "Avalonia Fallen",
	GREENSWARD = "Greensward",
	PATHS_OF_CONTEMPLATION = "The Paths of Contemplation",
	NORTHERN_HILLS_OF_AMBER = "The Northern Hills of Amber",
	PATHS_OF_CREATION = "The Paths of Creation",
	BLACK_IRON_BRIDGE = "Black Iron Bridge",
	OK_GUNDU = "Ok' Gundu",
	OK_VUNDU_MOK = "Ok' Vundu Mok",
	OK_BENDU = "Ok' Bendu",
	DROWNING_WENCH = "The Drowning Wench",
	BOZJA = "The Bozjan Southern Front",
	ZADNOR = "Zadnor",
	BOZJA_SOUTH = "South Bozja Area",
	BOZJA_MIDDLE = "Middle Bozja Area",
	BOZJA_NORTH = "North Bozja Area",
	ZADNOR_SOUTH = "South Zadnor Area",
	ZADNOR_MIDDLE = "Middle Zadnor Area",
	ZADNOR_NORTH = "North Zadnor Area",
	HYRSTMILL = "Hyrstmill",
	BONFIRE = "The Bonfire",
	VESPER_BAY = "Vesper Bay",
	SWIFTPERCH = "Swiftperch",
	HELIX = "Helix",
	GANGOS = "Gangos",
	PRIMA_VISTA = "The Prima Vista",
	MONZEN = "Monzen",
	STARFALL = "Starfall",
	SOUTHERN_MARE_LAMENTORUM = "Southern Mare Lamentorum",
	MARE_LAMENTORUM = "Mare Lamentorum",
	RADZ_AT_HAN = "Radz-At-Han",
	ARTHA = "Artha",
	BRUME = "The Brume",
	FIRMAMENT = "The Firmament",
	FEATHERFALL = "Featherfall",
	PURUSA = "Purusa",
	THAVNAIR = "Thavnair",
	PENDANTS = "The Pendants",
	ROTUNDA = "The Rotunda",
	BALDAQUIN = "The Baldaquin",
	TIMH_GYEUS = "Timh Gyeus",
	DUERGAR_MOUNTAINS = "The Duergar Mountains",
	SOURCE = "The Source",
	SHADOW_FAULT = "Shadow Fault",
	FIELDS_OF_AMBER = "The Fields of Amber",
	WEATHERING = "Weathering",
	KALLIMELIOS_ZEPHYROS = "Kallimelios Zephyros",
	ELPIS = "Elpis",
	GARLEMALD = "Garlemald",
	EBLAN_RIME = "The Eblan Rime",
	CERULEA_INGENS = "Cerulea Ingens",
	PHILOMYTHES_NOTOS = "Philomythes Notos",
	OSTRAKON_DEKA_OKTO = "Ostrakon Deka-okto",
	ULTIMA_THULE = "Ultima Thule",
	OSTRAKON_TRIA = "Ostrakon Tria",
	ETHOSELETIKOS_BOREAS = "Ethoseletikos Boreas",
	SULLEN = "Sullen",
	MEDIAL_CIRCUIT = "The Medial Circuit",
	LABYRINTHOS = "Labyrinthos",
	PERFUMED_RISE = "The Perfumed Rise",
	WELLWICK_WOOD = "Wellwick Wood",
	GREENTEAR = "Greentear",
	SAGOLII_DESERT = "Sagolii Desert",
	TREESPEAK = "Treespeak",
	DRYBONE = "Camp Drybone",
	HAMMERLEA = "Hammerlea",
	NINE_IVIES = "Nine Ivies",
	OAKWOOD = "Oakwood",
	SORREL_HAVEN = "Sorrel Haven",
	NOPHICAS_WELLS = "Nophica's Wells",
	MITHAI_GLORIANDA = "Mithai Glorianda",
	BRIGHT_CLIFF = "The Bright Cliff",
	CENTRAL_HILLS_OF_AMBER = "The Central Hills of Amber",
	GOOD_JENANNAS_GRACE = "Good Jenanna's Grace",
	NABAATH_ARENG = "Nabaath Areng",
	CALIBAN_GORGE = "The Caliban Gorge",
	BLIND_FOREST = "The Blind Forest of Yx'Maja",
	VOEBURTENBURG = "Voeburtenburg",
}
//#endregion MapLocation

//#region Teleports
export enum Teleport {
	UNKNOWN = "GUnknown",
	FORGOTTEN_SPRINGS = "Forgotten Springs",
	OVERLOOK = "Camp Overlook",
	DRAGONHEAD = "Camp Dragonhead",
	ALEPORT = "Aleport",
	HAWTHORNE_HUT = "The Hawthorne Hut",
	MOR_DHONA = "Mor Dhona",
	WINEPORT = "Wineport",
	FALLGOURD_FLOAT = "Fallgourd Float",
	QUARRYMILL = "Quarrymill",
	BLUEFOG = "Camp Bluefog",
	CERULEUM_PROCESSING_PLANT = "Ceruleum Processing Plant",
	DRYBONE = "Camp Drybone",
	HORIZON = "Horizon",
	BRONZE_LAKE = "Camp Bronze Lake",
	BENTBRANCH = "Bentbranch Meadows",
	LITTLE_ALA_MHIGO = "Little Ala Mhigo",
	BLACK_BRUSH = "Black Brush Station",
	FOUNDATION = "Foundation",
	SLITHERBOUGH = "Slitherbough",
	HELIX = "Helix",
	IDYLLSHIRE = "Idyllshire",
	FALCONS_NEST = "Falcon's Nest",
	NAMAI = "Namai",
	ABANDONED_LAB = "Abandoned Laboratory",
	GRAVITATIONAL_STUDIES = "Gravitational Studies",
	FIRING_CHAMBER = "The Firing Chamber",
	DORMITORY = "Dormitory",
	OK_ZUNDU = "Ok' Zundu",
	TAILFEATHER = "Tailfeather",
	ANYX_TRINE = "Anyx Trine",
	ZENITH = "Zenith",
	HOUSE_OF_THE_FIERCE = "The House of the Fierce",
	PORTA_PRAETORIA = "Porta Praetoria",
	LYDHA_LRAN = "Lydha Lran",
	OSTALL_IMPERATIVE = "The Ostall Imperative",
	FORT_JOBB = "Fort Jobb",
	TWINE = "Twine",
	INN_AT_JOURNEYS_HEAD = "The Inn at Journey's Head",
	TOMRA = "Tomra",
	ONDO_CUPS = "Ondo Cups",
	TRANQUIL = "Camp Tranquil",
	COSTA_DEL_SOL = "Costa Del Sol",
	SUMMERFORD_FARMS = "Summerford Farms",
	CLOUDTOP = "Camp Cloudtop",
	AFTCASTLE = "Aftcastle",
	SWIFTPERCH = "Swiftperch",
	KUGANE = "Kugane",
	RHALGRS_REACH = "Rhalgr's Reach",
	SINUS_LACRIMARUM = "Sinus Lacrimarum",
	RADZ_AT_HAN_PLAZA = "Radz-at-Han Aetheryte Plaza",
	PALAKAS_STAND = "Palaka's Stand",
	CRYSTARIUM = "The Crystarium",
	STILLTIDE = "Stilltide",
	WRIGHT = "Wright",
	MORD_SOUQ = "Mord Souq",
	POIETEN_OIKOS = "Poieten Oikos",
	CAMP_BROKEN_GLASS = "Camp Broken Glass",
	ANAGNORISIS = "Anagnorisis",
	REAH_TAHRA = "Reah Tahra",
	ABODE_OF_THE_EA = "Abode of the Ea",
	ARCHEION = "The Archeion",
	GREAT_WORK = "The Great Work",
	WOLEKDORF = "Wolekdorf",
}
//#endregion Teleports

//#region Shop
export enum Shop {
	UNKNOWN = "GUnknown",
	DENYS = "Denys",
	AURIANA = "Auriana",
	HISMENA = "Hismena",
	ROWENA_REP_LIMSA = "Rowena's Representative (Limsa Lominsa)",
	ROWENA_REP_UlDAH = "Rowena's Representative (Ul'Dah)",
	ROWENA_REP_GRIDANIA = "Rowena's Representative (Gridania)",
	ROWENA_REP_FOUNDATION = "Rowena's Representative (Foundation)",
	SERPENT_QUARTERMASTER = "Serpent Quartermaster",
	STORM_QUARTERMASTER = "Storm Quartermaster",
	FLAME_QUARTERMASTER = "Flame Quartermaster",
	JUNKMONGER = "Junkmonger",
	MERCHANT_MENDER_SOUTH_THANALAN = "Merchant & Mender (Southern Thanalan)",
	MERCHANT_MENDER_COERTHAS_CENTRAL_HIGHLANDS = "Merchant & Mender (Coerthas Central Highlands)",
	TOOL_SUPPLIER_MENDER = "Tool Supplier & Mender",
	CRISTIANA = "Cristiana",
	HUNT_BILLMASTER_ULDAH = "Hunt Billmaster (Ul'Dah)",
	HUNT_BILLMASTER_GRIDANIA = "Hunt Billmaster (Gridania)",
	HUNT_BILLMASTER_LIMSA = "Hunt Billmaster (Limsa Lominsa)",
	LUNA_VANU = "Luna Vanu",
	AMALJAA_VENDOR = "Amalj'aa Vendor",
	VATH_STICKPEDDLER = "Vath Stickpeddler",
	SABINA = "Sabina",
	MOGMUL_MOGBELLY = "Mogmul Mogbelly",
	SAHAGIN_VENDOR = "Sahagin Vendor",
	KOBOLD_VENDOR = "Kobold Vendor",
	SYLPHIC_VENDOR = "Sylphic Vendor",
	SCRIP_EXCHANGE_ULDAH = "Scrip Exchange (Ul'Dah)",
	SCRIP_EXCHANGE_GRIDANIA = "Scrip Exchange (Gridania)",
	SCRIP_EXCHANGE_LIMSA = "Scrip Exchange (Limsa Lominsa)",
	SCRIP_EXCHANGE_IDYLLSHIRE = "Scrip Exchange (Idyllshire)",
	SCRIP_EXCHANGE_MOR_DHONA = "Scrip Exchange (Mor Dhona)",
	SCRIP_EXCHANGE_RHALGRS_REACH = "Scrip Exchange (Rhalgr's Reach)",
	SCRIP_EXCHANGE_EULMORE = "Scrip Exchange (Eulmore)",
	SCRIP_EXCHANGE_CRYSTARIUM = "Scrip Exchange (The Crystarium)",
	SCRIP_EXCHANGE_SHARLAYAN = "Scrip Exchange (Old Sharlayan)",
	SCRIP_EXCHANGE_RADZ_AT_HAN = "Script Exchange (Radz-at-Han)",
	SANANA = "Sanana",
	ANNA = "Anna",
	DIANA = "Diana",
	ENIE = "Enie",
	PEDRONILLE = "Pedronille",
	NACILLE = "Nacille",
	GRAMSOL = "Gramsol",
	EXPEDITION_BIRDWATCHER_PAGOS = "Expedition Birdwatcher (Pagos)",
	EXPEDITION_BIRDWATCHER_ANEMOS = "Expedition Birdwatcher (Anemos)",
	EXPEDITION_BIRDWATCHER_PYROS = "Expedition Birdwatcher (Pyros)",
	JUBRUNNAH = "Jubrunnah",
	MOWEN = "Mowen's Merchant (The Crystarium)",
	ZRANMAIA = "Z'ranmaia",
	ALLAGAN_RESUPPLY_NODE = "Allagan Resupply Node",
	// ANANTA_MATERIAL_SUPPLIER = "Ananta Material Supplier",
	JOSSY = "Jossy",
	// KOJIN_MATERIAL_SUPPLIER = "Kojin Material Supplier",
	MATERIAL_SUPPLIER = "Material Supplier",
	// NAMAZU_MATERIAL_SUPPLIER = "Namazu Material Supplier",
	ESMENET = "Esmenet",
	TANIE = "Tanie",
	SCRAP_SALVAGER = "Scrap Salvager",
	VRAL = "Vral",
	HALDEN = "Halden",
	GOUSHS_OOAN = "Goushs Ooan",
	ENGERRAND = "Engerrand",
	FRIDURIH = "Fridurih",
	ORHOYOD = "O'rhoyod",
	PASDEVILLET = "Pasdevillet",
	SHIRAKASHI = "Shirakashi",
	TRAVELING_MATERIAL_SUPPLIER = "Traveling Material Supplier",
	MERCHANT_MENDER_SILVER_BAZAAR = "Merchant & Mender (Silver Bazaar)",
	LITTLEJOHN = "Littlejohn",
	ALEHOUSE_WENCH_BLACK_BRUSH = "Alehouse Wench (Black Brush)",
	SCRIP_EXCHANGE_OCEAN_FISHING = "Scrip Exchange (Ocean Fishing)",
	SOEMRWYB = "Soemrwyb",
	CALAMITY_SALVAGER_LIMSA = "Calamity Salvager (Limsa)",
	CALAMITY_SALVAGER_GRIDANIA = "Calamity Salvager (Old Gridania)",
	CALAMITY_SALVAGER_ULDAH = "Calamity Salvager (Ul'Dah)",
	QUINNANA = "Quinnana",
	GADFRID = "Gadfrid",
	SAJAREEN = "Sajareen",
	ZAWAWA = "Zawawa",
	AISARA = "Aisara",
	TRADINGWAY = "Tradingway",
	TALAN = "Talan",
	AISTAN = "Aistan",
	SMYDHAEMR = "Smydhaemr",
	IXALI_VENDOR = "Ixali Vendor",
	GIGIMA = "Gigima",
	MAHVEYDAH = "Mahveydah",
	SIULMET = "Siulmet",
	FAEZBROES = "Faezbroes",
}
//#endregion Shop

//#region Currency
export enum Currency {
	UNKNOWN = "GUnknown",
	POETICS = "Tomestone of Poetics",
	COMPANY_SEAL = "Company Seal",
	GIL = "Gil",
	STEEL_AMALJOK = "Steel Amalj'ok",
	ALLIED_SEAL = "Allied Seal",
	VANU_WHITEBONE = "Vanu Whitebone",
	KUPO_NUT = "Carved Kupo Nut",
	GORDIAN_BOLT = "Precision Gordian Bolt",
	GORDIAN_LENS = "Precision Gordian Lens",
	GORDIAN_SPRING = "Precision Gordian Spring",
	GORDIAN_SHAFT = "Precision Gordian Shaft",
	BLACK_COPPER_GIL = "Black Copper Gil",
	ASTRONOMY = "Tomestone of Astronomy",
	WHITE_CRAFTERS_SCRIP = "White Crafter's Scrip",
	WHITE_GATHERERS_SCRIP = "White Gatherer's Scrip",
	SKUBUILDERS_SCRIP = "Skybuilders' Scrip",
	BICOLOUR_GEMSTONE = "Bicolor Gemstone",
	IXALI_OAKNOT = "Ixali Oaknot",
	CAUSALITY = "Tomestone of Causality",
	SYLPHIC_GOLDLEAF = "Sylphic Goldleaf",
}
//#endregion Currency

//#region Relics

//#region RelicType
export enum RelicType {
	WEAPON = "relic_weapon",
	ARMOUR = "relic_armour",
	TOOL = "relic_tool",
}
//#endregion RelicType

//#region RelicGroup
export enum RelicGroup {
	// Weapons
	WEAPON_ZODIAC = "g_w_zodiac",
	WEAPON_ANIMA = "g_w_anima",
	WEAPON_EUREKA = "g_w_eureka",
	WEAPON_RESISTANCE = "g_w_resistance",
	WEAPON_MANDERVILLE = "g_w_manderville",

	// Tools
	TOOL_LUCIS = "g_t_lucis",
	TOOL_SKYSTEEL = "g_t_skysteel",
	TOOL_SPLENDOROUS = "g_t_splendorous",

	// Armour
	ARMOUR_ANEMOS = "g_a_anemos",
	ARMOUR_ELEMENTAL = "g_a_elemental",
	ARMOUR_BOZJAN = "g_a_bozjan",
	ARMOUR_LAWS_ORDER = "g_a_laws_order",
	ARMOUR_BLADES = "g_a_blades",
}
//#endregion RelicGroup

//#region RelicPart
export enum RelicPart {
	// Weapons
	WEAPON_BASE_WEAPON = "p_w_base_weapon", // Zodiac
	WEAPON_ZENITH = "p_w_zenith",
	WEAPON_ATMA = "p_w_atma",
	WEAPON_ANIMUS = "p_w_animus",
	WEAPON_NOVUS = "p_w_novus",
	WEAPON_NEXUS = "p_w_nexus",
	WEAPON_BRAVES = "p_w_braves",
	WEAPON_ZETA = "p_w_zeta",
	WEAPON_ANIMATED = "p_w_animated", // Anima
	WEAPON_AWOKEN = "p_w_awoken",
	WEAPON_ANIMA = "p_w_anima",
	WEAPON_HYPERCONDUCTIVE = "p_w_hyperconductive",
	WEAPON_RECONDITIONED = "p_w_reconditioned",
	WEAPON_SHARPENED = "p_w_sharpened",
	WEAPON_COMPLETE = "p_w_complete",
	WEAPON_LUX = "p_w_lux",
	WEAPON_ANTIQUATED = "p_w_antiquated", // Eureka
	WEAPON_ANEMOS = "p_w_anemos",
	WEAPON_ELEMENTAL = "p_w_elemental",
	WEAPON_PYROS = "p_w_pyros",
	WEAPON_EUREKA = "p_w_eureka",
	WEAPON_PHYSEOS = "p_w_physeos",
	WEAPON_RESISTANCE = "p_w_resistance", // Resistance
	WEAPON_AUGMENTED_RESISTANCE = "p_w_augmented_resistance",
	WEAPON_RECOLLECTION = "p_w_recollection",
	WEAPON_LAWS_ORDER = "p_w_laws_order",
	WEAPON_AUGMENTED_LAWS_ORDER = "p_w_augmented_laws_order",
	WEAPON_BLADE = "p_w_blade",
	WEAPON_MANDERVILLE = "p_w_manderville", // Manderville
	WEAPON_AMAZING_MANDERVILLE = "p_w_amazing_manderville",
	WEAPON_MAJESTIC_MANDERVILLE = "p_w_majestic_manderville",
	WEAPON_MANDERVILLOUS = "p_w_mandervillous",

	// Tools
	TOOL_MASTERCRAFT = "p_t_mastercraft", // Lucis
	TOOL_SUPRA = "p_t_supra",
	TOOL_LUCIS = "p_t_lucis",
	TOOL_SKYSTEEL = "p_t_skysteel", // Skysteel
	TOOL_SKYSTEEL_1 = "p_t_skysteel_1",
	TOOL_DRAGONSUNG = "p_t_dragonsung",
	TOOL_AUGMENTED_DRAGONSUNG = "p_t_augmented_dragonsung",
	TOOL_SKYSUNG = "p_t_skysung",
	TOOL_SKYBUILDERS = "p_t_skybuilders",
	TOOL_SPLENDOROUS = "p_t_splendorous", // Splendorous
	TOOL_AUGMENTED_SPLENDOROUS = "p_t_augmented_splendorous",
	TOOL_CRYSTALLINE = "p_t_crystalline",
	TOOL_CHORA_ZOIS_CRYSTALLINE = "p_t_chora_zois_crystalline",
	TOOL_BRILLIANT = "p_t_brilliant",
	TOOL_VRANDTIC_VISIONARYS = "p_t_vrandtic_visionarys",
	TOOL_LODESTAR = "p_t_lodestar",

	// Armour
	ARMOUR_ANTIQUATED = "p_a_antiquated", // Eureka Anemos
	ARMOUR_BASE_0 = "p_a_base_0",
	ARMOUR_BASE_1 = "p_a_base_1",
	ARMOUR_BASE_2 = "p_a_base_2",
	ARMOUR_ANEMOS = "p_a_anemos",
	ARMOUR_ELEMENTAL = "p_a_elemental", // Eureka Elemental
	ARMOUR_ELEMENTAL_1 = "p_a_elemental_1",
	ARMOUR_ELEMENTAL_2 = "p_a_elemental_2",
	ARMOUR_BOZJAN = "p_a_bozjan", // Resistance Bozjan
	ARMOUR_AUGMENTED_BOZJAN = "p_a_augmented_bozjan",
	ARMOUR_LAWS_ORDER = "p_a_laws_order", // Resistance Law's Order
	ARMOUR_AUGMENTED_LAWS_ORDER = "p_a_augmented_laws_order",
	ARMOUR_BLADES = "p_a_blades", // Resistance Blade
}
//#endregion RelicPart

//#region RelicStep
export enum RelicStep {
	BROKEN_WEAPON = "s_broken_weapon", // Zodiac - Base Weapon
	CLASS_WEAPON = "s_class_weapon",
	CHIMERA = "s_chimera",
	AMDAPOR = "s_amdapor",
	BEASTMAN = "s_beastman",
	HYDRA = "s_hydra",
	EMBER = "s_ember",
	GALE = "s_gale",
	ORE = "s_ore",
	OIL = "s_oil",
	MIST = "s_mist", // Zodiac - Zenith
	ARMS = "s_arms", // Zodiac - Atma
	ATMAS = "s_atmas",
	TRIALS = "s_trials", // Zodiac - Animus
	SKYFIRE_1 = "s_skyfire_1",
	SKYFIRE_2 = "s_skyfire_2",
	NETHERFIRE_1 = "s_netherfire_1",
	SKYFALL_1 = "s_skyfall_1",
	SKYFALL_2 = "s_skyfall_2",
	NETHERFALL_1 = "s_netherfall_1",
	SKYWIND_1 = "s_skywind_1",
	SKYWIND_2 = "s_skywind_2",
	SKYEARTH_1 = "s_skyearth_1",
	RADIANCE = "s_radiance", // Zodiac - Novus
	SCROLL = "s_scroll",
	STAR = "s_star",
	ALEXANDRITE = "s_alexandrite",
	BINDING = "s_binding",
	SOULGLAZE = "s_soulglaze", // Zodiac - Nexus
	LIGHT = "s_light",
	WHEREFORE = "s_wherefore", // Zodiac - Zodiac Braves
	SKYLIGHT = "s_skylight",
	ZODIUM = "s_zodium",
	FLAWLESS_ALEXANDRITE = "s_flawless_alexandrite",
	BRAVES_SCROLL = "s_braves_scroll",
	MATERIA = "s_materia",
	SHINE = "s_shine", // Zodiac - Zodiac Zeta
	SOUL = "s_soul",
	VITAL = "s_vital",
	CRYSTALS = "s_crystals", // Anima - Animated
	SNOWCLOAK = "s_snowcloak", // Anima - Awoken
	SASTASHA = "s_sastasha",
	QARN = "s_qarn",
	KEEPER = "s_keeper",
	WANDERER = "s_wanderer",
	AMDAPOR_AWOKEN = "s_amdapor_awoken",
	VIGIL = "s_vigil",
	SOHM = "s_sohm",
	AERY = "s_aery",
	VAULT = "s_vault",
	RUBBER = "s_rubber", // Anima - Anima
	CARBONCOAT = "s_carboncoat",
	WATER = "s_water",
	CATALYST = "s_catalyst",
	OIL_HYPER = "s_oil_hyper", // Anima - Hyperconductive
	SAND = "s_sand", // Anima - Reconditioned
	CLUSTERS = "s_clusters", // Anima - Sharpened
	DUNGEONS = "s_dungeons", // Anima - Complete
	PNEUMITE = "s_pneumite",
	DENSITY = "s_density",
	BFF = "s_bff", // Anima - Lux
	ANTIQUATED = "s_antiquated", // Eureka - Antiquated
	ANEMOS_0 = "s_anemos_0", // Eureka - Anemos
	ANEMOS_1 = "s_anemos_1",
	ANEMOS_2 = "s_anemos_2",
	ANEMOS = "s_anemos",
	PAGOS = "s_pagos", // Eureka - Elemental
	PAGOS_1 = "s_pagos_1",
	ELEMENTAL = "s_elemental",
	ELEMENTAL_1 = "s_elemental_1", // Eureka - Pyros
	ELEMENTAL_2 = "s_elemental_2",
	PYROS = "s_pyros",
	HYDATOS = "s_hydatos", // Eureka - Eureka
	HYDATOS_1 = "s_hydatos_1",
	BASE = "s_base",
	EUREKA = "s_eureka",
	FRAGMENTS = "s_fragments", // Eureka - Physeos
	SCALEPOWDER = "s_scalepowder", // Resistance - Resistance
	MEMORY = "s_memory", // Resistance - Augmented Resistance
	RESIST = "s_resist", // Resistance - Recollection
	CHANGE_OF_ARMS = "s_change_of_arms", // Resistance - Laws Order
	REMEMBERS = "s_remembers", // Resistance - Augmented Laws Order
	PATH = "s_path",
	SPARE_PARTS = "s_spare_parts", // Resistance - Blade
	STORY = "s_story",
	FOND_MEMORY = "s_fond_memory",
	DEAL = "s_deal",
	IRRESISTIBLE = "s_irresistible",
	MANDERVILLE = "s_manderville", // Manderville - Manderville
	AMAZING_MANDERVILLE = "s_amazing_manderville", // Manderville - Amazing Manderville
	MAJESTIC_MANDERVILLE = "s_majestic_manderville", // Manderville - Majestic Manderville
	MANDERVILLOUS = "s_mandervillous", // Manderville - Mandervillous
	CLASS_QUESTS = "s_class_quests", // Lucis - Masterwork
	SUPRA_ITEMS = "s_supra_items", // Lucis - Supra
	LUCIS_ITEMS = "s_lucis_items", // Lucis - Lucis
	PURCHASE = "s_purchase", // Skysteel - Skysteel
	SKYSTEEL_ITEMS = "s_skysteel_items", // Skysteel - Skysteel + 1
	DRAGONSUNG_ITEMS = "s_dragonsung_items", // Skysteel - Dragonsung
	BASIC_GOBBIEGOO = "s_basic_gobbiegoo", // Skysteel - Augmented Dragonsung
	VISCOUS_GOBBIEGOO = "s_viscous_gobbiegoo", // Skysteel - Skysung
	PARTS = "s_parts", // Skysteel - Skybuilders
	SPLENDOROUS_PURCHASE = "s_splendorous_purchase", // Splendorous - Splendorous
	SPLENDOROUS_COMPONENT = "s_splendorous_component", // Splendorous - Augmented Splendorous
	ADAPTIVE_COMPONENT = "s_adaptive_component", // Splendorous - Crystalline
	CUSTOMISED_COMPONENT = "s_customised_component", // Splendorous - Chora Zoi's Crystalline
	BRILLIANT_COMPONENT = "s_brilliant_component", // Splendorous - Brilliant
	INSPIRATIONAL_COMPONENT = "s_inspirational_component", // Splendorous - Vrandtic Visionary's
	NIGHTFORGED_COMPONENT = "s_nightforged_component", // Splendorous - Lodestar
	// TODO: Add in armour
}
//#endregion RelicStep

//#region RelicTask
export enum RelicTask {
	TASK_1 = "task_1",
	TASK_2 = "task_2",
	TASK_3 = "task_3",
	TASK_4 = "task_4",
	TASK_5 = "task_5",
	TASK_6 = "task_6",
	TASK_7 = "task_7",
	TASK_8 = "task_8",
	TASK_9 = "task_9",
	TASK_10 = "task_10",
	TASK_11 = "task_11",
	TASK_12 = "task_12",
	TASK_13 = "task_13",
	TASK_14 = "task_14",
	TASK_15 = "task_15",
	TASK_16 = "task_16",
	TASK_17 = "task_17",
	TASK_18 = "task_18",
	TASK_19 = "task_19",
}
//#endregion RelicTask

//#endregion Relics

//#region Duties

//#region Trials
export enum Trial {
	UNKNOWN = "GUnknown",
	CHIMERA = "A Relic Reborn: The Chimera",
	HYDRA = "A Relic Reborn: The Hydra",
	IFRIT = "The Bowl of Embers",
	IFRIT_HARD = "The Bowl of Embers (Hard)",
	GARUDA_HARD = "The Howling Eye (Hard)",
	TITAN_HARD = "The Navel (Hard)",
	MOGGLE_MOG_HARD = "Thornmarch (Hard)",
	LEVIATHAN_HARD = "The Whorleater (Hard)",
	RAMUH_HARD = "The Striking Tree (Hard)",
	SHIVA_HARD = "Akh Afah Amphitheatre (Hard)",
	RAVANA_HARD = "Thok ast Thok (Hard)",
	BISMARCK = "The Limitless Blue (Hard)",
	SEPHIROT = "Containment Bay S1T7",
	SOPHIA = "Containment Bay P1T6",
	ZURVAN = "Containment Bay Z1T9",
}
//#endregion Trials

//#region Dungeons
export enum Dungeon {
	UNKNOWN = "GUnknown",
	TAM_TARA = "The Tam-Tara Deepcroft",
	TAM_TARA_HARD = "Tam-Tara Deepcroft (Hard)",
	STONE_VIGIL = "The Stone Vigil",
	STONE_VIGIL_HARD = "The Stone Vigil (Hard)",
	SASTASHA = "Sastasha",
	SASTASHA_HARD = "Sastasha (Hard)",
	LOST_CITY_OF_AMDAPOR = "The Lost City of Amdapor",
	LOST_CITY_OF_AMDAPOR_HARD = "The Lost City of Amdapor (Hard)",
	BRAYFLOX = "Brayflox's Longstop",
	BRAYFLOX_HARD = "Brayflox's Longstop (Hard)",
	WANDERERS = "The Wanderer's Palace",
	WANDERERS_HARD = "The Wanderer's Palace (Hard)",
	COPPERBELL = "Copperbell Mines",
	COPPERBELL_HARD = "Copperbell Mines (Hard)",
	QARN = "The Sunken Temple of Qarn",
	QARN_HARD = "The Sunken Temple of Qarn (Hard)",
	HAUKKE_MANOR = "Haukke Manor",
	HAUKKE_MANOR_HARD = "Haukke Manor (Hard)",
	HALATALI = "Halatali",
	HALATALI_HARD = "Halatali (Hard)",
	DZEMAEL_DARKHOLD = "Dzemael Darkhold",
	TOTO_RAK = "The Thousand Maws of Toto-Rak",
	AMDAPOR_KEEP = "Amdapor Keep",
	AMDAPOR_KEEP_HARD = "Amdapor Keep (Hard)",
	CUTTERS = "Cutter's Cry",
	PHAROS_SIRIUS = "Pharos Sirius",
	AURUM = "The Aurum Vale",
	SNOWCLOAK = "Snowcloak",
	HULLBREAKER = "Hullbreaker Isle",
	KEEPER_OF_THE_LAKE = "The Keeper of the Lake",
	DUSK_VIGIL = "The Dusk Vigil",
	SOHM_AL = "Sohm Al",
	SOHM_AL_HARD = "Sohm Al (Hard)",
	AERY = "The Aery",
	VAULT = "The Vault",
	GUBAL_LIBRARY_HARD = "The Great Gubal Library (Hard)",
	MERIDIANUM = "Castrum Meridianum",
	PRAETORIUM = "The Praetorium",
}
//#endregion Dungeons

//#region Raids
export enum Raid {
	UNKNOWN = "GUnknown",
	LABYRINTH_OF_ANCIENTS = "The Labyrinth of the Ancients",
	SYRCUS_TOWER = "Syrcus Tower",
	WORLD_OF_DARKNESS = "The World of Darkness",
	VOID_ARK = "The Void Ark",
	MHACH = "The Weeping City of Mhach",
	DUN_SCAITH = "Dun Scaith",
	RABANASTRE = "The Royal City of Rabanastre",
	RIDORANA = "The Ridorana Lighthouse",
	ORBONNE = "The Orbonne Monastery",
	CASTRUM_LACUS_LITORE = "Castrum Lacus Litore",
	DELUBRUM_REGINAE = "Delubrum Reginae",
	DALRIADA = "The Dalriada",
	ALEXANDER_1 = "The Fist of the Father (A1)",
	ALEXANDER_2 = "The Cuff of the Father (A2)",
	ALEXANDER_3 = "The Arm of the Father (A3)",
	ALEXANDER_4 = "The Burden of the Father (A4)",
	ALEXANDER_5 = "The Fist of the Son (A5)",
	ALEXANDER_6 = "The Cuff of the Son (A6)",
	ALEXANDER_7 = "The Arm of the Son (A7)",
	ALEXANDER_8 = "The Burden of the Son (A8)",
	ALEXANDER_9 = "The Eyes of the Creator (A9)",
	ALEXANDER_10 = "The Breath of the Creator (A10)",
	ALEXANDER_11 = "The Heart of the Creator (A11)",
	ALEXANDER_12 = "The Soul of the Creator (A12)",
	OMEGA_1 = "Deltascape V1.0 (O1)",
	OMEGA_2 = "Deltascape V2.0 (O2)",
	OMEGA_3 = "Deltascape V3.0 (O3)",
	OMEGA_4 = "Deltascape V4.0 (O4)",
	OMEGA_5 = "Sigmascape V1.0 (O5)",
	OMEGA_6 = "Sigmascape V2.0 (O6)",
	OMEGA_7 = "Sigmascape V3.0 (O7)",
	OMEGA_8 = "Sigmascape V4.0 (O8)",
	OMEGA_9 = "Alphascape V1.0 (O9)",
	OMEGA_10 = "Alphascape V2.0 (O10)",
	OMEGA_11 = "Alphascape V3.0 (O11)",
	OMEGA_12 = "Alphascape V4.0 (O12)",
	EDEN_1 = "Eden's Gate: Resurrection (E1)",
	EDEN_2 = "Eden's Gate: Descent (E2)",
	EDEN_3 = "Eden's Gate: Inundation (E3)",
	EDEN_4 = "Eden's Gate: Sepulture (E4)",
	EDEN_5 = "Eden's Verse: Fulmination (E5)",
	EDEN_6 = "Eden's Verse: Furor (E6)",
	EDEN_7 = "Eden's Verse: Iconoclasm (E7)",
	EDEN_8 = "Eden's Verse: Refulgence (E8)",
	EDEN_9 = "Eden's Promise: Umbra (E9)",
	EDEN_10 = "Eden's Promise: Litany (E10)",
	EDEN_11 = "Eden's Promise: Anamorphosis (E11)",
	EDEN_12 = "Eden's Promise: Eternity (E12)",
}
//#endregion Raids

//#region FATEs
export enum Fate {
	UNKNOWN = "GUnknown",
	ENMITY_OF_MY_ENEMY = "The Enmity of My Enemy",
	GIANT_SEPS = "Giant Seps",
	MAKE_IT_RAIN = "Make it Rain",
	BREACHING_SOUTH_TIDEGATE = "Breaching South Tidegate",
	AIR_SUPPLY = "Air Supply",
	HEROES_OF_THE_2ND = "Heroes of the 2nd",
	ANOTHER_NOTCH = "Another Notch on the Torch",
	EVERYTHINGS_BETTER = "Everything's Better",
	RETURN_TO_CINDER = "Return to Cinder",
	BELLYFULL = "Bellyfull",
	KINGS_JUSTICE = "The King's Justice",
	QUARTZ_COUPLING = "Quartz Coupling",
	BLACK_AND_NBURU = "Black and Nburu",
	BREAKING_DAWN = "Breaking Dawn",
	BREACHING_NORTH_TIDEGATE = "Breaching North Tidegate",
	CERULEUM_ROAD = "The Ceruleum Road",
	FOUR_WINDS = "The Four Winds",
	RUDE_AWAKENING = "Rude Awakening",
	GOOD_TO_BE_BAD = "Good to Be Bud",
	SURPRISE = "Surprise",
	IN_SPITE_OF_IT_ALL = "In Spite of It All",
	WHAT_GORED_BEFORE = "What Gored Before",
	TAKEN = "Taken",
	TOWER_OF_POWER = "Tower of Power",
	BIG_BAGOLY_THEORY = "The Big Bagoly Theory",
	SCHISM = "Schism",
	TASTE_OF_FEAR = "The Taste of Fear",
	WAIL_IN_THE_WILLOWS = "Wail in the Willows",
	LOUHI_ON_ICE = "Louhi on Ice",
	LOST_EPIC = "Lost Epic",
	CRYSTALLINE_PROVENANCE = "Crystalline Provenance",
}
//#endregion FATEs

//#region Leves
export enum Leve {
	UNKNOWN = "GUnknown",
	AWRY_SALVAGES = "The Awry Salvages",
	NECROLOGOS_PALE_OBLATION = "Necrologos: Pale Oblation",
	AN_IMP_MOBILE = "An Imp Mobile",
	MUSEUM_IS_CLOSED = "The Museum Is Closed",
	YELLOW_IS_THE_NEW_BLACK = "Yellow Is the New Black",
	DONT_FORGET_TO_CRY = "Don't Forget to Cry",
	ONE_BIG_PROBLEM_SOLVED = "One Big Problem Solved",
	IF_YOU_PUT_IT_THAT_WAY = "If you Put It That Way",
	CIRCLING_THE_CERULEUM = "Circling the Ceruleum",
	GO_HOME_TO_MAMA = "Go Home to Mama",
	NECROLOGOS_WHISPERS_OF_THE_GEM = "Necrologos: Whispers of the Gem",
	GET_OFF_OUR_LAKE = "Get Off Our Lake",
	AREAS_A_BIT_SKETCHY = "The Area's a Bit Sketchy",
	SOMEONES_IN_THE_DOGHOUSE = "Someone's in the Doghouse",
	SUBDUING_THE_SUBPRIME = "Subduing the Subprime ",
	GOT_A_GUT_FEELING_ABOUT_THIS = "Got a Gut Feeling about This",
	WHO_WRITES_HISTORY = "Who Writes History ",
	BIG_BAD_IDEA = "Big, Bad Idea",
	SOMEONES_GOT_A_BIG_MOUTH = "Someone's Got a Big Mouth",
	PUT_YOUR_STOMP_ON_IT = "Put Your Stomp On It",
	BLOODHOUNDS_OF_COERTHAS = "The Bloodhounds of Coerthas",
	NO_BIG_WHOOP = "No Big Whoop",
	NECROLOGOS_THE_LIMINAL_ONES = "Necrologos: The Liminal Ones",
	ID_RATHER_BE_DIGGING = "I'd Rather Be Digging",
	I_MAUL_RIGHT = "I Maul Right",
	NO_REFUNDS_ONLY_EXCHANGES = "No Refunds, Only Exchanges",
	POP_THAT_TOP = "Pop That Top",
	TALON_TERRORS = "Talon Terrors",
	YOU_STAY_ON_THAT_SIDE = "You Stay on That Side",
	MATTER_OF_VITAL_IMPORTANCE = "A Matter of Vital Importance",
	MAKE_UP_YOUR_MIND_OR_ELSE = "Make Up Your Mind or Else",
	NOT_TAKING_NO_FOR_AN_ANSWER = "Not Taking No for an Answer",
	QUIT_YOUR_JIBJAB = "Quit Your Jib-jab",
	SHUT_UP_ALREADY = "Shut Up Already",
	SLEEPLESS_IN_SILVERTEAR = "Sleepless in Silvertear",
	FUSS_IN_BOOTS = "Fuss in Boots",
	HANDLE_WITH_CARE = "Handle with Care",
	HOLD_ON_TIGHT = "Hold On Tight",
	SPELLING_ME_SOFTLY = "Spelling Me Softly",
	TENDERFOOT_MOMENTS = "Tenderfoot Moments",
	TOO_HOT_TO_HANDLE = "Too Hot to Handle",
	BOW_DOWN_TO_MAGIC = "Bow Down to Magic",
	BOWING_TO_GREATER_POWER = "Bowing to Greater Power",
	CLOGS_OF_WAR = "Clogs of War",
	INCANT_NOW_THINK_LATER = "Incant Now, Think Later",
	TRIDENT_AND_ERROR = "Trident and Error",
	YOU_DO_THE_HEAVY_LIFTING = "You Do the Heavy Lifting",
	GOLD_IS_SO_LAST_YEAR = "Gold Is So Last Year",
	GREEN_AND_BEAR_IT = "Green and Bear It",
	LIGHT_IN_THE_DARKNESS = "Light in the Darkness",
	SEW_NOT_DOING_THIS = "Sew Not Doing This",
	SWEET_CHARITY = "Sweet Charity",
	BIG_RED = "The Big Red",
}
//#endregion Leves

//#endregion Duties

//#region Item
export enum Item {
	UNKNOWN = "GUnknown",
	AEOLIAN_SCIMITAR = "Aeolian Scimitar",
	BARBARIANS_BARDICHE = "Barbarian's Bardiche",
	MADMANS_WHISPERING_ROD = "Madman's Whispering Rod",
	ERUDITES_PICATRIX_OF_HEALING = "Erudite's Picatrix of Healing",
	WILDLINGS_CESTI = "Wildling's Cesti",
	CHAMPIONS_LANCE = "Champion's Lance",
	VAMPERS_KNIVES = "Vamper's Knives",
	LONGARMS_COMPOSITE_BOW = "Longarm's Composite Bow",
	SANGUINE_SCEPTER = "Sanguine Scepter",
	ERUDITES_PICATRIX_OF_CASTING = "Erudite's Picatrix of Casting",
	BATTLEDANCE_MATERIA_III = "Battledance Materia III",
	QUICKTONGUE_MATERIA_III = "Quicktongue Materia III",
	SAVAGE_AIM_MATERIA_III = "Savage Aim Materia III",
	HEAVENS_EYE_MATERIA_III = "Heavens' Eye Materia III",
	SAVAGE_MIGHT_MATERIA_III = "Savage Might Materia III",
	QUENCHING_OIL = "Radz-at-Han Quenching Oil",
	THAVNAIRIAN_MIST = "Thavnairian Mist",
	ATMA_OF_THE_LION = "Atma of the Lion",
	ATMA_OF_THE_WATERBEARER = "Atma of the Water-bearer",
	ATMA_OF_THE_RAM = "Atma of the Ram",
	ATMA_OF_THE_CRAB = "Atma of the Crab",
	ATMA_OF_THE_FISH = "Atma of the Fish",
	ATMA_OF_THE_BULL = "Atma of the Bull",
	ATMA_OF_THE_SCALES = "Atma of the Scales",
	ATMA_OF_THE_TWINS = "Atma of the Twins",
	ATMA_OF_THE_SCORPION = "Atma of the Scorpion",
	ATMA_OF_THE_ARCHER = "Atma of the Archer",
	ATMA_OF_THE_GOAT = "Atma of the Goat",
	ATMA_OF_THE_MAIDEN = "Atma of the Maiden",
	SUPERIOR_ENCHANTED_INK = "Superior Enchanted Ink",
	ALEXANDRITE = "Alexandrite",
	LIGHT = "Light",
	BOMBARD_CORE = "Bombard Core",
	BRONZE_LAKE_CRYSTAL = "Bronze Lake Crystal",
	SACRED_SPRING_WATER = "Sacred Spring Water",
	HORN_OF_THE_BEAST = "Horn of the Beast",
	GOBMACHINE_BANGPLATE = "Gobmachine Bangplate",
	NARASIMHA_HIDE = "Narasimha Hide",
	SICKLE_FANG = "Sickle Fang",
	FURNACE_RING_HQ = "Furnace Ring (HQ)",
	PERFECT_FIREWOOD_HQ = "Perfect Firewood (HQ)",
	ALLAGAN_RESIN = "Allagan Resin",
	VALE_BUBO = "Vale Bubo",
	VOIDWEAVE = "Voidweave",
	AMDAPOR_VELLUM = "Amdapor Vellum",
	INDIGO_PEARL = "Indigo Pearl",
	PERFECT_MORTAR_HQ = "Perfect Mortar (HQ)",
	PERFECT_PESTLE_HQ = "Perfect Pestle (HQ)",
	BRASS_KETTLE = "Brass Kettle",
	LOST_TREASURE_OF_AMDAPOR = "Lost Treasure of Amdapor",
	LOST_TREASURE_OF_PHAROS_SIRIUS = "Lost Treasure of Pharos Sirius",
	LOST_TREASURE_OF_TAM_TARA = "Lost Treasure of the Tam-Tara Deepcroft",
	LOST_TREASURE_OF_STONE_VIGIL = "Lost Treasure of the Stone Vigil",
	TAILOR_MADE_EEL_PIE_HQ = "Tailor-made Eel Pie (HQ)",
	PERFECT_CLOTH_HQ = "Perfect Cloth (HQ)",
	TONBERRY_KING_BLOOD = "Tonberry King Blood",
	ROYAL_GIGANT_BLOOD = "Royal Gigant Blood",
	KRAKEN_BLOOD = "Kraken Blood",
	VICEGERENT_BLOOD = "Vicegerent Blood",
	PERFECT_VELLUM_HQ = "Perfect Vellum (HQ)",
	PERFECT_POUNCE_HQ = "Perfect Pounce (HQ)",
	FURITE_SAND = "Furite Sand",
	MAHATMA_OF_THE_RAM = "Mahatma of the Ram",
	MAHATMA_OF_THE_BULL = "Mahatma of the Bull",
	MAHATMA_OF_THE_TWINS = "Mahatma of the Twins",
	MAHATMA_OF_THE_CRAB = "Mahatma of the Crab",
	MAHATMA_OF_THE_LION = "Mahatma of the Lion",
	MAHATMA_OF_THE_MAIDEN = "Mahatma of the Maiden",
	MAHATMA_OF_THE_SCALES = "Mahatma of the Scales",
	MAHATMA_OF_THE_SCORPION = "Mahatma of the Scorpion",
	MAHATMA_OF_THE_ARCHER = "Mahatma of the Archer",
	MAHATMA_OF_THE_GOAT = "Mahatma of the Goat",
	MAHATMA_OF_THE_WATERBEARER = "Mahatma of the Water-bearer",
	MAHATMA_OF_THE_FISH = "Mahatma of the Fish",
	LUMINOUS_WIND_CRYSTAL = "Luminous Wind Crystal",
	LUMINOUS_FIRE_CRYSTAL = "Luminous Fire Crystal",
	LUMINOUS_LIGHTNING_CRYSTAL = "Luminous Lightning Crystal",
	LUMINOUS_ICE_CRYSTAL = "Luminous Ice Crystal",
	LUMINOUS_EARTH_CRYSTAL = "Luminous Earth Crystal",
	LUMINOUS_WATER_CRYSTAL = "Luminous Water Crystal",
	UNIDENTIFIABLE_BONE = "Unidentifiable Bone",
	UNIDENTIFIABLE_SHELL = "Unidentifiable Shell",
	UNIDENTIFIABLE_ORE = "Unidentifiable Ore",
	UNIDENTIFIABLE_SEEDS = "Unidentifiable Seeds",
	ADAMANTITE_FRANCESCA = "Adamantite Francesca",
	TITANIUM_ALLOY_MIRROR = "Titanium Alloy Mirror",
	DISPELLING_ARROW = "Dispelling Arrow",
	KINGCAKE = "Kingcake",
	AETHER_OIL = "Aether Oil",
	CRYSTAL_SAND = "Crystal Sand",
	UMBRITE = "Umbrite",
	SINGING_CLUSTER = "Singing Cluster",
	PNEUMITE = "Pneumite",
	AETHERIC_DENSITY = "Aetheric Density",
	ARCHAIC_ENCHANTED_INK = "Archaic Enchanted Ink",
	PROTEAN_CRYSTAL = "Protean Crystal",
	PAZUZUS_FEATHER = "Pazuzu's Feather",
	FROSTED_PROTEAN_CRYSTAL = "Frosted Protean Crystal",
	PAGOS_CRYSTAL = "Pagos Crystal",
	LOUHIS_ICE = "Louhi's Ice",
	PYROS_CRYSTAL = "Pyros Crystal",
	PENTHESILEAS_FLAME = "Penthesilea's Flame",
	HYDATOS_CRYSTAL = "Hydatos Crystal",
	CRYSTALLINE_SCALE = "Crystalline Scale",
	EUREKA_FRAGMENT = "Eureka Fragment",
	THAVNAIRIAN_SCALEPOWDER = "Thavnairian Scalepowder",
	TORTURED_MEMORY_OF_THE_DYING = "Tortured Memory of the Dying",
	SORROWFUL_MEMORY_OF_THE_DYING = "Sorrowful Memory of the Dying",
	HARROWING_MEMORY_OF_THE_DYING = "Harrowing Memory of the Dying",
	BITTER_MEMORY_OF_THE_DYING = "Bitter Memory of the Dying",
	LOATHSOME_MEMORY_OF_THE_DYING = "Loathsome Memory of the Dying",
	HAUNTING_MEMORY_OF_THE_DYING = "Haunting Memory of the Dying",
	VEXATIOUS_MEMORY_OF_THE_DYING = "Vexatious Memory of the Dying",
	TIMEWORN_ARTIFACTS = "Timeworn Artifacts",
	COMPACT_AXLE = "Compact Axle",
	COMPACT_SPRING = "Compact Spring",
	BATTLES_FOR_THE_REALM = "A Day in the Life: Battles for the Realm",
	BEYOND_THE_RIFT = "A Day in the Life: Beyond the Rift",
	BLEAK_MEMORY_OF_THE_DYING = "Bleak Memory of the Dying",
	LURID_MEMORY_OF_THE_DYING = "Lurid Memory of the Dying",
	RAW_EMOTION = "Raw Emotion",
	MANDERIUM_METEORITE = "Manderium Meteorite",
	ODDLY_SPECIFIC_PETRIFIED_ORB_HQ = "Oddly Specific Petrified Orb (HQ)",
	ODDLY_SPECIFIC_PETRIFIED_LOG = "Oddly Specific Petrified Log",
	WHITE_ASH_LOG = "White Ash Log",
	ODDLY_SPECIFIC_RIVETS_HQ = "Oddly Specific Rivets (HQ)",
	ODDLY_SPECIFIC_IRON_SAND = "Oddly Specific Iron Sand",
	MANASILVER_SAND = "Manasilver Sand",
	ODDLY_SPECIFIC_WIRE_HQ = "Oddly Specific Wire (HQ)",
	ODDLY_SPECIFIC_IRON_ORE = "Oddly Specific Iron Ore",
	ODDLY_SPECIFIC_WHETSTONE_HQ = "Oddly Specific Whetstone (HQ)",
	ODDLY_SPECIFIC_UNCUT_GEMSTONE = "Oddly Specific Uncut Gemstone",
	ODDLY_SPECIFIC_LEATHER_HQ = "Oddly Specific Leather (HQ)",
	ODDLY_SPECIFIC_SKIN = "Oddly Specific Skin",
	ATROCIRAPTOR_SKIN = "Atrociraptor Skin",
	ODDLY_SPECIFIC_MOONBEAM_SILK_HQ = "Oddly Specific Moonbeam Silk (HQ)",
	ODDLY_SPECIFIC_COTTON = "Oddly Specific Cotton",
	PIXIE_FLOSS_BOLL = "Pixie Floss Boll",
	ODDLY_SPECIFIC_SYNTHETIC_RESIN_HQ = "Oddly Specific Synthetic Resin (HQ)",
	ODDLY_SPECIFIC_QUARTZ = "Oddly Specific Quartz",
	VAMPIRE_VINE_SAP = "Vampire Vine Sap",
	ODDLY_SPECIFIC_SEED_EXTRACT_HQ = "Oddly Specific Seed Extract (HQ)",
	ODDLY_SPECIFIC_SEEDS = "Oddly Specific Seeds",
	HIGHLAND_SPRING_WATER = "Highland Spring Water",
	ODDLY_SPECIFIC_OBSIDIAN = "Oddly Specific Obsidian",
	ODDLY_SPECIFIC_MINERAL_SAND = "Oddly Specific Mineral Sand",
	ODDLY_SPECIFIC_LATEX = "Oddly Specific Latex",
	ODDLY_SPECIFIC_FOSSIL_DUST = "Oddly Specific Fossil Dust",
	THINKERS_CORAL = "Thinker's Coral",
	ODDLY_SPECIFIC_SHAFT_HQ = "Oddly Specific Shaft (HQ)",
	SANDTEAK_LUMBER = "Sandteak Lumber",
	ODDLY_SPECIFIC_FITTING_HQ = "Oddly Specific Fitting (HQ)",
	TITANBRONZE_INGOT = "Titanbronze Ingot",
	ODDLY_SPECIFIC_CHAINMAIL_SHEET_HQ = "Oddly Specific Chainmail Sheet (HQ)",
	ODDLY_SPECIFIC_GEMSTONE_HQ = "Oddly Specific Gemstone (HQ)",
	TUFF_WHETSTONE = "Tuff Whetstone",
	ODDLY_SPECIFIC_VELLUM_HQ = "Oddly Specific Vellum (HQ)",
	ZONURE_LEATHER = "Zonure Leather",
	ODDLY_SPECIFIC_VELVET_HQ = "Oddly Specific Velvet (HQ)",
	OVIM_WOOL = "Ovim Wool",
	ODDLY_SPECIFIC_GLASS_HQ = "Oddly Specific Glass (HQ)",
	REFINED_NATRON = "Refined Natron",
	ODDLY_SPECIFIC_SEED_FLOUR_HQ = "Oddly Specific Seed Flour (HQ)",
	NIGHT_VINEGAR = "Night Vinegar",
	ODDLY_SPECIFIC_DARK_MATTER = "Oddly Specific Dark Matter",
	ODDLY_SPECIFIC_STRIKING_STONE = "Oddly Specific Striking Stone",
	ODDLY_SPECIFIC_AMBER = "Oddly Specific Amber",
	ODDLY_SPECIFIC_BAUBLE = "Oddly Specific Bauble",
	DRAGONSPINE = "Dragonspine",
	ODDLY_SPECIFIC_CEDAR_LUMBER = "Oddly Specific Cedar Lumber",
	ODDLY_SPECIFIC_CEDAR_LOG = "Oddly Specific Cedar Log",
	LIGNUM_VITAE_LOG = "Lignum Vitae Log",
	ODDLY_SPECIFIC_IRON_NAILS = "Oddly Specific Iron Nails",
	ODDLY_SPECIFIC_COERTHAN_IRON_ORE = "Oddly Specific Coerthan Iron Ore",
	DIMYTHRITE_ORE = "Dimythrite Ore",
	ODDLY_SPECIFIC_MYTHRIL_RINGS = "Oddly Specific Mythril Rings",
	ODDLY_SPECIFIC_MYTHRITE_SAND = "Oddly Specific Mythrite Sand",
	ODDLY_SPECIFIC_SILVER_NUGGET = "Oddly Specific Silver Nugget",
	ODDLY_SPECIFIC_SILVER_ORE = "Oddly Specific Silver Ore",
	DIMYTHRITE_SAND = "Dimythrite Sand",
	ODDLY_SPECIFIC_GAGANASKIN_STRAP = "Oddly Specific Gaganaskin Strap",
	ODDLY_SPECIFIC_GAGANA_SKIN = "Oddly Specific Gagana Skin",
	YELLOW_ALUMEN = "Yellow Alumen",
	ODDLY_SPECIFIC_CLOTH = "Oddly Specific Cloth",
	ODDLY_SPECIFIC_FLEECE = "Oddly Specific Fleece",
	DWARVEN_COTTON_BOLL = "Dwarven Cotton Boll",
	ODDLY_SPECIFIC_GLUE = "Oddly Specific Glue",
	ODDLY_SPECIFIC_SAP = "Oddly Specific Sap",
	VAMPIRE_CUP_VINE = "Vampire Cup Vine",
	ODDLY_SPECIFIC_OIL = "Oddly Specific Oil",
	ODDLY_SPECIFIC_ALOE = "Oddly Specific Aloe",
	FRANTOIO = "Frantoio",
	ODDLY_SPECIFIC_SCHORL = "Oddly Specific Schorl",
	ODDLY_SPECIFIC_LANDBORNE_AETHERSAND = "Oddly Specific Landborne Aethersand",
	ODDLY_SPECIFIC_DARK_CHESTNUT_LOG = "Oddly Specific Dark Chestnut Log",
	ODDLY_SPECIFIC_LEAFBORNE_AETHERSAND = "Oddly Specific Leafborne Aethersand",
	PETAL_SHELL = "Petal Shell",
	ODDLY_SPECIFIC_CEDAR_PLANK = "Oddly Specific Cedar Plank",
	LIGNUM_VITAE_LUMBER = "Lignum Vitae Lumber",
	ODDLY_SPECIFIC_IRON_INGOT = "Oddly Specific Iron Ingot",
	DWARVEN_MYTHRIL_INGOT = "Dwarven Mythril Ingot",
	ODDLY_SPECIFIC_MYTHRIL_PLATE = "Oddly Specific Mythril Plate",
	ODDLY_SPECIFIC_SILVER_INGOT = "Oddly Specific Silver Ingot",
	DWARVEN_MYTHRIL_NUGGET = "Dwarven Mythril Nugget",
	ODDLY_SPECIFIC_GAGANA_LEATHER = "Oddly Specific Gagana Leather",
	SEA_SWALLOW_LEATHER = "Sea Swallow Leather",
	ODDLY_SPECIFIC_UNDYED_WOOLEN_CLOTH = "Oddly Specific Undyed Woolen Cloth",
	DWARVEN_COTTON = "Dwarven Cotton",
	ODDLY_SPECIFIC_RUBBER = "Oddly Specific Rubber",
	ODDLY_SPECIFIC_PASTE = "Oddly Specific Paste",
	ODDLY_SPECIFIC_PRIMORDIAL_ORE = "Oddly Specific Primordial Ore",
	ODDLY_SPECIFIC_PRIMORDIAL_ASPHALTUM = "Oddly Specific Primordial Asphaltum",
	ODDLY_SPECIFIC_PRIMORDIAL_LOG = "Oddly Specific Primordial Log",
	ODDLY_SPECIFIC_PRIMORDIAL_RESIN = "Oddly Specific Primordial Resin",
	ALLAGAN_HUNTER = "Allagan Hunter",
	ODDLY_DELICATE_PINE_LUMBER = "Oddly Delicate Pine Lumber",
	ODDLY_DELICATE_PINE_LOG = "Oddly Delicate Pine Log",
	GRADE_4_ARTISANAL_SKYBUILDERS_LOG = "Grade 4 Artisanal Skybuilders' Log",
	GRADE_4_ARTISANAL_SKYBUILDERS_BARBGRASS = "Grade 4 Artisanal Skybuilders' Barbgrass",
	ODDLY_DELICATE_SILVER_GEAR = "Oddly Delicate Silver Gear",
	ODDLY_DELICATE_SILVERGRACE_ORE = "Oddly Delicate Silvergrace Ore",
	GRADE_4_ARTISANAL_SKYBUILDERS_CLOUDSTONE = "Grade 4 Artisanal Skybuilders' Cloudstone",
	GRADE_4_ARTISANAL_SKYBUILDERS_SILEX = "Grade 4 Artisanal Skybuilders' Silex",
	ODDLY_DELICATE_WOLFRAM_SQUARE = "Oddly Delicate Wolfram Square",
	ODDLY_DELICATE_SCHEELITE = "Oddly Delicate Scheelite",
	GRADE_4_ARTISANAL_SKYBUILDERS_PRISMSTONE = "Grade 4 Artisanal Skybuilders' Prismstone",
	ODDLY_DELICATE_CELESTINE = "Oddly Delicate Celestine",
	ODDLY_DELICATE_RAW_CELESTINE = "Oddly Delicate Raw Celestine",
	ODDLY_DELICATE_GAZELLE_LEATHER = "Oddly Delicate Gazelle Leather",
	ODDLY_DELICATE_GAZELLE_HIDE = "Oddly Delicate Gazelle Hide",
	GRADE_4_ARTISANAL_SKYBUILDERS_CAIMAN = "Grade 4 Artisanal Skybuilders' Caiman",
	ODDLY_DELICATE_RHEA_CLOTH = "Oddly Delicate Rhea Cloth",
	ODDLY_DELICATE_RHEA = "Oddly Delicate Rhea",
	GRADE_4_ARTISANAL_SKYBUILDERS_COCOON = "Grade 4 Artisanal Skybuilders' Cocoon",
	GRADE_4_ARTISANAL_SKYBUILDERS_ICE_STALAGMITE = "Grade 4 Artisanal Skybuilders' Ice Stalagmite",
	ODDLY_DELICATE_HOLY_WATER = "Oddly Delicate Holy Water",
	ODDLY_DELICATE_MISTLETOE = "Oddly Delicate Mistletoe",
	GRADE_4_ARTISANAL_SKYBUILDERS_SPRING_WATER = "Grade 4 Artisanal Skybuilders' Spring Water",
	GRADE_4_ARTISANAL_SKYBUILDERS_RASPBERRY = "Grade 4 Artisanal Skybuilders' Raspberry",
	ODDLY_DELICATE_SHARK_OIL = "Oddly Delicate Shark Oil",
	ODDLY_DELICATE_HAMMERHEAD_SHARK = "Oddly Delicate Hammerhead Shark",
	ODDLY_DELICATE_ADAMANTITE_ORE = "Oddly Delicate Adamantite Ore",
	ODDLY_DELICATE_RAW_JADE = "Oddly Delicate Raw Jade",
	ODDLY_DELICATE_FEATHER = "Oddly Delicate Feather",
	ODDLY_DELICATE_BIRCH_LOG = "Oddly Delicate Birch Log",
	FLINTSTRIKE = "Flintstrike",
	PICKLED_POM = "Pickled Pom",
	FIRE_SHARD = "Fire Shard",
	ICE_SHARD = "Ice Shard",
	WIND_SHARD = "Wind Shard",
	EARTH_SHARD = "Earth Shard",
	LIGHTNING_SHARD = "Lightning Shard",
	WATER_SHARD = "Water Shard",
	FIRE_CRYSTAL = "Fire Crystal",
	ICE_CRYSTAL = "Ice Crystal",
	WIND_CRYSTAL = "Wind Crystal",
	EARTH_CRYSTAL = "Earth Crystal",
	LIGHTNING_CRYSTAL = "Lightning Crystal",
	WATER_CRYSTAL = "Water Crystal",
	FIRE_CLUSTER = "Fire Cluster",
	ICE_CLUSTER = "Ice Cluster",
	WIND_CLUSTER = "Wind Cluster",
	EARTH_CLUSTER = "Earth Cluster",
	LIGHTNING_CLUSTER = "Lightning Cluster",
	WATER_CLUSTER = "Water Cluster",
	BASILISK_EGG = "Basilisk Egg",
	BLUNT_AEOLIAN_SCIMITAR = "Blunt Aeolian Scimitar",
	DARKSTEEL_NUGGET = "Darksteel Nugget",
	BASILISK_WHETSTONE = "Basilisk Whetstone",
	DARKSTEEL_ORE = "Darksteel Ore",
	BLOODY_BARDICHE_HEAD = "Bloody Bardiche Head",
	THAVNAIRIAN_MISTLETOE = "Thavnairian Mistletoe",
	GROWTH_FORMULA_DELTA = "Growth Formula Delta",
	VAMPIRE_PLANT = "Vampire Plant",
	BLOODY_GRIMOIRE_BINDING = "Bloody Grimoire Binding",
	ENCHANTED_GOLD_INK = "Enchanted Gold Ink",
	BLOODY_CESTI_COVERS = "Bloody Cesti Covers",
	BLOODY_LANCE_HEAD = "Bloody Lance Head",
	SPRUCE_LUMBER = "Spruce Lumber",
	SPRUCE_LOG = "Spruce Log",
	BLOODY_KNIFE_BLADES = "Bloody Knife Blades",
	POTENT_SLEEPING_POTION = "Potent Sleeping Potion",
	ROSEWOOD_LUMBER = "Rosewood Lumber",
	BLOODY_BOW_RIM = "Bloody Bow Rim",
	PINPRICK_PEBBLE = "Pinprick Pebble",
	AGED_EYE_OF_FIRE = "Aged Eye of Fire",
	BELAHDIAN_SILVER = "Belah'dian Silver",
	AGED_SPEAR_SHAFT = "Aged Spear Shaft",
	CYPRESS_LOG = "Cypress Log",
	GRENADE_ASH = "Grenade Ash",
	AGED_MORTAR_PIECES = "Aged Mortar Pieces",
	MAHOGANY_LUMBER = "Mahogany Lumber",
	AGED_PESTLE_PIECES = "Aged Pestle Pieces",
	PEISTE_LEATHER = "Peiste Leather",
	VINTAGE_COOKING_SHERRY = "Vintage Cooking Sherry",
	WORM_OF_NYM = "Worm of Nym",
	PIE_DOUGH = "Pie Dough",
	SMOOTH_BUTTER = "Smooth Butter",
	BLACK_PEPPER = "Black Pepper",
	CLOVES = "Cloves",
	STAINED_CLOTH = "Stained Cloth",
	PUMICE = "Pumice",
	LINEN_YARN = "Linen Yarn",
	EFFERVESCENT_WATER = "Effervescent Water",
	AGED_VELLUM = "Aged Vellum",
	SHARK_OIL = "Shark Oil",
	DRIED_ETHER = "Dried Ether",
	AETHERLOUSE = "Aetherlouse",
	ELECTRUM_SAND = "Electrum Sand",
	PTERODACTYL_STRAP = "Pterodactyl Strap",
	ADAMANTITE_NUGGET = "Adamantite Nugget",
	BIRCH_LUMBER = "Birch Lumber",
	TITANIUM_ALLOY_SQUARE = "Titanium Alloy Square",
	HOLY_WATER = "Holy Water",
	HARDSILVER_SAND = "Hardsilver Sand",
	VITRIOL	= "Vitriol",
	BLESSED_FLETCHINGS = "Blessed Fletchings",
	HALLOWED_CHESTNUT_LUMBER = "Hallowed Chestnut Lumber",
	HARDSILVER_NUGGET = "Hardsilver Nugget",
	MOOGLE_MINIATURE = "Moogle Miniature",
	HIGHLAND_FLOUR = "Highland Flour",
	YAK_MILK = "Yak Milk",
	OKEANIS_EGG = "Okeanis Egg",
	BIRCH_SYRUP = "Birch Syrup",
	TINY_CROWN = "Tiny Crown",
	SANDTEAK_LOG = "Sandteak Log",
	TITANCOPPER_ORE = "Titancopper Ore",
	TITANIUM_ORE = "Titanium Ore",
	VOLCANIC_TUFF = "Volcanic Tuff",
	ZONURE_SKIN = "Zonure Skin",
	OVIM_WOOL_YARN = "Ovim Wool Yarn",
	EXTRA_EFFERVESCENT_WATER = "Extra Effervescent Water",
	ALA_MHIGAN_SALT_CRYSTAL = "Ala Mhigan Salt Crystal",
	ROYAL_GRAPES = "Royal Grapes",
	MYTHRITE_ORE = "Mythrite Ore",
	MYTHRITE_SAND = "Mythrite Sand",
	SEA_SWALLOW_SKIN = "Sea Swallow Skin",
	DWARVEN_COTTON_THREAD = "Dwarven Cotton Thread",
	TRILLIUM_BULB = "Trillium Bulb",
	QUICKSILVER = "Quicksilver",
	ROCK_SALT = "Rock Salt",
	GOLD_SAND = "Gold Sand",
	SPOKEN_BLOOD = "Spoken Blood",
	OCHU_VINE = "Ochu Vine",
	DESERT_SAFFRON = "Desert Saffron",
	ROSEWOOD_LOG = "Rosewood Log",
	AGED_RING = "Aged Ring",
	FURNACE_RING = "Furnace Ring",
	PERFECT_MORTAR = "Perfect Mortar",
	AGED_SPEAR = "Aged Spear",
	PERFECT_FIREWOOD = "Perfect Firewood",
	PERFECT_PESTLE = "Perfect Pestle",
	AGED_MORTAR = "Aged Mortar",
	MAHOGANY_LOG = "Mahogany Log",
	AGED_PESTLE = "Aged Pestle",
	PEISTE_SKIN = "Peiste Skin",
	BLACK_ALUMEN = "Black Alumen",
	AGED_DECANTER = "Aged Decanter",
	TAILOR_MADE_EEL_PIE = "Tailor-made Eel Pie",
	SUNSET_WHEAT_FLOUR = "Sunset Wheat Flour",
	TABLE_SALT = "Table Salt",
	MINERAL_WATER = "Mineral Water",
	BUFFALO_MILK = "Buffalo Milk",
	AGED_ROBE = "Aged Robe",
	PERFECT_CLOTH = "Perfect Cloth",
	PERFECT_VELLUM = "Perfect Vellum",
	FLAX = "Flax",
	AGED_GRIMOIRE = "Aged Grimoire",
	HAMMERHEAD_SHARK = "Hammerhead Shark",
	SILVER_SHARK = "Silver Shark",
	AGED_PHIAL = "Aged Phial",
	PERFECT_POUNCE = "Perfect Pounce",
	PTERODACTYL = "Pterodactyl",
	DARK_CHESTNUT_LOG = "Dark Chestnut Log",
	DAWNBORNE_AETHERSAND = "Dawnborne Aethersand",
	ADAMANTITE_ORE = "Adamantite Ore",
	BIRCH_LOG = "Birch Log",
	SPHALERITE = "Sphalerite",
	TITANIUM_NUGGET = "Titanium Nugget",
	DRAVANIAN_SPRING_WATER = "Dravanian Spring Water",
	DRAVANIAN_MISTLETOE = "Dravanian Mistletoe",
	ROYAL_MISTLETOE = "Royal Mistletoe",
	EAGLE_FEATHER = "Eagle Feather",
	DARK_CHESTNUT_LUMBER = "Dark Chestnut Lumber",
	HALLOWED_WATER = "Hallowed Water",
	SILVER_ORE = "Silver Ore",
	CLOUD_COTTON_BOLL = "Cloud Cotton Boll",
	BLACK_SOIL = "Black Soil",
	HIGHLAND_WHEAT = "Highland Wheat",
	BIRCH_SAP = "Birch Sap",
	OVIM_FLEECE = "Ovim Fleece",
	CINNABAR = "Cinnabar",
	SUNSET_WHEAT = "Sunset Wheat",
	BRIGHT_FIRE_ROCK = "Bright Fire Rock",
	BRIGHT_LIGHTNING_ROCK = "Bright Lightning Rock",
	PEAT_MOSS = "Peat Moss",
	WATER_MINT = "Water Mint",
	IRON_ORE = "Iron Ore",
	ABALATHIAN_SPRING_WATER = "Abalathian Spring Water",
	ABALATHIAN_MISTLETOE = "Abalathian Mistletoe",
	COMPLEMENTARY_CHONDRITE = "Complementary Chondrite",
	CONNOISSEURS_CHAIR = "Connoisseur's Chair",
	CONNOISSEURS_WALL_LANTERN = "Connoisseur's Wall Lantern ",
	CONNOISSEURS_ORNATE_DOOR = "Connoisseur's Ornate Door",
	CONNOISSEURS_VANITY_MIRROR = "Connoisseur's Vanity Mirror",
	CONNOISSEURS_RUG = "Connoisseur's Rug",
	CONNOISSEURS_SWAG_VALANCE = "Connoisseur's Swag Valance",
	CONNOISSEURS_COSMETICS_BOX = "Connoisseur's Cosmetics Box",
	CONNOISSEURS_PIXIEBERRY_TEA = "Connoisseur's Pixieberry Tea",
	CONNOISSEURS_PRISMSTONE = "Connoisseur's Prismstone",
	SPLENDOROUS_WATER_SHARD = "Splendorous Water Shard",
	CONNOISSEURS_WATTLE_PETRIBARK = "Connoisseur's Wattle Petribark",
	SPLENDOROUS_EARTH_SHARD = "Splendorous Earth Shard",
	PLATINUM_SEAHORSE = "Platinum Seahorse",
	CLAVEKEEPER = "Clavekeeper",
	CONNOISSEURS_MARIMBA = "Connoisseur's Marimba",
	CONNOISSEURS_COFFEE_BREWER = "Connoisseur's Coffee Brewer",
	CONNOISSEURS_BENCH = "Connoisseur's Bench",
	CONNOISSEURS_ASTROSCOPE = "Connoisseur's Astroscope",
	CONNOISSEURS_LEATHER_SOFA = "Connoisseur's Leather Sofa",
	CONNOISSEURS_RED_CARPET = "Connoisseur's Red Carpet",
	CONNOISSEURS_ELIXIR_BOTTLE = "Connoisseur's Elixir Bottle",
	CONNOISSEURS_PIXIEBERRY_TART = "Connoisseur's Pixieberry Tart",
	CONNOISSEURS_RED_MALACHITE = "Connoisseur's Red Malachite",
	ADAPTIVE_FIRE_CRYSTAL = "Adaptive Fire Crystal",
	CONNOISSEURS_LEVIN_MINT = "Connoisseur's Levin Mint",
	ADAPTIVE_LIGHTNING_CRYSTAL = "Adaptive Lightning Crystal",
	MIRROR_IMAGE = "Mirror Image",
	SPANGLED_PIRARUCU = "Spangled Pirarucu",
	SELECT_IRONWOOD_LUMBER = "Select Ironwood Lumber",
	IRONWOOD_LOG = "Ironwood Log",
	SELECT_MANGANESE_INGOT = "Select Manganese Ingot",
	PHRYGIAN_GOLD_ORE = "Phrygian Gold Ore",
	SELECT_TITANIUM_PLATE = "Select Titanium Plate",
	SELECT_CRYSTAL_GLASS = "Select Crystal Glass",
	SELECT_SMILODON_LEATHER = "Select Smilodon Leather",
	SELECT_SCARLET_MOKO_CLOTH = "Select Scarlet Moko Cloth",
	SELECT_HOPTRAP_LEAF = "Select Hoptrap Leaf",
	SELECT_PIXIEBERRY = "Select Pixieberry",
	BISMUTH_ORE = "Bismuth Ore",
	ALMASTY_FUR = "Almasty Fur",
	PETALOUDA_SCALES = "Petalouda Scales",
	SIDERITIS_LEAVES = "Sideritis Leaves",
	INTEGRAL_LUMBER = "Integral Lumber",
	STAR_QUARTZ = "Star Quartz",
	AR_CAEAN_VELVET = "AR-Caean Velvet",
	GRADE_5_VITALITY_ALKAHEST = "Grade 5 Vitality Alkahest",
	DARK_RYE_FLOUR = "Dark Rye Flour",
	CHONDRITE_INGOT = "Chondrite Ingot",
	OPHIOTAUROS_LEATHER = "Ophiotauros Leather",
	GRADE_5_MIND_ALKAHEST = "Grade 5 Mind Alkahest",
	PALM_SUGAR = "Palm Sugar",
	INTEGRAL_LOG = "Integral Log",
	CHONDRITE = "Chondrite",
	ANNITE_WHETSTONE = "Annite Whetstone",
	RAW_STAR_QUARTZ = "Raw Star Quartz",
	AR_CAEAN_COTTON_BOLL = "AR-Caean Cotton Boll",
	OPHIOTAUROS_HIDE = "Ophiotauros Hide",
	EBLAN_ALUMEN = "Eblan Alumen",
	UNDERGROUND_SPRING_WATER = "Underground Spring Water",
	LUNATENDER_BLOSSOM = "Lunatender Blossom",
	LIME_BASIL = "Lime Basil",
	TIGER_LILY = "Tiger Lily",
	DARK_RYE = "Dark Rye",
	PALM_SYRUP = "Palm Syrup",
	ANNITE = "Annite",
	REINFORCED_SPRUCE_PLYWOOD = "Reinforced Spruce Plywood",
	BALANCED_DARKSTEEL_HOOK = "Balanced Darksteel Hook",
	REINFOCED_DARKSTEEL_WIRE = "Reinforced Darksteel Wire",
	CALIBRATED_ROSE_GOLD_COG = "Calibrated Rose Gold Cog",
	WATERHARDENED_HIPPOGRYPH_STRAP = "Water-hardened Hippogryph Strap",
	INTRICATE_SILVER_BROCADE = "Intricate Silver Brocade",
	CONCENTRATED_SPIRITS_OF_SALT = "Concentrated Spirits of Salt",
	RICH_TOMATO_RELISH = "Rich Tomato Relish",
	TALANS_SEAL_OF_MINING = "Talan's Seal of Mining",
	TALANS_SEAL_OF_BOTANY = "Talan's Seal of Botany",
	TALANS_SEAL_OF_FISHING = "Talan's Seal of Fishing",
	MASTERCRAFT_DEMIMATERIA = "Mastercraft Demimateria",
	SPRUCE_PLYWOOD = "Spruce Plywood",
	FIELDCRAFT_DEMIMATERIA_III = "Fieldcraft Demimateria III",
	DARKSTEEL_HOOK = "Darksteel Hook",
	DARKSTEEL_WIRE = "Darksteel Wire",
	ROSE_GOLD_COG = "Rose Gold Cog",
	GRYPHONSKIN_STRAP = "Gryphonskin Strap",
	SILVER_BROCADE = "Silver Brocade",
	SPIRITS_OF_SALT = "Spirits of Salt",
	SPICY_TOMATO_RELISH = "Spicy Tomato Relish",
	ANTUMBRAL_ROCK = "Antumbral Rock",
	REDOLENT_LOG = "Redolent Log",
	OLGOI_KHORKHOI = "Olgoi-Khorkhoi",
	HORN_GLUE = "Horn Glue",
	ROSE_GOLD_NUGGET = "Rose Gold Nugget",
	HIPPOGRYPH_LEATHER = "Hippogryph Leather",
	SILK_THREAD = "Silk Thread",
	SILVER_INGOT = "Silver Ingot",
	VOLCANIC_ROCK_SALT = "Volcanic Rock Salt",
	DZEMAEL_TOMATO = "Dzemael Tomato",
	CINNAMON = "Cinnamon",
	DARK_VINEGAR = "Dark Vinegar",
	ALDGOAT_HORN = "Aldgoat Horn",
	GOLD_ORE = "Gold Ore",
	COPPER_ORE = "Copper Ore",
	HIPPOGRYPH_SKIN = "Hippogryph Skin",
	SILKWORM_COCOON = "Silkworm Cocoon",
	NOBLE_GRAPES = "Noble Grapes",
	PETRIFIED_ORB_HQ = "Petrified Orb (HQ)",
	MUMMERS_DAGGERS_HQ = "Mummer's Daggers (HQ)",
	LAPDOG_COLLAR_BELL_HQ = "Lapdog Collar Bell (HQ)",
	FLUORITE_LENS_HQ = "Fluorite Lens (HQ)",
	PATENT_LEATHER_HQ = "Patent Leather (HQ)",
	CAMLET_HQ = "Camlet (HQ)",
	ENCHANTED_QUICKSILVER_HQ = "Enchanted Quicksilver (HQ)",
	DUCK_BROTH_HQ = "Duck Broth (HQ)",
	TALANS_SEAL_OF_MINING_MASTERY = "Talan's Seal of Mining Mastery",
	TALANS_SEAL_OF_BOTANY_MASTERY = "Talan's Seal of Botany Mastery",
	TALANS_SEAL_OF_FISHING_MASTERY = "Talan's Seal of Fishing Mastery",
	MOONSTONE = "Moonstone",
	URUSHI = "Urushi",
	PETRIFIED_LOG = "Petrified Log",
	SCARLET_SAP = "Scarlet Sap",
	COMPOSITE_WHETSTONE = "Composite Whetstone",
	SCHEELITE = "Scheelite",
	FERBERITE = "Ferberite",
	HEAT_RESISTANT_PLASTER = "Heat-resistant Plaster",
	CLEAR_FLUORITE = "Clear Fluorite",
	EMERY = "Emery",
	SILTSTONE_WHETSTONE = "Siltstone Whetstone",
	LAMPBLACK_LINSEED_OIL = "Lampblack Linseed Oil",
	SAURIAN_SKIN = "Saurian Skin",
	SHROUD_TEA_LEAVES = "Shroud Tea Leaves",
	CAMEL_HAIR = "Camel Hair",
	CASHMERE_FLEECE = "Cashmere Fleece",
	DILUTED_VITRIOL = "Diluted Vitriol",
	BLACK_CINNABAR = "Black Cinnabar",
	RAZIQSAND = "Raziqsand",
	TARANTULA = "Tarantula",
	DUCK_BONES = "Duck Bones",
	CANARD_BREAST = "Canard Breast",
	THYME = "Thyme",
	WILD_ONION = "Wild Onion",
	GARLEAN_GARLIC = "Garlean Garlic",
	MAGICKED_OILCLOTH = "Magicked Oilcloth",
	BAMBOO_PAPER = "Bamboo Paper",
	GIGANT_CLAM = "Gigant Clam",
	GLACIER_CRYSTAL = "Glacier Crystal",
	SILTSTONE = "Siltstone",
	FILTERED_WATER = "Filtered Water",
	AMPLIFYING_ACHONDRITE = "Amplifying Achondrite",
	CONNOISSEURS_PICTURE_FRAME = "Connoisseur's Picture Frame", // Chora Zoi's Crystalline / Customized Components
	CONNOISSEURS_SAMURAI_BLADE = "Connoisseur's Samurai Blade",
	CONNOISSEURS_AQUARIUM = "Connoisseur's Aquarium",
	CONNOISSEURS_GLAIVES = "Connoisseur's Glaives",
	CONNOISSEURS_VARSITY_SHOES = "Connoisseur's Varsity Shoes",
	CONNOISSEURS_LINEN_PARASOL = "Connoisseur's Linen Parasol",
	CONNOISSEURS_TOPIARY_MOOGLE = "Connoisseur's Topiary Moogle",
	CONNOISSEURS_SQUID_BAGUETTE = "Connoisseur's Squid Baguette",
	CONNOISSEURS_SOILED_FEMUR = "Connoisseur's Soiled Femur",
	CONNOISSEURS_MIRACLE_APPLE = "Connoisseur's Miracle Apple",
	GOLD_DUSTFISH = "Gold Dustfish",
	FORGIVEN_MELANCHOLY = "Forgiven Melancholy",
	CONNOISSEUR_BOOKSHELF = "Connoisseur's Bookshelf", // Brilliant / Brilliant Components
	CONNOISSEUR_CHANDELIER = "Connoisseur's Chandelier",
	CONNOISSEUR_ESCUTCHEON = "Connoisseur's Escutcheon",
	CONNOISSEUR_BAGHNAKHS = "Connoisseur's Baghnakhs",
	CONNOISSEUR_DRINKING_APKALLU = "Connoisseur's Drinking Apkallu",
	CONNOISSEUR_JACKET = "Connoisseur's Jacket",
	CONNOISSEUR_PLANTER_PARTITION = "Connoisseur's Planter Partition",
	CONNOISSEUR_SPAGHETTI_AL_NERO = "Connoisseur's Spaghetti al Nero",
	CONNOISSEUR_AURUM_REGIS_ORE = "Connoisseur's Aurum Regis Ore",
	CONNOISSEUR_CLOVES = "Connoisseur's Cloves",
	OIL_SLICK = "Oil Slick",
	GONZALOS_GRACE = "Gonzalo's Grace",
	CONNOISSEUR_BAMBOO_FENCE = "Connoisseur's Bamboo Fence", // Vrandtic Visionary's / Inspirational Components
	CONNOISSEUR_ROUSING_CHRONOMETER = "Connoisseur's Rousing Chronometer",
	CONNOISSEUR_TRUMPET = "Connoisseur's Trumpet",
	CONNOISSEUR_WASHBASIN = "Connoisseur's Washbasin",
	CONNOISSEUR_TARGE = "Connoisseur's Targe",
	CONNOISSEUR_PETASOS = "Connoisseur's Petasos",
	CONNOISSEUR_LUNAR_CURTAIN = "Connoisseur's Lunar Curtain",
	CONNOISSEUR_CRAB_CAKES = "Connoisseur's Crab Cakes",
	CONNOISSEUR_ASPHALTUM = "Connoisseur's Asphaltum",
	CONNOISSEUR_GIANTHIVE_CHIP = "Connoisseur's Gianthive Chip",
	DEADWOOD_SHADOW = "Deadwood Shadow",
	RONKAN_BULLION = "Ronkan Bullion",
	CONNOSISSEUR_SHISHI_ODOSHI = "Connoisseur's Shishi-odoshi", // Lodestar / Nightforged Components
	CONNOSISSEUR_RETAINER_BELL = "Connoisseur's Retainer Bell",
	CONNOSISSEUR_MARCHING_HORN = "Connoisseur's Marching Horn",
	CONNOSISSEUR_MARBLE_FOUNTAIN = "Connoisseur's Marble Fountain",
	CONNOSISSEUR_LEATHER_JACKET = "Connoisseur's Leather Jacket",
	CONNOSISSEUR_FAT_CAT_SOFA = "Connoisseur's Fat Cat Sofa",
	CONNOSISSEUR_TINCTURE_OF_VITALITY = "Connoisseur's Tincture of Vitality",
	CONNOSISSEUR_CHILI_CRAB = "Connoisseur's Chili Crab",
	CONNOISSEUR_RAW_ONYX = "Connoisseur's Raw Onyx",
	CONNOISSEUR_GLIMSHROOM = "Connoisseur's Glimshroom",
	LITTLE_BOUNTY = "Little Bounty",
	SAINT_FATHRICS_FACE = "Saint Fathric's Face",
	SELECT_DARK_CHESTNUT_LUMBER = "Select Dark Chestnut Lumber",
	RED_PINE_LUMBER = "Red Pine Lumber",
	LINEN_CANVAS = "Linen Canvas",
	RED_PINE_LOG = "Red Pine Log",
	COTTON_YARN = "Cotton Yarn",
	BEESWAX = "Beeswax",
	COTTON_BOLL = "Cotton Boll",
	BEEHIVE_CHIP = "Beehive Chip",
	SELECT_BISMUTH_INGOT = "Select Bismuth Ingot",
	PEWTER_INGOT = "Pewter Ingot",
	OROSHIGANE_INGOT = "Oroshigane Ingot",
	PEWTER_ORE = "Pewter Ore",
	TIN_ORE = "Tin Ore",
	DOMAN_IRON_ORE = "Doman Iron Ore",
	SELECT_COBALT_PLATE = "Select Cobalt Plate",
	MANGANESE_INGOT = "Manganese Ingot",
	CRYSTAL_GLASS = "Crystal Glass",
	MANGANESE_ORE = "Manganese Ore",
	MOLYBDENUM_ORE = "Molybdenum Ore",
	SILEX = "Silex",
	NATRON = "Natron",
	MINIUM = "Minium",
	SELECT_BLUESPIRIT_TILE = "Select Bluespirit Tile",
	MANASILVER_NUGGET = "Manasilver Nugget",
	SELECT_GREEN_GLIDER_LEATHER = "Select Green Glider Leather",
	KUMBHIRA_LEATHER = "Kumbhira Leather",
	IRIDESCENT_SILK = "Iridescent Silk",
	KUMBHIRA_SKIN = "Kumbhira Skin",
	IRIDESCENT_SILK_THREAD = "Iridescent Silk Thread",
	IRIDESCENT_COCOON = "Iridescent Cocoon",
	SELECT_WATERPROOF_CLOTH = "Select Waterproof Cloth",
	SELECT_RAKTIKA_SEEDLING = "Select Rak'tika Seedling",
	GRADE_5_INTELLIGENCE_ALKAHEST = "Grade 5 Intelligence Alkahest",
	GROWTH_FORMULA_KAPPA = "Growth Formula Kappa",
	FERNLEAF_LAVENDER = "Fernleaf Lavender",
	HOPTRAP_LEAF = "Hoptrap Leaf",
	SELECT_SQUID_INK = "Select Squid Ink",
	NORTHERN_SEA_SALT = "Northern Sea Salt",
	UPLAND_WHEAT_FLOUR = "Upland Wheat Flour",
	SHARLAYAN_ROCK_SALT = "Sharlayan Rock Salt",
	UPLAND_WHEAT = "Upland Wheat",
	IRONWOOD_LUMBER = "Ironwood Lumber",
	PHRYGIAN_GOLD_INGOT = "Phrygian Gold Ingot",
	ZINC_ORE = "Zinc Ore",
	APKALLU_DOWN = "Apkallu Down",
	SCARLET_MOKO_CLOTH = "Scarlet Moko Cloth",
	SAIGA_LEATHER = "Saiga Leather",
	SCARLET_MOKO_GRASS = "Scarlet Moko Grass",
	SAIGA_HIDE = "Saiga Hide",
	PERILLA_OIL = "Perilla Oil",
	GARLEAN_CHEESE = "Garlean Cheese",
	VERMICELLI = "Vermicelli",
	THAVNAIRIAN_PERILLA_LEAF = "Thavnairian Perilla Leaf",
	OVIBOS_MILK = "Ovibos Milk",
	ABALATHIAN_ROCK_SALT = "Abalathian Rock Salt",
	SELECT_BAMBOO_STICK = "Select Bamboo Stick",
	LIMESTONE = "Limestone",
	SELECT_DURALUMINUM_INGOT = "Select Duraluminum Ingot",
	SELECT_BRASHGOLD_PLATE = "Select Brashgold Plate",
	TRUEGOLD_ORE = "Truegold Ore",
	HORSE_CHESTNUT_LOG = "Horse Chestnut Log",
	SELECT_MARBLE = "Select Marble",
	SELECT_CHALICOTHERIUM_LEATHER = "Select Chalicotherium Leather",
	SELECT_DUSKCOURT_CLOTH = "Select Duskcourt Cloth",
	RUBY_COTTON_BOLL = "Ruby Cotton Boll",
	SELECT_CUDWEED = "Select Cudweed",
	MOUSSE_FLESH = "Mousse Flesh",
	SELECT_BLUE_CRAB = "Select Blue Crab",
	EGG_OF_ELPIS = "Egg of Elpis",
	AMBROSIAL_WATER = "Ambrosial Water",
	GRANITE = "Granite",
	WILDFOWN_FEATHER = "Wildfowl Feather",
	BERKANAN_SAP = "Berkanan Sap",
	AMRA = "Amra",
	BLOOD_TOMATO = "Blood Tomato",
	PEARL_GINGER = "Pearl Ginger",
	CUSTOM_ICE_CRYSTAL = "Custom Ice Crystal",
	CUSTOM_WIND_CRYSTAL = "Custom Wind Crystal",
	BRILLIANT_LIGHTNING_CLUSTER = "Brilliant Lightning Cluster",
	BRILLIANT_EARTH_CLUSTER = "Brilliant Earth Cluster",
	INSPIRATIONAL_WIND_CLUSTER = "Inspirational Wind Cluster",
	INSPIRATIONAL_FIRE_CLUSTER = "Inspirational Fire Cluster",
	NIGHTFORGED_ICE_CLUSTER = "Nightforged Ice Cluster",
	NIGHTFORGED_WATER_CLUSTER = "Nightforged Water Cluster",
	COSMIC_CRYSTALLITE = "Cosmic Crystallite",
}
//#endregion Item

//#region Quest
export enum Quest {
	UNKNOWN = "GUnknown",
	ULTIMATE_WEAPON = "The Ultimate Weapon",
	WEAPONSMITH_OF_LEGEND = "The Weaponsmith of Legend",
	RELIC_REBORN = "A Relic Reborn",
	UP_IN_ARMS = "Up in Arms",
	TRIALS_OF_THE_BRAVES = "Trials of the Braves",
	CELESTIAL_RADIANCE = "Celestial Radiance",
	STAR_LIGHT_STAR_BRIGHT = "Star Light, Star Bright",
	WHEREFORE_ART_THOU_ZODIAC = "Wherefore Art Thou, Zodiac",
	ONE_MANS_TRASH = "One Man's Trash",
	HEAVENSWARD = "Heavensward",
	UNEXPECTED_PROPOSAL = "An Unexpected Proposal",
	SOUL_WITHOUT_LIFE = "Soul Without Life",
	TOUGHENING_UP = "Toughening Up",
	COMING_INTO_ITS_OWN = "Coming into Its Own",
	MISLAID_PLANS = "Mislaid Plans",
	PONZE_OF_FLESH = "A Ponze of Flesh",
	LABOR_OF_LOVE = "Labor of Love",
	TREASURED_MOTHER = "A Treasured Mother",
	METHOD_IN_HIS_MALACE = "Method in His Malice",
	HIS_DARK_MATERIA = "His Dark Materia",
	SHADOWBRINGERS = "Shadowbringers",
	LOST_CITY_OF_ANGELS = "The Lost City of Angels",
	FIRE_IN_THE_FORGE = "Fire in the Forge",
	RESISTANCE_IS_NOT_FUTILE = "Resistance is (Not) Futile",
	SOBER_PROPSAL = "A sober Proposal",
	WHERE_EAGLES_NEST = "Where Eagles Nest",
	VOWS_VIRTUE_DEEDS_CRUELTY = "Vows of Virtue, Deeds of Cruelty",
	FOR_WANT_OF_A_MEMORY = "For want of a Memory",
	WILL_TO_RESIST = "The will to Resist",
	IN_THE_QUEENS_IMAGE = "In the Queen's Image",
	CHANGE_OF_ARMS = "Change of Arms",
	RESISTANCE_REMEMBERS = "The Resistance Remembers",
	NEW_PATH_OF_RESISTANCE = "A New Path of Resistance",
	HAIL_TO_THE_QUEEN = "Hail to the Queen",
	PATH_TO_THE_PAST = "Path to the Past",
	BOZJA_INCIDENT = "The Bozja Incident",
	WORK_HARDR_MAKE_BETTER = "Work It Harder, Make It Better",
	TOWARDS_THE_FIRMAMENT = "Towards the Firmament",
	EVER_SKYWARD = "Ever Skyward",
	PINNACLE_OF_POSSIBILITY = "The Pinnacle of Possibility",
	TOOLS_OF_TOMORROW = "The Tools of Tomorrow",
	FINDING_YOUR_VOICE = "Finding your Voice",
	GIFT_OF_THE_ARCHMAGUS = "The Gift of the Archmagus",
	DREAM_FULFILLED = "A Dream fulfilled",
	FUTURE_PROOF = "Future Proof",
	BORN_AGAIN = "Born Again Anima",
	SOME_ASSEMBLY_REQUIRED = "Some Assembly Required",
	WORDS_OF_WISDOM = "Words of Wisdom",
	BEST_FRIENDS_FOREVER = "Best Friends Forever",
	STORMBLOOD = "Stormblood",
	WE_SHALL_CALL_IT_EUREKA = "And We Shall Call It Eureka",
	WHAT_DREAMS_ARE_MADE_OF = "What Dreams Are Made Of",
	SPARE_PARTS = "Spare Parts",
	TELL_ME_A_STORY = "Tell Me a Story",
	FOND_MEMORY = "A Fond Memory",
	DONE_DEAL = "A Done Deal",
	IRRESISTIBLE = "Irresistible",
	SOULGLAZED_RELICS = "Mmmmmm, Soulglazed Relics",
	RISE_AND_SHINE = "Rise and Shine",
	VITAL_TITLE = "The Vital Title",
	SEEKING_INSPIRATION = "Seeking Inspiration",
	CUT_FROM_DIFFERENT_CLOTH = "Cut from a Different Cloth",
	BODY_SOUL = "Body and Soul",
	ENDWALKER = "Endwalker",
	IMPERFECT_GENTLEMAN = "The Imperfect Gentleman",
	MAKE_IT_A_MANDERVILLE = "Make it a Manderville",
	MAKE_ANOTHER_MANDERVILLE = "Make Another Manderville",
	ODDNESS_IN_THE_END = "Oddness in the End",
	IN_EVERYONES_BEST_INTERESTS = "In Everyone's Best Interests",
	SIGN_OF_WHATS_TO_COME = "A Sign of What's to Come",
	FIT_FOR_A_QUEEN = "Fit for a Queen",
	NEW_PLAYING_FIELD = "A New Playing Field",
	GENERATIONAL_BONDING = "Generation Bonding",
	WELL_OILED = "Well-oiled",
	NEXT_MANDER_LEVEL = "The Next Mander-level",
	CRYSTALLINE_MEAN = "The Crystalline Mean",
	ORIGINAL_IMPROVEMENT = "An Original Improvement",
	DEDICATED_TOOL = "A Dedicated Tool",
	ADAPTIVE_TOOL = "An Adaptive Tool",
	JUST_TOOLING_AROUND = "Just Tooling Around",
	SAVING_CAPTAIN_GAIRHARD = "Saving Captain Gairhard",
	WAITING_IN_THE_WINGLET = "Waiting in the Winglet",
	RIVALRY_AND_RESPECT = "Rivalry and Respect",
	JADED = "Jaded",
	ACCEPT_NO_IMITATIONS = "Accept No Imitations",
	A_MINER_REBORN = "A Miner Reborn",
	MOMENTARY_MIRACLE = "Momentary Miracle",
	REVENGE_OF_THE_CHEFSBANE = "Revenge of the Chefsbane",
	CANYON_OF_REGRET = "Canyon of Regret",
	SEEDS_OF_HOPE = "Seeds of Hope",
	SO_LONG_THANKS_FOR_ALL_THE_FISH = "So Long, and Thanks for All the Fish",
	A_SPIRITED_REFORGING = "A Spirited Reforging",
	IN_NEED_OF_ADJUSTMENT = "In Need of Adjustment",
	TOOL_OF_HER_OWN = "A Tool of Her Own",
	TOOL_WITHOUT_COMPARE = "A Tool without Compare",
	TOOL_FOR_THE_AGES = "A Tool for the Ages",
	STAND_TOOL_MY_FRIEND = "Stand Tool, My Friend",
	GENTLEMEN_AT_HEART = "Gentlemen at Heart",
	RESONATING_WITH_PERFECTION = "Resonating with Perfection",
	POSITIVELY_MANDERVILLOUS = "Positively Mandervillous",
}
//#endregion Quest

//#region Enemy
export enum Enemy {
	UNKNOWN = "GUnknown",
	ZAHARAK_LANCER = "Zahar'ak Lancer",
	ZAHARAK_PUGILIST = "Zahar'ak Pugilist",
	ZAHARAK_THAUMATURGE = "Zahar'ak Thaumaturge",
	UGHAMARO_QUARRYMAN = "U'Ghamaro Quarryman",
	UGHAMARO_BEDESMAN = "U'Ghamaro Bedesman",
	UGHAMARO_PRIEST = "U'Ghamaro Priest",
	UGHAMARO_ROUNDSMAN = "U'Ghamaro Roundsman",
	NATALAN_BOLDWING = "Natalan Boldwing",
	NATALAN_FOGCALLER = "Natalan Fogcaller",
	NATALAN_WINDTALON = "Natalan Windtalon",
	ZAHARAK_ARCHER = "Zahar'ak Archer",
	SAPSA_SHELFSPINE = "Sapsa Shelfspine",
	SAPSA_SHELFCLAW = "Sapsa Shelfclaw",
	SAPSA_SHELFTOOTH = "Sapsa Shelftooth",
	VIOLET_SIGH = "Violet Sigh",
	VIOLET_SCREECH = "Violet Screech",
	VIOLET_SNARL = "Violet Snarl",
	DARING_HARRIER = "Daring Harrier",
	COHORT_VANGUARD_5TH = "5th Cohort Vanguard",
	COHORT_HOPLOMACHUS_4TH = "4th Cohort Hoplomachus",
	BASILISK = "Basilisk",
	ZANRAK_PUGILIST = "Zanr'ak Pugilist",
	MILKROOT_CLUSTER = "Milkroot Cluster",
	GIANT_LOGGER = "Giant Logger",
	SYNTHETIC_DOBLYN = "Synthetic Doblyn",
	SHOALSPINE_SAHAGIN = "Shoalspine Sahagin",
	COHORT_HOPLOMACHUS_2ND = "2nd Cohort Hoplomachus",
	RAGING_HARRIER = "Raging Harrier",
	BIAST = "Biast",
	SHOALTOOTH_SAHAGIN = "Shoaltooth Sahagin",
	SHELFSCALE_REAVER = "Shelfscale Reaver",
	UGHAMARO_GOLEM = "U'Ghamaro Golem",
	DULLAHAN = "Dullahan",
	SYLPHEED_SIGH = "Sylpheed Sigh",
	TEMPERED_GLADIATOR = "Tempered Gladiator",
	HEXING_HARRIER = "Hexing Harrier",
	GIGAS_BONZE = "Gigas Bonze",
	GIANT_LUGGER = "Giant Lugger",
	WILD_HOG = "Wild Hog",
	SYLPHEED_SCREECH = "Sylpheed Screech",
	SHELFCLAW_REAVER = "Shelfclaw Reaver",
	COHORT_LAQUEARIUS_2ND = "2nd Cohort Laquearius",
	ZAHARAK_FORTUNETELLER = "Zahar'ak Fortune-teller",
	TEMPERED_ORATOR = "Tempered Orator",
	MUDPUPPY = "Mudpuppy",
	LAKE_COBRA = "Lake Cobra",
	GIANT_READER = "Giant Reader",
	SHELFSCALE_SAHAGIN = "Shelfscale Sahagin",
	SEA_WASP = "Sea Wasp",
	COHORT_EQUES_2ND = "2nd Cohort Eques",
	MAGITEK_VANGUARD = "Magitek Vanguard",
	AMALJAA_LANCER = "Amalj'aa Lancer",
	SYLPHLANDS_SENTINEL = "Sylphlands Sentinel",
	GIGAS_BHIKKHU = "Gigas Bhikkhu",
	COHORT_HOPLOMACHUS_5TH = "5th Cohort Hoplomachus",
	NATALAN_WATCHWOLF = "Natalan Watchwolf",
	SYLPH_BONNET = "Sylph Bonnet",
	KED = "Ked",
	COHORT_LAQUEARIUS_4TH = "4th Cohort Laquearius",
	IRON_TORTOISE = "Iron Tortoise",
	SHELFEYE_REAVER = "Shelfeye Reaver",
	SAPSA_SHELFSCALE = "Sapsa Shelfscale",
	AMALJAA_BRIGAND = "Amalj'aa Brigand",
	COHORT_SECUTOR_4TH = "4th Cohort Secutor",
	SNOW_WOLF = "Snow Wolf",
	IXALI_WINDTALON = "Ixali Windtalon",
	LESSER_KALONG = "Lesser Kalong",
	GIGAS_SOZU = "Gigas Sozu",
	COHORT_LAQUEARIUS_5TH = "5th Cohort Laquearius",
	HIPPOGRYPH = "Hippogryph",
	COHORT_EQUES_5TH = "5th Cohort Eques",
	SAPSA_ELBST = "Sapsa Elbst",
	TRENCHTOOTH_SAHAGIN = "Trenchtooth Sahagin",
	ELITE_ROUNDSMAN = "Elite Roundsman",
	COHORT_SECUTORES_2ND = "2nd Cohort Secutores",
	AHRIMAN = "Ahriman",
	AMALJAA_THAUMATURGE = "Amalj'aa Thaumaturge",
	SYLPHEED_SNARL = "Sylpheed Snarl",
	GIGAS_SHRAMANA = "Gigas Shramana",
	COHORT_SIGNIFER_5TH = "5th Cohort Signifer",
	DREAMTOAD = "Dreamtoad",
	ZAHARAK_BATTLE_DRAKE = "Zahar'ak Battle Drake",
	AMALJAA_ARCHER = "Amalj'aa Archer",
	COHORT_SIGNIFER_4TH = "4th Cohort Signifer",
	ELITE_PRIEST = "Elite Priest",
	IXALI_BOLDWING = "Ixali Boldwing",
	AMALJAA_SCAVENGER = "Amalj'aa Scavenger",
	AXOLOTL = "Axolotl",
	ELITE_QUARRYMAN = "Elite Quarryman",
	COHORT_SIGNIFER_2ND = "2nd Cohort Signifer",
	NATALAN_SWIFTBEAK = "Natalan Swiftbeak",
	COHORT_SECUTOR_5TH = "5th Cohort Secutor",
	HAPALIT = "Hapalit",
	WATCHWOLF = "Watchwolf",
	ATROCIRAPTOR = "Atrociraptor",
	VAMPIRE_VINE = "Vampire Vine",
	VAMPIRE_CUP = "Vampire Cup",
	DIADEM_MELIA = "Diadem Melia",
	DIADEM_ICETRAP = "Diadem Icetrap",
	DIADEM_ICE_BOMB = "Diadem Ice Bomb",
	DIADEM_ZOBLYN = "Diadem Zoblyn",
	PROTO_NOCTILUCALE = "Proto-noctilucale",
	DIADEM_BLOATED_BULB = "Diadem Bloated Bulb",
	DIADEM_ICE_GOLEM = "Diadem Ice Golem",
	DIADEM_BIAST = "Diadem Biast",
	DIADEM_GOLEM = "Diadem Golem",
	DIADEM_WEREWOOD = "Diadem Werewood",
	GRENADE = "Grenade",
	WOOLY_YAK = "Wooly Yak",
	OKEANIS = "Okeanis",
	FLAME_ZONURE = "Flame Zonure",
	TEMPEST_SWALLOW = "Tempest Swallow",
	OCHU = "Ochu",
	ROCKSKIN_PEISTE = "Rockskin Peiste",
	SANDSKIN_PEISTE = "Sandskin Peiste",
	OVIM_BILLY = "Ovim Billy",
	OVIM_NANNY = "Ovim Nanny",
	ALMASTY = "Almasty",
	IANTHINE_PETALOUDA = "Ianthine Petalouda",
	KOKKINE_PETALOUDA = "Kokkine Petalouda",
	KYANE_PETALOUDA = "Kyane Petalouda",
	OPHIOTAUROS = "Ophiotauros",
	LUNATENDER = "Lunatender",
	MOURNING_BILLY = "Mourning Billy",
	MOURNING_NANNY = "Mourning Nanny",
	MYOTRAGUS_BILLY = "Myotragus Billy",
	MYOTRAGUS_NANNY = "Myotragus Nanny",
	KUMBHIRA = "Kumbhira",
	HOPTRAP = "Hoptrap",
	APKALLU = "Apkallu",
	SAIGA = "Saiga",
	OVIBOS = "Ovibos",
	MOUSSE = "Mousse",
	BIRD_OF_ELPIS = "Bird of Elpis",
	BERKANAN = "Berkanan",
	VAJRALANGULA = "Vajralangula",
}
//#endregion Enemy

//#region Fishing Holes
export enum Fishing {
	UNKNOWN = "GUnknown",
	DELTA_QUADRANT = "Delta Quadrant",
	THALIAK_RIVER = "Thaliak River",
	DRAGONSPIT = "Dragonspit",
	PLUM_SPRING = "Plum Spring",
	ALPHA_QUADRANT = "Alpha Quadrant",
	PAPPUS_TREE = "The Pappus Tree",
	BRONZE_LAKE_SHALLOWS = "Bronze Lake Shallows",
	NORTH_SILVERTEAR = "North Silvertear",
	HALFSTONE = "Halfstone",
	SILVER_BAZAAR = "The Silver Bazaar",
	CAPE_WESTWIND = "Cape Westwind",
	COSTA_DEL_SOL = "Costa Del Sol",
	UMBRA_NORTHSHORE = "Isles of Umbra Northshore",
	SOUTH_BLOODSHORE = "South Bloodshore",
	SUMMERFORD = "Summerford",
	SOURCE = "The Source",
	SEAGAZER_SHOALS = "Seagazer Shoals",
	HANDMIRROR_LAKE = "Handmirror Lake",
	LAKE_TUSI_MEKTA = "Lake Tusi Mek'ta",
	SAGOLII_DUNES = "Sagolii Dunes",
	NABAATH_SEVERANCE = "The Nabaath Severance",
	ISLE_OF_KEN = "The Isle of Ken",
	WHITE_OIL_FALLS = "White Oil Falls",
	WEST_CALIBAN_GAP = "West Caliban Gap",
	MJRLS_TEARS = "Mjrl's Tears",
	SAINT_FATHRICS_TEMPLE = "Saint Fathric's Temple",
}
//#endregion Fishing Holes

//#region Bait
export enum Bait {
	UNKNOWN = "GUnknown",
	SKYBALL = "Signature Skyball",
	BUTTERWORM = "Butterworm",
	GLOWWORM = "Glowworm",
	HEAVY_STEEL_JIG = "Heavy Steel Jig",
	STEEL_JIG = "Steel Jig",
	SINKING_MINNOW = "Sinking Minnow",
	YUMIZUNO = "Yumizuno",
	NORTHERN_KRILL = "Northern Krill",
	SPOON_WORM = "Spoon Worm",
	VERSATILE_LURE = "Versatile Lure",
	KRILL_CAGE_FEEDER = "Krill Cage Feeder",
	SHRIMP_CAGE_FEEDER = "Shrimp Cage Feeder",
	LUGWORM = "Lugworm",
	PILL_BUG = "Pill Bug",
	GOBY_BALL = "Goby Ball",
	RAT_TAIL = "Rat Tail",
	FLOATING_MINNOW = "Floating Minnow",
	SALTWATER_BOILIE = "Saltwater Boilie",
	SELECT_BAIT_BALL = "Select Bait Ball",
	SAND_LEECH = "Sand Leech",
	SAND_GECKO = "Sand Gecko",
}
//#endregion Bait

//#region Fish
export enum Fish {
	UNKNOWN = "GUnknown",

	/* Fish that appear in Items */
	THINKERS_CORAL = "Thinker's Coral", // Item.THINKERS_CORAL
	DRAGONSPINE = "Dragonspine", // Item.DRAGONSPINE
	PETAL_SHELL = "Petal Shell", // Item.PETAL_SHELL
	ALLAGAN_HUNTER = "Allagan Hunter", // Item.ALLAGAN_HUNTER
	FLINTSTRIKE = "Flintstrike", // Item.FLINTSTRIKE
	PICKLED_POM = "Pickled Pom", // Item.PICKLED_POM
	WORM_OF_NYM = "Worm of Nym", // Item.WORM_OF_NYM
	AETHERLOUSE = "Aetherlouse", // Item.AETHERLOUSE
	HAMMERHEAD_SHARK = "Hammerhead Shark", // Item.HAMMERHEAD_SHARK
	SILVER_SHARK = "Silver Shark", // Item.SILVER_SHARK
	PLATINUM_SEAHORSE = "Platinum Seahorse", // Item.PLATINUM_SEAHORSE
	CLAVEKEEPER = "Clavekeeper", // Item.CLAVEKEEPER
	MIRROR_IMAGE = "Mirror Image", // Item.MIRROR_IMAGE
	SPANGLED_PIRARUCU = "Spangled Pirarucu", // Item.SPANGLED_PIRARUCU
	OLGOI_KHORKHOI = "Olgoi-Khorkhoi", // Item.OLGOI_KHORKHOI
	GIGANT_CLAM = "Gigant Clam", // Item.GIGANT_CLAM
	GOLD_DUSTFISH = "Gold Dustfish", // Item.GOLD_DUSTFISH
	FORGIVEN_MELANCHOLY = "Forgiven Melancholy", // Item.FORGIVEN_MELANCHOLY
	OIL_SLICK = "Oil Slick", // Item.OIL_SLICK
	GONZALOS_GRACE = "Gonzalo's Grace", // Item.GONZALOS_GRACE
	DEADWOOD_SHADOW = "Deadwood Shadow", // Item.DEADWOOD_SHADOW
	RONKAN_BULLION = "Ronkan Bullion", // Item.RONKAN_BULLION
	LITTLE_BOUNTY = "Little Bounty", // Item.LITTLE_BOUNTY
	SAINT_FATHRICS_FACE = "Saint Fathric's Face", // Item.SAINT_FATHRICS_FACE

	/* Other Fish that a full item is not needed for */
	MERLTHOR_GOBY = "Merlthor Goby",
	WHAOO = "Wahoo",
	FULLMOON_SARDINE = "Fullmoon Sardine",
	ORGRE_BARRACUDA = "Ogre Barracuda",
	HARBOR_HERRING = "Harbor Herring",
	STORM_RIDER = "Storm Rider",
	GOLDING = "Golding",
}
//#endregion Fish

//#region Weather
export enum Weather {
	CLEAR_SKIES= "Clear Skies",
	FAIR_SKIES = "Fair Skies",
	CLOUDS = "Clouds",
	FOG = "Fog",
	WIND = "Wind",
	GALES = "Gales",
	RAIN = "Rain",
	SHOWERS = "Showers",
	THUNDER = "Thunder",
	THUDNERSTORMS = "Thunderstorms",
	DUST_STORMS = "Dust Storms",
	SANDSTORMS = "Sandstorms",
	HEAT_WAVES = "Heat Waves",
	SNOW = "Snow",
	BLIZZARDS = "Blizzards",
	GLOOM = "Gloom",
	MOON_DUST = "Moon Dust",
	ASTROMAGNETIC_STORM = "Astromagnetic Storm",
	UMBRAL_FLARE = "Umbral Flare",
	UMBRAL_DUSTSTORM = "Umbral Duststorm",
	UMBRAL_LEVIN = "Umbral Levin",
	UMBRAL_TEMPEST = "Umbral Tempest",
}
//#endregion Weather

//#region Grand Companies
export enum GrandCompany {
	TWIN_ADDER = "The Order of the Twin Adder",
	FLAME = "The Immortal Flames",
	MAELSTROM = "The Maelstrom",
}
//#endregion Grand Companies

//#region Gather Methods
export enum GatherMethod {
	MINING = "Mining",
	QUARRYING = "Quarrying",
	LOGGING = "Logging",
	HARVESTING = "Harvesting",
}
//#endregion Gather Methods

//#region Markets
export enum Market {
	UNIVERSALIS = "Universalis",
}
//#endregion Markets

//#region ChangeType
export enum ChangeType {
	NEW = "NEW",
	FIX = "FIX",
	KNOWN_ISSUE = "KNOWN ISSUE",
	UPDATED = "UPDATED",
	UPCOMING = "UPCOMING",
}
//#endregion ChangeType

//#region Input Types
export enum InputType {
	BUTTON = "button",
	CHECKBOX = "checkbox",
	COLOR = "color",
	DATE = "date",
	DATETIMELOCAL = "datetime-local", // Date & Time with no specific time zone
	EMAIL = "email",
	FILE = "file",
	HIDDEN = "hidden",
	IMAGE = "image",
	MONTH = "month",
	NUMBER = "number",
	PASSWORD = "password",
	RADIO = "radio",
	RANGE = "range",
	RESET = "reset",
	SEARCH = "search",
	SUBMIT = "submit",
	TEL = "tel",
	TEXT = "text",
	TIME = "time",
	LINK = "url",
	WEEK = "week",
	TEXTAREA = "textarea", // Althought this isnt a <input> tag its very close
}
//#endregion Input Types

//#region Eorzea Data Types
export enum EorzeaDataType {
	UNKNOWN = "Unknown",
	ITEM = "item",
	BLUEQUEST = "bluequest",
	SIDEQUEST = "sidequest",
	MSQ = "msq",
	RECIPE = "recipe",
	LEVE = "leve",
	FATE = "fate",
	DUNGEON = "dungeon",
	TRIAL = "trial",
	RAID = "raid",
	SHOP = "shop",
	GATHER = "gather",
	FISHING = "fishing",
}
//#endregion Eorzea Data Types

//#region APIs
export enum APIEndpoint { // NOTE: These must match API
	GET_USER_DATA = "/data/user/get",
	SAVE_USER_OPTIONS = "/data/options/save",
	SAVE_USER_DATA = "/data/data/save",
	SAVE_TRANSLATIONS = "/data/translations/save",
	GET_NOTICES = "/data/notices/get",
	CHARACTER_SEARCH = "/lodestone/character/search",
	GET_ACHIEVEMENTS = "/lodestone/character/achievements",
	GET_AVATAR = "/lodestone/character/avatar",
	SERVER_LIST = "/lodestone/servers",
}

export enum APIStatus {
	SUCCESS = "SUCCESS",
	ERROR = "ERROR",
	APPERROR = "APPERROR",
}
//#endregion APIs

//#region Data
export enum DataSource {
	REMOTE = "Remote",
	LOCAL = "Local",
}

export enum LocalKeys {
	DATA = "rt_data",
	OPTIONS = "rt_options",
	ANONCHAR = "rt_anonchar",
	BACKUP = "rt_backup",
}

export enum Local_2_1_Keys {
	DATA = "user_data",
	VERSION = "version"
}

export enum OldLocalKeys {
	VERSION = "rt_version",
}
//#endregion Data

//#region State Actions
export enum ActionType {
	SET_BREAKPOINT,
	SET_VIEW,
	ADD_SYS_MESSAGE,
	REMOVE_SYS_MESSAGE,
	SET_USER_INFO,
	SET_USER,
	SET_USER_OPTIONS,
	SET_USER_DATA,
	SET_CHARACTERS,
	DELETE_CHARACTERS,
	SET_NOTICES,
	SET_MODAL_CONFIRMATION,
	SET_MODAL_ENEMIES,
	SET_MODAL_RECIPES,
	SET_MODAL_MARKETS,
	SET_MODAL_EXTRACTIONS,
	SET_MODAL_GATHERING,
	SET_MODAL_FISHING,
	SET_MODAL_SHOPS,
	SET_MODAL_LOCATIONS,
	SET_MODAL_TRIALS,
	SET_MODAL_DUNGEONS,
	SET_MODAL_RAIDS,
	SET_MODAL_FATES,
	SET_MODAL_LEVES,
	SET_MODAL_QUESTS,
	SET_MODAL_OTHER,
	SET_ANONYMOUS_AVAILABLE,
	SET_GAME_SERVERS,
}
//#endregion State Actions

//#region NPCs
export enum NPC {
	UNKNOWN = "GUnknown",
	TMOKKRI = "T'mokkri",
	KLEYTAI = "K'leytai",
	EUSTACE = "Eustace",
	GONTRANT = "Gontrant",
	EIDHART = "Eidhart",
	RURUBANA = "Rurubana",
	VOILINAUT = "Voilinaut",
	LODILLE = "Lodille",
	NEDRICK = "Nedrick Ironheart",
	GEROLT_NORTH_SHROUD = "Gerolt (North Shroud)",
	GEROLT_ANEMOS = "Gerolt (Anemos)",
	GEROLT_PAGOS = "Gerolt (Pagos)",
	GEROLT_PYROS = "Gerolt (Pyros)",
	GEROLT_HYDATOS = "Gerolt (Hydatos)",
	GEROLT_GANGOS = "Gerolt (Gangos)",
	GEROLT_RADZ_AT_HAN = "Gerolt (Radz-At-Han)",
	JALZAHN = "Jalzahn",
	GJUSANA = "G'jusana",
	HUBAIRTIN = "Hubairtin",
	PAPANA = "Papana",
	GUIDING_STAR = "Guiding Star",
	BRANGWINE = "Brangwine",
	ADKIN = "Adkin",
	REMON = "Remon",
	ROWENA_IDYLLSHIRE = "Rowena",
	ARDASHIR = "Ardashir",
	PROCESSING_NODE = "Processing Node",
	JENOMIS = "Jenomis",
	KEITEN = "Keiten",
	MARSAK = "Marsak",
	IRONWORKS_ENGINEER_RHALGRS = "Ironworks Engineer (Rhalgr's Reach)",
	ZLATAN = "Zlatan",
	ALPHINAUD = "Alphinaud",
	STRESSED_SOLDIER = "Stressed Soldier",
	BAJSALJEN = "Bajsaljen",
	HILDIBRAND_LAMENTORUM = "Hildibrand (Mare Lamentorum)",
	HILDIBRAND_THAVNAIR = "Hildibrand (Thavnair)",
	HOUSE_MANDERVILLE_MANSERVANT = "House Manderville Manservant",
	HOUSE_MANDERVILLE_ARTISAN = "House Manderville Artisan",
	SKYSTEEL_ENGINEER = "Skysteel Engineer",
	DENYS = "Denys",
	NEILLEMARD = "Neillemard",
	NIMIE = "Nimie",
	EMENY = "Emeny",
	MOWEN = "Mowen",
	KATLISS = "Katliss",
	CHORA_ZOI = "Chora-Zoi",
	TALAN = "Talan",
}
//#endregion NPCs

//#region Icons
export enum Icon {
	REDUCTION,
	UNKNOWN,
	BLUE_QUEST,
	NPC, // eslint-disable-line @typescript-eslint/no-shadow
	MARKET,
	DESYNTHESIS,
	DUNGEON,
	DUTY,
	FATE,
	FISHING,
	GRAND_COMPANY,
	GATHERING,
	LEVE,
	LOCATION,
	MAP,
	EXTRACTION,
	MSQ,
	OTHER,
	QUEST,
	RAID,
	RECIPE,
	SHOP,
	ENEMY,
	TELEPORT,
	TRIAL,
	REGION,
	BAIT,
}
//#endregion Icons

//#region Link Types
export enum LinkType {
	/* General Links */
	LINK,						// General Link with text, and icon if defined
	ANCHOR,						// Page Anchor
	/* Specialised Links */
	LODESTONE,					// Lodestone popup link with icon &/or text
	MARKET,						// Market link with icon &/or text
}
//#endregion Link Types

//#region Items Table Columns
export enum ItemTableColumn {
	OBTAINED = "obtained",
	ITEM = "item",
	CURRENCY = "currency",
	PRICE = "price",
	COST = "cost",
	REQUIRED = "required",
	USED = "used",
	INVENTORY = "inventory",
	REMAINING = "remaining",
	TOOBTAIN = "toObtain",
	REMAININGCOST = "remainingCost",
	RELIC = "relic",
	ILVL = "iLvl"
}
//#endregion Items Table Columns

//#region Currency Table Columns
export enum CurrencyTableColumn {
	CURRENCY = "currency",
	REQUIRED = "required",
	USED = "used",
	REMAINING = "remaining",
}
//#endregion Currency Table Columns

//#region Relic Array Indexes
export enum RelicIndex {
	TYPE,
	RELIC,
	PART,
	STEP,
	TASK
}
//#endregion Relic Array Indexes

//#region Gallery Layouts
export enum GalleryLayout {
	BYJOB = "byJob",
	BYRELIC = "byRelic",
}
//#endregion Gallery Layouts
