/* eslint-disable max-len */
//#region Imports
import { jobLists } from "character";
import FormComponents from "components/forms/FormComponents";
import JobIcon from "components/job-icon/JobIcon";
import RelicCheckbox from "components/relic-checkbox/RelicCheckbox";
import { RelicPart, View } from "enums";
import manager from "managers/app";
import { Component, SyntheticEvent } from "react";
import { connect } from "react-redux";
import t from "translations/translator";
import { AppState, CompleteParts, OverallTableProps, ViewRelicInfo } from "types";
import utils from "utils";
import "./OverallTable.css";
//#endregion Imports

const mapStateToProps = (state:AppState) => {
	return {
		lang: state.userInfo.options.lang,
		gameLang: state.userInfo.options.gameLang,
		userInfo: state.userInfo,
		ignorePhyseosPart: state.userInfo.options.progress.ignorePhyseosPart,
	};
};

class OverallTable extends Component<OverallTableProps> {
	private completeParts:CompleteParts = { allComplete: false };

	render(){
		const character = manager.data.getActiveCharacter();
		if(!character){ return null; }

		const jobList = jobLists.relic[this.props.relic];
		const parts = manager.relics.getRelicPartsForRelic(this.props.relic);
		this.completeParts = manager.content.getCompleteParts(character.progress, this.props.relicType, this.props.relic, parts);

		return (
			<div id="overall-table" className="table-wrapper">
				<table className="table">
					<thead>
						<tr>
							<th>{t.t("Task")}</th>
							{jobList.map((job) => {
								const weaponName = manager.relics.getRelicNameKey(job, parts[parts.length - 1]);
								return <th key={`${job}_1`} ><JobIcon job={job} tooltip={weaponName} /></th>;
							})}
							<th></th>
						</tr>
					</thead>
					<tbody>
						{parts.map((part) => {
							if(part === RelicPart.WEAPON_PHYSEOS && this.props.ignorePhyseosPart){ return null; }

							const checkboxes:JSX.Element[] = [];
							const relicInfo:ViewRelicInfo = { type: this.props.relicType, relic: this.props.relic, steps: [], stepPlaceholders: {}, jobs: [], part: part, previous: null, next: null };
							jobList.forEach((job) => {
								checkboxes.push(
									<td key={`${part}_${job}_checkbox`}>
										<RelicCheckbox character={character} job={job} relicInfo={relicInfo} />
									</td>,
								);
							});

							const thisView = `${this.props.relicType}-${this.props.relic}-${part}` as View;
							const linkText = `${t.t(part)} (iLvl ${manager.relics.getPartILvl(part)})`;

							return (
								<tr key={part} data-part={part} className={`${this.completeParts[part] ? "complete" : null}`}>
									<td>
										<FormComponents.Button asPlainLink text={linkText} translate={false} onClick={() => { manager.view.change(thisView); }} />
									</td>
									{checkboxes}
									<td><FormComponents.Button class={["no-margin"]} text={this.completeParts[part] ? "Uncheck All" : "Check All" } onClick={this.toggleRowCheckboxes} translate={true} /></td>
								</tr>
							);
						})}
						{this.getAllCompleteRow()}
					</tbody>
				</table>
			</div>
		);
	}

	getAllCompleteRow(){
		if(!this.completeParts.allComplete){ return null; }
		return <tr className="all-complete"><td colSpan={jobLists.relic[this.props.relic].length + 2}><p>
			{t.t("All Complete")}
		</p></td></tr>;
	}

	toggleRowCheckboxes = (evt:SyntheticEvent) => { // Event
		const button = evt.currentTarget as HTMLButtonElement;
		const row = button.closest("tr");
		const part = row?.dataset.part as RelicPart;
		const character = manager.data.getActiveCharacter();
		const jobList = jobLists.relic[this.props.relic];

		if(!character || !part){ return; }

		const isComplete = character.progress.isComplete(this.props.relicType, this.props.relic, part);
		const relicId = `${this.props.relicType}-${this.props.relic}-${part}`;

		if(utils.guards.isRelicId(relicId)){
			jobList.forEach((job) => {
				character.progress.setComplete(relicId, job, !isComplete);
			});

			manager.data.saveCharacters([character], true);
		}
	}
}
export default connect(mapStateToProps)(OverallTable);
