//#region Imports
import FormComponents from "components/forms/FormComponents";
import { Job, RelicGroup, RelicPart } from "enums";
import manager from "managers/app";
import { Component } from "react";
import { connect } from "react-redux";
import { MultiValue, SingleValue } from "react-select";
import t from "translations/translator";
import { AppState, ContentOptionsProps, RSOption, RSOptionGroup } from "types";
import utils from "utils";
import "./ContentOptions.css";
//#endregion Imports

const mapStateToProps = (state:AppState) => {
	return {
		lang: state.userInfo.options.lang,
		gameLang: state.userInfo.options.gameLang,
		breakpoint: state.breakpoint,
		options: state.userInfo.options,
		hideCompletedSteps: state.userInfo.options.hideCompletedSteps,
		hideCompletedTasks: state.userInfo.options.hideCompletedTasks,
		showMaximumItemQty: state.userInfo.options.showMaximumItemQty,
		job: state.userInfo.options.job,
		ignorePhyseosPart: state.userInfo.options.progress.ignorePhyseosPart,
		view: state.view,
	};
};

class ContentOptions extends Component<ContentOptionsProps> {
	render(){
		const relicJobs = this.props.jobs;
		const selectedJob = this.props.job;
		const hideCompletedSteps = this.props.hideCompletedSteps;
		const hideCompletedTasks = this.props.hideCompletedTasks;
		const showMaximumItemQty = this.props.showMaximumItemQty;
		const ignorePhyseosPart = this.props.ignorePhyseosPart;

		if(selectedJob && !relicJobs.includes(selectedJob as Job)){
			this.switchJob({ label: "", value: "none" });
			return null;
		}
		const jobOptions:(RSOption | RSOptionGroup)[] = manager.content.getJobSelectOptions(relicJobs, true);
		const jobDefaultValue = [{ label: t.t(selectedJob ? manager.relics.getJobName(selectedJob) : "None"), value: selectedJob ? selectedJob : "none" }];

		const showBasicOption = !this.props.view.includes("overall");

		return (
			<div id="content-options">
				<FormComponents.Form fieldDirection="row" justifyFields="evenly">
					<FormComponents.Checkbox
						label={{ hide: false, text: "Hide Completed Steps", position: "right", alignment: "center" }}
						input={{ checked: hideCompletedSteps, events: { onClick: this.toggleHideCompletedSteps } }}
					/>
					{showBasicOption
						? <FormComponents.Checkbox
							label={{ hide: false, text: "Hide Completed Tasks", position: "right", alignment: "center" }}
							input={{ checked: hideCompletedTasks, events: { onClick: this.toggleHideCompletedTasks } }}
						/>
						: null
					}
					{showBasicOption
						? <FormComponents.Checkbox
							label={{ hide: false, text: "Show Maximum Required", position: "right", alignment: "center" }}
							input={{ checked: showMaximumItemQty, events: { onClick: this.toggleShowMaximumItemQty } }}
						/>
						: null
					}
					{showBasicOption
						? <FormComponents.Select
							label={ { hide: false, text: "Job", position: "left", alignment: "center" } }
							input={ { options: jobOptions, value: jobDefaultValue, class: ["secondary"], events: { onChange: this.switchJob } } }
							fieldWidth="15%"
						/>
						: null
					}
					{this.props.relic === RelicGroup.WEAPON_EUREKA
						? <FormComponents.Checkbox
							label={{ hide: false, text: "Ignore %PART% part", placeholders: { PART: RelicPart.WEAPON_PHYSEOS }, position: "right", alignment: "center" }}
							input={{ checked: ignorePhyseosPart, events: { onClick: this.toggleIgnorePhyseosPart } }}
						/>
						: null
					}
				</FormComponents.Form>
			</div>
		);
	}

	toggleHideCompletedSteps = () => { // Event
		const options = this.props.options;
		options.hideCompletedSteps = !options.hideCompletedSteps;
		manager.data.saveOptions(options, true);
	}

	toggleHideCompletedTasks = () => { // Event
		const options = this.props.options;
		options.hideCompletedTasks = !options.hideCompletedTasks;
		manager.data.saveOptions(options, true);
	}

	toggleShowMaximumItemQty = () => { // Event
		const options = this.props.options;
		options.showMaximumItemQty = !options.showMaximumItemQty;
		manager.data.saveOptions(options, true);
	}

	switchJob = (option:MultiValue<RSOption>|SingleValue<RSOption>) => {
		if(option instanceof Array){ return; } // Should not be the case, This is a single select
		if(!option){ return; } // Should always have a value

		const job = option.value;
		const options = this.props.options;

		if(utils.guards.isEnumValue(Job, job)){
			options.job = Job[job];
		}else{
			options.job = null;
		}
		manager.data.saveOptions(options, true);
	}

	toggleIgnorePhyseosPart = () => { // Event
		const options = this.props.options;
		options.progress.ignorePhyseosPart = !options.progress.ignorePhyseosPart;
		manager.data.saveOptions(options, true);
	}
}
export default connect(mapStateToProps)(ContentOptions);
