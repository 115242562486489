//#region Imports
import ChangeEntry from "components/change-entry/ChangeEntry";
import { ChangeType } from "enums";
import manager from "managers/app";
import { Component } from "react";
import { connect } from "react-redux";
import t from "translations/translator";
import { AppState, ViewChangeLogProps } from "types";
import "./ChangeLog.css";
import LatestChanges from "./LatestChanges";
//#endregion Imports

const mapStateToProps = (state:AppState) => {
	return {
		lang: state.userInfo.options.lang,
		gameLang: state.userInfo.options.gameLang,
	};
};

class ChangeLog extends Component<ViewChangeLogProps> {
	render(){
		return (
			<div id="change-log">
				<LatestChanges />
				<div className="previous-changes">
					<h2>{t.t("Previous Changes")}</h2>
					<h3>{`${t.t("Version")} 3.3.0`}</h3>
					<ul>
						<ChangeEntry type={ChangeType.NEW} entry="Added Mandervillous" />
						<ChangeEntry type={ChangeType.UPDATED} entry="Added submitted site and game translations for FR, DE, JP" />
						<ChangeEntry type={ChangeType.FIX} entry="Typo in 'Sacred Spring Water' item name" />
						<ChangeEntry type={ChangeType.FIX} entry="Majestic Manderville item not being picked up correctly" />
						<ChangeEntry type={ChangeType.FIX} entry="Skybuilders items being incorrectly marked as used on items page if another job (or multiple) was completed" />
						<ChangeEntry type={ChangeType.FIX} entry="Some oddities with checking / unchecking certain parts" />
						<ChangeEntry type={ChangeType.KNOWN_ISSUE}
							entry="Parts where the last step is a first time only step may be potentially unmarked causing completed jobs to show as not complete - Effects Zodiac Braves and Zeta"
						/>
					</ul>
					<h3>{`${t.t("Version")} 3.2.0`}</h3>
					<ul>
						<ChangeEntry type={ChangeType.UPDATED} entry="Added submitted translations for FR, ES, DE, JP" />
						<ChangeEntry type={ChangeType.UPDATED} entry="Check achievements is available once again" />
						<ChangeEntry type={ChangeType.FIX} entry="Output the correct message on error message pop ups, no more 'Translate Error' appearing" />
						<ChangeEntry type={ChangeType.FIX} entry="Issue with converting old data where the version would not be set correctly" />
						<ChangeEntry type={ChangeType.FIX} entry="Only ask to import old data for Version 2.1.1, newer versions should convert automatically" />
					</ul>
					<h3>{`${t.t("Version")} 3.1.0`}</h3>
					<ul>
						<ChangeEntry type={ChangeType.NEW} entry="Splendorous Parts - Chora Zoi's Crystalline, Brilliant, Vrandtic Visionary's, Lodestar" />
						<ChangeEntry type={ChangeType.FIX} entry="Server crashses should be resolved, moved to new hosting" />
						<ChangeEntry type={ChangeType.FIX} entry="Character Search should now work" />
						<ChangeEntry type={ChangeType.FIX} entry="Night Vinegar recipe's yield was incorrect" />
						<ChangeEntry type={ChangeType.FIX} entry="Some Skysteel recipes were incorrect, mainly around Diadem materials" />
						<ChangeEntry type={ChangeType.FIX} entry="Lucis required item amounts were incorrect" />
						<ChangeEntry type={ChangeType.FIX} entry="Crystal Sand and Umbrite required amounts were incorrect, Note also added to Reconditioned relic page" />
						<ChangeEntry type={ChangeType.FIX} entry="Currencies used for buying Unidentifiable items were incorrect" />
						<ChangeEntry type={ChangeType.FIX} entry="Some Manderville & Splendorous achievements were missing" />
						<ChangeEntry type={ChangeType.FIX} entry="Sorting items table should now correctly update the inventory input" />
					</ul>
					<h3>{`${t.t("Version")} 3.0.0`}</h3>
					<ul>
						<ChangeEntry type={ChangeType.NEW} entry="Website rebuilt from scratch - No more size limitations, will be easier and quicker to update" />
						<ChangeEntry type={ChangeType.NEW} entry="Access your progress from any device with a web browser by creating an account" />
						<ChangeEntry type={ChangeType.NEW} entry="Added Lucis Tools" />
						<ChangeEntry type={ChangeType.NEW} entry="Added Manderville Relics (Except for latest steps, coming soon)" />
						<ChangeEntry type={ChangeType.NEW} entry="Added Splendorous Tools (Except for latest steps, coming soon)" />
						<ChangeEntry type={ChangeType.NEW} entry="Can now set the site and/or game language (Note: Translations are community submitted)" />
						<ChangeEntry type={ChangeType.UPDATED} entry="Light farming steps now have buttons to add amounts of light, and more details" />
						<ChangeEntry type={ChangeType.UPDATED} entry="Added more obtain information on items eg. expanded recipe detalis" />
						<ChangeEntry type={ChangeType.UPCOMING} entry="Site theming rework - Dark theme needs tweaks, Light theme needs work, more themes" />
						<ChangeEntry type={ChangeType.UPCOMING} entry="'Relic' Armours" />
					</ul>
					<h3>{`${t.t("Version")} 2.1.1`}</h3>
					<ul>
						<ChangeEntry type={ChangeType.FIX} entry="Restoring data from version 2.0.0 onwards caused an error on app load preventing it from starting up" />
					</ul>
					<h3>{`${t.t("Version")} 2.1.0`}</h3>
					<ul>
						<ChangeEntry type={ChangeType.NEW}
							entry="App load notice - Some users have reported issues loading the app - This notice serves to provide a way to work around the issue until a fix has been implemented"
						/>
						<ChangeEntry type={ChangeType.NEW} entry="A 'Recent Changes' button to the header bar to allow viewing the recent changes at any time" />
					</ul>
					<h3>{`${t.t("Version")} 2.0.3`}</h3>
					<ul>
						<ChangeEntry type={ChangeType.NEW} entry="Even More Relic Images" />
						<ChangeEntry type={ChangeType.UPDATED} entry="Max image height from 300 to 400 to better accommodate those tall images" />
						<ChangeEntry type={ChangeType.UPDATED} entry="Email field is now required on contact form - I cant help with queries if i have no details to reply to :)" />
					</ul>
					<h3>{`${t.t("Version")} 2.0.2`}</h3>
					<ul>
						<ChangeEntry type={ChangeType.NEW} entry="More Relic Images - Thanks to all who sent them across in the discord" />
						<ChangeEntry type={ChangeType.NEW} entry="Notes regarding the totals displayed for some steps & currency table on Items tab" />
						<ChangeEntry type={ChangeType.NEW} entry="Some tips on beating the grind - Resistance & Anima weapons crossovers" />
						<ChangeEntry type={ChangeType.UPDATED} entry="Various other notes" />
						<ChangeEntry type={ChangeType.FIX} entry="After deleting a character, Check achievements button was not being disabled for the Anonymous character" />
						<ChangeEntry type={ChangeType.FIX} entry="Used items display not updating correctly when more than 1 item is required for a step" />
						<ChangeEntry type={ChangeType.FIX} entry="Item name typo 'Sorrow Memory of the Dying' to 'Sorrowful Memory of the Dying'" />
						<ChangeEntry type={ChangeType.FIX} entry="Version checking not checking correctly" />
					</ul>
					<h3>{`${t.t("Version")} 2.0.1`}</h3>
					<ul>
						<ChangeEntry type={ChangeType.FIX} entry="App breaking issue where restoring data after selecting a character in options will result in an error on load" />
						<ChangeEntry type={ChangeType.FIX} entry="Issue where one time only items are not taken into account properly on the all items table resulting in item requirements being multiplied" />
					</ul>
					<h3>{`${t.t("Version")} 2.0.0`}</h3>
					<ul>
						<ChangeEntry type={ChangeType.UPDATED}
							entry="Inventory! - Number boxes now represent your characters inventory, and tables have been updated to show relevant details - No more math! (or maybe just less)"
						/>
						<ChangeEntry type={ChangeType.NEW} entry="Alts - You can now select a character via the options menu and your progress will be associated with that character" />
						<ChangeEntry type={ChangeType.NEW} entry="Easily re-check achievements from the options menu without having to find your character again" />
						<ChangeEntry type={ChangeType.NEW} entry="Check all buttons - Check off all jobs for a step in a single click!" />
						<ChangeEntry type={ChangeType.NEW} entry="Reset Character progress - Able to reset just a single character" />
						<ChangeEntry type={ChangeType.NEW} entry="Added Relic images to the name tooltip - Note: Most images are missing at the moment - Need to gather these up" />
						<ChangeEntry type={ChangeType.FIX} entry="Missing Skysteel Tool iLvls from part names" />
						<ChangeEntry type={ChangeType.FIX} entry="Various auto-checking issues" />
					</ul>
					<h3>{`${t.t("Version")} 1.3.1`}</h3>
					<ul>
						<ChangeEntry type={ChangeType.FIX} entry="Issue where some info boxes would not open" />
						<ChangeEntry type={ChangeType.NEW} entry="Note on Zodiac Braves that Relic weapon is only required when leaving the dungeon" />
						<ChangeEntry type={ChangeType.FIX} entry="Requirements for 'Oddly Delicate Birch Log' & 'Oddly Delicate Raw Jade' was incorrectly set" />
					</ul>
					<h3>{`${t.t("Version")} 1.3.0`}</h3>
					<ul>
						<ChangeEntry type={ChangeType.NEW} entry="All Items Tab - with filters - Note: This is still a work in progress" />
						<ChangeEntry type={ChangeType.NEW} entry="Hovering on job icons now displays the relic name (Only applies to relevant tables)" />
						<ChangeEntry type={ChangeType.UPDATED} entry="Split out the individual upgrades for Eureka relics into their own steps - Due to this change, some save data may be effected" />
						<ChangeEntry type={ChangeType.FIX} entry="Steps should now get checked off when ticking a Part" />
						<ChangeEntry type={ChangeType.NEW} entry="Note on the Resistance Blade upgrade that 'Spare Parts', 'Tell Me a Story', and 'A Fond Memory' can all be started and progressed together" />
						<ChangeEntry type={ChangeType.FIX} entry="Issue where table sort icons would get duplicated" />
						<ChangeEntry type={ChangeType.FIX} entry="Issue where if you sorted a table you would be unable to click the icons or tick things" />
					</ul>
					<h3>{`${t.t("Version")} 1.2.0`}</h3>
					<ul>
						<ChangeEntry type={ChangeType.NEW} entry="Table Sorting! - Known Issue: Zodiac Animus table does not sort correctly due to colspan" />
						<ChangeEntry type={ChangeType.NEW} entry="Note on Zodiac parts as to which menu option from Jalzhan is needed to finish the upgrade" />
						<ChangeEntry type={ChangeType.FIX} entry="Skysung & Skybuilders stating the wrong quests" />
						<ChangeEntry type={ChangeType.FIX} entry="Horizontal tabs not begin very horizontal" />
						<ChangeEntry type={ChangeType.FIX} entry="Dungeon information popup not displaying" />
					</ul>
					<h3>{`${t.t("Version")} 1.1.0`}</h3>
					<ul>
						<ChangeEntry type={ChangeType.UPDATED} entry="Removed Orbitron font from everywhere except the Main Site header" />
						<ChangeEntry type={ChangeType.UPDATED} entry="Renamed 'Eurekan' to 'Eureka'" />
						<ChangeEntry type={ChangeType.FIX} entry="Menu on Mobile should now work properly" />
						<ChangeEntry type={ChangeType.UPDATED} entry="Split up the Overall progress table into DoW/DoM & DoH/DoL (Also rotated back the same way as every other table)" />
						<ChangeEntry type={ChangeType.NEW} entry="Which company a Levequest belongs to" />
						<ChangeEntry type={ChangeType.NEW} entry="Automatic checking off previous parts" />
						<ChangeEntry type={ChangeType.NEW} entry="Options Menu" />
						<ChangeEntry type={ChangeType.NEW} entry="Import character achievements to automatically check already completed relics" />
						<ChangeEntry type={ChangeType.NEW} entry="Teamcraft links" />
						<ChangeEntry type={ChangeType.FIX} entry="Lurid Memory of the Dying obtained description incorrectly stating 'Omega' instead of 'Eden'" />
						<ChangeEntry type={ChangeType.NEW} entry="Note regarding Resistance quests & Memories" />
						<ChangeEntry type={ChangeType.NEW} entry="Weapon ilvl to the part names" />
						<ChangeEntry type={ChangeType.FIX} entry="Some layout issues, should make it a little easier on mobile as well" />
						<ChangeEntry type={ChangeType.UPDATED} entry="Task list tabs layout adjusted - Tabs are now horizontal" />
						<ChangeEntry type={ChangeType.UPDATED} entry="Removed Data Correction form - More confusing that its worth - Better off with the normal feedback form" />
					</ul>
					<h3>{`${t.t("Version")} 1.0.0`}</h3>
					<ul>
						<ChangeEntry type={ChangeType.FIX} entry="Fixed the quantity of Timworn Artifacts required per job (From 18 to the correct value of 15)" />
						<ChangeEntry type={ChangeType.NEW} entry="Initial Live Version! This is where our story begins" />
					</ul>
				</div>
			</div>
		);
	}

	componentDidMount = () => {
		manager.view.changeComplete();
	}
}
export default connect(mapStateToProps)(ChangeLog);
