//#region Imports
import BasicIcon from "components/basic-icon/BasicIcon";
import { locations } from "data-files/locations";
import { npcs } from "data-files/npc";
import { Icon } from "enums";
import { Component } from "react";
import { connect } from "react-redux";
import t from "translations/translator";
import { AppState, ImportantNPCsProps } from "types";
import "./ImportantNPCs.css";
//#endregion Imports

const mapStateToProps = (state:AppState) => {
	return {
		lang: state.userInfo.options.lang,
		gameLang: state.userInfo.options.gameLang,
	};
};

class ImportantNPCs extends Component<ImportantNPCsProps> {
	render(){
		return (
			<div id="important-npcs">
				<h2>{t.t("Important NPCs")}</h2>
				<div className="npc-wrapper">
					{this.props.importantNPCs.length === 0 ? <p className="no-npcs">{t.t("No NPCs of note")}</p> : null}
					{this.props.importantNPCs.map((npc, index) => {
						const info = npcs[npc];
						const npcLocation = locations[info.found.location];

						return <div key={`importantnpc-${index}`} className="npc">
							<p><BasicIcon icon={Icon.NPC} class={["sm"]} />{t.t(info.name)}</p>
							{npcLocation.region ? <p><BasicIcon icon={Icon.REGION} class={["sm"]} />{t.t(npcLocation.region)}</p> : null}
							<p><BasicIcon icon={Icon.MAP} class={["sm"]} />{t.t(npcLocation.map)}</p>
							<p><BasicIcon icon={Icon.LOCATION} class={["sm"]} />X: {info.found.x}, Y: {info.found.y}</p>
						</div>;
					})}
				</div>
			</div>
		);
	}
}
export default connect(mapStateToProps)(ImportantNPCs);
