//#region Imports
import FormComponents from "components/forms/FormComponents";
import Link from "components/link/Link";
import { Icon, LinkType, Market, Modal } from "enums";
import manager from "managers/app";
import ModalContent from "modals/content/ModalContent";
import ModalFooter from "modals/footer/ModalFooter";
import ModalHeader from "modals/header/ModalHeader";
import { Component } from "react";
import { connect } from "react-redux";
import { AppState, MarketsModalProps } from "types";
import "./MarketsModal.css";
//#endregion Imports

const mapStateToProps = (state:AppState) => {
	return {
		lang: state.userInfo.options.lang,
		gameLang: state.userInfo.options.gameLang,
		markets: state.modal[Modal.MARKETS],
	};
};

class MarketsModal extends Component<MarketsModalProps> {
	render(){
		return (
			<div id={Modal.MARKETS} className="modal-wrapper">
				<ModalHeader text="Markets" icon={Icon.MARKET} />
				<ModalContent>
					<div className="market-wrapper">
						{Object.keys(this.props.markets).map((marketKey) => {
							const market = marketKey as Market;
							const thisMarket = this.props.markets[market];
							if(!thisMarket){ return null; }
							return <p key={market}><Link type={LinkType.MARKET} market={market} params={thisMarket} /></p>;
						})}
					</div>
				</ModalContent>
				<ModalFooter>
					<FormComponents.Button text="Close" onClick={manager.modal.close} translate={true} />
				</ModalFooter>
			</div>
		);
	}
}
export default connect(mapStateToProps)(MarketsModal);
