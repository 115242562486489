/* eslint-disable max-len */
//#region Imports
import { jobLists } from "character";
import FormComponents from "components/forms/FormComponents";
import Link from "components/link/Link";
import NoCharacter from "components/no-character/NoCharacter";
import { items } from "data-files/items";
import { quests } from "data-files/quests";
import { EorzeaDataType, InputType, Item, Job, LinkType, NPC, Quest, RelicGroup, RelicPart, RelicStep, RelicTask, RelicType, View } from "enums";
import manager from "managers/app";
import { Component } from "react";
import { connect } from "react-redux";
import t from "translations/translator";
import { AppState, Requirement, TaskInfo, ViewRelicInfo, ViewWeaponAnimaAnimatedProps } from "types";
import utils from "utils";
import ContentHeader from "views/page-parts/content/content-header/ContentHeader";
import DetailsSection from "views/page-parts/content/details-section/DetailsSection";
import Obtained from "views/page-parts/content/obtained/Obtained";
import OverviewTable from "views/page-parts/content/overview-table/OverviewTable";
import "./WeaponAnimaAnimated.css";
//#endregion Imports

const mapStateToProps = (state:AppState) => {
	return {
		lang: state.userInfo.options.lang,
		gameLang: state.userInfo.options.gameLang,
		userInfo: state.userInfo,
		hideCompletedSteps: state.userInfo.options.hideCompletedSteps,
		hideCompletedTasks: state.userInfo.options.hideCompletedTasks,
		showJob: state.userInfo.options.job,
	};
};

class WeaponAnimaAnimated extends Component<ViewWeaponAnimaAnimatedProps> {
	private requirements:Requirement[] = [];
	private importantNPCs:NPC[] = [];
	private relicInfo:ViewRelicInfo = {
		jobs: jobLists.relic[RelicGroup.WEAPON_ANIMA],
		type: RelicType.WEAPON,
		relic: RelicGroup.WEAPON_ANIMA,
		part: RelicPart.WEAPON_ANIMATED,
		steps: [RelicStep.CRYSTALS],
		stepPlaceholders: {},
		next: {
			part: RelicPart.WEAPON_AWOKEN,
			view: View.RELIC_WEAPONS_ANIMA_AWOKEN,
		},
		previous: null,
	};

	constructor(props:ViewWeaponAnimaAnimatedProps){
		super(props);
		this.requirements = this.getRequirements();
		this.importantNPCs = this.getImportantNPCs();
	}

	render(){
		const character = manager.data.getActiveCharacter();
		if(character === null){ return <NoCharacter />; }

		let row = "odd-row";

		const completeSteps = manager.content.getCompleteSteps(character.progress, this.relicInfo);
		const taskInfo:TaskInfo[] = [
			{ task: RelicTask.TASK_1, target: manager.relics.getTaskItem(RelicStep.CRYSTALS, RelicTask.TASK_1, Job.PLD) },
			{ task: RelicTask.TASK_2, target: manager.relics.getTaskItem(RelicStep.CRYSTALS, RelicTask.TASK_2, Job.PLD) },
			{ task: RelicTask.TASK_3, target: manager.relics.getTaskItem(RelicStep.CRYSTALS, RelicTask.TASK_3, Job.PLD) },
			{ task: RelicTask.TASK_4, target: manager.relics.getTaskItem(RelicStep.CRYSTALS, RelicTask.TASK_4, Job.PLD) },
			{ task: RelicTask.TASK_5, target: manager.relics.getTaskItem(RelicStep.CRYSTALS, RelicTask.TASK_5, Job.PLD) },
			{ task: RelicTask.TASK_6, target: manager.relics.getTaskItem(RelicStep.CRYSTALS, RelicTask.TASK_6, Job.PLD) },
		];

		return (
			<div id="weapon-anima-animated" className="relic">
				<ContentHeader requirements={this.requirements} importantNPCs={this.importantNPCs} relicInfo={this.relicInfo}>
					<p>
						{t.t("In order to obtain the %PART% you must complete the quest", { PART: RelicPart.WEAPON_ANIMATED })}
						<Link type={LinkType.LODESTONE} lodestoneType={EorzeaDataType.BLUEQUEST} text={Quest.SOUL_WITHOUT_LIFE} lodestoneID={quests[Quest.SOUL_WITHOUT_LIFE].lodestoneID} />
					</p>
				</ContentHeader>
				<div id="relic-checklist">
					<OverviewTable character={character} completeSteps={completeSteps} relicInfo={this.relicInfo} />
				</div>
				<div id="relic-details">
					<h1>{t.t("Details")}</h1>
					{this.props.hideCompletedSteps && completeSteps.allComplete ? t.t("All Steps Complete") : null }
					<DetailsSection title={RelicStep.CRYSTALS} id={RelicStep.CRYSTALS} stepComplete={completeSteps[RelicStep.CRYSTALS]}>
						<p>{t.t("Obtain 1 of each of the following crystals OR you can trade in your %ZODIACZETA% Weapon to complete the quest", { ZODIACZETA: RelicPart.WEAPON_ZETA })}</p>
						<p>{t.t("If you choose to trade in your weapon, beware it will be permanently lost! however you can obtain replicas from Drake if you have completed %VITALTITLE%", { VITALTITLE: Quest.VITAL_TITLE })}</p>
						<div className="table-wrapper">
							<table className="table">
								<thead><tr><th>{t.t("Obtained")}</th><th>{t.t("Item")}</th><th>{t.t("Quantity / Job")}</th><th>{t.t("Total")}</th><th>{t.t("Used")}</th><th>{t.t("Remaining")}</th><th>{t.t("Inventory")}</th><th>{t.t("To Obtain")}</th></tr></thead>
								<tbody>
									{taskInfo.map((thisTask) => {
										let thisTarget = Item.UNKNOWN;
										if(utils.guards.isEnumValue(Item, thisTask.target)){ thisTarget = thisTask.target; }

										const inventory = manager.data.getInventoryStatus({
											item: thisTarget,
											relic: [this.relicInfo.type, this.relicInfo.relic, this.relicInfo.part, RelicStep.CRYSTALS, thisTask.task],
										});

										if(this.props.hideCompletedTasks && inventory.toObtain === 0){ return null; }

										const tableRow = <tr key={`${RelicStep.CRYSTALS}-${thisTask.task}`} className={row}>
											<td><Obtained obtained={items[thisTarget].obtained} qtyNeeded={inventory.total} /></td>
											<td>{t.t(thisTarget)}</td>
											<td>{inventory.qtyPerJob.toLocaleString()}</td>
											<td>{inventory.total.toLocaleString()}</td>
											<td>{inventory.used.toLocaleString()}</td>
											<td>{inventory.remaining.toLocaleString()}</td>
											<td><FormComponents.TextInput label={{ hide: true }} input={{ type: InputType.NUMBER, value: inventory.inventory, dataAttributes: { item: thisTarget }, events: { onChange: manager.content.inventoryUpdated }}} /></td>
											<td>{inventory.toObtain.toLocaleString()}</td>
										</tr>;
										row = row === "odd-row" ? "even-row" : "odd-row";
										return tableRow;
									})}
									{
										character.progress.isComplete(this.relicInfo.type, this.relicInfo.relic, this.relicInfo.part, RelicStep.CRYSTALS)
											? <tr className="all-complete"><td colSpan={8}><p>{t.t("All Complete")}</p></td></tr>
											: null
									}
								</tbody>
							</table>
						</div>
					</DetailsSection>
				</div>
			</div>
		);
	}

	componentDidMount(){
		manager.view.changeComplete();
	}

	private getRequirements():Requirement[]{
		return [
			{ type: quests[Quest.HEAVENSWARD].type, text: Quest.HEAVENSWARD, lodestoneID: quests[Quest.HEAVENSWARD].lodestoneID },
			{ type: quests[Quest.UNEXPECTED_PROPOSAL].type, text: Quest.UNEXPECTED_PROPOSAL, lodestoneID: quests[Quest.UNEXPECTED_PROPOSAL].lodestoneID },
		];
	}

	private getImportantNPCs():NPC[]{
		return [NPC.ROWENA_IDYLLSHIRE, NPC.ARDASHIR];
	}
}
export default connect(mapStateToProps)(WeaponAnimaAnimated);
