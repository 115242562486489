/* eslint-disable max-len */
//#region Imports
import { jobLists } from "character";
import FormComponents from "components/forms/FormComponents";
import NoCharacter from "components/no-character/NoCharacter";
import { items } from "data-files/items";
import { InputType, Item, NPC, RelicGroup, RelicPart, RelicStep, RelicTask, RelicType, View } from "enums";
import manager from "managers/app";
import { Component } from "react";
import { connect } from "react-redux";
import t from "translations/translator";
import { AppState, Requirement, TaskInfo, ViewRelicInfo, ViewWeaponEurekaPhyseosProps } from "types";
import ContentHeader from "views/page-parts/content/content-header/ContentHeader";
import DetailsSection from "views/page-parts/content/details-section/DetailsSection";
import Obtained from "views/page-parts/content/obtained/Obtained";
import OverviewTable from "views/page-parts/content/overview-table/OverviewTable";
import "./WeaponEurekaPhyseos.css";
//#endregion Imports

const mapStateToProps = (state:AppState) => {
	return {
		lang: state.userInfo.options.lang,
		gameLang: state.userInfo.options.gameLang,
		userInfo: state.userInfo,
		hideCompletedSteps: state.userInfo.options.hideCompletedSteps,
		hideCompletedTasks: state.userInfo.options.hideCompletedTasks,
		showJob: state.userInfo.options.job,
		ignorePhyseosPart: state.userInfo.options.progress.ignorePhyseosPart,
		options: state.userInfo.options,
	};
};

class WeaponEurekaPhyseos extends Component<ViewWeaponEurekaPhyseosProps> {
	private requirements:Requirement[] = [];
	private importantNPCs:NPC[] = [];
	private relicInfo:ViewRelicInfo = {
		jobs: jobLists.relic[RelicGroup.WEAPON_EUREKA],
		type: RelicType.WEAPON,
		relic: RelicGroup.WEAPON_EUREKA,
		part: RelicPart.WEAPON_PHYSEOS,
		steps: [RelicStep.FRAGMENTS],
		stepPlaceholders: { [RelicStep.FRAGMENTS]: { ITEM: "Eureka Fragment" } },
		next: null,
		previous: {
			part: RelicPart.WEAPON_EUREKA,
			view: View.RELIC_WEAPONS_EUREKA_EUREKA,
		},
	};

	constructor(props:ViewWeaponEurekaPhyseosProps){
		super(props);
		this.requirements = this.getRequirements();
		this.importantNPCs = this.getImportantNPCs();
	}

	render(){
		const character = manager.data.getActiveCharacter();
		if(character === null){ return <NoCharacter />; }

		const completeSteps = manager.content.getCompleteSteps(character.progress, this.relicInfo);

		if(this.props.ignorePhyseosPart){
			return <div id="weapon-eureka-physeos" className="relic ignored">
				<h2>{t.t("Ignoring %PART% Step", { PART: RelicPart.WEAPON_PHYSEOS})}</h2>
				<p>{t.t("You have selected to ignore this step")}</p>
				<FormComponents.Button text={"Show %PART% steps"} placeholders={{ PART: RelicPart.WEAPON_PHYSEOS}} onClick={this.showPhyseosPart} translate={true} />
			</div>;
		}

		return (
			<div id="weapon-eureka-physeos" className="relic">
				<ContentHeader requirements={this.requirements} importantNPCs={this.importantNPCs} relicInfo={this.relicInfo}>
					<p>{t.t("The %PART% upgrade follows on from %PREVIOUSPART%", { PART: this.relicInfo.part, PREVIOUSPART: this.relicInfo.previous ? this.relicInfo.previous.part : "Unknown"})}</p>
					<p>{t.t("You will need to follow the storyline for Eureka & Reach %LEVEL% elemental level in order to unlock and access %AREA%", { LEVEL: "60", AREA: "The Baldesion Arsenal" })}</p>
					<p>{t.t("Talk to Gerolt once you have obtained the relevant items to upgrade your weapon")}</p>
					<p>{t.t("This upgrade does not change the appearance of the weapon, but it adds elemental stats for use in Eureka - So may be ignored if only getting the weapon for glamour")}</p>
				</ContentHeader>
				<div id="relic-checklist">
					<OverviewTable character={character} completeSteps={completeSteps} relicInfo={this.relicInfo} />
				</div>
				<div id="relic-details">
					<h1>{t.t("Details")}</h1>
					{this.props.hideCompletedSteps && completeSteps.allComplete ? t.t("All Steps Complete") : null }
					{this.relicInfo.steps.map((step) => {
						let row = "odd-row";

						const taskInfo:TaskInfo[] = [{ task: RelicTask.TASK_1, target: manager.relics.getTaskItem(step, RelicTask.TASK_1) }];

						return <DetailsSection title={step} placeholders={this.relicInfo.stepPlaceholders[step]} id={step} stepComplete={completeSteps[step]} key={step}>
							<div className="table-wrapper">
								<table className="table">
									<thead><tr><th>{t.t("Obtained")}</th><th>{t.t("Item")}</th><th>{t.t("Quantity / Job")}</th><th>{t.t("Total")}</th><th>{t.t("Used")}</th><th>{t.t("Remaining")}</th><th>{t.t("Inventory")}</th><th>{t.t("To Obtain")}</th></tr></thead>
									<tbody>
										{taskInfo.map((thisTask) => {
											const thisTarget = thisTask.target as Item;
											const inventory = manager.data.getInventoryStatus({
												item: thisTarget,
												relic: [this.relicInfo.type, this.relicInfo.relic, this.relicInfo.part, step, thisTask.task],
											});

											if(this.props.hideCompletedTasks && inventory.toObtain === 0){ return null; }

											const tableRow = <tr key={`${step}-${thisTask.task}`} className={row}>
												<td><Obtained obtained={items[thisTarget].obtained} qtyNeeded={inventory.total} /></td>
												<td>{t.t(thisTarget)}</td>
												<td>{inventory.qtyPerJob.toLocaleString()}</td>
												<td>{inventory.total.toLocaleString()}</td>
												<td>{inventory.used.toLocaleString()}</td>
												<td>{inventory.remaining.toLocaleString()}</td>
												<td><FormComponents.TextInput label={{ hide: true }} input={{ type: InputType.NUMBER, value: inventory.inventory, dataAttributes: { item: thisTarget }, events: { onChange: manager.content.inventoryUpdated }}} /></td>
												<td>{inventory.toObtain.toLocaleString()}</td>
											</tr>;
											row = row === "odd-row" ? "even-row" : "odd-row";
											return tableRow;
										})}
										{
											character.progress.isComplete(this.relicInfo.type, this.relicInfo.relic, this.relicInfo.part, step)
												? <tr className="all-complete"><td colSpan={8}><p>{t.t("All Complete")}</p></td></tr>
												: null
										}
									</tbody>
								</table>
							</div>
						</DetailsSection>;
					})}
				</div>
			</div>
		);
	}

	componentDidMount(){
		manager.view.changeComplete();
	}

	private getRequirements():Requirement[]{
		if(!this.relicInfo || this.relicInfo.previous === null){ return []; }
		return [
			{ type: "previous part", part: this.relicInfo.previous.part, partView: this.relicInfo.previous.view },
		];
	}

	private getImportantNPCs():NPC[]{
		return [NPC.GEROLT_HYDATOS];
	}

	private showPhyseosPart = () => { // Event
		const options = this.props.options;
		options.progress.ignorePhyseosPart = false;
		manager.data.saveOptions(options, true);
	}
}
export default connect(mapStateToProps)(WeaponEurekaPhyseos);
