/* eslint-disable max-len */
//#region Imports
import FormComponents from "components/forms/FormComponents";
import JobIcon from "components/job-icon/JobIcon";
import Link from "components/link/Link";
import RelicCheckbox from "components/relic-checkbox/RelicCheckbox";
import { Job, LinkType, RelicStep } from "enums";
import manager from "managers/app";
import { Component, SyntheticEvent } from "react";
import { connect } from "react-redux";
import t from "translations/translator";
import { AppState, OverviewTableProps } from "types";
import utils from "utils";
import "./OverviewTable.css";
//#endregion Imports

const mapStateToProps = (state:AppState) => {
	return {
		lang: state.userInfo.options.lang,
		gameLang: state.userInfo.options.gameLang,
		hideCompletedSteps: state.userInfo.options.hideCompletedSteps,
		hideCompletedTasks: state.userInfo.options.hideCompletedTasks,
		showJob: state.userInfo.options.job,
	};
};

class OverviewTable extends Component<OverviewTableProps> {
	render(){
		const character = this.props.character;
		const completeSteps = this.props.completeSteps;
		const relicInfo = this.props.relicInfo;
		const hideCompletedSteps = this.props.hideCompletedSteps;
		const showJob = this.props.showJob;

		return (
			<div>
				<h1>{t.t("Overview")}</h1>
				<div id="overview-table" className="table-wrapper">
					<table className="table">
						<thead>
							<tr>
								<th>{t.t("Task")}</th>
								{relicInfo.jobs.map((job) => {
									if(showJob !== null && showJob !== job){ return null; }
									const weaponName = manager.relics.getRelicNameKey(job, relicInfo.part);
									return <th key={`${job}_1`} ><JobIcon job={job} tooltip={weaponName} /></th>;
								})}
								{showJob === null
									? <th></th>
									: null
								}
							</tr>
						</thead>
						<tbody>
							{relicInfo.steps.map((step) => {
								if(hideCompletedSteps && completeSteps[step]){ return null; }

								const checkboxes:JSX.Element[] = [];

								if(manager.relics.isFirstTimeOnlyStep(step)){
									checkboxes.push(<td key={`${step}_all_checkbox`} colSpan={relicInfo.jobs.length}><RelicCheckbox character={character} job={Job.PLD} relicInfo={relicInfo} step={step} /></td>);
								}else{
									relicInfo.jobs.forEach((job) => {
										if(showJob !== null && showJob !== job){ return null; }
										checkboxes.push(
											<td key={`${step}_${job}_checkbox`}>
												<RelicCheckbox character={character} job={job} relicInfo={relicInfo} step={step} />
											</td>,
										);
									});
								}

								return (
									<tr key={step} data-step={step} className={`${completeSteps[step] ? "complete" : null}`}>
										<td><Link type={LinkType.ANCHOR} link={`#${step}`} text={step} placeholders={relicInfo.stepPlaceholders[step]} tooltip="Jump to details" triggerClickOnTarget={true} /></td>
										{checkboxes}
										{showJob === null
											? <td><FormComponents.Button class={["no-margin"]} text={completeSteps[step] ? "Uncheck All" : "Check All" } onClick={this.toggleRowCheckboxes} translate={true} /></td>
											: null
										}
									</tr>
								);
							})}
							{this.getAllCompleteRow()}
						</tbody>
					</table>
				</div>
			</div>
		);
	}

	getAllCompleteRow(){
		if(!this.props.completeSteps.allComplete){ return null; }
		return <tr className="all-complete"><td colSpan={this.props.relicInfo.jobs.length + 2}>
			<p>{t.t("All Complete")}</p>
			{this.props.relicInfo.next === null
				? null
				: <FormComponents.Button text="Go to next part" translate={true} onClick={() => {
					if(this.props.relicInfo.next === null){ return; }
					manager.view.change(this.props.relicInfo.next.view);
				}}/>
			}
		</td></tr>;
	}

	toggleRowCheckboxes = (evt:SyntheticEvent) => { // Event
		const button = evt.currentTarget as HTMLButtonElement;
		const row = button.closest("tr");
		const step = row?.dataset.step as RelicStep;
		const character = manager.data.getActiveCharacter();

		if(!character || !step){ return; }

		const isComplete = character.progress.isComplete(this.props.relicInfo.type, this.props.relicInfo.relic, this.props.relicInfo.part, step);
		const relicId = `${this.props.relicInfo.type}-${this.props.relicInfo.relic}-${this.props.relicInfo.part}-${step}`;

		if(utils.guards.isRelicId(relicId)){
			if(manager.relics.isFirstTimeOnlyStep(step)){
				character.progress.setComplete(relicId, this.props.relicInfo.jobs, !isComplete);
			}else{
				this.props.relicInfo.jobs.forEach((job) => {
					character.progress.setComplete(relicId, job, !isComplete);
				});
			}

			manager.data.saveCharacters([character], true);
		}
	}
}
export default connect(mapStateToProps)(OverviewTable);
