//#region Imports
import BasicIcon from "components/basic-icon/BasicIcon";
import FormComponents from "components/forms/FormComponents";
import { Icon, Modal } from "enums";
import manager from "managers/app";
import { Component } from "react";
import { connect } from "react-redux";
import { AppState, ObtainedDesynthesis, ObtainedDuty, ObtainedEnemy, ObtainedFishing, ObtainedGathering, ObtainedLocation, ObtainedMarket, ObtainedMethod, ObtainedOther, ObtainedProps, ObtainedQuest, ObtainedShop, RecipeModalData } from "types"; // eslint-disable-line max-len
import "./Obtained.css";
//#endregion Imports

const mapStateToProps = (state:AppState) => {
	return {
		lang: state.userInfo.options.lang,
		gameLang: state.userInfo.options.gameLang,
	};
};

class Obtained extends Component<ObtainedProps> {
	render(){ return (<div className="obtained">{this.getObtained(this.props.obtained, this.props.qtyNeeded, this.props.ignoreRecipe)}</div>); }

	getObtained(obtained:ObtainedMethod, qtyNeeded:number, ignoreRecipe?:boolean):JSX.Element[]{
		const result:JSX.Element[] = [];
		if(obtained.desynthesis){ result.push(this.getDesynthObtained(obtained.desynthesis)); }
		if(obtained.enemies){ result.push(this.getEnemyObtained(obtained.enemies)); }
		if(!ignoreRecipe && obtained.recipes){ result.push(this.getReceipeObtained({ recipes: obtained.recipes, qtyNeeded: qtyNeeded })); }
		if(obtained.markets){ result.push(this.getMarketObtained(obtained.markets)); }
		if(obtained.extraction){ result.push(this.getExtractionObtained()); }
		if(obtained.gathering){ result.push(this.getGatheringObtained(obtained.gathering)); }
		if(obtained.fishingHoles){ result.push(this.getFishingHolesObtained(obtained.fishingHoles)); }
		if(obtained.shops){ result.push(this.getShopsObtained(obtained.shops)); }
		if(obtained.locations){ result.push(this.getLocationsObtained(obtained.locations)); }
		if(obtained.duties){ result.push(...this.getDutyObtained(obtained.duties)); }
		if(obtained.quests){ result.push(this.getquestsObtained(obtained.quests)); }
		if(obtained.other){ result.push(this.getOtherObtained(obtained.other)); }
		return result;
	}

	getDesynthObtained(desynthesis:ObtainedDesynthesis[]):JSX.Element{ return <div key="desynthesis"><FormComponents.Button tooltip="Desynthesis" icon={Icon.DESYNTHESIS} onClick={() => { manager.content.openModal(Modal.DESYNTHESIS, desynthesis); }} /></div>; } // eslint-disable-line max-len
	getEnemyObtained(enemies:ObtainedEnemy[]):JSX.Element{ return <div key="enemies"><FormComponents.Button tooltip="Location" icon={Icon.ENEMY} onClick={() => { manager.content.openModal(Modal.ENEMIES, enemies); }} /></div>; } // eslint-disable-line max-len
	getReceipeObtained(recipes:RecipeModalData):JSX.Element{ return <div key="recipe"><FormComponents.Button tooltip="Recipe" icon={Icon.RECIPE} onClick={() => { manager.content.openModal(Modal.RECIPES, recipes); }} /></div>; } // eslint-disable-line max-len
	getMarketObtained(markets:ObtainedMarket):JSX.Element{ return <div key="market"><FormComponents.Button tooltip="Market" icon={Icon.MARKET} onClick={() => { manager.content.openModal(Modal.MARKETS, markets); }} /></div>; } // eslint-disable-line max-len
	getExtractionObtained():JSX.Element{ return <div key="extraction"><BasicIcon icon={Icon.EXTRACTION} tooltip="Materia Extraction" /></div>; }
	getGatheringObtained(gathered:ObtainedGathering[]):JSX.Element{ return <div key="gather"><FormComponents.Button tooltip="Gather" icon={Icon.GATHERING} onClick={() => { manager.content.openModal(Modal.GATHERING, gathered); }} /></div>; } // eslint-disable-line max-len
	getFishingHolesObtained(fishing:ObtainedFishing[]):JSX.Element{ return <div key="fishing"><FormComponents.Button tooltip="Fishing" icon={Icon.FISHING} onClick={() => { manager.content.openModal(Modal.FISHING, fishing); }} /></div>; } // eslint-disable-line max-len
	getShopsObtained(shops:ObtainedShop[]):JSX.Element{ return <div key="shops"><FormComponents.Button tooltip="Shops" icon={Icon.SHOP} onClick={() => { manager.content.openModal(Modal.SHOPS, shops); }} /></div>; }
	getLocationsObtained(locations:ObtainedLocation[]):JSX.Element{ return <div key="location"><FormComponents.Button tooltip="Locations" icon={Icon.MAP} onClick={() => { manager.content.openModal(Modal.LOCATIONS, locations); }} /></div>; } // eslint-disable-line max-len
	getDutyObtained(duties:ObtainedDuty):JSX.Element[]{
		const result:JSX.Element[] = [];
		if(duties.dungeons){
			const dungeons = duties.dungeons; // To ensure typescript pulls correct narrowing
			result.push(<div key="dungeons"><FormComponents.Button tooltip="Dungeons" icon={Icon.DUNGEON} onClick={() => { manager.content.openModal(Modal.DUNGEONS, dungeons); }} /></div>);
		}
		if(duties.fates){
			const fates = duties.fates; // To ensure typescript pulls correct narrowing
			result.push(<div key="fates"><FormComponents.Button tooltip="FATEs" icon={Icon.FATE} onClick={() => { manager.content.openModal(Modal.FATES, fates); }} /></div>);
		}
		if(duties.leves){
			const leves = duties.leves; // To ensure typescript pulls correct narrowing
			result.push(<div key="leves"><FormComponents.Button tooltip="Leves" icon={Icon.LEVE} onClick={() => { manager.content.openModal(Modal.LEVES, leves); }} /></div>);
		}
		if(duties.raids){
			const raids = duties.raids; // To ensure typescript pulls correct narrowing
			result.push(<div key="raids"><FormComponents.Button tooltip="Raids" icon={Icon.RAID} onClick={() => { manager.content.openModal(Modal.RAIDS, raids); }} /></div>);
		}
		if(duties.trials){
			const trials = duties.trials; // To ensure typescript pulls correct narrowing
			result.push(<div key="trials"><FormComponents.Button tooltip="Trials" icon={Icon.TRIAL} onClick={() => { manager.content.openModal(Modal.TRIALS, trials); }} /></div>);
		}
		return result;
	}

	getquestsObtained(quests:ObtainedQuest[]):JSX.Element{ return <div key="quests"><FormComponents.Button tooltip="Quests" icon={Icon.QUEST} onClick={() => { manager.content.openModal(Modal.QUESTS, quests); }} /></div>; } // eslint-disable-line max-len
	getOtherObtained(others:ObtainedOther[]):JSX.Element{ return <div key="other"><FormComponents.Button tooltip="Other" icon={Icon.OTHER} onClick={() => { manager.content.openModal(Modal.OTHER, others); }} /></div>; } // eslint-disable-line max-len
}
export default connect(mapStateToProps)(Obtained);
