import { CharacterProgress, jobLists } from "character";
import { Item, Job, RelicGroup, RelicPart, RelicStep, RelicTask } from "enums";
import log from "logger";
import manager from "managers/app";
import { InventoryV2, InventoryV3, ProgressV2, RelicID } from "types";

interface OldItem { id: number; item: Item; }
interface OldRelic {
	id: number; relic: RelicGroup; parts: {
		id: number; part: RelicPart; steps: {
			id: number; step: RelicStep; tasks: {
				id: number;
				task: RelicTask;
				jobs: Job[];
			}[]
		}[]
	}[]
}

const oldItems:OldItem[] = getOldItems();
const oldRelics:OldRelic[] = getOldRelics();

function findItemById(id:number):Item|null{
	const thisItem = oldItems.find((z) => { return z.id === id; });
	if(thisItem){ return thisItem.item; }
	return null;
}

export function mapV2InventoryToV3(v2Inventory:InventoryV2):InventoryV3{
	const inventory:InventoryV3 = {};
	v2Inventory.forEach((entry) => {
		if(Number(entry.value) > 0){
			const thisItem = findItemById(entry.id);
			if(thisItem){
				if(!inventory[thisItem]){
					inventory[thisItem] = Number(entry.value);
				}
			}
		}
	});

	return inventory;
}

export function mapV2CompletedToV3(v2Completed:ProgressV2):CharacterProgress{
	const progress = new CharacterProgress();
	const jobs = Object.keys(v2Completed) as Job[];

	oldRelics.forEach((oldRelic) => {
		const relicType = manager.relics.getRelicType(oldRelic.relic);

		jobs.forEach((job) => {
			const completeList = v2Completed[job];

			if(
				jobLists.relic[oldRelic.relic].includes(job)
				&& completeList.includes(oldRelic.id)
				&& !progress.isComplete(relicType, oldRelic.relic, job)
			){
				progress.setComplete(`${relicType}-${oldRelic.relic}` as RelicID, job, true);
			}
		});

		oldRelic.parts.forEach((oldPart) => {
			jobs.forEach((job) => {
				const completeList = v2Completed[job];

				if(
					jobLists.relic[oldRelic.relic].includes(job)
					&& completeList.includes(oldPart.id)
					&& !progress.isComplete(relicType, oldRelic.relic, oldPart.part, job)
				){
					progress.setComplete(`${relicType}-${oldRelic.relic}-${oldPart.part}` as RelicID, job, true);
				}
			});

			oldPart.steps.forEach((oldStep) => {
				jobs.forEach((job) => {
					const completeList = v2Completed[job];

					if(
						jobLists.relic[oldRelic.relic].includes(job)
						&& completeList.includes(oldStep.id)
						&& !progress.isComplete(relicType, oldRelic.relic, oldPart.part, oldStep.step, job)
					){
						progress.setComplete(`${relicType}-${oldRelic.relic}-${oldPart.part}-${oldStep.step}` as any, job, true); // Using any due to Typscript Limitation
					}
				});

				oldStep.tasks.forEach((oldTask) => {
					jobs.forEach((job) => {
						const completeList = v2Completed[job];

						if(
							oldTask.jobs.includes(job)
							&& completeList.includes(oldTask.id)
							&& !progress.isComplete(relicType, oldRelic.relic, oldPart.part, oldStep.step, oldTask.task, job)
						){
							progress.setComplete(`${relicType}-${oldRelic.relic}-${oldPart.part}-${oldStep.step}-${oldTask.task}` as any, job, true); // Using any due to Typscript Limitation
						}
					});
				});
			});
		});
	});

	log.debug("V2 Completed: ", v2Completed);
	return progress;
}

function getOldItems():OldItem[]{
	/* eslint-disable array-element-newline */
	return [
		{ id: 144, item: Item.LONGARMS_COMPOSITE_BOW }, { id: 145, item: Item.VAMPERS_KNIVES }, { id: 146, item: Item.SANGUINE_SCEPTER }, { id: 147, item: Item.ERUDITES_PICATRIX_OF_CASTING },
		{ id: 148, item: Item.CHAMPIONS_LANCE }, { id: 149, item: Item.WILDLINGS_CESTI }, { id: 150, item: Item.AEOLIAN_SCIMITAR }, { id: 151, item: Item.BARBARIANS_BARDICHE },
		{ id: 152, item: Item.ERUDITES_PICATRIX_OF_HEALING }, { id: 153, item: Item.MADMANS_WHISPERING_ROD }, { id: 154, item: Item.HEAVENS_EYE_MATERIA_III }, { id: 155, item: Item.SAVAGE_MIGHT_MATERIA_III },
		{ id: 156, item: Item.SAVAGE_AIM_MATERIA_III }, { id: 157, item: Item.BATTLEDANCE_MATERIA_III }, { id: 158, item: Item.QUICKTONGUE_MATERIA_III }, { id: 159, item: Item.THAVNAIRIAN_MIST },
		{ id: 160, item: Item.ATMA_OF_THE_LION }, { id: 161, item: Item.ATMA_OF_THE_WATERBEARER }, { id: 162, item: Item.ATMA_OF_THE_RAM }, { id: 163, item: Item.ATMA_OF_THE_CRAB },
		{ id: 164, item: Item.ATMA_OF_THE_FISH }, { id: 165, item: Item.ATMA_OF_THE_BULL }, { id: 166, item: Item.ATMA_OF_THE_SCALES }, { id: 167, item: Item.ATMA_OF_THE_TWINS },
		{ id: 168, item: Item.ATMA_OF_THE_SCORPION }, { id: 169, item: Item.ATMA_OF_THE_ARCHER }, { id: 170, item: Item.ATMA_OF_THE_GOAT }, { id: 171, item: Item.ATMA_OF_THE_MAIDEN },
		{ id: 172, item: Item.QUENCHING_OIL }, { id: 542, item: Item.LIGHT }, { id: 558, item: Item.BOMBARD_CORE }, { id: 559, item: Item.BRONZE_LAKE_CRYSTAL }, { id: 560, item: Item.SACRED_SPRING_WATER },
		{ id: 561, item: Item.HORN_OF_THE_BEAST }, { id: 562, item: Item.GOBMACHINE_BANGPLATE }, { id: 563, item: Item.NARASIMHA_HIDE }, { id: 564, item: Item.SICKLE_FANG }, { id: 565, item: Item.FURNACE_RING_HQ },
		{ id: 566, item: Item.PERFECT_FIREWOOD_HQ }, { id: 576, item: Item.ALLAGAN_RESIN }, { id: 577, item: Item.VALE_BUBO }, { id: 578, item: Item.VOIDWEAVE }, { id: 579, item: Item.AMDAPOR_VELLUM },
		{ id: 580, item: Item.INDIGO_PEARL }, { id: 581, item: Item.PERFECT_MORTAR_HQ }, { id: 582, item: Item.PERFECT_PESTLE_HQ }, { id: 589, item: Item.TAILOR_MADE_EEL_PIE_HQ },
		{ id: 590, item: Item.PERFECT_CLOTH_HQ }, { id: 591, item: Item.FURITE_SAND }, { id: 592, item: Item.BRASS_KETTLE }, { id: 593, item: Item.PERFECT_VELLUM_HQ }, { id: 594, item: Item.PERFECT_POUNCE_HQ },
		{ id: 595, item: Item.TONBERRY_KING_BLOOD }, { id: 596, item: Item.ROYAL_GIGANT_BLOOD }, { id: 597, item: Item.KRAKEN_BLOOD }, { id: 598, item: Item.VICEGERENT_BLOOD },
		{ id: 599, item: Item.LOST_TREASURE_OF_AMDAPOR }, { id: 600, item: Item.LOST_TREASURE_OF_PHAROS_SIRIUS }, { id: 601, item: Item.LOST_TREASURE_OF_TAM_TARA }, { id: 602, item: Item.LOST_TREASURE_OF_STONE_VIGIL },
		{ id: 634, item: Item.MAHATMA_OF_THE_RAM }, { id: 635, item: Item.MAHATMA_OF_THE_BULL }, { id: 636, item: Item.MAHATMA_OF_THE_TWINS }, { id: 637, item: Item.MAHATMA_OF_THE_CRAB },
		{ id: 638, item: Item.MAHATMA_OF_THE_LION }, { id: 639, item: Item.MAHATMA_OF_THE_MAIDEN }, { id: 640, item: Item.MAHATMA_OF_THE_SCALES }, { id: 641, item: Item.MAHATMA_OF_THE_SCORPION },
		{ id: 642, item: Item.MAHATMA_OF_THE_ARCHER }, { id: 643, item: Item.MAHATMA_OF_THE_GOAT }, { id: 644, item: Item.MAHATMA_OF_THE_WATERBEARER }, { id: 645, item: Item.MAHATMA_OF_THE_FISH },
		{ id: 654, item: Item.LUMINOUS_WIND_CRYSTAL }, { id: 661, item: Item.LUMINOUS_FIRE_CRYSTAL }, { id: 662, item: Item.LUMINOUS_LIGHTNING_CRYSTAL }, { id: 663, item: Item.LUMINOUS_ICE_CRYSTAL },
		{ id: 664, item: Item.LUMINOUS_EARTH_CRYSTAL }, { id: 665, item: Item.LUMINOUS_WATER_CRYSTAL }, { id: 694, item: Item.UNIDENTIFIABLE_BONE }, { id: 695, item: Item.UNIDENTIFIABLE_SHELL },
		{ id: 696, item: Item.UNIDENTIFIABLE_ORE }, { id: 697, item: Item.UNIDENTIFIABLE_SEEDS }, { id: 716, item: Item.ADAMANTITE_FRANCESCA }, { id: 717, item: Item.TITANIUM_ALLOY_MIRROR },
		{ id: 718, item: Item.DISPELLING_ARROW }, { id: 719, item: Item.KINGCAKE }, { id: 720, item: Item.SUPERIOR_ENCHANTED_INK }, { id: 736, item: Item.ODDLY_SPECIFIC_PETRIFIED_ORB_HQ },
		{ id: 737, item: Item.ODDLY_SPECIFIC_RIVETS_HQ }, { id: 738, item: Item.ODDLY_SPECIFIC_WIRE_HQ }, { id: 739, item: Item.ODDLY_SPECIFIC_WHETSTONE_HQ }, { id: 740, item: Item.ODDLY_SPECIFIC_LEATHER_HQ },
		{ id: 741, item: Item.ODDLY_SPECIFIC_MOONBEAM_SILK_HQ }, { id: 742, item: Item.ODDLY_SPECIFIC_SYNTHETIC_RESIN_HQ }, { id: 743, item: Item.ODDLY_SPECIFIC_SEED_EXTRACT_HQ },
		{ id: 744, item: Item.ODDLY_SPECIFIC_OBSIDIAN }, { id: 745, item: Item.ODDLY_SPECIFIC_MINERAL_SAND }, { id: 746, item: Item.ODDLY_SPECIFIC_LATEX }, { id: 747, item: Item.ODDLY_SPECIFIC_FOSSIL_DUST },
		{ id: 748, item: Item.THINKERS_CORAL }, { id: 749, item: Item.ODDLY_SPECIFIC_SHAFT_HQ }, { id: 750, item: Item.ODDLY_SPECIFIC_FITTING_HQ }, { id: 751, item: Item.ODDLY_SPECIFIC_CHAINMAIL_SHEET_HQ },
		{ id: 752, item: Item.ODDLY_SPECIFIC_GEMSTONE_HQ }, { id: 753, item: Item.ODDLY_SPECIFIC_VELLUM_HQ }, { id: 754, item: Item.ODDLY_SPECIFIC_VELVET_HQ }, { id: 755, item: Item.ODDLY_SPECIFIC_GLASS_HQ },
		{ id: 756, item: Item.ODDLY_SPECIFIC_SEED_FLOUR_HQ }, { id: 757, item: Item.ODDLY_SPECIFIC_DARK_MATTER }, { id: 758, item: Item.ODDLY_SPECIFIC_STRIKING_STONE }, { id: 759, item: Item.ODDLY_SPECIFIC_AMBER },
		{ id: 760, item: Item.ODDLY_SPECIFIC_BAUBLE }, { id: 761, item: Item.DRAGONSPINE }, { id: 762, item: Item.ODDLY_SPECIFIC_CEDAR_LOG }, { id: 763, item: Item.LIGNUM_VITAE_LOG },
		{ id: 764, item: Item.ODDLY_SPECIFIC_CEDAR_LUMBER }, { id: 765, item: Item.ODDLY_SPECIFIC_COERTHAN_IRON_ORE }, { id: 766, item: Item.DIMYTHRITE_ORE }, { id: 767, item: Item.ODDLY_SPECIFIC_IRON_NAILS },
		{ id: 768, item: Item.ODDLY_SPECIFIC_MYTHRITE_SAND }, { id: 769, item: Item.ODDLY_SPECIFIC_MYTHRIL_RINGS }, { id: 770, item: Item.ODDLY_SPECIFIC_SILVER_ORE }, { id: 771, item: Item.DIMYTHRITE_SAND },
		{ id: 772, item: Item.ODDLY_SPECIFIC_SILVER_NUGGET }, { id: 773, item: Item.ODDLY_SPECIFIC_GAGANA_SKIN }, { id: 774, item: Item.YELLOW_ALUMEN }, { id: 775, item: Item.ODDLY_SPECIFIC_GAGANASKIN_STRAP },
		{ id: 776, item: Item.ODDLY_SPECIFIC_FLEECE }, { id: 777, item: Item.DWARVEN_COTTON_BOLL }, { id: 778, item: Item.ODDLY_SPECIFIC_CLOTH }, { id: 779, item: Item.ODDLY_SPECIFIC_SAP },
		{ id: 780, item: Item.VAMPIRE_CUP_VINE }, { id: 781, item: Item.ODDLY_SPECIFIC_GLUE }, { id: 782, item: Item.ODDLY_SPECIFIC_ALOE }, { id: 783, item: Item.FRANTOIO }, { id: 784, item: Item.ODDLY_SPECIFIC_OIL },
		{ id: 897, item: Item.THAVNAIRIAN_SCALEPOWDER }, { id: 903, item: Item.TORTURED_MEMORY_OF_THE_DYING }, { id: 904, item: Item.SORROWFUL_MEMORY_OF_THE_DYING },
		{ id: 905, item: Item.HARROWING_MEMORY_OF_THE_DYING }, { id: 909, item: Item.BITTER_MEMORY_OF_THE_DYING }, { id: 913, item: Item.LOATHSOME_MEMORY_OF_THE_DYING },
		{ id: 918, item: Item.HAUNTING_MEMORY_OF_THE_DYING }, { id: 919, item: Item.VEXATIOUS_MEMORY_OF_THE_DYING }, { id: 922, item: Item.TIMEWORN_ARTIFACTS }, { id: 954, item: Item.ODDLY_SPECIFIC_SCHORL },
		{ id: 955, item: Item.ODDLY_SPECIFIC_LANDBORNE_AETHERSAND }, { id: 956, item: Item.ODDLY_SPECIFIC_DARK_CHESTNUT_LOG }, { id: 957, item: Item.ODDLY_SPECIFIC_LEAFBORNE_AETHERSAND },
		{ id: 958, item: Item.PETAL_SHELL }, { id: 961, item: Item.LIGNUM_VITAE_LUMBER }, { id: 962, item: Item.ODDLY_SPECIFIC_CEDAR_PLANK }, { id: 963, item: Item.DWARVEN_MYTHRIL_INGOT },
		{ id: 964, item: Item.ODDLY_SPECIFIC_IRON_INGOT }, { id: 965, item: Item.ODDLY_SPECIFIC_MYTHRIL_PLATE }, { id: 966, item: Item.DWARVEN_MYTHRIL_NUGGET }, { id: 967, item: Item.ODDLY_SPECIFIC_SILVER_INGOT },
		{ id: 968, item: Item.SEA_SWALLOW_LEATHER }, { id: 969, item: Item.ODDLY_SPECIFIC_GAGANA_LEATHER }, { id: 970, item: Item.DWARVEN_COTTON }, { id: 971, item: Item.ODDLY_SPECIFIC_UNDYED_WOOLEN_CLOTH },
		{ id: 972, item: Item.REFINED_NATRON }, { id: 973, item: Item.ODDLY_SPECIFIC_RUBBER }, { id: 974, item: Item.NIGHT_VINEGAR }, { id: 975, item: Item.ODDLY_SPECIFIC_PASTE },
		{ id: 994, item: Item.ODDLY_SPECIFIC_PRIMORDIAL_ORE }, { id: 995, item: Item.ODDLY_SPECIFIC_PRIMORDIAL_ASPHALTUM }, { id: 996, item: Item.ODDLY_SPECIFIC_PRIMORDIAL_LOG },
		{ id: 997, item: Item.ODDLY_SPECIFIC_PRIMORDIAL_RESIN }, { id: 998, item: Item.ALLAGAN_HUNTER }, { id: 999, item: Item.ODDLY_DELICATE_PINE_LOG }, { id: 1000, item: Item.GRADE_4_ARTISANAL_SKYBUILDERS_LOG },
		{ id: 1001, item: Item.GRADE_4_ARTISANAL_SKYBUILDERS_BARBGRASS }, { id: 1002, item: Item.ODDLY_DELICATE_SILVERGRACE_ORE }, { id: 1003, item: Item.GRADE_4_ARTISANAL_SKYBUILDERS_CLOUDSTONE },
		{ id: 1004, item: Item.GRADE_4_ARTISANAL_SKYBUILDERS_SILEX }, { id: 1005, item: Item.ODDLY_DELICATE_SCHEELITE }, { id: 1007, item: Item.GRADE_4_ARTISANAL_SKYBUILDERS_PRISMSTONE },
		{ id: 1008, item: Item.ODDLY_DELICATE_RAW_CELESTINE }, { id: 1011, item: Item.ODDLY_DELICATE_GAZELLE_HIDE }, { id: 1013, item: Item.GRADE_4_ARTISANAL_SKYBUILDERS_CAIMAN },
		{ id: 1014, item: Item.ODDLY_DELICATE_RHEA }, { id: 1015, item: Item.GRADE_4_ARTISANAL_SKYBUILDERS_COCOON }, { id: 1016, item: Item.GRADE_4_ARTISANAL_SKYBUILDERS_ICE_STALAGMITE },
		{ id: 1017, item: Item.ODDLY_DELICATE_MISTLETOE }, { id: 1018, item: Item.GRADE_4_ARTISANAL_SKYBUILDERS_SPRING_WATER }, { id: 1019, item: Item.GRADE_4_ARTISANAL_SKYBUILDERS_RASPBERRY },
		{ id: 1020, item: Item.ODDLY_DELICATE_HAMMERHEAD_SHARK }, { id: 1023, item: Item.ODDLY_DELICATE_ADAMANTITE_ORE }, { id: 1024, item: Item.ODDLY_DELICATE_RAW_JADE },
		{ id: 1025, item: Item.ODDLY_DELICATE_FEATHER }, { id: 1026, item: Item.ODDLY_DELICATE_BIRCH_LOG }, { id: 1027, item: Item.FLINTSTRIKE }, { id: 1028, item: Item.PICKLED_POM },
		{ id: 785, item: Item.ODDLY_DELICATE_PINE_LUMBER }, { id: 786, item: Item.ODDLY_DELICATE_SILVER_GEAR }, { id: 788, item: Item.ODDLY_DELICATE_WOLFRAM_SQUARE }, { id: 789, item: Item.ODDLY_DELICATE_CELESTINE },
		{ id: 791, item: Item.ODDLY_DELICATE_GAZELLE_LEATHER }, { id: 792, item: Item.ODDLY_DELICATE_RHEA_CLOTH }, { id: 794, item: Item.ODDLY_DELICATE_HOLY_WATER }, { id: 795, item: Item.ODDLY_DELICATE_SHARK_OIL },
		{ id: 861, item: Item.AETHER_OIL }, { id: 978, item: Item.CRYSTAL_SAND }, { id: 1029, item: Item.UMBRITE }, { id: 1037, item: Item.SINGING_CLUSTER }, { id: 1055, item: Item.PNEUMITE },
		{ id: 1058, item: Item.AETHERIC_DENSITY }, { id: 1083, item: Item.ARCHAIC_ENCHANTED_INK }, { id: 1012, item: Item.PROTEAN_CRYSTAL }, { id: 1022, item: Item.PAZUZUS_FEATHER },
		{ id: 1087, item: Item.PAGOS_CRYSTAL }, { id: 1088, item: Item.FROSTED_PROTEAN_CRYSTAL }, { id: 1089, item: Item.LOUHIS_ICE }, { id: 1090, item: Item.PYROS_CRYSTAL },
		{ id: 1092, item: Item.PENTHESILEAS_FLAME }, { id: 1101, item: Item.HYDATOS_CRYSTAL }, { id: 1102, item: Item.CRYSTALLINE_SCALE }, { id: 1105, item: Item.EUREKA_FRAGMENT }, { id: 1110, item: Item.ALEXANDRITE },
		// { id: 1111, name: "Materia I-IV" }, // Not used as an item anymore
		{ id: 1113, item: Item.COMPACT_AXLE }, { id: 1114, item: Item.COMPACT_SPRING }, { id: 1115, item: Item.BATTLES_FOR_THE_REALM }, { id: 1116, item: Item.BEYOND_THE_RIFT },
		{ id: 1117, item: Item.BLEAK_MEMORY_OF_THE_DYING }, { id: 1118, item: Item.LURID_MEMORY_OF_THE_DYING }, { id: 1119, item: Item.RAW_EMOTION },
	];
	/* eslint-enable array-element-newline */
}

function getOldRelics():OldRelic[]{
	/* eslint-disable object-curly-newline */
	return [
		{ id: 247, relic: RelicGroup.WEAPON_ZODIAC, parts: [
			{ id: 248, part: RelicPart.WEAPON_BASE_WEAPON, steps: [
				{ id: 249, step: RelicStep.BROKEN_WEAPON, tasks: [] },
				{ id: 250, step: RelicStep.CLASS_WEAPON, tasks: [
					{ id: 284, task: RelicTask.TASK_1, jobs: [Job.BRD] }, // brd -> longarm's composite bow
					{ id: 285, task: RelicTask.TASK_1, jobs: [Job.NIN] }, // nin -> vamper's knives
					{ id: 286, task: RelicTask.TASK_1, jobs: [Job.BLM] }, // blm -> sanguine scepter
					{ id: 287, task: RelicTask.TASK_1, jobs: [Job.SMN] }, // smn -> erudite's picatrix of casting
					{ id: 288, task: RelicTask.TASK_1, jobs: [Job.DRG] }, // drg -> champion's lance
					{ id: 289, task: RelicTask.TASK_1, jobs: [Job.MNK] }, // mnk -> wildling's cesti
					{ id: 290, task: RelicTask.TASK_1, jobs: [Job.PLD] }, // pld -> aeolian scimitar
					{ id: 291, task: RelicTask.TASK_1, jobs: [Job.WAR] }, // war -> barbarian's bardiche
					{ id: 292, task: RelicTask.TASK_1, jobs: [Job.SCH] }, // sch -> erudite's picatrix of healing
					{ id: 293, task: RelicTask.TASK_1, jobs: [Job.WHM] }, // whm -> madmans whispering rod
					{ id: 294, task: RelicTask.TASK_2, jobs: [Job.NIN, Job.BRD] }, // nin -> heavens eye materia, brd -> heavens eye
					{ id: 295, task: RelicTask.TASK_2, jobs: [Job.BLM, Job.SMN] }, // blm -> savage might materia, smn -> savage might
					{ id: 296, task: RelicTask.TASK_2, jobs: [Job.MNK, Job.DRG] }, // mnk -> savage aim materia, drg -> savage aim
					{ id: 297, task: RelicTask.TASK_2, jobs: [Job.PLD, Job.WAR] }, // pld -> battledance materia, war -> battledance
					{ id: 298, task: RelicTask.TASK_2, jobs: [Job.WHM, Job.SCH] }, // whm -> quicktongue materia, sch -> quicktongue
				] },
				{ id: 251, step: RelicStep.CHIMERA, tasks: [] },
				{ id: 252, step: RelicStep.AMDAPOR, tasks: [] },
				{ id: 253, step: RelicStep.BEASTMAN, tasks: [
					{ id: 299, task: RelicTask.TASK_1, jobs: [Job.BRD] }, // brd -> natalan boldwing
					{ id: 494, task: RelicTask.TASK_2, jobs: [Job.BRD] }, // brd -> natalan fogcaller
					{ id: 504, task: RelicTask.TASK_3, jobs: [Job.BRD] }, // brd -> natalan windtalon
					{ id: 300, task: RelicTask.TASK_1, jobs: [Job.BLM] }, // blm -> 13th order quarryman
					{ id: 495, task: RelicTask.TASK_2, jobs: [Job.BLM] }, // blm -> 13th order bedesman
					{ id: 505, task: RelicTask.TASK_3, jobs: [Job.BLM] }, // blm -> 13th order priest
					{ id: 301, task: RelicTask.TASK_1, jobs: [Job.DRG] }, // drg -> natalan boldwing
					{ id: 496, task: RelicTask.TASK_2, jobs: [Job.DRG] }, // drg -> natalan fogcaller
					{ id: 506, task: RelicTask.TASK_2, jobs: [Job.DRG] }, // drg -> natalan windtalon
					{ id: 302, task: RelicTask.TASK_1, jobs: [Job.MNK] }, // mnk -> zahar'ak lancer
					{ id: 497, task: RelicTask.TASK_2, jobs: [Job.MNK] }, // mnk -> zahar'ak pugilist
					{ id: 507, task: RelicTask.TASK_3, jobs: [Job.MNK] }, // mnk -> zahar'ak archer
					{ id: 303, task: RelicTask.TASK_1, jobs: [Job.PLD] }, // pld -> zahar'ak lancer
					{ id: 498, task: RelicTask.TASK_2, jobs: [Job.PLD] }, // pld -> zahar'ak pugilist
					{ id: 508, task: RelicTask.TASK_3, jobs: [Job.PLD] }, // pld -> zahar'ak thaumaturge
					{ id: 304, task: RelicTask.TASK_1, jobs: [Job.SCH] }, // sch -> sapsa shelfspine
					{ id: 499, task: RelicTask.TASK_2, jobs: [Job.SCH] }, // sch -> sapsa shelfclaw
					{ id: 509, task: RelicTask.TASK_3, jobs: [Job.SCH] }, // sch -> sapsa shelftooth
					{ id: 305, task: RelicTask.TASK_1, jobs: [Job.NIN] }, // nin -> sapsa shelfspine
					{ id: 500, task: RelicTask.TASK_2, jobs: [Job.NIN] }, // nin -> sapsa shelfclaw
					{ id: 510, task: RelicTask.TASK_3, jobs: [Job.NIN] }, // nin -> sapsa shelftooth
					{ id: 306, task: RelicTask.TASK_1, jobs: [Job.SMN] }, // smn -> violet sigh
					{ id: 501, task: RelicTask.TASK_2, jobs: [Job.SMN] }, // smn -> violet screech
					{ id: 511, task: RelicTask.TASK_3, jobs: [Job.SMN] }, // smn -> violet snarl
					{ id: 307, task: RelicTask.TASK_1, jobs: [Job.WAR] }, // war -> 13th order quarryman
					{ id: 502, task: RelicTask.TASK_2, jobs: [Job.WAR] }, // war -> 13th order bedesman
					{ id: 512, task: RelicTask.TASK_3, jobs: [Job.WAR] }, // war -> 13th order priest
					{ id: 308, task: RelicTask.TASK_1, jobs: [Job.WHM] }, // whm -> 13th order quarryman
					{ id: 503, task: RelicTask.TASK_2, jobs: [Job.WHM] }, // whm -> 13th order bedesman
					{ id: 513, task: RelicTask.TASK_3, jobs: [Job.WHM] }, // whm -> 13th order priest
				] },
				{ id: 254, step: RelicStep.HYDRA, tasks: [] },
				{ id: 255, step: RelicStep.EMBER, tasks: [] },
				{ id: 256, step: RelicStep.GALE, tasks: [] },
				{ id: 257, step: RelicStep.ORE, tasks: [] },
				{ id: 258, step: RelicStep.OIL, tasks: [{ id: 309, task: RelicTask.TASK_1, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] }] },
			] },
			{ id: 259, part: RelicPart.WEAPON_ZENITH, steps: [
				{ id: 283, step: RelicStep.MIST, tasks: [
					{ id: 310, task: RelicTask.TASK_1, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
				] },
			] },
			{ id: 260, part: RelicPart.WEAPON_ATMA, steps: [
				{ id: 1163, step: RelicStep.ARMS, tasks: [] },
				{ id: 261, step: RelicStep.ATMAS, tasks: [
					{ id: 311, task: RelicTask.TASK_1, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 312, task: RelicTask.TASK_2, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 313, task: RelicTask.TASK_3, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 314, task: RelicTask.TASK_4, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 315, task: RelicTask.TASK_5, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 316, task: RelicTask.TASK_6, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 317, task: RelicTask.TASK_7, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 318, task: RelicTask.TASK_8, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 319, task: RelicTask.TASK_9, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 320, task: RelicTask.TASK_10, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 321, task: RelicTask.TASK_11, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 322, task: RelicTask.TASK_12, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
				] },
			] },
			{ id: 262, part: RelicPart.WEAPON_ANIMUS, steps: [
				{ id: 1164, step: RelicStep.TRIALS, tasks: [] },
				{ id: 263, step: RelicStep.SKYFIRE_1, tasks: [
					{ id: 323, task: RelicTask.TASK_1, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 324, task: RelicTask.TASK_2, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 325, task: RelicTask.TASK_3, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 326, task: RelicTask.TASK_4, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 327, task: RelicTask.TASK_5, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 328, task: RelicTask.TASK_6, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 329, task: RelicTask.TASK_7, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 330, task: RelicTask.TASK_8, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 331, task: RelicTask.TASK_9, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 332, task: RelicTask.TASK_10, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 333, task: RelicTask.TASK_11, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 334, task: RelicTask.TASK_12, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 335, task: RelicTask.TASK_13, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 336, task: RelicTask.TASK_14, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 337, task: RelicTask.TASK_15, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 338, task: RelicTask.TASK_16, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 339, task: RelicTask.TASK_17, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 340, task: RelicTask.TASK_18, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 341, task: RelicTask.TASK_19, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
				] },
				{ id: 264, step: RelicStep.SKYFIRE_2, tasks: [
					{ id: 342, task: RelicTask.TASK_1, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 343, task: RelicTask.TASK_2, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 344, task: RelicTask.TASK_3, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 345, task: RelicTask.TASK_4, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 346, task: RelicTask.TASK_5, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 347, task: RelicTask.TASK_6, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 348, task: RelicTask.TASK_7, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 349, task: RelicTask.TASK_8, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 350, task: RelicTask.TASK_9, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 351, task: RelicTask.TASK_10, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 352, task: RelicTask.TASK_11, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 353, task: RelicTask.TASK_12, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 354, task: RelicTask.TASK_13, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 355, task: RelicTask.TASK_14, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 356, task: RelicTask.TASK_15, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 357, task: RelicTask.TASK_16, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 358, task: RelicTask.TASK_17, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 359, task: RelicTask.TASK_18, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 360, task: RelicTask.TASK_19, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
				] },
				{ id: 265, step: RelicStep.NETHERFIRE_1, tasks: [
					{ id: 361, task: RelicTask.TASK_1, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 362, task: RelicTask.TASK_2, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 363, task: RelicTask.TASK_3, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 364, task: RelicTask.TASK_4, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 365, task: RelicTask.TASK_5, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 366, task: RelicTask.TASK_6, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 367, task: RelicTask.TASK_7, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 368, task: RelicTask.TASK_8, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 369, task: RelicTask.TASK_9, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 370, task: RelicTask.TASK_10, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 371, task: RelicTask.TASK_11, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 372, task: RelicTask.TASK_12, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 373, task: RelicTask.TASK_13, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 374, task: RelicTask.TASK_14, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 375, task: RelicTask.TASK_15, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 376, task: RelicTask.TASK_16, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 377, task: RelicTask.TASK_17, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 378, task: RelicTask.TASK_18, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 379, task: RelicTask.TASK_19, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
				] },
				{ id: 266, step: RelicStep.SKYFALL_1, tasks: [
					{ id: 380, task: RelicTask.TASK_1, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 381, task: RelicTask.TASK_2, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 382, task: RelicTask.TASK_3, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 383, task: RelicTask.TASK_4, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 384, task: RelicTask.TASK_5, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 385, task: RelicTask.TASK_6, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 386, task: RelicTask.TASK_7, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 387, task: RelicTask.TASK_8, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 388, task: RelicTask.TASK_9, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 389, task: RelicTask.TASK_10, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 390, task: RelicTask.TASK_11, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 391, task: RelicTask.TASK_12, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 392, task: RelicTask.TASK_13, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 393, task: RelicTask.TASK_14, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 394, task: RelicTask.TASK_15, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 395, task: RelicTask.TASK_16, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 396, task: RelicTask.TASK_17, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 397, task: RelicTask.TASK_18, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 398, task: RelicTask.TASK_19, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
				] },
				{ id: 267, step: RelicStep.SKYFALL_2, tasks: [
					{ id: 399, task: RelicTask.TASK_1, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 400, task: RelicTask.TASK_2, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 401, task: RelicTask.TASK_3, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 402, task: RelicTask.TASK_4, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 403, task: RelicTask.TASK_5, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 404, task: RelicTask.TASK_6, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 405, task: RelicTask.TASK_7, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 406, task: RelicTask.TASK_8, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 407, task: RelicTask.TASK_9, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 408, task: RelicTask.TASK_10, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 409, task: RelicTask.TASK_11, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 410, task: RelicTask.TASK_12, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 411, task: RelicTask.TASK_13, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 412, task: RelicTask.TASK_14, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 413, task: RelicTask.TASK_15, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 414, task: RelicTask.TASK_16, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 415, task: RelicTask.TASK_17, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 416, task: RelicTask.TASK_18, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 417, task: RelicTask.TASK_19, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
				] },
				{ id: 268, step: RelicStep.NETHERFALL_1, tasks: [
					{ id: 418, task: RelicTask.TASK_1, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 419, task: RelicTask.TASK_2, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 420, task: RelicTask.TASK_3, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 421, task: RelicTask.TASK_4, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 422, task: RelicTask.TASK_5, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 423, task: RelicTask.TASK_6, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 424, task: RelicTask.TASK_7, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 425, task: RelicTask.TASK_8, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 426, task: RelicTask.TASK_9, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 427, task: RelicTask.TASK_10, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 428, task: RelicTask.TASK_11, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 429, task: RelicTask.TASK_12, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 430, task: RelicTask.TASK_13, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 431, task: RelicTask.TASK_14, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 432, task: RelicTask.TASK_15, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 433, task: RelicTask.TASK_16, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 434, task: RelicTask.TASK_17, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 435, task: RelicTask.TASK_18, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 436, task: RelicTask.TASK_19, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
				] },
				{ id: 269, step: RelicStep.SKYWIND_1, tasks: [
					{ id: 437, task: RelicTask.TASK_1, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 438, task: RelicTask.TASK_2, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 439, task: RelicTask.TASK_3, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 440, task: RelicTask.TASK_4, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 441, task: RelicTask.TASK_5, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 442, task: RelicTask.TASK_6, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 443, task: RelicTask.TASK_7, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 444, task: RelicTask.TASK_8, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 445, task: RelicTask.TASK_9, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 446, task: RelicTask.TASK_10, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 447, task: RelicTask.TASK_11, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 448, task: RelicTask.TASK_12, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 449, task: RelicTask.TASK_13, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 450, task: RelicTask.TASK_14, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 451, task: RelicTask.TASK_15, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 452, task: RelicTask.TASK_16, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 453, task: RelicTask.TASK_17, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 454, task: RelicTask.TASK_18, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 455, task: RelicTask.TASK_19, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
				] },
				{ id: 270, step: RelicStep.SKYWIND_2, tasks: [
					{ id: 456, task: RelicTask.TASK_1, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 457, task: RelicTask.TASK_2, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 458, task: RelicTask.TASK_3, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 459, task: RelicTask.TASK_4, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 460, task: RelicTask.TASK_5, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 461, task: RelicTask.TASK_6, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 462, task: RelicTask.TASK_7, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 463, task: RelicTask.TASK_8, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 464, task: RelicTask.TASK_9, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 465, task: RelicTask.TASK_10, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 466, task: RelicTask.TASK_11, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 467, task: RelicTask.TASK_12, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 468, task: RelicTask.TASK_13, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 469, task: RelicTask.TASK_14, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 470, task: RelicTask.TASK_15, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 471, task: RelicTask.TASK_16, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 472, task: RelicTask.TASK_17, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 473, task: RelicTask.TASK_18, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 474, task: RelicTask.TASK_19, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
				] },
				{ id: 271, step: RelicStep.SKYEARTH_1, tasks: [
					{ id: 475, task: RelicTask.TASK_1, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 476, task: RelicTask.TASK_2, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 477, task: RelicTask.TASK_3, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 478, task: RelicTask.TASK_4, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 479, task: RelicTask.TASK_5, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 480, task: RelicTask.TASK_6, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 481, task: RelicTask.TASK_7, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 482, task: RelicTask.TASK_8, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 483, task: RelicTask.TASK_9, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 484, task: RelicTask.TASK_10, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 485, task: RelicTask.TASK_11, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 486, task: RelicTask.TASK_12, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 487, task: RelicTask.TASK_13, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 488, task: RelicTask.TASK_14, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 489, task: RelicTask.TASK_15, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 490, task: RelicTask.TASK_16, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 491, task: RelicTask.TASK_17, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 492, task: RelicTask.TASK_18, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 493, task: RelicTask.TASK_19, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
				] },
			] },
			{ id: 272, part: RelicPart.WEAPON_NOVUS, steps: [
				{ id: 1096, step: RelicStep.RADIANCE, tasks: [] },
				{ id: 273, step: RelicStep.SCROLL, tasks: [
					{ id: 721, task: RelicTask.TASK_1, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
				] },
				{ id: 1165, step: RelicStep.STAR, tasks: [] },
				{ id: 1108, step: RelicStep.ALEXANDRITE, tasks: [
					{ id: 1109, task: RelicTask.TASK_1, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
				] },
				{ id: 274, step: RelicStep.BINDING, tasks: [] },
			] },
			{ id: 529, part: RelicPart.WEAPON_NEXUS, steps: [
				{ id: 1167, step: RelicStep.SOULGLAZE, tasks: [] },
				{ id: 530, step: RelicStep.LIGHT, tasks: [
					{ id: 543, task: RelicTask.TASK_1, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
				] },
			] },
			{ id: 276, part: RelicPart.WEAPON_BRAVES, steps: [
				{ id: 1168, step: RelicStep.WHEREFORE, tasks: [] },
				{ id: 544, step: RelicStep.SKYLIGHT, tasks: [
					{ id: 549, task: RelicTask.TASK_1, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 550, task: RelicTask.TASK_2, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 551, task: RelicTask.TASK_3, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 552, task: RelicTask.TASK_4, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 553, task: RelicTask.TASK_5, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 554, task: RelicTask.TASK_6, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 555, task: RelicTask.TASK_7, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 556, task: RelicTask.TASK_8, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 557, task: RelicTask.TASK_9, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
				] },
				{ id: 545, step: RelicStep.ZODIUM, tasks: [
					{ id: 567, task: RelicTask.TASK_1, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 568, task: RelicTask.TASK_2, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 569, task: RelicTask.TASK_3, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 570, task: RelicTask.TASK_4, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 571, task: RelicTask.TASK_5, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 572, task: RelicTask.TASK_6, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 573, task: RelicTask.TASK_7, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 574, task: RelicTask.TASK_8, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 575, task: RelicTask.TASK_9, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
				] },
				{ id: 546, step: RelicStep.FLAWLESS_ALEXANDRITE, tasks: [
					{ id: 603, task: RelicTask.TASK_1, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 604, task: RelicTask.TASK_2, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 605, task: RelicTask.TASK_3, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 606, task: RelicTask.TASK_4, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 607, task: RelicTask.TASK_5, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 608, task: RelicTask.TASK_6, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 609, task: RelicTask.TASK_7, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 610, task: RelicTask.TASK_8, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 611, task: RelicTask.TASK_9, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
				] },
				{ id: 547, step: RelicStep.BRAVES_SCROLL, tasks: [
					{ id: 612, task: RelicTask.TASK_1, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 613, task: RelicTask.TASK_2, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 614, task: RelicTask.TASK_3, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 615, task: RelicTask.TASK_4, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 616, task: RelicTask.TASK_5, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 617, task: RelicTask.TASK_6, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 618, task: RelicTask.TASK_7, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 619, task: RelicTask.TASK_8, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 620, task: RelicTask.TASK_9, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
				] },
				{ id: 548, step: RelicStep.MATERIA, tasks: [] },
			] },
			{ id: 277, part: RelicPart.WEAPON_ZETA, steps: [
				{ id: 1171, step: RelicStep.SHINE, tasks: [] },
				{ id: 621, step: RelicStep.SOUL, tasks: [
					{ id: 622, task: RelicTask.TASK_1, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 623, task: RelicTask.TASK_2, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 624, task: RelicTask.TASK_3, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 625, task: RelicTask.TASK_4, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 626, task: RelicTask.TASK_5, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 627, task: RelicTask.TASK_6, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 628, task: RelicTask.TASK_7, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 629, task: RelicTask.TASK_8, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 630, task: RelicTask.TASK_9, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 631, task: RelicTask.TASK_10, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 632, task: RelicTask.TASK_11, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
					{ id: 633, task: RelicTask.TASK_12, jobs: jobLists.relic[RelicGroup.WEAPON_ZODIAC] },
				] },
				{ id: 1172, step: RelicStep.VITAL, tasks: [] },
			] },
		] },
		{ id: 278, relic: RelicGroup.WEAPON_ANIMA, parts: [
			{ id: 652, part: RelicPart.WEAPON_ANIMATED, steps: [
				{ id: 653, step: RelicStep.CRYSTALS, tasks: [
					{ id: 666, task: RelicTask.TASK_1, jobs: jobLists.relic[RelicGroup.WEAPON_ANIMA] },
					{ id: 667, task: RelicTask.TASK_2, jobs: jobLists.relic[RelicGroup.WEAPON_ANIMA] },
					{ id: 668, task: RelicTask.TASK_3, jobs: jobLists.relic[RelicGroup.WEAPON_ANIMA] },
					{ id: 669, task: RelicTask.TASK_4, jobs: jobLists.relic[RelicGroup.WEAPON_ANIMA] },
					{ id: 670, task: RelicTask.TASK_5, jobs: jobLists.relic[RelicGroup.WEAPON_ANIMA] },
					{ id: 671, task: RelicTask.TASK_6, jobs: jobLists.relic[RelicGroup.WEAPON_ANIMA] },
				] },
			] },
			{ id: 672, part: RelicPart.WEAPON_AWOKEN, steps: [
				{ id: 673, step: RelicStep.SNOWCLOAK, tasks: [] },
				{ id: 691, step: RelicStep.SASTASHA, tasks: [] },
				{ id: 275, step: RelicStep.QARN, tasks: [] },
				{ id: 722, step: RelicStep.KEEPER, tasks: [] },
				{ id: 723, step: RelicStep.WANDERER, tasks: [] },
				{ id: 724, step: RelicStep.AMDAPOR_AWOKEN, tasks: [] },
				{ id: 725, step: RelicStep.VIGIL, tasks: [] },
				{ id: 726, step: RelicStep.SOHM, tasks: [] },
				{ id: 727, step: RelicStep.AERY, tasks: [] },
				{ id: 728, step: RelicStep.VAULT, tasks: [] },
			] },
			{ id: 692, part: RelicPart.WEAPON_ANIMA, steps: [
				{ id: 814, step: RelicStep.RUBBER, tasks: [
					{ id: 701, task: RelicTask.TASK_1, jobs: jobLists.relic[RelicGroup.WEAPON_ANIMA] },
					{ id: 843, task: RelicTask.TASK_2, jobs: jobLists.relic[RelicGroup.WEAPON_ANIMA] },
				] },
				{ id: 698, step: RelicStep.CARBONCOAT, tasks: [
					{ id: 844, task: RelicTask.TASK_1, jobs: jobLists.relic[RelicGroup.WEAPON_ANIMA] },
					{ id: 846, task: RelicTask.TASK_2, jobs: jobLists.relic[RelicGroup.WEAPON_ANIMA] },
				] },
				{ id: 699, step: RelicStep.WATER, tasks: [
					{ id: 847, task: RelicTask.TASK_1, jobs: jobLists.relic[RelicGroup.WEAPON_ANIMA] },
					{ id: 849, task: RelicTask.TASK_2, jobs: jobLists.relic[RelicGroup.WEAPON_ANIMA] },
				] },
				{ id: 700, step: RelicStep.CATALYST, tasks: [
					{ id: 851, task: RelicTask.TASK_1, jobs: jobLists.relic[RelicGroup.WEAPON_ANIMA] },
					{ id: 852, task: RelicTask.TASK_2, jobs: jobLists.relic[RelicGroup.WEAPON_ANIMA] },
				] },
			] },
			{ id: 854, part: RelicPart.WEAPON_HYPERCONDUCTIVE, steps: [
				{ id: 857, step: RelicStep.OIL_HYPER, tasks: [
					{ id: 860, task: RelicTask.TASK_1, jobs: jobLists.relic[RelicGroup.WEAPON_ANIMA] },
				] },
			] },
			{ id: 863, part: RelicPart.WEAPON_RECONDITIONED, steps: [
				{ id: 977, step: RelicStep.SAND, tasks: [
					{ id: 1030, task: RelicTask.TASK_1, jobs: jobLists.relic[RelicGroup.WEAPON_ANIMA] },
					{ id: 1031, task: RelicTask.TASK_2, jobs: jobLists.relic[RelicGroup.WEAPON_ANIMA] },
				] },
			] },
			{ id: 1034, part: RelicPart.WEAPON_SHARPENED, steps: [
				{ id: 1035, step: RelicStep.CLUSTERS, tasks: [
					{ id: 1036, task: RelicTask.TASK_1, jobs: jobLists.relic[RelicGroup.WEAPON_ANIMA] },
				] },
			] },
			{ id: 1045, part: RelicPart.WEAPON_COMPLETE, steps: [
				{ id: 1046, step: RelicStep.DUNGEONS, tasks: [
					{ id: 1047, task: RelicTask.TASK_1, jobs: jobLists.relic[RelicGroup.WEAPON_ANIMA] },
					{ id: 1048, task: RelicTask.TASK_2, jobs: jobLists.relic[RelicGroup.WEAPON_ANIMA] },
					{ id: 1049, task: RelicTask.TASK_3, jobs: jobLists.relic[RelicGroup.WEAPON_ANIMA] },
				] },
				{ id: 1053, step: RelicStep.PNEUMITE, tasks: [
					{ id: 1054, task: RelicTask.TASK_1, jobs: jobLists.relic[RelicGroup.WEAPON_ANIMA] },
				] },
				{ id: 1056, step: RelicStep.DENSITY, tasks: [
					{ id: 1057, task: RelicTask.TASK_1, jobs: jobLists.relic[RelicGroup.WEAPON_ANIMA] },
				] },
			] },
			{ id: 1059, part: RelicPart.WEAPON_LUX, steps: [
				{ id: 1060, step: RelicStep.BFF, tasks: [
					{ id: 1070, task: RelicTask.TASK_1, jobs: jobLists.relic[RelicGroup.WEAPON_ANIMA] },
					{ id: 1071, task: RelicTask.TASK_2, jobs: jobLists.relic[RelicGroup.WEAPON_ANIMA] },
					{ id: 1072, task: RelicTask.TASK_3, jobs: jobLists.relic[RelicGroup.WEAPON_ANIMA] },
					{ id: 1073, task: RelicTask.TASK_4, jobs: jobLists.relic[RelicGroup.WEAPON_ANIMA] },
					{ id: 1074, task: RelicTask.TASK_5, jobs: jobLists.relic[RelicGroup.WEAPON_ANIMA] },
					{ id: 1075, task: RelicTask.TASK_6, jobs: jobLists.relic[RelicGroup.WEAPON_ANIMA] },
					{ id: 1076, task: RelicTask.TASK_7, jobs: jobLists.relic[RelicGroup.WEAPON_ANIMA] },
					{ id: 1077, task: RelicTask.TASK_8, jobs: jobLists.relic[RelicGroup.WEAPON_ANIMA] },
					{ id: 1078, task: RelicTask.TASK_9, jobs: jobLists.relic[RelicGroup.WEAPON_ANIMA] },
					{ id: 1079, task: RelicTask.TASK_10, jobs: jobLists.relic[RelicGroup.WEAPON_ANIMA] },
					{ id: 1080, task: RelicTask.TASK_11, jobs: jobLists.relic[RelicGroup.WEAPON_ANIMA] },
					{ id: 1081, task: RelicTask.TASK_12, jobs: jobLists.relic[RelicGroup.WEAPON_ANIMA] },
					{ id: 1082, task: RelicTask.TASK_13, jobs: jobLists.relic[RelicGroup.WEAPON_ANIMA] },
				] },
			] },
		] },
		{ id: 279, relic: RelicGroup.WEAPON_EUREKA, parts: [
			{ id: 980, part: RelicPart.WEAPON_ANTIQUATED, steps: [
				{ id: 1006, step: RelicStep.ANTIQUATED, tasks: [] },
			] },
			{ id: 981, part: RelicPart.WEAPON_ANEMOS, steps: [
				{ id: 1188, step: RelicStep.ANEMOS_0, tasks: [
					{ id: 1191, task: RelicTask.TASK_1, jobs: jobLists.relic[RelicGroup.WEAPON_EUREKA] },
				] },
				{ id: 1189, step: RelicStep.ANEMOS_1, tasks: [
					{ id: 1192, task: RelicTask.TASK_1, jobs: jobLists.relic[RelicGroup.WEAPON_EUREKA] },
				] },
				{ id: 1190, step: RelicStep.ANEMOS_2, tasks: [
					{ id: 1193, task: RelicTask.TASK_1, jobs: jobLists.relic[RelicGroup.WEAPON_EUREKA] },
				] },
				{ id: 1009, step: RelicStep.ANEMOS, tasks: [
					{ id: 1021, task: RelicTask.TASK_1, jobs: jobLists.relic[RelicGroup.WEAPON_EUREKA] },
				] },
			] },
			{ id: 983, part: RelicPart.WEAPON_ELEMENTAL, steps: [
				{ id: 1010, step: RelicStep.PAGOS, tasks: [
					{ id: 1085, task: RelicTask.TASK_1, jobs: jobLists.relic[RelicGroup.WEAPON_EUREKA] },
				] },
				{ id: 1194, step: RelicStep.PAGOS_1, tasks: [
					{ id: 1084, task: RelicTask.TASK_1, jobs: jobLists.relic[RelicGroup.WEAPON_EUREKA] },
					{ id: 1195, task: RelicTask.TASK_2, jobs: jobLists.relic[RelicGroup.WEAPON_EUREKA] },
				] },
				{ id: 984, step: RelicStep.ELEMENTAL, tasks: [
					{ id: 1196, task: RelicTask.TASK_1, jobs: jobLists.relic[RelicGroup.WEAPON_EUREKA] },
					{ id: 1086, task: RelicTask.TASK_2, jobs: jobLists.relic[RelicGroup.WEAPON_EUREKA] },
				] },
			] },
			{ id: 1093, part: RelicPart.WEAPON_PYROS, steps: [
				{ id: 1094, step: RelicStep.ELEMENTAL_1, tasks: [
					{ id: 1199, task: RelicTask.TASK_1, jobs: jobLists.relic[RelicGroup.WEAPON_EUREKA] },
				] },
				{ id: 1197, step: RelicStep.ELEMENTAL_2, tasks: [
					{ id: 1200, task: RelicTask.TASK_1, jobs: jobLists.relic[RelicGroup.WEAPON_EUREKA] },
				] },
				{ id: 1198, step: RelicStep.PYROS, tasks: [
					{ id: 1095, task: RelicTask.TASK_1, jobs: jobLists.relic[RelicGroup.WEAPON_EUREKA] },
					{ id: 1097, task: RelicTask.TASK_2, jobs: jobLists.relic[RelicGroup.WEAPON_EUREKA] },
				] },
			] },
			{ id: 986, part: RelicPart.WEAPON_EUREKA, steps: [
				{ id: 1098, step: RelicStep.HYDATOS, tasks: [
					{ id: 1099, task: RelicTask.TASK_1, jobs: jobLists.relic[RelicGroup.WEAPON_EUREKA] },
				] },
				{ id: 1201, step: RelicStep.HYDATOS_1, tasks: [
					{ id: 1203, task: RelicTask.TASK_1, jobs: jobLists.relic[RelicGroup.WEAPON_EUREKA] },
				] },
				{ id: 1205, step: RelicStep.BASE, tasks: [
					{ id: 1206, task: RelicTask.TASK_1, jobs: jobLists.relic[RelicGroup.WEAPON_EUREKA] },
				] },
				{ id: 1202, step: RelicStep.EUREKA, tasks: [
					{ id: 1204, task: RelicTask.TASK_1, jobs: jobLists.relic[RelicGroup.WEAPON_EUREKA] },
					{ id: 1100, task: RelicTask.TASK_2, jobs: jobLists.relic[RelicGroup.WEAPON_EUREKA] },
				] },
			] },
			{ id: 987, part: RelicPart.WEAPON_PHYSEOS, steps: [
				{ id: 1103, step: RelicStep.FRAGMENTS, tasks: [
					{ id: 1104, task: RelicTask.TASK_1, jobs: jobLists.relic[RelicGroup.WEAPON_EUREKA] },
				] },
			] },
		] },
		{ id: 280, relic: RelicGroup.WEAPON_RESISTANCE, parts: [
			{ id: 894, part: RelicPart.WEAPON_RESISTANCE, steps: [
				{ id: 895, step: RelicStep.SCALEPOWDER, tasks: [
					{ id: 896, task: RelicTask.TASK_1, jobs: jobLists.relic[RelicGroup.WEAPON_RESISTANCE] },
				] },
			] },
			{ id: 898, part: RelicPart.WEAPON_AUGMENTED_RESISTANCE, steps: [
				{ id: 899, step: RelicStep.MEMORY, tasks: [
					{ id: 900, task: RelicTask.TASK_1, jobs: jobLists.relic[RelicGroup.WEAPON_RESISTANCE] },
					{ id: 901, task: RelicTask.TASK_2, jobs: jobLists.relic[RelicGroup.WEAPON_RESISTANCE] },
					{ id: 902, task: RelicTask.TASK_3, jobs: jobLists.relic[RelicGroup.WEAPON_RESISTANCE] },
				] },
			] },
			{ id: 906, part: RelicPart.WEAPON_RECOLLECTION, steps: [
				{ id: 907, step: RelicStep.RESIST, tasks: [
					{ id: 908, task: RelicTask.TASK_1, jobs: jobLists.relic[RelicGroup.WEAPON_RESISTANCE] },
				] },
			] },
			{ id: 910, part: RelicPart.WEAPON_LAWS_ORDER, steps: [
				{ id: 911, step: RelicStep.CHANGE_OF_ARMS, tasks: [
					{ id: 912, task: RelicTask.TASK_1, jobs: jobLists.relic[RelicGroup.WEAPON_RESISTANCE] },
				] },
			] },
			{ id: 914, part: RelicPart.WEAPON_AUGMENTED_LAWS_ORDER, steps: [
				{ id: 915, step: RelicStep.REMEMBERS, tasks: [
					{ id: 916, task: RelicTask.TASK_1, jobs: jobLists.relic[RelicGroup.WEAPON_RESISTANCE] },
					{ id: 917, task: RelicTask.TASK_2, jobs: jobLists.relic[RelicGroup.WEAPON_RESISTANCE] },
				] },
				{ id: 920, step: RelicStep.PATH, tasks: [
					{ id: 921, task: RelicTask.TASK_1, jobs: jobLists.relic[RelicGroup.WEAPON_RESISTANCE] },
				] },
			] },
			{ id: 1106, part: RelicPart.WEAPON_BLADE, steps: [
				{ id: 1107, step: RelicStep.SPARE_PARTS, tasks: [
					{ id: 1120, task: RelicTask.TASK_1, jobs: jobLists.relic[RelicGroup.WEAPON_RESISTANCE] },
					{ id: 1121, task: RelicTask.TASK_2, jobs: jobLists.relic[RelicGroup.WEAPON_RESISTANCE] },
				] },
				{ id: 689, step: RelicStep.STORY, tasks: [
					{ id: 1122, task: RelicTask.TASK_1, jobs: jobLists.relic[RelicGroup.WEAPON_RESISTANCE] },
					{ id: 1123, task: RelicTask.TASK_2, jobs: jobLists.relic[RelicGroup.WEAPON_RESISTANCE] },
				] },
				{ id: 690, step: RelicStep.FOND_MEMORY, tasks: [
					{ id: 1124, task: RelicTask.TASK_1, jobs: jobLists.relic[RelicGroup.WEAPON_RESISTANCE] },
					{ id: 1125, task: RelicTask.TASK_2, jobs: jobLists.relic[RelicGroup.WEAPON_RESISTANCE] },
				] },
				{ id: 1091, step: RelicStep.DEAL, tasks: [] },
				{ id: 1112, step: RelicStep.IRRESISTIBLE, tasks: [
					{ id: 1126, task: RelicTask.TASK_1, jobs: jobLists.relic[RelicGroup.WEAPON_RESISTANCE] },
				] },
			] },
		] },
		{ id: 281, relic: RelicGroup.TOOL_SKYSTEEL, parts: [
			{ id: 729, part: RelicPart.TOOL_SKYSTEEL, steps: [
				{ id: 730, step: RelicStep.PURCHASE, tasks: [] },
			] },
			{ id: 809, part: RelicPart.TOOL_SKYSTEEL_1, steps: [
				{ id: 731, step: RelicStep.SKYSTEEL_ITEMS, tasks: [
					{ id: 817, task: RelicTask.TASK_1, jobs: [Job.CRP] },
					{ id: 818, task: RelicTask.TASK_1, jobs: [Job.BSM] },
					{ id: 819, task: RelicTask.TASK_1, jobs: [Job.ARM] },
					{ id: 820, task: RelicTask.TASK_1, jobs: [Job.GSM] },
					{ id: 821, task: RelicTask.TASK_1, jobs: [Job.LTW] },
					{ id: 822, task: RelicTask.TASK_1, jobs: [Job.WVR] },
					{ id: 823, task: RelicTask.TASK_1, jobs: [Job.ALC] },
					{ id: 824, task: RelicTask.TASK_1, jobs: [Job.CUL] },
					{ id: 825, task: RelicTask.TASK_1, jobs: [Job.MIN] },
					{ id: 826, task: RelicTask.TASK_2, jobs: [Job.MIN] },
					{ id: 827, task: RelicTask.TASK_1, jobs: [Job.BTN] },
					{ id: 828, task: RelicTask.TASK_2, jobs: [Job.BTN] },
					{ id: 829, task: RelicTask.TASK_1, jobs: [Job.FSH] },
				] },
			] },
			{ id: 810, part: RelicPart.TOOL_DRAGONSUNG, steps: [
				{ id: 732, step: RelicStep.DRAGONSUNG_ITEMS, tasks: [
					{ id: 830, task: RelicTask.TASK_1, jobs: [Job.CRP] },
					{ id: 831, task: RelicTask.TASK_1, jobs: [Job.BSM] },
					{ id: 832, task: RelicTask.TASK_1, jobs: [Job.ARM] },
					{ id: 833, task: RelicTask.TASK_1, jobs: [Job.GSM] },
					{ id: 834, task: RelicTask.TASK_1, jobs: [Job.LTW] },
					{ id: 835, task: RelicTask.TASK_1, jobs: [Job.WVR] },
					{ id: 836, task: RelicTask.TASK_1, jobs: [Job.ALC] },
					{ id: 837, task: RelicTask.TASK_1, jobs: [Job.CUL] },
					{ id: 838, task: RelicTask.TASK_1, jobs: [Job.MIN] },
					{ id: 839, task: RelicTask.TASK_2, jobs: [Job.MIN] },
					{ id: 840, task: RelicTask.TASK_1, jobs: [Job.BTN] },
					{ id: 841, task: RelicTask.TASK_2, jobs: [Job.BTN] },
					{ id: 842, task: RelicTask.TASK_1, jobs: [Job.FSH] },
				] },
			] },
			{ id: 811, part: RelicPart.TOOL_AUGMENTED_DRAGONSUNG, steps: [
				{ id: 733, step: RelicStep.BASIC_GOBBIEGOO, tasks: [
					{ id: 845, task: RelicTask.TASK_1, jobs: [Job.CRP] },
					{ id: 848, task: RelicTask.TASK_1, jobs: [Job.BSM] },
					{ id: 850, task: RelicTask.TASK_1, jobs: [Job.ARM] },
					{ id: 853, task: RelicTask.TASK_1, jobs: [Job.GSM] },
					{ id: 787, task: RelicTask.TASK_1, jobs: [Job.LTW] },
					{ id: 790, task: RelicTask.TASK_1, jobs: [Job.WVR] },
					{ id: 793, task: RelicTask.TASK_1, jobs: [Job.ALC] },
					{ id: 796, task: RelicTask.TASK_1, jobs: [Job.CUL] },
					{ id: 797, task: RelicTask.TASK_1, jobs: [Job.MIN] },
					{ id: 952, task: RelicTask.TASK_2, jobs: [Job.MIN] },
					{ id: 953, task: RelicTask.TASK_1, jobs: [Job.BTN] },
					{ id: 959, task: RelicTask.TASK_2, jobs: [Job.BTN] },
					{ id: 960, task: RelicTask.TASK_1, jobs: [Job.FSH] },
				] },
			] },
			{ id: 812, part: RelicPart.TOOL_SKYSUNG, steps: [
				{ id: 734, step: RelicStep.VISCOUS_GOBBIEGOO, tasks: [
					{ id: 856, task: RelicTask.TASK_1, jobs: [Job.CRP] },
					{ id: 859, task: RelicTask.TASK_1, jobs: [Job.BSM] },
					{ id: 862, task: RelicTask.TASK_1, jobs: [Job.ARM] },
					{ id: 976, task: RelicTask.TASK_1, jobs: [Job.GSM] },
					{ id: 979, task: RelicTask.TASK_1, jobs: [Job.LTW] },
					{ id: 982, task: RelicTask.TASK_1, jobs: [Job.WVR] },
					{ id: 985, task: RelicTask.TASK_1, jobs: [Job.ALC] },
					{ id: 988, task: RelicTask.TASK_1, jobs: [Job.CUL] },
					{ id: 989, task: RelicTask.TASK_1, jobs: [Job.MIN] },
					{ id: 990, task: RelicTask.TASK_2, jobs: [Job.MIN] },
					{ id: 991, task: RelicTask.TASK_1, jobs: [Job.BTN] },
					{ id: 992, task: RelicTask.TASK_2, jobs: [Job.BTN] },
					{ id: 993, task: RelicTask.TASK_1, jobs: [Job.FSH] },
				] },
			] },
			{ id: 813, part: RelicPart.TOOL_SKYBUILDERS, steps: [
				{ id: 735, step: RelicStep.PARTS, tasks: [
					{ id: 865, task: RelicTask.TASK_1, jobs: [Job.CRP] },
					{ id: 868, task: RelicTask.TASK_1, jobs: [Job.BSM] },
					{ id: 871, task: RelicTask.TASK_1, jobs: [Job.ARM] },
					{ id: 876, task: RelicTask.TASK_1, jobs: [Job.GSM] },
					{ id: 877, task: RelicTask.TASK_1, jobs: [Job.LTW] },
					{ id: 1032, task: RelicTask.TASK_1, jobs: [Job.WVR] },
					{ id: 1033, task: RelicTask.TASK_1, jobs: [Job.ALC] },
					{ id: 1038, task: RelicTask.TASK_1, jobs: [Job.CUL] },
					{ id: 1039, task: RelicTask.TASK_1, jobs: [Job.MIN] },
					{ id: 1040, task: RelicTask.TASK_2, jobs: [Job.MIN] },
					{ id: 1041, task: RelicTask.TASK_1, jobs: [Job.BTN] },
					{ id: 1042, task: RelicTask.TASK_2, jobs: [Job.BTN] },
					{ id: 1043, task: RelicTask.TASK_1, jobs: [Job.FSH] },
					{ id: 1044, task: RelicTask.TASK_2, jobs: [Job.FSH] },
				] },
			] },
		] },
	];
	/* eslint-enable object-curly-newline */
}
