//#region Imports
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FormComponents from "components/forms/FormComponents";
import { Icon, Modal } from "enums";
import manager from "managers/app";
import ModalContent from "modals/content/ModalContent";
import ModalFooter from "modals/footer/ModalFooter";
import ModalHeader from "modals/header/ModalHeader";
import { Component } from "react";
import { connect } from "react-redux";
import t from "translations/translator";
import { AppState, OtherModalProps } from "types";
import "./OtherModal.css";
//#endregion Imports

const mapStateToProps = (state:AppState) => {
	return {
		lang: state.userInfo.options.lang,
		gameLang: state.userInfo.options.gameLang,
		other: state.modal[Modal.OTHER],
	};
};

class OtherModal extends Component<OtherModalProps> {
	render(){
		const elements:JSX.Element[] = [];
		let list:JSX.Element[] = [];
		let addList = false;
		this.props.other.forEach((thisText, index) => {
			const eleKey = `other-${index}`;
			switch(thisText.element){
				default:
				case "p": elements.push(<p key={eleKey}>{t.t(thisText.text)}</p>); break;
				case "br": elements.push(<br key={eleKey}/>); break;
				case "li":
					list.push(<li key={`${eleKey}-li`}><span><FontAwesomeIcon icon={faAngleRight} />{t.t(thisText.text)}</span></li>);

					if(!this.props.other[index + 1] || this.props.other[index + 1].element !== "li"){
						addList = true;
					}
					break;
			}

			if(addList){
				elements.push(<ul key={eleKey}>{list.map((item) => { return item; })}</ul>);
				list = [];
				addList = false;
			}
		});

		return (
			<div id={Modal.OTHER} className="modal-wrapper">
				<ModalHeader text="Details" icon={Icon.OTHER} />
				<ModalContent>
					{ elements.map((ele) => { return ele; })}
				</ModalContent>
				<ModalFooter>
					<FormComponents.Button text="Close" onClick={manager.modal.close} translate={true} />
				</ModalFooter>
			</div>
		);
	}
}
export default connect(mapStateToProps)(OtherModal);
