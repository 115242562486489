//#region Imports
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react"; // eslint-disable-line @typescript-eslint/no-shadow
import { connect } from "react-redux";
import t from "translations/translator";
import { AppState, DetailsSectionProps } from "types";
import "./DetailsSection.css";
//#endregion Imports

const mapStateToProps = (state:AppState) => {
	return {
		lang: state.userInfo.options.lang,
		gameLang: state.userInfo.options.gameLang,
		hideCompletedSteps: state.userInfo.options.hideCompletedSteps,
	};
};

class DetailsSection extends Component<DetailsSectionProps> {
	wrapperRef = React.createRef<HTMLDivElement>();
	maxHeight:number = 0;

	render(){
		if(this.props.hideCompletedSteps && this.props.stepComplete){
			return null;
		}

		return (
			<div id={this.props.id} className="step-details">
				<h2 onClick={this.toggleSection} title={t.t("Click to expand")}><FontAwesomeIcon icon={faChevronDown} />{t.t(this.props.title, this.props.placeholders)}<FontAwesomeIcon icon={faChevronDown} /></h2>
				<div className="wrapper" ref={this.wrapperRef}>
					{this.props.children}
				</div>
			</div>
		);
	}

	componentDidMount(){
		const wrapper = this.wrapperRef.current;
		if(!wrapper){ return; }

		const height = Math.max(wrapper.clientHeight, wrapper.offsetHeight, wrapper.scrollHeight) + 5;
		this.maxHeight = height;

		this.setWrapperHeight(0);
	}

	componentDidUpdate(){
		const wrapper = this.wrapperRef.current;
		if(!wrapper){ return; }

		const currentMaxHeightVal = wrapper.style.maxHeight;
		wrapper.style.maxHeight = "100%";
		const height = Math.max(wrapper.clientHeight, wrapper.offsetHeight, wrapper.scrollHeight) + 105;
		this.maxHeight = height;

		if(currentMaxHeightVal === "0px"){
			this.setWrapperHeight(0);
		}else{
			this.setWrapperHeight(this.maxHeight);
		}
	}

	setWrapperHeight(value:number){
		const wrapper = this.wrapperRef.current;
		if(!wrapper){ return; }
		wrapper.style.maxHeight = `${value}px`;
	}

	toggleSection = () => { // Event
		const wrapper = this.wrapperRef.current;
		const sectionWrapper = wrapper?.closest(`#${this.props.id}`);

		if(!wrapper || !sectionWrapper){ return; }

		if(wrapper.style.maxHeight === "0px"){
			sectionWrapper.classList.add("show");
			this.setWrapperHeight(this.maxHeight);
		}else{
			sectionWrapper.classList.remove("show");
			this.setWrapperHeight(0);
		}
	}
}
export default connect(mapStateToProps)(DetailsSection);
