//#region Imports
import { jobLists } from "character";
import { Job, RelicGroup, RelicPart, RelicType } from "enums";
import manager from "managers/app";
import { Component } from "react";
import { connect } from "react-redux";
import t from "translations/translator";
import { AppState, Character, JobProgress, PartsProgress, ProgressStatsProps, RelicProgress, RelicsProgress } from "types";
import utils from "utils";
//#endregion Imports

const mapStateToProps = (state:AppState) => {
	return {
		lang: state.userInfo.options.lang,
		gameLang: state.userInfo.options.gameLang,
		ignorePhyseosPart: state.userInfo.options.progress.ignorePhyseosPart,
		compactProgressView: state.userInfo.options.progress.compactProgressView,
	};
};


class ProgressStats extends Component<ProgressStatsProps> {
	private gradientFallOff:number = 2;
	render(){
		if(this.props.compactProgressView){
			return <div id="progress-stats">
				<div className="col-wrapper compact">
					<div className="col">
						<div className="table-wrapper">{this.getCompactJobTable()}</div>
					</div>
					<div className="col">
						<div className="table-wrapper">{this.getCompactRelicTable()}</div>
						<div className="table-wrapper">{this.getOverallTable()}</div>
					</div>
				</div>
			</div>;
		}

		return <div id="progress-stats">
			<div className="table-wrapper">{this.getOverallTable()}</div>
			<div className="col-wrapper">
				<div className="col">
					<div className="table-wrapper">{this.getPartsByJobTable()}</div>
					<div className="table-wrapper">{this.getPartsByRelicTable()}</div>
				</div>
				<div className="col">
					<div className="table-wrapper">{this.getRelicsByJobTable()}</div>
					<div className="table-wrapper">{this.getJobsByRelicTable()}</div>
				</div>
			</div>
		</div>;
	}

	//#region Misc Methods
	getTableBackgroundGradient(percent:number, baseColour:string):string{
		const colour = `var(--progress-stats-gradient-colour-${percent === 0 ? 1 : Math.ceil(percent / 25)})`;
		const gradientParts:string[] = [
			`90deg`,
			`${colour} 0%`,
			`${colour} ${percent}%`,
			`${baseColour} ${percent + this.gradientFallOff}%`,
		];
		return `linear-gradient(${gradientParts.join(", ")})`;
	}

	getCompactTableBackgroundGradient(leftPercent:number, rightPercent:number, baseColour:string):string{
		const offset = 10;
		const leftRowPosition = Math.ceil(leftPercent / 100 * (50 - offset));
		const rightRowPosition = Math.ceil(rightPercent / 100 * (50 - offset));
		const leftStart = 50 - offset;
		const rightStart = 50 + offset;
		const leftColour = `var(--progress-stats-gradient-colour-${leftPercent === 0 ? 1 : Math.ceil(leftPercent / 25)})`;
		const rightColour = `var(--progress-stats-gradient-colour-${rightPercent === 0 ? 1 : Math.ceil(rightPercent / 25)})`;

		const leftPosition = this.getCompactTableGradientPosition("left", leftPercent, leftStart, leftRowPosition);
		const rightPosition = this.getCompactTableGradientPosition("right", rightPercent, rightStart, rightRowPosition);

		const gradientParts:string[] = [`90deg`];

		// Start
		gradientParts.push(`${baseColour} 0%`);

		// Left Side
		if(leftPercent > 0){
			gradientParts.push(`${baseColour} ${leftPosition - this.gradientFallOff}%`);
			gradientParts.push(`${leftColour} ${leftPosition}%`);
		}else{
			gradientParts.push(`${baseColour} ${leftStart}%`);
		}
		gradientParts.push(`${leftColour} ${leftStart}%`);

		// Center
		gradientParts.push(`${baseColour} ${50 - (offset / 2)}%`);
		gradientParts.push(`${baseColour} 50%`);
		gradientParts.push(`${baseColour} ${50 + (offset / 2)}%`);

		// Right Side
		gradientParts.push(`${rightColour} ${rightStart}%`);
		if(rightPercent > 0){
			gradientParts.push(`${rightColour} ${rightPosition}%`);
			gradientParts.push(`${baseColour} ${rightPosition + this.gradientFallOff}%`);
		}else{
			gradientParts.push(`${baseColour} ${rightStart}%`);
		}

		// End
		gradientParts.push(`${baseColour} 100%`);

		return `linear-gradient(${gradientParts.join(", ")})`;
	}

	getCompactTableGradientPosition(side:string, percent:number, start:number, rowPosition:number){
		if(percent === 0){
			return start;
		}else if(percent === 100){
			return side === "left" ? 0 : 100;
		}
		return side === "left" ? start - rowPosition : start + rowPosition;
	}
	//#endregion Misc Methods

	//#region Prepare Data
	getPartsProgress():PartsProgress|null{
		const character = manager.data.getActiveCharacter();
		if(!character){ return null; }

		let completeParts = 0;
		let totalParts = 0;

		const relicList = Object.values(RelicGroup);
		relicList.forEach((relic) => {
			if(manager.relics.getRelicType(relic) === RelicType.ARMOUR){ return; } // TODO: Add in relic armour

			const partList = manager.relics.getRelicPartsForRelic(relic);
			const type = manager.relics.getRelicType(relic);
			partList.forEach((part) => {
				if(this.props.ignorePhyseosPart && part === RelicPart.WEAPON_PHYSEOS){ return; }

				jobLists.relic[relic].forEach((job) => {
					const isComplete = character.progress.isComplete(type, relic, part, job);
					if(isComplete){ completeParts++; }
					totalParts++;
				});
			});
		});
		return { complete: completeParts, total: totalParts };
	}

	getRelicsProgress():RelicsProgress|null{
		const character = manager.data.getActiveCharacter();
		if(!character){ return null; }

		let completeRelics = 0;
		let totalRelics = 0;

		const relicList = Object.values(RelicGroup);
		relicList.forEach((relic) => {
			if(manager.relics.getRelicType(relic) === RelicType.ARMOUR){ return; } // TODO: Add in relic armour

			const type = manager.relics.getRelicType(relic);

			jobLists.relic[relic].forEach((job) => {
				const isComplete = character.progress.isComplete(type, relic, job);
				if(isComplete){ completeRelics++; }
				totalRelics++;
			});
		});
		return { complete: completeRelics, total: totalRelics };
	}

	getJobProgress():JobProgress[]{
		const character = manager.data.getActiveCharacter();
		if(!character){ return []; }

		const jobProgress:JobProgress[] = [];

		jobLists.all.forEach((job) => {
			const [finishedPart, totalParts] = this.getPartsForJob(character, job);
			const [relicsComplete, totalRelics] = this.getRelicsForJob(character, job);

			jobProgress.push({
				job: job,
				totalParts: totalParts,
				partsFinished: finishedPart,
				relicsComplete: relicsComplete,
				totalRelics: totalRelics,
			});
		});

		return jobProgress;
	}

	getPartsForJob(character:Character, job:Job){
		let finishedPart = 0;
		let totalParts = 0;

		const relicList = Object.values(RelicGroup);
		relicList.forEach((relic) => {
			if(manager.relics.getRelicType(relic) === RelicType.ARMOUR){ return; } // TODO: Add in relic armour

			const partList = manager.relics.getRelicPartsForRelic(relic);
			const type = manager.relics.getRelicType(relic);

			partList.forEach((part) => {
				if(this.props.ignorePhyseosPart && part === RelicPart.WEAPON_PHYSEOS){ return; }

				if(jobLists.relic[relic].includes(job)){
					const isComplete = character.progress.isComplete(type, relic, part, job);
					if(isComplete){ finishedPart++; }
					totalParts++;
				}
			});
		});
		return [finishedPart, totalParts];
	}

	getRelicsForJob(character:Character, job:Job){
		let relicsComplete = 0;
		let totalRelics = 0;

		const relicList = Object.values(RelicGroup);
		relicList.forEach((relic) => {
			if(manager.relics.getRelicType(relic) === RelicType.ARMOUR){ return; } // TODO: Add in relic armour

			const type = manager.relics.getRelicType(relic);

			if(jobLists.relic[relic].includes(job)){
				const isComplete = character.progress.isComplete(type, relic, job);
				if(isComplete){ relicsComplete++; }
				totalRelics++;
			}
		});
		return [relicsComplete, totalRelics];
	}

	getRelicProgress():RelicProgress[]{
		const character = manager.data.getActiveCharacter();
		if(!character){ return []; }

		const relicProgress:RelicProgress[] = [];

		Object.values(RelicGroup).forEach((relic) => {
			if(manager.relics.getRelicType(relic) === RelicType.ARMOUR){ return; } // TODO: Add in relic armour

			const [partsFinished, totalParts] = this.getPartsForRelic(character, relic);
			const jobsComplete = this.getJobsForRelic(character, relic);

			relicProgress.push({
				relic: relic,
				partsFinished: partsFinished,
				totalParts: totalParts,
				jobsComplete: jobsComplete,
				totalJobs: jobLists.relic[relic].length,
			});
		});

		return relicProgress;
	}

	getPartsForRelic(character:Character, relic:RelicGroup){
		let partsFinished = 0;
		let totalParts = 0;

		const type = manager.relics.getRelicType(relic);
		const partList = manager.relics.getRelicPartsForRelic(relic);

		partList.forEach((part) => {
			if(this.props.ignorePhyseosPart && part === RelicPart.WEAPON_PHYSEOS){ return; }

			const isComplete = character.progress.isComplete(type, relic, part);
			if(isComplete){ partsFinished++; }
			totalParts++;
		});
		return [partsFinished, totalParts];
	}

	getJobsForRelic(character:Character, relic:RelicGroup){
		let jobsComplete = 0;

		const type = manager.relics.getRelicType(relic);

		jobLists.relic[relic].forEach((job) => {

			const isComplete = character.progress.isComplete(type, relic, job);
			if(isComplete){ jobsComplete++; }
		});
		return jobsComplete;
	}
	//#endregion Prepare Data

	//#region Get Tables
	getOverallTable(){
		const parts = this.getPartsProgress();
		const relics = this.getRelicsProgress();

		let partsPercent = 0;
		let relicsPercent = 0;
		if(parts){ partsPercent = utils.helpers.getPercent(parts.complete, parts.total); }
		if(relics){ relicsPercent = utils.helpers.getPercent(relics.complete, relics.total); }

		const partsGradient = this.getTableBackgroundGradient(partsPercent, "var(--table-body-background-secondary)");
		const relicsGradient = this.getTableBackgroundGradient(partsPercent, "var(--table-body-background-primary)");

		return <table className="table overall">
			<thead><tr><th className="left-align">{t.t("Overall")}</th><th>{t.t("Complete")}</th><th>{t.t("Total")}</th><th>%</th></tr></thead>
			<tbody>
				{parts === null
					? <tr><td colSpan={4}>{t.t("Error preparing data")}</td></tr>
					: <tr style={{ background: partsGradient}}><td className="left-align">{t.t("Parts")}</td><td>{parts.complete}</td><td>{parts.total}</td><td>{partsPercent}%</td></tr>
				}
				{relics === null
					? <tr><td colSpan={4}>{t.t("Error preparing data")}</td></tr>
					: <tr style={{ background: relicsGradient}}><td className="left-align">{t.t("Relics")}</td><td>{relics.complete}</td><td>{relics.total}</td><td>{relicsPercent}%</td></tr>
				}
			</tbody>
		</table>;
	}

	getCompactJobTable(){
		const progressByJobStats = this.getJobProgress();

		return <table className="table">
			<thead>
				<tr><th colSpan={7}>{t.t("Relic Progress By Job")}</th></tr>
				<tr>
					<th>{t.t("Total Parts")}</th><th>{t.t("Parts Finished")}</th><th>{t.t("% Finished")}</th>
					<th>{t.t("Job")}</th>
					<th>{t.t("% Complete")}</th><th>{t.t("Relics Complete")}</th><th>{t.t("Total Relics")}</th></tr>
			</thead>
			<tbody>
				{progressByJobStats.map((jobProgress, index) => {
					const baseBackgroundColour = index % 2 ? "var(--table-body-background-primary)" : "var(--table-body-background-secondary)";
					const partsPercent = utils.helpers.getPercent(jobProgress.partsFinished, jobProgress.totalParts);
					const relicPercent = utils.helpers.getPercent(jobProgress.relicsComplete, jobProgress.totalRelics);
					const gradient = this.getCompactTableBackgroundGradient(partsPercent, relicPercent, baseBackgroundColour);

					return <tr key={jobProgress.job} style={{ background: gradient}}>
						<td>{jobProgress.totalParts}</td>
						<td>{jobProgress.partsFinished}</td>
						<td>{partsPercent}%</td>
						<td>{t.t(manager.relics.getJobName(jobProgress.job))}</td>
						<td>{relicPercent}%</td>
						<td>{jobProgress.relicsComplete}</td>
						<td>{jobProgress.totalRelics}</td>
					</tr>;
				})}
			</tbody>
		</table>;
	}

	getPartsByJobTable(){
		const progressByJobStats = this.getJobProgress();

		return <table className="table">
			<thead>
				<tr><th colSpan={4}>{t.t("Parts Progress By Job")}</th></tr>
				<tr><th className="left-align">{t.t("Job")}</th><th>{t.t("Parts Finished")}</th><th>{t.t("Total Parts")}</th><th>{t.t("% Finished")}</th></tr>
			</thead>
			<tbody>
				{progressByJobStats.map((jobProgress, index) => {
					const baseBackgroundColour = index % 2 ? "var(--table-body-background-primary)" : "var(--table-body-background-secondary)";
					const partsPercent = utils.helpers.getPercent(jobProgress.partsFinished, jobProgress.totalParts);
					const gradient = this.getTableBackgroundGradient(partsPercent, baseBackgroundColour);

					return <tr key={jobProgress.job} style={{ background: gradient}}>
						<td className="left-align">{t.t(manager.relics.getJobName(jobProgress.job))}</td>
						<td>{jobProgress.partsFinished}</td>
						<td>{jobProgress.totalParts}</td>
						<td>{partsPercent}%</td>
					</tr>;
				})}
			</tbody>
		</table>;
	}

	getRelicsByJobTable(){
		const progressByJobStats = this.getJobProgress();

		return <table className="table">
			<thead>
				<tr><th colSpan={4}>{t.t("Relic Progress By Job")}</th></tr>
				<tr><th>{t.t("Job")}</th><th>{t.t("Relics Complete")}</th><th>{t.t("Total Relics")}</th><th>{t.t("% Complete")}</th></tr>
			</thead>
			<tbody>
				{progressByJobStats.map((jobProgress, index) => {
					const baseBackgroundColour = index % 2 ? "var(--table-body-background-primary)" : "var(--table-body-background-secondary)";
					const relicPercent = utils.helpers.getPercent(jobProgress.relicsComplete, jobProgress.totalRelics);
					const gradient = this.getTableBackgroundGradient(relicPercent, baseBackgroundColour);

					return <tr key={jobProgress.job} style={{ background: gradient}}>
						<td>{t.t(manager.relics.getJobName(jobProgress.job))}</td>
						<td>{jobProgress.relicsComplete}</td>
						<td>{jobProgress.totalRelics}</td>
						<td>{relicPercent}%</td>
					</tr>;
				})}
			</tbody>
		</table>;
	}

	getCompactRelicTable(){
		const progressByRelicStats = this.getRelicProgress();

		return <table className="table">
			<thead>
				<tr><th colSpan={7}>{t.t("Relic Progress By Relic")}</th></tr>
				<tr><th>{t.t("Total Parts")}</th><th>{t.t("Parts Finished")}</th><th>{t.t("% Finished")}</th><th>{t.t("Relic")}</th><th>{t.t("% Complete")}</th><th>{t.t("Jobs Complete")}</th><th>{t.t("Total Jobs")}</th></tr>
			</thead>
			<tbody>
				{progressByRelicStats.map((relicProgress, index) => {
					const baseBackgroundColour = index % 2 ? "var(--table-body-background-primary)" : "var(--table-body-background-secondary)";

					const partsPercent = utils.helpers.getPercent(relicProgress.partsFinished, relicProgress.totalParts);
					const relicPercent = utils.helpers.getPercent(relicProgress.jobsComplete, relicProgress.totalJobs);
					const gradient = this.getCompactTableBackgroundGradient(partsPercent, relicPercent, baseBackgroundColour);

					return <tr key={relicProgress.relic} style={{ background: gradient}}>
						<td>{relicProgress.totalParts}</td>
						<td>{relicProgress.partsFinished}</td>
						<td>{partsPercent}%</td>
						<td>{t.t(relicProgress.relic)}</td>
						<td>{relicPercent}%</td>
						<td>{relicProgress.jobsComplete}</td>
						<td>{relicProgress.totalJobs}</td>
					</tr>;
				})}
			</tbody>
		</table>;
	}

	getPartsByRelicTable(){
		const progressByRelicStats = this.getRelicProgress();

		return <table className="table">
			<thead>
				<tr><th colSpan={4}>{t.t("Parts Progress By Relic")}</th></tr>
				<tr><th>{t.t("Relic")}</th><th>{t.t("Finished Parts")}</th><th>{t.t("Total Parts")}</th><th>{t.t("% Complete")}</th></tr>
			</thead>
			<tbody>
				{progressByRelicStats.map((relicProgress, index) => {
					const baseBackgroundColour = index % 2 ? "var(--table-body-background-primary)" : "var(--table-body-background-secondary)";

					const partsPercent = utils.helpers.getPercent(relicProgress.partsFinished, relicProgress.totalParts);
					const gradient = this.getTableBackgroundGradient(partsPercent, baseBackgroundColour);

					return <tr key={relicProgress.relic} style={{ background: gradient}}>
						<td>{t.t(relicProgress.relic)}</td>
						<td>{relicProgress.partsFinished}</td>
						<td>{relicProgress.totalParts}</td>
						<td>{partsPercent}%</td>
					</tr>;
				})}
			</tbody>
		</table>;
	}

	getJobsByRelicTable(){
		const progressByRelicStats = this.getRelicProgress();

		return <table className="table">
			<thead>
				<tr><th colSpan={4}>{t.t("Job Progress By Relic")}</th></tr>
				<tr><th>{t.t("Relic")}</th><th>{t.t("Jobs Complete")}</th><th>{t.t("Total Jobs")}</th><th>{t.t("% Complete")}</th></tr>
			</thead>
			<tbody>
				{progressByRelicStats.map((relicProgress, index) => {
					const baseBackgroundColour = index % 2 ? "var(--table-body-background-primary)" : "var(--table-body-background-secondary)";

					const relicPercent = utils.helpers.getPercent(relicProgress.jobsComplete, relicProgress.totalJobs);
					const gradient = this.getTableBackgroundGradient(relicPercent, baseBackgroundColour);

					return <tr key={relicProgress.relic} style={{ background: gradient}}>
						<td>{t.t(relicProgress.relic)}</td>
						<td>{relicProgress.jobsComplete}</td>
						<td>{relicProgress.totalJobs}</td>
						<td>{relicPercent}%</td>
					</tr>;
				})}
			</tbody>
		</table>;
	}
	//#endregion Get Tables
}
export default connect(mapStateToProps)(ProgressStats);
