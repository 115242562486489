//#region Imports
import FormComponents from "components/forms/FormComponents";
import { Currency, RelicGroup, RelicPart } from "enums";
import manager from "managers/app";
import { Component } from "react";
import { connect } from "react-redux";
import { MultiValue, SingleValue } from "react-select";
import t from "translations/translator";
import { AppState, ItemFilters, ItemsFiltersProps, OutputGameString, OutputString, RSOption } from "types";
import utils from "utils";
//#endregion Imports

const mapStateToProps = (state:AppState) => {
	return {
		lang: state.userInfo.options.lang,
		gameLang: state.userInfo.options.gameLang,
		breakpoint: state.breakpoint,
		options: state.userInfo.options,
		itemOptions: state.userInfo.options.items,
	};
};


class Filters extends Component<ItemsFiltersProps> {
	render(){
		const filters = this.getSelectedFilters();
		const selectOptions = this.getSelectOptions();

		return (
			<div id="item-filters">
				<div className="checkboxes">
					<FormComponents.Checkbox
						label={{ hide: false, text: "Hide 0 remaining items", position: "right", alignment: "center" }}
						input={{ checked: filters.hideNoRemainingItems.value, events: { onClick: filters.hideNoRemainingItems.event } }}
					/>
					<FormComponents.Checkbox
						label={{ hide: false, text: "Show only buyable items", position: "right", alignment: "center" }}
						input={{ checked: filters.showOnlyBuyable.value, events: { onClick: filters.showOnlyBuyable.event } }}
					/>
					<FormComponents.Checkbox
						label={{ hide: false, text: "Show Maximum Required", position: "right", alignment: "center" }}
						input={{ checked: filters.showMaximumRequired.value, events: { onClick: filters.showMaximumRequired.event } }}
					/>
				</div>
				<div className="selects">
					<FormComponents.Select
						label={{ hide: false, text: "View", position: "top", alignment: "center" }}
						input={{ options: selectOptions.view, value: filters.view.value, events: { onChange: filters.view.event } }}
					/>
					<FormComponents.Select
						label={{ hide: false, text: "Relics", position: "top", alignment: "center" }}
						input={{ options: selectOptions.relic, value: filters.relics.value, multiple: true, placeholder: "Select relics to filter", events: { onChange: filters.relics.event } }}
					/>
					<FormComponents.Select
						label={{ hide: false, text: "Relic Parts", position: "top", alignment: "center" }}
						input={{ options: selectOptions.part, value: filters.parts.value, multiple: true, placeholder: "Select relic parts to filter", events: { onChange: filters.parts.event } }}
					/>
					<FormComponents.Select
						label={{ hide: false, text: "Currency", position: "top", alignment: "center" }}
						input={{ options: selectOptions.currency, value: filters.currencies.value, multiple: true, placeholder: "Select currencies to filter", events: { onChange: filters.currencies.event } }}
					/>
				</div>
			</div>
		);
	}

	componentDidMount(){
		manager.view.changeComplete();
	}

	private getSelectedFilters():ItemFilters{
		return {
			view: { value: this.getSelectedOptions(this.props.itemOptions.selectedView), event: this.switchView },
			relics: { value: this.getSelectedOptions(this.props.itemOptions.relicsFilter), event: this.switchRelicFilter },
			parts: { value: this.getSelectedOptions(this.props.itemOptions.relicPartsFilter), event: this.switchPartFilter },
			currencies: { value: this.getSelectedOptions(this.props.itemOptions.currencyFilter), event: this.switchCurrencyFilter },
			hideNoRemainingItems: { value: this.props.itemOptions.hideNoRemaining, event: this.toggleHideNoRemainingItems },
			showOnlyBuyable: { value: this.props.itemOptions.showOnlyBuyable, event: this.toggleShowOnlyBuyable },
			showMaximumRequired: { value: this.props.itemOptions.showMaximumRequired, event: this.toggleGetMaxItems },
		};
	}

	private getSelectedOptions(values:RelicGroup[]|RelicPart[]|Currency[]|("Items"|"Currency")):RSOption[]{
		const selectOptions:RSOption[] = [];
		if(values === "Items" || values === "Currency"){
			selectOptions.push({ label: t.t(values), value: values });
			return selectOptions;
		}

		values.forEach((value) => {
			const optionValue = value;
			let optionText:string = "Unknown";
			if(utils.guards.isEnumValue(RelicGroup, value)){
				optionText = t.t(value);
			}else if(utils.guards.isEnumValue(RelicPart, value)){
				optionText = `${t.t(value)} (iLvl ${manager.relics.getPartILvl(value)})`;
			}else if(utils.guards.isEnumValue(Currency, value)){
				optionText = t.t(value);
			}
			selectOptions.push({ label: optionText, value: optionValue });
		});
		return selectOptions;
	}

	private getSelectOptions(){
		return {
			view: this.generateOptions(["Items", "Currency"]),
			relic: manager.content.getRelicSelectOptions(),
			part: manager.content.getRelicPartSelectOptions(),
			currency: this.generateOptions(Object.values(Currency)),
		};
	}

	private generateOptions(enumValues:(OutputString|OutputGameString)[]):RSOption[]{
		const options:RSOption[] = [];
		enumValues.forEach((thisValue) => {
			if(thisValue === "GUnknown"){ return; }
			options.push({ label: t.t(thisValue), value: thisValue});
		});
		return options;
	}

	switchView = (selectedOptions:MultiValue<RSOption>|SingleValue<RSOption>) => { // Event
		if((selectedOptions instanceof Array)){ return; }

		const selected = selectedOptions?.value;
		if(!selected || (selected !== "Items" && selected !== "Currency")){ return; }

		const options = this.props.options;
		options.items = { ...options.items, selectedView: selected };
		manager.data.saveOptions(options, true);
	}

	switchRelicFilter = (selectedOptions:MultiValue<RSOption>|SingleValue<RSOption>) => { // Event
		if(!(selectedOptions instanceof Array)){ return; }

		const selected:RelicGroup[] = [];
		selectedOptions.forEach((selectedOption) => { selected.push(selectedOption.value as RelicGroup); });

		const options = this.props.options;
		options.items = { ...options.items, relicsFilter: selected };
		manager.data.saveOptions(options, true);
	}

	switchPartFilter = (selectedOptions:MultiValue<RSOption>|SingleValue<RSOption>) => { // Event
		if(!(selectedOptions instanceof Array)){ return; }

		const selected:RelicPart[] = [];
		selectedOptions.forEach((selectedOption) => { selected.push(selectedOption.value as RelicPart); });

		const options = this.props.options;
		options.items = { ...options.items, relicPartsFilter: selected };
		manager.data.saveOptions(options, true);
	}

	switchCurrencyFilter = (selectedOptions:MultiValue<RSOption>|SingleValue<RSOption>) => { // Event
		if(!(selectedOptions instanceof Array)){ return; }

		const selected:Currency[] = [];
		selectedOptions.forEach((selectedOption) => { selected.push(selectedOption.value as Currency); });

		const options = this.props.options;
		options.items = { ...options.items, currencyFilter: selected };
		manager.data.saveOptions(options, true);
	}

	toggleHideNoRemainingItems = () => { // Event
		const options = this.props.options;
		options.items = { ...options.items, hideNoRemaining: !options.items.hideNoRemaining };
		manager.data.saveOptions(options, true);
	}

	toggleShowOnlyBuyable = () => { // Event
		const options = this.props.options;
		options.items = { ...options.items, showOnlyBuyable: !options.items.showOnlyBuyable };
		manager.data.saveOptions(options, true);
	}

	toggleGetMaxItems = () => { // Event
		const options = this.props.options;
		options.items = { ...options.items, showMaximumRequired: !options.items.showMaximumRequired };
		manager.data.saveOptions(options, true);
	}
}
export default connect(mapStateToProps)(Filters);
