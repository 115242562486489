//#region Imports
import FormComponents from "components/forms/FormComponents";
import Link from "components/link/Link";
import { dungeons } from "data-files/duties";
import { quests } from "data-files/quests";
import { Icon, LinkType, Modal } from "enums";
import manager from "managers/app";
import ModalContent from "modals/content/ModalContent";
import ModalFooter from "modals/footer/ModalFooter";
import ModalHeader from "modals/header/ModalHeader";
import { Component } from "react";
import { connect } from "react-redux";
import t from "translations/translator";
import { AppState, DungeonsModalProps } from "types";
import "./DungeonsModal.css";
//#endregion Imports

const mapStateToProps = (state:AppState) => {
	return {
		lang: state.userInfo.options.lang,
		gameLang: state.userInfo.options.gameLang,
		dungeons: state.modal[Modal.DUNGEONS],
	};
};

class DungeonsModal extends Component<DungeonsModalProps> {
	render(){
		return (
			<div id={Modal.DUNGEONS} className="modal-wrapper">
				<ModalHeader text="Dungeons" icon={Icon.DUNGEON} />
				<ModalContent>
					<div className="dungeon-wrapper">
						{this.props.dungeons.map((dung) => {
							const dungeon = dungeons[dung];
							const unlockQuest = quests[dungeon.unlocked];

							return <div key={dungeon.name} className="dungeon-card">
								<h3 className="dungeon-name"><Link type={LinkType.LODESTONE} lodestoneType={dungeon.type} lodestoneID={dungeon.lodestoneID} text={dungeon.name} /></h3>
								<p className="dungeon-unlocked">
									<span>{t.t("Quest to unlock")}</span><Link type={LinkType.LODESTONE} lodestoneType={unlockQuest.type} lodestoneID={unlockQuest.lodestoneID} text={unlockQuest.name} />
								</p>
							</div>;
						})}
					</div>
				</ModalContent>
				<ModalFooter>
					<FormComponents.Button text="Close" onClick={manager.modal.close} translate={true} />
				</ModalFooter>
			</div>
		);
	}
}
export default connect(mapStateToProps)(DungeonsModal);
