//#region Imports
import BasicIcon from "components/basic-icon/BasicIcon";
import FormComponents from "components/forms/FormComponents";
import { locations } from "data-files/locations";
import { Icon, Modal } from "enums";
import manager from "managers/app";
import ModalContent from "modals/content/ModalContent";
import ModalFooter from "modals/footer/ModalFooter";
import ModalHeader from "modals/header/ModalHeader";
import { Component } from "react";
import { connect } from "react-redux";
import t from "translations/translator";
import { AppState, LocationsModalProps } from "types";
import "./LocationsModal.css";
//#endregion Imports

const mapStateToProps = (state:AppState) => {
	return {
		lang: state.userInfo.options.lang,
		gameLang: state.userInfo.options.gameLang,
		locations: state.modal[Modal.LOCATIONS],
	};
};
// NOTE: Untested - As of writing this modal was not used anywhere
class LocationsModal extends Component<LocationsModalProps> {
	render(){
		return (
			<div id={Modal.LOCATIONS} className="modal-wrapper">
				<ModalHeader text="Locations" icon={Icon.LOCATION} />
				<ModalContent>
					<div className="location-wrapper">
						{this.props.locations.map((obtainedLocation) => {
							const thisLocation = locations[obtainedLocation.location];

							return <div key={obtainedLocation.location} className="location-card">
								<h3 className="location-name">{t.t(obtainedLocation.location)}</h3>
								<div className="location-location-wrapper">
									<div className="location-location">
										<span><BasicIcon icon={Icon.MAP} tooltip="Map" /> {t.t(thisLocation.map)}</span>
										{thisLocation.region ? <span><BasicIcon icon={Icon.REGION} tooltip="Region" /> {t.t(thisLocation.region)}</span> : null}
										{thisLocation.teleport ? <span><BasicIcon icon={Icon.TELEPORT} tooltip="Teleport" /> {t.t(thisLocation.teleport)}</span> : null}
										{obtainedLocation.x > -1 ? <span><BasicIcon icon={Icon.LOCATION} tooltip="Co-ordinates" /> X: {obtainedLocation.x}, Y: {obtainedLocation.y}</span> : null}
									</div>
								</div>
							</div>;
						})}
					</div>
				</ModalContent>
				<ModalFooter>
					<FormComponents.Button text="Close" onClick={manager.modal.close} translate={true} />
				</ModalFooter>
			</div>
		);
	}
}
export default connect(mapStateToProps)(LocationsModal);
